import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ModelListenersLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-model-listener", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Pentru a creea o clasa de tip listener pentru un model, clasa trebuie:
                    <ul>
                        <li>sa fie de tip <b>@Component(service = ModelListener.class)</b></li>
                        <li>sa extinda <b>BaseModelListener</b></li>
                        <li>sa implementeze metodele <b>on</b>Actiune()</li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package model.listeners;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.exception.ModelListenerException;\n' +
                            'import com.liferay.portal.kernel.model.BaseModelListener;\n' +
                            'import com.liferay.portal.kernel.model.ModelListener;\n' +
                            'import com.liferay.portal.kernel.model.User;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '@Component(service = ModelListener.class)\n' +
                            'public class UserModelListener extends BaseModelListener<User> {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onAfterAddAssociation(Object classPK, String associationClassName, Object associationClassPK) throws ModelListenerException {\n' +
                            '        super.onAfterAddAssociation(classPK, associationClassName, associationClassPK);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onAfterCreate(User model) throws ModelListenerException {\n' +
                            '        super.onAfterCreate(model);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onAfterRemove(User model) throws ModelListenerException {\n' +
                            '        super.onAfterRemove(model);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onAfterRemoveAssociation(Object classPK, String associationClassName, Object associationClassPK) throws ModelListenerException {\n' +
                            '        super.onAfterRemoveAssociation(classPK, associationClassName, associationClassPK);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onAfterUpdate(User model) throws ModelListenerException {\n' +
                            '        super.onAfterUpdate(model);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onBeforeAddAssociation(Object classPK, String associationClassName, Object associationClassPK) throws ModelListenerException {\n' +
                            '        super.onBeforeAddAssociation(classPK, associationClassName, associationClassPK);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onBeforeCreate(User model) throws ModelListenerException {\n' +
                            '        super.onBeforeCreate(model);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onBeforeRemove(User model) throws ModelListenerException {\n' +
                            '        super.onBeforeRemove(model);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onBeforeRemoveAssociation(Object classPK, String associationClassName, Object associationClassPK) throws ModelListenerException {\n' +
                            '        super.onBeforeRemoveAssociation(classPK, associationClassName, associationClassPK);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void onBeforeUpdate(User model) throws ModelListenerException {\n' +
                            '        super.onBeforeUpdate(model);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: model-listeners\n' +
                            'Bundle-SymbolicName: model.listeners\n' +
                            'Bundle-Version: 1.0.0'}
                    </SyntaxHighlighter>

                    Fisierul <b>build.gradle</b>
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\tcompileOnly group: "org.osgi", name: "org.osgi.core", version: "6.0.0"\n' +
                            '\tcompileOnly group: "org.osgi", name: "org.osgi.service.component.annotations", version: "1.3.0"\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "com.liferay.portal.kernel", version: "5.4.0"\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Sugestie organizare module pentru un modul numit XX:
                    <ul>
                        <li>\modules\XX-extensions\XX-model-listener</li>
                    </ul>

                    <hr/>
                    <b>Execitiu</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/x1.png'}/>
                    </div>

                    BookModelListener:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.agnes.model.listeners;\n' +
                            '\n' +
                            '\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.exception.ModelListenerException;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.model.BaseModelListener;\n' +
                            'import com.liferay.portal.kernel.model.ModelListener;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.agnes.service.builder.model.Book;\n' +
                            '\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author iulianb\n' +
                            ' */\n' +
                            '@Component(\n' +
                            '\timmediate = true,\n' +
                            '\tservice = ModelListener.class\n' +
                            ')\n' +
                            'public class BookModelListener extends BaseModelListener <Book>\t{\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic void onAfterCreate(Book model) throws ModelListenerException {\n' +
                            '\t\tsuper.onAfterCreate(model);\n' +
                            '\n' +
                            '\t\t_log.info("onAfterCreate Book");\n' +
                            '\t}\n' +
                            '\n' +
                            '\tprivate final Log _log = LogFactoryUtil.getLog(BookModelListener.class);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: agnes-model-listener\n' +
                            'Bundle-SymbolicName: ro.agnes.model.listeners\n' +
                            'Bundle-Version: 1.0.0\n'}
                    </SyntaxHighlighter>

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "release.portal.api" , version: "7.4.3.120-ga120"\n' +
                            '\n' +
                            '\tcompileOnly project(":modules:agnes-training:agnes-service-builder:agnes-service-builder-api")\n' +
                            '\tcompileOnly project(":modules:agnes-training:agnes-service-builder:agnes-service-builder-service")\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>*/}
                    {/*                MVC Render Command*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ModelListenersLiferayContent;