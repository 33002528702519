import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AdalineContent extends BaseContentPage {

    constructor(props) {
        super(props, "adaline", IndexContent);
    }

    render() {
        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    <b><a id={"adaline"}>1.5. Adaline</a></b>
                    <br/>
                    <br/>
                    <div className={"text-justify important"}>
                        Adaline este similar cu Perceptronul. Așa cum se vede în tablul de mai jos, una din diferențe fiind <b>regula de învățare</b> pentru ajutarea poderilor si bias-ului:
                        <table>
                            <tr>
                                <th>
                                    Parametru
                                </th>
                                <th>
                                    Variabila
                                </th>
                                <th>
                                    Tip
                                </th>
                            </tr>
                            <tr>
                                <td>
                                    Date de intrare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`x_i`}/>
                                </td>
                                <td>
                                    Real sau binar (0,1) / biploar (-1, 1)
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Ponderile
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`w_i`}/>
                                </td>
                                <td>
                                    Real (initializate cu valori aleatoare); ponderea asociată conexiunii dintre neuronul <i>i</i> din input layer
                                    și singurul neuron din output layer.
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Prag (bias)
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`\theta`}/>
                                </td>
                                <td>
                                    Real (initializat cu valori aleatoare)
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Iesire
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`y`}/>
                                </td>
                                <td>
                                    Binar
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Functie de activare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`f()`}/>
                                </td>
                                <td>
                                    Functia pas sau functia pas bipolara
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Tip antrenare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`-`}/>
                                </td>
                                <td>
                                    Supervizată
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    Rugula de învătare
                                </td>
                                <td>
                                    <MathComponent tex={String.raw`-`}/>
                                </td>
                                <td>
                                    <b>Regula de învătarea delta</b>
                                </td>
                            </tr>

                        </table>

                        <br/>
                        O altă diferență constă în condiția de oprire a procesului de învățare:
                        <ul>
                            <li>
                                Pentru Perceptron, rețeua se consideră antrenată dacă toate toate seturile de antrament au fost
                                prezentate rețelei de un anumit număr (=numărul maxim de epoci) de ori.
                            </li>
                            <li>
                                Pentru Adaline, rețeua se consideră antrenată daca diferența eroarea medie pătrată (MSE) dintre două epoci succesive
                                este mai mică decât o constantă numită <b>precizie</b> și notată cu &epsilon; ( stabilită la definirea rețelei).
                            </li>
                        </ul>
                        După ce s-a finalizat o epocă se calculeaza eroarea rețelei (eroare medie pătrată - MSE):
                            <MathComponent tex={String.raw`E = MSE = {1\over h} \sum_k^h(t_k-u_k)^2`}/>
                        unde:
                        <ul>
                            <li><b>h</b> este numărul de observații/exemple din setul de antrenament</li>
                            <li><b>t<sub>k</sub></b> este răspunsul dorit asociată cu a k-a observație din setul de antrenament</li>
                            <li>
                                <b>u<sub>k</sub></b> sumarizarea facută de rețea pentru cu a k-a observație din setul de antrenament
                                <MathComponent tex={String.raw`u_k = \sum_{i=1}^n w_i x_i+\theta`}/>
                            </li>
                        </ul>
                    </div>
                </div>

                <br/>

                <div className={"text-justify important"}>
                    <b>Algoritm Adaline (Faza de antrenare)</b>
                    <br/>
                    <br/>
                    Algoritmul se foloseste pentru ajustarea ponderilor de:
                    <ul>
                        <li>
                            un <b>set de antrenament A</b>
                            <MathComponent tex={String.raw`A=\{A^1,A^2,...,A^h\}`}/>
                            iar fiecare element din A il vom numi <b>imagine</b> sau observatie și va fi de forma:
                            <MathComponent tex={String.raw`A^i=\{x_1,x_2,...,x_n\}`}/>
                        </li>

                        <li>
                            un <b>set de etichete T</b> asociate setului de antrenament A:
                            <MathComponent tex={String.raw`T=\{t_1,t_2,...,t_h\}`}/>
                        </li>

                    </ul>
                    Perceptron va avea <b>n</b> intrări in input layer și <b>m</b> neuroni in output layer (nu are layere hidden)

                    <hr/>
                    <div className={"padding50"}>
                        <i>date de intrare:</i>
                        <br/>
                        <ul>
                            <li>perechea (A,T)</li>
                        </ul>

                        <i>initializare:</i>
                        <ul>
                            <li>ponderi <b>w<sub>ij</sub></b> cu valori mici aleatore</li>
                            <li>bias-urile <b>&theta;<sub>j</sub></b> cu valori mici aleatore</li>
                            <li>rata de invatare <b>&eta;</b> cu o valoare mică, de exemplu &eta;=0.001</li>
                            <li>epoca = 0</li>
                            <li>&epsilon; (initializată cu o valoare mică)</li>
                            <li>eroareAnterioară - eroarea anterioară rețelei</li>
                            <li>eroareCurentă - eroarea curentă a rețelei (initializătă cu o valoare mare)</li>
                        </ul>

                        <i>algoritm:</i>
                        <ul>
                            <li>repeta</li>

                            <ul>
                                <li>eroareAnterioară=eroareCurenta</li>
                                <li>eroareCurenta=0</li>
                                <li>pentru toate perechile din setul de antrenament A<sup>k</sup> : T<sup>k</sup>, cu (1 &#8804;k &#8804; h)</li>
                                <MathComponent tex={String.raw`A^k=\{x_1,x_2,...,x_n\}, t=T^k=\{t_k\}`}/>
                                <ul>

                                    <li>
                                        calculăm sumarizarea:
                                        <MathComponent tex={String.raw`sum=\theta+\sum_{i}^{n} x_i w_{i}`}/>
                                    </li>
                                    <li>
                                        calculăm eroare parțială:
                                        <MathComponent tex={String.raw`e = (t-sum)`}/>
                                        si a adaugam la eroarea curenta:
                                        <MathComponent tex={String.raw`eroareCurenta = eroareCurenta + e_k^2`}/>
                                    </li>
                                    <li>
                                        actualizam ponderile, folosind regula de invatare delta:
                                        <ul>
                                            <li>
                                                pentru fiecare <b>neuron i</b>  (1 &#8804;i &#8804; n), actualizăm:
                                                <MathComponent tex={String.raw`w_{i}=w_{i}+\eta x_i e`}/>
                                            </li>
                                        </ul>
                                    </li>

                                </ul>


                                <li>epoca = epoca + 1</li>
                                <li>   <MathComponent tex={String.raw`eroareCurenta = {1\over h} eroareCurenta`}/></li>
                            </ul>

                            <li>până când <b>|eroareCurentă-eroareAnterioară | &le; &epsilon;</b></li>
                        </ul>
                        <br/>

                    </div>
                    <hr/>
                    <b>Observatie 1:</b><br/>Precum se vede <i>functia de activare</i> nu are nici un rol în procesul de antrenare!
                    <br/>
                    Calculul erorii se bazează sumarizare și nu pe activare (aplicarea functiei de activare pe sumarizare) ca în cazul perceptronului.
                    <div style={{padding:20}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/adaline_1.png'}/>
                    </div>
                    <br/>
                    <b>Observatie 2:</b><br/>Algoritmul Adaline pentru <b>faza de operare</b> este identic cu cel de la Perceptron!
                </div>

                <br/>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson
                        </li>
                        <li>
                            <div>
                                Ivan Nunes da Silva, Danilo Hernane Spatti , Rogerio Andrade Flauzino, Luisa Helena Bartocci Liboni, Silas Franco dos Reis Alves. 2017. Artificial Neural Networks - A Practical Course
                            </div>
                        </li>
                        <li>
                            <a href={"https://www.tutorialspoint.com/artificial_neural_network/index.htm"}  rel="noreferrer" target={"_blank"}>
                                https://www.tutorialspoint.com/artificial_neural_network/index.htm
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AdalineContent;