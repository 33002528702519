import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InstallKeycloakContent extends BaseContentPage  {

    constructor(props) {
        super(props, "keycloak-install", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Instalare Keycloak</b>
                    <ul>
                        <li>Se descarca <a href={" https://github.com/keycloak/keycloak/releases/download/15.0.2/keycloak-15.0.2.zip"}><b>Keycloak</b> versiunea 15.0.2</a></li>
                        <li>Se dezarhiveaza (de exemplu in: cd D:\servers\keycloak-15.0.2)</li>
                        <li>Se porneste cu comanda:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'cd D:\\servers\\keycloak-15.0.2\\bin\n' +
                                'standalone.bat'}
                            </SyntaxHighlighter>

                            Daca vrem sa pornim pe alt port:
                            <SyntaxHighlighter  showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'standalone.bat -Djboss.socket.binding.port-offset=100'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>

                    <b>Verificare</b>: <a href={"http://localhost:8180/auth"}><b>http://localhost:8180/auth</b></a>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-1.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InstallKeycloakContent;