import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ChatOptionsSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-chat-options-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    in cod java:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                           ' // varianta cu ChatOptions\n' +
                            'OpenAiChatOptions chatOptions = new OpenAiChatOptions();\n' +
                            'chatOptions.setModel("gpt-4o");\n' +
                            'chatOptions.setTemperature(0.7);\n' +
                            'chatOptions.setMaxTokens(20);\n' +
                            'ChatResponse chatResponse = chatClient.prompt(new Prompt(question, chatOptions)).call().chatResponse();'
                        }
                    </SyntaxHighlighter>

                    sau <b>application.properties</b>:
                    <SyntaxHighlighter>
                        {'spring.ai.openai.chat.options.model=gpt-4o\n' +
                            'spring.ai.openai.chat.options.temperature=0.7\n' +
                            'spring.ai.openai.chat.options.max-tokens=20'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChatOptionsSpringIALlmContent;