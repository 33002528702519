import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AssemblyAISpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-assemblyai-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    AssemblyAI este o companie specializată în dezvoltarea de modele avansate de inteligență artificială pentru transcrierea și înțelegerea vorbirii. Prin intermediul unei API prietenoase pentru dezvoltatori,
                    AssemblyAI oferă soluții de conversie a vorbirii în text, transcriere în timp real și analiză a conținutului audio.

                    <br/>
                    Caracteristici principale ale AssemblyAI:
                    <ul>
                        <li>
                            Transcriere vorbire-text: Furnizează transcrieri precise ale fișierelor audio, cu funcționalități avansate precum diarizarea vorbitorilor și detectarea limbii.
                        </li>
                        <li>
                            Transcriere în timp real: Permite generarea de subtitrări și transcrieri în timp real, utilizând tehnologii de recunoaștere vocală cu latență redusă
                        </li>
                        <li>
                            Înțelegerea vorbirii: Oferă funcționalități precum detectarea sentimentului, identificarea subiectelor și rezumarea automată a conținutului audio, extrăgând informații valoroase din datele vocale.
                        </li>
                        <li>
                            Redactarea informațiilor personale (PII): Asigură protecția datelor sensibile prin identificarea și eliminarea automată a informațiilor personale din transcrieri.
                        </li>
                    </ul>

                    <hr/>
                    <b>Modelul Universal-2:</b>
                    <br/>
                    <br/>

                    AssemblyAI a lansat modelul Universal-2, cea mai avansată soluție de transcriere vorbire-text, care captează complexitatea conversațiilor reale,
                    oferind date audio impecabile pentru experiențe de produs de top.

                    <br/>
                    Transcrire vorbire-text (STT):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.audio;\n' +
                            '\n' +
                            'import com.assemblyai.api.AssemblyAI;\n' +
                            'import com.assemblyai.api.resources.transcripts.requests.TranscriptParams;\n' +
                            'import com.assemblyai.api.resources.transcripts.types.*;\n' +
                            '\n' +
                            'import java.io.File;\n' +
                            '\n' +
                            'public class TranscribeAudio {\n' +
                            '\n' +
                            '    public static void main(String... args) throws Exception {\n' +
                            '        var apiKey = "";\n' +
                            '      //  var fileUrl = "https://assembly.ai/wildfires.mp3";\n' +
                            '\n' +
                            '        var client = AssemblyAI.builder()\n' +
                            '                .apiKey(apiKey)\n' +
                            '                .build();\n' +
                            '\n' +
                            '        var filePath = "D:\\\\work\\\\spring-ia\\\\src\\\\main\\\\resources\\\\20230607_me_canadian_wildfires.mp3";\n' +
                            '        var uploadedFile = client.files().upload(new File(filePath));\n' +
                            '        var fileUrl = uploadedFile.getUploadUrl();\n' +
                            '\n' +
                            '        // transcript parameters\n' +
                            '        var transcriptParams = TranscriptParams.builder()\n' +
                            '                .audioUrl(fileUrl)\n' +
                            '                .build();\n' +
                            '\n' +
                            '        var transcript = client.transcripts().transcribe(transcriptParams);\n' +
                            '\n' +
                            '        if (transcript.getStatus() == TranscriptStatus.ERROR) {\n' +
                            '            throw new Exception("Transcript failed with error: " + transcript.getError().get());\n' +
                            '        }\n' +
                            '\n' +
                            '        System.out.println(transcript);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n' +
                            '\n'}
                    </SyntaxHighlighter>

                    Pentru a seta limba:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'var filePath = "D:\\\\work\\\\spring-ia\\\\src\\\\main\\\\resources\\\\usa.m4a";\n' +
                            'var uploadedFile = client.files().upload(new File(filePath));\n' +

                            '\n' +
                            'var params = TranscriptOptionalParams.builder()\n' +
                            '                .languageCode(TranscriptLanguageCode.RO)\n' +
                            '                .speechModel(SpeechModel.NANO)\n' +
                            '                .build();\n' +
                            '\n' +
                            'var transcript = client.transcripts().transcribe(uploadedFile, params);\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>RealTime</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.audio;\n' +
                            '\n' +
                            'import com.assemblyai.api.RealtimeTranscriber;\n' +
                            '\n' +
                            'import javax.sound.sampled.AudioFormat;\n' +
                            'import javax.sound.sampled.AudioSystem;\n' +
                            'import javax.sound.sampled.LineUnavailableException;\n' +
                            'import javax.sound.sampled.TargetDataLine;\n' +
                            '\n' +
                            'import static java.lang.Thread.interrupted;\n' +
                            '\n' +
                            'public class AudioStream {\n' +
                            '\n' +
                            '    public static void main(String... args) throws Exception {\n' +
                            '        var apiKey = "";\n' +
                            '        Thread thread = new Thread(() -> {\n' +
                            '            try {\n' +
                            '                RealtimeTranscriber realtimeTranscriber = RealtimeTranscriber.builder()\n' +
                            '                        .apiKey(apiKey )\n' +
                            '                        .sampleRate(16_000)\n' +
                            '                        .onSessionBegins(sessionBegins -> System.out.println(\n' +
                            '                                "Session opened with ID: " + sessionBegins.getSessionId()))\n' +
                            '                        .onPartialTranscript(transcript -> {\n' +
                            '                            if (!transcript.getText().isEmpty())\n' +
                            '                                System.out.println("Partial: " + transcript.getText());\n' +
                            '                        })\n' +
                            '                        .onFinalTranscript(transcript -> System.out.println("Final: " + transcript.getText()))\n' +
                            '                        .onError(err -> System.out.println("Error: " + err.getMessage()))\n' +
                            '                        .build();\n' +
                            '\n' +
                            '                System.out.println("Connecting to real-time transcript service");\n' +
                            '                realtimeTranscriber.connect();\n' +
                            '\n' +
                            '                System.out.println("Starting recording");\n' +
                            '                AudioFormat format = new AudioFormat(16_000, 16, 1, true, false);\n' +
                            '                // `line` is your microphone\n' +
                            '                TargetDataLine line = AudioSystem.getTargetDataLine(format);\n' +
                            '                line.open(format);\n' +
                            '                byte[] data = new byte[line.getBufferSize()];\n' +
                            '                line.start();\n' +
                            '                while (!interrupted()) {\n' +
                            '                    // Read the next chunk of data from the TargetDataLine.\n' +
                            '                    line.read(data, 0, data.length);\n' +
                            '                    realtimeTranscriber.sendAudio(data);\n' +
                            '                }\n' +
                            '\n' +
                            '                System.out.println("Stopping recording");\n' +
                            '                line.close();\n' +
                            '                System.out.println("Closing real-time transcript connection");\n' +
                            '                realtimeTranscriber.close();\n' +
                            '            } catch (LineUnavailableException e) {\n' +
                            '                throw new RuntimeException(e);\n' +
                            '\n' +
                            '            }\n' +
                            '        });\n' +
                            '        thread.start();\n' +
                            '\n' +
                            '        System.out.println("Press ENTER key to stop...");\n' +
                            '        System.in.read();\n' +
                            '        thread.interrupt();\n' +
                            '        System.exit(0);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n' +
                            '\n'}
                    </SyntaxHighlighter>

                    Poate afisa:
                    <SyntaxHighlighter>
                        {'Press ENTER key to stop...\n' +
                            'Connecting to real-time transcript service\n' +
                            'Starting recording\n' +
                            'Session opened with ID: e58c865f-3289-4934-bc8e-957fe0c40cf7\n' +
                            'Partial: my name is\n' +
                            'Partial: my name is jul\n' +
                            'Partial: my name is julian\n' +
                            'Partial: my name is julian what\n' +
                            'Partial: my name is julian what\'s your\n' +
                            'Partial: my name is julian what\'s your name\n' +
                            'Partial: my name is julian what\'s your name\n' +
                            'Final: My name is Julian. What\'s your name?'}
                    </SyntaxHighlighter>

               </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <a href={"https://www.youtube.com/watch?v=-NB1TdXf35Q&ab_channel=AssemblyAI"}>Real-time Speech To Text In Java - Transcribe From Microphone</a>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AssemblyAISpringIALlmContent;