import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ClientScopesMappersKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-client-scopes-mappers", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Mapari pentru scopuri de client</b>
                    <br/>
                    Maparile de client permit definirea de noi detalii ce se vor trasmite prin intermediul token-ului de acces.
                    <ul>
                        <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                        <li>se face click pe <b>Client Scopes</b> (meniul din stanga):</li>
                        <li>se alege tab-ul <b>Client Scopes</b> si apasa pe scopul de client numit <b>info</b>, apoi se selecteaza tab <b>Mappers</b>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l8.png'}/>
                            </div>
                        </li>
                        <li>se apasa pe butonul <b>Create</b> si va apare urmatorul formular:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l9.png'}/>
                            </div>
                            lista de <b>Mapper Type</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l10.png'}/>
                            </div>
                        </li>
                        <li>
                            1. vrem sa punem in token-ul de acces rolurile de real, intr-un atribut numit <b>authorities</b>, prin urmare:
                            <ul>
                                <li>name:authorities (nume mapper/mapare)</li>
                                <li><b>Mapper Type</b>:<b>User Realm Type</b></li>
                                <li><b>Token Claim Name</b>:authorities (numele atributului/cheii in token care va avea ca valoare rolurile de realm)</li>
                                <li>
                                    completam formularul dam <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l11.png'}/>
                                    </div>
                                </li>

                                <hr/>
                                <b>Observatie:</b>
                                <br/>
                                Acum, dupa ce am adaugat maparea <b>authorities</b> la clientul <b>liferay-portal-client</b>, daca testam:
                                <ul>
                                    <li><a href={"/keycloak/generate-token"}>Generare token</a></li>
                                </ul>
                                atunci vom regasi in token-ul de acces decodat:
                                <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                    {'"authorities": [\n' +
                                    '   "offline_access",\n' +
                                    '   "uma_authorization",\n' +
                                    '   "default-roles-vanilla-realm"\n' +
                                    ']'}
                                </SyntaxHighlighter>
                            </ul>
                        </li>

                        <li>
                            2. vrem sa punem in token-ul de acces numele de utilizator, intr-un atribut numit <b>user_name</b>, prin urmare:
                            <ul>
                                <li>name:username (nume mapper/mapare)</li>
                                <li><b>Mapper Type</b>:<b>User Property</b></li>
                                <li><b>Property</b>:username</li>
                                <li><b>Token Claim Name</b>:user_name (numele atributului/cheii in token care va avea ca valoare rolurile de realm)</li>
                                <li><b>Claim JSON Type</b>: String</li>
                                <li>
                                    completam formularul dam <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l12.png'}/>
                                    </div>
                                </li>

                                <hr/>
                                <b>Observatie:</b>
                                <br/>
                                Acum, dupa ce am adaugat maparea <b>username</b> la clientul <b>liferay-portal-client</b>, daca testam:
                                <ul>
                                    <li><a href={"/keycloak/generate-token"}>Generare token</a></li>
                                </ul>
                                atunci vom regasi in token-ul de acces decodat:
                                <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                    {'"user_name": "vanilla",'}
                                </SyntaxHighlighter>
                            </ul>
                        </li>

                        <li>
                            3. <b>audience claim</b> definește destinatarul vizat al token-ului de access
                            <ul>
                                <li>name:aud (nume mapper/mapare)</li>
                                <li><b>Mapper Type</b>:<b>Audience</b></li>
                                <li><b>Included Client Audience</b>:liferay-portal-client</li>
                                <li>
                                    completam formularul dam <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l13.png'}/>
                                    </div>
                                </li>

                                <hr/>
                                <b>Observatie:</b>
                                <br/>
                                Acum, dupa ce am adaugat maparea <b>aud</b> la clientul <b>liferay-portal-client</b>, daca testam:
                                <ul>
                                    <li><a href={"/keycloak/generate-token"}>Generare token</a></li>
                                </ul>
                                atunci vom regasi in token-ul de acces decodat:
                                <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                    {'"aud": [\n' +
                                    '    "liferay-portal-client",\n' +
                                    '    "account"\n' +
                                    ' ],'}
                                </SyntaxHighlighter>
                            </ul>
                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ClientScopesMappersKeycloakContent;