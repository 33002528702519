import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [
        {id:"java", url:"#", title:"Fundamente Java", subtitle:""},

        {id:"java-comments", url:"/java/comments", title:"Comentarii", subtitle:"", parent: "java"},
        {id:"java-identifiers", url:"/java/identifiers", title:"Identificatori", subtitle:"", parent: "java"},
        {id:"java-primitive-types", url:"/java/primitive-types", title:"Tipuri primitive", subtitle:"", parent: "java"},
        {id:"java-var", url:"/java/var", title:"Var (Local variable type inference)", subtitle:"", parent: "java"},
        {id:"java-operators", url:"/java/operators", title:"Operatori", subtitle:"", parent: "java"},
        {id:"java-numeric-promotion-rules", url:"/java/numeric-promotion-rules", title:"Reguli promovare numerica", subtitle:"", parent: "java"},
        {id:"java-decisions", url:"/java/decisions", title:"Instructiuni de control", subtitle:"", parent: "java"},
        {id:"java-strings", url:"/java/strings", title:"Clasa String", subtitle:"", parent: "java"},
        {id:"java-stringbuilder", url:"/java/stringbuilder", title:"Clasa StringBuilder", subtitle:"", parent: "java"},
        {id:"java-string-pool", url:"/java/string-pool", title:"String Pool", subtitle:"", parent: "java"},
        {id:"java-arrays", url:"/java/arrays", title:"Colectii: Vectori", subtitle:"", parent: "java"},
        {id:"java-arraylist", url:"/java/arraylist", title:"Colectii: ArrayList", subtitle:"", parent: "java"},
        {id:"java-sets", url:"/java/sets", title:"Colectii: Sets", subtitle:"", parent: "java"},
        {id:"java-maps", url:"/java/maps", title:"Colectii: Maps", subtitle:"", parent: "java"},
        {id:"java-math", url:"/java/math", title:"Clasa Math", subtitle:"", parent: "java"},
        {id:"java-lambdas", url:"/java/lambdas", title:"Lambda", subtitle:"", parent: "java"},
        {id:"java-methods", url:"/java/methods", title:"Metode", subtitle:"", parent: "java"},
        {id:"java-classes", url:"/java/classes", title:"Clase", subtitle:"", parent: "java"},
        {id:"java-modules", url:"/java/modules", title:"Module", subtitle:"", parent: "java"},
        {id:"java-record", url:"/java/record", title:"Record", subtitle:"", parent: "java"},

        {id:"java-advanced-root", url:"#", title:"Java - Avansat", subtitle:""},
        {id:"java-javap", url:"/java/javap", title:"Instrumentul: javap", subtitle:"", parent: "java-advanced-root"},
        {id:"java-scripting", url:"/java/scripting", title:"JEP 330: Interpretare si scripting", subtitle:"", parent: "java-advanced-root"},
        {id:"java-unnamed-classes", url:"/java/unnamed-classes", title:"JEP 445: Clase fără nume și metode principale ale instanțelor (previzualizare)", subtitle:"", parent: "java-advanced-root"},
        {id:"java-bytecode", url:"/java/bytecode", title:"Bytecode", subtitle:"", parent: "java-advanced-root"},
        {id:"java-debug", url:"/java/debug", title:"Debug: remote", subtitle:"", parent: "java-advanced-root"},
        {id:"java-profiler-visual-vm", url:"/java/profiler-visual-vm", title:"Profiler: VisualVM", subtitle:"", parent: "java-advanced-root"},
        {id:"java-profiler-jprofiler", url:"/java/profiler-jprofiler", title:"Profiler: JProfiler", subtitle:"", parent: "java-advanced-root"},
        {id:"java-jcmd", url:"/java/jcmd", title:"jcmd", subtitle:"", parent: "java-advanced-root"},
        {id:"java-thread-dump", url:"/java/thread-dump", title:"Thread Dump", subtitle:"", parent: "java-advanced-root"},
        {id:"java-head-dump", url:"/java/head-dump", title:"Head Dump", subtitle:"", parent: "java-advanced-root"},

        {id:"java-article", url:"#", title:"Java - Articole", subtitle:""},
        {id:"java-abstract-vs-interfaces", url:"/java/abstract-vs-interfaces", title:"Clase abstracte vs interfete", subtitle:"", parent: "java-article"},

        {id:"java-extra", url:"#", title:"Java - Extra", subtitle:""},
        {id:"java-ftp-client", url:"/java/ftp-client", title:"Client FTP", subtitle:"", parent: "java-extra"},
        {id:"java-audio-record", url:"/java/audio-record", title:"Audio Record", subtitle:"", parent: "java-extra"},
    ];

    static indexUrl = "/java/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Fundamente Java 11
                </div>

                <div>
                    <i>viața e prea scurtă pentru o cafea proastă</i> (habar n-am)
                    <br/>
                    <br/>
                </div>

                {TocHelper.display(IndexContent.content)}

            </div>
        );
    }
}

export default IndexContent;