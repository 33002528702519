import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MilvusSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-milvus-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Milvus</b>
                    <br/>
                    <br/>
                    Milvus este o baza de date pentru vectori, foarte scalabila intr-un cluster Kubernestes.

                    <br/>
                    Configurare Milvus pentru Docker:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'version: \'3.5\'\n' +
                            '\n' +
                            'services:\n' +
                            '  etcd:\n' +
                            '    container_name: milvus-etcd\n' +
                            '    image: quay.io/coreos/etcd:v3.5.5\n' +
                            '    environment:\n' +
                            '      - ETCD_AUTO_COMPACTION_MODE=revision\n' +
                            '      - ETCD_AUTO_COMPACTION_RETENTION=1000\n' +
                            '      - ETCD_QUOTA_BACKEND_BYTES=4294967296\n' +
                            '      - ETCD_SNAPSHOT_COUNT=50000\n' +
                            '    volumes:\n' +
                            '      - ${DOCKER_VOLUME_DIRECTORY:-.}/volumes/etcd:/etcd\n' +
                            '    command: etcd -advertise-client-urls=http://127.0.0.1:2379 -listen-client-urls http://0.0.0.0:2379 --data-dir /etcd\n' +
                            '    healthcheck:\n' +
                            '      test: ["CMD", "etcdctl", "endpoint", "health"]\n' +
                            '      interval: 30s\n' +
                            '      timeout: 20s\n' +
                            '      retries: 3\n' +
                            '\n' +
                            '  minio:\n' +
                            '    container_name: milvus-minio\n' +
                            '    image: minio/minio:RELEASE.2023-03-20T20-16-18Z\n' +
                            '    environment:\n' +
                            '      MINIO_ACCESS_KEY: minioadmin\n' +
                            '      MINIO_SECRET_KEY: minioadmin\n' +
                            '    ports:\n' +
                            '      - "9001:9001"\n' +
                            '      - "9000:9000"\n' +
                            '    volumes:\n' +
                            '      - ${DOCKER_VOLUME_DIRECTORY:-.}/volumes/minio:/minio_data\n' +
                            '    command: minio server /minio_data --console-address ":9001"\n' +
                            '    healthcheck:\n' +
                            '      test: ["CMD", "curl", "-f", "http://localhost:9000/minio/health/live"]\n' +
                            '      interval: 30s\n' +
                            '      timeout: 20s\n' +
                            '      retries: 3\n' +
                            '\n' +
                            '  standalone:\n' +
                            '    container_name: milvus-standalone\n' +
                            '    image: milvusdb/milvus:v2.3.0\n' +
                            '    command: ["milvus", "run", "standalone"]\n' +
                            '    security_opt:\n' +
                            '    - seccomp:unconfined\n' +
                            '    environment:\n' +
                            '      ETCD_ENDPOINTS: etcd:2379\n' +
                            '      MINIO_ADDRESS: minio:9000\n' +
                            '    volumes:\n' +
                            '      - ${DOCKER_VOLUME_DIRECTORY:-.}/volumes/milvus:/var/lib/milvus\n' +
                            '    healthcheck:\n' +
                            '      test: ["CMD", "curl", "-f", "http://localhost:9091/healthz"]\n' +
                            '      interval: 30s\n' +
                            '      start_period: 90s\n' +
                            '      timeout: 20s\n' +
                            '      retries: 3\n' +
                            '    ports:\n' +
                            '      - "19530:19530"\n' +
                            '      - "9091:9091"\n' +
                            '    depends_on:\n' +
                            '      - "etcd"\n' +
                            '      - "minio"\n' +
                            '\n' +
                            'networks:\n' +
                            '  default:\n' +
                            '    name: milvus'}
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'# Download the installation script\n' +
                            '$ curl -sfL https://raw.githubusercontent.com/milvus-io/milvus/master/scripts/standalone_embed.sh -o standalone_embed.sh\n' +
                            '\n' +
                            '# Start the Docker container\n' +
                            '$ bash standalone_embed.sh start'}
                    </SyntaxHighlighter>

                    Adaugare dependinta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'implementation \'org.springframework.ai:spring-ai-milvus-store-spring-boot-starter\''}
                    </SyntaxHighlighter>

                    Configurare Milvus in <i>application.properties</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'spring:\n' +
                            '  ai:\n' +
                            '    vectorstore:\n' +
                            '      milvus:\n' +
                            '        client:\n' +
                            '          host: "localhost"\n' +
                            '          port: 19530\n' +
                            '          username: "root"\n' +
                            '          password: "milvus"\n' +
                            '        databaseName: "default"\n' +
                            '        collectionName: "vector_store"\n' +
                            '        embeddingDimension: 1536\n' +
                            '        indexType: IVF_FLAT\n' +
                            '        metricType: COSINE'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import org.springframework.ai.document.Document;\n' +
                            'import org.springframework.ai.transformer.splitter.TextSplitter;\n' +
                            'import org.springframework.ai.transformer.splitter.TokenTextSplitter;\n' +
                            'import org.springframework.ai.vectorstore.SearchRequest;\n' +
                            'import org.springframework.ai.vectorstore.VectorStore;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            '\n' +
                            'import java.util.List;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class AIService {\n' +
                            '    @Autowired\n' +
                            '    VectorStore vectorStore;\n' +
                            '\n' +
                            '    public List<Document> loaddata() {\n' +
                            '        List<Document> documents = List.of(\n' +
                            '                new Document("HC a fost un calculator construit la ICE FELIX", Map.of("country", "Romania", "year", 1988)),\n' +
                            '                new Document("CIP a fost un calculator romanrsc construit la Electronica", Map.of("country", "Romania", "year", 1990))\n' +
                            '        );\n' +
                            '        TextSplitter textSplitter = new TokenTextSplitter();\n' +
                            '\n' +
                            '        for (Document document : documents) {\n' +
                            '            List<Document> splitteddocs = textSplitter.split(document);\n' +
                            '\n' +
                            '            try {\n' +
                            '                // Sleep for 1 second\n' +
                            '                vectorStore.add(splitteddocs);\n' +
                            '                System.out.println("Added document: " + document.getContent());\n' +
                            '                Thread.sleep(20000);\n' +
                            '            } catch (InterruptedException e) {\n' +
                            '                Thread.currentThread().interrupt();\n' +
                            '                throw new RuntimeException(e);\n' +
                            '            }\n' +
                            '        }\n' +
                            '        System.out.println("Transformed documents: " + documents);\n' +
                            '        return documents;\n' +
                            '    }\n' +
                            '\n' +
                            '    public List<Document> search(String query) {\n' +
                            '        List<Document> results = vectorStore.similaritySearch(SearchRequest.query(query).withTopK(3).withSimilarityThreshold(0.7));\n' +
                            '        return results;\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MilvusSpringIALlmContent;