import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class LCDArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-lcd", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. LCD (2x16)</b>
                    <br/>
                    <br/>

                    Ecranele LCD (Ecranele cu cristale lichide) sunt utilizate în aplicațiile pentru afișarea diferiților parametri și starea sistemului.
                    <br/>

                    LCD 16x2 este un dispozitiv cu 16 pini.
                    <br/>
                    LCD 16x2 afiseza informatia pe 2 rânduri, iar pe fiecare rand incap 16 caractere.

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_11.png'}/>
                    </div>
                    Pe unele placi pot fi marcate altfel, dar au acelasi rol; de exemplu:
                    <ul>
                        <li>
                            VSS = GND (Ground)
                        </li>
                        <li>
                            VCC = VDD (+5V)
                        </li>
                        <li>
                            VEE = VO (Contract Controll)
                        </li>
                        <li>
                            LED+ = BLA (LED+ 5V)
                        </li>
                        <li>
                            LED- = BLK (LED- Ground)
                        </li>
                    </ul>

                    Diagrama de conectare cu un Arduino:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_12.png'}/>
                    </div>

                    Observatie:
                    <ul>
                        <li>Este nevoie de un potentiometru de 10k omi (pentru a devini contrastul)</li>
                        <li>
                            Pentru potențiometru, conectați pinul din mijloc la pinul 3 (V0) al afișajului.
                            <br/>
                            Celelalte două, conectează-te la 5V și GND.
                            <br/>
                            Nu contează la ce pin merge 5V și la GND.
                        </li>
                    </ul>

                    <SyntaxHighlighter>
                        {' * Conectari\n' +
                            ' * -----------\n' +
                            ' *  LCD screen    |    Arduino Uno\n' +
                            ' *  -----------------------------\n' +
                            ' *      1  (Vss)  |      GND\n' +
                            ' *      2  (Vdd)  |      5V\n' +
                            ' *      3  (VO)   |      Potentiometer\n' +
                            ' *      4  (RS)   |      12\n' +
                            ' *      5  (RW)   |      GND\n' +
                            ' *      6  (E)    |      11\n' +
                            ' *      7  (D0)   |      Not connected\n' +
                            ' *      8  (D1)   |      Not connected\n' +
                            ' *      9  (D2)   |      Not connected\n' +
                            ' *      10 (D3)   |      Not connected\n' +
                            ' *      11 (D4)   |      5\n' +
                            ' *      12 (D5)   |      4\n' +
                            ' *      13 (D6)   |      3\n' +
                            ' *      14 (D7)   |      2\n' +
                            ' *      15 (A)    |      5V !! 3V\n' +
                            ' *      16 (K)    |      GND'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_14.png'}/>
                    </div>

                    Observatie:
                    <ul>
                        <li>pin 15 poate avea un rezistor de 220 omi!</li>
                    </ul>

                    Cod:
                    <SyntaxHighlighter>
                        {'#include <LiquidCrystal.h>\n' +
                            '\n' +
                            'LiquidCrystal lcd(12, 11, 5, 4, 3, 2); //mod 4-biti\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  // seteaza numarul de coloane si randuri: \n' +
                            '  lcd.begin(16, 2);\n' +
                            '  lcd.print("hello, world!");\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  // seteaza cursorul la colona 0, linia 1 (linia 2 de fapt)\n' +
                            '  lcd.setCursor(0, 1);\n' +
                            '  // afiseaza numarul de secunde\n' +
                            '  lcd.print(millis()/1000);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            LiquidCrystal lcd(12, 11, 5, 4, 3, 2):
                            <ul>
                                <li>
                                    pin RS -> pin 12 (pe arduino); se poate pune si pe A5
                                </li>
                                <li>
                                    pin E -> pin 11 (pe arduino); se poate pun si pe A4
                                </li>
                                <li>
                                    pin 11 (D4) -> pin 5 (pe arduino); (se poate pune si pe alt pin, ex: 6)
                                </li>
                                <li>
                                    pin 12 (D5) -> pin 4 (pe arduino); (se poate pune si pe alt pin, ex: 7)
                                </li>
                                <li>
                                    pin 13 (D6) -> pin 3 (pe arduino); (se poate pune si pe alt pin, ex: 8)
                                </li>
                                <li>
                                    pin 14 (D7) -> pin 2 (pe arduino); (se poate pune si pe alt pin, ex: 9)
                                </li>
                            </ul>
                        </li>
                        <li>
                            Alta functie utila ar putea fi:
                            <br/>
                            <SyntaxHighlighter>
                                {'lcd.setCursor()'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>

                    Mai exista o varianta de LCD cu I2C.

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_13.png'}/>
                    </div>

                    <SyntaxHighlighter>
                        {' * Conectari\n' +
                            ' * -----------\n' +
                            ' *  I2C backpack  |    Arduino Uno\n' +
                            ' *  -----------------------------\n' +
                            ' *      GND       |      GND\n' +
                            ' *      Vcc       |      5V\n' +
                            ' *      SDA       |      SDA or A4\n' +
                            ' *      SCL       |      SCL or A5'}
                    </SyntaxHighlighter>

                    Se instaleaza:
                    <ul>
                        <li>
                            LiquidCrystal I2C@1.1.2 - Frank de Bradander
                        </li>
                    </ul>

                    <SyntaxHighlighter>
                        {'#include <Wire.h>\n' +
                            '#include <LiquidCrystal_I2C.h>\n' +
                            '\n' +
                            'LiquidCrystal_I2C lcd(0x27, 16, 2);  // set the LCD address to 0x27 for a 16 chars and 2 line display\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  lcd.init();  // initialize the lcd\n' +
                            '  \n' +
                            '  lcd.backlight();\n' +
                            '  lcd.setCursor(0, 0);\n' +
                            '  lcd.print("Hello, world!");\n' +
                            '  lcd.setCursor(0, 1);\n' +
                            '  lcd.print("AKAUTZ");\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '}\n'}
                    </SyntaxHighlighter>


                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_15.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_16.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=zACmjwvbils&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=8&ab_channel=ScienceBuddies"}>*/}
                    {/*            PING Ultrasonic Distance Sensor and Arduino (Lesson #8)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=n-gJ00GTsNg&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=9&ab_channel=ScienceBuddies"}>*/}
                    {/*            HC-SR04 Ultrasonic Distance Sensor and Arduino (Lesson #9)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LCDArduinoContent;