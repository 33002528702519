import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateClientKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-client", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                            <b>Creare client</b>
                            <br/>
                            Clienții sunt aplicatii sau servicii care:
                            <ul>
                                <li>solicita Keycloak să <b>autentifice</b> un utilizator</li>
                                <li>doresc o solutie de tip <b>single sign-on</b> (conectare unică)</li>
                                <li>doresc doar să solicite <b>informații de identitate</b> sau un <b>token de acces</b>, astfel încât să poată invoca în siguranță alte servicii din rețea care sunt securizate de Keycloak</li>
                            </ul>
                            Pasii pentru crearea unui client nou:
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Clients</b> (meniul din stanga):
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-15.png'}/>
                                    </div>
                                </li>
                                <li>se face click pe butonul <b>Create</b> (colțul din dreapta sus al tabelului) </li>
                                <li>se completeaza formularul:
                                    <ul>
                                        <li><b>Client ID</b>: liferay-portal-client (identificatorul unic al clientului)</li>
                                        <li><b>Client Protocol</b>: openid-connect</li>
                                    </ul>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-16.png'}/>
                                    </div>
                                    Se apasa butonul <b>Save</b>. Apoi se completeaza urmatorul formular:
                                    <ul>
                                        <li><b>Client ID</b>: liferay-portal-client (identificatorul unic al clientului)</li>
                                        <li><b>Name</b>: Client OpenID Connect per Liferay Portal (descrierea clientului)</li>
                                        <li><b>Access Type</b>: <i>confidential</i></li>
                                        <li><b>Valid Redirect URIs</b>: http://localhost:8080/* (listă de URI-uri de redirecționare care trebuie considerate valide de Keycloak; sa presupunem ca pe <b>http://localhost:8080</b> este instanta de Liferay)</li>
                                    </ul>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-17.png'}/>
                                    </div>
                                    Se apasa butonul <b>Save</b>
                                </li>

                                <li> Apoi:
                                    <ul>
                                        <li>
                                            se merge in tab-ul <b>Credentials</b>
                                        </li>
                                        <li>
                                            se apasa pe butonul <b>Regenerate Secret</b> din dreptul campului <b>Secret</b>
                                        </li>
                                        <li>
                                            keycloak va generala, de exemplu, urmatorul <b>Client Secret</b> <i>0c778028-f03e-4bdd-b835-8ecb33653ea0</i>
                                        </li>
                                    </ul>

                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-18.png'}/>
                                    </div>

                                </li>

                                <li>
                                   Printre lista de clienți înregistrați pe Keycloak este disponibil si cel creat pentru integrare cu portalul Liferay (<b>liferay-portal-client</b>):
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-19.png'}/>
                                    </div>
                                </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateClientKeycloakContent;