import React from "react";

import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import IndexContentHelper from "./IndexContentHelper";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";
import {Helmet} from "react-helmet";
import {Link} from "react-router-dom";

class BaseContentPage extends React.Component {

    constructor(props, pageId, parentPage) {
        super(props);

        this.state = {
            pageId: pageId, // ex: "python-functions"
            parentPage: parentPage // ex: IndexContent
        };

      //  this.indexPage = IndexContentHelper.index(this.state.pageId, this.state.parentPage.content);
        this.currentPage = IndexContentHelper.current(this.state.pageId, this.state.parentPage.content);
        this.prevPage = IndexContentHelper.prev(this.state.pageId, this.state.parentPage.content);
        this.nextPage = IndexContentHelper.next(this.state.pageId, this.state.parentPage.content);

        this.chapter = IndexContentHelper.chapter(this.state.pageId, this.state.parentPage.content);
        this.chapterPrev = IndexContentHelper.chapterByItem(this.prevPage, this.state.parentPage.content);
        this.chapterNext = IndexContentHelper.chapterByItem(this.nextPage, this.state.parentPage.content);
    }

    title(){
        return (
            <span>
                <Helmet>
                    <title>{this.chapter}. {this.currentPage.title}</title>
                </Helmet>

                <div className={"title"}>
                    {this.chapter}. {this.currentPage.title}
                </div>
            </span>
        )
    }
    navigator() {

        return (
            <div className="navivator">
                <div className={"row"}>
                    <div className={"col-sm text-left"}>
                        {this.prevPage ?
                            <Link to={this.prevPage.url}><FontAwesomeIcon icon={faArrowLeft}/> {this.chapterPrev}. {this.prevPage.title}</Link>
                            : <span/>
                        }
                    </div>
                    <div className={"col-sm"}>
                        <Link to={this.state.parentPage.indexUrl}>[ cuprins ]</Link>
                    </div>
                    <div className={"col-sm text-right"}>
                        {this.nextPage ?
                            <Link to={this.nextPage.url}>{this.chapterNext}. {this.nextPage.title} <FontAwesomeIcon icon={faArrowRight}/></Link>
                            : <span/>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default BaseContentPage;
