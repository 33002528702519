import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ConfigurationInstanceSettingsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-configuration-instance-settings", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Se creaza un nou modul: <i>ibrid-repository-configuration</i></b>

                    <hr/>
                    <b>1. Creare interfata de configurare: ExtendedObjectClassDefinition + Meta.OCD</b>
                    <br/>
                    <br/>

                    Scopuri:
                    <ul>
                        <li>
                            SYSTEM (Valorile de configurare se aplică în întregul sistem)
                        </li>
                        <li>
                            COMPANY (Un set de valori de configurare este stocat pentru fiecare instanță virtuală, astfel încât fiecare instanță poate fi configurată individual)
                        </li>
                        <li>
                            GROUP ( Fiecare grup poate fi configurat individual)
                        </li>
                        <li>
                            PORTLET_INSTANCE (aceasta se referă la aplicațiile care pot fi plasate pe o pagină ca widget. Fiecare widget poate fi configurat individual)
                        </li>
                    </ul>

                    Atunci când se creaza o interfață de configurare, o interfață de utilizare este generată în:
                    <br/>
                    <b>Setările sistemului / System Settings</b>.
                    <br/>
                    Dacă configurația se limitează la <b>COMPANY, GROUP sau PORTLET_INSTANCE</b>,
                    o interfață de utilizare suplimentară este generată în
                    <br/>
                    <b>Setări de instanță / Instance Settings</b>
                    <br/>
                    <br/>
                    Când înregistrați o interfață de configurare, o interfață de utilizare este generată automat pentru aceasta în:
                    <br/>
                    <b>Setări sistem → Platformă → Terță parte</b> / <b>System Settings → Platform → Third Party</b>
                    <br/>
                    Aceasta este locația implicită.

                    <br/>


                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.repository.configuration;\n' +
                            '\n' +
                            'import aQute.bnd.annotation.metatype.Meta;\n' +
                            'import com.liferay.portal.configuration.metatype.annotations.ExtendedObjectClassDefinition;\n' +
                            'import ro.ibrid.repository.configuration.constants.IbridRepositoryConfigPortletKeys;\n' +
                            '\n' +
                            '@ExtendedObjectClassDefinition(\n' +
                            '\t\tcategory = "ibrid.repository.configuration", // _CATEGORY_KEY din IbridRepositoryConfigurationCategory\n' +
                            '\t\tscope = ExtendedObjectClassDefinition.Scope.SYSTEM\n' +
                            ')\n' +
                            '@Meta.OCD(\n' +
                            '\t\tid = IbridRepositoryConfigPortletKeys.IBRID_REPOSITORY_CONFIG,\n' +
                            '\t\tlocalization = "content/Language", \n' +
                            '\t\tname = "ibrid-repository-config-title"\n' +
                            ')\n' +
                            'public interface IbridRepositoryConfig {\n' +
                            '\n' +
                            '\t@Meta.AD(\n' +
                            '\t\t\tdeflt = "192.168.1.7",\n' +
                            '\t\t\trequired = true,\n' +
                            '\t\t\tdescription = "config.field.description.remoteHost",\n' +
                            '\t\t\tname = "config.field.label.remoteHost"\n' +
                            '\n' +
                            '\t)\n' +
                            '\tString remoteHost();\n' +
                            '\n' +
                            '\t@Meta.AD(\n' +
                            '\t\t\tdeflt = "sftp_kj",\n' +
                            '\t\t\trequired = true,\n' +
                            '\t\t\tdescription = "config.field.description.username",\n' +
                            '\t\t\tname = "config.field.label.username"\n' +
                            '\n' +
                            '\t)\n' +
                            '\tString username();\n' +
                            '\n' +
                            '\t@Meta.AD(\n' +
                            '\t\t\tdeflt = "test",\n' +
                            '\t\t\trequired = false,\n' +
                            '\t\t\tdescription = "config.field.description.password",\n' +
                            '\t\t\tname = "config.field.label.password",\n' +
                            '\t\t\ttype = Meta.Type.Password\n' +
                            '\n' +
                            '\t)\n' +
                            '\tString password();\n' +
                            '\n' +
                            '\t@Meta.AD(\n' +
                            '\t\t\tdeflt = "sftp_kj",\n' +
                            '\t\t\trequired = false,\n' +
                            '\t\t\tdescription = "config.field.description.remoteDir",\n' +
                            '\t\t\tname = "config.field.label.remoteDir"\n' +
                            '\n' +
                            '\t)\n' +
                            '\tString remoteDir();\n' +
                            '\n' +
                            '\t@Meta.AD(\n' +
                            '\t\t\tdeflt = "",\n' +
                            '\t\t\trequired = false,\n' +
                            '\t\t\tdescription = "config.field.description.privateKeyPath",\n' +
                            '\t\t\tname = "config.field.label.privateKeyPath"\n' +
                            '\n' +
                            '\t)\n' +
                            '\tString privateKeyPath();\n' +
                            '\t\n' +
                            '}\n'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>
                            <b>@Meta.OCD</b> - inregistreaza aceasta intertfata ca o configurare cu un id specific
                            <br/>
                            ID-ul trebuie să fie numele complet calificat al clasei de configurare (IbridRespositoryPortletKeys.IBRIDREPOSITORY)
                        </li>
                        <li>
                            <b>Meta.AD</b> - specifică metadate
                            <br/>
                            <br/>
                            Se utilizeaza proprietatile:
                            <ul>
                                <li>
                                    <b>deflt</b> pentru a specifica o valoare implicită
                                </li>
                                <li>
                                   <b>required</b> - daca un camp este necesar (trebuie specificata o valoare implicita in acest caz)
                                </li>
                                <li>
                                    <b>type</b> - tipul campului (Meta.Type.Password)
                                </li>
                           </ul>
                        </li>
                    </ul>
                    Se poate specifica de exemplu o lista de optiuni pentru un camp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'@Meta.AD(deflt = "descriptive", \n' +
                            '\t\t\tname = "colors", \n' +
                            '\t\t\toptionLabels = { "%White", "%Red", "%Yellow" }, \n' +
                            '\t\t\toptionValues = {"white", "red", "yellow" }, \n' +
                            '\t\t\trequired = false\n' +
                            ')\n' +
                            'public String colors();'}
                    </SyntaxHighlighter>

                    <b>2. Creare categorie si sectiune</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.repository.configuration;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            'import com.liferay.configuration.admin.category.ConfigurationCategory;\n' +
                            '\n' +
                            '@Component(service = ConfigurationCategory.class)\n' +
                            'public class IbridRepositoryConfigurationCategory implements ConfigurationCategory {\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getCategoryIcon() {\n' +
                            '\t\treturn _CATEGORY_ICON;\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\t@Override\n' +
                            '\tpublic String getCategoryKey() {\n' +
                            '\t\treturn _CATEGORY_KEY;\n' +
                            '\t}\n' +
                            '\n' +
                            '\t@Override\n' +
                            '\tpublic String getCategorySection() {\n' +
                            '\t\treturn _CATEGORY_SECTION;\n' +
                            '\t}\n' +
                            '\t\n' +
                            '\tprivate static final String _CATEGORY_ICON = "cog";\n' +
                            '\n' +
                            '\tprivate static final String _CATEGORY_KEY = "ibrid.repository.configuration";\n' +
                            '\n' +
                            '\tprivate static final String _CATEGORY_SECTION = "ibrid";\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Se adauga in <b> Language.properties </b> (din<b>/src/main/resources/content/Language.properties</b>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'category-section.ibrid = Ibrid\n' +
                            'category.ibrid.repository.configuration = Configurare Arhiva\n' +
                            '\n' +
                            'ibrid-repository-config-title = Configurare Arhiva\n' +
                            '\n' +
                            'config.field.label.remoteHost=Remote Host\n' +
                            'config.field.description.remoteHost=Remote Host\n' +
                            '\n' +
                            'config.field.label.username=Username\n' +
                            'config.field.description.username=Username\n' +
                            '\n' +
                            'config.field.label.password=Password\n' +
                            'config.field.description.password=Password\n' +
                            '\n' +
                            'config.field.label.remoteDir=Remote Dir\n' +
                            'config.field.description.remoteDir=Remote Dir\n' +
                            '\n' +
                            'config.field.label.privateKeyPath=Private Key Path\n' +
                            'config.field.description.privateKeyPath=Private Key Path'}
                    </SyntaxHighlighter>

                    Clasa cu constante IbridRepositoryConfigPortletKeys (ro.ibrid.repository.configuration.constants):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.repository.configuration.constants;\n' +
                            '\n' +
                            'public class IbridRepositoryConfigPortletKeys {\n' +
                            '\n' +
                            '\tpublic static final String IBRID_REPOSITORY_CONFIG =\n' +
                            '\t\t\t"ro.ibrid.repository.configuration.IbridRepositoryConfig";\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Pentru Liferay DXP 7.4 U51+ și Liferay Portal 7.4 GA51+, <b>ConfigurationBeanDeclaration</b> nu mai este necesară!
                            <br/>
                            Exemplu:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'@Component(\n' +
                                    '\t\timmediate = true, \n' +
                                    '\t\tservice = ConfigurationBeanDeclaration.class)\n' +
                                    '\n' +
                                    'public class RepositoryConfigurationBeanDeclaration implements ConfigurationBeanDeclaration {\n' +
                                    '\n' +
                                    '\t@Override\n' +
                                    '\tpublic Class<?> getConfigurationBeanClass() {\n' +
                                    '\t\treturn RepositoryConfiguration.class;\n' +
                                    '\t}\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    init.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {
                            '<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                            '\n' +
                            '<liferay-theme:defineObjects />\n' +
                            '\n' +
                            '<portlet:defineObjects />'
                        }
                    </SyntaxHighlighter>

                    view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/init.jsp" %>\n'}
                    </SyntaxHighlighter>

                    bnd.bnd:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: ibrid-repository-configuration\n' +
                            'Bundle-SymbolicName: ro.ibrid.repository.configuration\n' +
                            'Bundle-Version: 1.0.0\n' +
                            'Export-Package: \\\n' +
                            '\tro.ibrid.repository.configuration.constants,\\\n' +
                            '\tro.ibrid.repository.configuration\n'}
                    </SyntaxHighlighter>

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\tcompileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '\n' +
                            '\tcssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.0.2"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/img_2.png'}/>
                    </div>

                    <hr/>
                    Exemplu de folosire (daca scopul este <b>SYSTEM</b>):

                    build.gradle:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'compile project(path: \':modules:ibrid-repository-configuration\')'}
                    </SyntaxHighlighter>

                    in portletul nostru:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.repository.portlet;\n' +
                            '\n' +
                            'import com.liferay.portal.configuration.metatype.bnd.util.ConfigurableUtil;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.portlet.PortletResponseUtil;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                            'import com.liferay.portal.kernel.servlet.HttpHeaders;\n' +
                            'import com.liferay.portal.kernel.util.ContentTypes;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import org.osgi.service.component.annotations.Activate;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Modified;\n' +
                            'import ro.ibrid.repository.configuration.IbridRepositoryConfig;\n' +
                            'import ro.ibrid.repository.configuration.constants.IbridRepositoryConfigPortletKeys;\n' +
                            'import ro.ibrid.repository.constants.IbridRespositoryPortletKeys;\n' +
                            'import ro.ibrid.repository.service.RepositoryScanService;\n' +
                            'import ro.ibrid.repository.servicebuilder.model.RepositoryMetadata;\n' +
                            'import ro.ibrid.repository.servicebuilder.service.RepositoryMetadataLocalServiceUtil;\n' +
                            '\n' +
                            'import javax.portlet.*;\n' +
                            'import java.io.File;\n' +
                            'import java.io.IOException;\n' +
                            'import java.nio.file.Files;\n' +
                            'import java.nio.file.Paths;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author IulianB\n' +
                            ' */\n' +
                            '@Component(\n' +
                            '        configurationPid = IbridRepositoryConfigPortletKeys.IBRID_REPOSITORY_CONFIG,\n' +
                            '        immediate = true,\n' +
                            '        property = {\n' +
                            '                "com.liferay.portlet.display-category=category.sample",\n' +
                            '                "com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                            '                "com.liferay.portlet.instanceable=true",\n' +
                            '                "javax.portlet.display-name=IbridRepository",\n' +
                            '                "javax.portlet.init-param.template-path=/",\n' +
                            '                "javax.portlet.init-param.view-template=/view.jsp",\n' +
                            '                "javax.portlet.name=" + IbridRespositoryPortletKeys.IBRIDREPOSITORY,\n' +
                            '                "javax.portlet.resource-bundle=content.Language",\n' +
                            '                "javax.portlet.security-role-ref=power-user,user"\n' +
                            '        },\n' +
                            '        service = Portlet.class\n' +
                            ')\n' +
                            'public class IbridRepositoryPortlet extends MVCPortlet {\n' +
                            '\n' +
                            '    private Log log = LogFactoryUtil.getLog(this.getClass().getName());\n' +
                            '\n' +
                            '    private volatile IbridRepositoryConfig _configuration;\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    @Modified\n' +
                            '    protected void activate(Map<String, Object> properties) {\n' +
                            '        _configuration = ConfigurableUtil.createConfigurable(IbridRepositoryConfig.class, properties);\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void render(RenderRequest request, RenderResponse response)\n' +
                            '            throws IOException, PortletException {\n' +
                            '\n' +
                            '//        try {\n' +
                            '//            IbridRepositoryConfig config = ConfigurationProviderUtil.getSystemConfiguration(IbridRepositoryConfig.class);\n' +
                            '//            String remoteHost = GetterUtil.get(config.remoteHost(), "");\n' +
                            '//            String username= GetterUtil.get(config.username(), "");\n' +
                            '//\n' +
                            '//            System.out.println("remoteHost     ----------->" + remoteHost);\n' +
                            '//            System.out.println("username       ----------->" + username);\n' +
                            '//\n' +
                            '//        } catch (ConfigurationException e1) {\n' +
                            '//            log.warn("Unable to fetch system configuration for Ibrid Repository");\n' +
                            '//        }\n' +
                            '\n' +
                            '\n' +
                            '        System.out.println("username:"+_configuration.username());\n' +
                            '\n' +
                            '        super.render(request, response);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Ce e de retinut:
                    <ul>
                        <li>
                            <SyntaxHighlighter>
                                {' configurationPid = IbridRepositoryConfigPortletKeys.IBRID_REPOSITORY_CONFIG,'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter>
                                {'  private volatile IbridRepositoryConfig _configuration;\n' +
                                    '\n' +
                                    '    @Activate\n' +
                                    '    @Modified\n' +
                                    '    protected void activate(Map<String, Object> properties) {\n' +
                                    '        _configuration = ConfigurableUtil.createConfigurable(IbridRepositoryConfig.class, properties);\n' +
                                    '    }'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>
                    Pentru a obine configuratiaa, se utilizeaza <b>ConfigurationProvider</b>.
                    API-ul Configuration Provider oferă o modalitate ușoară de a accesa configurația. Se alege metoda specifică:
                    <ul>
                        <li>
                            <b>getSystemConfiguration()</b>:
                            <br/>
                            Obține configurația la nivelul sistemului.
                            Configurația la nivel de sistem a aplicației se găsește în:
                            <br/>
                            <b>Panoul de control → Configurare → Setări sistem</b>

                        </li>
                        <li>
                            <b>getCompanyConfiguration()</b>:
                            <br/>
                            Obține configurația în domeniul instanței.
                            Utilizați o instanță de Portal pentru a prelua companyId.
                            De exemplu, configurația la nivel de instanță a aplicației se găsește în:
                            <br/>
                            <b>Panoul de control → Configurare → Setări</b>
                            <br/>

                            instanță ._portal.getCompanyId(renderRequest)
                        </li>
                        <li>
                            <b>getGroupConfiguration()</b>:
                            <br/>
                            Obține configurația la nivelul site-ului.
                            <br/>
                            Utilizați o instanță de Portal pentru a prelua groupId.
                            De exemplu, configurația la nivel de site a aplicației se găsește în
                            <b>Meniu produs → Configurare → Setări site</b>.
                            Acest lucru este disponibil în Liferay 7.4._portal.getScopeGroupId(renderRequest)
                        </li>
                    </ul>
                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <div>*/}
                    {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360029122551-Overriding-Global-Language-Keys"}>*/}
                    {/*            Overriding Global Language Keys*/}
                    {/*        </a>*/}
                    {/*    </div>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default ConfigurationInstanceSettingsLiferayContent;