import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class VectorStoreSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-vector-store-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Stocare vector in
                    <ul>
                        <li>PGVector (PostgresSQL)</li>
                        <li>Croma db (in Docker)</li>
                        <li>Redis (in Docker)</li>
                        <li>
                            MongoDB (Atlas in Google Cloud)
                        </li>
                        <li>
                            Cassandra (in Docker)
                        </li>
                        <li>
                            Neo4J (in Docker)
                        </li>
                        <li>
                            Oracle (in Docker)
                        </li>
                        <li>
                            Milvus (in Docker)
                        </li>
                        <li>
                            Typesense  (in Docker)
                        </li>
                        <li>
                            Weaviate (in Docker)
                        </li>
                        <li>
                            Odrant (in Docker)
                        </li>
                        <li>
                            Opensearch (in Docker)
                        </li>
                        <li>
                            Elastic Search (in Docker)
                        </li>
                    </ul>

                    <hr/>
                    <b>1. PGVector (PostgresSQL)</b>
                    <br/>
                    <br/>

                    Rulare in PG:
                    <SyntaxHighlighter>
                        {'CREATE EXTENSION IF NOT EXISTS vector;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'CREATE EXTENSION IF NOT EXISTS hstore;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'CREATE EXTENSION IF NOT EXISTS "uuid-ossp";'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'CREATE TABLE IF NOT EXISTS vector_store (\n' +
                            '\tid uuid DEFAULT uuid_generate_v4() PRIMARY KEY\n' +
                            '\tcontent text\n' +
                            '\tmetadata json\n' +
                            '\tembedding vector(1536)\n' +
                            ')'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter>
                        {'CREATE INDEX ON vector_store USING HNSW (embedding vector_cosine_ops)'}
                    </SyntaxHighlighter>

                    Citire fisier si insearare in baza de date (e o metoda foarte lenta, 1 minut per document):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {"import org.springframework.ai.document.Document;\n" +
                            "import org.springframework.ai.transformer.splitter.TextSplitter;\n" +
                            "import org.springframework.ai.transformer.splitter.TokenTextSplitter;\n" +
                            "import org.springframework.ai.vectorstore.VectorStore;\n" +
                            "import org.springframework.beans.factory.annotation.Value;\n" +
                            "import org.springframework.boot.CommandLineRunner;\n" +
                            "import org.springframework.boot.SpringApplication;\n" +
                            "import org.springframework.boot.autoconfigure.SpringBootApplication;\n" +
                            "import org.springframework.core.io.Resource;\n" +
                            "\n" +
                            "import java.nio.file.Files;\n" +
                            "import java.util.List;\n" +
                            "import java.util.stream.Collectors;\n" +
                            "\n" +
                            "@SpringBootApplication\n" +
                            "public class SpringaiPgvectorInsertApplication implements CommandLineRunner {\n" +
                            "\n" +
                            "    @Value(\"classpath:input.txt\")\n" +
                            "    Resource resource;\n" +
                            "\n" +
                            "    private final VectorStore vectorStore;\n" +
                            "\n" +
                            "    public SpringaiPgvectorInsertApplication(VectorStore vectorStore) {\n" +
                            "        this.vectorStore = vectorStore;\n" +
                            "    }\n" +
                            "\n" +
                            "    public static void main(String[] args) {\n" +
                            "        SpringApplication.run(SpringaiPgvectorInsertApplication.class, args);\n" +
                            "    }\n" +
                            "\n" +
                            "    @Override\n" +
                            "    public void run(String... args) throws Exception {\n" +
                            "        List<Document> documents = Files.lines(resource.getFile().toPath())\n" +
                            "                .map(Document::new)\n" +
                            "                .collect(Collectors.toList());\n" +
                            "\n" +
                            "        TextSplitter textSplitter = new TokenTextSplitter();\n" +
                            "        for(Document document : documents) {\n" +
                            "            List<Document> splitteddocs = textSplitter.split(document);\n" +
                            "            System.out.println(\"before adding document: \" + document.getContent());\n" +
                            "            vectorStore.add(splitteddocs);\n" +
                            "            System.out.println(\"Added document: \" + document.getContent());\n" +
                            "            Thread.sleep(61000);\n" +
                            "        }\n" +
                            "    }\n" +
                            "}"}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'spring.ai.vectorstore.pgvector.initialize-schema=true\n' +
                            'spring.ai.vectorstore.pgvector.index-type=HNSW\n' +
                            'spring.ai.vectorstore.pgvector.distance-type=COSINE_DISTANCE\n' +
                            'spring.ai.vectorstore.pgvector.dimensions=1536\n' +
                            'spring.ai.vectorstore.pgvector.schema-validation=true\n' +
                            'spring.ai.vectorstore.pgvector.remove-existing-vector-store-table=true'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'<dependency>\n' +
                            '            <groupId>org.springframework.ai</groupId>\n' +
                            '            <artifactId>spring-ai-pgvector-store-spring-boot-starter</artifactId>\n' +
                            '</dependency>'}
                    </SyntaxHighlighter>

                    Pentru a obtine rezultate:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {' List<Document> results = vectorStore.similaritySearch(SearchRequest.query(prompt).withTopK(10));\n' +
                            '        return results.stream()\n' +
                            '                .map(Document::getContent)\n' +
                            '                .limit(10)\n' +
                            '                .collect(Collectors.joining(", "));'}
                    </SyntaxHighlighter>

                    <hr/>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VectorStoreSpringIALlmContent;