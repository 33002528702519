import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaServiceBuilderLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-service-builder", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Service Builder</b>
                    <br/>
                    <br/>

                    {/*Relatie Many-To-One: O carte este publicata de o editura, dar 1 editura publica mai multe carti*/}

                    Se creaza modulul:
                    <ul>
                        <li>
                            agnes-service-builder

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_1.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_2.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_3.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_4.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_5.png'}/>
                            </div>

                        </li>
                    </ul>

                    <hr/>
                    Optional se poate muta modulul in alt modul:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_6.png'}/>
                    </div>

                    Trebuie modificata calea, in urma mutarii:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_7.png'}/>
                    </div>

                    <hr/>

                    <b>2. Generare modele si servicii</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_8.png'}/>
                    </div>

                    <b>service.xml</b> (modules/agnes-training/agnes-service-builder/agnes-service-builder-service/service.xml)
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                            '<!DOCTYPE service-builder PUBLIC "-//Liferay//DTD Service Builder 7.4.0//EN" "http://www.liferay.com/dtd/liferay-service-builder_7_4_0.dtd">\n' +
                            '\n' +
                            '<service-builder dependency-injector="ds" package-path="ro.agnes.service.builder">\n' +
                            '\n' +
                            '\t<author>KJ</author>\n' +
                            '\t<namespace>agnes</namespace>\n' +
                            '\n' +
                            '\t<!--<entity data-source="sampleDataSource" local-service="true" name="Foo" remote-service="false" session-factory="sampleSessionFactory" table="foo" tx-manager="sampleTransactionManager uuid="true"">-->\n' +
                            '\t<entity local-service="true" name="Book" remote-service="true" uuid="true">\n' +
                            '\n' +
                            '\t\t<!-- PK fields -->\n' +
                            '\n' +
                            '\t\t<column name="bookId" primary="true" type="long" />\n' +
                            '\n' +
                            '\n' +
                            '\n' +
                            '\t\t<!-- Audit fields -->\n' +
                            '\n' +
                            '\t\t<column name="companyId" type="long" />\n' +
                            '\t\t<column name="createDate" type="Date" />\n' +
                            '\t\t<column name="modifiedDate" type="Date" />\n' +
                            '\n' +
                            '\t\t<!-- Other fields -->\n' +
                            '\n' +
                            '\t\t<column name="title" type="String" />\n' +
                            '\t\t<column name="description" type="String" />\n' +
                            '\t\t<column name="publicationYear" type="int" />\n' +
                            '\t\t<column name="publishingHouseId" type="long" />\n' +
                            '\n' +
                            '\t\t<!-- Order -->\n' +
                            '\n' +
                            '\t\t<order by="asc">\n' +
                            '\t\t\t<order-column name="createDate" />\n' +
                            '\t\t</order>\n' +
                            '\n' +
                            '\t\t<!-- Finder methods -->\n' +
                            '\n' +
                            '\t\t<finder name="TitleAndPublicationYear" return-type="Collection">\n' +
                            '\t\t\t<finder-column name="title" />\n' +
                            '\t\t\t<finder-column name="publicationYear" />\n' +
                            '\t\t</finder>\n' +
                            '\n' +
                            '\n' +
                            '\t</entity>\n' +
                            '\n' +
                            '\t<entity local-service="true" name="Author" remote-service="true" uuid="true">\n' +
                            '\n' +
                            '\t\t<!-- PK fields -->\n' +
                            '\n' +
                            '\t\t<column name="authorId" primary="true" type="long" />\n' +
                            '\n' +
                            '\t\t<!-- Other fields -->\n' +
                            '\n' +
                            '\t\t<column name="firstName" type="String" />\n' +
                            '\t\t<column name="lastName" type="String" />\n' +
                            '\n' +
                            '\t\t<!-- Finder methods -->\n' +
                            '\n' +
                            '\t\t<finder name="FirstNameAndLastName" return-type="Collection">\n' +
                            '\t\t\t<finder-column name="firstName" />\n' +
                            '\t\t\t<finder-column name="lastName" />\n' +
                            '\t\t</finder>\n' +
                            '\n' +
                            '\t</entity>\n' +
                            '\n' +
                            '\t<entity local-service="true" name="PublishingHouse" remote-service="true" uuid="true">\n' +
                            '\n' +
                            '\t\t<!-- PK fields -->\n' +
                            '\n' +
                            '\t\t<column name="publishingHouseId" primary="true" type="long" />\n' +
                            '\n' +
                            '\t\t<!-- Other fields -->\n' +
                            '\n' +
                            '\t\t<column name="name" type="String" />\n' +
                            '\t\t<column name="lastName" type="String" />\n' +
                            '\n' +
                            '\t</entity>\n' +
                            '\n' +
                            '\t<entity local-service="true" name="Cover" remote-service="true" uuid="true">\n' +
                            '\n' +
                            '\t\t<!-- PK fields -->\n' +
                            '\n' +
                            '\t\t<column name="coverId" primary="true" type="long" />\n' +
                            '\t\t<!-- FK fields -->\n' +
                            '\n' +
                            '\t\t<column name="bookId" type="long" />\n' +
                            '\n' +
                            '\t\t<!-- Other fields -->\n' +
                            '\n' +
                            '\t\t<column name="creatorName" type="String" />\n' +
                            '\n' +
                            '\t\t<!-- Finder methods -->\n' +
                            '\n' +
                            '\t\t<finder name="BookId" return-type="Cover">\n' +
                            '\t\t\t<finder-column name="bookId" />\n' +
                            '\t\t</finder>\n' +
                            '\n' +
                            '\t</entity>\n' +
                            '\n' +
                            '\n' +
                            '</service-builder>'}
                    </SyntaxHighlighter>

                    Generare cu Gradle:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_9.png'}/>
                    </div>

                    va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'11:03:47: Executing \'buildService\'...\n' +
                            '\n' +
                            '\n' +
                            '> Task :modules:agnes-training:agnes-service-builder:agnes-service-builder-service:buildService\n' +
                            'Building Author\n' +
                            'Writing src\\main\\java\\ro\\agnes\\service\\builder\\service\\persistence\\impl\\AuthorPersistenceImpl.java\n' +
                            'Writing ..\\agnes-service-builder-api\\src\\main\\java\\ro\\agnes\\service\\builder\\service\\persistence\\AuthorPersistence.java\n' +
                            'Writing ..\\agnes-service-builder-api\\src\\main\\java\\ro\\agnes\\service\\builder\\service\\persistence\\AuthorUtil.java'}
                    </SyntaxHighlighter>

                    Fisier <b>tables.sql</b> generat
                    (modules/agnes-training/agnes-service-builder/agnes-service-builder-service/src/main/resources/META-INF/sql/tables.sql):
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'create table agnes_Author (\n' +
                            '\tuuid_ VARCHAR(75) null,\n' +
                            '\tauthorId LONG not null primary key,\n' +
                            '\tfirstName VARCHAR(75) null,\n' +
                            '\tlastName VARCHAR(75) null\n' +
                            ');\n' +
                            '\n' +
                            'create table agnes_Book (\n' +
                            '\tuuid_ VARCHAR(75) null,\n' +
                            '\tbookId LONG not null primary key,\n' +
                            '\tcompanyId LONG,\n' +
                            '\tcreateDate DATE null,\n' +
                            '\tmodifiedDate DATE null,\n' +
                            '\ttitle VARCHAR(75) null,\n' +
                            '\tdescription VARCHAR(75) null,\n' +
                            '\tpublicationYear INTEGER,\n' +
                            '\tpublishingHouseId LONG\n' +
                            ');\n' +
                            '\n' +
                            'create table agnes_Cover (\n' +
                            '\tuuid_ VARCHAR(75) null,\n' +
                            '\tcoverId LONG not null primary key,\n' +
                            '\tbookId LONG,\n' +
                            '\tcreatorName VARCHAR(75) null\n' +
                            ');\n' +
                            '\n' +
                            'create table agnes_PublishingHouse (\n' +
                            '\tuuid_ VARCHAR(75) null,\n' +
                            '\tpublishingHouseId LONG not null primary key,\n' +
                            '\tname VARCHAR(75) null,\n' +
                            '\tlastName VARCHAR(75) null\n' +
                            ');'}
                    </SyntaxHighlighter>


                    Fisierul <b>portlet-model-hints.xml</b> generat:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                            '\n' +
                            '<model-hints>\n' +
                            '\t<model name="ro.agnes.service.builder.model.Author">\n' +
                            '\t\t<field name="uuid" type="String" />\n' +
                            '\t\t<field name="authorId" type="long" />\n' +
                            '\t\t<field name="firstName" type="String" />\n' +
                            '\t\t<field name="lastName" type="String" />\n' +
                            '\t</model>\n' +
                            '\t<model name="ro.agnes.service.builder.model.Book">\n' +
                            '\t\t<field name="uuid" type="String" />\n' +
                            '\t\t<field name="bookId" type="long" />\n' +
                            '\t\t<field name="companyId" type="long" />\n' +
                            '\t\t<field name="createDate" type="Date" />\n' +
                            '\t\t<field name="modifiedDate" type="Date" />\n' +
                            '\t\t<field name="title" type="String" />\n' +
                            '\t\t<field name="description" type="String" />\n' +
                            '\t\t<field name="publicationYear" type="int" />\n' +
                            '\t\t<field name="publishingHouseId" type="long" />\n' +
                            '\t</model>\n' +
                            '\t<model name="ro.agnes.service.builder.model.Cover">\n' +
                            '\t\t<field name="uuid" type="String" />\n' +
                            '\t\t<field name="coverId" type="long" />\n' +
                            '\t\t<field name="bookId" type="long" />\n' +
                            '\t\t<field name="creatorName" type="String" />\n' +
                            '\t</model>\n' +
                            '\t<model name="ro.agnes.service.builder.model.PublishingHouse">\n' +
                            '\t\t<field name="uuid" type="String" />\n' +
                            '\t\t<field name="publishingHouseId" type="long" />\n' +
                            '\t\t<field name="name" type="String" />\n' +
                            '\t\t<field name="lastName" type="String" />\n' +
                            '\t</model>\n' +
                            '</model-hints>'}
                    </SyntaxHighlighter>


                    <hr/>

                    <b>3. Adaugare/modificare metode</b>
                    <br/>
                    <br/>

                    Daca adaugam urmatoare metoda in <b>BookLocalServiceImpl</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\tpublic Book add(String title, String description, long publicationYear,\n' +
                            '\t\t\t\t\tlong publishingHouseId, long companyId) {\n' +
                            '\n' +
                            '\t\treturn null;\n' +
                            '\t}'}
                    </SyntaxHighlighter>
                    si vom rula <b>buildService</b>, se vor auto genera noi bucati de cod in:
                    <ul>
                        <li>
                            BookLocalService.java

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'\tpublic Book add(\n' +
                                    '\t\tString title, String description, long publicationYear,\n' +
                                    '\t\tlong publishingHouseId, long companyId);'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            BookLocalServiceUtil
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'\tpublic static Book add(\n' +
                                    '\t\tString title, String description, long publicationYear,\n' +
                                    '\t\tlong publishingHouseId, long companyId) {\n' +
                                    '\n' +
                                    '\t\treturn getService().add(\n' +
                                    '\t\t\ttitle, description, publicationYear, publishingHouseId, companyId);\n' +
                                    '\t}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            BookLocalServiceWrapper
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'\t@Override\n' +
                                    '\tpublic ro.agnes.service.builder.model.Book add(\n' +
                                    '\t\tString title, String description, long publicationYear,\n' +
                                    '\t\tlong publishingHouseId, long companyId) {\n' +
                                    '\n' +
                                    '\t\treturn _bookLocalService.add(\n' +
                                    '\t\t\ttitle, description, publicationYear, publishingHouseId, companyId);\n' +
                                    '\t}'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            service.properties
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'    build.namespace=ro.agnes.service.builder.service\n' +
                                    '    build.number=3\n' +
                                    '    build.date=1725092768544'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_10.png'}/>
                    </div>

                    <hr/>
                    Se adauga in clasa <b>CoverLocalServiceImpl</b> metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\tpublic Cover getCover(long bookId){\n' +
                            '\t\treturn coverPersistence.fetchByBookId(bookId);\n' +
                            '\t}'}
                    </SyntaxHighlighter>
                    Si se da buildService.

                    <hr/>
                    Se poate adauga metoda:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'/**\n' +
                            ' * SPDX-FileCopyrightText: (c) 2024 Liferay, Inc. https://liferay.com\n' +
                            ' * SPDX-License-Identifier: LGPL-2.1-or-later OR LicenseRef-Liferay-DXP-EULA-2.0.0-2023-06\n' +
                            ' */\n' +
                            '\n' +
                            'package ro.agnes.service.builder.model.impl;\n' +
                            '\n' +
                            'import ro.agnes.service.builder.model.Cover;\n' +
                            'import ro.agnes.service.builder.service.CoverLocalServiceUtil;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author KJ\n' +
                            ' */\n' +
                            'public class BookImpl extends BookBaseImpl {\n' +
                            '\n' +
                            '    public Cover getCover(){\n' +
                            '        return CoverLocalServiceUtil.getCoverByBookId(getBookId());\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>


                    <hr/>

                    <b>4. Relatii</b>
                    <br/>
                    <br/>

                    <b>Many-to-many (tabela asociativa)</b>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/sb_11.png'}/>
                    </div>


                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\t<!-- Definirea entității intermediare pentru relația many-to-many -->\n' +
                            '\t<entity name="BookAuthorAsoc" table="agnes_asoc_BookAuthor" local-service="false" remote-service="false">\n' +
                            '\t\t<column name="bookId" type="long" primary="true" />\n' +
                            '\t\t<column name="authorId" type="long" primary="true" />\n' +
                            '\t</entity>'}
                    </SyntaxHighlighter>

                    O alta abordare, dar cu mai putin control este (* si nici nu genereaza ok tot ce trebuie):
                    <SyntaxHighlighter  showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<column name="authors"  entity="Author" mapping-table="asoc_Book_Author" name="authors" type="Collection"/>'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaServiceBuilderLiferayContent;