import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnatomyThemeContent  extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anatomy-theme", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    Temele generate cu <a href={"https://github.com/liferay/liferay-js-themes-toolkit/tree/master/packages"}> <b>Liferay JS Theme Toolkit</b> </a>
                    (care include si <b>Liferay Theme Generator</b>) au urmatoarea structura:

                    <ul>
                        <li>
                            [theme-name]
                            <ul>
                                <li>src/
                                   <ul>
                                       <li>css/
                                            <ul>
                                                <li>_clay_custom.scss</li>
                                                <li>_clay_variables.scss</li>
                                                <li>_custom.scss</li>
                                                <li>_liferay_variables_custom.scss</li>
                                            </ul>
                                       </li>
                                       <li>images/
                                           <ul>
                                              <li>(imagini)</li>
                                           </ul>
                                       </li>

                                       <li>js/
                                           <ul>
                                               <li>main.js</li>
                                           </ul>
                                       </li>
                                       <li>templates/

                                           <ul>
                                               <li>init_custom.ftl</li>
                                               <li>navigation.ftl</li>
                                               <li>portal_normal.ftl</li>
                                               <li>portal_pop_up.ftl</li>
                                               <li>portlet.ftl</li>
                                           </ul>

                                       </li>
                                       <li>WEB-INF/

                                           <ul>
                                               <li>liferay-look-and-feel.xml</li>
                                               <li>liferay-plugin-package.properties</li>
                                               <li>src/
                                                   <ul>
                                                       <li>resources-importer/
                                                           <ul>
                                                               <li>(multe directoare)</li>
                                                           </ul>
                                                       </li>
                                                   </ul>
                                               </li>

                                           </ul>



                                       </li>
                                   </ul>
                                </li>
                                <li>liferay-theme.json</li>
                                <li>package.json</li>
                            </ul>
                        </li>

                    </ul>

                    <b>liferay-look-and-feel.xml</b>
                    <br/>
                    Conține informații de bază pentru temă. <b>Setări de temă (sectiunea look-and-feel\theme\settings\)</b> sunt definite în acest fișier.

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<?xml version="1.0"?>\n' +
                        '<!DOCTYPE look-and-feel PUBLIC "-//Liferay//DTD Look and Feel 7.3.0//EN" "http://www.liferay.com/dtd/liferay-look-and-feel_7_3_0.dtd">\n' +
                        '\n' +
                        '<look-and-feel>\n' +
                        '\t<compatibility>\n' +
                        '\t\t<version>7.3.0+</version>\n' +
                        '\t</compatibility>\n' +
                        '\t<theme id="vanilla" name="Vanilla">\n' +
                        '\t\t<template-extension>ftl</template-extension>\n' +
                        '\n' +
                        '\t\t<settings>\n' +
                        '\t\t\t<setting configurable="true" key="show-footer" type="checkbox" value="true" />\n' +
                        '\t\t\t<setting configurable="true" key="show-header" type="checkbox" value="true" />\n' +
                        '\t\t</settings>\n' +
                        '\t\t\n' +
                        '\t\t<portlet-decorator id="barebone" name="Barebone">\n' +
                        '\t\t\t<portlet-decorator-css-class>portlet-barebone</portlet-decorator-css-class>\n' +
                        '\t\t</portlet-decorator>\n' +
                        '\t\t<portlet-decorator id="borderless" name="Borderless">\n' +
                        '\t\t\t<portlet-decorator-css-class>portlet-borderless</portlet-decorator-css-class>\n' +
                        '\t\t</portlet-decorator>\n' +
                        '\t\t<portlet-decorator id="decorate" name="Decorate">\n' +
                        '\t\t\t<default-portlet-decorator>true</default-portlet-decorator>\n' +
                        '\t\t\t<portlet-decorator-css-class>portlet-decorate</portlet-decorator-css-class>\n' +
                        '\t\t</portlet-decorator>\n' +
                        '\t</theme>\n' +
                        '</look-and-feel>\n'}
                    </SyntaxHighlighter>

                    Cele doua setari definite mai sus (<b>show-footer/show-header</b>) se vor vedea si in interfata contribuind la personalizarea temei:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/anatomy-theme-1.png'}/>
                    </div>

                    <br/>
                    <b>init_custom.ftl</b>
                    <br/>
                    Folosit pentru variabile personalizate FreeMarker, adică variabile de setare a temei (cele definite in <b>liferay-look-and-feel.xml</b>)

                    <SyntaxHighlighter showLineNumbers={true} language="ftl" style={androidstudio}>
                        {'<#--\n' +
                        'This file allows you to override and define new FreeMarker variables.\n' +
                        '-->\n' +
                        '\n' +
                        '<#assign\n' +
                        'show_footer = getterUtil.getBoolean(themeDisplay.getThemeSetting("show-footer"))\n' +
                        'show_header = getterUtil.getBoolean(themeDisplay.getThemeSetting("show-header"))\n' +
                        '/>'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>_custom.scss</b>
                    <br/>
                    Folosit pentru stiluri CSS personalizate. Ar trebui să plasați toate modificările CSS personalizate în acest fișier.

                    <SyntaxHighlighter showLineNumbers={true} language="css" style={androidstudio}>
                        {'/* These inject tags are used for dynamically creating imports for themelet styles, you can place them where ever you like in this file. */\n' +
                        '\n' +
                        '/* inject:imports */\n' +
                        '\n' +
                        '@import \'liferay-font-awesome/scss/font-awesome\';\n' +
                        '@import \'liferay-font-awesome/scss/glyphicons\';\n' +
                        '\n' +
                        '/* endinject */\n' +
                        '\n' +
                        '/* This file allows you to override default styles in one central location for easier upgrade and maintenance. */\n' +
                        '\n' +
                        'footer{\n' +
                        '  background: #0b2e13;\n' +
                        '  color:white;\n' +
                        '  margin-right: -15px;\n' +
                        '  margin-left:-15px;\n' +
                        '  padding: 10px;\n' +
                        '}'}
                    </SyntaxHighlighter>

                    <br/>
                    <b>portal_normal.ftl</b>
                    <br/>
                    Similar cu index.html al unui site static, acest fișier acționează ca un schelet/template pentru toate paginile site-ului.
                    <br/>In mod implicit are, urmatoarea structura:

                    <SyntaxHighlighter showLineNumbers={true} language="xml" style={androidstudio}>
                        {'<!DOCTYPE html>\n' +
                        '\n' +
                        '<#include init />\n' +
                        '\n' +
                        '<html class="${root_css_class}" dir="<@liferay.language key="lang.dir" />" lang="${w3c_language_id}">\n' +
                        '\n' +
                        '<head>\n' +
                        '\t<title>${html_title}</title>\n' +
                        '\n' +
                        '\t<meta content="initial-scale=1.0, width=device-width" name="viewport" />\n' +
                        '\n' +
                        '\t<@liferay_util["include"] page=top_head_include />\n' +
                        '</head>\n' +
                        '\n' +
                        '<body class="${css_class}">\n' +
                        '\n' +
                        '<@liferay_ui["quick-access"] contentId="#main-content" />\n' +
                        '\n' +
                        '<@liferay_util["include"] page=body_top_include />\n' +
                        '\n' +
                        '<@liferay.control_menu />\n' +
                        '\n' +
                        '<div class="container-fluid" id="wrapper">\n' +
                        '\t<header id="banner" role="banner">\n' +
                        '\t\t<div id="heading">\n' +
                        '\t\t\t<div aria-level="1" class="site-title" role="heading">\n' +
                        '\t\t\t\t<a class="${logo_css_class}" href="${site_default_url}" title="<@liferay.language_format arguments="${site_name}" key="go-to-x" />">\n' +
                        '\t\t\t\t\t<img alt="${logo_description}" height="${site_logo_height}" src="${site_logo}" width="${site_logo_width}" />\n' +
                        '\t\t\t\t</a>\n' +
                        '\n' +
                        '\t\t\t\t<#if show_site_name>\n' +
                        '\t\t\t\t\t<span class="site-name" title="<@liferay.language_format arguments="${site_name}" key="go-to-x" />">\n' +
                        '\t\t\t\t\t\t${site_name}\n' +
                        '\t\t\t\t\t</span>\n' +
                        '\t\t\t\t</#if>\n' +
                        '\t\t\t</div>\n' +
                        '\t\t</div>\n' +
                        '\n' +
                        '\t\t<#if !is_signed_in>\n' +
                        '\t\t\t<a data-redirect="${is_login_redirect_required?string}" href="${sign_in_url}" id="sign-in" rel="nofollow">${sign_in_text}</a>\n' +
                        '\t\t</#if>\n' +
                        '\n' +
                        '\t\t<#if has_navigation && is_setup_complete>\n' +
                        '\t\t\t<#include "${full_templates_path}/navigation.ftl" />\n' +
                        '\t\t</#if>\n' +
                        '\t</header>\n' +
                        '\n' +
                        '\t<section id="content">\n' +
                        '\t\t<h2 class="hide-accessible" role="heading" aria-level="1">${the_title}</h2>\n' +
                        '\n' +
                        '\t\t<#if selectable>\n' +
                        '\t\t\t<@liferay_util["include"] page=content_include />\n' +
                        '\t\t<#else>\n' +
                        '\t\t\t${portletDisplay.recycle()}\n' +
                        '\n' +
                        '\t\t\t${portletDisplay.setTitle(the_title)}\n' +
                        '\n' +
                        '\t\t\t<@liferay_theme["wrap-portlet"] page="portlet.ftl">\n' +
                        '\t\t\t\t<@liferay_util["include"] page=content_include />\n' +
                        '\t\t\t</@>\n' +
                        '\t\t</#if>\n' +
                        '\t</section>\n' +
                        '\n' +
                        '\t<footer id="footer" role="contentinfo">\n' +
                        '\t\t<p class="powered-by">\n' +
                        '\t\t\t<@liferay.language key="powered-by" /> <a href="http://www.liferay.com" rel="external">Liferay</a>\n' +
                        '\t\t</p>\n' +
                        '\t</footer>\n' +
                        '</div>\n' +
                        '\n' +
                        '<@liferay_util["include"] page=body_bottom_include />\n' +
                        '\n' +
                        '<@liferay_util["include"] page=bottom_include />\n' +
                        '\n' +
                        '</body>\n' +
                        '\n' +
                        '</html>'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360028833812-Theme-Anatomy-Reference-Guide"}>
                                    Theme Anatomy Reference Guide
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AnatomyThemeContent;