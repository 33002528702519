import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class RecordJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-record", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>

                    Recordurile au fost introduse în Java odată cu Java 14, ca o caracteristică în stadiul de previzualizare (preview),
                    iar ulterior au devenit complet stabile și oficiale începând cu Java 16 (martie 2021).

                    <br/>

                    Recordurile sunt o caracteristică sintactică ce permite crearea rapidă a unor clase imutabile cu un set fix de câmpuri.
                    Ele elimină necesitatea scrierii manuale a metodelor boilerplate precum:
                    <ul>
                        <li>
                            constructori
                        </li>
                        <li>
                            metoda toString()
                        </li>
                        <li>
                            metoda equals()
                        </li>
                        <li>metoda hashCode()</li>
                    </ul>


                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public record Point(int x, int y) {\n' +
                            '    // Constructori, toString, equals și hashCode sunt generați automat\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Caracteristici principale:
                    <ul>
                        <li>Immutable by design - Câmpurile unui record sunt finale; (n)u se mai poate modifica x sau y in exemplu de mai sus)</li>
                        <li>Metode auto-generate - toString, equals, și hashCode sunt generate implicit.</li>
                        <li>Construcție rapidă - Constructor implicit care inițializează toate câmpurile declarate.</li>
                    </ul>

                    <hr/>
                    Pentru exemplu de mai sus, inainte de java 14 trebuia scris asa:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public class Point {\n' +
                            '    private final int x;\n' +
                            '    private final int y;\n' +
                            '\n' +
                            '    public Point(int x, int y) {\n' +
                            '        this.x = x;\n' +
                            '        this.y = y;\n' +
                            '    }\n' +
                            '\n' +
                            '    public int getX() {\n' +
                            '        return x;\n' +
                            '    }\n' +
                            '\n' +
                            '    public int getY() {\n' +
                            '        return y;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public boolean equals(Object o) {\n' +
                            '        if (this == o) return true;\n' +
                            '        if (o == null || getClass() != o.getClass()) return false;\n' +
                            '        Point point = (Point) o;\n' +
                            '        return x == point.x && y == point.y;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public int hashCode() {\n' +
                            '        return Objects.hash(x, y);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String toString() {\n' +
                            '        return "Point{" + "x=" + x + ", y=" + y + \'}\';\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    Intr-un record:
                    <ul>
                        <li>se pot creea metode de instanta</li>
                        <li>se pot creea metode statice</li>
                        <li>se pot adauga campuri statice+final</li>
                        <li>NU se pot adauga campuri de instanta (se poate doar in definirea record-ului)</li>
                    </ul>

                    Un record:
                    <ul>
                        <li>
                            este final implicit, nu poate fi extins. Si nu poate sa extinda alte clase.
                        </li>
                        <li>
                            poate sa implementeze o interfata.
                        </li>
                        <li>
                            poate suprascrie constructorul implicit
                            <SyntaxHighlighter>
                                {'public Point(int x, int y){\n' +
                                    '....\n' +
                                    '}'}
                            </SyntaxHighlighter>
                            sau mai scurt:
                            <SyntaxHighlighter>
                                {'public Point{\n' +
                                    '....\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RecordJavaContent;