import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DeployModuleContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-deploy-module", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    <div><b>1. Deploy modul folosind plugin Liferay IntelliJ</b></div>
                    <ul>
                        <li>Se apasa click dreapta pe Liferay Workspace creat la pasul precedent ('liferay-vanilla'): <i>Liferay → Watch</i></li>

                        <li> In log-urile serverului se vor afisa informatii legate de deploy, de exemplu:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/deploy-module-1.png'}/>
                            </div>
                        </li>
                    </ul>


                    <div><b>2. Deploy dintr-un terminal (cmd/ps)</b></div>


                    <ul>
                        <li>cd .\modules\apps\hello-world\</li>
                        <li><i>gradle clean deploy</i></li>

                        <li> In log-urile serverului se vor afisa informatii legate de deploy, de exemplu:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/deploy-module-2.png'}/>
                            </div>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360017885612-IntelliJ-IDEA"}>
                                    https://help.liferay.com/hc/en-us/articles/360017885612-IntelliJ-IDEA
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DeployModuleContent;