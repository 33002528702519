import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class SchedulerLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-scheduler", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>


                    <b>Job Scheduler</b> este un framework construit pe motorul de planificare Liferay pentru rularea și programarea logicii de afaceri.
                    <br/>
                    Acest cadru folosește interfata <b>DispatchTaskExecutor</b> pentru a defini șabloane cu logică personalizată
                    ce pot fi folosite pentru a crea sarcini prin <b>Panoul de control</b>.
                    <br/>


                    <hr/>

                    <b>1. BaseDispatchTaskExecutor</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.ibrid.repository.schedule;\n' +
                            '\n' +
                            'import com.liferay.dispatch.executor.BaseDispatchTaskExecutor;\n' +
                            'import com.liferay.dispatch.executor.DispatchTaskExecutor;\n' +
                            'import com.liferay.dispatch.executor.DispatchTaskExecutorOutput;\n' +
                            'import com.liferay.dispatch.model.DispatchTrigger;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            'import java.util.Date;\n' +
                            '\n' +
                            '/*\n' +
                            'Control Panel ~ Job Scheduler ~ tab Job Scheduler Triggers ~ [+] si se alege "schedule-repo-scan"\n' +
                            '=> apoi se da un nume, de exemplu: "Schedule Repo Scan" si se da Save\n' +
                            '=> se poate configura din:\n' +
                            '    Control Panel ~ Job Scheduler ~ tab Job Scheduler Triggers\n' +
                            '    Edit pe Job, apoi click pe tab-ul "Job Scheduler Trigger" (exista 3 tab-uri: Details, Logs, Job Scheduler Triggers)\n' +
                            '    la Cron Expresion se poate scrie:\n' +
                            '    - 0/2 1/1 * 1/1 * ? * (sa ruleze la 2 secunde)\n' +
                            ' */\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "dispatch.task.executor.name=schedule-repo-scan", "dispatch.task.executor.type=schedule-repo-scan"\n' +
                            '        },\n' +
                            '        service = DispatchTaskExecutor.class\n' +
                            ')\n' +
                            'public class RepoScanDispatchTaskExecutor extends BaseDispatchTaskExecutor {\n' +
                            '\n' +
                            '    private static final Log log = LogFactoryUtil.getLog(RepoScanDispatchTaskExecutor.class);\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void doExecute(DispatchTrigger dispatchTrigger, DispatchTaskExecutorOutput dispatchTaskExecutorOutput) throws Exception {\n' +
                            '\n' +
                            '        if (log.isInfoEnabled()) {\n' +
                            '            log.info("Invoking #doExecute(DispatchTrigger, " + "DispatchTaskExecutorOutput)");\n' +
                            '        }\n' +
                            '\n' +
                            '        log.info(new Date());\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getName() {\n' +
                            '        return "schedule-repo-scan";\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Apoi trebuie configurat din UI:
                    <ul>
                        <li>
                            <b>Control Panel</b> ~ <b>Job Scheduler</b>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/job.png'}/>
                            </div>

                        </li>
                        <li>
                            Tab <b>Job Scheduler Triggers</b> ~ [+] si se alege "schedule-repo-scan"
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/job2.png'}/>
                            </div>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/job3.png'}/>
                            </div>
                        </li>
                        <li>
                            apoi se da un nume, de exemplu: "Schedule Repo Scan" si se da <b>Save</b>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/job4.png'}/>
                            </div>

                            si, dupa save:

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/job5.png'}/>
                            </div>

                        </li>
                        <li>
                            pentru a rula pe baza unui expresii cron:
                            <ul>
                                <li>
                                    <b>Control Panel ~ Job Scheduler ~</b> tab <b>Job Scheduler Triggers</b>
                                    <br/>
                                    Edit pe Job:
                                    <div style={{padding: 10}}>
                                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/job6.png'}/>
                                    </div>

                                    apoi click pe tab-ul <b>Job Scheduler Trigger</b> (exista 3 tab-uri: Details, Logs, Job Scheduler Triggers)
                                    <br/>
                                    Putem completa:
                                    <ul>
                                        <li>
                                            <b>Active</b>: pentru a fi activ job-ul [<b>bifata</b>]
                                        </li>
                                        <li>
                                            <b>Cron Expresion</b> se poate scrie:

                                            <ul>
                                                <li>
                                                    0/2 1/1 * 1/1 * ? * (sa ruleze la 2 secunde)
                                                </li>
                                                <li>
                                                    0 45 23 1/1 * ? * (zilnic la 23:45)
                                                </li>
                                            </ul>


                                        </li>
                                        <li>
                                            <b>Overlap Allowed</b> / Suprapunere permisă [<b>debifata</b>]
                                            <br/>
                                            se activeaza sau dezactiveza execuția concomitentă pentru sarcina Job Scheduler.
                                            <br/>
                                            Când este activată, execuțiile de sarcini noi sunt inițiate conform programului stabilit, indiferent dacă o execuție anterioară este încă în curs de desfășurare.
                                        </li>
                                        <li>
                                            <b>Never End</b> [<b>bifata</b>]
                                        </li>
                                    </ul>

                                </li>
                            </ul>
                        </li>
                    </ul>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/img_1.png'}/>
                    </div>

                    Se poate rula, si inainte de programare, adica 11:45 seara, prin apasare butonului <b>Run now</b>:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/job7.png'}/>
                    </div>

                    Dupa ce se apasa pe butonul <b>Run now</b> se va schimba statusul in <b>In progress</b>:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/job8.png'}/>
                    </div>

                    Dupa ce job-ul a terminat, se va schimba iar statusul (cu speranta) in <b>Successful</b>:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/job9.png'}/>
                    </div>

                    <hr/>
                    Alte expresii cron:
                    <ul>
                        <li>
                            0/2 1/1 * 1/1 * ? * // la fiecare 2 secunde
                        </li>
                        <li>
                            0 0/2 1/1 1/1 * ? * // la fiecare 2 minute
                        </li>
                        <li>
                            0 0 0/2 1/1 * ? * // la fiecare 2 ore
                        </li>

                        <li>
                            0 45 23 1/1 * ? * // zilnic la 23:45
                        </li>
                    </ul>


                    <hr/>
                    <b>Exemplu pentru Liferay 7.2</b>
                    <br/>
                    <br/>
                    Exemplu pentru Liferay 7.2:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.messaging.BaseMessageListener;\n' +
                            'import com.liferay.portal.kernel.messaging.DestinationNames;\n' +
                            'import com.liferay.portal.kernel.messaging.Message;\n' +
                            'import com.liferay.portal.kernel.scheduler.*;\n' +
                            'import com.liferay.portal.kernel.util.GetterUtil;\n' +
                            'import org.osgi.service.component.annotations.*;\n' +
                            '\n' +
                            '\n' +
                            'import java.util.Date;\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        // "cron.expression= 0 0 0 ? * * *"  // At 12:00 AM\n' +
                            '        // 0 0 0/2 1/1 * ? * // every 2 hours\n' +
                            '        // 0 0/1 1/1 1/1 * ? * // Every 1 min\n' +
                            '        property = {\n' +
                            '                "cron.expression=0 0 0/23 1/1 * ? *" // La fiecare 23 ore\n' +
                            '        },\n' +
                            '        service = TestScheduler.class\n' +
                            ')\n' +
                            'public class TestScheduler extends BaseMessageListener {\n' +
                            '\n' +
                            '    private static final Log log = LogFactoryUtil.getLog(InsScheduler.class);\n' +
                            '\n' +
                            '\n' +
                            '    @Override\n' +
                            '    protected void doReceive(Message message) {\n' +
                            '        \n' +
                            '    }\n' +
                            '\n' +
                            '    @Activate\n' +
                            '    @Modified\n' +
                            '    protected void activate(Map<String, Object> properties) throws SchedulerException {\n' +
                            '\n' +
                            '        try {\n' +
                            '            String cronExpression = GetterUtil.getString(properties.get("cron.expression"), "cronExpression");\n' +
                            '            log.info(" cronExpression: " + cronExpression);\n' +
                            '\n' +
                            '            String listenerClass = getClass().getName();\n' +
                            '            Trigger jobTrigger = TriggerFactoryUtil.createTrigger(listenerClass, listenerClass, new Date(), null, cronExpression);\n' +
                            '\n' +
                            '            SchedulerEntryImpl schedulerEntryImpl = new SchedulerEntryImpl();\n' +
                            '            schedulerEntryImpl.setEventListenerClass(listenerClass);\n' +
                            '            schedulerEntryImpl.setTrigger(jobTrigger);\n' +
                            '\n' +
                            '            SchedulerEngineHelperUtil.register(this, schedulerEntryImpl, DestinationNames.SCHEDULER_DISPATCH);\n' +
                            '\n' +
                            '        } catch (Exception e) {\n' +
                            '            log.error(e);\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    @Deactivate\n' +
                            '    protected void deactive() {\n' +
                            '        SchedulerEngineHelperUtil.unregister(this);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://help.liferay.com/hc/en-us/articles/360030416992-Friendly-URLs"}>*/}
                    {/*                Friendly URLs*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.opensource-techblog.com/how-to-create-friendly-url-for-liferay.html"}>*/}
                    {/*                How to create Friendly URL for Liferay portlet*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SchedulerLiferayContent;