import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"llm-root", url:"#", title:"Large language model (LLM)", subtitle:""},
        {id:"llm-intro", url:"/llm/intro", title:"Introducere", subtitle:"", parent:"llm-root"},
        {id:"llm-word-embedding", url:"/llm/word-embedding", title:"Transformes: Word Embedding (incorporarea cuvintelor)", subtitle:"", parent:"llm-root"},
        {id:"llm-ollama-pi5", url:"/llm/ollama-pi5", title:"Ollama - Raspberry Pi 5", subtitle:"", parent:"llm-root"},
        {id:"llm-ollama-windows", url:"/llm/ollama-windows", title:"Ollama - Windows", subtitle:"", parent:"llm-root"},
        {id:"llm-util", url:"/llm/util", title:"Util", subtitle:"", parent:"llm-root"},

        {id:"llm-spring-ia-root", url:"#", title:"Spring IA", subtitle:""},
        {id:"llm-create-project-spring-ia", url:"/llm/create-project-spring-ia", title:"Creare proiect Spring IA", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-openai-api-key-spring-ia", url:"/llm/openai-api-key-spring-ia", title:"Openai: Generare OPENAI_API_KEY", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-spring-ia", url:"/llm/spring-ia", title:"Openai: ChatClient, ChatModel", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-format-spring-ia", url:"/llm/format-spring-ia", title:"Openai: PromptTemplate, Formatare", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-structured-output-spring-ia", url:"/llm/structured-output-spring-ia", title:"Openai: Date structurate (List, Map, Object)", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-message-spring-ia", url:"/llm/message-spring-ia", title:"Tipuri de mesaje", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-advisors-spring-ia", url:"/llm/advisors-spring-ia", title:"Openai: Advisors", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-memory-spring-ia", url:"/llm/memory-spring-ia", title:"Openai: Memory", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-chat-options-spring-ia", url:"/llm/chat-options-spring-ia", title:"Openai: ChatOptions", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-stt-spring-ia", url:"/llm/stt-spring-ia", title:"Openai: Speech To Text", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-tts-spring-ia", url:"/llm/tts-spring-ia", title:"Openai: Text To Speech", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-image-spring-ia", url:"/llm/image-spring-ia", title:"Openai: Image Generation", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-vision-spring-ia", url:"/llm/vision-spring-ia", title:"Openai: Vision (descriere imagine)", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-functions-spring-ia", url:"/llm/functions-spring-ia", title:"Openai: Apeluri de functii", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-moderations-spring-ia", url:"/llm/moderations-spring-ia", title:"Openai: Moderation", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-prompt-engineering-spring-ia", url:"/llm/prompt-engineering-spring-ia", title:"Openai: Prompt Engineering", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-ollama-spring-ia", url:"/llm/ollama-spring-ia", title:"Ollama: ChatClient", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-mistral-spring-ia", url:"/llm/mistral-spring-ia", title:"Mistral IA", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-amazon-spring-ia", url:"/llm/amazon-spring-ia", title:"Amazon Bedrock", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-azure-spring-ia", url:"/llm/azure-spring-ia", title:"Azure Open IA", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-google-spring-ia", url:"/llm/google-spring-ia", title:"Google Vertex Gemini", subtitle:"", parent:"llm-spring-ia-root"},
        {id:"llm-assemblyai-spring-ia", url:"/llm/assemblyai-spring-ia", title:"Assembly AI", subtitle:"", parent:"llm-spring-ia-root"},

        {id:"llm-rag-root", url:"#", title:"Spring IA / RAG - Vector Store", subtitle:""},
        {id:"llm-rag-spring-ia", url:"/llm/rag-spring-ia", title:"Introducere RAG: SimpleVectorStore", subtitle:"", parent:"llm-rag-root"},
        {id:"llm-vector-store-spring-ia", url:"/llm/vector-store-spring-ia", title:"PGVector (PostgresSQL)", subtitle:"", parent:"llm-rag-root"},
        {id:"llm-chroma-db-spring-ia", url:"/llm/chroma-db-spring-ia", title:"Chroma DB (in Docker)", subtitle:"", parent:"llm-rag-root"},
        {id:"llm-redis-spring-ia", url:"/llm/redis-spring-ia", title:"Redis (in Docker)", subtitle:"", parent:"llm-rag-root"},
        {id:"llm-mongo-spring-ia", url:"/llm/mongo-spring-ia", title:"Mongo (in Google Cloud)", subtitle:"", parent:"llm-rag-root"},
        {id:"llm-milvus-spring-ia", url:"/llm/milvus-spring-ia", title:"Milvus", subtitle:"", parent:"llm-rag-root"},


    ]

    static indexUrl = "/llm/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Large language model (LLM)
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;