import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers2LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-2", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Contiunuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Definire Custom Fields</b>
                    <br/>
                    <br/>

                    Definirea unui custom field se face de fisierul <b>expando-columns.json</b> (creat in <b>site-initializer</b>):

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'[\n' +
                            '  {\n' +
                            '    "dataType": 15,\n' +
                            '    "modelResource": "com.liferay.portal.kernel.model.Layout",\n' +
                            '    "name": "Icon URL"\n' +
                            '  }\n' +
                            ']'}
                    </SyntaxHighlighter>

                    Constantele pentru <b>dataType</b> se gasesc in clasa <a target={"_blank"} href={"https://github.com/liferay/liferay-portal/blob/master/portal-kernel/src/com/liferay/expando/kernel/model/ExpandoColumnConstants.java"}>
                        <b>ExpandoColumnConstants</b>
                    </a>

                    <hr/>
                    <b>2. Definire documente</b>
                    <br/>
                    <br/>

                    Fisiere se pun in directorul:
                    <ul>
                        <li>
                            <b>site-initializer/documents/group</b>
                        </li>
                        <li>
                            <b>site-initializer/documents/company</b> (daca se vrea in contextul global)
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_12.png'}/>
                    </div>

                    Putem pot creaa si alte foldere în interior structurii de mai sus, iar structura se va repeta in <b>Documents and Library</b>

                    Dupa build, in sectiunea <b>Content & Data ~ Documents and Media</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_10.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_11.png'}/>
                    </div>

                    <hr/>
                    Din pacate, in mod implicit, daca se mai adauga/modifica o resursa (imagine, de exemplu: mare_2.jpg), trebuie sters site-ul si creat din nou!
                    <br/>
                    Dar, exista o caracteristica ascunsa care permite actualizarea unui  Inițializatoarele de site / Site Initializer (SI)!
                    <br/>
                    Suportul de actualizare pentru Inițializatoarele de site (SI) a fost implementat în <b>LPS-165482</b>, dar este încă ascuns in <b>Feature Flags</b>.
                    <br/>
                    Pentru activarea acestei caracteristici, se poate folosi una din variantele:
                    <ul>
                        <li>adaugare proprietate in <b>portal-ext.properties</b>:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'feature.flag.LPS-165482=true'}
                            </SyntaxHighlighter>
                            si restart server
                        </li>
                        <li>
                            Folosire UI (incepand cu 7.4 GA 69):<br/>
                            <b>Control Panel → Instance Settings →Platform → Feature Flags</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_13.png'}/>
                            </div>
                            <br/>
                            Activare <b>LPS-165482</b> din tab-ul <b>Developer</b>:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_14.png'}/>
                            </div>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/si_15.png'}/>
                            </div>
                        </li>
                    </ul>

                    <b>Observatie</b>:
                    <ul>
                        <li>
                            Daca site-ul a fost creat inainte de activarea <b>LPS-165482</b>, trebuie sters si creat din nou!
                        </li>
                    </ul>

                    Numai in contexul de mai sus va apare in mediul din stanga al site-ului initializat cu SI:
                    <b>Publishing ~ Site Initializer</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_16.png'}/>
                    </div>

                    Se apasa pe butonul <b>Synchronize</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_17.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers2LiferayContent;