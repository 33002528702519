import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class AccessRouterNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-access-router", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Accesare router Digi de la distanta</b>
                    <br/>
                    <br/>

                    Prezentam pasi care trebuie facuti pentru a ne putea conecta remote la un router Digi.
                    <br/>
                    Fie <b>Fiberhome AN5506-02-FG</b> echipamentul de tip ONT (convertor de fibra optica) folosit ca router.

                    <hr/>

                    <b>1.1. Configurare Dynamic DNS</b>
                    <br/>
                    <br/>

                    Configurarea Dynamic DNS se face din contul Digi Romania.
                    <br/>
                    Trebuie sa ne conectam la contul de pe <b>https://www.digi.ro/</b>.
                    <br/>
                    Dupa ce ne logam:
                    <ul>
                        <li>
                            <b>Serviciile Mele – DigiNet Fiberlink – DNS Dinamic</b>
                            <br/>
                            introducem numele dorit pentru domeniu, exemplu dristor13 (cu extensia .go.ro)
                            <br/>
                            salvam
                        </li>
                        <li>
                            <b>repornire router</b> (din interfata de administrare a routerului: <i>Management {"->"} Device Management {"->"} Device Reboot {"->"} Reboot</i>, sau un reset de la butonul fizic de pe echipament)
                        </li>
                    </ul>

                    <hr/>
                    <b>1.2. Configurare router</b>
                    <br/>
                    <br/>

                    <ul>
                        <li>Accesam: <b>http://192.168.1.1/login.html</b>
                            <br/>
                            Datele de autentificare:
                            <ul>
                                <li>useradmin: <b>user</b></li>
                                <li>password: <b>digi</b></li>
                            </ul>
                        </li>
                        <li><b>Application {"–>"} Port Forwarding  {"–>"}  Add </b>

                            <ul>
                                <li>WAN: 2_INTERNET_R_VID_201</li>
                                <li>Description: Router (sau alt text sugestiv)</li>
                                <li>Protocol: ALL</li>
                                <li>Public Port: <b>8000</b> - 8000</li>
                                <li>Private IP: <b>192.168.1.1</b></li>
                                <li>Private Port: <b>80</b> - 80</li>
                                <li>Enable: Enable</li>
                            </ul>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-4/img_1.png'}/>
                            </div>

                        </li>
                        <li>
                            De acum router-ul poate fi accesat si de adresa: <b>http://dristor13.go.ro:8000/login.html</b>
                            <br/>
                            (inainte se putea doar de pe http://192.168.1.1/login.html, echivalent cu http://192.168.1.1:<b>80</b>/login.html)

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-4/img.png'}/>
                            </div>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <a target={"_blank"} href={"https://www.media-max.ro/accesare-router-digi-de-la-distanta/"}>Accesare router Digi de la distanta</a>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AccessRouterNasContent;