import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OllamaWindowsLlmContent extends BaseContentPage  {

    constructor(props) {
        super(props, "llm-ollama-windows", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Descarcare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'https://ollama.com/download/windows'}
                    </SyntaxHighlighter>

                    <hr/>

                    Instalare:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_1.png'}/>
                    </div>

                    Prima interactiune:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_2.png'}/>
                    </div>

                    <hr/>
                    <b>Comenzi</b>
                    <br/>
                    <br/>

                    1. Afisare modele disponibile
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ollama list'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'ollama list\n' +
                            'NAME    ID    SIZE    MODIFIED'}
                    </SyntaxHighlighter>

                    2. Adaugare model (de exemplu: gemma:2b) din registry:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ollama pull gemma:2b'}
                    </SyntaxHighlighter>

                    Pe Windows modele sunt in:
                    <SyntaxHighlighter>
                        {
                            'C:\\Users\\%username%\\.ollama\\models'
                        }
                    </SyntaxHighlighter>

                    Pentru a modifica directorul de modele, pe Windows, se adauga in Variabile de mediu:
                    <SyntaxHighlighter>
                        {'OLLAMA_MODELS'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_3.png'}/>
                    </div>

                    Testare daca exista noul model:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ollama list'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'ollama list\n' +
                            'NAME        ID              SIZE      MODIFIED\n' +
                            'gemma:2b    b50d6c999e59    1.7 GB    46 seconds ago'}
                    </SyntaxHighlighter>

                    3. Rulare model:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ollama run gemma:2b'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'ollama run gemma:2b\n' +
                            '>>> what is IA?\n' +
                            'IA stands for **Artificial Intelligence**. It\'s a branch of computer science concerned with creating intelligent machines that can perform tasks typically requiring human intelligence, such as learning,\n' +
                            'problem-solving, and decision-making.\n' +
                            '\n' +
                            '>>> Send a message (/? for help)'}
                    </SyntaxHighlighter>

                    Pentru a iesi:
                    <SyntaxHighlighter>
                        {'/bye'}
                    </SyntaxHighlighter>

                    4. Stergere model:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ollama rm gemma:2b'}
                    </SyntaxHighlighter>

                    5. Afisare procese:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'ollama ps'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'ollama ps\n' +
                            'NAME        ID              SIZE      PROCESSOR    UNTIL\n' +
                            'gemma:2b    b50d6c999e59    2.9 GB    100% GPU     4 minutes from now'}
                    </SyntaxHighlighter>

                    6. Verificare Ollama din browser:
                    <SyntaxHighlighter>
                        {'http://localhost:11434/'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://ollama.com/download/windows"}>
                            Download Ollama on Windows
                           </a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OllamaWindowsLlmContent;