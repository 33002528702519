import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function ProcesorMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-procesor";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>


                <b>1. AMD-X5</b>
                <br/>
                <br/>

                Procesorul din imagine este un AMD Am5x86-P75, parte din seria x86 dezvoltată de AMD pentru a oferi performanță superioară pe platformele mai vechi,
                în special cele bazate pe Socket 3. Este un procesor din anii 1995-1996 și a fost proiectat să fie un upgrade economic pentru utilizatorii care încă foloseau computere cu plăci de bază compatibile cu 486

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_51.png'}/>
                </div>

                Valoare: ~20 euro

                <hr/>
                <b>2. AMD-K5</b>
                <br/>
                <br/>

                Procesorul din imagine este un AMD K5 PR100, parte din prima generație de procesoare AMD din seria K5.
                Acesta a fost lansat în anii 1996 și a fost conceput pentru a concura cu procesoarele Intel Pentium din aceeași perioadă.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_56.png'}/>
                </div>

                Valoare: ~10 euro

                <hr/>
                <b>3. AMD Athlon</b>
                <br/>
                <br/>

                Procesorul din imagine este un AMD Athlon, o serie iconică de procesoare dezvoltate de AMD.
                Judecând după aspectul său și seria Athlon, pare a fi din generația Athlon Thunderbird (în funcție de modelul specific gravat pe procesor).
                <br/>
                <br/>
                Lansare:
                <ul>
                    <li>Seria originală AMD Athlon a fost lansată în 1999, ca răspuns competitiv la procesoarele Intel Pentium III.</li>
                    <li>Varianta Thunderbird (care pare să fie aceasta) a fost introdusă în anul 2000.</li>
                </ul>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_57.png'}/>
                </div>
                Semnificație istorică:
                <ul>
                    <li>AMD Athlon Thunderbird a fost primul procesor care a depășit pragul de 1 GHz, marcând un moment important în istoria procesoarelor.</li>
                    <li>A oferit performanță comparabilă sau chiar mai bună decât procesoarele Intel din aceeași perioadă, la un cost mai redus, ceea ce a dus la creșterea popularității AMD</li>
                </ul>

                Valoare: ~3 euro

                <hr/>
                <b>4. Co-procesor Intel '86</b>
                <br/>
                <br/>

                Co-procesor-ul matematic Intel 80387 (mai exact, modelul A80387-25 -
                Sufixul „25” indică faptul că funcționează la o frecvență de 25 MHz.), a fost cunoscut sub numele de "387".
                Acesta este un co-procesor de tip FPU (Floating Point Unit), proiectat pentru a lucra împreună cu procesoarele principale din familia Intel 80386,
                pentru a accelera calculele care implică aritmetică în virgulă mobilă.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_52.png'}/>
                </div>

                Lansare:
                <ul>
                    <li>Co-procesorul Intel 80387 a fost in 1987.
                        Acesta a fost proiectat să funcționeze împreună cu procesorul Intel 80386, care fusese lansat în 1985
                    </li>
                </ul>

                Semnificație istorică:
                <ul>
                    <li>
                        Reprezintă un pas important în istoria arhitecturii x86. Acesta a permis trecerea de la procesare matematică software (emulare) la una hardware,
                        reducând considerabil timpul de execuție pentru aplicațiile care necesitau calcule complexe.
                    </li>
                    <li>
                        A fost unul dintre ultimele co-procesoare independente, deoarece procesoarele ulterioare au integrat funcționalitatea FPU direct în unitatea principală (ex.
                        Intel 80486).
                    </li>
                </ul>
                Valoare: ~20 euro

                <hr/>
                <b>Intel '92'93</b>
                <br/>
                <br/>

                Intel Pentium din prima generație, cu o frecvență de 133 MHz. A fost lansat in 1995.
                Este parte a familiei P5, care a fost prima arhitectură Pentium lansată de Intel.
                Acesta a fost un procesor important, marcând tranziția de la arhitectura 486 la o arhitectură mai avansată,
                capabilă să susțină performanțe ridicate pentru cerințele din anii '90.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_58.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_59.png'}/>
                </div>

                Semnificație istorică:
                <ul>
                    <li>Intel Pentium a fost primul procesor care a introdus brandul „Pentium”, un nume care a devenit sinonim cu procesoarele de înaltă performanță pentru PC-uri în anii următori.</li>
                </ul>

                Valoare: ~10 euro

                <hr/>
                <b>Intel - Pentium II</b>
                <br/>
                <br/>

                Pentium II a fost lansat în mai 1997 și a fost un pas important în dezvoltarea procesoarelor, aducând performanțe mai mari și o arhitectură îmbunătățită față de predecesorul său, Pentium.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_53.png'}/>
                </div>

                Valoare: ~15 euro

                <hr/>
                <b>80 - PIO</b>
                <br/>
                <br/>

                Componenta din imagine este un cip Zilog Z80 PIO (Parallel Input/Output Controller).
                Este un circuit integrat care a fost utilizat în sisteme bazate pe procesorul Zilog Z80,
                popular în anii '70 și '80. Cipul PIO este responsabil pentru gestionarea intrărilor și ieșirilor paralele, fiind o parte esențială a arhitecturii Z80 în computere și dispozitive electronice.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_54.png'}/>
                </div>

                <hr/>
                <b>Memorie Texas Instruments TMS4532-20NL3</b>
                <br/>
                <br/>

                Componenta fabricata de Texas Instruments.
                <br/>
                Avand in vedere ca  prețul DRAM era ridicat, Sinclair a recurs la niste trucuri.
                16k „inferioară” a fost aceeași în toate mașinile, dar extinderea la 48 kB a fost combinată din cipuri de memorie defecte de 64 kilobiți.
                Pe cipurile OKI MSM3732 sau Texas Instruments TMS4532 s-a determinat dacă eroarea a fost în partea inferioară sau în partea superioară
                (numărul 3 sau 4 indicat la sfârșitul modelului), acestea trebuiau împerecheate.
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_55.png'}/>
                </div>


            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default ProcesorMuseumContent;