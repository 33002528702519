import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class ServoMotorArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-servo-motor", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Motor - servo</b>
                    <br/>
                    <br/>

                    Tipuri:
                    <ul>
                        <li>
                            Positional Servo Motor
                        </li>
                        <li>
                            Continuous Rotation Servo Motors
                        </li>
                    </ul>

                    Senzorul are 3 pini:
                    <ul>
                        <li>signal (de obicei, firul portocaliu)</li>
                        <li>power (de obicei firul rosu)</li>
                        <li>
                            ground (de obicei firul maro/negru)
                        </li>
                    </ul>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_5.png'}/>
                    </div>


                    Cod:
                    <SyntaxHighlighter>
                        {'#include <Servo.h>\n' +
                            '\n' +
                            'Servo servo;\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  servo.attach(9);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  int pos = 0;\n' +
                            '  for(pos=0; pos <= 180; pos +=1){\n' +
                            '    servo.write(pos); // unghiul  \n' +
                            '    delay(15);\n' +
                            '  }\n' +
                            '\n' +
                            '  for(pos=180; pos >= 0; pos -=1){\n' +
                            '    servo.write(pos);\n' +
                            '    delay(15);\n' +
                            '  }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=qJC1nt_eJZs&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=10&ab_channel=ScienceBuddies"}>
                                Control a Positional Servo Motor with an Arduino (Lesson #10)
                            </a>
                        </li>

                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=NV6YHZ2RAqc&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=11&ab_channel=ScienceBuddies"}>
                                Continuous Rotation Servo Motors and Arduino (Lesson #11)
                            </a>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ServoMotorArduinoContent;