import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../kubernetes/IndexContent";

class NetworkPoliciesK8sContent extends BaseContentPage  {

    constructor(props) {
        super(props, "kubernetes-network-policies", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Network Policies</b>
                    <br/>
                    <br/>

                    Tipuri de politici:
                    <ul>
                        <li>ingress (ce vine)</li>
                        <li>egress (ce iese in exterior)</li>
                    </ul>
                    Trebuie privit din perspectiva pod-ului asupra caruia se aplica polica (in cazul de mai sus, pt db pod)

                    <br/>
                    In mod implicit, nod-urile pot comunica intre ele fara nici o problema.

                    <hr/>
                    <b>1. Ingress</b>
                    <br/>
                    <br/>
                    Exemplu: daca vrem doar pod-urile cu label-ul <i>name: api-pod</i> sa poata accesa prin TCP si portul 3306 pod-urile cu label-ul <i>role: db</i>
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicies\n' +
                            'metadata:\n' +
                            '  name: db-policies\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    marchLabels:\n' +
                            '      role:db\n' +
                            '  policyTypes:\n' +
                            '  - Ingress\n' +
                            '  ingress:\n' +
                            '  - from:\n' +
                            '    - podSelector:\n' +
                            '         matchLabels:\n' +
                            '            name: api-pod\n' +
                            '    ports\n' +
                            '    - protocol: TCP\n' +
                            '      port: 3306'}
                    </SyntaxHighlighter>

                    <hr/>
                    Pentru a permite doar dintr-un anumit namespace: <i>name: prod</i>
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicies\n' +
                            'metadata:\n' +
                            '  name: db-policies\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    marchLabels:\n' +
                            '      role:db\n' +
                            '  policyTypes:\n' +
                            '  - Ingress\n' +
                            '  ingress:\n' +
                            '  - from:\n' +
                            '    - podSelector:\n' +
                            '         matchLabels:\n' +
                            '            name: api-pod\n' +
                            '      namespaceSelector:\n' +
                            '         matchLabels:\n' +
                            '             name: prod\n' +
                            '    ports\n' +
                            '    - protocol: TCP\n' +
                            '      port: 3306'}
                    </SyntaxHighlighter>
                    <hr/>
                    Sa prespunem ca avem un server de backup, care nu este intr-un pod.
                    Dar stim adresa server-ului de backup (fie: 192.168.5.10).
                    Se poate configura permiterea accesului de pe anumite ip-uri:

                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicies\n' +
                            'metadata:\n' +
                            '  name: db-policies\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    marchLabels:\n' +
                            '      role:db\n' +
                            '  policyTypes:\n' +
                            '  - Ingress\n' +
                            '  ingress:\n' +
                            '  - from:\n' +
                            '    - podSelector:\n' +
                            '         matchLabels:\n' +
                            '            name: api-pod\n' +
                            '      namespaceSelector:\n' +
                            '         matchLabels:\n' +
                            '             name: prod\n' +
                            '    - ipBlock:\n' +
                            '        cidr: 192.168.5.10/32\n' +
                            '    ports\n' +
                            '    - protocol: TCP\n' +
                            '      port: 3306'}
                    </SyntaxHighlighter>
                    Cele 2 reguli: <i>podSelector</i> si <i>ipBlock</i> functionaza ca si cum ar fi legate cu operaratorul OR.
                    <br/>
                    Cele 2 reguli: <i>podSelector</i> si <i>namespaceSelector</i> functionaza ca si cum ar fi legate cu operaratorul AND.

                    <br/>
                    Daca am fi avut:
                    <SyntaxHighlighter>
                        {'    - podSelector:\n' +
                            '         matchLabels:\n' +
                            '            name: api-pod\n' +
                            '      - namespaceSelector:\n' +
                            '         matchLabels:\n' +
                            '             name: prod\n' +
                            '    - ipBlock:\n' +
                            '        cidr: 192.168.5.10/32'}
                    </SyntaxHighlighter>
                    Atunci erau 3 reguli unite de OR.

                    <hr/>
                    <b>2. Egress</b>
                    <br/>
                    <br/>
                    Permite sa se trasmita date de la DB Pod la serverul de back-up 192.168.5.10, pe portul 80.
                    <SyntaxHighlighter>
                        {'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicies\n' +
                            'metadata:\n' +
                            '  name: db-policies\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    marchLabels:\n' +
                            '      role:db\n' +
                            '  policyTypes:\n' +
                            '  - Ingress\n' +
                            '  - Egress' +
                            '  ingress:\n' +
                            '  - from:\n' +
                            '    - podSelector:\n' +
                            '         matchLabels:\n' +
                            '    ports\n' +
                            '    - protocol: TCP\n' +
                            '      port: 3306\n' +
                            '  egress:\n' +
                            '  - to:\n' +
                            '    - ipBlock:\n' +
                            '         cidr: 192.168.5.10/32\n' +
                            '    ports:\n' +
                            '    - protocol: TCP\n' +
                            '      port: 80'}
                    </SyntaxHighlighter>

                    <hr/>
                    Exercitii:
                    <SyntaxHighlighter>
                        {'alias k=kubectl\n' +
                            'k get pods\n' +
                            'k get service\n' +
                            'k get networkpolicies\n' +
                            'k get netpol # nume scurt pentru networkpolicies\n' +
                            'k describe netpol payroll-policy\n' +
                            '\n' +
                            '------\n' +
                            'apiVersion: networking.k8s.io/v1\n' +
                            'kind: NetworkPolicy\n' +
                            'metadata:\n' +
                            '  name: internal-policy\n' +
                            '  namespace: default\n' +
                            'spec:\n' +
                            '  podSelector:\n' +
                            '    matchLabels:\n' +
                            '      name: internal\n' +
                            '  policyTypes:\n' +
                            '  - Egress\n' +
                            '  - Ingress\n' +
                            '  ingress:\n' +
                            '    - {}\n' +
                            '  egress:\n' +
                            '  - to:\n' +
                            '    - podSelector:\n' +
                            '        matchLabels:\n' +
                            '          name: mysql\n' +
                            '    ports:\n' +
                            '    - protocol: TCP\n' +
                            '      port: 3306\n' +
                            '\n' +
                            '  - to:\n' +
                            '    - podSelector:\n' +
                            '        matchLabels:\n' +
                            '          name: payroll\n' +
                            '    ports:\n' +
                            '    - protocol: TCP\n' +
                            '      port: 8080\n' +
                            '\n' +
                            '  - ports:\n' +
                            '    - port: 53\n' +
                            '      protocol: UDP\n' +
                            '    - port: 53\n' +
                            '      protocol: TCP'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/k8s/k8s-1.png'}/>
                    </div>

                    Observatii:
                    <ul>
                        <li>
                            serviciul kube-dns este expus pe portul 53
                        </li>
                        <li>
                            a fost permis și traficul de ieșire (Egress) către porturile TCP și UDP
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://create-react-app.dev/docs/getting-started/#npx"}>Getting Started</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default NetworkPoliciesK8sContent;