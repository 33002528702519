import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class IssuesNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-issues", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Gasirea NAS-ului. Configurare antivirus Nod32</b>
                    <br/>
                    <br/>

                    Pentru a gasit masina NAS se pot utiliza:
                    <ul>
                        <li><b>Web Assistant</b>: introduceți <b>find.synology.com</b> în bara de adrese a browserului web</li>
                        <li><b>Synology Assistant</b>:  Acest utilitar desktop poate fi găsit în Centrul de descărcare {">"} selectați modelul Synology NAS {">"} tab Desktop Utilities</li>
                    </ul>

                    Daca exista un antivirus, pentru a functiona <b>Synology Assistant</b> trebuie dezactivat firewall, de cele mai multe ori (sau de pus o regula).
                    <br/>
                    De exemplu, pentru Eset32:
                    <ul>
                        <li>
                            mergem in aplicatia Eset: <b>Setup {"->"} Network Protection</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-3/img_7.png'}/>
                            </div>
                        </li>

                        <li>
                            <b>Firewall {"->"} din meniu {"->"} Configuration</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-3/img_4.png'}/>
                            </div>
                        </li>

                        <li>
                            din stanga <b>Firewall Protection {"->"}  submeniu Firewall</b>; apoi din dreapta <b>Advanced</b> si la <b>Rules</b> se da <b>Edit</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-3/img_6.png'}/>
                            </div>
                        </li>

                        <li>
                            din tabul <b>General</b> se adauga o noua regula:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-3/img.png'}/>
                            </div>
                        </li>

                        <li>
                            din tabul <b>Local</b> se specifica aplicatia permisa (ex: C:\Program Files (x86)\Synology\Assistant\DSAssistant.exe):
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-3/img_1.png'}/>
                            </div>
                        </li>

                        <li>
                            se da <b>OK</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/nas-3/img_2.png'}/>
                            </div>
                        </li>

                    </ul>


                    Daca adaugarea regulii, sunt sanse sa fie gasit NAS-ul:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/nas-3/img_3.png'}/>
                    </div>


                    {/*Dupa ce am dezactivat firewall de Eset32...s-a vazut NAS-ul in Synology Assistent*/}
                    {/*Dar nu m-am putut conecta!*/}

                    {/*cu SMB rata de transfer este ffff mica ~ 5M*/}

                    {/*https://finds.synology.com/*/}

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://mariushosting.com/how-to-reset-synology-network-settings/"}>https://mariushosting.com/how-to-reset-synology-network-settings/</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IssuesNasContent;