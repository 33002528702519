import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ImageGenerateSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-image-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Spring Image Model API
                    <ul>
                        <li>
                            ImageModel
                        </li>
                        <li>
                            ImagePrompt
                        </li>
                        <li>
                            ImageMessage
                        </li>
                        <li>
                            ImageOptions
                        </li>
                    </ul>

                    <hr/>

                    Modele:
                    <ul>
                        <li>
                            dall-e-2
                            <ul>
                                <li>prompt: 1000 de caractere</li>
                                <li>dimensiuni: 256x256, 512x512, 1024x1024</li>
                            </ul>
                        </li>
                        <li>
                            dall-e-3
                            <ul>
                                <li>
                                    <li>prompt: 4000 de caractere</li>
                                    <li>dimensiuni: 1024x1024, 1792x1024, 1024x1792</li>
                                </li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>Generare imagine. Format: url</b>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            ' @Autowired\n' +
                            '    private OpenAiImageModel openAiImageModel;\n' +
                            '\n' +
                            '    public void generateImage(String text) {\n' +
                            '\n' +
                            '        OpenAiImageOptions options = OpenAiImageOptions.builder()\n' +
                            '                .quality("standard") // "hd"\n' +
                            '                .N(1)\n' +
                            '                .height(1024)\n' +
                            '                .width(1024)\n' +
                            '                .build();\n' +
                            '\n' +
                            '        ImagePrompt imagePrompt = new ImagePrompt(text, options);\n' +
                            '        ImageResponse imageResponse = openAiImageModel.call(imagePrompt);\n' +
                            '\n' +
                            '        // afisare link\n' +
                            '        Image image = imageResponse.getResult().getOutput();\n' +
                            '        System.out.println(image.getUrl());\n' +
                            '    }'
                        }
                    </SyntaxHighlighter>

                    Exemplu:
                    <SyntaxHighlighter>
                        {'https://oaidalleapiprodscus.blob.core.windows.net/private/org-P8wHao1neJf1x7c8Ti7JdI2H/user-MCs4cDdlmX6Mwin9lZUw7efS/img-GQiFTtZ9MQCvI5zmjD9MI9T6.png?st=2025-01-03T15%3A14%3A41Z&se=2025-01-03T17%3A14%3A41Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2025-01-02T19%3A35%3A14Z&ske=2025-01-03T19%3A35%3A14Z&sks=b&skv=2024-08-04&sig=80CkfHLYux%2Bpc2pAqm3xsME7pW6q/fYB33FWa/oPnS4%3D\n'}
                    </SyntaxHighlighter>
                    <hr/>

                    <hr/>
                    <b>Generare imagine. Format: b64_json</b>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' public void generateImage(String text) {\n' +
                            '\n' +
                            '        OpenAiImageOptions options = OpenAiImageOptions.builder()\n' +
                            '                .quality("standard") // "hd"\n' +
                            '                .N(1)\n' +
                            '                .height(1024)\n' +
                            '                .width(1024)\n' +
                            '                .model("dall-e-3")\n' +
                            '                .responseFormat("b64_json")\n' +
                            '                .style("natural") //default vivid\n'+
                            '                .build();\n' +
                            '\n' +
                            '        ImagePrompt imagePrompt = new ImagePrompt(text, options);\n' +
                            '        ImageResponse imageResponse = openAiImageModel.call(imagePrompt);\n' +
                            '\n' +
                            '        Image image = imageResponse.getResult().getOutput();\n' +
                            '\n' +
                            '        byte[] content = Base64.getDecoder().decode(image.getB64Json());\n' +
                            '\n' +
                            '        save(content, "D:\\\\work\\\\spring-ia\\\\src\\\\main\\\\resources\\\\img.jpg");\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    Observatie:
                    <ul>
                        <li>este o operatie costisitoare (0.04 euro per imagine)</li>
                        <li>formatul <i>url</i> este valid 60 minute</li>
                    </ul>


                    {/*https://oaidalleapiprodscus.blob.core.windows.net/private/org-P8wHao1neJf1x7c8Ti7JdI2H/user-MCs4cDdlmX6Mwin9lZUw7efS/img-GQiFTtZ9MQCvI5zmjD9MI9T6.png?st=2025-01-03T15%3A14%3A41Z&se=2025-01-03T17%3A14%3A41Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2025-01-02T19%3A35%3A14Z&ske=2025-01-03T19%3A35%3A14Z&sks=b&skv=2024-08-04&sig=80CkfHLYux%2Bpc2pAqm3xsME7pW6q/fYB33FWa/oPnS4%3D*/}

                    {/*https://oaidalleapiprodscus.blob.core.windows.net/private/org-P8wHao1neJf1x7c8Ti7JdI2H/user-MCs4cDdlmX6Mwin9lZUw7efS/img-TYuFPSSnrMldXXQP1EVUqnZN.png?st=2025-01-03T15%3A18%3A03Z&se=2025-01-03T17%3A18%3A03Z&sp=r&sv=2024-08-04&sr=b&rscd=inline&rsct=image/png&skoid=d505667d-d6c1-4a0a-bac7-5c84a87759f8&sktid=a48cca56-e6da-484e-a814-9c849652bcb3&skt=2025-01-02T19%3A43%3A42Z&ske=2025-01-03T19%3A43%3A42Z&sks=b&skv=2024-08-04&sig=ZgMFNZ8mtD5aRbCePnS4arqIobkbz71v/hiME61e1Ws%3D*/}

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ImageGenerateSpringIALlmContent;