import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function CableMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-cable";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>Cabluri</b>
                <br/>
                <br/>


                <b>Cablu video (DB-9 (D-sub cu 9 pini) - Cablu DB9 tata DB9 tata - RS232 )</b>
                <br/>
                <br/>

                Poate conecta un calculator de un monitor.
                <br/>
                <br/>
                Calculatoare cu port video: HC 91
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_25.png'}/>
                </div>

                <hr/>

                <b>Cablu RCA-RF</b>
                <br/>
                <br/>
                Un cablu RCA este format dintr-un conector cilindric cu un pin central (pentru semnal) și un inel exterior (pentru masă). Este utilizat pentru a transmite semnale
                analogice sau digitale între diverse dispozitive.
                <br/>
                <br/>
                Calculatoare cu port video: CIP 01, CIP 03.
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_68.png'}/>
                </div>

                <hr/>
                <b> Cablu DIN 5 Pini </b>
                <br/>
                <br/>

                Scop: audio analogic (ex. conexiuni pentru echipamente Hi-Fi mai vechi), semnale electrice generale sau aplicații industriale.
                <br/>
                Observatie: A se nu confunda cu cablul audio MIDI
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_69.png'}/>
                </div>
                Link:
                <ul>
                    <li>
                        <a href={"https://www.electronicservice.ro/en/cumpara/cablu-din-la-din-5pini-tata-1-5m-7754969"}>https://www.electronicservice.ro/</a>
                    </li>
                </ul>

                Un cablu MIDI poate fi folosit ca un cablu DIN de 5 pini în anumite situații, dar cu unele limitări și considerente importante.
                <br/>
                Când este posibil să funcționeze:
                <ul>
                    <li>
                        Utilizare audio analogică simplă (stereo/mono)
                        <br/>
                        Dacă dispozitivele între care faci conexiunea necesită doar 3 pini pentru transmisie (ex. masă și două canale audio), un cablu MIDI poate funcționa ca un
                        cablu DIN.
                        Motivul este că un cablu MIDI folosește doar 3 pini din cei 5 disponibili, iar pinii nefolosiți vor rămâne pur și simplu neconectați
                    </li>
                    <li>
                        Semnale cu cerințe reduse
                        <br/>
                        În aplicații în care conectivitatea minimă este suficientă și nu implică utilizarea tuturor celor 5 pini, cablul MIDI poate fi compatibil.
                    </li>
                </ul>

                Când NU va funcționa corect:
                <ul>
                    <li>
                        Conexiuni care utilizează toți cei 5 pini:
                        <br/>
                        Dacă dispozitivul care necesită un cablu DIN folosește toți cei 5 pini (de exemplu, pentru semnale multiple, canale stereo complexe sau funcționalitate
                        specifică), un cablu MIDI nu este adecvat. Pinii nefolosiți în cablul MIDI vor lipsi din conexiune.
                        Aplicații de semnal nespecific MIDI:
                    </li>
                    <li>
                        Aplicații de semnal nespecific MIDI:
                        <br/>
                        Cablurile MIDI sunt proiectate pentru transmisia de date opto-izolate și au o configurație specifică pentru standardul MIDI. Aceste caracteristici pot
                        interfera cu unele utilizări audio analogice sau alte semnale non-MIDI.

                    </li>
                </ul>

                <hr/>
                Dictionar
                <ul>
                    <li>AV = Audio Video</li>
                    <li>
                        CVBS = Composite Video, Blanking, and Sync (semnal video analogic compozit care combină toate informațiile necesare pentru afișarea unei imagini video într-un singur semnal transmis printr-un cablu)
                        <br/>
                        Conector: De obicei, folosește un cablu RCA (mufa galbenă) pentru transmisia semnalului video.
                    </li>
                    <li>
                        YPbPr = îmbunătățire a CVBS
                        <br/>
                        Conectori: Utilizează trei mufe RCA pentru video, de obicei colorate în verde, albastru și roșu. Poate avea două mufe RCA suplimentare pentru audio (alb și roșu).
                    </li>
                    <li>
                        S-Video
                        <br/>
                        Conector: Conector circular cu 4 sau 7 pini.
                    </li>
                    <li>
                        SCART  (Syndicat des Constructeurs d'Appareils Radiorécepteurs et Téléviseurs)
                    </li>
                    <li>
                        HDMI (High-Definition Multimedia Interface)
                    </li>
                    <li>
                        DisplayPort
                    </li>
                    <li>
                        Cablu Coaxial
                    </li>
                    <li>
                        Cablu VGA
                    </li>
                    <li>
                        Fibră Optică (Toslink)
                    </li>
                </ul>

                <hr/>
                <b>Intrari video</b>
                <br/>
                <br/>

                <ul>
                    <li>
                        <b>RF</b>: Intrarea RF (frecvență radio) este termenul tehnic pentru o intrare coaxială/antenă pe spatele unui televizor

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-3/img_64.png'}/>
                        </div>

                    </li>

                    <li>
                        <b>Serial</b>

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-3/img_68.png'}/>
                        </div>

                    </li>

                    <li>
                        <b>S-Video</b>: Video separat (2 canale), cunoscut mai frecvent ca S-Video și Y/C.
                        Este o transmisie video analogică (fără audio) care transmite video de definiție standard, de obicei la rezoluție 480i sau 576i.

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-3/img_65.png'}/>
                        </div>
                    </li>

                    <li>
                        <b>VGA</b>: Un conector Video Graphics Array (VGA) este un conector DE-15 cu trei rânduri și 15 pini. Conectorul VGA cu 15 pini se găsește pe multe plăci
                        video, monitoare de computer și unele televizoare de înaltă definiție.
                        Pe computerele laptop sau alte dispozitive mici, un port mini-VGA este uneori folosit în locul conectorului VGA de dimensiune completă,

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-3/img_66.png'}/>
                        </div>
                    </li>

                    <li>
                        <b>HDMI</b>: HDMI (High-Definition Multimedia Interface) este o interfață audio/video compactă
                        pentru transferul de date audio/video digitale necomprimate de la un dispozitiv compatibil HDMI ("sursa")
                        către un dispozitiv audio digital compatibil și un televizor digital.

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-3/img_67.png'}/>
                        </div>
                    </li>
                </ul>


                Link-uri cabluri:
                <ul>
                    <li>
                        (DIN 41524)
                        <br/>
                        <a target={"_blank"} href={"https://www.cryptomuseum.com/ref/conn/din/index.htm"}>Conectori DIN</a>
                        <br/>
                        <a target={"_blank"} href={" https://en.wikipedia.org/wiki/DIN_connector"}>Conectori DIN</a>
                    </li>
                </ul>

                Alte link-uri:
                <ul>

                    <li>
                        MCE2VGA
                        <br/>
                        https://www.youtube.com/watch?v=FOoyYJ2MkqQ&ab_channel=LGR
                    </li>
                    <li>
                        VGA to SCART
                        <br/>
                        https://www.retroupgrades.co.uk/product/vga2scart/
                    </li>
                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default CableMuseumContent;