import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HC2000MuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-hc2000";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>HC 2000</b>
                <br/>
                <br/>
                Detalii:
                <ul>
                    <li>
                        Model standard: 51 de taste
                    </li>
                    <li>
                        Microprocesor: Z80
                    </li>
                    <li>
                        Memoria EPROM: 16 KB (BASIC), 8K (CP/M BIOS), 8K (interfață disc)
                    </li>
                    <li>
                        Memoria RAM: 64 KB (memorii 41464), disponibil 48 KB
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter), CP/M (accesibil de pe disc flexibil)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text:
                        <ul>
                            <li>32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere</li>
                            <li>80 coloane X 25 linii (mod CP/M)</li>
                        </ul>
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 50 (lățime) X 20 (lungime) X 6 (înălțime) cm
                    </li>
                </ul>
                Porturi:
                <ul>
                    <li>
                        TV/RF (RCA-F)
                    </li>
                    <li>
                        RGB (DB9-F / 9-pini mamă D-Sub DE-9)
                    </li>
                    <li>
                        Joystick (DB9-M / 9-pini tată D-Sub DE-9)
                    </li>
                    <li>
                        Slot extensie compatibil ZX Spectrum
                    </li>
                    <li>
                        Conector casetofon (DIN-5 / conector tata 5 pini)
                    </li>
                    <li>
                        RS-232 (DB9-F)
                    </li>
                    <li>
                        Conector pentru unitate disc flexibil externă (opțional)
                    </li>
                    <li>
                        Unitate disc flexibil 3.5 inch internă
                    </li>
                </ul>
                Alimentare: externă, +5V (pentru circuitele TTL), +12V și -12V (pentru interfața serial)

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_4.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_12.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_41.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_42.png'}/>
                </div>


                A exista si o varianta cu casetofon:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_16.png'}/>
                </div>

                <hr/>
                <b>HC 2000 cu porturi extinse</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_40.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_41.png'}/>
                </div>

                Unde e marcat cu D se introduce alimentarea.

                <br/>

                Stare: <b>Functional</b>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_54.png'}/>
                </div>

                <hr/>
                <b>HC 2000: Serie No / Part No: 5954</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_46.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_42.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_43.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_44.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_45.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_47.png'}/>
                </div>

                Stare: <b>Nefunctional</b>

                <br/>

                Sursa alimentare HC 2000:

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_48.png'}/>
                </div>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_49.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_50.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_51.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_52.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_53.png'}/>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HC2000MuseumContent;