import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ChromaDBSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-chroma-db-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>ChromaStore</b>
                    <br/>
                    <br/>

                    Chroma este baza de date open-source pentru aplicații AI.
                    <br/>

                    <hr/>
                    <b>1. Chroma in Docker</b>
                    <br/>
                    <br/>

                    Pornire <b>Chroma</b> in Docker:
                    <SyntaxHighlighter>
                        {'docker run -it --rm --name chroma -p 8000:8000 ghcr.io/chroma-core/chroma:0.4.15'}
                    </SyntaxHighlighter>

                    Verificare in browser:
                    <SyntaxHighlighter>
                        {'http://localhost:8000/api/v1'}
                    </SyntaxHighlighter>
                    sau:
                    <SyntaxHighlighter>
                        {'http://192.168.0.206:8000/api/v1'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Spring AI</b>
                    <br/>
                    <br/>

                    Se adauga dependinta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'implementation \'org.springframework.ai:spring-ai-chroma-store-spring-boot-starter\''}
                    </SyntaxHighlighter>

                    Se adauga configurarile:
                    <SyntaxHighlighter>
                        {'spring.ai.vectorstore.chroma.collection-name=SpringAiCollection\n' +
                            'spring.ai.vectorstore.chroma.initialize-schema=true\n' +
                            'spring.ai.vectorstore.chroma.client.host=http://localhost #http://192.168.0.207\n' +
                            'spring.ai.vectorstore.chroma.client.port=8000'}
                    </SyntaxHighlighter>

                    Incarcare date (varianta 1):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.config;\n' +
                            '\n' +
                            'import jakarta.annotation.PostConstruct;\n' +
                            'import org.springframework.ai.reader.TextReader;\n' +
                            'import org.springframework.ai.transformer.splitter.TokenTextSplitter;\n' +
                            'import org.springframework.ai.vectorstore.VectorStore;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.core.io.ClassPathResource;\n' +
                            'import org.springframework.stereotype.Component;\n' +
                            '\n' +
                            '@Component\n' +
                            'public class DataInit {\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    private VectorStore vectorStore;\n' +
                            '\n' +
                            '    @PostConstruct\n' +
                            '    public void init(){\n' +
                            '\n' +
                            '        TokenTextSplitter tokenTextSplitter = new TokenTextSplitter(100, 100, 5, 1000, true);\n' +
                            '\n' +
                            '        TextReader t1Reader = new TextReader(new ClassPathResource("akautzu.txt"));\n' +
                            '        TextReader t2Reader = new TextReader(new ClassPathResource("akautzu.txt"));\n' +
                            '\n' +
                            '        vectorStore.add(tokenTextSplitter.split(t1Reader.get()));\n' +
                            '        vectorStore.add(tokenTextSplitter.split(t2Reader.get()));\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Incarcare date (varianta 2):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' public String load() throws IOException, InterruptedException {\n' +
                            '        List<Document> documents = Files.lines(resource.getFile().toPath())\n' +
                            '                .map(Document::new)\n' +
                            '                .toList();\n' +
                            '        TextSplitter textSplitter = new TokenTextSplitter();\n' +
                            '        for(Document document : documents) {\n' +
                            '            List<Document> splitteddocs = textSplitter.split(document);\n' +
                            '            System.out.println("before adding document: " + document.getContent());\n' +
                            '            vectorStore.add(splitteddocs);\n' +
                            '            Thread.sleep(61000);\n' +
                            '            System.out.println("Added document: " + document.getContent());\n' +
                            '        }\n' +
                            '        return "Loaded " + resource.getFilename();\n' +
                            '    }\n'}
                    </SyntaxHighlighter>

                    <b>2.1. QuestionAnswerAdvisor </b>
                    <br/>
                    <br/>

                    Cand se contruieste <b>ChatClient</b> se adauga un <b>QuestionAnswerAdvisor</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' ChatClient chatClient = builder\n' +
                            '                .defaultAdvisors(new QuestionAnswerAdvisor(vectorStore, SearchRequest.defaults()))\n' +
                            '                .build();'}
                    </SyntaxHighlighter>

                    Sau se poate folosi si asa, folosind <b>QuestionAnswerAdvisor</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'private String raspuns(String text){\n' +
                            '        return this.chatClient.prompt(text).advisors(new QuestionAnswerAdvisor(vectorStore)).call().content();\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <b>2.1. Metoda similaritySearch() </b>
                    <br/>
                    <br/>

                    Sau se poate cauta, folosind metoda <b>similaritySearch</b> :
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'public String search() {\n' +
                            '        List<Document> results = vectorStore.similaritySearch(SearchRequest.query("classic novel about wealth and society").withTopK(3));\n' +
                            '        return results.toString();\n' +
                            '    }'}
                    </SyntaxHighlighter>


                </div>
                <br/>
                <div className={"text-justify"}>
                {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChromaDBSpringIALlmContent;