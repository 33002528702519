import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function StorageMuseumContent(props) {

    let toc = new MuseumToc();

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={"museum-others"} toc={toc.getContent()}/>
            <NavigatorFragment pageId={"museum-others"} toc={toc.getContent()}/>

            <div className={"text-justify important"}>
                Console de jocuri:
                <ul>

                    <li>
                        <b>Universum TV-Multi-Spiel 2006</b>
                        <br/>
                        Universum TV-Multi-Spiel 2006 este o consolă de jocuri video de primă generație, lansată în 1978 sub marca Universum, deținută de compania germană Quelle.
                        Această consolă face parte din seria de dispozitive "Pong" și utilizează cipul General Instrument AY-3-8500, care permite rularea a șase jocuri integrate
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img.png'}/>
                        </div>

                        Universum TV-Multi-Spiel 2006 a fost succesorul modelului 2004 și a precedat modelele color, precum 4004 Color Multi-Spiel.
                        De asemenea, a fost comercializată sub diferite denumiri, inclusiv Hanimex 7771.
                    </li>

                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={"museum-others"} toc={toc.getContent()}/>

        </div>
    );
}

export default StorageMuseumContent;