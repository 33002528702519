import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaJobSchedulerLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-job-scheduler", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Job Scheduler</b>
                    <br/>
                    <br/>

                    Se creeaza in <b>modules</b> directorul <b>agnes-training</b>.
                    <br/>
                    Apoi, se creeaza in interiorul directorului <b>agnes-training</b> directorul/modulul <b>agnes-training-<b>job-schedulers</b></b>
                    <br/>
                    In modulul <b>agnes-training-<b>job-schedulers</b></b> se creeza 2 fisiere:
                    <ul>
                        <li><b>bnd.bnd</b>, cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'Bundle-Name: Agnes Training Job Schedules\n' +
                                    'Bundle-SymbolicName: ro.agnes.job.schedules\n' +
                                    'Bundle-Version: 1.0.0'}
                            </SyntaxHighlighter>
                        </li>
                        <li><b>build.gradle</b>, cu urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'dependencies {\n' +
                                    '    compileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                                    '}'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    click dreapta pe proiect pentru a creea structura <b>src/main/java</b> si <b>src/main/resources</b> (se tine apasat CTRL pentru a selecta mai multe)
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_1.png'}/>
                    </div>

                    se alege <b>src/main/java</b> si <b>src/main/resources</b> (se tine apasat CTRL pentru a selecta mai multe)
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_2.png'}/>
                    </div>

                    se creaza pachetul <b>ro.agnes.job.schedules</b> in \src\main\java:

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_3.png'}/>
                    </div>

                    apoi, clasa <b>HelloDispatchTaskExecutor</b> cu urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.job.schedules;\n' +
                            '\n' +
                            'import com.liferay.dispatch.executor.BaseDispatchTaskExecutor;\n' +
                            'import com.liferay.dispatch.executor.DispatchTaskExecutor;\n' +
                            'import com.liferay.dispatch.executor.DispatchTaskExecutorOutput;\n' +
                            'import com.liferay.dispatch.model.DispatchTrigger;\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "dispatch.task.executor.name=schedule-Hello",\n' +
                            '                "dispatch.task.executor.type=schedule-Hello"\n' +
                            '        },\n' +
                            '        service = DispatchTaskExecutor.class\n' +
                            ')\n' +
                            'public class HelloDispatchTaskExecutor extends BaseDispatchTaskExecutor {\n' +
                            '    @Override\n' +
                            '    public void doExecute(DispatchTrigger dispatchTrigger, DispatchTaskExecutorOutput dispatchTaskExecutorOutput) throws Exception {\n' +
                            '        _log.info("schedule-Hello");\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getName() {\n' +
                            '        return "schedule-Hello";\n' +
                            '    }\n' +
                            '\n' +
                            '    private static final Log _log = LogFactoryUtil.getLog(HelloDispatchTaskExecutor.class);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    apoi, logat ca administrator:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_4.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_5.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_6.png'}/>
                    </div>

                    Daca se apasa butonul <b>Run now</b>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_7.png'}/>
                    </div>
                    atunci in log-urile serverului se va afisa:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'2024-08-29 19:43:08.935 INFO  [liferay/dispatch/executor-2][HelloDispatchTaskExecutor:21] schedule-Hello'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Mesaje in Logs</b>
                    <br/>
                    <br/>
                    Daca metoda <b>doExecute()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {' public void doExecute(DispatchTrigger dispatchTrigger, DispatchTaskExecutorOutput dispatchTaskExecutorOutput) throws Exception {\n' +
                            '        _log.info("schedule-Hello");\n' +
                            '        String message = "Hello World!";\n' +
                            '        dispatchTaskExecutorOutput.setOutput(message);\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    atunci in tab-ul <b>Logs</b>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_8.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_9.png'}/>
                    </div>

                    <hr/>
                    <b>3. Obtinere parametri</b>
                    <br/>
                    <br/>

                    Daca metoda <b>doExecute()</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'  @Override\n' +
                            '    public void doExecute(DispatchTrigger dispatchTrigger, DispatchTaskExecutorOutput dispatchTaskExecutorOutput) throws Exception {\n' +
                            '        _log.info("schedule-Hello");\n' +
                            '\n' +
                            '        UnicodeProperties unicodeProperties = dispatchTrigger.getDispatchTaskSettingsUnicodeProperties();\n' +
                            '        String nrStrada = unicodeProperties.getProperty("nrStrada");\n' +
                            '        _log.info("nrStrada=" + nrStrada);\n' +
                            '\n' +
                            '        String message = "Hello World!";\n' +
                            '        dispatchTaskExecutorOutput.setOutput(message);\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'2024-08-29 20:00:34.667 INFO  [liferay/dispatch/executor-4][HelloDispatchTaskExecutor:26] nrStrada=19'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Infrumusetare output pentru Logs</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'@Override\n' +
                            '    public void doExecute(DispatchTrigger dispatchTrigger, DispatchTaskExecutorOutput dispatchTaskExecutorOutput) throws Exception {\n' +
                            '        _log.info("schedule-Hello");\n' +
                            '\n' +
                            '        UnicodeProperties unicodeProperties = dispatchTrigger.getDispatchTaskSettingsUnicodeProperties();\n' +
                            '        String nrStrada = unicodeProperties.getProperty("nrStrada");\n' +
                            '        _log.info("nrStrada=" + nrStrada);\n' +
                            '\n' +
                            '        String message = "<span style=\'color:red\'>Hello World!</span>";\n' +
                            '        dispatchTaskExecutorOutput.setOutput(message);\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/job_10.png'}/>
                    </div>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaJobSchedulerLiferayContent;