import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ArtContent extends BaseContentPage {

    constructor(props) {
        super(props, "art", IndexContent);
    }

    render() {
        return (
            <div className="home art">
                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify"}>
                    <b>1. Introducere</b>
                </div>
                <br/>
                <div className={"text-justify"}>
                    Teoria rezonantei adaptive (Adaptive resonance theory / ART) a fost dezvoltată de Carpenter și Grossberg. Există mai multe variante, de exemplu:
                    <ul>
                        <li><b>ART1</b> a fost proiectat pentru clusterizarea/gruparea vectorilor binari.</li>
                        <li><b>ART2</b> a fost proiectat pentru clusterizarea/gruparea vectorilor cu valori continue.</li>
                    </ul>

                    Aceste rețele <b>grupează</b> (clusterizează) <b>datele de intrare (observațiile/exemplele/modelele)</b> folosind <b>învătarea nesupervizată</b>.<br/>
                    Exemplele pot fi date în orice ordine. Si de fiecare dată când un exemplu este prezentat retelei, un <b>neuron (cluster unit) potrivit este ales</b> și <b>ponderile asociate cu acest neuron sunt actualizate</b>,
                    pentru ca neuronul să învețe exemplul.<br/>
                    De obicei, în genul acesta de rețele de clusterizare, <b>ponderile unui neuron X</b> pot fi considerate un fel de <b>exemplar</b> pentru <b>exemplele</b> grupate
                    în cluster-ul ascociat cu neuronul X.
                </div>

                <div className={"text-justify"}>
                    <br/>
                    <i>Despre similaritate</i> <br/>
                    Relele ART au fost proiecte pentru a gestiona la <b>gradul de similaritate</b> a exemplelor plasate în același cluster.
                    Acest lucru este controlat de un parametrul numit <b>parametru de vigilenta</b>.<br/>
                    {/*Exemplele pot fi diferii în ceea ce privește nivelul de detalii, adică numărul de componente care sunt diferite de 0*/}
                    {/*(un exemplu poate arăta în felul următor: 01010100, având 8 componente, 3 componente cu valoarea 1 și 5 componente cu valoarea 0).*/}
                    {/*De aceea, este folosită mai degrabă <b>similaritatea relativă</b> dintre un exemplu și exemplar, decât <b>diferenta absolută</b> între exemple.*/}
                    {/*(O diferență intr-o componentă este mai semnificativă în exemplele care au foarte putine componente cu valoari diferite de 0 decat în*/}
                    {/*exemplele cu componetele cu multe valori diferite de 0).*/}
                    {/*<br/>*/}
                    <br/>
                    <i>Despre stabilitate</i> <br/>
                    În procesul de antrenare, fiecare exemplu (din setul de antrenare) este prezentat retelei de mai multe ori.
                    Un exemplu poate fi plasat într-un cluster prima oară când este prezentat, ca apoi să fie plasat într-un cluster diferit când este prezentat mai târziu.
                    (datorită schimbarilor în ponderile primului cluster dacă între timp a învățat și alte exemple).
                    O <b>rețea stabilă</b> nu va întoarce un exemplu într-un cluster în care a mai fost anterior; cu alte cuvinte, un exemplu
                    care oscilează între diferite clustere la diferite stadii ale antrenării, indică faptul că <b>retețaua este instabilă</b>.
                    <br/>
                    <br/>
                    <i>Despre platicitate</i> <br/>
                    Unele rețele realizează stabilitatea prin reducerea graduală a <i>ratei de învățare</i>, deoarece aceeași mulțime de exemple este prezentată rețelei
                    de mai multe ori. Cu toate astea, aceasta nu permite rețelei să învețe cu ușurință un nou exemplu când prezentat pentru prima oară
                    chiar dacă au avut loc deja o serie de epoci de antrenare. Abilitatea rețelei de a învăța un nou exemplu la fel de bine
                    la orice stadiu al învățării se numeste <b>plasticitate</b>. <br/> <br/>
                    Retelele <i>ART</i> sunt proiectate să fie <b>stabile și plastice</b>.
                    <br/>
                    <br/>
                </div>

                <div className={"text-justify"}>
                    <i>Arhitectura ART</i> <br/>
                    Retele ART prespun 3 grupe de neuroni, fiecare în câte un layer separat:
                    <ul>
                        <li>
                            layer-ul <b>F<sub>1</sub></b> - stat de procesare a datelor de intrare; format din două părti:
                            <ul>
                                <li>
                                    <b>F<sub>1</sub>(a)</b> - porțiunea de intrare
                                    <ul>
                                        <li>unele procesări ale datelor de intrare au loc aici (mai ales în ART2)</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>F<sub>1</sub>(b)</b> - porțiunea de interfață:
                                    <ul>
                                        <li>
                                            combină semnale din F<sub>1</sub>(a) și layer-ul layer-ul F<sub>2</sub> (pentru
                                            utilizare în compararea similarității dintre semnalul de input (exemplu)
                                            și ponderile neuronului (exemplar),
                                            neuron selectat ca și candidat pentru a învăța exemplul)
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>layer-ul <b>F<sub>2</sub></b> - statul neuronilor (unitatilor cluster) </li>
                        <li><b>mecanismul de resetare</b> - mecanism de control al gradului de similaritate a exemplelor plasate in acelasi cluster</li>
                    </ul>

                    Pentru a constrola similaritatile dintre exemplele plasate in acelasi cluster, există 2 seturi de conexiuni, fiecare cu ponderile lor:
                    <ul>
                        <li>
                            conexiuni intre <b>F<sub>1</sub>(b) si  F<sub>2</sub></b>, notate cu <b>b<sub>ij</sub></b> cu
                            semnificatia ca reprezinta ponderea dintre neuronul i din statul F<sub>1</sub>(b) si neuronul j din statul F<sub>2</sub>
                        </li>
                        <li>
                            conexiuni intre <b>F<sub>2</sub> si F<sub>1</sub>(b)</b>, notate cu <b>t<sub>ji</sub></b> cu
                            semnificatia ca reprezinta ponderea dintre neuronul j din statul F<sub>2</sub> si neuronul i din statul F<sub>1</sub>(b)
                        </li>
                    </ul>

                    <div style={{padding:20}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/art1.png'}/>
                    </div>

                    Layer-ul <b>F<sub>2</sub></b> este un <b>layer competitiv</b>.
                    <br/>

                    Descriptiv, algoritmul urmează urmatorii pasi:
                    <ul>
                        <li>
                            se prezintă un exemplu rețelei
                        </li>
                        <li>
                            neuronul X din <b>F<sub>2</sub></b> cu cel mai mare net (sumarizare) devine candidat pentru a invata exemplul propus
                        </li>
                        <li>
                            activarile celorlați neuroni din <b>F<sub>2</sub></b> sunt setate la 0
                        </li>
                        <li>
                            neuronii din <b>F<sub>1</sub>(b)</b> (interfata) combina acum informatia dintre <b>F<sub>1</sub>(a)</b> (datele de intrare) si <b>F<sub>2</sub></b>
                        </li>
                        <li>
                            neuronul X poate sau nu să învețe exemplul prezentat retelei, depinzând de cât de similara este ponderea sa <b>t<sub>ji</sub></b> cu acest
                            exemplu:
                            <ul>
                                <li>
                                    această decizie este luată de unitate de restare (<b>mecanismul de resetare</b>),
                                    pe baza datelor primite de la <b>F<sub>1</sub>(a) si  F<sub>1</sub></b>(b)
                                </li>
                                <li>
                                    daca neuronului nu i se permite să invete exemplul, este inhibat (nu mai participa la gasirea neuronului cu cel mai mare net,
                                    ce mai mare sumarizare) si un nou neuron este selectat ca si candidat
                                </li>
                                <li>
                                    daca neuronului i se permite să invete exemplul, atunci reteaua intra in faza de învatare si se ponderile se actualizeaza
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>

                <div className={"text-justify"}>
                    <i>Incercare de invatare</i> <br/>
                    O încercare de învățare in ART presupune prezentarea unui exemplu. Dupa ce exemplul e prezentat:
                    <ul>
                        <li>activarile tuturor neuronilor sunt setate la 0</li>
                        <li>toti neuronii din <b>F<sub>2</sub></b> devin inactivi
                        </li>
                        <li>orice neuron din <b>F<sub>2</sub></b> care a fost inhibat intr-o incercare de învățare anterioară devine disponibil</li>
                    </ul>

                    {/*<i>Parametru de vigilenta</i> <br/>*/}
                    {/*Gradul de similaritate necesar pentru plasarea în acelasi cluster a exemplelor prezentate este controlat de un parametrul numit <b>parametru de vigilenta</b>*/}
                    {/*<br/>*/}
                    {/*<br/>*/}
                    Un neuron din <b>F<sub>2</sub></b> poate fi in una dintre aceste stări:
                    <ul>
                        <li>activ: activare = 1, pentru ART1 sau activare = valoare intre 0 si 1 pentru ART2</li>
                        <li>inactiv: activare = 0, dar poate participa la competitie</li>
                        <li>inhibat: activare = 0, nu poate participa la competitie</li>
                    </ul>
                </div>

                <div className={"text-justify"}>
                    <i>Rezonanta</i> <br/>
                    În ART schimbările în activările neuronilor și în ponderi sunt guvernate de <b>ecuații diferențiale cuplate</b>.
                    Rețeaua este un sistem dinamic, adică într-o continua schimbare, dar procesul poate fi simplificat pentru că
                    se presupune că activarile se schimbă mai rapid decât ponderile.
                    În momentul în care un neuron este selectat pentru antrenare, semnalele pentru modificarea ponderilor,
                    sunt mentinute pe o perioada prelungita pana cand ponderile au fost modificate.
                    Aceasta este <b>rezonanta</b> din numele ART.

                    <br/>
                    <br/>
                    <i>Moduri de invatare</i> <br/>
                    Exista 2 moduri de invatare:
                    <ul>
                        <li>
                            <i>invatare rapida</i> (fast learning) (ART1, in special):
                            <br/>
                            actualizările ponderilor (în timpul rezonanței) au loc rapid în raport cu durata procesului de învățare,
                            iar ponderile ating echilibrul în timpul fiecarei incercari de antrenare;
                            si din moment ce activarea neuronilor din <b>F<sub>1</sub></b> nu se schimbă, în timpul <b>fazei de rezonantă</b>,
                            echilibrul ponderilor poate fi determinat exact si solutia iterativa a ecuatilor diferentiale nu este necesara
                        </li>
                        <li><i>invatare lenta</i> (slow learning) (ART2, în special):
                            <br/>
                            actualizările ponderilor apar lent în raport cu durata procesului de învățare
                            iar ponderile nu ajung la un echilibru la fiecare încercare de antrenare;
                        </li>
                    </ul>
                    În cazul învățării lente sunt necesare mai multe prezentarii ale exemplelor decât in cazul învătării rapide.
                    Dar în cazul învățării lente sunt mai puține calcule la fiecare încercare de învătare.
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ArtContent;