import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function AmicMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-amic";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                Calculatorul personal aMIC a fost proiectat si realizat la Catedra de Calculatoare, din Institutul Politehnic Bucuresti de catre prof. Adrian Petrescu si Francisc Iacob, ca model de laborator. In perioada 1983–1984, a fost produs in serie la Fabrica de Memorii din Timisoara

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_33.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_34.png'}/>
                </div>

                Detalii:
                <ul>
                    <li>Producător: Institutul Politehnic Bucuresti (A. Petrescu, F. Iacob) / Fabrica de Memorii Timisoara</li>
                    <li>
                        Tip: Microcalculator personal
                    </li>
                    <li>
                        An lansare :1982
                    </li>
                    <li>
                        Scos din fabricatie :1984
                    </li>
                    <li>
                        Procesor :Z80 (8080 doar ca experiment)
                    </li>
                    <li>
                        Sistem de Operare: BASIC
                    </li>
                    <li>
                        Dimensiuni (L×l×g): 34,6×28×7 cm
                    </li>
                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default AmicMuseumContent;