import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers4LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-4", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Contiunuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Definire Style Books</b>
                    <br/>
                    <br/>

                    Putem crea un Style Book personalizată și putem suprascrie valorile definiției simbolului (definite în Tema clasică ).
                    <br/>
                    Putem defini propriile culori intr-un Style Book personalizat.
                    <br/>
                    Pentru a face acest lucru, trebuie să definim următoarea structură:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-initializer\n' +
                            '└── style-books\n' +
                            '    └── ibrid-style-book\n' +
                            '         ├── frontend-tokens-values.json\n' +
                            '         ├── style-book.json\n' +
                            '         └── thumbnail.png'}
                    </SyntaxHighlighter>

                    Setarile unui <b>style book</b> se fac in fisierul <b>style-book.json</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "defaultStyleBookEntry": true,\n' +
                            '  "frontendTokensValuesPath": "frontend-tokens-values.json",\n' +
                            '  "name": "Ibrid Style Book Entry",\n' +
                            '  "thumbnailPath": "thumbnail.png"\n' +
                            '}'}
                    </SyntaxHighlighter>
                    Cu proprietatea  <b>defaultStyleBookEntry</b>=true <b>Book Style</b> se vor aplica pentru paginile site-ului, in mod implicit!

                    <hr/>
                    In fisierul <i>frontend-tokens-values.json</i> se definesc culorile:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '\t"brandColor1": {\n' +
                            '\t\t"cssVariableMapping": "brand-color-1",\n' +
                            '\t\t"value": "#072252"\n' +
                            '\t},\n' +
                            '\t"brandColor2": {\n' +
                            '\t\t"cssVariableMapping": "brand-color-2",\n' +
                            '\t\t"value": "#2D55A8"\n' +
                            '\t},\n' +
                            '\t"brandColor3": {\n' +
                            '\t\t"cssVariableMapping": "brand-color-3",\n' +
                            '\t\t"value": "#6b6c7e"\n' +
                            '\t},\n' +
                            '\t"brandColor4": {\n' +
                            '\t\t"cssVariableMapping": "brand-color-4",\n' +
                            '\t\t"value": "#30313F"\n' +
                            '\t}\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Numele de token și variabile CSS pot fi preluate din  fișierul <a target={"_blank"} href={"https://github.com/liferay/liferay-portal/blob/master/modules/apps/frontend-theme/frontend-theme-classic/src/WEB-INF/frontend-token-definition.json"}>frontend-token-definition.json</a>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_19.png'}/>
                    </div>

                    Putem folosi culorile noastre personalizate, definite mai sus, din secțiunea <i>Brand Colors</i>.

                    <hr/>
                    <b>Design ~ Style Books</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_20.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_21.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers4LiferayContent;