import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class GitHubActionsDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-github-actions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. GitHub Actions </b>
                    <br/>
                    <br/>

                    O alternativa la Travis CI este <b>GitHub Actions</b>.

                    Pasi de urmat:
                    <ul>
                        <li>
                            se sterge fisierul <b>.travis.yml</b>
                        </li>
                        <li>
                            in directorul radacina al proiectului, se creaza directorul <b>.github</b>

                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'mkdir .github'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            in directorul <b>.github</b>, se creaza directorul <b>workflows</b>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'mkdir workflows'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            in directorul <b>workflows</b>, se creaza fisierul <b>deploy.yaml</b>, cu urmatorul continut:

                            <SyntaxHighlighter  showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'name: Deploy Frontend\n' +
                                    'on:\n' +
                                    '  push:\n' +
                                    '    branches:\n' +
                                    '      - master\n' +
                                    '\n' +
                                    'jobs:\n' +
                                    '  build:\n' +
                                    '    runs-on: ubuntu-latest\n' +
                                    '    steps:\n' +
                                    '      - uses: actions/checkout@v2\n' +
                                    '      - run: docker login -u ${{ secrets.DOCKER_USERNAME }} -p ${{ secrets.DOCKER_PASSWORD }}\n' +
                                    '      - run: docker build -t iulianbuzdugan/docker-react -f Dockerfile.dev .\n' +
                                    '      - run: docker run -e CI=true iulianbuzdugan/docker-react npm test'}
                            </SyntaxHighlighter>
                            comparatie cu <b>.travis.yml</b>
                            <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                                {'sudo: required\n' +
                                    'language: generic\n' +
                                    '\n' +
                                    'services:\n' +
                                    '  - docker\n' +
                                    '\n' +
                                    'before_install:\n' +
                                    '  - docker build -t iulianbuzdugan/docker-react -f Dockerfile.dev .\n' +
                                    '\n' +
                                    'script:\n' +
                                    '  - docker run -e CI=true iulianbuzdugan/docker-react npm run test'}
                            </SyntaxHighlighter>

                            In plus, trebuie specificate in starile de la GitHub, urmatoarele proprietati:
                            <ul>
                                <li>
                                    <b>DOCKER_USERNAME</b> (username de pe <b>dockerhub</b>: iulianbuzdugan)
                                </li>
                                <li>
                                    <b>DOCKER_PASSWORD</b> (parola de pe <b>dockerhub</b>)
                                </li>

                                din <b>Settings</b> {"->"} <b>Secrets</b> {"->"} <b>New repository secret</b>:
                                <div style={{padding:10}}>
                                    <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/docker/img_23.png'}/>
                                </div>

                                vom avea:
                                <div style={{padding:10}}>
                                    <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/docker/img_24.png'}/>
                                </div>

                            </ul>

                        </li>
                    </ul>

                    Se adauga noul fisier pe git
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git commit -m "added github actions file"'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'[master 01c6774] added github actions file\n' +
                            ' 1 file changed, 14 insertions(+)\n' +
                            ' create mode 100644 .github/deploy.yaml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Enumerating objects: 5, done.\n' +
                            'Counting objects: 100% (5/5), done.\n' +
                            'Delta compression using up to 2 threads\n' +
                            'Compressing objects: 100% (3/3), done.\n' +
                            'Writing objects: 100% (4/4), 545 bytes | 545.00 KiB/s, done.\n' +
                            'Total 4 (delta 1), reused 0 (delta 0), pack-reused 0\n' +
                            'remote: Resolving deltas: 100% (1/1), completed with 1 local object.\n' +
                            'To github.com:letyournailsgrownow/docker-react.git\n' +
                            '   e6d55ba..01c6774  master -> master'}
                    </SyntaxHighlighter>

                    In tab-ul <b>Actions</b> vom vedea in partea stanga o actiune cu numele <b>Deploy Frontend</b> (nume definit in fisierul <b>deploy.yaml</b>)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_26.png'}/>
                    </div>

                    Apasand pe workflow ("added github action file fix"):

                    <ul>
                        <li>
                            putem vedea si script-ul (sectiunea <b>Workflow file</b>):
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_25.png'}/>
                            </div>
                        </li>
                        <li>
                            detalii de rulare a scriptului (sectiunea <b>Summary</b>):
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_27.png'}/>
                            </div>
                        </li>
                        <li>
                            timpi de utilizare:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_28.png'}/>
                            </div>
                        </li>
                        <li>
                            detalii de build:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_29.png'}/>
                            </div>

                            se poate intra mai in detaliu pe unii pasi de build:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_30.png'}/>
                            </div>


                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a target={"_blank"} href={"https://github.com/einaregilsson/beanstalk-deploy"}>
                                    Beanstalk Deploy
                                </a>
                            </div>
                        </li>

                    </ol>


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GitHubActionsDockerContent;