import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers3LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-3", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Contiunuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Definire Widget Templates</b>
                    <br/>
                    <br/>

                    Vom crea două șabloane de widget personalizate (Widget Templates) pentru navigarea prin meniu:
                    <ul>
                        <li>
                            Left Navigation (left-navigation)
                        </li>
                        <li>
                            Chapter Navigation (chapters-navigation)
                        </li>
                    </ul>
                    Pentru a le crea cu un inițializator de site - avem nevoie de următoarea structură (group - poate fi optional):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-initializer\n' +
                            '└── ddm-templates\n' +
                            '    └── group\n' +
                            '         ├── chapters-navigation\n' +
                            '         │   ├── ddm-template.ftl\n' +
                            '         │   └── ddm-template.json\n' +
                            '         └── left-navigation\n' +
                            '             ├── ddm-template.ftl\n' +
                            '             └── ddm-template.json'}
                    </SyntaxHighlighter>

                    Setarile pentru un <b>widget template</b> se fac in fisierul <b>ddm-template.json</b>.
                    <br/>
                    Codul pentru un  <b>widget template</b> se fac in fisierul <b>ddm-template.ftl</b>.

                    <hr/>
                    Dupa build (si sincronizare):
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_18.png'}/>
                    </div>

                    <hr/>
                    Exemplu <i>ddm-template.json</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '\t"className": "com.liferay.portal.kernel.theme.NavItem",\n' +
                            '\t"ddmTemplateKey": "CHAPTER_NAV",\n' +
                            '\t"name": "Chapters Navigation",\n' +
                            '\t"resourceClassName": "com.liferay.portlet.display.template.PortletDisplayTemplate"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu <i>ddm-template.ftl</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<#include "${templatesPath}/NAVIGATION-MACRO-FTL" />\n' +
                            '\n' +
                            '<style>\n' +
                            '    #navbar_${portletDisplay.getId()} .sections-wrapper {\n' +
                            '        display: flex;\n' +
                            '        flex-wrap: wrap;\n' +
                            '    }\n' +
                            '\n' +
                            '    #navbar_${portletDisplay.getId()} .section-card {\n' +
                            '        border: 1px solid rgba(9,16,24,.12);\n' +
                            '        border-radius: .25rem;\n' +
                            '        box-shadow: 0 6px 15px -6px rgb(9 16 29);\n' +
                            '        cursor: pointer;\n' +
                            '        margin-left: 1rem;\n' +
                            '        margin-right: .5rem;\n' +
                            '        margin-top: 1.5rem;\n' +
                            '        padding: 1.25rem 1.5rem;\n' +
                            '        transition: transform .2s ease;\n' +
                            '        width: 45%;\n' +
                            '    }\n' +
                            '\n' +
                            '    #navbar_${portletDisplay.getId()} .section-card:hover {\n' +
                            '        border-bottom: 3px solid #0b5fff;\n' +
                            '        box-shadow: 0 6px 11px 0 rgb(9 16 29);\n' +
                            '        margin-bottom: -3px;\n' +
                            '        text-decoration: none;\n' +
                            '        transform: translateY(-4px);\n' +
                            '    }\n' +
                            '\n' +
                            '    #navbar_${portletDisplay.getId()} .section-card a h4 {\n' +
                            '        text-decoration: none;\n' +
                            '    }\n' +
                            '    #navbar_${portletDisplay.getId()} .section-card a h4 {\n' +
                            '        color: #09101d;\n' +
                            '        border-left: 3px solid #42bfc2;\n' +
                            '        margin-left: -1.5rem;\n' +
                            '        padding-left: 1.25rem;\n' +
                            '        font-size: 1.125rem;\n' +
                            '        margin-bottom: .5rem;\n' +
                            '    }\n' +
                            '\n' +
                            '    #navbar_${portletDisplay.getId()} ul.subsection {\n' +
                            '        margin-top: 10px;\n' +
                            '        padding: 0;\n' +
                            '    }\n' +
                            '    #navbar_${portletDisplay.getId()} ul.subsection li {\n' +
                            '        list-style-type: none;\n' +
                            '    }\n' +
                            '</style>\n' +
                            '\n' +
                            '<#if !entries?has_content>\n' +
                            '    <#if themeDisplay.isSignedIn()>\n' +
                            '        <div class="alert alert-info">\n' +
                            '            <@liferay.language key="there-are-no-menu-items-to-display" />\n' +
                            '        </div>\n' +
                            '    </#if>\n' +
                            '<#else>\n' +
                            '\n' +
                            '    <#assign isLayoutFound = false />\n' +
                            '    <#assign layoutNavItem = "" />\n' +
                            '\n' +
                            '    <#assign friendlyUrl = themeDisplay.getLayoutFriendlyURL(themeDisplay.getLayout()) />\n' +
                            '\n' +
                            '    <#assign navItems = entries />\n' +
                            '    <#list navItems as nav_item>\n' +
                            '        <#if nav_item.getURL()?ends_with(friendlyUrl)>\n' +
                            '            <#assign isLayoutFound = true />\n' +
                            '            <#assign layoutNavItem = nav_item />\n' +
                            '        </#if>\n' +
                            '        <#if !isLayoutFound>\n' +
                            '            <#assign isLayoutFound = true />\n' +
                            '            <#assign layoutNavItem = nav_item />\n' +
                            '        </#if>\n' +
                            '        <#if nav_item.hasBrowsableChildren()>\n' +
                            '            <#list nav_item.getChildren() as nav_child>\n' +
                            '                <#if nav_child.getURL()?ends_with(friendlyUrl)>\n' +
                            '                    <#assign isLayoutFound = true />\n' +
                            '                    <#assign layoutNavItem = nav_child />\n' +
                            '                </#if>\n' +
                            '                <#if nav_child.hasBrowsableChildren()>\n' +
                            '                    <#list nav_child.getChildren() as nav_sub_child>\n' +
                            '                        <#if nav_sub_child.getURL()?ends_with(friendlyUrl)>\n' +
                            '                            <#assign isLayoutFound = true />\n' +
                            '                            <#assign layoutNavItem = nav_sub_child />\n' +
                            '                        </#if>\n' +
                            '                    </#list>\n' +
                            '                </#if>\n' +
                            '            </#list>\n' +
                            '        </#if>\n' +
                            '    </#list>\n' +
                            '\n' +
                            '    <#assign\n' +
                            '    portletDisplay = themeDisplay.getPortletDisplay()\n' +
                            '    navbarId = "navbar_" + portletDisplay.getId()\n' +
                            '    />\n' +
                            '\n' +
                            '    <div id="${navbarId}">\n' +
                            '        <#if layoutNavItem.hasBrowsableChildren()>\n' +
                            '            <div class="sections-wrapper">\n' +
                            '                <#list layoutNavItem.getChildren() as nav_child>\n' +
                            '                    <div class="section-card">\n' +
                            '                        <div class="autofit-row autofit-row-center">\n' +
                            '                            <div class="autofit-col autofit-col-expand">\n' +
                            '                                <a href="${nav_child.getURL()}">\n' +
                            '                                    <h4 class="sidebar title">${nav_child.getName()}</h4>\n' +
                            '                                </a>\n' +
                            '                                <#if nav_child.hasBrowsableChildren()>\n' +
                            '                                    <ul class="subsection">\n' +
                            '                                        <#list nav_child.getChildren() as nav_sub_child>\n' +
                            '                                            <li>\n' +
                            '                                                <a href="${nav_sub_child.getURL()}">\n' +
                            '                                                    ${nav_sub_child.getName()}\n' +
                            '                                                </a>\n' +
                            '                                            </li>\n' +
                            '                                        </#list>\n' +
                            '                                    </ul>\n' +
                            '                                </#if>\n' +
                            '                            </div>\n' +
                            '                        </div>\n' +
                            '                    </div>\n' +
                            '                </#list>\n' +
                            '            </div>\n' +
                            '        </#if>\n' +
                            '    </div>\n' +
                            '</#if>'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers3LiferayContent;