import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function CIP03Rosu21MuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-cip03-21";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>


                <hr/>
                <b>CIP 03 - fara multe taste [20-215193]</b>
                <br/>
                <br/>

                Calculatorul:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_18.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_19.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_20.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_21.png'}/>
                </div>

                Alimentator:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_11.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_12.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_13.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_14.png'}/>
                </div>

                Testare:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_7.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_8.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_9.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_10.png'}/>
                </div>

                Observatii:
                <ul>
                    <li>lipsesc multe taste</li>
                </ul>

                Mod testare:
                <ul>
                    <li>
                        Cablu TV mufat m+m 90GR 1.5M alb
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-4/img_28.png'}/>
                        </div>
                    </li>
                    <li>
                        In loc de alimentatorul original, se poate folosi si <b>Alimentator universal 230V/3-12VDC 1500mA, Goobay 53997</b>, pus la <b>5V</b>:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-4/img_29.png'}/>
                        </div>
                        cu <b>3.5mm pawl</b>
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-4/img_30.png'}/>
                        </div>

                        Nu are nimic dacă folosești un alt alimentator cu 5V și 1.5A. Este perfect sigur și nu va afecta calculatorul tău, deoarece:
                        <ul>
                            <li>
                                Tensiunea (5V) este aceeași, ceea ce este esențial pentru a evita daunele la dispozitiv.
                            </li>
                            <li>
                                Amperajul (1.5A) reprezintă capacitatea maximă a alimentatorului de a furniza curent. Dispozitivul tău va consuma doar cât are nevoie (1.3A), deci
                                alimentatorul de 1.5A este mai mult decât suficient.
                            </li>
                        </ul>
                        Important este ca tensiunea să fie aceeași (5V) și mufa să fie compatibilă, atât ca dimensiune, cât și ca polaritate (+ și -). Dacă aceste condiții sunt
                        îndeplinite, poți folosi alimentatorul fără probleme.
                    </li>
                    <li>
                        Convertor Rf la HDMI:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-4/img_27.png'}/>
                        </div>

                    </li>
                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default CIP03Rosu21MuseumContent;