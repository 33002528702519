import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class KeycloakSLOLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-keycloak-slo", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Implementarea Single Logout (SLO)</b>
                    <br/>
                    Implementarea a OpenID Connect pe <b>Liferay versiunea 7.3 nu acceptă funcționalitatea SLO sau Single Logout</b>.
                    <br/>
                    <br/>
                    Aceasta înseamnă că atunci când un utilizator este deconectat de la Liferay (operația de deconectare sau sesiune a expirat), acesta nu este deconectat automat de la Keycloak, cu consecința că un nou acces nu va necesita introducerea acreditărilor utilizatorului pe Keycloak (dacă sesiunea este încă activă).
                    <br/>
                    <br/>
                    Altfel spus, dacă vă deconectați din Liferay si apoi încercați să vă conectați din nou, atunci veți vedea că utilizatorul se va conecta automat, fără a introduce acreditările în formularul de conectare Keycloak.
                    <br/>
                    Acest lucru se întâmplă deoarece deconectarea de la Liferay nu implică deconectarea de la Keycloak, deoarece Keycloak se folosete pentru autorizare.
                    <br/>
                    <br/>
                    <b>Scop</b>: Vrem să folosim Keycloak și pentru deconectare: odată ce un utilizator se deconectează în Liferay, ar trebui să fie deconectat și de la furnizorul de autorizare (Keycloak).
                    <br/>Aceasta se numește <b>Single Logout (SLO)</b>.
                    <br/>
                    <br/>Dar nu este acceptat în configurația Liferay. Cu toate acestea, poate fi implementat cu o acțiune personalizată de post logout.
                    <br/>

                    Pentru aceasta vom creea un modul nou în spațiul de lucru Liferay cu o clasă KeycloakLogoutPostAction.
                    <br/>
                    Structura fișierelor modulului:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-logout-2.png'}/>
                    </div>

                    Fisier <b>bnd.bnd</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="bnd" style={androidstudio}>
                        {'Bundle-Name: Keycloak Logout Filter\n' +
                        'Bundle-SymbolicName: ro.letyournailsgrow.liferay.vanilla.common.keycloak.logout.filter\n' +
                        'Bundle-Version: 1.0.0'}
                    </SyntaxHighlighter>

                    Fisier <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                        {'dependencies {\n' +
                        '    compileOnly group: "com.liferay.portal", name: "release.portal.api", version: "7.3.6-ga7"\n' +
                        '    // https://mvnrepository.com/artifact/com.liferay/com.liferay.portal.security.sso.openid.connect.impl\n' +
                        '    implementation group: \'com.liferay\', name: \'com.liferay.portal.security.sso.openid.connect.impl\', version: \'5.0.30\'\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Fisier <b>KeycloakLogoutPostAction</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.letyournailsgrow.liferay.vanilla.common.keycloak.logout.filter;\n' +
                        '\n' +
                        'import com.liferay.portal.kernel.events.ActionException;\n' +
                        'import com.liferay.portal.kernel.events.LifecycleAction;\n' +
                        'import com.liferay.portal.kernel.events.LifecycleEvent;\n' +
                        'import com.liferay.portal.kernel.json.JSONFactoryUtil;\n' +
                        'import com.liferay.portal.kernel.json.JSONObject;\n' +
                        'import com.liferay.portal.kernel.log.Log;\n' +
                        'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                        'import com.liferay.portal.kernel.util.Portal;\n' +
                        'import com.liferay.portal.kernel.util.PrefsProps;\n' +
                        'import com.liferay.portal.kernel.util.PropsKeys;\n' +
                        'import com.liferay.portal.kernel.util.StringUtil;\n' +
                        'import com.liferay.portal.security.sso.openid.connect.OpenIdConnectProvider;\n' +
                        'import com.liferay.portal.security.sso.openid.connect.OpenIdConnectProviderRegistry;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        'import javax.portlet.PortletPreferences;\n' +
                        'import javax.servlet.http.HttpServletRequest;\n' +
                        'import javax.servlet.http.HttpServletResponse;\n' +
                        'import java.util.Collection;\n' +
                        '\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = "key=logout.events.post",\n' +
                        '        service = LifecycleAction.class\n' +
                        ')\n' +
                        'public class KeycloakLogoutPostAction implements LifecycleAction {\n' +
                        '\n' +
                        '    private static final Log log = LogFactoryUtil.getLog(KeycloakLogoutPostAction.class);\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private Portal portal;\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private PrefsProps prefsProps;\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private OpenIdConnectProviderRegistry openIdConnectProviderRegistry;\n' +
                        '\n' +
                        '    @Override\n' +
                        '    public void processLifecycleEvent(LifecycleEvent lifecycleEvent) throws ActionException {\n' +
                        '        try {\n' +
                        '            HttpServletRequest request = lifecycleEvent.getRequest();\n' +
                        '            HttpServletResponse response = lifecycleEvent.getResponse();\n' +
                        '\n' +
                        '            long companyId = portal.getCompanyId(request);\n' +
                        '\n' +
                        '            Collection<String> openIdConnectProviderNames =\n' +
                        '                    openIdConnectProviderRegistry.getOpenIdConnectProviderNames(companyId);\n' +
                        '            if (openIdConnectProviderNames == null || openIdConnectProviderNames.isEmpty()) {\n' +
                        '                log.warn("No OpenID Connect Providers found.");\n' +
                        '                return;\n' +
                        '            }\n' +
                        '            String openIdConnectProviderName = openIdConnectProviderNames.iterator().next();\n' +
                        '            OpenIdConnectProvider openIdConnectProvider =\n' +
                        '                    openIdConnectProviderRegistry.getOpenIdConnectProvider(companyId, openIdConnectProviderName);\n' +
                        '            Object oidcProviderMetadata = openIdConnectProvider.getOIDCProviderMetadata();\n' +
                        '            String oidcJson = oidcProviderMetadata.toString();\n' +
                        '            JSONObject oidcJsonObject = JSONFactoryUtil.createJSONObject(oidcJson);\n' +
                        '            Object authEndpoint = oidcJsonObject.get("authorization_endpoint");\n' +
                        '            String authEndpointUrl = authEndpoint.toString();\n' +
                        '            String logoutEndpoint = StringUtil.replaceLast(authEndpointUrl, "/auth", "/logout");\n' +
                        '            String redirectUri = getRedirectUrl(request);\n' +
                        '            String logoutUrl = logoutEndpoint + "?redirect_uri=" + redirectUri;\n' +
                        '            response.sendRedirect(logoutUrl);\n' +
                        '        } catch (Exception e) {\n' +
                        '            log.error("Error in KeycloakLogoutPostAction: " + e.getMessage(), e);\n' +
                        '        }\n' +
                        '    }\n' +
                        '\n' +
                        '    private String getRedirectUrl(HttpServletRequest request) {\n' +
                        '        String portalURL = portal.getPortalURL(request);\n' +
                        '        long companyId = portal.getCompanyId(request);\n' +
                        '        PortletPreferences preferences = prefsProps.getPreferences(companyId);\n' +
                        '        String logoutPath =  prefsProps.getString(preferences, PropsKeys.DEFAULT_LOGOUT_PAGE_PATH);\n' +
                        '        return portalURL + logoutPath;\n' +
                        '    }\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Dupa logare, putem vedea sesiunile in <b>Keycloak</b>:
                    <div style={{padding:10}}>
                        <img alt={""}  style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-logout-1.png'}/>
                    </div>
                    Folosind filtrul de mai sus, atunci cand utilizatorul se va deloga, se va sterge sesiunea nu doar din <b>Liferay</b>, ci si din <b>Keycloak</b>.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://techblog.smc.it/en/2021-10-15/how-to-connect-keycloak-liferay-openid-connect"}>
                                    How to connect Keycloak and Liferay via OpenID Connect
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360024805271-Authenticating-with-OpenID-Connect"}>
                                    Authenticating with OpenID Connect
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://www.aimprosoft.com/blog/liferay-sso-integration/"}>
                                    How to Implement Keycloak SSO Authentication in Liferay DXP
                                </a>
                            </div>
                        </li>

                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://lifedev-solutions.blogspot.com/2019/10/liferay-keycloak-integration-using.html"}>*/}
                        {/*            Liferay Keycloak integration using OpenID*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default KeycloakSLOLiferayContent;