import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class StructuredOutputSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-structured-output-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <hr/>
                    <b>Returnare date structurate</b>
                    <br/>
                    <br/>

                    1. Returnare lista de String-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' public List<String> getCityList(){\n' +
                            '        String message = "Zi-mi 5 orase din Romania";\n' +
                            '\n' +
                            '        return chatClient.prompt()\n' +
                            '                .user(message)\n' +
                            '                .call()\n' +
                            '                .entity(new ListOutputConverter(new DefaultConversionService()));\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    poate returna:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    "București",\n' +
                            '    "Cluj-Napoca",\n' +
                            '    "Timișoara",\n' +
                            '    "Iași",\n' +
                            '    "Constanța"\n' +
                            ']'}
                    </SyntaxHighlighter>

                    <hr/>
                    2. Returnare mapa:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    public Map<String, String> getCityLMap(){\n' +
                            '        String message = "give me the names of tp five cities in us and their capitals";\n' +
                            '\n' +
                            '        return chatClient.prompt()\n' +
                            '                .user(message)\n' +
                            '                .call()\n' +
                            '                .entity(new ParameterizedTypeReference<Map<String, String>>() {});\n' +
                            '    }\n'}
                    </SyntaxHighlighter>

                    poate returna:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '    "New York City": "Albany",\n' +
                            '    "Los Angeles": "Sacramento",\n' +
                            '    "Chicago": "Springfield",\n' +
                            '    "Houston": "Austin",\n' +
                            '    "Phoenix": "Phoenix"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    3. Returnare obiect
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Override\n' +
                            '    public FoodResponse getFoods(String country, String numFoods) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate("Esti un expert in mancare traditionala.\\n" +\n' +
                            '                "Raspunde la urmatoarea intrabare: Care este mancarea traditionala in {country}?\\n" +\n' +
                            '                "Returneza o lista de {numFoods}.\\n");\n' +
                            '        \n' +
                            '        Prompt prompt = promptTemplate.create(Map.of("country", country, "numFoods", numFoods));\n' +
                            '\n' +
                            '        return chatClient.prompt(prompt).call().entity(FoodResponse.class);\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    cu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            'public record FoodResponse(String country, List<String> numFoods) {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Halucinatie:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_12.png'}/>
                    </div>

                    Pentru a evita halucinatiile se pot adauga restictii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Override\n' +
                            '    public FoodResponse getFoods(String country, String numFoods) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate("Esti un expert in mancare traditionala.\\n" +\n' +
                            '                "Raspunde la urmatoarea intrabare: Care este mancarea traditionala in {country}?\\n" +\n' +
                            '                "Returneza o lista de {numFoods}. Evita tarile care nu exista. \\n"+\n' +
                            '                "Oferiți informații despre un anumit fel de mâncare dintr-o anumită țară.\\n"+\n' +
                            '                "Evitați să oferiți informații despre locuri fictive.\\n" +\n' +
                            '                "Dacă țara este fictivă sau inexistentă, întoarceți țara fără mancare.");\n' +
                            '        \n' +
                            '        Prompt prompt = promptTemplate.create(Map.of("country", country, "numFoods", numFoods));\n' +
                            '\n' +
                            '        return chatClient.prompt(prompt).call().entity(FoodResponse.class);\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    4. Returnare lista de obiecte:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' public List<Movie> getMovies(){\n' +
                            '        String regizor = "Sergiu Nicolaescu";\n' +
                            '        String message = "Genereaza o lista cu filmeele regizorului {regizor}. Fiecare film va include titlul si anul cand a fost lansat. {format}";\n' +
                            '\n' +
                            '        return chatClient.prompt()\n' +
                            '                .user(\n' +
                            '                        promptUserSpec -> promptUserSpec.text(message)\n' +
                            '                                .param("regizor",regizor)\n' +
                            '                                .param("format", "json")\n' +
                            '                )\n' +
                            '                .call()\n' +
                            '                .entity(new ParameterizedTypeReference<List<Movie>>() {});\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'public record Movie(String title, String year) {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    posibil raspuns:
                    <SyntaxHighlighter>
                        {'[\n' +
                            '    {\n' +
                            '        "title": "Dacii",\n' +
                            '        "year": "1967"\n' +
                            '    },\n' +
                            '    {\n' +
                            '        "title": "Mihai Viteazul",\n' +
                            '        "year": "1971"\n' +
                            '    }\n' +
                            ']'}
                    </SyntaxHighlighter>
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default StructuredOutputSpringIALlmContent;