import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AdvisorsSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-advisors-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    In mod implicit, dialogul se desfasoara fara memorie, in sensul ca nu se tine cont de discutia dinainte, fiecare intrabare avand un raspuns indepenedent.

                    Pentru a lua in considerare discutiile anterioare, se folosesc <b>advisors</b>.
                    <br/>
                    Pentru a construi un <i>chatClient</i> cu <i>advisor</i> se foloseste: <b>MessageChatMemoryAdvisor</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '  this.chatClient = builder\n' +
                            '                .defaultAdvisors(new MessageChatMemoryAdvisor(new InMemoryChatMemory()))\n' +
                            '                .build();'
                        }
                    </SyntaxHighlighter>

                    sau:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '  this.chatClient = builder\n' +
                            '                .defaultAdvisors(new PromptChatMemoryAdvisor(new InMemoryChatMemory()))\n' +
                            '                .build();'
                        }
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AdvisorsSpringIALlmContent;