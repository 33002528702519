import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HC91MuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-hc91";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>
                    HC 91
                </b>
                <br/>
                <br/>
                Calculatorul personal HC 91 a fost produs de I.C.E. Felix incepand cu 1991.
                <br/>

                Detalii:
                <ul>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: Z80A avand viteza de 3,5 Mhz
                    </li>
                    <li>
                        Memoria ROM: 16 kB
                    </li>
                    <li>
                        Memoria RAM: 64 kB (din care, 48 erau disponibili pt utilizator)
                    </li>
                    <li>
                        Sistem de operare: BASIC 48K (Spectrum Basic interpreter)
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 34 (lățime) X 25 (lungime) X 4 (înălțime) cm
                    </li>
                </ul>
                Porturi:
                <ul>
                    <li>
                        TV/RF (RCA-F)
                    </li>
                    <li>
                        RGB (DB9-F)
                    </li>
                    <li>
                        Joystick (DB9-M)
                    </li>
                    <li>
                        Slot extensie compatibil ZX Spectrum
                    </li>
                    <li>
                        Conector casetofon (DIN-5)
                    </li>
                    <li>
                        Conector alimentare
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_25.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_26.png'}/>
                </div>

                Calculatorul a fost vandut cu 2 variante de tastatura:
                <ul>
                    <li>40 de taste, preluata de la modele precedente (varianta cea mai raspandita)</li>
                    <li>
                        50 de taste, preluata si de HC91+
                        <br/>
                        Tastatura extinsa avea urmatoarele taste:
                        <ul>
                            <li>
                                EDIT
                            </li>
                            <li>
                                TAB
                            </li>
                            <li>
                                CAPS LOCK (CL)
                            </li>
                            <li>
                                CAPS SHIFT (CS)
                            </li>
                            <li>
                                EXT MODE
                            </li>
                            <li>
                                DELETE
                            </li>
                            <li>
                                RETURM
                            </li>
                            <li>
                                GRAPH
                            </li>
                            <li>
                                SYMBOL SHIFT (SS)
                            </li>
                            <li>
                                sagetile: SUS, JOS, DREAPTA, STANGA
                            </li>
                        </ul>
                    </li>
                </ul>

                <hr/>
                <b>Serie No / Part No: 44187</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 800}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_27.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 800}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_28.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 800}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_29.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_30.png'}/>
                </div>

                Stare: functional

                <br/>
                <br/>
                Cabluri:
                <ul>
                    <li>
                        legatura cu monitor vechi: cablu video (<b>DB-9</b> (D-sub cu 9 pini) - Cablu DB9 tata DB9 tata - RS232 )

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-2/img_25.png'}/>
                        </div>
                        sau: Cablu serial RS-232 DB9 T-T, dar nu acesta, pentru ca are piulite:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-2/img_32.png'}/>
                        </div>
                        trebuie cu suruburi:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-2/img_33.png'}/>
                        </div>

                        Observatii:
                        <ul>
                            <li>
                                Interfata seriala standard RS-232 permite conectarea unui HC cu alt calculator sau alte
                                periferice înzestrate cu aceasta interfata.
                            </li>
                            <li>
                                Conectorul VIDEO este o mufa RACK 9 contacte mama. Acesta permite cuplarea
                                unui monitor alb/negru sau color, <b>PAL</b>, pe un semnal video complex <b>(pin.7)</b> sau a unui
                                monitor RGB.
                            </li>
                            <li>
                                Conector VIDEO:
                                <ul>
                                    <li> 1.SHVL</li>
                                    <li> 2.MASA (GND)</li>
                                    <li> 3.R (rosu)</li>
                                    <li> 4.G (verde)</li>
                                    <li> 5.B (albastru)</li>
                                    <li> 6.BRGH. (intensitate)</li>
                                    <li> 7.VIDEOOUT</li>
                                    <li> 8.Hsync.</li>
                                    <li> 9.Vsync.</li>
                                </ul>
                            </li>
                        </ul>

                    </li>
                    <li>
                        legatura cu tv: cablu RCA - RF

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_68.png'}/>
                        </div>

                    </li>

                    <li>
                        legatura cu monitor modern: convetor RF to HDMI + cablu RCA - RF

                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum-2/img_31.png'}/>
                        </div>
                    </li>
                </ul>

                Alimentator:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_92.png'}/>
                </div>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_93.png'}/>
                </div>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_94.png'}/>
                </div>

                CONECTOR ALIMENTARE:
                <ul>
                    <li>
                        1,4.a si 1,4.b - +9Volti
                    </li>
                    <li>
                        2,3.a si 2,3.b - GND (masa)
                    </li>
                </ul>

                Testare:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_34.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_35.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_36.png'}/>
                </div>



            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HC91MuseumContent;