import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MongoSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-mongo-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>MongoDB Atlas Vector Search</b>
                    <br/>
                    <br/>

                    Se poate configura in Google Cloud.

                    Se adauga dependinta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'implementation \'org.springframework.ai:spring-ai-mongodb-atlas-store-spring-boot-starter\''}
                    </SyntaxHighlighter>

                    Se adauga configurarile:
                    <SyntaxHighlighter>
                        {'spring.data.mongodb.uri=mongodb+srv://username:password@cluster0.2ljun.mongodb.net/?retryWrites=true&w=majority&appName=Cluster0\n' +
                            'spring.data.mongodb.database=springaivectordb\n' +
                            '\n' +
                            'spring.ai.vectorstore.mongodb.collection-name=vector_store\n' +
                            'spring.ai.vectorstore.mongodb.initialize-schema=true\n' +
                            'spring.ai.vectorstore.mongodb.path-name=embedding\n' +
                            'spring.ai.vectorstore.mongodb.indexName=vector_index'}
                    </SyntaxHighlighter>

                    Configurare Bean:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Bean\n' +
                            '    public VectorStore mongodbVectorStore(MongoTemplate mongoTemplate, EmbeddingModel embeddingModel) {\n' +
                            '        return new MongoDBAtlasVectorStore(mongoTemplate, embeddingModel,\n' +
                            '                MongoDBAtlasVectorStore.MongoDBVectorStoreConfig.builder().build(), true);\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Incarcare si cautare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'  @Value("classpath:data.txt")\n' +
                            '    Resource resource;\n' +
                            '\n' +
                            '    @GetMapping("/load")\n' +
                            '    public String load() throws IOException {\n' +
                            '\n' +
                            '        try (BufferedReader reader = new BufferedReader(new InputStreamReader(resource.getInputStream()))) {\n' +
                            '            List<Document> docs = reader.lines()\n' +
                            '                    .map(line -> new Document(line))\n' +
                            '                    .collect(Collectors.toList());\n' +
                            '\n' +
                            '            logger.info("Loaded documents: {}", docs);\n' +
                            '\n' +
                            '       /* List<Document> docs = List.of(\n' +
                            '                new Document("Proper tuber planting involves site selection, timing, and care. Choose well-drained soil and adequate sun exposure. Plant in spring, with eyes facing upward at a depth two to three times the tuber\'s height. Ensure 4-12 inch spacing based on tuber size. Adequate moisture is needed, but avoid overwatering. Mulching helps preserve moisture and prevent weeds.", Map.of("author", "A", "type", "post")),\n' +
                            '                new Document("Successful oil painting requires patience, proper equipment, and technique. Prepare a primed canvas, sketch lightly, and use high-quality brushes and oils. Paint \'fat over lean\' to prevent cracking. Allow each layer to dry before applying the next. Clean brushes often and work in a well-ventilated space.", Map.of("author", "A")),\n' +
                            '                new Document("For a natural lawn, select the right grass type for your climate. Water 1 to 1.5 inches per week, avoid overwatering, and use organic fertilizers. Regular aeration helps root growth and prevents compaction. Practice natural pest control and overseeding to maintain a dense lawn.", Map.of("author", "B", "type", "post")));\n' +
                            '   */\n' +
                            '            TextSplitter splitter = new TokenTextSplitter();\n' +
                            '            docs.forEach(doc -> {\n' +
                            '                List<Document> splitteddcs = splitter.split(doc);\n' +
                            '                vectorStore.add(splitteddcs);\n' +
                            '                try {\n' +
                            '                    TimeUnit.MILLISECONDS.sleep(20000);\n' +
                            '                    logger.info("Added document: {}", doc);\n' +
                            '                } catch (InterruptedException e) {\n' +
                            '                    throw new RuntimeException(e);\n' +
                            '                }\n' +
                            '            });\n' +
                            '            return "Loading AI Model";\n' +
                            '        }\n' +
                            '   }\n' +
                            '   /* @GetMapping("/search")\n' +
                            '    public String search() {\n' +
                            '        List<Document> results = vectorStore.similaritySearch(\n' +
                            '                SearchRequest\n' +
                            '                        .query("learn how to grow things")\n' +
                            '                        .withTopK(2)\n' +
                            '        );\n' +
                            '        return results.toString();\n' +
                            '    }*/\n' +
                            '\n' +
                            '        @GetMapping("/search")\n' +
                            '        public List<String> searchDocuments (@RequestParam(value = "query", defaultValue = "learn how to grow things") String query ){\n' +
                            '            List<Document> results = vectorStore.similaritySearch(\n' +
                            '                    SearchRequest\n' +
                            '                            .query(query)\n' +
                            '                            .withTopK(2)\n' +
                            '            );\n' +
                            '          return results.stream().map(document -> document.getContent()).collect(Collectors.toList());\n' +
                            '        }'}
                    </SyntaxHighlighter>


                </div>
                <br/>
                <div className={"text-justify"}>
                {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MongoSpringIALlmContent;