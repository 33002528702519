import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class InstallKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-user-admin", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Crearea utilizator de tip administrator</b>
                    <br/>
                    Keycloak nu vine cu un utilizator de administrare implicit, ceea ce înseamnă că înainte de a putea începe să utilizați Keycloak trebuie să creați un utilizator de administrare.
                    Pentru a face acest lucru, deschideți <a href={"http://localhost:8180/auth"}> <b>http://localhost:8180/auth</b></a>, apoi completați formularul cu numele de utilizator și parola preferata.

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-2.png'}/>
                    </div>

                    Si se apasa pe butonul <b>Create</b>.

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-3.png'}/>
                    </div>

                    <hr/>

                    <b>Verificare: Conectare la consola de administrare</b>
                    <br/>
                    Se apasa pe link-ul <b>Administration Console</b> sau <a href={"http://localhost:8080/auth/admin/ "}>http://localhost:8080/auth/admin/ </a>.
                    <br/>
                    Apoi va conectati cu numele de utilizator și parola pe care le-ați creat mai devreme:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-4.png'}/>
                    </div>

                    Dupa conectare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-5.png'}/>
                    </div>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>


            </div>
        );
    }
}

export default InstallKeycloakContent;