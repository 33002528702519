import React from "react";
import TocHelper from "../TocHelper";
import MuseumToc from "./MuseumToc";

function IndexContent(props) {

    return (
        <div className="home index index-page">

            <div className={"title"}>
                Muzeu
            </div>

            {TocHelper.display(new MuseumToc().getContent())}

            <br/>
        </div>
    );

}

export default IndexContent;