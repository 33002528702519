import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function CIP03RosuMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-cip03-rosu";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>CIP 03 - ROSU</b>
                <br/>
                <br/>

                Calculatorul:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_35.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_36.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_37.png'}/>
                </div>

                Testare:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_33.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_34.png'}/>
                </div>


            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default CIP03RosuMuseumContent;