import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AmazonBedrockISpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-amazon-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Amazon Bedrock este un serviciu complet gestionat oferit de Amazon Web Services (AWS),
                    conceput pentru a facilita dezvoltarea și scalarea aplicațiilor de inteligență artificială generativă.
                    Acesta oferă acces la o varietate de modele fundamentale de înaltă performanță de la companii de top în domeniul AI,
                    precum AI21 Labs, Anthropic, Cohere, Meta, Mistral AI, Stability AI, precum și modele proprii dezvoltate de Amazon, toate printr-un singur API.

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AmazonBedrockISpringIALlmContent;