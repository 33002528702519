import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class TermsOfUseLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-terms-of-use", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Terms of use / Termeni de utilizare</b>
                    <br/>
                    <br/>

                    Din UI, configurarea se face din:
                    <SyntaxHighlighter>
                        {'Control Panel -> Instance Settings -> (sectiunea) Platform -> Instance Configuration -> Terms of use'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_3.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_4.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_5.png'}/>
                    </div>

                    Trebuie specificat:
                    <ul>
                        <li>
                            Web Content Article (articleId) a unui Site (groupId)
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_9.png'}/>
                    </div>

                    <hr/>
                    <b>ID Articol</b>
                    <br/>
                    <br/>

                    Pentru a găsi ID-ul unui articol de conținut web (se creaza sau este deja creat):
                    <SyntaxHighlighter>
                        {'Meniul STANGA → Content & Data → Web Content'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_7.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_8.png'}/>
                    </div>

                    <hr/>
                    <b>ID Grup</b>
                    <br/>
                    <br/>

                    ID-ul grupului este ID-ul site-ului cu care este asociat conținutul web. Pentru a găsi ID-ul grupului/site-ului:

                    <SyntaxHighlighter>
                        {'Meniul STANGA → Configuration → Site Settings'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_6.png'}/>
                    </div>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://help.liferay.com/hc/en-us/articles/360018174711-Terms-of-Use"}>
                                   Terms of use
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TermsOfUseLiferayContent;