import React, {useState} from "react";

import {oldBooks as obooks} from "./data/old-books";
import ImageDialog from "../../component/ImageDialog";
import TitleFragment from "../TitleFragment";
import NavigatorFragment from "../NavigatorFragment";
import MuseumToc from "../museum/MuseumToc";

function ITOldBooksContent() {

    let toc = new MuseumToc();

    let pageId = "books-it-old";

    const [searchTerm, setSearchTerm] = useState('');

    const books = obooks.carti.carte;

    const filteredBooks = books.filter((book) => {
        const title = book.titlu.toLowerCase();
        const anAparitie = book.anAparitie.toLowerCase();
        const editura = book.editura.toLowerCase();

        const authorList = book.autori.autor;

        let author = "";
        if (Array.isArray(author)) {
            author = authorList[0];
        } else {
            author = authorList;
        }

        let okTitle = title.includes(searchTerm.toLowerCase());
        let okAnAparitie = anAparitie.includes(searchTerm.toLowerCase());
        let okEditura = editura.includes(searchTerm.toLowerCase());
        let okAuthors = false;
        if (Array.isArray(author)) {
            okAuthors = authorList.some(a => a._nume.toLowerCase().includes(searchTerm))
        } else {
            okAuthors = authorList._nume.includes(searchTerm.toLowerCase())
        }

        return okTitle || okAuthors || okAnAparitie || okEditura;

    });

    const renderAutori = (book) => {
        const authorList = book.autori.autor;
        if (Array.isArray(authorList)) {
            return authorList.map((autorObj, index) => (
                <li key={index}>{autorObj._nume}</li>
            ));
        } else if (authorList && authorList._nume) {
            return <li>{authorList._nume}</li>;
        } else {
            return <li>-</li>;
        }
    };

    const renderCuprins = (book) => {
        const list = book.cuprins.capitol;
        if (Array.isArray(list)) {
            return list.map((item, index) => (
                <div key={index}>{item._nr}. {item.__text}</div>
            ));
        } else if (list && list._nr) {
            return <div>{list._nr}. {list.__text}</div>;
        } else {
            return <div>-</div>;
        }
    };

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <br/>

            <div className={"text-justify important"}>
                <label htmlFor="search">
                    Cautare:&nbsp;

                    <input
                        type="text"
                        placeholder="Caută după titlu sau autor..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{
                            padding: '8px',
                            margin: '10px 0',
                            width: '100%',
                            boxSizing: 'border-box',
                        }}
                    />

                </label>
                &nbsp;
                <span>Numar total carti: {filteredBooks.length}</span>
            </div>

            <hr/>

            <div className={"text-justify important"}>

                {/* Afișarea cărților */}

                {filteredBooks.length > 0 ? (
                    filteredBooks.map((book, index) => (
                        <div key={index}>
                               <span
                                   className="position-absolute top-0 start-100 translate-middle badge rounded-pill badge-warning ">{index + 1}
                               </span>
                                <table className="table table-sm table-hover table-dark">
                                    <tbody>
                                    <tr>
                                        <td width={250}>
                                            <b><span className="card-text">{book.titlu}</span></b>

                                            <ImageDialog imagePath={book.coperta.imagine._path} />
                                        </td>
                                        <td width={250}>
                                            <div><strong>Autor:</strong> {renderAutori(book)}</div>
                                            <hr/>
                                            <div><strong>An Apăritie:</strong> {book.anAparitie}</div>
                                            <div><strong>Editura:</strong> {book.editura}</div>
                                            <div><strong>Număr de Pagini:</strong> {book.numarPagini}</div>
                                        </td>
                                        <td>
                                            <div><strong>Cuprins:</strong> {renderCuprins(book)}</div>
                                        </td>
                                    </tr>

                                    </tbody>
                                </table>
                        </div>
                    ))
                ) : (
                    <p>Nu s-au găsit cărți care să corespundă termenului de căutare.</p>
                )}

            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );

}

export default ITOldBooksContent;