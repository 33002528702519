import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers7LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-7", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Continuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            ├── segments-entries.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Definire Segments Experiences</b>
                    <br/>
                    <br/>

                    Structura:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_40.png'}/>
                    </div>

                    Fisierul <b>segments-entries.json</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'[\n' +
                            '  {\n' +
                            '    "active": "true",\n' +
                            '    "name": "Infochiosk",\n' +
                            '    "name_i18n": {\n' +
                            '      "en-US": "Infochiosk",\n' +
                            '      "ro-RO": "Infochiosk"\n' +
                            '    },\n' +
                            '    "segmentsEntryKey": "INFOCHIOSK",\n' +
                            '    "type": "com.liferay.portal.kernel.model.User",\n' +
                            '    "criteria": {\n' +
                            '      "criteria": {\n' +
                            '        "context": {\n' +
                            '          "conjunction": "and",\n' +
                            '          "filterString": "(contains(userAgent, \'infochiosk\') or (deviceScreenResolutionWidth eq 1080.00 and deviceScreenResolutionHeight eq 1920.00))",\n' +
                            '          "typeValue": "context"\n' +
                            '        }\n' +
                            '      },\n' +
                            '      "filterStrings": {\n' +
                            '        "context": "(contains(userAgent, \'infochiosk\') or (deviceScreenResolutionWidth eq 1080.00 and deviceScreenResolutionHeight eq 1920.00))"\n' +
                            '      }\n' +
                            '    }\n' +
                            '  }\n' +
                            ']'}
                    </SyntaxHighlighter>

                    Fisierul <b>segments-experiences.json</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "active": "true",\n' +
                            '  "friendlyURL": "/home",\n' +
                            '  "name": "Infochiosk",\n' +
                            '  "name_i18n": {\n' +
                            '    "en-US": "Infochiosk",\n' +
                            '    "ro-RO": "Infochiosk"\n' +
                            '  },\n' +
                            '  "segmentsEntryId": "[$SEGMENTS_ENTRY_ID:INFOCHIOSK$]"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>page.json</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "hidden": true,\n' +
                            '  "private": false,\n' +
                            '  "name": "Ibrid - Prima pagina",\n' +
                            '  "type": "Content",\n' +
                            '  "friendlyURL": "/home"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>page-definition.json</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "pageElement": {\n' +
                            '    "pageElements": [\n' +
                            '      {\n' +
                            '        "definition": {\n' +
                            '\n' +
                            '          "indexed": true,\n' +
                            '          "layout": {\n' +
                            '            "borderWidth": 0,\n' +
                            '            "marginBottom": 0,\n' +
                            '            "marginLeft": 0,\n' +
                            '            "marginRight": 0,\n' +
                            '            "marginTop": 0,\n' +
                            '            "opacity": 100,\n' +
                            '            "paddingBottom": 0,\n' +
                            '            "paddingLeft": 0,\n' +
                            '            "paddingRight": 0,\n' +
                            '            "paddingTop": 0,\n' +
                            '            "widthType": "Fluid"\n' +
                            '          }\n' +
                            '        },\n' +
                            '        "pageElements": [\n' +
                            '          {\n' +
                            '            "definition": {\n' +
                            '              "indexed": true,\n' +
                            '              "layout": {\n' +
                            '                "borderWidth": 0,\n' +
                            '                "marginBottom": 0,\n' +
                            '                "marginLeft": 0,\n' +
                            '                "marginRight": 0,\n' +
                            '                "marginTop": 0,\n' +
                            '                "opacity": 100,\n' +
                            '                "paddingBottom": 0,\n' +
                            '                "paddingLeft": 0,\n' +
                            '                "paddingRight": 0,\n' +
                            '                "paddingTop": 0,\n' +
                            '                "widthType": "Fluid"\n' +
                            '              }\n' +
                            '            },\n' +
                            '            "pageElements": [\n' +
                            '              {\n' +
                            '                "definition": {\n' +
                            '                  "indexed": true,\n' +
                            '                  "layout": {\n' +
                            '                    "borderWidth": 0,\n' +
                            '                    "marginBottom": 0,\n' +
                            '                    "marginLeft": 0,\n' +
                            '                    "marginRight": 0,\n' +
                            '                    "marginTop": 0,\n' +
                            '                    "opacity": 100,\n' +
                            '                    "paddingBottom": 0,\n' +
                            '                    "paddingLeft": 0,\n' +
                            '                    "paddingRight": 0,\n' +
                            '                    "paddingTop": 0,\n' +
                            '                    "widthType": "Fluid"\n' +
                            '                  }\n' +
                            '                },\n' +
                            '                "pageElements": [\n' +
                            '                  {\n' +
                            '                    "definition": {\n' +
                            '                      "fragment": {\n' +
                            '                        "key": "ibrid-chiosk-home"\n' +
                            '                      },\n' +
                            '                      "fragmentConfig": {\n' +
                            '                        "numberOfSlides": 3\n' +
                            '                      },\n' +
                            '                      "fragmentFields": [],\n' +
                            '                      "indexed": true\n' +
                            '                    },\n' +
                            '                    "type": "Fragment"\n' +
                            '                  }\n' +
                            '                ],\n' +
                            '                "type": "Section"\n' +
                            '              }\n' +
                            '            ],\n' +
                            '            "type": "Section"\n' +
                            '\n' +
                            '          }\n' +
                            '        ],\n' +
                            '        "type": "Section"\n' +
                            '      }\n' +
                            '    ],\n' +
                            '    "type": "Root"\n' +
                            '  },\n' +
                            '  "settings": {\n' +
                            '    "masterPage": {\n' +
                            '      "key": "ibrid"\n' +
                            '    }\n' +
                            '  }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_41.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_42.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_43.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers7LiferayContent;