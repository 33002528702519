import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function MonitorMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-monitor";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>Monitor ICE Felix - RGB-TTL / VDT-240</b>
                <br/>
                <br/>

                VDT = ViDeo Terminal:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_22.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_23.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_24.png'}/>
                </div>


            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default MonitorMuseumContent;