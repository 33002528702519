import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class ShortcutPremireVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "premiere-pro-shortcut", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Scurtaturi de taste:
                    <ul>
                        <li><b>SPACE</b> - pornire/oprire clip (in ecranul Source sau in ecranul Program)</li>
                        <li>subselectie, in momentul in care nu vrem sa selectam tot clipul ci doar o parte din acesta:
                            <ul>
                                <li><b>I</b> - mark in; marcam punctul de unde vrem sa inceapa selectia</li>
                                <li><b>O</b> - mark out; marcam punctul unde vrem sa se termine selectia</li>
                            </ul>
                        </li>
                        <li><b>C</b> - <b>The Razor Tool</b>; taie un clip in cate sectiuni vrem; este cel mai direct instrument de tăiere</li>
                        <li><b>B</b> - <b>Ripple Edit Tool</b>; cand se micsoreaza un clip, in loc sa apara pauza, clipul de langa el este tras automat si nu se mai creeaza pauza</li>
                        <li><b>Delete</b> sau <b>click dreapta pauza {"->"} Ripple Delete</b>: pentru a sterge pauzele dintre clipuri</li>
                        <li>
                            selectie clipuri in ecranul Secventa:
                            <ul>
                                <li>
                                    <b>A</b> - Track Select Forward Tool; pentru a selecta clipurile de la un punct pana la sfarsit;
                                    <br/>
                                    pentru a selecta un singur rand/track, fiind selectat acest tool, se apasa <b>Shift</b>
                                </li>

                                <li><b>Shift + A</b> - Track Select Backward Tool; pentru a selecta de la pun punct pana la inceput;
                                    <br/>
                                    pentru a selecta un singur rand/track, fiind selectat acest tool, se apasa <b>Shift</b>
                                </li>
                            </ul>
                        </li>
                        <li><b>N</b> - <b>Rolling Edit Tool</b>; avand 2 clipuri consecutive, se poate reselecta punctul de sfarsit a clipului 1 si inceputul clipului 2;
                        astfel:
                            <ul>
                                <li>mutat spre stanga, se scurteaza clipul 1 si se mareste clipul 2</li>
                                <li>mutat spre dreapta, se mareste clipul 1 si se scurteaza clipul 2</li>
                            </ul>
                        </li>
                        <li>
                            <b>Y</b> - <b>Slip Tool</b>; se poate modifica punctul de inceput (in) si de sfarsit (out) a unui clip, dar sa a afecta durata totala a clipului; astfel:
                            <ul>
                                <li>mutat spre stanga, vom vedea mai mult din partea din stanga a clipului</li>
                                <li>mutat spre dreapta, vom vedea mai mult din partea din dreapta a clipului</li>
                            </ul>
                        </li>
                        <li>
                            <b>U </b> - <b>Slide Tool</b>; avand 3 clipuri consecutive si selectand pe cel din mijloc:
                            <ul>
                                <li>mutat spre stanga, clipul 1 se va micsora, clipul 2 ramane la fel, clipul 3 se va mari</li>
                                <li>mutat spre dreapta, clipul 1 se va mari, clipul 2 ramane la fel, clipul 3 se va micsora</li>
                            </ul>
                        </li>
                        <li>
                            <b>R</b> - <b>Rate Stretch Tool</b> - putem modifica timpul de rulare al clipului:
                            <ul>
                                <li>lungindu-se, va rula in slow-motion</li>
                                <li>micsorandu-se, va rula in fast-motion</li>
                            </ul>
                        </li>
                        <li>
                            <b>T</b>- <b>Type Tool</b> - pentru a adauga un text
                        </li>

                        <li><b>Ctrl+K</b> - taie in doua clipul selectat, in dreptul capul de redare</li>
                        <li><b>`</b> - maximalizare ecran</li>
                        <br/>
                        <li><b>J</b> - play inainte</li>
                        <li><b>K</b> - stop</li>
                        <li><b>L</b> - play inapoi</li>

                        <br/>
                        <li><b>Shift + L</b> - play inapoi mai fin</li>
                        <li><b>Shift + J</b> - play inainte mai fin</li>
                        <br/>
                        <li><b>Shift + P</b> - Set Porter Frame - seteaza imagenea default pentru un video</li>
                        <li><b>Ctrl + U</b> - creare subclip</li>

                        <br/>
                        <li><b>Shift + F</b> - cautare clip dupa metadate in sectiunea Project</li>
                        <li><b>Ctrl + F</b> - cautare avansata clip dupa metadate in sectiunea Project</li>
                    </ul>

                    Premier Pro imparte fereastra in 4 ecrane:
                    <ul>
                        <li>ecranul <b>Project</b>, se pot adauga clip/video-uri apasand <i>dublu-click</i></li>
                        <li>ecranul <b>Source</b>, se pot vizualiza clipurile din ecranul de proiect</li>
                        <li>ecranul <b>Secventa</b>, se pot trage clipuri sau parti dintr-un clip din ecranul Source prin <i>drag-and-drop</i>; in momentul in care se trage pentru prima oara; se creaza un fisier de tip Secventa, vizibil in ecranul Proiect</li>
                        <li>Iecranul <b>Program</b>, se poate vizualiza clipul rezultat</li>
                    </ul>

                    Un clip adus in ecranul Secventa, poate fi:
                    <ul>
                        <li>scurtat (de la inceput sau de la sfarsit)</li>
                        <li>taiat in cate sectiuni se vrem (C)</li>
                    </ul>

                    <hr/>
                    <b>1. Decuparea</b>
                    <br/>
                    Decuparea este una dintre cele mai importante părți ale unei editări.
                    Este funcția principală prin intermediul careia se pot reduce clipurile și plasa într-o anumită ordine pentru a crea o poveste.
                    <br/>
                    Premiere Pro oferă trei moduri distincte de a manipula și decupa un clip video:
                    <ul>
                        <li>Razor Tool (C)</li>
                        <li>Ripple Edit Tool (B)</li>
                        <li>Ctrl+K</li>
                    </ul>

                    <hr/>
                    <b>2. Creare seventa dintr-un clip</b>
                    <br/>
                    Avand selectat un clip in ecranul Proiect {"->"} click dreapta {"->"} <b>New Sequence From Clip</b>.

                    <hr/>
                    <b>3. Schimbare durata clip</b>
                    <br/>
                    In ecranul Secventa pe clip, vom alege: click dreapta {"->"} <b>Speed/Duration</b>,
                    cu optiunile:
                    <ul>
                        <li><b>Reverse Speed</b>: pentru redare de la inceput la sfarsit</li>
                    </ul>

                    Alta optiune, este facand pe track-ul video <b>Zoom In</b> (marind in sus zona de track). Apoi click dreapta pe iconitza <b>fx</b> (stanga-sus a track-ului) {"->"} <b>Time Remapping</b> {"->"} <b>Speed</b>.
                    Va apare o linie care va corespunde vitezei de redare a clipului si se poate trage in sus sau in jos, controland astfel viteza de redare a clipului.
                    <br/>
                    Se poate modifica viteza doar intre doua puncte ale clipului. Punctele se adauga cu: <b>CTRL + Click</b>

                    <hr/>
                    <b>4. Schimbare opacitate clip</b>
                    <br/>

                    Pe track-ul video <b>Zoom In</b> (marind in sus zona de track). Apoi click dreapta pe iconitza <b>fx</b> (stanga-sus a track-ului) {"->"} <b>Opacity</b> {"->"} <b>Opacity</b>.
                    Va apare o linie care va corespunde opacitatii clipului. Daca se poate trage in sus clipul se va intuneca.
                    <br/>
                    Si de asemenea se pot adauga puncte (cu CTRL) intre care sa creasca sau sa descreasca opacitatea.

                    <hr/>
                    <b>5. Audio</b>
                    <br/>

                    Un audio e recomandat sa fie intre <b>-12 si -18 decibi</b>. Daca depaseste se poate ajusta:
                    <ul>
                        <li>click-dreapta pe audio/melodie</li>
                        <li><b>Audio Gain</b></li>
                        <li>Adjust Gain by (se ajusteaza pana ajunge in intervalul dorit)</li>
                    </ul>
                    De asemenea, punand puncte (cu CTRL) se poate ajusta ca sunetul sa creasca progresiv sau sa descreasca.
                    <br/>
                    Apasand <b>M</b> se poate da mute la sunet.

                    <hr/>
                    <b>6. Efecte tranzitii</b>
                    <br/>

                    Din ecranul Proiect, se selecteaza tab-ul <b>Effects</b>. Apoi:
                    <ul>
                        <li>Video Transitions {"->"} Dissolve {"->"} Cross Dissolve</li>
                        <li>se trage:
                            <ul>
                                <li>intre doua clipuri</li>
                                <li>inceputul unui clip</li>
                                <li>la sfarsitul unui clip</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>7. Efecte video</b>
                    <br/>

                    Din ecranul Proiect, se selecteaza tab-ul <b>Effects</b>. Apoi:
                    <ul>
                        <li>Video Effects {"->"} Blur & Sharpen {"->"} Gaussian Blur</li>
                        <li>se trage:
                            <ul>
                                <li>peste un clip</li>
                            </ul>
                        </li>
                    </ul>
                    Din ecranul Source, se selecteaza tab-ul <b>Effect Controls</b>. Apoi merge pe sectiune de Gaussian Blur:
                    <ul>
                        <li>Blurriness: se ajusteaza nivelul de blur</li>
                    </ul>
                    Gaussian Blur poate fi animat setand keyframe-uri (din <b>Effect Controls</b> {"->"} sectiune de Gaussian Blur):
                    <ul>
                        <li>Toggle Animation (cercul de langa Blurriness)</li>
                        <li>va apare o zona in care gestionam keyframe-urile; initial ne va indica o linie vericala cu un punct (linia este pozitionata unde este si capul de redare), pe care putem ajusta nivelul de blur</li>
                        <li>daca mutam capul de redare, putem apoi ajusta blur-ul; si intre cele 2 puncte se face o tranzitie de blur pe baza celor 2 valori setate</li>
                        <li>putem pune oricate puncte si sa ii setam pentru fiecare punct un nivel de blur</li>
                    </ul>

                    <hr/>
                    <b>8. Stabilizare</b>
                    <br/>

                    Din ecranul Proiect, se selecteaza tab-ul <b>Effects</b>. Apoi:
                    <ul>
                        <li>Video Effect {"->"} Distort {"->"} Warp Stabilizer</li>
                        <li>efectul se trage peste clipul care necesita stabilizare</li>
                        <li>Premier Pro va efectua automat stabilizarea (de obicei taie din marginile clipului, dar realizeaza o miscare mai fluida)</li>
                    </ul>

                    <hr/>
                    <b>9. Croma</b>
                    <br/>
                    Pentru a inlocui un clip cu croma cu o noua imagine,  din ecranul Proiect, se selecteaza tab-ul <b>Effects</b>. Apoi:
                    <ul>
                        <li>Video Effect {"->"} Keying {"->"} Ultra key</li>
                        <li>efectul se trage peste clip</li>
                        <li>din tab-ul Effect Control (ecranul Source), sectiunea Ultra Key
                            <ul>
                                <li>Key Color {"->"} se selecteaza o valoare medie de verde (PremierPro va transforma verdele in negru)</li>
                                <li>Output {"->"} Alpha Channel</li>
                                <li>Setting {"->"} Aggresive</li>
                                <li>Matte Generation {"->"} Shadow (scopul e sa avem negru pur unde a fost verde)</li>
                                <li>Output {"->"} Composite</li>
                                <li>clipul se muta pe track-ul V2</li>
                                <li>background-ul / imaginea de fundal, se pune pe track-ul V1:
                                    <ul>
                                        <li>se lungeste pe toata durata clipului</li>
                                        <li>daca nu are acceasi dimensiune/rezolutie cu clipul: click dreapta pe clip {"->"} Scale to Frame Size</li>
                                        <li>din Effect Control {"->"} Motion {"->"} Scale (se poate ajusta, pentru ca fundalul sa umple ecranul)</li>
                                    </ul>
                                </li>
                            </ul>

                        </li>
                    </ul>

                    <hr/>
                    <b>10. Export</b>
                    <br/>

                    Pentru export este indicat sa fie pus un In si un Out pe clipul editat in ecranul Secventa. Apoi, avand tab-ul cu secventa dorita selectata:
                    <ul>
                        <li>File {"->"} Export {"->"} Media</li>
                        <li>Output name: setare nume</li>
                        <li>Format: H.264</li>
                        <li>Preset: Youtube 1080p HD</li>
                        <li>Export Video: bifat</li>
                        <li>Export Audio: bifat</li>
                        <li>tab Video {"->"} Basic Video Settings
                            <ul>
                                <li>Width: 1920</li>
                                <li>Height: 1080</li>
                                <li>Frame Rate: 23.976 fps (cadenta) sau 25</li>
                                <li>Field Order: Progresive</li>
                                <li>Aspect: Square Pixels (1.0)</li>
                            </ul>
                        </li>
                        <li>tab Video {"->"} Encoding Settings:
                            <ul>
                                <li>Profile: High</li>
                                <li>Level: 4.2</li>
                            </ul>
                        </li>
                        <li>
                            tab Video {"->"} Bitrate Settings:
                            <ul>
                                <li>Bitrate Encoding: VBR, 1 pass sau VBR, 2 pass</li>
                                <li>Target Bitrate (Mbps): 12</li>
                                <li>Maximum Bitrate (Mbps): 16</li>
                            </ul>
                        </li>
                        <li>
                            tab Audio {"->"} Audio Format Settings:
                            <ul>
                                <li>Audio Format: AAC</li>
                            </ul>
                        </li>
                        <li>
                            tab Audio {"->"} Basic Audio Settings:
                            <ul>
                                <li>Sample Rate: 48000 Hz</li>
                                <li>Channels: Stereo</li>
                            </ul>
                        </li>

                        <li>
                            tab Audio {"->"} Bitrate Settings:
                            <ul>
                                <li>Bitrate (kbps): 320</li>
                            </ul>
                        </li>
                    </ul>

                    <hr/>
                    <b>11. Adaugare metadata la un video</b>
                    <br/>
                    <br/>
                    Meniu <b>Windows {"->"} Metadata</b>
                    <br/>
                    sau
                    <br/>
                    selectiunea <b>Source</b> tab <b>Metadata</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/ev-1.png'}/>
                    </div>

                    Adaugare informatie intr-o metadata (de exemplu: <b>Description</b> din <b>Dublin Core</b>)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/ev-3.png'}/>
                    </div>


                    {/*<br/>*/}
                    {/*<br/>*/}
                    {/*<a href={"https://pavilion.dinfos.edu/How-To/Article/2471503/how-to-add-metadata-to-a-video-using-adobe-premiere-pro/"}>HOW TO ADD METADATA TO A VIDEO USING ADOBE PREMIERE PRO</a>*/}


                    <hr/>
                    <b>12. Afisare metadate in sectiunea Project</b>
                    <br/>

                    Afisare metadate in sectiunea Project: click dreapta pe capul de tabul {"->"} Apare meniul cu <b>Metadata Display</b>:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/ev-2.png'}/>
                    </div>

                    <hr/>
                    <b>12. Cautare clipuri dupa metadate in sectiunea Project</b>
                    <br/>
                    Se apasa: <b>Shift + F</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/ev-5.png'}/>
                    </div>

                    Pentru cautare avansta se apasa: <b>CTRL + F</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/ev-4.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.premiumbeat.com/blog/cut-clips-premiere-pro-tools/"}>The 3 Easiest Ways to Cut Clips in Adobe Premiere Pro CC</a><br/>
                           </div>
                       </li>
                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        /!*<a href={"https://www.studiobinder.com/blog/deep-focus-shot-camera-movement-angle/"}>Deep Focus Shot: Creative Examples of Camera Movements & Angles</a>*!/*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ShortcutPremireVideografieContent;