import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SpeechToTextSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-stt-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Versiune Spring AI: <b>1.0.0-M5</b>

                    <hr/>

                    Modulul:
                    <ul>
                        <li>
                            AudioTranscriptionModel
                        </li>
                        <li>
                            AudioTranscriptionPrompt
                        </li>
                        <li>
                            AudioTranscriptionOptions
                        </li>
                        <li>
                            AudioTranscriptionResponse
                        </li>
                    </ul>

                    Pe scurt: (AudioTranscriptionPrompt + AudioTranscriptionOptions) ~ AudioTranscriptionModel ~ AudioTranscriptionResponse.

                    <hr/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'@Autowired\n' +
                            'private OpenAiAudioTranscriptionModel openAiAudioTranscriptionModel;\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '    @Override\n' +
                            '    public String speechToText(String path) {\n' +
                            '        AudioTranscriptionPrompt audioTranscriptionPrompt = new AudioTranscriptionPrompt(\n' +
                            '                new FileSystemResource(path));\n' +
                            '        return openAiAudioTranscriptionModel.call(audioTranscriptionPrompt).getResult().getOutput();\n' +
                            '    }'
                        }
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                           '    @Override\n' +
                            '    public String speechToText(String path) {\n' +
                            '        AudioTranscriptionPrompt audioTranscriptionPrompt = new AudioTranscriptionPrompt(\n' +
                            '                new FileSystemResource(path));\n' +
                            '        return openAiAudioTranscriptionModel.call(audioTranscriptionPrompt).getResult().getOutput();\n' +
                            '    }'
                        }
                    </SyntaxHighlighter>

                    sau

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'  @Override\n' +
                            '    public String speechToText() {\n' +
                            '        AudioTranscriptionPrompt audioTranscriptionPrompt = new AudioTranscriptionPrompt(audioPrompt);\n' +
                            '        return openAiAudioTranscriptionModel.call(audioTranscriptionPrompt).getResult().getOutput();\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    cu:

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'@Value("classpath:usa.m4a")\n' +
                            'private Resource audioPrompt;\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Optiuni</b>
                    <br/>
                    <br/>

                    Se pot adauga optiuni:
                    <ul>
                        <li>limba in care se doreste a fi tradus (de exemplu, audio este este in romana si doreste sa fie tradus ca text in franceza)</li>
                        <li>formatul: TEXT, JSON, SRT, VTT</li>
                    </ul>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'   @Override\n' +
                            '    public String speechToText() {\n' +
                            '\n' +
                            '        OpenAiAudioTranscriptionOptions options = OpenAiAudioTranscriptionOptions.builder()\n' +
                            '                .language("fr")\n' +
                            '                .responseFormat(OpenAiAudioApi.TranscriptResponseFormat.VTT)\n' +
                            '                .build();\n' +
                            '\n' +
                            '        AudioTranscriptionPrompt audioTranscriptionPrompt = new AudioTranscriptionPrompt(audioPrompt, options);\n' +
                            '        return openAiAudioTranscriptionModel.call(audioTranscriptionPrompt).getResult().getOutput();\n' +
                            '    }'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpeechToTextSpringIALlmContent;