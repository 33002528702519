import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class LearningRuleContent extends BaseContentPage {

    constructor(props) {
        super(props, "learning-rules#", IndexContent);
    }

    render() {
        return (
            <div className="home ann">
                {this.title()}
                {this.navigator()}

                <div className={"text-justify"}>
                    <b><a id={"learningrule"}>1.3. Reguli de invatare</a></b>
                    <br/>
                    <br/>
                    <div className={"text-justify important"}>
                        Regulile de învățare actualizează ponderile și biasul în mod iterativ pe măsură ce rețelei i se prezintă imagini din setul de antrenament.
                        <br/>
                        Reguli de învățare:
                        <ul>
                            <li>Regula de învățare a lui Hebb</li>
                            <li>Regula de învățare a percepronului</li>
                            <li>Regula de învățare delta (Widrow−Hoff / Least Mean Square / LMS)</li>
                            <li>Regula de învățare competitivă (Winner−takes−all / câstigătorul-ia-tot)</li>
                            <li>Regula de învățare outstar (Grossberg)</li>
                            <li>Regula de învățare a corelației</li>
                        </ul>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b><a id={"hebblearningrule"}>1.3.1. Regula de învățare a lui Hebb</a></b>

                    <br/>
                    <br/>
                    <div className={"text-justify"}>
                        În 1949, neuropsihologul Donald Hebb va contribui la revoluționarea modului în care neuronii artificiali erau percepuți.
                        În cartea sa, <b>The Organization of Behavior</b>, el a propus ceea ce a devenit cunoscut sub numele de regula de <i>învățare lui Hebb</i>.
                        El afirma că atunci când un axon al celulei A  excită o celulă B în mod repetat sau persistent activând-o,
                        în cel puțin una dintre cele două celule are loc un proces de creștere sau o schimbare metabolică,
                        astfel încât eficiența lui A, ca celulă activatoare crește.
                        Cu alte cuvinte, cu cât repetăm mai des un lucru, cu atât devine obicei.
                    </div>
                    <br/>
                    <div className={"text-justify important"}>
                        Actualizarea ponderilor dupa regula de învățare a lui Hebb:
                        <div className={"padding50"}>
                            <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta x_i y_j`}/>
                        </div>
                        unde
                            <ul>
                                <li>w<sub>ij</sub> ponderea asociata conexiunii dintre neuronul X<sub>i</sub> și neuronul Y<sub>i</sub></li>
                                <li>x<sub>i</sub> este iesirea din X<sub>i</sub></li>
                                <li>y<sub>j</sub> este iesirea din Y<sub>j</sub></li>
                                <li>&eta; este o constanta numita <i>rata de invatare</i></li>
                            </ul>

                        <div style={{padding:10}}>
                            <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/hebb.png'}/>
                        </div>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b><a id={"perceptronlearningrule"}>1.3.2. Regula de învățare a percepronului</a></b>

                    <br/>
                    <br/>
                    <div className={"text-justify"}>
                        Ideea aceste reguli este bazată pe ideea de <b>eroare</b> dintre ce am vrea să obținem (răspunsul dorit) și ceea ce am obținut.
                    </div>
                    <br/>
                    <div className={"text-justify important"}>
                        Actualizarea ponderilor dupa regula de învățare a perceptronului:
                        <div className={"padding50"}>
                            <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta x_i t_j`}/>
                        </div>
                        unde
                        <ul>
                            <li>w<sub>ij</sub> ponderea asociata conexiunii dintre neuronul X<sub>i</sub> și neuronul Y<sub>i</sub></li>
                            <li>x<sub>i</sub> este iesirea din X<sub>i</sub></li>
                            <li>t<sub>j</sub> este iesirea asteptata(<b>răspunsul dorit</b>) din Y<sub>j</sub></li>
                            <li>&eta; este o constanta numita <i>rata de invatare</i></li>
                        </ul>

                        <div style={{padding:10}}>
                            <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/perceptron_1.png'}/>
                        </div>
                    </div>
                    <br/>
                    <div className={"text-justify"}>
                        <i>Observatie:</i>
                            <ul>
                                <li>Valorile pe care le poate lua t<sub>j</sub> sunt -1 sau 1.</li>
                                <li>Valorile pe care le poate lua y<sub>j</sub> sunt -1, 0 sau 1. Pentru valoarea 0, ponderile nu se actualizează.</li>
                            </ul>

                        Algoritm pentru actualizarea ponderilor dupa regula de învățare a perceptronului:
                            <ul>
                                <li>
                                    if (y<sub>j</sub> &lt;&gt; t<sub>j</sub>)
                                    <ul>
                                        <li>
                                            if (y<sub>j</sub>=-1) w<sub>ij</sub>+=1 (maresc ponderea cu 1, pentru ar fi trebuit sa fie egala cu 1, adică raspunsul dorit (t<sub>j</sub> ))
                                        </li>
                                        <li>
                                            if (y<sub>j</sub>=+1) w<sub>ij</sub>-=1 (micsorez ponderea cu 1, pentru ca ar fi trebuit sa fie egala cu -1, adică raspunsul dorit (t<sub>j</sub> ))
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                        Putem observa în următorul tabel (y<sub>j</sub> &lt;&gt; 0), că ponderea trebuie modificată în funcție de t<sub>j</sub>:
                        <table>
                            <tr>
                                <th>
                                    t \ y
                                </th>
                                <th>
                                    -1
                                </th>
                                <th>
                                    1
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    -1
                                </th>
                                <td>
                                    0
                                </td>
                                <td>
                                    <span className="badge badge-warning">-1</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    1
                                </th>
                                <td>
                                    <span className="badge badge-warning">+1</span>
                                </td>
                                <td>
                                    0
                                </td>
                            </tr>
                        </table>
                        Eroarea în cazul de mai sus poate fi considerată: <b>e<sub>j</sub>' = t<sub>j</sub></b>
                        <br/>
                        Dacă considerăm eroarea: <b>e<sub>j</sub> = (t<sub>j</sub>-y<sub>j</sub>)</b> și refacem tabelul de mai sus, obținem:
                        <table>
                            <tr>
                                <th>
                                    t \ y
                                </th>
                                <th>
                                    -1
                                </th>
                                <th>
                                    1
                                </th>
                            </tr>
                            <tr>
                                <th>
                                    -
                                </th>
                                <td>
                                    0
                                </td>
                                <td>
                                    <span className="badge badge-warning">-2</span>
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    1
                                </th>
                                <td>
                                    <span className="badge badge-warning">+2</span>
                                </td>
                                <td>
                                    0
                                </td>
                            </tr>
                        </table>
                    </div>

                    Deci, având în vedere că se obtin acelasi rezultate (<b>e<sub>j</sub>=2e<sub>j</sub>'</b>) putem folosi și aceasta forma de eroare, care pare mai naturală.
                    <br/> <br/>
                </div>

                <div className={" text-justify important"}>
                    Actualizarea ponderilor dupa regula de învățare a perceptronului se poate face și în felul următor:
                    <div className={"padding50"}>
                        <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta x_i e_j`}/>
                    </div>
                    unde
                    <ul>
                        <li>w<sub>ij</sub> ponderea asociata conexiunii dintre neuronul X<sub>i</sub> și neuronul Y<sub>i</sub></li>
                        <li>x<sub>i</sub> este iesirea din X<sub>i</sub></li>
                        <li>y<sub>i</sub> este iesirea din Y<sub>j</sub></li>
                        <li>t<sub>j</sub> este iesirea asteptata (răspunsul dorit) din Y<sub>j</sub></li>
                        <li>e<sub>j</sub>=t<sub>j</sub>-y<sub>j</sub></li>
                        <li>&eta; este o constanta numita <i>rata de invatare</i></li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/perceptron_2.png'}/>
                    </div>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b><a id={"deltalearningrule"}>1.3.3. Regula de învățare delta</a></b>
                    <br/>
                    <br/>
                    <div className={"text-justify important"}>
                        Acestă regulă de învățare a propusă de Bernard Widrow și Marcin Hoff pentru a minimza
                        eroare totală a setului de antrenament. Se mai numește si Least Mean Square (LMS).
                        <br/>
                        Actualizarea ponderilor dupa regula de delta:
                        <div className={"padding50"}>
                            <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta x_i e_j f'(h_j)`}/>
                        </div>
                        unde
                        <ul>
                            <li>w<sub>ij</sub> ponderea asociata conexiunii dintre neuronul X<sub>i</sub> și neuronul Y<sub>i</sub></li>
                            <li>x<sub>i</sub> este iesirea din X<sub>i</sub></li>
                            <li>y<sub>i</sub> este iesirea din Y<sub>j</sub></li>
                            <li>t<sub>j</sub> este iesirea asteptata (răspunsul dorit) din Y<sub>j</sub></li>
                            <li>e<sub>j</sub>=t<sub>j</sub>-y<sub>j</sub></li>
                            <li>&eta; este o constanta numita <i>rata de invatare</i></li>
                            <li><b>f</b> este functia de activare</li>
                            <li><b>f'</b> este derivata lui f</li>
                            <li>
                                <MathComponent tex={String.raw`h_j=\sum x_i w_{ij}`}/>
                            </li>
                            <li>
                                <MathComponent tex={String.raw`y_j=f(h_j)`}/>
                            </li>
                        </ul>
                        Pentru o funcție de activare liniară, avem f'=1, deci:
                        <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta x_i e_j`}/>.
                        <b>Observație:</b><br/>
                        Regula de învățare delta este similară cu regula de învățare a percepronului.
                        Ceea ce e diferită este derivarea:
                        <ul>
                            <li>percepronul folosește <b>funcția pas</b> f ca funcție de activare,
                                ceea ce înseamană că f' nu există la 0 și f'=0 în rest, și prin urmare aplicarea reguli de învătare delta este imposibilă</li>
                        </ul>
                    </div>
                    <br/>
                    <b>Regula delta pentru output layer cu un sigur neuron</b>
                    <br/>
                    Să presupunem că reteaua primeste exemplul <b>x</b>, având ca rezultat dorit valoarea <b>t</b>. Atunci sumarizarea este:
                    <MathComponent tex={String.raw`h=\sum_{i=1}^{n} x_i w_{i}`}/>
                    Eroare pătrată pentru acest exemplu:
                    <MathComponent tex={String.raw`e=t-h`}/>
                    <MathComponent tex={String.raw`E=e^2`}/>
                    Gradientul lui E este un vector constand din derivatele partiale a lui E pentru fiecare pondere w:
                    <MathComponent tex={String.raw`{d E \over d w_1}, {d E \over d w_2},...,{d E \over d w_n}`}/>
                    Calculam aceste derivate partiale:
                    <MathComponent tex={String.raw`{d E \over d w_i} = {d E \over d h}{d h \over d w_i}`}/>
                    <MathComponent tex={String.raw`{d E \over d h} = {d (t-h)^2 \over d h} = 2(t-h)(-1)=-2(t-h)`}/>
                    <MathComponent tex={String.raw`{d h \over d w_i} = {d (w_1x_1+w_2x_2+...+w_ix_i+...+w_nx_i) \over d w_i}=x_i`}/>
                    Deci:
                    <MathComponent tex={String.raw`{d E \over d w_i} = -2(t-h) x_i`}/>
                    Deci avem:
                    <MathComponent tex={String.raw`w_{i}=w_{i}+\eta (t-j)x_i`}/>
                    <MathComponent tex={String.raw`w_{i}=w_{i}+\eta ex_i`}/>

                    <b>Observatie:</b> Similar e si pentru cazul in care in output layer sunt mai multi neuroni.
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b><a id={"wtalearningrule"}>1.3.4. Regula de învățare competitivă (Winner−takes−all)</a></b>
                    <br/>
                    <br/>
                    <div className={"text-justify important"}>
                        Această regulă a pornit de la rețele competitive și constă în faptul că între nodurile de ieșire (din output layer) există o <i>competiție</i>.
                        În timpul antrenamentului, neuronul cu cea mai mare <b>activare</b> pentru o observație dată, va fi declarat <b>câștigător</b> și
                        doar acele <b>ponderi</b> asociate conexiunilor cu neuronul câștigător vor fi actualizate.
                        <br/>
                        Să presupunem că neuronul Y<sub>k</sub> este câștigător.
                        Actualizarea ponderilor dupa regula de învățare competitivă:
                        <div className={"padding50"}>
                            <MathComponent tex={String.raw`w_{ik}=w_{ik}-\eta(x_i-w_{ik})`}/>
                        </div>
                        unde
                        <ul>
                            <li>w<sub>ik</sub> ponderea asociata conexiunii dintre neuronul X<sub>i</sub> și neuronul Y<sub>k</sub></li>
                            <li>x<sub>i</sub> este iesirea din X<sub>i</sub></li>
                            <li>&eta; este o constanta numita <i>rata de invatare</i></li>
                        </ul>
                    </div>
                </div>

                <br/>
                <br/>
                <div className={"text-justify"}>
                    <b><a id={"outstarlearningrule"}>1.3.5. Regula de învățare outstar (Grossberg)</a></b>
                    <br/>
                    <br/>
                    <div className={"text-justify important"}>
                        Acestă regulă de învățare a propusă de Grossberg.
                        <br/>
                        Actualizarea ponderilor dupa regula de delta:
                        <div className={"padding50"}>
                            <MathComponent tex={String.raw`w_{ij}=w_{ij}+\eta (t_j-w_{ij})`}/>
                        </div>
                        unde
                        <ul>
                            <li>w<sub>ij</sub> ponderea asociata conexiunii dintre neuronul X<sub>i</sub> și neuronul Y<sub>i</sub></li>
                            <li>t<sub>j</sub> este iesirea asteptata (răspunsul dorit) din Y<sub>j</sub></li>
                            <li>&eta; este o constanta numita <i>rata de invatare</i></li>
                        </ul>
                        Precum se vede, e similară cu regula de învățare a percepronului, dar sunt câteva diferente:
                        <ul>
                            <li>
                                eroarea în regula de învățarea delta nu este constrânsă să aibă valorile -2,0, 2 ( sau -1, 0, 1).
                                poate avea orice valoare
                            </li>

                        </ul>
                    </div>
                </div>

                <br/>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson
                        </li>
                        <li>
                            <div>
                                Ivan Nunes da Silva, Danilo Hernane Spatti , Rogerio Andrade Flauzino, Luisa Helena Bartocci Liboni, Silas Franco dos Reis Alves. 2017. Artificial Neural Networks - A Practical Course
                            </div>
                        </li>
                        <li>
                            <a href={"https://www.tutorialspoint.com/artificial_neural_network/index.htm"} target={"_blank"}>
                                https://www.tutorialspoint.com/artificial_neural_network/index.htm
                            </a>
                        </li>
                    </ul>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default LearningRuleContent;