import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class DLStoreUtilLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-dlstoreutil", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>1. DLStoreUtil</b>
                    <br/>
                    <br/>
                    Folosind <b>DLStoreUtil</b>, fisierele <b>nu</b> se pot vedea in <b>Documents and Media</b>. Acestea sunt stocate in directorul <b>/data/document_library</b>.

                    <b>DLStoreUtil</b> oferă metode de stocare a fișierelor în portal.
                    <br/>
                    Implementarea stocării fișierelor poate fi selectată în <b>portal.properties</b> prin intermediul proprietatii:
                    <br/>
                    <b>dl.store.impl</b>.
                    <br/>

                    Verificarea virușilor poate fi activată și prin intermediul proprietatii:
                    <br/>
                    <b>dl.store.antivirus.impl</b>.
                    <br/>

                    Clientul principal pentru această clasă este portletul <b>Document Library</b>.
                    Este utilizat de alte portleturi precum <b>Wiki</b> și <b> Message Boards</b> pentru a stoca fișiere atașate.
                    <br/>

                    Pentru a obtine <b>repositoryId</b>:
                    <ul>
                        <li>pentru portletul <b>Document Library</b>: poate fi obținut apelând:
                            <br/>
                            com.liferay.portlet.documentlibrary.model.DLFolderConstants#getDataRepositoryId( long,long)
                        </li>
                        <li>pentru toate celelalte portleturi (diferit de <b>Document Library</b>): egal cu CompanyConstants.SYSTEM</li>
                    </ul>

                    Pentru a obtine companyId (<i>COMPANY_ID</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ThemeDisplay themeDisplay = (ThemeDisplay) resourceRequest.getAttribute(WebKeys.THEME_DISPLAY);\n' +
                        'Company company = themeDisplay.getCompany();\n' +
                        'Long companyId = company.getCompanyId();'}
                    </SyntaxHighlighter>

                    De obicei, repositoryId / <i>REPOSITORY_ID</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'public static final long REPOSITORY_ID = CompanyConstants.SYSTEM;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'long repositoryId = CompanyConstants.SYSTEM;'}
                    </SyntaxHighlighter>

                    Mai multe: <a href={"https://docs.liferay.com/dxp/portal/7.3-latest/javadocs/portal-kernel/com/liferay/document/library/kernel/store/DLStoreUtil.html"}>aici</a>

                    <br/>
                    <br/>
                    Salvare fisier:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'long repositoryId = CompanyConstants.SYSTEM;\n' +
                        ' String dirName = "price-repository/1";\n' +
                        '\n' +
                        ' try {\n' +
                        ' DLStoreUtil.addDirectory(companyId, repositoryId, dirName);\n' +
                        ' }\n' +
                        ' catch (PortalException pe) {\n' +
                        ' }\n' +
                        '\n' +
                        ' DLStoreUtil.addFile(\n' +
                        ' companyId, repositoryId, dirName + "/" + fileName, file);'}
                    </SyntaxHighlighter>

                    Sa presupunem ca <i>companyId</i> este 20101, <i>repositoryId</i> este 0 si am introdus fisierele: <i>file1</i>, <i>file2</i>, <i>file3</i>, atunci repository-ul <i>data/document_library</i> va arata in felul urmator:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/store-1.png'}/>
                    </div>


                    Obtinere fisier:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String path = fileName;\n' +
                        '// if there is a directory then path would be\n' +
                        '// String path = "mydirectory/mySubDirectory/" + fileName; // mydirectory/mySubDirectory/my_File_image_name.png\n' +
                        '\n' +
                        'InputStream inputStream = DLStoreUtil.getFileAsStream(companyId(), CompanyConstants.SYSTEM, path);\n' +
                        '\n' +
                        'long contentLength = DLStoreUtil.getFileSize(companyId(), CompanyConstants.SYSTEM, path);\n' +
                        '\n' +
                        'String contentType = MimeTypesUtil.getContentType(fileName);\n' +
                        '\n' +
                        'ServletResponseUtil.sendFile(request, response, fileName, inputStream, contentLength, contentType);'}
                    </SyntaxHighlighter>

                    Pentru a obtine numele de fisiere dintr-un director:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'String[] files = DLStoreUtil.getStore().getFileNames(companyId, repositoryId, dirName);'}
                    </SyntaxHighlighter>

                    <b>2. DLAppService</b>
                    <br/>
                    Folosind <b>DLAppService</b>, fisierele se pot vedea in <b>Documents and Media</b>. Acestea sunt stocate in baza de date.
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'FileEntry = DLAppServiceUtil.addFileEntry(repositoryId, folderId, sourceFileName, mimeType, title, description, changeLog, bytes, serviceContext);'}
                    </SyntaxHighlighter>

                    <b>3. ImageLocalService</b>
                    <br/>

                    Folosit in special pentru stocarea imaginilr
                    <br/>
                    Exemplu stocare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'// to Add an image\n' +
                        'long imageID = 0;\n' +
                        'imageID = CounterLocalServiceUtil.increment();\n' +
                        'ImageLocalServiceUtil.updateImage(imageID, bytes);\n' +
                        '\n' +
                        '// to update the same image, pass the existing Image ID\n' +
                        'ImageLocalServiceUtil.updateImage(existingImageID, bytes);'}
                    </SyntaxHighlighter>

                    Din JSP:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'<img alt="My Image"\n' +
                        '     id="myImgID"\n' +
                        '     title="This my image stored in liferay"\n' +
                        '     src="<%=themeDisplay.getPathImage()%>/any_string_will_do_?img_id=<%=myImageId%>&img_timestamp=<%=someTimestampOrRandomString %>" />'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}


                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={"https://help.liferay.com/hc/en-us/articles/360018159951-Overriding-Liferay-Services-Service-Wrappers-"}>*/}
                    {/*                Overriding Liferay Services (Service Wrappers)*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DLStoreUtilLiferayContent;