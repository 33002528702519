import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaPortletLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-portlet", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Portlet</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p1.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p2.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p3.png'}/>
                    </div>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p4.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p5.png'}/>
                    </div>

                    <hr/>
                    <b>2. CRUD</b>
                    <br/>
                    <br/>

                    init.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {
                            '<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                            '\n' +
                            '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %><%@\n' +
                            'taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                            '\n' +
                            '<%@ taglib prefix="clay" uri="http://liferay.com/tld/clay" %>\n' +
                            '\n' +
                            '<liferay-theme:defineObjects />\n' +
                            '\n' +
                            '<portlet:defineObjects />'
                        }
                    </SyntaxHighlighter>

                    view.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\n' +
                            '<%@ page import="javax.portlet.PortletURL" %>\n' +
                            '<%@ page import="com.liferay.portal.kernel.portlet.url.builder.PortletURLBuilder" %>\n' +
                            '<%@ page import="ro.agnes.service.builder.service.BookLocalServiceUtil" %>\n' +
                            '<%@ page import="ro.agnes.book.web.constants.AgnesBookWebPortletKeys" %>\n' +
                            '<%@ include file="/init.jsp" %>\n' +
                            '\n' +
                            '<div class="d-flex">\n' +
                            '    <portlet:renderURL var="addBookURL">\n' +
                            '        <portlet:param name="mvcRenderCommandName" value="<%= AgnesBookWebPortletKeys.MVC_COMMAND_BOOK_EDIT  %>"/>\n' +
                            '    </portlet:renderURL>\n' +
                            '\n' +
                            '    <clay:link href="${addBookURL}" icon="plus" label="agnes.book.add" type="button" displayType="primary"/>\n' +
                            '</div>\n' +
                            '\n' +
                            '<%\n' +
                            '    PortletURL iteratorURL = PortletURLBuilder.create(renderResponse.createRenderURL()).buildPortletURL();\n' +
                            '%>\n' +
                            '\n' +
                            '<liferay-ui:search-container total="<%= BookLocalServiceUtil.getBooksCount() %>"\n' +
                            '                             delta="10"\n' +
                            '                             emptyResultsMessage="no-books-found"\n' +
                            '                             iteratorURL="<%=iteratorURL%>">\n' +
                            '\n' +
                            '\n' +
                            '    <liferay-ui:search-container-results results="<%= BookLocalServiceUtil.getBooks(searchContainer.getStart(), searchContainer.getEnd()) %>"/>\n' +
                            '    <liferay-ui:search-container-row className="ro.agnes.service.builder.model.Book" modelVar="book" keyProperty="bookId">\n' +
                            '<%--        <liferay-ui:search-container-column-text name="book.bookId" value="${book.bookId}"/>--%>\n' +
                            '        <liferay-ui:search-container-column-text name="agnes.book.title" value="${book.title}"/>\n' +
                            '        <liferay-ui:search-container-column-text name="agnes.book.publicationYear" value="${book.publicationYear}"/>\n' +
                            '\n' +
                            '        <liferay-ui:search-container-column-text name="agnes.book.actions">\n' +
                            '\n' +
                            '\n' +
                            '            <liferay-ui:icon-menu direction="left-side" icon="" markupView="lexicon" message="actions" showWhenSingleIcon="<%=true%>">\n' +
                            '\n' +
                            '                <portlet:renderURL var="editBookURL">\n' +
                            '                    <portlet:param name="mvcRenderCommandName" value="<%= AgnesBookWebPortletKeys.MVC_COMMAND_BOOK_EDIT  %>"/>\n' +
                            '                    <portlet:param name="bookId" value="${book.bookId}"/>\n' +
                            '                </portlet:renderURL>\n' +
                            '\n' +
                            '                <liferay-ui:icon url="${editBookURL}"  message="agnes.book.edit"/>\n' +
                            '\n' +
                            '            </liferay-ui:icon-menu>\n' +
                            '\n' +
                            '        </liferay-ui:search-container-column-text>\n' +
                            '\n' +
                            '        \n' +
                            '    </liferay-ui:search-container-row>\n' +
                            '    <liferay-ui:search-iterator markupView="lexicon" />\n' +
                            '\n' +
                            '</liferay-ui:search-container>'}
                    </SyntaxHighlighter>

                    edit.jsp:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<%@ page import="ro.agnes.book.web.constants.AgnesBookWebPortletKeys" %>\n' +
                            '<%@ include file="init.jsp"%>\n' +
                            '\n' +
                            '<portlet:renderURL var="backURL"/>\n' +
                            '\n' +
                            '<%--URL pt actiunea formei --%>\n' +
                            '<portlet:actionURL name="<%= AgnesBookWebPortletKeys.MVC_COMMAND_BOOK_EDIT %>" var="addBookURL" />\n' +
                            '\n' +
                            '<%--forma --%>\n' +
                            '<aui:form action="${addBookURL}"  method="post" name="fm" cssClass="agnes-book-web">\n' +
                            '\n' +
                            '    <clay:container-fluid>\n' +
                            '        <clay:sheet size="full">\n' +
                            '            <clay:sheet-header>\n' +
                            '                <h2 class="sheet-title">Adauga carte</h2>\n' +
                            '            </clay:sheet-header>\n' +
                            '            <clay:sheet-section>\n' +
                            '                <aui:input name="bookId" type="hidden" value="${book.bookId}" />\n' +
                            '                <aui:input name="title" label="agnes.book.title" value="${book.title}" />\n' +
                            '                <aui:input name="description" type="textarea" label="agnes.book.description" value="${book.description}" />\n' +
                            '                <aui:input name="publicationYear" type="number" label="agnes.book.publicationYear" value="${book.publicationYear}" />\n' +
                            '            </clay:sheet-section>\n' +
                            '            <clay:sheet-footer>\n' +
                            '                <div class="btn-group">\n' +
                            '                    <div class="btn-group-item">\n' +
                            '                        <clay:link href="${backURL}" type="button" displayType="secondary" label="back" />\n' +
                            '                        <clay:button displayType="primary" label="agnes.btn.save" type="submit"/>\n' +
                            '                    </div>\n' +
                            '                </div>\n' +
                            '            </clay:sheet-footer>\n' +
                            '        </clay:sheet>\n' +
                            '    </clay:container-fluid>\n' +
                            '</aui:form>'}
                    </SyntaxHighlighter>

                    AgnesBookWebPortletKeys:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.constants;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author iulianb\n' +
                            ' */\n' +
                            'public class AgnesBookWebPortletKeys {\n' +
                            '\n' +
                            '\n' +
                            '\tpublic static final String PORTLET_ID = "ro_agnes_book_web_AgnesBookWebPortlet";\n' +
                            '\n' +
                            '\tpublic static final String VIEW_JSP = "/view.jsp";\n' +
                            '\n' +
                            '\tpublic static final String EDIT_JSP = "/edit.jsp";\n' +
                            '\n' +
                            '\tpublic static final String MVC_COMMAND_BOOK_EDIT = "/book/edit";\n' +
                            '\n' +
                            '\tpublic static final String MVC_COMMAND_DEFAULT = "/";\n' +
                            '}'}
                    </SyntaxHighlighter>


                    AgnesBookWebPortlet:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.portlet;\n' +
                            '\n' +
                            'import ro.agnes.book.web.constants.AgnesBookWebPortletKeys;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                            '\n' +
                            'import javax.portlet.Portlet;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '/**\n' +
                            ' * @author iulianb\n' +
                            ' */\n' +
                            '@Component(\n' +
                            '\tproperty = {\n' +
                            '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                            '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                            '\t\t"com.liferay.portlet.instanceable=true",\n' +
                            '\t\t"javax.portlet.display-name=AgnesBookWeb",\n' +
                            '\t\t"javax.portlet.init-param.template-path=/",\n' +
                            '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                            '\t\t"javax.portlet.name=" + AgnesBookWebPortletKeys.PORTLET_ID,\n' +
                            '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                            '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                            '\t},\n' +
                            '\tservice = Portlet.class\n' +
                            ')\n' +
                            'public class AgnesBookWebPortlet extends MVCPortlet {\n' +
                            '}'}
                    </SyntaxHighlighter>

                    ViewBooksMVCRenderCommand:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.portlet.actions;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCRenderCommand;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.agnes.book.web.constants.AgnesBookWebPortletKeys;\n' +
                            '\n' +
                            'import javax.portlet.PortletException;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.portlet.RenderResponse;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + AgnesBookWebPortletKeys.PORTLET_ID,\n' +
                            '                "mvc.command.name=" + AgnesBookWebPortletKeys.MVC_COMMAND_DEFAULT\n' +
                            '        },\n' +
                            '        service = MVCRenderCommand.class\n' +
                            ')\n' +
                            'public class ViewBooksMVCRenderCommand implements MVCRenderCommand {\n' +
                            '    @Override\n' +
                            '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                            '\n' +
                            '        renderRequest.setAttribute("nume", "Biblioteca Agnes");\n' +
                            '\n' +
                            '        return AgnesBookWebPortletKeys.VIEW_JSP;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    EditBookMVCRenderCommand:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.portlet.actions;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.exception.PortalException;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCRenderCommand;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.agnes.book.web.constants.AgnesBookWebPortletKeys;\n' +
                            'import ro.agnes.service.builder.model.Book;\n' +
                            'import ro.agnes.service.builder.service.BookLocalService;\n' +
                            '\n' +
                            'import javax.portlet.PortletException;\n' +
                            'import javax.portlet.RenderRequest;\n' +
                            'import javax.portlet.RenderResponse;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + AgnesBookWebPortletKeys.PORTLET_ID,\n' +
                            '                "mvc.command.name=" + AgnesBookWebPortletKeys.MVC_COMMAND_BOOK_EDIT\n' +
                            '        },\n' +
                            '        service = MVCRenderCommand.class\n' +
                            ')\n' +
                            'public class EditBookMVCRenderCommand implements MVCRenderCommand {\n' +
                            '    @Override\n' +
                            '    public String render(RenderRequest renderRequest, RenderResponse renderResponse) throws PortletException {\n' +
                            '\n' +
                            '        long bookId = ParamUtil.getLong(renderRequest, "bookId");;\n' +
                            '\n' +
                            '        Book book = bookLocalService.fetchBook(bookId);\n' +
                            '        renderRequest.setAttribute("book", book);\n' +
                            '\n' +
                            '        return AgnesBookWebPortletKeys.EDIT_JSP;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private BookLocalService bookLocalService;\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    EditBookMVCActionCommand:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.book.web.portlet.actions;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.log.Log;\n' +
                            'import com.liferay.portal.kernel.log.LogFactoryUtil;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                            'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                            'import com.liferay.portal.kernel.util.Portal;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import org.osgi.service.component.annotations.Reference;\n' +
                            'import ro.agnes.book.web.constants.AgnesBookWebPortletKeys;\n' +
                            'import ro.agnes.service.builder.service.BookLocalService;\n' +
                            '\n' +
                            'import javax.portlet.*;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        property = {\n' +
                            '                "javax.portlet.name=" + AgnesBookWebPortletKeys.PORTLET_ID,\n' +
                            '                "mvc.command.name=" + AgnesBookWebPortletKeys.MVC_COMMAND_BOOK_EDIT\n' +
                            '        },\n' +
                            '        service = MVCActionCommand.class\n' +
                            ')\n' +
                            'public class EditBookMVCActionCommand extends BaseMVCActionCommand {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse)  {\n' +
                            '\n' +
                            '        try {\n' +
                            '\n' +
                            '            long companyId = portal.getCompanyId(actionRequest);\n' +
                            '\n' +
                            '            long bookId = ParamUtil.getLong(actionRequest, "bookId", 0);\n' +
                            '            String title = ParamUtil.getString(actionRequest, "title", "");\n' +
                            '            String description = ParamUtil.getString(actionRequest, "description", "");\n' +
                            '            int publicationYear = ParamUtil.getInteger(actionRequest, "publicationYear", 1990);\n' +
                            '\n' +
                            '            if (bookId > 0) {\n' +
                            '                // edit/update\n' +
                            '                bookLocalService.upgrade(bookId, title, description, publicationYear, 0, companyId );\n' +
                            '            } else {\n' +
                            '                // add\n' +
                            '                bookLocalService.add(title, description, publicationYear, 0, companyId );\n' +
                            '            }\n' +
                            '\n' +
                            '        }catch (Exception e){\n' +
                            '            _log.error(e.getMessage(), e);\n' +
                            '        }\n' +
                            '\n' +
                            '    }\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private Portal portal;\n' +
                            '\n' +
                            '    @Reference\n' +
                            '    private BookLocalService bookLocalService;\n' +
                            '\n' +
                            '    private static Log _log = LogFactoryUtil.getLog(EditBookMVCActionCommand.class);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p6.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/p7.png'}/>
                    </div>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaPortletLiferayContent;