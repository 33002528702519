import React from "react";

import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateClientScopesKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-client-scopes", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className="navivator">
                    <div className={"row"}>
                        <div className={"col-sm text-left"}>
                            <a href={"/keycloak/config-identity-provider"}><FontAwesomeIcon icon={faArrowLeft} /> 10. Configurare Identity Provider</a>
                        </div>
                        <div className={"col-sm"}>
                            <a href={"/keycloak/index"}>[ cuprins ]</a>
                        </div>
                        <div className={"col-sm text-right"}>
                            <a href={"/keycloak/assign-client-scopes-to-client"}>12. Asignare scopuri de client la un client  <FontAwesomeIcon icon={faArrowRight} /></a>
                        </div>
                    </div>
                </div>

                <br/>

                <div className={"text-justify important"}>

                    <b>Creare scopuri de client</b>
                    <br/>
                    Scopurile de client permit definirea unui set comun de roluri si 'protocol mappers', partajate intre mai multi clienti.
                    <ul>
                        <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                        <li>se face click pe <b>Client Scopes</b> (meniul din stanga):</li>
                        <li>se alege tab-ul <b>Client Scopes</b> si apoi se apasa butonul dreapta <b>Create</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l5.png'}/>
                            </div>
                        </li>


                        <li>se completeaza formularul de adaugare rol si se apasa apoi butonul <b>Save</b>:
                            <ul>
                                <li>name: info</li>
                                <li>protocol: <b>openid-connect</b></li>
                            </ul>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l6.png'}/>
                            </div>
                        </li>

                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateClientScopesKeycloakContent;