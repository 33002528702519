import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../elasticsearch/IndexContent";

class InsertElasticsearchContent extends BaseContentPage  {

    constructor(props) {
        super(props, "elasticsearch-insert", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>0. Contectare la masina virtuala</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/elasticsearch/img.png'}/>
                    </div>

                    <b>1. Insert</b>
                    <br/>
                    <br/>

                    Inserarea se face pe un PUT.

                    <br/>
                    <br/>
                    Exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                        {'curl -XPUT 127.0.0.1:9200/movies/_doc/109487 -d \'{\n' +
                            '\t"genre":["IMAX","Sci-Fi"]\n'+
                            '\t"title":"Interstellar"\n'+
                            '\t"year":2014\n'+
                            '}\''}
                    </SyntaxHighlighter>

                    unde:
                    <ul>
                        <li>movies: numele indexului</li>
                        <li>109487: id unic</li>
                    </ul>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://www.digitalocean.com/community/tutorials/how-to-use-systemctl-to-manage-systemd-services-and-units"}>How To Use Systemctl to Manage Systemd Services and Units</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default InsertElasticsearchContent;