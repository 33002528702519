import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MessageSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-message-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Tipuri de mesaje:
                    <ul>
                        <li>
                            SYSTEM (Example for system message prompt: "You are a summarizer", "You are a translator", etc)

                            <br/>
                            Metoda <b>defaultSystem()</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'  public OllamaServiceImpl(@Qualifier("ollamaChatClientBuilder") ChatClient.Builder builder) {\n' +
                                    '\n' +
                                    '        this.chatClient = builder\n' +
                                    '                .defaultSystem("You are an educational tudor. Explain concepts clearly and simply")\n' +
                                    '                .build();\n' +
                                    '    }'}
                            </SyntaxHighlighter>

                            sau:

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'chatClient.prompt()\n' +
                                    '.system("Tu esti un asistent care raspunde la orice intrebare")\n' +
                                    '.user("Care este capitala Italiei?")\n' +
                                    '.call().chatResponse().getResult().getOutput().getContent();'}
                            </SyntaxHighlighter>

                            (ofera cumva un context)

                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'Message systemMessage = new SystemPromptTemplate("Esti un serviciu de vreme").createMessage()'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            USER (Example for user message prompt: "What is capital of France", "Write a short story about a dragon", etc)
                            <br/>
                            Metoda <b>user()</b>
                            <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                                {'chatClient.prompt()\n' +
                                    '.user("Care este capitala Italiei?")\n' +
                                    '.call().chatResponse().getResult().getOutput().getContent();'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            ASSISTANT: raspunsul asistentului (chatgpt, ollama, etc)
                        </li>
                        <li>
                            TOOL: apeluri de functii
                        </li>
                    </ul>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MessageSpringIALlmContent;