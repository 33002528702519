export let oldBooks = {
	"carti": {
		"info": {
			"descriere": "Carti de informatica si calculatoare",
			"include": {
				"domeniu": [
					"Criptografie. Coduri",
					"Limbaje formale",
					"Cercetari operationale",
					"Analiza numerica",
					"Statistica. Data Mining. Biostatistica",
					"Inteligenta artificiala",
					"Beletristica informatica"
				]
			}
		},
		"carte": [
			{
				"titlu": "Tratat de programarea calculatoarelor. Algoritmi fundamentali. Volumul 1",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"tiraj": "7300+140 exemplare legate",
				"numarPagini": "676",
				"pretOriginal": "47",
				"pretCumparare": "10000",
				"traducere": {
					"traducatori": {
						"traducator": [
							"Adrian Davidoviciu",
							"Adrian Petrescu"
						]
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "1",
					"_an": "1973"
				},
				"redactori": {
					"redactor": [
						"Smaranda Dimitriu",
						"Paul Zamfirescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Tratat_de_programare_a_calculatoarelor_Algoritmi_fundamentali_Knuth .jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "1.1",
							"__text": "Algoritmi"
						},
						{
							"_nr": "1.2",
							"__text": "Consideratii matematice preliminare"
						},
						{
							"_nr": "1.3",
							"__text": "M I X"
						},
						{
							"_nr": "1.4",
							"__text": "Cateva tehnici fundamentale de programare"
						},
						{
							"_nr": "2",
							"__text": "Structuri ale informatiei"
						},
						{
							"_nr": "2.1",
							"__text": "Intoducere"
						},
						{
							"_nr": "2.2",
							"__text": "Liste liniare"
						},
						{
							"_nr": "2.3",
							"__text": "Structuri arborescente"
						},
						{
							"_nr": "2.4",
							"__text": "Structuri multiplu inlantuite"
						},
						{
							"_nr": "2.5",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "2.6",
							"__text": "Istoric si bibliografie"
						}
					]
				},
				"_id": "1"
			},
			{
				"titlu": "Tratat de programarea calculatoarelor. Sortare si cautare. Volumul 3",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "6500+70 exemplare legate",
				"numarPagini": "740",
				"pretOriginal": "48",
				"pretCumparare": "30000",
				"traducere": {
					"traducatori": {
						"traducator": [
							"Rodica Boconcios",
							"Adrian Davidoviciu",
							"P. Dimo",
							"Fl. Moraru",
							"Adrian Petrescu",
							"I. Sipos"
						]
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "2",
					"_an": "1973"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Tratat_de_programare_a_calculatoarelor_Sortare_si_cautatare_Knuth.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "5",
							"__text": "Sortare"
						},
						{
							"_nr": "5.1",
							"__text": "Proprietatile combinatorii ale permutarilor"
						},
						{
							"_nr": "5.2",
							"__text": "Sortare interna"
						},
						{
							"_nr": "5.3",
							"__text": "Sortare optima"
						},
						{
							"_nr": "5.4",
							"__text": "Sortare externa"
						},
						{
							"_nr": "5.5",
							"__text": "Rezumat, istoric si bibliografie"
						},
						{
							"_nr": "6",
							"__text": "Cautare"
						},
						{
							"_nr": "6.1",
							"__text": "Cautare secventiala"
						},
						{
							"_nr": "6.2",
							"__text": "Cautare prin comparatie de chei"
						},
						{
							"_nr": "6.3",
							"__text": "Cautare numerica"
						},
						{
							"_nr": "6.4",
							"__text": "Dispersarea"
						},
						{
							"_nr": "6.5",
							"__text": "Regasirea dupa chei secundare"
						}
					]
				},
				"_id": "2"
			},
			{
				"titlu": "Sisteme de calculatoare cu divizarea timpului",
				"autori": {
					"autor": [
						{
							"_nume": "Walter J. Karplus"
						},
						{
							"_nume": "Walter F. Bauer"
						},
						{
							"_nume": "Glen J. Culler"
						},
						{
							"_nume": "ETC"
						}
					]
				},
				"anAparitie": "1970",
				"editura": "Tehnica",
				"tiraj": "",
				"numarPagini": "336",
				"pretOriginal": "29",
				"pretCumparare": "10000",
				"traducere": {
					"traducatori": {
						"traducator": ""
					},
					"_limba": "engleza",
					"_tara": "S.U.A",
					"_an": "1967",
					"_editia": "1"
				},
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": ""
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_calculatoare_cu_divizarea_timpului_Karplus.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Analiza si proiectarea sistemelor"
						},
						{
							"_nr": "3",
							"__text": "Echipament de intrare-iesire pentru date grafice"
						},
						{
							"_nr": "4",
							"__text": "Aspctele economice sle sistemelor de calcul on-line"
						},
						{
							"_nr": "5",
							"__text": "Consideratii fiziologice si psihologice"
						},
						{
							"_nr": "6",
							"__text": "Rezolvarea problemelor matematice"
						},
						{
							"_nr": "7",
							"__text": "Rezolvarea problemelor de proiectare"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii in domeniul sistemelor de conducere strategica"
						},
						{
							"_nr": "9",
							"__text": "Teoria organizarii fisierelor"
						},
						{
							"_nr": "10",
							"__text": "Considerente sociologice"
						}
					]
				},
				"_id": "3"
			},
			{
				"titlu": "Practica programarii calculatoarelor",
				"autori": {
					"autor": {
						"_nume": "Ivan Flores"
					}
				},
				"anAparitie": "1969",
				"editura": "Tehnica",
				"tiraj": "",
				"numarPagini": "416",
				"pretOriginal": "31",
				"pretCumparare": "10000",
				"traducere": {
					"traducatori": {
						"traducator": ""
					},
					"_an": "1968",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Practica_programarii_calculatoarelor_Flores.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatorul"
						},
						{
							"_nr": "2",
							"__text": "Teoria grafelor"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de adresare"
						},
						{
							"_nr": "4",
							"__text": "Programe si subprograme simple"
						},
						{
							"_nr": "5",
							"__text": "Cicluri"
						},
						{
							"_nr": "6",
							"__text": "Masive multidimensionale"
						},
						{
							"_nr": "7",
							"__text": "Date: calculatoare organizate pe campuri"
						},
						{
							"_nr": "8",
							"__text": "Liste, tabele, fisiere, inregistrari"
						},
						{
							"_nr": "9",
							"__text": "Liste ordonate"
						},
						{
							"_nr": "10",
							"__text": "Intarea si iesirea"
						},
						{
							"_nr": "11",
							"__text": "Memorii tampon"
						},
						{
							"_nr": "12",
							"__text": "Notiuni fundamentale asupra sistemelor de programe (software)"
						},
						{
							"_nr": "13",
							"__text": "Analiza sistemelor de programe"
						}
					]
				},
				"_id": "4"
			},
			{
				"titlu": "Circuite integrate digitale si sisteme cu microprocesoare",
				"autori": {
					"autor": [
						{
							"_nume": "I. Spanulescu"
						},
						{
							"_nume": "S. I. Spanulescu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Victor",
				"tiraj": "",
				"numarPagini": "400",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Butnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Circuite_integrate_digitale_si_sisteme_cu_microprocesoare_Spanulescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algebra numerelor si functiilor binare"
						},
						{
							"_nr": "2",
							"__text": "Circuite logice combinationale. Porti logice"
						},
						{
							"_nr": "3",
							"__text": "Circuite digitale combinationale complexe. Structuri logice programabile de tip ROM"
						},
						{
							"_nr": "4",
							"__text": "Elemente aritmetice combinationale"
						},
						{
							"_nr": "5",
							"__text": "Sisteme secventiale. Circuite basculante bistabile"
						},
						{
							"_nr": "6",
							"__text": "Numaratoare electronice in binar. Registre"
						},
						{
							"_nr": "7",
							"__text": "Circuite de memorie"
						},
						{
							"_nr": "8",
							"__text": "Proiectarea automatelor cu stari finite prin metoda masinii algoritmice de stare ASM"
						},
						{
							"_nr": "9",
							"__text": "Arhitectura unui microprocesor standard de 8 biti si a unui sistem cu microprocesor"
						},
						{
							"_nr": "10",
							"__text": "Arhitectura de baza a microprocesoarelor I 80x86"
						},
						{
							"_nr": "11",
							"__text": "Arhitectura de baza si functionarea microprocesorului INTEL 80286"
						},
						{
							"_nr": "12",
							"__text": "Microprocesoare de tip 32 de biti - I 80386 si I 80486"
						}
					]
				},
				"_id": "5",
				"_isbn": "973-973318-2-1"
			},
			{
				"titlu": "Bazele informaticii - Manual [X]",
				"autori": {
					"autor": {
						"_nume": "Ioan Tomescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Didactica si pedagogica",
				"tiraj": "",
				"numarPagini": "104",
				"pretOriginal": "4900",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Daniela Nitescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Oprisanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_X_Tomescu.jpg"
					},
					"__text": "Doina Baranowsky"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Structuri de date"
						},
						{
							"_nr": "II",
							"__text": "Elemente de teoria grafurilor"
						},
						{
							"_nr": "III",
							"__text": "Indicatii si raspunsuri"
						}
					]
				},
				"_id": "6",
				"_isbn": "973-30-5730-4"
			},
			{
				"titlu": "Probleme de combinatorica si teoria grafurilor",
				"autori": {
					"autor": {
						"_nume": "Ioan Tomescu"
					}
				},
				"anAparitie": "1981",
				"editura": "Didactica si pedagogica",
				"tiraj": "",
				"numarPagini": "272",
				"pretOriginal": "7.40",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Eugenia Pantelimon"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vergilia Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_combinatorica_si_teoria_grafurilor_Tomescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Identitati combinatorii"
						},
						{
							"_nr": "2",
							"__text": "Principiul includerii si al excluderii si formule de inversiune"
						},
						{
							"_nr": "3",
							"__text": "Numerele lui Stirling, Bell, Fibonacci, Catalan"
						},
						{
							"_nr": "4",
							"__text": "Probleme de combinatorica multimilor"
						},
						{
							"_nr": "5",
							"__text": "Partitiile unei intreg"
						},
						{
							"_nr": "6",
							"__text": "Arbori"
						},
						{
							"_nr": "7",
							"__text": "Paritate"
						},
						{
							"_nr": "8",
							"__text": "Conexitate"
						},
						{
							"_nr": "9",
							"__text": "Probleme extremale pentru grafuri si retele de transport"
						},
						{
							"_nr": "10",
							"__text": "Probleme de colorare"
						},
						{
							"_nr": "11",
							"__text": "Probleme hamiltoniene"
						},
						{
							"_nr": "12",
							"__text": "Permutari"
						},
						{
							"_nr": "13",
							"__text": "Numararea claselor de configuratii relative la un grup de permutari"
						},
						{
							"_nr": "14",
							"__text": "Probleme de tip Ramsey"
						}
					]
				},
				"_id": "7"
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": [
						{
							"_nume": "Stanescu Maria"
						},
						{
							"_nume": "Tamas Ilie"
						},
						{
							"_nume": "Nastase Pavel"
						},
						{
							"_nume": "Berbec Florentina"
						},
						{
							"_nume": "Nastase Floarea"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Cison",
				"numarPagini": "240",
				"pretOriginal": "7800",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Babeanu Delia",
						"Bostan Mirela"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Stanescu.jpg"
					},
					"__text": "Chiru Gheorghe"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni teoretice privind prelucrarea automata a datelor"
						},
						{
							"_nr": "II",
							"__text": "Sisteme electronice de calcul"
						},
						{
							"_nr": "III",
							"__text": "Organizarea si reprezentarea datelor in procesul de prelucrare automata a datelor"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme de operare ale calculatoarelor electronice"
						},
						{
							"_nr": "V",
							"__text": "Retele locale de calculatoare electronice"
						},
						{
							"_nr": "VI",
							"__text": "Interconectivitatea retelelor de calculatoarelor"
						}
					]
				},
				"_id": "8",
				"_isbn": "973-96370-3-5"
			},
			{
				"titlu": "Teoria generala a bazelor de date",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Despi"
						},
						{
							"_nume": "Gheorghe Petrov"
						},
						{
							"_nume": "Reisz Robert"
						},
						{
							"_nume": "Aurel Stepan"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Mirton",
				"numarPagini": "482",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Teoria_generala_a_bazelor_de_date_Despi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "Partea 1",
							"__text": "Proiectarea logica a bazelor de date"
						},
						{
							"_nr": "Partea 2",
							"__text": "Proiectarea fizica a bazelor de date"
						},
						{
							"_nr": "Partea 3",
							"__text": "Administrarea si protectia bazelor de date"
						},
						{
							"_nr": "Partea 4",
							"__text": "Aplicatii in SGBD FoxPro"
						}
					]
				},
				"_id": "9",
				"_isbn": "973-578-663-X"
			},
			{
				"titlu": "Informatica - Manual (varianta C++)[XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Daniela Oprescu"
						},
						{
							"_nume": "Liana Bejan Ienulescu"
						},
						{
							"_nume": "Viorica Patrascu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Niculescu",
				"numarPagini": "216",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_XI_Varianta_Cpp_Oprescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de teoira grafurilor"
						},
						{
							"_nr": "II",
							"__text": "Alocarea dinamica a memoriei interne"
						},
						{
							"_nr": "III",
							"__text": "Elemente de programare orientata pe obiecte"
						},
						{
							"_nr": "IV",
							"__text": "Aplicatii practice"
						}
					]
				},
				"_id": "10",
				"_isbn": "973-568-544-2"
			},
			{
				"titlu": "Culegere de probleme Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Atanasiu"
						},
						{
							"_nume": "Rodica Pintea"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Petrion",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "28000",
				"coperta": {
					"imagine": {
						"_path": "Culegere_de_probleme_Pascal_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Algoritmi elementari"
						},
						{
							"_nr": "II",
							"__text": "Algoritmi cu tablouri"
						},
						{
							"_nr": "III",
							"__text": "Siruri de caractere si multimi"
						},
						{
							"_nr": "IV",
							"__text": "Structuri de tip inregistrare"
						},
						{
							"_nr": "V",
							"__text": "Proceduri si functii definite"
						},
						{
							"_nr": "VI",
							"__text": "Unitati de program"
						},
						{
							"_nr": "VII",
							"__text": "Fisiere"
						},
						{
							"_nr": "VIII",
							"__text": "Recursitate"
						},
						{
							"_nr": "IX",
							"__text": "Grafuri"
						},
						{
							"_nr": "X",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "XI",
							"__text": "Alocare dinamica"
						}
					]
				},
				"_id": "11",
				"_isbn": "973-9116-05-1"
			},
			{
				"titlu": "Bazele informaticii (Grafuri si Elemente de Combinatorica) - Manual [X]",
				"autori": {
					"autor": [
						{
							"_nume": "Cornelia Ivasc"
						},
						{
							"_nume": "Mona Pruna"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Petrion",
				"numarPagini": "176",
				"pretOriginal": "0",
				"pretCumparare": "11000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Grafuri_si_Elemente_de_combinatorica_X_Ivasc.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Grafuri"
						},
						{
							"_nr": "II",
							"__text": "Elemente de combinatorica"
						},
						{
							"_nr": "III",
							"__text": "Inducatii la problemele propuse"
						},
						{
							"_nr": "IV",
							"__text": "Anexa - Complexitatea algoritmilor"
						}
					]
				},
				"_id": "12",
				"_isbn": "973-9116-0-0"
			},
			{
				"titlu": "C++ Probleme rezolvate si algoritmi",
				"autori": {
					"autor": {
						"_nume": "Doina Hrinciuc Logofatu"
					}
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "280",
				"pretOriginal": "0",
				"pretCumparare": "80000",
				"coperta": {
					"imagine": {
						"_path": "Cpp_Probleme_rezolvate_si_algoritmi_Logofatu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajul C - Prezentare generala"
						},
						{
							"_nr": "2",
							"__text": "Extensii C++"
						},
						{
							"_nr": "3",
							"__text": "Numere si elemente de combinatorica"
						},
						{
							"_nr": "4",
							"__text": "Tablouri"
						},
						{
							"_nr": "5",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "6",
							"__text": "Greedy"
						},
						{
							"_nr": "7",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "8",
							"__text": "Geometrie"
						},
						{
							"_nr": "9",
							"__text": "Recursivitate"
						},
						{
							"_nr": "10",
							"__text": "Divide-et-impera"
						},
						{
							"_nr": "11",
							"__text": "Backtracking"
						},
						{
							"_nr": "12",
							"__text": "Grafuri"
						}
					]
				},
				"_id": "13",
				"_isbn": "973-683-690-8"
			},
			{
				"titlu": "XML",
				"autori": {
					"autor": {
						"_nume": "Adrian Vasilescu"
					}
				},
				"anAparitie": "2001",
				"editura": "Economica",
				"numarPagini": "176",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Florin Erhan"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Capitanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "XML_Vasilescu.jpg"
					},
					"__text": "Adriana Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Obiective ale proiectarii si terminologie"
						},
						{
							"_nr": "2",
							"__text": "Documente"
						},
						{
							"_nr": "3",
							"__text": "Structuri logice"
						},
						{
							"_nr": "4",
							"__text": "Structuri fizice"
						},
						{
							"_nr": "5",
							"__text": "Conformare"
						},
						{
							"_nr": "6",
							"__text": "Notatie"
						}
					]
				},
				"_id": "14",
				"_isbn": "973-590-539-6"
			},
			{
				"titlu": "Macromedia Fireworks MX",
				"autori": {
					"autor": {
						"_nume": "Patti Schulze"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "218",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "475"
				},
				"traducere": {
					"titluOriginal": "Macromedia Fireworks MX: Training frpm the Source",
					"editura": "Macromedia Press",
					"traducatori": {
						"traducator": [
							"Cora Radulian",
							"Mihai Manastireanu",
							"Dan Pavelescu"
						]
					},
					"_an": "2003",
					"_limba": "engleza",
					"_editia": "1",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Macromedia_Fireworks_MX_Schulze.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Editarea imaginilor bitmap"
						},
						{
							"_nr": "2",
							"__text": "Utilizarea instrumentelor vectoriale"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu straturi"
						},
						{
							"_nr": "4",
							"__text": "Insertia textelor"
						},
						{
							"_nr": "5",
							"__text": "Tehnici avansate"
						},
						{
							"_nr": "6",
							"__text": "Crearea butoanelor"
						},
						{
							"_nr": "7",
							"__text": "Crearea feliilor si a punctelor fierbinti"
						},
						{
							"_nr": "8",
							"__text": "Optimizare si export"
						},
						{
							"_nr": "9",
							"__text": "Crearea animatiilor GIF"
						},
						{
							"_nr": "10",
							"__text": "Mascarea si meniurile pop-up"
						},
						{
							"_nr": "11",
							"__text": "Integrarea wu Dreamweaver MX"
						}
					]
				},
				"_id": "15",
				"_isbn": "973-20-0635-8"
			},
			{
				"titlu": "Multimedia - Ghid practic",
				"autori": {
					"autor": {
						"_nume": "Tay Vaughan"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "416",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "445"
				},
				"traducere": {
					"titluOriginal": "Multimedia: Making It Work,Fifth Edition",
					"editura": "Osborne Media Group",
					"traducatori": {
						"traducator": [
							"Cora Radulian",
							"Alina Teodoru",
							"Mihai Manastireanu"
						]
					},
					"_an": "2001",
					"_limba": "engleza",
					"_editia": "5"
				},
				"coperta": {
					"imagine": {
						"_path": "Multimedia_Ghid_practic_Vaughan.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Componente hardware si software pentru multimedia"
						},
						{
							"_nr": "III",
							"__text": "Elemente constuctive ale unui proiect multimedia"
						},
						{
							"_nr": "IV",
							"__text": "Multimedia si Internetul"
						}
					]
				},
				"_id": "16",
				"_isbn": "973-601-931-4"
			},
			{
				"titlu": "Macromedia ColdFusion 5",
				"autori": {
					"autor": {
						"_nume": "Jeffry Houser"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "560",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "433"
				},
				"traducere": {
					"titluOriginal": "Instant ColdFusion 5",
					"editura": "Osborne Media Group",
					"traducatori": {
						"traducator": "Radu Biris"
					},
					"_an": "2001",
					"_limba": "engleza",
					"_editia": "1"
				},
				"coperta": {
					"imagine": {
						"_path": "Macromedia_ColdFusion_5_Houser.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Interactivitate in internet"
						},
						{
							"_nr": "II",
							"__text": "Elemente de particularizare pentru utilizator"
						},
						{
							"_nr": "III",
							"__text": "Creare unui site intranet in programul ColdFusion"
						},
						{
							"_nr": "IV",
							"__text": "Notiuni avansate despre transmiterea datelor"
						}
					]
				},
				"_id": "17",
				"_isbn": "973-601-414-2"
			},
			{
				"titlu": "Microsoft FrontPage 98",
				"autori": {
					"autor": [
						{
							"_nume": "Denise Tyler"
						},
						{
							"_nume": "Crystal D. Erickson"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "678",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "302"
				},
				"traducere": {
					"titluOriginal": "Laura Lemay's Workshop: FrontPage 98",
					"editura": "SAMS Publishing",
					"traducatori": {
						"traducator": "Nicolae Ionescu Crutan"
					},
					"_an": "1997",
					"_limba": "engleza",
					"_editia": "1"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_FrontPage_98_Tyler.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Familarizarea cu programul"
						},
						{
							"_nr": "II",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "III",
							"__text": "Tehnici avansate"
						},
						{
							"_nr": "IV",
							"__text": "Folosirea formularelor"
						},
						{
							"_nr": "V",
							"__text": "Alte tehnici avansate"
						},
						{
							"_nr": "VI",
							"__text": "Publicarea si intretinerea Web-urilor"
						},
						{
							"_nr": "VII",
							"__text": "Anexe"
						}
					]
				},
				"_id": "18",
				"_isbn": "973-20-0189-5"
			},
			{
				"titlu": "Macromedia Flash MX 2004",
				"autori": {
					"autor": {
						"_nume": "Jen Dehaan"
					}
				},
				"anAparitie": "2004",
				"editura": "Teora USA LLC",
				"numarPagini": "398",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Macromedia Flash MX 2004: Training from the Source",
					"editura": "Macromedia Press",
					"traducatori": {
						"traducator": "Nicolae Ionescu Crutan"
					},
					"_an": "2004",
					"_limba": "engleza",
					"_editia": "1"
				},
				"coperta": {
					"imagine": {
						"_path": "Macromedia_Flash_MX_2004_Dehaan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Elaborarea graficii"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu text"
						},
						{
							"_nr": "4",
							"__text": "Crearea si editarea simbolurilor"
						},
						{
							"_nr": "5",
							"__text": "Crearea animatiilor"
						},
						{
							"_nr": "6",
							"__text": "Introducerea elementelor fundamentale de interactivitate"
						},
						{
							"_nr": "7",
							"__text": "Intoducerea sunetului si a imaginii"
						},
						{
							"_nr": "8",
							"__text": "Lucrul cu ecrane"
						},
						{
							"_nr": "9",
							"__text": "Crearea formularelor cu ajutorul componetelor"
						},
						{
							"_nr": "10",
							"__text": "Inserarea datelor dinamice"
						},
						{
							"_nr": "11",
							"__text": "Notiuni fundamentale despre ActionScript"
						},
						{
							"_nr": "12",
							"__text": "Optimizarea continutului Flash"
						},
						{
							"_nr": "13",
							"__text": "Publicarea documentelor Flash"
						}
					]
				},
				"_id": "19",
				"_isbn": "1-59496-018-6"
			},
			{
				"titlu": "Macromedia Director 8",
				"autori": {
					"autor": {
						"_nume": "Gary Rosenzweig"
					}
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "896",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "403"
				},
				"traducere": {
					"titluOriginal": "Special Edition Using Macromedia Director 8",
					"editura": "QUE Corporation",
					"traducatori": {
						"traducator": "Cora Radulian"
					},
					"_an": "2000",
					"_limba": "engleza",
					"_editia": "1"
				},
				"coperta": {
					"imagine": {
						"_path": "Macromedia_Director_8_Rosenzweig.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente fundamentale ale programului Director"
						},
						{
							"_nr": "II",
							"__text": "Prezentarea detaliata a membrilor"
						},
						{
							"_nr": "III",
							"__text": "Crearea proiectelor in Director"
						},
						{
							"_nr": "IV",
							"__text": "Utilizarea elementelor Lingo de baza"
						},
						{
							"_nr": "V",
							"__text": "Utilizarea componentelor"
						},
						{
							"_nr": "VI",
							"__text": "Utilizarea limbajului Lingo ca mediu de control"
						},
						{
							"_nr": "VII",
							"__text": "Utilizarea tehnicilor Lingo avansate"
						},
						{
							"_nr": "VIII",
							"__text": "Utilizarea programului Director pentru crearea aplicatiilor profesioniste"
						},
						{
							"_nr": "IX",
							"__text": "Incheierea unui proiect"
						},
						{
							"_nr": "X",
							"__text": "Subiecte de nivel avansat"
						},
						{
							"_nr": "XI",
							"__text": "Ghid de referinta Lingo"
						}
					]
				},
				"_id": "20",
				"_isbn": "973-20-0266"
			},
			{
				"titlu": "Macromedia Dreamweaver 3",
				"autori": {
					"autor": {
						"_nume": "Betsy Bruce"
					}
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "376",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "398"
				},
				"traducere": {
					"titluOriginal": "Teach Yourself Macromedia Dreamweaver 3 in 24 Hours",
					"editura": "SAMS Publishing",
					"traducatori": {
						"traducator": "Mihai Manastireanu"
					},
					"_an": "2000",
					"_editia": "1",
					"_limba": "engleza"
				},
				"coperta": {
					"imagine": {
						"_path": "Macromedia_Dreanweaver_3_Bruce.jpg"
					},
					"__text": "Carmen Biris"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Macromedia Director 8 - Primii pasi"
						},
						{
							"_nr": "II",
							"__text": "Adaugarea obiectelor grafice si multimedia in paginile Web"
						},
						{
							"_nr": "III",
							"__text": "Machete si proiectare cu Dreamweaver"
						},
						{
							"_nr": "IV",
							"__text": "Insertia scripturilor si a actiunilor Dreamweaver in paginile Web"
						},
						{
							"_nr": "V",
							"__text": "Colectarea datelor de la utilizatori cu ajutorul formularelor"
						},
						{
							"_nr": "VI",
							"__text": "Imaginea de ansamblu : gestiunea proiectelor dumneavoastra"
						},
						{
							"_nr": "VII",
							"__text": "Reutilizarea elementelor pentru cresterea eficientei dezvoltarii"
						},
						{
							"_nr": "VIII",
							"__text": "Utilizarea editoarelor externe si modificarea programului Dreamweaver conform preferintelor dumneavoastra"
						},
						{
							"_nr": "IX",
							"__text": "Anexe"
						}
					]
				},
				"_id": "21",
				"_isbn": "973-20-0260-3"
			},
			{
				"titlu": "XML in Flash",
				"autori": {
					"autor": [
						{
							"_nume": "Craig Swann"
						},
						{
							"_nume": "Greg Caines"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "266",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "455"
				},
				"traducere": {
					"titluOriginal": "",
					"editura": "QUE Publishing",
					"traducatori": {
						"traducator": [
							"Simona Preda",
							"Titi Preda"
						]
					},
					"_an": "2002",
					"_editia": "1",
					"_limba": "engleza"
				},
				"coperta": {
					"imagine": {
						"_path": "XML_in_Flash_Swann.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Prezentarea utilizarii limbajului XML in programul Flash"
						},
						{
							"_nr": "II",
							"__text": "Programul Flash si continutul dinamic"
						},
						{
							"_nr": "III",
							"__text": "Programul Flash si obiectul XMLSocket"
						},
						{
							"_nr": "IV",
							"__text": "Anexe"
						}
					]
				},
				"_id": "22",
				"_isbn": "973-20-0472-X"
			},
			{
				"titlu": "Cine stie LOGO?",
				"autori": {
					"autor": {
						"_nume": "Ion Diamandi"
					}
				},
				"anAparitie": "1994",
				"editura": "Agni",
				"numarPagini": "170",
				"pretOriginal": "1450",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": "Cristina Manoiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Cine_stie_LOGO_Diamandi.jpg"
					},
					"__text": "Adina Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Tastatura, ecranul si broasca testoasa"
						},
						{
							"_nr": "II",
							"__text": "Proceduri"
						},
						{
							"_nr": "III",
							"__text": "Variabile"
						},
						{
							"_nr": "IV",
							"__text": "Activitati conditionate si recursivitatea"
						},
						{
							"_nr": "V",
							"__text": "Utilizarea coordonatelor"
						},
						{
							"_nr": "VI",
							"__text": "Alte obiecte LOGO"
						},
						{
							"_nr": "VII",
							"__text": "Probleme scolare"
						},
						{
							"_nr": "VIII",
							"__text": "Activitati pentru vacanta"
						},
						{
							"_nr": "IX",
							"__text": "O deschidere pentru mai departe : LOGOGOMETRIA"
						}
					]
				},
				"_id": "23",
				"_isbn": "973-95626-9-8"
			},
			{
				"titlu": "Provocarea algoritmilor",
				"autori": {
					"autor": {
						"_nume": "Victor Mitrana"
					}
				},
				"anAparitie": "1994",
				"editura": "Agni",
				"numarPagini": "160",
				"pretOriginal": "2800",
				"pretCumparare": "25000",
				"colectia": {
					"_nume": "Biblioteca de informatica",
					"_numarul": "0"
				},
				"redactori": {
					"redactor": "Ion Mandoiu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Cezar Ciobotaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Provocarea_algoritmilor_Probleme_pentru_concursurile_de_informatica_Mitrana.jpg"
					},
					"__text": "Adina Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Capitorul 1"
						},
						{
							"_nr": "1.1",
							"__text": "Introducere"
						},
						{
							"_nr": "1.2",
							"__text": "Limbajul algoritmic"
						},
						{
							"_nr": "1.2.1",
							"__text": "Date"
						},
						{
							"_nr": "1.2.2",
							"__text": "Instructiuni"
						},
						{
							"_nr": "1.3",
							"__text": "Corectitudine si complexitate de calcul"
						},
						{
							"_nr": "2",
							"__text": "Capitorul 2"
						},
						{
							"_nr": "2.1",
							"__text": "Probleme pentru gimnaziu"
						},
						{
							"_nr": "2.1.1",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "3",
							"__text": "Capitorul 3"
						},
						{
							"_nr": "3.1",
							"__text": "Probleme pentru liceu"
						},
						{
							"_nr": "3.1.1",
							"__text": "Algoritmi numerici si algebrici"
						},
						{
							"_nr": "3.1.1.1",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "3.1.2",
							"__text": "Probleme cu secvente de simboluri"
						},
						{
							"_nr": "3.1.2.1",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "3.1.3",
							"__text": "Probleme geometrice"
						},
						{
							"_nr": "3.1.3.1",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "3.1.3",
							"__text": "Algoritmi cu grafuri"
						},
						{
							"_nr": "3.1.3.1",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "24",
				"_isbn": "973-96347-2-9"
			},
			{
				"titlu": "LogoMatematica",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Vass"
					}
				},
				"anAparitie": "1995",
				"editura": "Alternative",
				"numarPagini": "250",
				"pretOriginal": "4800",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Vass"
				},
				"coperta": {
					"imagine": {
						"_path": "LogoMatematica_initiere_in_Logo_Matematica_si_stiinte_exacte_Vass.jpg"
					},
					"__text": "Claudiu B.Serban"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Editorul LOGO"
						},
						{
							"_nr": "II",
							"__text": "Lectii LOGO"
						},
						{
							"_nr": "III",
							"__text": "Dictionarul LOGO"
						},
						{
							"_nr": "VI",
							"__text": "Experiente LOGO"
						},
						{
							"_nr": "V",
							"__text": "Medii LOGO de invatare"
						},
						{
							"_nr": "VI",
							"__text": "Rosturile LOGO-Matematicii"
						}
					]
				},
				"_id": "25",
				"_isbn": "973-96996-2-6"
			},
			{
				"titlu": "Introducere in programarea logica.Limbajul Prolog",
				"autori": {
					"autor": {
						"_nume": "Nicolae Tandareanu"
					}
				},
				"anAparitie": "1994",
				"editura": "INTARF",
				"numarPagini": "214",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "1",
					"_nume": "Inteligenta artificiala"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programarea_logica_Limbajul_Prolog_Tandareanu.jpg"
					},
					"__text": "Adrian Sandita"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Partea I"
						},
						{
							"_nr": "I.1",
							"__text": "Calculul propozitional"
						},
						{
							"_nr": "I.2",
							"__text": "Calculul predicatelor"
						},
						{
							"_nr": "I.3",
							"__text": "Unificare, rezolutie si demonstrare automata"
						},
						{
							"_nr": "II",
							"__text": "Partea a-II-a"
						},
						{
							"_nr": "II.1",
							"__text": "Elemente de baza"
						},
						{
							"_nr": "II.2",
							"__text": "Prelucrari de obiecte"
						},
						{
							"_nr": "II.3",
							"__text": "Predicate standard de intrare-iesire.Gadica in Prolog"
						},
						{
							"_nr": "II.4",
							"__text": "Fisiere si baze de date"
						},
						{
							"_nr": "II.5",
							"__text": "Stil de programare"
						}
					]
				},
				"_id": "26",
				"_isbn": "973-95984-1-2"
			},
			{
				"titlu": "Ghidul bobocului pentru MS-DOS 6 - Comenzi",
				"autori": {
					"autor": {
						"_nume": "Jennifer Fulton"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "264",
				"pretOriginal": "11000",
				"traducere": {
					"titluOriginal": "The complete idiot's poket reference to Ms-Dos 6",
					"editura": "QUE Corporation",
					"traducatori": {
						"traducator": "Rodica German"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Diana Dumitrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Carmen Dumitrecu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_MS_DOS_6_Comenzi_Fulton.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Strictul necesar despre DOS"
						},
						{
							"_nr": "2",
							"__text": "Sa stapanim sistemul de operare DOS: Dictionar de comenzi DOS"
						},
						{
							"_nr": "3",
							"__text": "Ciopartirea fisierelor cu editorul DOS (EDIT.COM)"
						},
						{
							"_nr": "4",
							"__text": "Mai mult decat ati dori sa stiti despre configuratia calculatorului dumneavoastra"
						},
						{
							"_nr": "5",
							"__text": "Mesaje de eroare ale sistemului DOS"
						}
					]
				},
				"_id": "27",
				"_isbn": "973-601-333-2"
			},
			{
				"titlu": "Webmarketing in Romania",
				"autori": {
					"autor": {
						"_nume": "George Alin Popescu"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "124",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "480"
				},
				"coperta": {
					"imagine": {
						"_path": "Webmarketing_in_Romania_Popescu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Dictionar de termeni utilizati in marketingul online"
						},
						{
							"_nr": "3",
							"__text": "Cui se adreseaza aceasta carte"
						},
						{
							"_nr": "4",
							"__text": "Primii pasi intr-o afacere virtuala"
						},
						{
							"_nr": "5",
							"__text": "Webhosting"
						},
						{
							"_nr": "6",
							"__text": "Markentinul site-ului dvs."
						},
						{
							"_nr": "7",
							"__text": "Administrarea site-ului"
						},
						{
							"_nr": "8",
							"__text": "In loc de epilog"
						}
					]
				},
				"_id": "28",
				"_isbn": "973-20-0663-3"
			},
			{
				"titlu": "Unix.Aplicatii",
				"autori": {
					"autor": {
						"_nume": "Dan Cosma"
					}
				},
				"anAparitie": "2001",
				"editura": "Editura de Vest",
				"numarPagini": "112",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"coperta": {
					"imagine": {
						"_path": "Unix_Aplicatii_Cosma.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Interfata cu utilizatorul"
						},
						{
							"_nr": "2",
							"__text": "Fisiere de comenzi"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de fisiere"
						},
						{
							"_nr": "4",
							"__text": "Apeluri sistem si functii de biblioteca C pentru lucrul cu fisiere"
						},
						{
							"_nr": "5",
							"__text": "Apeluri sistem si functii de biblioteca C pentru lucrul cu directoare"
						},
						{
							"_nr": "6",
							"__text": "Procese"
						},
						{
							"_nr": "7",
							"__text": "Comunicarea intre procese folosind semnale"
						},
						{
							"_nr": "8",
							"__text": "Comunicarea intre procese folosind PIPES"
						},
						{
							"_nr": "9",
							"__text": "Prelucrarea paramentrilor din linia de comanda in C"
						},
						{
							"_nr": "10",
							"__text": "Cateva comenzi EMACS"
						}
					]
				},
				"_id": "29",
				"_isbn": "973-36-0338-4"
			},
			{
				"titlu": "Bazele informaticii economice",
				"autori": {
					"autor": {
						"_nume": "Dr. Tudorel M. Fatu"
					}
				},
				"anAparitie": "1993",
				"editura": "Graphix",
				"numarPagini": "362",
				"pretOriginal": "2300",
				"colectia": {
					"_nume": "Economie, Informatica, Marketing, Management, Finante, Burse, Banci",
					"_numarul": "0"
				},
				"redactori": {
					"redactor": "Christian Tamas"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicusor-Lilian Miu"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_economice_Fatu.jpg"
					},
					"__text": "Lucian Tiron"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Evolutia tehnicii de calcul"
						},
						{
							"_nr": "II",
							"__text": "Concepte de baza utilizate in prelucrarea automata a datelor"
						},
						{
							"_nr": "III",
							"__text": "Sisteme de numeratie si coduri de reprezentare a datelor"
						},
						{
							"_nr": "IV",
							"__text": "Logica programarii calculatoarelor electronice"
						},
						{
							"_nr": "V",
							"__text": "Memorii externe (auxiliare)"
						},
						{
							"_nr": "VI",
							"__text": "Microcalculatoare IBM PC"
						},
						{
							"_nr": "VII",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "VIII",
							"__text": "Minicalculatoare"
						},
						{
							"_nr": "IX",
							"__text": "Retele de calculatoare"
						}
					]
				},
				"_id": "30",
				"_isbn": "973-95934-7-X"
			},
			{
				"titlu": "Turbo Pascal 6.0 - Culegere de programe",
				"autori": {
					"autor": [
						{
							"_nume": "Vasiu Lucian"
						},
						{
							"_nume": "Grama Radu"
						},
						{
							"_nume": "Aldica Alexandra"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "MicroInformatica",
				"numarPagini": "224",
				"pretOriginal": "750",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "20"
				},
				"redactori": {
					"redactor": "Poenaru Codruta"
				},
				"tehnoredactori": {
					"tehnoredactor": "Poenaru Codruta"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_6_0_Culegere_de_probleme_Vasiu.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Enciclopedia Turbo Pascal 6.0"
						},
						{
							"_nr": "2",
							"__text": "Programe care folosesc unit-urile standard"
						},
						{
							"_nr": "3",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "31",
				"_isbn": "973-95718-4-0"
			},
			{
				"titlu": "Programarea in Turbo Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Winfried Kassera"
						},
						{
							"_nume": "Volter Kassera"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Tipomur",
				"numarPagini": "524",
				"pretOriginal": "1495",
				"traducere": {
					"titluOriginal": "Programmieren mit Turbo Pascal",
					"editura": "Markt Technik",
					"traducatori": {
						"traducator": "Eva Belkovits"
					},
					"_an": "1991",
					"_editia": "1",
					"_limba": "germana",
					"_tara": "Germania"
				},
				"tehnoredactori": {
					"tehnoredactor": "Iosif Fettich"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_Turbo_Pascal_6_0_Kassera.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Indicatii si precizari"
						},
						{
							"_nr": "2",
							"__text": "Programarea cu Turbo-Pascal - pregatiri"
						},
						{
							"_nr": "3",
							"__text": "Intrari/iesiri Tastatura si ecranul"
						},
						{
							"_nr": "4",
							"__text": "Structruri de control"
						},
						{
							"_nr": "5",
							"__text": "Proceduri"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Unit-uri create de noi"
						},
						{
							"_nr": "8",
							"__text": "Lucrul cu fisiere"
						},
						{
							"_nr": "9",
							"__text": "Turbo Pascal pentru avansati"
						},
						{
							"_nr": "10",
							"__text": "Legaturi cu Pc - respectiv MS-DOS"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea mouse-ului si a joystick-ului"
						},
						{
							"_nr": "12",
							"__text": "BGI si grafica"
						},
						{
							"_nr": "13",
							"__text": "O utilizare neobisnuita a compilatorului extern TPC.EXE"
						},
						{
							"_nr": "14",
							"__text": "Programare orientata obiect cu Turno Pascal"
						},
						{
							"_nr": "15",
							"__text": "Programarea cu Turbo Vision"
						}
					]
				},
				"_id": "32",
				"_isbn": "973-95403-7-6"
			},
			{
				"titlu": "Sistemul de operare DOS - Ghidul programatorului",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Marius Chiorean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"numarPagini": "324",
				"pretOriginal": "135",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "4"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_operare_DOS_Ghidul_programatorului_Caprariu.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Microprocesoare INTEL"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura calculatoarelor de tip IBM PC"
						},
						{
							"_nr": "3",
							"__text": "Structura interna a sistemului de operare DOS"
						},
						{
							"_nr": "4",
							"__text": "Gestiunea discurilor magnetice"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea ecranului"
						},
						{
							"_nr": "6",
							"__text": "Folosirea timpului si a sunetului. Gestionarea tastaturii"
						}
					]
				},
				"_id": "33",
				"_isbn": ""
			},
			{
				"titlu": "PC.Pas cu pas",
				"autori": {
					"autor": [
						{
							"_nume": "Emanuela Cerchez"
						},
						{
							"_nume": "Marinel Serban"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "288",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "PC_pas_cu_pas_Cerchez.jpg"
					},
					"__text": "Manuela Odoroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura generala a unui calculator"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "3",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "4",
							"__text": "Editorul de texte Microsoft Word"
						},
						{
							"_nr": "5",
							"__text": "Editoare de imagini"
						},
						{
							"_nr": "6",
							"__text": "Editorul de foi de calcul Microsoft Excel"
						},
						{
							"_nr": "7",
							"__text": "Microsoft PowerPoint"
						},
						{
							"_nr": "8",
							"__text": "Internet"
						}
					]
				},
				"_id": "34",
				"_isbn": "973-683-703-3"
			},
			{
				"titlu": "Prezentari multimedia pe Web - Limbajele XHTML+TIME si SMIL",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Brut"
						},
						{
							"_nume": "Sabin Buraga"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "256",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Andrei Dimitriu"
				},
				"coperta": {
					"imagine": {
						"_path": "Prezentari_multimedia_pe_web_Limbajele_XHTML_TIME_SMIL_Brut.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Multimedia pe Web"
						},
						{
							"_nr": "II",
							"__text": "Limbajul XHTML+TIME"
						},
						{
							"_nr": "III",
							"__text": "Limbajul SMIL"
						},
						{
							"_nr": "IV",
							"__text": "Studiu de caz - dezvoltarea unui site educational multimedia"
						}
					]
				},
				"_id": "35",
				"_isbn": "973-683-674-6"
			},
			{
				"titlu": "Informatica.Culegere de probleme pentru liceu",
				"autori": {
					"autor": {
						"_nume": "Emanuela Cerchez"
					}
				},
				"anAparitie": "2002",
				"editura": "Polirom",
				"numarPagini": "240",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Elena Sascau"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Culegere_de_probleme_pentru_liceu_Cerchez.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi elementari"
						},
						{
							"_nr": "2",
							"__text": "Structuri de date"
						},
						{
							"_nr": "3",
							"__text": "Subprograme.Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Metoda backtraking"
						},
						{
							"_nr": "5",
							"__text": "Metoda programarii dinamice"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi pe grafuri"
						},
						{
							"_nr": "7",
							"__text": "Structuri arborescente"
						}
					]
				},
				"_id": "36",
				"_isbn": "973-681-088-7"
			},
			{
				"titlu": "SQL. Dialecte DB2, Oracle si Visual FoxPro",
				"autori": {
					"autor": {
						"_nume": "Marin Fotache"
					}
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "368",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Cezar Petrica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Irina Juravlea"
				},
				"coperta": {
					"imagine": {
						"_path": "SQL_Dialecte_DB2_Oracle_Visual_FoxPro_Fotache.jpg"
					},
					"__text": "Manuela Odoroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni ale modelului relational"
						},
						{
							"_nr": "2",
							"__text": "Algebra relationala"
						},
						{
							"_nr": "3",
							"__text": "Crearea bazelor de date prin comenzi SQL"
						},
						{
							"_nr": "4",
							"__text": "Elementele de baza ale interogarilor SQL"
						},
						{
							"_nr": "5",
							"__text": "SQL (ceva mai) avansat"
						},
						{
							"_nr": "6",
							"__text": "SQL si mai avansat"
						},
						{
							"_nr": "7",
							"__text": "SQL si OLAP"
						},
						{
							"_nr": "8",
							"__text": "Obiecte din schema bazei de date. Extensii procedurale al SQL"
						}
					]
				},
				"_id": "37",
				"_isbn": "973-683-709-2"
			},
			{
				"titlu": "Macromedia Flash: concepte, exemple, studii de caz",
				"autori": {
					"autor": {
						"_nume": "Cosmin Vârlan"
					}
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "456",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Seria Web",
					"_numarul": "0"
				},
				"redactori": {
					"redactor": "Daniel Chitoi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Irina Lacatusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Macromedia_Flash_Concepte_exemple_studii_de_caz_Varlan.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Intoducere"
						},
						{
							"_nr": "2",
							"__text": "Flash - Designer"
						},
						{
							"_nr": "3",
							"__text": "Flash - Developer"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii Flash"
						}
					]
				},
				"_id": "38",
				"_isbn": "973-681-707-5"
			},
			{
				"titlu": "Proiectarea bazelor de date : Normalizare si postnormalizare : Implementari SQL si Oracle",
				"autori": {
					"autor": {
						"_nume": "Marin Fotache"
					}
				},
				"anAparitie": "2005",
				"editura": "Polirom",
				"numarPagini": "352",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Lucian Pavel"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_bazelor_de_date_Normalizarea_si_postnormalizare_Implementari_SQL_si_Oracle_Fotache.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Obiectul proiectarii bazelor de date"
						},
						{
							"_nr": "2",
							"__text": "La inceput a fost normalizarea"
						},
						{
							"_nr": "3",
							"__text": "Prima forma normaluzata"
						},
						{
							"_nr": "4",
							"__text": "Dependente functionale. A doua forma normalizata"
						},
						{
							"_nr": "5",
							"__text": "A treia forma normalizata si forma normala Boyce-Codd"
						},
						{
							"_nr": "6",
							"__text": "Dependente multivaloare si de jonctiune. Formele normalizate 4 si 5"
						},
						{
							"_nr": "7",
							"__text": "Surogate, reguli, incluziuni. Solutii SQL si Oracle. Un caz practic"
						},
						{
							"_nr": "8",
							"__text": "Generalizari, ierarhii, grafuri, dependente"
						},
						{
							"_nr": "9",
							"__text": "Denormalizare si postnormalizare"
						},
						{
							"_nr": "10",
							"__text": "Temporalitate si acces in baza de date"
						}
					]
				},
				"_id": "39",
				"_isbn": "973-681-898-5"
			},
			{
				"titlu": "Programare in Lisp : Inteligenta artificiala si web semantic",
				"autori": {
					"autor": {
						"_nume": "Stefan Trausan-Matu"
					}
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "288",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Otilia Lazarescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Lucian Pavel"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_in_Lisp_Inteligenta_artificiala_si_web_semantic_Matu.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Inteligenta artificiala simbolica si webul semantic"
						},
						{
							"_nr": "2",
							"__text": "Introducere in Lisp"
						},
						{
							"_nr": "3",
							"__text": "Ingredientele unui program Lisp. Functiile read, eval si print"
						},
						{
							"_nr": "4",
							"__text": "Principalele functii in Lisp"
						},
						{
							"_nr": "5",
							"__text": "Functii si prelucrari functionale"
						},
						{
							"_nr": "6",
							"__text": "Simboluri"
						},
						{
							"_nr": "7",
							"__text": "Prelucari \"chirurgicale\""
						},
						{
							"_nr": "8",
							"__text": "Intrari-iesiri"
						},
						{
							"_nr": "9",
							"__text": "Ciclari"
						},
						{
							"_nr": "10",
							"__text": "Blocuri"
						},
						{
							"_nr": "11",
							"__text": "Tipuri de date in Lisp; secvente si matrice"
						},
						{
							"_nr": "12",
							"__text": "Asocieri, proprietati si structuri"
						},
						{
							"_nr": "13",
							"__text": "Programare orientate spre obiecte in Lisp"
						},
						{
							"_nr": "14",
							"__text": "Macrodefinitii"
						},
						{
							"_nr": "15",
							"__text": "Aplicatie : sortari in Lisp"
						},
						{
							"_nr": "16",
							"__text": "Aplicatii : probleme de cautare"
						},
						{
							"_nr": "17",
							"__text": "Aplicatii : explorarea si caracterizarea grafurilor"
						},
						{
							"_nr": "18",
							"__text": "Aplicatii : Logici descriptive si webul semantic"
						}
					]
				},
				"_id": "40",
				"_isbn": "973-681-682-6"
			},
			{
				"titlu": "Introducere in analiza algoritmilor : teorie si aplicatie",
				"autori": {
					"autor": {
						"_nume": "Cristian A. Giumale"
					}
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "496",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ines Simionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_analiza_algoritmilor_Teorie_si_aplicatie_Giumale.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De la problema la algoritm"
						},
						{
							"_nr": "2",
							"__text": "Corectitudine si complexitate"
						},
						{
							"_nr": "3",
							"__text": "Clase de probleme"
						},
						{
							"_nr": "4",
							"__text": "Tipare de constructie a algoritmilor"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi pentru grafuri"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi aleatori"
						},
						{
							"_nr": "7",
							"__text": "Algoritmi euristici de explorare"
						},
						{
							"_nr": "8",
							"__text": "Algoritmi de aproximare"
						}
					]
				},
				"_id": "41",
				"_isbn": "973-681-751-2"
			},
			{
				"titlu": "Dezvoltarea aplicatiilor in Visual Basic.NET",
				"autori": {
					"autor": {
						"_nume": "Octavian Dospinescu"
					}
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "328",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Daniel Chitoi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Ghetau"
				},
				"coperta": {
					"imagine": {
						"_path": ""
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea platformei .NET"
						},
						{
							"_nr": "2",
							"__text": "Variabile si tipuri de date in Visual Basic.NET"
						},
						{
							"_nr": "3",
							"__text": "Structuri de control"
						},
						{
							"_nr": "4",
							"__text": "Proceduri si functii"
						},
						{
							"_nr": "5",
							"__text": "Programarea orientata pe obiecte"
						},
						{
							"_nr": "6",
							"__text": "Erori, exceptii si alte (ne)placeri"
						},
						{
							"_nr": "7",
							"__text": "Programarea controalelor vizuale"
						},
						{
							"_nr": "8",
							"__text": "Gestionarea bazelor de date in Visual Basic.NET"
						},
						{
							"_nr": "9",
							"__text": "Dezvoltarea rapoartelor in aplicatii VB.NET"
						},
						{
							"_nr": "10",
							"__text": "Sistemul de Intrare/Iesire in VB.NET"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea firelor de executie"
						},
						{
							"_nr": "12",
							"__text": "Dezvoltarea componentelor reutilizabile"
						},
						{
							"_nr": "13",
							"__text": "Dezvoltarea aplicatiilor web cu Visual Basic. NET"
						},
						{
							"_nr": "14",
							"__text": "Pachetul de instalare"
						}
					]
				},
				"_id": "42",
				"_isbn": "973-683-674-6"
			},
			{
				"titlu": "Fundamentele algebrice ale informaticii",
				"autori": {
					"autor": {
						"_nume": "Ferucio Laurentiu Tiple"
					}
				},
				"anAparitie": "2006",
				"editura": "Polirom",
				"numarPagini": "588",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Fundamentele_algebrice_ale_informaticii_Tiplea.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Elementele de teoria numerelor"
						},
						{
							"_nr": "3",
							"__text": "Semigrupuri si monoizi"
						},
						{
							"_nr": "4",
							"__text": "Grupuri"
						},
						{
							"_nr": "5",
							"__text": "Inele si corpuri"
						},
						{
							"_nr": "6",
							"__text": "Spatii vectoriale"
						},
						{
							"_nr": "7",
							"__text": "Teoria multimilor partial ordonate"
						},
						{
							"_nr": "8",
							"__text": "Algebre universale"
						}
					]
				},
				"_id": "43",
				"_isbn": "973-46-0398-1"
			},
			{
				"titlu": "Programare Web in bash si Perl",
				"autori": {
					"autor": [
						{
							"_nume": "Sabin Buraga"
						},
						{
							"_nume": "Victor Tarhon-Onu"
						},
						{
							"_nume": "Stefan Tanasa"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Polirom",
				"numarPagini": "256",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Cezar Petrila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_Web_in_Bash_si_Perl_Buraga.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tehnologiile Web pe scurt"
						},
						{
							"_nr": "2",
							"__text": "Standardul CGI"
						},
						{
							"_nr": "3",
							"__text": "Bash"
						},
						{
							"_nr": "4",
							"__text": "Limbajul Perl"
						},
						{
							"_nr": "5",
							"__text": "Proiecte propuse"
						}
					]
				},
				"_id": "44",
				"_isbn": "973-683-931-1"
			},
			{
				"titlu": "Administrarea si configurarea sistemelor Linux [Editia a III-a]",
				"autori": {
					"autor": {
						"_nume": "Dragos Acostachioie"
					}
				},
				"anAparitie": "2006",
				"editura": "Polirom",
				"numarPagini": "328",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Madalina Ioradache"
				},
				"coperta": {
					"imagine": {
						"_path": "Administrarea_si_configurarea_sistemelor_Linux_III_Acostachioaie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Instalarea sistemului Linux"
						},
						{
							"_nr": "II",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "III",
							"__text": "Notiuni de administrare de baza"
						},
						{
							"_nr": "IV",
							"__text": "Configurarea serviciilor sistemului"
						},
						{
							"_nr": "V",
							"__text": "Securitatea sistemului Linux"
						},
						{
							"_nr": "VI",
							"__text": "Studii de caz"
						}
					]
				},
				"_id": "45",
				"_isbn": "973-46-0108-3"
			},
			{
				"titlu": "Tehnologii XML",
				"autori": {
					"autor": {
						"_nume": "Sabin Buraga"
					}
				},
				"anAparitie": "2006",
				"editura": "Polirom",
				"numarPagini": "416",
				"pretOriginal": "26.90",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Web"
				},
				"redactori": {
					"redactor": "Madalina Ioradache"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_XML_Buraga.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calea de acces la Web"
						},
						{
							"_nr": "2",
							"__text": "Modele de date semistructurate. Familia XML"
						},
						{
							"_nr": "3",
							"__text": "Procesari XML"
						},
						{
							"_nr": "4",
							"__text": "XML pentru servicii Web: SOA, REST, AJAX"
						},
						{
							"_nr": "5",
							"__text": "Catre Web-ul semantic"
						}
					]
				},
				"_id": "46",
				"_isbn": "973-46-0249-7"
			},
			{
				"titlu": "Servicii Web : Concepte de baza si implementari",
				"autori": {
					"autor": [
						{
							"_nume": "Lenuta Alboaie"
						},
						{
							"_nume": "Sabin Buraga"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Polirom",
				"numarPagini": "320",
				"pretOriginal": "269500",
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Ghetau"
				},
				"coperta": {
					"imagine": {
						"_path": "Servicii_Web_Buraga.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare generala a serviciilor Web"
						},
						{
							"_nr": "2",
							"__text": "Familia XML"
						},
						{
							"_nr": "3",
							"__text": "Descrierea serviciilor Web"
						},
						{
							"_nr": "4",
							"__text": "Protocolul SOAP"
						},
						{
							"_nr": "5",
							"__text": "Descoperirea serviciilor"
						},
						{
							"_nr": "6",
							"__text": "Dezvoltarea si utilizarea serviciilor Web"
						},
						{
							"_nr": "7",
							"__text": "Retrospectiva si perspective"
						}
					]
				},
				"_id": "47",
				"_isbn": "973-681-522-6"
			},
			{
				"titlu": "Introducere in AJAX",
				"autori": {
					"autor": {
						"_nume": "Traian Anghel"
					}
				},
				"anAparitie": "2006",
				"editura": "Polirom",
				"numarPagini": "320",
				"pretOriginal": "249500",
				"redactori": {
					"redactor": "Lauretiu Ursu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Irina Lacatusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_Ajax_Anghel.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare generala a tehnicii AJAX"
						},
						{
							"_nr": "2",
							"__text": "Introducere in JavaScript"
						},
						{
							"_nr": "3",
							"__text": "Accesarea arborelui DOM in browser"
						},
						{
							"_nr": "4",
							"__text": "Obiectul XMLHttpRequest"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea AJAX in cadrul aplicatiilor Web"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea unor instrumente AJAX"
						},
						{
							"_nr": "7",
							"__text": "JSON"
						},
						{
							"_nr": "8",
							"__text": "Accesarea serviciilor Web folosind AJAX"
						},
						{
							"_nr": "9",
							"__text": "Aspecte privind securitatea in JavaScript"
						},
						{
							"_nr": "10",
							"__text": "Aspecte privind dezvoltarea aplicatiilor AJAX"
						}
					]
				},
				"_id": "48",
				"_isbn": "973-46-0568-2"
			},
			{
				"titlu": "Dezvoltarea aplicatiilor Web folosind Java",
				"autori": {
					"autor": [
						{
							"_nume": "Stefan Tanasa"
						},
						{
							"_nume": "Crsitian Olaru"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Polirom",
				"numarPagini": "256",
				"pretOriginal": "349000",
				"tehnoredactori": {
					"tehnoredactor": "Suzana Lazanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_aplicatiilor_Web_folosind_Java_Olaru.jpg"
					},
					"__text": "Manuela Odoroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalare si configurare"
						},
						{
							"_nr": "2",
							"__text": "Servlet-uri"
						},
						{
							"_nr": "3",
							"__text": "JavaServer Page (JSP)"
						},
						{
							"_nr": "4",
							"__text": "Framework-uri Web"
						},
						{
							"_nr": "5",
							"__text": "Arhitectura aplicatiilor Web"
						},
						{
							"_nr": "6",
							"__text": "Dezvoltarea proiectului Web"
						},
						{
							"_nr": "7",
							"__text": "Struts"
						},
						{
							"_nr": "8",
							"__text": "Spring si Hibernate"
						},
						{
							"_nr": "9",
							"__text": "JavaServer Faces (JSF)"
						}
					]
				},
				"_id": "49",
				"_isbn": "973-681-789-X"
			},
			{
				"titlu": "Java de la 0 la expert",
				"autori": {
					"autor": [
						{
							"_nume": "Stefan Tanasa"
						},
						{
							"_nume": "Cristian Olaru"
						},
						{
							"_nume": "Stefan Andrei"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Polirom",
				"numarPagini": "840",
				"pretOriginal": "549000",
				"tehnoredactori": {
					"tehnoredactor": "Lucian Pavel"
				},
				"coperta": {
					"imagine": {
						"_path": "Java_de_la_0_la_expert_Olaru.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Fundamentele limbajului Java"
						},
						{
							"_nr": "3",
							"__text": "Clase, interfete si tablouri"
						},
						{
							"_nr": "4",
							"__text": "Siruri si structuri dinamice de date"
						},
						{
							"_nr": "5",
							"__text": "Fisiere, fluxuri de date si serializarea obiectelor"
						},
						{
							"_nr": "6",
							"__text": "Fire de executie"
						},
						{
							"_nr": "7",
							"__text": "Appleturi si instrumente de lucru cu ferestrele (AWT)"
						},
						{
							"_nr": "8",
							"__text": "Accesul la baze de date folosind JDBC"
						},
						{
							"_nr": "9",
							"__text": "Programarea retelelor"
						},
						{
							"_nr": "10",
							"__text": "Servleturi"
						},
						{
							"_nr": "11",
							"__text": "JavaServer Pages (JSP)"
						},
						{
							"_nr": "12",
							"__text": "Procesarea documentelor XML"
						},
						{
							"_nr": "13",
							"__text": "Swing"
						},
						{
							"_nr": "14",
							"__text": "Sistemul Help pentru Java"
						},
						{
							"_nr": "15",
							"__text": "Internationalizarea aplicatiilor"
						}
					]
				},
				"_id": "50",
				"_isbn": "973-681-201-4"
			},
			{
				"titlu": "Oracle 9i2: ghidul dezvoltarii aplicatiilor profesionale",
				"autori": {
					"autor": [
						{
							"_nume": "Marin Fotache"
						},
						{
							"_nume": "Catalin Strimbei"
						},
						{
							"_nume": "Liviu Cretu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Polirom",
				"numarPagini": "960",
				"pretOriginal": "71.00",
				"redactori": {
					"redactor": "Adela Teodorovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Oracle9i2_Ghidul_dezvoltarii_aplicatiilor_profesionale_Fotache.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Oracle in contexul IT actual"
						},
						{
							"_nr": "2",
							"__text": "Serverul de baze de date"
						},
						{
							"_nr": "3",
							"__text": "Interfata (neprietenoasa) cu serverul SQL*Plus"
						},
						{
							"_nr": "4",
							"__text": "Crearea tabelelor si definirea restrictiilor"
						},
						{
							"_nr": "5",
							"__text": "Actualizarea tabelor prin comenzi SQL"
						},
						{
							"_nr": "6",
							"__text": "Interogari SQL"
						},
						{
							"_nr": "7",
							"__text": "Interogari SQL avansate"
						},
						{
							"_nr": "8",
							"__text": "PL/SQL. Elemente generale"
						},
						{
							"_nr": "9",
							"__text": "Proceduri, functii si pachete PL/SQL"
						},
						{
							"_nr": "10",
							"__text": "SQL Dinamic"
						},
						{
							"_nr": "11",
							"__text": "Declansatoare"
						},
						{
							"_nr": "12",
							"__text": "Tabele virtuale"
						},
						{
							"_nr": "13",
							"__text": "Modelul relatinal-obiectual in Oracle9i2"
						},
						{
							"_nr": "14",
							"__text": "Elemente de adminstrare avansata"
						},
						{
							"_nr": "15",
							"__text": "Platforme client Oracle. FormsReports6i"
						},
						{
							"_nr": "16",
							"__text": "Tehnici de manipulare a datelor Oracle in aplicatiile Java"
						},
						{
							"_nr": "17",
							"__text": "Aplicatii cu interfata grafica pentru clientii Java si server Oracle"
						},
						{
							"_nr": "18",
							"__text": "Rapoarte XML in format PDF"
						},
						{
							"_nr": "19",
							"__text": "Aplicatii Web Java-Oracle"
						}
					]
				},
				"_id": "51",
				"_isbn": "973-681-453-X"
			},
			{
				"titlu": "Prelucrarea documentelor folosind XML si Perl",
				"autori": {
					"autor": [
						{
							"_nume": "Stefan Trausan-Matu"
						},
						{
							"_nume": "Claudia Raibulet"
						},
						{
							"_nume": "Ovidiu Constantin"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "MatrixRom",
				"numarPagini": "122",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea_documentelor_folosind_XML_si_PERL_Matu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "XML"
						},
						{
							"_nr": "II",
							"__text": "Limbajul Perl"
						}
					]
				},
				"_id": "52",
				"_isbn": "973-685-224-5"
			},
			{
				"titlu": "Tehnologii WEB - vol I",
				"autori": {
					"autor": {
						"_nume": "Sabin Corneliu Buraga"
					}
				},
				"anAparitie": "2001",
				"editura": "MatrixRom",
				"numarPagini": "662",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_WEB_Vol_I_Buraga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "A",
							"__text": "Specificarea si organizarea informatiilor pe Web"
						},
						{
							"_nr": "B",
							"__text": "Marcarea informatiilor pentru Web"
						},
						{
							"_nr": "C",
							"__text": "Aplicatii ale XML"
						},
						{
							"_nr": "D",
							"__text": "Limbajul HTML(HyperText Markup Language)"
						},
						{
							"_nr": "E",
							"__text": "Document Object Model (DOM) pentru documente XML/HTML"
						},
						{
							"_nr": "F",
							"__text": "Documente hipermedia pe Web"
						}
					]
				},
				"_id": "53",
				"_isbn": "953-685-280-6"
			},
			{
				"titlu": "Tehnologii WEB - vol II",
				"autori": {
					"autor": {
						"_nume": "Sabin Corneliu Buraga"
					}
				},
				"anAparitie": "2001",
				"editura": "MatrixRom",
				"numarPagini": "662",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_WEB_Vol_II_Buraga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "G",
							"__text": "Programarea aplicatiilor Web"
						},
						{
							"_nr": "H",
							"__text": "JavaScript in paginile Web"
						},
						{
							"_nr": "I",
							"__text": "Applet-uri si servlet-uri Java"
						},
						{
							"_nr": "J",
							"__text": "Ingineria Web"
						},
						{
							"_nr": "K",
							"__text": "Realitatea virtuala"
						},
						{
							"_nr": "L",
							"__text": "Anexe"
						},
						{
							"_nr": "M",
							"__text": "Glosar de termeni"
						},
						{
							"_nr": "N",
							"__text": "Resurse bibliografice"
						},
						{
							"_nr": "O",
							"__text": "Cuprins"
						}
					]
				},
				"_id": "54",
				"_isbn": "953-685-280-6"
			},
			{
				"titlu": "Semantic Web : fundamente si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Sabin Corneliu Buraga"
					}
				},
				"anAparitie": "2004",
				"editura": "MatrixRom",
				"numarPagini": "235",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Semantic_Web_fundamente_si_aplicatii_Buraga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura spatiului WWW"
						},
						{
							"_nr": "2",
							"__text": "Web-ul semantic"
						},
						{
							"_nr": "3",
							"__text": "Descrierea si regasirea resurselor multimedia"
						},
						{
							"_nr": "4",
							"__text": "Solutii de implementare"
						},
						{
							"_nr": "5",
							"__text": "La final"
						}
					]
				},
				"_id": "55",
				"_isbn": "973-685-772-7"
			},
			{
				"titlu": "Algoritmi de prelucrare paralela",
				"autori": {
					"autor": {
						"_nume": "Valentin Cristea"
					}
				},
				"anAparitie": "2002",
				"editura": "MatrixRom",
				"numarPagini": "180",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_de_prelucrare_paralela_Cristea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Limbajul de descriere a algoritmilor"
						},
						{
							"_nr": "3",
							"__text": "Complexitatea calculului paralel"
						},
						{
							"_nr": "4",
							"__text": "Dezvoltarea aplicatiilor pentru calculatoare SM SIMD"
						},
						{
							"_nr": "5",
							"__text": "Dezvoltarea algoritmilor folosind variabile partajate"
						},
						{
							"_nr": "6",
							"__text": "Dezvoltarea algoritmilor distribuiti folosind comunicarea prin mesaje"
						},
						{
							"_nr": "7",
							"__text": "Comunicarea asincrona de mesaje"
						},
						{
							"_nr": "8",
							"__text": "Operatii cu matrice"
						},
						{
							"_nr": "9",
							"__text": "Operatii cu liste"
						},
						{
							"_nr": "10",
							"__text": "Prelucrarea grafurilor"
						}
					]
				},
				"_id": "56",
				"_isbn": "973-685-515-5"
			},
			{
				"titlu": "Limbaje formale si automate. Indrumar pentru aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Diana Raiciu"
						},
						{
							"_nume": "Radu Sion"
						},
						{
							"_nume": "Irina Mocanu"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "MatrixRom",
				"numarPagini": "96",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_automate_Indrumar_pentru_aplicatii_Athanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de teoria limbajelor formale"
						},
						{
							"_nr": "1.1",
							"__text": "Gramatici"
						},
						{
							"_nr": "1.2",
							"__text": "Multimi regulate. Expresii regulate"
						},
						{
							"_nr": "1.3",
							"__text": "Acceptoare"
						},
						{
							"_nr": "2",
							"__text": "Lex - generator de analizatoare lexicale"
						},
						{
							"_nr": "2.1",
							"__text": "Expresii regulate. Structura unei specificatii lex"
						},
						{
							"_nr": "2.2",
							"__text": "Elemente avansate"
						},
						{
							"_nr": "2.3",
							"__text": "Exemple comentate"
						},
						{
							"_nr": "3",
							"__text": "Teme pentru acasa"
						},
						{
							"_nr": "4",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "57",
				"_isbn": "973-685-407-8"
			},
			{
				"titlu": "Java ca limbaj pentru programarea distribuita",
				"autori": {
					"autor": {
						"_nume": "Irina Athanasiu"
					}
				},
				"anAparitie": "2002",
				"editura": "MatrixRom",
				"numarPagini": "140",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Java_ca_limbaj_pentru_programarea_distribuita_Athansiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Java ca limbaj de programare distribuita"
						},
						{
							"_nr": "2",
							"__text": "Linda ca limbaj de programare distribuita"
						},
						{
							"_nr": "3",
							"__text": "JavaSpaces"
						}
					]
				},
				"_id": "58",
				"_isbn": "973-685-476-0"
			},
			{
				"titlu": "Informatica in medicina si ingineria medicala",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Voinea"
						},
						{
							"_nume": "Augustin Semenescu"
						},
						{
							"_nume": "Mihai Tarcolea"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "MatrixRom",
				"numarPagini": "338",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_in_medicina_si_inginerie_medicala_Voinea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de operare Microsoft (MS)"
						},
						{
							"_nr": "2",
							"__text": "Editarea documentelor"
						},
						{
							"_nr": "3",
							"__text": "Calcul tabelar si grafica. Aplicatia Excel 2000"
						},
						{
							"_nr": "4",
							"__text": "Aplicatia PowerPoint 2000"
						},
						{
							"_nr": "5",
							"__text": "Internet"
						},
						{
							"_nr": "6",
							"__text": "Limbajul de programare Euphoria"
						},
						{
							"_nr": "7",
							"__text": "Aplicatia FrontPage 2000"
						},
						{
							"_nr": "8",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "59",
				"_isbn": "973-685-411-6"
			},
			{
				"titlu": "Fundamentele Java",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Petac"
						},
						{
							"_nume": "Tudor Udrescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "MatrixRom",
				"numarPagini": "504",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Fundamente_Java_Petac.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Programarea orientata spre obiect"
						},
						{
							"_nr": "3",
							"__text": "Scrierea programelor in Java"
						},
						{
							"_nr": "4",
							"__text": "Limbajul Java"
						},
						{
							"_nr": "5",
							"__text": "Obiecte si clase"
						},
						{
							"_nr": "6",
							"__text": "Obiecte si polimorfism"
						},
						{
							"_nr": "7",
							"__text": "Appleturi si grafica"
						},
						{
							"_nr": "8",
							"__text": "Interfete grafice"
						},
						{
							"_nr": "9",
							"__text": "Fluxuri de intrare/iesire si fisiere"
						},
						{
							"_nr": "10",
							"__text": "Tehnici de programare"
						},
						{
							"_nr": "11",
							"__text": "Programarea retelelor de calculatoare"
						},
						{
							"_nr": "12",
							"__text": "Limbajul UML. Proiectarea aplicatiilor"
						}
					]
				},
				"_id": "60",
				"_isbn": "973-685-944-4"
			},
			{
				"titlu": "Algoritmi: Introducere in algoritmica fluxurilor in retele",
				"autori": {
					"autor": [
						{
							"_nume": "Eleonor Ciurea"
						},
						{
							"_nume": "Laura Ciupala"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "MatrixRom",
				"numarPagini": "382",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_introducere_in_algoritmica_fluxurilor_in_retele_Ciurea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "PArcurgerea de grafuri"
						},
						{
							"_nr": "3",
							"__text": "Arbori si arborescente"
						},
						{
							"_nr": "4",
							"__text": "Distante si drumuri minime"
						},
						{
							"_nr": "5",
							"__text": "Fluxuri maxime in retele"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi polinomiali"
						},
						{
							"_nr": "7",
							"__text": "Fluxuri minime in retele"
						},
						{
							"_nr": "8",
							"__text": "Fluxuri de cost minim"
						},
						{
							"_nr": "9",
							"__text": "Algoritmi pentru fluxul de cost minim"
						},
						{
							"_nr": "10",
							"__text": "Fluxuri dinamice"
						},
						{
							"_nr": "11",
							"__text": "Algoritmul simplex pentru fluxuri"
						},
						{
							"_nr": "12",
							"__text": "Fluxuri cu multiplicatori si fluxuri cu mai multe produse"
						}
					]
				},
				"_id": "61",
				"_isbn": "973-755-084-6"
			},
			{
				"titlu": "Informatica privita...altfel",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Socaciu"
					}
				},
				"anAparitie": "2006",
				"editura": "InfoData",
				"numarPagini": "140",
				"pretOriginal": "10",
				"coperta": {
					"imagine": {
						"_path": "Informatica_privita_altfel_Socaciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Circuitul timer"
						},
						{
							"_nr": "2",
							"__text": "Circuitul CMOS"
						},
						{
							"_nr": "3",
							"__text": "Despre infasurarea curbelor"
						},
						{
							"_nr": "4",
							"__text": "Din nou despre infasurarea curbelor"
						},
						{
							"_nr": "5",
							"__text": "Legarea modulelor C si Assembler cu FoxPro"
						},
						{
							"_nr": "6",
							"__text": "Realizarea unui sistem de gestiune a proceselor in background pentru FoxPro"
						},
						{
							"_nr": "7",
							"__text": "Despre completarea mandatelor postale sau...cum se face (tran)scrierea numerelor in litere"
						},
						{
							"_nr": "8",
							"__text": "Despre dangling-else"
						},
						{
							"_nr": "9",
							"__text": "O posibila definire ALBEBRICA a tipurilor ORDINALE"
						},
						{
							"_nr": "10",
							"__text": "Aritmetica cu numerele mari"
						},
						{
							"_nr": "11",
							"__text": "Conjectura palindroamelor"
						},
						{
							"_nr": "12",
							"__text": "Limitari de viteze in retele"
						},
						{
							"_nr": "13",
							"__text": "Realizarea unei conexiuni autonome (pentru navigarea Internet) o data cu pornirea browser-ului favorit"
						}
					]
				},
				"_id": "62",
				"_isbn": "973-87774-9-6"
			},
			{
				"titlu": "O altfel de...Informatica",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Socaciu"
					}
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "142",
				"pretOriginal": "10",
				"coperta": {
					"imagine": {
						"_path": "O_altfel_de_informatica_Socaciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Clasificarea sistemelor paralele"
						},
						{
							"_nr": "2",
							"__text": "Metoda backtraking"
						},
						{
							"_nr": "3",
							"__text": "Proiectarea unui cluster pentru HPC folosind Linux"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii ale retelelor neuronale la protectia prin parola"
						},
						{
							"_nr": "5",
							"__text": "Algoritm Backtraking pentru un cluster bazat pe mesaje"
						},
						{
							"_nr": "6",
							"__text": "Lectia de retele : Tuneluri si Bridge-uri"
						},
						{
							"_nr": "7",
							"__text": "Despre numere mari si foarte mari"
						},
						{
							"_nr": "8",
							"__text": "Deprotejarea fisierelor sub Novell DOS"
						},
						{
							"_nr": "9",
							"__text": "Principiul contorului"
						},
						{
							"_nr": "10",
							"__text": "Rezolvarea unei probleme prin metoda Backtraking:Determinarea partitiilor de lungime fixa a unui numar natural dat"
						},
						{
							"_nr": "11",
							"__text": "Scrierea numeralilor cardinali in litere"
						},
						{
							"_nr": "12",
							"__text": "Grafica pe SVGA 1M"
						},
						{
							"_nr": "13",
							"__text": "Din nou despre SVGA-uri"
						}
					]
				},
				"_id": "63",
				"_isbn": "973-88224-1-6"
			},
			{
				"titlu": "Tehnici de optimizare. Culegere de probleme",
				"autori": {
					"autor": [
						{
							"_nume": "Marilena Oprea"
						},
						{
							"_nume": "Radu Marin"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "162",
				"pretOriginal": "10",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_optimizare_culegere_de_probleme_Oprea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tehnici bazate pe reprezentarea datelor"
						},
						{
							"_nr": "1.1",
							"__text": "Submultimi pe biti"
						},
						{
							"_nr": "2",
							"__text": "Heap-uri"
						},
						{
							"_nr": "2.1",
							"__text": "Deque"
						},
						{
							"_nr": "3",
							"__text": "Programare dinamica"
						}
					]
				},
				"_id": "64",
				"_isbn": "973-88224-6-7"
			},
			{
				"titlu": "Arhitectura calculatorului",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "2006",
				"editura": "InfoData",
				"numarPagini": "208",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Bogdan Patrut"
				},
				"tehnoredactori": {
					"tehnoredactor": "Adrian Atanasiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Arhitectura_calculatorului_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura unui sistem de calcul"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea numerelor in calculator"
						},
						{
							"_nr": "3",
							"__text": "Algebre si functii booleene"
						},
						{
							"_nr": "4",
							"__text": "Sisteme digitale"
						},
						{
							"_nr": "5",
							"__text": "Sisteme 0 - DS"
						},
						{
							"_nr": "6",
							"__text": "Sisteme 1 - DS (Memorii)"
						},
						{
							"_nr": "7",
							"__text": "Sisteme 2 - DS (Automate)"
						},
						{
							"_nr": "8",
							"__text": "Sisteme 3 - DS (Procesori)"
						},
						{
							"_nr": "9",
							"__text": "Sisteme 4 - DS"
						},
						{
							"_nr": "10",
							"__text": "Structura unui computer la nivel de performanta"
						}
					]
				},
				"_id": "65",
				"_isbn": "973-87774-0-2"
			},
			{
				"titlu": "Limbaje formale si automate",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "170",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_automate_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Automate finite"
						},
						{
							"_nr": "2",
							"__text": "Teoreme de reprezentare pentru limbajele regulate"
						},
						{
							"_nr": "3",
							"__text": "Gramatici si limbaje clasificabile Chomsky"
						},
						{
							"_nr": "4",
							"__text": "Gramatici de inchidere ale limbajelor Chomsky"
						},
						{
							"_nr": "5",
							"__text": "Metode de recunoastere pentru limbajele Chomsky"
						},
						{
							"_nr": "6",
							"__text": "Gramatici independente de context"
						},
						{
							"_nr": "7",
							"__text": "L1 si automate liniar marginite"
						},
						{
							"_nr": "8",
							"__text": "Limbaje de tip 0 si masini Turing"
						}
					]
				},
				"_id": "66",
				"_isbn": "973-88224-0-8"
			},
			{
				"titlu": "FoxPro : comenzi si functii",
				"autori": {
					"autor": {
						"_nume": "Lia Chiorean"
					}
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "592",
				"pretOriginal": "7150",
				"colectia": {
					"_numarul": "28",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "FoxPro_comenzi_si_functii_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Comenzi si functii FoxPro.Memento (in ordine alfabetica)"
						},
						{
							"_nr": "2",
							"__text": "Comenzi si functii FoxPro"
						},
						{
							"_nr": "3",
							"__text": "Anexe"
						}
					]
				},
				"_id": "67",
				"_isbn": "973-96274-5-5"
			},
			{
				"titlu": "Prelucrarea digitala a imaginilor",
				"autori": {
					"autor": {
						"_nume": "Aurel Vlaicu"
					}
				},
				"anAparitie": "1997",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "394",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea_digitala_a_imaginilor_Vlaicu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in prelucrarea imaginilor"
						},
						{
							"_nr": "2",
							"__text": "Sisteme bidimensionale si notiuni matematice de baza"
						},
						{
							"_nr": "3",
							"__text": "Esantionarea si cuantizarea imaginilor"
						},
						{
							"_nr": "4",
							"__text": "Transformari de imagini"
						},
						{
							"_nr": "5",
							"__text": "Transformarea Wavelet"
						},
						{
							"_nr": "6",
							"__text": "Imbunatatirea imaginilor"
						},
						{
							"_nr": "7",
							"__text": "Restaurarea imaginilor"
						},
						{
							"_nr": "8",
							"__text": "Analiza de imagini si recunoasterea de forme"
						},
						{
							"_nr": "9",
							"__text": "Reconstructia imaginilor din proiectii"
						},
						{
							"_nr": "10",
							"__text": "Reprezentarea tridimensionala a imaginilor"
						},
						{
							"_nr": "11",
							"__text": "Compresia de imagini"
						}
					]
				},
				"_id": "68",
				"_isbn": "973-0215-41-6"
			},
			{
				"titlu": "Word 2000 : functiile de baza",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "2001",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "260",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "112",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Word_2000_functiile_de_baza_Kovacs.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza"
						},
						{
							"_nr": "2",
							"__text": "Introducere si editare"
						},
						{
							"_nr": "3",
							"__text": "Formatarea caracterelor"
						},
						{
							"_nr": "4",
							"__text": "Formatarea paragrafelor"
						},
						{
							"_nr": "5",
							"__text": "Formatarea paginilor"
						},
						{
							"_nr": "6",
							"__text": "Grafica"
						},
						{
							"_nr": "7",
							"__text": "Tabelele"
						},
						{
							"_nr": "8",
							"__text": "Gestiunea documentelor"
						},
						{
							"_nr": "9",
							"__text": "Anexe"
						}
					]
				},
				"_id": "69",
				"_isbn": "973-9443-91-5"
			},
			{
				"titlu": "Sinteza si analiza algoritmilor",
				"autori": {
					"autor": [
						{
							"_nume": "Leon Livovschi"
						},
						{
							"_nume": "Horia Georgescu"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "458",
				"pretOriginal": "21",
				"redactori": {
					"redactor": "Ion Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Sinteza_si_analiza_algoritmilor_Georgescu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Grafuri. Reprezentare si parcurgere"
						},
						{
							"_nr": "3",
							"__text": "Metode de elaboare a algoritmilor"
						},
						{
							"_nr": "4",
							"__text": "Generarea de submultimi"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi combinatoriali"
						},
						{
							"_nr": "6",
							"__text": "Cautare, sortare si selectie"
						},
						{
							"_nr": "7",
							"__text": "Seminificatia, verificarea si transformarea programelor"
						},
						{
							"_nr": "8",
							"__text": "Programarea algoritmilor recursivi"
						},
						{
							"_nr": "9",
							"__text": "Algoritmi numerici"
						},
						{
							"_nr": "10",
							"__text": "Probleme NP-complete"
						}
					]
				},
				"_id": "70",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in Java",
				"autori": {
					"autor": {
						"_nume": "Calin Marin Vaduva"
					}
				},
				"anAparitie": "2000",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "402",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "89",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Java_Vaduva.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Java"
						},
						{
							"_nr": "2",
							"__text": "Fundamenete de programare Java"
						},
						{
							"_nr": "3",
							"__text": "Programare obiectuala in Java"
						},
						{
							"_nr": "4",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "5",
							"__text": "Programare multithread"
						},
						{
							"_nr": "6",
							"__text": "Programare input/output"
						},
						{
							"_nr": "7",
							"__text": "Programarea interfetelor grafice"
						},
						{
							"_nr": "8",
							"__text": "Programare internet. Applet-uri Java"
						}
					]
				},
				"_id": "71",
				"_isbn": "973-9443-85-0"
			},
			{
				"titlu": "Visual Basic 5.0 : ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "1998",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "446",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "83",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Visual_Basic_5_0_Ghid_de_utilizare_Kokacs.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Masa de lucru a programului Visual Basic 5.0"
						},
						{
							"_nr": "2",
							"__text": "Exemple demonstrative"
						},
						{
							"_nr": "3",
							"__text": "Principalele meniuri si comenzi"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea inspectorului de obiecte"
						},
						{
							"_nr": "5",
							"__text": "Forme"
						},
						{
							"_nr": "6",
							"__text": "Paleta de controale standard"
						},
						{
							"_nr": "7",
							"__text": "Controale standard"
						},
						{
							"_nr": "8",
							"__text": "Gestiunea controalelor"
						},
						{
							"_nr": "9",
							"__text": "Controlul COMMANDBUTTON si TABELE DE CONTROALE"
						},
						{
							"_nr": "10",
							"__text": "Ferestre de mesaje si de introducere"
						},
						{
							"_nr": "11",
							"__text": "Controlul LABEL"
						},
						{
							"_nr": "12",
							"__text": "Controlul TEXTBOX"
						},
						{
							"_nr": "13",
							"__text": "Controlul CHECKBOX"
						},
						{
							"_nr": "14",
							"__text": "Controlul FRAME"
						},
						{
							"_nr": "15",
							"__text": "Controlul OPTIONBUTTON"
						},
						{
							"_nr": "16",
							"__text": "Controlul HSCROLLBAR si VSCROLLBAR"
						},
						{
							"_nr": "17",
							"__text": "Metode grafice"
						},
						{
							"_nr": "18",
							"__text": "Controlul PICTUREBOX"
						},
						{
							"_nr": "19",
							"__text": "Controlul IMAGE"
						},
						{
							"_nr": "20",
							"__text": "Controlul IMAGELIST"
						},
						{
							"_nr": "21",
							"__text": "Controlul SHAPE"
						},
						{
							"_nr": "22",
							"__text": "Controlul LINE"
						},
						{
							"_nr": "23",
							"__text": "Controlul LISTBOX"
						},
						{
							"_nr": "24",
							"__text": "Controlul COMBOBOX"
						},
						{
							"_nr": "25",
							"__text": "Obiectul Screen"
						},
						{
							"_nr": "26",
							"__text": "Ferestre de dialog predefinite"
						},
						{
							"_nr": "27",
							"__text": "Controale pentru gestiunea fisierelor"
						},
						{
							"_nr": "28",
							"__text": "Gestiunea tastaturii"
						},
						{
							"_nr": "29",
							"__text": "Controlul Timer"
						},
						{
							"_nr": "30",
							"__text": "Gestiunea mouse-ului"
						},
						{
							"_nr": "31",
							"__text": "Operatia de tragere cu mouse-ului"
						},
						{
							"_nr": "32",
							"__text": "Gestiunea meniurilor"
						},
						{
							"_nr": "33",
							"__text": "Controlul SSTAB"
						},
						{
							"_nr": "34",
							"__text": "Controlul TOOLBAR"
						},
						{
							"_nr": "35",
							"__text": "Controlul STATUSBAR"
						},
						{
							"_nr": "36",
							"__text": "Controlul PROGRESSBAR"
						},
						{
							"_nr": "37",
							"__text": "Controlul SLIDER"
						},
						{
							"_nr": "38",
							"__text": "Controlul UPDOWN"
						},
						{
							"_nr": "39",
							"__text": "Controlul ANIMATION"
						},
						{
							"_nr": "40",
							"__text": "Controlul MULTIMEDIA"
						},
						{
							"_nr": "41",
							"__text": "Aplicatii multiforme"
						},
						{
							"_nr": "42",
							"__text": "Interfata multidocument"
						},
						{
							"_nr": "43",
							"__text": "Utilizarea mesei de montaj"
						},
						{
							"_nr": "44",
							"__text": "Controul OLE"
						},
						{
							"_nr": "45",
							"__text": "Baze de date"
						}
					]
				},
				"_id": "72",
				"_isbn": "973-9215-90-4"
			},
			{
				"titlu": "Programarea dinamica a paginilor WEB cu ACTIVE SERVER PAGES",
				"autori": {
					"autor": {
						"_nume": "Bogdan Eugen Trifu"
					}
				},
				"anAparitie": "2001",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "268",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "137",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_dinamica_a_paginilor_Web_cu_Active_Server_Pages_Trifu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Internet si ASP"
						},
						{
							"_nr": "2",
							"__text": "Aplicatii Internet. Tehnologii client-server"
						},
						{
							"_nr": "3",
							"__text": "ASP - Modelul obiectual"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii internet si tehnologia ASP"
						},
						{
							"_nr": "5",
							"__text": "Lucrul cu baze de date"
						},
						{
							"_nr": "6",
							"__text": "Tratarea erorilor si optimizarea codului ASP"
						},
						{
							"_nr": "7",
							"__text": "Aplicatia HomePage. Studiu de caz"
						},
						{
							"_nr": "8",
							"__text": "5+5 exemple de pagini ASP"
						}
					]
				},
				"_id": "73",
				"_isbn": "973-650-027-6"
			},
			{
				"titlu": "Metode de proiectare a compilatoarelor",
				"autori": {
					"autor": {
						"_nume": "Simona Motogna"
					}
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "170",
				"pretOriginal": "22",
				"pretCumparare": "220000",
				"colectia": {
					"_numarul": "202",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_de_proiectare_a_compilatoarelor_Motogna.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "3",
							"__text": "Analiza sintactica"
						},
						{
							"_nr": "4",
							"__text": "Analiza semantica"
						},
						{
							"_nr": "5",
							"__text": "Generarea codului intermediar"
						},
						{
							"_nr": "6",
							"__text": "Optimizare codului intermediar"
						},
						{
							"_nr": "7",
							"__text": "Generarea codului obiect"
						},
						{
							"_nr": "8",
							"__text": "Tratarea erorilor"
						}
					]
				},
				"_id": "74",
				"_isbn": "973-650-172-8"
			},
			{
				"titlu": "Antrenamente PROLOG :Introducere in limbajul de programare logica PROLOG",
				"autori": {
					"autor": {
						"_nume": "Mihaela Malita"
					}
				},
				"anAparitie": "2000",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "220",
				"pretOriginal": "49000",
				"pretCumparare": "49000",
				"coperta": {
					"imagine": {
						"_path": "Antrenamente_PROLOG_Introducere_in_limbajul_de_programare_logica_Prolog_Malita.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prima lectie in PROLOG"
						},
						{
							"_nr": "2",
							"__text": "Mai multe despre PROLOG"
						},
						{
							"_nr": "3",
							"__text": "Aritmetica"
						},
						{
							"_nr": "4",
							"__text": "Lucru cu liste"
						},
						{
							"_nr": "5",
							"__text": "Multimi si multiset-uri"
						},
						{
							"_nr": "6",
							"__text": "Operatii cu cuvinte"
						},
						{
							"_nr": "7",
							"__text": "Iteratie. Ciclare"
						},
						{
							"_nr": "8",
							"__text": "Lucru cu fisiere"
						},
						{
							"_nr": "9",
							"__text": "Combinatorica"
						},
						{
							"_nr": "10",
							"__text": "Logica propozitiilor"
						},
						{
							"_nr": "11",
							"__text": "Grafuri. Arbori"
						},
						{
							"_nr": "12",
							"__text": "Sortare"
						},
						{
							"_nr": "13",
							"__text": "Jocuri"
						},
						{
							"_nr": "14",
							"__text": "Planificare"
						},
						{
							"_nr": "15",
							"__text": "Lingvistica computationala"
						}
					]
				},
				"_id": "75",
				"_isbn": "973-575-500-9"
			},
			{
				"titlu": "Antrenamente LISP :Introducere in limbajul de programare functionala LISP",
				"autori": {
					"autor": {
						"_nume": "Mihaela Malita"
					}
				},
				"anAparitie": "1998",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "212",
				"pretOriginal": "30000",
				"pretCumparare": "30000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Mihaela Malita",
						"Florian Mihalcea"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Antrenamente_LISP_Introducere_in_limbajul_de_programare_functionala_LISP_Malita.jpg"
					},
					"__text": "Iuliana Mihut"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prima lectie"
						},
						{
							"_nr": "2",
							"__text": "Despre LISP"
						},
						{
							"_nr": "3",
							"__text": "Lumea listelor"
						},
						{
							"_nr": "4",
							"__text": "LISP-ul pur"
						},
						{
							"_nr": "5",
							"__text": "Aritmetica"
						},
						{
							"_nr": "6",
							"__text": "Recursivitatea"
						},
						{
							"_nr": "7",
							"__text": "Procipalele functii pe liste"
						},
						{
							"_nr": "8",
							"__text": "Totul despre functii"
						},
						{
							"_nr": "9",
							"__text": "Iteratia"
						},
						{
							"_nr": "10",
							"__text": "Liste de proprietati"
						},
						{
							"_nr": "11",
							"__text": "Stringuri (siruri de caractere)"
						},
						{
							"_nr": "12",
							"__text": "Multimi si combinatorica"
						},
						{
							"_nr": "13",
							"__text": "Intrari si iesiri"
						},
						{
							"_nr": "14",
							"__text": "Melania invata cu calculatorul"
						},
						{
							"_nr": "15",
							"__text": "Derivarea"
						},
						{
							"_nr": "16",
							"__text": "Multimi fuzzy"
						},
						{
							"_nr": "17",
							"__text": "Grafuri"
						},
						{
							"_nr": "18",
							"__text": "Arbori"
						},
						{
							"_nr": "19",
							"__text": "Sortare"
						},
						{
							"_nr": "20",
							"__text": "Logica"
						},
						{
							"_nr": "21",
							"__text": "Automate celulare"
						},
						{
							"_nr": "22",
							"__text": "Algoritmi genetici"
						}
					]
				},
				"_id": "76",
				"_isbn": "973-575-204-2"
			},
			{
				"titlu": "Data structures",
				"autori": {
					"autor": {
						"_nume": "Ioan Tomescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "208",
				"pretOriginal": "90000",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Ion Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constanta Titu"
				},
				"coperta": {
					"imagine": {
						"_path": "Data_structures_Tomescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Structured dat types"
						},
						{
							"_nr": "2",
							"__text": "Sorting techniques"
						},
						{
							"_nr": "3",
							"__text": "Searching techniques"
						}
					]
				},
				"_id": "77",
				"_isbn": "973-575-866-0"
			},
			{
				"titlu": "Arhitectura bazelor de date:mediul SQL",
				"autori": {
					"autor": {
						"_nume": "Doina Fusaru"
					}
				},
				"anAparitie": "2002",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "180",
				"pretOriginal": "75000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Constantin Florea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Andronie"
				},
				"coperta": {
					"imagine": {
						"_path": "Arhitectura_bazelor_de_date_Mediul_SQL_Fusaru.jpg"
					},
					"__text": "Maria Andronie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Baze de date si SGBD"
						},
						{
							"_nr": "2",
							"__text": "Limbajul SQL"
						},
						{
							"_nr": "3",
							"__text": "Notiuni de SQL Server"
						}
					]
				},
				"_id": "78",
				"_isbn": "973-582-557-0"
			},
			{
				"titlu": "Limbajul Visual Basic in proiectarea interfetelor grafice",
				"autori": {
					"autor": {
						"_nume": "Sanda Monica Tataram"
					}
				},
				"anAparitie": "2004",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "224",
				"pretOriginal": "104.000",
				"pretCumparare": "104000",
				"redactori": {
					"redactor": "Cosmin Comarnescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Balan (Gurlui)"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_Visual_Basic_in_proiectarea_interfetelor_grafice_Tataram.jpg"
					},
					"__text": "Stan Baron"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Mediul de dezvoltare Visual Basic"
						},
						{
							"_nr": "2",
							"__text": "Introducere in programarea Visual Basic"
						}
					]
				},
				"_id": "79",
				"_isbn": "973-582-863-4"
			},
			{
				"titlu": "Crearea interfetelor grafice : principii si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Sanda Monica Tataram"
					}
				},
				"anAparitie": "2004",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "224",
				"pretOriginal": "96.000",
				"pretCumparare": "96000",
				"redactori": {
					"redactor": "Maria Cernea"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Sanda Monica Tataram",
						"Magdalena Ilie"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Crearea_interfetelor_grafice_Principii_si_aplicatii_Tataram.jpg"
					},
					"__text": "Stan Baron"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea interfetelor grafice"
						},
						{
							"_nr": "3",
							"__text": "Niveluri de programare"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "80",
				"_isbn": "973-582-864-2"
			},
			{
				"titlu": "Delphi, ultima provocare",
				"autori": {
					"autor": {
						"_nume": "Alin Blaga"
					}
				},
				"anAparitie": "1998",
				"editura": "Promedia Plus",
				"numarPagini": "316",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Alin Blaga"
				},
				"coperta": {
					"imagine": {
						"_path": "Delphi_ultima_provocare_Blaga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modelul obiect"
						},
						{
							"_nr": "3",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "4",
							"__text": "Tratarea mesajelor"
						},
						{
							"_nr": "5",
							"__text": "Componente"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii MDI"
						},
						{
							"_nr": "7",
							"__text": "Legare dinamica (DLL)"
						},
						{
							"_nr": "8",
							"__text": "Multimedia in Delphi"
						}
					]
				},
				"_id": "81",
				"_isbn": "973-9275-19-2"
			},
			{
				"titlu": "Initiere in C++. Programare orientata pe obiecte",
				"autori": {
					"autor": {
						"_nume": "Ionut Muslea"
					}
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"numarPagini": "268",
				"pretOriginal": "425",
				"colectia": {
					"_numarul": "16",
					"_nume": "Biblioteca PC"
				},
				"redactori": {
					"redactor": "Chiorean Calin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Chiorean Calin"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Cpp_Programare_orientata_pe_obiecte_Muslea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in O.O.P."
						},
						{
							"_nr": "2",
							"__text": "Scurta incursiune in C++"
						},
						{
							"_nr": "3",
							"__text": "Clase"
						},
						{
							"_nr": "4",
							"__text": "Mostenirea"
						},
						{
							"_nr": "5",
							"__text": "Alte facilitati ale limbajului C++"
						},
						{
							"_nr": "6",
							"__text": "Stream-uri"
						}
					]
				},
				"_id": "82",
				"_isbn": "973-95718-2-4"
			},
			{
				"titlu": "Arhitecturi PC",
				"autori": {
					"autor": [
						{
							"_nume": "Adrina Sandita"
						},
						{
							"_nume": "Florin Constantinescu"
						},
						{
							"_nume": "Tiberiu Dumitrescu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Aius",
				"numarPagini": "208",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Florin Constantinescu",
						"Tiberiu Dumitrescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Arhitecturi_PC_Notiuni_hardware_Sandita.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Microprocesoare"
						},
						{
							"_nr": "2",
							"__text": "Memoria"
						},
						{
							"_nr": "3",
							"__text": "Medii de stocare a datelor"
						},
						{
							"_nr": "4",
							"__text": "Concectarea mediilor de stocare a datelor"
						},
						{
							"_nr": "5",
							"__text": "Arhitecturi in transferul datelor"
						},
						{
							"_nr": "6",
							"__text": "Placi grafice"
						},
						{
							"_nr": "7",
							"__text": "Periferice de baza"
						},
						{
							"_nr": "8",
							"__text": "Comunicare mediu-sistem-mediu. Conectarea perifericelor la sistem"
						}
					]
				},
				"_id": "83",
				"_isbn": "973-9251-11-0"
			},
			{
				"titlu": "FoxBase+. Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Alexandru Moraru"
					}
				},
				"anAparitie": "1993",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "208",
				"pretOriginal": "1350",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "2",
					"_nume": "Initiere PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "FoxBase_Plus_Ghid_de_utilizare_Moraru.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Baze de date.Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de gestiune a bazelor de date FoxBase+"
						},
						{
							"_nr": "3",
							"__text": "Crearea, modificarea, copierea si stergerea fisierelor de date"
						},
						{
							"_nr": "4",
							"__text": "Operatii efectuate asupra articolelordin fisierele de date"
						},
						{
							"_nr": "5",
							"__text": "Modul de lucru indexat"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea simultana a mai multor fisiere de date"
						},
						{
							"_nr": "7",
							"__text": "Gestiunea memoriei de lucru"
						},
						{
							"_nr": "8",
							"__text": "Functii FoxBase+"
						},
						{
							"_nr": "9",
							"__text": "Proceduri FoxBase"
						},
						{
							"_nr": "10",
							"__text": "Gestiunea ecranului"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea imprimantei"
						},
						{
							"_nr": "12",
							"__text": "Generarea rapoartelor"
						},
						{
							"_nr": "13",
							"__text": "Apelul modulelor de program externe"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea FoxBase in regim multiutilizator"
						}
					]
				},
				"_id": "84",
				"_isbn": "973-96096-7-8"
			},
			{
				"titlu": "Turbo C. Tehnici de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Kasco Adrian"
						},
						{
							"_nume": "Kasco Daniela"
						},
						{
							"_nume": "Vald Caprariu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"numarPagini": "336",
				"pretOriginal": "425",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "15",
					"_nume": "Biblioteca PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_C_Tehnici_de_programare_Kasco.jpg"
					},
					"__text": "Ionut T Rusu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurte consideratii teoretice"
						},
						{
							"_nr": "2",
							"__text": "Mediul de executie"
						},
						{
							"_nr": "3",
							"__text": "Alternative si posibilitati"
						},
						{
							"_nr": "4",
							"__text": "O privire generala asupra limbajului C"
						},
						{
							"_nr": "5",
							"__text": "Tehnici de depanare"
						},
						{
							"_nr": "6",
							"__text": "Alocator de memorie orientat pe obiect"
						}
					]
				},
				"_id": "85",
				"_isbn": "973-95718-1-6"
			},
			{
				"titlu": "Introducere in microprocesoare",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Toacse"
					}
				},
				"anAparitie": "1986",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "424",
				"pretOriginal": "32",
				"pretCumparare": "28000",
				"redactori": {
					"redactor": "Florica Plopeanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_microprocesoare_Toacse.jpg"
					},
					"__text": "Ion Mincu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Suportul binar al electronicii numerice"
						},
						{
							"_nr": "2",
							"__text": "Circuite logice combinationale"
						},
						{
							"_nr": "3",
							"__text": "Circuite logice secventiale"
						},
						{
							"_nr": "4",
							"__text": "Structura si functionarea unui microprocesor"
						},
						{
							"_nr": "5",
							"__text": "Programare in limbaj de asamblare a microprocesoarelor"
						},
						{
							"_nr": "6",
							"__text": "Lucrul cu periferice intr-un sistem de baza de microprocesor"
						},
						{
							"_nr": "7",
							"__text": "Elaboararea sistemelor de baza de microprocesor"
						},
						{
							"_nr": "8",
							"__text": "Anexe"
						}
					]
				},
				"_id": "86",
				"_isbn": ""
			},
			{
				"titlu": "Limbajele C si C++ pentru incepatori. Limbajul C. Volumul I (editia II)",
				"autori": {
					"autor": {
						"_nume": "Liviu Negrescu"
					}
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "782",
				"pretOriginal": "19500",
				"colectia": {
					"_numarul": "27",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajele_C_si_Cpp_pentru_incepatori_Limbajul_C_Volumul_I_Negrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Intrari/Iesiri standard"
						},
						{
							"_nr": "3",
							"__text": "Expresii"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni"
						},
						{
							"_nr": "5",
							"__text": "Clase de memorie"
						},
						{
							"_nr": "6",
							"__text": "Initializare"
						},
						{
							"_nr": "7",
							"__text": "Programare modulara"
						},
						{
							"_nr": "8",
							"__text": "Pointeri"
						},
						{
							"_nr": "9",
							"__text": "Recursivitate"
						},
						{
							"_nr": "10",
							"__text": "Structuri si tipuri definite de utlizator"
						},
						{
							"_nr": "11",
							"__text": "Liste"
						},
						{
							"_nr": "12",
							"__text": "Arbori"
						},
						{
							"_nr": "13",
							"__text": "Tabele"
						},
						{
							"_nr": "14",
							"__text": "Sortare"
						},
						{
							"_nr": "15",
							"__text": "Din nou despre preprocesare in C"
						},
						{
							"_nr": "16",
							"__text": "Intrari/Iesiri"
						},
						{
							"_nr": "17",
							"__text": "Functii standard"
						},
						{
							"_nr": "18",
							"__text": "Gestiunea ecranului in mod text"
						},
						{
							"_nr": "19",
							"__text": "Gestiunea ecranului in mod grafic"
						}
					]
				},
				"_id": "87",
				"_isbn": "973-9215-17-3"
			},
			{
				"titlu": "Limbajele C si C++ pentru incepatori. Limbajul C++. Volumul II (editia II)",
				"autori": {
					"autor": {
						"_nume": "Liviu Negrescu"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "672",
				"pretOriginal": "17550",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "35",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": ""
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "20",
							"__text": "Limbajul C++ ca extensie a limbajului C"
						},
						{
							"_nr": "21",
							"__text": "Programarea prin abstractizarea datelor"
						},
						{
							"_nr": "22",
							"__text": "Clase"
						},
						{
							"_nr": "23",
							"__text": "Supraincarcarea operatorilor"
						},
						{
							"_nr": "24",
							"__text": "Conversii"
						},
						{
							"_nr": "25",
							"__text": "Modificatorul cast pentru obiecte si functii membru"
						},
						{
							"_nr": "26",
							"__text": "Operatorul de deferentiere a pointerilor spre membrii claselor (.* si ->*)"
						},
						{
							"_nr": "27",
							"__text": "Concluzii asupra programarii prin abstractizarea datelor"
						},
						{
							"_nr": "28",
							"__text": "Programare orientata spre obiecte"
						},
						{
							"_nr": "29",
							"__text": "Clase derivate si clase de baza"
						},
						{
							"_nr": "30",
							"__text": "Functii virtuale"
						},
						{
							"_nr": "31",
							"__text": "Intrarii/Iesiri"
						}
					]
				},
				"_id": "88",
				"_isbn": "973-9215-13-0"
			},
			{
				"titlu": "Limbajele C si C++ pentru incepatori. Limbajul C++. Volumul II",
				"autori": {
					"autor": {
						"_nume": "Liviu Negrescu"
					}
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "3000",
				"numarPagini": "672",
				"pretOriginal": "9880",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "35",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajele_C_si_Cpp_pentru_Incepatori_Vol_II_Negrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "20",
							"__text": "Limbajul C++ ca extensie a limbajului C"
						},
						{
							"_nr": "21",
							"__text": "Programarea prin abstractizarea datelor"
						},
						{
							"_nr": "22",
							"__text": "Clase"
						},
						{
							"_nr": "23",
							"__text": "Supraincarcarea operatorilor"
						},
						{
							"_nr": "24",
							"__text": "Conversii"
						},
						{
							"_nr": "25",
							"__text": "Modificatorul cast pentru obiecte si functii membru"
						},
						{
							"_nr": "26",
							"__text": "Operatorul de deferentiere a pointerilor spre membrii claselor (.* si ->*)"
						},
						{
							"_nr": "27",
							"__text": "Concluzii asupra programarii prin abstractizarea datelor"
						},
						{
							"_nr": "28",
							"__text": "Programare orientata spre obiecte"
						},
						{
							"_nr": "29",
							"__text": "Clase derivate si clase de baza"
						},
						{
							"_nr": "30",
							"__text": "Functii virtuale"
						},
						{
							"_nr": "31",
							"__text": "Intrarii/Iesiri"
						}
					]
				},
				"_id": "89",
				"_isbn": "973-96980-4-2"
			},
			{
				"titlu": "Sistemul de operare DOS. Functii sistem",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Munteanu"
						},
						{
							"_nume": "Marius Chiorean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica (Romanian Software Comp.)",
				"numarPagini": "144",
				"pretOriginal": "80",
				"pretCumparare": "11000",
				"colectia": {
					"_nume": "?",
					"_numarul": "3"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_DOS_Functii_sistem_Caprariu_1991.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Apeluri de functii sistem"
						},
						{
							"_nr": "2",
							"__text": "Descrierea detaliata a apelurilor sistem"
						},
						{
							"_nr": "3",
							"__text": "Exemple de utilizare a functiilor sistem"
						}
					]
				},
				"_id": "90",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul de operare DOS. Functii sistem",
				"autori": {
					"autor": {
						"_nume": "Vlad Caprariu"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "4000",
				"numarPagini": "192",
				"pretOriginal": "3900",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Seria PC",
					"_numarul": "3"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_DOS_Functii_sistem_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Apeluri de functii sistem"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea detaliata a apelurilor sistem"
						},
						{
							"_nr": "3",
							"__text": "Exemple de utilizare a functiilor sistem"
						}
					]
				},
				"_id": "91",
				"_isbn": "973-96980-2-6"
			},
			{
				"titlu": "Sistemul de operare DOS. Ghidul programatorului (editia IV)",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Munteanu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "304",
				"pretOriginal": "5720",
				"colectia": {
					"_nume": "Seria PC",
					"_numarul": "4"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_DOS_Ghidul_programatorului_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Microprocesoare INTEL"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura calculatoarelor de tiup IBM PC"
						},
						{
							"_nr": "3",
							"__text": "Structura interna a sistemului de operare DOS"
						},
						{
							"_nr": "4",
							"__text": "Gestionarea discurilor magnetice"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea ecranului"
						},
						{
							"_nr": "6",
							"__text": "Folosirea timpului si a sunetului. Gestiunea tastaturii"
						}
					]
				},
				"_id": "92",
				"_isbn": "973-96980-6-9"
			},
			{
				"titlu": "Ghid de utilizare Turbo Pascal 5.0-5.5",
				"autori": {
					"autor": [
						{
							"_nume": "Makkai Andras"
						},
						{
							"_nume": "Cecilia Crisan"
						},
						{
							"_nume": "Kovacs Sandor"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica (Romanian Software Comp.)",
				"numarPagini": "348",
				"pretOriginal": "160",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "?",
					"_numarul": "5"
				},
				"redactori": {
					"redactor": [
						"Vlad Caprariu",
						"Andrei Enyedi",
						"Marius Muntean",
						"Marius Chiorean"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_utilizare_Turbo_Pascal_5_0_5_5_Makkai.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Program model"
						},
						{
							"_nr": "2",
							"__text": "Mediul de programare Turbo Pascal"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Turbo Pascal"
						},
						{
							"_nr": "4",
							"__text": "Organizarea programelor mari"
						},
						{
							"_nr": "5",
							"__text": "UNIT-ul SYSTEM"
						},
						{
							"_nr": "6",
							"__text": "UNIT-ul DOS"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea graficii"
						},
						{
							"_nr": "8",
							"__text": "Tehnica OVERLAY"
						},
						{
							"_nr": "9",
							"__text": "Deapanare si corectare"
						},
						{
							"_nr": "10",
							"__text": "Anexa A - Editorul de texte"
						},
						{
							"_nr": "11",
							"__text": "Anexa B - Codurile tastelor"
						},
						{
							"_nr": "12",
							"__text": "Anexa C"
						},
						{
							"_nr": "13",
							"__text": "Anexa D - Extensiile limbajului Turbo Pascal V5.5"
						}
					]
				},
				"_id": "93",
				"_isbn": ""
			},
			{
				"titlu": "Turbo C 2.0. Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Vlad Caprariu"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "10"
				},
				"redactori": {
					"redactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_C_2_0_ghid_de_utilizare_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Editarea"
						},
						{
							"_nr": "3",
							"__text": "Construirea fisierului executabil"
						},
						{
							"_nr": "4",
							"__text": "Lucrul din linie de comanda"
						},
						{
							"_nr": "5",
							"__text": "Fisiere tipice Turbo C"
						},
						{
							"_nr": "6",
							"__text": "Depanarea"
						},
						{
							"_nr": "7",
							"__text": "Meniul Options"
						},
						{
							"_nr": "8",
							"__text": "Elemente de limbaj"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni"
						},
						{
							"_nr": "10",
							"__text": "Functii"
						},
						{
							"_nr": "11",
							"__text": "Structuri de date"
						},
						{
							"_nr": "12",
							"__text": "Notiuni de programare avansata"
						},
						{
							"_nr": "13",
							"__text": "Sugestii pentru programatori"
						}
					]
				},
				"_id": "94",
				"_isbn": ""
			},
			{
				"titlu": "Programare avansata in Turbo Pascal 6.0",
				"autori": {
					"autor": [
						{
							"_nume": "Vasiu Lucian"
						},
						{
							"_nume": "Grama Radu"
						},
						{
							"_nume": "Aldica Alexandra"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "180",
				"pretOriginal": "1120",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Seria PC",
					"_numarul": "22"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_avansata_in_Turbo_Pascal_6_0_Vasiu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "4",
							"__text": "Unit-uri proprii. Programe care folosesc unit-urile proprii"
						},
						{
							"_nr": "5",
							"__text": "Programare orientata pe obiecte si Turbo Visio"
						},
						{
							"_nr": "6",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "95",
				"_isbn": "973-96096-4-3"
			},
			{
				"titlu": "Introducere in limbajul C",
				"autori": {
					"autor": {
						"_nume": "Negrescu Liviu"
					}
				},
				"anAparitie": "1993",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "480",
				"pretOriginal": "1350",
				"pretCumparare": "30000",
				"colectia": {
					"_nume": "Seria PC",
					"_numarul": "21"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_limbajul_C_Negrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Expresii"
						},
						{
							"_nr": "3",
							"__text": "Intrari/Iesiri de caractere"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni.Operatorul virgula. Expresie conditionata. Functia exit"
						},
						{
							"_nr": "5",
							"__text": "Alocarea memoriei"
						},
						{
							"_nr": "6",
							"__text": "Pointeri"
						},
						{
							"_nr": "7",
							"__text": "Functiile GETCH si UNGETCH"
						},
						{
							"_nr": "8",
							"__text": "Stive"
						},
						{
							"_nr": "9",
							"__text": "Recursivitate"
						},
						{
							"_nr": "10",
							"__text": "Structuri"
						},
						{
							"_nr": "11",
							"__text": "Functiile de biblioteca. MALLOC si FREE"
						},
						{
							"_nr": "12",
							"__text": "Liste"
						},
						{
							"_nr": "13",
							"__text": "Arbori"
						},
						{
							"_nr": "14",
							"__text": "Cautare in tabele"
						},
						{
							"_nr": "15",
							"__text": "Sortare"
						},
						{
							"_nr": "16",
							"__text": "Alocarea spatiului de memorie"
						},
						{
							"_nr": "17",
							"__text": "Din nou despre preprocesare in C"
						},
						{
							"_nr": "18",
							"__text": "Interfata cu dispozitive periferice"
						},
						{
							"_nr": "19",
							"__text": "Conversii sub controlul formatelor"
						},
						{
							"_nr": "20",
							"__text": "Introducere in mediul de programare Turbo C"
						},
						{
							"_nr": "21",
							"__text": "Gestiunea ecranului"
						},
						{
							"_nr": "22",
							"__text": "Probleme de algebra rezolvate in C"
						}
					]
				},
				"_id": "96",
				"_isbn": "973-95718-18-3"
			},
			{
				"titlu": "C++ pentru avansati",
				"autori": {
					"autor": {
						"_nume": "Ionut Muslea"
					}
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "4000",
				"numarPagini": "294",
				"pretOriginal": "4410",
				"pretCumparare": "30000",
				"colectia": {
					"_nume": "Seria PC",
					"_numarul": "33"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_pentru_avansati_Muslea.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Precizari, completari, modificari, recomandari"
						},
						{
							"_nr": "2",
							"__text": "Clase si functii \"Template\""
						},
						{
							"_nr": "3",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "4",
							"__text": "Conceptul de carte"
						},
						{
							"_nr": "5",
							"__text": "Universul claselor. Artificii de implementare"
						},
						{
							"_nr": "6",
							"__text": "Universul claselor. O abordare conceptuala"
						},
						{
							"_nr": "7",
							"__text": "Ciclul de viata al produselor software"
						},
						{
							"_nr": "8",
							"__text": "Proiectarea aplicatiilor C++"
						},
						{
							"_nr": "9",
							"__text": "Proiectarea unui mini-interpretor BASIC"
						}
					]
				},
				"_id": "97",
				"_isbn": "973-9171-03-6"
			},
			{
				"titlu": "Metode si modele ale CERCETARII OPERATIONALE (Matematica intreprinderilor) - Volumnul I",
				"autori": {
					"autor": {
						"_nume": "A. Kaufmann"
					}
				},
				"anAparitie": "1967",
				"editura": "Stiintifica",
				"tiraj": "2300+140",
				"numarPagini": "602",
				"pretOriginal": "18.50",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Methodes et modeles de la recherche operationnelle (Les mathenatiques de l' entreprise)",
					"editura": "Dumon",
					"traducatori": {
						"traducator": "Adriana Berechet"
					},
					"_an": "1962",
					"_editia": "1",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Eugenia Teodorof"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Stanculescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_si_modele_ale_cercetarii_operationale_Kaufmann_I.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Programarea liniara"
						},
						{
							"_nr": "3",
							"__text": "Fenomene de asteptare"
						},
						{
							"_nr": "4",
							"__text": "Problema stocurilor"
						},
						{
							"_nr": "5",
							"__text": "Uzura, inlocuirea si mentinerea utilajelor"
						},
						{
							"_nr": "6",
							"__text": "Elemente de matematici"
						},
						{
							"_nr": "7",
							"__text": "Metode de calcul al programarii liniare"
						},
						{
							"_nr": "8",
							"__text": "Teoria fenomenelor de asteptare"
						},
						{
							"_nr": "9",
							"__text": "Studiul analitic al problemelor stocurilor"
						},
						{
							"_nr": "10",
							"__text": "Studiul analitic al problemelor de uzura, inlocuire si de mentinere a utilajelor"
						}
					]
				},
				"_id": "98",
				"_isbn": ""
			},
			{
				"titlu": "Metode si modele ale CERCETARII OPERATIONALE (Matematica intreprinderilor) - Volumnul II",
				"autori": {
					"autor": {
						"_nume": "A. Kaufmann"
					}
				},
				"anAparitie": "1967",
				"editura": "Stiintifica",
				"tiraj": "3800+140",
				"numarPagini": "600",
				"pretOriginal": "19.50",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Methodes et modeles de la recherche operationnelle (Les mathenatiques de l' entreprise)",
					"editura": "Dumon",
					"traducatori": {
						"traducator": "Adriana Berechet"
					},
					"_an": "1962",
					"_editia": "1",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Eugenia Teodorof"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Stanculescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_si_modele_ale_cercetarii_operationale_Kaufmann_II.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Grafele"
						},
						{
							"_nr": "2",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "3",
							"__text": "Teoria jocurilor de strategie"
						},
						{
							"_nr": "4",
							"__text": "Principalele proprietati ale grafelor"
						},
						{
							"_nr": "5",
							"__text": "Proprietatile matematice ale programarii dinamice"
						},
						{
							"_nr": "6",
							"__text": "Proprietatile matematice ale jocurilor de strategie"
						}
					]
				},
				"_id": "99",
				"_isbn": ""
			},
			{
				"titlu": "Metode si modele ale CERCETARII OPERATIONALE (Programarea matematica in numere intregi) - Volumnul III",
				"autori": {
					"autor": [
						{
							"_nume": "A. Kaufmann"
						},
						{
							"_nume": "A. Henry-Labordere"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Stiintifica si enciclopedica",
				"tiraj": "3600",
				"numarPagini": "442",
				"pretOriginal": "17.50",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Methodes et modeles de la recherche operationnelle (Les mathenatiques de l' entreprise)",
					"editura": "Dumon",
					"traducatori": {
						"traducator": "Cristian Bergthaller"
					},
					"_an": "1974",
					"_editia": "1",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Dorina Badila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_si_modele_ale_cercetarii_operationale_Kaufmann_III.jpg"
					},
					"__text": "George Paul Mihail"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme de programare discreta"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi si metode euristice pentru rezolcarea problemelor de programare in numere intregi si de programare mixta"
						}
					]
				},
				"_id": "100",
				"_isbn": ""
			},
			{
				"titlu": "Analiza numerica in Turbo Pascal",
				"autori": {
					"autor": {
						"_nume": "Titus Adrian Beu"
					}
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "204",
				"pretOriginal": "525",
				"colectia": {
					"_numarul": "0",
					"_nume": "Biblioteca PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Titus Adrian Beu"
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_numerica_in_Turbo_Pascal_Beu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi"
						},
						{
							"_nr": "3",
							"__text": "Elemente ale limbajului Turbo Pascal"
						},
						{
							"_nr": "4",
							"__text": "Elemente de grafica in Turbo Pascal"
						},
						{
							"_nr": "5",
							"__text": "Numere aproximative"
						},
						{
							"_nr": "6",
							"__text": "Evaluarea functiilor"
						},
						{
							"_nr": "7",
							"__text": "Rezolvarea numerica a ecuatiilor algebrice si transcendente"
						},
						{
							"_nr": "8",
							"__text": "Rezolvarea sistemlor de ecuatii algebrice liniare"
						},
						{
							"_nr": "9",
							"__text": "Probleme de valori si vectori proprii"
						},
						{
							"_nr": "10",
							"__text": "Aproximarea functiilor"
						},
						{
							"_nr": "11",
							"__text": "Integrarea numerica a functiilor"
						},
						{
							"_nr": "12",
							"__text": "Integrara ecuatiilor diferentiale ordinare"
						}
					]
				},
				"_id": "101",
				"_isbn": "973-95718-5-9"
			},
			{
				"titlu": "Introducere in ACCESS 2.0",
				"autori": {
					"autor": {
						"_nume": "Mihai Anton Cerghizan"
					}
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "378",
				"pretOriginal": "9000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Microsoft"
				},
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Staicu"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_Access_2_0_Cerghizan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prima sesiune de lucru in Access"
						},
						{
							"_nr": "2",
							"__text": "Baze de date si aplicatii Access"
						},
						{
							"_nr": "3",
							"__text": "Tabele"
						},
						{
							"_nr": "4",
							"__text": "Interogari"
						},
						{
							"_nr": "5",
							"__text": "Machete"
						},
						{
							"_nr": "6",
							"__text": "Rapoarte"
						},
						{
							"_nr": "7",
							"__text": "Prelucrarea datelor"
						},
						{
							"_nr": "8",
							"__text": "Folosirea tabelelor corelate"
						},
						{
							"_nr": "9",
							"__text": "Macro-comenzi"
						},
						{
							"_nr": "10",
							"__text": "Module"
						},
						{
							"_nr": "11",
							"__text": "Interfata aplicatiei cu utilizatorul"
						},
						{
							"_nr": "12",
							"__text": "Administrarea bazelor de date Access"
						}
					]
				},
				"_id": "102",
				"_isbn": "973-31-0893-6"
			},
			{
				"titlu": "POO. Analiza, proiectarea im programarea orientate spre obiecte",
				"autori": {
					"autor": [
						{
							"_nume": "Claudia Spircu"
						},
						{
							"_nume": "Ionut Lopatan"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "208",
				"pretOriginal": "4900",
				"colectia": {
					"_numarul": "42",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "POO_Analoza_proiectarea_si_programarea_orientate_spre_obiecte_Spircu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive de inginerie a programarii"
						},
						{
							"_nr": "2",
							"__text": "Modelul obiectelor"
						},
						{
							"_nr": "3",
							"__text": "Modelul dinamic"
						},
						{
							"_nr": "4",
							"__text": "Modelul functional"
						},
						{
							"_nr": "5",
							"__text": "Analiza orientata spre obiecte"
						},
						{
							"_nr": "6",
							"__text": "Proiectarea"
						},
						{
							"_nr": "7",
							"__text": "Avantajele utilizarii metodelor orientate spre obiecte"
						},
						{
							"_nr": "8",
							"__text": "Implementarii in C++"
						},
						{
							"_nr": "9",
							"__text": "Alte metode de analiza orietata spre obiecte - prezentare comparativa"
						},
						{
							"_nr": "10",
							"__text": "Instrumente CASE : C++ Deginer si Select Yourdon"
						}
					]
				},
				"_id": "103",
				"_isbn": "973-601-189-5"
			},
			{
				"titlu": "Matlab. Calcul numeric. Grafica. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Marin Ghinea"
						},
						{
							"_nume": "Virgiliu Fireteanu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "204",
				"pretOriginal": "10900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "74",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Matlab_calcul_numeric_grafica_aplicatii_Ghinea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in MATLAB?"
						},
						{
							"_nr": "II",
							"__text": "Caclul numeric cu MATLAB"
						},
						{
							"_nr": "III",
							"__text": "Grafica in MATLAB"
						},
						{
							"_nr": "IV",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "104",
				"_isbn": "973-601-275-1"
			},
			{
				"titlu": "Calculatorul fara secrete",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Carstea"
						},
						{
							"_nume": "Ion Diamandi"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Agni",
				"numarPagini": "288",
				"pretOriginal": "22.000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Biblioteca de informatica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Trusca"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatorul_fara_secrete_Diamandi.jpg"
					},
					"__text": "Felicia Trusca"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Hardware"
						},
						{
							"_nr": "III",
							"__text": "Software"
						},
						{
							"_nr": "IV",
							"__text": "Retele"
						}
					]
				},
				"_id": "105",
				"_isbn": "973-97983-3-0"
			},
			{
				"titlu": "Programare Object Windows",
				"autori": {
					"autor": {
						"_nume": "Liana Cecal"
					}
				},
				"anAparitie": "1994",
				"editura": "Agni",
				"numarPagini": "288",
				"pretOriginal": "7300",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Marian Gheorghe"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Olariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_Object_Windows_Cecal.jpg"
					},
					"__text": "Adina Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Programare orientata pe obiect si C++"
						},
						{
							"_nr": "II",
							"__text": "Programare folosind ObjectWindows"
						},
						{
							"_nr": "III",
							"__text": "ObjectWindows - Aplicatii complexe"
						}
					]
				},
				"_id": "106",
				"_isbn": "973-96347-1-0"
			},
			{
				"titlu": "Algoritmi si limbaje programare [IX]",
				"autori": {
					"autor": {
						"_nume": "Daniela Saru"
					}
				},
				"anAparitie": "1998",
				"editura": "Didactica si pedagogica",
				"numarPagini": "260",
				"pretOriginal": "11.000",
				"redactori": {
					"redactor": "Marius Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Stan"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_si_limbaje_de_programare_IX_Saru.jpg"
					},
					"__text": "Elena Dragulelei Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Programe. Algoritmi. Elemente de programare structurata"
						},
						{
							"_nr": "3",
							"__text": "Notiuni de vocabular"
						},
						{
							"_nr": "4",
							"__text": "Notiunea de date"
						},
						{
							"_nr": "5",
							"__text": "Notiunea de tip. Tipuri standard"
						},
						{
							"_nr": "6",
							"__text": "Structura generala a unui program PASCAL"
						},
						{
							"_nr": "7",
							"__text": "Operatii de intrare/iesire"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni de baza ale limbajului PASCAL"
						},
						{
							"_nr": "9",
							"__text": "Tipuri definite in program (tipuri de date utilizator)"
						},
						{
							"_nr": "10",
							"__text": "Subprograme"
						},
						{
							"_nr": "11",
							"__text": "Subprograme recursive"
						},
						{
							"_nr": "12",
							"__text": "Alte tipuri structurate de date"
						},
						{
							"_nr": "13",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "14",
							"__text": "Instructiunea GOTO (salt neconditionat)"
						},
						{
							"_nr": "15",
							"__text": "Aspecte legate de structurarea programelor"
						},
						{
							"_nr": "16",
							"__text": "Unitati de program"
						}
					]
				},
				"_id": "107",
				"_isbn": "973-30-5226-4"
			},
			{
				"titlu": "Introducere algebrica in informatica: Teoria Automatelor (Vol I)",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Creanga"
						},
						{
							"_nume": "C. Reischer"
						},
						{
							"_nume": "Dan Simovici"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Junimea",
				"numarPagini": "300",
				"pretOriginal": "18",
				"redactori": {
					"redactor": "Mihail Gradinaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Bujdei"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_algebrica_Teoria_automatelor_Simovici.jpg"
					},
					"__text": "P. Olga"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de algebra"
						},
						{
							"_nr": "II",
							"__text": "Teoria automatelor"
						},
						{
							"_nr": "III",
							"__text": "Probleme speciale de teoria automatelor"
						}
					]
				},
				"_id": "108",
				"_isbn": ""
			},
			{
				"titlu": "Probleme de teoria grafurilor",
				"autori": {
					"autor": [
						{
							"_nume": "Nicolae Radescu"
						},
						{
							"_nume": "Eugenia Radescu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Scrisul Romanesc",
				"numarPagini": "304",
				"pretOriginal": "26",
				"redactori": {
					"redactor": "Vasile Predescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Lungulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_teoria_grafurilor_Radescu.jpg"
					},
					"__text": "Victor Parlac"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Definitia unui graf. Conexiunea intr-un graf. Descompunerea unui graf in componente tare conexe. Inchiderea tranzitiva. Operatia cu grafuri"
						},
						{
							"_nr": "2",
							"__text": "Functia Grundy si functia ordinala"
						},
						{
							"_nr": "3",
							"__text": "Numere fundamentale in teoria grafurilor"
						},
						{
							"_nr": "4",
							"__text": "Drumuri intr-un graf"
						},
						{
							"_nr": "5",
							"__text": "Retele de transport"
						}
					]
				},
				"_id": "109",
				"_isbn": ""
			},
			{
				"titlu": "Teoria algoritmilor",
				"autori": {
					"autor": {
						"_nume": "Ioan Marusciac"
					}
				},
				"anAparitie": "1966",
				"editura": "Didactica si pedagogica",
				"numarPagini": "148",
				"pretOriginal": "11.60",
				"tehnoredactori": {
					"tehnoredactor": "Prosan Ilinca"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_algoritmilor_Marusciac.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Operatori alfabetici si algoritmici"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi normali"
						},
						{
							"_nr": "3",
							"__text": "Functii calculabile"
						},
						{
							"_nr": "4",
							"__text": "Masina lui Turing"
						},
						{
							"_nr": "5",
							"__text": "Probleme nerezolvabile algoritmic"
						}
					]
				},
				"_id": "110",
				"_isbn": ""
			},
			{
				"titlu": "Grafuri. Aplicatii - Volumul I",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Ionescu"
					}
				},
				"anAparitie": "1973",
				"editura": "Didactica si pedagogica",
				"tiraj": "3920",
				"numarPagini": "274",
				"pretOriginal": "16.70",
				"redactori": {
					"redactor": "Eliferie Rogai"
				},
				"tehnoredactori": {
					"tehnoredactor": "T. Balaita"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafuri_Aplicatii_Vol_I_Ionescu.jpg"
					},
					"__text": "I. Dragan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Cunostinte prealabile"
						},
						{
							"_nr": "II",
							"__text": "Grafuri: definitii, generalitati, notatii"
						},
						{
							"_nr": "III",
							"__text": "Matrici asociate grafurilor (I)"
						},
						{
							"_nr": "IV",
							"__text": "Drumuri si circuite optime cu lungime data. Drumuri si circuite hamiltoniene optime"
						},
						{
							"_nr": "V",
							"__text": "Alte algoritme pentru aflarea drumurilor si circuitelor optime"
						},
						{
							"_nr": "VI",
							"__text": "Aspecte ale ordinii in grafuri"
						},
						{
							"_nr": "VII",
							"__text": "Arbori : arborescente"
						},
						{
							"_nr": "VIII",
							"__text": "Matrici asociate grafurilor (II)"
						},
						{
							"_nr": "IX",
							"__text": "Drumul critic"
						}
					]
				},
				"_id": "111",
				"_isbn": ""
			},
			{
				"titlu": "Metode si tehnici de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Odagescu"
						},
						{
							"_nume": "Daniel Luca"
						},
						{
							"_nume": "Felix Furtuna"
						},
						{
							"_nume": "Cristina Copos"
						},
						{
							"_nume": "Ion Smeureanu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "INTACT",
				"numarPagini": "224",
				"pretOriginal": "2500",
				"coperta": {
					"imagine": {
						"_path": "Metode_si_tehnici_de_programare_enunturi_solutii_probleme_propuse_Odagescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme de logica"
						},
						{
							"_nr": "2",
							"__text": "Iterativitate si recursivitate"
						},
						{
							"_nr": "3",
							"__text": "Metode si tehnici de programare"
						},
						{
							"_nr": "3.1",
							"__text": "Metoda Greedy"
						},
						{
							"_nr": "3.2",
							"__text": "Metoda Backtraking"
						},
						{
							"_nr": "3.3",
							"__text": "Metoda Greedy combinata cu Backtraking"
						},
						{
							"_nr": "3.4",
							"__text": "Metoda programarii dinamice"
						},
						{
							"_nr": "3.5",
							"__text": "Metoda Divide et impera"
						},
						{
							"_nr": "3.6",
							"__text": "Metoda euristice"
						},
						{
							"_nr": "3.7",
							"__text": "Metoda Branch and bound"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi de cautare, sortare, selectie"
						},
						{
							"_nr": "5",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "112",
				"_isbn": "973-95549-8-9"
			},
			{
				"titlu": "Sisteme informatice : o abordare intre clasic si modern",
				"autori": {
					"autor": {
						"_nume": "Minodora Ursacescu"
					}
				},
				"anAparitie": "2002",
				"editura": "Economica",
				"numarPagini": "184",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Florin Erhan"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Petronella Andrei",
						"Elena Milea"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_O_abordare_intre_clasic_si_modern_Ursacescu.jpg"
					},
					"__text": "Doina Calin"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Procesul de informare in cadrul organizatiei"
						},
						{
							"_nr": "2",
							"__text": "Tehnologii si sisteme informatice"
						},
						{
							"_nr": "3",
							"__text": "Sisteme informatice de gestiune economica"
						},
						{
							"_nr": "4",
							"__text": "Sisteme informatice moderne"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea strategica a sistemelor informatice"
						},
						{
							"_nr": "6",
							"__text": "Gestiunea tactica a sistemelor informatice"
						},
						{
							"_nr": "7",
							"__text": "Controlul sistemelor informatice"
						}
					]
				},
				"_id": "113",
				"_isbn": "973-590-675-9"
			},
			{
				"titlu": "Introducere in algoritmi",
				"autori": {
					"autor": [
						{
							"_nume": "Thomas H. Cormen"
						},
						{
							"_nume": "Charles E. Leiserson"
						},
						{
							"_nume": "Ronald R. Rivest"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Agora",
				"numarPagini": "882",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Introduction of Algorithms",
					"editura": "Masachusetts Institute of Technology",
					"traducatori": {
						"traducator": [
							"Simona Motagna",
							"Horia F. Pop",
							"Paul Blaga",
							"Adrian Monea",
							"Radu Trambitas",
							"Clara Ionescu",
							"Zoltan Kasa",
							"Luminita State",
							"Bazil Parv",
							"Cristina Vertan",
							"Florian M. Boian",
							"Ioan Lazar",
							"Virginia Niculescu",
							"Mihai Scortaru",
							"Liviu Negrescu",
							"Liana Bogzga"
						]
					},
					"_an": "1990",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_algoritmi_Cormen.jpg"
					},
					"__text": "Mircea Dragoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Cresterea functiilor"
						},
						{
							"_nr": "3",
							"__text": "Sume"
						},
						{
							"_nr": "4",
							"__text": "Recurente"
						},
						{
							"_nr": "5",
							"__text": "Multimi etc"
						},
						{
							"_nr": "6",
							"__text": "Numarare si probabilitate"
						},
						{
							"_nr": "7",
							"__text": "Heapsort"
						},
						{
							"_nr": "8",
							"__text": "Sortarea rapida"
						},
						{
							"_nr": "9",
							"__text": "Sortare in timp liniar"
						},
						{
							"_nr": "10",
							"__text": "Mediane si statistici de ordine"
						},
						{
							"_nr": "11",
							"__text": "Structuri de date elementare"
						},
						{
							"_nr": "12",
							"__text": "Tabele de dispersie"
						},
						{
							"_nr": "13",
							"__text": "Arbori binari de cautare"
						},
						{
							"_nr": "14",
							"__text": "Arbori rosu-negru"
						},
						{
							"_nr": "15",
							"__text": "Inmunatatirea structurilor de date"
						},
						{
							"_nr": "16",
							"__text": "Programarea dinamica"
						},
						{
							"_nr": "17",
							"__text": "Algoritmi Greedy"
						},
						{
							"_nr": "18",
							"__text": "Analiza amortizata"
						},
						{
							"_nr": "19",
							"__text": "B-arbori"
						},
						{
							"_nr": "20",
							"__text": "Heap-uri binomiale"
						},
						{
							"_nr": "21",
							"__text": "Heap-uri Fibonacci"
						},
						{
							"_nr": "22",
							"__text": "Structuri de date pentru multimi disjuncte"
						},
						{
							"_nr": "23",
							"__text": "Alogritmi elementari de grafuri"
						},
						{
							"_nr": "24",
							"__text": "Arbori de acoperire minimi"
						},
						{
							"_nr": "25",
							"__text": "Drumuri minime de sursa unica"
						},
						{
							"_nr": "26",
							"__text": "Drumuri minime intre toate perechile de varfuri"
						},
						{
							"_nr": "27",
							"__text": "Flux maxim"
						},
						{
							"_nr": "28",
							"__text": "Retele de sortare"
						},
						{
							"_nr": "29",
							"__text": "Circuite aritmetice"
						},
						{
							"_nr": "30",
							"__text": "Algoritmi pentru calculatoarele paralele"
						},
						{
							"_nr": "31",
							"__text": "Operatii cu matrice"
						},
						{
							"_nr": "32",
							"__text": "Polinoame si TFR"
						},
						{
							"_nr": "33",
							"__text": "Algoritmi din teoria numerelor"
						},
						{
							"_nr": "34",
							"__text": "Potrivirea sirurilor"
						},
						{
							"_nr": "35",
							"__text": "Geometrie computationala"
						},
						{
							"_nr": "36",
							"__text": "NP-completitudine"
						},
						{
							"_nr": "37",
							"__text": "Algoritmi de aproximare"
						}
					]
				},
				"_id": "114",
				"_isbn": "973-97534-7-7"
			},
			{
				"titlu": "Procesorul de text pentru Windows : LOTUS AMI PRO. Conepte, tehnici,exemple",
				"autori": {
					"autor": {
						"_nume": "Mihai Popescu"
					}
				},
				"anAparitie": "1995",
				"editura": "Promedia",
				"numarPagini": "256",
				"pretOriginal": "4490",
				"coperta": {
					"imagine": {
						"_path": "Lotus_Ami_Pro_pentru_Windows_Concepte_Tehnici_Exemple_Popescu.jpg"
					},
					"__text": "Octavian Bour"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Crearea si modificarea unui document Ami Pro"
						},
						{
							"_nr": "3",
							"__text": "Salvarea unui document Ami Pro, deschidera unui document creat anterior"
						},
						{
							"_nr": "4",
							"__text": "Stilurile de paragraf"
						},
						{
							"_nr": "5",
							"__text": "Cadrele grafice"
						},
						{
							"_nr": "6",
							"__text": "Inserare unui text intr-un cadru grafic"
						},
						{
							"_nr": "7",
							"__text": "Importul si prelucrarea unei imagini"
						},
						{
							"_nr": "8",
							"__text": "Crearea, modificarea si salvarea desenelor in Ami Pro"
						},
						{
							"_nr": "9",
							"__text": "Editarea de formule stiintifice"
						},
						{
							"_nr": "10",
							"__text": "Crearea si modificarea graficelor in Ami Pro"
						},
						{
							"_nr": "11",
							"__text": "Crearea si modificarea tabelelor in Ami Pro"
						},
						{
							"_nr": "12",
							"__text": "Modificarea aspectului paginilor unui document"
						},
						{
							"_nr": "13",
							"__text": "Imprimarea unui document"
						},
						{
							"_nr": "14",
							"__text": "Stilurile de document"
						},
						{
							"_nr": "15",
							"__text": "Sortarea datelor"
						},
						{
							"_nr": "16",
							"__text": "Utilizarea si crearea rapida a unui macro"
						},
						{
							"_nr": "17",
							"__text": "Organizarea documentelor utilizand modul de lucru Outline"
						},
						{
							"_nr": "18",
							"__text": "Generarea unui cuprins si a unui index. Note de subsol"
						},
						{
							"_nr": "19",
							"__text": "Realizarea unei interclasari intre un document Ami Pro si o lista de date"
						}
					]
				},
				"_id": "115",
				"_isbn": "973-96862-0-6"
			},
			{
				"titlu": "Xerox Ventura Publisher",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Trandafirescu"
						},
						{
							"_nume": "Dan Marinescu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "192",
				"pretOriginal": "580",
				"colectia": {
					"_numarul": "7",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Xerox_Ventura_Publisher_Trandafirescu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Start rapid"
						},
						{
							"_nr": "2",
							"__text": "Privire de ansamblu"
						},
						{
							"_nr": "3",
							"__text": "Referinte complete"
						}
					]
				},
				"_id": "116",
				"_isbn": "973-60-030-9"
			},
			{
				"titlu": "Initiere in COBOL",
				"autori": {
					"autor": {
						"_nume": "Margareta Draghici"
					}
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "15000+140",
				"numarPagini": "372",
				"pretOriginal": "14.50",
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Cobol_Draghici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organizarea si manevrarea datelor. Notiuni necesare programatorului in COBOL"
						},
						{
							"_nr": "2",
							"__text": "Structura programelor in COBOL"
						},
						{
							"_nr": "3",
							"__text": "Descrierea datelor intr-un program COBOL. Diviziunea DATA"
						},
						{
							"_nr": "4",
							"__text": "Descrierea algoritmului prelucrarii prin instructiuni COBOL. Diviziunea PROCEDURE"
						},
						{
							"_nr": "5",
							"__text": "Diviziunele IDENTIFICATION si ENVIRONMEMT"
						},
						{
							"_nr": "6",
							"__text": "Fisiere organizate secvential indexat si selectiv. Prelucrarea  lor in COBOL"
						},
						{
							"_nr": "7",
							"__text": "Sortarea inregistrarilor unui fisier prin program COBOL"
						}
					]
				},
				"_id": "117",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul de operare AMS : manual de prezentare",
				"autori": {
					"autor": {
						"_nume": "Becea Aurel"
					}
				},
				"anAparitie": "1979",
				"editura": "ITC",
				"numarPagini": "88",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_AMS_manual_de_prezentare_Becea.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte si functii ale sistemului de operare AMS"
						},
						{
							"_nr": "3",
							"__text": "Servicii ale monitorului si operatii de intrare/iesire"
						},
						{
							"_nr": "4",
							"__text": "Dialogul utilzatorului cu sistemul"
						},
						{
							"_nr": "5",
							"__text": "Dezvoltare de programe"
						},
						{
							"_nr": "6",
							"__text": "Operatii cu fisiere"
						},
						{
							"_nr": "7",
							"__text": "Adaptarea sistemului de operare AMS la aplicatii"
						},
						{
							"_nr": "8",
							"__text": "Dezvoltarea sistemului de operare AMS"
						},
						{
							"_nr": "9",
							"__text": "Documentatia sistemului de operare AMS"
						}
					]
				},
				"_id": "118",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul de operare MINOS : manual de prezentare",
				"autori": {
					"autor": {
						"_nume": "Manea Nicolae"
					}
				},
				"anAparitie": "1979",
				"editura": "ITC",
				"numarPagini": "216",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_MINOS_manual_de_prezentare_Manea.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in sistemul de operare MINOS"
						},
						{
							"_nr": "2",
							"__text": "Concepte si functii ale sistemului de operare MINOS"
						},
						{
							"_nr": "3",
							"__text": "Servicii si directive sistem MINOS"
						},
						{
							"_nr": "4",
							"__text": "Interfata operator sistem MINOS"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de intrare/iesire MINOS"
						},
						{
							"_nr": "6",
							"__text": "Drivere de intrare/iesire MINOS"
						},
						{
							"_nr": "7",
							"__text": "Sistemul de gestiune a fisierelor MINOS"
						},
						{
							"_nr": "8",
							"__text": "Componentele sistemului de operare MINOS"
						},
						{
							"_nr": "9",
							"__text": "Compatibilitatea sistemului de operare cu alte sisteme similare"
						},
						{
							"_nr": "10",
							"__text": "Tehnici de programare utilizate in sistemul de operare MINOS"
						},
						{
							"_nr": "11",
							"__text": "Variantele sistemului de operare MINOS"
						},
						{
							"_nr": "12",
							"__text": "Versiunile sistemului de operare MINOS"
						},
						{
							"_nr": "13",
							"__text": "Aplicatiile sistemului de operare MINOS"
						},
						{
							"_nr": "14",
							"__text": "Documentatia sistemului de operare MINOS"
						}
					]
				},
				"_id": "119",
				"_isbn": ""
			},
			{
				"titlu": "Totul despre...BASIC in 14 conversatii si 7 sinteze - Volumul I",
				"autori": {
					"autor": {
						"_nume": "Liviu Dumitrascu"
					}
				},
				"anAparitie": "1989",
				"editura": "Tehnica",
				"numarPagini": "608",
				"pretOriginal": "48.5",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_Basic_in_14_conversatii_si_7_sinteze_I_Dumitrascu.jpg"
					},
					"__text": "Gheorghe Luchian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conversatia 1. Comenzi : RUN, LIST"
						},
						{
							"_nr": "2",
							"__text": "Conversatia 2. Comenzi : LET, PRINT, SCR, NEW"
						},
						{
							"_nr": "3",
							"__text": "Conversatia 3. Comenzi : INPUT, REM"
						},
						{
							"_nr": "4",
							"__text": "Conversatia 4. Comenzi : FOR-NEXT, PRINT USING"
						},
						{
							"_nr": "5",
							"__text": "Conversatia 5. Comenzi : READ, DATA, WHILE-WEND, IF-THEN, IF-THEN-ELSE, GOSUB, RETURN"
						},
						{
							"_nr": "6",
							"__text": "Conversatia 6. Comenzi : DEF FN, RANDOMIZE, PRINT AT"
						},
						{
							"_nr": "7",
							"__text": "Conversatia 7. Instructiuni BASIC"
						},
						{
							"_nr": "8",
							"__text": "Conversatia 8. Instructiuni : INKEY$, GET, ERASE, OPENIN, CLOSEIN, WRITE#, ON..GOTO, EOF"
						},
						{
							"_nr": "9",
							"__text": "Conversatia 9. Instructiuni : DIM, OPEN...AS VIRTUAL, CLOSE"
						},
						{
							"_nr": "10",
							"__text": "Conversatia 10. Depanarea unui program"
						},
						{
							"_nr": "11",
							"__text": "Conversatia 11. Functii standard pe siruri de caractere"
						},
						{
							"_nr": "12",
							"__text": "Conversatia 12. Instructiuni grafice"
						},
						{
							"_nr": "13",
							"__text": "Conversatia 13. Simboluri grafice in miscare"
						},
						{
							"_nr": "14",
							"__text": "Conversatia 14. Animatie"
						}
					]
				},
				"_id": "120",
				"_isbn": "973-31-0011-0"
			},
			{
				"titlu": "Totul despre...BASIC in 14 conversatii si 7 sinteze - Volumul II",
				"autori": {
					"autor": {
						"_nume": "Liviu Dumitrascu"
					}
				},
				"anAparitie": "1989",
				"editura": "Tehnica",
				"numarPagini": "360",
				"pretOriginal": "48.5",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_Basic_in_14_conversatii_si_7_sinteze_II_Dumitrascu.jpg"
					},
					"__text": "Gheorghe Luchian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sinteza 15. Cuvinte rezervate si diagnosticare...post mortem BASIC"
						},
						{
							"_nr": "2",
							"__text": "Sinteza 16. Mai mult decat un memento al limbajului BASIC-AMSTRAD"
						},
						{
							"_nr": "3",
							"__text": "Sinteza 17. Mai mult decat un memento al limbajului GW-BASIC de pe FELIX PC (IBM PC) sub MS-DOS"
						},
						{
							"_nr": "4",
							"__text": "Sinteza 18. Proiectarea asistata de calculator si reprezentari geomentrice in BASIC"
						},
						{
							"_nr": "5",
							"__text": "Sinteza 19. Jocuri. Elemente de proiectare si implementare. Cate ceva despre inteligenta artificiala. Programe sursa BASIC"
						},
						{
							"_nr": "6",
							"__text": "Sinteza 20. Programe pentru exemplele din conversatiile 1-14"
						},
						{
							"_nr": "7",
							"__text": "Sinteza 21. Complemente informatice"
						}
					]
				},
				"_id": "121",
				"_isbn": "973-31-0012-9"
			},
			{
				"titlu": "Programare structurata in BASIC - Culegere de probleme - Volumul I (editia II)",
				"autori": {
					"autor": [
						{
							"_nume": "Ioane Stan"
						},
						{
							"_nume": "Marinel Serban"
						},
						{
							"_nume": "Ovidiu Sandor"
						},
						{
							"_nume": "Herald Singer"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Libris",
				"tiraj": "3000",
				"numarPagini": "256",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Clara Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vajda Andras"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_structurata_in_Basic_Culegere_de_probleme_Stan.jpg"
					},
					"__text": "Sipos Laszlo"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare teoretica"
						},
						{
							"_nr": "2",
							"__text": "Enunturi"
						},
						{
							"_nr": "3",
							"__text": "Indicatii si solutii"
						}
					]
				},
				"_id": "122",
				"_isbn": "973-95118-2-1"
			},
			{
				"titlu": "Lotus 1.2.3",
				"autori": {
					"autor": [
						{
							"_nume": "Monica Stanescu"
						},
						{
							"_nume": "Valentin Trasnea"
						},
						{
							"_nume": "Ion Naftanaila"
						},
						{
							"_nume": "Calin Rechea"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "144",
				"pretOriginal": "1890",
				"colectia": {
					"_numarul": "18",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Lotus_1_2_3_Stanescu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale foii de calcul"
						},
						{
							"_nr": "2",
							"__text": "Exercitii aplicative - Partea I"
						},
						{
							"_nr": "3",
							"__text": "Exercitii aplicative - Partea II"
						},
						{
							"_nr": "4",
							"__text": "Sumar de operatii si comezi Lotus 1-2-3"
						},
						{
							"_nr": "5",
							"__text": "Lotus 1-2-3 Versiunea 2.0. Suplimentul de operatii si comenzii"
						},
						{
							"_nr": "6",
							"__text": "Organizarea foii de calcul"
						},
						{
							"_nr": "7",
							"__text": "Functii Lotus 1-2-3"
						},
						{
							"_nr": "8",
							"__text": "Grafice Lotus 1-2-3"
						},
						{
							"_nr": "9",
							"__text": "Sortarea datelor. Cautarea datelor. Functii pentru baza de date"
						},
						{
							"_nr": "10",
							"__text": "Macrocomenzi Lotus 1-2-3"
						},
						{
							"_nr": "11",
							"__text": "Cateva sugestii utile"
						}
					]
				},
				"_id": "123",
				"_isbn": "973-601-101-1"
			},
			{
				"titlu": "WordStar",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Alexandru Panoiu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "96",
				"pretOriginal": "350",
				"colectia": {
					"_numarul": "3",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "WordStar_Cristea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este WordStar"
						},
						{
							"_nr": "2",
							"__text": "Notiuni despre calculatorul personal (PC) strict necesare utilizatorului de WordStar"
						},
						{
							"_nr": "3",
							"__text": "Pregatirea unei sesiuni de lucru cu WordStar"
						},
						{
							"_nr": "4",
							"__text": "Editarea documentelor. Operatii uzuale"
						},
						{
							"_nr": "5",
							"__text": "Efecte speciale de tiparire"
						},
						{
							"_nr": "6",
							"__text": "Formatarea textelor"
						},
						{
							"_nr": "7",
							"__text": "Manevrarea blocurilor si fisierelor"
						},
						{
							"_nr": "8",
							"__text": "Imprimarea documentelor"
						},
						{
							"_nr": "9",
							"__text": "Comenzi cu punct"
						},
						{
							"_nr": "10",
							"__text": "WSCHANGE"
						}
					]
				},
				"_id": "124",
				"_isbn": "973-601-015-5"
			},
			{
				"titlu": "Word Perfect 5.1",
				"autori": {
					"autor": {
						"_nume": "Doina Zabulica"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "156",
				"pretOriginal": "995",
				"colectia": {
					"_numarul": "16",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Word_Perfect_5_1_Zabulica.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea procesorului de texte WORDPERFECT"
						},
						{
							"_nr": "2",
							"__text": "Facilitatea de interclasare"
						},
						{
							"_nr": "3",
							"__text": "Font-uri pentru efecte speciale"
						},
						{
							"_nr": "4",
							"__text": "Elemente esentiale in tiparirea la imprimanta"
						},
						{
							"_nr": "5",
							"__text": "Introducere in formatare"
						},
						{
							"_nr": "6",
							"__text": "Macro-urile usureaza lucrul"
						},
						{
							"_nr": "7",
							"__text": "Despre stiluri"
						},
						{
							"_nr": "8",
							"__text": "Facilitatea de cautare/inlocuire text"
						},
						{
							"_nr": "9",
							"__text": "Administrarea documentelor si a fisierelor"
						},
						{
							"_nr": "10",
							"__text": "Accesul la mai multe documente"
						},
						{
							"_nr": "11",
							"__text": "Analizatorul lexical si tezaurul"
						},
						{
							"_nr": "12",
							"__text": "Facilitatea de silabisire a cuvintelor"
						},
						{
							"_nr": "13",
							"__text": "Lucrul cu mai multe coloane de text"
						},
						{
							"_nr": "14",
							"__text": "Lucrul cu coloane matematice"
						}
					]
				},
				"_id": "125",
				"_isbn": "973-601-073-2"
			},
			{
				"titlu": "Norton Commander",
				"autori": {
					"autor": {
						"_nume": "Miorita Ilie"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "184",
				"pretOriginal": "3490",
				"colectia": {
					"_numarul": "26",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Norton_Commander_Manualul_incepatorului_Ilie.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive pe intelesul incepatorilor"
						},
						{
							"_nr": "2",
							"__text": "Instalarea utilitarului Norton Cammander"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu utilitarul Norton Commander"
						}
					]
				},
				"_id": "126",
				"_isbn": "973-601-127-5"
			},
			{
				"titlu": "MS-DOS 5.0",
				"autori": {
					"autor": {
						"_nume": "Alexandru Panoiu"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "160",
				"pretOriginal": "980",
				"colectia": {
					"_numarul": "12",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_5_0_Panoiu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bine ai venit in lumea DOS!"
						},
						{
							"_nr": "2",
							"__text": "Notiuni preliminare"
						},
						{
							"_nr": "3",
							"__text": "Componentele sistemului de operare DOS"
						},
						{
							"_nr": "4",
							"__text": "Activitati uzuale"
						},
						{
							"_nr": "5",
							"__text": "Comenzi DOS"
						},
						{
							"_nr": "6",
							"__text": "CONFIG.SYS"
						},
						{
							"_nr": "7",
							"__text": "Editorul de texte EDIT"
						},
						{
							"_nr": "8",
							"__text": "DOSSHELL"
						}
					]
				},
				"_id": "127",
				"_isbn": "973-601-061-9"
			},
			{
				"titlu": "Introducere in informatica",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Spircu"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "104",
				"pretOriginal": "550",
				"colectia": {
					"_numarul": "11",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_informatica_Spircu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Preliminarii"
						},
						{
							"_nr": "2",
							"__text": "Despre calculatoarele personale"
						},
						{
							"_nr": "3",
							"__text": "Despre sistemul de operare"
						},
						{
							"_nr": "4",
							"__text": "Despre programe"
						},
						{
							"_nr": "5",
							"__text": "Despre editare si baze de date"
						},
						{
							"_nr": "6",
							"__text": "Despre tabele"
						},
						{
							"_nr": "7",
							"__text": "Despre inteligenta artificiala"
						},
						{
							"_nr": "8",
							"__text": "Despre aplicatiile informaticii in medicina"
						}
					]
				},
				"_id": "128",
				"_isbn": "973-601-056-9"
			},
			{
				"titlu": "Windows 3.0",
				"autori": {
					"autor": {
						"_nume": "Florin Vladimit Pilat"
					}
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "160",
				"pretOriginal": "650",
				"colectia": {
					"_numarul": "8",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_3_0_Pilat.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea si modurile de functionare ale produsului Windows 3.0"
						},
						{
							"_nr": "2",
							"__text": "Bazele functionarii interfetei grafice Windows 3.0"
						},
						{
							"_nr": "3",
							"__text": "Lansarea in executie a programelor de aplicatie"
						},
						{
							"_nr": "4",
							"__text": "Crearea si folosirea grupurilor. Gestiunea programelor cu componenta program MANAGER"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea fisierelor cu FILE MANAGER. Gestiunea Task-urilor"
						},
						{
							"_nr": "6",
							"__text": "Configurarea cu modulul de instalare si personalizarea sistemului"
						},
						{
							"_nr": "7",
							"__text": "Instalarea unei imprimante. Gestiunea imprimarii cu PRINT MANAGER"
						},
						{
							"_nr": "8",
							"__text": "Aplicatia TERMINAL"
						},
						{
							"_nr": "9",
							"__text": "Aplicatiile CLIPBOARD si PAINTBRUSH"
						},
						{
							"_nr": "10",
							"__text": "Aplicatia WRITE. Alte accesorii Windows"
						},
						{
							"_nr": "11",
							"__text": "Optimizarea folosirii aplicatiilor sub Windows cu ajutorul fisierelor .PIF"
						},
						{
							"_nr": "12",
							"__text": "Optimizarea sistemului pentru lucrul cu Windows"
						}
					]
				},
				"_id": "129",
				"_isbn": "973-601-043-0"
			},
			{
				"titlu": "Corel Draw!",
				"autori": {
					"autor": [
						{
							"_nume": "Tiberiu Spircu"
						},
						{
							"_nume": "Claudia Spircu"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "128",
				"pretOriginal": "1990",
				"colectia": {
					"_numarul": "24",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Corel_Draw_Spircu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Preliminarii"
						},
						{
							"_nr": "2",
							"__text": "Instrumentele si ecranul"
						},
						{
							"_nr": "3",
							"__text": "Creionul"
						},
						{
							"_nr": "4",
							"__text": "Dreptunghiul si Elipsa"
						},
						{
							"_nr": "5",
							"__text": "Conturatorul si Vopsitorul"
						},
						{
							"_nr": "6",
							"__text": "Lupa"
						},
						{
							"_nr": "7",
							"__text": "Textul"
						},
						{
							"_nr": "8",
							"__text": "Selectorul"
						},
						{
							"_nr": "9",
							"__text": "Modelatorul"
						},
						{
							"_nr": "10",
							"__text": "Transformari geometrice"
						},
						{
							"_nr": "11",
							"__text": "Din nou depsre ecran"
						},
						{
							"_nr": "12",
							"__text": "Despre editare"
						},
						{
							"_nr": "13",
							"__text": "Despre efectele speciale"
						},
						{
							"_nr": "14",
							"__text": "Despre grupare si combinare"
						},
						{
							"_nr": "15",
							"__text": "Despre texte"
						},
						{
							"_nr": "16",
							"__text": "Despre preferinte, bitmap-uri si sageti"
						},
						{
							"_nr": "17",
							"__text": "Despre fisiere, import si export"
						},
						{
							"_nr": "18",
							"__text": "Crearea unor efecte speciale"
						},
						{
							"_nr": "19",
							"__text": "Despre trasare si biblioteci de imagini"
						}
					]
				},
				"_id": "130",
				"_isbn": "973-601-120"
			},
			{
				"titlu": "Totul despre...Windows 3.1",
				"autori": {
					"autor": {
						"_nume": "Doru Turturea"
					}
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "344",
				"pretOriginal": "1500",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_Windows_3_1_Tuturea.jpg"
					},
					"__text": "Ileana Timotin"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De ce Windows?"
						},
						{
							"_nr": "2",
							"__text": "Prezentare generala Windows 3.1"
						},
						{
							"_nr": "3",
							"__text": "Elementele sistemului Windows"
						},
						{
							"_nr": "4",
							"__text": "Notiuni de baza despre aplicatii"
						},
						{
							"_nr": "5",
							"__text": "Program MANAGER"
						},
						{
							"_nr": "6",
							"__text": "Programul FILE MANAGER"
						},
						{
							"_nr": "7",
							"__text": "Programul CONTROL PANEL"
						},
						{
							"_nr": "8",
							"__text": "Programul PIF EDITOR"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii de tip NON-WINDOWS"
						},
						{
							"_nr": "10",
							"__text": "Accesoriile sistemului Windows"
						},
						{
							"_nr": "11",
							"__text": "Integrarea aplicatiilor de tip Windows (OLE)"
						},
						{
							"_nr": "12",
							"__text": "Optimizarea sistemului Windows 3.1"
						},
						{
							"_nr": "13",
							"__text": "Winbdow 3.1 in retele locale de tip NETWARE 3.11"
						},
						{
							"_nr": "14",
							"__text": "Aplicatii de tip Windows"
						}
					]
				},
				"_id": "131",
				"_isbn": "973-31-0525-8"
			},
			{
				"titlu": "PC 386 - o noua generatie de calculatoare personale",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Samoila"
					}
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "152",
				"pretOriginal": "225",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Daniela Raduly"
				},
				"coperta": {
					"imagine": {
						"_path": "PC_386_o_noua_generatie_de_calculatoare_personale_Samoila.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "80386, rezultatul unei evolutii tehnologice"
						},
						{
							"_nr": "3",
							"__text": "Calculatoare personale 80386"
						},
						{
							"_nr": "4",
							"__text": "Capete de serie pe piata calculatoare 386"
						},
						{
							"_nr": "5",
							"__text": "Software pentru 386"
						},
						{
							"_nr": "6",
							"__text": "Microprocesorul 80386"
						},
						{
							"_nr": "7",
							"__text": "Evolutii in domeniu"
						},
						{
							"_nr": "8",
							"__text": "PC 386 pe piata romaneasca"
						}
					]
				},
				"_id": "132",
				"_isbn": "973-31-0420-5"
			},
			{
				"titlu": "Internet. Posta electronica standard",
				"autori": {
					"autor": {
						"_nume": "Ionela Iorgulescu"
					}
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "164",
				"pretOriginal": "4500",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Internet_posta_electronica_standard_Iorgulescu.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "2",
							"__text": "O sesiune simpla de posta electronica"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea avansata a postei electronice"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea postei electronice de catre servicii INTERNET"
						},
						{
							"_nr": "5",
							"__text": "Securitatea informatiei transferate prin posta electronica"
						},
						{
							"_nr": "6",
							"__text": "Probleme ale utilizarii postei electronice"
						}
					]
				},
				"_id": "133",
				"_isbn": "973-31-0737-9"
			},
			{
				"titlu": "LOGO - o noua metoda de a invata cu ajutorul calculatorului",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Gheorghe Vass"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Acific",
				"numarPagini": "72",
				"pretOriginal": "43",
				"coperta": {
					"imagine": {
						"_path": "LOGO_o_nou_metoda_de_a_invata_cu_ajutorul_calculatorului_Vass.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea, tastatura si ecranul"
						},
						{
							"_nr": "2",
							"__text": "Broasca testoasa"
						},
						{
							"_nr": "3",
							"__text": "Proceduri"
						},
						{
							"_nr": "4",
							"__text": "Variabile"
						},
						{
							"_nr": "5",
							"__text": "Actiuni conditionate"
						},
						{
							"_nr": "6",
							"__text": "Recursivitatea"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea coordonatelor"
						},
						{
							"_nr": "8",
							"__text": "Alte obiecte LOGO"
						},
						{
							"_nr": "9",
							"__text": "Probleme scolare"
						},
						{
							"_nr": "10",
							"__text": "Metode grafice decorative"
						},
						{
							"_nr": "11",
							"__text": "Jocuri"
						},
						{
							"_nr": "12",
							"__text": "Memorator LOGO"
						}
					]
				},
				"_id": "134",
				"_isbn": "973-95036-1-6"
			},
			{
				"titlu": "GW-BASIC",
				"autori": {
					"autor": {
						"_nume": "Octavian Dogaru"
					}
				},
				"anAparitie": "1998",
				"editura": "Editura de Vest",
				"numarPagini": "288",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ion Ilin"
				},
				"coperta": {
					"imagine": {
						"_path": "GW_BASIC_Dogaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Operare GW-BASIC"
						},
						{
							"_nr": "3",
							"__text": "Gramatica Limbajului GW-BASIC"
						},
						{
							"_nr": "4",
							"__text": "Modul de operare indirect"
						},
						{
							"_nr": "5",
							"__text": "Calcule de baza si instructiuni de intrare/iesire"
						},
						{
							"_nr": "6",
							"__text": "Structuri cu ramificare"
						},
						{
							"_nr": "7",
							"__text": "Structuri repetitive"
						},
						{
							"_nr": "8",
							"__text": "Numere aleatoare"
						},
						{
							"_nr": "9",
							"__text": "Tablouri (ARRAY)"
						},
						{
							"_nr": "10",
							"__text": "Functii si subrutine"
						},
						{
							"_nr": "11",
							"__text": "Inlatuirea programelor"
						},
						{
							"_nr": "12",
							"__text": "Fisiere GW-BASIC"
						},
						{
							"_nr": "13",
							"__text": "Redarea sunetelor in GW-BASIC"
						},
						{
							"_nr": "14",
							"__text": "Grafica in GW-BASIC"
						},
						{
							"_nr": "15",
							"__text": "Dispozitive speciale. Comunicatii"
						},
						{
							"_nr": "16",
							"__text": "Pointeri"
						},
						{
							"_nr": "17",
							"__text": "Utilizare subrutinelor in limbaj de asamblare"
						},
						{
							"_nr": "18",
							"__text": "Formatul general al liniei de comanda GW-BASIC"
						}
					]
				},
				"_id": "135",
				"_isbn": "973-36-0309-0"
			},
			{
				"titlu": "dBase IV v1.5 - Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Lucian Vasiu"
					}
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "160",
				"pretOriginal": "450",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "dBase_IV_v_1_5_Ghid_de_utilizare_Vasiu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ghid de utilizare dBase IV v1.5"
						},
						{
							"_nr": "II",
							"__text": "Exercitii si aplicatii"
						}
					]
				},
				"_id": "136",
				"_isbn": "973-31-0506-6"
			},
			{
				"titlu": "Paradox. Ghid de utlizare ...si nu doar atat",
				"autori": {
					"autor": {
						"_nume": "Vasile Petrovici"
					}
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "104",
				"pretOriginal": "210",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"coperta": {
					"imagine": {
						"_path": "Paradox_Ghid_de_utilizare_si_nu_doar_atat_Petrovici.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea modului de lucru imediat"
						},
						{
							"_nr": "2",
							"__text": "Functii"
						},
						{
							"_nr": "3",
							"__text": "Comenzi"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea comezilor si functiilor intr-o aplicatie"
						}
					]
				},
				"_id": "137",
				"_isbn": "973-31-0404-3"
			},
			{
				"titlu": "Aplicatii in QBasic",
				"autori": {
					"autor": {
						"_nume": "Lucian Vasiu"
					}
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "144",
				"pretOriginal": "3000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Microsoft"
				},
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_in_QBasic_Vasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "MS-DOS QBASIC - Ghid de utilizare"
						},
						{
							"_nr": "2",
							"__text": "Aplicatii si exercitii de programare"
						}
					]
				},
				"_id": "138",
				"_isbn": "973-31-0611-9"
			},
			{
				"titlu": "Fox Pro 2.5",
				"autori": {
					"autor": {
						"_nume": "Lucian Vasiu"
					}
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "260",
				"pretOriginal": "4000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Microsoft"
				},
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Fox_Pro_2_5_Vasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare generala"
						},
						{
							"_nr": "2",
							"__text": "Interfata FoxPro 2.5"
						},
						{
							"_nr": "3",
							"__text": "Programarea in FoxPro 2.5"
						}
					]
				},
				"_id": "139",
				"_isbn": "973-31-0611-9"
			},
			{
				"titlu": "Norton Commander 4.0 - Norton Utilities 7.0 si 8.0",
				"autori": {
					"autor": [
						{
							"_nume": "Florentina Hristea"
						},
						{
							"_nume": "Denis Enachescu"
						},
						{
							"_nume": "Razvan Balint"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "432",
				"pretOriginal": "6000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Norton_Commander_4_0_Norton_Utilities_7_0_si_8_0.jpg"
					},
					"__text": "Simona Dumitrescu, Silviu Andrei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Norton Commander 4.0"
						},
						{
							"_nr": "2",
							"__text": "Structura discului"
						},
						{
							"_nr": "3",
							"__text": "Utilitarele NORTON"
						},
						{
							"_nr": "4",
							"__text": "Refacerea informatiilor"
						},
						{
							"_nr": "5",
							"__text": "Securtiatea informatiilor"
						},
						{
							"_nr": "6",
							"__text": "Optimizarea accesului la informatii"
						},
						{
							"_nr": "7",
							"__text": "Noton Tools"
						},
						{
							"_nr": "8",
							"__text": "Depanarea software a discului"
						},
						{
							"_nr": "9",
							"__text": "Sistemul NDOS"
						},
						{
							"_nr": "10",
							"__text": "Utilitare pentru Windows"
						}
					]
				},
				"_id": "140",
				"_isbn": "973-31-0658-5"
			},
			{
				"titlu": "Initiere in structura calculatoarelor electronice",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Francisc Iacob"
						},
						{
							"_nume": "Zoea Racovita"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "152",
				"pretOriginal": "7500",
				"colectia": {
					"_numarul": "85",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_structura_calculatoarelor_electronice_Petrescu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea informatiei in calculator"
						},
						{
							"_nr": "3",
							"__text": "Functii si circuite logice"
						},
						{
							"_nr": "4",
							"__text": "Unitatile functionale ale unui calculator secvential"
						},
						{
							"_nr": "5",
							"__text": "Arhitectura procesorului"
						},
						{
							"_nr": "6",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "7",
							"__text": "Sisteme performante de calcul"
						}
					]
				},
				"_id": "141",
				"_isbn": "973-601-297-2"
			},
			{
				"titlu": "Electronica digitala",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Toacse"
						},
						{
							"_nume": "Dan Nicula"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "624",
				"pretOriginal": "25000",
				"colectia": {
					"_numarul": "4",
					"_nume": "Electronica profesionala"
				},
				"coperta": {
					"imagine": {
						"_path": "Electronica_digitala_Toacse.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Circuite integrale digitale"
						},
						{
							"_nr": "II",
							"__text": "Limbajul de descriere hardware VHDL"
						}
					]
				},
				"_id": "142",
				"_isbn": "973-601-254-9"
			},
			{
				"titlu": "MS-DOS 6.2 (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Jennifer Fulton"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "192",
				"pretOriginal": "5000",
				"colectia": {
					"_numarul": "34",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 minute guid to MS-DOS 6.2",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "SUA"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_6_2_in_lectii_de_10_minute_Fulton.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Un prim contact cu sistemul de operare DOS"
						},
						{
							"_nr": "2",
							"__text": "Ce sunt discurile, cataloagele si fisierele"
						},
						{
							"_nr": "3",
							"__text": "Introducerea comezilor DOS"
						},
						{
							"_nr": "4",
							"__text": "Artificii pnetru introducerea comenzilor"
						},
						{
							"_nr": "5",
							"__text": "O mana de ajutor"
						},
						{
							"_nr": "6",
							"__text": "Listarea fisierelor"
						},
						{
							"_nr": "7",
							"__text": "Copierea si mutarea fisierelor"
						},
						{
							"_nr": "8",
							"__text": "Stergerea fisierelor si recuperarea lor"
						},
						{
							"_nr": "9",
							"__text": "Lucrul cu cataloage"
						},
						{
							"_nr": "10",
							"__text": "Formatarea dischetelor"
						},
						{
							"_nr": "11",
							"__text": "Alte operatii cu dischete"
						},
						{
							"_nr": "12",
							"__text": "Dublarea spatiului pe disc cu DoubleScace"
						},
						{
							"_nr": "13",
							"__text": "Alte operatii"
						},
						{
							"_nr": "14",
							"__text": "Defragmentarea unei unitati"
						},
						{
							"_nr": "15",
							"__text": "O privire generala"
						},
						{
							"_nr": "16",
							"__text": "Restaurarea discului"
						},
						{
							"_nr": "17",
							"__text": "Protejarea sistemului importriva virusilor"
						},
						{
							"_nr": "18",
							"__text": "Optimizarea memoriei"
						},
						{
							"_nr": "19",
							"__text": "Editara unui fisier cu editorul DOS"
						},
						{
							"_nr": "20",
							"__text": "Configurarea sistemului dumneavoastra"
						},
						{
							"_nr": "21",
							"__text": "Definirea configuratiilor multiple ale sistemului"
						}
					]
				},
				"_id": "143",
				"_isbn": "973-601-175-5"
			},
			{
				"titlu": "Sisteme de operare in retea NOVELL - NETWARE 2.15",
				"autori": {
					"autor": {
						"_nume": "Razvan Orbu"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "224",
				"pretOriginal": "460",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_in_retea_Novell_Netware_2_15_Orbu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Universul NOVELL"
						},
						{
							"_nr": "2",
							"__text": "Protectia datelor sub sitemul de operare in retea NETWARE 286"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de tranzactii"
						},
						{
							"_nr": "4",
							"__text": "Versiuni de sistem de operare in retea"
						},
						{
							"_nr": "5",
							"__text": "Structura sistemului de operare in retea"
						},
						{
							"_nr": "6",
							"__text": "Tipuri de utilizatori de retea"
						},
						{
							"_nr": "7",
							"__text": "Securitatea datelor in retea"
						},
						{
							"_nr": "8",
							"__text": "Structura de directoare a FILE SERVER-ului"
						},
						{
							"_nr": "9",
							"__text": "Comenzi si procese utilizator"
						},
						{
							"_nr": "10",
							"__text": "Utilitare NETWARE"
						},
						{
							"_nr": "11",
							"__text": "Detalii privind modulul LOGIN SCRIPT"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea comenzilor MS-DOS in retea"
						},
						{
							"_nr": "13",
							"__text": "Ghidul supervizorului"
						},
						{
							"_nr": "14",
							"__text": "Comenzi consola"
						},
						{
							"_nr": "15",
							"__text": "Utilitarul FCONSOLE"
						},
						{
							"_nr": "16",
							"__text": "Intretinerea retelei NOVELL"
						},
						{
							"_nr": "17",
							"__text": "Orientari in universul NOVELL"
						},
						{
							"_nr": "18",
							"__text": "Concepte de lucru la nivelul programelor de retea"
						},
						{
							"_nr": "19",
							"__text": "BTRIEVE"
						}
					]
				},
				"_id": "144",
				"_isbn": "973-31-0504-X"
			},
			{
				"titlu": "MathCAD. Prezentare si probleme rezolvate",
				"autori": {
					"autor": [
						{
							"_nume": "Ernest Scheiber"
						},
						{
							"_nume": "Dorin Lixandroiu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "128",
				"pretOriginal": "2200",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "MathCAD_Prezentare_si_probleme_rezolvate_Scheiber.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "MathCAD. Prezentare generala"
						},
						{
							"_nr": "2",
							"__text": "Aplicatii numerice rezolvate cu MathCAD"
						},
						{
							"_nr": "3",
							"__text": "Probleme rezolvate cu MathCAD"
						}
					]
				},
				"_id": "145",
				"_isbn": "973-31-0615-1"
			},
			{
				"titlu": "ORCAD III",
				"autori": {
					"autor": [
						{
							"_nume": "Andrei Campeanu"
						},
						{
							"_nume": "Ioan Jivet"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "264",
				"pretOriginal": "5990",
				"colectia": {
					"_numarul": "31",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "ORCAD_Campeanu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Prezentare generala a pachetului de programe OrCAD"
						},
						{
							"_nr": "II",
							"__text": "Pachetul de programe OrCAD/SDT"
						},
						{
							"_nr": "III",
							"__text": "OrCAD/VST Programe de simulare logica"
						},
						{
							"_nr": "IV",
							"__text": "OrCAD/PCB - Editarea cablajelor imprimate"
						},
						{
							"_nr": "V",
							"__text": "Practici curente si eficienta in OrCAD/SDT"
						},
						{
							"_nr": "VI",
							"__text": "Aplicatia PRESCALER"
						}
					]
				},
				"_id": "146",
				"_isbn": "973-601-165-9"
			},
			{
				"titlu": "AutoCAD 11",
				"autori": {
					"autor": [
						{
							"_nume": "Adriana Popescu"
						},
						{
							"_nume": "Aurelia Filip"
						},
						{
							"_nume": "Daniel Merezeanu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "240",
				"pretOriginal": "3990",
				"colectia": {
					"_numarul": "25",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "AutoCAD_11_Popescu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "AutoCAD? Nimic mai simplu"
						},
						{
							"_nr": "2",
							"__text": "Introducere in AutoCAD"
						},
						{
							"_nr": "3",
							"__text": "Desenarea entitatilor grafice fara latime"
						},
						{
							"_nr": "4",
							"__text": "Comenzi de afisare"
						},
						{
							"_nr": "5",
							"__text": "Ajutoare grafice"
						},
						{
							"_nr": "6",
							"__text": "Stergerea. copierea si deplasarea entitatilor"
						},
						{
							"_nr": "7",
							"__text": "Polilinii si alte obiecte \"pline\""
						},
						{
							"_nr": "8",
							"__text": "Comenzi de editare"
						},
						{
							"_nr": "9",
							"__text": "Comenzi de informare"
						},
						{
							"_nr": "10",
							"__text": "Entitati de tip text"
						},
						{
							"_nr": "11",
							"__text": "Proprietati ale entitatilor. Structurarea desenelor"
						},
						{
							"_nr": "12",
							"__text": "Cotarea desenelor"
						},
						{
							"_nr": "13",
							"__text": "Reprezentarea obiectelor tridimesionale"
						},
						{
							"_nr": "14",
							"__text": "De pe ecran...pe hartie"
						},
						{
							"_nr": "15",
							"__text": "Utilizarea AutoCAD pentru prezentari"
						},
						{
							"_nr": "16",
							"__text": "Comenzi utilitare"
						}
					]
				},
				"_id": "147",
				"_isbn": "973-601-126-7"
			},
			{
				"titlu": "Secretele sistemului FoxPro 2.5 pentru DOS",
				"autori": {
					"autor": {
						"_nume": "Bob Grommes"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "992",
				"pretOriginal": "40000",
				"colectia": {
					"_numarul": "87",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Inside FoxPro 2.5 for DOS",
					"editura": "New Riders Publishing",
					"traducatori": {
						"traducator": [
							"Gabriel Dima",
							"Mihai Dima",
							"Ioana Dima",
							"Bogdan Apostol"
						]
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "SUA"
				},
				"redactori": {
					"redactor": "Mihaela Tarpa"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Dima"
				},
				"coperta": {
					"imagine": {
						"_path": "Secretele_sistemului_FoxPro_2_5_pentru_DOS_Grommes.jpg"
					},
					"__text": "Mihai Nasta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "FoxPro 2.5 privire generala"
						},
						{
							"_nr": "II",
							"__text": "Construirea si utilizarea bazelor de date FoxPro"
						},
						{
							"_nr": "III",
							"__text": "Programarea aplicatiilor FoxPro"
						},
						{
							"_nr": "IV",
							"__text": "Programarea condusa de evenimente"
						},
						{
							"_nr": "V",
							"__text": "Mediul de dezvoltare a aplicatiilor FoxPro"
						},
						{
							"_nr": "VI",
							"__text": "SQL in FoxPro"
						},
						{
							"_nr": "VII",
							"__text": "Extinderea limbajului cu produse suplimentare"
						}
					]
				},
				"_id": "148",
				"_isbn": "973-601-304-9"
			},
			{
				"titlu": "FoxPro 2.5, 2.6 sub DOS",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriel Dima"
						},
						{
							"_nume": "Mihai Dima"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "544",
				"pretOriginal": "16900",
				"colectia": {
					"_numarul": "77",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "FoxPro_2_5_2_6_sub_DOS_Dima.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Lucrul cu tabele prin intermediul interfetei sistemului"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "4",
							"__text": "Variabile si masive"
						},
						{
							"_nr": "5",
							"__text": "Baze de date - Limbaj de programare"
						},
						{
							"_nr": "6",
							"__text": "Intrare/Iesire"
						},
						{
							"_nr": "7",
							"__text": "Ferestre si meniuri"
						},
						{
							"_nr": "8",
							"__text": "Programe"
						},
						{
							"_nr": "9",
							"__text": "Generatoare"
						},
						{
							"_nr": "10",
							"__text": "Facilitati ale versiunii 2.6 a FoxPro-ului"
						},
						{
							"_nr": "11",
							"__text": "Alte elemente ale mediului FoxPro"
						}
					]
				},
				"_id": "149",
				"_isbn": "973-601-276-X"
			},
			{
				"titlu": "AutoCAD 12",
				"autori": {
					"autor": [
						{
							"_nume": "David S. Cohn"
						},
						{
							"_nume": "Nancy Fulton"
						},
						{
							"_nume": "Richard Halle"
						},
						{
							"_nume": "Mike Morrison"
						},
						{
							"_nume": "Tim Ryan"
						},
						{
							"_nume": "Peter K. Sheerin"
						},
						{
							"_nume": "Craig W. Sharp"
						},
						{
							"_nume": "Richard Whobrey"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "768",
				"pretOriginal": "25000",
				"traducere": {
					"titluOriginal": "Using AutoCAD - Release 12",
					"editura": "QUE Corporation",
					"traducatori": {
						"traducator": [
							"Marius Dinescu",
							"Adrian Borlan",
							"Roberto Iosupescu",
							"Mihai Nastasescu",
							"Iulian Ciulinet"
						]
					},
					"_an": "1992",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "SUA"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marinela Tupilat"
				},
				"coperta": {
					"imagine": {
						"_path": "AutoCAD_12_Cohn.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente fundamentale in AutoCAD"
						},
						{
							"_nr": "II",
							"__text": "Tehnici de baza in AutoCAD"
						},
						{
							"_nr": "III",
							"__text": "Tehnici avansate de lucru in AutoCAD"
						},
						{
							"_nr": "IV",
							"__text": "Desenarea in 3 dimensiuni (3D)"
						},
						{
							"_nr": "V",
							"__text": "Ambientarea si utilizarea in retea a AutoCAD-ului"
						},
						{
							"_nr": "VI",
							"__text": "Referinte complete"
						}
					]
				},
				"_id": "150",
				"_isbn": "973-601-195-X"
			},
			{
				"titlu": "AMC 49",
				"autori": {
					"autor": [
						{
							"_nume": "C. Farber"
						},
						{
							"_nume": "H. Yoshikawa"
						},
						{
							"_nume": "R. Radner"
						},
						{
							"_nume": "V.A. Viktorov"
						},
						{
							"_nume": "V.N. Novoseltev"
						},
						{
							"_nume": "V.I. Sumakov"
						},
						{
							"_nume": "P. Kovanic"
						},
						{
							"_nume": "A. Bekessy"
						},
						{
							"_nume": "J. Demetrovics"
						},
						{
							"_nume": "I. Horvath Gaudi"
						},
						{
							"_nume": "L. Hannek"
						},
						{
							"_nume": "G, Buvar"
						},
						{
							"_nume": "Cs. Balogh"
						},
						{
							"_nume": "G. Gramov"
						},
						{
							"_nume": "T. Geber"
						},
						{
							"_nume": "E. Miclescu"
						},
						{
							"_nume": "G. Manolescu"
						},
						{
							"_nume": "A. Varga"
						},
						{
							"_nume": "A. Davidoviciu"
						},
						{
							"_nume": "A. Petrescu"
						},
						{
							"_nume": "N. Tapus"
						},
						{
							"_nume": "Fl. Tanase"
						},
						{
							"_nume": "T. Domocos"
						},
						{
							"_nume": "G. Draghicescu"
						},
						{
							"_nume": "A. Negulescu"
						},
						{
							"_nume": "Fr. Iabob"
						},
						{
							"_nume": "T. Moisa"
						},
						{
							"_nume": "I. Atanasiu"
						},
						{
							"_nume": "D. Mihu"
						},
						{
							"_nume": "A. Anghel"
						},
						{
							"_nume": "C. Alupului"
						},
						{
							"_nume": "O. Borozan"
						},
						{
							"_nume": "M. Constantinescu"
						},
						{
							"_nume": "A. Lazar"
						},
						{
							"_nume": "D. Trifanescu"
						},
						{
							"_nume": "M. Draghici"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "336",
				"pretOriginal": "26",
				"colectia": {
					"_numarul": "49",
					"_nume": "AMC"
				},
				"traducere": {
					"traducatori": {
						"traducator": [
							"Adrian Davidoviciu",
							"Vasile Sima",
							"Radu Magda",
							"Marcel Sarbu",
							"Aristide Predoi",
							"Sandu Lazar",
							"Dan Dobrescu",
							"Alexandra Tatu",
							"Matei Constantin",
							"Doina Tudor",
							"Nicolae Tudorancescu",
							"Ilie Paiusi",
							"Cornelia Basarab",
							"Dan Radulescu",
							"Virgil Micula",
							"Serban Sporea",
							"Radu Manolescu",
							"Nicolae Algiu",
							"Leonard Stoian Horobat",
							"Gabriel Spiridon",
							"Eust. Stanciu",
							"Serban Lupescu"
						]
					},
					"_an": "",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Traznea"
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_49_Tehnica.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "P3. Conducerea proceselor tehnologice cu calculatorul"
						},
						{
							"_nr": "2",
							"__text": "P4. Sistem de fabricatie flexibila in Japonia"
						},
						{
							"_nr": "3",
							"__text": "P5. Jocuri de intreprindere; stimulare si control"
						},
						{
							"_nr": "4",
							"__text": "P6. Reglarea automata in si pentru biosisteme"
						},
						{
							"_nr": "5",
							"__text": "C.S.6. Fundamentare pentru estimare"
						},
						{
							"_nr": "6",
							"__text": "C.S.4. Aplicatii ale calculatoarelor in agricultura R.P.U"
						},
						{
							"_nr": "7",
							"__text": "Resurse informationale nationale"
						},
						{
							"_nr": "8",
							"__text": "Minicalculatoare CORAL si INDEPENDENT (partea a II-a)"
						},
						{
							"_nr": "9",
							"__text": "Consfatuire nationala C.P.A.C. '84"
						},
						{
							"_nr": "10",
							"__text": "P.A.C. Arhitectura prototip"
						},
						{
							"_nr": "11",
							"__text": "CASAD - Pachet de programe pentru P.A.C."
						},
						{
							"_nr": "12",
							"__text": "Microprocesorul personal-profesional FELIX-PC"
						},
						{
							"_nr": "13",
							"__text": "Microprocesorul personal HC-80"
						},
						{
							"_nr": "14",
							"__text": "Memento de teleprelucrare (partea a II-a)"
						},
						{
							"_nr": "15",
							"__text": "Feriti-va de capcane in analiza de sistem"
						},
						{
							"_nr": "16",
							"__text": "Baze de date. Proiectul RA"
						}
					]
				},
				"_id": "151",
				"_isbn": ""
			},
			{
				"titlu": "Inteligenta artificiala",
				"autori": {
					"autor": {
						"_nume": "Patrik H. Winston"
					}
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "420",
				"pretOriginal": "34",
				"colectia": {
					"_numarul": "0",
					"_nume": "Biblioteca de automatica, informatica, electronica, management"
				},
				"traducere": {
					"editura": "Addison-Wesley Publishing Company, Inc",
					"traducatori": {
						"traducator": [
							"Ioan Georgescu",
							"Florian Petrescu",
							"Mihaela Malita",
							"Rolanda PRedescu"
						]
					},
					"_an": "1977",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_Winston.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatoul inteligent"
						},
						{
							"_nr": "2",
							"__text": "Recunoasterea analogiilor si invatarea conceptelor simple"
						},
						{
							"_nr": "3",
							"__text": "Valorificarea (exploatarea) restrictiilor naturale"
						},
						{
							"_nr": "4",
							"__text": "Explorarea alternativelor"
						},
						{
							"_nr": "5",
							"__text": "Probleme de control"
						},
						{
							"_nr": "6",
							"__text": "Semnificatia semnificatiei"
						},
						{
							"_nr": "7",
							"__text": "Reprezentarea cunoasterii prin cadre"
						},
						{
							"_nr": "8",
							"__text": "Puncte de vedere asupra perceptiei vizuale a calculatoarelor"
						},
						{
							"_nr": "9",
							"__text": "Ingineria cunoasterii si perfectionarii gandirii"
						},
						{
							"_nr": "10",
							"__text": "Intelegerea programarii"
						},
						{
							"_nr": "11",
							"__text": "Elemente de baza ale programarii in limbajul LISP"
						},
						{
							"_nr": "12",
							"__text": "Lumea de cuburi"
						},
						{
							"_nr": "13",
							"__text": "Universul jocurilor"
						},
						{
							"_nr": "14",
							"__text": "Corescpondenta cu prototipurile simbolice"
						},
						{
							"_nr": "15",
							"__text": "Implementarea limbajelor in LISP"
						},
						{
							"_nr": "16",
							"__text": "Baze de date si demoni"
						},
						{
							"_nr": "17",
							"__text": "Probleme propuse spre rezolvare"
						}
					]
				},
				"_id": "152",
				"_isbn": ""
			},
			{
				"titlu": "Informatica. Caiet de laborator [IX]",
				"autori": {
					"autor": {
						"_nume": "Carmen Minca"
					}
				},
				"anAparitie": "2005",
				"editura": "L and S InfoMat",
				"numarPagini": "96",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Caiet_de_laborator_Minca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni generale despre algoritmi"
						},
						{
							"_nr": "II",
							"__text": "Principiile progamarii structurate"
						}
					]
				},
				"_id": "153",
				"_isbn": "973-7658-01-9"
			},
			{
				"titlu": "Tehnici de programare [X]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1996",
				"editura": "L and S InfoMat",
				"numarPagini": "304",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_Manual_X_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metoda backtracking"
						},
						{
							"_nr": "2",
							"__text": "Introducere in recursivitate"
						},
						{
							"_nr": "3",
							"__text": "Backtraking recursiv"
						},
						{
							"_nr": "4",
							"__text": "Divide et impera"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii ale recursivitatii"
						},
						{
							"_nr": "6",
							"__text": "Analiza timpului de calcul necesar algoritmilor"
						},
						{
							"_nr": "7",
							"__text": "Tehnica Greedy"
						},
						{
							"_nr": "8",
							"__text": "Structuri de date"
						},
						{
							"_nr": "9",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "10",
							"__text": "Tehnica Branch and Bound"
						},
						{
							"_nr": "11",
							"__text": "Elemente de teoria grafurilor"
						}
					]
				},
				"_id": "154",
				"_isbn": "973-96382-3-6"
			},
			{
				"titlu": "Teste de sinteza in programare",
				"autori": {
					"autor": [
						{
							"_nume": "Daniela Oprescu"
						},
						{
							"_nume": "Cecilia Balanescu"
						},
						{
							"_nume": "Rodica Pintea"
						},
						{
							"_nume": "Dan Grigoriu"
						},
						{
							"_nume": "Anca Voicu"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "L and S InfoMat",
				"numarPagini": "192",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Teste_de_sinteza_in_programare_Pintea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Pascal si C++"
						},
						{
							"_nr": "II",
							"__text": "FoxPro"
						}
					]
				},
				"_id": "155",
				"_isbn": "973-98770-5-2"
			},
			{
				"titlu": "Psihologia concursurilor de informatica",
				"autori": {
					"autor": {
						"_nume": "Catalin Francu"
					}
				},
				"anAparitie": "1997",
				"editura": "L and S InfoMat",
				"numarPagini": "192",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Phihologia_concursurilor_de_informatica_Francu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Concursul de informatica"
						},
						{
							"_nr": "II",
							"__text": "Lucrul cu numere mari"
						},
						{
							"_nr": "III",
							"__text": "Lucrul cu structuri mari de date"
						},
						{
							"_nr": "IV",
							"__text": "Heap-uri si tabele de dispersie"
						},
						{
							"_nr": "V",
							"__text": "Despre algoritmi exponentiali si imbunatatirea lor"
						},
						{
							"_nr": "VI",
							"__text": "Programe de concurs"
						}
					]
				},
				"_id": "156",
				"_isbn": "973-96382-8-7"
			},
			{
				"titlu": "Sisteme de calcul [IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Emanuela Cerchez"
						},
						{
							"_nume": "Marinel Serban"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "L and S InfoMat",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_calcul_IX_Cerchez.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura generala a unui microcalculator"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "4",
							"__text": "Programe utiliare"
						}
					]
				},
				"_id": "157",
				"_isbn": "973-98770-0-1"
			},
			{
				"titlu": "Bacalaureat in informatica. Notiuni recapitulative. Teze rezvoltate si probleme propuse",
				"autori": {
					"autor": [
						{
							"_nume": "Maria Codrina"
						},
						{
							"_nume": "Dana Lica"
						},
						{
							"_nume": "Adrian Runceanu"
						},
						{
							"_nume": "Anca Voicu"
						},
						{
							"_nume": "Doru Popescu Anastasiu"
						},
						{
							"_nume": "Radu Boriga"
						},
						{
							"_nume": "Mihaela Runceanu"
						},
						{
							"_nume": "Mariana Ciobanu"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "L and S InfoMat",
				"numarPagini": "208",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Bacalaureat_la_informatica_Notiuni_recapitulative_Teze_rezolvate_si_probleme_propuse_Codrina.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programa pentru bacalaureat"
						},
						{
							"_nr": "2",
							"__text": "Obiective"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Pascal. Notiuni recapitulative"
						},
						{
							"_nr": "4",
							"__text": "Limbajul C/C++. Notiuni recapitulative"
						},
						{
							"_nr": "5",
							"__text": "Teze de bacalaureat"
						},
						{
							"_nr": "6",
							"__text": "Sfaturi practice pentru candidati"
						},
						{
							"_nr": "7",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "8",
							"__text": "Bibliografice"
						}
					]
				},
				"_id": "158",
				"_isbn": "973-99276-4-0"
			},
			{
				"titlu": "Fundamentele programarii. Culegere de probleme pentru clasele IX-XI. Bacalaureat la informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Dana Lica"
						},
						{
							"_nume": "Radu Boriga"
						},
						{
							"_nume": "Doru Popescu Anastasiu"
						},
						{
							"_nume": "Dan Pracsiu"
						},
						{
							"_nume": "Mariana Ciobanu"
						},
						{
							"_nume": "Radu Visinescu"
						},
						{
							"_nume": "Mihaela Stan"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "L and S InfoMat",
				"numarPagini": "360",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Fundamentele_programarii_Culegere_de_probleme_pentru_clasele_IX_XI_Bacalaureat_la_informatica_Lica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi elementari si date din tipuri simple"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date structurate"
						},
						{
							"_nr": "3",
							"__text": "Subprograme definite de utilizator"
						},
						{
							"_nr": "4",
							"__text": "Metoda de programare Backtraking"
						},
						{
							"_nr": "5",
							"__text": "Teoria grafurilor"
						},
						{
							"_nr": "6",
							"__text": "Teze pentru bacalaureat"
						}
					]
				},
				"_id": "159",
				"_isbn": "973-86022-1-1"
			},
			{
				"titlu": "Tehnoredactare computerizata. Initiere cu ajutorul mediului. Microsoft Word 2.0",
				"autori": {
					"autor": [
						{
							"_nume": "Marian Stas"
						},
						{
							"_nume": "Alina Ciobanu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Militara",
				"numarPagini": "150",
				"pretOriginal": "5000",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Alexandru Mihalcea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Narcisa Gradinaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnoredactare_computerizata_Initiere_cu_ajutorul_mediului_Microsoft_Word_2_0_Stas.jpg"
					},
					"__text": "Zaharie Chira"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Scurta incursiune in tehnoredactarea computerizata, cu ajuoturl mediului mediului Microsoft Word"
						},
						{
							"_nr": "II",
							"__text": "Tehnoredactare computerizata...de la simplu la complex"
						},
						{
							"_nr": "III",
							"__text": "Mai multa organizare = mai multa eficienta"
						}
					]
				},
				"_id": "160",
				"_isbn": "973-32-0434"
			},
			{
				"titlu": "Informatica.Varianta C++ [IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Sorin"
						},
						{
							"_nume": "Emanuela Cerchez"
						},
						{
							"_nume": "Marinel Serban"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "L and S InfoMat",
				"numarPagini": "376",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Varianta_Cpp_IX_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rolul si functiile sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Principalele functii ale unui sistem de operare"
						},
						{
							"_nr": "3",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "4",
							"__text": "Editoare de texte"
						},
						{
							"_nr": "5",
							"__text": "Editoare de imagini"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi"
						},
						{
							"_nr": "7",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "8",
							"__text": "Elemente de baza ale limbajului C++"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni limbajului C++"
						},
						{
							"_nr": "10",
							"__text": "Mediul limbajului de programare studiat"
						},
						{
							"_nr": "11",
							"__text": "Tipuri de date structurate"
						},
						{
							"_nr": "12",
							"__text": "Fisiere"
						},
						{
							"_nr": "13",
							"__text": "Realizarea aplicatiilor"
						}
					]
				},
				"_id": "161",
				"_isbn": "973-99377-0-5"
			},
			{
				"titlu": "Informatica.Varianta Pascal [IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Sorin"
						},
						{
							"_nume": "Emanuela Cerchez"
						},
						{
							"_nume": "Marinel Serban"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "L and S InfoMat",
				"numarPagini": "380",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Pascal_IX_1999_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rolul si functiile sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Principalele functii ale unui sistem de operare"
						},
						{
							"_nr": "3",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "4",
							"__text": "Editoare de texte"
						},
						{
							"_nr": "5",
							"__text": "Editoare de imagini"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi"
						},
						{
							"_nr": "7",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "8",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni limbajului Turbo Pascal"
						},
						{
							"_nr": "10",
							"__text": "Mediul limbajului de programare studiat"
						},
						{
							"_nr": "11",
							"__text": "Tipuri de date structurate"
						},
						{
							"_nr": "12",
							"__text": "Fisiere"
						},
						{
							"_nr": "13",
							"__text": "Realizarea aplicatiilor"
						}
					]
				},
				"_id": "162",
				"_isbn": "973-98770-7-9"
			},
			{
				"titlu": "Informatica.Varianta C++ [IX]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "2000",
				"editura": "L and S InfoMat",
				"numarPagini": "320",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Cpp_IX_2000_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatorul"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "3",
							"__text": "Cateva aplicatii sub Windows"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi"
						},
						{
							"_nr": "5",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "6",
							"__text": "Elemente de baza ale limbajului C++"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni limbajului C++"
						},
						{
							"_nr": "8",
							"__text": "Mediul limbajului de programare studiat"
						},
						{
							"_nr": "9",
							"__text": "Tipuri de date structurate"
						},
						{
							"_nr": "10",
							"__text": "Fisiere"
						},
						{
							"_nr": "11",
							"__text": "Realizarea aplicatiilor"
						}
					]
				},
				"_id": "163",
				"_isbn": "973-99377-4-8"
			},
			{
				"titlu": "Informatica - varianta Visual FoxPro [XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Mariana Pantiru"
						},
						{
							"_nume": "Ionut Pantiru"
						},
						{
							"_nume": "Irina-Ioana Pantiru"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "L and S InfoMat",
				"numarPagini": "288",
				"pretOriginal": "70000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_varianta_Visual_FoxPro_2002_Pantiru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organizarea datelor in beza de date"
						},
						{
							"_nr": "2",
							"__text": "Baze de date relationale"
						},
						{
							"_nr": "3",
							"__text": "Inroducere in mediul FoxPro"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de date in Visual FoxPro si operatii specifice"
						},
						{
							"_nr": "5",
							"__text": "Crearea tabelelor"
						},
						{
							"_nr": "6",
							"__text": "Vizualizare, cautare, sortare"
						},
						{
							"_nr": "7",
							"__text": "Actulizarea datelor"
						},
						{
							"_nr": "8",
							"__text": "Indexare si cautare rapida"
						},
						{
							"_nr": "9",
							"__text": "Relationarea tabelelor"
						},
						{
							"_nr": "10",
							"__text": "Transferul de date intre tabele Visual FoxPro"
						},
						{
							"_nr": "11",
							"__text": "Prelucrari statistice si financiare"
						},
						{
							"_nr": "12",
							"__text": "Programare clasica in FoxPro"
						},
						{
							"_nr": "13",
							"__text": "Operatii cu baze de date in Visual FoxPro"
						},
						{
							"_nr": "14",
							"__text": "Interogarea bazelor de date"
						},
						{
							"_nr": "15",
							"__text": "Comunicarea aplicatiei Visual FoxPro cu exteriorul"
						},
						{
							"_nr": "16",
							"__text": "Elemente de programare orientata spre obiecte"
						},
						{
							"_nr": "17",
							"__text": "Proiectarea formularelor"
						},
						{
							"_nr": "18",
							"__text": "Afisarea datelor sub forma de rapoarte"
						},
						{
							"_nr": "19",
							"__text": "Proiectarea meniurilor"
						},
						{
							"_nr": "20",
							"__text": "Proiecte si aplicatii"
						},
						{
							"_nr": "21",
							"__text": "Dezvoltarea aplicatiilor informatice"
						}
					]
				},
				"_id": "164",
				"_isbn": "973-99376-2-4"
			},
			{
				"titlu": "Informatica (Tehnici de programare) - Varianta C++ [X]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "2000",
				"editura": "L and S InfoMat",
				"numarPagini": "192",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Cpp_X_2000_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Pointeri"
						},
						{
							"_nr": "2",
							"__text": "Subprograme"
						},
						{
							"_nr": "3",
							"__text": "Structuri de date"
						},
						{
							"_nr": "4",
							"__text": "Introducere in recursivitate"
						},
						{
							"_nr": "5",
							"__text": "Tehnica backtraking"
						},
						{
							"_nr": "6",
							"__text": "Alte tehnici de elaborare a algoritmilor"
						},
						{
							"_nr": "7",
							"__text": "Alte notiuni necesare programarii in C++"
						},
						{
							"_nr": "8",
							"__text": "Proiectarea aplicatiilor pentru rezolvarea unor programe"
						}
					]
				},
				"_id": "165",
				"_isbn": "973-99377-6-4"
			},
			{
				"titlu": "Informatica (Tehnici de programare) - Varianta Pascal [X]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "2000",
				"editura": "L and S InfoMat",
				"numarPagini": "192",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Pascal_X_2000_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Subprograme"
						},
						{
							"_nr": "2",
							"__text": "Structuri de date"
						},
						{
							"_nr": "3",
							"__text": "Introducere in recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Tehnica backtraking"
						},
						{
							"_nr": "5",
							"__text": "Alte tehnici de elaborare a algoritmilor"
						},
						{
							"_nr": "6",
							"__text": "Proiectarea aplicatiilor"
						}
					]
				},
				"_id": "166",
				"_isbn": "973-99377-2-1"
			},
			{
				"titlu": "Informatica - Varianta C++ [XI]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "2002",
				"editura": "L and S InfoMat",
				"numarPagini": "288",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Cpp_XI_2002_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "2",
							"__text": "Liste liniare"
						},
						{
							"_nr": "3",
							"__text": "Elemente de teoria grafurilor"
						},
						{
							"_nr": "4",
							"__text": "Arbori si arborescente"
						},
						{
							"_nr": "5",
							"__text": "Initiere in programarea orientata pe obiecte"
						},
						{
							"_nr": "6",
							"__text": "Proiectarea aplicatiilor"
						},
						{
							"_nr": "7",
							"__text": "Initiere in programarea vizuala, principala aplicatie a programarii orientate pe obiecte"
						}
					]
				},
				"_id": "167",
				"_isbn": "973-99377-9-9"
			},
			{
				"titlu": "Informatica - Varianta C++ [XI]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "2001",
				"editura": "L and S InfoMat",
				"numarPagini": "192",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Pascal_XI_2001_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "2",
							"__text": "Liste liniare"
						},
						{
							"_nr": "3",
							"__text": "Elemente de teoria grafurilor"
						},
						{
							"_nr": "4",
							"__text": "Arbori si arborescente"
						},
						{
							"_nr": "5",
							"__text": "Initiere in programarea orientata pe obiecte"
						},
						{
							"_nr": "6",
							"__text": "Proiectarea aplicatiilor"
						}
					]
				},
				"_id": "168",
				"_isbn": "973-99377-8-0"
			},
			{
				"titlu": "Tehnici de programare",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "192",
				"pretOriginal": "3490",
				"colectia": {
					"_numarul": "35",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_Sorin.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Stiva"
						},
						{
							"_nr": "2",
							"__text": "Metoda Backtraking"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Tehnica DIVIDE ET IMPERA"
						},
						{
							"_nr": "5",
							"__text": "Structuri de date"
						},
						{
							"_nr": "6",
							"__text": "Tehnica BRANCH AND BOUND"
						},
						{
							"_nr": "7",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "8",
							"__text": "Tehnica Greedy"
						}
					]
				},
				"_id": "169",
				"_isbn": "973-601-176-3"
			},
			{
				"titlu": "Tehnici de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Eugenia Kaliz"
						},
						{
							"_nume": "Valeriu Iorga"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "192",
				"pretOriginal": "1790",
				"pretCumparare": "25000",
				"colectia": {
					"_numarul": "15",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_Cristea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Preliminarii"
						},
						{
							"_nr": "1",
							"__text": "Despre proiectarea si implementarea programelor"
						},
						{
							"_nr": "II",
							"__text": "Structuri de date"
						},
						{
							"_nr": "2",
							"__text": "Liste"
						},
						{
							"_nr": "3",
							"__text": "Stive si cozi"
						},
						{
							"_nr": "4",
							"__text": "Arbori"
						},
						{
							"_nr": "5",
							"__text": "Grafuri"
						},
						{
							"_nr": "III",
							"__text": "Algoritmi"
						},
						{
							"_nr": "6",
							"__text": "Metoda cautarii cu revenire (Backtraking)"
						},
						{
							"_nr": "7",
							"__text": "Metoda divizarii"
						},
						{
							"_nr": "8",
							"__text": "Metoda optimului local (Greedy)"
						},
						{
							"_nr": "9",
							"__text": "Cautare si sortare"
						},
						{
							"_nr": "10",
							"__text": "Algoritmi pentru prelucrarea grafurilor"
						}
					]
				},
				"_id": "170",
				"_isbn": "973-601-072-4"
			},
			{
				"titlu": "Informatica [XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Teodora Chicioreanu"
						},
						{
							"_nume": "Octavian Lucian Popa"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Nemira",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Alina Niculae"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Sibiceanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_XI_Chicioreanu.jpg"
					},
					"__text": "Radu Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "2",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "3",
							"__text": "Programare orietata pe obiecte in Turbo Pascal"
						},
						{
							"_nr": "4",
							"__text": "Probleme recapitulative"
						}
					]
				},
				"_id": "171",
				"_isbn": "973-569-476-X"
			},
			{
				"titlu": "Turbo Pascal  - Algoritmi si limbaje de programare [IX]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "336",
				"pretOriginal": "8000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "2",
					"_nume": "Informatica pentru clasele IX-XII"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_Algoritmi_si_limbaje_de_programare_IX_Sorin.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de algoritm, caracteristici"
						},
						{
							"_nr": "2",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "4",
							"__text": "Instructiunile limbajului Turbo Pascal"
						},
						{
							"_nr": "5",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "6",
							"__text": "Subprograme"
						},
						{
							"_nr": "7",
							"__text": "Unitatea de program CRT"
						},
						{
							"_nr": "8",
							"__text": "Fisiere Pascal"
						},
						{
							"_nr": "9",
							"__text": "Unitatea de program DOS"
						},
						{
							"_nr": "10",
							"__text": "Grafica pe calculator"
						}
					]
				},
				"_id": "172",
				"_isbn": "973-601-212-3"
			},
			{
				"titlu": "Borland Pascal 7.0 pentru Windows",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Eugenia Kaliz"
						},
						{
							"_nume": "Lorina Negrescu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "466",
				"pretOriginal": "7990",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Borland_Pascal_7_0_pentru_Windows_Cristea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fundamente"
						},
						{
							"_nr": "II",
							"__text": "Elaborarea programelor Borland Pascal pentru Windows"
						}
					]
				},
				"_id": "173",
				"_isbn": "973-601-163-1"
			},
			{
				"titlu": "Invatati limbajul Pascal in 12 lectii",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "416",
				"pretOriginal": "23000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "214",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatati_Limbajul_Pascal_in_12_lectii_Patrut.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in informatica"
						},
						{
							"_nr": "2",
							"__text": "Elementele de baza ale limbajului"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de control"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de date definite de utilizator"
						},
						{
							"_nr": "5",
							"__text": "Subprograme"
						},
						{
							"_nr": "6",
							"__text": "Metode de elaborare a algoritmilor"
						},
						{
							"_nr": "7",
							"__text": "Algoritmi referitori la grafuri"
						},
						{
							"_nr": "8",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "9",
							"__text": "Fisiere"
						},
						{
							"_nr": "10",
							"__text": "Biblioteci"
						},
						{
							"_nr": "11",
							"__text": "Grafica si animatie"
						},
						{
							"_nr": "12",
							"__text": "Programarea orientata pe obiecte"
						}
					]
				},
				"_id": "174",
				"_isbn": "973-601-462-2"
			},
			{
				"titlu": "Programe Turbo Pascal in detaliu",
				"autori": {
					"autor": {
						"_nume": "Andrei Cioroianu"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "248",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "240",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Programe_Turbo_Pascal_in_detaliu_Cioroianu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programe care folosesc numai unitul SYSTEM"
						},
						{
							"_nr": "2",
							"__text": "Programe care foloesc unitul CRT"
						},
						{
							"_nr": "3",
							"__text": "Programe care foloesc unitul DOS"
						},
						{
							"_nr": "4",
							"__text": "Programe care foloesc uniturile CRT si DOS"
						},
						{
							"_nr": "5",
							"__text": "Programe care foloesc unitul GRAPH"
						},
						{
							"_nr": "6",
							"__text": "Conversii"
						},
						{
							"_nr": "7",
							"__text": "Operatii pe biti si metode de accesare a memoriei"
						},
						{
							"_nr": "8",
							"__text": "Vizualizarea fisierelor text"
						},
						{
							"_nr": "9",
							"__text": "Programe care folosesc intreruperile"
						},
						{
							"_nr": "10",
							"__text": "Metode de parcurgere recursiva a unui disc"
						},
						{
							"_nr": "11",
							"__text": "Liste si arbori folosind OOP"
						},
						{
							"_nr": "12",
							"__text": "Interfata aplicatiilor cu utilizatorul"
						}
					]
				},
				"_id": "175",
				"_isbn": "973-601-634-X"
			},
			{
				"titlu": "Manual de Informatica  - Programarea calculatoarelor [X]",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "200",
				"pretOriginal": "20000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "270",
					"_nume": "Calculatoare personale"
				},
				"redactori": {
					"redactor": "Marius Popitiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_X_Patrut.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Recapitularea cunostintelor din clasa a IX-a"
						},
						{
							"_nr": "2",
							"__text": "Metoda Back-traking"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Complexitatea algoritmilor"
						},
						{
							"_nr": "5",
							"__text": "Metoda Greedy"
						},
						{
							"_nr": "6",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "7",
							"__text": "Probleme recapitulative"
						}
					]
				},
				"_id": "176",
				"_isbn": "973-601-520-3"
			},
			{
				"titlu": "Procesoare INTEL. Programare in Limbaj de asamblare",
				"autori": {
					"autor": {
						"_nume": "Vasile Lungu"
					}
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "304",
				"pretOriginal": "150000",
				"pretCumparare": "80000",
				"colectia": {
					"_numarul": "354",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Procesoare_Intel_Programare_in_Limbaj_de_asamblare_Lungu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura calculatoarelor"
						},
						{
							"_nr": "2",
							"__text": "Adresarea virtuala si mecanismul de protectie"
						},
						{
							"_nr": "3",
							"__text": "Pafinarea si administrarea memoriei cache"
						},
						{
							"_nr": "4",
							"__text": "Procesoare superscalare"
						},
						{
							"_nr": "5",
							"__text": "Structura procesoarelor din familia INTEL"
						},
						{
							"_nr": "6",
							"__text": "Sistemul de intreruperi"
						},
						{
							"_nr": "7",
							"__text": "Formatul instructiunilor"
						},
						{
							"_nr": "8",
							"__text": "Limbajul de asamblare"
						},
						{
							"_nr": "9",
							"__text": "Definirea si utilizarea segmentelor"
						},
						{
							"_nr": "10",
							"__text": "Setul de instructiuni"
						},
						{
							"_nr": "11",
							"__text": "Procesuri in limbaj de asamblare"
						},
						{
							"_nr": "12",
							"__text": "Programe compuse din mai multe module"
						},
						{
							"_nr": "13",
							"__text": "Definirea si utilizarea macroinstructiunilor"
						},
						{
							"_nr": "14",
							"__text": "Directive de asamblare conditionata"
						},
						{
							"_nr": "15",
							"__text": "Structuri de date in limbajul de asamblare"
						},
						{
							"_nr": "16",
							"__text": "Utilizarea intreruperilor BIOS si DOS"
						},
						{
							"_nr": "17",
							"__text": "Programe rezidente in memorie TSR"
						},
						{
							"_nr": "18",
							"__text": "Mixarea codului de 16 biti cu cel de 32 de biti"
						},
						{
							"_nr": "19",
							"__text": "Tehnologia MMX"
						},
						{
							"_nr": "20",
							"__text": "Unitatea in virgula mobila"
						}
					]
				},
				"_id": "177",
				"_isbn": "973-20-0436-3"
			},
			{
				"titlu": "Programe in limbaj de asamblare",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Musca"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "322",
				"pretOriginal": "19000",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Programare_in_limbaj_de_asamblare_Musca.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere. Arhitectura 8086. Moduri de adresare"
						},
						{
							"_nr": "2",
							"__text": "Setul de instructiuni 8086"
						},
						{
							"_nr": "3",
							"__text": "Procesoare pe 32 de biti. Coprocesoare matematice"
						},
						{
							"_nr": "4",
							"__text": "Structura programelor. Definirea si initializarea datelor.Operatori"
						},
						{
							"_nr": "5",
							"__text": "Macroinstructiuni"
						},
						{
							"_nr": "6",
							"__text": "Tehnici de programare in limbaj de asamblare"
						},
						{
							"_nr": "7",
							"__text": "Interfata ASM - limbaj de nivel inalt"
						},
						{
							"_nr": "8",
							"__text": "Interfata cu sistemul de operare DOS"
						}
					]
				},
				"_id": "178",
				"_isbn": "973-601-480-0"
			},
			{
				"titlu": "Macroasamblor. Programare si utilizare",
				"autori": {
					"autor": {
						"_nume": ""
					}
				},
				"anAparitie": "1991",
				"editura": "ITC",
				"numarPagini": "360",
				"pretOriginal": "0",
				"pretCumparare": "35000",
				"colectia": {
					"_numarul": "9",
					"_nume": "DOS-PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Macroasamblor_Programare_si_utilizare_ITC.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Utilizarea programelor asamblorului"
						},
						{
							"_nr": "II",
							"__text": "Utilizarea directivelor"
						},
						{
							"_nr": "III",
							"__text": "Utilizarea instructiunilor"
						}
					]
				},
				"_id": "179",
				"_isbn": ""
			},
			{
				"titlu": "Informatica pentru definitivare si grad",
				"autori": {
					"autor": [
						{
							"_nume": "O. Dogaru"
						},
						{
							"_nume": "Gh. Bocsan"
						},
						{
							"_nume": "I. Despi"
						},
						{
							"_nume": "A. Ionica"
						},
						{
							"_nume": "V. Iorgan"
						},
						{
							"_nume": "L. Luca"
						},
						{
							"_nume": "D. Petcu"
						},
						{
							"_nume": "P. Popovici"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Editura de Vest",
				"numarPagini": "360",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ion Ilin"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_definitivare_si_grad_Dogaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi si metode de reprezentare"
						},
						{
							"_nr": "2",
							"__text": "Structura sistemelor de calcul"
						},
						{
							"_nr": "3",
							"__text": "Limbajul BASIC"
						},
						{
							"_nr": "4",
							"__text": "Structura de date de tip lista"
						},
						{
							"_nr": "5",
							"__text": "Limbajul Turbo Pascal"
						},
						{
							"_nr": "6",
							"__text": "Logica matematica"
						},
						{
							"_nr": "7",
							"__text": "Metode de elaborare a algoritmilor"
						},
						{
							"_nr": "8",
							"__text": "Grafuri"
						},
						{
							"_nr": "9",
							"__text": "Structuri arborescente"
						},
						{
							"_nr": "10",
							"__text": "Baze de date"
						},
						{
							"_nr": "11",
							"__text": "Metode numerice"
						},
						{
							"_nr": "12",
							"__text": "grafica pe calculator"
						},
						{
							"_nr": "13",
							"__text": "Birotica"
						}
					]
				},
				"_id": "180",
				"_isbn": "973-36-0308-2"
			},
			{
				"titlu": "Teste si programare in Visual FoxPro",
				"autori": {
					"autor": [
						{
							"_nume": "Manole Velicanu"
						},
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Mihaela Munteanu"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Petrion",
				"numarPagini": "216",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Camelia Dana Cristea"
				},
				"coperta": {
					"imagine": {
						"_path": "Teste_si_programe_de_programare_in_Visual_FoxPro_si_Oracle.jpg"
					},
					"__text": "Cornel Olaru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Aspecte fundamentale"
						},
						{
							"_nr": "II",
							"__text": "Visual FoxPro"
						},
						{
							"_nr": "III",
							"__text": "ORACLE"
						},
						{
							"_nr": "IV",
							"__text": "Propuner de aplicatii cu baze de date"
						}
					]
				},
				"_id": "181",
				"_isbn": "973-9116-73-6"
			},
			{
				"titlu": "Grafica asistata  - Programe FORTRAN pentru reprezentari geometrice (1)",
				"autori": {
					"autor": [
						{
							"_nume": "A. Tanasescu"
						},
						{
							"_nume": "R. Constantinescu"
						},
						{
							"_nume": "I. D. Marinescu"
						},
						{
							"_nume": "L. Busuioc"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Teora",
				"numarPagini": "312",
				"pretOriginal": "28",
				"tehnoredactori": {
					"tehnoredactor": "V.E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_asistata_Programe_Fortran_pentru_reprezentari_geometrice_Vol1_Tanasescu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Biblioteca software pentru functi grafice DIGIGRAF 1712 in 2D si 3D"
						},
						{
							"_nr": "II",
							"__text": "Reprezentari automate in geometria desciptiva. Punctul. Dreapta. Planul. Probleme de paralelism, incidenta si perpendicularitate. Subprograme geometrice si de desen"
						},
						{
							"_nr": "III",
							"__text": "Sectiuni plane in polidre si intersectia dintre doua poliedre"
						},
						{
							"_nr": "IV",
							"__text": "Conice. Cuadrice. Suprafete de rotatie.Suprafete de translatie. Reprezentare. Sectiuni plane.Intersecti mixte de suprafete"
						},
						{
							"_nr": "V",
							"__text": "Generarea reprezentarii spatiului (S3) tridimensional pe un spatiu bidimensional (S2)"
						},
						{
							"_nr": "VI",
							"__text": "Reprezentarea obiectelor spatiale"
						}
					]
				},
				"_id": "182",
				"_isbn": "973-31-0016-1"
			},
			{
				"titlu": "Informatica - Bacalaureat 2003",
				"autori": {
					"autor": [
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Constanta Golli"
						},
						{
							"_nume": "Roxana Carmen Marin"
						},
						{
							"_nume": "Ioana Oprea"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Corint",
				"numarPagini": "208",
				"pretOriginal": "0",
				"pretCumparare": "80000",
				"redactori": {
					"redactor": "Sorin Teodorescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Jora Grecea"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Golli.jpg"
					},
					"__text": "Walter Riess"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Algoritmi"
						},
						{
							"_nr": "II",
							"__text": "Elemente de programare"
						},
						{
							"_nr": "III",
							"__text": "Structuri de date"
						},
						{
							"_nr": "IV",
							"__text": "Fisiere text"
						},
						{
							"_nr": "V",
							"__text": "Algoritmi elementari"
						},
						{
							"_nr": "VI",
							"__text": "Subprograme"
						},
						{
							"_nr": "VII",
							"__text": "Recursivitate"
						},
						{
							"_nr": "VIII",
							"__text": "Metoda backtraking"
						},
						{
							"_nr": "IX",
							"__text": "Alocare dinamica"
						},
						{
							"_nr": "X",
							"__text": "Grafuri"
						},
						{
							"_nr": "XI",
							"__text": "Arbori cu radacina"
						},
						{
							"_nr": "XII",
							"__text": "Teze si teste finale"
						}
					]
				},
				"_id": "183",
				"_isbn": "973-653-397-2"
			},
			{
				"titlu": "Metode numerice si programe Pascal",
				"autori": {
					"autor": {
						"_nume": "Afrodita Iorgulescu"
					}
				},
				"anAparitie": "1996",
				"editura": "Inforec",
				"numarPagini": "338",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Ion Gh. Rosca"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_si_programe_Pascal_Iorgulescu.jpg"
					},
					"__text": "Gabriel Zamfir"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Erori"
						},
						{
							"_nr": "2",
							"__text": "Solutia ecuatiilor si a sistemelor de ecuatii neliniare"
						},
						{
							"_nr": "3",
							"__text": "Solutiile ecuatiilor algebrice"
						},
						{
							"_nr": "4",
							"__text": "Metode matriciale si sisteme de ecuatii algebrice liniare"
						},
						{
							"_nr": "5",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "6",
							"__text": "Aproximarea functiilor"
						},
						{
							"_nr": "7",
							"__text": "Derivarea si integrarea numerica"
						},
						{
							"_nr": "8",
							"__text": "Solutia problemei cu valoare initiala"
						}
					]
				},
				"_id": "184",
				"_isbn": "973-97435-0-1"
			},
			{
				"titlu": "Programarea in Pascal si Turbo Pascal.Culegere de probleme",
				"autori": {
					"autor": {
						"_nume": "Grigore Albeanu"
					}
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "264",
				"pretOriginal": "3500",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Pascal_si_Turbo_Pascal_Albeanu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Identificatori. Numere. Expresii"
						},
						{
							"_nr": "2",
							"__text": "Program Pascal. Tipuri simple. Instructiunea de atribuire"
						},
						{
							"_nr": "3",
							"__text": "Instructiunea if"
						},
						{
							"_nr": "4",
							"__text": "Instructiunea goto"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni repetitive"
						},
						{
							"_nr": "6",
							"__text": "Tipul caracter"
						},
						{
							"_nr": "7",
							"__text": "Tipuri enumerare si subdomeniu"
						},
						{
							"_nr": "8",
							"__text": "Instructiunea case"
						},
						{
							"_nr": "9",
							"__text": "Tablouri unidimensionale"
						},
						{
							"_nr": "10",
							"__text": "Tablouri bidimensionale"
						},
						{
							"_nr": "11",
							"__text": "Proceduri si functii"
						},
						{
							"_nr": "12",
							"__text": "Tipul multime. Structuri impachetate. Sirul de caractere"
						},
						{
							"_nr": "13",
							"__text": "Inregistari"
						},
						{
							"_nr": "14",
							"__text": "Fisiere"
						},
						{
							"_nr": "15",
							"__text": "Variabile dinamice"
						},
						{
							"_nr": "16",
							"__text": "Recursivitate"
						},
						{
							"_nr": "17",
							"__text": "Programare orientata spre obiecte"
						},
						{
							"_nr": "18",
							"__text": "Unitati utilizator"
						}
					]
				},
				"_id": "185",
				"_isbn": "973-31-0639-9"
			},
			{
				"titlu": "Structuri si obiecte in C++",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Smeureanu"
						},
						{
							"_nume": "Ion Ivan"
						},
						{
							"_nume": "Marian Dardala"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Cison",
				"numarPagini": "180",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Structuri_si_obiecte_in_Cpp_Smeureanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Structuri si uniuni"
						},
						{
							"_nr": "2",
							"__text": "Structuri autoreferite"
						},
						{
							"_nr": "3",
							"__text": "Grafuri"
						},
						{
							"_nr": "4",
							"__text": "Programare oriectata pe obiect"
						},
						{
							"_nr": "5",
							"__text": "Teste grila"
						}
					]
				},
				"_id": "186",
				"_isbn": "973-98623-1-4"
			},
			{
				"titlu": "Programarea aplicatiilor Windows",
				"autori": {
					"autor": [
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Gabriel Hera"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "416",
				"pretOriginal": "6990",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "33",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_aplicatiilor_Windows_Moldoveanu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Modelul programari aplicatiilor Windows"
						},
						{
							"_nr": "2",
							"__text": "Afisarea in fereastra aplicatiei"
						},
						{
							"_nr": "3",
							"__text": "Interactiunea cu utilizatorul"
						},
						{
							"_nr": "4",
							"__text": "Resurse"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea memoriei"
						},
						{
							"_nr": "6",
							"__text": "Fisiere"
						},
						{
							"_nr": "7",
							"__text": "Notiuni avansate privind functiile de afisare"
						},
						{
							"_nr": "8",
							"__text": "Stocarea si transferul imaginilor"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea imprimantelor"
						},
						{
							"_nr": "10",
							"__text": "Comunicatia intre programe"
						},
						{
							"_nr": "11",
							"__text": "Biblioteci cu legare dinamica (DLL)"
						},
						{
							"_nr": "12",
							"__text": "Interfete multidocumente(MDI)"
						},
						{
							"_nr": "13",
							"__text": "Exemple"
						}
					]
				},
				"_id": "187",
				"_isbn": "973-601-166-6"
			},
			{
				"titlu": "Limbajul Turbo Pascal (Vol 1 - Editia III)",
				"autori": {
					"autor": {
						"_nume": "Doina Rancea"
					}
				},
				"anAparitie": "1994",
				"editura": "Libris",
				"tiraj": "10000",
				"numarPagini": "144",
				"pretOriginal": "1850",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": [
						"Mioara Ivanov",
						"Adina Hudrea"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Clara Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_Turbo_Pascal_1_Rancea.jpg"
					},
					"__text": "Gavril Zmicala"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "II",
							"__text": "Elementele de baza ale limbajului Turbo Pascal"
						},
						{
							"_nr": "III",
							"__text": "Structuri de control"
						},
						{
							"_nr": "IV",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "V",
							"__text": "Subprograme"
						}
					]
				},
				"_id": "188",
				"_isbn": "973-95118-9-9"
			},
			{
				"titlu": "Limbajul Turbo Pascal (Vol 2 - Editia III)",
				"autori": {
					"autor": {
						"_nume": "Doina Rancea"
					}
				},
				"anAparitie": "1994",
				"editura": "Libris",
				"tiraj": "10000",
				"numarPagini": "176",
				"pretOriginal": "1850",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": [
						"Mioara Ivanov",
						"Adina Hudrea"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Clara Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_Turbo_Pascal_2_Rancea.jpg"
					},
					"__text": "Gavril Zmicala"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "VI",
							"__text": "Recursivitate"
						},
						{
							"_nr": "VII",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "VIII",
							"__text": "Tipul fisier"
						},
						{
							"_nr": "IX",
							"__text": "Grafica"
						}
					]
				},
				"_id": "189",
				"_isbn": "973-95118-9-9"
			},
			{
				"titlu": "Lisp (Vol 1)",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Ciumale"
						},
						{
							"_nume": "Dan Preotescu"
						},
						{
							"_nume": "Dan Luca Serbanati"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Tehnica",
				"numarPagini": "304",
				"pretOriginal": "22",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiada Nistor"
				},
				"coperta": {
					"imagine": {
						"_path": "Lisp_1_Giumale.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Programare in LISP (DM-LISP). 50 probleme rezolvate. Aplicatii complexe"
						},
						{
							"_nr": "1",
							"__text": "Notiuni de reprezentare simbolica"
						},
						{
							"_nr": "2",
							"__text": "Elemente de baza in LISP"
						},
						{
							"_nr": "3",
							"__text": "Functii elementare de sistem"
						},
						{
							"_nr": "4",
							"__text": "Mecanisme definitionale evolutate"
						},
						{
							"_nr": "5",
							"__text": "Mecanisme de gestiune contextuala"
						},
						{
							"_nr": "6",
							"__text": "Structuri si functii de memorare asociativa"
						},
						{
							"_nr": "7",
							"__text": "Gestiunea obiectelor LISP"
						},
						{
							"_nr": "8",
							"__text": "Evaluatorul LISP"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii"
						},
						{
							"_nr": "10",
							"__text": "Solutiile problemelor din Capitolele 3-7"
						},
						{
							"_nr": "II",
							"__text": "LISP86.Manual de reprezentare pentru implementarile pe calculatoarele personal-profesionale DELIX PC (compatibil IBM PC) si pe microcalculatorul M 216"
						},
						{
							"_nr": "1",
							"__text": "Tipuri de obiecte si functii"
						},
						{
							"_nr": "2",
							"__text": "Functii de sistem"
						},
						{
							"_nr": "3",
							"__text": "Atomi simbolici"
						},
						{
							"_nr": "4",
							"__text": "Tratarea erorilor"
						},
						{
							"_nr": "5",
							"__text": "Operare"
						}
					]
				},
				"_id": "190",
				"_isbn": ""
			},
			{
				"titlu": "Lisp (Vol 2)",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Tufis"
						},
						{
							"_nume": "Gheorghe Tecuci"
						},
						{
							"_nume": "Dan Cristea"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Tehnica",
				"numarPagini": "272",
				"pretOriginal": "22",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiada Nistor"
				},
				"coperta": {
					"imagine": {
						"_path": "Lisp_2_Giumale.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "III",
							"__text": "Manual de referinta pentru TC-LISP implementat pe minicalculatoarele CORAL, INDEPENDENT (si cele compatibile PDP 1 11)"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Indentitate si izomorfism structural"
						},
						{
							"_nr": "3",
							"__text": "Operatii intrare/iesire"
						},
						{
							"_nr": "4",
							"__text": "Expresii functionale"
						},
						{
							"_nr": "5",
							"__text": "Functii pentru inspectarea listelor"
						},
						{
							"_nr": "6",
							"__text": "Functii pentru crearea, modificarea si actualizarea listelor"
						},
						{
							"_nr": "7",
							"__text": "Predicate booleene"
						},
						{
							"_nr": "8",
							"__text": "Structuri de control si functii de modificare si actualizarea listelor"
						},
						{
							"_nr": "9",
							"__text": "Functii pentru controlul evaluarii"
						},
						{
							"_nr": "10",
							"__text": "Atomi simbolici, valoare proprie"
						},
						{
							"_nr": "11",
							"__text": "Functii speciale de iteratie"
						},
						{
							"_nr": "12",
							"__text": "Functii pentru gestionarea simbolurilor si pentru manipularea sirurilor de caractere"
						},
						{
							"_nr": "13",
							"__text": "Operatii logice pe numere"
						},
						{
							"_nr": "14",
							"__text": "Functii aritmetice"
						},
						{
							"_nr": "15",
							"__text": "Documentarea programelor LISP"
						},
						{
							"_nr": "16",
							"__text": "Functii pentru masurarea timpului"
						},
						{
							"_nr": "17",
							"__text": "Colectarea si reutilizarea memoriei libere in spatiul virtual"
						},
						{
							"_nr": "18",
							"__text": "Depanarea in TC-LISP"
						},
						{
							"_nr": "19",
							"__text": "Functii grafice"
						},
						{
							"_nr": "20",
							"__text": "Functii catalogate"
						},
						{
							"_nr": "21",
							"__text": "Lansarea interpretorului TC-LISP"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme bazate pe cunostinte, implementate in TC-LISP"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Sistemul IURES"
						},
						{
							"_nr": "3",
							"__text": "Sistemul QUERNAL"
						},
						{
							"_nr": "4",
							"__text": "Sistemul DISCIPOL"
						}
					]
				},
				"_id": "191",
				"_isbn": ""
			},
			{
				"titlu": "Grafica pe calculator in limbajele Pascal si C. Implementare si aplicatii (Vol I: Implementare)",
				"autori": {
					"autor": [
						{
							"_nume": "Marin Vlada"
						},
						{
							"_nume": "Adrian Posea"
						},
						{
							"_nume": "Ioan Nistor"
						},
						{
							"_nume": "Calin Constatinescu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "210",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Savulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_pe_calculator_in_limbajele_Pascal_si_C_Implementare_si_aplicatii_Vol1_Vlada.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Grafica in limbajul C (Decus)"
						},
						{
							"_nr": "2",
							"__text": "Grafica in limbajul Pascal (Oregon)"
						},
						{
							"_nr": "3",
							"__text": "Grafica in limbajul Turbo Pascal"
						},
						{
							"_nr": "4",
							"__text": "Implementarea curbelor plane"
						}
					]
				},
				"_id": "192",
				"_isbn": "973-31-0406-X"
			},
			{
				"titlu": "Grafica pe calculator in limbajele Pascal si C. Implementare si aplicatii (Vol II: Aplicatii)",
				"autori": {
					"autor": [
						{
							"_nume": "Marin Vlada"
						},
						{
							"_nume": "Adrian Posea"
						},
						{
							"_nume": "Ioan Nistor"
						},
						{
							"_nume": "Calin Constatinescu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "252",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Savulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_pe_calculator_in_limbajele_Pascal_si_C_Implementare_si_aplicatii_Vol2_Vlada.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "5",
							"__text": "Aplicatii in teoria fractalilor"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii in geometria \"TURTLE\""
						},
						{
							"_nr": "7",
							"__text": "Aplicatii in teoria curbelor si suprafetelor"
						},
						{
							"_nr": "8",
							"__text": "Trasarea incrementala a curbelor de gradul intai si doi"
						}
					]
				},
				"_id": "193",
				"_isbn": "973-31-0406-X"
			},
			{
				"titlu": "Pascal si Turbo Pascal (Vol 1 : Limbajul Pascal.Concepte fundamentale)",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Serban Gavrila"
						},
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Marian Gheorghe"
						},
						{
							"_nume": "Liviu Sofonea"
						},
						{
							"_nume": "Ion Vaduva"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"tiraj": "20000",
				"numarPagini": "256",
				"pretOriginal": "335",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Pascal_si_Turbo_Pascal_Vol1_Balanescu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Vocabular si sintaxa limbajului"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date, variabile, constante, etichete, expresii"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Proceduri, functii, programe, module"
						},
						{
							"_nr": "5",
							"__text": "Reprezentarea formalizata a semanticii"
						},
						{
							"_nr": "6",
							"__text": "Depanatorul simbolic"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea compilatorului Pascal-Oregon"
						}
					]
				},
				"_id": "194",
				"_isbn": "973-31-0430-2"
			},
			{
				"titlu": "Pascal si Turbo Pascal (Vol 2 : Limbajul Turbo Pascal)",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Serban Gavrila"
						},
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Marian Gheorghe"
						},
						{
							"_nume": "Liviu Sofonea"
						},
						{
							"_nume": "Ion Vaduva"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"tiraj": "20000",
				"numarPagini": "376",
				"pretOriginal": "335",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Pascal_si_Turbo_Pascal_Vol2_Balanescu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Turbo Pascal. Ghid de utilizare"
						},
						{
							"_nr": "1",
							"__text": "Vocabularul limbajului"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date, variabile, constante, etichete, expresii"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Proceduri si functii"
						},
						{
							"_nr": "5",
							"__text": "Programe,module, unitati"
						},
						{
							"_nr": "6",
							"__text": "Unitati srandard si aplicatii"
						},
						{
							"_nr": "7",
							"__text": "Programarea orietata spre obiecte"
						},
						{
							"_nr": "8",
							"__text": "Turbo Pascal 5.5 fata de standardul Pascal"
						},
						{
							"_nr": "9",
							"__text": "Parametri de compilare"
						},
						{
							"_nr": "10",
							"__text": "Utilitare Turbo Pascal"
						}
					]
				},
				"_id": "195",
				"_isbn": "973-31-0430-2"
			},
			{
				"titlu": "Introducere in limbajul SIRIS",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Petre Preoteasa"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Albatros",
				"numarPagini": "208",
				"pretOriginal": "5",
				"pretCumparare": "5000",
				"tehnoredactori": {
					"tehnoredactor": "Cornel Cristescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_sistemul_de_operare_SIRIS_Georgescu.jpg"
					},
					"__text": "Marina Georgescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Organizarea generala a sistemului FELIX"
						},
						{
							"_nr": "II",
							"__text": "Sistemul de operare SIRIS"
						},
						{
							"_nr": "III",
							"__text": "Descrierea principalelor cartele de comanda"
						},
						{
							"_nr": "IV",
							"__text": "Descrierea structurii fisierelor"
						},
						{
							"_nr": "V",
							"__text": "Exploatarea fisierelor in FORTRAN"
						},
						{
							"_nr": "VI",
							"__text": "Cartele de comanda pentru fisiere"
						},
						{
							"_nr": "VII",
							"__text": "Programul BIBLIOTECAR"
						},
						{
							"_nr": "VIIO",
							"__text": "Segmentarea programelor"
						},
						{
							"_nr": "IX",
							"__text": "Facilitati de rulare a programelor in sistemul SIRIS"
						},
						{
							"_nr": "X",
							"__text": "Depanarea programelor"
						},
						{
							"_nr": "XI",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "XII",
							"__text": "Unele programe actuale ale sistemelor de operare"
						}
					]
				},
				"_id": "196",
				"_isbn": ""
			},
			{
				"titlu": "Informatica",
				"autori": {
					"autor": {
						"_nume": "Jacques Arsac"
					}
				},
				"anAparitie": "1973",
				"editura": "Enciclopedica romana",
				"tiraj": "12200",
				"numarPagini": "264",
				"pretOriginal": "8.50",
				"pretCumparare": "10000",
				"traducere": {
					"titluOriginal": "Le science informatique",
					"editura": "Dunod",
					"traducatori": {
						"traducator": [
							"Constantin P. Popovici",
							"Petru Navodaru"
						]
					},
					"_an": "1970",
					"_editia": "I",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Sorin Toma"
				},
				"tehnoredactori": {
					"tehnoredactor": "C. Branciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Arsac.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Informatia"
						},
						{
							"_nr": "2",
							"__text": "Informatica"
						},
						{
							"_nr": "3",
							"__text": "Calculatoarele"
						},
						{
							"_nr": "4",
							"__text": "Programarea"
						},
						{
							"_nr": "5",
							"__text": "Algoritmii"
						},
						{
							"_nr": "6",
							"__text": "Analiza"
						},
						{
							"_nr": "7",
							"__text": "Modurile de utilizare a calculatoarelor"
						},
						{
							"_nr": "8",
							"__text": "Perspective"
						}
					]
				},
				"_id": "197",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in limbaje de asamblare ASSIRIS",
				"autori": {
					"autor": [
						{
							"_nume": "Emil Munteanu"
						},
						{
							"_nume": "Viorel Costea"
						},
						{
							"_nume": "Mircu Mitrov"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"tiraj": "9150+10+70 exemplare brosate",
				"numarPagini": "336",
				"pretOriginal": "10.50",
				"pretCumparare": "5000",
				"colectia": {
					"_numarul": "43",
					"_nume": "AI"
				},
				"redactori": {
					"redactor": "smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicolae Serbanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbaje_de_asamblare_Assiris_Munteanu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Asamblarea si limbajele de asamblare"
						},
						{
							"_nr": "2",
							"__text": "Directivele limbajului de asamblare"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni masina ale limbajului de asamblare"
						},
						{
							"_nr": "4",
							"__text": "Macroinstructiunile limbajului de asamblare"
						},
						{
							"_nr": "5",
							"__text": "Exemple de programe comentate"
						}
					]
				},
				"_id": "198",
				"_isbn": ""
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": {
						"_nume": "Leon Livovschi"
					}
				},
				"anAparitie": "1979",
				"editura": "Albatros",
				"tiraj": "50000",
				"numarPagini": "474",
				"pretOriginal": "13",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": "Cornel Cristescu n"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Livovschi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Structura si functionarea calculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi, scheme logice si programe"
						},
						{
							"_nr": "4",
							"__text": "Limbajul FORTRAN"
						},
						{
							"_nr": "5",
							"__text": "Intocmirea si rularea unui program"
						},
						{
							"_nr": "6",
							"__text": "Anexe"
						}
					]
				},
				"_id": "199",
				"_isbn": ""
			},
			{
				"titlu": "COBOL ANS-2. Manual de utilizare",
				"autori": {
					"autor": [
						{
							"_nume": "Glodeanu Gavril"
						},
						{
							"_nume": "Handrea Mihai"
						}
					]
				},
				"anAparitie": "1979",
				"editura": "ITC",
				"numarPagini": "232",
				"pretOriginal": "0",
				"pretCumparare": "5000",
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "COBOL_ANS_2_Manual_de_utilizare_Glodeanu.jpg"
					},
					"__text": "Badescu Andrei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Structura de baza"
						},
						{
							"_nr": "3",
							"__text": "Prelucrarea fisierelor"
						},
						{
							"_nr": "4",
							"__text": "Prelucrarea tabelelor"
						},
						{
							"_nr": "5",
							"__text": "TRI"
						},
						{
							"_nr": "6",
							"__text": "Editorul COBOL ANS-2"
						},
						{
							"_nr": "7",
							"__text": "Segmentare"
						},
						{
							"_nr": "8",
							"__text": "Biblioteca"
						},
						{
							"_nr": "9",
							"__text": "Facilitati de punere la zi"
						},
						{
							"_nr": "10",
							"__text": "Tolerante pentru compatibilitate"
						}
					]
				},
				"_id": "200",
				"_isbn": ""
			},
			{
				"titlu": "Culegere de probleme de programare in limbajul COBOL",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Peteanu"
						},
						{
							"_nume": "Sabin Goron"
						},
						{
							"_nume": "Geza Venczel"
						},
						{
							"_nume": "Ioan Moldovan"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Dacia",
				"tiraj": "14600+90+20 brosate",
				"numarPagini": "352",
				"pretOriginal": "14.50",
				"pretCumparare": "5000",
				"colectia": {
					"_numarul": "6",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Mircea Stefan"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavarhy"
				},
				"coperta": {
					"imagine": {
						"_path": "Culegere_de_probleme_in_limbajul_COBOL_Peteanu.jpg"
					},
					"__text": "Vasile Pop-Silaghi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbaj Cobol. Cartele de comanda si programe utilitare"
						},
						{
							"_nr": "2",
							"__text": "Probleme rezolvate fara utilizarea fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Probleme economice rezolvate prin utilizarea fisierelor"
						}
					]
				},
				"_id": "201",
				"_isbn": ""
			},
			{
				"titlu": "Invatam C...pas cu pas",
				"autori": {
					"autor": {
						"_nume": "Tom Swan"
					}
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "416",
				"pretOriginal": "10000",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Type and Learn C",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Dan Somnea"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "SUA"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatam_C_pas_cu_pas_Swan.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Studiu introductiv"
						},
						{
							"_nr": "1",
							"__text": "Metoda de invatare prin scriere (tastare)"
						},
						{
							"_nr": "2",
							"__text": "Sa incepem cu...inceputul"
						},
						{
							"_nr": "3",
							"__text": "Programarea in C"
						},
						{
							"_nr": "II",
							"__text": "C..luat pe indelete"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de date si variabile"
						},
						{
							"_nr": "5",
							"__text": "Operatori si expresii"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni de control. Operatori relationali"
						},
						{
							"_nr": "7",
							"__text": "Tipuri de date derivate"
						},
						{
							"_nr": "8",
							"__text": "Pointeri"
						},
						{
							"_nr": "9",
							"__text": "Functii"
						},
						{
							"_nr": "10",
							"__text": "Tehnici avansate de programare"
						},
						{
							"_nr": "III",
							"__text": "Biblioteca de functii standard"
						},
						{
							"_nr": "11",
							"__text": "Functii de intrare si de iesire (STDIO.H)"
						},
						{
							"_nr": "12",
							"__text": "Functii utitare (STDLIB.H)"
						},
						{
							"_nr": "13",
							"__text": "Functii cronologice (TIME.H)"
						},
						{
							"_nr": "14",
							"__text": "Functii pentru prelucrarea sirurilor (STRING.H)"
						},
						{
							"_nr": "15",
							"__text": "Functii matematice (MATH.H)"
						},
						{
							"_nr": "IV",
							"__text": "Algoritmi"
						},
						{
							"_nr": "16",
							"__text": "Structuri de date autoreferentiale"
						},
						{
							"_nr": "17",
							"__text": "Sortarea si cautarea"
						},
						{
							"_nr": "18",
							"__text": "Prelucrarea fisierelor"
						}
					]
				},
				"_id": "202",
				"_isbn": "973-31-0779-4"
			},
			{
				"titlu": "Initiera in C++. Programarea orietata pe obiecte",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Somnea"
						},
						{
							"_nume": "Doru Turturea"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "384",
				"pretOriginal": "1100",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Cpp_Programarea_orientata_pe_obiecte_Somnea.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Structuri de date predefinite"
						},
						{
							"_nr": "3",
							"__text": "Crearea claselor"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea pointerilor si referintelor. Elemente preliminare despre functii"
						},
						{
							"_nr": "5",
							"__text": "Elemente speciale despre clase si functii"
						},
						{
							"_nr": "6",
							"__text": "Redefinirea operatorilor si functiilor"
						},
						{
							"_nr": "7",
							"__text": "Studiul sistematic al functiilor de tipurile constructor si destructor"
						},
						{
							"_nr": "8",
							"__text": "Mecanismul mostenirii (inheritance) si construirea ierarhiilor de clase"
						},
						{
							"_nr": "9",
							"__text": "Functii virtuale"
						},
						{
							"_nr": "10",
							"__text": "Sistemul \"stream de I/E din C++\""
						},
						{
							"_nr": "11",
							"__text": "Medii de operare grafice in C++. Generatoare de aplicatii WINDOWS. Object WINDOWS for C++"
						}
					]
				},
				"_id": "203",
				"_isbn": "973-31-0493-0"
			},
			{
				"titlu": "C++ limbaj si programe",
				"autori": {
					"autor": {
						"_nume": "George Daniel Mateescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Petrion",
				"numarPagini": "176",
				"pretOriginal": "60000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Cpp_Limbaj_si_programare_Mateescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Obiectele de baza ale limbajului"
						},
						{
							"_nr": "2",
							"__text": "Functii si operatori"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "4",
							"__text": "Instructiunile de baza ale limbajului"
						},
						{
							"_nr": "5",
							"__text": "Supraincarcarea operatorilor"
						},
						{
							"_nr": "6",
							"__text": "Caracteristici avansate ale functiilor"
						},
						{
							"_nr": "7",
							"__text": "Derivarea claselor"
						},
						{
							"_nr": "8",
							"__text": "Operatii de intrare/iesire"
						},
						{
							"_nr": "9",
							"__text": "Directive de preprocesare"
						},
						{
							"_nr": "10",
							"__text": "Cateva biblioteciutile in mediul MS-DOS"
						},
						{
							"_nr": "11",
							"__text": "Elemente de programare in mediul WINDOWS"
						}
					]
				},
				"_id": "204",
				"_isbn": "973-9116-31-0"
			},
			{
				"titlu": "Manual de C/C++ pentru licee [XI]",
				"autori": {
					"autor": {
						"_nume": "Dorian Stoilescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Radial",
				"numarPagini": "374",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Paula Brehoiu",
						"Dan Dascalescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Manual_de_C_Cpp_pentru_licee_Stoilescu.jpg"
					},
					"__text": "Stelian Ileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "3",
							"__text": "Intrari/Iesri standard"
						},
						{
							"_nr": "4",
							"__text": "Operatori C"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni dolosite in limbajul C"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Tablouri si pointeri"
						},
						{
							"_nr": "8",
							"__text": "Prelucrarea sirurilor de caractere si a zonelor de memorie"
						},
						{
							"_nr": "9",
							"__text": "Tipuri structurate"
						},
						{
							"_nr": "10",
							"__text": "Functii pentru prelucrarea fisierelor"
						},
						{
							"_nr": "11",
							"__text": "Directive preprocesare"
						},
						{
							"_nr": "12",
							"__text": "Alte functii folosite in limbajul C"
						},
						{
							"_nr": "13",
							"__text": "Introducere in ANSI-C++"
						},
						{
							"_nr": "14",
							"__text": "Clase si obiecte"
						},
						{
							"_nr": "15",
							"__text": "Supraincarcare"
						},
						{
							"_nr": "16",
							"__text": "Mostenire"
						},
						{
							"_nr": "17",
							"__text": "Sistemul stream de intrare/iesire folosit in C++"
						},
						{
							"_nr": "18",
							"__text": "Clase si functii template.Tratarea erorilor"
						}
					]
				},
				"_id": "205",
				"_isbn": "973=98337-0-3"
			},
			{
				"titlu": "Programarea in limbajul C/C++",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Smeureanu"
						},
						{
							"_nume": "Marian Dardala"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Cison",
				"numarPagini": "308",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_C_Cpp_Smeureanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in limbajul C++"
						},
						{
							"_nr": "2",
							"__text": "Tipuri fundamentale de date si clase de memorie"
						},
						{
							"_nr": "3",
							"__text": "Operatori si expresii"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni de baza ale limbajului C/C++"
						},
						{
							"_nr": "5",
							"__text": "Masive si pointeri"
						},
						{
							"_nr": "6",
							"__text": "Lucru cu siruri de caractere"
						},
						{
							"_nr": "7",
							"__text": "Functii"
						},
						{
							"_nr": "8",
							"__text": "Structuri si uniuni in limbajul C"
						},
						{
							"_nr": "9",
							"__text": "Preprocesarea"
						},
						{
							"_nr": "10",
							"__text": "Lucru cu fisiere"
						}
					]
				},
				"_id": "206",
				"_isbn": "973-99725-7-8"
			},
			{
				"titlu": "Programarea in limbajul C",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Petrovici"
						},
						{
							"_nume": "Florin Goicea"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "328",
				"pretOriginal": "800",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_C_Petrovici.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De ce C?"
						},
						{
							"_nr": "2",
							"__text": "Elementele limbajului C"
						},
						{
							"_nr": "3",
							"__text": "Declaratii"
						},
						{
							"_nr": "4",
							"__text": "Conversii"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Structura programului"
						},
						{
							"_nr": "8",
							"__text": "Functii si macro-uri din biblioteca MICROSOFT C"
						}
					]
				},
				"_id": "207",
				"_isbn": "973-31-0508-2"
			},
			{
				"titlu": "Turbo C++",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Catrina"
						},
						{
							"_nume": "Iuliana Cojocaru"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "240",
				"pretOriginal": "4990",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "9",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Cpp_Catrina.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Programarea in limbajul C"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "O scurta initiere in limbajul C"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de date simple, constante, declaratii de variabile"
						},
						{
							"_nr": "4",
							"__text": "Operatori si expresii"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni"
						},
						{
							"_nr": "6",
							"__text": "Tablouri si siruri de caractere"
						},
						{
							"_nr": "7",
							"__text": "Variabile pointer"
						},
						{
							"_nr": "8",
							"__text": "Functii"
						},
						{
							"_nr": "9",
							"__text": "Tipuri de date definite de utilizator"
						},
						{
							"_nr": "10",
							"__text": "Dispozitive de intrare/iesire"
						},
						{
							"_nr": "11",
							"__text": "Elemente de programare avansata"
						},
						{
							"_nr": "12",
							"__text": "Functiile video"
						},
						{
							"_nr": "II",
							"__text": "Programarea in C++"
						},
						{
							"_nr": "13",
							"__text": "Programarea orientata spre obiecte (POO) in Turbo C++"
						},
						{
							"_nr": "14",
							"__text": "Incompatibilitati  intre ANSI C si C++"
						},
						{
							"_nr": "15",
							"__text": "Completari aduse de C++"
						},
						{
							"_nr": "16",
							"__text": "Clase si obiecte"
						},
						{
							"_nr": "17",
							"__text": "Constructori si destructori"
						},
						{
							"_nr": "18",
							"__text": "Clase si obiecte. Completari"
						},
						{
							"_nr": "19",
							"__text": "Supradefinirea operatorilor"
						},
						{
							"_nr": "20",
							"__text": "Conversii de tip definite de programator"
						},
						{
							"_nr": "21",
							"__text": "Conceptul de mostenire. Clase derivate"
						},
						{
							"_nr": "22",
							"__text": "Operatii de intrare/iesire in C++"
						}
					]
				},
				"_id": "208",
				"_isbn": "973-601-108-9"
			},
			{
				"titlu": "Limbajul C standard",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Cristian Ciumale"
						},
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Alexandru Panoiu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "120",
				"pretOriginal": "350",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "6",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_C_Cristea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Trecerea in revista a limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Elemente de baza ale limbajului C"
						},
						{
							"_nr": "3",
							"__text": "Tipuri"
						},
						{
							"_nr": "4",
							"__text": "Operatori si expresii"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni"
						},
						{
							"_nr": "6",
							"__text": "Tipuri structurate"
						},
						{
							"_nr": "7",
							"__text": "Functii"
						},
						{
							"_nr": "8",
							"__text": "Functii standard"
						},
						{
							"_nr": "9",
							"__text": "Facilitati suplimentare"
						},
						{
							"_nr": "10",
							"__text": "De la problema la program"
						}
					]
				},
				"_id": "209",
				"_isbn": "973-601-027-9"
			},
			{
				"titlu": "Totul despre...SQL. Interogarea bazelor de date",
				"autori": {
					"autor": [
						{
							"_nume": "Corina Pascu"
						},
						{
							"_nume": "Adrian Pascu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "160",
				"pretOriginal": "3700",
				"pretCumparare": "22000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_SQL_Pascu.jpg"
					},
					"__text": "Simona Dumitrescu, Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Fundamente ale limbajului SQL"
						},
						{
							"_nr": "3",
							"__text": "Modelul relational"
						},
						{
							"_nr": "4",
							"__text": "Elemente ale algebrei relationale"
						},
						{
							"_nr": "5",
							"__text": "Definirea datelor"
						},
						{
							"_nr": "6",
							"__text": "Manipularea datelor"
						},
						{
							"_nr": "7",
							"__text": "Controlul datelor"
						},
						{
							"_nr": "8",
							"__text": "Dezvoltarea aplicatiilor de baza de date realtionale"
						},
						{
							"_nr": "9",
							"__text": "Accesul la baze de date publice"
						}
					]
				},
				"_id": "210",
				"_isbn": "973-31-0689-5"
			},
			{
				"titlu": "Sisteme de gestiune a bazelor de date. Aplicatii ORACLE",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Manole Velicanu"
						},
						{
							"_nume": "Constanta Bodea"
						},
						{
							"_nume": "Cristina Ionita"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All",
				"numarPagini": "482",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_gestiune_a_bazelor_de_date_SGBD_Aplicatii_Oracle_Lungu.jpg"
					},
					"__text": "Daniel Munteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Oracle - sistem de gestiune a bazelor de date relationale"
						},
						{
							"_nr": "2",
							"__text": "Realizarea uneui baze de date in SQL*Plus"
						},
						{
							"_nr": "3",
							"__text": "Selectarea si editarea datelor in SQL*Plus"
						},
						{
							"_nr": "4",
							"__text": "Limbajul de programare PL/SQL"
						},
						{
							"_nr": "5",
							"__text": "Interfete de programare in Oracle"
						},
						{
							"_nr": "6",
							"__text": "Proiectarea videoformatelor cu SQL*Forms"
						},
						{
							"_nr": "7",
							"__text": "Realizarea meniurilor in SQL*Menu"
						},
						{
							"_nr": "8",
							"__text": "SQL*ReportWriter"
						}
					]
				},
				"_id": "211",
				"_isbn": "973-9392-46-6"
			},
			{
				"titlu": "Bazele ORACLE 7",
				"autori": {
					"autor": {
						"_nume": "Tom Luers"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "536",
				"pretOriginal": "35000",
				"pretCumparare": "80000",
				"colectia": {
					"_numarul": "209",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Essential ORACLE 7",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_Oracle_7_Luers.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Trecere in revista a sistemului de gestiune a bezelor de date relationale Oracle"
						},
						{
							"_nr": "II",
							"__text": "Obiectele bazelor de date"
						},
						{
							"_nr": "III",
							"__text": "SQL - Structured Query Language"
						},
						{
							"_nr": "IV",
							"__text": "Accesarea datelor"
						},
						{
							"_nr": "V",
							"__text": "Integritatea datelor"
						},
						{
							"_nr": "VI",
							"__text": "Structuri Oracle"
						},
						{
							"_nr": "VII",
							"__text": "Utilitare Oracle"
						},
						{
							"_nr": "VIII",
							"__text": "Siguranta bezei de date"
						},
						{
							"_nr": "IX",
							"__text": "Codurile si mesajele serverului Oracle"
						},
						{
							"_nr": "X",
							"__text": "Rafinarea aplicatiilor Oracle"
						}
					]
				},
				"_id": "212",
				"_isbn": "973-601-740-0"
			},
			{
				"titlu": "Java API - Manualul interfetei de programare  a aplicatiilor",
				"autori": {
					"autor": [
						{
							"_nume": "Colin Fraizer"
						},
						{
							"_nume": "Jill Bond"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "360",
				"pretOriginal": "50000",
				"pretCumparare": "80000",
				"colectia": {
					"_numarul": "259",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Java API Reference",
					"editura": "New Rider Publishing",
					"traducatori": {
						"traducator": "Eugen Dumitrescu"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihaela Carasca"
				},
				"coperta": {
					"imagine": {
						"_path": "Java_API_Bond.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Pachetul API java.applet"
						},
						{
							"_nr": "II",
							"__text": "Pachetul API java.awt"
						}
					]
				},
				"_id": "213",
				"_isbn": "973-601-971-3"
			},
			{
				"titlu": "Prezentarea limbajului Java",
				"autori": {
					"autor": {
						"_nume": "John December"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "240",
				"pretOriginal": "14000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "183",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Presenting Java",
					"editura": "Sams.net Publishing",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Prezentarea_limbajului_Java_December.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Intelegerea posibitatilor limbajului Java si a retelei Web"
						},
						{
							"_nr": "II",
							"__text": "Explorarea potentialului limbajului Java"
						},
						{
							"_nr": "III",
							"__text": "Folosirea programului HotJava"
						},
						{
							"_nr": "IV",
							"__text": "Dezvoltarea aplicatiilor Java"
						}
					]
				},
				"_id": "214",
				"_isbn": "973-601-654-4"
			},
			{
				"titlu": "Mediul Java pentru Internet",
				"autori": {
					"autor": [
						{
							"_nume": "Auguston Prodan"
						},
						{
							"_nume": "Mihai Prodan"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Promedia Plus",
				"numarPagini": "288",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Serban Droncea"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Auguston Prodan",
						"Mihai Prodan"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Mediul_Java_pentru_Internet_Prodan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce reprezinta Java pentru Internet"
						},
						{
							"_nr": "2",
							"__text": "Browsere pentru continut executabil Java"
						},
						{
							"_nr": "3",
							"__text": "Incepeti sa scrieti programe Java"
						},
						{
							"_nr": "4",
							"__text": "Elemente lexicale si structuri de baza in limbajul Java"
						},
						{
							"_nr": "5",
							"__text": "Clase, interfete si pachete"
						},
						{
							"_nr": "6",
							"__text": "Fire de executie si exceptii"
						},
						{
							"_nr": "7",
							"__text": "Biblioteci de clase in mediul Java"
						},
						{
							"_nr": "8",
							"__text": "Bibliotecile de clase applet si awt"
						},
						{
							"_nr": "9",
							"__text": "Bibliotecile de clase io si util"
						},
						{
							"_nr": "10",
							"__text": "Bibliteca de clase net"
						},
						{
							"_nr": "11",
							"__text": "Interfata cu limbajul C"
						},
						{
							"_nr": "12",
							"__text": "Instrumente in mediul Java"
						},
						{
							"_nr": "13",
							"__text": "Masina virtuala Java"
						}
					]
				},
				"_id": "215",
				"_isbn": "973-9275-07-9"
			},
			{
				"titlu": "Sisteme de operare. Principii si functionare",
				"autori": {
					"autor": [
						{
							"_nume": "Traian Ionescu"
						},
						{
							"_nume": "Daniela Saru"
						},
						{
							"_nume": "John Floroiu"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "180",
				"pretOriginal": "7000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Margareta Chilim"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_Principii_si_functionare_Ionescu.jpg"
					},
					"__text": "Margareta Chilim"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Structura sistemelor de operare"
						},
						{
							"_nr": "III",
							"__text": "Procese concurente"
						},
						{
							"_nr": "IV",
							"__text": "Planificarea unitatii centrale"
						},
						{
							"_nr": "V",
							"__text": "Starea de interblocare"
						},
						{
							"_nr": "VI",
							"__text": "Gestionarea memoriei"
						},
						{
							"_nr": "VII",
							"__text": "Memoria virtuala"
						},
						{
							"_nr": "VIII",
							"__text": "Sistemul de fisiere"
						},
						{
							"_nr": "IX",
							"__text": "Sistemul de intrare/iesire (I/O)"
						}
					]
				},
				"_id": "216",
				"_isbn": "973-31-1064-7"
			},
			{
				"titlu": "Comenzi si fisiere de comenzi in sistemul de operare UNIX",
				"autori": {
					"autor": {
						"_nume": "Madalina Mlak"
					}
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "100",
				"pretOriginal": "68000",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Comenzi_si_fisiere_de_comenzi_in_sistemul_de_operare_Unix_Mlak.jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul de fisiere UNIX"
						},
						{
							"_nr": "2",
							"__text": "Comenzi UNIX"
						},
						{
							"_nr": "3",
							"__text": "Editoare de texte"
						},
						{
							"_nr": "4",
							"__text": "Trimiterea si receptionarea mesajelor"
						},
						{
							"_nr": "5",
							"__text": "Transferul fisierelor cu ajutorul protocolului FTP"
						},
						{
							"_nr": "6",
							"__text": "Fisiere de comenzi (Shell script-uri)"
						}
					]
				},
				"_id": "217",
				"_isbn": "973-594-261-5"
			},
			{
				"titlu": "Introducere in Linux",
				"autori": {
					"autor": {
						"_nume": "Mihai Basca"
					}
				},
				"anAparitie": "1998",
				"editura": "All",
				"numarPagini": "148",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_Unix_Basca.jpg"
					},
					"__text": "Daniel Munteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Instalare"
						},
						{
							"_nr": "3",
							"__text": "Configurare"
						},
						{
							"_nr": "4",
							"__text": "Primii pasi"
						},
						{
							"_nr": "5",
							"__text": "Bash"
						},
						{
							"_nr": "6",
							"__text": "Editarea textelor"
						},
						{
							"_nr": "7",
							"__text": "Posta electronica"
						}
					]
				},
				"_id": "218",
				"_isbn": "973-9392-28-8"
			},
			{
				"titlu": "Unix",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Alexandru Panoiu"
						},
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Lorina Negreanu"
						},
						{
							"_nume": "Silviu Calinoiu"
						},
						{
							"_nume": "Florin Baboescu"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "288",
				"pretOriginal": "2790",
				"pretCumparare": "39000",
				"colectia": {
					"_numarul": "19",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Unix_Cristea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Comenzi. Linii de comanda. Comenzi uzuale"
						},
						{
							"_nr": "3",
							"__text": "Editarea textelor"
						},
						{
							"_nr": "4",
							"__text": "Formatoare de texte"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de fisiere"
						},
						{
							"_nr": "6",
							"__text": "Comenzi si utilitare de baza pentru manipularea fisierelor"
						},
						{
							"_nr": "7",
							"__text": "Interpretoare de comenzi"
						},
						{
							"_nr": "8",
							"__text": "Compilarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Utilitare de comunizatie"
						},
						{
							"_nr": "10",
							"__text": "Utilitare pentru prelucrarea textelor"
						},
						{
							"_nr": "11",
							"__text": "Generatoare de programe"
						},
						{
							"_nr": "12",
							"__text": "Sistemul de ferestre X"
						}
					]
				},
				"_id": "219",
				"_isbn": "973-601-105-4"
			},
			{
				"titlu": "Unix",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Vladimir Pilat"
						},
						{
							"_nume": "Irina Stefanescu"
						},
						{
							"_nume": "Deaconu Sorin"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "224",
				"pretOriginal": "1200",
				"pretCumparare": "22000",
				"colectia": {
					"_numarul": "13",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Unix_Pilat.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente  generale si caracteristici ale sistemului de operare (S.O.) Unix"
						},
						{
							"_nr": "2",
							"__text": "Pornirea lucrului cu sistemul Unix. Sistemul de fisiere in S.O Unix"
						},
						{
							"_nr": "3",
							"__text": "Nucleul sistemului de operare Unix. Executia programelor. Procese"
						},
						{
							"_nr": "4",
							"__text": "Interpretorul de comenzi (Shell-ul)"
						},
						{
							"_nr": "5",
							"__text": "Comunicatia intre sisteme Unix. Editarea si procesarea de texte"
						},
						{
							"_nr": "6",
							"__text": "Administrarea si intretinerea sistemului Unix. Dezvoltarea programelor securitatea sistemului"
						},
						{
							"_nr": "7",
							"__text": "Mediul utilizator"
						},
						{
							"_nr": "8",
							"__text": "Retele de calculatoare si protocoale de comunicatie su sistemul de operare Unix"
						},
						{
							"_nr": "9",
							"__text": "Sistemul de operare SCO OPEN DESKTOP (ODT)"
						}
					]
				},
				"_id": "220",
				"_isbn": "973-601-063-5"
			},
			{
				"titlu": "Limbaje de programare si compilatoare",
				"autori": {
					"autor": {
						"_nume": "Luca-Dan Serbanati"
					}
				},
				"anAparitie": "1987",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "350",
				"pretOriginal": "31",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Melus Tureac"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_programare_si_compilatoare_Serbanati.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbaje de programare"
						},
						{
							"_nr": "1",
							"__text": "Structurainerarhica a limbajelor de programare"
						},
						{
							"_nr": "2",
							"__text": "Universul limbajelor de programare (ULP)"
						},
						{
							"_nr": "3",
							"__text": "Notiunea de variabila in programare"
						},
						{
							"_nr": "4",
							"__text": "Atribute. Legarea atributelor. Declaratii"
						},
						{
							"_nr": "5",
							"__text": "Date structurate"
						},
						{
							"_nr": "6",
							"__text": "Expresii si comenzi"
						},
						{
							"_nr": "7",
							"__text": "Unitati de program"
						},
						{
							"_nr": "8",
							"__text": "Gestionarea memoriei in faza de executie"
						},
						{
							"_nr": "II",
							"__text": "Introducere in teoria compilarii"
						},
						{
							"_nr": "1",
							"__text": "Specificarea limbajelor"
						},
						{
							"_nr": "2",
							"__text": "Traducerea limbajelor"
						},
						{
							"_nr": "III",
							"__text": "Proiectarea compilatoarelor"
						},
						{
							"_nr": "1",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "2",
							"__text": "Analiza sintactica"
						},
						{
							"_nr": "3",
							"__text": "Analiza semantica"
						},
						{
							"_nr": "4",
							"__text": "Generarea codului intermediar"
						},
						{
							"_nr": "5",
							"__text": "Tabela de simboluri"
						},
						{
							"_nr": "6",
							"__text": "Tratarea erorilor in compilare"
						},
						{
							"_nr": "7",
							"__text": "Optimizarea codului"
						},
						{
							"_nr": "8",
							"__text": "Generarea programului obiect"
						},
						{
							"_nr": "9",
							"__text": "Interpretoare"
						},
						{
							"_nr": "10",
							"__text": "Limbaje de asamblare si asamblatoare"
						}
					]
				},
				"_id": "221",
				"_isbn": ""
			},
			{
				"titlu": "Limbaje formale si tehnici de compilare",
				"autori": {
					"autor": {
						"_nume": "Dan Simovici"
					}
				},
				"anAparitie": "1978",
				"editura": "Didactica si pedagogica",
				"tiraj": "4750+80 brosate",
				"numarPagini": "248",
				"pretOriginal": "10.20",
				"redactori": {
					"redactor": "Gabriela Iliescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ana Timpau"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_tehnici_de_compilare_Simovici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "1",
							"__text": "Semigrupuri libere"
						},
						{
							"_nr": "2",
							"__text": "Gramtici generative"
						},
						{
							"_nr": "II",
							"__text": "Limbaje regulate si limbaje independente de context"
						},
						{
							"_nr": "3",
							"__text": "Semiautomate si limbaje regulate"
						},
						{
							"_nr": "4",
							"__text": "Arbori de derivare"
						},
						{
							"_nr": "5",
							"__text": "Simplificarea gramaticilor de tip doi"
						},
						{
							"_nr": "6",
							"__text": "Forme normale pentru gramatici de tip doi"
						},
						{
							"_nr": "7",
							"__text": "Limbaje de tip ALGOL"
						},
						{
							"_nr": "8",
							"__text": "Proprietati locale ale limbajelor de tip doi"
						},
						{
							"_nr": "9",
							"__text": "Limbajde de tip doi si limbaje regulate"
						},
						{
							"_nr": "10",
							"__text": "Transductori"
						},
						{
							"_nr": "11",
							"__text": "Automate pushdown"
						},
						{
							"_nr": "12",
							"__text": "Expresii regulate generalizate"
						},
						{
							"_nr": "13",
							"__text": "Teoreme de reprezentare pentru limbaje de tip 3 i de tip doi"
						},
						{
							"_nr": "III",
							"__text": "Elemente de tehnici de compilare"
						},
						{
							"_nr": "14",
							"__text": "Problematica generala a proceselor de compilare"
						},
						{
							"_nr": "15",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "16",
							"__text": "Algoritmi generali de analiza sintactica"
						},
						{
							"_nr": "17",
							"__text": "Notatia poloneza"
						},
						{
							"_nr": "18",
							"__text": "Gramatici LR(k) si LL(k)"
						},
						{
							"_nr": "19",
							"__text": "Relatii de precedenta in gramatici de tipul doi"
						}
					]
				},
				"_id": "222",
				"_isbn": ""
			},
			{
				"titlu": "Limbaje formale si automate",
				"autori": {
					"autor": {
						"_nume": "Grigore Albeanu"
					}
				},
				"anAparitie": "2005",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "112",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_automate_Albeanu.jpg"
					},
					"__text": "Marilena Balan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbaje si expresii regulate"
						},
						{
							"_nr": "2",
							"__text": "Mecanisme generative fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Mecanisme pentru recunoasterea automata a multimilor regulate"
						},
						{
							"_nr": "4",
							"__text": "Optimizara automatelor finite"
						},
						{
							"_nr": "5",
							"__text": "Transformari asupra gramaticilor formale"
						},
						{
							"_nr": "6",
							"__text": "Forme normale. Lema Bar-Hillel"
						},
						{
							"_nr": "7",
							"__text": "Gramatici si automate"
						},
						{
							"_nr": "8",
							"__text": "Proprietati de inchidere"
						},
						{
							"_nr": "9",
							"__text": "Specificarea sintaxei limbajelor de progamare"
						},
						{
							"_nr": "10",
							"__text": "Introducere in analiza sintactica"
						},
						{
							"_nr": "11",
							"__text": "Exercitii recapitulare"
						}
					]
				},
				"_id": "223",
				"_isbn": "973-725-233-0"
			},
			{
				"titlu": "Sisteme expert. Teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Zaharie"
						},
						{
							"_nume": "Pavel Nastase"
						},
						{
							"_nume": "Felicia Albescu"
						},
						{
							"_nume": "Irina Bojan"
						},
						{
							"_nume": "Florin Mihai"
						},
						{
							"_nume": "Liana Anica-Popa"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Dual Tech",
				"numarPagini": "410",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_expert_Teorie_si_aplicatii_Zaharie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Structura si obiectivele sistemelor expert"
						},
						{
							"_nr": "III",
							"__text": "Metode de reprezentare a cunostiintelor"
						},
						{
							"_nr": "IV",
							"__text": "Functionarea sistemelor expert bazate pe reguli de deductie"
						},
						{
							"_nr": "V",
							"__text": "Limbajul de programare Turbo Prolog"
						},
						{
							"_nr": "VI",
							"__text": "Generatorul de sisteme expert VP-EXPERT"
						},
						{
							"_nr": "VII",
							"__text": "Generatorul de sisteme expert H-EXPERT"
						},
						{
							"_nr": "VIII",
							"__text": "Proiectarea si realizarea sistemelor expert"
						},
						{
							"_nr": "IX",
							"__text": "Teste grila"
						}
					]
				},
				"_id": "224",
				"_isbn": "973-99482-0-0"
			},
			{
				"titlu": "Sisteme expert",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Zaharie"
						},
						{
							"_nume": "Pavel Nastase"
						},
						{
							"_nume": "Felicia Albescu"
						},
						{
							"_nume": "Irina Bojan"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Stiinta si tehnica",
				"numarPagini": "314",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Ioan Albescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan Albescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_expert_Zaharia.jpg"
					},
					"__text": "Felicia Albescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Structura si obiectivele sistemelor expert"
						},
						{
							"_nr": "III",
							"__text": "Metode de reprezentare a cunostiintelor"
						},
						{
							"_nr": "IV",
							"__text": "Functionarea sistemelor expert bazate pe reguli de deductie"
						},
						{
							"_nr": "V",
							"__text": "Limbajul de programare Turbo Prolog"
						},
						{
							"_nr": "VI",
							"__text": "Generatorul de sisteme expert VP-EXPERT"
						},
						{
							"_nr": "VII",
							"__text": "Generatorul de sisteme expert H-EXPERT"
						},
						{
							"_nr": "VIII",
							"__text": "Proiectarea si realizarea sistemelor expert"
						}
					]
				},
				"_id": "225",
				"_isbn": "973-9236-28-6"
			},
			{
				"titlu": "Sisteme informatice pentru asistarea deciziei",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Zaharie"
						},
						{
							"_nume": "Felicia Albescu"
						},
						{
							"_nume": "Irina Bojan"
						},
						{
							"_nume": "Veronica Ivancenco"
						},
						{
							"_nume": "Corina Vasilescu"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Dual Tech",
				"numarPagini": "314",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_pentru_asistarea_deciziei_Zaharie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme de asistare a deciziei"
						},
						{
							"_nr": "II",
							"__text": "Sisteme informatice de asistare a deciziei bazate pe modele"
						},
						{
							"_nr": "III",
							"__text": "Sistem interactiv de asistare a deciziei financiare. Studiu de caz"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme pentru asistarea deciziei bazate pe sinteza si analiza datelor"
						},
						{
							"_nr": "4.1",
							"__text": "Depozite de date (Data warehouse)"
						},
						{
							"_nr": "4.2",
							"__text": "OLAP - On-Line Analytical Processing"
						},
						{
							"_nr": "4.3",
							"__text": "Suport pentru depozite de date si OLAP Microsoft SQL SERVER 7.0"
						},
						{
							"_nr": "4.4",
							"__text": "Limbaje de procesare a datelor multidimensionale"
						},
						{
							"_nr": "4.5",
							"__text": "Analiza datelor in mediul MS ACCESS - Aplicatie"
						},
						{
							"_nr": "4.6",
							"__text": "Analiza datelor in mediul MS OLAP - Aplicatie"
						},
						{
							"_nr": "V",
							"__text": "Analiza profitabilitatii activitatii unei asociatii - studiu de caz"
						},
						{
							"_nr": "VI",
							"__text": "Tehnologii pentru extragerea cunostintelor - Data Mining"
						}
					]
				},
				"_id": "226",
				"_isbn": "973-85525-1-6"
			},
			{
				"titlu": "Initiere in Turbo Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Irina Athanasiu"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "152",
				"pretOriginal": "10000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "37",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Turbo_Pascal_Kalisz.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Utilizarea mediului de programare Turbo Pascal"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Expresii booleene, instructiunea if"
						},
						{
							"_nr": "4",
							"__text": "Cicluri repeat si while"
						},
						{
							"_nr": "5",
							"__text": "Cicluri for"
						},
						{
							"_nr": "6",
							"__text": "Declaratii de tip, tipul subdomeniu, tipul tablou"
						},
						{
							"_nr": "7",
							"__text": "Tipul real. Prelucrari combinate"
						},
						{
							"_nr": "8",
							"__text": "Tipurile char si string"
						},
						{
							"_nr": "9",
							"__text": "Tipuri inregistrare. Fisiere"
						},
						{
							"_nr": "10",
							"__text": "Subprograme (I)"
						},
						{
							"_nr": "11",
							"__text": "Subprograme (II)"
						},
						{
							"_nr": "12",
							"__text": "Cautare si ordonare"
						}
					]
				},
				"_id": "227",
				"_isbn": "973-601-182-2"
			},
			{
				"titlu": "Turbo Pascal 6.0",
				"autori": {
					"autor": [
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Alexandru Panoiu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "224",
				"pretOriginal": "560",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "2",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_60_1992_Cristea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Partea I"
						},
						{
							"_nr": "1",
							"__text": "Structura sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Functionarea sistemului de calcul. Descrierea algoritmilor"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea programelor"
						},
						{
							"_nr": "II",
							"__text": "Partea II"
						},
						{
							"_nr": "4",
							"__text": "Structura programelor Pascal"
						},
						{
							"_nr": "5",
							"__text": "Tipuri simple de date. Constante si variabile"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni de citire/scriere"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni de atribuire"
						},
						{
							"_nr": "8",
							"__text": "Instructiune compusa"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni de selectie"
						},
						{
							"_nr": "10",
							"__text": "Instructiuni repetitive"
						},
						{
							"_nr": "11",
							"__text": "Abateri de la regulile programari structurate"
						},
						{
							"_nr": "12",
							"__text": "Tipuri simple de date definte de utilizator"
						},
						{
							"_nr": "13",
							"__text": "Tipuri tablou"
						},
						{
							"_nr": "14",
							"__text": "Subprograme"
						},
						{
							"_nr": "15",
							"__text": "Tipuri string (sir de caractere)"
						},
						{
							"_nr": "16",
							"__text": "Tipuri multime"
						},
						{
							"_nr": "17",
							"__text": "Tipuri inregistrare (record)"
						},
						{
							"_nr": "18",
							"__text": "Fisiere"
						},
						{
							"_nr": "19",
							"__text": "Tipuri referinta (pointer). Structuri dinamice"
						},
						{
							"_nr": "III",
							"__text": "Partea III"
						},
						{
							"_nr": "20",
							"__text": "Dezvoltarea sistematica a programelor complexe"
						},
						{
							"_nr": "21",
							"__text": "Organizarea programelor mari"
						},
						{
							"_nr": "22",
							"__text": "Facilitati de programare speciale"
						},
						{
							"_nr": "23",
							"__text": "Afisarea textelor"
						},
						{
							"_nr": "24",
							"__text": "Utilizareaa bibliotecilor grafice"
						},
						{
							"_nr": "25",
							"__text": "Programarea orietata pe obiecte"
						},
						{
							"_nr": "26",
							"__text": "Turbo Vision"
						}
					]
				},
				"_id": "228",
				"_isbn": "973-601-010-4"
			},
			{
				"titlu": "Concursuri de programare. Probleme si solutii",
				"autori": {
					"autor": [
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Cristian Tapus"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "304",
				"pretOriginal": "30000",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Concursuri_de_programare_probleme_si_solutii_Kalisz.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Enunturi"
						},
						{
							"_nr": "II",
							"__text": "Rezolvare"
						}
					]
				},
				"_id": "229",
				"_isbn": "973-601-447-9"
			},
			{
				"titlu": "Programarea in Visual Basic",
				"autori": {
					"autor": {
						"_nume": "Paul Fogarassy"
					}
				},
				"anAparitie": "1999",
				"editura": "Sedona",
				"numarPagini": "174",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Visual_Basic_Fogarassy.jpg"
					},
					"__text": "Paul Fogarassy"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Modul de lucru in Visual Basic"
						},
						{
							"_nr": "2",
							"__text": "Crearea unei aplicatii"
						},
						{
							"_nr": "3",
							"__text": "Programe controlate de evanimente"
						},
						{
							"_nr": "4",
							"__text": "Lucru cu ferestre (forms) si obiecte de control (controls)"
						},
						{
							"_nr": "5",
							"__text": "Lucrul cu meniurile"
						},
						{
							"_nr": "6",
							"__text": "Depanarea aplicatiilor (debugging)"
						},
						{
							"_nr": "7",
							"__text": "Accesarea bazelor de date"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii practice"
						}
					]
				},
				"_id": "230",
				"_isbn": "9739345-15-8"
			},
			{
				"titlu": "Microsoft Visual Basic 6.0 - Manualul programatorului",
				"autori": {
					"autor": [
						{
							"_nume": "John Clark Craig"
						},
						{
							"_nume": "Jeff Webb"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "736",
				"pretOriginal": "136000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "239",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Microsoft Visual Basic 5.0 Developer's Workshop",
					"editura": "Microsoft Press",
					"traducatori": {
						"traducator": [
							"Silvia Petrescu",
							"Anca Petrescu",
							"Cristian Mocanu"
						]
					},
					"_an": "1997",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Visual_Basic_5_0_Manualul_programatorului_Craig.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sa pornim la lucru cu Visual Basic 5"
						},
						{
							"_nr": "II",
							"__text": "Mastre, cum sa...?"
						},
						{
							"_nr": "III",
							"__text": "Exemple de aplicatii"
						}
					]
				},
				"_id": "231",
				"_isbn": "973-601-600-5"
			},
			{
				"titlu": "Bazele Visual Basic 4",
				"autori": {
					"autor": {
						"_nume": "Mark Steven Heyman"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "552",
				"pretOriginal": "30000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "155",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Essential Visual Basic 4",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Calin Suciu"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_Visual_Basic_4_Heyman.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul Visual Basic si mediul vizual"
						},
						{
							"_nr": "II",
							"__text": "Sa facem cunostinta cu barele cu instrumente"
						},
						{
							"_nr": "III",
							"__text": "Aplicatia Image Viewer - de la proiect la programul executabil"
						},
						{
							"_nr": "IV",
							"__text": "Desenare, animatie si jocuri"
						},
						{
							"_nr": "V",
							"__text": "Un program simplu de baze de date"
						},
						{
							"_nr": "VI",
							"__text": "Tratarea erorilor si depanarea programelor"
						},
						{
							"_nr": "VII",
							"__text": "Subiecte de programare avansata"
						},
						{
							"_nr": "VIII",
							"__text": "Crearea fisierelor Help de tip Windows 95"
						}
					]
				},
				"_id": "232",
				"_isbn": "973-601-548-3"
			},
			{
				"titlu": "Bazele C++ 4",
				"autori": {
					"autor": {
						"_nume": "Mickey Williams"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "528",
				"pretOriginal": "45000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "208",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Essential C++ 4",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Lian Claudiu Sebe"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_Visual_Cpp_4_Williams.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sistemul de dezvoltare Visual C++"
						},
						{
							"_nr": "II",
							"__text": "Limbajul C++"
						},
						{
							"_nr": "III",
							"__text": "Biblioteca Microsoft Foundation Class (MFC)"
						},
						{
							"_nr": "IV",
							"__text": "Mediul Windows"
						},
						{
							"_nr": "V",
							"__text": "Clase de control"
						},
						{
							"_nr": "VI",
							"__text": "Clase de vizualizare"
						},
						{
							"_nr": "VII",
							"__text": "Interfata grafica"
						},
						{
							"_nr": "VIII",
							"__text": "Diverse"
						}
					]
				},
				"_id": "233",
				"_isbn": "973-601-662-5"
			},
			{
				"titlu": "Grafica pe calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Zoea Racovita"
						},
						{
							"_nume": "Serban Petrescu"
						},
						{
							"_nume": "Gabriel Hera"
						},
						{
							"_nume": "Marius Zaharia"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "448",
				"pretOriginal": "17900",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "102",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_pe_calculator_Moldoveanu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme grafice"
						},
						{
							"_nr": "2",
							"__text": "Trasformari grafice bidimensionale"
						},
						{
							"_nr": "3",
							"__text": "Trasformari grafice tridimensionale"
						},
						{
							"_nr": "4",
							"__text": "Tehnici de interactiune"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi fundamentali de sinteza a imaginilor"
						},
						{
							"_nr": "6",
							"__text": "Curbe de forma libera"
						},
						{
							"_nr": "7",
							"__text": "Suprafete"
						},
						{
							"_nr": "8",
							"__text": "Algoritmi de determinare a vizibilitati obiectelor la afisarea scenelor 3D"
						},
						{
							"_nr": "9",
							"__text": "Redarea luminii si a umbrelor in imagini"
						},
						{
							"_nr": "10",
							"__text": "Texturi"
						}
					]
				},
				"_id": "234",
				"_isbn": "973-601-290-5"
			},
			{
				"titlu": "TCP/IP pentru Toti",
				"autori": {
					"autor": [
						{
							"_nume": "Marshall Wilensky"
						},
						{
							"_nume": "Candace Leiden"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "364",
				"pretOriginal": "20000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "159",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "TCP/IP for Dummies",
					"editura": "IDGBooks Worldwide",
					"traducatori": {
						"traducator": "Ileana Busac"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Ileana Busac"
				},
				"coperta": {
					"imagine": {
						"_path": "TCP_IP_pentru_toti_Leiden.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Chestiuni de baza si cuvinte tehnice (sau cum sa-i impresionati pe ceilalti la sedinte)"
						},
						{
							"_nr": "II",
							"__text": "TPC/IP de la supa la desert"
						},
						{
							"_nr": "III",
							"__text": "Tocanita TCP/IP - Un pic din asta, putin din cealalta"
						},
						{
							"_nr": "IV",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "235",
				"_isbn": "973-601-422-3"
			},
			{
				"titlu": "DHTML si CSS",
				"autori": {
					"autor": {
						"_nume": "Jason Cranfors Teaque"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "592",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "446",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "DHTML and CSS  for the Word wide Web: Visual QuickStart Guide",
					"editura": "Person Education",
					"traducatori": {
						"traducator": [
							"Cora Radulian",
							"Mihai Manastireanu",
							"Dan Pavelescu"
						]
					},
					"_an": "2001",
					"_editia": "2",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Catalin Sleam"
				},
				"coperta": {
					"imagine": {
						"_path": "DHTML_si_CSS_Teague.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Foi de stil cascada"
						},
						{
							"_nr": "II",
							"__text": "HTML dinamic"
						},
						{
							"_nr": "III",
							"__text": "Utilizarea limbajului DHTML si a intrumentelor CSS"
						},
						{
							"_nr": "IV",
							"__text": "Situri WEB dinamice"
						}
					]
				},
				"_id": "236",
				"_isbn": "973-20-0415-0"
			},
			{
				"titlu": "Microsoft Excel 2002",
				"autori": {
					"autor": {
						"_nume": "Patrick Blattner"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "792",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "424",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Special Edition Using Microsodt Excel 2002",
					"editura": "Que",
					"traducatori": {
						"traducator": "Andreea Lutic"
					},
					"_an": "2001",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Excel_2002_Blattner.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sa facem cunostinta cu Excel 2002"
						},
						{
							"_nr": "II",
							"__text": "Formatarea si tiparirea foilor de lucru Excel"
						},
						{
							"_nr": "III",
							"__text": "Utilizarea formulelor si functiilor"
						},
						{
							"_nr": "IV",
							"__text": "Utilizarea instrumentelor de analiza Excel"
						},
						{
							"_nr": "V",
							"__text": "Crearea si modificarea diagramelor"
						},
						{
							"_nr": "VI",
							"__text": "Utilizarea programului Excel pentru gestionarea afacerilor"
						},
						{
							"_nr": "VII",
							"__text": "Utilizarea programului Excel cu alte aplicatii Microsoft Office"
						}
					]
				},
				"_id": "237",
				"_isbn": "973-20-0188-7"
			},
			{
				"titlu": "PHP pentru WORD WIDE WEB in imagini",
				"autori": {
					"autor": {
						"_nume": "Larry Ullman"
					}
				},
				"anAparitie": "2004",
				"editura": "Teora USA LLC",
				"numarPagini": "340",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "PHP for WORD WIDE WEB : Visual Quickstart Guide",
					"editura": "Person Education",
					"traducatori": {
						"traducator": [
							"Cora Radulian",
							"Mihai Manastireanu",
							"Dan Pavelescu"
						]
					},
					"_an": "2004",
					"_editia": "2",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "PHP_pentru_World_Wide_Web_in_imagini_Ullman.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Primi pasi cu PHP"
						},
						{
							"_nr": "2",
							"__text": "Variabile"
						},
						{
							"_nr": "3",
							"__text": "PHP si formularele HTML"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea numerelor"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea sirurilor"
						},
						{
							"_nr": "6",
							"__text": "Structuri de control"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea matricilor"
						},
						{
							"_nr": "8",
							"__text": "Crearea aplicatiilor Web"
						},
						{
							"_nr": "9",
							"__text": "Cookie-urile si sesiunile"
						},
						{
							"_nr": "10",
							"__text": "Crearea functiilor"
						},
						{
							"_nr": "11",
							"__text": "Fisiere si directoare"
						},
						{
							"_nr": "12",
							"__text": "Introducere in baze de date"
						},
						{
							"_nr": "13",
							"__text": "Expresi regulate"
						},
						{
							"_nr": "Anexa A",
							"__text": "Instalare si configurare"
						},
						{
							"_nr": "Anexa B",
							"__text": "Resurse"
						}
					]
				},
				"_id": "238",
				"_isbn": "1-59496-020-8"
			},
			{
				"titlu": "Dezvoltarea paginilor Web",
				"autori": {
					"autor": {
						"_nume": "Mihai Scortaru"
					}
				},
				"anAparitie": "1996",
				"editura": "InfoData",
				"numarPagini": "176",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Bogdan Patrut"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Scortaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_paginilor_web_Scortaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Internet Explorer"
						},
						{
							"_nr": "3",
							"__text": "Netscape Navigator"
						},
						{
							"_nr": "4",
							"__text": "Motoare de cautare"
						},
						{
							"_nr": "5",
							"__text": "Introducere in HTML"
						},
						{
							"_nr": "6",
							"__text": "Formatarea textelor"
						},
						{
							"_nr": "7",
							"__text": "Adaugarea imaginilor"
						},
						{
							"_nr": "8",
							"__text": "Legaturi hipertext"
						},
						{
							"_nr": "9",
							"__text": "Alte notiuni HTML"
						},
						{
							"_nr": "10",
							"__text": "Harti de imagini"
						},
						{
							"_nr": "11",
							"__text": "Tabele"
						},
						{
							"_nr": "12",
							"__text": "Cadre"
						},
						{
							"_nr": "13",
							"__text": "Formulare"
						},
						{
							"_nr": "14",
							"__text": "Interfata CGI"
						},
						{
							"_nr": "15",
							"__text": "Modele de stiluri"
						},
						{
							"_nr": "16",
							"__text": "Obiecte"
						},
						{
							"_nr": "17",
							"__text": "Informatii referitpare la documentele HTML"
						},
						{
							"_nr": "18",
							"__text": "Script-uri"
						}
					]
				},
				"_id": "239",
				"_isbn": "973-87774-4-5"
			},
			{
				"titlu": "Visual Basic pentru toti",
				"autori": {
					"autor": {
						"_nume": "Wahllace Wang"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "432",
				"pretOriginal": "17900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "101",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Visual Basic 3 For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Cristina Botez"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": [
						"Cristian Mihai",
						"Mihai Manastireanu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Cristian Matache"
				},
				"coperta": {
					"imagine": {
						"_path": "Visual_Basic_3_pentru_toti_Wang.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Orezentarea pentru incepatori a limbajului Visual Basic"
						},
						{
							"_nr": "II",
							"__text": "Cum sa proiectati o interata cu utilizatorul"
						},
						{
							"_nr": "III",
							"__text": "Meniuri care va fac programul mai putin urat"
						},
						{
							"_nr": "IV",
							"__text": "Principiile de baza ale scrierii instructiunilor care alcatuiesc un program"
						},
						{
							"_nr": "V",
							"__text": "Cum sa luati decizii (un lucru pe care incetati sa-l mai faceti dupa ce va casatoriti)"
						},
						{
							"_nr": "VI",
							"__text": "Bucle si iar bucle (sau \"Chiar trebuie sa ma repet?\")"
						},
						{
							"_nr": "VII",
							"__text": "Cum sa scrieti subprograme (astfel incat sa nu va pierdeti mintile chiar dintr-o data)"
						},
						{
							"_nr": "VIII",
							"__text": "Program care gestioneaza baze de date.Cum sa tipariti la imprimanta"
						},
						{
							"_nr": "IX",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "240",
				"_isbn": "973-601-289-1"
			},
			{
				"titlu": "Borland C++ pentru toti",
				"autori": {
					"autor": {
						"_nume": "Michael Hyman"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "496",
				"pretOriginal": "18900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "86",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Borland C++ For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": [
						"Cristian Mihai",
						"Cora Radulian"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": ">Gheorghe Popescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Borland_Cpp_pentru_toti_Hyman.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Manual rapid de Borland C++ 4.0"
						},
						{
							"_nr": "II",
							"__text": "Concepte fundamentale ale programarii in C++"
						},
						{
							"_nr": "III",
							"__text": "Elemente de programare orientata pe obiecte"
						},
						{
							"_nr": "IV",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "241",
				"_isbn": "973-601-288-3"
			},
			{
				"titlu": "Modernizarea si depanarea calculatoarelor pentru toti",
				"autori": {
					"autor": {
						"_nume": "Andy Rathbone"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "352",
				"pretOriginal": "20000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "146",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Upgrading and Fixing PCs For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Dan Soloveanu"
					},
					"_an": "1995",
					"_editia": "2",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": [
						"Cristian Mihai",
						"Mihai Manastireanu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Marcel Stanciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modernizarea_si_depanarea_calculatoarelor_pentru_toti_Rathbone.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Luati-va inima in dinti"
						},
						{
							"_nr": "II",
							"__text": "Componente din PC pe care le puteti vedea (perifericele)"
						},
						{
							"_nr": "III",
							"__text": "Chestiile care stau ascunse in interiorul PC-ului dvs."
						},
						{
							"_nr": "IV",
							"__text": "Cum ii spuneti calulatorului ce ati facut"
						},
						{
							"_nr": "V",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "242",
				"_isbn": "973-601-384-7"
			},
			{
				"titlu": "Design Patterns. Sabloane de proiectare. Elemente de software reutilizabil orientat pe obiect",
				"autori": {
					"autor": [
						{
							"_nume": "Erich Gamma"
						},
						{
							"_nume": "Richard Helm"
						},
						{
							"_nume": "Ralph Johnson"
						},
						{
							"_nume": "John Vlissides"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "348",
				"pretOriginal": "0",
				"pretCumparare": "534100",
				"traducere": {
					"titluOriginal": "Design Patterns",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Radu Biris"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Design_Patterns_Sabloane_de_proiectare_Elemente_de_software_reuntilizabil_orientat_pe_obiect_Gamma.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Un studiu de caz: proiectarea unui editor de documente"
						},
						{
							"_nr": "3",
							"__text": "Sabloane creationale"
						},
						{
							"_nr": "4",
							"__text": "Sabloane structurale"
						},
						{
							"_nr": "5",
							"__text": "Sabloane comportamentale"
						},
						{
							"_nr": "6",
							"__text": "Concluzie"
						}
					]
				},
				"_id": "243",
				"_isbn": "973-20-0254-9"
			},
			{
				"titlu": "Aplicatii Java pentru intenet si intraneturi",
				"autori": {
					"autor": {
						"_nume": "Mark Watson"
					}
				},
				"anAparitie": "1999",
				"editura": "All",
				"numarPagini": "326",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Intelligent Java applications for Internt and intranets",
					"editura": "",
					"traducatori": {
						"traducator": "Norocel Munteanu"
					},
					"_an": "1997",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastirenu"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_Java_inteligente_pentru_internet_si_intraneturi_Watson.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in conceptul de inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Interfata utilizator JAVA. Cadrul de lucru pentru testarea programelor AI"
						},
						{
							"_nr": "3",
							"__text": "Inteligenta artificiala distribuita"
						},
						{
							"_nr": "4",
							"__text": "Retele neurale"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi genetici"
						},
						{
							"_nr": "6",
							"__text": "Procesarea limbajului natural"
						},
						{
							"_nr": "7",
							"__text": "Agenti AI"
						},
						{
							"_nr": "8",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea algoritmilor genetici pentru crearea de programe de jocuri"
						},
						{
							"_nr": "10",
							"__text": "Utilizarea resurselor neuronale pentru modelarea algorimilor de recunoastere a scrisului"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea algoritmilor de tip greedy pentru optimizarea retlor neuronale"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea algoritmilor genetici in optimizarea retelelor neuronale"
						},
						{
							"_nr": "13",
							"__text": "Interogarile in limbaj natural in World Wide Web"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea agentilor Java de colectare a datelor"
						}
					]
				},
				"_id": "244",
				"_isbn": "973-684-014-X"
			},
			{
				"titlu": "Constructia compilatoarelor: algoritmi fundamentali",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Grigoras"
					}
				},
				"anAparitie": "2005",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "270",
				"pretOriginal": "0",
				"pretCumparare": "297600",
				"coperta": {
					"imagine": {
						"_path": "Constructia_compilatoarelor_Algoritmi_fundamentali_Grigoras.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbaje"
						},
						{
							"_nr": "2",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "3",
							"__text": "Analiza sintactica descendenta"
						},
						{
							"_nr": "4",
							"__text": "Analiza sintactica baza pe relatii de precendenta"
						},
						{
							"_nr": "5",
							"__text": "Analiza sintactica in gramatici LR"
						},
						{
							"_nr": "6",
							"__text": "Generatoarer de analizatoare sintactice"
						},
						{
							"_nr": "7",
							"__text": "Analiza semantica"
						}
					]
				},
				"_id": "245",
				"_isbn": "973-703-084-2"
			},
			{
				"titlu": "Limbaje formale si teoria automatelor: teorie si practica",
				"autori": {
					"autor": [
						{
							"_nume": "Teoder Jucan"
						},
						{
							"_nume": "Stefan Andrei"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "328",
				"pretOriginal": "0",
				"pretCumparare": "113400",
				"redactori": {
					"redactor": "Dana Lungu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_teoria_automatelor_Teorie_si_practica_Jucan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ierarhia lui Chomsky. Generari de limbaje"
						},
						{
							"_nr": "2",
							"__text": "Automate finite"
						},
						{
							"_nr": "3",
							"__text": "Limbaje independente de context"
						},
						{
							"_nr": "4",
							"__text": "Masini Turing si automate liniar marginite"
						},
						{
							"_nr": "Anexa A",
							"__text": "Implementari ale algoritmilor din carte"
						},
						{
							"_nr": "Anexa B",
							"__text": "Aplicatii ale limbajelor formale in descrierea limbajelor de programare Pascal si Java"
						}
					]
				},
				"_id": "246",
				"_isbn": "973-8243-41-6"
			},
			{
				"titlu": "Structuri de date si algoritmi in Java",
				"autori": {
					"autor": [
						{
							"_nume": "Mitchell Waite"
						},
						{
							"_nume": "Robert Lafore"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "592",
				"pretOriginal": "160000",
				"pretCumparare": "150000",
				"colectia": {
					"_numarul": "283",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Data Structures and Algorithms in Java",
					"editura": "Waite Group Press",
					"traducatori": {
						"traducator": [
							"Bogdan Pisai",
							"Diana Pisai"
						]
					},
					"_an": "1998",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_si_algoritmi_in_Java_Waite.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Tablouri"
						},
						{
							"_nr": "3",
							"__text": "Sortarea simpla"
						},
						{
							"_nr": "4",
							"__text": "Stive si cozi"
						},
						{
							"_nr": "5",
							"__text": "Liste inlantuite"
						},
						{
							"_nr": "6",
							"__text": "Recursivitatea"
						},
						{
							"_nr": "7",
							"__text": "Metode avansate de sortare"
						},
						{
							"_nr": "8",
							"__text": "Arbori binari"
						},
						{
							"_nr": "9",
							"__text": "Arbori bicolori"
						},
						{
							"_nr": "10",
							"__text": "Arbori 2-3-4 si memorarea externa a datelor"
						},
						{
							"_nr": "11",
							"__text": "Tabele de dispersie"
						},
						{
							"_nr": "12",
							"__text": "Movile"
						},
						{
							"_nr": "13",
							"__text": "Grafuri"
						},
						{
							"_nr": "14",
							"__text": "Grafuri ponderate"
						},
						{
							"_nr": "15",
							"__text": "Ce utilizam si cand"
						}
					]
				},
				"_id": "247",
				"_isbn": "973-20-0186-0"
			},
			{
				"titlu": "Java 2 fara profesor in 21 de zile",
				"autori": {
					"autor": [
						{
							"_nume": "Laura Lemay"
						},
						{
							"_nume": "Rogers Cadenhead"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "552",
				"pretOriginal": "350000",
				"pretCumparare": "300000",
				"colectia": {
					"_numarul": "338",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Teach Yourself Java 2 in 21 Days",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Silvia Petrescu"
					},
					"_an": "1999",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Corolina Scumpu"
				},
				"coperta": {
					"imagine": {
						"_path": "Java_2_fara_profesor_in_21_de_zile_Lemary.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Saptamana 1",
							"__text": "Sa incepem cu programarea Java"
						},
						{
							"_nr": "1",
							"__text": "O portie de Java"
						},
						{
							"_nr": "2",
							"__text": "Gustul programarii orientate pe obiect"
						},
						{
							"_nr": "3",
							"__text": "ABC-ul Java"
						},
						{
							"_nr": "4",
							"__text": "Lectii despre obiecte"
						},
						{
							"_nr": "5",
							"__text": "Liste, conditii si cicluri"
						},
						{
							"_nr": "6",
							"__text": "Crearea claselor"
						},
						{
							"_nr": "7",
							"__text": "Folosirea metodelor pentru indeplinirea sarcinilor"
						},
						{
							"_nr": "Saptamana 2",
							"__text": "Tehnici Java avansate"
						},
						{
							"_nr": "8",
							"__text": "Crearea de programe interactive pentru Web"
						},
						{
							"_nr": "9",
							"__text": "Imbunatarirea aspectului programelor prin grafica, fonturi si culori"
						},
						{
							"_nr": "10",
							"__text": "Introducerea de imagini, animatie si sunet"
						},
						{
							"_nr": "11",
							"__text": "Construirea unor interfete utilizator simple spre applet-uri"
						},
						{
							"_nr": "12",
							"__text": "Aranjarea componentelor intr-o interfata utilizator"
						},
						{
							"_nr": "13",
							"__text": "Raspunsul la actiunile utilizatorului intr-un applet"
						},
						{
							"_nr": "14",
							"__text": "Dezvoltarea de interfete utilizator avansate folosind AWT"
						},
						{
							"_nr": "Saptamana 3",
							"__text": "Imbogatirea cunostintelor despre Java"
						},
						{
							"_nr": "15",
							"__text": "Rolul claselor: pachete, interfete si alte caracterisitici"
						},
						{
							"_nr": "16",
							"__text": "Cazuri de exeptie: tratarea erorilor si securitatea"
						},
						{
							"_nr": "17",
							"__text": "Manipulare datelor prin fluxuri Java"
						},
						{
							"_nr": "18",
							"__text": "Comunicarea prin Internet"
						},
						{
							"_nr": "19",
							"__text": "JavaBeans si alte facilatati avansate"
						},
						{
							"_nr": "20",
							"__text": "Proiectarea unei interfete utilizator cu ajutorul Swing"
						},
						{
							"_nr": "21",
							"__text": "Tratarea evenimentelor utilizator cu ajutorul Swing"
						},
						{
							"_nr": "Anexe",
							"__text": "Anexe"
						},
						{
							"_nr": "A",
							"__text": "Limbajul Java pe scurt"
						},
						{
							"_nr": "B",
							"__text": "Biblioteca de clase Java"
						},
						{
							"_nr": "C",
							"__text": "Resurse Java in Internet"
						},
						{
							"_nr": "D",
							"__text": "Configurarea Java Development Kit"
						},
						{
							"_nr": "E",
							"__text": "Folosirea unui editor de text pentru JDK"
						}
					]
				},
				"_id": "248",
				"_isbn": "973-20-0378-2"
			},
			{
				"titlu": "Java 1001 secrete pentru programatori",
				"autori": {
					"autor": [
						{
							"_nume": "Mark C. Chan"
						},
						{
							"_nume": "Steven W. Griffith"
						},
						{
							"_nume": "Anthony F. Iasi"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "704",
				"pretOriginal": "350000",
				"pretCumparare": "250000",
				"colectia": {
					"_numarul": "358",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "1001 JAVA Programmer's Tips",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": [
							"Constanta Dumitrescu",
							"Eugen Dumitrescu"
						]
					},
					"_an": "1997",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Java_1001_secrete_pentru_programtori_Chan.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Primii pasi"
						},
						{
							"_nr": "2",
							"__text": "Bazele programarii"
						},
						{
							"_nr": "3",
							"__text": "Programarea orientata pe obiecte"
						},
						{
							"_nr": "4",
							"__text": "Definirea unei clase proprii"
						},
						{
							"_nr": "5",
							"__text": "Programarea Java in detaliu"
						},
						{
							"_nr": "6",
							"__text": "Diferente intre Java si C/C++"
						},
						{
							"_nr": "7",
							"__text": "Bazele de limbaj"
						},
						{
							"_nr": "8",
							"__text": "Functiile matematice ale limbajului Java"
						},
						{
							"_nr": "9",
							"__text": "Functii de timp in Java"
						},
						{
							"_nr": "10",
							"__text": "Utilizarea tablourilor pentru stocarea datelor"
						},
						{
							"_nr": "11",
							"__text": "Tratarea evenimentelor"
						},
						{
							"_nr": "12",
							"__text": "Abstract Windowing Toolkit"
						},
						{
							"_nr": "13",
							"__text": "Programare multimedia"
						},
						{
							"_nr": "14",
							"__text": "Grafica 3-D"
						},
						{
							"_nr": "15",
							"__text": "Operatii cu fluxuri I/O"
						},
						{
							"_nr": "16",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "17",
							"__text": "Programarea cu fire de executie"
						},
						{
							"_nr": "18",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "19",
							"__text": "Programarea in retea"
						},
						{
							"_nr": "20",
							"__text": "Gestionarea memoriei"
						},
						{
							"_nr": "21",
							"__text": "JDBC (Java Database Connectivity)"
						},
						{
							"_nr": "22",
							"__text": "Serializarea obiectelor"
						},
						{
							"_nr": "23",
							"__text": "Securitatea in Java"
						},
						{
							"_nr": "24",
							"__text": "Depanarea in Java"
						},
						{
							"_nr": "25",
							"__text": "Controale personalizate"
						},
						{
							"_nr": "26",
							"__text": "Exemple de aplicatii"
						},
						{
							"_nr": "27",
							"__text": "Incheiere"
						}
					]
				},
				"_id": "249",
				"_isbn": "973-20-0169-0"
			},
			{
				"titlu": "Enterprise JavaBeans 2.0",
				"autori": {
					"autor": [
						{
							"_nume": "Chuck Cavaness"
						},
						{
							"_nume": "Brian Keeton"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "592",
				"pretOriginal": "0",
				"pretCumparare": "742100",
				"traducere": {
					"titluOriginal": "Special Edition Using Enterprise JavaBeans 2.0",
					"editura": "Que",
					"traducatori": {
						"traducator": "Radu Biris"
					},
					"_an": "2002",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Enterprise_JavaBeans_2_avaness.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Dezvoltarea componentelor EJB"
						},
						{
							"_nr": "1",
							"__text": "Prezentarea aplicatiilor de intreprindere"
						},
						{
							"_nr": "2",
							"__text": "Punerea in scena - un exemplu de site de licitatie"
						},
						{
							"_nr": "3",
							"__text": "Concepte EJB"
						},
						{
							"_nr": "4",
							"__text": "Serviciul Java Naming and Directory Interface - JNDI"
						},
						{
							"_nr": "5",
							"__text": "Componente entitate"
						},
						{
							"_nr": "6",
							"__text": "Persistenta gestionata de componente"
						},
						{
							"_nr": "7",
							"__text": "Persistenta gestionata de container"
						},
						{
							"_nr": "8",
							"__text": "Limbajul de interogare EJB"
						},
						{
							"_nr": "9",
							"__text": "Componente de sesiune"
						},
						{
							"_nr": "10",
							"__text": "Java Message Service (JMS)"
						},
						{
							"_nr": "11",
							"__text": "Componente bazate pe mesaj"
						},
						{
							"_nr": "12",
							"__text": "Tranzactii"
						},
						{
							"_nr": "13",
							"__text": "Gestionarea exceptiilor"
						},
						{
							"_nr": "14",
							"__text": "Proiectarea si gestionarea masurilor de securitate"
						},
						{
							"_nr": "15",
							"__text": "Integrare"
						},
						{
							"_nr": "II",
							"__text": "Design si performanta"
						},
						{
							"_nr": "16",
							"__text": "Modele si strategii in proiectarea EJB"
						},
						{
							"_nr": "17",
							"__text": "Probleme de performanta"
						},
						{
							"_nr": "18",
							"__text": "Performanta si testarea la solicitare a aplicatiilor"
						},
						{
							"_nr": "III",
							"__text": "Construirea stratului Web"
						},
						{
							"_nr": "19",
							"__text": "Construirea unui strat de prezentare pentru EJB"
						},
						{
							"_nr": "IV",
							"__text": "Concepte avansate"
						},
						{
							"_nr": "20",
							"__text": "Distribuirea si interoperabilitatea EJB"
						},
						{
							"_nr": "21",
							"__text": "Servicii orizontale"
						},
						{
							"_nr": "22",
							"__text": "Concepte EJB de lucru in grupuri"
						},
						{
							"_nr": "23",
							"__text": "Restrictiile de programare din EJB 2.0"
						},
						{
							"_nr": "V",
							"__text": "Anexe"
						},
						{
							"_nr": "A",
							"__text": "EJB 2.0 API"
						},
						{
							"_nr": "B",
							"__text": "Modificari fata de EJB 1.1"
						}
					]
				},
				"_id": "250",
				"_isbn": "973-601-916-0"
			},
			{
				"titlu": "Servicii WEB cu Java. XML, SOAP, WSDL si UDDI",
				"autori": {
					"autor": [
						{
							"_nume": "Steve Graham"
						},
						{
							"_nume": "Simeon Simeonov"
						},
						{
							"_nume": "Toufic Boubez"
						},
						{
							"_nume": "Doug Davis"
						},
						{
							"_nume": "Glen Daniels"
						},
						{
							"_nume": "Yuichi Nakamura"
						},
						{
							"_nume": "Ryp Neyama"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "506",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "463",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Building Web Services with Java - Making sense of XML, SOAP, WSDL and UDDI",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Tiberiu Ionescu"
					},
					"_an": "2002",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Servicii_Web_cu_Java_XML_SOAP_WSDL_si_UDDI_Graham.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea serviciilor Web"
						},
						{
							"_nr": "2",
							"__text": "Introducere in XML"
						},
						{
							"_nr": "3",
							"__text": "Simple Object Access Protocol (SOAP)"
						},
						{
							"_nr": "4",
							"__text": "Crearea serviciilor Web"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea SOAP pentru e-business"
						},
						{
							"_nr": "6",
							"__text": "Descrierea serviciilor Web"
						},
						{
							"_nr": "7",
							"__text": "Descoperirea serviciilor Web"
						},
						{
							"_nr": "8",
							"__text": "Interoperabilitate, unelte si produse middleware"
						},
						{
							"_nr": "9",
							"__text": "Concepte de viitor"
						}
					]
				},
				"_id": "251",
				"_isbn": "973-20-0470-3"
			},
			{
				"titlu": "Java. Ghid practic pentru programatori avansati",
				"autori": {
					"autor": {
						"_nume": "Joshua Bloch"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "196",
				"pretOriginal": "0",
				"pretCumparare": "272500",
				"colectia": {
					"_numarul": "440",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Effective Java Programming Language Guide",
					"editura": "Sun Microsystems",
					"traducatori": {
						"traducator": "Andreea Lutic"
					},
					"_an": "2001",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Java_Ghid_practic_pentru_programatori_avanati_Bloch.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Crearea si distrugerea obiectelor"
						},
						{
							"_nr": "3",
							"__text": "Metode comune tuturor obiectelor"
						},
						{
							"_nr": "4",
							"__text": "Clase si interfete"
						},
						{
							"_nr": "5",
							"__text": "Inlocuitori ai structurilor C"
						},
						{
							"_nr": "6",
							"__text": "Metode"
						},
						{
							"_nr": "7",
							"__text": "Programare generala"
						},
						{
							"_nr": "8",
							"__text": "Exceptii"
						},
						{
							"_nr": "9",
							"__text": "Fire de executie"
						},
						{
							"_nr": "10",
							"__text": "Serializarea"
						}
					]
				},
				"_id": "252",
				"_isbn": "973-20-0406-1"
			},
			{
				"titlu": "Dezvoltarea aplicatiilor JavaServer Pages",
				"autori": {
					"autor": [
						{
							"_nume": "Ben Forta"
						},
						{
							"_nume": "Edwin Smith"
						},
						{
							"_nume": "Scott Stirling"
						},
						{
							"_nume": "Larry Kim"
						},
						{
							"_nume": "Roger Kerr"
						},
						{
							"_nume": "David Aden"
						},
						{
							"_nume": "Andre Lei"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "350000",
				"colectia": {
					"_numarul": "406",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "JavaServer Pages application development",
					"editura": "Sams",
					"traducatori": {
						"traducator": [
							"Aurelia Merezeanu",
							"Daniel Merezeanu"
						]
					},
					"_an": "2001",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_aplicatiilor_JavaServerPages_Forta.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este JSP"
						},
						{
							"_nr": "2",
							"__text": "Crearea unui pagini JSP"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea elementelor de scriptare"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea obiectelor disponibile"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea claselor Bean"
						},
						{
							"_nr": "6",
							"__text": "Conectarea paginilor"
						},
						{
							"_nr": "7",
							"__text": "Lucrul cu formulare"
						},
						{
							"_nr": "8",
							"__text": "Interactiune cu bazele dedate"
						},
						{
							"_nr": "9",
							"__text": "Securitatea aplicatiilor"
						},
						{
							"_nr": "10",
							"__text": "Gestionarea starilor sesiunii"
						},
						{
							"_nr": "11",
							"__text": "Integrarea cu posta electonica"
						},
						{
							"_nr": "12",
							"__text": "Dezvoltarea etichetelor personalizate"
						},
						{
							"_nr": "13",
							"__text": "Interactiunea cu clasele Enterprise JavaBean"
						},
						{
							"_nr": "14",
							"__text": "Tratarea erorilor"
						},
						{
							"_nr": "15",
							"__text": "Depanarea si detectarea surselor de eroare"
						}
					]
				},
				"_id": "253",
				"_isbn": "973-20-0245-X"
			},
			{
				"titlu": "Access 2002 pentru incepatori",
				"autori": {
					"autor": {
						"_nume": "Joe Habracken"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "216",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"traducere": {
					"titluOriginal": "Microsoft Access 2002, 10 minute guide",
					"editura": "Que",
					"traducatori": {
						"traducator": "Cora Radulian"
					},
					"_an": "2002",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Catalin Sleam"
				},
				"coperta": {
					"imagine": {
						"_path": "Access_2002_pentru_inceptatori_Habraken.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este nou in Access 2002"
						},
						{
							"_nr": "2",
							"__text": "Lucrul in Access"
						},
						{
							"_nr": "3",
							"__text": "Crearea unei baze de date"
						},
						{
							"_nr": "4",
							"__text": "Crearea unui tabel cu Table Wizard"
						},
						{
							"_nr": "5",
							"__text": "Crearea unui tabel pornind de la zero"
						},
						{
							"_nr": "6",
							"__text": "Editarea structurii unui tabel"
						},
						{
							"_nr": "7",
							"__text": "Introducerea datelor intr-un tabel"
						},
						{
							"_nr": "8",
							"__text": "Editarea datelor dintr-un tabel"
						},
						{
							"_nr": "9",
							"__text": "Formatarea tabelelor Access"
						},
						{
							"_nr": "10",
							"__text": "Obtinerea asistentei in Microsoft Access"
						},
						{
							"_nr": "11",
							"__text": "Crearea relatiilor dintre tabele"
						},
						{
							"_nr": "12",
							"__text": "Crearea unui formular simplu"
						},
						{
							"_nr": "13",
							"__text": "Modifcarea unui formular"
						},
						{
							"_nr": "14",
							"__text": "Adaugarea controlelor speciale in formulare"
						},
						{
							"_nr": "15",
							"__text": "Cautarea informatiilor in baza dumneavoastra de date"
						},
						{
							"_nr": "16",
							"__text": "Sortarea, filtrarea si indexarea datelor"
						},
						{
							"_nr": "17",
							"__text": "Crearea unei interogari simple"
						},
						{
							"_nr": "18",
							"__text": "Crearea interogrilor pornind de la zero"
						},
						{
							"_nr": "19",
							"__text": "Crearea unui raport simplu"
						},
						{
							"_nr": "20",
							"__text": "Particularizarea unui raport"
						},
						{
							"_nr": "21",
							"__text": "Exploatarea realtiilor din baza de date"
						},
						{
							"_nr": "22",
							"__text": "Tiparirea obiectelor Access"
						}
					]
				},
				"_id": "254",
				"_isbn": "973-20-0250-6"
			},
			{
				"titlu": "Ajax in lectii de 10 minute",
				"autori": {
					"autor": {
						"_nume": "Phil Ballard"
					}
				},
				"anAparitie": "2007",
				"editura": "Teora USA LLC",
				"numarPagini": "232",
				"pretOriginal": "0",
				"pretCumparare": "195000",
				"traducere": {
					"titluOriginal": "Sams Teach Yourself Ajax in 10 Minutes",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Radu Biris"
					},
					"_an": "2006",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Ajax_in_lectii_de_10_minute_Ballard.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "O recapitulare a tehnologiilor Web"
						},
						{
							"_nr": "1",
							"__text": "Anatomia unui site Web"
						},
						{
							"_nr": "2",
							"__text": "Scrierea paginilor Web in HTML"
						},
						{
							"_nr": "3",
							"__text": "Trimiterea cererilor folosind HTTP"
						},
						{
							"_nr": "4",
							"__text": "Programare pe parte de client cu JavaScript"
						},
						{
							"_nr": "5",
							"__text": "Programare pe parte de server in PHP"
						},
						{
							"_nr": "6",
							"__text": "O scurta introducere in XML"
						},
						{
							"_nr": "II",
							"__text": "Prezentare Ajax"
						},
						{
							"_nr": "7",
							"__text": "Anatomia unei aplicatii Ajax"
						},
						{
							"_nr": "8",
							"__text": "Obiectul XMLHTTPRequest"
						},
						{
							"_nr": "9",
							"__text": "Discutai cu serverul"
						},
						{
							"_nr": "10",
							"__text": "Utilizarea datelor returnate"
						},
						{
							"_nr": "11",
							"__text": "Prima noastra aplicatie Ajax"
						},
						{
							"_nr": "III",
							"__text": "Tehnologii Ajax complexe"
						},
						{
							"_nr": "12",
							"__text": "Returnarea datelor sub forma de text"
						},
						{
							"_nr": "13",
							"__text": "AHAH - HTML si HTML asincrone"
						},
						{
							"_nr": "14",
							"__text": "Returnarea datelor in forma XML"
						},
						{
							"_nr": "15",
							"__text": "Serviciile Web si protocolul REST"
						},
						{
							"_nr": "16",
							"__text": "Serviciile Web care utilizeaza SOAP"
						},
						{
							"_nr": "17",
							"__text": "O biblioteca JavaScropt pentru Ajax"
						},
						{
							"_nr": "18",
							"__text": "Erori Ajax"
						},
						{
							"_nr": "IV",
							"__text": "Resurse Ajax comerciale si cu sursa deschisa"
						},
						{
							"_nr": "19",
							"__text": "Pachetul de instrumente prototype.js"
						},
						{
							"_nr": "20",
							"__text": "Utilizarea Rico"
						},
						{
							"_nr": "21",
							"__text": "Utilizarea XOAD"
						}
					]
				},
				"_id": "255",
				"_isbn": "978-1-59496-544-9"
			},
			{
				"titlu": "Java 2 Enterprise Edition (J2EE). Aplicatii multimedia",
				"autori": {
					"autor": [
						{
							"_nume": "Mircea-Florin Vaida"
						},
						{
							"_nume": "Cosmin Porumb"
						},
						{
							"_nume": "Radu-Vasile Fotea"
						},
						{
							"_nume": "Florin-Ovidu Hurducas"
						},
						{
							"_nume": "Liviu Lazar"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Albastra",
				"numarPagini": "546",
				"pretOriginal": "0",
				"pretCumparare": "440000",
				"colectia": {
					"_numarul": "144",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Java_2_Enterprise_Edition_J2EE_Aplicatii_multimedia_Vaida.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea mediului Java"
						},
						{
							"_nr": "1.1",
							"__text": "Introducere"
						},
						{
							"_nr": "1.2",
							"__text": "Tehnologia Java"
						},
						{
							"_nr": "1.3",
							"__text": "Descrierea limbajului Java prin comparatie cu limbajul C/C++"
						},
						{
							"_nr": "1.4",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "2",
							"__text": "Introducere in Java 2 Enterprise Edition"
						},
						{
							"_nr": "2.1",
							"__text": "Concepte conexe J2EE"
						},
						{
							"_nr": "2.2",
							"__text": "Platforma Java 2 Enterprise Edition"
						},
						{
							"_nr": "2.3",
							"__text": "Introducere in tehnologia Enterprise JavaBeans (EJB)"
						},
						{
							"_nr": "2.4",
							"__text": "Session Beans"
						},
						{
							"_nr": "2.5",
							"__text": "Entity Beans"
						},
						{
							"_nr": "2.6",
							"__text": "Tranzactiile in J2EE"
						},
						{
							"_nr": "2.7",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "3",
							"__text": "Componente Web"
						},
						{
							"_nr": "3.1",
							"__text": "Introducere"
						},
						{
							"_nr": "3.2",
							"__text": "Java Servlets"
						},
						{
							"_nr": "3.3",
							"__text": "Tehnologia JSP"
						},
						{
							"_nr": "3.4",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea bazelor de date in Java"
						},
						{
							"_nr": "4.1",
							"__text": "Introducere in ODBC si JDBC"
						},
						{
							"_nr": "4.2",
							"__text": "Utilizarea JDBC 1.0 API"
						},
						{
							"_nr": "4.3",
							"__text": "Structura JDBC"
						},
						{
							"_nr": "4.4",
							"__text": "Conectarea la baza de date"
						},
						{
							"_nr": "4.5",
							"__text": "Executarea comenzilor SQL"
						},
						{
							"_nr": "4.6",
							"__text": "Conecrtarea la o baza de date Access"
						},
						{
							"_nr": "4.7",
							"__text": "JDBC 2.0"
						},
						{
							"_nr": "4.8",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "5",
							"__text": "Perceptia umana si procesarea de imagini"
						},
						{
							"_nr": "5.1",
							"__text": "Introducere"
						},
						{
							"_nr": "5.2",
							"__text": "Caracteristici specifice procesarii de imagini"
						},
						{
							"_nr": "5.3",
							"__text": "Domenii abordate de procesarea imaginilor"
						},
						{
							"_nr": "5.4",
							"__text": "Etape ale procesarii de imagii si preprezentarea imaginilor digitale"
						},
						{
							"_nr": "5.5",
							"__text": "Dispozitive de afisare"
						},
						{
							"_nr": "5.6",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "6",
							"__text": "Elemente privind procesarea de imagini"
						},
						{
							"_nr": "6.1",
							"__text": "Elemente introductive"
						},
						{
							"_nr": "6.2",
							"__text": "Caracteristici de baza ale domeniului procesarii de imagini"
						},
						{
							"_nr": "6.3",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "7",
							"__text": "Procesare de imagini in Java"
						},
						{
							"_nr": "7.1",
							"__text": "Evolutia procesarii de imagini in Java"
						},
						{
							"_nr": "7.2",
							"__text": "Modelul de impingere AWT"
						},
						{
							"_nr": "7.3",
							"__text": "Modelul de mod imedial Java 2D"
						},
						{
							"_nr": "7.4",
							"__text": "Modelul de tragere JAI"
						},
						{
							"_nr": "7.5",
							"__text": "Bibliogafie"
						},
						{
							"_nr": "8",
							"__text": "Java Media Framework"
						},
						{
							"_nr": "8.1",
							"__text": "Introducere in Java Media Framework"
						},
						{
							"_nr": "8.2",
							"__text": "Lucrul cu date dependente de timp (Time-Based Media)"
						},
						{
							"_nr": "8.3",
							"__text": "Principiile Java Media Framework"
						},
						{
							"_nr": "8.4",
							"__text": "Prezentarea datelor dependente de timp in Java Media Framework"
						},
						{
							"_nr": "8.5",
							"__text": "Pricesarea datelor dependente de timp"
						},
						{
							"_nr": "8.6",
							"__text": "Captura datelor in Java Media Framework"
						},
						{
							"_nr": "8.7",
							"__text": "Real-Time Transport Protocol"
						},
						{
							"_nr": "8.8",
							"__text": "Receptia si prezentarea stream-urilor RTP"
						},
						{
							"_nr": "8.9",
							"__text": "Transmisia stream-uilot RTP"
						},
						{
							"_nr": "8.10",
							"__text": "Importul si exportul stream-urilor RTP"
						},
						{
							"_nr": "8.11",
							"__text": "Bibliografie"
						},
						{
							"_nr": "9",
							"__text": "Aplicatie de procesare a imaginilor folosind JAI API"
						},
						{
							"_nr": "9.1",
							"__text": "Descrierea aplicatiei"
						},
						{
							"_nr": "9.2",
							"__text": "Structura aplicatiei"
						},
						{
							"_nr": "10",
							"__text": "Aplicatia Ghost Surveillance System"
						},
						{
							"_nr": "10.1",
							"__text": "Scopul aplicatiei Ghost Surveillance System"
						},
						{
							"_nr": "10.2",
							"__text": "Prezentarea aplicatiei"
						},
						{
							"_nr": "10.3",
							"__text": "Interfata aplicatiei cu camera video"
						},
						{
							"_nr": "10.4",
							"__text": "Detectia miscarii"
						},
						{
							"_nr": "10.5",
							"__text": "Avantajele si dezantajele aplicatiei GSS"
						},
						{
							"_nr": "11",
							"__text": "Noi tehnologii introduse in mediul Java"
						},
						{
							"_nr": "11.1",
							"__text": "Procesare XML"
						},
						{
							"_nr": "11.2",
							"__text": "New I/O API"
						},
						{
							"_nr": "11.3",
							"__text": "Image I/O API"
						},
						{
							"_nr": "11.4",
							"__text": "Securitate Java"
						},
						{
							"_nr": "11.5",
							"__text": "Java Print Service"
						},
						{
							"_nr": "11.6",
							"__text": "Java Logging API"
						},
						{
							"_nr": "11.7",
							"__text": "Java Web Start"
						},
						{
							"_nr": "11.8",
							"__text": "JDBC 3.0 API"
						},
						{
							"_nr": "11.9",
							"__text": "Preferences API"
						},
						{
							"_nr": "11.10",
							"__text": "Suport pentru retea"
						},
						{
							"_nr": "11.11",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "256",
				"_isbn": "973-650-062-4"
			},
			{
				"titlu": "Informatica [XI] (Varianta Pascal)",
				"autori": {
					"autor": [
						{
							"_nume": "George Daniel Mateescu"
						},
						{
							"_nume": "Pavel Florin Moraru"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Niculescu",
				"numarPagini": "224",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_XI_Varianta_Pascal_Mateescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Identificarea si clasificarea unor tipuri de date alocate static"
						},
						{
							"_nr": "I.1",
							"__text": "Grafuri neorientate - notiuni de baza"
						},
						{
							"_nr": "I.2",
							"__text": "Arbori"
						},
						{
							"_nr": "I.3",
							"__text": "Grafuri neorientate - notiuni de baza"
						},
						{
							"_nr": "II",
							"__text": "Aplicarea unor algoritmi in structuri de date alocate static"
						},
						{
							"_nr": "II.1",
							"__text": "Parcurgerea grafurilor neorientate"
						},
						{
							"_nr": "II.2",
							"__text": "Conexivitate in grafuri neorientate"
						},
						{
							"_nr": "II.3",
							"__text": "Grafuri hamiltoniene si euliene"
						},
						{
							"_nr": "II.4",
							"__text": "Arborele partial de cost minim"
						},
						{
							"_nr": "II.5",
							"__text": "Conexitate in grafuri orientate"
						},
						{
							"_nr": "II.6",
							"__text": "Drumuri minime si maxime in grafuri orientate"
						},
						{
							"_nr": "III",
							"__text": "Identificarea si clasificarea unor tipuri de date alocate dinamic"
						},
						{
							"_nr": "III.1",
							"__text": "Tipul referinta (pointer)"
						},
						{
							"_nr": "III.2",
							"__text": "Lista liniare"
						},
						{
							"_nr": "III.3",
							"__text": "Arbori"
						},
						{
							"_nr": "IV",
							"__text": "Programarea pe obiecte"
						},
						{
							"_nr": "IV.1",
							"__text": "Notiuni de obiect si metoda"
						},
						{
							"_nr": "IV.2",
							"__text": "Metoda constructor"
						},
						{
							"_nr": "IV.3",
							"__text": "Incapsulare"
						},
						{
							"_nr": "IV.4",
							"__text": "Mostenire"
						},
						{
							"_nr": "IV.5",
							"__text": "Polimorfism"
						},
						{
							"_nr": "IV.6",
							"__text": "Alocarea dinamica a obiectelor, destructori"
						},
						{
							"_nr": "IV.7",
							"__text": "Descrierea formala a obiectelor"
						},
						{
							"_nr": "IV.8",
							"__text": "Aprofundare: Tehnica Backtacking si urmasi care rezolva problema permutarilor si problema damelor"
						},
						{
							"_nr": "V",
							"__text": "Proiectarea aplicatiilor"
						},
						{
							"_nr": "V.1",
							"__text": "Studiul de oportunitate (utilitate, piata posibila)"
						},
						{
							"_nr": "V.2",
							"__text": "Analiza"
						},
						{
							"_nr": "V.3",
							"__text": "Conceptia"
						},
						{
							"_nr": "V.4",
							"__text": "Finalizarea"
						},
						{
							"_nr": "V.5",
							"__text": "Elaborarea documentatiei"
						}
					]
				},
				"_id": "257",
				"_isbn": "973-568-546-9"
			},
			{
				"titlu": "Visual C++. Programarea aplicatiilor Windows cu MFC",
				"autori": {
					"autor": [
						{
							"_nume": "Eduard Koller"
						},
						{
							"_nume": "Monica E. Rosculet"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "456",
				"pretOriginal": "70000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "244",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Visual_Cpp_Programarea_aplicatiilor_Windows_cu_MFC_Koller.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Microsoft Windows si Visul C++"
						},
						{
							"_nr": "III",
							"__text": "Programarea cu Microsoft Foundation Classes"
						},
						{
							"_nr": "IV",
							"__text": "Exemple... pas cu pas"
						},
						{
							"_nr": "V",
							"__text": "Ceva mai mult: implementarea caracteristicilor OLE"
						},
						{
							"_nr": "VI",
							"__text": "Dezvoltarea aplicatiilor in domeniul bazelor de date"
						},
						{
							"_nr": "VII",
							"__text": "Sfaturi"
						},
						{
							"_nr": "VIII",
							"__text": "In loc de incheiere"
						}
					]
				},
				"_id": "258",
				"_isbn": "973-601-596-3"
			},
			{
				"titlu": "Biblioreca programatorului Java",
				"autori": {
					"autor": [
						{
							"_nume": "Suleiman Lalani"
						},
						{
							"_nume": "Kris Jamsa"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "All",
				"numarPagini": "576",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"traducere": {
					"titluOriginal": "Java Programmer's Library",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Niculae-Otto Grunfeld"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Catalin Mantu"
				},
				"coperta": {
					"imagine": {
						"_path": "Java_Jamsa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Java"
						},
						{
							"_nr": "2",
							"__text": "Hello, Java! Afisarea mesajelor la consola sistemului"
						},
						{
							"_nr": "3",
							"__text": "Hello, Java! II - Definirea unei clase in cadrul unui applet si afisarea mesajelor in fereastra applet"
						},
						{
							"_nr": "4",
							"__text": "Font List - Listarea fonturilor sistem disponible"
						},
						{
							"_nr": "5",
							"__text": "Simple Marquee - Afisarea uneui mesaj marquee"
						},
						{
							"_nr": "6",
							"__text": "Modifiable Marquee - Controlul mesajului prin utilizarea HTML"
						},
						{
							"_nr": "7",
							"__text": "Rectangular Marquee - Afisarea unui text mobil in jurul ecranului"
						},
						{
							"_nr": "8",
							"__text": "Free Samples - Grafica intermediara capteaza atentia utilizatorului"
						},
						{
							"_nr": "9",
							"__text": "Viva Las Vegas - Utilizarea unei imagini cu un titlu fantezist"
						},
						{
							"_nr": "10",
							"__text": "Welcome to Joe's Bar - Utilizarea calasei audio pentru a reda muzica"
						},
						{
							"_nr": "11",
							"__text": "Neon Las Vegas - Afisarea unor imagini de neon in jurul unei imagini"
						},
						{
							"_nr": "12",
							"__text": "Pushbutton Music - Raspunsul la selectarea unor butoane de catre utilizator"
						},
						{
							"_nr": "13",
							"__text": "Cyber Zoo - Grafice, sunete si operatiunile cu mouse-ul"
						},
						{
							"_nr": "14",
							"__text": "Animal I.D. - Utilizarea casetelor mesaj pentru identificarea obiectelor de pe ecran"
						},
						{
							"_nr": "15",
							"__text": "Animal Popus - Mesaje pop-up care apar si dispar cu mouse-ul"
						},
						{
							"_nr": "16",
							"__text": "Election Year - Urmarirea coordonatelor obiectelor"
						},
						{
							"_nr": "17",
							"__text": "Bouncing Ball - Utilizarea functiilor trigonometrice prin pachetul Java Math"
						},
						{
							"_nr": "18",
							"__text": "Noisy Bouncing Ball - Copierea zonelor ecranului si combinarea sunetelor"
						},
						{
							"_nr": "19",
							"__text": "Bouncing Heads With Background - Modifcarea demo-ului JDK pentru a genera sarituri pe o imagine fundal"
						},
						{
							"_nr": "20",
							"__text": "Multiple Bouncing Heads - Coordonarea unei multimi de obiecte aflate in miscare"
						},
						{
							"_nr": "21",
							"__text": "Modiflabe Bouncing Heads - Utilizarea parametrilor HTML"
						},
						{
							"_nr": "22",
							"__text": "Flower Shop - Utilizarea functiilor RANDOM si TRANSLATE"
						},
						{
							"_nr": "23",
							"__text": "Moving Free Sample - Oscilarea stelei care clipeste"
						},
						{
							"_nr": "24",
							"__text": "Fishes - Imagini care raspund la eliberare butonului mouse-ului"
						},
						{
							"_nr": "25",
							"__text": "Movinf the Fishes - Deplasarea unui peste intr-o pozitie specificata de utilizator"
						},
						{
							"_nr": "26",
							"__text": "Drag-and-drop Fish - Utilizarea mouse-ului pentru tragerea si asezarea obiectelor"
						},
						{
							"_nr": "27",
							"__text": "Fish Tank - Pesti in miscare aleatorie"
						},
						{
							"_nr": "28",
							"__text": "Magnify - Scalarea unei portiuni dein imagine"
						},
						{
							"_nr": "29",
							"__text": "Improved Magnify - Eliminarea partiala a licaririlor"
						},
						{
							"_nr": "30",
							"__text": "Digital Clock - Desenarea pe ecran unui ceas in functiune"
						},
						{
							"_nr": "31",
							"__text": "Round Clock - Desenarea pe ecran a unui ceas rotund in functiune"
						},
						{
							"_nr": "32",
							"__text": "Calendar - Crearea unui calendar in timp real"
						},
						{
							"_nr": "33",
							"__text": "LED Clock - Crearea unui ceas scalabil cu LED-uri"
						},
						{
							"_nr": "34",
							"__text": "Bitmap Font Clock - crearea unui obiect bitmap"
						},
						{
							"_nr": "35",
							"__text": "Rotate Class - Rotirea obiectelor 2D"
						},
						{
							"_nr": "36",
							"__text": "Rotated Bitmap Clock - Rotirea unui font bitmap"
						},
						{
							"_nr": "37",
							"__text": "Tower of Hanoi - Utilizarea recurentei cu elemente grafice din limbajul Java"
						},
						{
							"_nr": "38",
							"__text": "Client/Server - Schimbul de mesaje prin Web"
						},
						{
							"_nr": "39",
							"__text": "Multiple Clients - Conectarea mai multor clienti la un server si transmiterea de mesaje"
						},
						{
							"_nr": "40",
							"__text": "Graphical Client/Server - Combinarea graficii cu lucrul pe retea"
						},
						{
							"_nr": "41",
							"__text": "Two-Way Char- Comunicarea a doi clienti printr-un server"
						},
						{
							"_nr": "42",
							"__text": "Sockets Chat - Un program de conversatie bazat pe socket"
						},
						{
							"_nr": "43",
							"__text": "Java FTP - Conectarea la un site FTP si obtinerea unui fisier prin transfer"
						},
						{
							"_nr": "44",
							"__text": "Ticker - Crearea unui ticket (font punctiform) bitmap"
						},
						{
							"_nr": "45>",
							"__text": "Eraser - Utilizarea mouse-ului pentru a sterge o imagine"
						},
						{
							"_nr": "46",
							"__text": "Reveal Image - Stergerea unei imagini pentru a revela o a doua imagine"
						},
						{
							"_nr": "47",
							"__text": "Poorman's Photoshop - Adaugarea si eliberarea culorilor unei imagini"
						},
						{
							"_nr": "48",
							"__text": "Pizza Site - Pregatirea unei pizze prin deplasare si asezare"
						},
						{
							"_nr": "49",
							"__text": "Playing Cards - Prezentarea unui pachet complet de carti de joc"
						},
						{
							"_nr": "50",
							"__text": "Cards with Suits - Afisarea unui pachet de carti cu numarul corect de culori"
						},
						{
							"_nr": "51",
							"__text": "Blackjack - Utilizarea clasei Cards pentru a juca un joc"
						}
					]
				},
				"_id": "259",
				"_isbn": "973-9337-21-X"
			},
			{
				"titlu": "Biblioteca programatorului VRML",
				"autori": {
					"autor": [
						{
							"_nume": "Kris Jamsa"
						},
						{
							"_nume": "Phil Schmauder"
						},
						{
							"_nume": "Nelson Yee"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All",
				"numarPagini": "602",
				"pretOriginal": "0",
				"pretCumparare": "101000",
				"traducere": {
					"titluOriginal": "VRML Programmers's Library",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Corina-Elena Matea"
					},
					"_an": "1997",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "VRML_Jamsa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in limbajele de modelare in realitatea virtuala"
						},
						{
							"_nr": "2",
							"__text": "Crearea primelor lumi VRML"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea intensa a sferelor si a cilindrilor"
						},
						{
							"_nr": "4",
							"__text": "Crearea de altare si de monumente"
						},
						{
							"_nr": "5",
							"__text": "Crearea de cutii si de custi"
						},
						{
							"_nr": "6",
							"__text": "Crearea unori navete ideale pentru traversarea lumilor VRML"
						},
						{
							"_nr": "7",
							"__text": "Crearea de obiecte VRML care folosesc seturi de suprafete geomentrice"
						},
						{
							"_nr": "8",
							"__text": "Crearea de piese si table de joc"
						},
						{
							"_nr": "9",
							"__text": "\"Cornul abundentei\" VRML"
						},
						{
							"_nr": "10",
							"__text": "Exercitii de fimnastica cu ajutorul VRML"
						},
						{
							"_nr": "11",
							"__text": "New York, New York"
						},
						{
							"_nr": "12",
							"__text": "Crearea unui PC si a unui monitor"
						},
						{
							"_nr": "13",
							"__text": "Incepe sa semene cu Craciunul"
						},
						{
							"_nr": "14",
							"__text": "Crearea unei incaperi virtuale"
						},
						{
							"_nr": "15",
							"__text": "Planete in miscare"
						},
						{
							"_nr": "16",
							"__text": "Crearea unui ceas virtual"
						},
						{
							"_nr": "17",
							"__text": "Crearea unui clip animat"
						},
						{
							"_nr": "18",
							"__text": "Meci de Sah Virtual"
						},
						{
							"_nr": "19",
							"__text": "Crearea unui font tridimensional"
						},
						{
							"_nr": "20",
							"__text": "Virtual Las Vegas"
						}
					]
				},
				"_id": "260",
				"_isbn": "973-9337-96-1"
			},
			{
				"titlu": "Bazele Visual FoxPro 5.0",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriel Dima"
						},
						{
							"_nume": "Mihai Dima"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "408",
				"pretOriginal": "60000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_Visual _FoxPro_5_0_Dima.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date gestionate in Visual FoxPro"
						},
						{
							"_nr": "II",
							"__text": "Baze de date"
						},
						{
							"_nr": "3",
							"__text": "Constuirea bazelor de date"
						},
						{
							"_nr": "4",
							"__text": "Exploatarea bazelor de date"
						},
						{
							"_nr": "5",
							"__text": "Interogarea bazelor de date"
						},
						{
							"_nr": "III",
							"__text": "Programe"
						},
						{
							"_nr": "6",
							"__text": "Elemente de programare clasica (structurata)"
						},
						{
							"_nr": "7",
							"__text": "Programare orientata pe obiecte"
						},
						{
							"_nr": "8",
							"__text": "Depanarea programelor"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme infromatice. Tipuri de programe componente"
						},
						{
							"_nr": "9",
							"__text": "Programe de introducere a datelor. Constructorul de forme"
						},
						{
							"_nr": "10",
							"__text": "Programe pentru raportare. Constructorul de rapoarte"
						},
						{
							"_nr": "11",
							"__text": "Meniuri. Constructorul de meniuri"
						},
						{
							"_nr": "12",
							"__text": "Asamblarea componentelor intr-un sistem informatic"
						},
						{
							"_nr": "V",
							"__text": "Tehnici speciale"
						},
						{
							"_nr": "13",
							"__text": "Tehnici speciale disponibile in Visual FoxPro"
						}
					]
				},
				"_id": "261",
				"_isbn": "973-20-0298-0"
			},
			{
				"titlu": "Introducere in universul Java",
				"autori": {
					"autor": {
						"_nume": "Horia Georgescu"
					}
				},
				"anAparitie": "2002",
				"editura": "Tehnica",
				"tiraj": "1000",
				"numarPagini": "302",
				"pretOriginal": "105000",
				"redactori": {
					"redactor": "Adina Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Tanase"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_universul_Java_Georgescu.jpg"
					},
					"__text": "Roxana Rosu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "O prima incursiune in limbaj Java"
						},
						{
							"_nr": "2",
							"__text": "Elemente de baza ale limbajului Java"
						},
						{
							"_nr": "3",
							"__text": "Alte elemente de baza ale limbajului Java. Aplicatii"
						},
						{
							"_nr": "4",
							"__text": "Pachete. Acces si vizibilitate"
						},
						{
							"_nr": "5",
							"__text": "Extinderea claselor"
						},
						{
							"_nr": "6",
							"__text": "Metode si clase abstracte. Interfete. Clase interne"
						},
						{
							"_nr": "7",
							"__text": "Exceptii. Conversii"
						},
						{
							"_nr": "8",
							"__text": "Facilitati standard"
						},
						{
							"_nr": "9",
							"__text": "Fire de executare. Programare paralela si concurenta"
						},
						{
							"_nr": "10",
							"__text": "Facilitati de intrare/iesire"
						},
						{
							"_nr": "11",
							"__text": "Interfete grafice"
						},
						{
							"_nr": "12",
							"__text": "Serializarea obiectelor"
						},
						{
							"_nr": "13",
							"__text": "Sisteme distribuite. Socket-uri"
						},
						{
							"_nr": "14",
							"__text": "Invocarea la distanta a metodelor (RMI)"
						},
						{
							"_nr": "15",
							"__text": "Pe scurt despre applet-uri"
						}
					]
				},
				"_id": "262",
				"_isbn": "973-31-2095-2"
			},
			{
				"titlu": "Linux pentru avansati. Sugestii, exemple",
				"autori": {
					"autor": [
						{
							"_nume": "Cornelia Palivan"
						},
						{
							"_nume": "Horatiu Palivan"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "272",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Alice Raluca Vinereanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Roxana Ioana Rosu"
				},
				"coperta": {
					"imagine": {
						"_path": "Linux_pentru_avansati_Sugestii_exemple_Palivan.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea sistemului Linux"
						},
						{
							"_nr": "2",
							"__text": "Incarcarea sistemului Linux"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de fisiere pentru Linux"
						},
						{
							"_nr": "4",
							"__text": "Linux in retea"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea perifericelor"
						},
						{
							"_nr": "6",
							"__text": "Gestiunea utilizatorilor"
						},
						{
							"_nr": "7",
							"__text": "Interfetele utilizator"
						},
						{
							"_nr": "8",
							"__text": "Drivere"
						}
					]
				},
				"_id": "263",
				"_isbn": "973-31-2060-X"
			},
			{
				"titlu": "Delphi 3.0 : ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "1998",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "442",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "80",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Delphi_3_0_ghid_de_utilizare_Kovacs.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Masa de lucru a programului Delphi 3.0"
						},
						{
							"_nr": "2",
							"__text": "Exemple demonstrative"
						},
						{
							"_nr": "3",
							"__text": "Principalele meniuri si comenzi in sistemul Delphi 3.0"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea inspectorului de obiecte"
						},
						{
							"_nr": "5",
							"__text": "Forme"
						},
						{
							"_nr": "6",
							"__text": "Paleta de componente"
						},
						{
							"_nr": "7",
							"__text": "Gestiunea componentelor"
						},
						{
							"_nr": "8",
							"__text": "Componenta BUTTON"
						},
						{
							"_nr": "9",
							"__text": "Componenta BITBTN"
						},
						{
							"_nr": "10",
							"__text": "Componenta LABEL"
						},
						{
							"_nr": "11",
							"__text": "Obiectul lista de siruri de caractere STRINGLIST"
						},
						{
							"_nr": "12",
							"__text": "Componenta EDIT"
						},
						{
							"_nr": "13",
							"__text": "Componenta MEMO"
						},
						{
							"_nr": "14",
							"__text": "Componenta MASKEDIT"
						},
						{
							"_nr": "15",
							"__text": "Ferestre pentru afisarea mesajelor si introducerea informatiilor"
						},
						{
							"_nr": "16",
							"__text": "Componenta CHECKBOX"
						},
						{
							"_nr": "17",
							"__text": "Componenta GROUPBOX"
						},
						{
							"_nr": "18",
							"__text": "Componenta RADIOBUTTON"
						},
						{
							"_nr": "19",
							"__text": "Componenta RADIOGROUP"
						},
						{
							"_nr": "20",
							"__text": "Componenta SCROLLBAR"
						},
						{
							"_nr": "21",
							"__text": "Obiectul CANVAS"
						},
						{
							"_nr": "22",
							"__text": "Componenta IMAGE"
						},
						{
							"_nr": "23",
							"__text": "Componenta IMAGELIST"
						},
						{
							"_nr": "24",
							"__text": "Componenta SHAPE"
						},
						{
							"_nr": "25",
							"__text": "Componenta PANEL"
						},
						{
							"_nr": "26",
							"__text": "Componenta LISTBOX"
						},
						{
							"_nr": "27",
							"__text": "Componenta COMBOBOX"
						},
						{
							"_nr": "28",
							"__text": "Obiect SCREN"
						},
						{
							"_nr": "29",
							"__text": "Ferestre generale de dialog"
						},
						{
							"_nr": "30",
							"__text": "Componente pentru gestionarea fisierelor"
						},
						{
							"_nr": "31",
							"__text": "Gestiunea tastaturii"
						},
						{
							"_nr": "32",
							"__text": "Componenta TIMER"
						},
						{
							"_nr": "33",
							"__text": "Gestiunea mouse-ului"
						},
						{
							"_nr": "34",
							"__text": "Operatia de tragere cu mouse-ului"
						},
						{
							"_nr": "35",
							"__text": "Componenta STRINGGRID"
						},
						{
							"_nr": "36",
							"__text": "Componenta DRAWGRID"
						},
						{
							"_nr": "37",
							"__text": "Componenta MAINMENU"
						},
						{
							"_nr": "38",
							"__text": "Componenta POPUPMENU"
						},
						{
							"_nr": "39",
							"__text": "Componenta ANIMATE"
						},
						{
							"_nr": "40",
							"__text": "Componenta MEDIAPLAYER"
						},
						{
							"_nr": "41",
							"__text": "Componenta PROGRESSBAR"
						},
						{
							"_nr": "42",
							"__text": "Componenta TRACKBAR"
						},
						{
							"_nr": "43",
							"__text": "Componenta TOOLBAR"
						},
						{
							"_nr": "44",
							"__text": "Componenta STATUSBAR"
						},
						{
							"_nr": "45",
							"__text": "Componenta TABCONTROL"
						},
						{
							"_nr": "46",
							"__text": "Componenta PAGECONTROL"
						},
						{
							"_nr": "47",
							"__text": "Componenta HEADERCONTROL"
						},
						{
							"_nr": "48",
							"__text": "Crearea obiectelor DELPHI in timpul executiei"
						},
						{
							"_nr": "49",
							"__text": "Aplicatii multiforme"
						},
						{
							"_nr": "50",
							"__text": "Interfata multidocument"
						},
						{
							"_nr": "51",
							"__text": "Utilizarea mesei de montaj (CLIPBOARD)"
						},
						{
							"_nr": "52",
							"__text": "Schimbul dinamic de date (DDE)"
						},
						{
							"_nr": "53",
							"__text": "Componenta OLECONTAINER"
						},
						{
							"_nr": "54",
							"__text": "Componente utilizator in gestiunea bazelor de date"
						}
					]
				},
				"_id": "264",
				"_isbn": "973-9215-70-X"
			},
			{
				"titlu": "Dictionar de tehnica de calcul ENGLEZ-ROMAN",
				"autori": {
					"autor": {
						"_nume": "Jodal Endre"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "348",
				"pretOriginal": "9490",
				"colectia": {
					"_numarul": "4",
					"_nume": "Seria enciclopedica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_de_tehnica_de_calcul_Englez_Roman_Endre.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": {
						"_nr": ""
					}
				},
				"_id": "265",
				"_isbn": "973-97000-7-1"
			},
			{
				"titlu": "Comunicarea interactiva om-calculator",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Coroescu"
					}
				},
				"anAparitie": "2001",
				"editura": "Lumina Lex",
				"numarPagini": "256",
				"pretOriginal": "120000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Comunicarea_interactiva_om_calculator_Coroescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Conceptul HCIC (Human-Computer Interactive Communication) de comunicare interactiva om-calculator"
						},
						{
							"_nr": "1",
							"__text": "Modelarea primara a comunicarii interactive HCIC"
						},
						{
							"_nr": "2",
							"__text": "Utilizatorul uman ca o componenta a comunicarii interactive HCIC"
						},
						{
							"_nr": "3",
							"__text": "Computerul ca o componenta a comunicarii interactive HCIC"
						},
						{
							"_nr": "4",
							"__text": "Analiza sistemica a comunicatii interactive om-calculator HCIC"
						},
						{
							"_nr": "II",
							"__text": "Aplicarea conceptului HCIC la proiectara interferentelor de comunicarea interactiva om-calculator"
						},
						{
							"_nr": "5",
							"__text": "Elemente de proiectare, realizarea prototipului, implementarea si evaluare a interfetei HCIC"
						},
						{
							"_nr": "6",
							"__text": "Proiectarea structurata a interfetei de comunicare interactiva HCIC folosind metodologia SSADM"
						},
						{
							"_nr": "7",
							"__text": "Proiectarea bazata pe factorul uman a interfetei HCIC folosind metodologia SSM"
						},
						{
							"_nr": "8",
							"__text": "Proiectarea automatelor computerizate cu stari finite si iesire"
						}
					]
				},
				"_id": "266",
				"_isbn": "973-588-400-3"
			},
			{
				"titlu": "Introducere in stiinta sistemelor si a calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Tiberiu Coroescu"
						},
						{
							"_nume": "Vali Sarb"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Didactica si pedagogica",
				"numarPagini": "160",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Liana Faca"
				},
				"tehnoredactori": {
					"tehnoredactor": "Cristina Dragomir"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_stiinta_sistemelor_si_a_calculatoarelor_Coroescu.jpg"
					},
					"__text": "Elena Dragulelei Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in stiinta sistemelor"
						},
						{
							"_nr": "1",
							"__text": "Concepte generale privind stiinta sistemelor"
						},
						{
							"_nr": "2",
							"__text": "Proprietati structurale ale sistemelor"
						},
						{
							"_nr": "3",
							"__text": "Regimuri de functionare ale sistemelor"
						},
						{
							"_nr": "4",
							"__text": "Realizari standard ale sitemelor liniare si stationare mono I/E"
						},
						{
							"_nr": "II",
							"__text": "Introducere in stiinta calculatoarelor"
						},
						{
							"_nr": "5",
							"__text": "Concepte generale privind stiinta calculatoarelor"
						},
						{
							"_nr": "6",
							"__text": "Sinteza si meterializarea functiilor logice ale sistemlor computerizate discrete"
						},
						{
							"_nr": "7",
							"__text": "Concepte privind tehnologia informationala si sistemele informatice computerizate"
						},
						{
							"_nr": "8",
							"__text": "Concepte privind automatele computerizate cu stari finite si iesiri"
						}
					]
				},
				"_id": "267",
				"_isbn": "973-30-2439-2"
			},
			{
				"titlu": "Modelarea UML in ingineria sitemelor de programe",
				"autori": {
					"autor": {
						"_nume": "Anca Daniela Ionita"
					}
				},
				"anAparitie": "2003",
				"editura": "All",
				"numarPagini": "208",
				"pretOriginal": "0",
				"pretCumparare": "120000",
				"redactori": {
					"redactor": "Daniel Slavu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_UML_in_ingineria_sistemelor_de_prograne_Ionita.jpg"
					},
					"__text": "Stelian Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ingineria sistemelor de programare"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Ciclul de viata al software-ului"
						},
						{
							"_nr": "3",
							"__text": "Notiuni de inginerie software pentru dezvoltarea programelor"
						},
						{
							"_nr": "4",
							"__text": "Notiuni de inginerie software pentru evolutia programelor"
						},
						{
							"_nr": "5",
							"__text": "Analiza si proiectarea structurale"
						},
						{
							"_nr": "6",
							"__text": "Analiza si proiectarea orientate pe obiecte"
						},
						{
							"_nr": "II",
							"__text": "Modelare UML cu aplicatii practice"
						},
						{
							"_nr": "7",
							"__text": "Analiza cerintelor pe baza cazurilor de utilizare"
						},
						{
							"_nr": "8",
							"__text": "Modelarea aspectelor statice"
						},
						{
							"_nr": "9",
							"__text": "Interactiunea intre obiecte"
						},
						{
							"_nr": "10",
							"__text": "Modelarea aspectelor dinamice"
						},
						{
							"_nr": "11",
							"__text": "Modelarea sistemului la nivel inalt"
						},
						{
							"_nr": "12",
							"__text": "Specificarea restrictiilor in modele UML"
						}
					]
				},
				"_id": "268",
				"_isbn": "973-571-463-9"
			},
			{
				"titlu": "Proiectare si implementare software",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Mircea Popovici"
						},
						{
							"_nume": "Ioan Mircea Popovici"
						},
						{
							"_nume": "Jean Gabriel Rican"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "240",
				"pretOriginal": "55000",
				"pretCumparare": "80000",
				"colectia": {
					"_numarul": "264",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectare_si_implementare_software_Popovici.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Paradigma Orientarii spre Obiecte"
						},
						{
							"_nr": "1",
							"__text": "Fundamentele orientarii spre obiecte"
						},
						{
							"_nr": "2",
							"__text": "Concepte avansate ale orientarii spre obiecte"
						},
						{
							"_nr": "3",
							"__text": "Transpunerea conceptelor OO in C++"
						},
						{
							"_nr": "II",
							"__text": "Procesul Software Orientat spre Obiecte"
						},
						{
							"_nr": "4",
							"__text": "Ciclul de viata OO"
						},
						{
							"_nr": "5",
							"__text": "Determinarea cerintelor"
						},
						{
							"_nr": "6",
							"__text": "Evenimente, rezumate si studii de caz"
						},
						{
							"_nr": "7",
							"__text": "Modelul obiectelor"
						},
						{
							"_nr": "8",
							"__text": "Modelul dinamic"
						},
						{
							"_nr": "9",
							"__text": "Modelarea functionala"
						},
						{
							"_nr": "10",
							"__text": "Analiza orientata spre obiecte a PCP"
						},
						{
							"_nr": "11",
							"__text": "Analiza orientata spre obiecte a PSB"
						},
						{
							"_nr": "III",
							"__text": "Proiectarea orientata spre obiecte"
						},
						{
							"_nr": "12",
							"__text": "Proiectarea sistemului"
						},
						{
							"_nr": "13",
							"__text": "Proiectarea obiectelor"
						},
						{
							"_nr": "14",
							"__text": "Obiectele si integrarea relationala"
						},
						{
							"_nr": "IV",
							"__text": "Indicatii. Solutii. Bibliografie selectiva"
						},
						{
							"_nr": "15",
							"__text": "Indicatii si solutii"
						}
					]
				},
				"_id": "269",
				"_isbn": "973-601-996-9"
			},
			{
				"titlu": "Aplicatii in Visual Basic",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "152",
				"pretOriginal": "33000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "284",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_in_Visual_Basic_Patrut.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Aplicatia 1",
							"__text": "Calculator de buzunar"
						},
						{
							"_nr": "Aplicatia 2",
							"__text": "Maxime si cugetari"
						},
						{
							"_nr": "Aplicatia 3",
							"__text": "Desenator"
						},
						{
							"_nr": "Aplicatia 4",
							"__text": "Prinde mustele"
						},
						{
							"_nr": "Aplicatia 5",
							"__text": "Vanatoarea de berze"
						},
						{
							"_nr": "Aplicatia 6",
							"__text": "Tetris"
						},
						{
							"_nr": "Aplicatia 7",
							"__text": "Puzzle cu numere"
						},
						{
							"_nr": "Aplicatia 8",
							"__text": "Puzzle cu imagini"
						},
						{
							"_nr": "Aplicatia 9",
							"__text": "Spanzuratoarea"
						},
						{
							"_nr": "Aplicatia 10",
							"__text": "Patrate glisante"
						},
						{
							"_nr": "Aplicatia 11",
							"__text": "Intoarce broscutele"
						},
						{
							"_nr": "Aplicatia 12",
							"__text": "Bila"
						},
						{
							"_nr": "Aplicatia 13",
							"__text": "Fructele"
						},
						{
							"_nr": "Aplicatia 14",
							"__text": "Test grila"
						},
						{
							"_nr": "Aplicatia 15",
							"__text": "Tabele si grafice"
						},
						{
							"_nr": "Aplicatia 16",
							"__text": "Test de circulatie rutiera"
						},
						{
							"_nr": "Aplicatia 17",
							"__text": "Bioritm"
						},
						{
							"_nr": "Aplicatia 18",
							"__text": "Mini enciclopedie"
						},
						{
							"_nr": "Aplicatia 19",
							"__text": "Editor de harti"
						},
						{
							"_nr": "Aplicatia 20",
							"__text": "Cinematografie AVI"
						}
					]
				},
				"_id": "270",
				"_isbn": "973-601-981-0"
			},
			{
				"titlu": "Aplicatii in C si C++",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "168",
				"pretOriginal": "25000",
				"pretCumparare": "80000",
				"colectia": {
					"_numarul": "241",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_in_C_si_Cpp_Patrut.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului C/C++"
						},
						{
							"_nr": "2",
							"__text": "Tablouri si pointeri"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Structuri si tipuri definite de programator"
						},
						{
							"_nr": "5",
							"__text": "Explorarea fisierelor"
						},
						{
							"_nr": "6",
							"__text": "Algorirmi de teoria grafurilor"
						},
						{
							"_nr": "7",
							"__text": "Grafica in C/C++"
						},
						{
							"_nr": "8",
							"__text": "Programare orientata pe obiecte in Borland C++"
						}
					]
				},
				"_id": "271",
				"_isbn": "973-601-760-5"
			},
			{
				"titlu": "Bacalaureat informatica",
				"autori": {
					"autor": {
						"_nume": "Florin Moraru"
					}
				},
				"anAparitie": "2000",
				"editura": "Petrion",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": "Laurentiu Codau"
				},
				"coperta": {
					"imagine": {
						"_path": "Bacalaureat_Informatica_Moraru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Materia de Bacalaureat in 10 capitole"
						},
						{
							"_nr": "I",
							"__text": "Elemente de baza ale limbajului"
						},
						{
							"_nr": "II",
							"__text": "Instructiuni de selectie"
						},
						{
							"_nr": "III",
							"__text": "Instructiuni repetitive"
						},
						{
							"_nr": "IV",
							"__text": "Subprogame (proceduri si functii)"
						},
						{
							"_nr": "V",
							"__text": "Tablouri (vectori si matrici)"
						},
						{
							"_nr": "VI",
							"__text": "Siruri de caractere, inregistrari si multimi"
						},
						{
							"_nr": "VII",
							"__text": "Fisiere de date"
						},
						{
							"_nr": "VIII",
							"__text": "Recursivitate"
						},
						{
							"_nr": "IX",
							"__text": "Tehnici de programare"
						},
						{
							"_nr": "X",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "Partea II",
							"__text": "Teze pentru Bacalaureat"
						},
						{
							"_nr": "1",
							"__text": "Variante de subiecte cu rezolvari complete si bareme de notare"
						},
						{
							"_nr": "2",
							"__text": "Variante de subiecte propuse cu indicatii si raspunsuri"
						},
						{
							"_nr": "3",
							"__text": "Intrebari recapitulative din teorie"
						},
						{
							"_nr": "4",
							"__text": "Raspunsuri"
						}
					]
				},
				"_id": "172",
				"_isbn": "973-9116-94-9"
			},
			{
				"titlu": "C#",
				"autori": {
					"autor": {
						"_nume": "Herbert Schildt"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "494",
				"pretOriginal": "0",
				"pretCumparare": "300000",
				"traducere": {
					"titluOriginal": "C#: A Beginner's Guide",
					"editura": "The McGraw-Hill Companies",
					"traducatori": {
						"traducator": "Bogdan Pisai"
					},
					"_an": "2001",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "C_Sharp_Schildt.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Fundamentele limbajului C#"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea tipurilor de date si operatorilor"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de control"
						},
						{
							"_nr": "4",
							"__text": "Prezentarea claselor, obiectelor si metodelor"
						},
						{
							"_nr": "5",
							"__text": "Mai multe despre tipurile de date operatori"
						},
						{
							"_nr": "6",
							"__text": "O privire mai detaliata asupra metodelor si claselor"
						},
						{
							"_nr": "7",
							"__text": "Supraincarcarea operatorilor, indexari si proprietati"
						},
						{
							"_nr": "8",
							"__text": "Mostenirea"
						},
						{
							"_nr": "9",
							"__text": "Interfete, structuri si enumerari"
						},
						{
							"_nr": "10",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "11",
							"__text": "Operatii de intrare-iesire"
						},
						{
							"_nr": "12",
							"__text": "Delegari, evenimente, spatii de nume si subiecte avansate"
						}
					]
				},
				"_id": "273",
				"_isbn": "973-20-0083-X"
			},
			{
				"titlu": "C. Manual complet",
				"autori": {
					"autor": {
						"_nume": "Herbert Schildt"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "840",
				"pretOriginal": "0",
				"pretCumparare": "300000",
				"traducere": {
					"titluOriginal": "C: The Complete Reference",
					"editura": "McGraw-Hill Companies",
					"traducatori": {
						"traducator": "Mihai Manastireanu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "C_Manual_complet_Schildt.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul C"
						},
						{
							"_nr": "1",
							"__text": "C, vedere de asamblu"
						},
						{
							"_nr": "2",
							"__text": "Expresii C"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de control al programului"
						},
						{
							"_nr": "4",
							"__text": "Tablouri si siruri"
						},
						{
							"_nr": "5",
							"__text": "Pointeri"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Structuri, uniuni, enumerari si tipuri definite de utilizator"
						},
						{
							"_nr": "8",
							"__text": "Operatii de intrare/iesire pentru consola"
						},
						{
							"_nr": "9",
							"__text": "Operatii I/O pentru fisiere"
						},
						{
							"_nr": "10",
							"__text": "Preprocesorul C. Comentarii"
						},
						{
							"_nr": "II",
							"__text": "Biblioteca standard a limbajului C"
						},
						{
							"_nr": "11",
							"__text": "Legarea, bibliotecile si fisierele antet"
						},
						{
							"_nr": "12",
							"__text": "Funcii pentru operatii de intrare/iesire"
						},
						{
							"_nr": "13",
							"__text": "Functii sir si functii caracter"
						},
						{
							"_nr": "14",
							"__text": "Functii matematice"
						},
						{
							"_nr": "15",
							"__text": "Ora, data si alte functii de sistem"
						},
						{
							"_nr": "16",
							"__text": "Variabile globale si variabile locale"
						},
						{
							"_nr": "17",
							"__text": "Functii grafice si functii ecran"
						},
						{
							"_nr": "18",
							"__text": "Functii diverse"
						},
						{
							"_nr": "III",
							"__text": "Algoritmi si aplicatii"
						},
						{
							"_nr": "19",
							"__text": "Cautare si sortare"
						},
						{
							"_nr": "20",
							"__text": "Cozi, stive, liste inlantuite si arbori"
						},
						{
							"_nr": "21",
							"__text": "Tablou si tablouri rare"
						},
						{
							"_nr": "22",
							"__text": "Analiza si evaluarea expresiilor"
						},
						{
							"_nr": "23",
							"__text": "Rezolvarea unei probleme de inteligenta artificiala"
						},
						{
							"_nr": "24",
							"__text": "Alcatuirea unei structuri de baza pentru aplicatiile Windows'95"
						},
						{
							"_nr": "IV",
							"__text": "Dezvoltarea programelor folosind limbajul C"
						},
						{
							"_nr": "25",
							"__text": "Interfata cu rutinele limbajelor de asamblare"
						},
						{
							"_nr": "26",
							"__text": "Ingineria programelor folosind limbajul C"
						},
						{
							"_nr": "27",
							"__text": "Despre eficienta, portabilitate si depanare"
						},
						{
							"_nr": "V",
							"__text": "Partea a V-a"
						},
						{
							"_nr": "28",
							"__text": "Un interpretor de C restrans"
						}
					]
				},
				"_id": "274",
				"_isbn": "973-601-471-1"
			},
			{
				"titlu": "Game graphics in C++",
				"autori": {
					"autor": {
						"_nume": "Len Dorfman"
					}
				},
				"anAparitie": "1995",
				"editura": "McGraw-Hill",
				"numarPagini": "386",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Game_graphics_in_Cpp_Dorfman.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Part 1",
							"__text": "The demonstration programs"
						},
						{
							"_nr": "1",
							"__text": "Sprite, missile, and bullet objects"
						},
						{
							"_nr": "2",
							"__text": "Moving a sprite object in a playfield space"
						},
						{
							"_nr": "3",
							"__text": "Moving a tree-colored sprite"
						},
						{
							"_nr": "4",
							"__text": "A within-sprite animation technique"
						},
						{
							"_nr": "5",
							"__text": "Simultaneous sprite, missile, and bullet movement"
						},
						{
							"_nr": "6",
							"__text": "Controlling a sprite within a track"
						},
						{
							"_nr": "7",
							"__text": "Launching a missile from a moving sprite"
						},
						{
							"_nr": "8",
							"__text": "A simple moving-tank-shoots-chasing-ghosts game"
						},
						{
							"_nr": "Part 2",
							"__text": "Source code for the bit-plane animation class library"
						},
						{
							"_nr": "9",
							"__text": "Assembly source code for the sprite library"
						},
						{
							"_nr": "10",
							"__text": "C++ source code for the bit-plane animation class library"
						},
						{
							"_nr": "Part 3",
							"__text": "The sprite editor"
						},
						{
							"_nr": "11",
							"__text": "The sprite editor"
						},
						{
							"_nr": "12",
							"__text": "C language sprite editor support functions"
						},
						{
							"_nr": "13",
							"__text": "Assembly language sprite editor support functions"
						},
						{
							"_nr": "X",
							"__text": "Epiloque"
						}
					]
				},
				"_id": "275",
				"_isbn": "0-07-911051-4"
			},
			{
				"titlu": "Metode formale si de clasificare in lingvistica matematica si computationala",
				"autori": {
					"autor": {
						"_nume": "Liviu P. Dinu"
					}
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "184",
				"pretOriginal": "110000",
				"pretCumparare": "110000",
				"coperta": {
					"imagine": {
						"_path": "Metode_formale_si_de_clasificare_in_lingvistica_matematica_si_computationala_Dinu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Aspecte ale silabei"
						},
						{
							"_nr": "2.1",
							"__text": "Abordari lingvistice"
						},
						{
							"_nr": "2.2",
							"__text": "Cercetari cantitative"
						},
						{
							"_nr": "2.3",
							"__text": "Algoritm pentru despartirea in silabe"
						},
						{
							"_nr": "2.4",
							"__text": "Structura fonologica a silabei"
						},
						{
							"_nr": "3",
							"__text": "Un model contextual-Marcus al silabei"
						},
						{
							"_nr": "3.1",
							"__text": "Gramatici contextuale Marcus"
						},
						{
							"_nr": "3.2",
							"__text": "Preliminarii"
						},
						{
							"_nr": "3.3",
							"__text": "O abordare a silabei"
						},
						{
							"_nr": "3.4",
							"__text": "Gramatici contextuale totale cu derivare stanga"
						},
						{
							"_nr": "3.5",
							"__text": "Gramatici silabice"
						},
						{
							"_nr": "3.6",
							"__text": "Automate Go-Through (GTA)"
						},
						{
							"_nr": "3.7",
							"__text": "Gramatici silabice si GTA"
						},
						{
							"_nr": "3.8",
							"__text": "O abordare paralela a despartirii in silabe"
						},
						{
							"_nr": "3.9",
							"__text": "Silaba  si gramaticile paralele de insertie"
						},
						{
							"_nr": "3.10",
							"__text": "Aspecte cognitive ale abordarii contextuale"
						},
						{
							"_nr": "3.11",
							"__text": "Concluzii"
						},
						{
							"_nr": "4",
							"__text": "Despre Similaritate"
						},
						{
							"_nr": "4.1",
							"__text": "Distanta Hamming"
						},
						{
							"_nr": "4.2",
							"__text": "Distanta de editare"
						},
						{
							"_nr": "4.3",
							"__text": "Distanta rang"
						},
						{
							"_nr": "4.4",
							"__text": "Similaritatea arborilor"
						},
						{
							"_nr": "4.5",
							"__text": "Concluzii"
						},
						{
							"_nr": "5",
							"__text": "Metode multicriteriale de decizie"
						},
						{
							"_nr": "5.1",
							"__text": "Clasificare automata de texte"
						},
						{
							"_nr": "5.2",
							"__text": "Metode de clasificare automata"
						},
						{
							"_nr": "5.3",
							"__text": "Agregarea clasamentelor"
						},
						{
							"_nr": "5.4",
							"__text": "Determinarea eficienta a agregarii"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii si rezultate experimentale"
						},
						{
							"_nr": "6.1",
							"__text": "Asupra metodei de clasificare rang"
						},
						{
							"_nr": "6.2",
							"__text": "Similaritatea silabica a limbilor romanice"
						},
						{
							"_nr": "7",
							"__text": "Summary"
						},
						{
							"_nr": "8",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "276",
				"_isbn": "973-575-928-4"
			},
			{
				"titlu": "Criptografie. Coduri. Algoritmi",
				"autori": {
					"autor": [
						{
							"_nume": "Catalin Gherghe"
						},
						{
							"_nume": "Dorin Popescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "218",
				"pretOriginal": "160000",
				"pretCumparare": "160000",
				"coperta": {
					"imagine": {
						"_path": "Criptografie_coduri_algoritmi_Gherghe.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "Criptografie"
						},
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "1.1",
							"__text": "Criptosisteme simple"
						},
						{
							"_nr": "1.2",
							"__text": "Cifruri bloc"
						},
						{
							"_nr": "1.3",
							"__text": "Probabilitate si criptosisteme perfect sigure"
						},
						{
							"_nr": "2",
							"__text": "DES (Data Encryption Standard)"
						},
						{
							"_nr": "2.1",
							"__text": "Cifruri Feistel"
						},
						{
							"_nr": "2.2",
							"__text": "Algoritmul DES"
						},
						{
							"_nr": "3",
							"__text": "Criptosisteme cu cheie publica"
						},
						{
							"_nr": "3.1",
							"__text": "Criptosistemul RSA"
						},
						{
							"_nr": "3.2",
							"__text": "Protocolul Diffie-Hellman"
						},
						{
							"_nr": "3.3",
							"__text": "Criptosistemul ElGamal"
						},
						{
							"_nr": "3.4",
							"__text": "Criptosistemul Rabin"
						},
						{
							"_nr": "3.5",
							"__text": "Signatura digitala"
						},
						{
							"_nr": "4",
							"__text": "Criptosisteme cu curbe eliptice"
						},
						{
							"_nr": "4.1",
							"__text": "Curbe eliptice"
						},
						{
							"_nr": "4.2",
							"__text": "Criptosisteme cu curbe eliptice"
						},
						{
							"_nr": "4.3",
							"__text": "Signatura digitala si scheme de autentificare folosind curbe eliptice"
						},
						{
							"_nr": "Partea 2",
							"__text": "Coduri"
						},
						{
							"_nr": "5",
							"__text": "Coduri si teoria informatiei"
						},
						{
							"_nr": "5.1",
							"__text": "Exemple uzuale de coduri"
						},
						{
							"_nr": "5.2",
							"__text": "Distanta Hamming.Coduri perfecte"
						},
						{
							"_nr": "5.3",
							"__text": "Entropia informationala"
						},
						{
							"_nr": "5.4",
							"__text": "Coduri cu lungime variabila"
						},
						{
							"_nr": "5.5",
							"__text": "Codificarea surselor informationale"
						},
						{
							"_nr": "5.6",
							"__text": "Codificarea Huffman"
						},
						{
							"_nr": "5.7",
							"__text": "Teorema fundamentala a teoriei informatiei"
						},
						{
							"_nr": "6",
							"__text": "Coduri liniare"
						},
						{
							"_nr": "6.1",
							"__text": "Matrici generatoare. Matrice de control"
						},
						{
							"_nr": "6.2",
							"__text": "Operatii cu coduri"
						},
						{
							"_nr": "6.3",
							"__text": "Decodificarea codurilor liniare"
						},
						{
							"_nr": "6.4",
							"__text": "Coduri Hamming"
						},
						{
							"_nr": "6.5",
							"__text": "Coduri Hamming extinse"
						},
						{
							"_nr": "6.6",
							"__text": "Coduri Reed-Muller"
						},
						{
							"_nr": "7",
							"__text": "Coduri ciclice"
						},
						{
							"_nr": "7.1",
							"__text": "Defintie.Proprietati.Exemple"
						},
						{
							"_nr": "7.2",
							"__text": "Codificarea si decodificarea codurilor ciclice"
						},
						{
							"_nr": "7.3",
							"__text": "Coduri BCH"
						},
						{
							"_nr": "8",
							"__text": "Coduri de resturi patratice"
						},
						{
							"_nr": "8.1",
							"__text": "Definitie generala"
						},
						{
							"_nr": "8.2",
							"__text": "Idempotentele codurilor binare de resturi patratice"
						},
						{
							"_nr": "8.3",
							"__text": "Coduri binare de resturi patratice extinse"
						},
						{
							"_nr": "8.4",
							"__text": "Automorfismele unui cod de rsturi patratice extins"
						},
						{
							"_nr": "8.5",
							"__text": "Coduri Golay binare"
						},
						{
							"_nr": "A",
							"__text": "Algoritmul lui Euclid"
						},
						{
							"_nr": "B",
							"__text": "Generarea numerelor prime"
						},
						{
							"_nr": "1",
							"__text": "Testul Micii Teoreme a lui Fermat"
						},
						{
							"_nr": "2",
							"__text": "Testul Miler-Rabin"
						},
						{
							"_nr": "3",
							"__text": "Metoda p-1"
						},
						{
							"_nr": "C",
							"__text": "Inele, ideale, corpuri, corpuri finite"
						}
					]
				},
				"_id": "277",
				"_isbn": "973-737-092-9"
			},
			{
				"titlu": "Transact SQL",
				"autori": {
					"autor": {
						"_nume": "Stefan Ardeleanu"
					}
				},
				"anAparitie": "2004",
				"editura": "Niculescu",
				"numarPagini": "272",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Razvan Tufeanu-Livintz"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Stefan Ardeleanu",
						"Serban Popina",
						"Razvan Tufeanu-Livintz"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "TransactSQL_Standardul_ANSI_SQL_pas_cu_pas_Ardeleanu.jpg"
					},
					"__text": "Razvan Tufeanu-Livintz"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sistemul informatic. Baza de date"
						},
						{
							"_nr": "II",
							"__text": "Cum sunt memorate informatiile intr-o baza de date"
						},
						{
							"_nr": "III",
							"__text": "Vizualizarea si filtrarea datelor"
						},
						{
							"_nr": "IV",
							"__text": "Operatori logici. Alte fnctii simple si utile"
						},
						{
							"_nr": "V",
							"__text": "Selectii din mai multe tabele. Constrangeri"
						},
						{
							"_nr": "VI",
							"__text": "Diverse tipuri de asocieri. Reuniuni de date"
						},
						{
							"_nr": "VII",
							"__text": "Functii totalizatoare"
						},
						{
							"_nr": "VIII",
							"__text": "Inserara datelor"
						},
						{
							"_nr": "IX",
							"__text": "Modificarea datelor"
						},
						{
							"_nr": "X",
							"__text": "Stergerea datelor"
						}
					]
				},
				"_id": "278",
				"_isbn": "973-568-919-7"
			},
			{
				"titlu": "Modelarea bazelor de date",
				"autori": {
					"autor": {
						"_nume": "Ileana Popescu"
					}
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "352",
				"pretOriginal": "0",
				"pretCumparare": "136250",
				"colectia": {
					"_numarul": "0",
					"_nume": "Tehnologia informatiei"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ileana Popescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_bazelor_de_date_Popescu.jpg"
					},
					"__text": "Vlad Oancea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea bazelor de date relationale"
						},
						{
							"_nr": "3",
							"__text": "Proiectarea bazelor de date orientate obiect"
						},
						{
							"_nr": "4",
							"__text": "Proiectarea bazelor de date relationare obiect"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea bazelor da date distribuite"
						},
						{
							"_nr": "6",
							"__text": "Procesarea interogarilor in sisteme de gestiune a bazelor de date relationale"
						},
						{
							"_nr": "7",
							"__text": "Procesarea interogarilor in sisteme de gestiune a bazelor de date orientate obiect"
						},
						{
							"_nr": "8",
							"__text": "Procesarea interogarilor in sisteme de gestiune a bazelor de date relationale oriectate obiect"
						},
						{
							"_nr": "9",
							"__text": "Procesarea interogarilor in sisteme de gestiune a bazelor de date distribuite"
						},
						{
							"_nr": "10",
							"__text": "Concurenta in baze de date"
						}
					]
				},
				"_id": "279",
				"_isbn": "973-31-1555-X"
			},
			{
				"titlu": "Programare avansata in Oracle9i",
				"autori": {
					"autor": [
						{
							"_nume": "Ileana Popescu"
						},
						{
							"_nume": "Letitia Velcescu"
						},
						{
							"_nume": "Alexandra Alecu"
						},
						{
							"_nume": "Gabriela Florea"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Tehnica",
				"numarPagini": "532",
				"pretOriginal": "0",
				"pretCumparare": "239800",
				"colectia": {
					"_numarul": "0",
					"_nume": "Tehnologia informatiei"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_avansata_in_Oracle9i_Popescu.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura sistemelor Oracle9i"
						},
						{
							"_nr": "2",
							"__text": "SQL"
						},
						{
							"_nr": "3",
							"__text": "Programare in PL/SQL"
						},
						{
							"_nr": "4",
							"__text": "Orientare pe obiecte in Oracle9i"
						}
					]
				},
				"_id": "280",
				"_isbn": "973-31-2208-4"
			},
			{
				"titlu": "Baze de date relationale si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Felicia Ionescu"
					}
				},
				"anAparitie": "2004",
				"editura": "Tehnica",
				"tiraj": "500",
				"numarPagini": "348",
				"pretOriginal": "120000",
				"pretCumparare": "187500",
				"colectia": {
					"_numarul": "0",
					"_nume": "Tehnologia informatiei"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_relationale_si_aplicatii_Ionescu.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de baze de date"
						},
						{
							"_nr": "1.1",
							"__text": "Componentele unui sistem de baza de date"
						},
						{
							"_nr": "1.2",
							"__text": "Arhitectura interna a sistemelor de baza de date"
						},
						{
							"_nr": "1.3",
							"__text": "Avantajele sistemelor de baza de date"
						},
						{
							"_nr": "1.4",
							"__text": "Clasificarea sistemelor de baza de date"
						},
						{
							"_nr": "1.5",
							"__text": "Modelarea datelor"
						},
						{
							"_nr": "2",
							"__text": "Modelul relational"
						},
						{
							"_nr": "2.1",
							"__text": "Relatii"
						},
						{
							"_nr": "2.2",
							"__text": "Limbajul SQL"
						},
						{
							"_nr": "2.3",
							"__text": "Constrangeri de integritate"
						},
						{
							"_nr": "2.4",
							"__text": "Indexarea relatiilor"
						},
						{
							"_nr": "2.5",
							"__text": "Securitatea si protectia bazelor de date"
						},
						{
							"_nr": "2.6",
							"__text": "Transpunerea modelui Entitate-Asociere in model relational"
						},
						{
							"_nr": "2.7",
							"__text": "Terminologia in domeniul bazelor de date relationale"
						},
						{
							"_nr": "3",
							"__text": "Interogarea bazelor de date"
						},
						{
							"_nr": "3.1",
							"__text": "Algebra relationala"
						},
						{
							"_nr": "3.2",
							"__text": "Calcul relational"
						},
						{
							"_nr": "3.3",
							"__text": "Prelucarea si optimizarea interogarilor"
						},
						{
							"_nr": "4",
							"__text": "Limbaje si biblioteci de programare a aplicatiilor de baze de date"
						},
						{
							"_nr": "4.1",
							"__text": "Limbaje procedurale de extensie a limbajului SQL"
						},
						{
							"_nr": "4.2",
							"__text": "Limbajul SQL integrat"
						},
						{
							"_nr": "4.3",
							"__text": "Biblioteci de programare a aplicatiilor de baze de date"
						},
						{
							"_nr": "4.4",
							"__text": "Tehnologia Java in sistemele de baza de date"
						},
						{
							"_nr": "5",
							"__text": "Normalizarea relatiilor"
						},
						{
							"_nr": "5.1",
							"__text": "Redundata datelor si anomaliile de actualizare a relatiilor"
						},
						{
							"_nr": "5.2",
							"__text": "Dependente functionale"
						},
						{
							"_nr": "5.3",
							"__text": "Dependente multivalorice"
						},
						{
							"_nr": "5.4",
							"__text": "Dependente de joctiune si a cincia forma normala (FN5)"
						},
						{
							"_nr": "5.5",
							"__text": "Decizii de normalizare a relatiilor"
						},
						{
							"_nr": "6",
							"__text": "Gestiunea tranzactiilor si refacerea bazelor de date"
						},
						{
							"_nr": "6.1",
							"__text": "Tranzactii"
						},
						{
							"_nr": "6.2",
							"__text": "Tehnici de control a concurentei"
						},
						{
							"_nr": "6.3",
							"__text": "Tehnici de refacere a bazelor de date"
						},
						{
							"_nr": "6.4",
							"__text": "Controlul tranzactiilor din programele de aplicatii"
						},
						{
							"_nr": "6.5",
							"__text": "Decizii de proiectare a tranzactiilor"
						},
						{
							"_nr": "7",
							"__text": "Proiectarea bazelor de date si a aplicatiilor"
						},
						{
							"_nr": "7.1",
							"__text": "Fazele de proiectare a bazelor de date"
						},
						{
							"_nr": "7.2",
							"__text": "Aplicatii distribuite de baza de date"
						},
						{
							"_nr": "7.3",
							"__text": "Decizii de proiectare a aplicatiilor de baze de date"
						}
					]
				},
				"_id": "281",
				"_isbn": "973-31-2207-6"
			},
			{
				"titlu": "XML",
				"autori": {
					"autor": {
						"_nume": "Lee Anne Phillips"
					}
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "816",
				"pretOriginal": "0",
				"pretCumparare": "300000",
				"colectia": {
					"_numarul": "407",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Special Edition Using XML",
					"editura": "",
					"traducatori": {
						"traducator": [
							"Ioan Bledea",
							"Eugen Sipos"
						]
					},
					"_an": "2000",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Marius Stuparu"
				},
				"coperta": {
					"imagine": {
						"_path": "XML_Phillips.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fundamentele XML"
						},
						{
							"_nr": "1",
							"__text": "Introducere in XML"
						},
						{
							"_nr": "2",
							"__text": "Sintaxa XML"
						},
						{
							"_nr": "3",
							"__text": "Definitiile tipurilor documentului XML"
						},
						{
							"_nr": "4",
							"__text": "Extinderea definitiei tipului documententului prin modificari locale"
						},
						{
							"_nr": "5",
							"__text": "Crearea unui DTD pornind de la zero"
						},
						{
							"_nr": "6",
							"__text": "Zone de nume XML"
						},
						{
							"_nr": "7",
							"__text": "Scheme XML"
						},
						{
							"_nr": "8",
							"__text": "XPath"
						},
						{
							"_nr": "9",
							"__text": "XLink si XPointer"
						},
						{
							"_nr": "II",
							"__text": "Manipularea XML"
						},
						{
							"_nr": "10",
							"__text": "DOM (Document Object Model) - Modelul document obiect"
						},
						{
							"_nr": "11",
							"__text": "SAX (the Simple API for XML) - API simplu pentru XML"
						},
						{
							"_nr": "12",
							"__text": "CSS1, CSS2, DSSL, XSL"
						},
						{
							"_nr": "13",
							"__text": "Foi de stil in cascada si XML/XHTML"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea generatiei urmatoare de foi de stil extensibile  - XSL"
						},
						{
							"_nr": "III",
							"__text": "Integrarea XML cu alte tehnologii"
						},
						{
							"_nr": "15",
							"__text": "Obtinerea informatiilor despre utilizator"
						},
						{
							"_nr": "16",
							"__text": "Securitate si confidentialitate"
						},
						{
							"_nr": "17",
							"__text": "Utilizarea facilitatilor server-side - Java"
						},
						{
							"_nr": "18",
							"__text": "Utilizarea facilitatilor server-side - ActiveX"
						},
						{
							"_nr": "19",
							"__text": "Utilizarea brokerilor de obiecte comune"
						},
						{
							"_nr": "20",
							"__text": "Planificarea resurselor pentru intreprinderi in XML"
						},
						{
							"_nr": "IV",
							"__text": "Alte aplicatii ale XML"
						},
						{
							"_nr": "21",
							"__text": "RDF (The Resource Description Framework - cadrul descrierii resurselor)"
						},
						{
							"_nr": "22",
							"__text": "XHTML in detaliu"
						},
						{
							"_nr": "23",
							"__text": "Utilizarea SMIL (The Synchronized Multimedia Integration Language - limbaj de integrare multimedia sincronizat)"
						},
						{
							"_nr": "24",
							"__text": "Utilizarea MathML (The Math Markup Language - limbajul de marcare matematic)"
						},
						{
							"_nr": "25",
							"__text": "Accesibilitate"
						},
						{
							"_nr": "26",
							"__text": "Cooperarea in domeniu si resurse"
						},
						{
							"_nr": "27",
							"__text": "Concluzii - viitorul in Web"
						}
					]
				},
				"_id": "282",
				"_isbn": "973-20-0604-8"
			},
			{
				"titlu": "C# pentru programarea Web",
				"autori": {
					"autor": [
						{
							"_nume": "Chris H. Pappas"
						},
						{
							"_nume": "William H. Murray"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "All",
				"numarPagini": "474",
				"pretOriginal": "0",
				"pretCumparare": "319000",
				"traducere": {
					"titluOriginal": "C# for web programming",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": [
							"Bogdan Caranda",
							"Razvan Raica"
						]
					},
					"_an": "2002",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Daniela Slavu"
				},
				"coperta": {
					"imagine": {
						"_path": "C#_pentru_programare_Web_Pappas.jpg"
					},
					"__text": "Andreea Dobreci"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calatoria spre C#"
						},
						{
							"_nr": "2",
							"__text": "Elemente esentiale ale C#"
						},
						{
							"_nr": "3",
							"__text": "C# si Visual Studio.NET"
						},
						{
							"_nr": "4",
							"__text": "Proprietatile statice ale controalelor"
						},
						{
							"_nr": "5",
							"__text": "Proprietatile dinamice ale controalelor"
						},
						{
							"_nr": "6",
							"__text": "Evenimente"
						},
						{
							"_nr": "7",
							"__text": "Procesarea formularelor Web pentru introducerea datelor"
						},
						{
							"_nr": "8",
							"__text": "Procesarea formularelor Web pentru afisare datelor"
						},
						{
							"_nr": "9",
							"__text": "Fundamentele proiectarii GDI"
						},
						{
							"_nr": "10",
							"__text": "Grafica de prezentare"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii numerice si conversii"
						},
						{
							"_nr": "12",
							"__text": "Servicii Web"
						}
					]
				},
				"_id": "283",
				"_isbn": "973-571-472-8"
			},
			{
				"titlu": "Virusii calculatoarelor",
				"autori": {
					"autor": {
						"_nume": "Dr. Frederick B. Cohen"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "256",
				"pretOriginal": "9500",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "73",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "A short course on computer viruses",
					"editura": "John Wiley and Sons",
					"traducatori": {
						"traducator": "Rodica German"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Virusi_calculatorului_Cohen.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Virusi ai realitati"
						},
						{
							"_nr": "3",
							"__text": "Protectii de natura tehnica"
						},
						{
							"_nr": "4",
							"__text": "Metode de aparare netehnice"
						},
						{
							"_nr": "5",
							"__text": "Analize"
						},
						{
							"_nr": "6",
							"__text": "Tactica si strategie in tehnicile de aparare antivirus"
						},
						{
							"_nr": "7",
							"__text": "Cuvant de incheiere"
						},
						{
							"_nr": "A",
							"__text": "Un banc bun"
						},
						{
							"_nr": "B",
							"__text": "Definitia formal a unui virus de calculator"
						},
						{
							"_nr": "C",
							"__text": "Modele de tehnici defensive ce pot fi puse in aplicare"
						},
						{
							"_nr": "D",
							"__text": "Cateva modele de forme de evolutie a programelelor"
						},
						{
							"_nr": "E",
							"__text": "Virusi in retelele de calculatoare"
						}
					]
				},
				"_id": "284",
				"_isbn": "973-601-261-1"
			},
			{
				"titlu": "Initiere in limbajul C",
				"autori": {
					"autor": {
						"_nume": "Damian Costea"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "320",
				"pretOriginal": "12000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "46",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_limbajul_C_Costea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Principiile C"
						},
						{
							"_nr": "2",
							"__text": "Instructiunile de control ale programului"
						},
						{
							"_nr": "3",
							"__text": "Aprofundarea instructiunilor de control ale programului"
						},
						{
							"_nr": "4",
							"__text": "O privire atenta asupra tipurilor de date, variabilelor si expresiilor"
						},
						{
							"_nr": "5",
							"__text": "Tablouri si siruri"
						},
						{
							"_nr": "6",
							"__text": "Folosirea pointerilor"
						},
						{
							"_nr": "7",
							"__text": "O privire atenta asupra functiilor"
						},
						{
							"_nr": "8",
							"__text": "Functiile de intrare/iesire (I/O) pentru consola"
						},
						{
							"_nr": "9",
							"__text": "Functiile de intrare/iesire (I/O) pentru fisiere"
						},
						{
							"_nr": "10",
							"__text": "Structuri si uniuni"
						},
						{
							"_nr": "11",
							"__text": "Operatori"
						},
						{
							"_nr": "12",
							"__text": "Preprocesorul C si alte chestiuni avansate"
						},
						{
							"_nr": "13",
							"__text": "Functiile video"
						},
						{
							"_nr": "A",
							"__text": "Cateva din cele mai utilizate functii din biblioteca C"
						},
						{
							"_nr": "B",
							"__text": "Sumar al cuvintelor cheie"
						},
						{
							"_nr": "C",
							"__text": "Raspunsuri"
						}
					]
				},
				"_id": "285",
				"_isbn": "973-601-200-X"
			},
			{
				"titlu": "Informatica pentru grupele de performanta (clasa a XII-a)",
				"autori": {
					"autor": {
						"_nume": "Mihai Scortaru"
					}
				},
				"anAparitie": "2004",
				"editura": "Dacia educational",
				"numarPagini": "148",
				"pretOriginal": "0",
				"pretCumparare": "114500",
				"tehnoredactori": {
					"tehnoredactor": [
						"Mihai Scortaru",
						"Claudiu Soroiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_grupele_de_performanta_XII_Ionescu.jpg"
					},
					"__text": "Sorin Luca"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Problemele olimpiadelor de informatica"
						},
						{
							"_nr": "1.1",
							"__text": "Faza Locala Cluj, 2003"
						},
						{
							"_nr": "1.2",
							"__text": "Olimpiada Nationala de Informatica, 2002"
						},
						{
							"_nr": "1.3",
							"__text": "Solutiile problemelor propuse spre rezolvare la olimpiada de informatica Faza Locala Clusj 2003"
						},
						{
							"_nr": "1.4",
							"__text": "Solutiile problemelor propuse spre rezolvare la Olimpiada Nationala de Informatica 2002"
						},
						{
							"_nr": "2",
							"__text": "Problemele concursului de programare \"Bursele Agora\""
						},
						{
							"_nr": "2.1",
							"__text": "Problemele propuse in editia 2000-2001"
						},
						{
							"_nr": "2.2",
							"__text": "Problemele propuse in editia 2001-2002"
						},
						{
							"_nr": "2.3",
							"__text": "Solutiile problemelor propuse in editia 2000-2001"
						},
						{
							"_nr": "2.4",
							"__text": "Solutiile problemelor propuse in editia 2001-2002"
						}
					]
				},
				"_id": "286",
				"_isbn": "973-35-1904-2"
			},
			{
				"titlu": "Informatica pentru grupele de performanta (clasa a XI-a)",
				"autori": {
					"autor": {
						"_nume": "Mihai Scortaru"
					}
				},
				"anAparitie": "2004",
				"editura": "Dacia educational",
				"numarPagini": "324",
				"pretOriginal": "0",
				"pretCumparare": "234400",
				"tehnoredactori": {
					"tehnoredactor": [
						"Mihai Scortaru",
						"Claudiu Soroiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_grupele_de_performanta_XI_Ionescu.jpg"
					},
					"__text": "Sorin Luca"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concursuri"
						},
						{
							"_nr": "2",
							"__text": "Analiza complexitatii"
						},
						{
							"_nr": "I",
							"__text": "Teoria grafurilor"
						},
						{
							"_nr": "3",
							"__text": "Notiuni elementare"
						},
						{
							"_nr": "4",
							"__text": "Tare-conexitate"
						},
						{
							"_nr": "5",
							"__text": "Cicluri"
						},
						{
							"_nr": "6",
							"__text": "Puncte de articulatie"
						},
						{
							"_nr": "7",
							"__text": "Punti in grafuri"
						},
						{
							"_nr": "8",
							"__text": "Bioconexitate"
						},
						{
							"_nr": "9",
							"__text": "Fluxuri"
						},
						{
							"_nr": "10",
							"__text": "Cuplaje maxime"
						},
						{
							"_nr": "II",
							"__text": "Probleme NP"
						},
						{
							"_nr": "11",
							"__text": "NP-completitudine"
						},
						{
							"_nr": "12",
							"__text": "Masurarea timpului"
						},
						{
							"_nr": "13",
							"__text": "Probleme NP-complete"
						},
						{
							"_nr": "14",
							"__text": "Algoritmi probabilisti"
						},
						{
							"_nr": "15",
							"__text": "Algoritmi genetici"
						},
						{
							"_nr": "16",
							"__text": "Multi Expression Programming"
						},
						{
							"_nr": "III",
							"__text": "Geometrie computationala"
						},
						{
							"_nr": "17",
							"__text": "Linii si segmente"
						},
						{
							"_nr": "18",
							"__text": "Infasuratoarea convexa"
						},
						{
							"_nr": "19",
							"__text": "Distante"
						},
						{
							"_nr": "IV",
							"__text": "Algoritmi avansati"
						},
						{
							"_nr": "20",
							"__text": "Teoria numerelor"
						},
						{
							"_nr": "21",
							"__text": "Potrivirea sirurilor"
						},
						{
							"_nr": "22",
							"__text": "Arbori indexati binar"
						}
					]
				},
				"_id": "287",
				"_isbn": "973-35-1803-4"
			},
			{
				"titlu": "PERL",
				"autori": {
					"autor": [
						{
							"_nume": "R. Allen Wyke"
						},
						{
							"_nume": "Donald B. Thomas"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "459",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Perl : A Beginner's Guide",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": "Cristian Danciulescu"
					},
					"_an": "2001",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "PERL_Wyke.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Inceputul"
						},
						{
							"_nr": "1",
							"__text": "Introducere in Perl"
						},
						{
							"_nr": "2",
							"__text": "Date scalare"
						},
						{
							"_nr": "3",
							"__text": "Structuri de control"
						},
						{
							"_nr": "4",
							"__text": "Liste, tabluri si dispersii"
						},
						{
							"_nr": "5",
							"__text": "Fluxul de program si subrutine"
						},
						{
							"_nr": "II",
							"__text": "Aplicarea cunostiintelor"
						},
						{
							"_nr": "6",
							"__text": "Lucrul cu fisire si directoare"
						},
						{
							"_nr": "7",
							"__text": "Expresii regulate"
						},
						{
							"_nr": "8",
							"__text": "Pachete si module"
						},
						{
							"_nr": "9",
							"__text": "Interactiunea cu bazele de date folosinf Perl DBI"
						},
						{
							"_nr": "10",
							"__text": "Scrierea programelor CGI"
						},
						{
							"_nr": "11",
							"__text": "Functii si solutii trans-platforma"
						},
						{
							"_nr": "12",
							"__text": "Mesaje de eroare si depanarea"
						},
						{
							"_nr": "13",
							"__text": "Concepte si caracteristici avansate"
						},
						{
							"_nr": "Anexa",
							"__text": "Raspunsuri la verificarea cunostiintelor"
						}
					]
				},
				"_id": "288",
				"_isbn": "973-20-0641-2"
			},
			{
				"titlu": "Probleme de analiza numerica",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Marinescu"
						},
						{
							"_nume": "Lori Nadea"
						},
						{
							"_nume": "Badea Grigore"
						},
						{
							"_nume": "Csaba Jambor"
						},
						{
							"_nume": "Petre Mazilu"
						},
						{
							"_nume": "Irina Rizzoli"
						},
						{
							"_nume": "Cristina Stefan"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Didactica si pedagogica",
				"numarPagini": "208",
				"pretOriginal": "7.65",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Magdalena Stavinschi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ghimis"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_analiza_numerica_Rizzoli.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Operatii cu numere reale"
						},
						{
							"_nr": "II",
							"__text": "Aproximarea numerica a functiilor"
						},
						{
							"_nr": "III",
							"__text": "Calculul numeric al integralelor"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme liniare"
						},
						{
							"_nr": "V",
							"__text": "Ecuatii neliniare"
						},
						{
							"_nr": "VI",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "VII",
							"__text": "Sisteme mari"
						},
						{
							"_nr": "VIII",
							"__text": "Ecuatii diferentiale si cu derivate partiale"
						}
					]
				},
				"_id": "289",
				"_isbn": ""
			},
			{
				"titlu": "Tehnici de programare",
				"autori": {
					"autor": {
						"_nume": "Horia Georgescu"
					}
				},
				"anAparitie": "2005",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "142",
				"pretOriginal": "105000",
				"pretCumparare": "10500",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_Georgescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre algoritmi"
						},
						{
							"_nr": "2",
							"__text": "Arbori"
						},
						{
							"_nr": "3",
							"__text": "Grafuri"
						},
						{
							"_nr": "4",
							"__text": "Metoda Greedy"
						},
						{
							"_nr": "5",
							"__text": "Metoda Backtraking"
						},
						{
							"_nr": "6",
							"__text": "Metoda Divide et Impera"
						},
						{
							"_nr": "7",
							"__text": "Metoda programarii dinamice"
						},
						{
							"_nr": "8",
							"__text": "Metoda Branch and Bound"
						},
						{
							"_nr": "9",
							"__text": "Drumuri in grafuri"
						},
						{
							"_nr": "10",
							"__text": "Alte tipuri de algoritmi"
						},
						{
							"_nr": "11",
							"__text": "Algoritmi liniari"
						},
						{
							"_nr": "12",
							"__text": "NP-Completitudine"
						},
						{
							"_nr": "13",
							"__text": "Un algoritm de analiza sintactica"
						},
						{
							"_nr": "14",
							"__text": "Expresii aritmetice"
						}
					]
				},
				"_id": "290",
				"_isbn": "973-737-094-5"
			},
			{
				"titlu": "Tehnici statistice de Data Mining",
				"autori": {
					"autor": {
						"_nume": "Denis Enachescu"
					}
				},
				"anAparitie": "2003",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "160",
				"pretOriginal": "115000",
				"pretCumparare": "115000",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_statistice_de_data_mining_Enachescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode de analiza factoriala"
						},
						{
							"_nr": "1.1",
							"__text": "Preliminarii matematice"
						},
						{
							"_nr": "1.2",
							"__text": "Analiza in componente principale (ACP)"
						},
						{
							"_nr": "1.3",
							"__text": "Analiza corespondentelor simple (ACS)"
						},
						{
							"_nr": "1.4",
							"__text": "Analiza corespondentelor multiple (ACM)"
						},
						{
							"_nr": "2",
							"__text": "Metode de clasificare"
						},
						{
							"_nr": "2.1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2.2",
							"__text": "Aspecte combinatorii ale clasificarii"
						},
						{
							"_nr": "2.3",
							"__text": "Metode de clasificare neierarhice"
						},
						{
							"_nr": "2.4",
							"__text": "Clasificae ierarhica"
						},
						{
							"_nr": "2.5",
							"__text": "Clasificare mixta si descrierea statistica a claselor"
						},
						{
							"_nr": "3",
							"__text": "Metode explicative uzuale"
						},
						{
							"_nr": "3.1",
							"__text": "Analiza discriminanta"
						},
						{
							"_nr": "3.2",
							"__text": "Metode probabiliste de discriminare"
						},
						{
							"_nr": "3.3",
							"__text": "Segmentare"
						}
					]
				},
				"_id": "291",
				"_isbn": "973-575-914-8"
			},
			{
				"titlu": "Modele de simulare",
				"autori": {
					"autor": {
						"_nume": "Ion Vaduva"
					}
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "192",
				"pretOriginal": "195000",
				"pretCumparare": "195000",
				"coperta": {
					"imagine": {
						"_path": "Modele_de_simulare_Vaduva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati despre simulare"
						},
						{
							"_nr": "1.1",
							"__text": "Introducere"
						},
						{
							"_nr": "1.2",
							"__text": "Constructia unui model de simulare"
						},
						{
							"_nr": "1.3",
							"__text": "Generalitati despre limbajul GPSS"
						},
						{
							"_nr": "2",
							"__text": "Numere aleatoare"
						},
						{
							"_nr": "2.1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2.2",
							"__text": "Necesitatea simularii numerelor aleatoare"
						},
						{
							"_nr": "2.3",
							"__text": "Metodele congruentiale liniare"
						},
						{
							"_nr": "2.4",
							"__text": "Alti generatori de numere aleatoare uniforme"
						},
						{
							"_nr": "3",
							"__text": "Simularea variabilelor neuniforme"
						},
						{
							"_nr": "3.1",
							"__text": "Metoda inversa"
						},
						{
							"_nr": "3.2",
							"__text": "Metoda compunerii sau amestecarii"
						},
						{
							"_nr": "3.3",
							"__text": "Metoda respingerii"
						},
						{
							"_nr": "3.4",
							"__text": "Alte metode"
						},
						{
							"_nr": "3.5",
							"__text": "Simularea unor variabile aleatoare particulare"
						},
						{
							"_nr": "3.6",
							"__text": "Simularea unor variabile discrete"
						},
						{
							"_nr": "3.7",
							"__text": "Validarea generatorilor"
						},
						{
							"_nr": "4",
							"__text": "Simularea vectorilor aleatori"
						},
						{
							"_nr": "4.1",
							"__text": "Generalitati"
						},
						{
							"_nr": "4.2",
							"__text": "Simularea vectorilor uniformi"
						},
						{
							"_nr": "4.3",
							"__text": "Simularea vectorilor normali"
						},
						{
							"_nr": "4.4",
							"__text": "Simularea repartitiei Cauchy multidimenensionale"
						},
						{
							"_nr": "4.5",
							"__text": "Simularea repartitiei multinominale"
						},
						{
							"_nr": "4.6",
							"__text": "Simularea repartitiei Dirichlet"
						},
						{
							"_nr": "5",
							"__text": "Simularea proceselor stochastice"
						},
						{
							"_nr": "5.1",
							"__text": "Generalitati"
						},
						{
							"_nr": "5.2",
							"__text": "Lanturi si procese Markov"
						},
						{
							"_nr": "5.3",
							"__text": "Simularea unui lant Markov"
						},
						{
							"_nr": "5.4",
							"__text": "Simularea unor procese gaussiene particulare"
						},
						{
							"_nr": "5.5",
							"__text": "Simularea procesului Poisson"
						},
						{
							"_nr": "6",
							"__text": "Metoda Monte Carlo"
						},
						{
							"_nr": "6.1",
							"__text": "Generalitati"
						},
						{
							"_nr": "6.2",
							"__text": "Metoda Monte Carlo bruta"
						},
						{
							"_nr": "6.3",
							"__text": "Metode de reducere a dispersiei"
						},
						{
							"_nr": "6.4",
							"__text": "Rezolvarea unor ecuatii operatoriale"
						},
						{
							"_nr": "6.5",
							"__text": "Rezolvarea ecuatiillor cu derivate partiale"
						},
						{
							"_nr": "7",
							"__text": "Cateva modele de simulare"
						},
						{
							"_nr": "7.1",
							"__text": "Generalitati despre modelele de asteptare"
						},
						{
							"_nr": "7.2",
							"__text": "Simularea unui sistem cu o statie"
						},
						{
							"_nr": "7.3",
							"__text": "Simularea unui sistem cu N statii paralele"
						},
						{
							"_nr": "7.4",
							"__text": "Modele de simulare pentru stocuri"
						}
					]
				},
				"_id": "292",
				"_isbn": "973-575-862-8"
			},
			{
				"titlu": "Cautarea si reprezentarea cunostiintelor in inteligenta artificiala. Teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Florentina Hristea"
						},
						{
							"_nume": "Maria Florina Balcan"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "364",
				"pretOriginal": "435000",
				"pretCumparare": "435000",
				"coperta": {
					"imagine": {
						"_path": "Cautarea_si_reprezentarea_cunostiintelor_in_inteligenta_artificiala_Teorie_si_aplicatii_Hristea.jpg"
					},
					"__text": "Ana-Maria Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Consideratii preliminare"
						},
						{
							"_nr": "1.1",
							"__text": "Domeniuk inteligentei artificiale. Defintii si un scurt istoric"
						},
						{
							"_nr": "1.2",
							"__text": "Sudomenii ale inteligentei artificiale"
						},
						{
							"_nr": "1.3",
							"__text": "Tehnici de cautare"
						},
						{
							"_nr": "1.4",
							"__text": "Un exemplu de problema tipica in inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Tehnici de cautare"
						},
						{
							"_nr": "2.1",
							"__text": "Rezolvarea problemelor prin intermediul cautarii"
						},
						{
							"_nr": "2.2",
							"__text": "Cautarea neinformata"
						},
						{
							"_nr": "2.3",
							"__text": "Cautarea informata"
						},
						{
							"_nr": "3",
							"__text": "Jocurile ca probleme de cautare"
						},
						{
							"_nr": "3.1",
							"__text": "O definire formala a jocurilor"
						},
						{
							"_nr": "3.2",
							"__text": "Algoritmul Minimax"
						},
						{
							"_nr": "3.3",
							"__text": "O implementare eficienta a princiului Minimax: Algoritmul Alpha-Beta"
						},
						{
							"_nr": "3.4",
							"__text": "Un exenplu. Jocul X si O"
						},
						{
							"_nr": "4",
							"__text": "Aspecte ale reprezentarii cunostintelor"
						},
						{
							"_nr": "4.1",
							"__text": "Tipuri de cunostinte"
						},
						{
							"_nr": "4.2",
							"__text": "Clase de metode pentru reprezentarea cunostintelor"
						},
						{
							"_nr": "4.3",
							"__text": "Reprezentarea cunostintelor in sistemele expert"
						},
						{
							"_nr": "4.4",
							"__text": "Retele semantice si cadre"
						},
						{
							"_nr": "4.5",
							"__text": "Consideratii finale"
						},
						{
							"_nr": "5",
							"__text": "Generarea semiautomata a retelelor semantice de tip WORDNET"
						},
						{
							"_nr": "5.1",
							"__text": "Considerarii introductive"
						},
						{
							"_nr": "5.2",
							"__text": "Algoritmul de traducere"
						},
						{
							"_nr": "5.3",
							"__text": "Synset-uri de substantive"
						},
						{
							"_nr": "5.4",
							"__text": "Synset-uri si cluster-e de adjective"
						},
						{
							"_nr": "5.5",
							"__text": "Alte comentarii lingistice privitoare la rezultatele obtinute"
						},
						{
							"_nr": "5.6",
							"__text": "Revizitarea synset-urilor de substantive"
						},
						{
							"_nr": "5.7",
							"__text": "Synset-uri de verbe"
						},
						{
							"_nr": "5.8",
							"__text": "Synset-uri de adverbe"
						},
						{
							"_nr": "5.9",
							"__text": "Consideratii finale"
						},
						{
							"_nr": "6",
							"__text": "Rationament statistic"
						},
						{
							"_nr": "6.1",
							"__text": "Abordari statistice pentru sistemel bazate pe reguli"
						},
						{
							"_nr": "7",
							"__text": "Cautare si reprezentarea cunostiintelor in procesarea limbajului natural. Analiza sintactica"
						},
						{
							"_nr": "7.1",
							"__text": "Notiunea de grup sintactic"
						},
						{
							"_nr": "7.2",
							"__text": "Gramatici"
						},
						{
							"_nr": "7.3",
							"__text": "Analiza sintactica bazate pe constituenti"
						}
					]
				},
				"_id": "293",
				"_isbn": "973-575-996-9"
			},
			{
				"titlu": "Teoria codurilor corectoare de erori",
				"autori": {
					"autor": {
						"_nume": "Adrian Aranasiu"
					}
				},
				"anAparitie": "2001",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "338",
				"pretOriginal": "235000",
				"pretCumparare": "235000",
				"coperta": {
					"imagine": {
						"_path": "Teoria_codurilor_corectoare_de_erori_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Codificare si decodificare"
						},
						{
							"_nr": "2",
							"__text": "Coduri liniare"
						},
						{
							"_nr": "3",
							"__text": "Clase de coduri liniare"
						},
						{
							"_nr": "4",
							"__text": "Circuite liniare si extensii Galois"
						},
						{
							"_nr": "5",
							"__text": "Coduri ciclice"
						},
						{
							"_nr": "6",
							"__text": "Decodificarea codurilor ciclice"
						},
						{
							"_nr": "7",
							"__text": "Alte definitii ale codurilor ciclice"
						},
						{
							"_nr": "8",
							"__text": "Coduri BCH"
						},
						{
							"_nr": "9",
							"__text": "Coduri Reed-Solomon"
						},
						{
							"_nr": "10",
							"__text": "Alte clase de coduri ciclice"
						},
						{
							"_nr": "11",
							"__text": "Coduri Preparata"
						},
						{
							"_nr": "12",
							"__text": "Coduri convolutionale"
						},
						{
							"_nr": "13",
							"__text": "Supra - codificari"
						},
						{
							"_nr": "14",
							"__text": "Coduri aritmetice"
						}
					]
				},
				"_id": "294",
				"_isbn": "973-575-589-0"
			},
			{
				"titlu": "Combinatorica si teoria grafurilor",
				"autori": {
					"autor": {
						"_nume": "Dragos-Radu Popescu"
					}
				},
				"anAparitie": "2005",
				"editura": "Societatea de stiinte matematice din Romania",
				"numarPagini": "254",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Barbara Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Combinatorica_si_teoria_grafurilor_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Teoria grafurilor"
						},
						{
							"_nr": "1",
							"__text": "Repere istorice"
						},
						{
							"_nr": "2",
							"__text": "Definitii. Notatii. Conventii"
						},
						{
							"_nr": "3",
							"__text": "Teoreme de caracterizare a multiseturilor de numere care pot fi gradele unui graf"
						},
						{
							"_nr": "4",
							"__text": "Arbori"
						},
						{
							"_nr": "5",
							"__text": "Grafuri planare"
						},
						{
							"_nr": "6",
							"__text": "Linii euleriene"
						},
						{
							"_nr": "7",
							"__text": "Linii hamiltoniene"
						},
						{
							"_nr": "8",
							"__text": "Cuplaje"
						},
						{
							"_nr": "II",
							"__text": "Combinatorica"
						},
						{
							"_nr": "9",
							"__text": "Principii si metode de numarare"
						},
						{
							"_nr": "10",
							"__text": "Functii de numarare"
						},
						{
							"_nr": "11",
							"__text": "Numararea arborilor"
						}
					]
				},
				"_id": "295",
				"_isbn": "973-0-02908-3"
			},
			{
				"titlu": "Elemente de grafuri si combinatorica. Metode, algoritmi si programe",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Patrascoiu"
						},
						{
							"_nume": "Gheorghe Marian"
						},
						{
							"_nume": "Nicolae Mitroi"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "All",
				"numarPagini": "224",
				"pretOriginal": "6480",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Marius Somodi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Luminita Velicanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_grafuri_si_combinatorica_Metode_algoritmi_si_programe_Marian.jpg"
					},
					"__text": "Stanciu Stelian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elaborarea programelor"
						},
						{
							"_nr": "2",
							"__text": "Metode de programare"
						},
						{
							"_nr": "3",
							"__text": "Metode de rezolvare a unor probleme"
						},
						{
							"_nr": "4",
							"__text": "Generarea de multimi"
						},
						{
							"_nr": "5",
							"__text": "Relatii binare"
						},
						{
							"_nr": "6",
							"__text": "Grafuri"
						}
					]
				},
				"_id": "296",
				"_isbn": "973-571-017-X"
			},
			{
				"titlu": "Programare concurenta. Teorie si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Horia Georgescu"
					}
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "240",
				"pretOriginal": "7000",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_concurenta_Teorie_si_aplicatii_Georgescu.jpg"
					},
					"__text": "Silvia Candea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in programarea concurenta"
						},
						{
							"_nr": "2",
							"__text": "Excluderea reciproca"
						},
						{
							"_nr": "3",
							"__text": "Semafoare"
						},
						{
							"_nr": "4",
							"__text": "Monitoare"
						},
						{
							"_nr": "5",
							"__text": "Transmitera sincronizata a mesajelor"
						},
						{
							"_nr": "6",
							"__text": "Invocarea la distanta"
						},
						{
							"_nr": "7",
							"__text": "Resurse"
						}
					]
				},
				"_id": "297",
				"_isbn": "9734-31-0895-2"
			},
			{
				"titlu": "Corectitudinea algoritmilor",
				"autori": {
					"autor": {
						"_nume": "Tudor Balanescu"
					}
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "272",
				"pretOriginal": "7000",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "V. E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Corectitudinea_algoritmilor_Balanescu.jpg"
					},
					"__text": "Silvia Candea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Testare di depanare"
						},
						{
							"_nr": "1.1",
							"__text": "Notiuni generala"
						},
						{
							"_nr": "1.2",
							"__text": "Tehnici de testare a programelor"
						},
						{
							"_nr": "1.3",
							"__text": "Depnarea programelor Turbo Pascal"
						},
						{
							"_nr": "2",
							"__text": "Verificarea formalizata a programelor"
						},
						{
							"_nr": "2.1",
							"__text": "Regula compunerii secventiale"
						},
						{
							"_nr": "2.2",
							"__text": "Regula implicatiei"
						},
						{
							"_nr": "2.3",
							"__text": "Regula instructiunii de atribuire"
						},
						{
							"_nr": "2.4",
							"__text": "Regula instructiunii de decizie"
						},
						{
							"_nr": "2.5",
							"__text": "Reguli pentru instructiuni iterative"
						},
						{
							"_nr": "3",
							"__text": "Cea mai slaba preconditie si cea mai tare postconditie"
						},
						{
							"_nr": "3.1",
							"__text": "Cea mai slaba preconditie"
						},
						{
							"_nr": "3.2",
							"__text": "Cea mai tare postconditie"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni generalizate"
						},
						{
							"_nr": "4.1",
							"__text": "Instructiunea de atribuire multipla"
						},
						{
							"_nr": "4.2",
							"__text": "Instructiunea de decizie if fi"
						},
						{
							"_nr": "4.3",
							"__text": "Instructiuni iterative"
						},
						{
							"_nr": "4.4",
							"__text": "Principii de impartialitate la executarea instructiunilor nedeterministe"
						},
						{
							"_nr": "A",
							"__text": "Notiuni de logica"
						},
						{
							"_nr": "A.1",
							"__text": "Sintaxa expresiilor, a conditiilor booleane si a formulelor logice"
						},
						{
							"_nr": "A.2",
							"__text": "Semantica in logica interna si in logica externa"
						},
						{
							"_nr": "A.3",
							"__text": "Reguli de calcul cu propozitii si cu predicate"
						},
						{
							"_nr": "A.4",
							"__text": "Reguli de calcul cu operatori logici"
						}
					]
				},
				"_id": "298",
				"_isbn": "973-31-0895-2"
			},
			{
				"titlu": "Elemente de statistica informationala",
				"autori": {
					"autor": [
						{
							"_nume": "Octav Onicescu"
						},
						{
							"_nume": "Vasile Stefanescu"
						}
					]
				},
				"anAparitie": "1979",
				"editura": "Tehnica",
				"numarPagini": "212",
				"pretOriginal": "9.25",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_statistica_informationala_cu_aplicatii_Onicescu.jpg"
					},
					"__text": "Teodora Doxan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Caracteristici informationale"
						},
						{
							"_nr": "2",
							"__text": "Corelatii informationale inte lanturi sau procese Markoviene si cu legaturi complete"
						},
						{
							"_nr": "3",
							"__text": "Energia si corelatia informationala pentru diverse repartitii clasice"
						},
						{
							"_nr": "4",
							"__text": "Teoria informationala a chestionarului"
						},
						{
							"_nr": "5",
							"__text": "Analiza factorial calitativa"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii ale energiei informationale in analiza dispersiobala"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii ale energiei si corelatiei informationale la problema verificarii ipotezelor statistice si a teoriei estimatiei"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii practice in invatamant si lingvistica"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii in probleme sociale si de economice"
						},
						{
							"_nr": "10",
							"__text": "Aplicatii in cercetarile experimentale"
						},
						{
							"_nr": "Anexa I",
							"__text": "Procedee de extimare comparativa a unor obiecte purtatoare de mai multe caracteristici"
						},
						{
							"_nr": "Anexa II",
							"__text": "Masura concordantei si aplicatii la problema asociatiilor"
						},
						{
							"_nr": "Anexa III",
							"__text": "Scheme logice si program de calcul"
						}
					]
				},
				"_id": "299",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in teoria limbajelor formale",
				"autori": {
					"autor": {
						"_nume": "Virgil Emil Cazanescu"
					}
				},
				"anAparitie": "1983",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "168",
				"pretOriginal": "9.50",
				"pretCumparare": "28000",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Doina Nord"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_teoria_limbajelor_formale_Cazanescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Monoizi"
						},
						{
							"_nr": "1",
							"__text": "Multimi"
						},
						{
							"_nr": "2",
							"__text": "Monoizi"
						},
						{
							"_nr": "3",
							"__text": "Monoizi liberi"
						},
						{
							"_nr": "4",
							"__text": "M-module"
						},
						{
							"_nr": "5",
							"__text": "Monoizi echidivizibli"
						},
						{
							"_nr": "6",
							"__text": "Categorii"
						},
						{
							"_nr": "7",
							"__text": "Multimi partial ordonate"
						},
						{
							"_nr": "8",
							"__text": "Monoidul M[X]"
						},
						{
							"_nr": "II",
							"__text": "Parti recunoscute"
						},
						{
							"_nr": "1",
							"__text": "M-automate"
						},
						{
							"_nr": "2",
							"__text": "Monoidul sintactic"
						},
						{
							"_nr": "3",
							"__text": "Criterii de recunoastere"
						},
						{
							"_nr": "4",
							"__text": "Proprietarile parilor recunoscute"
						},
						{
							"_nr": "5",
							"__text": "Categoria M-automatelor nedeterministe"
						},
						{
							"_nr": "6",
							"__text": "Automatul minimal"
						},
						{
							"_nr": "III",
							"__text": "Parti rationale"
						},
						{
							"_nr": "1",
							"__text": "Parti rationale ale unui semigrup"
						},
						{
							"_nr": "2",
							"__text": "Parti rationale ale unui monoid"
						},
						{
							"_nr": "3",
							"__text": "Limbaje locale"
						},
						{
							"_nr": "4",
							"__text": "Relatii rationale"
						},
						{
							"_nr": "IV",
							"__text": "Gramatici peste un monoid"
						},
						{
							"_nr": "1",
							"__text": "Ierarhia lui Chomsky"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de ecuatii"
						},
						{
							"_nr": "3",
							"__text": "Parti rationale si gramatici"
						},
						{
							"_nr": "V",
							"__text": "Parti algebrice"
						},
						{
							"_nr": "1",
							"__text": "Teorema substitutiei"
						},
						{
							"_nr": "2",
							"__text": "Expresii algebrice"
						},
						{
							"_nr": "3",
							"__text": "Derivari stangi"
						},
						{
							"_nr": "4",
							"__text": "Despre ambiguitate"
						},
						{
							"_nr": "VI",
							"__text": "Forme normale"
						},
						{
							"_nr": "1",
							"__text": "Eliminarea productiilor de forma (x,1)"
						},
						{
							"_nr": "2",
							"__text": "Eliminarea neterminalelor care genereaza parte vida"
						},
						{
							"_nr": "3",
							"__text": "Eliminarea productiilor de forma (x,x')"
						},
						{
							"_nr": "4",
							"__text": "Eliminarea neterminalelor care nu apare intr-o derivare din axioma"
						},
						{
							"_nr": "5",
							"__text": "Forma normala Chomsky"
						},
						{
							"_nr": "6",
							"__text": "Forma normala Greibach"
						},
						{
							"_nr": "7",
							"__text": "Limbaje Dyck"
						}
					]
				},
				"_id": "300",
				"_isbn": ""
			},
			{
				"titlu": "Gramatici matriciale",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Paun"
					}
				},
				"anAparitie": "1981",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "264",
				"pretOriginal": "12.50",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Maria Boricea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Gramatici_Matriciale_Paun.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Gramatici si limbaje matriciale"
						},
						{
							"_nr": "1",
							"__text": "Notiuni si rezultate preliminare din teoria limbajelor formale"
						},
						{
							"_nr": "2",
							"__text": "Aspecte de baza privind gramaticile matriciale"
						},
						{
							"_nr": "3",
							"__text": "Clase speciale de gramatici matriciale"
						},
						{
							"_nr": "II",
							"__text": "Aplicatii ale gramaticilor matriciale"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii in probleme economice"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii in studiul basmelor populare"
						},
						{
							"_nr": "6",
							"__text": "Gramatici matriciale si limbaje de programare"
						},
						{
							"_nr": "7",
							"__text": "Alte aplicatii si posibilitati de aplicare a gramaticilor matriciale"
						},
						{
							"_nr": "8",
							"__text": "Limbaje asocitate unor siruri remarcabile de numere"
						}
					]
				},
				"_id": "301",
				"_isbn": ""
			},
			{
				"titlu": "Teoria algebrica si a semigrupurilor cu aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Creanga"
						},
						{
							"_nume": "Dan Simovici"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"tiraj": "3350+20+75 exemplare legate",
				"numarPagini": "258",
				"pretOriginal": "16",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Lili Ticos"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_algebrica_a_semigrupurilor_cu_aplicatii_Creanga.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Relatii"
						},
						{
							"_nr": "2",
							"__text": "Relatia de ordine partiala"
						},
						{
							"_nr": "3",
							"__text": "Axioma alegerii"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de inchidere si spatii topologice"
						},
						{
							"_nr": "5",
							"__text": "Elemente de algebra universala"
						},
						{
							"_nr": "6",
							"__text": "Elemete si parti remarcabile intr-un grupoid"
						},
						{
							"_nr": "7",
							"__text": "Latici, semilatici si semigrupuri idempotente"
						},
						{
							"_nr": "8",
							"__text": "Semigrupuri Brandt"
						},
						{
							"_nr": "9",
							"__text": "Semigrupuri ciclice"
						},
						{
							"_nr": "10",
							"__text": "Ideale"
						},
						{
							"_nr": "11",
							"__text": "Relatiile Green"
						},
						{
							"_nr": "12",
							"__text": "Semigrupul transformarilor unei multimi"
						},
						{
							"_nr": "13",
							"__text": "Descompunerea semigrupurilor"
						},
						{
							"_nr": "14",
							"__text": "Semigrupuri libere si coduri"
						},
						{
							"_nr": "15",
							"__text": "Semiautomate si semimodule"
						},
						{
							"_nr": "16",
							"__text": "Semigrupuri topologice"
						},
						{
							"_nr": "17",
							"__text": "Reprezentarea semigrupurilor"
						}
					]
				},
				"_id": "302",
				"_isbn": ""
			},
			{
				"titlu": "Teoria erorilor de masurare si metoda celor mai mici patrate",
				"autori": {
					"autor": {
						"_nume": "Marin Tiron"
					}
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "5200+140 de exemplare legate",
				"numarPagini": "402",
				"pretOriginal": "22",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_erorilor_de_masurare_si_metoda_celor_mai_mici_patrate_Tiron.jpg"
					},
					"__text": "Nicolae Nicolaev"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Prelucrarea statistica si informationala a rezultatelor obtinute din masuratori"
						},
						{
							"_nr": "I",
							"__text": "Studiul repartitiei erorilor intamplatoare"
						},
						{
							"_nr": "II",
							"__text": "Repartitii clasice folosite in studiul erorilor de masurare"
						},
						{
							"_nr": "III",
							"__text": "Studiul variabilelor intamplatoare corelate"
						},
						{
							"_nr": "IV",
							"__text": "Estimarea preciziei rezultatelor obtinute din masuratori efectuate asupra uneia sau a mai multor marimi fizice"
						},
						{
							"_nr": "V",
							"__text": "Prelucrarea statistica a masuratorilor efectuate de erori intamplatoare si sistematice"
						},
						{
							"_nr": "VI",
							"__text": "Erori grosolane si criterii de excludere a acestora din masuratori"
						},
						{
							"_nr": "VII",
							"__text": "Elemente de prelucrare informationala a rezulatalor obtinute din masuratori"
						},
						{
							"_nr": "VIII",
							"__text": "Efectuarea calculelor cu numere aproximative"
						},
						{
							"_nr": "Partea II",
							"__text": "Metoda celor mai mici patrate"
						},
						{
							"_nr": "IX",
							"__text": "Metoda masuratorilor indirecte"
						},
						{
							"_nr": "X",
							"__text": "Metode de rezolvare a sistemelor de ecuatii normale liniare"
						},
						{
							"_nr": "XI",
							"__text": "Estimarea preciziei marimilor compensate prin metoda masuratorilor indirecte"
						},
						{
							"_nr": "XII",
							"__text": "Compensarea rezultatelor obtinute din masuratori directe conditionate"
						},
						{
							"_nr": "XIII",
							"__text": "Compensarea rezultatlor obtinute din masuratori indirecte legate de conditii"
						},
						{
							"_nr": "XIV",
							"__text": "Compensarea pe grupe"
						}
					]
				},
				"_id": "303",
				"_isbn": ""
			},
			{
				"titlu": "Probleme de cercetare operationala",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Gh. Vranceanu"
						},
						{
							"_nume": "Stefan Mititelu"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Tehnica",
				"numarPagini": "236",
				"pretOriginal": "15.00",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Ceru"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_cercetare_operationala_Vranceanu.jpg"
					},
					"__text": "Alexandru Banu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Grafuri"
						},
						{
							"_nr": "2",
							"__text": "Programare neliniara"
						},
						{
							"_nr": "3",
							"__text": "Jocuri strategice"
						},
						{
							"_nr": "4",
							"__text": "Teoria asteptarii"
						},
						{
							"_nr": "5",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "6",
							"__text": "Teoria stocurilor"
						},
						{
							"_nr": "7",
							"__text": "Teoria uzurii si inlocuirii utilajelor"
						}
					]
				},
				"_id": "304",
				"_isbn": ""
			},
			{
				"titlu": "Dictionar de informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Petrica Dumitru"
						},
						{
							"_nume": "Cristian Ciumale"
						},
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Tiberiu Popescu"
						},
						{
							"_nume": "Luca Serbanati"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "386",
				"pretOriginal": "21",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_de_informatica_Cristea.jpg"
					},
					"__text": "Gheorghe Motora"
				},
				"cuprins": {
					"capitol": {
						"_nr": ""
					}
				},
				"_id": "305",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in combinatorica",
				"autori": {
					"autor": {
						"_nume": "Ioan Tomescu"
					}
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "5450+140 exemplare legate",
				"numarPagini": "254",
				"pretOriginal": "18.50",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Ceru"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_combinatorica_Tomescu.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Multimi si functii"
						},
						{
							"_nr": "2",
							"__text": "Aranjamente, permutari, combinari"
						},
						{
							"_nr": "3",
							"__text": "Principiul includerii si al excluderii si aplicatii"
						},
						{
							"_nr": "4",
							"__text": "Numerele lui Stirling, Bell si Fibonacci"
						},
						{
							"_nr": "5",
							"__text": "Exprimarea unui intreg ca suma de numare intregi"
						},
						{
							"_nr": "6",
							"__text": "Numararea arborilor"
						},
						{
							"_nr": "7",
							"__text": "Determinarea numarului de subarborescentelor unei arborescente"
						},
						{
							"_nr": "8",
							"__text": "Grupuri de permutari si teorema lui Burnside"
						},
						{
							"_nr": "9",
							"__text": "Metoda de numarare Polya-de Bruijn"
						},
						{
							"_nr": "10",
							"__text": "Formule de inversiune"
						},
						{
							"_nr": "11",
							"__text": "Sisteme de reprezentanti distincti"
						},
						{
							"_nr": "12",
							"__text": "Teorema lui Ramsey"
						},
						{
							"_nr": "13",
							"__text": "Distante si drumuri minime in grafuri"
						},
						{
							"_nr": "14",
							"__text": "Determinarea multimilor intern stabile maximale si a numarului cromatic a unui graf"
						},
						{
							"_nr": "15",
							"__text": "Numarul maxim de colorari ale unui graf"
						}
					]
				},
				"_id": "306",
				"_isbn": ""
			},
			{
				"titlu": "Metoda drumului critic",
				"autori": {
					"autor": [
						{
							"_nume": "A. Kaufmann"
						},
						{
							"_nume": "G. Desbazeille"
						}
					]
				},
				"anAparitie": "1971",
				"editura": "Tehnica",
				"numarPagini": "198",
				"pretOriginal": "16.50",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"traducere": {
					"titluOriginal": "Le methode du chemin critique",
					"editura": "Dumod",
					"traducatori": {
						"traducator": [
							"Gavril Gamboan",
							"Ioan Tomescu"
						]
					},
					"_an": "1969",
					"_editia": "2",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Mararescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Metoda_drumului_critic_Kaufmann.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Grafuri de ordonantare"
						},
						{
							"_nr": "2",
							"__text": "Stabilirea unui program de cercetare sau de productie"
						},
						{
							"_nr": "3",
							"__text": "Generalizarea metodei PERT"
						},
						{
							"_nr": "4",
							"__text": "Optimizarea functiei economice a costurilor"
						}
					]
				},
				"_id": "307",
				"_isbn": ""
			},
			{
				"titlu": "Teoria jocurilor",
				"autori": {
					"autor": {
						"_nume": "Guillermo Owen"
					}
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"tiraj": "5925",
				"numarPagini": "254",
				"pretOriginal": "18.50",
				"pretCumparare": "40000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"traducere": {
					"titluOriginal": "Game theory",
					"editura": "W.B. Sauders Company",
					"traducatori": {
						"traducator": "Mihai Dragomirescu"
					},
					"_an": "1968",
					"_editia": "2",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_jocurilor_Owen.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conceptul de joc"
						},
						{
							"_nr": "2",
							"__text": "Jocuri de doua persoane cu suma nula"
						},
						{
							"_nr": "3",
							"__text": "Programare liniara"
						},
						{
							"_nr": "4",
							"__text": "Jocuri infinite"
						},
						{
							"_nr": "5",
							"__text": "Jocuri in mai multe etape"
						},
						{
							"_nr": "6",
							"__text": "Teoria utilitatii"
						},
						{
							"_nr": "7",
							"__text": "Jocuri de doua persoane cu suma arbitrara"
						},
						{
							"_nr": "8",
							"__text": "Jocuri de n persoane"
						},
						{
							"_nr": "9",
							"__text": "Alte concpte de solutie pentru jocurile de n persoane"
						},
						{
							"_nr": "10",
							"__text": "Modificari ale conceptului de joc"
						}
					]
				},
				"_id": "308",
				"_isbn": ""
			},
			{
				"titlu": "Teoria Optimizarii sistemelor mari",
				"autori": {
					"autor": {
						"_nume": "Leon S. Lasdon"
					}
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "3550+70 exemplare legate",
				"numarPagini": "622",
				"pretOriginal": "33",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"traducere": {
					"titluOriginal": "Optimization Theory for Large Systems",
					"editura": "Leon  S. Lasdon",
					"traducatori": {
						"traducator": "Stefan Cruceanu"
					},
					"_an": "1970",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_optimizarii_sistemelor_mari_Lasdon.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programare liniara si neliniara"
						},
						{
							"_nr": "2",
							"__text": "Probleme matematice mari cu structura speciala"
						},
						{
							"_nr": "3",
							"__text": "Principiul de descompunere Dantzing-Wolfe"
						},
						{
							"_nr": "4",
							"__text": "Rezolvarea problemelor liniare cu multe coloane prin proceduri de generare a coloanelor"
						},
						{
							"_nr": "5",
							"__text": "Metode de partitionare si relaxare in programarea liniara"
						},
						{
							"_nr": "6",
							"__text": "Metode compacte"
						},
						{
							"_nr": "7",
							"__text": "Proceduri de partitionare in programarea liniara"
						},
						{
							"_nr": "8",
							"__text": "Dualitate si descompunere in programarea matematica"
						},
						{
							"_nr": "9",
							"__text": "Decompunere prin alocare directa"
						}
					]
				},
				"_id": "309",
				"_isbn": ""
			},
			{
				"titlu": "Java fara mistere",
				"autori": {
					"autor": {
						"_nume": "Jim Keogh"
					}
				},
				"anAparitie": "2006",
				"editura": "Rosetti Educational",
				"numarPagini": "336",
				"pretOriginal": "0",
				"pretCumparare": "109000",
				"traducere": {
					"titluOriginal": "Java Demystified",
					"editura": "McGraw-Hill Companies",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "2004",
					"_editia": "1",
					"_limba": "engleze",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Java_fara_mistere_Ghid_pentru_autodidacti_Keogh.jpg"
					},
					"__text": "Daniel Munteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "In interiorul Java"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date si variabile"
						},
						{
							"_nr": "3",
							"__text": "Expresii si instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Structuri de control"
						},
						{
							"_nr": "5",
							"__text": "Matrice"
						},
						{
							"_nr": "6",
							"__text": "Metrode si polimorfism"
						},
						{
							"_nr": "7",
							"__text": "Clase"
						},
						{
							"_nr": "8",
							"__text": "Mostenirea"
						},
						{
							"_nr": "9",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "10",
							"__text": "Executia multifilara (multithreading)"
						},
						{
							"_nr": "11",
							"__text": "Fisiere si fluxuri"
						},
						{
							"_nr": "12",
							"__text": "Interfata grafica cu utilizatorul"
						},
						{
							"_nr": "13",
							"__text": "JDBC si obiecte Java pentru date"
						},
						{
							"_nr": "14",
							"__text": "Miniaplicatii Java"
						}
					]
				},
				"_id": "310",
				"_isbn": "973-7881-05-2"
			},
			{
				"titlu": "Tehnologii XML - XML in Java - initiere in XML",
				"autori": {
					"autor": [
						{
							"_nume": "Anghel Octavia Andreea"
						},
						{
							"_nume": "Anghel Leonard"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "454",
				"pretOriginal": "0",
				"pretCumparare": "376000",
				"colectia": {
					"_numarul": "219",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_XML_XML_in_Java_initiere_in_XML_Leonard.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Componente XML si definitii DTD"
						},
						{
							"_nr": "2",
							"__text": "XML Schema"
						},
						{
							"_nr": "3",
							"__text": "SAX"
						},
						{
							"_nr": "4",
							"__text": "STAX"
						},
						{
							"_nr": "5",
							"__text": "DOM"
						}
					]
				},
				"_id": "311",
				"_isbn": "978-973-650-609-5"
			},
			{
				"titlu": "Tehnologii XML - XML in Java - XML pentru avansati",
				"autori": {
					"autor": [
						{
							"_nume": "Anghel Octavia Andreea"
						},
						{
							"_nume": "Anghel Leonard"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "534",
				"pretOriginal": "0",
				"pretCumparare": "432000",
				"colectia": {
					"_numarul": "220",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_XML_XML_in_Java_XML_pentru_avansati_Leonard.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "XSL"
						},
						{
							"_nr": "2",
							"__text": "XPath"
						},
						{
							"_nr": "3",
							"__text": "XPath in Java"
						},
						{
							"_nr": "4",
							"__text": "Pachetul javax.xml.datatype"
						},
						{
							"_nr": "5",
							"__text": "XSLT"
						},
						{
							"_nr": "6",
							"__text": "TrAX"
						},
						{
							"_nr": "7",
							"__text": "XSL-FO"
						},
						{
							"_nr": "8",
							"__text": "FOP"
						},
						{
							"_nr": "9",
							"__text": "XML Schema in Java"
						},
						{
							"_nr": "10",
							"__text": "XQuery"
						},
						{
							"_nr": "11",
							"__text": "DXQ si XQJ"
						}
					]
				},
				"_id": "312",
				"_isbn": "978-973-650-610-1"
			},
			{
				"titlu": "Comunicatia prin intermediul retelelor de calculatoare",
				"autori": {
					"autor": {
						"_nume": "Mihai Ceaparu"
					}
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "264",
				"pretOriginal": "7000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Comutatia_prin_intermediul_retelelor_de_calculatoare_Ceaparu.jpg"
					},
					"__text": "Silvia Candea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Necesitatea si scopurile retelelor de calculatoare"
						},
						{
							"_nr": "1.1",
							"__text": "Fluxurile de informatii in mediul industrial"
						},
						{
							"_nr": "1.2",
							"__text": "Motivatia retelelor de calculatoare"
						},
						{
							"_nr": "1.3",
							"__text": "Conceptul de retea de calculatoare"
						},
						{
							"_nr": "2",
							"__text": "Structura si arhitectura retelelor de calculatoare"
						},
						{
							"_nr": "2.1",
							"__text": "Structura retelelor de calculatoare"
						},
						{
							"_nr": "2.2",
							"__text": "Arhitectura retelelor de calculatoare"
						},
						{
							"_nr": "3",
							"__text": "Exemple de arhitecturi si structruri de retele"
						},
						{
							"_nr": "3.1",
							"__text": "Retele publice de date"
						},
						{
							"_nr": "3.2",
							"__text": "Retele mari"
						},
						{
							"_nr": "3.3",
							"__text": "Retele locale"
						},
						{
							"_nr": "3.4",
							"__text": "Retele cu legaturi prin fibre optice"
						},
						{
							"_nr": "3.5",
							"__text": "Retele cu legaturi prin propagare electromagnetica in spatiu"
						},
						{
							"_nr": "3.6",
							"__text": "Retele cu comutatoare de circuite de tip PBX"
						},
						{
							"_nr": "3.7",
							"__text": "Retele numerice cu servicii integrate"
						}
					]
				},
				"_id": "313",
				"_isbn": "973-31=0761-1"
			},
			{
				"titlu": "Bazele teoriei calculului - limbaje formale si automate",
				"autori": {
					"autor": [
						{
							"_nume": "Ciresica Jalobeanu"
						},
						{
							"_nume": "Daniela Marinescu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "176",
				"pretOriginal": "0",
				"pretCumparare": "192000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_teoriei_calculului_Limbaje_formale_si_automate_Jalobeanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbaje formale"
						},
						{
							"_nr": "2",
							"__text": "Limbaje regulate si automate finite"
						},
						{
							"_nr": "3",
							"__text": "Expresii regulate"
						},
						{
							"_nr": "4",
							"__text": "Proprietatile limbajelor regulate"
						},
						{
							"_nr": "5",
							"__text": "Gramatici independente de context si Automate Push-Down"
						},
						{
							"_nr": "6",
							"__text": "Proprietatile limbajelor indenpedente de context"
						},
						{
							"_nr": "7",
							"__text": "Masini Turing, calculabilitate"
						},
						{
							"_nr": "8",
							"__text": "Masinile Turing si inteligenta artificiala"
						},
						{
							"_nr": "9",
							"__text": "Limbaje Lindermayer"
						},
						{
							"_nr": "10",
							"__text": "Notiuni introductive de teoria multimilor"
						},
						{
							"_nr": "11",
							"__text": "Relatii"
						},
						{
							"_nr": "12",
							"__text": "In loc de incheiere"
						}
					]
				},
				"_id": "314",
				"_isbn": "973-650-187-6"
			},
			{
				"titlu": "Informatica (pentru anul IV liceu, clase speciale de informatica)",
				"autori": {
					"autor": [
						{
							"_nume": "Petre Preoteasa"
						},
						{
							"_nume": "Maria Lovin"
						},
						{
							"_nume": "Constantin P. Popovici"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Didactica si pedagogica",
				"tiraj": "6100+90 ex. brosate",
				"numarPagini": "168",
				"pretOriginal": "5.80",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Valentin Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": "P. Gaspar"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Lovin.jpg"
					},
					"__text": "N. Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre sisteme"
						},
						{
							"_nr": "2",
							"__text": "Domenii de utilizare ale calculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Informatica in economie"
						},
						{
							"_nr": "4",
							"__text": "Introducerea unui sisteme automat de prelucrare a datelor intr-o intreprindere"
						},
						{
							"_nr": "5",
							"__text": "Prelucrarea statistica a informatiilor obtinute prin anchete"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii in stiinta"
						}
					]
				},
				"_id": "315",
				"_isbn": ""
			},
			{
				"titlu": "Descrierea proceselor de calcul pentru calculatoare electronice mici",
				"autori": {
					"autor": {
						"_nume": "Dragos Vaida"
					}
				},
				"anAparitie": "1967",
				"editura": "Tehnica",
				"tiraj": "2000+140 ex.",
				"numarPagini": "128",
				"pretOriginal": "6",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Lina Ticos"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Descrierea_programelor_de_calcul_pentru_calculatoare_electronice_mici_Vaida.jpg"
					},
					"__text": "Constanta Vaida"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programarea in cod si automatizarea programarii"
						},
						{
							"_nr": "2",
							"__text": "Structura limbajului algoritmic ALGOL"
						},
						{
							"_nr": "3",
							"__text": "Expresii aritmetice"
						},
						{
							"_nr": "4",
							"__text": "Operatorii fundamentali"
						},
						{
							"_nr": "5",
							"__text": "Operatorul de transfer conditionat"
						},
						{
							"_nr": "6",
							"__text": "Operatorul compus conditionat"
						},
						{
							"_nr": "7",
							"__text": "Declararea, blocul si programul"
						},
						{
							"_nr": "8",
							"__text": "Sintaxa limbajului"
						}
					]
				},
				"_id": "316",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in Fortran",
				"autori": {
					"autor": {
						"_nume": "Petre Dimo"
					}
				},
				"anAparitie": "1971",
				"editura": "Didactica si pedagogica",
				"numarPagini": "300",
				"pretOriginal": "7.40",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "I. Dumitrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Sena Stanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Fortran_Dimo.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Elementele limbajului FORTRAN"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni generale de intrare/iesire"
						},
						{
							"_nr": "3",
							"__text": "Instructiunile de atribuire"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni de salt"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni de ciclare"
						},
						{
							"_nr": "6",
							"__text": "Subprograme"
						},
						{
							"_nr": "7",
							"__text": "Alte facilitati de I/E. Utilizarea memoriilor auxiliare"
						}
					]
				},
				"_id": "317",
				"_isbn": ""
			},
			{
				"titlu": "Exploatarea sistemelor de calcul electonic [Manual XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Damachi"
						},
						{
							"_nume": "Mircea Hrim"
						},
						{
							"_nume": "Liana Vlass"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Didactica si pedagogica",
				"tiraj": "2059+80",
				"numarPagini": "204",
				"pretOriginal": "6.40",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Monica Serbu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constnata Velcovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Exploatarea_sistemelor_de_calcul_electronic_Vlass.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Scurt istoric al evolutiei sistemelor de prelucrare automata a datelor"
						},
						{
							"_nr": "II",
							"__text": "Unitatea de memorie"
						},
						{
							"_nr": "III",
							"__text": "Unitatea centrala"
						},
						{
							"_nr": "IV",
							"__text": "Sistemul de intrare/iesire"
						},
						{
							"_nr": "V",
							"__text": "Dispozitive periferice. Functionarea si exploatarea echipamentelor compatibile cu calculatorul FELIX C-256"
						},
						{
							"_nr": "VI",
							"__text": "Sistemul de operare"
						},
						{
							"_nr": "VII",
							"__text": "Dialogul operatorului cu sistemul de operare"
						},
						{
							"_nr": "VIII",
							"__text": "Metode de organizarea a lucrarilor pentru executie la calculatorul FELIX C-32"
						},
						{
							"_nr": "IX",
							"__text": "Exploatarea sistemului FELIX C-32"
						},
						{
							"_nr": "X",
							"__text": "Instalatii si servicii auxiliare intr-un centru de calcul"
						},
						{
							"_nr": "XI",
							"__text": "Tendinte de dezvoltare a sistemelor de prelucrare a datelor"
						}
					]
				},
				"_id": "318",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme informatice [Manual XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Bita"
						},
						{
							"_nume": "Cristian Antonecu"
						},
						{
							"_nume": "Marcela Iosep"
						},
						{
							"_nume": "Ileana Trandafir"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Didactica si pedagogica",
				"tiraj": "600+50 brosate",
				"numarPagini": "248",
				"pretOriginal": "7.30",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Lucia Barna"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ghimis"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_Bita.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Definirea si clasificarea sitemelor informatice"
						},
						{
							"_nr": "II",
							"__text": "Elaboarea conceptiei sistemelor informatice"
						},
						{
							"_nr": "III",
							"__text": "Proiectarea tehnica a sistemului informatic si elaboarea programelor"
						},
						{
							"_nr": "IV",
							"__text": "Introducerea si experimetarea sistemelor informatice"
						},
						{
							"_nr": "V",
							"__text": "Exploatarea curenta, intretinerea si dezvolatarea sistemului informatic"
						},
						{
							"_nr": "VI",
							"__text": "Organizarea activitatii de informatica"
						}
					]
				},
				"_id": "319",
				"_isbn": ""
			},
			{
				"titlu": "Limbajele de programare Pascal si Pascal Concurent",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Ciocarlie"
						},
						{
							"_nume": "Petru Eles"
						},
						{
							"_nume": "Ioan Balla"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Facla",
				"numarPagini": "200",
				"pretOriginal": "22.50",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan I. Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_programare_Pascal_si_Pascal_Concurent_Ciocarlie.jpg"
					},
					"__text": "Sergiu Nicola"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul PASCAL"
						},
						{
							"_nr": "1",
							"__text": "Notiuni de baza ale limbajului Pascal"
						},
						{
							"_nr": "2",
							"__text": "Instructiunile limbajului Pascal"
						},
						{
							"_nr": "3",
							"__text": "Tipuri definite in program"
						},
						{
							"_nr": "4",
							"__text": "Proceduri si functii"
						},
						{
							"_nr": "5",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "6",
							"__text": "Proceduri standard pentru intrari/iesiri"
						},
						{
							"_nr": "7",
							"__text": "Descrierea si prelucrarea fisierelor"
						},
						{
							"_nr": "II",
							"__text": "Limbajul PASCAL CONCURENT"
						},
						{
							"_nr": "8",
							"__text": "Notiuni introductive privind programarea concurenta"
						},
						{
							"_nr": "9",
							"__text": "PAscal Concurent, limbaj de tip Pascal"
						},
						{
							"_nr": "10",
							"__text": "Procese, monitoare, clase - componente ale unui program Pascal Concurent"
						},
						{
							"_nr": "11",
							"__text": "Factorul timp intr-un program Pascal Concurent"
						},
						{
							"_nr": "12",
							"__text": "Operatii de intrare/iesire in Pascal Concurent"
						},
						{
							"_nr": "III",
							"__text": "Comunicarea intre procese"
						},
						{
							"_nr": "13",
							"__text": "Apelul suprogramelor scrise in alte limbaje"
						},
						{
							"_nr": "14",
							"__text": "Comunicarea intre programe PAscal. Segmentarea"
						},
						{
							"_nr": "15",
							"__text": "Apelul si lansarea programelor secventiale dintr-un program Pascal Concurent"
						}
					]
				},
				"_id": "320",
				"_isbn": ""
			},
			{
				"titlu": "Instruire programata in calculatoare numerice",
				"autori": {
					"autor": {
						"_nume": "Victor Popescu"
					}
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "11500+90 exemplare brosate",
				"numarPagini": "160",
				"pretOriginal": "6",
				"pretCumparare": "0",
				"colectia": {
					"_nume": "Seria INITIERE",
					"_numarul": ""
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Intruiere_programata_in_calculatoare_numerice_Hardware_Popescu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Codificarea datelor"
						},
						{
							"_nr": "3",
							"__text": "Suporturi de informatii"
						},
						{
							"_nr": "4",
							"__text": "Echipamente pentru pregatirea datelor"
						},
						{
							"_nr": "5",
							"__text": "Calculatoare numerice (SPAD). Destinatie, organizare"
						},
						{
							"_nr": "6",
							"__text": "Calculatoare numerice (SPAD). Memoria principala"
						},
						{
							"_nr": "7",
							"__text": "Calculatoare numerice (SPAD). Unitatea centrala de prelucrare si comanda"
						},
						{
							"_nr": "8",
							"__text": "Calculatoare numerice (SPAD). Unitatile de intrare/iesire"
						},
						{
							"_nr": "9",
							"__text": "Configuratii ale sistemului de calcul FELIX C-256"
						},
						{
							"_nr": "10",
							"__text": "Teleprelucrarea datelor"
						}
					]
				},
				"_id": "321",
				"_isbn": ""
			},
			{
				"titlu": "Totul despre...calculatorul personal aMIC (Volumul 1)",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Gheorghe Rizescu"
						},
						{
							"_nume": "Francisc Iacob"
						},
						{
							"_nume": "Tiberiu Ilin"
						},
						{
							"_nume": "Eduard Decsov"
						},
						{
							"_nume": "Constantin Novacescu"
						},
						{
							"_nume": "Florian Bar"
						},
						{
							"_nume": "Radu Berindeanu"
						},
						{
							"_nume": "Dumitru Panescu"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "280",
				"pretOriginal": "21",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elly Gorun"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_calculatorul_personal_aMIC_1_Rizescu.jpg"
					},
					"__text": "Silvia Mirtu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Clase de microcalculatoare personale si personal-profesionale"
						},
						{
							"_nr": "2",
							"__text": "Preznetarea generala a microcalculatorului aMIC"
						},
						{
							"_nr": "3",
							"__text": "Structura si functionarea microcalculatorului aMIC"
						},
						{
							"_nr": "4",
							"__text": "Microprocesorul Z80"
						},
						{
							"_nr": "5",
							"__text": "Monitoarele V0.1, V0.2, Z80-V0.0"
						},
						{
							"_nr": "6",
							"__text": "Monitorul DEST"
						},
						{
							"_nr": "7",
							"__text": "Sistemul de operare MATE (Monitor-Asamblor-Text-Editor)"
						}
					]
				},
				"_id": "322",
				"_isbn": ""
			},
			{
				"titlu": "Totul despre...calculatorul personal aMIC (Volumul 2)",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Gheorghe Rizescu"
						},
						{
							"_nume": "Francisc Iacob"
						},
						{
							"_nume": "Tiberiu Ilin"
						},
						{
							"_nume": "Eduard Decsov"
						},
						{
							"_nume": "Constantin Novacescu"
						},
						{
							"_nume": "Agota Matekovits"
						},
						{
							"_nume": "Florian Bar"
						},
						{
							"_nume": "Radu Berindeanu"
						},
						{
							"_nume": "Iocob Petrescu"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "248",
				"pretOriginal": "21",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elly Gorun"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_calculatorul_personal_aMIC_2_Rizescu.jpg"
					},
					"__text": "Silvia Mirtu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "8",
							"__text": "Cuplari de echipamente periferice, interconectari si aplicatii ale microcalculatorului aMIC"
						},
						{
							"_nr": "9",
							"__text": "Limbajul BASIC, pentru microcalculatorul personal aMIC. Manual practic"
						},
						{
							"_nr": "10",
							"__text": "Microcalculatorul aMIC in matematici elementare si statistica"
						},
						{
							"_nr": "11",
							"__text": "Microcalculatorul aMIC in economie si tehnica"
						},
						{
							"_nr": "12",
							"__text": "Microcalculatorul aMIC in invatamant"
						},
						{
							"_nr": "13",
							"__text": "Microcalculatorul aMIC in grafica, jocuri, aplicatii diverse"
						},
						{
							"_nr": "14",
							"__text": "Testarea resurselor hardware si a interpretorului BASIC"
						}
					]
				},
				"_id": "323",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de prelucrare automata a datelor [Manual X]",
				"autori": {
					"autor": [
						{
							"_nume": "Cristina Mircescu"
						},
						{
							"_nume": "Alexandru Teodorescu"
						},
						{
							"_nume": "Mihai Jitaru"
						},
						{
							"_nume": "Eugeniu Diatcu"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Didactica si pedagogica",
				"tiraj": "1550+S.P 65 exemplare brosate",
				"numarPagini": "264",
				"pretOriginal": "15.80",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Lucia Barna"
				},
				"tehnoredactori": {
					"tehnoredactor": "A. Daniel"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_prelucrare_automata_a_datelor_Mircescu.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme de prelucrare autoamta a datelor"
						},
						{
							"_nr": "II",
							"__text": "Sisteme de calcul"
						},
						{
							"_nr": "III",
							"__text": "Tipuri moderne de SPAD"
						},
						{
							"_nr": "IV",
							"__text": "Elemente de baza ale limbajului ASSIRIS"
						},
						{
							"_nr": "V",
							"__text": "Programarea in limbajul ASSIRIS"
						},
						{
							"_nr": "VI",
							"__text": "Conversia numerelor intregi din zecimal in cod complementar si invers"
						}
					]
				},
				"_id": "324",
				"_isbn": ""
			},
			{
				"titlu": "Programarea si exploatarea sistemelor de calcul [Manual XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Ioana Barbat"
						},
						{
							"_nume": "Mihai Barbu Dumitrescu"
						},
						{
							"_nume": "Stelian Niculescu"
						},
						{
							"_nume": "Cazimir Macarie"
						},
						{
							"_nume": "Anastase Pitis"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Didactica si pedagogica",
				"tiraj": "",
				"numarPagini": "272",
				"pretOriginal": "13.50",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Lucia Barna"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Opriseanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_si_exploatarea_sistemelor_de_calcul_Niculescu.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Programarea in limbajul FORTRAN"
						},
						{
							"_nr": "Partea II",
							"__text": "Programarea in limbajul COBOL"
						}
					]
				},
				"_id": "325",
				"_isbn": ""
			},
			{
				"titlu": "FORTRAN - initiere in programarea structurata",
				"autori": {
					"autor": {
						"_nume": "Stelian Niculescu"
					}
				},
				"anAparitie": "1979",
				"editura": "Tehnica",
				"numarPagini": "280",
				"pretOriginal": "12",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Automatica Informatica Electronica Management - Seria Initiere"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Fortran_initiere_in_programare_structurata_Niculescu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului FORTRAN"
						},
						{
							"_nr": "4",
							"__text": "Instructiunile limbajului FORTRAN"
						},
						{
							"_nr": "5",
							"__text": "Programarea structurata in FORTRAN"
						},
						{
							"_nr": "6",
							"__text": "Proceduri FORTRAN"
						},
						{
							"_nr": "7",
							"__text": "Prelucrarea fisierelor in FORTRAN"
						},
						{
							"_nr": "8",
							"__text": "Segmentarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Cartele de comanda"
						}
					]
				},
				"_id": "326",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in FORTRAN - Stil in programare",
				"autori": {
					"autor": {
						"_nume": "Octavian Petrus"
					}
				},
				"anAparitie": "1980",
				"editura": "Junimea",
				"numarPagini": "208",
				"pretOriginal": "9.75",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Vicentiu Donose"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Bujdei"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_FORTRAN_stil_in_programare_Petrus.jpg"
					},
					"__text": "Anca Stefan Muller"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de structura si functionare a calculatoarelor"
						},
						{
							"_nr": "2",
							"__text": "Elmente de practica programarii. Tehnici eficiente in programare"
						},
						{
							"_nr": "3",
							"__text": "Notiuni de baza ale limbajului Fortran"
						},
						{
							"_nr": "4",
							"__text": "Programe Fortran simple"
						},
						{
							"_nr": "5",
							"__text": "Instructiunea DO"
						},
						{
							"_nr": "6",
							"__text": "Operatii de intrare/iesire (notiuni avansate)"
						},
						{
							"_nr": "7",
							"__text": "Proceduri si subprograme"
						},
						{
							"_nr": "8",
							"__text": "Alte caracteristici ale limbajului Fortran"
						}
					]
				},
				"_id": "327",
				"_isbn": ""
			},
			{
				"titlu": "Depanarea programelor Fortran",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Visan"
						},
						{
							"_nume": "Corneliu Georgescu"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Militara",
				"numarPagini": "262",
				"pretOriginal": "17",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Alexandru Mihalcea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Niculae"
				},
				"coperta": {
					"imagine": {
						"_path": "Depanarea_programelor_FORTRAN_Visan.jpg"
					},
					"__text": "M. Vlad"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "II",
							"__text": "Particularitati HARD si SOFT ale calculatoarelor de tip FELIX C-256 (C-512)"
						},
						{
							"_nr": "III",
							"__text": "Depanarea programelor Fortran"
						}
					]
				},
				"_id": "328",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in limbajul Fortran. Calculatorul FELIX C-256",
				"autori": {
					"autor": [
						{
							"_nume": "C. Cazacu"
						},
						{
							"_nume": "T. Jucan"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Junimea",
				"numarPagini": "216",
				"pretOriginal": "9.25",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Vasile Suvaiala"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Bujdei"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_FORTRAN_Calculatorul_FELIX_C_256_Cazacu.jpg"
					},
					"__text": "Gabriela Buhoiu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul de calcul FELIX C-256"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare al calculatorului FELIX C-256"
						},
						{
							"_nr": "3",
							"__text": "Limbajul FORTRAN. Notiuni introductive"
						},
						{
							"_nr": "4",
							"__text": "Fisiere in FORTRAN"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni de introducere si de extragere a datelor"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni de atribuire si de control"
						},
						{
							"_nr": "7",
							"__text": "Proceduri"
						},
						{
							"_nr": "8",
							"__text": "Segmentarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Crearea si exploatarea bibliotecilor"
						},
						{
							"_nr": "10",
							"__text": "Cartele de comanda de nivel 1"
						},
						{
							"_nr": "11",
							"__text": "Informatii in fisierul de iesire (IDEX*2)"
						},
						{
							"_nr": "12",
							"__text": "Exemple de Job-uri rulate"
						}
					]
				},
				"_id": "329",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de calculatoare Wang 2200",
				"autori": {
					"autor": {
						"_nume": "WANG-MINICIMPUTERS SYSTEMS - S.U.A"
					}
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "AMC"
				},
				"traducere": {
					"titluOriginal": "",
					"traducatori": {
						"traducator": [
							"Oleg Cernian",
							"Constantin Belea",
							"Mihai Socaciu",
							"Rodica Bojin"
						]
					},
					"_an": "1977",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_calculatoare_Wang_2200_Wang.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezntarea genrerala a sistemelor de minicalculatoare WANG 2200 (minisitemul e calucl WANG 2200)"
						},
						{
							"_nr": "2",
							"__text": "Unitatea centrala 2200"
						},
						{
							"_nr": "3",
							"__text": "Echipamente periferice ale ministemului WANG 2200 (prezentare generala)"
						},
						{
							"_nr": "4"
						},
						{
							"_nr": "5",
							"__text": "Echipamente periferice sub familia tastaturiloe, unitatilor de afisare sub catodic si a consolelor"
						},
						{
							"_nr": "6",
							"__text": "Echipamente periferice de intrare"
						},
						{
							"_nr": "7",
							"__text": "Unitati de memorie externa"
						},
						{
							"_nr": "8",
							"__text": "Interfete"
						},
						{
							"_nr": "9",
							"__text": "Versiuni noi de configuratii WANG 2200"
						},
						{
							"_nr": "10",
							"__text": "Biblioteca generala de programe"
						},
						{
							"_nr": "11",
							"__text": "Unitati centrale perfectionate WANG 2200"
						},
						{
							"_nr": "12"
						},
						{
							"_nr": "13",
							"__text": "Echipamente periferice de extragerea informatiei"
						},
						{
							"_nr": "14",
							"__text": "Modele noi de interfete"
						},
						{
							"_nr": "15",
							"__text": "Terminal interactiv model 2236. Interfata de multiplexare 2236 MXC"
						},
						{
							"_nr": "16",
							"__text": "Compatibilitatea IBM prin unitatea de discuri flexibile model 2270 A"
						},
						{
							"_nr": "17",
							"__text": "Familia de echipamente WANG de prelucrarea a textelor"
						},
						{
							"_nr": "18",
							"__text": "Istoricul dezvoltarii companiei WANG Laboratories Inc."
						}
					]
				},
				"_id": "330",
				"_isbn": ""
			},
			{
				"titlu": "Informatica pentru conducere",
				"autori": {
					"autor": [
						{
							"_nume": "Paul Constantinescu"
						},
						{
							"_nume": "Stefan Pana"
						},
						{
							"_nume": "Valerie Marinescu"
						},
						{
							"_nume": "Ion Stancioiu"
						},
						{
							"_nume": "Iulian Nitescu"
						},
						{
							"_nume": "Eduard Radaceanu"
						},
						{
							"_nume": "Ioana Mihailescu"
						},
						{
							"_nume": "Ion Faur"
						},
						{
							"_nume": "Eugen Popescu"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Academia \"Stefan Gheorghiu\"",
				"numarPagini": "462",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_coducere_Constantinescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sistemul informatic in unitati economice"
						},
						{
							"_nr": "II",
							"__text": "Modele ca elemente tipizate ale sistemului informatic. Proiect tip de sistem informatic"
						},
						{
							"_nr": "III",
							"__text": "Metodologia de analiza, proiectare si implementare a sistemelor informatice"
						},
						{
							"_nr": "IV",
							"__text": "Indicatii metodologice generale privind analiza, proiectarea si implementarea sistemelor informatice"
						}
					]
				},
				"_id": "331",
				"_isbn": ""
			},
			{
				"titlu": "Concepte mederne de programare in limbajul Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "Serban Gavrila"
						},
						{
							"_nume": "Marian Gheorghe"
						},
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Liviu Sofonea"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "182",
				"pretOriginal": "23",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Concepte_moderne_de_programare_in_limbajul_Pascal_Vaduva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Vocabularul si sintaxa limbajului"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date, variabile, constante, etichete, expresii"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Proceduri, functii, programe, module"
						},
						{
							"_nr": "5",
							"__text": "Prezentarea formalizata a semanticii"
						},
						{
							"_nr": "6",
							"__text": "Depanatorul simbolic"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea compilatorului Pascal - Oberon"
						}
					]
				},
				"_id": "332",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in utilizarea minicalculatoarelor",
				"autori": {
					"autor": {
						"_nume": "Silviu Craciunas"
					}
				},
				"anAparitie": "1987",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "80",
				"pretOriginal": "10.10",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_utilizarea_minicalculatoarelor_Craciunas.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepute de baza privind minicalculatoarele"
						},
						{
							"_nr": "2",
							"__text": "Preznetarea generala a sistemului de operare RSX-11M"
						},
						{
							"_nr": "3",
							"__text": "Comenzi MCR. Exemple si erori"
						},
						{
							"_nr": "4",
							"__text": "Dezvolarea de programe"
						}
					]
				},
				"_id": "333",
				"_isbn": ""
			},
			{
				"titlu": "Curs de bazele informaticii. Logica matematica. Fascicula a II-a. Calculul propozitiilor",
				"autori": {
					"autor": {
						"_nume": "Serviu Rudeanu"
					}
				},
				"anAparitie": "1977",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "20",
				"pretOriginal": "4.15",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Curs_de_bazele_informaticii_Logica_matematica_Calculul_propozitiilor_Rudeanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aspectul semantic al calcului propozitiilor"
						},
						{
							"_nr": "2",
							"__text": "Aspectul sintactic al calcului propozitiilor"
						},
						{
							"_nr": "3",
							"__text": "Teorema de completitudine a calcului propozitiilor"
						}
					]
				},
				"_id": "334",
				"_isbn": ""
			},
			{
				"titlu": "Informatica economica (Tema: Fiabilitatea produselor program)",
				"autori": {
					"autor": [
						{
							"_nume": "T. Baron"
						},
						{
							"_nume": "Al. Balog"
						},
						{
							"_nume": "I. Ivan"
						},
						{
							"_nume": "C. Baron"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Academia de Studii Economice",
				"numarPagini": "64",
				"pretOriginal": "4.50",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_economica_Fiabilitatea_produselor_program_Ivan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Importanta studiului fiablitatii programelor"
						},
						{
							"_nr": "2",
							"__text": "Probleme in fiabilitatea produselor program"
						},
						{
							"_nr": "3",
							"__text": "Fiabilitatea programelor - calitate extinsa in timp"
						},
						{
							"_nr": "4",
							"__text": "Parametri de fiabilitate. Functii de repartitie"
						},
						{
							"_nr": "5",
							"__text": "Metode si modele matematice pentru determinarea fiabilitatii programelor"
						},
						{
							"_nr": "6",
							"__text": "Cai de crestere a fiabilitatii produselor program"
						}
					]
				},
				"_id": "335",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul IBM 1401. Structura si programare",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Golumbovici"
						},
						{
							"_nume": "Traian Sava"
						},
						{
							"_nume": "Radu Irimescu"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Institutul Politehnic Bucuresti",
				"numarPagini": "114",
				"pretOriginal": "6.50",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Sistemul_IBM_1401_Structura_si_programare_Sava.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Prezentarea generala a sistemului de calcul IBM 1401"
						},
						{
							"_nr": "II",
							"__text": "Unitatea centrala IBM 1401"
						},
						{
							"_nr": "III",
							"__text": "Programarea calculatorului IBM 1401 in limbaj masina"
						},
						{
							"_nr": "IV",
							"__text": "Programare complexa"
						}
					]
				},
				"_id": "336",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul IBM 1401. Unitatea de discuri magnetice si unitatea de banda magnetica",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Golumbovici"
						},
						{
							"_nume": "Traian Sava"
						},
						{
							"_nume": "Radu Irimescu"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Institutul Politehnic Bucuresti",
				"numarPagini": "118",
				"pretOriginal": "5",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Sistemul_IBM_1401_Unitatea_de_discuri_magnetice_si_unitatea_de_banda_magnetica_Sava.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Unitatea de discuri IBM 1311"
						},
						{
							"_nr": "II",
							"__text": "Banda magnetica"
						},
						{
							"_nr": "III",
							"__text": "Exemple de programe"
						}
					]
				},
				"_id": "337",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de operare. Indrumar de laborator",
				"autori": {
					"autor": {
						"_nume": "Florica Moldoveanu"
					}
				},
				"anAparitie": "1980",
				"editura": "Institutul Politehnic Bucuresti",
				"tiraj": "309",
				"numarPagini": "108",
				"pretOriginal": "5.80",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_indrumar_de_laborator_Moldoveanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea sistemelor de calcul FELIX-C256 si FELIX-C512"
						},
						{
							"_nr": "2",
							"__text": "Pupitrul de comanda"
						},
						{
							"_nr": "3",
							"__text": "Comunicarea operatorului cu sistemul de operare"
						},
						{
							"_nr": "4",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "5",
							"__text": "Generarea sistemului"
						},
						{
							"_nr": "6",
							"__text": "Exemple de exploatare a sistemelor FELIX prin SYMBIONT"
						}
					]
				},
				"_id": "338",
				"_isbn": ""
			},
			{
				"titlu": "Exercitii de programarea calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Dobra Georgescu"
						},
						{
							"_nume": "Calina Bardiuc"
						},
						{
							"_nume": "Sabina Olariu"
						},
						{
							"_nume": "Ileana Popescu"
						},
						{
							"_nume": "Nicolae Nedita"
						},
						{
							"_nume": "Paul Radovici"
						},
						{
							"_nume": "Matei Bogdan"
						},
						{
							"_nume": "Serban Gavrila"
						},
						{
							"_nume": "Radu Nicolescu"
						},
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "Victorina Barbulescu"
						},
						{
							"_nume": "Nina Carstea"
						},
						{
							"_nume": "Anton Batatorescu"
						},
						{
							"_nume": "Laurentiu Lupas"
						},
						{
							"_nume": "Ion Rosca"
						},
						{
							"_nume": "Stefan Stefanescu"
						},
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Dumitru David"
						},
						{
							"_nume": "Vasile Stoica"
						},
						{
							"_nume": "Marin Popa"
						},
						{
							"_nume": "Traian Posea"
						},
						{
							"_nume": "Maria Lovin"
						},
						{
							"_nume": "Doina Petroniu"
						},
						{
							"_nume": "Adrian Atanasiu"
						},
						{
							"_nume": "Popoviciu Nicolae"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "226",
				"pretOriginal": "12",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Exercitii_de_programarea_calculatoarelor_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "I",
							"__text": "Probleme rezolvate"
						},
						{
							"_nr": "II",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "339",
				"_isbn": ""
			},
			{
				"titlu": "Indrumar pentru utilizarea eficienta a sistemelor FELIX si IBM",
				"autori": {
					"autor": [
						{
							"_nume": "L. Livovschi"
						},
						{
							"_nume": "H. Georgescu"
						},
						{
							"_nume": "I. Popescu"
						},
						{
							"_nume": "S. Mustata"
						},
						{
							"_nume": "D. Somnea"
						},
						{
							"_nume": "A. Mateescu"
						},
						{
							"_nume": "R. Ghinescu"
						},
						{
							"_nume": "A. Georgescu"
						},
						{
							"_nume": "S. Barza"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "322",
				"pretOriginal": "21.85",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Indrumar_pentru_utilizarea_eficienta_a_sistemelor_Felix_si_IBM_Livovschi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "A",
							"__text": "PARTEA INTAI"
						},
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "III",
							"__text": "Adresarea memoriei"
						},
						{
							"_nr": "IV",
							"__text": "Mesaje de eroare la sistemele FELIX C-256 si IBM 360"
						},
						{
							"_nr": "V",
							"__text": "Metode de identificare si depanare a erorilor"
						},
						{
							"_nr": "VI",
							"__text": "Metode de diagnosticare la sistemul IBM 360"
						},
						{
							"_nr": "VII",
							"__text": "Metode de diagnosticare la sistemul FELIX C-256"
						},
						{
							"_nr": "VIII",
							"__text": "Programe Fortran exemplificative cu erori"
						},
						{
							"_nr": "B",
							"__text": "PARTEA A DOUA"
						},
						{
							"_nr": "I",
							"__text": "Recomandari privind utilizarea calculatorului FELIX C-256"
						},
						{
							"_nr": "II",
							"__text": "Probleme legate cu lucrul cu fisiere"
						},
						{
							"_nr": "III",
							"__text": "Lucrul cu biblioteci utilizator"
						},
						{
							"_nr": "IV",
							"__text": "Editarea legaturilor;segmentare"
						},
						{
							"_nr": "V",
							"__text": "Aspecte legate de folosirea programelor utilizate"
						}
					]
				},
				"_id": "340",
				"_isbn": ""
			},
			{
				"titlu": "Curs de bazele informaticii. Introducere in sistemul de operare SIRIS",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Petre Preoteasa"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "156",
				"pretOriginal": "12.30",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Curs_de_bazele_informaticii_Introducere_in_sistemul_de_operare_Siris_Georgescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organizarea generala a sistemului FELIX"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare SIRIS 2"
						},
						{
							"_nr": "3",
							"__text": "Descrierea  principalelor cartele de comanda"
						},
						{
							"_nr": "4",
							"__text": "Descrierea structurii fisierelor"
						},
						{
							"_nr": "5",
							"__text": "Exploatarea fisierelor in Fortran"
						},
						{
							"_nr": "6",
							"__text": "Cartele de comanda pentru fisiere"
						},
						{
							"_nr": "7",
							"__text": "Programul Bibliotecar"
						},
						{
							"_nr": "8",
							"__text": "Segmentarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Facilitari de rulare a programelor in sistemul SIRIS"
						},
						{
							"_nr": "10",
							"__text": "Depanarea programelor"
						},
						{
							"_nr": "11",
							"__text": "Probleme actuale ale sistemelor de operare"
						}
					]
				},
				"_id": "341",
				"_isbn": ""
			},
			{
				"titlu": "Bazele informaticii. Elaboarea schemelor logice",
				"autori": {
					"autor": {
						"_nume": "L. Livovschi"
					}
				},
				"anAparitie": "1978",
				"editura": "Tipografia Universitatii Bucuresti",
				"numarPagini": "212",
				"pretOriginal": "14.05",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Elaborarea_schemelor_logice_Livovschi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Descrierea evolutiei unui calculator"
						},
						{
							"_nr": "2",
							"__text": "Elaborarea schemelor logice"
						},
						{
							"_nr": "3",
							"__text": "Structura si transferarea schemelor logice"
						},
						{
							"_nr": "4",
							"__text": "Calculabilitate si recursivitate"
						}
					]
				},
				"_id": "342",
				"_isbn": ""
			},
			{
				"titlu": "Retele locale de calculatoare - de la cablare la interconectare (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Vasile Teodor Dadarlat"
					}
				},
				"anAparitie": "2000",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "298",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "94",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_de_calculatoare_de_la_cablare_la_interconectare_Dadarlat.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modele de referinta"
						},
						{
							"_nr": "3",
							"__text": "Mediile de transmisie si cablarea structurata"
						},
						{
							"_nr": "4",
							"__text": "Retele locale clasice"
						},
						{
							"_nr": "5",
							"__text": "Retele locale de mare viteza si alte standarde IEEE 802"
						},
						{
							"_nr": "6",
							"__text": "Interconectarea retelelor locale"
						},
						{
							"_nr": "7",
							"__text": "Accesul la retelele publice"
						},
						{
							"_nr": "8",
							"__text": "Principalele arhitecturi ale retelelor de calculatoare"
						},
						{
							"_nr": "9",
							"__text": "Retelele locale si ATM"
						}
					]
				},
				"_id": "343",
				"_isbn": "973-9443-86-9"
			},
			{
				"titlu": "Baze de date si gestiunea tranzactiilor",
				"autori": {
					"autor": {
						"_nume": "Robert Dollinger"
					}
				},
				"anAparitie": "1998",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "304",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "78",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_si_gestiunea_tranzactiilor_Dollinger.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Baze de date"
						},
						{
							"_nr": "1",
							"__text": "Concepte fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Modele de date"
						},
						{
							"_nr": "3",
							"__text": "LMD relationale"
						},
						{
							"_nr": "4",
							"__text": "Normalizarea relatiilor"
						},
						{
							"_nr": "II",
							"__text": "Gestiunea tranzactiilor"
						},
						{
							"_nr": "1",
							"__text": "Tranzactii"
						},
						{
							"_nr": "2",
							"__text": "Controlul concurentei"
						},
						{
							"_nr": "3",
							"__text": "Rezistenta la defecte"
						}
					]
				},
				"_id": "344",
				"_isbn": "973-9215-67-X"
			},
			{
				"titlu": "Programarea web utilizand Java",
				"autori": {
					"autor": {
						"_nume": "Andrei Baranga"
					}
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "178",
				"pretOriginal": "230000",
				"pretCumparare": "230000",
				"colectia": {
					"_numarul": "218",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_web_utilizand_Java_Baranga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Preliminarii"
						},
						{
							"_nr": "2",
							"__text": "Servletii"
						},
						{
							"_nr": "3",
							"__text": "Java Server Pages (JSP)"
						},
						{
							"_nr": "4",
							"__text": "Alte tehnici diverse folosite in programarea Web"
						},
						{
							"_nr": "5",
							"__text": "Arhitectura Three-Tier"
						}
					]
				},
				"_id": "345",
				"_isbn": "978-973-650-207-1"
			},
			{
				"titlu": "Programare concurenta si paralel-distribuita in Java",
				"autori": {
					"autor": {
						"_nume": "Ernest Scheiber"
					}
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "198",
				"pretOriginal": "200.000",
				"pretCumparare": "200000",
				"colectia": {
					"_numarul": "224",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_concurenta_si_paralel_distribuita_in_Java_Scheiber.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in programarea concurenta"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi paraleli"
						},
						{
							"_nr": "3",
							"__text": "Aspecte concurentiale in Java"
						},
						{
							"_nr": "4",
							"__text": "Java OpenMP"
						},
						{
							"_nr": "5",
							"__text": "Java Communicating Sequential Processes"
						},
						{
							"_nr": "6",
							"__text": "Modelul PVM-MPI"
						},
						{
							"_nr": "7",
							"__text": "TSpaces - Modelul Linda"
						}
					]
				},
				"_id": "346",
				"_isbn": "978-973-650-214-9"
			},
			{
				"titlu": "Tehnologii fundamentale Java pentru aplicatii Web",
				"autori": {
					"autor": [
						{
							"_nume": "Florian Mircea Boian"
						},
						{
							"_nume": "Rares Florin Boian"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Albastra",
				"numarPagini": "470",
				"pretOriginal": "540000",
				"pretCumparare": "540000",
				"colectia": {
					"_numarul": "183",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_fundamentale_Java_pentru_aplicatii_Web_Boian.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Compediu Java"
						},
						{
							"_nr": "2",
							"__text": "Intrari/Iesiri(locale).Pachetul JAVA.IO"
						},
						{
							"_nr": "3",
							"__text": "Servicii Java de acces la resurse"
						},
						{
							"_nr": "3.1",
							"__text": "JNDI (Java Naming and Directory Interface)"
						},
						{
							"_nr": "3.2",
							"__text": "JDBC (Java Data Base Connectivity)"
						},
						{
							"_nr": "4",
							"__text": "Comunicatii Java folosind Socket"
						},
						{
							"_nr": "5",
							"__text": "Apelul la distanta al obiectelor Java (RMI)"
						},
						{
							"_nr": "6",
							"__text": "CORBA - dezvoltarea aplicatiilor distribuite eterogene"
						},
						{
							"_nr": "7",
							"__text": "Tehnologia Java Servlet"
						},
						{
							"_nr": "8",
							"__text": "Java Server Pages (JSP)"
						}
					]
				},
				"_id": "347",
				"_isbn": "973-650-131-0"
			},
			{
				"titlu": "Ghid de utilizare Turbo Pascal 7.0",
				"autori": {
					"autor": [
						{
							"_nume": "Sandor Kovacs"
						},
						{
							"_nume": "Antonia Kovacs"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"numarPagini": "480",
				"pretOriginal": "12480",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "40",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_utilizare_Turbo_Pascal_7_0_Kovaks.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Mediul de programare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Turbo Pascal 7.0"
						},
						{
							"_nr": "4",
							"__text": "Unit-ul System"
						},
						{
							"_nr": "5",
							"__text": "Unit-ul Strings"
						},
						{
							"_nr": "6",
							"__text": "Unit-ul Dos"
						},
						{
							"_nr": "7",
							"__text": "Unit-ul Graph"
						},
						{
							"_nr": "8",
							"__text": "Unit-ul Crt"
						},
						{
							"_nr": "9",
							"__text": "Unit-ul Overlay"
						}
					]
				},
				"_id": "348",
				"_isbn": "973-97000-4-7"
			},
			{
				"titlu": "Criptografia si securitatea retelelor de calculatoare cu aplicatii in C si Pascal",
				"autori": {
					"autor": {
						"_nume": "Victor-Valeriu Patriciu"
					}
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "320",
				"pretOriginal": "2500",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Criptografia_si_securitatea_retelelor_de_calculatoare_cu_aplicatii_in_C_si_Pascal_Patriciu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Securitatea retelelor de calculatoare"
						},
						{
							"_nr": "2",
							"__text": "Rolul criptografiei in securitatea retelelor"
						},
						{
							"_nr": "3",
							"__text": "Criptografia computationala conventionala (simetrica)"
						},
						{
							"_nr": "4",
							"__text": "Criptografia computationala cu chei publice (asimetrica)"
						},
						{
							"_nr": "5",
							"__text": "Arhitectura securitatii in sistemele distribuite"
						},
						{
							"_nr": "6",
							"__text": "Protocoale de identificare si autentificare"
						},
						{
							"_nr": "7",
							"__text": "Protocoale pentru protectia transferului de date"
						},
						{
							"_nr": "8",
							"__text": "Protocoale pentru gestionarea cheilor de cifrare"
						},
						{
							"_nr": "9",
							"__text": "Integrarea serviciilor de securitate in arhitectura retelelor"
						}
					]
				},
				"_id": "349",
				"_isbn": ""
			},
			{
				"titlu": "Compresia de date",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Ivan"
						},
						{
							"_nume": "Daniel Vernis"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Cison",
				"tiraj": "1000",
				"numarPagini": "496",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"tehnoredactori": {
					"tehnoredactor": "Daniel Vernis"
				},
				"coperta": {
					"imagine": {
						"_path": "Compresia_de_date_Ivan.jpg"
					},
					"__text": "Roxana Raducu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Compresia"
						},
						{
							"_nr": "3",
							"__text": "Compresia fisierelor de date numerice"
						},
						{
							"_nr": "4",
							"__text": "Compresia fisierelor text"
						},
						{
							"_nr": "5",
							"__text": "Compresia in formatele grafice"
						},
						{
							"_nr": "6",
							"__text": "Metrici ale fisierelor pentru compresie"
						},
						{
							"_nr": "7",
							"__text": "Clasa de algoritmi Huffman"
						},
						{
							"_nr": "8",
							"__text": "Compresia aritmetica"
						},
						{
							"_nr": "9",
							"__text": "Clasa de algoritmi Lempel-Ziv"
						},
						{
							"_nr": "10",
							"__text": "Standardul JPEG"
						},
						{
							"_nr": "11",
							"__text": "Compresia cu fractali"
						},
						{
							"_nr": "12",
							"__text": "Compresia prin retele neuronale"
						},
						{
							"_nr": "13",
							"__text": "Compresia fisierelor neomogene"
						},
						{
							"_nr": "14",
							"__text": "Programe de compresie rezidente"
						},
						{
							"_nr": "15",
							"__text": "Analiza comparata a algoritmilor"
						},
						{
							"_nr": "16",
							"__text": "Programe utilitare de compresie"
						}
					]
				},
				"_id": "350",
				"_isbn": "973-96370-9-4"
			},
			{
				"titlu": "C++. Tehnologia orientata pe obiecte. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Mircea Popovici"
						},
						{
							"_nume": "Ioan Mircea Popovici"
						},
						{
							"_nume": "Iustin Tanase"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "472",
				"pretOriginal": "22000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Cpp_Tehnologia_orientata_pe_obiecte_Aplicatii_Popovici.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Bazele in C/C++"
						},
						{
							"_nr": "1",
							"__text": "Introducere in C++"
						},
						{
							"_nr": "2",
							"__text": "Fundamentele limbajului C++.Partea I"
						},
						{
							"_nr": "3",
							"__text": "Fundamentele limbajului C++.Partea II"
						},
						{
							"_nr": "4",
							"__text": "Functii in C/C++"
						},
						{
							"_nr": "5",
							"__text": "Structuri si uniuni"
						},
						{
							"_nr": "II",
							"__text": "Programarea orientata pe obiecte"
						},
						{
							"_nr": "6",
							"__text": "Introducere in lumea OOP"
						},
						{
							"_nr": "7",
							"__text": "Crearea si initializarea obiectelor"
						},
						{
							"_nr": "8",
							"__text": "Despre elementele claselor"
						},
						{
							"_nr": "9",
							"__text": "Mostenirea si derivarea claselor"
						},
						{
							"_nr": "10",
							"__text": "Operatorii ca functii"
						},
						{
							"_nr": "11",
							"__text": "Solutia template"
						},
						{
							"_nr": "12",
							"__text": "Doua aplicatii in OO"
						},
						{
							"_nr": "III",
							"__text": "Sinteze si raspunsuri"
						},
						{
							"_nr": "13",
							"__text": "Sinteza prin exercitii"
						},
						{
							"_nr": "14",
							"__text": "Solutii si raspunsuri"
						}
					]
				},
				"_id": "351",
				"_isbn": "973-601-481-9"
			},
			{
				"titlu": "Retele de calculatoare:o abordare sistematica",
				"autori": {
					"autor": [
						{
							"_nume": "Larry L. Peterson"
						},
						{
							"_nume": "Bruce S. Davie"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "All Educational",
				"numarPagini": "464",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Computer Networks: A Systems Aproach",
					"editura": "Morgan Kaufmann Publishers, Inc.",
					"traducatori": {
						"traducator": "Mihai Manastireanu"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Viorica Georgeta Murariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_de_calculatoare_o_abordare_sistemica_Peterson.jpg"
					},
					"__text": "Daniel Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Implementarea protocoalelor"
						},
						{
							"_nr": "3",
							"__text": "Retele cu conexiuni directe"
						},
						{
							"_nr": "4",
							"__text": "Comutatia de pachete"
						},
						{
							"_nr": "5",
							"__text": "Interconectarea retelelor de calculatoare"
						},
						{
							"_nr": "6",
							"__text": "Protocoale end-la-end"
						},
						{
							"_nr": "7",
							"__text": "Transmisia de date end-la-end"
						},
						{
							"_nr": "8",
							"__text": "Controlul congestiei"
						},
						{
							"_nr": "9",
							"__text": "Retele de mare viteza"
						}
					]
				},
				"_id": "352",
				"_isbn": "973-684-389-0"
			},
			{
				"titlu": "Cum imi aleg un calculator (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Robert Badea"
					}
				},
				"anAparitie": "2004",
				"editura": "Cartea de buzunar",
				"numarPagini": "192",
				"pretOriginal": "99000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "213",
					"_nume": "Practic IT"
				},
				"redactori": {
					"redactor": "Alina Mezesan"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alexandru Melnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Cum_imi_aleg_un_calculator_II_Badea.jpg"
					},
					"__text": "Tinel Folea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Faceti cunostinta:el va fi PC-ul tau"
						},
						{
							"_nr": "2",
							"__text": "Lasam principiile, punem mana pe lupa"
						},
						{
							"_nr": "3",
							"__text": "Capitol pentru pedanti - partea practica a lucrurilor"
						},
						{
							"_nr": "4",
							"__text": "Cum sa alegem"
						},
						{
							"_nr": "5",
							"__text": "Despre software"
						},
						{
							"_nr": "6",
							"__text": "Atentie la neatentie"
						}
					]
				},
				"_id": "353",
				"_isbn": "973-8351-36-7"
			},
			{
				"titlu": "Informatica pentru liceu si bacalaureat [XI]",
				"autori": {
					"autor": [
						{
							"_nume": "George Daniel Mateescu"
						},
						{
							"_nume": "Pavel Florin Moraru"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Donaris",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "8",
					"_nume": "Donaris-Info"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_liceu_si_bacalaureat_Varianta_Cpp_XI_Mateescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Grafuri neorientate : terminologie, notiuni de baza, forme de reprezentare"
						},
						{
							"_nr": "II",
							"__text": "Structuri de date"
						},
						{
							"_nr": "III",
							"__text": "Structuri de date arborescente"
						},
						{
							"_nr": "IV",
							"__text": "Metode si tehnici de programare"
						}
					]
				},
				"_id": "354",
				"_isbn": "973-86685-7-3"
			},
			{
				"titlu": "Limbaje formale si translatoare",
				"autori": {
					"autor": {
						"_nume": "Ecaterina Vladu"
					}
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Oradea",
				"numarPagini": "170",
				"pretOriginal": "150000",
				"pretCumparare": "150000",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_translatoare_Vladu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "3",
							"__text": "Limbaje formale"
						},
						{
							"_nr": "4",
							"__text": "Construirea automata a analizatoarelor lexicale"
						},
						{
							"_nr": "5",
							"__text": "Gramatici"
						},
						{
							"_nr": "6",
							"__text": "Analiza sintactica"
						},
						{
							"_nr": "7",
							"__text": "Tabela de simboluri"
						},
						{
							"_nr": "8",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "355",
				"_isbn": "973-613-453-9"
			},
			{
				"titlu": "Inteligenta artificiala",
				"autori": {
					"autor": {
						"_nume": "Ecaterina Vladu"
					}
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Oradea",
				"numarPagini": "242",
				"pretOriginal": "250000",
				"pretCumparare": "250000",
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_Vladu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea cunoasterii"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi de cautare"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi de evolutie"
						},
						{
							"_nr": "5",
							"__text": "Invatare automata"
						},
						{
							"_nr": "6",
							"__text": "Arbori de decizie"
						},
						{
							"_nr": "7",
							"__text": "Retele neurale artificiale"
						},
						{
							"_nr": "8",
							"__text": "Probleme de orarii si planificare in inteligenta artificiala"
						},
						{
							"_nr": "9",
							"__text": "Programarea genetica"
						},
						{
							"_nr": "10",
							"__text": "Elemente de logica Fuzzy"
						}
					]
				},
				"_id": "356",
				"_isbn": ""
			},
			{
				"titlu": "Algoritmi in teoria grafurilor",
				"autori": {
					"autor": {
						"_nume": "Marin Popa"
					}
				},
				"anAparitie": "2006",
				"editura": "Credis",
				"numarPagini": "170",
				"pretOriginal": "100000",
				"pretCumparare": "100000",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_in_teoria_grafurilor_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Grafuri neorientate (grafuri)"
						},
						{
							"_nr": "II",
							"__text": "Grafuri orientate (digrafuri)"
						},
						{
							"_nr": "III",
							"__text": "Exercitii si probleme"
						}
					]
				},
				"_id": "357",
				"_isbn": "978-973-734-113-6"
			},
			{
				"titlu": "Programarea retelelor de calculatoare",
				"autori": {
					"autor": {
						"_nume": "Ioan Jurca"
					}
				},
				"anAparitie": "2001",
				"editura": "Editura de Vest",
				"numarPagini": "280",
				"pretOriginal": "174400",
				"pretCumparare": "174400",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ion Ilin"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_retelelor_de_calculatoare_Jurca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Retele de calculatoare. Protocoale"
						},
						{
							"_nr": "2",
							"__text": "Programarea cu sockets in Unix"
						},
						{
							"_nr": "3",
							"__text": "Apelarea procedurilor la distanta (RPC)"
						},
						{
							"_nr": "4",
							"__text": "Introducere/Extragere in Java"
						},
						{
							"_nr": "5",
							"__text": "Programarea cu sockets in Java"
						},
						{
							"_nr": "6",
							"__text": "Apelarea metodelor la distanta"
						},
						{
							"_nr": "7",
							"__text": "CORBA - Aplicatii distribuite eterogene"
						},
						{
							"_nr": "8",
							"__text": "EJB - Aplicatii bazate pe componente"
						}
					]
				},
				"_id": "358",
				"_isbn": "973-36-0331-7"
			},
			{
				"titlu": "Concepte algebrice fundamentale in studiul limbajelor formale. Teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Ivan"
						},
						{
							"_nume": "Mihai Ivan"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Editura de Vest",
				"tiraj": "400",
				"numarPagini": "264",
				"pretOriginal": "207100",
				"pretCumparare": "207100",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpia Marginean"
				},
				"coperta": {
					"imagine": {
						"_path": "Concepte_algebrice_fundamentale_in_studiul_limbajelor_formale_Teorie_si_exercitii_Ivan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni fundamentale in matematica discreta"
						},
						{
							"_nr": "2",
							"__text": "Algebre universale si structuri algebrice"
						},
						{
							"_nr": "3",
							"__text": "Elemente de teoria laticelor"
						},
						{
							"_nr": "4",
							"__text": "Algebre booleebe si aplicatii"
						},
						{
							"_nr": "5",
							"__text": "Elemente de teoria generala a automatelor"
						},
						{
							"_nr": "6",
							"__text": "Limbaje formale si automate"
						}
					]
				},
				"_id": "359",
				"_isbn": "978-973-36-0432-7"
			},
			{
				"titlu": "Informatica [XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Monica Tataram"
						},
						{
							"_nume": "Corina Achinca"
						},
						{
							"_nume": "Ioana Pestritu"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Corint",
				"numarPagini": "288",
				"pretOriginal": "160000",
				"pretCumparare": "160000",
				"redactori": {
					"redactor": "Sorin Petrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Radu Dobreci"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_XII_Gheorghe.jpg"
					},
					"__text": "Valeria Moldovan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Baze de date"
						},
						{
							"_nr": "1",
							"__text": "Gestiunea datelor"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea bazelor de date"
						},
						{
							"_nr": "3",
							"__text": "Modelul conceptual al bazelor de date relationale"
						},
						{
							"_nr": "4",
							"__text": "Normalizarea bazelor de date"
						},
						{
							"_nr": "5",
							"__text": "Implementarea modelului conceptual"
						},
						{
							"_nr": "6",
							"__text": "Dezvoltarea carierei in domeniul IT"
						},
						{
							"_nr": "II",
							"__text": "Programare Web"
						},
						{
							"_nr": "1",
							"__text": "Proiectarea interfetelor Web"
						},
						{
							"_nr": "2",
							"__text": "Dezvoltarea aplicatiilor Web - introducere"
						},
						{
							"_nr": "3",
							"__text": "Realizarea paginilor Web - HTML"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii server-side limbajul PHP"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii WEB cu baze de date MySQL"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii WEB, exemple practice"
						}
					]
				},
				"_id": "360",
				"_isbn": "978-973-135-128-5"
			},
			{
				"titlu": "Oracle 8. Prelucrarea avansata a informatiei",
				"autori": {
					"autor": {
						"_nume": "Ileana Popescu"
					}
				},
				"anAparitie": "1999",
				"editura": "Tehnica",
				"numarPagini": "280",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Denisa Popa"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Staicu"
				},
				"coperta": {
					"imagine": {
						"_path": "Oracle8_Prelucrarea_avansata_a_informatiei_Popescu.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezntarea generala  sistemului ORACLE"
						},
						{
							"_nr": "2",
							"__text": "SQL*Plus"
						},
						{
							"_nr": "3",
							"__text": "SQL(Structured Query Language)"
						},
						{
							"_nr": "4",
							"__text": "Programare in ORACLE utilizand PL/SQL"
						},
						{
							"_nr": "5",
							"__text": "Probleme rezolvate in SQL si PL/SQL"
						}
					]
				},
				"_id": "361",
				"_isbn": "973-31-1417-0"
			},
			{
				"titlu": "Introducere in calcul paralel si distribuit:algoritmi",
				"autori": {
					"autor": {
						"_nume": "Ilie Popa"
					}
				},
				"anAparitie": "2005",
				"editura": "MatrixRom",
				"numarPagini": "226",
				"pretOriginal": "170000",
				"pretCumparare": "170000",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_calculul_paralel_si_distribuit_algoritmi_Popa.jpg"
					},
					"__text": "Ilie Popa, Alin Berechet"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Realizarea aplicatiilor de procesare paralela"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi PRAM"
						},
						{
							"_nr": "4",
							"__text": "Notiuni de programare paralela folosind variabile partajate"
						},
						{
							"_nr": "5",
							"__text": "Notiuni de programare paralela folosind comunicarea prin mesaje"
						},
						{
							"_nr": "6",
							"__text": "Limbaje de programare paralela"
						}
					]
				},
				"_id": "362",
				"_isbn": "983-685-902-5"
			},
			{
				"titlu": "Biostatistica pentru incepatori (Vol 1 - Biostatistica descriptiva) (editia IV)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Dragomirescu"
						},
						{
							"_nume": "J.W. Wanzer Drane"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Credis",
				"numarPagini": "188",
				"pretOriginal": "0",
				"pretCumparare": "150000",
				"coperta": {
					"imagine": {
						"_path": "Biostatistica_pentru_incepatori_Vol_I_Dragomirescu.jpg"
					},
					"__text": "Ancuta Becherescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere si concepte statistice de baza"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte statistice de baza"
						},
						{
							"_nr": "II",
							"__text": "Introducere in statistica descriptiva"
						},
						{
							"_nr": "3",
							"__text": "Statistica descriptiva univariata"
						},
						{
							"_nr": "III",
							"__text": "Statistica descriptiva bivariata"
						},
						{
							"_nr": "4",
							"__text": "Tratarea simultana a doua dimensiuni"
						},
						{
							"_nr": "5",
							"__text": "Tratarea simultana a doua variabile ordinale"
						},
						{
							"_nr": "6",
							"__text": "Tratarea simultana a doua variabile calitative"
						}
					]
				},
				"_id": "363",
				"_isbn": "978-973-734-185-191-4"
			},
			{
				"titlu": "Principii de logica si programare logica",
				"autori": {
					"autor": [
						{
							"_nume": "G. Metakides"
						},
						{
							"_nume": "A. Nerode"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Tehnica",
				"numarPagini": "284",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"traducere": {
					"titluOriginal": "Priceples of Logic and Logic Programming",
					"editura": "Elsevier Science",
					"traducatori": {
						"traducator": [
							"Adina Florea",
							"Barbat Boldur"
						]
					},
					"_an": "1998",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": ""
				},
				"redactori": {
					"redactor": "Margareta Chilim"
				},
				"tehnoredactori": {
					"tehnoredactor": "Diana Jilavu"
				},
				"coperta": {
					"imagine": {
						"_path": "Principii_de_logica_si_programare_logica_Nerode.jpg"
					},
					"__text": "Silvia Candea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Logica propozitiilor"
						},
						{
							"_nr": "2",
							"__text": "Logica predicatelor"
						},
						{
							"_nr": "3",
							"__text": "Programarea logica: paradigma PROLOG"
						}
					]
				},
				"_id": "364",
				"_isbn": "973-31-1198-8"
			},
			{
				"titlu": "Administrarea si configurarea sistemelor Linux [Editia a II-a]",
				"autori": {
					"autor": {
						"_nume": "Dragos Acostachioie"
					}
				},
				"anAparitie": "2003",
				"editura": "Polirom",
				"numarPagini": "312",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Cezar Petrila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Administarea_si_configurarea_sistemelor_Linux_Ed_II_Acostachioaie.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Instalarea sistemului Linux"
						},
						{
							"_nr": "II",
							"__text": "Configurari de baza ale sistemului"
						},
						{
							"_nr": "III",
							"__text": "Setari avansate ale sistemului"
						},
						{
							"_nr": "IV",
							"__text": "Securitatea sistemului Linux"
						}
					]
				},
				"_id": "365",
				"_isbn": "973-681-455-6"
			},
			{
				"titlu": "Securitatea sistemelor Linux",
				"autori": {
					"autor": {
						"_nume": "Dragos Acostachioie"
					}
				},
				"anAparitie": "2003",
				"editura": "Polirom",
				"numarPagini": "224",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Securitatea_sistemelor_Linux_Acostachioaie.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Securitatea informatica"
						},
						{
							"_nr": "2",
							"__text": "Criptografia"
						},
						{
							"_nr": "3",
							"__text": "Internetul"
						},
						{
							"_nr": "4",
							"__text": "Legi si recomandari"
						},
						{
							"_nr": "5",
							"__text": "Linux/UNIX"
						},
						{
							"_nr": "6",
							"__text": "Instrumente"
						},
						{
							"_nr": "7",
							"__text": "Securitatea sistemului"
						},
						{
							"_nr": "8",
							"__text": "Securizarea serviciului WWW"
						},
						{
							"_nr": "9",
							"__text": "Monitorizarea sistemului"
						},
						{
							"_nr": "10",
							"__text": "Dispozitive firewall"
						}
					]
				},
				"_id": "366",
				"_isbn": "973-681-317-7"
			},
			{
				"titlu": "Atelier de programare in retele de calculatoare",
				"autori": {
					"autor": [
						{
							"_nume": "Sabin Buraga"
						},
						{
							"_nume": "Gabriel Ciobanu"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Atelier_de_programare_in_retele_de_calculatoare_Buraga.jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Gestiunea fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Procese"
						},
						{
							"_nr": "4",
							"__text": "Semnale"
						},
						{
							"_nr": "5",
							"__text": "Comunicarea intre procese. Pipe-uri"
						},
						{
							"_nr": "6",
							"__text": "Duplicarea descriptorilor"
						},
						{
							"_nr": "7",
							"__text": "Interfata socket"
						},
						{
							"_nr": "8",
							"__text": "Modelul client/server - TCP"
						},
						{
							"_nr": "9",
							"__text": "Modelul client/server - UDP"
						},
						{
							"_nr": "10",
							"__text": "Multiplexarea intrarilor/iesirilor"
						},
						{
							"_nr": "11",
							"__text": "RPC - Apelul procedurilor la distanta"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea bibliotecii MySQL"
						},
						{
							"_nr": "13",
							"__text": "Biblioteca ncurses"
						},
						{
							"_nr": "14",
							"__text": "Mediul Glade"
						}
					]
				},
				"_id": "367",
				"_isbn": "973-683-755-6"
			},
			{
				"titlu": "Dictionar informatic trilingv",
				"autori": {
					"autor": [
						{
							"_nume": "Viorel Marinescu"
						},
						{
							"_nume": "Rodica Hrin"
						},
						{
							"_nume": "Mihaela Tomescu"
						},
						{
							"_nume": "Mircea Hrin"
						},
						{
							"_nume": "Laurian Anania"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "All Educational",
				"numarPagini": "392",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_informatic_Marinescu.jpg"
					},
					"__text": "Daniel Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Prefata"
						},
						{
							"_nr": "I",
							"__text": "Dictionar englez-francez-roman"
						},
						{
							"_nr": "II",
							"__text": "Dictionar francez-englez-roman"
						},
						{
							"_nr": "III",
							"__text": "Dictionar roman-englez-francez"
						},
						{
							"_nr": "Anexa1",
							"__text": "Anexa 1"
						},
						{
							"_nr": "Anexa2",
							"__text": "Anexa 2:Abrevieri"
						}
					]
				},
				"_id": "368",
				"_isbn": "973-9431-95-X"
			},
			{
				"titlu": "Ghid de programare in Java",
				"autori": {
					"autor": [
						{
							"_nume": "Peter Norton"
						},
						{
							"_nume": "William Stanek"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "896",
				"pretOriginal": "190000",
				"pretCumparare": "90000",
				"colectia": {
					"_numarul": "235",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Peter Norton's Guide to Java Programming",
					"editura": "Peter Norton",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_programare_in_Java_Norton.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Urmatoare oprire - Java"
						},
						{
							"_nr": "1",
							"__text": "Prezentarea limbajului Java"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea setului de dezvoltarea Java (JDK)"
						},
						{
							"_nr": "II",
							"__text": "Abecedarele puterii"
						},
						{
							"_nr": "3",
							"__text": "Browserul Java si sitemul Word Wide Web:abecedar"
						},
						{
							"_nr": "4",
							"__text": "Limbajul Java:abecedar"
						},
						{
							"_nr": "5",
							"__text": "Instrumentele Java si setul JDK:abecedar"
						},
						{
							"_nr": "III",
							"__text": "Anatomia limbajului Java"
						},
						{
							"_nr": "6",
							"__text": "Elemente de baza ale limbajului Java"
						},
						{
							"_nr": "7",
							"__text": "Construirea obiectelor"
						},
						{
							"_nr": "8",
							"__text": "Integrarea tuturor elementelor: fire de executie, exceptii si altele"
						},
						{
							"_nr": "IV",
							"__text": "Interfata Java de programare a aplicatiilor"
						},
						{
							"_nr": "9",
							"__text": "Prezentarea interfetei Java API"
						},
						{
							"_nr": "10",
							"__text": "Bibliotecile de clase java.lang si java.applet"
						},
						{
							"_nr": "11",
							"__text": "Biblioteca de clase AWT"
						},
						{
							"_nr": "12",
							"__text": "Bibliotecile de clase de intrare/iesire si utilitare"
						},
						{
							"_nr": "13",
							"__text": "Bibliotecile de clase pentru lucrul in retea si pentru depanare"
						},
						{
							"_nr": "V",
							"__text": "Dezvoltarea miniaplicatiilor Java pentru Web"
						},
						{
							"_nr": "14",
							"__text": "Crearea unei aplicatii independente"
						},
						{
							"_nr": "15",
							"__text": "Crearea unor prezentari Web imbunatatite cu minialicatii Java"
						},
						{
							"_nr": "16",
							"__text": "Refolosrea miniaplicatiilor"
						},
						{
							"_nr": "VI",
							"__text": "Dezvoltarea aplicatiilor independente"
						},
						{
							"_nr": "17",
							"__text": "Crearea unei aplicatii independente"
						},
						{
							"_nr": "18",
							"__text": "Folosirea aplicatiilor Java in lumea reala"
						},
						{
							"_nr": "19",
							"__text": "Modernizarea aplicatiilor"
						},
						{
							"_nr": "VII",
							"__text": "Elemente avansate"
						},
						{
							"_nr": "20",
							"__text": "Proiectarea si implementarea miniaplicatiilor Java avansate"
						},
						{
							"_nr": "21",
							"__text": "Proiectarea si implementarea aplicatiilor avansate"
						},
						{
							"_nr": "22",
							"__text": "Integrarea programelor si a bibliotecilor native"
						},
						{
							"_nr": "23",
							"__text": "Elemente de depanare avansata"
						},
						{
							"_nr": "24",
							"__text": "Masina virtuala Java"
						},
						{
							"_nr": "VIII",
							"__text": "Anexe"
						}
					]
				},
				"_id": "369",
				"_isbn": "973-601-719-2"
			},
			{
				"titlu": "Retele de calculatoare [editia III]",
				"autori": {
					"autor": {
						"_nume": "Andrew S. Tanenbaum"
					}
				},
				"anAparitie": "1998",
				"editura": "Agora",
				"numarPagini": "766",
				"pretOriginal": "0",
				"pretCumparare": "190000",
				"traducere": {
					"titluOriginal": "Computer Networks, 3rd ed.",
					"editura": "Prentice-Hall",
					"traducatori": {
						"traducator": [
							"Valentin Cristea",
							"Irina Athanasiu",
							"Eugenia Kalisz",
							"Nicolae Tapus",
							"Trandafir Moisa",
							"Vlad Vieru",
							"Anca Bucur",
							"Gavril Godza",
							"Bogdan Costinescu",
							"Bodgan Panghe",
							"Florin Teodorescu",
							"Andrei Rares",
							"Ion Bica",
							"Matei Rapeanu",
							"Monica Pietroseanu",
							"Stefan Rusen",
							"Florentina Popovici",
							"Mihaela Tancu",
							"Liviu Ristoscu"
						]
					},
					"_an": "1996",
					"_editia": "3",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_de_calculatoare_Tanenbaum.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Nivelul fizic"
						},
						{
							"_nr": "3",
							"__text": "Nivelul de legatura de date"
						},
						{
							"_nr": "4",
							"__text": "Subnivelul de acces la mediu"
						},
						{
							"_nr": "5",
							"__text": "Nivelul retea"
						},
						{
							"_nr": "6",
							"__text": "Nivelul transport"
						},
						{
							"_nr": "7",
							"__text": "Nivelul aplicatie"
						},
						{
							"_nr": "8",
							"__text": "Bibliografie si recomandarile de lectura"
						}
					]
				},
				"_id": "370",
				"_isbn": ""
			},
			{
				"titlu": "Baze de date [editia VIII]",
				"autori": {
					"autor": {
						"_nume": "C.J.Date"
					}
				},
				"anAparitie": "2005",
				"editura": "Plus",
				"numarPagini": "1095",
				"pretOriginal": "800000",
				"pretCumparare": "800000",
				"traducere": {
					"titluOriginal": "An introduction to Database Systems",
					"editura": "Peterson Education",
					"traducatori": {
						"traducator": [
							"Simona Preda",
							"Titi Preda"
						]
					},
					"_an": "2004",
					"_editia": "8",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Andreea Matei"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_Date.jpg"
					},
					"__text": "Gabriel Iancu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "1",
							"__text": "Introducere in gestiunea bazelor de date"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura sistemelor de baze de date"
						},
						{
							"_nr": "3",
							"__text": "Prezentarea bazelor de date relationale"
						},
						{
							"_nr": "4",
							"__text": "Prezentarea limbajului SQL"
						},
						{
							"_nr": "II",
							"__text": "Modelul relational"
						},
						{
							"_nr": "5",
							"__text": "Tipuri"
						},
						{
							"_nr": "6",
							"__text": "Relatii"
						},
						{
							"_nr": "7",
							"__text": "Algebra relationala"
						},
						{
							"_nr": "8",
							"__text": "Calul relational"
						},
						{
							"_nr": "9",
							"__text": "Integritate"
						},
						{
							"_nr": "10",
							"__text": "Vederi"
						},
						{
							"_nr": "III",
							"__text": "Proiectarea bezelor de date"
						},
						{
							"_nr": "11",
							"__text": "Dependente functionale"
						},
						{
							"_nr": "12",
							"__text": "Continuarea normalizarii:1NF, 2NF, BCNF"
						},
						{
							"_nr": "13",
							"__text": "Continuarea normalizarii II: forme normale superioare"
						},
						{
							"_nr": "14",
							"__text": "Modelare semantica"
						},
						{
							"_nr": "IV",
							"__text": "Gestionarea tranzatiilor"
						},
						{
							"_nr": "15",
							"__text": "Refacerea"
						},
						{
							"_nr": "16",
							"__text": "Concurenta"
						},
						{
							"_nr": "V",
							"__text": "Alte subiecte"
						},
						{
							"_nr": "17",
							"__text": "Securitatea"
						},
						{
							"_nr": "18",
							"__text": "Optimizare"
						},
						{
							"_nr": "19",
							"__text": "Informatiile lipsa"
						},
						{
							"_nr": "20",
							"__text": "Mostenirea tipurilor"
						},
						{
							"_nr": "21",
							"__text": "Baze de date distribuite"
						},
						{
							"_nr": "22",
							"__text": "Suportul pentru procesul decizional"
						},
						{
							"_nr": "23",
							"__text": "Baze de date temporale"
						},
						{
							"_nr": "24",
							"__text": "Baze de date bazate pe logica"
						},
						{
							"_nr": "VI",
							"__text": "Obiecte, relatii si limbajul XML"
						},
						{
							"_nr": "25",
							"__text": "Baze de date de obiecte"
						},
						{
							"_nr": "26",
							"__text": "Baze de date obiect-relationale"
						},
						{
							"_nr": "27",
							"__text": "World Wide Web si XML"
						},
						{
							"_nr": "ANEXE"
						},
						{
							"_nr": "A",
							"__text": "Modelul TransRelational"
						},
						{
							"_nr": "B",
							"__text": "Expresii SQL"
						},
						{
							"_nr": "C",
							"__text": "Abrevieri, acronime si simboluri"
						},
						{
							"_nr": "D",
							"__text": "Structuri de stocare si metode de acces"
						}
					]
				},
				"_id": "371",
				"_isbn": "973-86190-1-7"
			},
			{
				"titlu": "Programarea in limbajul ADA",
				"autori": {
					"autor": [
						{
							"_nume": "Iosif Kaufmann"
						},
						{
							"_nume": "Ioan Jurca"
						},
						{
							"_nume": "Dorina Petriu"
						},
						{
							"_nume": "Vladimir Cretu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Facla",
				"numarPagini": "264",
				"pretOriginal": "15.50",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_ADA_Kaufmann.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Elemente lexicale"
						},
						{
							"_nr": "3",
							"__text": "Generalitati despre tipuri"
						},
						{
							"_nr": "4",
							"__text": "Tipuri predefinite"
						},
						{
							"_nr": "5",
							"__text": "Tipuri numerice"
						},
						{
							"_nr": "6",
							"__text": "Expresii"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni"
						},
						{
							"_nr": "8",
							"__text": "Declaratii si tipuri"
						},
						{
							"_nr": "9",
							"__text": "Nume si variabile"
						},
						{
							"_nr": "10",
							"__text": "Tipuri si variabile de acces"
						},
						{
							"_nr": "11",
							"__text": "Subprograme"
						},
						{
							"_nr": "12",
							"__text": "Structura programelor"
						},
						{
							"_nr": "13",
							"__text": "Module"
						},
						{
							"_nr": "14",
							"__text": "Vizibilitate"
						},
						{
							"_nr": "15",
							"__text": "Compilarea programelor"
						},
						{
							"_nr": "16",
							"__text": "Notiuni de programare concurenta"
						},
						{
							"_nr": "17",
							"__text": "Taskuri si intrari"
						},
						{
							"_nr": "18",
							"__text": "Familii de taskuri si de intrari"
						},
						{
							"_nr": "19",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "20",
							"__text": "Unitati de program generice"
						},
						{
							"_nr": "21",
							"__text": "Specificari de reprezentare si trasaturi dependente de implementare"
						},
						{
							"_nr": "22",
							"__text": "Intrare-iesire"
						}
					]
				},
				"_id": "272",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in limbajul PL/I",
				"autori": {
					"autor": {
						"_nume": "Horia Georgescu"
					}
				},
				"anAparitie": "1973",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "268",
				"pretOriginal": "13",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_PL_I_Georgescu.jpg"
					},
					"__text": "Ionita Gheorghe"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Capitol introductiv"
						},
						{
							"_nr": "2",
							"__text": "Elementele unui program"
						},
						{
							"_nr": "3",
							"__text": "Date"
						},
						{
							"_nr": "4",
							"__text": "Expresii"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni de intrare/iesire pentru fisiere standard"
						},
						{
							"_nr": "7",
							"__text": "Secventa controlului. Alocarea memoriei. Recunoasterea numelor"
						},
						{
							"_nr": "8",
							"__text": "Subrutine si functii"
						},
						{
							"_nr": "9",
							"__text": "Date de tip caractere numerice. Instructiunea ON"
						},
						{
							"_nr": "10",
							"__text": "Fisiere pe discuri si benzi"
						},
						{
							"_nr": "11",
							"__text": "Mic ghid de programare"
						}
					]
				},
				"_id": "373",
				"_isbn": ""
			},
			{
				"titlu": "Retele de calculatoare. Proiectare si administrare",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Munteanu"
						},
						{
							"_nume": "Valerica Greavu Serban"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Polirom",
				"numarPagini": "304",
				"pretOriginal": "209000",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": "Mihai-Silviu Chirila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Ghetau"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_locale_de_calcultatoare_proiectare_si_administrare_Munteanu.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Arhitecturi si standarde"
						},
						{
							"_nr": "1",
							"__text": "Modele standarde"
						},
						{
							"_nr": "2",
							"__text": "Infrastructura retelei"
						},
						{
							"_nr": "3",
							"__text": "Nivelul legatura date"
						},
						{
							"_nr": "4",
							"__text": "Nivelul retea"
						},
						{
							"_nr": "5",
							"__text": "Nivelul transport"
						},
						{
							"_nr": "6",
							"__text": "Nivelurile sesiune si prezentare"
						},
						{
							"_nr": "7",
							"__text": "Nivelul aplicatie"
						},
						{
							"_nr": "II",
							"__text": "Proiectarea retelelor de calculatoare"
						},
						{
							"_nr": "8",
							"__text": "Cablarea structurala a unei retele de calculatoare"
						},
						{
							"_nr": "9",
							"__text": "Comutarea pachetelor in retelele de calculatoare"
						},
						{
							"_nr": "10",
							"__text": "Retele virtuale"
						},
						{
							"_nr": "11",
							"__text": "Privire generala asupra routerelor"
						},
						{
							"_nr": "12",
							"__text": "Proiectarea si adminstrarea retelelor"
						},
						{
							"_nr": "13",
							"__text": "Instalarea si configurarea logica a unei retele locale"
						},
						{
							"_nr": "14",
							"__text": "Utilitare de adminstrare"
						}
					]
				},
				"_id": "374",
				"_isbn": "973-681-457-2"
			},
			{
				"titlu": "Criptanaliza. Rezultate si tehnici matematice",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Preda"
						},
						{
							"_nume": "Emil Simion"
						},
						{
							"_nume": "Adrian Popescu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "340",
				"pretOriginal": "220000",
				"pretCumparare": "220000",
				"coperta": {
					"imagine": {
						"_path": "Criptanaliza_Rezultate_si_tehnici_matematice_Preda.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "3",
							"__text": "Teoria complexitatii algoritmilor"
						},
						{
							"_nr": "4",
							"__text": "Analiza statistico-informationala"
						},
						{
							"_nr": "5",
							"__text": "Codificarea in absenta perturbatiei"
						},
						{
							"_nr": "6",
							"__text": "Criptanaliza cifrurilor clasice"
						},
						{
							"_nr": "7",
							"__text": "Criptanaliza cifrurilor flux"
						},
						{
							"_nr": "8",
							"__text": "Criptanaliza cifrurilor bloc"
						},
						{
							"_nr": "9",
							"__text": "Criptanaliza cifrurilor cu chei publice"
						},
						{
							"_nr": "10",
							"__text": "Criptanaliza semnaturilor digitale"
						},
						{
							"_nr": "11",
							"__text": "Criptanaliza protocoalelor criptografice"
						},
						{
							"_nr": "12",
							"__text": "Criptanaliza sistemelor de cifrare analogice"
						},
						{
							"_nr": "13",
							"__text": "Managementul cheilor criptografice"
						},
						{
							"_nr": "14",
							"__text": "Stenografia"
						},
						{
							"_nr": "A",
							"__text": "Metode si tehnici de programare"
						},
						{
							"_nr": "B",
							"__text": "Elemente de teoria probabilitatilor"
						},
						{
							"_nr": "C",
							"__text": "Statistica descriptiva"
						},
						{
							"_nr": "D",
							"__text": "Teoria estimatiei"
						},
						{
							"_nr": "E",
							"__text": "Repartiti statistice"
						},
						{
							"_nr": "F",
							"__text": "Serii dinamice stationare"
						},
						{
							"_nr": "G",
							"__text": "Modelul autoregresiv-medie mobila"
						},
						{
							"_nr": "H",
							"__text": "Simularea variabilelor aleatoare"
						},
						{
							"_nr": "I",
							"__text": "Elemente de teoria numerelor"
						}
					]
				},
				"_id": "375",
				"_isbn": "973-575-975-6"
			},
			{
				"titlu": "Tehnologia informatiei si comunicatiilor [Manual IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Ciprian Constantinescu"
						},
						{
							"_nume": "Manuela Florea"
						},
						{
							"_nume": "Monica Tataram"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Corint",
				"numarPagini": "160",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Sorin Petrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Liubovi Grecea"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologia_informatiei_si_comunicatiilor_Tataram.jpg"
					},
					"__text": "Walter Riess"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatoare si retele de calculatoare. Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare Windows"
						},
						{
							"_nr": "3",
							"__text": "Procesorul de texte Microsoft Word"
						},
						{
							"_nr": "4",
							"__text": "Comunicarea prin internet"
						},
						{
							"_nr": "5",
							"__text": "Pagini Web. Notiuni introductive"
						}
					]
				},
				"_id": "376",
				"_isbn": "973-653-633-5"
			},
			{
				"titlu": "Procesare paralela",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "Bogdan Oancea"
						},
						{
							"_nume": "Madalina Raceanu"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Economica",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Carmen Taranu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Milea"
				},
				"coperta": {
					"imagine": {
						"_path": "Procesare_paralela_Dodescu.jpg"
					},
					"__text": "Adriana Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in problematica calculului paralel"
						},
						{
							"_nr": "2",
							"__text": "Notiuni fundamentale utilizate in procesarea paralela"
						},
						{
							"_nr": "3",
							"__text": "Arhitecturi ale calcului paralel"
						},
						{
							"_nr": "4",
							"__text": "Arhitecturi pentru paralelismul la nivel de date"
						},
						{
							"_nr": "5",
							"__text": "Arhitecturi pentru paralelismul la nivel de proces"
						},
						{
							"_nr": "6",
							"__text": "Evaluarea performantelor algoritmilor paraleli"
						},
						{
							"_nr": "7",
							"__text": "Algoritmi paraleli"
						},
						{
							"_nr": "8",
							"__text": "Sisteme de operare pentru procesarea paralala"
						}
					]
				},
				"_id": "377",
				"_isbn": "973-590-730-5"
			},
			{
				"titlu": "Multimedia",
				"autori": {
					"autor": {
						"_nume": "Adrian Vasilescu"
					}
				},
				"anAparitie": "2000",
				"editura": "Economica",
				"numarPagini": "288",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Mihai Milca"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gabriela Capitanescu",
						"Angela Cotofana"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Multimedia_Vasilescu.jpg"
					},
					"__text": "Adriana Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode de recunostere a formelor"
						},
						{
							"_nr": "2",
							"__text": "Geometria si topologia figurilor binare"
						},
						{
							"_nr": "3",
							"__text": "Analiza si codarea imaginilor transformate"
						},
						{
							"_nr": "4",
							"__text": "Audio"
						},
						{
							"_nr": "5",
							"__text": "Multimedia/Hypermedia in medii distribuite"
						},
						{
							"_nr": "6",
							"__text": "Baze de date relationale cu aplicatii multimedia"
						},
						{
							"_nr": "7",
							"__text": "Baze de date orientate obiect cu aplicatii multimedia"
						},
						{
							"_nr": "8",
							"__text": "Siteme multimedia de informatie"
						}
					]
				},
				"_id": "378",
				"_isbn": "973-590-355-5"
			},
			{
				"titlu": "Informatica pentru liceu si bacalaureat [X] (Varianta Pascal)",
				"autori": {
					"autor": [
						{
							"_nume": "George Daniel Mateescu"
						},
						{
							"_nume": "Pavel Florin Moraru"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Donaris",
				"numarPagini": "224",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "3",
					"_nume": "Donaris-Info"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Razvan Bucur",
						"Laurentiu Codau"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Varianta_Pascal_X_Mateescu.jpg"
					},
					"__text": "Attila Biblia, Ciprian Ciocan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "II",
							"__text": "Subprograme"
						},
						{
							"_nr": "III",
							"__text": "Recursivitate"
						},
						{
							"_nr": "IV",
							"__text": "Conexiunile intre informatica si societate (aplicatii interdisciplinare)"
						}
					]
				},
				"_id": "379",
				"_isbn": "973-86665-1-3"
			},
			{
				"titlu": "Informatica pentru liceu si bacalaureat [IX] (Varianta Pascal)",
				"autori": {
					"autor": [
						{
							"_nume": "George Daniel Mateescu"
						},
						{
							"_nume": "Pavel Florin Moraru"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Donaris",
				"numarPagini": "272",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "1",
					"_nume": "Donaris-Info"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Razvan Bucur",
						"Laurentiu Codau"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Varianta_Pascal_IX_Mateescu.jpg"
					},
					"__text": "Attila Biblia, Ciprian Ciocan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Algoritmi"
						},
						{
							"_nr": "II",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "III",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "IV",
							"__text": "Instructiunile limbajului"
						},
						{
							"_nr": "V",
							"__text": "Tablouri"
						},
						{
							"_nr": "VI",
							"__text": "Fisiere"
						}
					]
				},
				"_id": "380",
				"_isbn": "973-87842-12-X"
			},
			{
				"titlu": "MS Word: simplu si eficient",
				"autori": {
					"autor": [
						{
							"_nume": "Cristina Perhinschi"
						},
						{
							"_nume": "Petronela Ilunca"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "176",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "MS_Word_simplu_si_eficient_Perhinschi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cateva cuvinte de inceput"
						},
						{
							"_nr": "2",
							"__text": "Cum \"deschideti\" calculatorul"
						},
						{
							"_nr": "3",
							"__text": "Despre Windows Explorer"
						},
						{
							"_nr": "4",
							"__text": "Directoare si fisiere"
						},
						{
							"_nr": "5",
							"__text": "Cum se scrie intr-un fisier Word"
						},
						{
							"_nr": "6",
							"__text": "Principalele operatii care se pot efectua asupra textului"
						},
						{
							"_nr": "7",
							"__text": "Salvarea documentului"
						},
						{
							"_nr": "8",
							"__text": "Listarea documentului"
						},
						{
							"_nr": "9",
							"__text": "Cum se gaseste un document"
						},
						{
							"_nr": "10",
							"__text": "Formatari speciale"
						},
						{
							"_nr": "11",
							"__text": "Corectarea textului"
						},
						{
							"_nr": "12",
							"__text": "Cautarea si inlocuirea unui sir de caractere"
						},
						{
							"_nr": "13",
							"__text": "Antetul si notele de subsol"
						},
						{
							"_nr": "14",
							"__text": "Inserarea graficelor"
						},
						{
							"_nr": "15",
							"__text": "Ecuatii"
						},
						{
							"_nr": "16",
							"__text": "Inserarea unui imagini"
						},
						{
							"_nr": "17",
							"__text": "Lucrul simultan cu mai multe documente"
						},
						{
							"_nr": "18",
							"__text": "Modele de scrisori de afaceri"
						},
						{
							"_nr": "19",
							"__text": "Word-ul va ajuta sa scrieti scrisori"
						},
						{
							"_nr": "20",
							"__text": "Macro-uri"
						},
						{
							"_nr": "21",
							"__text": "Meniuri"
						},
						{
							"_nr": "23",
							"__text": "Mesaje"
						}
					]
				},
				"_id": "381",
				"_isbn": "973-683-719-X"
			},
			{
				"titlu": "Totul despre...Winsows 95",
				"autori": {
					"autor": {
						"_nume": "Andrew Schulman"
					}
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "400",
				"pretOriginal": "16000",
				"pretCumparare": "10000",
				"traducere": {
					"titluOriginal": "Unauthorized Windows 95",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Doru Turture"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_Windows_95_Schulman.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Implicatiile mesajului \"Starting Windows 95\""
						},
						{
							"_nr": "2",
							"__text": "Procesul de lansare a sistemului Windows 95"
						},
						{
							"_nr": "3",
							"__text": "Relatia Windows-DOS"
						},
						{
							"_nr": "4",
							"__text": "Tot despre... relatia Windows-DOS"
						},
						{
							"_nr": "5",
							"__text": "Prezentarea celor doua fatete ale sistemului Windows 95"
						},
						{
							"_nr": "6",
							"__text": "Examinarea  programelor de tip protected-mode DOS: WIN 386 si MSDPMI"
						},
						{
							"_nr": "7",
							"__text": "Prezentarea facilitatilor 32BFA si LFN"
						},
						{
							"_nr": "8",
							"__text": "Disparitia graduala a sistemului DOS"
						},
						{
							"_nr": "9",
							"__text": "Cine comanda sistemul Windows 95, Windows sau MS-DOS"
						},
						{
							"_nr": "10",
							"__text": "Prezentarea modului in care Windows executa programe DOS"
						},
						{
							"_nr": "11",
							"__text": "Cine are nevoie de sitemul MS-DOS"
						},
						{
							"_nr": "12",
							"__text": "Explorarea relatiei Windows-DOS prin intermediul programului V86TEST"
						},
						{
							"_nr": "13",
							"__text": "Prezentarea mecanismului THUNK. KERNEL32 invoca modulul KRNL386"
						},
						{
							"_nr": "14",
							"__text": "Aplicatia Clock: releva mixarea fragmentelor de cod scrise  pe 32 de biti cu cele de 16 biti"
						}
					]
				},
				"_id": "382",
				"_isbn": "973-31-0793-X"
			},
			{
				"titlu": "Netscape Navigator",
				"autori": {
					"autor": {
						"_nume": "Jason J. Manger"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "19000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "70",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Netscape Navigator",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": "Adrian Ludosan"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Netscape_Navigator_Manger.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "O introducere in Netscape"
						},
						{
							"_nr": "2",
							"__text": "Locatoare uniforme de resurse - URL"
						},
						{
							"_nr": "3",
							"__text": "Crearea propriilor pagini Web, folosind HTML"
						},
						{
							"_nr": "4",
							"__text": "Netscape : browserul pentru grupuri de stiri"
						},
						{
							"_nr": "5",
							"__text": "Cautare in Web: repertoare, robori, paianjeni si taratoare"
						},
						{
							"_nr": "",
							"__text": "Anexe"
						}
					]
				},
				"_id": "383",
				"_isbn": "973-601-381-2"
			},
			{
				"titlu": "Integrarea intraNetWare- NT",
				"autori": {
					"autor": [
						{
							"_nume": "J.D. Marymess"
						},
						{
							"_nume": "Sandy Stevens"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "416",
				"pretOriginal": "109000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "287",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Guide to integrating IntranetWare and NT",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": [
							"Daniel Marian Merezeanu",
							"Aurelia Nicoleta Merezeanu"
						]
					},
					"_an": "1998",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Integrare_intraNetWare_NT_Stevens.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De ce este necesara integrarea?"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare IntranetWare"
						},
						{
							"_nr": "3",
							"__text": "Retele NT Server"
						},
						{
							"_nr": "4",
							"__text": "Integrarea sistemelor eterogene"
						},
						{
							"_nr": "5",
							"__text": "Integrarea statiilor de lucru NT"
						},
						{
							"_nr": "6",
							"__text": "Sicronizarea directoarelor din retea"
						},
						{
							"_nr": "7",
							"__text": "Integrarea prin Novell NDS for NT"
						},
						{
							"_nr": "8",
							"__text": "NDS Cross-Platform"
						},
						{
							"_nr": "9",
							"__text": "Distribuirea serviciilor de emulare in retea"
						},
						{
							"_nr": "10",
							"__text": "Gestionarea statiilor NT prin NDS"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea scripturilor intr-un mediu integrat"
						},
						{
							"_nr": "12",
							"__text": "Produsul Novell Application Launcher"
						},
						{
							"_nr": "Anexa A",
							"__text": "NDS sau Doman"
						},
						{
							"_nr": "Anexa B",
							"__text": "Ghidul solutiilor"
						},
						{
							"_nr": "Anexa C",
							"__text": "Novell Workstation Manager - ghid de instalare"
						},
						{
							"_nr": "Anexa D",
							"__text": "Novell Administration for Windows NT - ghid de instalare"
						},
						{
							"_nr": "Anexa E",
							"__text": "Synchronicity for NT - ghid de instalare"
						},
						{
							"_nr": "Anexa F",
							"__text": "NDS for NT v 1.0 - ghid de instalare"
						},
						{
							"_nr": "Anexa G",
							"__text": "Microsoft Active Directory Services"
						},
						{
							"_nr": "Anexa H",
							"__text": "Continutul CD-ROM-ului"
						}
					]
				},
				"_id": "384",
				"_isbn": "973-20-0117-8"
			},
			{
				"titlu": "Sisteme integrate de productie",
				"autori": {
					"autor": [
						{
							"_nume": "George Dragoi"
						},
						{
							"_nume": "Marius Guran"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "432",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Vasile Buzatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_integrate_de_productie_asistate_de_calculator_Dragoi.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Integrarea activatatilor industriale cu calculatorul"
						},
						{
							"_nr": "2",
							"__text": "Subsitemele componente ale CIM"
						},
						{
							"_nr": "3",
							"__text": "Inteprinderea. Cerinte si specificatii ale CIM"
						},
						{
							"_nr": "4",
							"__text": "Sisteme integrate de productie si standardizarea"
						},
						{
							"_nr": "5",
							"__text": "Tendinte si perspective ale CIM"
						},
						{
							"_nr": "6",
							"__text": "Anexe"
						},
						{
							"_nr": "7",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "385",
				"_isbn": "973-31-1124-4"
			},
			{
				"titlu": "PageMaker 5 sub Windows pentru toti",
				"autori": {
					"autor": [
						{
							"_nume": "Deke McClelland"
						},
						{
							"_nume": "Galen Gruman"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "384",
				"pretOriginal": "13900",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "62",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "PageMaker 4 for Windows For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Gebriel Cornilescu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Vladurescu"
				},
				"coperta": {
					"imagine": {
						"_path": "PageMaker_5_sub_Windows_pentru_toti_Gruman.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Cu ce sa incepeti? (Chiar inainte de a lansa programul PageMaker)"
						},
						{
							"_nr": "II",
							"__text": "O pagina alba; si mai departe?"
						},
						{
							"_nr": "III",
							"__text": "E dragut, dar totusi ati vrea ceva cu totul special"
						},
						{
							"_nr": "IV",
							"__text": "Documente la patru ace"
						},
						{
							"_nr": "V",
							"__text": "Desene teribile de care nimeni nu v-ar fi crezut in stare"
						},
						{
							"_nr": "VI",
							"__text": "Adica tot nu s-a terminat?"
						},
						{
							"_nr": "VII",
							"__text": "Partea decaloagelor"
						},
						{
							"_nr": "Anexa",
							"__text": "Cum sa (re)instalati programul PageMaker"
						}
					]
				},
				"_id": "386",
				"_isbn": "973-601-245-X"
			},
			{
				"titlu": "Modemuri pentru toti",
				"autori": {
					"autor": {
						"_nume": "Rita Rathbone"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "496",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "134",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Modems For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Alexandru Niculescu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modemuri_pentru_toti_Rathbone.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Faceti cunostinta cu modemul"
						},
						{
							"_nr": "II",
							"__text": "Modemul are nevoie de soft (ca sa strice ceva)"
						},
						{
							"_nr": "III",
							"__text": "Sa punem modemul la treaba"
						},
						{
							"_nr": "IV",
							"__text": "Depanarea modemului"
						},
						{
							"_nr": "V",
							"__text": "Supliment de decaloage"
						},
						{
							"_nr": "VI",
							"__text": "Anexe"
						}
					]
				},
				"_id": "387",
				"_isbn": "973-601-328-6"
			},
			{
				"titlu": "Neprocedural in Oracle10g SQL",
				"autori": {
					"autor": [
						{
							"_nume": "Ileana Popescu"
						},
						{
							"_nume": "Letitia Velcescu"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "232",
				"pretOriginal": "310000",
				"pretCumparare": "310000",
				"coperta": {
					"imagine": {
						"_path": "Neprocedural_in_Oracle10g_SQL_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati despre SQL"
						},
						{
							"_nr": "2",
							"__text": "Elemente SQL in Oracle10g"
						},
						{
							"_nr": "3",
							"__text": "Limbajul de definire a datelor"
						},
						{
							"_nr": "4",
							"__text": "Limbajul de prelucrare a datelor"
						},
						{
							"_nr": "5",
							"__text": "Limbajele de control al tranzactiilor si datelor"
						}
					]
				},
				"_id": "388",
				"_isbn": "978-973-737-465-3"
			},
			{
				"titlu": "Gramatici formale",
				"autori": {
					"autor": {
						"_nume": "Emil Ionescu"
					}
				},
				"anAparitie": "2007",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "148",
				"pretOriginal": "200000",
				"pretCumparare": "200000",
				"tehnoredactori": {
					"tehnoredactor": "Victoria Iacob"
				},
				"coperta": {
					"imagine": {
						"_path": "Gramatici_formale_Ionescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fundamentele gramaticilor formale"
						},
						{
							"_nr": "II",
							"__text": "Extensiile gramaticilor formale"
						},
						{
							"_nr": "III",
							"__text": "Teoria HPSG"
						}
					]
				},
				"_id": "389",
				"_isbn": "978-973-737-275-8"
			},
			{
				"titlu": "MCSE: Windows 2000 Directory Services Administration: Ghid de studiu",
				"autori": {
					"autor": [
						{
							"_nume": "Anil Sesai"
						},
						{
							"_nume": "James Chellis"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "All",
				"numarPagini": "582",
				"pretOriginal": "2",
				"pretCumparare": "2",
				"traducere": {
					"titluOriginal": "MCSE:Windows 2000 Directory Services Administration Study Guide",
					"traducatori": {
						"traducator": "Madalina Aimee Erighin"
					},
					"_an": "2000",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Marius Murariu"
				},
				"coperta": {
					"imagine": {
						"_path": "MCSE_Windows_2000_Directory_Services_Adminstration_Ghide_de_studiu_Desai.jpg"
					},
					"__text": "Daniel Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Trecerea in revista a unor notiuni despre Active Directory"
						},
						{
							"_nr": "2",
							"__text": "Integrarea DNS cu Active Directory"
						},
						{
							"_nr": "3",
							"__text": "Instalarea si configurarea Active Directory"
						},
						{
							"_nr": "4",
							"__text": "Crearea si administrarea Unitatilor Organizationale"
						},
						{
							"_nr": "5",
							"__text": "Instalarea si adminstrarea arborilor padurilor de domenii"
						},
						{
							"_nr": "6",
							"__text": "Configurarea site-urilor si administarea replicarii"
						},
						{
							"_nr": "7",
							"__text": "Administarea Active Directory"
						},
						{
							"_nr": "8",
							"__text": "Securitatea Active Directory"
						},
						{
							"_nr": "9",
							"__text": "Optimizarea si fiabilitatea Active Directory"
						},
						{
							"_nr": "10",
							"__text": "Administrarea politicii de grup"
						},
						{
							"_nr": "11",
							"__text": "Procesul de instalare a produselor software prin intermediul Group Policy"
						},
						{
							"_nr": "12",
							"__text": "Instalarea si configurarea RIS"
						},
						{
							"_nr": "Anexa A",
							"__text": "Examen"
						},
						{
							"_nr": "Anexa B",
							"__text": "Planificarea Active Directory"
						}
					]
				},
				"_id": "390",
				"_isbn": "973-571-347-0"
			},
			{
				"titlu": "Generarea simpla, rapida si performanta a aplicatiilor Windows in limbajul C, utilizand biblioteca de functii CONTI",
				"autori": {
					"autor": {
						"_nume": "Mihai Gavota"
					}
				},
				"anAparitie": "1997",
				"editura": "All",
				"numarPagini": "128",
				"pretOriginal": "0",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Andreea Lutic"
				},
				"tehnoredactori": {
					"tehnoredactor": "Florian Bulmez"
				},
				"coperta": {
					"imagine": {
						"_path": "Generarea_simpla_rapida_si_performanta_a_aplicatiilor_Windows_in_limbajul_C_utilizand_biblioteca_de_functii_CONTI_Gavota.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Siruri de caractere generate ca variabile de memorie dinamica"
						},
						{
							"_nr": "2",
							"__text": "Tratarea tastaturii si mouse-ului"
						},
						{
							"_nr": "3",
							"__text": "Interactiunea prin dialoguri predefinite"
						},
						{
							"_nr": "4",
							"__text": "Controlul afisarii textelor si imaginilor"
						},
						{
							"_nr": "5",
							"__text": "Lucrul cu fisiere"
						},
						{
							"_nr": "6",
							"__text": "Servicii pentru selectia fisierelor si lucrul cu directoare"
						},
						{
							"_nr": "7",
							"__text": "Editare"
						},
						{
							"_nr": "8",
							"__text": "Meniuri"
						},
						{
							"_nr": "9",
							"__text": "Butoane si liste de butoane"
						},
						{
							"_nr": "10",
							"__text": "Lucrul cu desktop-ul"
						},
						{
							"_nr": "11",
							"__text": "Clipboard-ul si paleta de culori"
						},
						{
							"_nr": "12",
							"__text": "Lansari in executie"
						},
						{
							"_nr": "13",
							"__text": "Controlul actiunilor prin intermediul mouse-ului si timpului"
						},
						{
							"_nr": "14",
							"__text": "Multimedia"
						},
						{
							"_nr": "15",
							"__text": "Exemple de programe realizate utilizand biblioteca de functii CONTI"
						},
						{
							"_nr": "16",
							"__text": "Instalarea bibliotecii CONTI.LIB si utilizarea ei in proiectele de aplicatie"
						},
						{
							"_nr": "17",
							"__text": "Memento pentru constantele si variabile globale CONTI"
						},
						{
							"_nr": "18",
							"__text": "Memento pentru functiile CONTI"
						}
					]
				},
				"_id": "391",
				"_isbn": "973-9229-49-2"
			},
			{
				"titlu": "ActiveX. Concepte si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Mihai Cioata"
					}
				},
				"anAparitie": "2003",
				"editura": "Polirom",
				"numarPagini": "200",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Cezar Petrila"
				},
				"coperta": {
					"imagine": {
						"_path": "ActiveX_Concepte_si_aplicatii_Cioata.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "COM, OLE si ActiveX"
						},
						{
							"_nr": "II",
							"__text": "Introducere in Active Template Library"
						},
						{
							"_nr": "III",
							"__text": "Controale ActiveX"
						},
						{
							"_nr": "IV",
							"__text": "Studii de caz"
						},
						{
							"_nr": "In loc de epilog",
							"__text": "COM, ActiveX - viitor sau sfarsit"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Crearea unui control ActiveX prin utilizarea MFC ActiveX Control Wizard"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Crearea unui control ActiveX prin utilizarea  aTL COM Object Wizard"
						}
					]
				},
				"_id": "392",
				"_isbn": "973-681-268-5"
			},
			{
				"titlu": "Bazele programarii in C: aplicatii",
				"autori": {
					"autor": {
						"_nume": "Doina Logofatu"
					}
				},
				"anAparitie": "2006",
				"editura": "Polirom",
				"numarPagini": "408",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Madalina Iordache"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Ghetau"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_programarii_in_C_Aplicatii_Logofatu.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Evolutia sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi - elemente definitorii"
						},
						{
							"_nr": "3",
							"__text": "Limbajul C - prezentare generala"
						},
						{
							"_nr": "4",
							"__text": "Alogoritmi elementari"
						},
						{
							"_nr": "5",
							"__text": "Tabloruri, pointeri, pointeri la functii"
						},
						{
							"_nr": "6",
							"__text": "Siruri de caractere. Operatii cu fisiere"
						},
						{
							"_nr": "7",
							"__text": "Structuri, uniuni, campuri de biti"
						},
						{
							"_nr": "8",
							"__text": "Operatii pe biti"
						},
						{
							"_nr": "9",
							"__text": "Functii de timp, numere aleatorii"
						},
						{
							"_nr": "10",
							"__text": "Structuri cu autoreferire"
						},
						{
							"_nr": "11",
							"__text": "Grafuri, tehnici de programare"
						},
						{
							"_nr": "12",
							"__text": "Probleme de concurs"
						},
						{
							"_nr": "13",
							"__text": "Aplicatie: numere mari"
						},
						{
							"_nr": "14",
							"__text": "Aplicatie: fractali space-feeling"
						}
					]
				},
				"_id": "393",
				"_isbn": "973-46-0219-5"
			},
			{
				"titlu": "Retele neuronale. Teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "D. Dumitrescu"
						},
						{
							"_nume": "Hariton Costin"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "464",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"coperta": {
					"imagine": {
						"_path": "Retele_neuronale_Teorie_si_aplicatii_Dumitrescu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Procese de invatare in sitemele cu inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Elemente de neurodinamica"
						},
						{
							"_nr": "3",
							"__text": "Modelul perceptronului"
						},
						{
							"_nr": "4",
							"__text": "Instruiere prin minimizarea erorilor patratice"
						},
						{
							"_nr": "5",
							"__text": "Retele asociative"
						},
						{
							"_nr": "6",
							"__text": "Optimizare combinatoriala. Algoritmul de calire simulata"
						},
						{
							"_nr": "7",
							"__text": "Masina Boltzmann"
						},
						{
							"_nr": "8",
							"__text": "Propagarea inapoi a erorii"
						},
						{
							"_nr": "9",
							"__text": "Multimi nuantate"
						},
						{
							"_nr": "10",
							"__text": "Intruire supervizata si nesupervizata cu multimi nuantate"
						},
						{
							"_nr": "11",
							"__text": "Invatare nesupervizata. Sisteme cu auto-organizare"
						},
						{
							"_nr": "12",
							"__text": "Arhitecturi moderne de retele neuronale"
						},
						{
							"_nr": "13",
							"__text": "Principii de calcul evolutiv"
						},
						{
							"_nr": "14",
							"__text": "Aplicatii ale retelelor neuronale"
						}
					]
				},
				"_id": "394",
				"_isbn": "973-601-461-4"
			},
			{
				"titlu": "Proiectarea obiectuala a sistemelor informatice",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Zaharie"
						},
						{
							"_nume": "Ioan Rosca"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Dual Tech",
				"numarPagini": "344",
				"pretOriginal": "375000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_obiectuala_a_sistemelor_informatice_Zaharie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in abordarea obiectuala"
						},
						{
							"_nr": "2",
							"__text": "Limbajul unificat de modelare (UML)"
						},
						{
							"_nr": "3",
							"__text": "Modelarea cazurilor de utilizare (MCU)"
						},
						{
							"_nr": "4",
							"__text": "Modelarea structruii claselor si obiectelor"
						},
						{
							"_nr": "5",
							"__text": "Modelarea comportamentului"
						},
						{
							"_nr": "6",
							"__text": "Modelarea arhitecturii"
						},
						{
							"_nr": "7",
							"__text": "Obiectele de gestiune"
						},
						{
							"_nr": "8",
							"__text": "Persistenta"
						},
						{
							"_nr": "9",
							"__text": "Interfata cu utilizatorii"
						},
						{
							"_nr": "10",
							"__text": "Sabloane si arhitecturi"
						},
						{
							"_nr": "11",
							"__text": "Procesul de dezvoltare"
						},
						{
							"_nr": "12",
							"__text": "Studiu de caz"
						}
					]
				},
				"_id": "395",
				"_isbn": "973-85525-4-0"
			},
			{
				"titlu": "Modele de aplicatii practice in Microsoft Excel si Visual FoxPro",
				"autori": {
					"autor": [
						{
							"_nume": "Dinu Airinei"
						},
						{
							"_nume": "Iulian Oprea"
						},
						{
							"_nume": "Denisa Neagu"
						},
						{
							"_nume": "Diana Genete"
						},
						{
							"_nume": "Octavian Dospinescu"
						},
						{
							"_nume": "Daniela Rusu"
						},
						{
							"_nume": "Daniel Pavaloaia"
						},
						{
							"_nume": "Florian Sarbu"
						},
						{
							"_nume": "Ionela Bacain"
						},
						{
							"_nume": "Valerica Greavu-Serban"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Sedcom Libris",
				"numarPagini": "404",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Petru Radu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modele_de_aplicatii_practice_in_Microsoft_Excel_si_Visual_FoxPro_Airinei.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Aplicatii practice in Microsoft Excel"
						},
						{
							"_nr": "1",
							"__text": "Categorii de functii"
						},
						{
							"_nr": "2",
							"__text": "Date de date si baele de simulare"
						},
						{
							"_nr": "3",
							"__text": "Reprezentari grafice"
						},
						{
							"_nr": "II",
							"__text": "Aplicatii practice in Visual  FoxPro"
						},
						{
							"_nr": "4",
							"__text": "Prezentarea mediului de lucru"
						},
						{
							"_nr": "5",
							"__text": "Crearea bazelode de date"
						},
						{
							"_nr": "6",
							"__text": "Modul de lucru direct"
						},
						{
							"_nr": "7",
							"__text": "Actualizarea bazelor de date in modul de lucru asistat"
						},
						{
							"_nr": "8",
							"__text": "Actualizarea si interogarea bazelor de date in modul de lucru direct"
						},
						{
							"_nr": "9",
							"__text": "Modul de lucru program"
						},
						{
							"_nr": "10",
							"__text": "Comanda SELECT-SQL"
						},
						{
							"_nr": "11",
							"__text": "Crearea si utilizarea rapoartelor"
						},
						{
							"_nr": "12",
							"__text": "Meniuri"
						}
					]
				},
				"_id": "396",
				"_isbn": "973-8353-64-5"
			},
			{
				"titlu": "Programarea orientata pe obiecte: limbajul Java",
				"autori": {
					"autor": {
						"_nume": "Ioan Jurca"
					}
				},
				"anAparitie": "2005",
				"editura": "Editura de Vest",
				"tiraj": "400",
				"numarPagini": "312",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpia Marginean"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_orientata_pe_obicte_Limbajul_Java_Jurca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Clase si obiecte"
						},
						{
							"_nr": "3",
							"__text": "Mostenire si polimorfism"
						},
						{
							"_nr": "4",
							"__text": "Interfete"
						},
						{
							"_nr": "5",
							"__text": "Clase si interfete incuibate"
						},
						{
							"_nr": "6",
							"__text": "Exceptii si asertiuni"
						},
						{
							"_nr": "7",
							"__text": "Pachete"
						},
						{
							"_nr": "8",
							"__text": "Operatii de intrare/iesire"
						},
						{
							"_nr": "9",
							"__text": "Colectii"
						},
						{
							"_nr": "10",
							"__text": "Programare concurenta in Java"
						},
						{
							"_nr": "11",
							"__text": "Interfete grafice (GUI)"
						}
					]
				},
				"_id": "397",
				"_isbn": "973-36-0410-0"
			},
			{
				"titlu": "Ghidul bobocului pentru CorelDRAW!",
				"autori": {
					"autor": [
						{
							"_nume": "Jenna Howard"
						},
						{
							"_nume": "Michael Howard"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "384",
				"pretOriginal": "17900",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "124",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The complete idiot's guide to CorelDraw!",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Catrinel Albert"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Liniana Dabuleanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Vladulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_CorelDraw_Howard.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Chiar si un copil poate desena asta"
						},
						{
							"_nr": "II",
							"__text": "Se mai poate desena ceva?"
						},
						{
							"_nr": "III",
							"__text": "Chestiuni legate de texte"
						},
						{
							"_nr": "IV",
							"__text": "Intr-un fel, aici avem chestii de groaza"
						},
						{
							"_nr": "V",
							"__text": "Mai putine, dar nu mai putin importante: programe auxiliare"
						},
						{
							"_nr": "VI",
							"__text": "Peste tot dai de logistica"
						}
					]
				},
				"_id": "398",
				"_isbn": "973-601-332-4"
			},
			{
				"titlu": "Quattro Pro 6 (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Joe Kraynak"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "168",
				"pretOriginal": "6000",
				"colectia": {
					"_numarul": "63",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 minute guide to Quattro Pro for Windows 6",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Alexandra Nanu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Quattro_Pro_6_in_lectii_de_10_minute_Kraynak.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Cum se lanseaza si cum se iese din programul Quattro Pro"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Deplasarea in cadrul unei ferestre Quattro Pro"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Introducerea etichetelor, valorilor si datelor calendaristice"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Introducerea datelor"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Introducerea functiilor"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Salvarea inchiderea si deschiderea registrelor de calcul"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Selectarea si denumirea celulelor"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Editarea celulelor"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Controlul coloanelor si liniilor"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Imbunatatirea modului de prezentare al etichetelor si valorilor"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Adaugarea chenarelor si umbrelor celulelor si blocurilor de celule"
						},
						{
							"_nr": "Lectia 12",
							"__text": "Aranjarea cu ajutorul stilurilor si a optiunii SppedFormat (Aranjare Rapida)"
						},
						{
							"_nr": "Lectia 13",
							"__text": "Modificarea parametrilor de pagina pentru tiparire"
						},
						{
							"_nr": "Lectia 14",
							"__text": "Tiparirea foii de calcul"
						},
						{
							"_nr": "Lectia 15",
							"__text": "Lucrul cu paginile registrului de clacul"
						},
						{
							"_nr": "Lectia 16",
							"__text": "Crearea graficelor"
						},
						{
							"_nr": "Lectia 17",
							"__text": "Imbunatirea si tiparirea unui graf"
						},
						{
							"_nr": "Lectia 18",
							"__text": "Crearea unei prezentari cu \"diapozitive\""
						},
						{
							"_nr": "Lectia 19",
							"__text": "Crearea unei baze de date"
						},
						{
							"_nr": "Lectia 20",
							"__text": "Sortarea si cautarea intr-o baza de date"
						},
						{
							"_nr": "Lectia 21",
							"__text": "Utilizarea instrumentelor pentru finante personale"
						},
						{
							"_nr": "Lectia 22",
							"__text": "Analiza datelor cu modele de date"
						},
						{
							"_nr": "Lectia 23",
							"__text": "Utilizarea scenariilor pentru estimarea rezultatelor"
						},
						{
							"_nr": "Lectia 24",
							"__text": "Automatizarea activatilor cu ajutorul comenzilor compuse"
						}
					]
				},
				"_id": "399",
				"_isbn": "973-601-250-6"
			},
			{
				"titlu": "Manual de informatica pentru clasa a IX-a. Sistem de calcul",
				"autori": {
					"autor": {
						"_nume": "Mariana Milosescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "288",
				"pretOriginal": "30000",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_calcul_Manual_IX_Milosescu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistem de calcul"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura generala a unui sistem de calcul"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "4",
							"__text": "Prezentarea comenzilor Norton Commander"
						},
						{
							"_nr": "5",
							"__text": "Utilitare"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Codul ASCII"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Ce este un fisier?"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Ce sunt programele de aplicatie?"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Norton Commander"
						}
					]
				},
				"_id": "400",
				"_isbn": "973-601-715-X"
			},
			{
				"titlu": "WordPerfect pentru secretariat si corespondenta de afaceri",
				"autori": {
					"autor": [
						{
							"_nume": "Traian Surcel"
						},
						{
							"_nume": "Gheorghe Sofronie"
						},
						{
							"_nume": "Stefan Preda"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Calipso-2000",
				"numarPagini": "152",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Stefan Preda"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Stefan Preda",
						"Mariana Oprescu",
						"Mariana Frincu",
						"Cecilia Mitrea",
						"Diana Olaru"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "WordPerfect_pentru_secretariat_si_corespondenta_de_afaceri_Surcel.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Sesiunea de lucru WP5.1"
						},
						{
							"_nr": "3",
							"__text": "Dactilografierea unui text"
						},
						{
							"_nr": "4",
							"__text": "Scrisoarea de afaceri"
						},
						{
							"_nr": "5",
							"__text": "Memorandum-ul"
						},
						{
							"_nr": "6",
							"__text": "Corectii intr-un text"
						},
						{
							"_nr": "7",
							"__text": "Documente de mai multe pagini"
						},
						{
							"_nr": "8",
							"__text": "Procesul verbal-minuta"
						},
						{
							"_nr": "9",
							"__text": "Fax-ul"
						},
						{
							"_nr": "10",
							"__text": "Evidenta fisierelor pe disc"
						},
						{
							"_nr": "11",
							"__text": "Oferta catre mai multi clienti"
						},
						{
							"_nr": "12",
							"__text": "Sortarea unei liste"
						},
						{
							"_nr": "13",
							"__text": "Tabele"
						},
						{
							"_nr": "14",
							"__text": "Scrierea pe coloane"
						},
						{
							"_nr": "15",
							"__text": "Lucru simultan cu doua documente"
						},
						{
							"_nr": "16",
							"__text": "Editorul de ecuatii"
						},
						{
							"_nr": "17",
							"__text": "Grafica"
						},
						{
							"_nr": "18",
							"__text": "Intefata grafica Windows pentru WordPerfect 6.0"
						}
					]
				},
				"_id": "401",
				"_isbn": "973-97077-0-X"
			},
			{
				"titlu": "Cum imi aleg un calculator (Editia I)",
				"autori": {
					"autor": {
						"_nume": "Robert Badea"
					}
				},
				"anAparitie": "2003",
				"editura": "Cartea de buzunar",
				"numarPagini": "96",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "55",
					"_nume": "Practic IT"
				},
				"redactori": {
					"redactor": "Violeta Lungu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Adriana-Paula Arsenie"
				},
				"coperta": {
					"imagine": {
						"_path": "Cum_imi_aleg_un_calculator_Badea.jpg"
					},
					"__text": "Tinel Folea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cuvant catre cititor"
						},
						{
							"_nr": "2",
							"__text": "Faceti cunostinta:el va fi PC-ul tau"
						},
						{
							"_nr": "3",
							"__text": "In care lasam principiile, punem mana pe lupa"
						},
						{
							"_nr": "4",
							"__text": "Capitol pentru pedanti - partea practica a lucrurilor"
						},
						{
							"_nr": "5",
							"__text": "Cum sa alegem"
						},
						{
							"_nr": "6",
							"__text": "Despre software"
						},
						{
							"_nr": "7",
							"__text": "Atentie la neatentie"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Evolutia procesoarelor"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Diagrama von Neuman, actualizata"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Tabel pentru intocmirea unui configuratii"
						},
						{
							"_nr": "Glosar"
						}
					]
				},
				"_id": "402",
				"_isbn": "973-8351-36-7"
			},
			{
				"titlu": "Ingineria cunoasterii",
				"autori": {
					"autor": {
						"_nume": "Edmond Nicolau"
					}
				},
				"anAparitie": "1985",
				"editura": "Albatros",
				"numarPagini": "338",
				"pretOriginal": "12.50",
				"pretCumparare": "10000",
				"tehnoredactori": {
					"tehnoredactor": "Lucia Iliupolos"
				},
				"coperta": {
					"imagine": {
						"_path": "Ingineria_cunoasterii_Nicolau.jpg"
					},
					"__text": "Gheorghe Marinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Cunoasterea de sine"
						},
						{
							"_nr": "II",
							"__text": "Masinile de gandit"
						},
						{
							"_nr": "III",
							"__text": "Instrumentele cunoasterii"
						},
						{
							"_nr": "IV",
							"__text": "Quo vadis homine?"
						}
					]
				},
				"_id": "403",
				"_isbn": ""
			},
			{
				"titlu": "Programarea patratica. Introducere in programarea convexa",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Dragomirescu"
						},
						{
							"_nume": "Mircea Malita"
						}
					]
				},
				"anAparitie": "1968",
				"editura": "Stiintifica",
				"tiraj": "2700+140+20 ex legate",
				"numarPagini": "394",
				"pretOriginal": "14.50",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Eugenia Teodorof"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_patratica_Introducere_in_programarea_convexa_Malita.jpg"
					},
					"__text": "Harry Guttman"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Preliminarii matematice"
						},
						{
							"_nr": "II",
							"__text": "Programare liniara"
						},
						{
							"_nr": "III",
							"__text": "Introducere in programarea convexa"
						},
						{
							"_nr": "IV",
							"__text": "Programare patratica"
						},
						{
							"_nr": "V",
							"__text": "Algoritmul lui Beale"
						},
						{
							"_nr": "VI",
							"__text": "Algoritmul simplex pentru programarea patratica"
						},
						{
							"_nr": "VII",
							"__text": "Algoritmul Hildreth-D'Esopo"
						},
						{
							"_nr": "VIII",
							"__text": "Algoritmul Theil si Van De Panne"
						},
						{
							"_nr": "IX",
							"__text": "Programare patratica, parametrica. Stabilitate"
						},
						{
							"_nr": "X",
							"__text": "Programare patratica in numere intregi"
						},
						{
							"_nr": "XI",
							"__text": "Metoda planului de sectiune"
						},
						{
							"_nr": "XII",
							"__text": "Aplicatii ale programarii convexe"
						}
					]
				},
				"_id": "404",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in lingvistica matematica",
				"autori": {
					"autor": [
						{
							"_nume": "Solomon Marcus"
						},
						{
							"_nume": "Edmond Nicolau"
						},
						{
							"_nume": "Sorin Stati"
						}
					]
				},
				"anAparitie": "1966",
				"editura": "Stiintifica",
				"tiraj": "5500",
				"numarPagini": "336",
				"pretOriginal": "9.75",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Intoducere_in_lingvistica_matematica_Marcus.jpg"
					},
					"__text": "T. Mironescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Lingvistica structurala"
						},
						{
							"_nr": "II",
							"__text": "Privire generala asupra lingvisticii matematice"
						},
						{
							"_nr": "III",
							"__text": "Modele matematice in lingvistica"
						},
						{
							"_nr": "IV",
							"__text": "Modelarea algoritmica a limbii"
						},
						{
							"_nr": "V",
							"__text": "Limbi informationale"
						},
						{
							"_nr": "VI",
							"__text": "Limbajul cosmic"
						},
						{
							"_nr": "VII",
							"__text": "Traducere automata"
						},
						{
							"_nr": "VIII",
							"__text": "Probabilitate, informatie si entropie in limba"
						},
						{
							"_nr": "IX",
							"__text": "Alte metode statistice in lingvistica"
						},
						{
							"_nr": "X",
							"__text": "Limba si cod"
						},
						{
							"_nr": "XI",
							"__text": "Recunoasterea autmata a formelor"
						}
					]
				},
				"_id": "405",
				"_isbn": ""
			},
			{
				"titlu": "Poetica matematica",
				"autori": {
					"autor": {
						"_nume": "Solomon Marcus"
					}
				},
				"anAparitie": "1970",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "400",
				"pretOriginal": "23",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria-Eugenia Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Poetica_matematica_Marcus.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Preliminarii"
						},
						{
							"_nr": "II",
							"__text": "Opozitii intre limbajul stiintific si cel poetic"
						},
						{
							"_nr": "III",
							"__text": "Un limbaj pur denotativ: limbajul matematic"
						},
						{
							"_nr": "IV",
							"__text": "Modelarea matematica a opozitiei dintre limbajul poetic si limbajul stiintific"
						},
						{
							"_nr": "V",
							"__text": "Figuri poetice"
						},
						{
							"_nr": "VI",
							"__text": "Aspecte probabilistice si informationale ale limbajului poetic"
						},
						{
							"_nr": "VII",
							"__text": "Analiza comparativa a textelor poetice"
						},
						{
							"_nr": "VIII",
							"__text": "Metode matematice in studiul teatrului"
						},
						{
							"_nr": "Anexa"
						}
					]
				},
				"_id": "406",
				"_isbn": ""
			},
			{
				"titlu": "Semiotica matematica a artelor vizuale",
				"autori": {
					"autor": [
						{
							"_nume": "Solomon Marcus"
						},
						{
							"_nume": "Adina Caloenescu"
						},
						{
							"_nume": "Mihaela Petrache"
						},
						{
							"_nume": "Razvan Andonie"
						},
						{
							"_nume": "Adrian Marian"
						},
						{
							"_nume": "Vladimir Marina"
						},
						{
							"_nume": "Anca Manolescu"
						},
						{
							"_nume": "Grigore Arbore"
						},
						{
							"_nume": "Marian Gheorghe"
						},
						{
							"_nume": "Theodor Redlow"
						},
						{
							"_nume": "Gabriela Ghioca"
						},
						{
							"_nume": "Alexandru Botta"
						},
						{
							"_nume": "Cik Damadian"
						},
						{
							"_nume": "Doina Gheorghe"
						},
						{
							"_nume": "Gheorghe Paun"
						},
						{
							"_nume": "Sorin Istrail"
						},
						{
							"_nume": "Liviu Olaru"
						},
						{
							"_nume": "Mircea Andrasiu"
						},
						{
							"_nume": "Adriana Dobra"
						},
						{
							"_nume": "Mihai Jalobeanu"
						},
						{
							"_nume": "Victor Mercea"
						},
						{
							"_nume": "Adrian Rogoz"
						},
						{
							"_nume": "P. Teodorescu-Brinzeu"
						},
						{
							"_nume": "Mircea Mihaies"
						},
						{
							"_nume": "Mihai Bredicenu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si Enciclopedica",
				"numarPagini": "412",
				"pretOriginal": "47",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Semiotica_matematica_a_artelor_vizuale_Marcus.jpg"
					},
					"__text": "Cik Damadian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Pictura"
						},
						{
							"_nr": "3",
							"__text": "Sculptura"
						},
						{
							"_nr": "4",
							"__text": "Istoria artei"
						},
						{
							"_nr": "5",
							"__text": "Arhitecuta"
						},
						{
							"_nr": "6",
							"__text": "Semiotica spatiului"
						},
						{
							"_nr": "7",
							"__text": "Arta decorativa"
						},
						{
							"_nr": "8",
							"__text": "Grafica pe calculator"
						},
						{
							"_nr": "9",
							"__text": "Text si imagine"
						},
						{
							"_nr": "10",
							"__text": "Timpul si vizualul"
						},
						{
							"_nr": "11",
							"__text": "Semiotica scrierii"
						}
					]
				},
				"_id": "407",
				"_isbn": ""
			},
			{
				"titlu": "Tips and Tricks. Jocuri pe calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Mihaela Carstea"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Communication Publishing Group SRL",
				"numarPagini": "224",
				"pretOriginal": "2200",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Cristina Manoiu",
						"Mihai Chivaran"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Tips_si_Tricks_Jocuri_pe_calculator_Diamandi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tips and Tricks - jocuri logice"
						},
						{
							"_nr": "2",
							"__text": "Tips and Tricks - Spectrum HC"
						},
						{
							"_nr": "3",
							"__text": "Tips and Tricks - PC"
						},
						{
							"_nr": "4",
							"__text": "Tips and Tricks Macintosh"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Lista jocurilor"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Lista jocurilor tips and tricks-urilor"
						}
					]
				},
				"_id": "408",
				"_isbn": ""
			},
			{
				"titlu": "Windows Server 2003 pentru Administratori - ghid practic",
				"autori": {
					"autor": [
						{
							"_nume": "Nelson Ruest"
						},
						{
							"_nume": "Danielle Rust"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "All",
				"numarPagini": "312",
				"pretOriginal": "249000",
				"pretCumparare": "249000",
				"traducere": {
					"titluOriginal": "Microsoft Server 2003 Pocket Administrator",
					"editura": "McGraw-Hill/Osborne",
					"traducatori": {
						"traducator": "Ovidiu Slavu"
					},
					"_an": "2003",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Marcel Oaida"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_Server_2003_pentru_administratori_Ruest.jpg"
					},
					"__text": "Stelian Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Administrarea generala a serverului"
						},
						{
							"_nr": "2",
							"__text": "Administrarea serverelor de fisiere si de printare"
						},
						{
							"_nr": "3",
							"__text": "Administrarea serverelor pentru infrastructura de retea"
						},
						{
							"_nr": "4",
							"__text": "Administrarea serverelor de identitate"
						},
						{
							"_nr": "5",
							"__text": "Administrarea serverelor de aplicatii"
						}
					]
				},
				"_id": "409",
				"_isbn": "973-571-505-8"
			},
			{
				"titlu": "Din tainele programarii in C++",
				"autori": {
					"autor": {
						"_nume": "Bujor Silaghi"
					}
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "292",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "61",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Din_tainele_programarii_in_cpp_Silaghi.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Elemente de limbaj"
						},
						{
							"_nr": "3",
							"__text": "Modificatori si operatori"
						},
						{
							"_nr": "4",
							"__text": "Preprocesarea si mecanismul template"
						},
						{
							"_nr": "5",
							"__text": "Tratarea erorilor si procesarile run-time"
						}
					]
				},
				"_id": "410",
				"_isbn": "973-9215-33-5"
			},
			{
				"titlu": "Situri Web la cheie: solutii profesionale de implementare",
				"autori": {
					"autor": [
						{
							"_nume": "Sabiun Corneliu Buraga"
						},
						{
							"_nume": "Viorel Atofani"
						},
						{
							"_nume": "Sorin Bahmata"
						},
						{
							"_nume": "Marian Bostan"
						},
						{
							"_nume": "Catalin Bulancea"
						},
						{
							"_nume": "Andrei Croitoru"
						},
						{
							"_nume": "Adrian Lazariuc"
						},
						{
							"_nume": "Bogdan Manolache"
						},
						{
							"_nume": "Daniel Puiu"
						},
						{
							"_nume": "Casmin Varlan"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "368",
				"pretOriginal": "9",
				"pretCumparare": "39900",
				"redactori": {
					"redactor": "Madalina Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Situri_Web_la_cheie_Solutii_profesionale_de_implementare_Buraga.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Romania in Flash"
						},
						{
							"_nr": "II",
							"__text": "De la Flash la PHP. Dezvoltarea unui forum Web"
						},
						{
							"_nr": "III",
							"__text": "Portaluri educationale"
						},
						{
							"_nr": "IV",
							"__text": "Medelarea si reprezentarea relatiilor dintre tabele"
						},
						{
							"_nr": "V",
							"__text": "Noua provocare 2D - SVG"
						},
						{
							"_nr": "VI",
							"__text": "FindMyHome, o solutie e-business in domeniul imobiliar"
						},
						{
							"_nr": "VII",
							"__text": "Managementul blog-urilor in Perl"
						},
						{
							"_nr": "VIII",
							"__text": "Hattrick - simularea meciurilor de fotbal pe Web"
						},
						{
							"_nr": "IX",
							"__text": "De la desktop la dispozitive wireless"
						},
						{
							"_nr": "Anexa I",
							"__text": "ShockWave Flash (.SWF) versuk Scalable Vector Graphics (.SVG)"
						},
						{
							"_nr": "Anexa II",
							"__text": "PHP versul ASP.NET"
						}
					]
				},
				"_id": "411",
				"_isbn": "973-681-785-7"
			},
			{
				"titlu": "Structuri de data si algoritmi: aplicatii in C++ folosind STL",
				"autori": {
					"autor": [
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Cristian Opincaru"
						},
						{
							"_nume": "Corin Stratan"
						},
						{
							"_nume": "Alexandru Chirita"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Polirom",
				"numarPagini": "352",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Madalina Iordache"
				},
				"tehnoredactori": {
					"tehnoredactor": "Lucian Pavel"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_si_algoritmi_Aplicatii_in_Cpp_folosind_STL_Iorga.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Complexitatea algoritmilor"
						},
						{
							"_nr": "2",
							"__text": "Recursivitate. Divide et impera"
						},
						{
							"_nr": "3",
							"__text": "Metoda optimului local (greedy)"
						},
						{
							"_nr": "4",
							"__text": "Stive"
						},
						{
							"_nr": "5",
							"__text": "Cozi"
						},
						{
							"_nr": "6",
							"__text": "Liste"
						},
						{
							"_nr": "7",
							"__text": "Arbori binari"
						},
						{
							"_nr": "8",
							"__text": "Containere asociative (multimi, dictionare, tabele de dispersie)"
						},
						{
							"_nr": "9",
							"__text": "Backtraking"
						},
						{
							"_nr": "10",
							"__text": "Grafuri"
						},
						{
							"_nr": "11",
							"__text": "Programare dinamica"
						}
					]
				},
				"_id": "412",
				"_isbn": "973-681-872-1"
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": {
						"_nume": "Elena-Liliana Popescu"
					}
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "390",
				"pretOriginal": "265000",
				"pretCumparare": "265000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Nicolae Gosoniu",
						"Vicentiu Rusu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Algoritmi"
						},
						{
							"_nr": "II",
							"__text": "Bazele algebrice ale informaticii"
						},
						{
							"_nr": "III",
							"__text": "Arhitectura calculatoarelor"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "V",
							"__text": "Limbajul de programare Pascal"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Codul ASCII"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Sintaxa limbajului Pascal - BNF"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Diagrame sntactice"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Functii si Proceduri standard"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Mic dictionar de termeni informatici"
						}
					]
				},
				"_id": "413",
				"_isbn": "973-575-667-6"
			},
			{
				"titlu": "Proiectarea bazelor de date",
				"autori": {
					"autor": [
						{
							"_nume": "Ileana Popescu"
						},
						{
							"_nume": "Letitia Velcescu"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "316",
				"pretOriginal": "330000",
				"pretCumparare": "330000",
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_bazelor_de_date_Popescu.jpg"
					},
					"__text": "Mihail-Alexandru Velcescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati despre bazele de date"
						},
						{
							"_nr": "2",
							"__text": "Modelarea entitate-relatie"
						},
						{
							"_nr": "3",
							"__text": "Proiectarea bazelor de date relationale"
						},
						{
							"_nr": "4",
							"__text": "Normalizarea relatiilor"
						},
						{
							"_nr": "5",
							"__text": "Limbaje pentru prelucrarea datelor relationale"
						},
						{
							"_nr": "6",
							"__text": "Concurenta in baze de date relationale"
						},
						{
							"_nr": "7",
							"__text": "Modelarea bazelor de date distribuite"
						}
					]
				},
				"_id": "414",
				"_isbn": "978-973-737-418-9"
			},
			{
				"titlu": "Structuri de date si algoritmi. Aspecte matematice si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Rodica Ceterchi"
					}
				},
				"anAparitie": "2001",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "180",
				"pretOriginal": "200000",
				"pretCumparare": "200000",
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_si_algoritmi_Aspecte_matematice_si_aplicatii_Ceterchi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Structuri liniare"
						},
						{
							"_nr": "II",
							"__text": "Structuri arborescente"
						},
						{
							"_nr": "III",
							"__text": "Sortari interne"
						},
						{
							"_nr": "IV",
							"__text": "Arbori binari stricti. Aplicatii"
						}
					]
				},
				"_id": "415",
				"_isbn": "973-575-594-7"
			},
			{
				"titlu": "Neobrazatul, Magnificul Java",
				"autori": {
					"autor": {
						"_nume": "Brian D. Eubanks"
					}
				},
				"anAparitie": "2008",
				"editura": "Bastion",
				"numarPagini": "224",
				"pretOriginal": "180000",
				"pretCumparare": "144000",
				"traducere": {
					"titluOriginal": "Wicked Cool Java: code bits, open-source libraries, and project ideas",
					"editura": "No Starch Press",
					"traducatori": {
						"traducator": "Vasile Mitu"
					},
					"_an": "2004",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Neograzatul_magnificul_Java_Eubanks.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajul Java si nucleul API"
						},
						{
							"_nr": "2",
							"__text": "Utilitarele String"
						},
						{
							"_nr": "3",
							"__text": "Prelucrarea formatelor XML si HTML"
						},
						{
							"_nr": "4",
							"__text": "Sa ne cataram pe reteaua semantica"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii stiintifice si matematice"
						},
						{
							"_nr": "6",
							"__text": "Grafica si vizualizarea datelor"
						},
						{
							"_nr": "7",
							"__text": "Multimedia si sincronizarea"
						},
						{
							"_nr": "8",
							"__text": "Distractie, integrare si idei de proiecte"
						}
					]
				},
				"_id": "416",
				"_isbn": "978-973-88610-5-3"
			},
			{
				"titlu": "Introducere in informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Coman"
						},
						{
							"_nume": "Militon Frentiu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Dacia",
				"numarPagini": "216",
				"pretOriginal": "8.50",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_informatica_Coman.jpg"
					},
					"__text": "Tiotiu Epaminonda"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Bazele aritmetice ale calculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Organizarea generala a calculatorului FELIX C-256"
						},
						{
							"_nr": "4",
							"__text": "Notiuni de teoria algoritmilor"
						},
						{
							"_nr": "5",
							"__text": "Limbajul Fortran"
						},
						{
							"_nr": "6",
							"__text": "Asupra unori metode de programare"
						},
						{
							"_nr": "7",
							"__text": "Bibligrafie"
						}
					]
				},
				"_id": "417",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in Haskell 98 prin exemple",
				"autori": {
					"autor": {
						"_nume": "Dan Popa"
					}
				},
				"anAparitie": "2007",
				"editura": "EduSoft",
				"numarPagini": "230",
				"pretOriginal": "50000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_Haskell_98_prin_exemple_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Functii si aritmetica"
						},
						{
							"_nr": "2",
							"__text": "Pe urmele functiilor care manipuleaza functii"
						},
						{
							"_nr": "3",
							"__text": "Cat mai multe despre tipuri si crearea lor"
						},
						{
							"_nr": "4",
							"__text": "Structuri de date infinite si alte notiuni utile"
						},
						{
							"_nr": "5",
							"__text": "Reprezentarea unui limbaj in Haskell"
						},
						{
							"_nr": "6",
							"__text": "Monada"
						},
						{
							"_nr": "7",
							"__text": "Manode utilizate de programatori"
						},
						{
							"_nr": "8",
							"__text": "Studiu de caz: evaluatorul de expresii"
						}
					]
				},
				"_id": "418",
				"_isbn": "978-973-8934-48-1"
			},
			{
				"titlu": "Constructia compilatoarelor folosind Flex si Bison",
				"autori": {
					"autor": [
						{
							"_nume": "Anthnoy A. Aaby"
						},
						{
							"_nume": "Dan Popa"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "EduSoft",
				"numarPagini": "110",
				"pretOriginal": "50000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Constructia_compilatoarelor_folosind_Flex_si_Bison_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Analizatorul sintactic (Parser-ul)"
						},
						{
							"_nr": "3",
							"__text": "Scaner-ul (Analizatorul lexical)"
						},
						{
							"_nr": "4",
							"__text": "Contextul"
						},
						{
							"_nr": "5",
							"__text": "Optimizarea"
						},
						{
							"_nr": "6",
							"__text": "Masini virtuale"
						},
						{
							"_nr": "7",
							"__text": "Generarea codului"
						},
						{
							"_nr": "8",
							"__text": "Optimizarea de cod dupa generare"
						},
						{
							"_nr": "9",
							"__text": "Lecturi suplimentare"
						},
						{
							"_nr": "10",
							"__text": "Exercitii"
						},
						{
							"_nr": "Anaxa A",
							"__text": "Limbajul Simple - Implementare completa"
						},
						{
							"_nr": "Anexa B",
							"__text": "Instalarea programelor Flex si Bison"
						}
					]
				},
				"_id": "419",
				"_isbn": "978-973-87714-6-3"
			},
			{
				"titlu": "Programarea web...altfel",
				"autori": {
					"autor": {
						"_nume": "Alexandru Deva"
					}
				},
				"anAparitie": "2007",
				"editura": "EduSoft",
				"numarPagini": "170",
				"pretOriginal": "140000",
				"pretCumparare": "140000",
				"coperta": {
					"imagine": {
						"_path": "Programarea_Web_altfel_Deva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "De ce altfel?"
						},
						{
							"_nr": "3",
							"__text": "Programarea Web 'stas'"
						},
						{
							"_nr": "4",
							"__text": "Web 2.0 - Mostenitorul de drept"
						},
						{
							"_nr": "5",
							"__text": "Flash si SOAP - Sapun si chirpici"
						},
						{
							"_nr": "6",
							"__text": "Perl - 1000 de forme ale poeziei"
						},
						{
							"_nr": "7",
							"__text": "Ruby - Giuvaierul de pe Web"
						},
						{
							"_nr": "8",
							"__text": "Extrem de...altfel!"
						},
						{
							"_nr": "9",
							"__text": "Incheiere"
						}
					]
				},
				"_id": "420",
				"_isbn": "978-973-8934-65-8"
			},
			{
				"titlu": "Applet-uri Java. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Camelia M. Pintea"
						},
						{
							"_nume": "Gabriel Negara"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "EduSoft",
				"numarPagini": "102",
				"pretOriginal": "130000",
				"pretCumparare": "130000",
				"coperta": {
					"imagine": {
						"_path": "Appleturi_Java_Aplicatii_Pintea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Applet-uri Java"
						},
						{
							"_nr": "2",
							"__text": "Aplicatii grafice"
						},
						{
							"_nr": "3",
							"__text": "Evenimente in aplicatii"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii diverse"
						}
					]
				},
				"_id": "421",
				"_isbn": "978-973-8934-63-4"
			},
			{
				"titlu": "Programarea in Java 2 Micro Edition",
				"autori": {
					"autor": {
						"_nume": "Ionut David"
					}
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "168",
				"pretOriginal": "140000",
				"pretCumparare": "140000",
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Java_2_Micro_Edition_David.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de baza ale limbajului"
						},
						{
							"_nr": "3",
							"__text": "Modelul de organizare a unui aplicatii"
						},
						{
							"_nr": "4",
							"__text": "Vizualizarea unui aplicatii J2ME functie de trasaturile dispozitivelor Nokia"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii utilitare J2ME - MPGuide - \"Mobile Phone Guide\""
						},
						{
							"_nr": "6",
							"__text": "Posibilitatile de extindere ale aplicatiei MPGuide"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea J2ME Polish in crearea de aplicatii"
						}
					]
				},
				"_id": "422",
				"_isbn": "978-973-87496-3-8"
			},
			{
				"titlu": "Simularea evenimentelor aleatoare sau cm sa castigi pariurile sportive cu programe Java",
				"autori": {
					"autor": [
						{
							"_nume": "Gloria Cerasela Crisan"
						},
						{
							"_nume": "Elena Nechita"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "134",
				"pretOriginal": "100000",
				"pretCumparare": "100000",
				"coperta": {
					"imagine": {
						"_path": "Simularea_evenimentelor_aleatoare_sau_cum_sa_castigi_pariurile_sportive_cu_programe_Java_Crisan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce sunt probabilitatile"
						},
						{
							"_nr": "2",
							"__text": "Ce este simularea ?"
						},
						{
							"_nr": "3",
							"__text": "Simularea in Java"
						},
						{
							"_nr": "4",
							"__text": "Simularea evenimentelor aleatoare"
						},
						{
							"_nr": "5",
							"__text": "Simularea jocurilor"
						}
					]
				},
				"_id": "423",
				"_isbn": "973-87655-1-X"
			},
			{
				"titlu": "Metode de optimizare patratica",
				"autori": {
					"autor": {
						"_nume": "Cristian Niculescu"
					}
				},
				"anAparitie": "2006",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "196",
				"pretOriginal": "170000",
				"pretCumparare": "170000",
				"coperta": {
					"imagine": {
						"_path": "Metode_de_optimizare_patratica_Cristian_Niculescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Probleme fara restrictii"
						},
						{
							"_nr": "3",
							"__text": "Probleme cu restrictii ecuatii"
						},
						{
							"_nr": "4",
							"__text": "Probleme cu restrictii ecuatii si inecuatii"
						},
						{
							"_nr": "5",
							"__text": "Metoda iterativa de proiectie pentru problema cu restricii ecuatii"
						},
						{
							"_nr": "6",
							"__text": "Metode de multime activa si de punct interior"
						},
						{
							"_nr": "7",
							"__text": "Problema de complementaritate liniara si optimizarea patratica"
						},
						{
							"_nr": "8",
							"__text": "Optimizare liniara si patratica cu perturbare entropica"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii ale optimizarii patratice"
						},
						{
							"_nr": "10",
							"__text": "Anexa"
						}
					]
				},
				"_id": "424",
				"_isbn": "978-973-737-221-5"
			},
			{
				"titlu": "Algoritmi fundamentali in utilizarea structurilor de date",
				"autori": {
					"autor": {
						"_nume": "Manuela Serban"
					}
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "264",
				"pretOriginal": "290000",
				"pretCumparare": "232000",
				"colectia": {
					"_numarul": "204",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_fundamentali_in_utilizarea_structurilor_de_date_Serban.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Elemente introductive"
						},
						{
							"_nr": "3",
							"__text": "Probleme cu numere"
						},
						{
							"_nr": "4",
							"__text": "Siruri"
						},
						{
							"_nr": "5",
							"__text": "Matrici"
						},
						{
							"_nr": "6",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "7",
							"__text": "Metoda Divide et impera"
						},
						{
							"_nr": "8",
							"__text": "Metoda Backtraking"
						},
						{
							"_nr": "9",
							"__text": "Metoda Greedty"
						},
						{
							"_nr": "10",
							"__text": "Metoda Programarii Dinamice"
						}
					]
				},
				"_id": "425",
				"_isbn": "978-973-650-176-0"
			},
			{
				"titlu": "Structuri de date si algoritmi",
				"autori": {
					"autor": [
						{
							"_nume": "Iosif Ignat"
						},
						{
							"_nume": "Claudia-Lavinia Ignat"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "278",
				"pretOriginal": "210000",
				"pretCumparare": "168000",
				"colectia": {
					"_numarul": "223",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_si_algoritmi_Ignat.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Liste"
						},
						{
							"_nr": "2",
							"__text": "Arbori"
						},
						{
							"_nr": "3",
							"__text": "Grafuri"
						},
						{
							"_nr": "4",
							"__text": "Tabele de dispersie"
						},
						{
							"_nr": "5",
							"__text": "Metode generale de elaborare a algoritmilor"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi fundamentali de sortare"
						},
						{
							"_nr": "7",
							"__text": "Exemple de subiecte date la examen"
						}
					]
				},
				"_id": "426",
				"_isbn": "978-973-650-213-2"
			},
			{
				"titlu": "Limbaje formale si acceptori",
				"autori": {
					"autor": {
						"_nume": "Gabriel V. Orman"
					}
				},
				"anAparitie": "2002",
				"editura": "Albastra",
				"numarPagini": "274",
				"pretOriginal": "240000",
				"pretCumparare": "192000",
				"colectia": {
					"_numarul": "140",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_acceptori_Orman.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de teoria multimilor si teoria semigrupurilor"
						},
						{
							"_nr": "2",
							"__text": "Consideratii generale asupra limbajelor formale"
						},
						{
							"_nr": "3",
							"__text": "Limbaje formale. Ierarhia Chomsky"
						},
						{
							"_nr": "4",
							"__text": "Acceptori"
						},
						{
							"_nr": "5",
							"__text": "Limbaje independente de context"
						},
						{
							"_nr": "6",
							"__text": "Multimi regulate"
						},
						{
							"_nr": "7",
							"__text": "Limbaje dependente de context"
						},
						{
							"_nr": "8",
							"__text": "Multimi enumerabile recursiv si masini Turing"
						},
						{
							"_nr": "9",
							"__text": "Gramatici si limbaje contextuale Marcus"
						},
						{
							"_nr": "10",
							"__text": "Trei probleme de baza"
						},
						{
							"_nr": "Addenda",
							"__text": "Elemente de teoira spatiilor vectoriale"
						}
					]
				},
				"_id": "427",
				"_isbn": "973-650-056-X"
			},
			{
				"titlu": "Modelare stochastica si simulare",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Gorunescu"
						},
						{
							"_nume": "Augustin Prodan"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "372",
				"pretOriginal": "200000",
				"pretCumparare": "160000",
				"colectia": {
					"_numarul": "135",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelare_stochastica_si_simulare_Gorunescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in modelarea stochastica"
						},
						{
							"_nr": "2",
							"__text": "Etapele modelarii stochastice"
						},
						{
							"_nr": "3",
							"__text": "Modele distribuite"
						},
						{
							"_nr": "4",
							"__text": "Numere aleatoare si metoda Monte Carlo"
						},
						{
							"_nr": "5",
							"__text": "Simularea unori repartitii discrete"
						},
						{
							"_nr": "6",
							"__text": "Simularea unori repartitii continue"
						},
						{
							"_nr": "7",
							"__text": "Procese Poisson"
						},
						{
							"_nr": "8",
							"__text": "Procese de reinnoire"
						},
						{
							"_nr": "9",
							"__text": "Procese Markov"
						},
						{
							"_nr": "10",
							"__text": "Elemente de teoria asteptarii"
						},
						{
							"_nr": "11",
							"__text": "Serii temporale si modele dinamice"
						},
						{
							"_nr": "12",
							"__text": "Modele stochastice aplicate"
						}
					]
				},
				"_id": "428",
				"_isbn": "973-650-023-3"
			},
			{
				"titlu": "Inteligenta artificiala. Aplicatii in prelucrarea limbajului natural",
				"autori": {
					"autor": {
						"_nume": "Doina Tatar"
					}
				},
				"anAparitie": "2003",
				"editura": "Albastra",
				"numarPagini": "250",
				"pretOriginal": "270000",
				"pretCumparare": "216000",
				"colectia": {
					"_numarul": "162",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_Aplicatii_in_prelucrarea_limbajului_natural_Doina_Tatar.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Prelucrarea statistica"
						},
						{
							"_nr": "3",
							"__text": "Probleme semantice"
						},
						{
							"_nr": "4",
							"__text": "Pragmatica si teoria discursului"
						},
						{
							"_nr": "5",
							"__text": "Gramatici de unificare"
						}
					]
				},
				"_id": "429",
				"_isbn": "973-650-100-0"
			},
			{
				"titlu": "Informatica/Tehnologia informatiei [IX]",
				"autori": {
					"autor": {
						"_nume": "Mariana Milosescu"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "296",
				"pretOriginal": "29000",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Liliana Dabulescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mariana Milosescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Tehnologia_informatiei_Manual_pentru_clasa a_IX_a_Milosescu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rolul si functiile sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "3",
							"__text": "Birotica"
						},
						{
							"_nr": "4",
							"__text": "Informatica si societatea"
						}
					]
				},
				"_id": "430",
				"_isbn": "973-20-0368-5"
			},
			{
				"titlu": "Programare distribuita in Java (I)",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Radu Boriga"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "170",
				"pretOriginal": "290000",
				"pretCumparare": "290000",
				"coperta": {
					"imagine": {
						"_path": "Programare_distribuita_in_Java_I_Georgescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni preliminare"
						},
						{
							"_nr": "2",
							"__text": "Sisteme distribuite. Socket-uri"
						},
						{
							"_nr": "3",
							"__text": "Invocarea la distanta a metodelor (RMI)"
						},
						{
							"_nr": "4",
							"__text": "Corba"
						},
						{
							"_nr": "5",
							"__text": "Servlet-uri"
						}
					]
				},
				"_id": "431",
				"_isbn": "978-973-737-513-0"
			},
			{
				"titlu": "C++. Manual complet",
				"autori": {
					"autor": {
						"_nume": "Herbert Schildt"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "646",
				"pretOriginal": "78000",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "C++: The Complete Reference",
					"editura": "McGraw-Hill Companies",
					"traducatori": {
						"traducator": "Mihai Dabuleanu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_manual_complet_1998_Schildt.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Baza limbajului C++: Limbajul C"
						},
						{
							"_nr": "1",
							"__text": "O privire de ansamblu asupra limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Expresii"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Matrice si siruri"
						},
						{
							"_nr": "5",
							"__text": "Pointeri"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Structuri, uniuni, enumerari si tipuri definite de utilizator"
						},
						{
							"_nr": "8",
							"__text": "I/O la consola"
						},
						{
							"_nr": "9",
							"__text": "I/O cu fisiere"
						},
						{
							"_nr": "10",
							"__text": "Preprocesorul. Comentarii"
						},
						{
							"_nr": "II",
							"__text": "C++ - Caracteristici specifice"
						},
						{
							"_nr": "11",
							"__text": "O privire de ansamblu asupra limbajului C++"
						},
						{
							"_nr": "12",
							"__text": "Clase si obecte"
						},
						{
							"_nr": "13",
							"__text": "Matrice, pointeri si referinte"
						},
						{
							"_nr": "14",
							"__text": "Supraincarcarea functiilor si a operatorilor"
						},
						{
							"_nr": "15",
							"__text": "Mostenirea"
						},
						{
							"_nr": "16",
							"__text": "Functii virtuale si polimorfism"
						},
						{
							"_nr": "17",
							"__text": "Bazele sitemului de I/O din C++"
						},
						{
							"_nr": "18",
							"__text": "I/O cu fisiere in C++"
						},
						{
							"_nr": "19",
							"__text": "I/O bazate pe matrice"
						},
						{
							"_nr": "20",
							"__text": "Sabloane"
						},
						{
							"_nr": "21",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "22",
							"__text": "Elemente diverse si caracteristici avansate"
						},
						{
							"_nr": "III",
							"__text": "Cateva aplicatii de C++"
						},
						{
							"_nr": "23",
							"__text": "O clasa de tip sir"
						},
						{
							"_nr": "24",
							"__text": "O clasa pentru afisarea ferestrelor"
						},
						{
							"_nr": "25",
							"__text": "O clasa generica de liste inlantuite"
						}
					]
				},
				"_id": "432",
				"_isbn": "973-601-471-1"
			},
			{
				"titlu": "Arta programarii in Java. Elemente-suport fundamentale [Volumnul I]",
				"autori": {
					"autor": [
						{
							"_nume": "Daniel Danciu"
						},
						{
							"_nume": "George Mardale"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Albastra",
				"numarPagini": "370",
				"pretOriginal": "380000",
				"pretCumparare": "304000",
				"colectia": {
					"_numarul": "163",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Arta_programarii_in_Java_Elemente_suport_fundamentale_Danciu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea limbajului Java"
						},
						{
							"_nr": "2",
							"__text": "Notiuni fundamentale de programare in Java"
						},
						{
							"_nr": "3",
							"__text": "Referinte"
						},
						{
							"_nr": "4",
							"__text": "Obiecte si clase"
						},
						{
							"_nr": "5",
							"__text": "Mostenire"
						},
						{
							"_nr": "6",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "7",
							"__text": "Intrare si iesire (Java I/O)"
						},
						{
							"_nr": "8",
							"__text": "Fire de executie"
						},
						{
							"_nr": "A",
							"__text": "Mediul Java si uneltele ajutatoare"
						},
						{
							"_nr": "B",
							"__text": "Conventii de contatie in Java. Utilitarul javadoc"
						},
						{
							"_nr": "C",
							"__text": "Definirea pachetelor Java. Arhive jar"
						},
						{
							"_nr": "D",
							"__text": "Internationalizarea aplicatiilor. Colectii de resurse"
						},
						{
							"_nr": "E",
							"__text": "Resurse Java"
						}
					]
				},
				"_id": "433",
				"_isbn": "973-650-121-3"
			},
			{
				"titlu": "Arta programarii in Java. Algoritmi si structuri de date [Volumnul II]",
				"autori": {
					"autor": [
						{
							"_nume": "Daniel Danciu"
						},
						{
							"_nume": "George Mardale"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Albastra",
				"numarPagini": "264",
				"pretOriginal": "280000",
				"pretCumparare": "244000",
				"colectia": {
					"_numarul": "164",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Arta_programarii_in_Java+Algoritmi_si_structuri_de_date_Danciu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "9",
							"__text": "Analiza eficientei algorimilor"
						},
						{
							"_nr": "10",
							"__text": "Structuri de date"
						},
						{
							"_nr": "11",
							"__text": "Metoda Backtracking"
						},
						{
							"_nr": "12",
							"__text": "Divide et impera"
						},
						{
							"_nr": "13",
							"__text": "Algoritmi Greedy"
						},
						{
							"_nr": "14",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "15",
							"__text": "Metoda Branch si bound"
						},
						{
							"_nr": "16",
							"__text": "Metode de elaborare a algoritmilor (sinteza)"
						}
					]
				},
				"_id": "434",
				"_isbn": "973-650-122-1"
			},
			{
				"titlu": "Modelare obiect orientata cu UML. Fundamentele modelarii cu UML. Initiere in sabloane de proiectare utilizand sintaxa UML",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Bocu"
						},
						{
							"_nume": "Razvan Bocu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "252",
				"pretOriginal": "280000",
				"pretCumparare": "244000",
				"colectia": {
					"_numarul": "213",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelare_obiect_orientata_cu_UML_Fundamentele_modelarii_cu_UML_Initiere_in_sabloane_de_proiectare_utilizand_sintaxa_UML_Bocu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente introductive"
						},
						{
							"_nr": "2",
							"__text": "Modelarea aspectelor structurale. Elemente-suport fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Modelarea aspectelor structurale. Elemente-suport avansate"
						},
						{
							"_nr": "4",
							"__text": "Modelarea aspectelor comportametale. Elemente-suport fundamentale"
						},
						{
							"_nr": "5",
							"__text": "Modelarea aspectelor comportametale. Elemente-suport avansate"
						},
						{
							"_nr": "6",
							"__text": "Elemente de modelare arhitecturala"
						},
						{
							"_nr": "7",
							"__text": "Fluxuri de lucru fundamentale pentrur un proiect dezvoltat cu suport UML. Structura si continutul documentatiei realizate"
						},
						{
							"_nr": "8",
							"__text": "Scurta introducere in studiul sabloanelor de proiectare utilizand formalismul UML"
						}
					]
				},
				"_id": "435",
				"_isbn": "973-650-194-9"
			},
			{
				"titlu": "Data mining. Concepte, modele si tehnici",
				"autori": {
					"autor": {
						"_nume": "Florin Gorunescu"
					}
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "294",
				"pretOriginal": "320000",
				"pretCumparare": "256000",
				"colectia": {
					"_numarul": "207",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Data_mining_concepte_modele_si_tehnici_Gorunescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in data mining"
						},
						{
							"_nr": "2",
							"__text": "'Mina' de date"
						},
						{
							"_nr": "3",
							"__text": "Analiza exploratorie a datelor"
						},
						{
							"_nr": "4",
							"__text": "Arbori de clasificare si decizie"
						},
						{
							"_nr": "5",
							"__text": "Tehnici si modele de data mining"
						},
						{
							"_nr": "6",
							"__text": "Performanta clasificarii"
						}
					]
				},
				"_id": "436",
				"_isbn": "973-650-169-8"
			},
			{
				"titlu": "Algoritmi genetici si strategii evolutive. Aplicatii in Inteligenta artificala si in domenii conexe",
				"autori": {
					"autor": {
						"_nume": "Dumitru Dumitrescu"
					}
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "228",
				"pretOriginal": "260000",
				"pretCumparare": "208000",
				"colectia": {
					"_numarul": "106",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_generici_si_strategii_evolutive_aplicatii_in_Inteligenta_Artificiala_si_in_domenii_conexe.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Cautare, optimizare, invatare"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi genetici si evolutivi. Principii generale"
						},
						{
							"_nr": "3",
							"__text": "Structura unui algoritm genetic"
						},
						{
							"_nr": "4",
							"__text": "Operatori genetici uzuali. Codificarea binara"
						},
						{
							"_nr": "5",
							"__text": "Codificarea reala"
						},
						{
							"_nr": "6",
							"__text": "Optimizarea functiilor reale"
						},
						{
							"_nr": "7",
							"__text": "Teorema schemelor si problemelor conexe"
						},
						{
							"_nr": "8",
							"__text": "Alte abordari privind algoritmii genetici. Programarea genetica"
						},
						{
							"_nr": "9",
							"__text": "Strategii evolutive"
						},
						{
							"_nr": "10",
							"__text": "Programarea evolutiva"
						},
						{
							"_nr": "11",
							"__text": "Aplicatiile calculului evolutiv"
						}
					]
				},
				"_id": "437",
				"_isbn": "973-650-167-1"
			},
			{
				"titlu": "Sisteme multiprocesor",
				"autori": {
					"autor": {
						"_nume": "Francisc Iacob"
					}
				},
				"anAparitie": "2000",
				"editura": "Victor",
				"numarPagini": "176",
				"pretOriginal": "45000",
				"pretCumparare": "45000",
				"redactori": {
					"redactor": "Elena Diatcu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Florica Budnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_multiprocesor_Iacob.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive despre calculatoare paralele"
						},
						{
							"_nr": "2",
							"__text": "Sisteme SIMD"
						},
						{
							"_nr": "3",
							"__text": "Minicalculatoare"
						},
						{
							"_nr": "4",
							"__text": "Sisteme multiprocesor"
						},
						{
							"_nr": "5",
							"__text": "Multiprocesare cu acces uniform in memorie (UMA)"
						},
						{
							"_nr": "6",
							"__text": "Multiprocesare cu acces neuniform in memorie (NUMA)"
						},
						{
							"_nr": "7",
							"__text": "Sisteme de operare pentru multiprocesoare"
						},
						{
							"_nr": "8",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "438",
				"_isbn": "973-8128-05-6"
			},
			{
				"titlu": "Excel 5 pentru Windows in 477 imagini",
				"autori": {
					"autor": {
						"_nume": "Trudi Reisner"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "160",
				"pretOriginal": "7000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "82",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Excel 5 for Windows VisiRef",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Silviu Petrescu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Excel_5_pentru_Windows_in_477_imagini_Reisner.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Diagrame"
						},
						{
							"_nr": "3",
							"__text": "Coloane si randuri"
						},
						{
							"_nr": "4",
							"__text": "Analiza datelor"
						},
						{
							"_nr": "5",
							"__text": "Baze de date"
						},
						{
							"_nr": "6",
							"__text": "Editare"
						},
						{
							"_nr": "7",
							"__text": "Gestionarea fisierelor"
						},
						{
							"_nr": "8",
							"__text": "Formatare"
						},
						{
							"_nr": "9",
							"__text": "Forumule si functii"
						},
						{
							"_nr": "10",
							"__text": "Imagini grafice"
						},
						{
							"_nr": "11",
							"__text": "Sistemul de asistenta"
						},
						{
							"_nr": "12",
							"__text": "Interfata"
						},
						{
							"_nr": "13",
							"__text": "Macrocomenzi"
						},
						{
							"_nr": "14",
							"__text": "Tiparirea"
						},
						{
							"_nr": "15",
							"__text": "Domenii"
						},
						{
							"_nr": "16",
							"__text": "Foi de calcul"
						}
					]
				},
				"_id": "439",
				"_isbn": "973-601-295-6"
			},
			{
				"titlu": "Windows 3.11 in 411 imagini",
				"autori": {
					"autor": [
						{
							"_nume": "Trudi Reisner"
						},
						{
							"_nume": "Michael Watson"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "168",
				"pretOriginal": "14000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "167",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Windows VisiRef",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Silviu Petrescu",
							"Anca Petrescu"
						]
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_3_1_1_in_411_imagini_Reisner.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Configurare: Dispozitive, parametri de baza, sunet, fisier swap"
						},
						{
							"_nr": "3",
							"__text": "Administrarea discului"
						},
						{
							"_nr": "4",
							"__text": "Sistemul DOS"
						},
						{
							"_nr": "5",
							"__text": "Administrarea fisierelor"
						},
						{
							"_nr": "6",
							"__text": "Help"
						},
						{
							"_nr": "7",
							"__text": "Integrare"
						},
						{
							"_nr": "8",
							"__text": "Interfata"
						},
						{
							"_nr": "9",
							"__text": "Macrocomenzi"
						},
						{
							"_nr": "10",
							"__text": "Retele"
						},
						{
							"_nr": "11",
							"__text": "Tiparire"
						},
						{
							"_nr": "12",
							"__text": "Programe"
						},
						{
							"_nr": "13",
							"__text": "Configurare: Sisem, aplicatii, comonente"
						},
						{
							"_nr": "14",
							"__text": "Accesoriile sitemului Windows"
						},
						{
							"_nr": "15",
							"__text": "Index"
						}
					]
				},
				"_id": "440",
				"_isbn": "973-601-635-8"
			},
			{
				"titlu": "Sistemul de programe. Norton Commander",
				"autori": {
					"autor": {
						"_nume": "Florentina Hristea"
					}
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "136",
				"pretOriginal": "150",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "0"
				},
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Sorin Serban"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_programe_Norton_Commander_Hristea.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Cuvant inainte"
						},
						{
							"_nr": "1",
							"__text": "Preliminarii la studiul mediului Norton Commander"
						},
						{
							"_nr": "2",
							"__text": "Norton Commander"
						},
						{
							"_nr": "3",
							"__text": "Bar-meniul postei electronice MCI"
						},
						{
							"_nr": "Anexe",
							"__text": "Anexe si note"
						}
					]
				},
				"_id": "441",
				"_isbn": "973-31-0443-4"
			},
			{
				"titlu": "MS-DOS 4.01",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Tepelea"
						},
						{
							"_nume": "Cristian Lupu"
						},
						{
							"_nume": "Niculae Stratica"
						},
						{
							"_nume": "Eugen Georgescu"
						},
						{
							"_nume": "Doina Istratescu"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Teora",
				"numarPagini": "148",
				"pretOriginal": "150",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "1",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_4_01_Tepelea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Instalarea sistemului de operare MS-DOS"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de fisiere"
						},
						{
							"_nr": "4",
							"__text": "Lansarea sistemului de operare MS-DOS"
						},
						{
							"_nr": "5",
							"__text": "Procesorul de comenzi"
						},
						{
							"_nr": "6",
							"__text": "Interfata grafica MS-DOS SHELL"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea depanatorului DEBUG"
						}
					]
				},
				"_id": "442",
				"_isbn": "973-601-005-8"
			},
			{
				"titlu": "Proiectarea algoritmilor",
				"autori": {
					"autor": [
						{
							"_nume": "Dorel Lucan"
						},
						{
							"_nume": "Mitica Craus"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Polirom",
				"numarPagini": "368",
				"pretOriginal": "320600",
				"pretCumparare": "256480",
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_algorimtilor_Lucanu.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre algoritmi"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date de nivel inalt"
						},
						{
							"_nr": "3",
							"__text": "Derecursivitate"
						},
						{
							"_nr": "4",
							"__text": "Sortare interna"
						},
						{
							"_nr": "5",
							"__text": "Cautare"
						},
						{
							"_nr": "6",
							"__text": "Tipuri de dae avansate pentru cautare"
						},
						{
							"_nr": "7",
							"__text": "Cautare peste siruri"
						},
						{
							"_nr": "8",
							"__text": "Despre paradigmele de proiectare"
						},
						{
							"_nr": "9",
							"__text": "Algoritmi greedy"
						},
						{
							"_nr": "10",
							"__text": "Divide-et-Impera"
						},
						{
							"_nr": "11",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "12",
							"__text": "Backtraking si branch-and-bound"
						},
						{
							"_nr": "13",
							"__text": "Probleme NP-complete"
						}
					]
				},
				"_id": "443",
				"_isbn": "978-973-46-1140-9"
			},
			{
				"titlu": "Initiere in Matlab",
				"autori": {
					"autor": {
						"_nume": "Silvia Curteanu"
					}
				},
				"anAparitie": "2008",
				"editura": "Polirom",
				"numarPagini": "220",
				"pretOriginal": "302300",
				"pretCumparare": "241840",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vasilica Nistor"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Matlab_Curteanu.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Mediul Matlab"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "3",
							"__text": "Matrice"
						},
						{
							"_nr": "4",
							"__text": "Tablouri"
						},
						{
							"_nr": "5",
							"__text": "Reprezentari grafice"
						},
						{
							"_nr": "6",
							"__text": "Programare in Matlab"
						},
						{
							"_nr": "7",
							"__text": "Elemente de calcul algebric si statistic"
						}
					]
				},
				"_id": "444",
				"_isbn": "978-973-46-0920-8"
			},
			{
				"titlu": "Java de la 0 la expert [Editia a II-a]",
				"autori": {
					"autor": [
						{
							"_nume": "Stefan Tanasa"
						},
						{
							"_nume": "Cristian Olaru"
						},
						{
							"_nume": "Stefan Andrei"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Polirom",
				"numarPagini": "864",
				"pretOriginal": "577500",
				"pretCumparare": "404250",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alexandru Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Java_de_la_0_la_expert_II_Olaru.jpg"
					},
					"__text": "Angela Rotaru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Fundamentele limbajului Java"
						},
						{
							"_nr": "3",
							"__text": "Clase, interfete si tablouri"
						},
						{
							"_nr": "4",
							"__text": "Siruri si structuri dinamice de date"
						},
						{
							"_nr": "5",
							"__text": "Fisiere, fluxuri de date si serializarea obiectelor"
						},
						{
							"_nr": "6",
							"__text": "Fire de executie"
						},
						{
							"_nr": "7",
							"__text": "Appleturi si instrumente de lucru cu ferestrele (AWT)"
						},
						{
							"_nr": "8",
							"__text": "Accesul la baze de date folosind JDBC"
						},
						{
							"_nr": "9",
							"__text": "Programarea retelelor"
						},
						{
							"_nr": "10",
							"__text": "Servleturi"
						},
						{
							"_nr": "11",
							"__text": "JavaServer Pages (JSP)"
						},
						{
							"_nr": "12",
							"__text": "Procesarea documentelor XML"
						},
						{
							"_nr": "13",
							"__text": "Interactiunea cu utilizatorul prin Swing"
						},
						{
							"_nr": "14",
							"__text": "Sistemul Help pentru Java"
						},
						{
							"_nr": "15",
							"__text": "Internationalizarea aplicatiilor"
						}
					]
				},
				"_id": "445",
				"_isbn": "978-973-46-0317-6"
			},
			{
				"titlu": "Programare RSS, Atom si Podcast",
				"autori": {
					"autor": {
						"_nume": "Traian Anghel"
					}
				},
				"anAparitie": "2008",
				"editura": "Institutul European",
				"numarPagini": "234",
				"pretOriginal": "350000",
				"pretCumparare": "350000",
				"redactori": {
					"redactor": "Mihai Baltagu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Cristina Aiftimie"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_RSS_Atom_si_Podcast_Anghel.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De la Gutenberg la Internet"
						},
						{
							"_nr": "2",
							"__text": "Limbajul XML"
						},
						{
							"_nr": "3",
							"__text": "Introducere in RDF"
						},
						{
							"_nr": "4",
							"__text": "Formatul RSS"
						},
						{
							"_nr": "5",
							"__text": "Feed-uri Potcast. Extensii media RSS 2.0"
						},
						{
							"_nr": "6",
							"__text": "Formatul Atom"
						},
						{
							"_nr": "7",
							"__text": "Formatul OPML"
						},
						{
							"_nr": "8",
							"__text": "Crearea feed-urilor si a listelor OPML"
						},
						{
							"_nr": "9",
							"__text": "Publicarea, cautarea si managementul feed-urilor"
						},
						{
							"_nr": "10",
							"__text": "Procesarea feed-urilor si a listelor OPML"
						},
						{
							"_nr": "11",
							"__text": "Abonarea la feed-uri si la listele OPML"
						},
						{
							"_nr": "12",
							"__text": "Alte aplicatii"
						}
					]
				},
				"_id": "446",
				"_isbn": "978-973-611-556-1"
			},
			{
				"titlu": "Grafica interactiva cu aplicatii in Java si Java 3D",
				"autori": {
					"autor": {
						"_nume": "Titus Felix Furtuna"
					}
				},
				"anAparitie": "2007",
				"editura": "ASE",
				"numarPagini": "172",
				"pretOriginal": "291000",
				"pretCumparare": "291000",
				"tehnoredactori": {
					"tehnoredactor": "Mioara Gamulea"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_interactiva_cu_aplicatii_in_Java_si_Java_3D_Furtuna.jpg"
					},
					"__text": "Livia Radu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Java 2D"
						},
						{
							"_nr": "II",
							"__text": "Transformari geometrice"
						},
						{
							"_nr": "III",
							"__text": "Problema liniilor si suprafetelor ascunse in grafica 3D"
						},
						{
							"_nr": "IV",
							"__text": "Scalarea multidimensionala"
						},
						{
							"_nr": "V",
							"__text": "Elemente de programare sub Java 3D"
						}
					]
				},
				"_id": "447",
				"_isbn": "978-973-594-970-9"
			},
			{
				"titlu": "Siseme de programe pentru retele de calculatoare. Aplicatii practice",
				"autori": {
					"autor": [
						{
							"_nume": "Ciprina Dobre"
						},
						{
							"_nume": "Florin Pop"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Politehnica Press",
				"numarPagini": "198",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_programe_pentru_retele_de_calculatoare_Aplicatii_practice_Dobre.jpg"
					},
					"__text": "Adriana Butmalai"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "RPC - Apelarea procedurilor la distanta"
						},
						{
							"_nr": "3",
							"__text": "Dezvoltarea aplicatiilor distribuite in MPI"
						},
						{
							"_nr": "4",
							"__text": "Consistenta datelor si toleranta la defecte"
						},
						{
							"_nr": "5",
							"__text": "Securitate in Java"
						},
						{
							"_nr": "6",
							"__text": "CORBA"
						},
						{
							"_nr": "7",
							"__text": "Agenti mobili"
						},
						{
							"_nr": "8",
							"__text": "Sisteme Grid"
						}
					]
				},
				"_id": "448",
				"_isbn": "978-606-515-000-3"
			},
			{
				"titlu": "Modelare numerica pentru ecuatii diferentiale si ecuatii cu derivate partiale",
				"autori": {
					"autor": [
						{
							"_nume": "Ariana Pitea"
						},
						{
							"_nume": "Mihai Postolache"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Fair Partners",
				"numarPagini": "116",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Viorica Cerasela Postolache"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelare_numerica_pentru_ecuatii_diferentiale_si_ecuatii_cu_derivate_partiale_Pitea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Derivarea numerica"
						},
						{
							"_nr": "2",
							"__text": "Metode analitice pentru ecuatii diferentiale cu conditii initiale"
						},
						{
							"_nr": "3",
							"__text": "Integrarea numerica a ecuatiilor diferentiale"
						},
						{
							"_nr": "4",
							"__text": "Problem cu valori limita"
						},
						{
							"_nr": "5",
							"__text": "Anaza numerica a ecuatiilor cu derivate partiale"
						},
						{
							"_nr": "6",
							"__text": "Ecuatii integrale"
						}
					]
				},
				"_id": "449",
				"_isbn": "978-973-8470-98-9"
			},
			{
				"titlu": "Cercetari operationale",
				"autori": {
					"autor": {
						"_nume": "Vasile Masgras"
					}
				},
				"anAparitie": "2004",
				"editura": "Fair Partners",
				"numarPagini": "196",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"colectia": {
					"_nume": "Manuale. Tratate. Monografii",
					"_numarul": "47"
				},
				"coperta": {
					"imagine": {
						"_path": "Cercetari_operationale_Masgras.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programrea liniara"
						},
						{
							"_nr": "2",
							"__text": "Problema transporturi"
						},
						{
							"_nr": "3",
							"__text": "Teoria jocurilor"
						},
						{
							"_nr": "4",
							"__text": "Teoria grafurilor"
						},
						{
							"_nr": "5",
							"__text": "Teoria asteptarii"
						},
						{
							"_nr": "6",
							"__text": "Problema propuse"
						}
					]
				},
				"_id": "450",
				"_isbn": "973-8470-28-5"
			},
			{
				"titlu": "Formalisme si instrumente de descriere si prelucrare ale  limbajului natural",
				"autori": {
					"autor": {
						"_nume": "Dan Cristea"
					}
				},
				"anAparitie": "2002",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "260",
				"pretOriginal": "180000",
				"pretCumparare": "180000",
				"redactori": {
					"redactor": "Mariana Pricop"
				},
				"coperta": {
					"imagine": {
						"_path": "Formalisme_si_instrumente_de_descriere_si_prelucrare_ale_limbajului_natural_Cristea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Teorii si formalisme in prelucrarea limbajului natural"
						},
						{
							"_nr": "2",
							"__text": "Definitia limbajului L-exp"
						},
						{
							"_nr": "3",
							"__text": "Programarea in L-exp"
						},
						{
							"_nr": "4",
							"__text": "Achizitia de cunostinte in limbaj natural"
						},
						{
							"_nr": "5",
							"__text": "Dezvoltari si concluzii"
						}
					]
				},
				"_id": "451",
				"_isbn": "973-8243-60-2"
			},
			{
				"titlu": "Calcul paralel. Proiectare si dezvoltare formala a programelor paralele",
				"autori": {
					"autor": {
						"_nume": "Virginia Niculescu"
					}
				},
				"anAparitie": "2006",
				"editura": "Presa Universitara Clujeana",
				"numarPagini": "310",
				"pretOriginal": "145000",
				"pretCumparare": "145000",
				"coperta": {
					"imagine": {
						"_path": "Calcul_paralel_Proiectare_si_dezvoltare_formala_a_programelor_paralele_Niculescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fundamente"
						},
						{
							"_nr": "1",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "2",
							"__text": "Constructia programelor paralele"
						},
						{
							"_nr": "II",
							"__text": "Proiectare"
						},
						{
							"_nr": "3",
							"__text": "Paradigme"
						},
						{
							"_nr": "4",
							"__text": "Tehnici"
						},
						{
							"_nr": "III",
							"__text": "Dezvoltare formala"
						},
						{
							"_nr": "5",
							"__text": "Modelul UNITY"
						},
						{
							"_nr": "6",
							"__text": "Dezvoltare formala din specificatii"
						},
						{
							"_nr": "7",
							"__text": "Formalismul Bird-Meertens - BMF"
						},
						{
							"_nr": "8",
							"__text": "Structuri de date pentru paralelism"
						},
						{
							"_nr": "IV",
							"__text": "Modele"
						},
						{
							"_nr": "9",
							"__text": "Model de calul paralel"
						},
						{
							"_nr": "Anexa",
							"__text": "Notiuni de teoria grafurilor"
						}
					]
				},
				"_id": "452",
				"_isbn": "978-610-493-1"
			},
			{
				"titlu": "Grafica in realitatea virtuala",
				"autori": {
					"autor": {
						"_nume": "Felicia Ionescu"
					}
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "320",
				"pretOriginal": "76500",
				"pretCumparare": "76500",
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Staicu"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_in_realitatea_virtuala_Ionescu.jpg"
					},
					"__text": "Simona Dumitrescu, Vlad Oancea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in realitatea virtuala"
						},
						{
							"_nr": "2",
							"__text": "Modelarea obiectelor"
						},
						{
							"_nr": "3",
							"__text": "Transformari geometrice"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de vizualizare"
						},
						{
							"_nr": "5",
							"__text": "Transformarea de rastru"
						},
						{
							"_nr": "6",
							"__text": "Biblioteca grafica OpenGL"
						},
						{
							"_nr": "7",
							"__text": "Modele de reflexie si iluminare"
						},
						{
							"_nr": "8",
							"__text": "Modelarea si redarea suprafetelor parametrice"
						},
						{
							"_nr": "9",
							"__text": "Anti-aliasing"
						},
						{
							"_nr": "10",
							"__text": "Texturarea"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii ale realitatii virtuale"
						}
					]
				},
				"_id": "453",
				"_isbn": "973-31-1466-9"
			},
			{
				"titlu": "Structuri de date",
				"autori": {
					"autor": [
						{
							"_nume": "Silviu Barza"
						},
						{
							"_nume": "Luciana Maria Morogan"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "120",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"tehnoredactori": {
					"tehnoredactor": "Silviu Barza"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_Branza.jpg"
					},
					"__text": "Marilena Balan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Informatie, coduri, reprezentari"
						},
						{
							"_nr": "II",
							"__text": "Tablouri"
						},
						{
							"_nr": "III",
							"__text": "Liste liniare"
						},
						{
							"_nr": "IV",
							"__text": "Structuri de date neliniare"
						},
						{
							"_nr": "V",
							"__text": "Cautare"
						},
						{
							"_nr": "VI",
							"__text": "Interclasarea"
						},
						{
							"_nr": "VII",
							"__text": "Sortarea"
						}
					]
				},
				"_id": "454",
				"_isbn": "978-973-725-689-8"
			},
			{
				"titlu": "Structuri de date si algoritmi in memoria secundara",
				"autori": {
					"autor": {
						"_nume": "Mircea Adam"
					}
				},
				"anAparitie": "2008",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "136",
				"pretOriginal": "160000",
				"pretCumparare": "160000",
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_si_algoritmi_in_memoria_secundara_Adam.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Principale structuri de cautare multidimensionala externa"
						},
						{
							"_nr": "3",
							"__text": "Arbori in memoria interna"
						},
						{
							"_nr": "4",
							"__text": "Scheme de indexare"
						},
						{
							"_nr": "5",
							"__text": "Arbori in memoria secundara"
						},
						{
							"_nr": "6",
							"__text": "Concluzii"
						}
					]
				},
				"_id": "455",
				"_isbn": "978-973-737-483"
			},
			{
				"titlu": "Instrumente web 2.0 utilizate in educatie",
				"autori": {
					"autor": {
						"_nume": "Traian Anghel"
					}
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "262",
				"pretOriginal": "360000",
				"pretCumparare": "288000",
				"colectia": {
					"_numarul": "232",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Instrumente_Web_2_0_utilizate_in_educatie_Anghel.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Web 1.0"
						},
						{
							"_nr": "2",
							"__text": "Web 2.0"
						},
						{
							"_nr": "3",
							"__text": "Implicatii sociale ale utilizarii internetului"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea Web 2.0 in educatie"
						},
						{
							"_nr": "5",
							"__text": "Marcarea sociala a resurselor"
						},
						{
							"_nr": "6",
							"__text": "Informare, documentare si colectarea datelor"
						},
						{
							"_nr": "7",
							"__text": "Crearea, publicarea si partajarea continutului"
						},
						{
							"_nr": "8",
							"__text": "Managementul resurselor educationale"
						},
						{
							"_nr": "9",
							"__text": "Bloguri si microbloguri"
						},
						{
							"_nr": "10",
							"__text": "Instrumente pentru colaborare online"
						}
					]
				},
				"_id": "456",
				"_isbn": "978-973-650-243-9"
			},
			{
				"titlu": "Logici clasice si circuite logice. Teorie si exemple",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaiela Lupea"
						},
						{
							"_nume": "Amdreea Mihis"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Albastra",
				"numarPagini": "224",
				"pretOriginal": "320000",
				"pretCumparare": "256000",
				"colectia": {
					"_numarul": "70",
					"_nume": "A"
				},
				"coperta": {
					"imagine": {
						"_path": "Logici_clasice_si_circuite_logice_Teorie_si_exemple_Lupea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Logica propozitiilor"
						},
						{
							"_nr": "2",
							"__text": "Logica predicatelor de ordinul I"
						},
						{
							"_nr": "3",
							"__text": "Metoda tabelelor sematice in logicilor clasice"
						},
						{
							"_nr": "4",
							"__text": "Metoda secventelor si metoda anti-secventelor"
						},
						{
							"_nr": "5",
							"__text": "Metoda rezolutiei in logicile clasice"
						},
						{
							"_nr": "6",
							"__text": "Algebre booleene si functii booleene"
						},
						{
							"_nr": "7",
							"__text": "Simplificarea functiilor booleene"
						},
						{
							"_nr": "8",
							"__text": "Circuite logice"
						},
						{
							"_nr": "9",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "457",
				"_isbn": "978-973-650-236-1"
			},
			{
				"titlu": "Dezvoltarea de software in context CMMI",
				"autori": {
					"autor": {
						"_nume": "Mariana Mocanu"
					}
				},
				"anAparitie": "2008",
				"editura": "Albastra",
				"numarPagini": "484",
				"pretOriginal": "560000",
				"pretCumparare": "448000",
				"colectia": {
					"_numarul": "228",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_de_software_in_context_CMMI_Mocanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ingineria dezvoltarii programelor"
						},
						{
							"_nr": "II",
							"__text": "Capability matury model integration (CMMI)"
						},
						{
							"_nr": "III",
							"__text": "Ariile de process"
						},
						{
							"_nr": "--",
							"__text": "Managementul proceselor"
						},
						{
							"_nr": "--",
							"__text": "Ingineri"
						},
						{
							"_nr": "--",
							"__text": "Management de proiect"
						},
						{
							"_nr": "--",
							"__text": "Suport"
						}
					]
				},
				"_id": "458",
				"_isbn": "978-973-650-225-5"
			},
			{
				"titlu": "Programarea aplicatiilor 3D cu OpenGL",
				"autori": {
					"autor": {
						"_nume": "Rodica Baciu"
					}
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "428",
				"pretOriginal": "490000",
				"pretCumparare": "294000",
				"colectia": {
					"_numarul": "194",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_aplicatilor_grafice_3D_cu_OpenGL_Baciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Introducere general in programarea OpenGL"
						},
						{
							"_nr": "3",
							"__text": "Primitive de iesire"
						},
						{
							"_nr": "4",
							"__text": "Transformari in OpenGL"
						},
						{
							"_nr": "5",
							"__text": "Iluminarea"
						},
						{
							"_nr": "6",
							"__text": "Texturarea"
						},
						{
							"_nr": "7",
							"__text": "Buffer-ul de cadru"
						},
						{
							"_nr": "8",
							"__text": "Efecte vizuale"
						},
						{
							"_nr": "9",
							"__text": "Operarii pe fragment"
						},
						{
							"_nr": "10",
							"__text": "Aplicatiigrafice interactive cu OpenGL"
						},
						{
							"_nr": "11",
							"__text": "Diverse alte functii OpenGL"
						},
						{
							"_nr": "12",
							"__text": "OpenGL si Windows API"
						},
						{
							"_nr": "13",
							"__text": "OpenGL si MFC"
						},
						{
							"_nr": "14",
							"__text": "Aplicatii cu OpenGL"
						}
					]
				},
				"_id": "459",
				"_isbn": "973-650-158-2"
			},
			{
				"titlu": "Metode numerice",
				"autori": {
					"autor": [
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Boris Jora"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Albastra",
				"numarPagini": "538",
				"pretOriginal": "590000",
				"pretCumparare": "472000",
				"colectia": {
					"_numarul": "188",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_Iorga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Preliminarii matematice"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de ecuatii liniare"
						},
						{
							"_nr": "3",
							"__text": "Ecuatii neliniare"
						},
						{
							"_nr": "4",
							"__text": "Interpolare polinomiala"
						},
						{
							"_nr": "5",
							"__text": "Aproximare uniforma"
						},
						{
							"_nr": "6",
							"__text": "Aproximarea in sensul celor mai mici patrate"
						},
						{
							"_nr": "7",
							"__text": "Integrare si derivare numerica"
						},
						{
							"_nr": "8",
							"__text": "Integrarea ecuatiilor diferentiale cu conditii initiale"
						},
						{
							"_nr": "9",
							"__text": "Calculul valorilor proprii si vectorilor proprii pentru matrice nesimetrice"
						},
						{
							"_nr": "10",
							"__text": "Calculul valorilor proprii generalizate si vectorilor proprii generalizati"
						},
						{
							"_nr": "11",
							"__text": "Calculul valorilor proprii si vectorilor proprii pentru matrice reale simetrice"
						},
						{
							"_nr": "12",
							"__text": "Descompunerea valorilor singulare"
						}
					]
				},
				"_id": "460",
				"_isbn": "978-973-650-231-6"
			},
			{
				"titlu": "Metode de calcul numeric pentru algebra",
				"autori": {
					"autor": {
						"_nume": "Dumitru Ebanca"
					}
				},
				"anAparitie": "2005",
				"editura": "Sitech",
				"numarPagini": "272",
				"pretOriginal": "218000",
				"pretCumparare": "218000",
				"colectia": {
					"_numarul": "250",
					"_nume": "Computer Science"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_de_calcul_numeric_pentru_algebra_Ebanca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ecuatii si sistem de ecuatii neliniare"
						},
						{
							"_nr": "2",
							"__text": "Matrice. Sisteme de ecuatii liniare"
						},
						{
							"_nr": "3",
							"__text": "Polinom caracteristic. Vectori si valori proprii"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi pentru accelerarea convergentei numerice. Aplicatii in algebra"
						}
					]
				},
				"_id": "461",
				"_isbn": "973-746-062-6"
			},
			{
				"titlu": "Structuri de date si algoritmi. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Dumintru Dan Burdescu"
						},
						{
							"_nume": "Marian Cristian Mihaescu"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Sitech",
				"numarPagini": "360",
				"pretOriginal": "381500",
				"pretCumparare": "381500",
				"coperta": {
					"imagine": {
						"_path": "Stucturi_de_date_si_algorimi_Burdescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Structuri arborescente"
						},
						{
							"_nr": "1.1",
							"__text": "Arbori binari (2-3)"
						},
						{
							"_nr": "1.2",
							"__text": "Arbori oarecare (a-b)"
						},
						{
							"_nr": "1.3",
							"__text": "Arbori de cautare"
						},
						{
							"_nr": "1.4",
							"__text": "Arbori echilibrati"
						},
						{
							"_nr": "1.5",
							"__text": "Arbori optimali"
						},
						{
							"_nr": "1.6",
							"__text": "Arbori Splay si arbori de cautare aletori"
						},
						{
							"_nr": "II",
							"__text": "Structuri arborescente multicai"
						},
						{
							"_nr": "2.1",
							"__text": "Arbori multicai (2-3)"
						},
						{
							"_nr": "2.2",
							"__text": "Arbori multicai (a-b)"
						},
						{
							"_nr": "2.3",
							"__text": "Arbori B"
						},
						{
							"_nr": "2.4",
							"__text": "Arbori Trie"
						},
						{
							"_nr": "2.5",
							"__text": "Moviel Fibonacii si movile binome"
						},
						{
							"_nr": "III",
							"__text": "Structuri arborecene spatiale"
						},
						{
							"_nr": "3.1",
							"__text": "Arbori M"
						},
						{
							"_nr": "3.2",
							"__text": "Arbori M+"
						},
						{
							"_nr": "3.3",
							"__text": "Arbori R"
						},
						{
							"_nr": "3.4",
							"__text": "Arbori R+"
						},
						{
							"_nr": "3.5",
							"__text": "Arbori R*"
						}
					]
				},
				"_id": "462",
				"_isbn": "978-973-746-646-4"
			},
			{
				"titlu": "Metode numerice pentru ecuatii neliniare",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Cira"
						},
						{
							"_nume": "Stefan Maruster"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "MatrixRom",
				"numarPagini": "328",
				"pretOriginal": "330000",
				"pretCumparare": "330000",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_pentru_ecuatii_neliniare_Cira.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de analiza"
						},
						{
							"_nr": "2",
							"__text": "Metode numerice pentru ecuatii neliniare in R"
						},
						{
							"_nr": "3",
							"__text": "Metode numerice pentru ecuatii neliniare in C"
						},
						{
							"_nr": "4",
							"__text": "Metode numerice pentru ecuatii neliniare in R^n"
						},
						{
							"_nr": "5",
							"__text": "Metode numerice pentru ecuatii operationale"
						}
					]
				},
				"_id": "463",
				"_isbn": "978-973-755-310-2"
			},
			{
				"titlu": "Metode numerice pentru rezolvarea ecuatiilor neliniare",
				"autori": {
					"autor": {
						"_nume": "Stefan Maruster"
					}
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "208",
				"pretOriginal": "7.5",
				"pretCumparare": "500000",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_in_rezolvarea_ecuatiilor_neliniare_Maruster.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ecuatii neliniare cu o necunoscuta"
						},
						{
							"_nr": "2",
							"__text": "Sisteme neliniare"
						},
						{
							"_nr": "3",
							"__text": "Ecuatii algebrice"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "464"
			},
			{
				"titlu": "Matematici aplicate in economie. Modelarea si simularea proceselor economice",
				"autori": {
					"autor": {
						"_nume": "Liviu Popescu"
					}
				},
				"anAparitie": "2007",
				"editura": "Sitech",
				"numarPagini": "252",
				"pretOriginal": "272500",
				"pretCumparare": "272500",
				"coperta": {
					"imagine": {
						"_path": "Modelarea_si_simularea_proceselor_economice_Matematici_aplicate_in_economie_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Modele liniare"
						},
						{
							"_nr": "2",
							"__text": "Modele eliniare"
						},
						{
							"_nr": "3",
							"__text": "Elemente de teoria probabilitatilor"
						},
						{
							"_nr": "4",
							"__text": "Elemente de statistica matematica"
						}
					]
				},
				"_id": "465",
				"_isbn": "973-746-361-7"
			},
			{
				"titlu": "Statistica aplicata in psihologie",
				"autori": {
					"autor": [
						{
							"_nume": "Valentin Clocotici"
						},
						{
							"_nume": "Aurel Stan"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Polirom",
				"numarPagini": "296",
				"pretOriginal": "269500",
				"pretCumparare": "190000",
				"redactori": {
					"redactor": [
						"Adina Cobuz",
						"Paul Paduraru"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Statistica_aplicata_in_psihologie_Clocotici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Scale de masura si statistici"
						},
						{
							"_nr": "3",
							"__text": "Indicatori de masura a imprastierii"
						},
						{
							"_nr": "4",
							"__text": "Prezentarea si sistemetizarea datelor"
						},
						{
							"_nr": "5",
							"__text": "Notiuni de teoria probabilitatilor"
						},
						{
							"_nr": "6",
							"__text": "Inferenta statistica. Estimatii"
						},
						{
							"_nr": "7",
							"__text": "Testarea ipotezelor statistice"
						},
						{
							"_nr": "8",
							"__text": "Asocierea datelor"
						},
						{
							"_nr": "9",
							"__text": "Analiza dispersionala"
						}
					]
				},
				"_id": "466",
				"_isbn": "973-683-577-4"
			},
			{
				"titlu": "Calitatea si fiabilitatea sistemelor mecatronice. Metode de analiza si sinteza",
				"autori": {
					"autor": {
						"_nume": "Ioana Armas"
					}
				},
				"anAparitie": "2004",
				"editura": "Victor",
				"numarPagini": "200",
				"pretOriginal": "70000",
				"pretCumparare": "70000",
				"tehnoredactori": {
					"tehnoredactor": "Florica Budnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Calitatea_si_fiabilitatea_sistemelor_informatice_Armas.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme mecatronice - definitii si caracteristici"
						},
						{
							"_nr": "2",
							"__text": "Calitatea sistemelor mecatronice"
						},
						{
							"_nr": "3",
							"__text": "Conformitatea. Indicatori de conformitate"
						},
						{
							"_nr": "4",
							"__text": "Fiabilitatea sistemelor mecatronice"
						},
						{
							"_nr": "5",
							"__text": "Modelul probabilist de analiza a fiabilitatii sistemelor mecatronice"
						},
						{
							"_nr": "6",
							"__text": "Modelul structural binar de analiza a fiabilitatii sistemelor mecatronice"
						}
					]
				},
				"_id": "467",
				"_isbn": "973-8128-50-1"
			},
			{
				"titlu": "Autoinstruire in programare",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Vaduva"
						},
						{
							"_nume": "Vasile Florescu"
						},
						{
							"_nume": "Dan Farcas"
						},
						{
							"_nume": "Gheorghe Popa"
						},
						{
							"_nume": "Nicolae Mihoci"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Tehnica",
				"numarPagini": "408",
				"pretOriginal": "22",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Ioan Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Mararescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Autoinstruire_in_programare_Vaduva.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Stuctura, functionarea si programarea calculatoarelor electronice"
						},
						{
							"_nr": "2",
							"__text": "Fisire : concept, componente, operatii, organizare si acces"
						},
						{
							"_nr": "3",
							"__text": "Tehnici de descriere si reprezentare a algoritmilor"
						},
						{
							"_nr": "4",
							"__text": "Elemente constitutive ale limbajului COBOL, formate generale COBOL"
						},
						{
							"_nr": "5",
							"__text": "Structura generala si componentele unui program COBOL"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni de lucru cu fisiere memorate pe cartele sau hartie de imprimanta"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni de calcul"
						},
						{
							"_nr": "8",
							"__text": "Testarea conditiilor si instructiunea IF. Validarea datelor"
						},
						{
							"_nr": "9",
							"__text": "Diviziunea ENVIRONMENT: completare"
						},
						{
							"_nr": "10",
							"__text": "Rubrici de descriere a fisierelor si datelor: formate dezvoltate"
						},
						{
							"_nr": "11",
							"__text": "Sortarea fisierelor prin program COBOL"
						},
						{
							"_nr": "12",
							"__text": "Instructiuni de lucru cu fisiere momorate pe suporturi magnetice"
						},
						{
							"_nr": "13",
							"__text": "Modularizarea programelor"
						},
						{
							"_nr": "14",
							"__text": "Sectiunea WORKING-STORAGE si editarea rapoartelor"
						},
						{
							"_nr": "15",
							"__text": "Organizarea si prelucrarea datelor de tip tablou"
						},
						{
							"_nr": "16",
							"__text": "testarea si depanarea programlor"
						},
						{
							"_nr": "17",
							"__text": "Editorul de rapoarte COBOL"
						},
						{
							"_nr": "18",
							"__text": "Limbajul de comanda si cartelele cu punct"
						},
						{
							"_nr": "19",
							"__text": "Organizarea si exploatarea bibliotecilor de programe"
						},
						{
							"_nr": "20",
							"__text": "Modularizarea si structurarea programelor complexe"
						},
						{
							"_nr": "21",
							"__text": "Tipuri de erori si tehnici de depanare a programelor"
						},
						{
							"_nr": "22",
							"__text": "Programe utilitare"
						}
					]
				},
				"_id": "468"
			},
			{
				"titlu": "Fundamente teoretice ale modelului relational al datelor",
				"autori": {
					"autor": {
						"_nume": "Christian Mancas"
					}
				},
				"anAparitie": "2007",
				"editura": "Ovidius University Press",
				"numarPagini": "340",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Fundamente_teoretice_ale_modelului_relational_al_datelor_Mancas.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Constrangeri de integritate primitive"
						},
						{
							"_nr": "3",
							"__text": "Forma normala Boyce-Codd"
						},
						{
							"_nr": "4",
							"__text": "Teoria dependentelor elementare"
						},
						{
							"_nr": "5",
							"__text": "Forme normale 4,5,(3,3) si domenii-chei"
						},
						{
							"_nr": "6",
							"__text": "Critica abordarii relationale a  modelarii datelor"
						},
						{
							"_nr": "7",
							"__text": "Limbaje relationale"
						},
						{
							"_nr": "8",
							"__text": "Probleme propuse spre rezolvare"
						},
						{
							"_nr": "9",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "469",
				"_isbn": "978-973-614-407-3"
			},
			{
				"titlu": "Teoria asteptarii cu aplicatii",
				"autori": {
					"autor": {
						"_nume": "Alex M. Lee"
					}
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"tiraj": "5850+50",
				"numarPagini": "248",
				"pretOriginal": "18",
				"pretCumparare": "80000",
				"colectia": {
					"_nume": "Bazele matematice ale cercetarii operationale",
					"_numarul": "0"
				},
				"traducere": {
					"titluOriginal": "Applied Queuering Theory",
					"editura": "The Macmillan Press",
					"traducatori": {
						"traducator": [
							"Vincentiu Dumitru",
							"Andrei Alexiu",
							"Tiberiu Spircu"
						]
					},
					"_an": "1966",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "Anglia"
				},
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_astepati_cu_aplicatii_Lee.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modele ale sitemelor de asteptare"
						},
						{
							"_nr": "3",
							"__text": "Modele cu o singura statie de servire"
						},
						{
							"_nr": "4",
							"__text": "Modele cu mai multe statii de servire"
						},
						{
							"_nr": "5",
							"__text": "Modele ale sistemelor seriale si ciclice"
						},
						{
							"_nr": "6",
							"__text": "Tehnici de simulare"
						},
						{
							"_nr": "7",
							"__text": "Natura solutiilor operationale"
						},
						{
							"_nr": "8",
							"__text": "Trei tipuri de aproximari"
						},
						{
							"_nr": "9",
							"__text": "Sisteme de rezervare de locuri"
						},
						{
							"_nr": "10",
							"__text": "Metode de control al calatorilor"
						},
						{
							"_nr": "11",
							"__text": "Problema sortarii cupoanelor"
						},
						{
							"_nr": "12",
							"__text": "Patru situatii selectate"
						},
						{
							"_nr": "13",
							"__text": "Proiectarea sediului din Londrea al companiei Bea"
						},
						{
							"_nr": "14",
							"__text": "Probleme de asteptare intr-un sistem de control"
						},
						{
							"_nr": "15",
							"__text": "O problema a standerdelor de servire"
						},
						{
							"_nr": "16",
							"__text": "Concluzii"
						}
					]
				},
				"_id": "470",
				"_isbn": ""
			},
			{
				"titlu": "Lingvistica matematica",
				"autori": {
					"autor": {
						"_nume": "Solomon Marcus"
					}
				},
				"anAparitie": "1966",
				"editura": "Didactica si pedagogica",
				"tiraj": "3600+115",
				"numarPagini": "256",
				"pretOriginal": "17.20",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Borsan Dorin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Prosan Ilinca"
				},
				"coperta": {
					"imagine": {
						"_path": "Lingvistica_matematica_Marcus.jpg"
					},
					"__text": "Eugen Stoian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Aspectul logic al opozitiilor lingvistice"
						},
						{
							"_nr": "II",
							"__text": "Analiza fonematica"
						},
						{
							"_nr": "III",
							"__text": "Analiza morfematica"
						},
						{
							"_nr": "IV",
							"__text": "Categorii gramaticale"
						},
						{
							"_nr": "V",
							"__text": "Modele bazate pe partitii si pe relatia de dominare"
						},
						{
							"_nr": "VI",
							"__text": "Cazul gramtical"
						},
						{
							"_nr": "VII",
							"__text": "Planul continutului si planul expresiei"
						},
						{
							"_nr": "VIII",
							"__text": "Gramatici generative"
						},
						{
							"_nr": "IX",
							"__text": "Cateva interpretati topologice ale unor structuri lingvistice"
						}
					]
				},
				"_id": "471",
				"_isbn": ""
			},
			{
				"titlu": "Info si tehnologie",
				"autori": {
					"autor": {
						"_nume": "John Browning"
					}
				},
				"anAparitie": "1999",
				"editura": "Nemira",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"traducere": {
					"titluOriginal": "Pocket information technology",
					"editura": "The Economist Newspaper",
					"traducatori": {
						"traducator": "Elimian Bazac"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "?"
				},
				"redactori": {
					"redactor": "Corina Ialgoczi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Luminita Catana"
				},
				"coperta": {
					"imagine": {
						"_path": "Info_si_tehnologie_Browning.jpg"
					},
					"__text": "Razvan Luscov"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Eseuri"
						},
						{
							"_nr": "II",
							"__text": "A-Z"
						},
						{
							"_nr": "III",
							"__text": "Anexe"
						}
					]
				},
				"_id": "472",
				"_isbn": "973-569-394-1"
			},
			{
				"titlu": "Criminalitatea informatica",
				"autori": {
					"autor": {
						"_nume": "Ioana Vasiu"
					}
				},
				"anAparitie": "2001",
				"editura": "Nemira",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Marcela Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Camelia Lazar"
				},
				"coperta": {
					"imagine": {
						"_path": "Criminalitatea_informatica_Vasiu.jpg"
					},
					"__text": "Razvan Luscov"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Consideratii introductive"
						},
						{
							"_nr": "2",
							"__text": "Noua era informationala"
						},
						{
							"_nr": "3",
							"__text": "Infractiuni savarsite prin calculator"
						},
						{
							"_nr": "4",
							"__text": "Criminalitatea legata de Internet"
						},
						{
							"_nr": "5",
							"__text": "Prevenirea infractiunilor informatice si mijloace de securitate informatica"
						}
					]
				},
				"_id": "473",
				"_isbn": "973-569-509-X"
			},
			{
				"titlu": "Telecomunicatii",
				"autori": {
					"autor": {
						"_nume": "Jim Chalmers"
					}
				},
				"anAparitie": "1998",
				"editura": "Nemira",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"traducere": {
					"titluOriginal": "Pocket Telecommunications",
					"editura": "The Economist Newspaper",
					"traducatori": {
						"traducator": [
							"Sorin Simion",
							"Gabriel Stoian"
						]
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "?"
				},
				"redactori": {
					"redactor": "Luminita Georgescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Luminita Catana"
				},
				"coperta": {
					"imagine": {
						"_path": "Telecomunicatii_Chalmers.jpg"
					},
					"__text": "Razvan Luscov"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Eseuri"
						},
						{
							"_nr": "II",
							"__text": "A-Z"
						},
						{
							"_nr": "III",
							"__text": "Anexe"
						}
					]
				},
				"_id": "474",
				"_isbn": "973-569-257-7"
			},
			{
				"titlu": "Sisteme senzitive la context",
				"autori": {
					"autor": [
						{
							"_nume": "Anca-Elena Rarau"
						},
						{
							"_nume": "Marcel Cremene"
						},
						{
							"_nume": "Kuderna-Iulian Benta"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Albastra",
				"numarPagini": "178",
				"pretOriginal": "250000",
				"pretCumparare": "200000",
				"colectia": {
					"_numarul": "227",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistem_senzitive_la_context_Rarau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura sistemelor senzitive la context"
						},
						{
							"_nr": "3",
							"__text": "Modele de context"
						},
						{
							"_nr": "4",
							"__text": "Programarea senzitiva la context: paradigme si limbaje de programare"
						},
						{
							"_nr": "5",
							"__text": "Senzitivitatea la context autonoma"
						}
					]
				},
				"_id": "475",
				"_isbn": "978-973-650-224-8"
			},
			{
				"titlu": "Codarea si compresia informatiilor multimedia",
				"autori": {
					"autor": {
						"_nume": "Bogdan Orza"
					}
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "264",
				"pretOriginal": "290000",
				"pretCumparare": "231000",
				"colectia": {
					"_numarul": "222",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Codarea_si_compreia_informatiilor_multimedia_Orza.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "2",
							"__text": "Culoarea"
						},
						{
							"_nr": "3",
							"__text": "Textul"
						},
						{
							"_nr": "4",
							"__text": "Grafica vectoriala"
						},
						{
							"_nr": "5",
							"__text": "Imagine si sunet"
						},
						{
							"_nr": "6",
							"__text": "Compresia datelor multimedia"
						},
						{
							"_nr": "7",
							"__text": "Metode de compresie"
						},
						{
							"_nr": "8",
							"__text": "Standarde de compresie multimedia"
						}
					]
				},
				"_id": "476",
				"_isbn": "978-973-650-212-5"
			},
			{
				"titlu": "Elemente avansate de programare in Lisp si Prolog. Aplicatii in Inteligenta Artificiala",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriela Serban"
						},
						{
							"_nume": "Horia F. Pop"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "270",
				"pretOriginal": "300000",
				"pretCumparare": "240000",
				"colectia": {
					"_numarul": "201",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_avansate_de_programare_in_Lisp_si_Prolog_Serban.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aspecte introductive in programarea declarativa"
						},
						{
							"_nr": "I",
							"__text": "Programare functionala. Limbajul Lisp"
						},
						{
							"_nr": "2",
							"__text": "Importanta programarii functionale ca noua metodologie de programare"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului Lisp"
						},
						{
							"_nr": "4",
							"__text": "Functii si predicate de baza in Lisp. Metoda variabilei colectoare"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea obiectelor in Lisp. Functii cu acces destructiv"
						},
						{
							"_nr": "6",
							"__text": "Mecanisme definitionale evaluate"
						},
						{
							"_nr": "7",
							"__text": "Forme iterative"
						},
						{
							"_nr": "8",
							"__text": "Argumente optionale si macrodefinitii"
						},
						{
							"_nr": "9",
							"__text": "Alte aspecte ale programarii functionale"
						},
						{
							"_nr": "10",
							"__text": "Alte facilitati GCLisp"
						},
						{
							"_nr": "II",
							"__text": "Programare logica. Limbajul Prolog"
						},
						{
							"_nr": "11",
							"__text": "Elemente fundamentele ale limbajului Prolog"
						},
						{
							"_nr": "12",
							"__text": "Constructia programelor Turbo Prolog"
						},
						{
							"_nr": "13",
							"__text": "Aritmetica si comparatii. Operatii de intrare/iesire"
						},
						{
							"_nr": "14",
							"__text": "Controlarea backtracking-ului"
						},
						{
							"_nr": "15",
							"__text": "Repetitii si recursivitate"
						},
						{
							"_nr": "16",
							"__text": "Obiecte simple si obiecte compuse"
						},
						{
							"_nr": "17",
							"__text": "Baza de date interna a sistemului Prolog"
						},
						{
							"_nr": "III",
							"__text": "Programare declarativa orientata obiect"
						},
						{
							"_nr": "18",
							"__text": "Programare Orientata Obiect in Lisp"
						},
						{
							"_nr": "19",
							"__text": "Programare Orientata Obiect in Prolog"
						},
						{
							"_nr": "IV",
							"__text": "Elemente avansate de programare in Lisp si Prolog"
						},
						{
							"_nr": "21",
							"__text": "Domenii de aplicare ale programarii declarative"
						},
						{
							"_nr": "22",
							"__text": "Interactiunea intre limbajele de programarea declarativa si alte limbaje de programare"
						}
					]
				},
				"_id": "477",
				"_isbn": "973-650-175-2"
			},
			{
				"titlu": "Introducere in XUL. XML User interface Language",
				"autori": {
					"autor": {
						"_nume": "Calin Ioan Acu"
					}
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "204",
				"pretOriginal": "240000",
				"pretCumparare": "192000",
				"colectia": {
					"_numarul": "196",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_XUL_Acu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurta introducere in limbajul XUL"
						},
						{
							"_nr": "2",
							"__text": "Despre elementele XUL"
						},
						{
							"_nr": "3",
							"__text": "Modelul Box"
						},
						{
							"_nr": "4",
							"__text": "Elemente XUL cu functii speciale"
						},
						{
							"_nr": "5",
							"__text": "Realizarea meniurilor"
						},
						{
							"_nr": "6",
							"__text": "Grile, casete cu lista multicolore si structuri arborescente"
						},
						{
							"_nr": "Anexa",
							"__text": "XHTML. Diferente fata de HTML 4"
						}
					]
				},
				"_id": "478",
				"_isbn": "973-650-130-2"
			},
			{
				"titlu": "Performantele inteligentei artificiale - de la teorie la aplicatii",
				"autori": {
					"autor": {
						"_nume": "Titu I. Bajenescu"
					}
				},
				"anAparitie": "2002",
				"editura": "Albastra",
				"numarPagini": "162",
				"pretOriginal": "170000",
				"pretCumparare": "136000",
				"colectia": {
					"_numarul": "148",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Performantele_inteligentei_artificiale_Bajenescu.jpg"
					},
					"__text": "Liviu Devesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre performantele inteligentei arificiale (IA)"
						},
						{
							"_nr": "2",
							"__text": "Introducere in IA"
						},
						{
							"_nr": "3",
							"__text": "Cateva teorii asupra inteligentei la om si la animale"
						},
						{
							"_nr": "4",
							"__text": "Inteligenta artificiala"
						},
						{
							"_nr": "5",
							"__text": "Reprezentari de cunostinte"
						},
						{
							"_nr": "6",
							"__text": "Limbajul si inteligenta artificiala"
						},
						{
							"_nr": "7",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "8",
							"__text": "Robotica"
						},
						{
							"_nr": "9",
							"__text": "Realizari si perspective ale IA"
						},
						{
							"_nr": "10",
							"__text": "Cum se construieste o relatie in domeniul marketingului, cu ajutorul IA"
						},
						{
							"_nr": "11",
							"__text": "Mic glosar al inteligentei artificiale (IA)"
						}
					]
				},
				"_id": "479",
				"_isbn": "973-650-070-5"
			},
			{
				"titlu": "Modele de simulare cu calculatorul",
				"autori": {
					"autor": {
						"_nume": "Ion Vaduva"
					}
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"tiraj": "6900+75 exemplare legate",
				"numarPagini": "360",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Lili Ticos"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modele_de_simulare_cu_calculatorul_Vaduva.jpg"
					},
					"__text": "Valetin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati asupra simularii numerice"
						},
						{
							"_nr": "2",
							"__text": "Generarea numerelor aleatoare uniforme"
						},
						{
							"_nr": "3",
							"__text": "Metode de generarea variabiabilelor aleatoare neuniforme"
						},
						{
							"_nr": "4",
							"__text": "Modele de simulare"
						}
					]
				},
				"_id": "480",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in programarea automata",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "Nicolae Popoviciu"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Didactica si pedagogica",
				"tiraj": "4300+120 exemplare legate",
				"numarPagini": "222",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Magdalena Iolanda Stavinshi"
				},
				"tehnoredactori": {
					"tehnoredactor": "V. Biris"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programarea_automata_Vaduva.jpg"
					},
					"__text": "I. Dragan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in cunoasterea unui sistem de calcul"
						},
						{
							"_nr": "2",
							"__text": "Metode matematice si algoritme pentru probleme de cercetare stiintifica"
						},
						{
							"_nr": "3",
							"__text": "Introducere in limbajul Fortran"
						},
						{
							"_nr": "4",
							"__text": "Folosirea memorillor externe in probleme de cercetare stiintifica"
						},
						{
							"_nr": "5",
							"__text": "Probleme rezolvate"
						}
					]
				},
				"_id": "481",
				"_isbn": ""
			},
			{
				"titlu": "Compresie de date. Algoritmi de predictie",
				"autori": {
					"autor": {
						"_nume": "Rodica Stoian"
					}
				},
				"anAparitie": "1988",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "278",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "D. I. Nechita"
				},
				"tehnoredactori": {
					"tehnoredactor": "Angela Ilovan"
				},
				"coperta": {
					"imagine": {
						"_path": "Compresie_de_date_Algoritmi_de_predictie_Stoian.jpg"
					},
					"__text": "Adrian Ionescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Surse de informatie. Cnale de comunicatie"
						},
						{
							"_nr": "2",
							"__text": "Functia rata-distorsiune"
						},
						{
							"_nr": "3",
							"__text": "Aplicarea teoriei rata-distorsiune in tehnicile de compresie"
						},
						{
							"_nr": "4",
							"__text": "Cunatizarea in sistemele de compresie"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi de apertura utilizati in sistemele de compresie prin codere cu pas variabil"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi de predictie liniara"
						},
						{
							"_nr": "I",
							"__text": "Masurarea distorsiunilor in spatii vectoriale"
						},
						{
							"_nr": "II",
							"__text": "Transformari ortogonale discrete"
						},
						{
							"_nr": "III",
							"__text": "Modele utilizate in codarea predictiva a semnalului vocal si semnalului imagine"
						}
					]
				},
				"_id": "482",
				"_isbn": ""
			},
			{
				"titlu": "Algoritmi de compilare cu aplicatii la limbajele de tipul Algol",
				"autori": {
					"autor": {
						"_nume": "Dragos Vaida"
					}
				},
				"anAparitie": "1971",
				"editura": "Didactica si pedagogica",
				"numarPagini": "216",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "C. Cioaca"
				},
				"tehnoredactori": {
					"tehnoredactor": "T. Balaita"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_de_compilare_Vaida.jpg"
					},
					"__text": "O. Magheran"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Compilarea programelor : trecerile multiple, recursivitatea, generarea dinamica"
						},
						{
							"_nr": "3",
							"__text": "Analiza sintactica predictiva"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea stocurilor"
						},
						{
							"_nr": "5",
							"__text": "Transcrierea  progemului  in notatia poloneza inversa"
						},
						{
							"_nr": "6",
							"__text": "Operatorul de transfer neconditionat"
						},
						{
							"_nr": "7",
							"__text": "Operatorul de ciclare"
						},
						{
							"_nr": "8",
							"__text": "Compilarea procedurilor"
						},
						{
							"_nr": "9",
							"__text": "Exercitii"
						},
						{
							"_nr": "10",
							"__text": "Anexe"
						}
					]
				},
				"_id": "483",
				"_isbn": ""
			},
			{
				"titlu": "Structuri matematice discrete. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Alexandru Mateescu"
						},
						{
							"_nume": "Dragos Vaida"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "216",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Luminita Zorilescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_matematice_discrete_Vaida.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conditii de iterativitate de tip W. Ogden si aplicatii la limbajele de programare"
						},
						{
							"_nr": "2",
							"__text": "Semantica partial aditiva"
						},
						{
							"_nr": "3",
							"__text": "Gramatici van Wijngaarden"
						},
						{
							"_nr": "4",
							"__text": "Structuri algebrice orinate in semantica"
						}
					]
				},
				"_id": "484",
				"_isbn": "973-27-0014-9"
			},
			{
				"titlu": "Limbaje formale",
				"autori": {
					"autor": {
						"_nume": "Gabriel V. Orman"
					}
				},
				"anAparitie": "1982",
				"editura": "Tehnica",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "1",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_Orman.jpg"
					},
					"__text": "Cosntantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Preliminarii matematice"
						},
						{
							"_nr": "2",
							"__text": "Consideratii generale asupra limbajelor formale"
						},
						{
							"_nr": "3",
							"__text": "Limbaje formale. Ierarhia lui Chomsky"
						},
						{
							"_nr": "4",
							"__text": "Acceptori"
						},
						{
							"_nr": "5",
							"__text": "Limbaje independente de context"
						},
						{
							"_nr": "6",
							"__text": "Multimi regulate"
						},
						{
							"_nr": "7",
							"__text": "Limbaje dependente de context"
						},
						{
							"_nr": "8",
							"__text": "Multimi enumerabile recursiv si masini Turing"
						},
						{
							"_nr": "9",
							"__text": "Gramatici si limbaje contextuale Marcus"
						},
						{
							"_nr": "10",
							"__text": "Alte rezultate semnificative"
						}
					]
				},
				"_id": "485",
				"_isbn": ""
			},
			{
				"titlu": "Grafuri de fluenta si aplicatii in tehnica",
				"autori": {
					"autor": [
						{
							"_nume": "Nicolae Ghircoiasiu"
						},
						{
							"_nume": "Costin Miron"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"numarPagini": "136",
				"pretOriginal": "5.50",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "12",
					"_nume": "Matematici moderne aplicate"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafuri_de_fluenta_Miron.jpg"
					},
					"__text": "Cosntantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni fundamentale din teoria grafurilor"
						},
						{
							"_nr": "2",
							"__text": "Grafuri de fluenta"
						},
						{
							"_nr": "3",
							"__text": "Transformari elementare"
						},
						{
							"_nr": "4",
							"__text": "Regula lui Mason"
						},
						{
							"_nr": "5",
							"__text": "Regula lui Coates"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii ale grafurilor de fluenta"
						}
					]
				},
				"_id": "486",
				"_isbn": ""
			},
			{
				"titlu": "Modele matematice de organizarea si conducerea productiei",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "C. Dinescu"
						},
						{
							"_nume": "B. Savulescu"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Didactica si pedagogica",
				"numarPagini": "320",
				"pretOriginal": "18.60",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "I. Tomescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "V. Biris"
				},
				"coperta": {
					"imagine": {
						"_path": "Modele_matematice_de_organizarea_si_conducerea_productiei_I_Vaduva.jpg"
					},
					"__text": "V. Wegeman"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Succesiuni de faze in procesul de productie"
						},
						{
							"_nr": "I",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "1",
							"__text": "Notinui generale despre grafe"
						},
						{
							"_nr": "2",
							"__text": "Feluri de grafe (retele)"
						},
						{
							"_nr": "3",
							"__text": "Legaturi dintre varfurile unui graf (retea)"
						},
						{
							"_nr": "4",
							"__text": "Grade (retele) deduse dintr-un graf (retea) dat"
						},
						{
							"_nr": "5",
							"__text": "Grade intr-un graf (retea)"
						},
						{
							"_nr": "6",
							"__text": "Matrice asociate grafelor (retelor)"
						},
						{
							"_nr": "II",
							"__text": "Determinarea succesiunilor optime de faze"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Algoritmul A"
						},
						{
							"_nr": "III",
							"__text": "Determinarea succesiunilor optime de faze conditionate"
						},
						{
							"_nr": "1",
							"__text": "Determinarea succesiunilor optime care contin toate fazele unui sistem fara circuite"
						},
						{
							"_nr": "2",
							"__text": "Determinarea succesiunilor optime care contin toate fazele unui sistem cu circuite"
						},
						{
							"_nr": "3",
							"__text": "Determinarea succesiunilor optime, care trebuie sa treaca prin anumite faze ale unui sistem fara circuite"
						},
						{
							"_nr": "4",
							"__text": "Determinarea succesiunilor optime, care trebuie sa treaca prin anumite faze ale unui sistem cu circuite"
						},
						{
							"_nr": "IV",
							"__text": "Legaturi de importanta majora intre fazele unui proces de productie"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Determinarea arcelor-legaturi unice in grafe fara circuite"
						},
						{
							"_nr": "3",
							"__text": "Determinarea arcelor-legaturi unice intr-un graf care contine circuite"
						},
						{
							"_nr": "4",
							"__text": "Determinarea unui graf cu grad minimal fata de un graf care contine circuite"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Determinarea planului optim de productie in cazul unor resurse date"
						},
						{
							"_nr": "V",
							"__text": "Modele de programare liniara si tehnica simplex"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "VI",
							"__text": "Modificari in problemele de programare liniara"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modelul dual al problemei de programre liniara"
						},
						{
							"_nr": "3",
							"__text": "Schimbari ale valorilor parametrului modelului"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Refucerea timpilor de asteptare, intre fazele procesului de productie"
						},
						{
							"_nr": "VII",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Limbaj folosite in femomenele de asteptare"
						},
						{
							"_nr": "3",
							"__text": "Clasificare fenomenelor de asteptare"
						},
						{
							"_nr": "4",
							"__text": "Distributia Poisson si legatura ei cu fenomentele de asteptare"
						},
						{
							"_nr": "5",
							"__text": "Repartitia exponentiala si legatura ei cu fenomentele de asteptare"
						},
						{
							"_nr": "VIII",
							"__text": "Tipuri de modele de asteptare"
						},
						{
							"_nr": "1",
							"__text": "Model cu un fir de asteptare, o statie, populatie infinita"
						},
						{
							"_nr": "2",
							"__text": "Model cu un fir de asteptare, o statie, populatie finita"
						},
						{
							"_nr": "3",
							"__text": "Model cu un fir de asteptare, mai multe statii, populatie infinita"
						},
						{
							"_nr": "4",
							"__text": "Model cu un fir de asteptare, mai multe statii, populatie finita"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Scheme logice de calcul"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Rezolvarea sistemelor de ecuatii liniare prin metoda Gauss-Jordan"
						}
					]
				},
				"_id": "487",
				"_isbn": ""
			},
			{
				"titlu": "Modele matematice de organizarea si conducerea productiei - Vol II",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "C Dinescu"
						},
						{
							"_nume": "B Savulescu"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Didactica si pedagogica",
				"tiraj": "4390",
				"numarPagini": "324",
				"pretOriginal": "20.20",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Stavinschi Magda-Iolanda"
				},
				"tehnoredactori": {
					"tehnoredactor": "V. Biris"
				},
				"coperta": {
					"imagine": {
						"_path": "Modele_matematice_de_organizarea_si_conducerea_productiei_II_Vaduva.jpg"
					},
					"__text": "V. Wegeman"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Organizarea fluxurilor in procesul de productie"
						},
						{
							"_nr": "I",
							"__text": "Determinarea fluxurilor optime in procesul de productie"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Fluxul optim intr-o retea oarecare cu restrictie de capacitate"
						},
						{
							"_nr": "3",
							"__text": "Fluxul optim intr-o retea bipartita cu restrictie de cost"
						},
						{
							"_nr": "4",
							"__text": "Fluxul optim intr-o retea oarecare, cu restrictie de cost si restrictie de capacitate"
						},
						{
							"_nr": "II",
							"__text": "Probleme de productie reductibile la probleme e fluxuri in retele"
						},
						{
							"_nr": "1",
							"__text": "Probleme de repartitii de sarcini in procesul de productie"
						},
						{
							"_nr": "2",
							"__text": "Probleme de organizare a productiei, tinand seama de rationalizarea transporturilor si a stocurilor"
						},
						{
							"_nr": "3",
							"__text": "Probleme de transport cu capacitati limitate"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Probleme de programare a productiei"
						},
						{
							"_nr": "III",
							"__text": "Tehnici de optimizare pentru probleme de programare liniara in numere intregi"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Metoda de tip 'Branch and bound' (ramificare si limitare)"
						},
						{
							"_nr": "3",
							"__text": "Metoda planului de sectiune"
						},
						{
							"_nr": "IV",
							"__text": "Tehnici de optimizare a unor probleme de programare neliniara"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni si propozitii ajutatoare"
						},
						{
							"_nr": "3",
							"__text": "Extremele functiilor de o variabila"
						},
						{
							"_nr": "4",
							"__text": "Extremele functiilor de doua sau mai multe variabile"
						},
						{
							"_nr": "5",
							"__text": "Determinarea extremelor unei functii cu ajutorul gradientului"
						},
						{
							"_nr": "6",
							"__text": "Determinarea extremelor functiilor restricionate prin egalitati"
						},
						{
							"_nr": "7",
							"__text": "Determinarea extremelor functiilor restricionate prin inegalitati. Conditiile Kuhn-Tucker"
						},
						{
							"_nr": "8",
							"__text": "Folosirea algoritmului simplex la rezolvarea problemelor de programare patratica cu ajutorul conditiilor Kuhn-Tucker"
						},
						{
							"_nr": "9",
							"__text": "Rezolvarea problemelor de programare patratica cu algoritmul lui Beale"
						},
						{
							"_nr": "10",
							"__text": "Rezolvarea problemelor de programare patratica cu aproxmarea functiilor neliniare cu functii liniare"
						},
						{
							"_nr": "11",
							"__text": "Rezolvarea problemelor de programare neliniara prin metoda secventiala"
						},
						{
							"_nr": "12",
							"__text": "Rezolvarea problemelor de programare neliniara in numera intregi"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Planificarea optima a stocurilr de produse si materiale"
						},
						{
							"_nr": "V",
							"__text": "Modele statice deterministe"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Generalitati si notatii"
						},
						{
							"_nr": "3",
							"__text": "Modelul clasic al 'lotului economic'"
						},
						{
							"_nr": "4",
							"__text": "Modelul clasic al 'lipsei de stoc'"
						},
						{
							"_nr": "5",
							"__text": "Variante ale modelului clasic"
						},
						{
							"_nr": "6",
							"__text": "Modele cu cerere discreta"
						},
						{
							"_nr": "7",
							"__text": "Modele pentru stocarea a mai multe tipuri de produse"
						},
						{
							"_nr": "VI",
							"__text": "Modele aleatoare ale teoriei stocurilor"
						},
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "O varianta stochastica generala a modelului clasic"
						},
						{
							"_nr": "3",
							"__text": "Un model cu cost de lansare nul"
						},
						{
							"_nr": "4",
							"__text": "Problema vanzatorului de ziare"
						},
						{
							"_nr": "5",
							"__text": "Un model de cerere dependenta de timpul de livrare"
						},
						{
							"_nr": "6",
							"__text": "Un model pentru stocul de rezerva optim intre masini in productia de serie"
						},
						{
							"_nr": "VII",
							"__text": "Modele de stocare bazate pe teoria cozilor"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modelul Exp(lambda)/Exp(mu)/1:(Infinity, FIFO)"
						},
						{
							"_nr": "3",
							"__text": "Un model cu mai multe statii paralele si cu timp de avans aleator"
						},
						{
							"_nr": "4",
							"__text": "Alt model cu statii paralele si cu timp de avans aleator (cererea nesatisfacuta se pastreaza)"
						},
						{
							"_nr": "5",
							"__text": "Un model cu reaprovizionare in loturi"
						}
					]
				},
				"_id": "488",
				"_isbn": ""
			},
			{
				"titlu": "Tratat de programarea calculatoarelor. Algoritmi seminumerici. Volumul 2",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "1983",
				"editura": "Tehnica",
				"numarPagini": "726",
				"pretOriginal": "73",
				"pretCumparare": "0",
				"traducere": {
					"titluOriginal": "The art of computer programming. Seminumerical Algorithms",
					"editura": "Addison Wesley Publishing Company",
					"traducatori": {
						"traducator": [
							"Florin Petrescu",
							"Ioan Georgescu",
							"Rolanda Predescu"
						]
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "2",
					"_an": "1981"
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Tratat_de_programare_a_calculatoarelor_II_Knuth.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "3",
							"__text": "Numere aleatoare"
						},
						{
							"_nr": "3.1",
							"__text": "Introducere"
						},
						{
							"_nr": "3.2",
							"__text": "Generarea numerelor aleatoare uniforme"
						},
						{
							"_nr": "3.3",
							"__text": "Teste statistice"
						},
						{
							"_nr": "3.4",
							"__text": "Alte tipuri de cantitati aleatoare"
						},
						{
							"_nr": "3.5",
							"__text": "Ce este un sir aleator"
						},
						{
							"_nr": "3.6",
							"__text": "Rezumat"
						},
						{
							"_nr": "4",
							"__text": "Aritmetica"
						},
						{
							"_nr": "4.1",
							"__text": "Sisteme pozitionale de numeratie"
						},
						{
							"_nr": "4.2",
							"__text": "Aritmetica numerelor reprezentate cu punct mobil"
						},
						{
							"_nr": "4.3",
							"__text": "Aritmetica in precizie multipla"
						},
						{
							"_nr": "4.4",
							"__text": "Conversia bazelor"
						},
						{
							"_nr": "4.5",
							"__text": "Aritmetica rationala"
						},
						{
							"_nr": "4.6",
							"__text": "Aritmetica polinoamelor"
						},
						{
							"_nr": "4.7",
							"__text": "Operatii cu serii de puteri"
						}
					]
				},
				"_id": "489"
			},
			{
				"titlu": "Arta programarii calculatoarelor. Algoritmi fundamentali. Volumul 1",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "616",
				"pretOriginal": "450000",
				"pretCumparare": "490500",
				"colectia": {
					"_numarul": "280",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The Art of Computer Programming - Volume 1. Fundamental Algorithms, Third Edition",
					"editura": "Addison Wesley Longman",
					"traducatori": {
						"traducator": "Mihaela Tarpa"
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "3",
					"_an": "1998"
				},
				"coperta": {
					"imagine": {
						"_path": "Arta_programarii_calculatoarelor_I_Knuth.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "1.1",
							"__text": "Algoritmi"
						},
						{
							"_nr": "1.2",
							"__text": "Preliminarii matematice"
						},
						{
							"_nr": "1.3",
							"__text": "M I X"
						},
						{
							"_nr": "1.4",
							"__text": "Tehnici fundamentale de programare"
						},
						{
							"_nr": "2",
							"__text": "Structuri de informatii"
						},
						{
							"_nr": "2.1",
							"__text": "Introducere"
						},
						{
							"_nr": "2.2",
							"__text": "Liste liniare"
						},
						{
							"_nr": "2.3",
							"__text": "Arbori"
						},
						{
							"_nr": "2.4",
							"__text": "Structuri multiplu inlantuite"
						},
						{
							"_nr": "2.5",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "2.6",
							"__text": "Istoric si bibliografie"
						}
					]
				},
				"_id": "490"
			},
			{
				"titlu": "Programe de contabilitate in Visual Basic",
				"autori": {
					"autor": [
						{
							"_nume": "Bogdan Patrut"
						},
						{
							"_nume": "Alina Branzei"
						},
						{
							"_nume": "Marius Cernat"
						},
						{
							"_nume": "Camelia Dobos"
						},
						{
							"_nume": "Alexandru Munteanu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "EduSoft",
				"numarPagini": "110",
				"pretOriginal": "65400",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Programe_de_contabilitate_in_Visual_Basic_Patrut.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Planul de conturi"
						},
						{
							"_nr": "2",
							"__text": "Amortizarea"
						},
						{
							"_nr": "3",
							"__text": "Cartea mare"
						},
						{
							"_nr": "4",
							"__text": "Program cu elemente din statistica"
						},
						{
							"_nr": "5",
							"__text": "Stat de plata"
						},
						{
							"_nr": "6",
							"__text": "Agenda electronica"
						}
					]
				},
				"_id": "491",
				"_isbn": "973-8934-17-6"
			},
			{
				"titlu": "Metoda Backtracking cu exemple in limbajul C",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Socaciu"
					}
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "108",
				"pretOriginal": "109000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Metoda_Backtraking_C_Socaciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metoda backtraking"
						},
						{
							"_nr": "2",
							"__text": "Backtracking pe matrice"
						},
						{
							"_nr": "3",
							"__text": "Backtracking cu solutii de lungime variabila"
						},
						{
							"_nr": "4",
							"__text": "Backtracking cu miscari"
						},
						{
							"_nr": "5",
							"__text": "Backtracking in grafuri"
						},
						{
							"_nr": "6",
							"__text": "Backtracking in combinatorica"
						},
						{
							"_nr": "7",
							"__text": "Backtracking pe tabla de sah"
						}
					]
				},
				"_id": "492",
				"_isbn": "973-87496-6-2"
			},
			{
				"titlu": "Metoda Backtracking cu exemple in limbajul Pascal",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Socaciu"
					}
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "108",
				"pretOriginal": "109000",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Metoda_Backtacking_Pascal_Socaciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metoda backtraking"
						},
						{
							"_nr": "2",
							"__text": "Backtracking pe matrice"
						},
						{
							"_nr": "3",
							"__text": "Backtracking cu solutii de lungime variabila"
						},
						{
							"_nr": "4",
							"__text": "Backtracking cu miscari"
						},
						{
							"_nr": "5",
							"__text": "Backtracking in grafuri"
						},
						{
							"_nr": "6",
							"__text": "Backtracking in combinatorica"
						},
						{
							"_nr": "7",
							"__text": "Backtracking pe tabla de sah"
						}
					]
				},
				"_id": "493",
				"_isbn": "973-87496-5-4"
			},
			{
				"titlu": "Limbaje de simulare",
				"autori": {
					"autor": {
						"_nume": "Eduard Radaceanu"
					}
				},
				"anAparitie": "1981",
				"editura": "Militara",
				"numarPagini": "272",
				"pretOriginal": "20",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "D. Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_simulare_Radaceanu.jpg"
					},
					"__text": "V. Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metoda simularii"
						},
						{
							"_nr": "2",
							"__text": "Limbaje de simulare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul de simulare GPSS/360"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "494",
				"_isbn": ""
			},
			{
				"titlu": "Simularea proceselor econimice",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "Marcel Stoica"
						},
						{
							"_nume": "Ioan Ogagescu"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Tehnica",
				"numarPagini": "284",
				"pretOriginal": "21",
				"pretCumparare": "5000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Simularea_proceselor_economice_Vaduva.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generarea cu calculatorul a marimilor aleatoare"
						},
						{
							"_nr": "2",
							"__text": "Metode de cautare a optimului bazate pr simulare"
						},
						{
							"_nr": "3",
							"__text": "Pachete de programe de simulare"
						},
						{
							"_nr": "4",
							"__text": "Simularea proceselor de servire"
						},
						{
							"_nr": "5",
							"__text": "Simularea proceselor decizionale"
						},
						{
							"_nr": "6",
							"__text": "Probleme de clasificare de dimensiuni mari"
						},
						{
							"_nr": "7",
							"__text": "Simularea proceselor de stocare"
						},
						{
							"_nr": "8",
							"__text": "Simularea procesului de productie"
						},
						{
							"_nr": "9",
							"__text": "Simularea proceselor de reparatii"
						},
						{
							"_nr": "10",
							"__text": "Jocuri de intreprindere"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii ale simularii in macroeconomie"
						}
					]
				},
				"_id": "495",
				"_isbn": ""
			},
			{
				"titlu": "Bazele cercetatii operationale",
				"autori": {
					"autor": [
						{
							"_nume": "Russell.L. Ackoff"
						},
						{
							"_nume": "Maurice .W. Sasieni"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"numarPagini": "564",
				"pretOriginal": "31",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"traducere": {
					"titluOriginal": "Fundamentals of operations research",
					"editura": "John Willey and Sons,Inc",
					"traducatori": {
						"traducator": [
							"C. Berghtaller",
							"S. Rudeanu"
						]
					},
					"_an": "1968",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_cercetari_operationale_Ackoff.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere: Natura cercetarii operationale"
						},
						{
							"_nr": "2",
							"__text": "Formularea problemelor"
						},
						{
							"_nr": "3",
							"__text": "Constructia modelului"
						},
						{
							"_nr": "4",
							"__text": "Rezolvarea modelelor"
						},
						{
							"_nr": "5",
							"__text": "Probleme de alocare : Distribuirea si repartizarea resurselor"
						},
						{
							"_nr": "6",
							"__text": "Probleme de alocare liniara generala"
						},
						{
							"_nr": "7",
							"__text": "Teoria stocurilor"
						},
						{
							"_nr": "8",
							"__text": "Teoria reinnoirii si fiabilitate"
						},
						{
							"_nr": "9",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "10",
							"__text": "Teoria asteptarii"
						},
						{
							"_nr": "11",
							"__text": "Probleme de ordonantare si de coordonare (PERT si drum critic)"
						},
						{
							"_nr": "12",
							"__text": "Probleme de drumuri in retele"
						},
						{
							"_nr": "13",
							"__text": "Probleme de competitie"
						},
						{
							"_nr": "14",
							"__text": "Probleme de cautare"
						},
						{
							"_nr": "15",
							"__text": "Testarea modelui si a solutie"
						},
						{
							"_nr": "16",
							"__text": "Implementarea si controlul solutie"
						},
						{
							"_nr": "17",
							"__text": "Epilog : Frontierele cercetarii operationale"
						}
					]
				},
				"_id": "496",
				"_isbn": ""
			},
			{
				"titlu": "Matematica organizarii (editia II)",
				"autori": {
					"autor": [
						{
							"_nume": "Mircea Malita"
						},
						{
							"_nume": "Corneliu Zidaroiu"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "3200+75 exemplare legate",
				"numarPagini": "416",
				"pretOriginal": "23.50",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Matematica_organizarii_Malita.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Probleme de optimizare"
						},
						{
							"_nr": "1",
							"__text": "Modelarea matematica a problemelor de optimizare"
						},
						{
							"_nr": "2",
							"__text": "Programe liniare"
						},
						{
							"_nr": "3",
							"__text": "Programe neliniare"
						},
						{
							"_nr": "4",
							"__text": "Programe dinamice"
						},
						{
							"_nr": "5",
							"__text": "Elemente de teoria jocurilor"
						},
						{
							"_nr": "6",
							"__text": "Programe aleatoare"
						},
						{
							"_nr": "7",
							"__text": "Elemente de teoria grafurilor"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Probleme de analiza statistica"
						},
						{
							"_nr": "8",
							"__text": "Analiza dispersionala"
						},
						{
							"_nr": "9",
							"__text": "Controlul statistica al calitatii"
						},
						{
							"_nr": "10",
							"__text": "Elemente ale teoriei echipamentului"
						},
						{
							"_nr": "11",
							"__text": "Elemnte de teoria asteptarii"
						},
						{
							"_nr": "12",
							"__text": "Elemente de teoria stocurilor"
						}
					]
				},
				"_id": "497",
				"_isbn": ""
			},
			{
				"titlu": "Programare dinamica discreta",
				"autori": {
					"autor": {
						"_nume": "Corneliu Zidaroiu"
					}
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "2800+65 exemplare legate",
				"numarPagini": "284",
				"pretOriginal": "17",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_dinamica_discreta_Zidaroiu.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Programare dinamica determinista cu orizont finit"
						},
						{
							"_nr": "3",
							"__text": "Programare dinamica determinista cu orizont infinit"
						},
						{
							"_nr": "4",
							"__text": "Programare dinamica aleatoare cu orizont finit"
						},
						{
							"_nr": "5",
							"__text": "Programare dinamica aleatoare cu orizont infinit"
						},
						{
							"_nr": "6",
							"__text": "Lanturi Markov finite"
						},
						{
							"_nr": "7",
							"__text": "Lanturi Markov finite reglabile"
						}
					]
				},
				"_id": "498",
				"_isbn": ""
			},
			{
				"titlu": "Programare liniara",
				"autori": {
					"autor": {
						"_nume": "Corneliu Zidaroiu"
					}
				},
				"anAparitie": "1983",
				"editura": "Tehnica",
				"numarPagini": "376",
				"pretOriginal": "27",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_liniara_Zidaroiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bazele matematice ale programarii liniare"
						},
						{
							"_nr": "2",
							"__text": "Metoda simplex"
						},
						{
							"_nr": "3",
							"__text": "Dualitatea in programarea liniara"
						},
						{
							"_nr": "4",
							"__text": "Postoptimizare si parametrizare"
						},
						{
							"_nr": "5",
							"__text": "Problema de transport"
						},
						{
							"_nr": "6",
							"__text": "Programare liniara multicriteriala"
						},
						{
							"_nr": "7",
							"__text": "Descompunere in programarea liniara"
						}
					]
				},
				"_id": "499",
				"_isbn": ""
			},
			{
				"titlu": "Metode ale cercetarii operationale in gestiunea intreprinderilor",
				"autori": {
					"autor": [
						{
							"_nume": "N. Teodorescu"
						},
						{
							"_nume": "Gh. Boldur"
						},
						{
							"_nume": "M. Stoica"
						},
						{
							"_nume": "I. Stancu-Minasian"
						},
						{
							"_nume": "I. Bancila"
						}
					]
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "5600+140+40 exemplare legate",
				"numarPagini": "286",
				"pretOriginal": "18",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_ale_cercetatii_operationale_in_gestiunea_intreprinderilor_Teodorescu.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conceptul modern de gestiune a intreprinderii"
						},
						{
							"_nr": "2",
							"__text": "Decizii multidimensionale in gestiunea intreprinderilor"
						},
						{
							"_nr": "3",
							"__text": "Programare operativa a productiei. Probleme generale"
						},
						{
							"_nr": "4",
							"__text": "Programare operativa a productiei. Probleme dinamice"
						},
						{
							"_nr": "5",
							"__text": "Problema croirii elementelor dreptunghiulare"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Notiuni din teoria grafurilor simple"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Terminologie folosita in programarea operativa a productiei"
						},
						{
							"_nr": "7",
							"__text": "Lanturi Markov finite reglabile"
						}
					]
				},
				"_id": "500",
				"_isbn": ""
			},
			{
				"titlu": "Metode matematice in optimizarea croirii",
				"autori": {
					"autor": [
						{
							"_nume": "Lucian Doru Duta"
						},
						{
							"_nume": "Csaba Fabian"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Tehnica",
				"numarPagini": "152",
				"pretOriginal": "12",
				"pretCumparare": "5000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Bazele matematice ale cercetarii operationale"
				},
				"redactori": {
					"redactor": "Ticos Lina"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_matematice_in_optimizarea_croirii_Duta.jpg"
					},
					"__text": "Valentin Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea problemelor de croire"
						},
						{
							"_nr": "2",
							"__text": "Metode de rezolvarea a problemelor de croire prin programare dinamica"
						},
						{
							"_nr": "3",
							"__text": "Rezolvarea problemelor de croire prin metoda Monte Carlo"
						},
						{
							"_nr": "4",
							"__text": "Pachetul de programe CROCODIL"
						},
						{
							"_nr": "5",
							"__text": "Alte pachete de programe utilizate in tara"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii in economia nationala"
						}
					]
				},
				"_id": "501",
				"_isbn": ""
			},
			{
				"titlu": "Initiere in EXCEL",
				"autori": {
					"autor": [
						{
							"_nume": "Elvira Nicoleta Bizdoaca"
						},
						{
							"_nume": "Sorin Matei"
						},
						{
							"_nume": "Nicu George Bizdoaca"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Arves",
				"numarPagini": "200",
				"pretOriginal": "60000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "2",
					"_nume": "Initiere in calculatoare"
				},
				"coperta": {
					"imagine": {
						"_path": "Initire_in_Excel_Bizdoaca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prefata"
						},
						{
							"_nr": "2",
							"__text": "Introducere"
						},
						{
							"_nr": "3",
							"__text": "Prezentare meniurilor"
						},
						{
							"_nr": "Capitolul 1",
							"__text": "Operatii de baza"
						},
						{
							"_nr": "Capitolul 2",
							"__text": "Gestiunea agendelor de lucru"
						},
						{
							"_nr": "Capitolul 3",
							"__text": "Operatii fundamentale de editare a datelor"
						},
						{
							"_nr": "Capitolul 4",
							"__text": "Operatii speciale de editare"
						},
						{
							"_nr": "Capitolul 5",
							"__text": "Gestiunea liniilor si coloanelor"
						},
						{
							"_nr": "Capitolul 6",
							"__text": "Formatarea celulelor"
						},
						{
							"_nr": "Capitolul 7",
							"__text": "Utilizarea formulelor si a functiilor"
						},
						{
							"_nr": "Capitolul 8",
							"__text": "Diagrame - Grafice"
						},
						{
							"_nr": "Capitolul 9",
							"__text": "Baze de date"
						},
						{
							"_nr": "Capitolul 10",
							"__text": "Gestiunea foilor de calcul"
						},
						{
							"_nr": "Capitolul 11",
							"__text": "Configurarea si listarea unei foi de calcul"
						},
						{
							"_nr": "Capitolul 12",
							"__text": "Barele de unelte"
						}
					]
				},
				"_id": "502",
				"_isbn": "973-85773-0-6"
			},
			{
				"titlu": "Programarea calculatoarelor [clasa X]",
				"autori": {
					"autor": [
						{
							"_nume": "Stelian Niculescu"
						},
						{
							"_nume": "Sorin Eftene"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Didactica si pedagogica",
				"numarPagini": "224",
				"pretOriginal": "6500",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Valentin Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Sorin Eftene",
						"Stelian Niculescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_Niculescu.jpg"
					},
					"__text": "Stelian Niculescu,Sorin Eftene"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiunea de algoritm"
						},
						{
							"_nr": "2",
							"__text": "Notiunea de programare structurata"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "4",
							"__text": "Structuri de control"
						},
						{
							"_nr": "5",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "6",
							"__text": "Subprograme"
						},
						{
							"_nr": "7",
							"__text": "Fisiere"
						},
						{
							"_nr": "8",
							"__text": "Unit-urile Turbo Pascal"
						},
						{
							"_nr": "9",
							"__text": "Elemente de grafica in Pascal"
						},
						{
							"_nr": "10",
							"__text": "Probleme recapitulative"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Coduri ASCII (standard)"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Cuvinte rezervate"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Functii si proceduri standard"
						}
					]
				},
				"_id": "503",
				"_isbn": "973-30-5754-1"
			},
			{
				"titlu": "Informatica [Clasa IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Doina Rancea"
						},
						{
							"_nume": "Gabriela Balan"
						},
						{
							"_nume": "Ioana Cucu"
						},
						{
							"_nume": "Diana Gruita"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Libris",
				"numarPagini": "320",
				"pretOriginal": "60000",
				"pretCumparare": "0",
				"redactori": {
					"redactor": [
						"Clara Ionescu",
						"Mihai Scortaru"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Carmen Bucur",
						"Clara Ionescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Rancea.jpg"
					},
					"__text": "Adrian Pop"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme de calcul"
						},
						{
							"_nr": "II",
							"__text": "Sistemul de operare MS-DOS"
						},
						{
							"_nr": "III",
							"__text": "Sistemul de operare Microsoft Windows 95"
						},
						{
							"_nr": "IV",
							"__text": "Algoritmi"
						},
						{
							"_nr": "V",
							"__text": "Elemente de baza ale limbajelor de programare"
						}
					]
				},
				"_id": "504",
				"_isbn": "973-97515-7-1"
			},
			{
				"titlu": "Prelucrarea fisierelor in Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Constantin Apostol"
						},
						{
							"_nume": "Bogdan Ghilic-Micu"
						},
						{
							"_nume": "Valer Rosca"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "220",
				"pretOriginal": "3500",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "V.E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea fisierelor_in_Pascal_Rosca.jpg"
					},
					"__text": "Simona Dumitrecu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organizarea externa a datelor in fisiere"
						},
						{
							"_nr": "2",
							"__text": "Tupul de data articol"
						},
						{
							"_nr": "3",
							"__text": "Asignarea fisierelor logice la fisierele fizice"
						},
						{
							"_nr": "4",
							"__text": "Fisire text"
						},
						{
							"_nr": "5",
							"__text": "Fisiere cu tip"
						},
						{
							"_nr": "6",
							"__text": "Fisiere fara tip"
						},
						{
							"_nr": "7",
							"__text": "Prelucrarea etichetelor de fisier si a altor informatii externe memorate in discuri"
						},
						{
							"_nr": "8",
							"__text": "Unele aspecte tehnice referitoare la prelucrarea fisierelor"
						},
						{
							"_nr": "9",
							"__text": "Tehnici de introducere primara a datelor in procesul prelucrarii"
						},
						{
							"_nr": "10",
							"__text": "Validarea datelor"
						},
						{
							"_nr": "11",
							"__text": "Caracteristici generale ale algoritmilor de prelucrarea a fisierelor Pascal"
						},
						{
							"_nr": "12",
							"__text": "Algoritmi de prelucrare a fisierelor binare"
						},
						{
							"_nr": "Anexe",
							"__text": "Anexe"
						}
					]
				},
				"_id": "505",
				"_isbn": "973-31-0625-9"
			},
			{
				"titlu": "Sisteme expert cu Prolog",
				"autori": {
					"autor": {
						"_nume": "Constantin Sambotin"
					}
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "208",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicileta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_expert_in_Prolog_Sambotin.jpg"
					},
					"__text": "Silvia Candea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Inteligenta artificiala cu Prolog"
						},
						{
							"_nr": "2",
							"__text": "Prolog"
						},
						{
							"_nr": "3",
							"__text": "Rationamentul artificial si reprezentarea cunostiintelor"
						},
						{
							"_nr": "4",
							"__text": "Construirea sistemelor expert"
						}
					]
				},
				"_id": "506",
				"_isbn": "973-31-1065-5"
			},
			{
				"titlu": "Aplicatii ale recunoasterii formelor in diagnosticul automat",
				"autori": {
					"autor": {
						"_nume": "Stefan-Gheorghe Pentiuc"
					}
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "160",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicileta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_ale_recunoasterii_formelor_in_diagnosticul_automat_Pentiuc.jpg"
					},
					"__text": "Gabriel Citron"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in recunoasterea formelro"
						},
						{
							"_nr": "2",
							"__text": "Diagnostic si recunoastere formelor"
						},
						{
							"_nr": "3",
							"__text": "Analiza formei"
						},
						{
							"_nr": "4",
							"__text": "Diagnostic prin discriminare paramentrica"
						},
						{
							"_nr": "5",
							"__text": "Forme neclasificabile"
						},
						{
							"_nr": "6",
							"__text": "Clasificarea ierarhica a formelor"
						},
						{
							"_nr": "7",
							"__text": "Retele neuronale"
						},
						{
							"_nr": "8",
							"__text": "Algoritmi de instruire a retelelor neuronale"
						},
						{
							"_nr": "9",
							"__text": "Abordare fuzzy a recunoasterii formelor"
						},
						{
							"_nr": "10",
							"__text": "Sistemul de programe DIAGREF"
						},
						{
							"_nr": "11",
							"__text": "Aplicatie in procesul de fabricare a celulozei"
						}
					]
				},
				"_id": "507",
				"_isbn": "973-31-1096-5"
			},
			{
				"titlu": "Sisteme cu prelucrare distribuita si aplicatiile lor",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Paunescu"
						},
						{
							"_nume": "Danut Petre Golesteanu"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "560",
				"pretOriginal": "1000",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_cu_prelucrare_distribuita_si_aplicatiile_lor_Paunescu.jpg"
					},
					"__text": "Simona Dumitrecu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Ce sunt sistemele cu prelucrare distribuita a datelor"
						},
						{
							"_nr": "3",
							"__text": "Paradigme. Model arhitectural global"
						},
						{
							"_nr": "4",
							"__text": "Straturi hardware pentru prelucrarea distribuita"
						},
						{
							"_nr": "5",
							"__text": "Software pentru prelucrarea distribuita"
						},
						{
							"_nr": "6",
							"__text": "Conceperea si programarea aplicatiilor distribuite"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii ale sistemelor cu prelucrare distribuita"
						},
						{
							"_nr": "8",
							"__text": "Concluzii"
						}
					]
				},
				"_id": "508",
				"_isbn": "973-31-0472-8"
			},
			{
				"titlu": "Teoria codurilor",
				"autori": {
					"autor": {
						"_nume": "Ion Angheloiu"
					}
				},
				"anAparitie": "1972",
				"editura": "Militara",
				"numarPagini": "554",
				"pretOriginal": "37",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "D. Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_codurilor_Angeheloiu.jpg"
					},
					"__text": "Eugen Stoian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Complemente de matematica"
						},
						{
							"_nr": "II",
							"__text": "Caracterisiticle statistice ale erorilor in canale"
						},
						{
							"_nr": "III",
							"__text": "Scheme de codificare si de decodificare"
						},
						{
							"_nr": "IV",
							"__text": "Notiuni introductive in teoria codurilor corectoare"
						},
						{
							"_nr": "V",
							"__text": "Coduri liniare"
						},
						{
							"_nr": "VI",
							"__text": "Coduri neliniare"
						},
						{
							"_nr": "VII",
							"__text": "Coduri convolutinale"
						},
						{
							"_nr": "VIII",
							"__text": "Coduri aritmetice"
						},
						{
							"_nr": "IX",
							"__text": "Coduri corecpunzatoare contruite pe baza patratelor magice"
						},
						{
							"_nr": "X",
							"__text": "Alte coduri corectoare de erori"
						},
						{
							"_nr": "XI",
							"__text": "Coduri sincronizatoare"
						}
					]
				},
				"_id": "509",
				"_isbn": ""
			},
			{
				"titlu": "Teoria grafurilor si aplicatiile ei",
				"autori": {
					"autor": {
						"_nume": "Claude Berge"
					}
				},
				"anAparitie": "1969",
				"editura": "Tehnica",
				"numarPagini": "298",
				"pretOriginal": "20.50",
				"pretCumparare": "0",
				"traducere": {
					"titluOriginal": "Theorie des graphes et ses applications",
					"editura": "Dunod",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1967",
					"_editia": "II",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_grafurilor_si_aplicatiile_ei_Berge.jpg"
					},
					"__text": "Nicolae Nicolaev"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Definitii generale"
						},
						{
							"_nr": "II",
							"__text": "Studiul preliminar al decendentei"
						},
						{
							"_nr": "III",
							"__text": "Functia ordinala si funtia Grundy pe un graf infinit"
						},
						{
							"_nr": "IV",
							"__text": "Numerele fundamentale ale teoriei grafurilor"
						},
						{
							"_nr": "V",
							"__text": "Nucleul unui graf"
						},
						{
							"_nr": "VI",
							"__text": "Jocuri pe un graf"
						},
						{
							"_nr": "VII",
							"__text": "Problema celui mai scurt drum"
						},
						{
							"_nr": "VIII",
							"__text": "Retele de transport"
						},
						{
							"_nr": "IX",
							"__text": "Teorema semigradelor"
						},
						{
							"_nr": "X",
							"__text": "Cuplajul unui grad simplu"
						},
						{
							"_nr": "XI",
							"__text": "Factori"
						},
						{
							"_nr": "XII",
							"__text": "Centrele unui graf"
						},
						{
							"_nr": "XIII",
							"__text": "Diamentrul unui graf tare conex"
						},
						{
							"_nr": "XIV",
							"__text": "Matricea asociata unui graf"
						},
						{
							"_nr": "XV",
							"__text": "Matricea de incidenta"
						},
						{
							"_nr": "XVI",
							"__text": "Arbori si arborescente"
						},
						{
							"_nr": "XVII",
							"__text": "Problema lui Euler"
						},
						{
							"_nr": "XVIII",
							"__text": "Cuplajul unui graf oarecare"
						},
						{
							"_nr": "XIX",
							"__text": "Semifactori"
						},
						{
							"_nr": "XX",
							"__text": "Conexiune unui graf"
						},
						{
							"_nr": "XXI",
							"__text": "Grafuri planare"
						},
						{
							"_nr": "Anexa",
							"__text": "Completari"
						}
					]
				},
				"_id": "510",
				"_isbn": ""
			},
			{
				"titlu": "Programare in mod text. Aplicatii Turbo C 2.0",
				"autori": {
					"autor": [
						{
							"_nume": "Marin Nicolae"
						},
						{
							"_nume": "Stelian Niculescu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "228",
				"pretOriginal": "5000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marin Nicolae"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_in_mod_text_Aplicatii_Turbo_C20_Niculescu.jpg"
					},
					"__text": "Anamaria Nicolae"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Afisarea textelor pe ecran"
						},
						{
							"_nr": "2",
							"__text": "Afisarea textelor in ferestre"
						},
						{
							"_nr": "3",
							"__text": "Aplicatii ale programarii in modul text"
						}
					]
				},
				"_id": "511",
				"_isbn": "973-31-0842-1"
			},
			{
				"titlu": "Totul despre...WordPerfect 5.1",
				"autori": {
					"autor": {
						"_nume": "Mirel Dobrila"
					}
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "200",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_WordPerfect_Dobrila.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conventii utilizate in manual"
						},
						{
							"_nr": "2",
							"__text": "Tastele functionale"
						},
						{
							"_nr": "3",
							"__text": "Sfaturi pentru incepatori"
						},
						{
							"_nr": "4",
							"__text": "Functia Help"
						},
						{
							"_nr": "5",
							"__text": "Functia de aliniere"
						},
						{
							"_nr": "6",
							"__text": "Functia anulare"
						},
						{
							"_nr": "7",
							"__text": "Functia bloc"
						},
						{
							"_nr": "8",
							"__text": "Functia de calcul"
						},
						{
							"_nr": "9",
							"__text": "Caractere speciale WordPerfect"
						},
						{
							"_nr": "10",
							"__text": "Functia de centrare text"
						},
						{
							"_nr": "11",
							"__text": "Functia de despartire in silabe a cuvintelor (Hyphenation)"
						},
						{
							"_nr": "12",
							"__text": "Tastatura in WordPerfect"
						},
						{
							"_nr": "13",
							"__text": "Coduri interne WordPerfect"
						},
						{
							"_nr": "14",
							"__text": "Functia 'coloane' in WordPerfect"
						},
						{
							"_nr": "15",
							"__text": "Comentarii si rezultate"
						},
						{
							"_nr": "16",
							"__text": "Dunctia de copiere a WordPerfect"
						},
						{
							"_nr": "17",
							"__text": "Culorile ecranului WordPerfect"
						},
						{
							"_nr": "18",
							"__text": "Cursorul in WordPerfect"
						},
						{
							"_nr": "19",
							"__text": "Functia data calendaristica"
						},
						{
							"_nr": "20",
							"__text": "Deplarea textului in WordPerfect"
						},
						{
							"_nr": "21",
							"__text": "Dictionarele WordPerfect"
						},
						{
							"_nr": "22",
							"__text": "Documente active in WordPerfect"
						},
						{
							"_nr": "23",
							"__text": "Functia stergere in WordPerfect"
						},
						{
							"_nr": "24",
							"__text": "Fisiere in WordPerfect"
						},
						{
							"_nr": "25",
							"__text": "Fuzionarea documentelor in WordPerfect"
						},
						{
							"_nr": "26",
							"__text": "Functia desen (grafica in mod text)"
						},
						{
							"_nr": "27",
							"__text": "Functia grafica in WordPerfect"
						},
						{
							"_nr": "28",
							"__text": "Elemente de tipografie"
						},
						{
							"_nr": "29",
							"__text": "Imprimante si imprimarea in WordPerfect"
						},
						{
							"_nr": "30",
							"__text": "Meniul Format"
						},
						{
							"_nr": "31",
							"__text": "Functia Mark text in WordPerfect"
						},
						{
							"_nr": "32",
							"__text": "Instalarea programului WordPerfect"
						},
						{
							"_nr": "33",
							"__text": "Lansarea in executie a WordPerfect"
						},
						{
							"_nr": "34",
							"__text": "Macrocomenzile WordPerfect"
						},
						{
							"_nr": "35",
							"__text": "Functia 'Margins' WordPerfect"
						},
						{
							"_nr": "36",
							"__text": "Mesajele de eroare WordPerfect"
						},
						{
							"_nr": "37",
							"__text": "Punerea in evidenta a textului"
						},
						{
							"_nr": "38",
							"__text": "Asazarea in pagina a textului"
						},
						{
							"_nr": "39",
							"__text": "Notele de subsol, notele de sfarsit document"
						},
						{
							"_nr": "40",
							"__text": "Functia de numerotare WordPerfect"
						},
						{
							"_nr": "41",
							"__text": "Meniul 'Styles' al programului WordPerfect"
						},
						{
							"_nr": "42",
							"__text": "Functia 'Search' (cautare text/coduri)"
						},
						{
							"_nr": "43",
							"__text": "Functia recuperare (incarcarea fisiere)"
						},
						{
							"_nr": "44",
							"__text": "Directori si subdirectori WordPerfect"
						},
						{
							"_nr": "45",
							"__text": "Functia 'Save' - Inregistrare fisiere pe disc"
						},
						{
							"_nr": "46",
							"__text": "Functia sonora ('Beep')a WordPerfect"
						},
						{
							"_nr": "47",
							"__text": "Incheierea unui document WordPerfect"
						},
						{
							"_nr": "48",
							"__text": "Sinomimele in WordPerfect:'Thesaurus'"
						},
						{
							"_nr": "49",
							"__text": "Tabele si liste in WordPerfect"
						},
						{
							"_nr": "50",
							"__text": "Spatierea in WordPerfect"
						},
						{
							"_nr": "51",
							"__text": "Sortarea si selectia in WordPerfect"
						},
						{
							"_nr": "52",
							"__text": "Programe utilitare WordPerfect"
						},
						{
							"_nr": "53",
							"__text": "Meniul 'Setup' al programului WordPerfect"
						}
					]
				},
				"_id": "512",
				"_isbn": "973-31-0580-5"
			},
			{
				"titlu": "Traficul in retele de telecomunicatii",
				"autori": {
					"autor": {
						"_nume": "Grazziela Niculescu"
					}
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "228",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Bogdan Zotta"
				},
				"tehnoredactori": {
					"tehnoredactor": "Serban Dinca"
				},
				"coperta": {
					"imagine": {
						"_path": "Traficul_in_retele_de_telecomunicatii_Niculescu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Procese stocastice"
						},
						{
							"_nr": "2",
							"__text": "Traficul in telecomunicatii"
						},
						{
							"_nr": "3",
							"__text": "Procese de servire a traficului"
						},
						{
							"_nr": "4",
							"__text": "Revarsarea traficului"
						},
						{
							"_nr": "5",
							"__text": "Retele de telecomunicatii"
						},
						{
							"_nr": "6",
							"__text": "Planificarea retelelor de telecomunicatii"
						},
						{
							"_nr": "7",
							"__text": "Apeluri repetate - suprasarcina a retelelor"
						},
						{
							"_nr": "8",
							"__text": "Traficul in retele ATM"
						}
					]
				},
				"_id": "513",
				"_isbn": "973-31-0722-0"
			},
			{
				"titlu": "Introducere in conducerea Fuzzy a proceselor",
				"autori": {
					"autor": [
						{
							"_nume": "Stefan Preitl"
						},
						{
							"_nume": "Radu Emil Precup"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "152",
				"pretOriginal": "0",
				"pretCumparare": "80000",
				"redactori": {
					"redactor": "Vasile Buzatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_conducerea_Fuzzy_a_priceselor_Preitl.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducerea in problematica"
						},
						{
							"_nr": "Partea 1",
							"__text": "Pricipiile conducerii proceselor bazate pe teoria multimilor vagi:coducere fuzzy"
						},
						{
							"_nr": "2",
							"__text": "Elemente de teoria multimilor vagi"
						},
						{
							"_nr": "3",
							"__text": "Tratarea informatiei vagi"
						},
						{
							"_nr": "4",
							"__text": "Regulatoare fuzzy: structura de baza"
						},
						{
							"_nr": "5",
							"__text": "Regulatoare fuzzy tipizate si regulatoare fuzzy speciale"
						},
						{
							"_nr": "6",
							"__text": "Structuri de reglare cu regulatoare fuzzy. Aspecte de proiectare"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii ale conducerii fuzzy"
						},
						{
							"_nr": "Partea 2",
							"__text": "Metode de analiza a stabilitatii sistemelor de reglare automata cu regulatoare fuzzy"
						},
						{
							"_nr": "8",
							"__text": "Aspecte introductive la analiza stabilitatii sistemelor de reglare automata cu regulatoare fuzzy"
						},
						{
							"_nr": "9",
							"__text": "Analiza stabilitatii sistemelor de reglare automata cu regulatoare fuzzy in spatiul starilor"
						},
						{
							"_nr": "10",
							"__text": "Analiza stabilitatii sistemelor de reglare automata a cu regulatoare fuzzy pe baza teoriei hiperstabilitatii dupa Popov"
						},
						{
							"_nr": "11",
							"__text": "Analiza stabilitatii sistemelor de reglare automata a cu regulatoare fuzzy pe baza teoriei stabilitatii dupa Lyapunov"
						},
						{
							"_nr": "12",
							"__text": "Analiza stabilitatii sistemelor de reglare automata a cu regulatoare fuzzy pe baza criteriului cercului"
						},
						{
							"_nr": "13",
							"__text": "Analiza stabilitatii sistemelor de reglare automata a cu regulatoare fuzzy pe baza metodei balansului armonic"
						},
						{
							"_nr": "Partea 3",
							"__text": "Elemente de simulare numerica a sistemelelor de reglare automata cu regulatoare fuzzy"
						},
						{
							"_nr": "14",
							"__text": "Dezvolarea asistata de calculator a unui regulator fuzzy. Simularea proprietatilor regulatoarearelor fuzzy si a comportarii sistemelor de reglare automata cu regulatoare fuzzy"
						}
					]
				},
				"_id": "514",
				"_isbn": "973-31-1081-1"
			},
			{
				"titlu": "Metode numerice",
				"autori": {
					"autor": {
						"_nume": "Mihai Postolache"
					}
				},
				"anAparitie": "1994",
				"editura": "Sirius",
				"numarPagini": "252",
				"pretOriginal": "2990",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_Postolache.jpg"
					},
					"__text": "Doru Alexandru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode de rezolvare numerica a ecuatiilor cu o necunoscuta (neliniare sau transcendente)"
						},
						{
							"_nr": "2",
							"__text": "Metode numerice in algebra liniara"
						},
						{
							"_nr": "3",
							"__text": "Metode iterative pentru rezolvare sistemelor neliniare"
						},
						{
							"_nr": "4",
							"__text": "Interpolarea functiilor de o variabila reala"
						},
						{
							"_nr": "5",
							"__text": "Notiuni de teoria aproximarii"
						},
						{
							"_nr": "6",
							"__text": "Derivare si integrare numerica"
						},
						{
							"_nr": "7",
							"__text": "Integrarea numerica a ecuatiilor diferentiale"
						}
					]
				},
				"_id": "515",
				"_isbn": "973-95774-4-X"
			},
			{
				"titlu": "Lectii computerizate de aritmetica pentru clasele IV-X",
				"autori": {
					"autor": {
						"_nume": "Stefan Andrei"
					}
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "126",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Lectii_computerizare_de_aritmetica_pentru_clasele_IV_si_X_Andrei.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni recapitulative"
						},
						{
							"_nr": "2",
							"__text": "Sistem de numeratie"
						},
						{
							"_nr": "3",
							"__text": "Divizibilitate"
						}
					]
				},
				"_id": "516",
				"_isbn": "973-87655-9-5"
			},
			{
				"titlu": "Invatati rapid limbajul de asamblare pentru procesoare x86",
				"autori": {
					"autor": [
						{
							"_nume": "Cezar Ungureanasu"
						},
						{
							"_nume": "Mirela Petrea"
						},
						{
							"_nume": "Laurentiu Vornicu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "EduSoft",
				"numarPagini": "112",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Invatati_rapid_Limbajul_de_asamblare_pentru_procesoare_x86_Ungureanasu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre reprezentarea datelor. Codificarea si aritmetica binara"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare DOS. Comenzi"
						},
						{
							"_nr": "3",
							"__text": "Introducere in structura microprocesorului 8086"
						},
						{
							"_nr": "4",
							"__text": "Primele programe in limbaj de asamblare"
						},
						{
							"_nr": "5",
							"__text": "Folosirea stivei. Proceduri"
						},
						{
							"_nr": "6",
							"__text": "Folosirea fisierelor"
						},
						{
							"_nr": "7",
							"__text": "Prefixul segmentului program"
						},
						{
							"_nr": "8",
							"__text": "Sistemul de intreruperi"
						},
						{
							"_nr": "9",
							"__text": "Programe rezidente"
						},
						{
							"_nr": "10",
							"__text": "Sumar al limbajului de asamblare"
						},
						{
							"_nr": "11",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "517",
				"_isbn": "973-87714-1-2"
			},
			{
				"titlu": "Tehnologii si produse Groupware",
				"autori": {
					"autor": {
						"_nume": "Ramona Ionescu Purcariu"
					}
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "138",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_si_produse_Groupware_Purcariu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Noile tehnologii informationale si societatea informationala"
						},
						{
							"_nr": "2",
							"__text": "Rolul si importanta tehnologiilor groupware in activitatea de management a organizatiilor"
						},
						{
							"_nr": "3",
							"__text": "Tehnologii informatice GroupWare"
						},
						{
							"_nr": "4",
							"__text": "Produse program Groupware/Groupweb"
						}
					]
				},
				"_id": "518",
				"_isbn": "973-87655-7-9"
			},
			{
				"titlu": "Matlab. Ghid de studiu",
				"autori": {
					"autor": {
						"_nume": "Carmen-Violeta Muraru"
					}
				},
				"anAparitie": "2006",
				"editura": "EduSoft",
				"numarPagini": "136",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Matlab_Ghid_de_studiu_Muraru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este Matlab"
						},
						{
							"_nr": "2",
							"__text": "Calcule simple in Matlab. Vectori si matrici"
						},
						{
							"_nr": "3",
							"__text": "Grafica in Matlab"
						},
						{
							"_nr": "4",
							"__text": "Vizualizare in Matlab"
						},
						{
							"_nr": "5",
							"__text": "Obiecte si proprietati"
						},
						{
							"_nr": "6",
							"__text": "Programare in Matlab"
						},
						{
							"_nr": "7",
							"__text": "functii predefinite in Matlab"
						},
						{
							"_nr": "8",
							"__text": "Calcul numeric"
						}
					]
				},
				"_id": "519",
				"_isbn": "973-8934-35-4"
			},
			{
				"titlu": "Metode numerice. Seminari Matlab",
				"autori": {
					"autor": {
						"_nume": "Carmen-Violeta Muraru"
					}
				},
				"anAparitie": "2005",
				"editura": "EduSoft",
				"numarPagini": "70",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_Seminarii_Matlab_Muraru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "2",
							"__text": "Rezolvarea ecuatiilor neliniare"
						},
						{
							"_nr": "3",
							"__text": "Interpolarea functiilor"
						},
						{
							"_nr": "4",
							"__text": "Valori si vectori proprii"
						},
						{
							"_nr": "5",
							"__text": "Integrarea numerica a functiilor"
						}
					]
				},
				"_id": "520",
				"_isbn": "973-87655-4-4"
			},
			{
				"titlu": "SQL. Dialecte DB2, Oracle, PostgreSQL si SQL Server [Editia II]",
				"autori": {
					"autor": {
						"_nume": "Marin Fotache"
					}
				},
				"anAparitie": "2009",
				"editura": "Polirom",
				"numarPagini": "890",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "catalina Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "SQL_Dialoecte_DB2_Oracle_PostgreSQL_SQL_Server_EditiaII_Fotache.jpg"
					},
					"__text": "Laurentiu Midvichei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre baze de date"
						},
						{
							"_nr": "2",
							"__text": "Modelul relational de organizare a datelor"
						},
						{
							"_nr": "3",
							"__text": "Standarde si dialecte SQL. Tipuri de date. Crearea tabelelor si modificarea continutului"
						},
						{
							"_nr": "4",
							"__text": "Limbaje de interogare. Algebra relationala"
						},
						{
							"_nr": "5",
							"__text": "Sintaxa de baza a comenzoo SELECT"
						},
						{
							"_nr": "6",
							"__text": "Functii SQL"
						},
						{
							"_nr": "7",
							"__text": "Grupari"
						},
						{
							"_nr": "8",
							"__text": "NULL-itati, jonctiuni externe si structuri alternative"
						},
						{
							"_nr": "9",
							"__text": "Subconsultari"
						},
						{
							"_nr": "10",
							"__text": "Subconsultari corelate"
						},
						{
							"_nr": "11",
							"__text": "OLAP in SQL"
						},
						{
							"_nr": "12",
							"__text": "SQL, ierarhii si recursivitate"
						},
						{
							"_nr": "13",
							"__text": "Tabele, restrictii si actualizari prin interogarii SQL"
						},
						{
							"_nr": "14",
							"__text": "Tabele temporare si virtuale"
						},
						{
							"_nr": "15",
							"__text": "Schme, obiecte si utilizatori"
						},
						{
							"_nr": "16",
							"__text": "Functii, pricesuri si pachete stocate"
						},
						{
							"_nr": "17",
							"__text": "Declansatoare"
						},
						{
							"_nr": "18",
							"__text": "SQL dinamic"
						},
						{
							"_nr": "19",
							"__text": "Obiectual-relational in SQL"
						}
					]
				},
				"_id": "521",
				"_isbn": "978-973-46-1339-7"
			},
			{
				"titlu": "Teoria grafelor",
				"autori": {
					"autor": [
						{
							"_nume": "Petre Mirescu"
						},
						{
							"_nume": "Alexandru Rosu"
						}
					]
				},
				"anAparitie": "1968",
				"editura": "Militara",
				"numarPagini": "276",
				"pretOriginal": "18",
				"redactori": {
					"redactor": "D. Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_grafelor_Rosu.jpg"
					},
					"__text": "N. Valdescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Generalitati"
						},
						{
							"_nr": "II",
							"__text": "Drumul optimal intr-un graf"
						},
						{
							"_nr": "III",
							"__text": "Relatii de preordine in grafe"
						},
						{
							"_nr": "IV",
							"__text": "Retel de transport"
						},
						{
							"_nr": "V",
							"__text": "Matrice asociate unui p-graf"
						},
						{
							"_nr": "VI",
							"__text": "Drumuri hamilitoniene"
						},
						{
							"_nr": "VII",
							"__text": "Arbori"
						},
						{
							"_nr": "VIII",
							"__text": "Drumul critic"
						}
					]
				},
				"_id": "522",
				"_isbn": ""
			},
			{
				"titlu": "Incertitudine si decizie (Vol I - Analiza incertitudinii)",
				"autori": {
					"autor": [
						{
							"_nume": "Mircea Malita"
						},
						{
							"_nume": "Corneliu Zidaroiu"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "472",
				"pretOriginal": "17",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Incetitudine_si_decizie_Malita.jpg"
					},
					"__text": "Constantin Enescu-Gherghiu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere elementara in teoria deciziei"
						},
						{
							"_nr": "1",
							"__text": "Formularea problemei"
						},
						{
							"_nr": "2",
							"__text": "Un exemplu"
						},
						{
							"_nr": "II",
							"__text": "Incertitudine"
						},
						{
							"_nr": "3",
							"__text": "Campuri de probabilitate"
						},
						{
							"_nr": "4",
							"__text": "Variabile aleatoare"
						},
						{
							"_nr": "5",
							"__text": "Valori numerice asociate variabilelor aleatoare"
						},
						{
							"_nr": "6",
							"__text": "Valori medii conditionate"
						},
						{
							"_nr": "7",
							"__text": "Siruri de variabile aleatoare"
						},
						{
							"_nr": "8",
							"__text": "Functii caracteristice"
						},
						{
							"_nr": "9",
							"__text": "Legea numerelor mari"
						},
						{
							"_nr": "10",
							"__text": "Repartitia normala si teorema limita centrala"
						},
						{
							"_nr": "11",
							"__text": "Introducere in statistica matematica"
						},
						{
							"_nr": "12",
							"__text": "Repartitii clasice discrete"
						},
						{
							"_nr": "13",
							"__text": "Repartitii clasice continue"
						},
						{
							"_nr": "14",
							"__text": "Familii de repartitii conjugate"
						}
					]
				},
				"_id": "523",
				"_isbn": ""
			},
			{
				"titlu": "In cautarea optimului",
				"autori": {
					"autor": {
						"_nume": "Alexandru Isaic-Maniu"
					}
				},
				"anAparitie": "1985",
				"editura": "Albatros",
				"numarPagini": "240",
				"pretOriginal": "10.50",
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Iliopolos"
				},
				"coperta": {
					"imagine": {
						"_path": "In_cautarea_optimului_Maniu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Optimul economic si modelarea"
						},
						{
							"_nr": "1",
							"__text": "Optimul economic sau intre dorinta si posibilitati"
						},
						{
							"_nr": "2",
							"__text": "Modelarea - intrument al optimizarii"
						},
						{
							"_nr": "II",
							"__text": "Timpul - dimensiune a evolutiei proceselor economice"
						},
						{
							"_nr": "1",
							"__text": "Indicatori pentru caracterizarea evolutiei in timp, a femomentelor economico-sociale"
						},
						{
							"_nr": "2",
							"__text": "Modele de evolutie"
						},
						{
							"_nr": "III",
							"__text": "Modele pentru optimizarea unor activitati"
						},
						{
							"_nr": "1",
							"__text": "Optimizarea transporturilor"
						},
						{
							"_nr": "2",
							"__text": "Optimizarea asteptarii"
						},
						{
							"_nr": "3",
							"__text": "Optimizarea stocurilor"
						},
						{
							"_nr": "4",
							"__text": "Optimizarea sistemului de alimentatie"
						},
						{
							"_nr": "IV",
							"__text": "Alte probleme optimizabile"
						},
						{
							"_nr": "1",
							"__text": "Problema cautarii"
						},
						{
							"_nr": "2",
							"__text": "Problema croitului"
						},
						{
							"_nr": "3",
							"__text": "Problema orarului"
						},
						{
							"_nr": "4",
							"__text": "Problema invatarii. Pregatirea cadrelor"
						},
						{
							"_nr": "5",
							"__text": "Problema deciziei"
						},
						{
							"_nr": "6",
							"__text": "Fiabilitatea - optimizarea capacitatii de functionare"
						},
						{
							"_nr": "7",
							"__text": "Problema rucsacului"
						},
						{
							"_nr": "V",
							"__text": "Modele speciale"
						},
						{
							"_nr": "1",
							"__text": "Functii de productie - Model de studiu ale combinarii optime a factorilor de productie"
						},
						{
							"_nr": "2",
							"__text": "Modele si metode pentru cercetarea corelatiilr in economie"
						},
						{
							"_nr": "3",
							"__text": "Jocurile (serioase) si unele din aplicatiilor lor"
						}
					]
				},
				"_id": "524",
				"_isbn": ""
			},
			{
				"titlu": "Limbaje de de programare pentru calculatoare de proces",
				"autori": {
					"autor": [
						{
							"_nume": "Dan N. Dobrescu"
						},
						{
							"_nume": "Florentina Ioanitescu"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Albatros",
				"tiraj": "30000",
				"numarPagini": "240",
				"pretOriginal": "5",
				"tehnoredactori": {
					"tehnoredactor": "Cornel Cristescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_programare_pentru_calculatoare_de_proces_Dobrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Rolul calculatoarelor in conducerea proceselor industriale"
						},
						{
							"_nr": "3",
							"__text": "Particularitatile unui limbaj de programare  destinat calculatoarelor de proces"
						},
						{
							"_nr": "4",
							"__text": "Nivelul actual si tendinte in dezvoltarea limbajelor de programere pentru calculatoarele de proces"
						},
						{
							"_nr": "5",
							"__text": "Exemple de limbaje de programare de proces"
						},
						{
							"_nr": "5.1",
							"__text": "Prezentarea limbajului PROCOL"
						},
						{
							"_nr": "5.2",
							"__text": "Prezentarea limbajului BICEPS"
						},
						{
							"_nr": "5.3",
							"__text": "Prezentarea limbajului COPRES"
						},
						{
							"_nr": "5.4",
							"__text": "Prezentarea limbajului PROSPRO"
						},
						{
							"_nr": "5.5",
							"__text": "Prezentarea limbajului SZPAK"
						},
						{
							"_nr": "5.6",
							"__text": "Prezentarea limbajului DELIS"
						}
					]
				},
				"_id": "525",
				"_isbn": ""
			},
			{
				"titlu": "Metode numerice si subrutine",
				"autori": {
					"autor": [
						{
							"_nume": "Marin Toma"
						},
						{
							"_nume": "Ion Odagescu"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Tehnica",
				"tiraj": "13500+90",
				"numarPagini": "192",
				"pretOriginal": "5.25",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_si_subrutine_Toma.jpg"
					},
					"__text": "Marian Negru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de logica matematice cu aplicatii in structura logica a calculatorului numeric"
						},
						{
							"_nr": "3",
							"__text": "Algebra functiilor de adevar in logica calculatorului numeric"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii ale algebrei functiilor booleene in structura logica a calculatorului numeric"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de numeratie"
						},
						{
							"_nr": "6",
							"__text": "Adunarea si scaderea linara"
						},
						{
							"_nr": "7",
							"__text": "Inmultirea binara"
						},
						{
							"_nr": "8",
							"__text": "Impartirea binara"
						},
						{
							"_nr": "9",
							"__text": "Extragerea radacinii patrate"
						},
						{
							"_nr": "10",
							"__text": "Aritmetica zecimala"
						}
					]
				},
				"_id": "526",
				"_isbn": ""
			},
			{
				"titlu": "Bazele matematice pentru calculatoare numerice",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Ionescu"
						},
						{
							"_nume": "Livia Nisipeanu"
						},
						{
							"_nume": "Emil Stoica"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Tehnica",
				"tiraj": "11800+70",
				"numarPagini": "218",
				"pretOriginal": "5.25",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_matematice_pentru_calculatoare_numerice_Ionescu.jpg"
					},
					"__text": "Marian Negru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Erori. Numere aproximative. Propagarea erorilor in calcule"
						},
						{
							"_nr": "3",
							"__text": "Rezolvarea numerica a ecuatiilr alberice si transcendente si a sistemelor de cuatii neliniare"
						},
						{
							"_nr": "4",
							"__text": "Calcul numeric matricial"
						},
						{
							"_nr": "5",
							"__text": "Interpolare"
						},
						{
							"_nr": "6",
							"__text": "Derivarea si integrarea numerica"
						},
						{
							"_nr": "7",
							"__text": "Integrarea numerica a ecuatiilor diferentiale"
						}
					]
				},
				"_id": "527",
				"_isbn": ""
			},
			{
				"titlu": "Initiere in matematica aplicata",
				"autori": {
					"autor": [
						{
							"_nume": "C. Dinescu"
						},
						{
							"_nume": "B. Savulescu"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Albatros",
				"numarPagini": "368",
				"pretOriginal": "16",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_matematica_aplicata_Dinescu.jpg"
					},
					"__text": "Gabriela Iliopos"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Propozitii. Multimi. Matrice"
						},
						{
							"_nr": "2",
							"__text": "Elemente de algebra liniara"
						},
						{
							"_nr": "3",
							"__text": "Elemente de teoria grafurilor"
						}
					]
				},
				"_id": "528",
				"_isbn": ""
			},
			{
				"titlu": "Initiere in FORTRAN",
				"autori": {
					"autor": {
						"_nume": "Stelian Niculescu"
					}
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "18100+140",
				"numarPagini": "280",
				"pretOriginal": "10.50",
				"colectia": {
					"_numarul": "0",
					"_nume": "Automatica Informatica Electronica Management - Seria Initiere"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Fortran_Niculescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Descrierea algoritmilor cu ajutorul schemelor logice"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului FORTRAN"
						},
						{
							"_nr": "4",
							"__text": "Practica limbajului FORTRAN. Intructiuni de instrare/iesire standard"
						},
						{
							"_nr": "5",
							"__text": "Practica limbajului FORTRAN. Instructiuni de atribuire si de control"
						},
						{
							"_nr": "6",
							"__text": "Proceduri"
						},
						{
							"_nr": "7",
							"__text": "Fisiere"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni de intrare/iesire"
						},
						{
							"_nr": "9",
							"__text": "Cartele de comanda"
						},
						{
							"_nr": "10",
							"__text": "Segmentarea programelor"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "529",
				"_isbn": ""
			},
			{
				"titlu": "Initiere in limbajul ADA",
				"autori": {
					"autor": [
						{
							"_nume": "Emil Munteanu"
						},
						{
							"_nume": "Ioan Mihut"
						},
						{
							"_nume": "Adrian Ivanov"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Tehnica",
				"numarPagini": "288",
				"pretOriginal": "26",
				"colectia": {
					"_numarul": "0",
					"_nume": "Automatica Informatica Electronica Management - Seria Initiere"
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_limbajul_ADA_Munteanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurt istoric si consideratii generale"
						},
						{
							"_nr": "2",
							"__text": "Lexicul limbajului ADA"
						},
						{
							"_nr": "3",
							"__text": "Declaratii"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "5",
							"__text": "Tipuri de date de acces"
						},
						{
							"_nr": "6",
							"__text": "Expresii"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni"
						},
						{
							"_nr": "8",
							"__text": "Subprograme"
						},
						{
							"_nr": "9",
							"__text": "Module (pachete)"
						},
						{
							"_nr": "10",
							"__text": "Taskuri"
						},
						{
							"_nr": "11",
							"__text": "Exceptii"
						},
						{
							"_nr": "12",
							"__text": "Unitati generice"
						},
						{
							"_nr": "13",
							"__text": "Operatii de intrare - iesire (I-O)"
						},
						{
							"_nr": "14",
							"__text": "Structura programelor ADA. Compilarea separata si vizibilitatea"
						},
						{
							"_nr": "15",
							"__text": "Aspecte dependente de implementarea limbajului"
						}
					]
				},
				"_id": "530",
				"_isbn": ""
			},
			{
				"titlu": "Turbo Pascal 6.0",
				"autori": {
					"autor": [
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Alexandru Panoiu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "224",
				"pretOriginal": "2800",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_60_1993_Cristea.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Partea I"
						},
						{
							"_nr": "1",
							"__text": "Structura sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Functionarea sistemului de calcul. Descrierea algoritmilor"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea programelor"
						},
						{
							"_nr": "II",
							"__text": "Partea II"
						},
						{
							"_nr": "4",
							"__text": "Structura programelor Pascal"
						},
						{
							"_nr": "5",
							"__text": "Tipuri simple de date. Constante si variabile"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni de citire/scriere"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni de atribuire"
						},
						{
							"_nr": "8",
							"__text": "Instructiune compusa"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni de selectie"
						},
						{
							"_nr": "10",
							"__text": "Instructiuni repetitive"
						},
						{
							"_nr": "11",
							"__text": "Abateri de la regulile programari structurate"
						},
						{
							"_nr": "12",
							"__text": "Tipuri simple de date definte de utilizator"
						},
						{
							"_nr": "13",
							"__text": "Tipuri tablou"
						},
						{
							"_nr": "14",
							"__text": "Subprograme"
						},
						{
							"_nr": "15",
							"__text": "Tipuri string (sir de caractere)"
						},
						{
							"_nr": "16",
							"__text": "Tipuri multime"
						},
						{
							"_nr": "17",
							"__text": "Tipuri inregistrare (record)"
						},
						{
							"_nr": "18",
							"__text": "Fisiere"
						},
						{
							"_nr": "19",
							"__text": "Tipuri referinta (pointer). Structuri dinamice"
						},
						{
							"_nr": "III",
							"__text": "Partea III"
						},
						{
							"_nr": "20",
							"__text": "Dezvoltarea sistematica a programelor complexe"
						},
						{
							"_nr": "21",
							"__text": "Organizarea programelor mari"
						},
						{
							"_nr": "22",
							"__text": "Facilitati de programare speciale"
						},
						{
							"_nr": "23",
							"__text": "Afisarea textelor"
						},
						{
							"_nr": "24",
							"__text": "Utilizareaa bibliotecilor grafice"
						},
						{
							"_nr": "25",
							"__text": "Programarea orietata pe obiecte"
						},
						{
							"_nr": "26",
							"__text": "Turbo Vision"
						}
					]
				},
				"_id": "531",
				"_isbn": "973-601-109-7"
			},
			{
				"titlu": "Matematica aplicata in tehnica de calcul",
				"autori": {
					"autor": [
						{
							"_nume": "Petre Preoteasa"
						},
						{
							"_nume": "Luca Dan Serbanati"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "Didactica si pedagogica",
				"numarPagini": "120",
				"pretOriginal": "6.20",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Eleonora Draghia"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paraschiva Gaspar"
				},
				"coperta": {
					"imagine": {
						"_path": "Matematica_aplicata_in_tehnica_de_calcul_Preoteasa.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de baza ale programarii calculatoarelor"
						},
						{
							"_nr": "II",
							"__text": "Programarea in limbajul Fortran"
						}
					]
				},
				"_id": "532",
				"_isbn": ""
			},
			{
				"titlu": "Fortran-77. Depanarea programelor. Aplicatii practice",
				"autori": {
					"autor": {
						"_nume": "Marian Zaharia"
					}
				},
				"anAparitie": "1991",
				"editura": "Tehnica",
				"numarPagini": "120",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Automatica Informatica Electronica Management - Seria Initiere"
				},
				"redactori": {
					"redactor": [
						"Claudia Dulgheru",
						"Adrian Ionescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiada Nistor"
				},
				"coperta": {
					"imagine": {
						"_path": "Fortran_77_Depanarea_programelor_Aplicatii_practice_Zaharia.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instrumente utilizate in depanarea programelor FORTRAN-77"
						},
						{
							"_nr": "2",
							"__text": "Erori si depanarea lor. Exemple"
						}
					]
				},
				"_id": "533",
				"_isbn": "973-31-0279-2"
			},
			{
				"titlu": "Modele de simulare cu aplicatii in fiabilitate",
				"autori": {
					"autor": {
						"_nume": "Barbu Gheorghe"
					}
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "112",
				"pretOriginal": "225",
				"redactori": {
					"redactor": [
						"Vasile Buzatu",
						"Joavina Marcelina"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Vlaiciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modele_de_simulare_cu_aplicatii_in_fiabilitate_Gheorghe.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Simulare si fiabilitate. Consideratii generale"
						},
						{
							"_nr": "2",
							"__text": "Generarea variabilelor aleatoare"
						},
						{
							"_nr": "3",
							"__text": "Generarea vectorilor aleatori"
						},
						{
							"_nr": "4",
							"__text": "Simularea fiabilitatiii. Aplicatii"
						}
					]
				},
				"_id": "534",
				"_isbn": "973-31-0392-6"
			},
			{
				"titlu": "Sisteme de operare MIX si programarea in limbajul MACRO pentru minicalculatoarele romanesti (Vol I)",
				"autori": {
					"autor": {
						"_nume": "Nicolae Manea"
					}
				},
				"anAparitie": "1991",
				"editura": "Tehnica",
				"numarPagini": "324",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_MIX_si_Programarea_in_limbajul_MACRO_pentru_minicalculatoarele_romanesti_Manea.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura minicalculatoarelor romanesti"
						},
						{
							"_nr": "2",
							"__text": "Familia de sisteme de operare MIX/MIX-PLUS"
						},
						{
							"_nr": "3",
							"__text": "Concepte relative la task-uri, evenimente si planificarea task-urilor"
						},
						{
							"_nr": "4",
							"__text": "Comunicarea si sincronizarea intre task-uri"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea memoriei si spatii de adresare"
						},
						{
							"_nr": "6",
							"__text": "Exceptii si intreruperi software"
						},
						{
							"_nr": "7",
							"__text": "Servicii si directive sistem MIX"
						},
						{
							"_nr": "8",
							"__text": "Interfete operator - Sistemul MIX"
						},
						{
							"_nr": "9",
							"__text": "Sistemul de intrare/iesire MIX"
						},
						{
							"_nr": "10",
							"__text": "Drivere de intrare/iesire MIX"
						},
						{
							"_nr": "11",
							"__text": "Servicii de gestiune si manipulare a datelor"
						},
						{
							"_nr": "12",
							"__text": "Sistemul de gestiune a fisierelor (FCS)"
						},
						{
							"_nr": "13",
							"__text": "Sistemul de gestiune a inregistrarilor (RMS)"
						},
						{
							"_nr": "14",
							"__text": "Concepte si facilitati ale retelei MININET/MIX"
						}
					]
				},
				"_id": "535",
				"_isbn": "973-31-0359-4"
			},
			{
				"titlu": "Principiile calculului paralel",
				"autori": {
					"autor": {
						"_nume": "Felicia Ionescu"
					}
				},
				"anAparitie": "1999",
				"editura": "Tehnica",
				"numarPagini": "372",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Inginerie electronica"
				},
				"redactori": {
					"redactor": "Mihaela Marian"
				},
				"coperta": {
					"imagine": {
						"_path": "Principiile_calculului_paralel_Ionescu.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Modele de calculatoare paralele"
						},
						{
							"_nr": "2",
							"__text": "Retele de interconectare in calculatoarele paralele"
						},
						{
							"_nr": "3",
							"__text": "Sisteme paralele scalabile si specializate"
						},
						{
							"_nr": "4",
							"__text": "Modele de programare paralele"
						},
						{
							"_nr": "5",
							"__text": "Programarea paralela prin variabile partajate"
						},
						{
							"_nr": "6",
							"__text": "Programare paralela prin transfer de mesaje"
						}
					]
				},
				"_id": "536",
				"_isbn": "973-31-1331-X"
			},
			{
				"titlu": "Informatica Aplicata [Manual pentru liceele de informatica. Clasele XI-XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Radu Marsanu"
						},
						{
							"_nume": "Manole Velicanu"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Petrion",
				"numarPagini": "224",
				"pretOriginal": "0",
				"pretCumparare": "5000",
				"tehnoredactori": {
					"tehnoredactor": "Angela Gabriela Caracas"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_aplicata_Velicanu.jpg"
					},
					"__text": "Serban Andreescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Procesoare de calcul tabelar"
						},
						{
							"_nr": "Partea II",
							"__text": "Generatoare FOXPRO. Sisteme informatice"
						}
					]
				},
				"_id": "537",
				"_isbn": "973-9116-20-5"
			},
			{
				"titlu": "Calculatoare electronice, grafica interactiva si prelucrarea imaginilor",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "Dan Roman"
						},
						{
							"_nume": "Nicolae Zegheru"
						},
						{
							"_nume": "Dan Costachescu"
						},
						{
							"_nume": "Adrian Lustig"
						},
						{
							"_nume": "Claudia Dumitrescu"
						},
						{
							"_nume": "Cristian Stanescu"
						},
						{
							"_nume": "Vlad Tepelea"
						},
						{
							"_nume": "Gheorghe Corcodel"
						},
						{
							"_nume": "Mihai Jalobeanu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "360",
				"pretOriginal": "41",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Practica"
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_electronice_grafica_interactiva_si_prelucrarea_imaginilor_Baltac.jpg"
					},
					"__text": "Teodora Doxan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Echipamente si principii de programare pentru grafica interactiva"
						},
						{
							"_nr": "1",
							"__text": "Sisteme grafice interactive"
						},
						{
							"_nr": "2",
							"__text": "Echipamente de vizualizare si interactiune"
						},
						{
							"_nr": "3",
							"__text": "Echipamente de trasare si digitizare"
						},
						{
							"_nr": "4",
							"__text": "Echipamente grafice romanesti"
						},
						{
							"_nr": "5",
							"__text": "Elemente de programare si de reazliare pentru grafica interactiva"
						},
						{
							"_nr": "Partea II",
							"__text": "Aplicatii ale graficii interactive si prelucrarea imaginilor"
						},
						{
							"_nr": "6",
							"__text": "Grafica interactiva si CAD, CAM, CAE in procese mecanice"
						},
						{
							"_nr": "7",
							"__text": "Grafica interactiva si CAD in electronica. Sistemul de programare PIX pentru proiecarea placilor cu circuite imprimate"
						},
						{
							"_nr": "8",
							"__text": "Grafica interactiva si CAD in arhitectura. Sistemul de programare ARTIS"
						},
						{
							"_nr": "9",
							"__text": "Prelucrarea imaginilor, cu aplicatii in medicina, biologie, robotica"
						},
						{
							"_nr": "9.1",
							"__text": "Introducerea imaginilor in calculator"
						},
						{
							"_nr": "9.2",
							"__text": "Structura unui sistem de prelucrare a imaginilor"
						},
						{
							"_nr": "9.3",
							"__text": "Transformari si metode de prelucrare a imaginilor"
						},
						{
							"_nr": "9.3.1",
							"__text": "Transformarile imaginilor monocrome"
						},
						{
							"_nr": "9.3.2",
							"__text": "Histograme si alte determinari statistice"
						},
						{
							"_nr": "9.3.3",
							"__text": "Vechinii unui pixel"
						},
						{
							"_nr": "9.3.4",
							"__text": "Transformari liniare"
						},
						{
							"_nr": "9.3.5",
							"__text": "Detectia muchiilor"
						},
						{
							"_nr": "9.3.6",
							"__text": "Prelucrarea imaginilor binare"
						},
						{
							"_nr": "9.3.7",
							"__text": "Analiza imaginilor numerice"
						},
						{
							"_nr": "9.4",
							"__text": "Aplicatii ale prelucrarii imaginilor"
						},
						{
							"_nr": "9.5",
							"__text": "Impactul prelucrarii imaginilor asupra dezvoltarii sistemelor de calcul"
						},
						{
							"_nr": "10",
							"__text": "Prelucrarea imaginilor si grafica interactiva in fotogrammetrie si teledetectie"
						}
					]
				},
				"_id": "538",
				"_isbn": ""
			},
			{
				"titlu": "Fisiere, baza si banci de date",
				"autori": {
					"autor": [
						{
							"_nume": "Valeriu Pescaru"
						},
						{
							"_nume": "I. Catona"
						},
						{
							"_nume": "D. Duta"
						},
						{
							"_nume": "Cr. Popescu"
						},
						{
							"_nume": "I. Satran"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"numarPagini": "312",
				"pretOriginal": "17.50",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Practica"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Tasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Fisiere_baze_si_banci_de_date_Pescaru.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Informatia in sistemele de prelucrare"
						},
						{
							"_nr": "2",
							"__text": "Tehnici de structurare si suporturi de inregistare"
						},
						{
							"_nr": "3",
							"__text": "Tehnici de organizare si exploatare a fisierelor"
						},
						{
							"_nr": "4",
							"__text": "Integrarea fisierelor de date"
						},
						{
							"_nr": "5",
							"__text": "Banci de date"
						},
						{
							"_nr": "6",
							"__text": "Protectia fisierelor si a bancilor de date"
						},
						{
							"_nr": "7",
							"__text": "Sisteme de gestiune pentru banci de date"
						}
					]
				},
				"_id": "539",
				"_isbn": ""
			},
			{
				"titlu": "Analiza economico-financiara asistata de calculator",
				"autori": {
					"autor": [
						{
							"_nume": "D. Margulescu"
						},
						{
							"_nume": "Ilie Vaduva"
						},
						{
							"_nume": "A. Isfanescu"
						},
						{
							"_nume": "V. Florescu"
						},
						{
							"_nume": "M. Alexandru"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "408",
				"pretOriginal": "35",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Practica"
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_economico_financiara_asistata_de_calculator_Vaduva.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Analiza economico-financiara in intreprinderea moderna"
						},
						{
							"_nr": "II",
							"__text": "Analiza productiei fizice si valorice"
						},
						{
							"_nr": "III",
							"__text": "Analiza cheltuielilor de productie"
						},
						{
							"_nr": "IV",
							"__text": "Analiza rentabilitatii"
						},
						{
							"_nr": "V",
							"__text": "Analiza asigurarii si utlizarii fortei de munca"
						},
						{
							"_nr": "VI",
							"__text": "Analiza utilizarii fondurilor fixe"
						},
						{
							"_nr": "VII",
							"__text": "Analiza utilizarii fondurilor ciculante si de curculatie"
						},
						{
							"_nr": "VIII",
							"__text": "Analiza operativa a incasarolor si platilor"
						},
						{
							"_nr": "IX",
							"__text": "Caz practic: Analiza comlexa (integrala) a rentabilitatii"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Forme generale ale principalelor comenzi dBase"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Exemple comentate de comenzi dBase"
						}
					]
				},
				"_id": "540",
				"_isbn": ""
			},
			{
				"titlu": "Curs practic de Java",
				"autori": {
					"autor": {
						"_nume": "Cristian Frasinaru"
					}
				},
				"anAparitie": "2005",
				"editura": "MatrixRom",
				"numarPagini": "460",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Curs_practic_de_Java_Frasinaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Java"
						},
						{
							"_nr": "2",
							"__text": "Clase si obiecte"
						},
						{
							"_nr": "3",
							"__text": "Exceptii"
						},
						{
							"_nr": "4",
							"__text": "Intrari si ieresiri"
						},
						{
							"_nr": "5",
							"__text": "Interfete"
						},
						{
							"_nr": "6",
							"__text": "Organizarea claselor"
						},
						{
							"_nr": "7",
							"__text": "Colectii"
						},
						{
							"_nr": "8",
							"__text": "Serializarea obiectelor"
						},
						{
							"_nr": "9",
							"__text": "Interfata grafica cu utilizatorul"
						},
						{
							"_nr": "10",
							"__text": "Desenarea"
						},
						{
							"_nr": "11",
							"__text": "Swing"
						},
						{
							"_nr": "12",
							"__text": "Fire de executie"
						},
						{
							"_nr": "13",
							"__text": "Programarea in retea"
						},
						{
							"_nr": "14",
							"__text": "Appleturi"
						},
						{
							"_nr": "15",
							"__text": "Lucrul cu baze de date"
						},
						{
							"_nr": "16",
							"__text": "Lucrul dinamic cu clase"
						}
					]
				},
				"_id": "541",
				"_isbn": "973-685-856-1"
			},
			{
				"titlu": "Management. Teorie si aplicatii C++",
				"autori": {
					"autor": [
						{
							"_nume": "Marian Zaharia"
						},
						{
							"_nume": "Camelia Zaharia"
						},
						{
							"_nume": "Anda Deac"
						},
						{
							"_nume": "Florina Vizinteanu"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Tehnica",
				"numarPagini": "272",
				"pretOriginal": "900",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "V.E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Management_teorie_si_aplicatii_in_Cpp_Zaharia.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Management, manager, elemente de baza"
						},
						{
							"_nr": "2",
							"__text": "Functiile managementului"
						},
						{
							"_nr": "3",
							"__text": "Proces si sistem de managent"
						},
						{
							"_nr": "4",
							"__text": "Probleme ale managementului strategic"
						},
						{
							"_nr": "5",
							"__text": "Aspecte ale managementului resurselor umane"
						},
						{
							"_nr": "6",
							"__text": "Piata, barometru in functionarea sistemului de management al organizatiei"
						},
						{
							"_nr": "7",
							"__text": "Femomen stochastice si procesul de management"
						},
						{
							"_nr": "8",
							"__text": "Simularea dinamicii nivelului stocurilor"
						},
						{
							"_nr": "9",
							"__text": "Optimizarea deciziei manageriale"
						}
					]
				},
				"_id": "542",
				"_isbn": "973-31-0503-1"
			},
			{
				"titlu": "Aplicatii Windows in birotica. Norton Desktop",
				"autori": {
					"autor": [
						{
							"_nume": "Doru Turturea"
						},
						{
							"_nume": "Florina Balint"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "260",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_Windows_in_birotica_Norton_Desktop_Turturea.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea programului Norton Desktop"
						},
						{
							"_nr": "2",
							"__text": "Lansarea programului"
						},
						{
							"_nr": "3",
							"__text": "Programul help"
						},
						{
							"_nr": "4",
							"__text": "Ferestre si grupuri"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea ferestelor drive"
						},
						{
							"_nr": "6",
							"__text": "Lansarea fisierelor"
						},
						{
							"_nr": "7",
							"__text": "Configurarea desktop-ului"
						},
						{
							"_nr": "8",
							"__text": "Programul Desktop Editor"
						},
						{
							"_nr": "9",
							"__text": "Programul Day Planner"
						},
						{
							"_nr": "10",
							"__text": "Programul Scheduler"
						},
						{
							"_nr": "11",
							"__text": "Programul Speed Disk For Windows"
						},
						{
							"_nr": "12",
							"__text": "Programul Norton Disk Doctor"
						},
						{
							"_nr": "13",
							"__text": "Programul Norton Antivirus"
						},
						{
							"_nr": "14",
							"__text": "Programul SmartErase"
						},
						{
							"_nr": "15",
							"__text": "Programul Norton Backup"
						},
						{
							"_nr": "16",
							"__text": "Programul Shredder"
						},
						{
							"_nr": "17",
							"__text": "Programul de recuperare Rescue Disk"
						},
						{
							"_nr": "18",
							"__text": "Utilizarea calculatoarelor"
						},
						{
							"_nr": "19",
							"__text": "Programul Icon Editor"
						},
						{
							"_nr": "20",
							"__text": "Programul System Information"
						},
						{
							"_nr": "21",
							"__text": "Programul KeyFinder"
						},
						{
							"_nr": "22",
							"__text": "Programul Screen Saver"
						},
						{
							"_nr": "23",
							"__text": "Programul Norton Viewer"
						},
						{
							"_nr": "24",
							"__text": "Programul SuperFind"
						},
						{
							"_nr": "25",
							"__text": "Script Maker si Dialog Editor"
						},
						{
							"_nr": "26",
							"__text": "Limbajul Batch Builder"
						}
					]
				},
				"_id": "543",
				"_isbn": "973-31-0732-9"
			},
			{
				"titlu": "Medii de programare pentru gestiunea bazelor de de date",
				"autori": {
					"autor": [
						{
							"_nume": "Traian Surcel"
						},
						{
							"_nume": "Vasile Avram"
						},
						{
							"_nume": "Diana Avram"
						},
						{
							"_nume": "Radu Marsanu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Tribuna Economica",
				"numarPagini": "382",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Cristian Gorie"
				},
				"coperta": {
					"imagine": {
						"_path": "Medii_de_programare_pentru_gestiunea_bazelor_de_date_Surcel.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Comunicatii electronice si Internet"
						},
						{
							"_nr": "1.1",
							"__text": "Introducere"
						},
						{
							"_nr": "1.2",
							"__text": "Procesul de comunicatie"
						},
						{
							"_nr": "1.3",
							"__text": "Componete de retea si de conectare a retelelor"
						},
						{
							"_nr": "1.4",
							"__text": "Topologii"
						},
						{
							"_nr": "1.5",
							"__text": "Prelucarea cooperanta"
						},
						{
							"_nr": "1.6",
							"__text": "Comunicatii in Internet"
						},
						{
							"_nr": "1.7",
							"__text": "Introducere in HTML"
						},
						{
							"_nr": "1.8",
							"__text": "Comunicatii prin Fax-Modem"
						},
						{
							"_nr": "1.9",
							"__text": "e-mail.Gestiunea mesajelor cu Outlook"
						},
						{
							"_nr": "II",
							"__text": "Medii de programare Web"
						},
						{
							"_nr": "2.1",
							"__text": "Microsoft Front Page"
						},
						{
							"_nr": "2.2",
							"__text": "HTML"
						},
						{
							"_nr": "2.3",
							"__text": "Inserarea de applet-uri Java in pagina web"
						},
						{
							"_nr": "2.4",
							"__text": "Inserarea unui control ActiveX"
						},
						{
							"_nr": "2.5",
							"__text": "Scripturi si aplicatii CGI"
						},
						{
							"_nr": "2.6",
							"__text": "Scripturi Java"
						},
						{
							"_nr": "2.7",
							"__text": "ASP- Active Server Pages"
						},
						{
							"_nr": "III",
							"__text": "Organizarea datelor"
						},
						{
							"_nr": "IV",
							"__text": "Programarea orientata obiect in Visual Basic"
						},
						{
							"_nr": "V",
							"__text": "Mediul de programare Microsoft ACCESS"
						}
					]
				},
				"_id": "544",
				"_isbn": "973-8257-91-3"
			},
			{
				"titlu": "Practica interpretarii monadice",
				"autori": {
					"autor": {
						"_nume": "Dan Popa"
					}
				},
				"anAparitie": "2008",
				"editura": "MatrixRom",
				"numarPagini": "160",
				"pretOriginal": "290000",
				"pretCumparare": "290000",
				"coperta": {
					"imagine": {
						"_path": "Practica_interpretarii_monadice_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Alegerea unui tip de interpretor"
						},
						{
							"_nr": "2",
							"__text": "Interpretor de arbori : back-end-ul"
						},
						{
							"_nr": "3",
							"__text": "Front-end-ul unui interpretor (1) - Expresii"
						},
						{
							"_nr": "4",
							"__text": "Front-end-ul unui interpretor (2) - Instructiuni"
						},
						{
							"_nr": "5",
							"__text": "Interfata dintre front-end si back-end"
						},
						{
							"_nr": "6",
							"__text": "Implementarea for-ului cu mijloace sintactice"
						},
						{
							"_nr": "7",
							"__text": "Implementarea for-ului cu mijloace semantice"
						},
						{
							"_nr": "8",
							"__text": "Interpretor de expresii cu functii anonime (lamda expresii)"
						}
					]
				},
				"_id": "545",
				"_isbn": "978-973-755-417-8"
			},
			{
				"titlu": "Inteligenta artificiala - principii, tehnici, aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Leon"
						},
						{
							"_nume": "Andrei Stefan Andrei"
						},
						{
							"_nume": "Dan Galea"
						},
						{
							"_nume": "Angela Chelariu"
						},
						{
							"_nume": "Irina Topolniceanu"
						},
						{
							"_nume": "Alin Dumitru"
						},
						{
							"_nume": "Aleodor Daniel Ioan"
						},
						{
							"_nume": "Bogdan Alecsa"
						},
						{
							"_nume": "Andrei Dobrovici"
						},
						{
							"_nume": "Roxana Otilia Sonia Hritcu"
						},
						{
							"_nume": "Marian Constantin Paduraru"
						},
						{
							"_nume": "Caludiu Gall"
						},
						{
							"_nume": "Antonio Pascariu"
						},
						{
							"_nume": "George Talaba"
						},
						{
							"_nume": "Razvan Popa"
						},
						{
							"_nume": "Mircea Vlad Trifan"
						},
						{
							"_nume": "Mihai Ciomaga"
						},
						{
							"_nume": "Bogdan Dimitriu"
						},
						{
							"_nume": "Dan Pletea"
						},
						{
							"_nume": "Florentin Radu"
						},
						{
							"_nume": "Lucica Ibanescu"
						},
						{
							"_nume": "Cristian Tipiscanu"
						},
						{
							"_nume": "Octavian Niculita"
						},
						{
							"_nume": "Raluca Moraru"
						},
						{
							"_nume": "Cosmin Andrei"
						},
						{
							"_nume": "Cornel Maranduca"
						},
						{
							"_nume": "Cati Mararov"
						},
						{
							"_nume": "Razvan Lucaci"
						},
						{
							"_nume": "Iulian Tabara"
						},
						{
							"_nume": "Eduard Ranghiuc"
						},
						{
							"_nume": "Andrei Raileanu"
						},
						{
							"_nume": "Cristian Haulica"
						},
						{
							"_nume": "Alina Covachi"
						},
						{
							"_nume": "Rares Balaita"
						},
						{
							"_nume": "Mircea Hulea"
						},
						{
							"_nume": "Cristian Olariu"
						},
						{
							"_nume": "Raluca Popescu"
						},
						{
							"_nume": "Razvan Gavrilas"
						},
						{
							"_nume": "Denis Bogdanas"
						},
						{
							"_nume": "Adrian Baban"
						},
						{
							"_nume": "Tudor Moga"
						},
						{
							"_nume": "Radu Papadopol"
						},
						{
							"_nume": "Simona Iulia Cantea"
						},
						{
							"_nume": "Daniel Costin"
						},
						{
							"_nume": "Alina Coroi"
						},
						{
							"_nume": "Alexandru Cojocarul"
						},
						{
							"_nume": "Pavel Spinu"
						},
						{
							"_nume": "Florin Miron"
						},
						{
							"_nume": "Ancuta Hamciuc"
						},
						{
							"_nume": "Mihai Hamciuc"
						},
						{
							"_nume": "Ana Plesea"
						},
						{
							"_nume": "Mirela Oprea"
						},
						{
							"_nume": "Alin Herbu"
						},
						{
							"_nume": "Ovidiu Mardar"
						},
						{
							"_nume": "Cristina Alexandroaei"
						},
						{
							"_nume": "Constantin Parascan"
						},
						{
							"_nume": "Iulian Vorobiov"
						},
						{
							"_nume": "Bogdan Carlescu"
						},
						{
							"_nume": "Radu Gavril"
						},
						{
							"_nume": "Khalid Muasher"
						},
						{
							"_nume": "Valentin Pal"
						},
						{
							"_nume": "Bogdan Filip"
						},
						{
							"_nume": "Bogdan Romanescu"
						},
						{
							"_nume": "Sorin Zebreniuc"
						},
						{
							"_nume": "Razvan Glodeanu"
						},
						{
							"_nume": "Elena Popovici"
						},
						{
							"_nume": "Laura Alexandru"
						},
						{
							"_nume": "Andrei Ochesel"
						},
						{
							"_nume": "Neculai Blebdea"
						},
						{
							"_nume": "Carmen Marin"
						},
						{
							"_nume": "Adina Smau"
						},
						{
							"_nume": "Andreea Rusu"
						},
						{
							"_nume": "Radu Ilinca"
						},
						{
							"_nume": "Mihaela Prisecaru"
						},
						{
							"_nume": "Mihaela Zavate"
						},
						{
							"_nume": "Ciprian Doru Iordache"
						},
						{
							"_nume": "Bogdan Nicolae Leonte"
						},
						{
							"_nume": "Radu Cristian Tempu"
						},
						{
							"_nume": "Mariana Aruxandrei"
						},
						{
							"_nume": "Gabriela Sfetcu"
						},
						{
							"_nume": "Monalisa Apintei"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Tehnopress",
				"numarPagini": "704",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_principii_tehnici_aplicatii_Leon.jpg"
					},
					"__text": "Mircea Sechelea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in inteligenta artifiala"
						},
						{
							"_nr": "2",
							"__text": "Metode traditionale de reprezentare a cunoasterii"
						},
						{
							"_nr": "3",
							"__text": "Teoria multimilor fuzzy"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi genetici"
						},
						{
							"_nr": "5",
							"__text": "Perceptronii multistrat"
						},
						{
							"_nr": "6",
							"__text": "Soft computing"
						},
						{
							"_nr": "7",
							"__text": "Rezolvarea generala a problemelor"
						},
						{
							"_nr": "8",
							"__text": "Rationamentul bazat pe cazuri"
						},
						{
							"_nr": "9",
							"__text": "Limbaje de programare declarative"
						},
						{
							"_nr": "10",
							"__text": "Prelucrarea limbajului natural"
						},
						{
							"_nr": "11",
							"__text": "Prelucrarea imaginilor. Compresia fractala"
						},
						{
							"_nr": "12",
							"__text": "Sisteme de informatii geografice"
						},
						{
							"_nr": "13",
							"__text": "Ingineria cunoasterii. Ontologii"
						},
						{
							"_nr": "14",
							"__text": "Web-ul semantic"
						},
						{
							"_nr": "15",
							"__text": "Invatarea abductiva. Invatarea transductiva"
						},
						{
							"_nr": "16",
							"__text": "Invatarea cu intarire"
						},
						{
							"_nr": "17",
							"__text": "Data mining"
						},
						{
							"_nr": "18",
							"__text": "Data mining spatial"
						},
						{
							"_nr": "19",
							"__text": "Data mining temporal"
						},
						{
							"_nr": "20",
							"__text": "Arbori de decizie"
						},
						{
							"_nr": "21",
							"__text": "Retele bayesiene"
						},
						{
							"_nr": "22",
							"__text": "Retele neuronale recurente"
						},
						{
							"_nr": "23",
							"__text": "Retele de memorii asociate"
						},
						{
							"_nr": "24",
							"__text": "Retele nesupervizate feedforward"
						},
						{
							"_nr": "25",
							"__text": "Retele neuronale cu functii de baza radiala si eliptica"
						},
						{
							"_nr": "26",
							"__text": "Retele cu logica adaptativa"
						},
						{
							"_nr": "27",
							"__text": "Retele fara ponderi si bazate pe memorie"
						},
						{
							"_nr": "28",
							"__text": "Retele neoronale stohastice"
						},
						{
							"_nr": "29",
							"__text": "Retele ontogenetice supervizate"
						},
						{
							"_nr": "30",
							"__text": "Teoria rezonantei adaptive"
						},
						{
							"_nr": "31",
							"__text": "Masini cu vector suport"
						},
						{
							"_nr": "32",
							"__text": "Arhitectri reconfigurabile pentru procesarea digitala a semnalelor"
						},
						{
							"_nr": "33",
							"__text": "Robotica"
						},
						{
							"_nr": "34",
							"__text": "Agenti inteligenti. Sisteme multiagent"
						},
						{
							"_nr": "35",
							"__text": "Viata artificiala"
						},
						{
							"_nr": "36",
							"__text": "Auto-organizare si emergenta"
						},
						{
							"_nr": "37",
							"__text": "Autoreproducerea"
						},
						{
							"_nr": "38",
							"__text": "Automate celulare"
						},
						{
							"_nr": "39",
							"__text": "Teoria jocurilor"
						},
						{
							"_nr": "40",
							"__text": "Echilibrul sistemelor inteligente dinamice"
						}
					]
				},
				"_id": "546",
				"_isbn": "973-702-423-0"
			},
			{
				"titlu": "Agenti inteligenti cu capacitati cognitive",
				"autori": {
					"autor": {
						"_nume": "Florin Leon"
					}
				},
				"anAparitie": "2006",
				"editura": "Tehnopress",
				"numarPagini": "272",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Agenti_inteligenti_cu_capacitati_cognitive_Leon.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in teoria agentilor"
						},
						{
							"_nr": "II",
							"__text": "Arhitecturi de agenti inteligenti"
						},
						{
							"_nr": "III",
							"__text": "Sisteme multiagent"
						},
						{
							"_nr": "IV",
							"__text": "Teoria sistemelor fuzzy"
						},
						{
							"_nr": "V",
							"__text": "Retele neuronale"
						},
						{
							"_nr": "VI",
							"__text": "Sisteme hibride neuro-fuzzy"
						},
						{
							"_nr": "VII",
							"__text": "Invarea in sistemele multiagent"
						},
						{
							"_nr": "VIII",
							"__text": "Structura unui agent inteligent cu capacitati cognitive"
						},
						{
							"_nr": "IX",
							"__text": "Studiu experimental"
						}
					]
				},
				"_id": "547",
				"_isbn": "973-702-422-2"
			},
			{
				"titlu": "Exploarea datelor web. Aplicatii",
				"autori": {
					"autor": {
						"_nume": "Florin Leon"
					}
				},
				"anAparitie": "2008",
				"editura": "Tehnopress",
				"numarPagini": "150",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Exploarea_datelor_web_Aplicatii_Leon.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "Exploarea utilizarii"
						},
						{
							"_nr": "1",
							"__text": "Statistici web"
						},
						{
							"_nr": "2",
							"__text": "Clasificarea bazata pe cei mai apropriati vecini"
						},
						{
							"_nr": "3",
							"__text": "Clasificarea bazata pe arbori de decizie"
						},
						{
							"_nr": "4",
							"__text": "Inductia regulilor de asociere"
						},
						{
							"_nr": "Partea 2",
							"__text": "Exploarea continutului"
						},
						{
							"_nr": "5",
							"__text": "Retele neuronale"
						},
						{
							"_nr": "6",
							"__text": "Clasificarea bayesiana"
						},
						{
							"_nr": "7",
							"__text": "Partitionarea k-medii"
						},
						{
							"_nr": "Partea 3",
							"__text": "Explorarea structurii"
						},
						{
							"_nr": "8",
							"__text": "Motoare de cautare"
						},
						{
							"_nr": "9",
							"__text": "Relevanta paginilor web"
						}
					]
				},
				"_id": "548",
				"_isbn": "973-702-530-X"
			},
			{
				"titlu": "Tehnici de simulare numerica cu Matlab",
				"autori": {
					"autor": {
						"_nume": "Damian Trif"
					}
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "246",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_simulare_numerica_cu_Matlab_Trif.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Derivare automata"
						},
						{
							"_nr": "3",
							"__text": "Aritmetica intervalelor"
						},
						{
							"_nr": "4",
							"__text": "Probleme rau conditionate"
						},
						{
							"_nr": "5",
							"__text": "Calcul cu functii"
						}
					]
				},
				"_id": "549",
				"_isbn": "978-973-88389-9-4"
			},
			{
				"titlu": "Proiectarea si implementarea bazelor de date",
				"autori": {
					"autor": [
						{
							"_nume": "Mirela Danubianu"
						},
						{
							"_nume": "Tiberiu Socaciu"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "InfoData",
				"numarPagini": "242",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Sorina Nicoleta Stanica"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_si_implementare_bazelor_de_date_Danubianu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Capitolul 1"
						},
						{
							"_nr": "1.1",
							"__text": "Introducere"
						},
						{
							"_nr": "1.2",
							"__text": "Evolutia activitatii de organizare si prelucare a datelor"
						},
						{
							"_nr": "1.3",
							"__text": "Arhitectura bazelor de date"
						},
						{
							"_nr": "1.4",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "1.5",
							"__text": "Mediul bazelor de date"
						},
						{
							"_nr": "2",
							"__text": "Capitolul 2"
						},
						{
							"_nr": "2.1",
							"__text": "Modelul de date si modelare conceptuala"
						},
						{
							"_nr": "2.2",
							"__text": "Modelul de date relational"
						},
						{
							"_nr": "3",
							"__text": "Capitolul 3"
						},
						{
							"_nr": "3.1",
							"__text": "Limbaje relationale. Generalitati"
						},
						{
							"_nr": "3.2",
							"__text": "Algebra relationala"
						},
						{
							"_nr": "3.3",
							"__text": "Calcul relational"
						},
						{
							"_nr": "4",
							"__text": "Capitolul 4"
						},
						{
							"_nr": "4.1",
							"__text": "Limbajul SQL"
						},
						{
							"_nr": "4.2",
							"__text": "Vederi"
						},
						{
							"_nr": "5",
							"__text": "Capitolul 5"
						},
						{
							"_nr": "5.1",
							"__text": "Prezentarea generala a mediului Visual FoxPro"
						},
						{
							"_nr": "5.2",
							"__text": "Prezentare Project Manager"
						},
						{
							"_nr": "6",
							"__text": "Capitolul 6"
						},
						{
							"_nr": "6.1",
							"__text": "Notiuni elementare de proiectare a bazelor de date"
						},
						{
							"_nr": "7",
							"__text": "Capitolul 7"
						},
						{
							"_nr": "7.1",
							"__text": "Crearea tabelelor si lucrul cu inregistrarile"
						},
						{
							"_nr": "7.2",
							"__text": "Lucrul cu inregistrarile"
						},
						{
							"_nr": "7.3",
							"__text": "Ordonarea datelor"
						},
						{
							"_nr": "7.4",
							"__text": "Utilizarea mai multor tabele"
						},
						{
							"_nr": "8",
							"__text": "Capitolul 8"
						},
						{
							"_nr": "8.1",
							"__text": "Crearea bazelor de date"
						},
						{
							"_nr": "9",
							"__text": "Capitolul 9"
						},
						{
							"_nr": "9.1",
							"__text": "Mecanismele programarii in Visual FoxPro"
						},
						{
							"_nr": "10",
							"__text": "Capitolul 10"
						},
						{
							"_nr": "10.1",
							"__text": "Formulare"
						},
						{
							"_nr": "11",
							"__text": "Capitolul 11"
						},
						{
							"_nr": "11.1",
							"__text": "Utilizarea controalelor"
						},
						{
							"_nr": "11.2",
							"__text": "Punerea la dispozitia utilizatorului a unui set de optiuni predeterminate"
						},
						{
							"_nr": "11.3",
							"__text": "Acceptarea intrarilor de date care nu pot fi anticipate"
						},
						{
							"_nr": "11.4",
							"__text": "Acceptarea intrarilor de date care apartin unui interval dat"
						},
						{
							"_nr": "11.5",
							"__text": "Permisiunea acordata utilizatorilor de a efecuta anumite actiuni"
						},
						{
							"_nr": "11.6",
							"__text": "Efectuarea unor actiuni specifice la anumite intervale de timp"
						},
						{
							"_nr": "11.7",
							"__text": "Afisarea de informatii"
						},
						{
							"_nr": "11.8",
							"__text": "Manipularea mai multor linii de date"
						},
						{
							"_nr": "11.9",
							"__text": "Simplificarea utilizarii controalelor"
						},
						{
							"_nr": "12",
							"__text": "Capitolul 12"
						},
						{
							"_nr": "12.1",
							"__text": "Rapoarte"
						},
						{
							"_nr": "13",
							"__text": "Capitolul 13"
						},
						{
							"_nr": "13.1",
							"__text": "Meniuri"
						}
					]
				},
				"_id": "550",
				"_isbn": "978-973-1803-45-6"
			},
			{
				"titlu": "Securitatea informatiei (Volumnul 1)",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "2006",
				"editura": "InfoData",
				"numarPagini": "226",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Securitatea_informatiei_Volumul_1_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de criptare"
						},
						{
							"_nr": "2",
							"__text": "Sisteme simetrice de criptare"
						},
						{
							"_nr": "3",
							"__text": "Sisteme mecanice de criptare"
						},
						{
							"_nr": "4",
							"__text": "Sisteme fluide de criptare"
						},
						{
							"_nr": "5",
							"__text": "Sisteme simetrice de criptare moderne"
						},
						{
							"_nr": "6",
							"__text": "Atacuri asupra sistemelor de criptare bloc"
						},
						{
							"_nr": "7",
							"__text": "Sisteme de criptare cu cheie publica"
						},
						{
							"_nr": "8",
							"__text": "Sistemul de criptare RSA"
						},
						{
							"_nr": "9",
							"__text": "Sistemul de criptare El Gamal"
						},
						{
							"_nr": "10",
							"__text": "Sistem de criptare bazate pe curbe eliptice"
						},
						{
							"_nr": "11",
							"__text": "Alte sisteme de criptare cu cheie publica"
						},
						{
							"_nr": "12",
							"__text": "Generatori de numere pseudo-aleatoare"
						}
					]
				},
				"_id": "551",
				"_isbn": "978-973-731-8031-6"
			},
			{
				"titlu": "Ghidul bobocului pentru Access",
				"autori": {
					"autor": {
						"_nume": "Paul McFedries"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "148"
				},
				"traducere": {
					"titluOriginal": "The complete idiot's guide to Access",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Claudiu Lian Sebe"
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Irina Ciobanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Vladulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_Access_McFedries.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Simtind teama, trebuie totusi sa  o faceti : inceperea lucrului cu programul Access"
						},
						{
							"_nr": "Partea II",
							"__text": "Sa fim ordonati : tabelele Access"
						},
						{
							"_nr": "Partea III",
							"__text": "Instrumente de baza pentru imblanzirea 'bestiei' numite Access"
						},
						{
							"_nr": "Partea IV",
							"__text": "Cere si ti se va da: interogarea tabelelor Access"
						},
						{
							"_nr": "Partea V",
							"__text": "Impresionarea prieteilor si a familiei cu rapoartele programului Access"
						},
						{
							"_nr": "Partea VI",
							"__text": "Excesul de Access: subiecte avansate pentru curajosi"
						}
					]
				},
				"_id": "552",
				"_isbn": "973-601-483-5"
			},
			{
				"titlu": "Lotus 1-2-3 sub Windows pentru toti",
				"autori": {
					"autor": {
						"_nume": "John Walkenbach"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "368",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "118"
				},
				"traducere": {
					"titluOriginal": "1-2-3 For Windows For Dummies",
					"editura": "IDGBooks Worldwide",
					"traducatori": {
						"traducator": "Dan Mihai Pavelescu"
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cristian Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marcel Stanciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Lotus_1_2_3_sub_Windows_pentru_toti_Walkenbach.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Sa incepem cu inceputul"
						},
						{
							"_nr": "Partea II",
							"__text": "Lucruri esentiale ce trebue stiute"
						},
						{
							"_nr": "Partea III",
							"__text": "Cum sa-i impresionati pe cei usor impresionabili"
						},
						{
							"_nr": "Partea IV",
							"__text": "Trasul pe sfoara"
						},
						{
							"_nr": "Partea V",
							"__text": "Partea celor zece"
						},
						{
							"_nr": "Partea VI",
							"__text": "Anexe"
						}
					]
				},
				"_id": "553",
				"_isbn": "973-601-326-X"
			},
			{
				"titlu": "Retele pentru toti",
				"autori": {
					"autor": {
						"_nume": "Doug Lowe"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "352",
				"pretOriginal": "12000",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "72"
				},
				"traducere": {
					"titluOriginal": "Networking For Dummies",
					"editura": "IDGBooks Worldwide",
					"traducatori": {
						"traducator": "Ecaterina Vasile"
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cristian Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alin Dragomir"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_pentru_toti_Lowe.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Notiuni de baza (Ghidul utilizatorului de retea)"
						},
						{
							"_nr": "Partea II",
							"__text": "Construiti-va singur propria dvs. retea"
						},
						{
							"_nr": "Partea III",
							"__text": "Ghidul nataraului pentru administrarea retelei"
						},
						{
							"_nr": "Partea IV",
							"__text": "Partea decaloagelor"
						},
						{
							"_nr": "Partea V",
							"__text": "Referinte pentru oameni adevarati"
						}
					]
				},
				"_id": "554",
				"_isbn": "973-601-258-1"
			},
			{
				"titlu": "Modemuri",
				"autori": {
					"autor": [
						{
							"_nume": "Esther Schindler"
						},
						{
							"_nume": "Steven Vaughan-Nichols"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "318",
				"pretOriginal": "17000",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "91"
				},
				"traducere": {
					"titluOriginal": "New Riders' Guide To Modems",
					"editura": "New Riders",
					"traducatori": {
						"traducator": "Dana Paradovschi"
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Modemuri_Schindler.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "Prima intalnire cu modemul"
						},
						{
							"_nr": "Partea a 2-a",
							"__text": "Despre softul modemului"
						},
						{
							"_nr": "Partea a 3-a",
							"__text": "Ce puteti face cu un modem"
						},
						{
							"_nr": "Partea a 4-a",
							"__text": "Trusa de instrumente online"
						},
						{
							"_nr": "Partea a 5-a",
							"__text": "Cand lucrurile nu merg bine"
						},
						{
							"_nr": "Partea a 6-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "555",
				"_isbn": "973-601-314-6"
			},
			{
				"titlu": "Utilizare Excel 5 pentru Windows",
				"autori": {
					"autor": {
						"_nume": "Joshua C. Nossiter"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "352",
				"pretOriginal": "17900",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "130"
				},
				"traducere": {
					"titluOriginal": "Using Excel 5 for Windows",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Calin Suciu"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizare_Excel_5_pentru_Windows_Nossiter.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Faceti cunostinta cu programul Excel"
						},
						{
							"_nr": "II",
							"__text": "Tehnici de baza : editare, formatare si altele"
						},
						{
							"_nr": "III",
							"__text": "Formule si functii"
						},
						{
							"_nr": "IV",
							"__text": "Utilizarea diagramelor"
						},
						{
							"_nr": "V",
							"__text": "Analiza datelor tabelare"
						},
						{
							"_nr": "VI",
							"__text": "Utilizarea programului Excel ca baza de date"
						},
						{
							"_nr": "VII",
							"__text": "Legarea, importul si altele"
						},
						{
							"_nr": "VIII",
							"__text": "Indexuri"
						}
					]
				},
				"_id": "556",
				"_isbn": "973-601-359-6"
			},
			{
				"titlu": "Introducere in Microsoft Windows 98 (Editie Beta)",
				"autori": {
					"autor": {
						"_nume": "Russell Borland"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "512",
				"pretOriginal": "70000",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "245"
				},
				"traducere": {
					"titluOriginal": "Introducing Microsoft Windows 98 , Beta edition",
					"editura": "Microsoft Corporation",
					"traducatori": {
						"traducator": "Nocolae Ionescu Crutan"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_Microsoft_Windows_98_Borland.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Instalare si configurare"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Interfata sistemului de operare Windows 98 in Internet"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Extinderea posibilitatilor de lucru cu Windows 98"
						},
						{
							"_nr": "Parta a IV-a",
							"__text": "Facilitati hardware si software"
						}
					]
				},
				"_id": "557",
				"_isbn": "973-601-652-8"
			},
			{
				"titlu": "Limbajele C si C++ pentru incepatori. Limbajul C++. Volumul II (reeditare)",
				"autori": {
					"autor": {
						"_nume": "Liviu Negrescu"
					}
				},
				"anAparitie": "2000",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "520",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "35",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajele_C_si_Cpp_pentru_Incepatori_Vol_II_Reeditare_Negrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "20",
							"__text": "Limbajul C++ ca extensie a limbajului C"
						},
						{
							"_nr": "21",
							"__text": "Programarea prin abstractizarea datelor"
						},
						{
							"_nr": "22",
							"__text": "Clase"
						},
						{
							"_nr": "23",
							"__text": "Supraincarcarea operatorilor"
						},
						{
							"_nr": "24",
							"__text": "Conversii"
						},
						{
							"_nr": "25",
							"__text": "Modificatorul cast pentru obiecte si functii membru"
						},
						{
							"_nr": "26",
							"__text": "Operatorul de deferentiere a pointerilor spre membrii claselor (.* si ->*)"
						},
						{
							"_nr": "27",
							"__text": "Concluzii asupra programarii prin abstractizarea datelor"
						},
						{
							"_nr": "28",
							"__text": "Programare orientata spre obiecte"
						},
						{
							"_nr": "29",
							"__text": "Clase derivate si clase de baza"
						},
						{
							"_nr": "30",
							"__text": "Functii virtuale"
						},
						{
							"_nr": "31",
							"__text": "Intrarii/Iesiri"
						}
					]
				},
				"_id": "558",
				"_isbn": "973-9443-51-6"
			},
			{
				"titlu": "Limbajul Java",
				"autori": {
					"autor": {
						"_nume": "Eugen Rotariu"
					}
				},
				"anAparitie": "1996",
				"editura": "Computer Press Agora",
				"numarPagini": "192",
				"pretOriginal": "12000",
				"tehnoredactori": {
					"tehnoredactor": "Adrian Pop"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_Java_Rotariu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Parta I-a",
							"__text": "Fundamentele programarii"
						},
						{
							"_nr": "1",
							"__text": "Arhitectura calculatoarelor"
						},
						{
							"_nr": "2",
							"__text": "Limbajde de programare"
						},
						{
							"_nr": "3",
							"__text": "Reprezentarea informatiilor cu obiecte"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Descrierea limbajului Java"
						},
						{
							"_nr": "4",
							"__text": "Structura lexicala Java"
						},
						{
							"_nr": "5",
							"__text": "Componente de baza ale programelor Java"
						},
						{
							"_nr": "6",
							"__text": "Obiecte Java"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Tehnici de programare in Java"
						},
						{
							"_nr": "7",
							"__text": "Modele de programare"
						},
						{
							"_nr": "8",
							"__text": "Structura programelor"
						},
						{
							"_nr": "9",
							"__text": "Fire de executie si sincronizare"
						}
					]
				},
				"_id": "559",
				"_isbn": "973-97706-1-4"
			},
			{
				"titlu": "Grafica 3D in OpenGL",
				"autori": {
					"autor": [
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Marius Dorian Zaharia"
						},
						{
							"_nume": "Zoea Racovita"
						},
						{
							"_nume": "Irina Georgecu Mocanu"
						},
						{
							"_nume": "Catalin Tudose"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Printech",
				"numarPagini": "155",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Grafica_3D_in_OpenGL_Moldoveanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conventii de numire a functiilor, constantelor si tipurilor de date OpenGL"
						},
						{
							"_nr": "2",
							"__text": "Functii FLUT de realizare a interfetei cu utilizatorul"
						},
						{
							"_nr": "3",
							"__text": "Exceutia aplicatiei"
						},
						{
							"_nr": "4",
							"__text": "Crearea unui aplicatii OpenGL folosind GLUT"
						},
						{
							"_nr": "5",
							"__text": "Afisarea obiectelor 3D predefinite"
						},
						{
							"_nr": "6",
							"__text": "Specificarea culorilor"
						},
						{
							"_nr": "7",
							"__text": "Definirea primitivelor geometrice"
						},
						{
							"_nr": "8",
							"__text": "Specificarea atributelor de afisare ale primitivelor geometrice"
						},
						{
							"_nr": "9",
							"__text": "Transformari ale obiectelor 3D in OpenGL"
						},
						{
							"_nr": "10",
							"__text": "Iluminarea"
						},
						{
							"_nr": "11",
							"__text": "Liste de afisare"
						},
						{
							"_nr": "12",
							"__text": "Grafica raster in OpenGL"
						},
						{
							"_nr": "13",
							"__text": "Aplicarea texturilor pe suprafetele obiectelor"
						},
						{
							"_nr": "14",
							"__text": "Evaluatori OpenGL"
						},
						{
							"_nr": "15",
							"__text": "Tehnici de vizualizare realista in OpenGL"
						},
						{
							"_nr": "16",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "560",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in Web 2.0",
				"autori": {
					"autor": [
						{
							"_nume": "Sabin Buraga"
						},
						{
							"_nume": "Andrei Mihaila"
						},
						{
							"_nume": "Ciprian Amariei"
						},
						{
							"_nume": "Andreea Pantescu"
						},
						{
							"_nume": "Anca Paula Luca"
						},
						{
							"_nume": "Ecaterina Valica"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Polirom",
				"numarPagini": "264",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Radu Capraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Web_2_0_Buraga.jpg"
					},
					"__text": "Carmen Parii"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Load-balancing in clustere Wrb - nucleul dur al Web 2.0"
						},
						{
							"_nr": "2",
							"__text": "JavaScript : componenta sine qua non a aplicatiilor Web 2.0"
						},
						{
							"_nr": "3",
							"__text": "Inginerie Web - revizuirea codului-sursa"
						},
						{
							"_nr": "4",
							"__text": "Recomandari Web prin intermediul  microformatelor"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii hibride: mashup-uri"
						}
					]
				},
				"_id": "561",
				"_isbn": "978-973-46-0816-4"
			},
			{
				"titlu": "Protectia si securitatea informatiilor [Editia a II-a]",
				"autori": {
					"autor": {
						"_nume": "Dumitru Oprea"
					}
				},
				"anAparitie": "2007",
				"editura": "Polirom",
				"numarPagini": "448",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Radu Capraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Protectia_si_securitatea_informatiilor_II_Oprea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Cadrul general al protectiei si securitatii sistemelor informationale"
						},
						{
							"_nr": "II",
							"__text": "Protectia informatiilor prin clasificarea lor"
						},
						{
							"_nr": "III",
							"__text": "Controlul accesului in sistemelor informationale"
						},
						{
							"_nr": "IV",
							"__text": "Politici, standarde, norme si proceduri de securitate"
						},
						{
							"_nr": "V",
							"__text": "Criptografia"
						},
						{
							"_nr": "VI",
							"__text": "Asigurarea securitatii sistemelor informationale publice si private"
						},
						{
							"_nr": "VII",
							"__text": "Securitatea telecomunicatiilor si a retelelor"
						},
						{
							"_nr": "VIII",
							"__text": "Globalizarea sistemelor si securizarea spatiului cibernetic"
						},
						{
							"_nr": "IX",
							"__text": "Virusii calculatoarelor si programele antivirus"
						},
						{
							"_nr": "X",
							"__text": "Aspecte juridice privind protectia si securitatea sistemelor informationale"
						}
					]
				},
				"_id": "562",
				"_isbn": "978-973-46-0927-7"
			},
			{
				"titlu": "Ghid de orientare Microsoft Windows 95",
				"autori": {
					"autor": {
						"_nume": "Stephen L. Nelson"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "200",
				"pretOriginal": "0",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "228"
				},
				"traducere": {
					"titluOriginal": "Field Guide to Microsoft Windows 95",
					"editura": "Microsoft Corporation",
					"traducatori": {
						"traducator": "Mihaela Claudia Tarpa"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_orientare_Microsoft_Windows_95_Nelson.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Mediul de lucru"
						},
						{
							"_nr": "2",
							"__text": "Windows 95 de la A la Z"
						},
						{
							"_nr": "3",
							"__text": "Ghide de depanare"
						},
						{
							"_nr": "4",
							"__text": "Referinte rapide"
						},
						{
							"_nr": "5",
							"__text": "Index"
						}
					]
				},
				"_id": "563",
				"_isbn": "973-601-487-8"
			},
			{
				"titlu": "Proiectarea cu microprocesoare",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Capatina"
						},
						{
							"_nume": "Marius Cornea-Hasegan"
						},
						{
							"_nume": "Mircea Pusca"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Dacia",
				"numarPagini": "348",
				"pretOriginal": "22",
				"pretCumparare": "5000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. HLavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_cu_microprocesoare_Pusca.jpg"
					},
					"__text": "Mircea Baciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Structura sistemelor microprocesor"
						},
						{
							"_nr": "3",
							"__text": "Interconectarea componentelor unui sistem"
						},
						{
							"_nr": "4",
							"__text": "Programarea"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea cu microprocesoare"
						}
					]
				},
				"_id": "564",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare. Arhitectura interna, programare, aplicatii",
				"autori": {
					"autor": {
						"_nume": "Ioan Dancea"
					}
				},
				"anAparitie": "1979",
				"editura": "Dacia",
				"numarPagini": "272",
				"pretOriginal": "13.50",
				"colectia": {
					"_numarul": "10",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Mircea Stefan"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. HLavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesoare_Arhitectura_interna_programare_aplicatii_Dancea.jpg"
					},
					"__text": "Mircea Baciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Microprocesorul. Concepte fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Componentele unui microsistem"
						},
						{
							"_nr": "3",
							"__text": "Programarea microprocesoarelor"
						},
						{
							"_nr": "4",
							"__text": "Microprocesoare semnificative"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii ale microprocesoarelor"
						}
					]
				},
				"_id": "565",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Lupu"
						},
						{
							"_nume": "Vlad Tepelea"
						},
						{
							"_nume": "Emil Purice"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Militara",
				"numarPagini": "458",
				"pretOriginal": "40",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Ion Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gh. Cucos"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesoare_Aplicatii_Lupu.jpg"
					},
					"__text": "Al. Iulian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatoare. Microprocesoare. Istoric si perspective"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea structurilor de control cu microprocesoare"
						},
						{
							"_nr": "3",
							"__text": "Microprocesoare SINGLE-CHIP"
						},
						{
							"_nr": "4",
							"__text": "SD-800. Un sistem de dezvoltare pentru microprocesoare"
						},
						{
							"_nr": "5",
							"__text": "Limbajul de asamblare 8080. Tehnici de programare"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii ale microprocesorului 8080"
						},
						{
							"_nr": "7",
							"__text": "Microprogramare"
						},
						{
							"_nr": "8",
							"__text": "Sisteme suport pentru microprogramare"
						},
						{
							"_nr": "9",
							"__text": "Microprocesoare BIT-SLICE"
						},
						{
							"_nr": "10",
							"__text": "Aplicatii ale microprocesoarelor BIT-SLICE"
						}
					]
				},
				"_id": "566",
				"_isbn": ""
			},
			{
				"titlu": "Limbajul de programare COBOL. Aplicatii financiar-contabile pe microprocesoare PC",
				"autori": {
					"autor": [
						{
							"_nume": "Stefanescu Alexandru"
						},
						{
							"_nume": "Popa Gheorghe"
						},
						{
							"_nume": "Ionescu Bogdan"
						},
						{
							"_nume": "Lupu Liviu"
						},
						{
							"_nume": "Posseler Marius"
						},
						{
							"_nume": "Mares Valeria"
						},
						{
							"_nume": "Cosacescu Luana"
						},
						{
							"_nume": "Cumpanasu Ion"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Cison",
				"numarPagini": "408",
				"pretOriginal": "0",
				"pretCumparare": "128000",
				"coperta": {
					"imagine": {
						"_path": "Limbajul_de_programare_Cobol_Aplicatii_finaciar_contabile_pe_microcalculatoare_PC_Stefanescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Aplicatii ale limbajului de programare 'COBOL'"
						},
						{
							"_nr": "1.1",
							"__text": "Aspecte introductive privind programarea in limbajul de programare 'COBOL'"
						},
						{
							"_nr": "1.2",
							"__text": "Elemente si structuri de baza ele limbajului de programare 'COBOL'"
						},
						{
							"_nr": "1.3",
							"__text": "Aplicarea limbajului de programare 'COBOL' in elaborarea programelor specifice gestiunii financiar-contabile"
						}
					]
				},
				"_id": "567",
				"_isbn": "973-96370-6-X"
			},
			{
				"titlu": "333 probleme de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Moraru"
						},
						{
							"_nume": "Gheorghe Marian"
						},
						{
							"_nume": "Costin Badica"
						},
						{
							"_nume": "Carmin Badica"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "128",
				"pretOriginal": "2990",
				"colectia": {
					"_numarul": "20",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "333_probleme_de_programare_Mocanu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Algoritmi elementari"
						},
						{
							"_nr": "II",
							"__text": "Metode numerice"
						},
						{
							"_nr": "III",
							"__text": "Aplicatii de sortarte si cautate"
						},
						{
							"_nr": "IV",
							"__text": "Probleme diverse"
						}
					]
				},
				"_id": "568",
				"_isbn": "973-601-113-5"
			},
			{
				"titlu": "Limbaje si calculator",
				"autori": {
					"autor": {
						"_nume": "Vlad Bazon"
					}
				},
				"anAparitie": "1999",
				"editura": "Petrion",
				"numarPagini": "288",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_si_calculator_Bazon.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "De la limbaj la microprocesor"
						},
						{
							"_nr": "II",
							"__text": "Investigarea setului de instructiuni I80486"
						},
						{
							"_nr": "III",
							"__text": "Exemple de programare (asm, DOS, Pascal, C++)"
						},
						{
							"_nr": "IV",
							"__text": "Implementarea numerelor naturale si operatii cu numere mari"
						}
					]
				},
				"_id": "569",
				"_isbn": "973-9116-45-0"
			},
			{
				"titlu": "Programare functionala. O perspectiva pragmatica",
				"autori": {
					"autor": {
						"_nume": "Cristian Giumale"
					}
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "502",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Solvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_functionala_O_perspectiva_pragmatica_Giumale.jpg"
					},
					"__text": "Silvia Candea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date si sinteze tipurilor"
						},
						{
							"_nr": "3",
							"__text": "Variabile si inchideri functionale"
						},
						{
							"_nr": "4",
							"__text": "Functii si exceptii"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii in stil functional"
						},
						{
							"_nr": "6",
							"__text": "Referinte si efecte laterale limitate"
						},
						{
							"_nr": "7",
							"__text": "Evaluarea lenesa, fluxuri"
						},
						{
							"_nr": "8",
							"__text": "Interfatarea si modularizarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Specificarea datelor si verificarea programelor"
						},
						{
							"_nr": "10",
							"__text": "O masina de calcul Lambda"
						},
						{
							"_nr": "Anexa A",
							"__text": "Notatii"
						},
						{
							"_nr": "Anexa B",
							"__text": "Sintaxa limbajului Caml"
						},
						{
							"_nr": "Anexa C",
							"__text": "Biblioteca standard Caml"
						},
						{
							"_nr": "Anexa D",
							"__text": "Comenzi Caml Light"
						}
					]
				},
				"_id": "570",
				"_isbn": "973-31-1063-9"
			},
			{
				"titlu": "Multimedia. Concepte si practica",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Smeureanu"
						},
						{
							"_nume": "Georgeta Drula"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Cison",
				"numarPagini": "412",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Multimedia_Concepte_si_practica_Smeureanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Tehnologii si echipamente"
						},
						{
							"_nr": "3",
							"__text": "Resurse software"
						},
						{
							"_nr": "4",
							"__text": "Tehnoligia sunetului computerizat"
						},
						{
							"_nr": "5",
							"__text": "Imaginea"
						},
						{
							"_nr": "6",
							"__text": "Video digital"
						},
						{
							"_nr": "7",
							"__text": "Realizarea aplicatiilor multimedia in limbaje de programare vizuale. Visual Basic"
						},
						{
							"_nr": "8",
							"__text": "Limbaje specializate ale multimediei"
						},
						{
							"_nr": "9",
							"__text": "Multimedia Toolbook"
						},
						{
							"_nr": "10",
							"__text": "Director"
						},
						{
							"_nr": "11",
							"__text": "Authorware professional"
						},
						{
							"_nr": "12",
							"__text": "Baze de date multimedia"
						},
						{
							"_nr": "13",
							"__text": "Aplicatii multimedia"
						},
						{
							"_nr": "14",
							"__text": "Dictionar multimedia"
						}
					]
				},
				"_id": "571",
				"_isbn": "973-96370-8-6"
			},
			{
				"titlu": "MS-DOS 6.2 - comenzi, metode, exemple",
				"autori": {
					"autor": [
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Marius Joldos"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Promedia",
				"numarPagini": "320",
				"pretOriginal": "4490",
				"pretCumparare": "500",
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_6_2_Comenzi_metode_exemple_Muntean.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere si conventii"
						},
						{
							"_nr": "2",
							"__text": "Bazele utilizarii MS-DOS"
						},
						{
							"_nr": "3",
							"__text": "Comenzile MS-DOS 6"
						},
						{
							"_nr": "4",
							"__text": "Administrarea sistemului"
						},
						{
							"_nr": "5",
							"__text": "Configurartea sistemului"
						},
						{
							"_nr": "6",
							"__text": "Diagnoza si depanarea MS-DOS 6.2"
						}
					]
				},
				"_id": "572",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul Felix C-256. Limbajul Assiris",
				"autori": {
					"autor": [
						{
							"_nume": "Al. Teodorescu"
						},
						{
							"_nume": "C. Popescu"
						},
						{
							"_nume": "I. Catona"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "450",
				"pretOriginal": "36",
				"redactori": {
					"redactor": "Luminita Zorilescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_Felix_C_256_Limbajul_Assiris_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Elemente de matematica si informatica"
						},
						{
							"_nr": "Partea II",
							"__text": "Sistemul de calcil Felix C-256"
						},
						{
							"_nr": "Partea III",
							"__text": "Limbajul Assiris"
						}
					]
				},
				"_id": "573",
				"_isbn": ""
			},
			{
				"titlu": "Microsft Visual Basic 6.0 - Ghidul programatorului",
				"autori": {
					"autor": {
						"_nume": "Microsoft Press"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "930",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "Microsoft Visual Basic 6.0 Programmer's Guide",
					"editura": "Microsoft Press",
					"traducatori": {
						"traducator": [
							"Liviu Braicu",
							"Lucian Limona"
						]
					},
					"_an": "1999",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Visual_Basic_6_0_Ghidul_Programatorului_Microsoft_Press.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Bazele limbajului Visual Basic"
						},
						{
							"_nr": "Partea II",
							"__text": "Ce se poate face cu Visual Basic"
						},
						{
							"_nr": "Anexa A",
							"__text": "Specificatii, limitari si formate de fisiere in Visual Basic"
						},
						{
							"_nr": "Anexa B",
							"__text": "Conventii de scriere a codului Visual Basic"
						},
						{
							"_nr": "Anexa C",
							"__text": "Comutatoarele de compilare in cod nativ"
						},
						{
							"_nr": "Anaxa D",
							"__text": "Adaugarea fisierelor Help in aplicatie"
						}
					]
				},
				"_id": "574",
				"_isbn": "973-501-459-2"
			},
			{
				"titlu": "Unix",
				"autori": {
					"autor": {
						"_nume": "Razvan Daniel Zota"
					}
				},
				"anAparitie": "2004",
				"editura": "ASE",
				"numarPagini": "216",
				"pretOriginal": "106000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Nica"
				},
				"coperta": {
					"imagine": {
						"_path": "Unix_Zota.jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "UNIX - caracteristici generale"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de fisiere UNIX si comenzi"
						},
						{
							"_nr": "3",
							"__text": "Administrarea fisierelor si directoarelor"
						},
						{
							"_nr": "4",
							"__text": "Servicii de retea"
						},
						{
							"_nr": "5",
							"__text": "Editoare de text"
						},
						{
							"_nr": "6",
							"__text": "Shell-uri UNIX"
						},
						{
							"_nr": "7",
							"__text": "Bourne Shell"
						},
						{
							"_nr": "8",
							"__text": "Bourne Again Shel"
						}
					]
				},
				"_id": "575",
				"_isbn": "973-594-513-4"
			},
			{
				"titlu": "Metode numerice si programe Pascal",
				"autori": {
					"autor": {
						"_nume": "Afrodita Iorgulescu"
					}
				},
				"anAparitie": "2001",
				"editura": "ASE",
				"numarPagini": "336",
				"pretOriginal": "99000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_si_programe_Pascal_Iorgulescu_ASE.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Erori"
						},
						{
							"_nr": "2",
							"__text": "Solutia ecuatiilor si a sistemelor de ecuatii neliniare"
						},
						{
							"_nr": "3",
							"__text": "Solutiile ecuatiilor algebrice"
						},
						{
							"_nr": "4",
							"__text": "Metode matriciale si sisteme de ecuatii algebrice liniare"
						},
						{
							"_nr": "5",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "6",
							"__text": "Aproximarea functiilor"
						},
						{
							"_nr": "7",
							"__text": "Derivarea si integrarea numerica"
						},
						{
							"_nr": "8",
							"__text": "Solutia problemei cu valoare initiala"
						}
					]
				},
				"_id": "576",
				"_isbn": "973-594-062-0"
			},
			{
				"titlu": "Seria ABCWindows 98",
				"autori": {
					"autor": [
						{
							"_nume": "Sharon Crawford"
						},
						{
							"_nume": "Neil J. Salkind"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "60000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "282",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The ABCs of Windows 98",
					"editura": "Sybex",
					"traducatori": {
						"traducator": "Bogdan Pisai"
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dragos Chitu"
				},
				"coperta": {
					"imagine": {
						"_path": "Seria_ABC_Windows_98_Crawford.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Windows 98"
						},
						{
							"_nr": "2",
							"__text": "Suprafata de lucru in Windows 98"
						},
						{
							"_nr": "3",
							"__text": "Crearea si utilizarea comenzilor rapide"
						},
						{
							"_nr": "4",
							"__text": "Lucrul cu mouse-ul"
						},
						{
							"_nr": "5",
							"__text": "Windows 98 Explorer"
						},
						{
							"_nr": "6",
							"__text": "Lucrul cu fisiere si directoare"
						},
						{
							"_nr": "7",
							"__text": "Rularea aplicatiilor"
						},
						{
							"_nr": "8",
							"__text": "Utilizarea Recycle Bin"
						},
						{
							"_nr": "9",
							"__text": "Conectarea cu lumea exterioara"
						},
						{
							"_nr": "10",
							"__text": "Utilizarea Outlook Express"
						},
						{
							"_nr": "11",
							"__text": "Navigarea prin Web"
						},
						{
							"_nr": "12",
							"__text": "DOS si Windows 98"
						},
						{
							"_nr": "13",
							"__text": "Modificari hardware in Windows 98"
						},
						{
							"_nr": "14",
							"__text": "Vazand si auzind multimedia"
						},
						{
							"_nr": "15",
							"__text": "Configurarea aplicatiei Control Panel"
						},
						{
							"_nr": "16",
							"__text": "Lumea applet-urilor din Windows 98"
						},
						{
							"_nr": "17",
							"__text": "Mentinerea integritatii sistemului"
						},
						{
							"_nr": "Anexa A",
							"__text": "Instalarea sistemului din Windows 95"
						},
						{
							"_nr": "Anexa B",
							"__text": "Instalarea pornind de la sistemul Windows 3.1"
						},
						{
							"_nr": "Glosar"
						}
					]
				},
				"_id": "577",
				"_isbn": "973-601-908-X"
			},
			{
				"titlu": "Programarea calculatoarelor. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Catalina - Lucia Cocianu"
						},
						{
							"_nume": "Cristian Uscatu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "122",
				"pretOriginal": "53000",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Claudia-Marinela Dumitru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Daniela Panait"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_Aplicatii_Rosca.jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Lucrul cu masive memorate in zona HEAP"
						},
						{
							"_nr": "2",
							"__text": "Implementarea structurii de lista in limbajul Pascal"
						},
						{
							"_nr": "3",
							"__text": "Grafuri. Implementari in limbajul Pascal"
						},
						{
							"_nr": "4",
							"__text": "Arbori"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi recursivi. Metode Divide et Impera si Backtracking"
						},
						{
							"_nr": "6",
							"__text": "Reprezentarea vizuala a datelor"
						},
						{
							"_nr": "7",
							"__text": "Obiecte in Pascal"
						}
					]
				},
				"_id": "578",
				"_isbn": "973-594-171-6"
			},
			{
				"titlu": "Informatica [IX]",
				"autori": {
					"autor": {
						"_nume": "Octavian Lucian Popa"
					}
				},
				"anAparitie": "2000",
				"editura": "Nemira",
				"numarPagini": "160",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Dan Radulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_IX_Popa.jpg"
					},
					"__text": "Ravan Luscov"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi. Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Limbajul Pascal"
						},
						{
							"_nr": "3",
							"__text": "Intructiunile libajului Pascal"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de date definete de utilizator"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de calcul"
						},
						{
							"_nr": "6",
							"__text": "Editarea de texte si procesarea acestora"
						},
						{
							"_nr": "7",
							"__text": "Editoare grafice : editorul Paintbrush"
						},
						{
							"_nr": "8",
							"__text": "Programe de arhivare / dezarhivare"
						},
						{
							"_nr": "9",
							"__text": "Visrusi si antivirusi"
						}
					]
				},
				"_id": "579",
				"_isbn": "973-569-420-X"
			},
			{
				"titlu": "Programarea si utilizarea masinilor de calcul si elemente de caclul numeric si informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Marcel Rosculet"
						},
						{
							"_nume": "Paraschiv Balea"
						},
						{
							"_nume": "Serban Moldoveanu"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Didactica si pedagogica",
				"numarPagini": "236",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Mariana Craiu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ghimis"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_si_utilizarea_masinilor_de_calcul_si_elemente_de_calcul_numeric_si_informatica_Rosculet.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Etapele rezolvarii unei probleme tehnici-stiintifice cu ajutorul calculatorului numeric"
						},
						{
							"_nr": "2",
							"__text": "Elemente de analiza numerica aplicate la probleme de chimie"
						},
						{
							"_nr": "3",
							"__text": "Elemente de programare in limbajul FORTRAN"
						}
					]
				},
				"_id": "580",
				"_isbn": ""
			},
			{
				"titlu": "Modelarea si simularea proceselor economice [editia II]",
				"autori": {
					"autor": {
						"_nume": "Camelia Ratiu-Suciu"
					}
				},
				"anAparitie": "1997",
				"editura": "Didactica si pedagogica",
				"numarPagini": "288",
				"pretOriginal": "12000",
				"pretCumparare": "50000",
				"tehnoredactori": {
					"tehnoredactor": "Elena Stan"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_si_simularea_proceselor_economice_II_Suciu.jpg"
					},
					"__text": "Dumitru Smalenic"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Problematica modelarii economice. Aspecte generale"
						},
						{
							"_nr": "1",
							"__text": "Obiectul de studiu. Metode. Concepte. Clasificari"
						},
						{
							"_nr": "2",
							"__text": "Elemente de logica formala"
						},
						{
							"_nr": "3",
							"__text": "Locul activitatii de modelare in analiza de sistem"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Modelarea proceselor economice"
						},
						{
							"_nr": "4",
							"__text": "Construirea modelelor"
						},
						{
							"_nr": "5",
							"__text": "Modelarea fenomenelor de piata"
						},
						{
							"_nr": "6",
							"__text": "Modelarea situatiilor concurentiale"
						},
						{
							"_nr": "7",
							"__text": "Modelarea proceselor decizionale multicriteriale"
						},
						{
							"_nr": "8",
							"__text": "Modelarea proceselor de alocare a resurselor"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Metode de simulare"
						},
						{
							"_nr": "9",
							"__text": "Simularea numerica .Generarea numerelor aleatoare"
						},
						{
							"_nr": "10",
							"__text": "Metoda Monte Carlo"
						},
						{
							"_nr": "11",
							"__text": "Simularea prin joc a proceselor economice"
						},
						{
							"_nr": "12",
							"__text": "Simularea proceselor economice cu ajutorul tehnicilor Forrester"
						},
						{
							"_nr": "13",
							"__text": "Limbaje de simulare"
						}
					]
				},
				"_id": "581",
				"_isbn": "973-30-5692-8"
			},
			{
				"titlu": "Modelarea si simularea proceselor economice. Lucrari practice: studii de caz, proiect, teste",
				"autori": {
					"autor": [
						{
							"_nume": "Camelia Ratiu-Suciu"
						},
						{
							"_nume": "Daniela Hincu"
						},
						{
							"_nume": "Florica Luban"
						},
						{
							"_nume": "Mara Sarchiz"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Didactica si pedagogica",
				"numarPagini": "248",
				"pretOriginal": "15000",
				"redactori": {
					"redactor": "Marius Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Stan"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_si_simularea_proceselor_economice_Lucrari_practice_I_Suciu.jpg"
					},
					"__text": "Dumitru Smalenic"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Studii de caz"
						},
						{
							"_nr": "1",
							"__text": "Procese complexe de piata - productie - asigurare cu resurse materiale"
						},
						{
							"_nr": "2",
							"__text": "Procese decizionale la nivel de firma"
						},
						{
							"_nr": "3",
							"__text": "Simularea Monte Carlo"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Proiect"
						},
						{
							"_nr": "4",
							"__text": "Decizia manageriala asistata de calculator pentru indeplinirea obiectivelor propuse la S.C. \"Flora\" S.A."
						},
						{
							"_nr": "Partea a treia",
							"__text": "Teste"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Teme pentru lucrari de cercetare stiintifica"
						}
					]
				},
				"_id": "582",
				"_isbn": "973-30-5825-4"
			},
			{
				"titlu": "Modelarea si simularea proceselor economice. Lucrari practice: studii de caz, teste de autoevaluare [editia II]",
				"autori": {
					"autor": [
						{
							"_nume": "Camelia Ratiu-Suciu"
						},
						{
							"_nume": "Daniela Hincu"
						},
						{
							"_nume": "Florica Luban"
						},
						{
							"_nume": "Nadia Ene"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Didactica si pedagogica",
				"numarPagini": "320",
				"pretOriginal": "50000",
				"redactori": {
					"redactor": "Marius Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Stan"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_si_simularea_proceselor_economice_Lucrari_practice_II_Suciu.jpg"
					},
					"__text": "Dumitru Smalenic"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Studii de caz"
						},
						{
							"_nr": "1",
							"__text": "Probleme economice complexe tratate prin prisma planului de afaceri al organizatiei. Rezolvare cu modele analitice si prin simularea Monte Carlo"
						},
						{
							"_nr": "2",
							"__text": "Dimenionarea si alocarea resurselor organizatiei cu modele analitice si de simulare"
						},
						{
							"_nr": "3",
							"__text": "Procese decizionale prin cadrul organizatiei. Rezolvare cu modele analitice si tehnica simularii"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Teste"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Teme pentru lucrari de cercetare stiintifica pentru studenti"
						}
					]
				},
				"_id": "583",
				"_isbn": "973-30-9733-0"
			},
			{
				"titlu": "Modelarea si simularea proceselor economice: teorie si practica",
				"autori": {
					"autor": {
						"_nume": "Camelia Rariu-Suciu"
					}
				},
				"anAparitie": "2001",
				"editura": "Economica",
				"numarPagini": "432",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Carmen Taranu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Angela Cotofana"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_si_simularea_proceselor_conomice_teorie_si_practica_Suciu.jpg"
					},
					"__text": "Adriana Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Problematica modelarii si simularii proceselor economice sub aspect managerial"
						},
						{
							"_nr": "1",
							"__text": "Modelarea economico-matematica, alternatica la \"exeperimentul\" din stiintele exacte. Metode. Concepte. Clasificari"
						},
						{
							"_nr": "2",
							"__text": "Simularea numerica. Generarea numerelor aleatoare"
						},
						{
							"_nr": "3",
							"__text": "Simularea stochastica cu tehnica Monte Carlo"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Modele economico-matematice analitice si de simulare pentru rezolvarea unor procese economice"
						},
						{
							"_nr": "4",
							"__text": "Modelarea fenomenelor de piata"
						},
						{
							"_nr": "5",
							"__text": "Studiul de senzitivitate a solutiilor optimale. Facilitati de analiza in sistem conversational"
						},
						{
							"_nr": "6",
							"__text": "Modelarea situatiilor concurentiale"
						},
						{
							"_nr": "7",
							"__text": "Modelarea proceselor decizionale multicriteriale"
						},
						{
							"_nr": "8",
							"__text": "Modele economico-matematice si de simulare pentru utilizarea si alocarea resurselor (materiale, umane, banesti si de timp) in cadrul unei organizatii"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Simularea de tip joc si tip Forrester"
						},
						{
							"_nr": "9",
							"__text": "Simularea prin joc a proceselor economice"
						},
						{
							"_nr": "10",
							"__text": "Simularea sistemelor continue cu tehnici Forrester"
						},
						{
							"_nr": "11",
							"__text": "Limbaje de simulare"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Locul activitatii de modelare si simulare in proiectul de analiza de sistem al organizatiei"
						},
						{
							"_nr": "12",
							"__text": "Conceptele sistem, analiza de sistem in cadrul organizatiei social-economice"
						},
						{
							"_nr": "13",
							"__text": "Aspecte de referinta in cadrul metodologiilor de tip ameliorativ si de tip constructiv"
						},
						{
							"_nr": "14",
							"__text": "Specificarea construirii modelelor economico-matematice"
						},
						{
							"_nr": "15",
							"__text": "Produse informatice in exploatare pentru utilizarea metodelor cantitative si a tehnicilor specifice de management"
						}
					]
				},
				"_id": "584",
				"_isbn": "973-590-448-9"
			},
			{
				"titlu": "Modelarea economica",
				"autori": {
					"autor": {
						"_nume": "Camelia Ratiu-Suciu"
					}
				},
				"anAparitie": "1997",
				"editura": "Sylvi",
				"numarPagini": "248",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Triscaru Oana"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelare_economica_Suciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rezolvarea problemelor economice de tip liniar cu produsul informatic QM (Quantitative Analysis for Management)"
						},
						{
							"_nr": "2",
							"__text": "Modelarea unor procese de piata"
						},
						{
							"_nr": "3",
							"__text": "Modelarea unor procese de luare a deciziei"
						},
						{
							"_nr": "4",
							"__text": "Modelarea unor procese economice de tip retea"
						},
						{
							"_nr": "5",
							"__text": "Modelarea unor procese de stocare"
						},
						{
							"_nr": "6",
							"__text": "Simularea unor procese economice cu tehnica Monte Carlo"
						}
					]
				},
				"_id": "585",
				"_isbn": "973-9175-39-2"
			},
			{
				"titlu": "Metode numerice cu programe in Fortran IV",
				"autori": {
					"autor": [
						{
							"_nume": "William S.Dorn"
						},
						{
							"_nume": "Daniel D. McCraken"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"tiraj": "92000+50 exemplare legate",
				"numarPagini": "468",
				"pretOriginal": "34",
				"traducere": {
					"titluOriginal": "Numerical Methods with FORTRAN IV. Case Studies",
					"editura": "John Wiley and Sons",
					"traducatori": {
						"traducator": [
							"Iulian Stran",
							"Alexandru Satran",
							"Ioan Draghici",
							"Mircea Adam"
						]
					},
					"_an": "1972",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_cu_programe_in_Fortran_IV_Dorn.jpg"
					},
					"__text": "Eugen Keri"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rezolvarea ecuatiilor"
						},
						{
							"_nr": "2",
							"__text": "Erori"
						},
						{
							"_nr": "3",
							"__text": "Instabilitati numerice si tratarea lor"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de ecuatii algebrice liniare"
						},
						{
							"_nr": "5",
							"__text": "Derivarea si integrarea numerice"
						},
						{
							"_nr": "6",
							"__text": "Interpolarea"
						},
						{
							"_nr": "7",
							"__text": "Metoda celor mai mici patrate"
						},
						{
							"_nr": "8",
							"__text": "Ecuatii diferentiale ordinare"
						}
					]
				},
				"_id": "586",
				"_isbn": ""
			},
			{
				"titlu": "Proiectarea interfetelor utilizator",
				"autori": {
					"autor": {
						"_nume": "Alain Cooper"
					}
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "488",
				"pretOriginal": "0",
				"pretCumparare": "54500",
				"traducere": {
					"titluOriginal": "ABOUT FACE. The Essentials of User Interface Design",
					"editura": "IDG Books",
					"traducatori": {
						"traducator": "Liana Cecal"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Margareta Chilim"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_Interfetelor_Grafice_Cooper.jpg"
					},
					"__text": "Liana Cecal"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Scopul"
						},
						{
							"_nr": "Partea II",
							"__text": "Forma"
						},
						{
							"_nr": "Partea III",
							"__text": "Comportarea"
						},
						{
							"_nr": "Partea IV",
							"__text": "Interactiunea"
						},
						{
							"_nr": "V",
							"__text": "Distributia"
						},
						{
							"_nr": "VI",
							"__text": "Gizmo-urile"
						},
						{
							"_nr": "VII",
							"__text": "Gardianul"
						},
						{
							"_nr": "VIII",
							"__text": "Profesorul"
						}
					]
				},
				"_id": "587",
				"_isbn": "973-31-1085-X"
			},
			{
				"titlu": "Teoria sistemelor dinamice",
				"autori": {
					"autor": [
						{
							"_nume": "R.E. Kalman"
						},
						{
							"_nume": "P.L. Falb"
						},
						{
							"_nume": "M.A. Arbib"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "3700+50 exemplare legate",
				"numarPagini": "330",
				"pretOriginal": "27",
				"pretCumparare": "30000",
				"traducere": {
					"titluOriginal": "Topics in mathematical system theory",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": [
							"Vlad Ionescu",
							"Laurentiu Lupas",
							"Corneliu Popeea"
						]
					},
					"_an": "1969",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_sistemelor_dinamice_Kalman.jpg"
					},
					"__text": "Alexandru Banu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Teoria elementara a reglarii din punct de vedere modern"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Teoria conducerii optimale"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Teoria automatelor"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Progrese in teoria moderna a sistemelor liniare"
						}
					]
				},
				"_id": "588",
				"_isbn": ""
			},
			{
				"titlu": "Modelarea sistemelor de operare",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Dodescu"
					}
				},
				"anAparitie": "1981",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "430",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Maria Blaga"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_sistemelor_de_operare_Dodescu.jpg"
					},
					"__text": "Gheorghe Motora"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de operare pentru procese secventiale"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de operare pentru procese concurente (paralele)"
						},
						{
							"_nr": "4",
							"__text": "Functia sistemelor de operare privind gestiunea memoriei"
						},
						{
							"_nr": "5",
							"__text": "Functia sistemului de operare privind gestiunea activitatilor la nivelul unitatii centrale"
						},
						{
							"_nr": "6",
							"__text": "Functia sistemului de operare privind gestiunea dispozitivelor periferice"
						},
						{
							"_nr": "7",
							"__text": "Functia sistemelor de operare privind gestiunea informatiei"
						},
						{
							"_nr": "8",
							"__text": "Procese software"
						},
						{
							"_nr": "9",
							"__text": "Software pentru banci de date"
						},
						{
							"_nr": "10",
							"__text": "Caracteristicile unui sistem de operare pentru Time-Sharing (T-S)"
						},
						{
							"_nr": "11",
							"__text": "Sisteme de operare pentru procesele in Timp-Real (ON-LINE)"
						}
					]
				},
				"_id": "589",
				"_isbn": ""
			},
			{
				"titlu": "Sondaje statistice si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Monica Dumitrescu"
					}
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "192",
				"pretOriginal": "0",
				"pretCumparare": "70800",
				"coperta": {
					"imagine": {
						"_path": "Sondaje_statistice_si_aplicatii_Dumitrescu.jpg"
					},
					"__text": "Sorana Grigoras, Vlad Oancea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Populatii finite"
						},
						{
							"_nr": "2",
							"__text": "Planuri de sondaje aleatoare"
						},
						{
							"_nr": "3",
							"__text": "Sondaje in populatii simple"
						},
						{
							"_nr": "4",
							"__text": "Sondaje in populatii grupate"
						},
						{
							"_nr": "5",
							"__text": "Sondaje in populatii stratificate"
						},
						{
							"_nr": "6",
							"__text": "Sondaje cu informatie suplimentara"
						},
						{
							"_nr": "7",
							"__text": "Sondaje complexe"
						},
						{
							"_nr": "8",
							"__text": "Analiza statistica a datelor"
						},
						{
							"_nr": "9",
							"__text": "Control statistic final"
						}
					]
				},
				"_id": "590",
				"_isbn": "973-31-1512-6"
			},
			{
				"titlu": "Inteligenta artificiala. Demonstrarea automata a teoremelor. Prelucrarea limbajului natural",
				"autori": {
					"autor": {
						"_nume": "Doina Tatar"
					}
				},
				"anAparitie": "2001",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "230",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "126",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Doina Tatar"
				},
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_Demonstrarea_automata_a_teoremelor_Prelucrarea_limbajului_natural_Tatar.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Demonstrarea automata de teoreme"
						},
						{
							"_nr": "1.1",
							"__text": "Scurt istoric"
						},
						{
							"_nr": "1.2",
							"__text": "Calculul propozitiilor"
						},
						{
							"_nr": "1.3",
							"__text": "Demonstrare de teoreme in calculul predicatelor"
						},
						{
							"_nr": "2",
							"__text": "Prelucrarea limbajului natural"
						},
						{
							"_nr": "2.1",
							"__text": "Introducere"
						},
						{
							"_nr": "2.2",
							"__text": "Gramatici DCG (Define Clause Grammars)"
						},
						{
							"_nr": "2.3",
							"__text": "Procesare morfologica"
						},
						{
							"_nr": "2.4",
							"__text": "Gramatici de unificare"
						},
						{
							"_nr": "2.5",
							"__text": "Structuri de atribute fara tipuri"
						},
						{
							"_nr": "2.6",
							"__text": "Structuri de atribute tipizate"
						},
						{
							"_nr": "2.7",
							"__text": "O gramatica de unificare cu tipuri (HPSG : Head-Driven Phrase Structure Grammar)"
						},
						{
							"_nr": "2.8",
							"__text": "Probleme de semantica limbajului natural"
						},
						{
							"_nr": "2.9",
							"__text": "Interfete in limbaj natural"
						}
					]
				},
				"_id": "591",
				"_isbn": "973-9443-99-0"
			},
			{
				"titlu": "Introducere algebrica in informatica : Limbaje formale(vol II)",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Creanga"
						},
						{
							"_nume": "C. Reischer"
						},
						{
							"_nume": "Dan Simovici"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Junimea",
				"tiraj": "6200",
				"numarPagini": "200",
				"pretOriginal": "11.50",
				"redactori": {
					"redactor": "Mihai Gradinaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Bujdei"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_algebrica_in_informatica_Limbaje_formale_Creanga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Elemente de algebra"
						},
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Notiuni de teoria multimilor"
						},
						{
							"_nr": "2",
							"__text": "Latici"
						},
						{
							"_nr": "3",
							"__text": "Semigrupuri"
						},
						{
							"_nr": "4",
							"__text": "Spatii metrice si spatii liniare"
						},
						{
							"_nr": "5",
							"__text": "Spatiul metric al limbajelor"
						},
						{
							"_nr": "6",
							"__text": "Ecuatii pe multimea limbajelor"
						},
						{
							"_nr": "7",
							"__text": "Functii recursive"
						},
						{
							"_nr": "8",
							"__text": "Elemente de logica matematica"
						},
						{
							"_nr": "Partea II",
							"__text": "Limbaje formale"
						},
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Gramatici"
						},
						{
							"_nr": "2",
							"__text": "Ierarhia gramaticilor"
						},
						{
							"_nr": "3",
							"__text": "Autmate finite si limbaje regulate"
						},
						{
							"_nr": "4",
							"__text": "Limbaje de tip 3"
						},
						{
							"_nr": "5",
							"__text": "Limbaje de tip 2 (limbaje libere de context)"
						},
						{
							"_nr": "6",
							"__text": "Caracterizarea limbajelor de tip 3 in clasa limbajelor de tip 2"
						},
						{
							"_nr": "7",
							"__text": "Limbaje de tip ALGOL"
						},
						{
							"_nr": "8",
							"__text": "Automate pushdown (automate cu pile de memorie)"
						},
						{
							"_nr": "9",
							"__text": "Automate de tip Leticevski"
						},
						{
							"_nr": "10",
							"__text": "Limbaje de tip 1 (limbaje sensibile la context)"
						},
						{
							"_nr": "11",
							"__text": "Automate liniar marginite"
						},
						{
							"_nr": "12",
							"__text": "Masini Turind - dispozitive de recunoastere a limbajelor"
						},
						{
							"_nr": "13",
							"__text": "Masini Turing - dispozitive de calcul"
						},
						{
							"_nr": "14",
							"__text": "Multimi recursiv enumerative si limbaje"
						},
						{
							"_nr": "15",
							"__text": "Limbaje de tip 0"
						}
					]
				},
				"_id": "592",
				"_isbn": ""
			},
			{
				"titlu": "Programarea calculatoarelor (Editia Pascal)",
				"autori": {
					"autor": [
						{
							"_nume": "Dorel Lucanu"
						},
						{
							"_nume": "Toader Jucan"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "236",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Dana Lungu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_Editia_Pascal_Lucanu.jpg"
					},
					"__text": "Al. Cvasnai"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Limbaje de programare"
						},
						{
							"_nr": "3",
							"__text": "MiniPascal"
						},
						{
							"_nr": "4",
							"__text": "De le MiniPascal la Pascal"
						},
						{
							"_nr": "5",
							"__text": "Subprograme"
						},
						{
							"_nr": "6",
							"__text": "Tipuri de data structurate"
						},
						{
							"_nr": "7",
							"__text": "Programare modulara"
						},
						{
							"_nr": "8",
							"__text": "Fisiere"
						},
						{
							"_nr": "9",
							"__text": "Eficienta programelor"
						}
					]
				},
				"_id": "593",
				"_isbn": "973-8243-30-0"
			},
			{
				"titlu": "Elemente de baza ale sitemului TEX si Plain TEX [Editia II]",
				"autori": {
					"autor": {
						"_nume": "Costica Morosanu"
					}
				},
				"anAparitie": "2008",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "236",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Mariana Pricop"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_baza_ale_sistemului_TEX_si_Plain_TEX_Morosanu.jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este TEX"
						},
						{
							"_nr": "2",
							"__text": "Fonturi utilizate de plain TEX"
						},
						{
							"_nr": "3",
							"__text": "Spatieri, concatenari si salturi"
						},
						{
							"_nr": "4",
							"__text": "Paragrafe"
						},
						{
							"_nr": "5",
							"__text": "Organizarea si proiectarea paginilor"
						},
						{
							"_nr": "6",
							"__text": "Boxuri"
						},
						{
							"_nr": "7",
							"__text": "Realizarea tabelelor"
						},
						{
							"_nr": "8",
							"__text": "Scrierea matematica"
						},
						{
							"_nr": "9",
							"__text": "Ce este LATEX"
						}
					]
				},
				"_id": "594",
				"_isbn": "978-973-703-320-8"
			},
			{
				"titlu": "C++. Manual complet",
				"autori": {
					"autor": {
						"_nume": "Herbert Schildt"
					}
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "646",
				"pretOriginal": "350000",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "C++: The Complete Reference,2e",
					"editura": "McGraw-Hill Companies",
					"traducatori": {
						"traducator": "Mihai Dabuleanu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cristina Mihai"
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_manual_complet_2000_Schildt.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Baza limbajului C++: Limbajul C"
						},
						{
							"_nr": "1",
							"__text": "O privire de ansamblu asupra limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Expresii"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Matrice si siruri"
						},
						{
							"_nr": "5",
							"__text": "Pointeri"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Structuri, uniuni, enumerari si tipuri definite de utilizator"
						},
						{
							"_nr": "8",
							"__text": "I/O la consola"
						},
						{
							"_nr": "9",
							"__text": "I/O cu fisiere"
						},
						{
							"_nr": "10",
							"__text": "Preprocesorul. Comentarii"
						},
						{
							"_nr": "II",
							"__text": "C++ - Caracteristici specifice"
						},
						{
							"_nr": "11",
							"__text": "O privire de ansamblu asupra limbajului C++"
						},
						{
							"_nr": "12",
							"__text": "Clase si obecte"
						},
						{
							"_nr": "13",
							"__text": "Matrice, pointeri si referinte"
						},
						{
							"_nr": "14",
							"__text": "Supraincarcarea functiilor si a operatorilor"
						},
						{
							"_nr": "15",
							"__text": "Mostenirea"
						},
						{
							"_nr": "16",
							"__text": "Functii virtuale si polimorfism"
						},
						{
							"_nr": "17",
							"__text": "Bazele sitemului de I/O din C++"
						},
						{
							"_nr": "18",
							"__text": "I/O cu fisiere in C++"
						},
						{
							"_nr": "19",
							"__text": "I/O bazate pe matrice"
						},
						{
							"_nr": "20",
							"__text": "Sabloane"
						},
						{
							"_nr": "21",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "22",
							"__text": "Elemente diverse si caracteristici avansate"
						},
						{
							"_nr": "III",
							"__text": "Cateva aplicatii de C++"
						},
						{
							"_nr": "23",
							"__text": "O clasa de tip sir"
						},
						{
							"_nr": "24",
							"__text": "O clasa pentru afisarea ferestrelor"
						},
						{
							"_nr": "25",
							"__text": "O clasa generica de liste inlantuite"
						}
					]
				},
				"_id": "595",
				"_isbn": "973-601-595-5"
			},
			{
				"titlu": "Lectii de retele I sau primele tale 7 lectii de networking",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Socaciu"
					}
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "96",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Lectii_de_retele_Vol_I_Socaciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tuneluri si Bridge-uri"
						},
						{
							"_nr": "2",
							"__text": "VLAN, TRUNK si STP"
						},
						{
							"_nr": "3",
							"__text": "Limitari de viteze in retele"
						},
						{
							"_nr": "4",
							"__text": "MAC si ARP"
						},
						{
							"_nr": "5",
							"__text": "MAC si ARP; ARPING si pachete ARP"
						},
						{
							"_nr": "6",
							"__text": "MAC si ARP; Protectia la nivel de MAC"
						},
						{
							"_nr": "7",
							"__text": "Tehnici de protectie anti-frauda in retelele de cartier"
						},
						{
							"_nr": "8",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "9",
							"__text": "Rezolvarea problemelor propuse"
						}
					]
				},
				"_id": "596",
				"_isbn": "978-973-88224-3-2"
			},
			{
				"titlu": "Dezvoltarea de aplicatii pentru terminale mobile",
				"autori": {
					"autor": [
						{
							"_nume": "Marcel Cremene"
						},
						{
							"_nume": "Kuderna-Iulian Benta"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "138",
				"pretOriginal": "150000",
				"colectia": {
					"_numarul": "209",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_de_aplicatii_pentru_terminale_mobile_Cremene.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modemuri, Comenzi AT, Aplicatii CTI"
						},
						{
							"_nr": "3",
							"__text": "Aplicatii cu SMS si MMS"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii cu interfata vocala"
						},
						{
							"_nr": "5",
							"__text": "Acces internet mobil, WAP"
						},
						{
							"_nr": "6",
							"__text": "Generrea dinamica de pagini Web si accesul la baze de date"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii VXML si WAP cu continut dinamic"
						},
						{
							"_nr": "8",
							"__text": "Introducere in J2ME"
						},
						{
							"_nr": "9",
							"__text": "Dezvoltarea interfetelor grafice in J2ME"
						},
						{
							"_nr": "10",
							"__text": "Aplicatii J2ME distribuite"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea Bluetooth in J2ME"
						},
						{
							"_nr": "12",
							"__text": "Stocarea persistenta a datelor in J2ME"
						}
					]
				},
				"_id": "597",
				"_isbn": "973-650-185-X"
			},
			{
				"titlu": "Distributed computing and systems : a practical approach",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Salomie"
						},
						{
							"_nume": "Tudor Cioara"
						},
						{
							"_nume": "Ionut Anghel"
						},
						{
							"_nume": "Tudor Salomie"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Albastra",
				"numarPagini": "368",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "230",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Distributed_computing_and_systems_a_practical_approach_Salomie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Basics of programming Web Applications"
						},
						{
							"_nr": "2",
							"__text": "Distributed objects"
						},
						{
							"_nr": "3",
							"__text": "Component-based distributed systems"
						},
						{
							"_nr": "4",
							"__text": "Message-based distributed systems"
						},
						{
							"_nr": "5",
							"__text": "Service oriented architectures and Web Services"
						},
						{
							"_nr": "6",
							"__text": "Business processes and workflows"
						}
					]
				},
				"_id": "598",
				"_isbn": "978-973-650-234-7"
			},
			{
				"titlu": "Sisteme multiagent",
				"autori": {
					"autor": {
						"_nume": "Dan Floroian"
					}
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "202",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "235",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_multiagent_Floroian.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte suport"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura sistemului de control care permite reingineria"
						},
						{
							"_nr": "4",
							"__text": "Proiectarea unui sistem cu multiagenti"
						}
					]
				},
				"_id": "599",
				"_isbn": "978-973-650-249-1"
			},
			{
				"titlu": "Grafe: teorie, algoritmi si aplicatii [reeditare]",
				"autori": {
					"autor": {
						"_nume": "Teodor Teoadere"
					}
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "199",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "27",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafe_teorie_algoritmi_si_aplicatii_Teodere.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Spatii liniare asociate grafelor"
						},
						{
							"_nr": "3",
							"__text": "Drumuri in grafe"
						},
						{
							"_nr": "4",
							"__text": "Fluxuri si retele de transport"
						},
						{
							"_nr": "5",
							"__text": "Cuplaje in grafe"
						}
					]
				},
				"_id": "600",
				"_isbn": "978-973-650-256-9"
			},
			{
				"titlu": "Grafica pe calculator si in retele",
				"autori": {
					"autor": {
						"_nume": "Gelu Tomsa"
					}
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "228",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Gelu Tomsa"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_pe_calculator_si_in_retea_Tomsa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de geometrie computationala"
						},
						{
							"_nr": "2",
							"__text": "Grafica LOGO"
						},
						{
							"_nr": "3",
							"__text": "Grafica pentru WEB si multimedia"
						}
					]
				},
				"_id": "601",
				"_isbn": "978-973-87774-5-3"
			},
			{
				"titlu": "Bioinformatica",
				"autori": {
					"autor": {
						"_nume": "Ionut David"
					}
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "182",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Bioinformatica_David.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Amino-acizi"
						},
						{
							"_nr": "3",
							"__text": "Proteine"
						},
						{
							"_nr": "4",
							"__text": "PDB (Protein Data Bank) - Banca de date Proteice si SCOP (Structural Classification of Proteins) - Clasificarea Structurala a Proteinelor"
						},
						{
							"_nr": "5",
							"__text": "\"Data mining\" (selectia si verificarea datelor)"
						},
						{
							"_nr": "6",
							"__text": "\"Pattern Recognition and Discovery\" (descoperirea si recunoasterea pattern-urilor) in bioinformatica"
						},
						{
							"_nr": "7",
							"__text": "\"Pattern Matching\" (potrivirea pattern-urilor)"
						},
						{
							"_nr": "8",
							"__text": "RMSD (Root Mean Square Deviation) - deviatii atomice"
						},
						{
							"_nr": "9",
							"__text": "Proiect de cercetare  - \"O noua clasificare a familiilor de proteine prin comparatia domeniilor regiunilor de interactie intre toate famiile de proteine la nivel atomic\""
						},
						{
							"_nr": "10",
							"__text": "Unelte software utilizate in Bioinformatica"
						}
					]
				},
				"_id": "602",
				"_isbn": "978-973-87774-7-7"
			},
			{
				"titlu": "Initiere in Turbo Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Eugenia Kalisz"
						},
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Irina Athanasiu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "152",
				"pretOriginal": "16000",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "37",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Turbo_Pascal_Kalisz_1998.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Utilizarea mediului de programare Turbo Pascal"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Expresii booleene, instructiunea if"
						},
						{
							"_nr": "4",
							"__text": "Cicluri repeat si while"
						},
						{
							"_nr": "5",
							"__text": "Cicluri for"
						},
						{
							"_nr": "6",
							"__text": "Declaratii de tip, tipul subdomeniu, tipul tablou"
						},
						{
							"_nr": "7",
							"__text": "Tipul real. Prelucrari combinate"
						},
						{
							"_nr": "8",
							"__text": "Tipurile char si string"
						},
						{
							"_nr": "9",
							"__text": "Tipuri inregistrare. Fisiere"
						},
						{
							"_nr": "10",
							"__text": "Subprograme (I)"
						},
						{
							"_nr": "11",
							"__text": "Subprograme (II)"
						},
						{
							"_nr": "12",
							"__text": "Cautare si ordonare"
						}
					]
				},
				"_id": "603",
				"_isbn": "973-601-182-2"
			},
			{
				"titlu": "Atac la Java",
				"autori": {
					"autor": [
						{
							"_nume": "Kathy Sierra"
						},
						{
							"_nume": "Bert Bates"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Teora",
				"numarPagini": "680",
				"pretOriginal": "690000",
				"pretCumparare": "690000",
				"traducere": {
					"titluOriginal": "Head First Java Second Edition",
					"editura": "O'Reilly Media",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "2005",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Atac_la_Java_Sierra.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sa spargem gheata: incepem atacul la Java"
						},
						{
							"_nr": "2",
							"__text": "O calatorie in lumea obiectelor: da, vor aparea si obiectele"
						},
						{
							"_nr": "3",
							"__text": "Cunoaste-ti variabilele: primitive si referinte"
						},
						{
							"_nr": "4",
							"__text": "Cum se comporta obiectele: starea obiectelor afecteaza comportamentul metodelor"
						},
						{
							"_nr": "5",
							"__text": "Metode superputernice: controlul fluxului, operatii si multe altele"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea bibliotecii Java : nu trebuie sa scrii tu chiar tot"
						},
						{
							"_nr": "7",
							"__text": "O viata mai buna in lumea obiectelor: planuri pentru viitor"
						},
						{
							"_nr": "8",
							"__text": "Poliformismul luat in serios : exploatarea interfetelor si claselor abstracte"
						},
						{
							"_nr": "9",
							"__text": "Viata si moartea unui obiect : contructori si gestionarea memoriei"
						},
						{
							"_nr": "10",
							"__text": "Numerele conteaza: matematica, formatare, impachetare si variabile statice"
						},
						{
							"_nr": "11",
							"__text": "Un comportament riscant: tratarea execeptiilor"
						},
						{
							"_nr": "12",
							"__text": "O poveste grafica : despre interfete grafice cu utilizatorul, tratarea evenimentelor si clase interne"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea bibliotecii Swing: adminstratori de machete si componente"
						},
						{
							"_nr": "14",
							"__text": "Salvarea obiectelor : serializarea si operatii de intrare/iesire"
						},
						{
							"_nr": "15",
							"__text": "Crearea unei conexiuni: socluri de retea si fire de executie multiple"
						},
						{
							"_nr": "16",
							"__text": "Structuri de date : colectii si tipuri generice"
						},
						{
							"_nr": "17",
							"__text": "Distribuirea codului: impachetare si distribuire"
						},
						{
							"_nr": "18",
							"__text": "Calcul distribuit: RMI, plus cateva cuvinte despre miniservere, EJB si Jini"
						},
						{
							"_nr": "Anexa A",
							"__text": "Bucataria de cod finala"
						},
						{
							"_nr": "Anexa B",
							"__text": "Primele zece lucruri care nu au incaput in cartea propriu-zisa"
						}
					]
				},
				"_id": "604",
				"_isbn": "978-1-59496-117-5"
			},
			{
				"titlu": "Hello, Basic. Aplicatii pe calcualtoare de tip SPECTRUM (editia a II-a)",
				"autori": {
					"autor": {
						"_nume": "Luminita State"
					}
				},
				"anAparitie": "1993",
				"editura": "Agni",
				"numarPagini": "192",
				"pretOriginal": "550",
				"coperta": {
					"imagine": {
						"_path": "Hello_Basic_State.jpg"
					},
					"__text": "Ioan Hutu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul BASIC pentru calculatoare compatibile Spectrum"
						},
						{
							"_nr": "1",
							"__text": "Despre limbajul BASIC"
						},
						{
							"_nr": "2",
							"__text": "Expresii BASIC"
						},
						{
							"_nr": "3",
							"__text": "Functii BASIC"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni si comenzi BASIC"
						},
						{
							"_nr": "5",
							"__text": "Subprograme BASIC"
						},
						{
							"_nr": "6",
							"__text": "Grafica"
						},
						{
							"_nr": "7",
							"__text": "Cromatica"
						},
						{
							"_nr": "8",
							"__text": "Efecte speciale"
						},
						{
							"_nr": "II",
							"__text": "Aplicatii in BASIC"
						},
						{
							"_nr": "9",
							"__text": "Elemente de calcul matricial"
						},
						{
							"_nr": "10",
							"__text": "Rezolvarea sistemelor liniare"
						},
						{
							"_nr": "11",
							"__text": "Rezlvarea ecuatiilor neliniare"
						},
						{
							"_nr": "12",
							"__text": "Integrarea numerica"
						},
						{
							"_nr": "13",
							"__text": "Programare liniara"
						},
						{
							"_nr": "14",
							"__text": "Metode de sortare"
						},
						{
							"_nr": "15",
							"__text": "Jocuri"
						},
						{
							"_nr": "16",
							"__text": "Diverse"
						}
					]
				},
				"_id": "605",
				"_isbn": "973-95626-6-3"
			},
			{
				"titlu": "Programarea orientata pe obiecte in limbajul C++",
				"autori": {
					"autor": {
						"_nume": "Ioan Jurca"
					}
				},
				"anAparitie": "1992",
				"editura": "Eurobit",
				"numarPagini": "134",
				"pretOriginal": "225",
				"coperta": {
					"imagine": {
						"_path": "Programarea_orientata_pe_obiecte_in_limbajul_Cpp_Jurca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente moderne ale inginerii software"
						},
						{
							"_nr": "2",
							"__text": "Paradigme ale programarii si suportul lor in limbajele de programare"
						},
						{
							"_nr": "3",
							"__text": "Concepte de baza pentru programarea orientata pe obiecte in C++"
						},
						{
							"_nr": "4",
							"__text": "Proprietati ale claselor : mostenirea si controlul accesului"
						},
						{
							"_nr": "5",
							"__text": "Polimorfism, functii virtuale si supraincarcare"
						}
					]
				},
				"_id": "606",
				"_isbn": ""
			},
			{
				"titlu": "Cand calculatoarele gresesc... Fiabilitatea sistemelor de programe (software)",
				"autori": {
					"autor": {
						"_nume": "Adrian Mihalache"
					}
				},
				"anAparitie": "1995",
				"editura": "Didactica si pedagogica",
				"numarPagini": "188",
				"pretOriginal": "5400",
				"redactori": {
					"redactor": "Rodica Mihalache"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Stan"
				},
				"coperta": {
					"imagine": {
						"_path": "Cand_calculatoarele_gresesc_Fiabilitatea_sistemelor_de_programe_software_Mihalache.jpg"
					},
					"__text": "Doina Baranovsky"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi si programe"
						},
						{
							"_nr": "2",
							"__text": "Conceptul de fiabilitate in domeniul software"
						},
						{
							"_nr": "3",
							"__text": "Modeul fundamental al fiabilitatii programelor"
						},
						{
							"_nr": "4",
							"__text": "Depanarea imperfecta a programelor. Modelele Goel-Okumoto II si Musa"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea informatiei de natura neexperimetala. Modelele Littlewood si Verrall"
						},
						{
							"_nr": "6",
							"__text": "Modele cu rata de defectare variabila intre defectari"
						},
						{
							"_nr": "7",
							"__text": "Validarea experimentala a modelelor fiabilitatii software"
						},
						{
							"_nr": "8",
							"__text": "Modele structurale. Sisteme de programe tolerante la defectari"
						},
						{
							"_nr": "9",
							"__text": "Asigurarea fiabilitatii sistemelor de programe"
						}
					]
				},
				"_id": "607",
				"_isbn": "973-30-4267-6"
			},
			{
				"titlu": "Limbaj Cobol. Exercitii si programe (Vol I)",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Mandruleanu"
						},
						{
							"_nume": "Gheorghe Schneider"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Scrisul Romanesc",
				"numarPagini": "332",
				"pretOriginal": "31",
				"coperta": {
					"imagine": {
						"_path": "Limbajul_COBOL_Exercitii_si_probleme_Mandruleanu.jpg"
					},
					"__text": "Victor Pirlac"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi si scheme logice"
						},
						{
							"_nr": "2",
							"__text": "Exercitii si probleme necesare insusirii limbajului COBOL"
						},
						{
							"_nr": "3",
							"__text": "Indicatii privind aplicarea proiectarii modular-structurate in limbajul COBOL"
						},
						{
							"_nr": "4",
							"__text": "Principiile elemente specifice limbajului COBOL pentru minicalculatoare si microcalculatoare electronice"
						},
						{
							"_nr": "5",
							"__text": "Probleme tratate modular-structurat"
						},
						{
							"_nr": "6",
							"__text": "Probleme date la exemenele de admitere la facultati cu profil economic"
						},
						{
							"_nr": "7",
							"__text": "Raspunsuri la exercitii si probleme"
						}
					]
				},
				"_id": "608",
				"_isbn": ""
			},
			{
				"titlu": "Grafele si aplicatiile lor",
				"autori": {
					"autor": {
						"_nume": "Oystein Ore"
					}
				},
				"anAparitie": "1968",
				"editura": "Stiintifica",
				"tiraj": "4000+140 ex",
				"numarPagini": "176",
				"pretOriginal": "4.50",
				"traducere": {
					"titluOriginal": "Graphs an their uses",
					"editura": "Yale University",
					"traducatori": {
						"traducator": "?"
					},
					"_an": "1966",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Stanculescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafele_si_aplicatiile_lor_Ore.jpg"
					},
					"__text": "T. Mironescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ce este un graf?"
						},
						{
							"_nr": "II",
							"__text": "Grafe conexe"
						},
						{
							"_nr": "III",
							"__text": "Arbori"
						},
						{
							"_nr": "IV",
							"__text": "Corespondente"
						},
						{
							"_nr": "V",
							"__text": "Grafe orientate"
						},
						{
							"_nr": "VI",
							"__text": "Probleme referitoare la jocuri si enigme"
						},
						{
							"_nr": "VII",
							"__text": "Relatii"
						},
						{
							"_nr": "VIII",
							"__text": "Grafuri planare"
						},
						{
							"_nr": "IX",
							"__text": "Colorarea hartilor"
						}
					]
				},
				"_id": "609",
				"_isbn": ""
			},
			{
				"titlu": "Programe Fortran comentate, in automatica",
				"autori": {
					"autor": [
						{
							"_nume": "Marius Hanganut"
						},
						{
							"_nume": "Ioan Dancea"
						},
						{
							"_nume": "Olimpiu Negru"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"tiraj": "8600 + 140 + 40 ex. brosate",
				"numarPagini": "312",
				"pretOriginal": "10.50",
				"colectia": {
					"_numarul": "35",
					"_nume": "Automatica. Informatica"
				},
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programe_Fortan_comentate_in_automatica_Dancea.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Trasarea si interpretarea diagramelor logaritmice"
						},
						{
							"_nr": "2",
							"__text": "Trasarea si interpretarea locului radacinilor"
						},
						{
							"_nr": "3",
							"__text": "Trasarea si interpretarea  traiectoriilor din planul fazelor"
						},
						{
							"_nr": "4",
							"__text": "Stabilitatea sistemelor neliniiare. Metode diagramelor logaritmice"
						},
						{
							"_nr": "5",
							"__text": "Stabilitatea sistemelor liniare. Criteriile Rounth si Hurwitz"
						},
						{
							"_nr": "6",
							"__text": "Stabilitatea sistemelor liniare. Metoda a doua a lui Liapunov"
						},
						{
							"_nr": "7",
							"__text": "Raspunsul sistemelor liniare la semnale stochastice"
						},
						{
							"_nr": "8",
							"__text": "Raspunsul liber al sistemelor liniare exprimate prin variabile de stare"
						},
						{
							"_nr": "9",
							"__text": "Raspunsul fortat al sistemelor liniarea exprimate prin variabila de stare"
						},
						{
							"_nr": "10",
							"__text": "Optimizarea parametrica a sisemelor"
						}
					]
				},
				"_id": "610",
				"_isbn": ""
			},
			{
				"titlu": "Informatica mirabilis. Arta si literatura de calculator",
				"autori": {
					"autor": {
						"_nume": "Radu Bagdasar"
					}
				},
				"anAparitie": "1982",
				"editura": "Dacia",
				"numarPagini": "228",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"redactori": {
					"redactor": "Felicia Teodor"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Infomatica_Mirabilis_Bagdasar.jpg"
					},
					"__text": "Florin Maxa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Spre o civilizatie cibernetica"
						},
						{
							"_nr": "2",
							"__text": "Artelele de calculator"
						},
						{
							"_nr": "3",
							"__text": "Exploarari viitoare"
						},
						{
							"_nr": "4",
							"__text": "Anexa. Notiuni generale despre calculatoare. Analiza si programare"
						}
					]
				},
				"_id": "611",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in analiza combinatorie",
				"autori": {
					"autor": {
						"_nume": "Constantin Smadici"
					}
				},
				"anAparitie": "2007",
				"editura": "MatrixRom",
				"numarPagini": "302",
				"pretOriginal": "409900",
				"pretCumparare": "409900",
				"coperta": {
					"imagine": {
						"_path": "Introducere_ina_analiza_combinatorie_Smadici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Multimi, relatii si functii"
						},
						{
							"_nr": "2",
							"__text": "Configuratii combinatorice"
						},
						{
							"_nr": "3",
							"__text": "Configutatii combinatorice elementare"
						},
						{
							"_nr": "4",
							"__text": "Functii generatoare"
						},
						{
							"_nr": "5",
							"__text": "Partitii de numere naturale"
						},
						{
							"_nr": "6",
							"__text": "Permutarii"
						},
						{
							"_nr": "7",
							"__text": "Grafuri"
						},
						{
							"_nr": "8",
							"__text": "Sisteme de reprezentanti distincti si patrate latine"
						},
						{
							"_nr": "9",
							"__text": "Configuratii de blocuri echilibrate"
						},
						{
							"_nr": "10",
							"__text": "Matroizi"
						}
					]
				},
				"_id": "612",
				"_isbn": "978-973-755-254-9"
			},
			{
				"titlu": "Teoria codurilor",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Creanga"
						},
						{
							"_nume": "Dan Simovici"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Didactica si pedagogica",
				"tiraj": "2950+80 legate",
				"numarPagini": "248",
				"pretOriginal": "10.30",
				"redactori": {
					"redactor": "Iliescu Gabriela"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mirea I"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria codurilor_Creanga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 0",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "Partea 1",
							"__text": "Elemente de teoria generala a codurilor"
						},
						{
							"_nr": "Partea 2",
							"__text": "Coduri cu lungime variabila pentru canale fara zgomot"
						},
						{
							"_nr": "Partea 3",
							"__text": "Coduri cu lungime variabila pentru canale cu zgomot"
						},
						{
							"_nr": "Partea 4",
							"__text": "Coduri liniare si coduri ciclice"
						}
					]
				},
				"_id": "613",
				"_isbn": ""
			},
			{
				"titlu": "Complexitatea calculului. Aspecte calitative",
				"autori": {
					"autor": {
						"_nume": "Cristian Calude"
					}
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "304",
				"pretOriginal": "21.50",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Complexitatea_calculului_Aspecte_calitative_Calude.jpg"
					},
					"__text": "Gheorghe Motora"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculabilitate intruitiva"
						},
						{
							"_nr": "2",
							"__text": "Recursivitate primitiva"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate generala"
						},
						{
							"_nr": "4",
							"__text": "Complitatea algoritmilor"
						},
						{
							"_nr": "5",
							"__text": "Masuri abstracte ale complexitatii calculului"
						},
						{
							"_nr": "6",
							"__text": "Eficienta programelor si limbajelor"
						}
					]
				},
				"_id": "614",
				"_isbn": ""
			},
			{
				"titlu": "Lanturi Markov finite si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Marius Iosifescu"
					}
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"tiraj": "3650 + 75 exemplare legate",
				"numarPagini": "272",
				"pretOriginal": "18",
				"redactori": {
					"redactor": "Valentin Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Lanturi_Markov_finite_si_aplicatii_Iosifescu.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de teoria probabilitatilor si de algebra liniara"
						},
						{
							"_nr": "2",
							"__text": "Concepte fundamentale in teoria lanturilor Markov omogene"
						},
						{
							"_nr": "3",
							"__text": "Lanturi Markov absorbante"
						},
						{
							"_nr": "4",
							"__text": "Lanturi Markov ergodice"
						},
						{
							"_nr": "5",
							"__text": "Proprietari generale ale lanturilor Markov"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii ale lanturilor Markov in psihologie si genetica"
						},
						{
							"_nr": "7",
							"__text": "Lanturi Markov neomogene"
						}
					]
				},
				"_id": "615",
				"_isbn": ""
			},
			{
				"titlu": "Inteligenta artificiala. Teorie si aplicatii in economie",
				"autori": {
					"autor": [
						{
							"_nume": "Alexsandru P. Tacu"
						},
						{
							"_nume": "Romul Vancea"
						},
						{
							"_nume": "Stefan Holban"
						},
						{
							"_nume": "Aurel Burciu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Economica",
				"numarPagini": "520",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ioan Erhan"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioana Manoliu"
				},
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_Teorie_si_aplicatii_economice_Tacu.jpg"
					},
					"__text": "Adriana Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Managementul - factor activ al progresului"
						},
						{
							"_nr": "II",
							"__text": "Elemente de baza ale limbajului Prolog"
						},
						{
							"_nr": "III",
							"__text": "Reprezentarea cunostintelor"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme expert in alegerea celor mai bune decizii"
						},
						{
							"_nr": "V",
							"__text": "Optimizarea activitatii de cercetate prin reprezentarea formelor si prin sisteme expert"
						},
						{
							"_nr": "VI",
							"__text": "Analiza ciclicitatii in afaceri si a riscului de faliment pe baza retelelor neuronale"
						},
						{
							"_nr": "VII",
							"__text": "Deciziile si irationalitatea : recurgerea la tehnici de recunostere a formelor"
						}
					]
				},
				"_id": "616",
				"_isbn": "973-590-083-1"
			},
			{
				"titlu": "Cum se scrie un algoritm? Simplu",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "1993",
				"editura": "Agni",
				"numarPagini": "192",
				"pretOriginal": "950",
				"colectia": {
					"_numarul": "?",
					"_nume": "Biblioteca de informatica"
				},
				"redactori": {
					"redactor": "Victor Mitrana"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Vlad Atanasiu",
						"Aurelian Lavric"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Cum se scrie un algoritm_Simplu_Atanasiu.jpg"
					},
					"__text": "Adina Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Algoritmi - prezentare"
						},
						{
							"_nr": "II",
							"__text": "Limbajul algoritmic pseudocod"
						},
						{
							"_nr": "III",
							"__text": "Clasificarea algoritmilor"
						}
					]
				},
				"_id": "617",
				"_isbn": "973-95626-6-3"
			},
			{
				"titlu": "Cine esti tu BASIC?",
				"autori": {
					"autor": {
						"_nume": "Marian Gheorghe"
					}
				},
				"anAparitie": "1994",
				"editura": "Agni",
				"numarPagini": "192",
				"pretOriginal": "1250",
				"colectia": {
					"_numarul": "?",
					"_nume": "Biblioteca de informatica"
				},
				"redactori": {
					"redactor": "Rodica Ceterchi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Aurelian Lavric"
				},
				"coperta": {
					"imagine": {
						"_path": "Cine_esti_tu_BASIC_Gheorghe.jpg"
					},
					"__text": "Adina Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programe simple in limbajul BASIC"
						},
						{
							"_nr": "2",
							"__text": "Comenzi de baza. Ecranul de afisare"
						},
						{
							"_nr": "3",
							"__text": "Intructiunile de citire si scriere. Instructiuni STOP"
						},
						{
							"_nr": "4",
							"__text": "Constante, variabile numerice, expresii aritmetice. Instructiunea de atribuire. Comentariul"
						},
						{
							"_nr": "5",
							"__text": "Expresii logice. Instructiunile IF si GO TO"
						},
						{
							"_nr": "6",
							"__text": "Scheme logice"
						},
						{
							"_nr": "7",
							"__text": "Instructiunea FOR...NEXT. Valoarea absoluta, partea intreaga, radicalul"
						},
						{
							"_nr": "8",
							"__text": "Instructiunile READ, DATA, RESTORE"
						},
						{
							"_nr": "9",
							"__text": "Siruri de caractere. Variabile si expresii de tip sir de caractere"
						},
						{
							"_nr": "10",
							"__text": "Probleme rezolvate in limbajul BASIC"
						},
						{
							"_nr": "11",
							"__text": "Variabile structurate"
						},
						{
							"_nr": "12",
							"__text": "Subrutine"
						},
						{
							"_nr": "13",
							"__text": "Instructiunea BEEP"
						},
						{
							"_nr": "14",
							"__text": "Elemente de grafica in limbajul BASIC"
						},
						{
							"_nr": "15",
							"__text": "Variabile structurate de tip tablou. Variabile structurate siruri de caractere"
						},
						{
							"_nr": "16",
							"__text": "Probleme rezolvate si propuse"
						},
						{
							"_nr": "17",
							"__text": "Limbajul GW-BASIC. Diferente fata de limbajul prezentat"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Lista instructiunilor"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Lista comenzilor"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Lista operatiilor"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Lista exemplelor de programe"
						}
					]
				},
				"_id": "618",
				"_isbn": "973-95626-1-2"
			},
			{
				"titlu": "Securitatea informatiei (Volumnul 2 : Protocale de securitate)",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "2009",
				"editura": "InfoData",
				"numarPagini": "266",
				"pretOriginal": "250000",
				"pretCumparare": "250000",
				"coperta": {
					"imagine": {
						"_path": "Securitatea_informatiei_Volumul_2_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Functii de dispersie"
						},
						{
							"_nr": "2",
							"__text": "Protocoale de autentificare"
						},
						{
							"_nr": "3",
							"__text": "Semnaturi electronice"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de partajare a secretelor"
						},
						{
							"_nr": "5",
							"__text": "Protocoale de gestiune a cheilor"
						},
						{
							"_nr": "6",
							"__text": "IBE (Identitt - Besed Encryption)"
						},
						{
							"_nr": "7",
							"__text": "Sisteme electronice de plata"
						},
						{
							"_nr": "8",
							"__text": "Protocoale de vot electonic"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Elemente de algebra curbelor elipice"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Problema Diffie-Hellman"
						}
					]
				},
				"_id": "619",
				"_isbn": "978-973-1803-29-6"
			},
			{
				"titlu": "Tehnici de programare si structuri dinamice de date",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "2008",
				"editura": "InfoData",
				"numarPagini": "210",
				"pretOriginal": "300000",
				"pretCumparare": "300000",
				"redactori": {
					"redactor": "Tiberiu Socaciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_si_structuri_dinamice_de_date_Patrut.jpg"
					},
					"__text": "Bogdan Patrut"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Recapitularea cunostiintelor din clasa a IX-a"
						},
						{
							"_nr": "2",
							"__text": "Metoda Back-Tracking"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Complexitatea algoritmilor"
						},
						{
							"_nr": "5",
							"__text": "Metoda Greedy"
						},
						{
							"_nr": "6",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "7",
							"__text": "Probleme recapitulative"
						}
					]
				},
				"_id": "620",
				"_isbn": "978-973-1803-42-5"
			},
			{
				"titlu": "Ghid de utilizare dBase III Plus",
				"autori": {
					"autor": {
						"_nume": "Marius Chiorean"
					}
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"numarPagini": "160",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "11",
					"_nume": "Biblioteca PC"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_utilizare_dBase_III_Plus_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "3",
							"__text": "Crearea bazelor de date"
						},
						{
							"_nr": "4",
							"__text": "Gestiunea ecranului"
						},
						{
							"_nr": "5",
							"__text": "Operatii efectualte asupra articolelor din baza de date"
						},
						{
							"_nr": "6",
							"__text": "Programarea in dBase III Plus"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea impprimantei"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni de calcul. Macrosubstitutia"
						},
						{
							"_nr": "9",
							"__text": "Functii dBase III Plus"
						},
						{
							"_nr": "10",
							"__text": "Testarea programelor dBase III Plus"
						},
						{
							"_nr": "11",
							"__text": "Programe comentate"
						},
						{
							"_nr": "Anexa",
							"__text": "Instructiuni dBase III Plus"
						}
					]
				},
				"_id": "621",
				"_isbn": ""
			},
			{
				"titlu": "WordStar - Ghid de utilizare (Editia III)",
				"autori": {
					"autor": {
						"_nume": "Lia Chiorean"
					}
				},
				"anAparitie": "1993",
				"editura": "MicroInformatica",
				"numarPagini": "144",
				"pretOriginal": "500",
				"colectia": {
					"_numarul": "7",
					"_nume": "Biblioteca PC"
				},
				"coperta": {
					"imagine": {
						"_path": "WordStar_Ghid_de_utilizare_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea editorului de texte WordStar"
						},
						{
							"_nr": "2",
							"__text": "OPENING MENU (meniul principal)"
						},
						{
							"_nr": "3",
							"__text": "Comenzi cu punct"
						},
						{
							"_nr": "4",
							"__text": "Instalarea si configurarea editorului de texte"
						}
					]
				},
				"_id": "622",
				"_isbn": "973-95718-6-7"
			},
			{
				"titlu": "dBase II, III, III+, IV in 5 conversatii si 4 sinteze",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Dumitrascu"
						},
						{
							"_nume": "Traian Sperlea"
						},
						{
							"_nume": "Cristian Marinoiu"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Tehnica",
				"numarPagini": "428",
				"pretOriginal": "215",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Mihaela Popa",
						"Bogdan Apetrei"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "dBASE_II_III_III_PLUS_IV_5_conversatii_si_4_sinteze_Dumitrascu.jpg"
					},
					"__text": "Adrian Avram"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Conversatia 1",
							"__text": "Crearea si utilizarea unui fisiere de date (.DBF). \n          Comenzile CREATE, USE. Operarea in mod ecran.\n          Listarea integrala/selectiva a continutului unei baze de date.\n          Comenzile LIST/DISPLAY, GO (GOTO), SKIP.\n          TESTE si APLICATII."
						},
						{
							"_nr": "Conversatia 2",
							"__text": "Actualizarea unei baze de date (adaugarea/stergerea, modificarea structurii bazei de date).\n          Comenzile DELETE, PACK, RECALL, APPEND, INSERT, EDIT, BROWSE, REPLACE, COPY, MODIFY, STRCTURE, RENAME.\n          Sortarea si indexarea unui fisier de date.\n          Comenzile SORT si INDEX.\n          TESTE si APLICATII."
						},
						{
							"_nr": "Conversatia 3",
							"__text": "Fisiere de comenzi (.CMD) si de tip MEM (.MEM).\n          Utilizarea structurilor fundamentale de program dBASE.\n          Program complex dBASE ghidat de un meniu.\n          Comenzile SUM. STORE, SAVE TO, RESTORE FROM, RELEASE, MODIFY COMMAND, SETTALK, OFF/ON\n          DO WHILE...ENDDO, IF...ELSE...ENDIF, DO CASE....ENDCASE, ERASE, ACCEPT, INPUT, CLEAR, WAIT, T, CANCEL, QUIT.\n          TESTE si APLICATII"
						},
						{
							"_nr": "Conversatia 4",
							"__text": "Solutia TEMEI 3 (a,b,c).\n          Analiza structurata a problemei.\n          Comenzile SELECT, FIND, LOCATE.\n          Macrosubstituia & si functia STR.\n          Program dBASE III PLUS pentru jocul Master Mind.\n          Sistem de programe dBASE III PLUS pentru gestiune stocuri.\n          TEST"
						},
						{
							"_nr": "Conversatia 5",
							"__text": "Proiectarea si realizarea unui (micro) baze de date pentru documentatia armaturilor energetice - studiu de caz.\n          Fisierele FORMAT (.FMT).\n          Comenzile SET CONSOLE, TEXT, END TEXT.\n          Indexare fisiere.\n          Memorarea unor comenzi intr-o variabila.\n          Utilizarea comenzii @ pentru afisarea la imprimanta.\n          Operatori logici.\n          Operatii cu siruri de caractere.\n          Functiile LEN, VAL, INT si extragere subsir.\n          dCONVERT."
						},
						{
							"_nr": "Sintexa 6",
							"__text": "Interfata dBASE II,III,III PLUS cu COBOL, GWBASIC, PASCAL.\n          Tehnici, aplicatii"
						},
						{
							"_nr": "Sintexa 7",
							"__text": "FoxBASE PLUS. Caracteristici, extensii.\n          Sistem de programe FoxBASE PLUS pentru caclulul automat al preturilor"
						},
						{
							"_nr": "Sintexa 8",
							"__text": "dBASE IV : dimenziuni, performate, facilitati.\n          Ce sunt SQL, QBE si CONTROL CENTER ? \n          Aplicatie dBASE IV"
						},
						{
							"_nr": "Sintexa 9",
							"__text": "dBASE dintr- privire. Vocabular comparat dBASE II, III PLUS, FoxBASE+.\n          Sinopticul si repertoriul tuturor comenzilor dBASE II, III, III+ IV si FoxBASE+"
						}
					]
				},
				"_id": "623",
				"_isbn": "973-31-0340-3"
			},
			{
				"titlu": "Algoritmi si programe sub Windows",
				"autori": {
					"autor": {
						"_nume": "Theodor Balan"
					}
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "304",
				"pretOriginal": "0",
				"pretCumparare": "92600",
				"redactori": {
					"redactor": "Denisa Popa"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Staicu"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_si_programe_sub_Windows_Balan.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Algoritmi"
						},
						{
							"_nr": "Partea II",
							"__text": "Mediul WINDOWS"
						},
						{
							"_nr": "Partea III",
							"__text": "Programe"
						}
					]
				},
				"_id": "624",
				"_isbn": "973-31-1430-8"
			},
			{
				"titlu": "Interogarea bazelor de date. Aplicatii in Oracle si SQL Server",
				"autori": {
					"autor": [
						{
							"_nume": "Victor Felea"
						},
						{
							"_nume": "Codrut Matei"
						},
						{
							"_nume": "Marian Balta"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "MatrixRom",
				"numarPagini": "160",
				"pretOriginal": "0",
				"pretCumparare": "230000",
				"coperta": {
					"imagine": {
						"_path": "Interogarea_bazelor_de_date_Aplicatii_in_Oracle_si_SQL_Server_Felea.jpg"
					},
					"__text": "Corneliu Sabin Buraga"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Capitolul I",
							"__text": "Interogari de tip SQL"
						},
						{
							"_nr": "Capitolul II",
							"__text": "Limbaje generale de interogare a bazelor de date"
						},
						{
							"_nr": "Capitolul III",
							"__text": "Interogari complexe"
						},
						{
							"_nr": "Capitolul IV",
							"__text": "Optimizarea interogarolor - MS SQL Server 2000"
						},
						{
							"_nr": "Capitolul V",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "625",
				"_isbn": "973-685-938-X"
			},
			{
				"titlu": "Bazele programarii orientate pe obiecte: aplicatii in limbajul Smalltalk",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Bizoi"
						},
						{
							"_nume": "Ana-Maria Suduc"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Bibliotheca",
				"numarPagini": "156",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Ioan Alexandru Muscalu"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_programarii_orientate_pe_obiecte_Aplicatii_in_limbajul_Smalltalk.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in programarea orientata pe obiecte"
						},
						{
							"_nr": "2",
							"__text": "Programarea in Smalltalk"
						},
						{
							"_nr": "3",
							"__text": "Blocuri si structuri de control"
						},
						{
							"_nr": "4",
							"__text": "Biblioteca de clase"
						},
						{
							"_nr": "5",
							"__text": "Realizarea aplicatiilor orientate pe obiecte"
						},
						{
							"_nr": "6",
							"__text": "Crearea interfetelor"
						},
						{
							"_nr": "7",
							"__text": "Distribuirea aplicatiilor in Smalltalk"
						},
						{
							"_nr": "8",
							"__text": "Teste grila rezolvate"
						}
					]
				},
				"_id": "626",
				"_isbn": "978-973-712-406-7"
			},
			{
				"titlu": "C++ : manualul incepatorului",
				"autori": {
					"autor": {
						"_nume": "Kris Jamsa"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "368",
				"pretOriginal": "99000",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "300",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Rescued by C++, Thrid Edition",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Lucian Limona"
					},
					"_an": "1997",
					"_editia": "III",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_Manualul_incepatorului_Jamsa.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Elemente de baza"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Crearea programelor cu ajutorul functiilor"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Pastrarea informatiilor cu ajutorul vectorilor si al structurilor"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Utilizarea claselor in C++"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Desprea mostenire si sabloane"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Elemente avansate de C++"
						}
					]
				},
				"_id": "627",
				"_isbn": "973-20-0139-9"
			},
			{
				"titlu": "Limbaje formale si translatoare:aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Ecaterina Vladu"
						},
						{
							"_nume": "Elisa-Valentina Onet"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Universitatii din Oradea",
				"numarPagini": "188",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_translatoare_Aplicatii_Vladu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "3",
							"__text": "Limbaje formale"
						},
						{
							"_nr": "4",
							"__text": "Gramatici"
						},
						{
							"_nr": "5",
							"__text": "Analiza sintactica"
						},
						{
							"_nr": "6",
							"__text": "Generatoare de analizatoare sintactice"
						}
					]
				},
				"_id": "628",
				"_isbn": "978-973-759-865-3"
			},
			{
				"titlu": "Analiza datelor",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Mihai Pomohaci"
						},
						{
							"_nume": "Daniela Parlea"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "180",
				"pretOriginal": "130000",
				"pretCumparare": "130000",
				"coperta": {
					"imagine": {
						"_path": "Analiza_datelor_Parlea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni preliminare"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea datelor ca mod de examinare preliminara a fenomenului studiat"
						},
						{
							"_nr": "3",
							"__text": "Statistica in cercetarea sociala"
						},
						{
							"_nr": "4",
							"__text": "Notiuni introductive. Despre grafuri si baze de date"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea EXCEL si SPSS in statistica"
						}
					]
				},
				"_id": "629",
				"_isbn": "978-973-163-162-2"
			},
			{
				"titlu": "Dictionar de tehnica de calcul ROMAN-ENGLEZ",
				"autori": {
					"autor": {
						"_nume": "Jodal Endre"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "362",
				"pretOriginal": "10400",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "5",
					"_nume": "Seria enciclopedica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_de_tehnica_de_calcul_roman_englez_Jodal.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": {
						"_nr": ""
					}
				},
				"_id": "630",
				"_isbn": "973-9215-15-7"
			},
			{
				"titlu": "Algoritmi: Introducere in algoritmica grafurilor",
				"autori": {
					"autor": {
						"_nume": "Eleonor Ciurea"
					}
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "200",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_Introducere_in_algoritmica_grafurilor_Ciurea.jpg"
					},
					"__text": "Andreea Staiuc"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Parcurgeri de grafuri"
						},
						{
							"_nr": "3",
							"__text": "Arbori si arborescente"
						},
						{
							"_nr": "4",
							"__text": "Distante si drumuri minime"
						},
						{
							"_nr": "5",
							"__text": "Fluxuri maxime in retele"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi polinomiali pentru fluxul maxim"
						}
					]
				},
				"_id": "631",
				"_isbn": "973-31-593-2"
			},
			{
				"titlu": "Algoritmica grafurilor",
				"autori": {
					"autor": {
						"_nume": "Corneliu Bereanu"
					}
				},
				"anAparitie": "2009",
				"editura": "Sitech",
				"numarPagini": "248",
				"pretOriginal": "0",
				"pretCumparare": "218000",
				"coperta": {
					"imagine": {
						"_path": "Algoritmica_grafurilor_Bereanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi si complexitatea calculului. Complexitate asimptotica"
						},
						{
							"_nr": "2",
							"__text": "Grafuri neorientate. Notiuni de baza. Algoritmi de reprezentare"
						},
						{
							"_nr": "3",
							"__text": "Grafuri conexe. Algoritmi de parcurgere a grafurilor neorientate"
						},
						{
							"_nr": "4",
							"__text": "Cicluri hamiltoniene si euleriene intr-un graf neorientat"
						},
						{
							"_nr": "5",
							"__text": "Structuri arborescente. Arbori partiali de cost minim (MST)"
						},
						{
							"_nr": "6",
							"__text": "Arbori binari"
						},
						{
							"_nr": "7",
							"__text": "Grafuri orientate. Notiuni de baza. Algoritmi de reprezentare"
						},
						{
							"_nr": "8",
							"__text": "Parcurgerea grafurilor orientate. Algoritmi"
						},
						{
							"_nr": "9",
							"__text": "Grafuri tare conexe. Drumuri hamiltoniene"
						},
						{
							"_nr": "10",
							"__text": "Distante minime. Algoritmi matriciali"
						},
						{
							"_nr": "11",
							"__text": "Flux maxim/minim in retele de transport"
						},
						{
							"_nr": "12",
							"__text": "Cuplaje si suporturi in grafuri bipartite"
						}
					]
				},
				"_id": "632",
				"_isbn": "978-606-049-1"
			},
			{
				"titlu": "Structuri numerice discrete. Arbori. Jocuri. Stocuri",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Lupsoiu"
						},
						{
							"_nume": "Ioan Tutunea"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Sitech",
				"numarPagini": "372",
				"pretOriginal": "0",
				"pretCumparare": "250000",
				"coperta": {
					"imagine": {
						"_path": "Structuri_numerice_discrete_Arbori_Jocuri_Stocuri_Tutunea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arbori binari"
						},
						{
							"_nr": "2",
							"__text": "Arbori"
						},
						{
							"_nr": "3",
							"__text": "Teoria jocurilor"
						},
						{
							"_nr": "4",
							"__text": "Jocuri cu N persoane"
						},
						{
							"_nr": "5",
							"__text": "Elemente de teoria stocurilor"
						},
						{
							"_nr": "6",
							"__text": "Modele deterministe"
						},
						{
							"_nr": "7",
							"__text": "Modele aleatoare"
						}
					]
				},
				"_id": "633",
				"_isbn": "978-606-530-524-3"
			},
			{
				"titlu": "Studii de Logica Matematica",
				"autori": {
					"autor": {
						"_nume": "Wang Hao"
					}
				},
				"anAparitie": "1972",
				"editura": "Stiintifica",
				"tiraj": "3100",
				"numarPagini": "624",
				"pretOriginal": "36",
				"pretCumparare": "190000",
				"traducere": {
					"titluOriginal": "A Survey of Mathematical Logic",
					"editura": "Science Press",
					"traducatori": {
						"traducator": [
							"S. Vieru",
							"U. Morgenstern"
						]
					},
					"_an": "1962",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "China"
				},
				"redactori": {
					"redactor": "Aurel Dicu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Studii_de_Logica_Matematica_Hao.jpg"
					},
					"__text": "Gheorghe Popovici"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Aspecte generale"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Masini de calcul"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Teoria formala a numerelor"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Toeria impredicativa a multimilor"
						},
						{
							"_nr": "Partea a cincea",
							"__text": "Teoria predicativa a multimilor"
						}
					]
				},
				"_id": "634",
				"_isbn": ""
			},
			{
				"titlu": "Gestionarea memoriei (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Jennifer Flynn"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "128",
				"pretOriginal": "3990",
				"colectia": {
					"_numarul": "50",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 minute guide to memory management",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Mihai Dan-Pavelescu"
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Gestionarea_memoriei_in_lectii_de_10_minute_Flynn.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Inceputul: Prezentarea memoriei"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Memoria RAM (Random Access Memory)"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Modelitati de depasire a barierei DOS"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Determinarea memoriei unui sistem"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Modalitati simple de verificare a memoriei"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Ce este si la ce foloseste CONFIG.SYS"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Ce este AUTOEXEC.BAT si cum afecteaza memoria"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Realizarea unei dischete de siguranta"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Cum se poate sistemul de operare DOS sa imbunatateasca memoria calculatorului"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Editarea fisierelor de configirare MS-DOS"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Gestiunea memoriei conventionale cu MS-DOS"
						},
						{
							"_nr": "Lectia 12",
							"__text": "Maximizarea memoriei conventionale cu ajutorul sistemului de operare DOS"
						},
						{
							"_nr": "Lectia 13",
							"__text": "Editarea fisierelor de configurare in sistemul de operare DR DOS"
						},
						{
							"_nr": "Lectia 14",
							"__text": "Gestionarea memoriei cu sistemul de operare DR DOS"
						},
						{
							"_nr": "Lectia 15",
							"__text": "Maximizarea memoriei conventinale cu sistemul de operare DR DOS"
						},
						{
							"_nr": "Lectia 16",
							"__text": "Strategii de economisire a memoriei de tip RAM"
						},
						{
							"_nr": "Lectia 17",
							"__text": "Gestionarea memoriei cu Windows"
						},
						{
							"_nr": "Lectia 18",
							"__text": "Memoria imediata"
						},
						{
							"_nr": "Lectia 19",
							"__text": "Accelerarea lucrului cu discul fix, folosind memoria RAM"
						},
						{
							"_nr": "Lectia 20",
							"__text": "Strategii fundamentale pentru calculator"
						},
						{
							"_nr": "Lectia 21",
							"__text": "Adaugarea de circuite intergrate de momorie"
						},
						{
							"_nr": "Lectia 22",
							"__text": "MemMaker si produse complementare"
						}
					]
				},
				"_id": "635",
				"_isbn": "973-601-227-1"
			},
			{
				"titlu": "Latex - Ghid de utilizare",
				"autori": {
					"autor": [
						{
							"_nume": "Artur Pusztai"
						},
						{
							"_nume": "Gheorghe Ardelean"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "162",
				"pretOriginal": "3800",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Latex_Ghid_de_utilizare_Pusztai.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Organizarea logica a documentului"
						},
						{
							"_nr": "4",
							"__text": "Cadruri"
						},
						{
							"_nr": "5",
							"__text": "Macrodefinitii"
						},
						{
							"_nr": "6",
							"__text": "Modul paragraf"
						},
						{
							"_nr": "7",
							"__text": "Modul matematic"
						},
						{
							"_nr": "8",
							"__text": "Modul LR"
						},
						{
							"_nr": "9",
							"__text": "Variabile interne LATEX"
						},
						{
							"_nr": "10",
							"__text": "Erori si avertismente"
						}
					]
				},
				"_id": "636",
				"_isbn": "973-31-0712-3"
			},
			{
				"titlu": "Teoria calculabilitatii efective",
				"autori": {
					"autor": {
						"_nume": "Constantin Cazacu"
					}
				},
				"anAparitie": "1996",
				"editura": "Universitatii \"Alexandru Ioan Cuza\"",
				"numarPagini": "212",
				"pretOriginal": "4900",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Mirela Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_calculabilitatii_efective_Cazacu.jpg"
					},
					"__text": "Al. Cvasnai"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi si functii calculabile"
						},
						{
							"_nr": "2",
							"__text": "R-algoritmi"
						},
						{
							"_nr": "3",
							"__text": "Functii universabile"
						},
						{
							"_nr": "4",
							"__text": "Obiecte recursiv enumerative"
						},
						{
							"_nr": "5",
							"__text": "Probleme algoritmic nedecidabile"
						},
						{
							"_nr": "6",
							"__text": "Complexitatea algoritmilor"
						}
					]
				},
				"_id": "637",
				"_isbn": "973-9149-61-8"
			},
			{
				"titlu": "Analiza numerica - Exercitii si teme de laborator",
				"autori": {
					"autor": [
						{
							"_nume": "Iuliana Paraschi-Munteanu"
						},
						{
							"_nume": "Daniel Stanica"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "206",
				"pretOriginal": "170000",
				"pretCumparare": "170000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Iuliana Paraschi-Munteanu",
						"Daniel Stanica"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_numerica_Exercitii_si_teme_de_laborator_Stanica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme liniare"
						},
						{
							"_nr": "2",
							"__text": "Ecuatii neliniare"
						},
						{
							"_nr": "3",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "4",
							"__text": "Interpolare"
						},
						{
							"_nr": "5",
							"__text": "Cuadraturi numerice"
						},
						{
							"_nr": "6",
							"__text": "Metode numerice pentru ecuatii diferentiale"
						}
					]
				},
				"_id": "638",
				"_isbn": "978-973-737-181-2"
			},
			{
				"titlu": "Bazele programarii orientate pe obiecte",
				"autori": {
					"autor": {
						"_nume": "Ioan Jurca"
					}
				},
				"anAparitie": "2006",
				"editura": "Editura de Vest",
				"tiraj": "400",
				"numarPagini": "312",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpia Marginean"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_programarii_orientate_pe_obiecte_Jurca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Clase si obiecte"
						},
						{
							"_nr": "3",
							"__text": "Mostenire si polimorfism"
						},
						{
							"_nr": "4",
							"__text": "Interfete"
						},
						{
							"_nr": "5",
							"__text": "Clase si interfete incuibate"
						},
						{
							"_nr": "6",
							"__text": "Exceptii si asertiuni"
						},
						{
							"_nr": "7",
							"__text": "Pachete"
						},
						{
							"_nr": "8",
							"__text": "Operatii de intrare/iesire"
						},
						{
							"_nr": "9",
							"__text": "Colectii"
						},
						{
							"_nr": "10",
							"__text": "Programare concurenta in Java"
						},
						{
							"_nr": "11",
							"__text": "Interfete grafice (GUI)"
						}
					]
				},
				"_id": "639",
				"_isbn": "973-36-0428-3"
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "Gheorghe Barbu"
						},
						{
							"_nume": "Mircea Bolosteanu"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "278",
				"pretOriginal": "17000",
				"pretCumparare": "70000",
				"redactori": {
					"redactor": "Vasile Buzatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Vaduva.jpg"
					},
					"__text": "Gheorghe Costache"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Structura, functionarea si utilizarea calculatoarelor compatibile IBM-PC"
						},
						{
							"_nr": "II",
							"__text": "Bazele aritmetice si logice ale sistemelor de calcul"
						},
						{
							"_nr": "III",
							"__text": "Structura software a calculatoarelor personale"
						},
						{
							"_nr": "IV",
							"__text": "Algoritmi. Metode de descriere a algoritmilor"
						},
						{
							"_nr": "V",
							"__text": "Structuri de date"
						},
						{
							"_nr": "VI",
							"__text": "Limbajul Turbo Pascal"
						},
						{
							"_nr": "VII",
							"__text": "Tehnici de programare"
						},
						{
							"_nr": "VIII",
							"__text": "Programarea oriectata spre obiecte"
						}
					]
				},
				"_id": "640",
				"_isbn": "973-31-1049-3"
			},
			{
				"titlu": "Culegere de probleme de programare. 375 problem, cu rezolvari",
				"autori": {
					"autor": [
						{
							"_nume": "A.M. Buhtiiarov"
						},
						{
							"_nume": "L.M. Zikevskaia"
						},
						{
							"_nume": "G.D. Frolov"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Tehnica",
				"tiraj": "8000+130 exemplare brosate",
				"numarPagini": "368",
				"pretOriginal": "12",
				"traducere": {
					"editura": "Nauka",
					"traducatori": {
						"traducator": "Rodica Boconcios"
					},
					"_an": "1970",
					"_editia": "II",
					"_limba": "rusa",
					"_tara": "URSS"
				},
				"redactori": {
					"redactor": [
						"Smaranda Dumitriu",
						"Paul Zamfirescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Culegere_de_probleme_de_programare_375_probleme_cu_rezolvari_Buhtiiarov.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Bazele aritimetice ale calculatoarelor electronice numerice (CEN)"
						},
						{
							"_nr": "II",
							"__text": "Elemente de programare"
						},
						{
							"_nr": "III",
							"__text": "Exemple de programare"
						},
						{
							"_nr": "IV",
							"__text": "Programarea in limbajul Algol-60"
						}
					]
				},
				"_id": "641",
				"_isbn": ""
			},
			{
				"titlu": "Grafuri si programare liniara. Introducere elementara",
				"autori": {
					"autor": {
						"_nume": "Ioan Tomescu"
					}
				},
				"anAparitie": "1975",
				"editura": "Didactica si pedagogica",
				"tiraj": "14180",
				"numarPagini": "132",
				"pretOriginal": "3.60",
				"redactori": {
					"redactor": "Eugenia Pantelimon"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ghimis Victoria"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafuri_si_programare_liniara_Introducere_liniara_Tomescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni de teoria grafurilor"
						},
						{
							"_nr": "II",
							"__text": "Notiuni de programare liniara"
						}
					]
				},
				"_id": "642",
				"_isbn": ""
			},
			{
				"titlu": "Ingineria programarii (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Vaduva"
						},
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "Vasile Florescu"
						},
						{
							"_nume": "Ion Floricica"
						},
						{
							"_nume": "Mihai Jitaru"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "316",
				"pretOriginal": "32",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Aurel Budnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Ingineria programarii_Vol_1_Vaduva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme generale ale alaborarii produselor-program"
						},
						{
							"_nr": "2",
							"__text": "Structuri de programe si programare structurata"
						},
						{
							"_nr": "3",
							"__text": "Proiectarea programelor"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi si structuri de date"
						},
						{
							"_nr": "5",
							"__text": "Codificarea programelor"
						},
						{
							"_nr": "6",
							"__text": "Codificarea programelor in limbajele COBOL si FORTRAN"
						}
					]
				},
				"_id": "643",
				"_isbn": ""
			},
			{
				"titlu": "Structuri arborescente cu aplicatiile lor",
				"autori": {
					"autor": [
						{
							"_nume": "Ionescu Texe Clara"
						},
						{
							"_nume": "Zsako Ioan"
						}
					]
				},
				"anAparitie": "1990",
				"editura": "Tehnica",
				"numarPagini": "224",
				"pretOriginal": "43",
				"redactori": {
					"redactor": [
						"Carmen Hotaranu",
						"Marcelina Joavina"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_arborecente_cu_aplicatiile_lor_Clara.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Structuri de date"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea structurilor arborescente"
						},
						{
							"_nr": "3",
							"__text": "Operatii elementare pe arbori"
						},
						{
							"_nr": "4",
							"__text": "Structuri arborecente in procesele de compilare"
						},
						{
							"_nr": "5",
							"__text": "Generarea imaginilor cu ajutorul arborilor quadro"
						},
						{
							"_nr": "6",
							"__text": "Codificare eficienta cu arbori semi-splay"
						},
						{
							"_nr": "7",
							"__text": "Arbore partial de cost minim"
						},
						{
							"_nr": "8",
							"__text": "Arborele de intervale"
						}
					]
				},
				"_id": "644",
				"_isbn": "973-31-0215-6"
			},
			{
				"titlu": "Utilizarea calculatoarelor personale. Sistemul de operare MS-DOS 5.0",
				"autori": {
					"autor": [
						{
							"_nume": "Cornel Popa"
						},
						{
							"_nume": "Eugenia Oprea"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "ECCE",
				"numarPagini": "224",
				"pretOriginal": "300",
				"coperta": {
					"imagine": {
						"_path": "Utilizarea_calculatoarelor_personale_Sistemul_de_operare_MS_DOS_5_0_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Interfata grafica Shell"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea MS-DOS"
						},
						{
							"_nr": "4",
							"__text": "Comenzi MS-DOS"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea optima a sistemului de operare"
						},
						{
							"_nr": "Anexa A",
							"__text": "Drivere instalabile"
						},
						{
							"_nr": "Anexa B",
							"__text": "Instalarea sistemului de operare"
						}
					]
				},
				"_id": "645",
				"_isbn": "973-95605-0-4"
			},
			{
				"titlu": "Invatam COBOL...conversand cu calculatorul (Vol.1)",
				"autori": {
					"autor": {
						"_nume": "Liviu Dumitrascu"
					}
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "535",
				"pretOriginal": "58",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatam_COBOL_conversand_cu_calculatorul_Vol1_Dumitrascu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Conversatia 1",
							"__text": "Calculul consumului mediu anual al unui articol. Cel mai simplu program in COBOL. Metodica de analiza, proiectare si realizare a programelor"
						},
						{
							"_nr": "Conversatia 2",
							"__text": "Calculul consumului total al unui articol si al procentelor corespunzatoare consumurilor trimestriale. Scrierea unui program COBOL :IDENTIFICATION DIVISION, ENVIRONMENT DIVISION si DATA DIVISION"
						},
						{
							"_nr": "Conversatia 2(continuare)",
							"__text": "Scrierea unui program COBOL : PROCEDURE DIVISION. Instructiuni pentru comunicarea (interactiva) intre program si utilizator. Instructiuni de calcul. Erori comune ale programatorilor incepatori. Depanarea unui program. TESTE pentru cititor: notiuni teoretice, doua programe, depistarea unor erori"
						},
						{
							"_nr": "Conversatia 3",
							"__text": "Calculul consumului total al unui articol si tiparirea la imprimanta a unui text decodificat. Transferul de date. Decizii-selectii. Editarea prin inserare flotanta. Noi elemente COBOL. TEST pentru cititor: depistarea unor erori"
						},
						{
							"_nr": "Conversatia 4",
							"__text": "Calculul abaterii in procente a consumului realizat fata de cel normat pentru mai multe articole. Iteratii. Instructiunea PERFORM/UNTIL. TESTE pentru citiror : depistarea unor erori de sintaxa si de logica"
						},
						{
							"_nr": "Conversatia 5",
							"__text": "Calculul abaterii in procente a consumurilor trimestriale realizate fata de consumurile trimestriale normate, pentru mai multe articole. Tablouri, vectori. Instructiunea PERFORM/VARYING/AFTER. Depanarea a trei programe. TESTE pentru cititor: intelegerea unor programe simple, depistarea unor erori"
						},
						{
							"_nr": "Conversatia 6",
							"__text": "Calculul in procente al consumului valoric pentru mai multe articole. Instructiuni COBOL FELIX C pentru comunicarea interactiva intre program si utilizator. Depanarea unor programe. Instructiune pentru impartirea cu rest. TEST pentru cititor: simularea functionarii unui program"
						},
						{
							"_nr": "Conversatia 7",
							"__text": "Crearea unui fisier de tranzactii referitoare la consumurile unor articole pe banda magnetica. Clauze si instructiuni COBOL pentru prelucrarea fisierelor de organizare secventiala. Depanarea a doua programe. Erori comune ale programatorilor incepatori.Diferente COBOL (FELIX C, mini, micro)"
						},
						{
							"_nr": "Conversatia 8",
							"__text": "Crearea unui fisier de articole pe disc magnetic. Clauze si instructiuni COBOL pentru prelucrarea fisierelor de organizare secvential-indexata si directa. Diferente COBOL (FELIX C, mini, micro). TESTE pentru cititor: simularea si modificarea functionarii unui program"
						},
						{
							"_nr": "Conversatia 9",
							"__text": "Validarea tranzactiilor referitoare la consumurile unui articol si actualizarea cantitatii consumate din fisierul de articole. Testul de clasa. Combinarea instructiunilor IF. Clauze si instructiuni COBOL INDEPENDENT, CORAL pentru prelucrarea fisierelor de organizare relativa. Diferente COBOL (FELIX C, mini, micro) TESTE pentru cititor: modificarea unui program"
						},
						{
							"_nr": "Conversatia 10",
							"__text": "Proiectarea si scriererea unui program de listare a unui fisier de articole. Clauze si instructiuni COBOL pentru fisiere memorate pe hartie de imprimanta. Diferente COBOL (FELIX C, mini, micro). TESTE pentru cititor: interclasarea a doua fisiere, depistarea unei erori de intrare/iesire, resconstruirea unui program, proiectarea unei scheme de sistem pornind de la un raport"
						},
						{
							"_nr": "Conversatia 11",
							"__text": "Proiectarea si scrierea unui program COBOL de sortare a unui fisier de articole. Sortarea fisierelor in programele COBOL. Instructiunea SORT. Depanarea unui program. Diferente COBOL (FELIX C, mini, micro). TESTE pentru cititor"
						},
						{
							"_nr": "Conversatia 12",
							"__text": "Aplicarea metodelor si tehnicilor de programare structurata in proiectarea si realizarea unei aplicatii de personal. Utilizarea subprogramelor COBOL. Instructiunea CALL"
						}
					]
				},
				"_id": "646",
				"_isbn": ""
			},
			{
				"titlu": "Invatam COBOL...conversand cu calculatorul (Vol.2)",
				"autori": {
					"autor": {
						"_nume": "Liviu Dumitrascu"
					}
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "400",
				"pretOriginal": "58",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatam_COBOL_conversand_cu_calculatorul_Vol2_Dumitrascu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Sinteza 13",
							"__text": "Structuri fundamentale la programarea structurata"
						},
						{
							"_nr": "Sinteza 14",
							"__text": "Dezvoltarea programarii structurate"
						},
						{
							"_nr": "Sinteza 15",
							"__text": "Organizarea datelor. Fisiere. Baze de date"
						},
						{
							"_nr": "Sinteza 16",
							"__text": "Memento complex privind COBOL-ul pe maxi-mini-micro calculatoare. Formate generale ANSI '74  (cu extensii) pentru COBOL WANG VS, IBM 370. IBM PC., Felix PC. Prelucrarea fisierelor pe Felix C, INDEPENT-CORAL, Felix M 118"
						},
						{
							"_nr": "Sinteza 17",
							"__text": "Memento privind clauze si instructiui COBOL mai putin folosite. Biblioteci sursa COBOL"
						},
						{
							"_nr": "Sinteza 18",
							"__text": "Memento privind formatele generale ale limbajului COBOL. Diferente COBOL (FELIX C, mini, micro). Cuvinte rezervate COBOL"
						},
						{
							"_nr": "Sinteza 19",
							"__text": "Memento privind mesajele de erori in COBOL"
						},
						{
							"_nr": "Sinteza 20",
							"__text": "Programe si rezultatele prelucrarilor pentru exemplele si temele din conversatii"
						}
					]
				},
				"_id": "647",
				"_isbn": ""
			},
			{
				"titlu": "Conceperea si realizarea programelor COBOL",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Vaduva"
						},
						{
							"_nume": "Mihai Jitaru"
						},
						{
							"_nume": "Vasile Florescu"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "470",
				"pretOriginal": "17.50",
				"redactori": {
					"redactor": "Maria Blaga"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Conceperea_si_relizarea_programelor_COBOL_Vaduva.jpg"
					},
					"__text": "Constantin Gheorghiu-Enescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Notiuni elementare de limbaj COBOL"
						},
						{
							"_nr": "3",
							"__text": "Date numerice si instructiuni artimetice"
						},
						{
							"_nr": "4",
							"__text": "Conditii"
						},
						{
							"_nr": "5",
							"__text": "Descrierea structurilor de control fundamentale"
						},
						{
							"_nr": "6",
							"__text": "Reprezentarea si transferul datelor"
						},
						{
							"_nr": "7",
							"__text": "Prelucrarea datelor organizate sub forma de tablouri"
						},
						{
							"_nr": "8",
							"__text": "Organizarea datelor pe suporturi externe"
						},
						{
							"_nr": "9",
							"__text": "Programe de prelucrare a datelor organizate in fisiere"
						},
						{
							"_nr": "10",
							"__text": "Utilizarea editorului COBOL"
						},
						{
							"_nr": "11",
							"__text": "Proiectarea si realizarea programelor complexe"
						},
						{
							"_nr": "12",
							"__text": "Sistemul de operare si limbjul de comanda"
						},
						{
							"_nr": "13",
							"__text": "Verificarea corectitudinii si depanarea programelor"
						},
						{
							"_nr": "14",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "15",
							"__text": "Particularitatile programarii in conditiile folosirii sistemului de calcul I-100"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Lista cuvintelor COBOL rezervate"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Structura si continutul etichetelor"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Formate generale COBOL"
						}
					]
				},
				"_id": "648",
				"_isbn": ""
			},
			{
				"titlu": "COBOL complet pentru microcalculatoare profesionale",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Constantin Apostol"
						},
						{
							"_nume": "Bogdan Ghilic-Micu"
						},
						{
							"_nume": "Adriana Ionescu"
						},
						{
							"_nume": "Ileana Vrejba"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "All",
				"numarPagini": "344",
				"pretOriginal": "7900",
				"pretCumparare": "130000",
				"tehnoredactori": {
					"tehnoredactor": [
						"Mircea Moldoveanu",
						"Adriana Sima"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "COBOL_COMPLET_pentru_microcalculatoare_profesionale_Rosca.jpg"
					},
					"__text": "Carmen Fundi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului"
						},
						{
							"_nr": "2",
							"__text": "Descrierea datelor interne"
						},
						{
							"_nr": "3",
							"__text": "Operatii de baza in algoritmi"
						},
						{
							"_nr": "4",
							"__text": "Realizarea structurilor fundamentale"
						},
						{
							"_nr": "5",
							"__text": "Prelucrarea masivelor de date"
						},
						{
							"_nr": "6",
							"__text": "Prelucrarea sirurilor de caractere"
						},
						{
							"_nr": "7",
							"__text": "Formatarea ecrenelor"
						},
						{
							"_nr": "8",
							"__text": "Caracteristicile generale ale fisierelor de date"
						},
						{
							"_nr": "9",
							"__text": "Descrierea fisierelor in COBOL"
						},
						{
							"_nr": "10",
							"__text": "Realizarea operatiilor de acces la fisiere si articole"
						},
						{
							"_nr": "11",
							"__text": "Sortarea si interclasarea fisierelor"
						},
						{
							"_nr": "12",
							"__text": "Algoritmi de prelucrare a fisierelor"
						},
						{
							"_nr": "13",
							"__text": "Editarea de rapoarte"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea subprogramelor COBOL"
						},
						{
							"_nr": "15",
							"__text": "Segmentarea interna a programelor"
						},
						{
							"_nr": "16",
							"__text": "Subprograme de biblioteca"
						},
						{
							"_nr": "17",
							"__text": "Configurarea dispozitivului CRT in realizarea operatiilor de Intrare/Iesire"
						},
						{
							"_nr": "18",
							"__text": "Tratarea erorilor de Intrare/Iesire prin programele utilizatorului"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Memoratorul instructiunilor COBOL"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Lista cuvintelor rezervate"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Facilitati de lucru multiutilizator"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Sistemul Cobol profesional"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Directive de compilare"
						},
						{
							"_nr": "Anexa 6",
							"__text": "Optiuni de link-editare"
						},
						{
							"_nr": "Anexa 7",
							"__text": "Instructiuni pentru fisierul de definitii"
						},
						{
							"_nr": "Anexa 8",
							"__text": "Erori in timpul executiei"
						}
					]
				},
				"_id": "649",
				"_isbn": "973-9156-94-0"
			},
			{
				"titlu": "Sistemul FoxPro - prezentare si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Nely Musat"
						},
						{
							"_nume": "Mihaela Nicolae"
						},
						{
							"_nume": "Manole Velicanu"
						},
						{
							"_nume": "Ion Teodor"
						},
						{
							"_nume": "Attila Barta"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "All",
				"numarPagini": "324",
				"pretOriginal": "7200",
				"redactori": {
					"redactor": "Dana Colibaba"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Catalin Fratila",
						"Dana Colibaba",
						"Nely Musat",
						"Cristina Branzila",
						"Adriana Cucu",
						"Gabriela Dobrinescu",
						"Alina Oancea"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_Fox_Pro_Prezentare_si_aplicatii_Lungu.jpg"
					},
					"__text": "Nicolae Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Componente functionale si interfata cu utilizatorii"
						},
						{
							"_nr": "2",
							"__text": "Stabilirea configuratiei sistemului"
						},
						{
							"_nr": "3",
							"__text": "Crearea si actualizarea structurii tabelelor"
						},
						{
							"_nr": "4",
							"__text": "Actualizarea inregistrarilor"
						},
						{
							"_nr": "5",
							"__text": "Sortarea si indexarea tabelelelor"
						},
						{
							"_nr": "6",
							"__text": "Regasirea si vizualizarea datelor"
						},
						{
							"_nr": "7",
							"__text": "Realizarea legaturilor intre tabelele unei baze de date"
						},
						{
							"_nr": "8",
							"__text": "Programarea in limbajul FoxPro"
						},
						{
							"_nr": "9",
							"__text": "Facilitati specifice ale versiunii FoxPro 2.0"
						},
						{
							"_nr": "10",
							"__text": "Aplicatie"
						}
					]
				},
				"_id": "650",
				"_isbn": "973-9156-14-2"
			},
			{
				"titlu": "Sisteme de gestiune a bazelor de date: dbase IV, Oracle",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Popa"
						},
						{
							"_nume": "Alexandru Stefanescu"
						},
						{
							"_nume": "Victoria Stanciu"
						},
						{
							"_nume": "Veronica Ivancenco"
						},
						{
							"_nume": "Valerica Mares"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "All",
				"numarPagini": "264",
				"pretOriginal": "5900",
				"redactori": {
					"redactor": "Catalin Fratila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paradovschi Adrian"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_gestiune_a_bazelor_de_date_dBase_IV_Oracle_Popa.jpg"
					},
					"__text": "Carmen Fundi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Baze de dae"
						},
						{
							"_nr": "II",
							"__text": "Sistemul de gestiune al bazelor de date dBase IV"
						},
						{
							"_nr": "III",
							"__text": "Sistemul de gestiune al bazelor de date Oracle"
						}
					]
				},
				"_id": "651",
				"_isbn": "973-9156-89-4"
			},
			{
				"titlu": "Fundamentele limbajelor de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Bazil Parv"
						},
						{
							"_nume": "Alexandru Vancea"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "322",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "55",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Pernaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Fundamentele_limbajelor_de_programare_Parv.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Evolutia si clasificarea limbajelor de programare"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea limbajelor de programare"
						},
						{
							"_nr": "3",
							"__text": "Traducerea si executarea programelor"
						},
						{
							"_nr": "4",
							"__text": "Declaratii"
						},
						{
							"_nr": "5",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "6",
							"__text": "Expresii"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni si controlul executiei"
						},
						{
							"_nr": "8",
							"__text": "Proceduri si transmiterea parametrilor"
						}
					]
				},
				"_id": "652",
				"_isbn": "973-9215-25-4"
			},
			{
				"titlu": "Turbo Prolog 2.0 - Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Judith Meszaros"
					}
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "154",
				"pretOriginal": "6500",
				"colectia": {
					"_numarul": "50",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Pernaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Prolog_2_0_Ghid_de_utilizare_Meszatos.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere. Principiile programarii logice"
						},
						{
							"_nr": "2",
							"__text": "Mediul Turbo Prolog"
						},
						{
							"_nr": "3",
							"__text": "Sectiunile unui program Prolog"
						},
						{
							"_nr": "4",
							"__text": "Programare predicativa"
						},
						{
							"_nr": "5",
							"__text": "Obiecte si liste"
						},
						{
							"_nr": "6",
							"__text": "Functii Prolog"
						},
						{
							"_nr": "7",
							"__text": "Intrari, iesiri, fisiere"
						},
						{
							"_nr": "8",
							"__text": "Baze de date"
						},
						{
							"_nr": "9",
							"__text": "Ferestre in Prolog"
						},
						{
							"_nr": "10",
							"__text": "Interfata grafica Prolog"
						},
						{
							"_nr": "11",
							"__text": "Proiectarea programelor Prolog"
						},
						{
							"_nr": "12",
							"__text": "Programare la nivelul sistemului de operare"
						},
						{
							"_nr": "13",
							"__text": "Inteligenta artificiala in Prolog"
						}
					]
				},
				"_id": "653",
				"_isbn": "973-9215-14-9"
			},
			{
				"titlu": "Calculul paralel - fundamente",
				"autori": {
					"autor": {
						"_nume": "Ioana Chiorean"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "132",
				"pretOriginal": "9100",
				"colectia": {
					"_numarul": "46",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihaela Mihaescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculul_paralel_fundamente_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modele de programare pentru calculul paralel"
						},
						{
							"_nr": "3",
							"__text": "Tehnici utilizate in scrierea algoritmilor paraleli"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi paraleli"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi paraleli de sortare"
						},
						{
							"_nr": "6",
							"__text": "Programarea sub sistemul PARIX"
						},
						{
							"_nr": "7",
							"__text": "Programarea sub sistemul PVM"
						}
					]
				},
				"_id": "654",
				"_isbn": "973-9215-08-4"
			},
			{
				"titlu": "Programare in AutoLisp sub AutoCAD",
				"autori": {
					"autor": {
						"_nume": "Dan Manolea"
					}
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "344",
				"pretOriginal": "10400",
				"colectia": {
					"_numarul": "51",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_in_AutoLISP_sub_AutoCAD_Manolea.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este sice poate AutoLISP-ul"
						},
						{
							"_nr": "2",
							"__text": "Notiuni generale de programare in AutoLISP"
						},
						{
							"_nr": "3",
							"__text": "Introducere in limbajul de programare"
						},
						{
							"_nr": "4",
							"__text": "Notiuni de sintaxa"
						},
						{
							"_nr": "5",
							"__text": "Functii AutoLISP predefinite"
						},
						{
							"_nr": "6",
							"__text": "Mediul de lucru AutoLISP"
						},
						{
							"_nr": "7",
							"__text": "Programarea in AutoLISP"
						},
						{
							"_nr": "8",
							"__text": "Module de baza in programarea cu AutoLISP-ul"
						},
						{
							"_nr": "9",
							"__text": "Programe AutoLisp"
						},
						{
							"_nr": "10",
							"__text": "Mesaje de eroare"
						}
					]
				},
				"_id": "655",
				"_isbn": "973-9215-16-5"
			},
			{
				"titlu": "Mit si adevar despre virusii PC",
				"autori": {
					"autor": [
						{
							"_nume": "Jozsef Vasarhelyi"
						},
						{
							"_nume": "Zoltan Kasa"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "332",
				"pretOriginal": "25000",
				"colectia": {
					"_numarul": "54",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Mit_si_adevar_despre_virusii_PC_Kasa.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Virusii si clasificarea lor"
						},
						{
							"_nr": "3",
							"__text": "Caii troieni si viermi"
						},
						{
							"_nr": "4",
							"__text": "Efecte sociale si legale"
						},
						{
							"_nr": "5",
							"__text": "Surse de infectare si masuri de prevenire"
						},
						{
							"_nr": "6",
							"__text": "Programe antivirus"
						},
						{
							"_nr": "7",
							"__text": "Cum functioneaza programele antivirus"
						},
						{
							"_nr": "8",
							"__text": "Determinator de virusi"
						},
						{
							"_nr": "9",
							"__text": "Mit si adevar despre... virusi"
						},
						{
							"_nr": "Anexa A",
							"__text": "Intrebari frecvent puse despre virusi"
						},
						{
							"_nr": "Anexa B",
							"__text": "Lista virusilor activi"
						},
						{
							"_nr": "Anexa C",
							"__text": "Lista programelor antivirus"
						},
						{
							"_nr": "Anexa D",
							"__text": "Adrese internet utile"
						}
					]
				},
				"_id": "656",
				"_isbn": "973-9215-23-8"
			},
			{
				"titlu": "WordStar - Ghid de utilizare (Editia V-a)",
				"autori": {
					"autor": {
						"_nume": "Lia Chiorean"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "144",
				"pretOriginal": "7800",
				"colectia": {
					"_numarul": "47",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "WordStar_Ghid_de_utilizare_Editia_V_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea editorului de texte WordStar"
						},
						{
							"_nr": "2",
							"__text": "OPENING MENU (meniul principal)"
						},
						{
							"_nr": "3",
							"__text": "Comenzi cu punct"
						},
						{
							"_nr": "4",
							"__text": "Instalarea si configurarea editorului de texte"
						}
					]
				},
				"_id": "657",
				"_isbn": "973-9215-05-X"
			},
			{
				"titlu": "WordPerfect 5.1 - Ghid de utilizare (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "300",
				"pretOriginal": "9100",
				"colectia": {
					"_numarul": "24",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "WordPerfect_5_1_Ghid_de_utilizare_Kovacs.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Functiile de baza"
						},
						{
							"_nr": "4",
							"__text": "Operatii referitoare la fisiere"
						},
						{
							"_nr": "5",
							"__text": "Bazele editarii"
						},
						{
							"_nr": "6",
							"__text": "Comenzile de formatare referitoare la linii"
						},
						{
							"_nr": "7",
							"__text": "Comenzile de formatare referitare la pagini"
						},
						{
							"_nr": "8",
							"__text": "Comenzile de formatare referitare la documente"
						},
						{
							"_nr": "9",
							"__text": "Alte categorii de comeni de formatare"
						},
						{
							"_nr": "10",
							"__text": "Fonturi"
						},
						{
							"_nr": "11",
							"__text": "Definitie de claviatura"
						},
						{
							"_nr": "12",
							"__text": "Tiparirea"
						},
						{
							"_nr": "13",
							"__text": "Gestiunea coloanelor"
						},
						{
							"_nr": "14",
							"__text": "Gestiunea tabelelor"
						},
						{
							"_nr": "15",
							"__text": "Foi de stil"
						},
						{
							"_nr": "16",
							"__text": "Posibilitatile grafice ale editorului"
						},
						{
							"_nr": "17",
							"__text": "Sintaxa comenzilor editorului de ecuatii"
						},
						{
							"_nr": "18",
							"__text": "Descrierea structurii unui document"
						},
						{
							"_nr": "19",
							"__text": "Instrumentele software ale editorului"
						},
						{
							"_nr": "20",
							"__text": "Macrouri"
						},
						{
							"_nr": "21",
							"__text": "Elemente de baza ale macrolimbajului editorului"
						},
						{
							"_nr": "22",
							"__text": "Interclasare si sortare"
						},
						{
							"_nr": "23",
							"__text": "Configurarea editorului"
						},
						{
							"_nr": "24",
							"__text": "Anexe"
						}
					]
				},
				"_id": "658",
				"_isbn": "973-9215-11-4"
			},
			{
				"titlu": "DrawPerfect 1.1 - Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "3000",
				"numarPagini": "272",
				"pretOriginal": "4550",
				"colectia": {
					"_numarul": "32",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "DrawPerfect_1_1_Ghid_de_utilizare_Kovacs.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Functiile de baza"
						},
						{
							"_nr": "4",
							"__text": "Desenare"
						},
						{
							"_nr": "5",
							"__text": "Editarea desenelor"
						},
						{
							"_nr": "6",
							"__text": "Operatii referitoare la fisiere"
						},
						{
							"_nr": "7",
							"__text": "Fonturi"
						},
						{
							"_nr": "8",
							"__text": "Atribute"
						},
						{
							"_nr": "9",
							"__text": "Optiuni"
						},
						{
							"_nr": "10",
							"__text": "Meniul de vizualizare"
						},
						{
							"_nr": "11",
							"__text": "Tiparirea"
						},
						{
							"_nr": "12",
							"__text": "Prezentarea (Show)"
						},
						{
							"_nr": "13",
							"__text": "Definitie de claviatura"
						},
						{
							"_nr": "14",
							"__text": "Macro-uri"
						},
						{
							"_nr": "15",
							"__text": "Elementele de baza ale macrolimbajului"
						},
						{
							"_nr": "16",
							"__text": "Configurarea programului DrawPerfect"
						},
						{
							"_nr": "17",
							"__text": "Anexe"
						},
						{
							"_nr": "18",
							"__text": "Posibilitatile grafice ale editorului WordPerfect"
						},
						{
							"_nr": "19",
							"__text": "Fonturi DrawPerfect"
						},
						{
							"_nr": "20",
							"__text": "Machete predefinite"
						}
					]
				},
				"_id": "659",
				"_isbn": "973-96274-8-X"
			},
			{
				"titlu": "Initiere in ingineria sistemelor soft",
				"autori": {
					"autor": {
						"_nume": "Dorin Bocu"
					}
				},
				"anAparitie": "2001",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "146",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "129",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_ingineria_sistemelor_soft_Bocu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme a caror rezolvare depinde esential de ingineria sistemelor soft"
						},
						{
							"_nr": "2",
							"__text": "Ce este o metodologie de dezvoltare a softului"
						},
						{
							"_nr": "3",
							"__text": "Ciclul de viata al unui sistem soft"
						},
						{
							"_nr": "4",
							"__text": "Abstractizarea solutiei unui sistem soft"
						},
						{
							"_nr": "5",
							"__text": "Managementul proiectelor in industria softului"
						},
						{
							"_nr": "6",
							"__text": "Aspecte metodologice in abordarea  unor probleme de dezvoltare a unor sisteme soft"
						}
					]
				},
				"_id": "660",
				"_isbn": "973-650-006-3"
			},
			{
				"titlu": "ORACLE de la 9i la 11g pentru dezvoltatorii de aplicatii. Instalare-administrare si limbajele SQL si PL/SQL (Volumul I, partea I-a)",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Maftei"
						},
						{
							"_nume": "Catalin Maftei"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "374",
				"pretOriginal": "490000",
				"colectia": {
					"_numarul": "237",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "ORACLE_de_la_9i_la_11g_pentru_dezvoltatorii_de_aplicatii_Instalare_administrare_si_limbajele_SQL_si_PL_SQL_Vol1_ParteaII_Maftei.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de baze de date"
						},
						{
							"_nr": "2",
							"__text": "Introducere in sistemul ORACLE"
						},
						{
							"_nr": "3",
							"__text": "Instalare ORACLE DATABASE si administrare baza de date"
						},
						{
							"_nr": "4",
							"__text": "SQL*PLUS si iSQL*PLUS"
						},
						{
							"_nr": "5",
							"__text": "Elementele limbajului SQL"
						},
						{
							"_nr": "6",
							"__text": "Regasirea si afisarea datelor din baza de date"
						},
						{
							"_nr": "7",
							"__text": "Parametrizarea interogarilor, operatori relationali, interogari pe tabele dependente"
						},
						{
							"_nr": "8",
							"__text": "Interogari complexe"
						},
						{
							"_nr": "9",
							"__text": "Afisarea datelor sub forma de rapoarte in SQL*PLUS si iSQL*PLUS"
						},
						{
							"_nr": "10",
							"__text": "Manipularea obiectelor de tip non-schema"
						}
					]
				},
				"_id": "661",
				"_isbn": "978-973-650-267-5"
			},
			{
				"titlu": "ORACLE de la 9i la 11g pentru dezvoltatorii de aplicatii. Instalare-administrare si limbajele SQL si PL/SQL (Volumul I, partea II-a)",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Maftei"
						},
						{
							"_nume": "Catalin Maftei"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "404",
				"pretOriginal": "490000",
				"colectia": {
					"_numarul": "237",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "ORACLE_de_la_9i_la_11g_pentru_dezvoltatorii_de_aplicatii_Instalare_administrare_si_limbajele_SQL_si_PL_SQL_Vol1_ParteaI_Maftei.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "11",
							"__text": "Manipularea obiectelor de tip schema"
						},
						{
							"_nr": "12",
							"__text": "Actualizarea datelor din tabele"
						},
						{
							"_nr": "13",
							"__text": "Limbajul procedural PL/SQL"
						},
						{
							"_nr": "14",
							"__text": "Instructiuni si facilitati de baza in PL/SQL"
						},
						{
							"_nr": "15",
							"__text": "Subprogeame utilizator"
						},
						{
							"_nr": "16",
							"__text": "Declasantoare (Triggere)"
						},
						{
							"_nr": "17",
							"__text": "Modelul obiectual-relational in ORACLE"
						}
					]
				},
				"_id": "662",
				"_isbn": "978-973-650-269-9"
			},
			{
				"titlu": "Programare functionala (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Alfred Letia"
						},
						{
							"_nume": "Liviu A. Negrescu"
						},
						{
							"_nume": "Lavinia Negrescu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "214",
				"pretOriginal": "440000",
				"colectia": {
					"_numarul": "215",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_functionala_Vol1_Letia.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Identificatori"
						},
						{
							"_nr": "2",
							"__text": "Date"
						},
						{
							"_nr": "3",
							"__text": "Expresii"
						},
						{
							"_nr": "4",
							"__text": "Functii"
						},
						{
							"_nr": "5",
							"__text": "Conversii"
						},
						{
							"_nr": "6",
							"__text": "Tratarea erorilor"
						},
						{
							"_nr": "7",
							"__text": "Tupluri"
						},
						{
							"_nr": "8",
							"__text": "Operatori infix"
						},
						{
							"_nr": "9",
							"__text": "Evaluarea expresiilor"
						},
						{
							"_nr": "10",
							"__text": "Intrari-iesiri standard"
						},
						{
							"_nr": "11",
							"__text": "Referinta"
						},
						{
							"_nr": "12",
							"__text": "Cicluri"
						},
						{
							"_nr": "13",
							"__text": "Declaratii locale"
						}
					]
				},
				"_id": "663",
				"_isbn": "973-650-196-5"
			},
			{
				"titlu": "Quick Basic 4.5. Mediul de programare, limbaj, aplicatii",
				"autori": {
					"autor": {
						"_nume": "Alexandru A. Popovici"
					}
				},
				"anAparitie": "1995",
				"editura": "Promedia Plus",
				"numarPagini": "248",
				"pretOriginal": "6990",
				"tehnoredactori": {
					"tehnoredactor": "Alexandru A. Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Quick_Basic_4_5_Mediul_de_programare_limbaj_aplicatii_Popovici.jpg"
					},
					"__text": "Octavian Bour"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere: limbaje si programe"
						},
						{
							"_nr": "I",
							"__text": "Mediul integrat de programare (MIP)"
						},
						{
							"_nr": "2",
							"__text": "Prezentare generala a MIP"
						},
						{
							"_nr": "3",
							"__text": "Acordarea ajutorului (asistentei)"
						},
						{
							"_nr": "4",
							"__text": "Crearea si salvarea programelor"
						},
						{
							"_nr": "5",
							"__text": "Editarea (scrierea si modificarea) fisierelor sursa"
						},
						{
							"_nr": "6",
							"__text": "Compilarea, executia is depanarea programelor"
						},
						{
							"_nr": "II",
							"__text": "Limbajul QuickBASIC (QB)"
						},
						{
							"_nr": "7",
							"__text": "Structura programelor QB"
						},
						{
							"_nr": "8",
							"__text": "Constante si variabile"
						},
						{
							"_nr": "9",
							"__text": "Expresii"
						},
						{
							"_nr": "10",
							"__text": "Proceduri"
						},
						{
							"_nr": "11",
							"__text": "Operatii de intrare/iesire (I/E)"
						},
						{
							"_nr": "12",
							"__text": "Text si grafica pe ecran"
						},
						{
							"_nr": "13",
							"__text": "Lucrul cu fisiere"
						},
						{
							"_nr": "14",
							"__text": "Functii standard"
						},
						{
							"_nr": "III",
							"__text": "Aplicatii"
						},
						{
							"_nr": "15",
							"__text": "Probleme de analiza numerica"
						},
						{
							"_nr": "16",
							"__text": "Probleme de cercetare operationala si de grafica"
						}
					]
				},
				"_id": "664",
				"_isbn": "973-96862-4-9"
			},
			{
				"titlu": "Totul despre procesorul de texte WORD 2.0 sub WINDOWS",
				"autori": {
					"autor": [
						{
							"_nume": "Victor-Valeriu Patriciu"
						},
						{
							"_nume": "Telu Dumitru"
						},
						{
							"_nume": "Razvan Muresan"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Cantemir",
				"numarPagini": "192",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Vlad-Florian Tepelea"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_procesorul_de_text_WORD_2_0_sub_Windows_Patriciu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Sectiunea A",
							"__text": "Elemente de baza in editarea cu WORD sub Windows"
						},
						{
							"_nr": "Sectiunea B",
							"__text": "Editarea avansata cu WORD sub Windows"
						},
						{
							"_nr": "Sectiunea C",
							"__text": "Desene si grafce in WORD sub Windows"
						},
						{
							"_nr": "Sectiunea D",
							"__text": "Instalarea si personalizarea lui WORD sub Windows"
						}
					]
				},
				"_id": "665",
				"_isbn": "973-96419-5-4"
			},
			{
				"titlu": "Bazele utilizarii si programarii calculatoarelor personale",
				"autori": {
					"autor": [
						{
							"_nume": "Cristina Badut"
						},
						{
							"_nume": "Mircea Badut"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Adias",
				"numarPagini": "132",
				"pretOriginal": "1800",
				"tehnoredactori": {
					"tehnoredactor": "Octavian Aspru"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_utilizarii_si_programarii_calculatoarelor_personale_Badut.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "i",
							"__text": "Arhitectura calculatoarelor personale"
						},
						{
							"_nr": "2",
							"__text": "Disk Operating System (DOS)"
						},
						{
							"_nr": "3",
							"__text": "Norton Commander"
						},
						{
							"_nr": "4",
							"__text": "Programe utilitare"
						},
						{
							"_nr": "5",
							"__text": "Editoare si procesoare de texte"
						},
						{
							"_nr": "6",
							"__text": "Windows"
						},
						{
							"_nr": "7",
							"__text": "Notiuni de programare"
						},
						{
							"_nr": "8",
							"__text": "Initiere in QuickBasic"
						}
					]
				},
				"_id": "666",
				"_isbn": "973-96265-1-3"
			},
			{
				"titlu": "Grafica in Turbo C",
				"autori": {
					"autor": {
						"_nume": "Octavian Aspru"
					}
				},
				"anAparitie": "1994",
				"editura": "Adias",
				"numarPagini": "248",
				"pretOriginal": "4980",
				"tehnoredactori": {
					"tehnoredactor": "Octavian Aspru"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_in_Turbo_C_Aspru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Functii de initializare si alternare mod grafic/mod text"
						},
						{
							"_nr": "III",
							"__text": "Functii pentru determinare moduri grafice"
						},
						{
							"_nr": "IV",
							"__text": "Functii pentru ferestre"
						},
						{
							"_nr": "V",
							"__text": "Functii pentru modul de desenare a liniilor"
						},
						{
							"_nr": "VI",
							"__text": "Functii pentru hasurare (umplere) desene"
						},
						{
							"_nr": "VII",
							"__text": "Functii pentru culori"
						},
						{
							"_nr": "VIII",
							"__text": "Functii pentru mutarea cursorului grafic"
						},
						{
							"_nr": "IX",
							"__text": "Functii pentru realizarea de desene"
						},
						{
							"_nr": "X",
							"__text": "Functii pentru scriere text in mod grafic"
						},
						{
							"_nr": "XI",
							"__text": "Functii pentru realizarea de animatie"
						},
						{
							"_nr": "XII",
							"__text": "Functii pentru erori"
						},
						{
							"_nr": "XIII",
							"__text": "Functii pentru realizarea imaginilor"
						},
						{
							"_nr": "XIV",
							"__text": "Functii pentru gestiunea memoriei grafice"
						},
						{
							"_nr": "XV",
							"__text": "Gestiunea memoriei"
						}
					]
				},
				"_id": "667",
				"_isbn": "973-96265-2-1"
			},
			{
				"titlu": "Baze de date relationale. Utilizarea limbajului SQL*PLUS",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Ion Rosca"
						},
						{
							"_nume": "Nely Musat"
						},
						{
							"_nume": "Gh. Sabau"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "All",
				"numarPagini": "180",
				"pretOriginal": "2300",
				"tehnoredactori": {
					"tehnoredactor": "Nely Musat"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_relationale_Utilizarea_limbajului_sQL_PLUS_Lungu.jpg"
					},
					"__text": "Nicolae Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte utilizate si elemente de baza ale limbajului SQL*PLUS"
						},
						{
							"_nr": "2",
							"__text": "Conectarea si deconectarea unei baze de date"
						},
						{
							"_nr": "3",
							"__text": "Editarea unui program SQL"
						},
						{
							"_nr": "4",
							"__text": "Selectarea datelor intr-o baza de date"
						},
						{
							"_nr": "5",
							"__text": "Actualizarea datelor"
						},
						{
							"_nr": "6",
							"__text": "Definirea unei baze de date"
						},
						{
							"_nr": "7",
							"__text": "Control accesului la baza de date"
						},
						{
							"_nr": "8",
							"__text": "Definirea optiunilor de lucru si realizarea altor operatii"
						},
						{
							"_nr": "9",
							"__text": "Formatarea situaliilor de iesire"
						},
						{
							"_nr": "10",
							"__text": "Comenzi pentru lucrul in retea"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Cuvinte rezervate"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Operatori utilizatati in limbajul SQL*PLUS"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Functii, variabile sitem si pseudo-coloane"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Dictionarul de cuvinte/expresii"
						}
					]
				},
				"_id": "668",
				"_isbn": "973-9156-18-5"
			},
			{
				"titlu": "QBASIC - Proiect de manual pentru gimnaziu",
				"autori": {
					"autor": {
						"_nume": "Marinel Serban"
					}
				},
				"anAparitie": "1997",
				"editura": "Petrion",
				"numarPagini": "320",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "QBASIC_Proiect_de_manual_pentru_gimnaziu_Serban.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I.1",
							"__text": "Structura si functionarea unui calculator IBM-PC"
						},
						{
							"_nr": "I.2",
							"__text": "Baze de numeratie si elemente de logica matematica"
						},
						{
							"_nr": "I.3",
							"__text": "Algoritmi"
						},
						{
							"_nr": "I.4",
							"__text": "Limbajul de programare QBASIC (I)"
						},
						{
							"_nr": "I.5",
							"__text": "Probleme"
						},
						{
							"_nr": "II.1",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "II.2",
							"__text": "Limbajul de programare QBASIC (II)"
						},
						{
							"_nr": "III.1",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "III.2",
							"__text": "Limbajul de programare QBASIC (III)"
						},
						{
							"_nr": "IV.1",
							"__text": "Elemente de grafica si cromatica"
						},
						{
							"_nr": "IV.2",
							"__text": "Generarea sunetelor in QBASIC"
						},
						{
							"_nr": "IV.3",
							"__text": "Generalitati despre fisiere in QBASIC"
						},
						{
							"_nr": "IV.4",
							"__text": "Programe comentate"
						},
						{
							"_nr": "IV.5",
							"__text": "Probleme"
						},
						{
							"_nr": "Anexa A1",
							"__text": "Tabela codurilor ASCII"
						},
						{
							"_nr": "Anexa A2",
							"__text": "Mesaje de eroare QBASIC"
						}
					]
				},
				"_id": "669",
				"_isbn": "973-9116-10-8"
			},
			{
				"titlu": "Programare concurenta pe platforme Unix, Windows, Java",
				"autori": {
					"autor": [
						{
							"_nume": "Florina Mircea Boian"
						},
						{
							"_nume": "Corina Maria Ferdean"
						},
						{
							"_nume": "Rares Florin Boian"
						},
						{
							"_nume": "Radu Calin Dragos"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Albastra",
				"numarPagini": "420",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "147",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_concurenta_pe_platforme_Unix_Windows_Java_Boian.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Nivelel prelucrarii concurente"
						},
						{
							"_nr": "2",
							"__text": "Concepte abstracte utilizate in descrierea concurentei"
						},
						{
							"_nr": "3",
							"__text": "Programarea concurenta la nivel de proces"
						},
						{
							"_nr": "4",
							"__text": "Programarea concurenta la nivel de Thread-uri"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii concurente complexe"
						}
					]
				},
				"_id": "670",
				"_isbn": "973-650--072-1"
			},
			{
				"titlu": "Informatica aplicata in economie. Baze de date si sisteme de gestiune a bazelor de date",
				"autori": {
					"autor": {
						"_nume": "Ana-Maria Preda"
					}
				},
				"anAparitie": "1997",
				"editura": "Didactica si Pedagogica",
				"numarPagini": "402",
				"pretOriginal": "15000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_aplicata_in_economie_Baze_de_date_si_sisteme_de_gestiune_a_bazelor_de_date_Preda.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elementele limbajului FoxPro"
						},
						{
							"_nr": "2",
							"__text": "Crearea unei baze de date"
						},
						{
							"_nr": "3",
							"__text": "Intretinerea unei baze de date"
						},
						{
							"_nr": "4",
							"__text": "Concultarea (interograrea) unei baze de date"
						},
						{
							"_nr": "5",
							"__text": "Relatii intre baze de date si interogarea bazelor corelate"
						},
						{
							"_nr": "6",
							"__text": "Rapoarte si etichete"
						},
						{
							"_nr": "7",
							"__text": "Elemente de programare in FoxPro"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni pentru descrierea structurilor de control"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni de intrare/iesire"
						},
						{
							"_nr": "10",
							"__text": "Instructiuni pentru realizarea interfetei cu utilizatorul"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea generatoarelor pentru realizarea aplicatiilor FoxPro"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Lansarea in executie a produsului FoxPro"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Interfata utilizator - sistem FoxPro"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Utilizarea meniului sistem FoxPro pentru lucrul cu baze de date"
						}
					]
				},
				"_id": "671",
				"_isbn": "973-30-5753-3"
			},
			{
				"titlu": "Programarea la calculatorul Felix C 256: Fortran,Cobol",
				"autori": {
					"autor": [
						{
							"_nume": "G. Crutu"
						},
						{
							"_nume": "C. Lupsa"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Stiintifica",
				"tiraj": "10150",
				"numarPagini": "490",
				"pretOriginal": "17.50",
				"redactori": {
					"redactor": "Maria Blaga"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_la_calculatorul_Felix_C_256_Fortran_Cobol_Crutu.jpg"
					},
					"__text": "Hary Guttman"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Notiuni generale privind utilizarea calculatoarelor electronice"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Limbajul Fortran"
						},
						{
							"_nr": "Partea a traia",
							"__text": "Limbajul Cobol"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Exploatarea programelor scrise in Fortran si Cobol"
						}
					]
				},
				"_id": "672",
				"_isbn": ""
			},
			{
				"titlu": "SAS - Statisitca asistata de calculator",
				"autori": {
					"autor": {
						"_nume": "Rudolf Dootz"
					}
				},
				"anAparitie": "2003",
				"editura": "Tipotrib Sibiu",
				"numarPagini": "196",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "SAS_Statistica_asistata_de_calculator_Dootz.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "I"
						},
						{
							"_nr": "1.1",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "1.2",
							"__text": "Crearea unui fisier (data) in SAS"
						},
						{
							"_nr": "1.3",
							"__text": "Etichetarea numelui si continutului varibabilelor unui fisier"
						},
						{
							"_nr": "1.3.1",
							"__text": "Etichetarea numelui variabilelor"
						},
						{
							"_nr": "1.3.2",
							"__text": "Etichetarea continutului variabilelor"
						},
						{
							"_nr": "II",
							"__text": "II"
						},
						{
							"_nr": "2.1",
							"__text": "Procedura PROC SORT"
						},
						{
							"_nr": "2.2",
							"__text": "Procedura PROC PRINT"
						},
						{
							"_nr": "III",
							"__text": "Prezentarea rezultatelor prelucrarii prin metode statistice descriptive"
						},
						{
							"_nr": "3.1",
							"__text": "PROC MEANS"
						},
						{
							"_nr": "3.2",
							"__text": "PROC UNIVARIATE"
						},
						{
							"_nr": "IV",
							"__text": "Prezentarea rezultatelor prelucrarii prin tabele"
						},
						{
							"_nr": "4.1",
							"__text": "Procedura PROC FREQ"
						},
						{
							"_nr": "4.2",
							"__text": "Procedura PROC TABULATE"
						},
						{
							"_nr": "V",
							"__text": "Prezentarea rezultatelor prelucrarii cu ajutorul reprezentarilor grafice"
						},
						{
							"_nr": "5.1",
							"__text": "Grafice realizate prin procedura PROC CHART"
						},
						{
							"_nr": "5.2",
							"__text": "Prezentarea rezultatelor prelucrarii prin CPROC GCHART"
						},
						{
							"_nr": "5.3",
							"__text": "Grafice realizate prin procedura PROC PLOT"
						},
						{
							"_nr": "5.4",
							"__text": "Grafice realizate prin procedura PROC GPLOT"
						},
						{
							"_nr": "VI",
							"__text": "Corelatie si regresie cu SAS"
						},
						{
							"_nr": "6.1",
							"__text": "Calculul coeficienetului de corelatie si covarinata"
						},
						{
							"_nr": "6.2",
							"__text": "Analiza de regresie"
						},
						{
							"_nr": "6.2.1",
							"__text": "Regresie liniara simpla"
						},
						{
							"_nr": "6.2.2",
							"__text": "Regresie liniara multipla"
						},
						{
							"_nr": "6.2.3",
							"__text": "Regresie neliniara"
						}
					]
				},
				"_id": "673",
				"_isbn": "973-8009-81-2"
			},
			{
				"titlu": "Windows 3.X, 95, NT. Programarea in C, pas cu pas",
				"autori": {
					"autor": {
						"_nume": "Cristian George Savu"
					}
				},
				"anAparitie": "1996",
				"editura": "All",
				"numarPagini": "416",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Andreea Ludic"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_3_x_95_NT_PRogramarea_in_C_pas_cu_pas_Savu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul de operare Windows 95"
						},
						{
							"_nr": "2",
							"__text": "Introducere in limbajul C"
						},
						{
							"_nr": "3",
							"__text": "Ferestre, mesaje"
						},
						{
							"_nr": "4",
							"__text": "Programarea pentru Window 95"
						},
						{
							"_nr": "5",
							"__text": "Intrari de la tastatura si mouse"
						},
						{
							"_nr": "6",
							"__text": "Resurse"
						},
						{
							"_nr": "7",
							"__text": "Crearea cutiilor de dialog (Dialog Box)"
						},
						{
							"_nr": "8",
							"__text": "Ferestre control"
						},
						{
							"_nr": "9",
							"__text": "Controale owner-draw"
						},
						{
							"_nr": "10",
							"__text": "Controle comune Window 95"
						},
						{
							"_nr": "11",
							"__text": "Font si text"
						},
						{
							"_nr": "12",
							"__text": "Grafica in Windows 95"
						},
						{
							"_nr": "13",
							"__text": "Fisiere"
						},
						{
							"_nr": "14",
							"__text": "Gestiunea memoriei Windows 95"
						},
						{
							"_nr": "15",
							"__text": "Imagini bipmap"
						},
						{
							"_nr": "16",
							"__text": "Imagini metafisier"
						},
						{
							"_nr": "17",
							"__text": "Multimedia in Windows 95"
						},
						{
							"_nr": "19",
							"__text": "Biblioteci DLL"
						},
						{
							"_nr": "Anexa",
							"__text": "Index de functii, mesaje si resurse"
						}
					]
				},
				"_id": "674",
				"_isbn": "973-9229-26-3"
			},
			{
				"titlu": "HTML. Publicatii Web",
				"autori": {
					"autor": {
						"_nume": "Dumitru Radoiu"
					}
				},
				"anAparitie": "1996",
				"editura": "Agora",
				"numarPagini": "272",
				"pretOriginal": "15000",
				"tehnoredactori": {
					"tehnoredactor": "Adrian Pop"
				},
				"coperta": {
					"imagine": {
						"_path": "HTML_Publicatii_Web_Radoiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Vedere de sus"
						},
						{
							"_nr": "2",
							"__text": "HTTP"
						},
						{
							"_nr": "3",
							"__text": "CGI"
						},
						{
							"_nr": "4",
							"__text": "Comparatii"
						},
						{
							"_nr": "5",
							"__text": "Instalrea unui server WWW"
						},
						{
							"_nr": "6",
							"__text": "Sintaxa limbajului HTML"
						},
						{
							"_nr": "7",
							"__text": "Structurarea documentelor HTML"
						},
						{
							"_nr": "8",
							"__text": "Legaturi"
						},
						{
							"_nr": "9",
							"__text": "Tabele"
						},
						{
							"_nr": "10",
							"__text": "Gateway su scripturi CGI"
						},
						{
							"_nr": "11",
							"__text": "Imagini in documente HTML"
						},
						{
							"_nr": "12",
							"__text": "Formulare interactive"
						},
						{
							"_nr": "13",
							"__text": "Medii externe:imagini, sunete, video"
						},
						{
							"_nr": "14",
							"__text": "Spre standardizarea HTML 3.0"
						},
						{
							"_nr": "15",
							"__text": "Extensiile Netscape"
						},
						{
							"_nr": "16",
							"__text": "Applet-uri Java"
						},
						{
							"_nr": "17",
							"__text": "Procesarea documentelor"
						},
						{
							"_nr": "18",
							"__text": "Instrumente de editare"
						},
						{
							"_nr": "19",
							"__text": "Designul publicatiilor electronice"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Glosar"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Resurse romanesti pe Web"
						},
						{
							"_nr": "Anaxa 3",
							"__text": "Publicatii comerciale pe Web"
						},
						{
							"_nr": "Anexa 4",
							"__text": "cautand pe Web"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Tag-uri HTML utilizate frecvent"
						}
					]
				},
				"_id": "675",
				"_isbn": "973-97706-0-6"
			},
			{
				"titlu": "Aplicatii de informatica pentru liceu [IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Mariana Bold"
						},
						{
							"_nume": "Marius Harzoiu"
						},
						{
							"_nume": "Daniela Manolescu"
						},
						{
							"_nume": "Alina Matei"
						},
						{
							"_nume": "Aurelian Raducu"
						},
						{
							"_nume": "Serenela Raducu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Paralela 45",
				"numarPagini": "234",
				"pretOriginal": "150000",
				"pretCumparare": "30000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Radulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_de_informatica_pentru_liceu_IX_Bold .jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Expresii"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi. Limbajul pseudocod"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Pascal. Tipuri simple. Instructiuni"
						},
						{
							"_nr": "4",
							"__text": "Variabile structurate. Tablouri"
						},
						{
							"_nr": "5",
							"__text": "Fisiere"
						}
					]
				},
				"_id": "676",
				"_isbn": "973-697-623-8"
			},
			{
				"titlu": "Aplicatii de informatica pentru liceu [X]",
				"autori": {
					"autor": [
						{
							"_nume": "Mariana Bold"
						},
						{
							"_nume": "Marius Harzoiu"
						},
						{
							"_nume": "Daniela Manolescu"
						},
						{
							"_nume": "Alina Matei"
						},
						{
							"_nume": "Aurelian Raducu"
						},
						{
							"_nume": "Serenela Raducu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Paralela 45",
				"numarPagini": "311",
				"pretOriginal": "190000",
				"pretCumparare": "30000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Radulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_de_informatica_pentru_liceu_X_Bold.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Recapitulare clasa a IX-a"
						},
						{
							"_nr": "2",
							"__text": "Tipul de date String"
						},
						{
							"_nr": "3",
							"__text": "Tipul de date Record"
						},
						{
							"_nr": "4",
							"__text": "Subprograme"
						},
						{
							"_nr": "5",
							"__text": "Structuri e date alocate static"
						},
						{
							"_nr": "6",
							"__text": "Recursivitate"
						}
					]
				},
				"_id": "677",
				"_isbn": "973-697-624-6"
			},
			{
				"titlu": "Informatica. Culegere de probleme. Probleme rezolvate pentru examenul de atestare informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Rodica Cherciu"
						},
						{
							"_nume": "Marcel Grigoriu"
						},
						{
							"_nume": "Corina Achinca"
						},
						{
							"_nume": "Marcel Homorodean"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Nedion",
				"numarPagini": "272",
				"pretOriginal": "15.81",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Culegere_de_probleme_Probleme_rezolvate_pentru_examenul_de_atestare_in_informatica_Cherciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Subiectele probei practice pentru examenul de atestat profesional la infomatica, 2006"
						},
						{
							"_nr": "I.1",
							"__text": "Word si Windows"
						},
						{
							"_nr": "I.2",
							"__text": "Programare"
						},
						{
							"_nr": "I.3",
							"__text": "Clasele de matematica-informatica intensiv informatica"
						},
						{
							"_nr": "I.4",
							"__text": "Baze de date"
						}
					]
				},
				"_id": "678",
				"_isbn": "973-7748-26-3"
			},
			{
				"titlu": "Internetul, o lume noua",
				"autori": {
					"autor": {
						"_nume": "?"
					}
				},
				"anAparitie": "2006",
				"editura": "StadiForm",
				"numarPagini": "96",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Internetul_o_lume_noua_StadiFrom.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "II",
							"__text": "Servicii Internet"
						},
						{
							"_nr": "III",
							"__text": "Browsere"
						},
						{
							"_nr": "IV",
							"__text": "WAP - Accesul Mobil la Internet"
						},
						{
							"_nr": "V",
							"__text": "Securitatea pe Internet"
						},
						{
							"_nr": "VI",
							"__text": "Dictionar de Internet"
						}
					]
				},
				"_id": "679",
				"_isbn": "973-87542-2-4"
			},
			{
				"titlu": "Limbajul C",
				"autori": {
					"autor": {
						"_nume": "Andrei Stefan"
					}
				},
				"anAparitie": "2001",
				"editura": "StadiForm",
				"numarPagini": "96",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C_instructiuni_Stefan.jpg"
					},
					"__text": "Stefan Blaga"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Generalitati"
						},
						{
							"_nr": "II",
							"__text": "Elementele unui program C"
						},
						{
							"_nr": "III",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "IV",
							"__text": "Instructiuni in C"
						},
						{
							"_nr": "V",
							"__text": "Structuri in C"
						},
						{
							"_nr": "VI",
							"__text": "Tipuri de date in C"
						},
						{
							"_nr": "VII",
							"__text": "Operati si expresii in C"
						},
						{
							"_nr": "VIII",
							"__text": "Directive de compilare"
						},
						{
							"_nr": "IX",
							"__text": "Functii specifice"
						},
						{
							"_nr": "X",
							"__text": "Cozi, stive, liste si arbori"
						},
						{
							"_nr": "XI",
							"__text": "Dezvoltarea programelor folosind limbajul C"
						},
						{
							"_nr": "XII",
							"__text": "Erori"
						}
					]
				},
				"_id": "680",
				"_isbn": ""
			},
			{
				"titlu": "Norton Commander - utilizare",
				"autori": {
					"autor": {
						"_nume": "Andrei Stefan"
					}
				},
				"anAparitie": "2001",
				"editura": "StadiForm",
				"numarPagini": "64",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Norton_Commander_Utilizare_Stefan.jpg"
					},
					"__text": "Stefan Blaga"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "II",
							"__text": "Operatii cu mouse-ul"
						},
						{
							"_nr": "III",
							"__text": "Configurarea mediului de lucru Norton Commander"
						},
						{
							"_nr": "IV",
							"__text": "Operatii cu discul"
						},
						{
							"_nr": "V",
							"__text": "Operatii cu directoare"
						},
						{
							"_nr": "VI",
							"__text": "Operatii cu fisiere"
						},
						{
							"_nr": "VII",
							"__text": "Operatii de informare"
						}
					]
				},
				"_id": "681",
				"_isbn": ""
			},
			{
				"titlu": "Memorator de informatica pentru clasa a X-a: C++",
				"autori": {
					"autor": {
						"_nume": "Mihai Nanu"
					}
				},
				"anAparitie": "2005",
				"editura": "Booklet",
				"numarPagini": "80",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Memorator_De_informatica_pentru_clasa_a_X_a_Cpp_Nanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Implementarea algoritmilor intr-un limbaj de programare"
						},
						{
							"_nr": "2",
							"__text": "Elaborarea algoritmilor de rezolvare a problemelor. Metode de rezolvare a problemelor"
						},
						{
							"_nr": "3",
							"__text": "Clase de algoritmi"
						},
						{
							"_nr": "4",
							"__text": "Structuri dinamice de date"
						}
					]
				},
				"_id": "682",
				"_isbn": "973-7752-27-9"
			},
			{
				"titlu": "Memorator de informatica pentru clasa a X-a",
				"autori": {
					"autor": {
						"_nume": "Mihai Nanu"
					}
				},
				"anAparitie": "2004",
				"editura": "Booklet",
				"numarPagini": "64",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Memorator_De_informatica_pentru_clasa_a_X_a_Nanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Implementarea algoritmilor intr-un limbaj de programare"
						},
						{
							"_nr": "2",
							"__text": "Elaborarea algoritmilor de rezolvare a problemelor. Metode de rezolvare a problemelor"
						},
						{
							"_nr": "3",
							"__text": "Clase de algoritmi"
						},
						{
							"_nr": "4",
							"__text": "Structuri dinamice de date"
						}
					]
				},
				"_id": "683",
				"_isbn": "973-7752-10-4"
			},
			{
				"titlu": "Memorator de informatica pentru clasa a IX-a",
				"autori": {
					"autor": {
						"_nume": "Mihai Nanu"
					}
				},
				"anAparitie": "2004",
				"editura": "Booklet",
				"numarPagini": "64",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Memorator_De_informatica_pentru_clasa_a_IX_a_Nanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Identificare datelor care intervin intr-o problema si a relatiilor dintre acestea"
						},
						{
							"_nr": "III",
							"__text": "Elaboarea algoritmilor de rezolvare a problemelor"
						},
						{
							"_nr": "IV",
							"__text": "Implementarea algorimilor intr-un limbaj de programare"
						}
					]
				},
				"_id": "684",
				"_isbn": "973-7752-09-0"
			},
			{
				"titlu": "Memorator de informatica pentru clasa a XII-a",
				"autori": {
					"autor": [
						{
							"_nume": "Anca Ravar"
						},
						{
							"_nume": "Adrian Popescu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Booklet",
				"numarPagini": "80",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Memorator_De_informatica_pentru_clasa_a_XII_a_Ravar.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Organizarea datelor"
						},
						{
							"_nr": "II",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "III",
							"__text": "Programare si functii in Visual FoxPro"
						},
						{
							"_nr": "IV",
							"__text": "Prelucrari sistematice a bazelor de date"
						},
						{
							"_nr": "V",
							"__text": "Baze de date"
						},
						{
							"_nr": "VI",
							"__text": "Elaborarea unor strategii de modularizare si abstractizare a prelucrarii datelor"
						}
					]
				},
				"_id": "685",
				"_isbn": "973-7752-12-0"
			},
			{
				"titlu": "Algoritmi",
				"autori": {
					"autor": {
						"_nume": "Stelian Niculescu"
					}
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "88",
				"pretOriginal": "2.62",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Cretu"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_Niculescu.jpg"
					},
					"__text": "Eugen Kery"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Scheme logice"
						},
						{
							"_nr": "3",
							"__text": "Limbaj pentru Descrierea Algoritmilor (LDA)"
						},
						{
							"_nr": "4",
							"__text": "Tabele de decizie"
						}
					]
				},
				"_id": "686",
				"_isbn": ""
			},
			{
				"titlu": "Totul despre...microprocesorul Z80 (Vol.1)",
				"autori": {
					"autor": {
						"_nume": "Miklos Patrubany"
					}
				},
				"anAparitie": "1989",
				"editura": "Tehnica",
				"numarPagini": "424",
				"pretOriginal": "32.5",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "V.E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_microprocesorul_Z80_Vol1_Patrubany.jpg"
					},
					"__text": "Silvia Pintea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea a I-a",
							"__text": "Microprocesorului Z80: o prezentare inedita. Simularea functionarii lui Z80, cu si fara caseta magnetica. Aventuri cu Visible-Z80"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Complemente privind microprocesorul Z80 si simulatorul sau"
						}
					]
				},
				"_id": "687",
				"_isbn": "973-31-0007-2"
			},
			{
				"titlu": "Totul despre...microprocesorul Z80 (Vol.2)",
				"autori": {
					"autor": {
						"_nume": "Miklos Patrubany"
					}
				},
				"anAparitie": "1989",
				"editura": "Tehnica",
				"numarPagini": "232",
				"pretOriginal": "32.5",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "V.E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_microprocesorul_Z80_Vol2_Patrubany.jpg"
					},
					"__text": "Silvia Pintea"
				},
				"cuprins": {
					"capitol": {
						"_nr": "Partea a III-a",
						"__text": "Programarea procesorului Z80. Un proiect: o casa de marcat electronica"
					}
				},
				"_id": "688",
				"_isbn": "973-31-0007-2"
			},
			{
				"titlu": "Invatam FORTRAN...conversand cu calculatorul (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Dumitrascu"
						},
						{
							"_nume": "Stefan Patrut"
						},
						{
							"_nume": "Stefan Stan"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "256",
				"pretOriginal": "10",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatam_FOTRAN_conversand_cu_calculatorul_Vol1_Dumitrascu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Conversatia 1",
							"__text": "Calculul mediei aritmetice a doua valori. Cel mai simplu program in FORTRAN"
						},
						{
							"_nr": "Conversatia 2",
							"__text": "Calculul mediei aritmerice a trei valori. Enunturi de intrare/iesire si atribuire aritmetica. Variabile si constante numerice"
						},
						{
							"_nr": "Conversatia 3",
							"__text": "Calculul mediei aritmetice a trei valori pentru mai multe articole. Cicluri: iteratii"
						},
						{
							"_nr": "Conversatia 4",
							"__text": "Calculul mediei artimetice a unui numar variabil de valori pentur mai multe articole. Tablouri, vectori. Bucla DO. TESTE pentru cititor: o schema logica, nu program , prima depanare"
						},
						{
							"_nr": "Conversatia 5",
							"__text": "Calculul mediei artimetice a unui numar variabil de valori nenule pentru mai multe articole. Decizii-selectii. Cicluri in cicluri. TEST pentru cititor"
						},
						{
							"_nr": "Conversatia 5 (continuare)",
							"__text": "Depanarea unui program. TESTE pentru cititor: depistarea unei erori de impartire cu zero si a uneia de tiparire"
						},
						{
							"_nr": "Conversatia 6",
							"__text": "Sortarea unui sir de valori. Enunturi de initializare a datelor"
						},
						{
							"_nr": "Conversatia 6 (continuare)",
							"__text": "Localizarea si corectarea erorilor semnalate la executia programelor. Simularea functionarii unui program"
						},
						{
							"_nr": "Conversatia 7",
							"__text": "Sortari complexe. Clasamente Expresii logice"
						},
						{
							"_nr": "Conversatia 8",
							"__text": "Prelucrari statistice in FORTRAN. Conceptia modulara a programelor. Subprograme de tip SUBROUTINE"
						},
						{
							"_nr": "Conversatia 9",
							"__text": "Abaterea standard in analiza statistica. Subprograme de tip FUNCTION. Functii standard FORTRAN"
						},
						{
							"_nr": "Conversatia 10",
							"__text": "Prelucrari statistice pentru un volum mare de date. Utilizarea fisierelor pe benzi magnetice. Enuntul DEFINE FILE"
						},
						{
							"_nr": "Conversatia 10 (continuare)",
							"__text": "Sapte rulari succesive cu erorile lor. Tehnici speciale de depnare. TEST pentru cititor: depistarea unor erori"
						},
						{
							"_nr": "Conversatia 11",
							"__text": "Determinarea radacinilor reale dintr-un interval ale ecuatiilor polinomiale. TESTE pentru citiror privind scheme logice si programe modulare"
						},
						{
							"_nr": "Conversatia 12",
							"__text": "Rezolvarea sistemelor de ecuatii liniare. TEST pentru cititor: depnare prin vidaj"
						},
						{
							"_nr": "Conversatia 13",
							"__text": "Precizie dubla in FORTRAN. Fisiere pe discuri magnetice. Intrari/iesiri fara FORMAT"
						},
						{
							"_nr": "Conversatia 14",
							"__text": "Calcule matriciale de mari dimensiuni. Restrictii in dimensionarea programelor. Structuri arbrorescente"
						},
						{
							"_nr": "Conversatia 15",
							"__text": "Fisiere in acces direct. Enuntul EQUIVALENCE. Dimensionarea variabila a tablourilor in subprograme. Optimizarea folosirii fisierelor de manevra"
						},
						{
							"_nr": "Conversatia 16",
							"__text": "TEST FINAL pentru cititor: analiza conceptiei unui program. Implementarea top-down in programarea structurata, folosind un limbaj pseudocod"
						}
					]
				},
				"_id": "689",
				"_isbn": ""
			},
			{
				"titlu": "Invatam FORTRAN...conversand cu calculatorul (Vol.2)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Dumitrascu"
						},
						{
							"_nume": "Stefan Patrut"
						},
						{
							"_nume": "Stefan Stan"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "256",
				"pretOriginal": "10",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatam_FOTRAN_conversand_cu_calculatorul_Vol2_Dumitrascu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Sinteza 17",
							"__text": "Complemente informatice"
						},
						{
							"_nr": "Sinteza 18",
							"__text": "Memento al elementelor prezentate ale limbajului FORTRAN"
						},
						{
							"_nr": "Sinteza 19",
							"__text": "Mesaje de erori in FORTRAN"
						},
						{
							"_nr": "Sinteza 20",
							"__text": "Probleme si rezultatele prelucrarilor pentru exemplele si temele din conversatiile"
						},
						{
							"_nr": "Sinteza 21",
							"__text": "Raspunsuri la exercitiile din conversatii"
						},
						{
							"_nr": "Sinteza 22",
							"__text": "Comentarii bibliografice"
						}
					]
				},
				"_id": "690",
				"_isbn": ""
			},
			{
				"titlu": "Calcul simbolic cu Maple",
				"autori": {
					"autor": {
						"_nume": "Valeriu Anisiu"
					}
				},
				"anAparitie": "2006",
				"editura": "Presa Univeritara Clujeana",
				"numarPagini": "300",
				"pretOriginal": "77000",
				"pretCumparare": "77000",
				"coperta": {
					"imagine": {
						"_path": "Calcul_simbolic_cu_Maple_Anisiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Maple pe scurt"
						},
						{
							"_nr": "2",
							"__text": "Sistemul Maple"
						},
						{
							"_nr": "3",
							"__text": "Expresii si evaloarea lor in Maple"
						},
						{
							"_nr": "4",
							"__text": "Operatori"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni"
						},
						{
							"_nr": "6",
							"__text": "Functii matematice uzuale"
						},
						{
							"_nr": "7",
							"__text": "Simplificare expresiilor"
						},
						{
							"_nr": "8",
							"__text": "Utilizarea structurilor Maple fundamentale"
						},
						{
							"_nr": "9",
							"__text": "Prelucrari matematice"
						},
						{
							"_nr": "10",
							"__text": "Grafice 2D si 3D"
						},
						{
							"_nr": "11",
							"__text": "Fisiere in Maple"
						},
						{
							"_nr": "12",
							"__text": "Executia si depanarea programelor"
						},
						{
							"_nr": "13",
							"__text": "Probleme rezolvate"
						}
					]
				},
				"_id": "691",
				"_isbn": "973-610-451-6"
			},
			{
				"titlu": "Introducere in programarea logica (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Luminita State"
					}
				},
				"anAparitie": "2007",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "276",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programarea_logica_State.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajul calcului cu propozitii"
						},
						{
							"_nr": "2",
							"__text": "Limbaje de prim ordin"
						},
						{
							"_nr": "3",
							"__text": "Elemente de programare logica"
						}
					]
				},
				"_id": "692",
				"_isbn": "978-973-163-087-8"
			},
			{
				"titlu": "ActionScript 3.0: programare Web in Flash si Flex",
				"autori": {
					"autor": {
						"_nume": "Cosmin Varlan"
					}
				},
				"anAparitie": "2009",
				"editura": "Polirom",
				"numarPagini": "432",
				"pretOriginal": "429500",
				"pretCumparare": "425900",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "ActionScript3_Programare_Web_in_Flash_si_Flex_Varlan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prima aplicatie RIA"
						},
						{
							"_nr": "2",
							"__text": "Fundamentele limbajului ActionScript 3"
						},
						{
							"_nr": "3",
							"__text": "Pachetul de baza (Top Level)"
						},
						{
							"_nr": "4",
							"__text": "Urilizarea evenimentelor"
						},
						{
							"_nr": "5",
							"__text": "Elemente grafice"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea obiectelor textuale"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea filtrelor"
						},
						{
							"_nr": "8",
							"__text": "Multimedia"
						},
						{
							"_nr": "9",
							"__text": "Conectivitate in AS3"
						},
						{
							"_nr": "10",
							"__text": "Crearea unui joc in AS3"
						}
					]
				},
				"_id": "693",
				"_isbn": "978-973-46-1538-1"
			},
			{
				"titlu": "C# pentru liceu: programare in Visual C# 2008 Express Edition",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Galatan"
						},
						{
							"_nume": "Susana Galatan"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "L and S InfoMat",
				"numarPagini": "352",
				"pretOriginal": "310000",
				"pretCumparare": "310000",
				"coperta": {
					"imagine": {
						"_path": "C#_pentru_liceu_programare_in_Visual_C#_2008_express_Edition_Galatan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "C# si platforma .NET"
						},
						{
							"_nr": "2",
							"__text": "Limbajul C#. Introducere"
						},
						{
							"_nr": "3",
							"__text": "Fundamentele limbajului C#"
						},
						{
							"_nr": "4",
							"__text": "Programrea orientata pe obiecte in C#"
						},
						{
							"_nr": "5",
							"__text": "Trasaturi esentiale ale limbajului C#"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii de tip Windows Forms"
						},
						{
							"_nr": "7",
							"__text": "Controale Windows Forms"
						},
						{
							"_nr": "8",
							"__text": "Desenare in .NET cu Visual C#"
						},
						{
							"_nr": "9",
							"__text": "XML cu C#"
						},
						{
							"_nr": "10",
							"__text": "Baze de date si ADO.NET. Notiuni introductive"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii cu baze de date in modelul conectat"
						},
						{
							"_nr": "12",
							"__text": "Aplicatii cu baze de date in modelul neconectat"
						},
						{
							"_nr": "13",
							"__text": "Relatii intre tabele"
						}
					]
				},
				"_id": "694",
				"_isbn": "978-973-7558-16-6"
			},
			{
				"titlu": "Bazele programarii in Java",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Sorin"
						},
						{
							"_nume": "Vlad Hutan"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "L and S InfoMat",
				"numarPagini": "296",
				"pretOriginal": "230000",
				"pretCumparare": "230000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_programarii_in_Java_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Java, primele notiuni"
						},
						{
							"_nr": "2",
							"__text": "Tipuri si operatii de date"
						},
						{
							"_nr": "3",
							"__text": "Metode"
						},
						{
							"_nr": "4",
							"__text": "Clase - primele notiuni, exemple"
						},
						{
							"_nr": "5",
							"__text": "Studiul unor clase ale limbejului Java"
						},
						{
							"_nr": "6",
							"__text": "Extinderea claselor"
						},
						{
							"_nr": "7",
							"__text": "Notiuni avansate de programare orientata pe obiecte"
						},
						{
							"_nr": "8",
							"__text": "Exceptii"
						},
						{
							"_nr": "9",
							"__text": "Alte clase esentiale in Java"
						},
						{
							"_nr": "10",
							"__text": "Initiere in programarea vizuala"
						},
						{
							"_nr": "11",
							"__text": "Applet-uri"
						}
					]
				},
				"_id": "695",
				"_isbn": "978-973-7558-03-5"
			},
			{
				"titlu": "ASRS_RL: sistem de recunostere a vorbirii pentru limba romana",
				"autori": {
					"autor": {
						"_nume": "Corneliu-Octavian Dumitru"
					}
				},
				"anAparitie": "2009",
				"editura": "Politehnica Press",
				"numarPagini": "204",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "ASRS_RL_Sistem_de_recunostere_a_vorbirii_pentru_limba_romana_Dumitru.jpg"
					},
					"__text": "Adriana Butmalai"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Semnalul vocal"
						},
						{
							"_nr": "II",
							"__text": "Tehnici de recunoastere a vorbirii"
						},
						{
							"_nr": "III",
							"__text": "Baze de date utilizate in recunosterea vorbirii"
						},
						{
							"_nr": "IV",
							"__text": "Sistemul ASRS_RL"
						},
						{
							"_nr": "V",
							"__text": "Rezultate experimentale"
						},
						{
							"_nr": "VI",
							"__text": "Concluzii"
						},
						{
							"_nr": "Anexa A",
							"__text": "Inventarul fonemelor"
						},
						{
							"_nr": "Anexa B",
							"__text": "Caracteristicile baze de date CDRL"
						},
						{
							"_nr": "Anexa C",
							"__text": "Paramentrii MMA"
						},
						{
							"_nr": "Anexa D",
							"__text": "Arbori fonetici de decizie"
						},
						{
							"_nr": "Anexa E",
							"__text": "Pachetul de programe"
						}
					]
				},
				"_id": "696",
				"_isbn": "973-606-515-027-0"
			},
			{
				"titlu": "Structuri de date:Tipologii de structuri de date (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Ivan"
						},
						{
							"_nume": "Marius Popa"
						},
						{
							"_nume": "Paul Pocatiu"
						},
						{
							"_nume": "Catalin Boja"
						},
						{
							"_nume": "Cristian Ionita"
						},
						{
							"_nume": "Cristian Toma"
						},
						{
							"_nume": "Mihaela Munteanu"
						},
						{
							"_nume": "Cristian Bologa"
						},
						{
							"_nume": "Adrian Visoiu"
						},
						{
							"_nume": "Felician Alecu"
						},
						{
							"_nume": "Vitalie Cotelea"
						},
						{
							"_nume": "Gheorghe Nosca"
						},
						{
							"_nume": "Eugen Popovici"
						},
						{
							"_nume": "Mihai Doinea"
						},
						{
							"_nume": "Romica Avram"
						},
						{
							"_nume": "Cristian Amancei"
						},
						{
							"_nume": "Milodin Daniel"
						},
						{
							"_nume": "Bogdan Zurbagiu"
						},
						{
							"_nume": "George Matei"
						},
						{
							"_nume": "Dragos Palaghita"
						},
						{
							"_nume": "Sergiu Capisizu"
						},
						{
							"_nume": "Paul Balanoiu"
						},
						{
							"_nume": "Traian Badica"
						},
						{
							"_nume": "Biatrice Ambsosa"
						},
						{
							"_nume": "Nicolae Apostol"
						},
						{
							"_nume": "Roland Dragoi"
						},
						{
							"_nume": "Cosmin Ivan"
						},
						{
							"_nume": "Mihai Marinescu"
						},
						{
							"_nume": "Zsolt Mark"
						},
						{
							"_nume": "Raluca Vasilache"
						},
						{
							"_nume": "Silviu Niculescu"
						},
						{
							"_nume": "Lucian Nae"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "ASE",
				"numarPagini": "770",
				"pretOriginal": "308000",
				"pretCumparare": "308000",
				"redactori": {
					"redactor": "Luiza Constantinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Emilia Velcu"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_Vol1_Ivan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tipuri de date si date elementare"
						},
						{
							"_nr": "2",
							"__text": "Programare orientata pe obiect"
						},
						{
							"_nr": "3",
							"__text": "Masive - structuri de date omogene"
						},
						{
							"_nr": "4",
							"__text": "Functii de prelucrare masive"
						},
						{
							"_nr": "5",
							"__text": "Matrice rara"
						},
						{
							"_nr": "6",
							"__text": "Articolul - structura de date neomogena si contigua"
						},
						{
							"_nr": "7",
							"__text": "Variabile pointer"
						},
						{
							"_nr": "8",
							"__text": "Reuniuni de date continue"
						},
						{
							"_nr": "9",
							"__text": "Listele - structuri dinamice necontigue"
						},
						{
							"_nr": "10",
							"__text": "Stive si cozi"
						},
						{
							"_nr": "11",
							"__text": "Arbori binari si arbori de cautare"
						},
						{
							"_nr": "12",
							"__text": "Arbori B"
						},
						{
							"_nr": "13",
							"__text": "Arbori echilibrati"
						},
						{
							"_nr": "14",
							"__text": "Heap"
						},
						{
							"_nr": "15",
							"__text": "Grafuri"
						},
						{
							"_nr": "16",
							"__text": "Tabele de dispersie"
						},
						{
							"_nr": "17",
							"__text": "Fisiere"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Functii de prelucrare a structurilor de date"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Determinarea stabilitatii domeniului Structuri de date"
						}
					]
				},
				"_id": "697",
				"_isbn": "978-606-505-031-0"
			},
			{
				"titlu": "Structuri de date:Managementul structurilor de date (Vol.2)",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Ivan"
						},
						{
							"_nume": "Marius Popa"
						},
						{
							"_nume": "Paul Pocatiu"
						},
						{
							"_nume": "Catalin Boja"
						},
						{
							"_nume": "Cristian Ionita"
						},
						{
							"_nume": "Cristian Toma"
						},
						{
							"_nume": "Mihaela Munteanu"
						},
						{
							"_nume": "Cristian Bologa"
						},
						{
							"_nume": "Adrian Visoiu"
						},
						{
							"_nume": "Felician Alecu"
						},
						{
							"_nume": "Vitalie Cotelea"
						},
						{
							"_nume": "Gheorghe Nosca"
						},
						{
							"_nume": "Eugen Popovici"
						},
						{
							"_nume": "Mihai Doinea"
						},
						{
							"_nume": "Romica Avram"
						},
						{
							"_nume": "Cristian Amancei"
						},
						{
							"_nume": "Milodin Daniel"
						},
						{
							"_nume": "Bogdan Zurbagiu"
						},
						{
							"_nume": "George Matei"
						},
						{
							"_nume": "Dragos Palaghita"
						},
						{
							"_nume": "Sergiu Capisizu"
						},
						{
							"_nume": "Paul Balanoiu"
						},
						{
							"_nume": "Traian Badica"
						},
						{
							"_nume": "Biatrice Ambsosa"
						},
						{
							"_nume": "Nicolae Apostol"
						},
						{
							"_nume": "Roland Dragoi"
						},
						{
							"_nume": "Cosmin Ivan"
						},
						{
							"_nume": "Mihai Marinescu"
						},
						{
							"_nume": "Zsolt Mark"
						},
						{
							"_nume": "Raluca Vasilache"
						},
						{
							"_nume": "Silviu Niculescu"
						},
						{
							"_nume": "Lucian Nae"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "ASE",
				"numarPagini": "534",
				"pretOriginal": "154000",
				"pretCumparare": "154000",
				"redactori": {
					"redactor": "Luiza Constantinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Emilia Velcu"
				},
				"coperta": {
					"imagine": {
						"_path": "Structuri_de_date_Vol2_Ivan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Expresii de definire si referire ale structurilor de date"
						},
						{
							"_nr": "2",
							"__text": "Standard Template Library - STL"
						},
						{
							"_nr": "3",
							"__text": "Conversii ale structurilor de date"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea structurilor de date in clonarea informatica"
						},
						{
							"_nr": "5",
							"__text": "Compactarea si compresia datelor"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea structurilor de date in compresia datelor"
						},
						{
							"_nr": "7",
							"__text": "Procese de agregare software"
						},
						{
							"_nr": "8",
							"__text": "Utilizarea structurilor de date in testarea software"
						},
						{
							"_nr": "9",
							"__text": "Managementul calitatii datelor si a structurilor de date"
						},
						{
							"_nr": "10",
							"__text": "Structuri de date si acces in baze de date"
						},
						{
							"_nr": "11",
							"__text": "Structuri folosite in procese de cautare"
						},
						{
							"_nr": "12",
							"__text": "Structuri de date in securizarea informatiei"
						},
						{
							"_nr": "13",
							"__text": "Complexitatea structurilor de date"
						},
						{
							"_nr": "14",
							"__text": "Gradul de ocupare a zoneo de memorie"
						},
						{
							"_nr": "15",
							"__text": "Structuri de text uniform"
						},
						{
							"_nr": "16",
							"__text": "Inspectia software"
						},
						{
							"_nr": "17",
							"__text": "Modelul de date XML"
						},
						{
							"_nr": "18",
							"__text": "Bilete de examen date in anii trecuti"
						},
						{
							"_nr": "19",
							"__text": "Subiecte propuse"
						}
					]
				},
				"_id": "698",
				"_isbn": "978-606-505-033-4"
			},
			{
				"titlu": "Proiectarea paginilor WEB dinamice utilizand PHP",
				"autori": {
					"autor": {
						"_nume": "Florentin Rdica Niculescu"
					}
				},
				"anAparitie": "2009",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "122",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Roxana Ene"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marcela Olaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_paginilor_WEB_dinamice_utilizand_PHP_Niculescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemenete de baza ale limbajului PHP"
						},
						{
							"_nr": "2",
							"__text": "Variabile, operatori si instructiuni"
						},
						{
							"_nr": "3",
							"__text": "Functii"
						},
						{
							"_nr": "4",
							"__text": "Siruri de caractere si tablouri in PHP"
						},
						{
							"_nr": "5",
							"__text": "Transmiterea datelor din formulare HTML catre server"
						},
						{
							"_nr": "6",
							"__text": "Fisiere"
						},
						{
							"_nr": "7",
							"__text": "Grafica in PHP"
						}
					]
				},
				"_id": "699",
				"_isbn": "978-973-163-489-0"
			},
			{
				"titlu": "Limbaje formale si automate (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Grigore Albeanu"
					}
				},
				"anAparitie": "2009",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "128",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Marian Bolintis"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_atomate_Editia_II_Albeanu.jpg"
					},
					"__text": "Cornelia Prodan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbaje si expresii regulate"
						},
						{
							"_nr": "2",
							"__text": "Mecanisme generative fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Mecanisme pentru recunoasterea automata a multimilor regulate"
						},
						{
							"_nr": "4",
							"__text": "Optimizara automatelor finite"
						},
						{
							"_nr": "5",
							"__text": "Transformari asupra gramaticilor formale"
						},
						{
							"_nr": "6",
							"__text": "Forme normale. Lema Bar-Hillel"
						},
						{
							"_nr": "7",
							"__text": "Gramatici si automate"
						},
						{
							"_nr": "8",
							"__text": "Proprietati de inchidere"
						},
						{
							"_nr": "9",
							"__text": "Specificarea sintaxei limbajelor"
						},
						{
							"_nr": "10",
							"__text": "Introducere in analiza sintactica"
						},
						{
							"_nr": "11",
							"__text": "Exercitii recapitulare"
						}
					]
				},
				"_id": "700",
				"_isbn": "978-973-163-468-5"
			},
			{
				"titlu": "Grafica 3D in OpenGL",
				"autori": {
					"autor": [
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Marius Dorian Zaharia"
						},
						{
							"_nume": "Zoea Racovita"
						},
						{
							"_nume": "Irina Georgecu Mocanu"
						},
						{
							"_nume": "Catalin Tudose"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Printech",
				"numarPagini": "155",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Grafica_3D_in_OpenGL_2004_Moldoveanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conventii de numire a functiilor, constantelor si tipurilor de date OpenGL"
						},
						{
							"_nr": "2",
							"__text": "Functii FLUT de realizare a interfetei cu utilizatorul"
						},
						{
							"_nr": "3",
							"__text": "Exceutia aplicatiei"
						},
						{
							"_nr": "4",
							"__text": "Crearea unui aplicatii OpenGL folosind GLUT"
						},
						{
							"_nr": "5",
							"__text": "Afisarea obiectelor 3D predefinite"
						},
						{
							"_nr": "6",
							"__text": "Specificarea culorilor"
						},
						{
							"_nr": "7",
							"__text": "Definirea primitivelor geometrice"
						},
						{
							"_nr": "8",
							"__text": "Specificarea atributelor de afisare ale primitivelor geometrice"
						},
						{
							"_nr": "9",
							"__text": "Transformari ale obiectelor 3D in OpenGL"
						},
						{
							"_nr": "10",
							"__text": "Iluminarea"
						},
						{
							"_nr": "11",
							"__text": "Liste de afisare"
						},
						{
							"_nr": "12",
							"__text": "Grafica raster in OpenGL"
						},
						{
							"_nr": "13",
							"__text": "Aplicarea texturilor pe suprafetele obiectelor"
						},
						{
							"_nr": "14",
							"__text": "Evaluatori OpenGL"
						},
						{
							"_nr": "15",
							"__text": "Tehnici de vizualizare realista in OpenGL"
						},
						{
							"_nr": "16",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "701",
				"_isbn": "973-652-423-X"
			},
			{
				"titlu": "Concursuri de informatica: probleme propuse",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "1995",
				"editura": "Petrion",
				"numarPagini": "192",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Victor Mitrana"
				},
				"tehnoredactori": {
					"tehnoredactor": "Laura Clapon"
				},
				"coperta": {
					"imagine": {
						"_path": "Concursuri_de_informatica_Probleme_propuse_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cuvant inainte"
						},
						{
							"_nr": "2",
							"__text": "Olimpiada nationala de informatica, etapa judeteana"
						},
						{
							"_nr": "3",
							"__text": "Etapa finala"
						},
						{
							"_nr": "4",
							"__text": "Baraje pentru lotul national largit"
						},
						{
							"_nr": "5",
							"__text": "Olimpiada internationala din Europa Centrala"
						},
						{
							"_nr": "6",
							"__text": "Concursul 'Avram Iancu', Cluj"
						},
						{
							"_nr": "7",
							"__text": "Concursul republican, Chisinau"
						},
						{
							"_nr": "8",
							"__text": "Olimpiada Internationala de Informatica"
						},
						{
							"_nr": "9",
							"__text": "A doua Balcaniada de Informatica"
						},
						{
							"_nr": "10",
							"__text": "Concursuri studentesti de informatica"
						}
					]
				},
				"_id": "702",
				"_isbn": "973-9116-17-5"
			},
			{
				"titlu": "CorelDraw 4.0, 5.0 : grafica cu calculatorul",
				"autori": {
					"autor": {
						"_nume": "Codruta Poenaru"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "244",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"colectia": {
					"_numarul": "43",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "CorelDRAW_4_0_5_0_grafica_cu_calculatorul_Poenaru.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "CorelDraw 4.0"
						},
						{
							"_nr": "1",
							"__text": "Informatii generale"
						},
						{
							"_nr": "2",
							"__text": "Configurarea CorelDraw-ului"
						},
						{
							"_nr": "3",
							"__text": "Modul de lucru selectie"
						},
						{
							"_nr": "4",
							"__text": "Modul de lucru curba"
						},
						{
							"_nr": "5",
							"__text": "Modul de lucru zoom"
						},
						{
							"_nr": "6",
							"__text": "Modul de lucru liber"
						},
						{
							"_nr": "7",
							"__text": "Modul de lucru dreptunghi"
						},
						{
							"_nr": "8",
							"__text": "Modul de lucru elipsa"
						},
						{
							"_nr": "9",
							"__text": "Modul de lucru text"
						},
						{
							"_nr": "10",
							"__text": "Modul de lucru contur"
						},
						{
							"_nr": "11",
							"__text": "Modul de lucru umplere"
						},
						{
							"_nr": "12",
							"__text": "Transformari geometrice aplicate obiectelor"
						},
						{
							"_nr": "13",
							"__text": "Aranjarea obiectelor"
						},
						{
							"_nr": "14",
							"__text": "Efecte speciale"
						},
						{
							"_nr": "15",
							"__text": "Manipulari de obicete intre diverse aplicatii"
						},
						{
							"_nr": "16",
							"__text": "Gestiunea si listarea fisierelor"
						},
						{
							"_nr": "17",
							"__text": "Prelucrarea hartilor de biti"
						},
						{
							"_nr": "18",
							"__text": "Baze de date asociate graficelor"
						},
						{
							"_nr": "Anexa A",
							"__text": "Lista comenzilor"
						},
						{
							"_nr": "Anexa B",
							"__text": "Lista modurilor de lucru"
						},
						{
							"_nr": "Anexa C",
							"__text": "Lista ferestrelor de rulare"
						},
						{
							"_nr": "II",
							"__text": "CorelDraw 5.0"
						},
						{
							"_nr": "1",
							"__text": "Noutati in versiunea 5.0"
						},
						{
							"_nr": "Anexa A",
							"__text": "Lista comenzilor"
						},
						{
							"_nr": "Anexa B",
							"__text": "Lista modurilor de lucru"
						},
						{
							"_nr": "Anexa C",
							"__text": "Lista ferestrelor de rulare"
						}
					]
				},
				"_id": "703",
				"_isbn": "973-9215-00-9"
			},
			{
				"titlu": "Programarea mouse-ului",
				"autori": {
					"autor": {
						"_nume": "Vlad Caprariu"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "64",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "42",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_mouse_ului_Caprariu.jpg"
					},
					"__text": "Liviu Deversteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea detaliata a functiilor intreruperi 33h"
						},
						{
							"_nr": "3",
							"__text": "Biblioteca de interfata cu registrii EGA (EGA RIL)"
						}
					]
				},
				"_id": "704",
				"_isbn": "973-9215-02-5"
			},
			{
				"titlu": "DOS 6.2 Memento (Editia II)",
				"autori": {
					"autor": [
						{
							"_nume": "Kovacs Sandor"
						},
						{
							"_nume": "Kovacs Antonia"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "88",
				"pretOriginal": "3640",
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "DOS_2_0_Memento_Editia_II_Kovacs.jpg"
					},
					"__text": "Liviu Deversteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Comenzi MS-DOS Versiunea 6.2"
						},
						{
							"_nr": "2",
							"__text": "Comenzi utilizabile in fisiere de comenzi"
						},
						{
							"_nr": "3",
							"__text": "Comenzile de configurare (fisierul CONFIG.SYS)"
						},
						{
							"_nr": "4",
							"__text": "Drivere"
						}
					]
				},
				"_id": "705",
				"_isbn": "973-9195-06-8"
			},
			{
				"titlu": "Un PC pentru fiecare",
				"autori": {
					"autor": [
						{
							"_nume": "Kovacs Sandor"
						},
						{
							"_nume": "Kovacs Antonia"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "3000",
				"numarPagini": "248",
				"pretOriginal": "3990",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "3",
					"_nume": "Initiere PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Un_PC_pentru_fiecare_Kovacs.jpg"
					},
					"__text": "Liviu Deversteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatorul personal"
						},
						{
							"_nr": "2",
							"__text": "Linia de comanda"
						},
						{
							"_nr": "3",
							"__text": "Fisiere"
						},
						{
							"_nr": "4",
							"__text": "Directoare"
						},
						{
							"_nr": "5",
							"__text": "Discuri"
						},
						{
							"_nr": "6",
							"__text": "Gestiunea discurilor fixe"
						},
						{
							"_nr": "7",
							"__text": "Comenzi speciale"
						},
						{
							"_nr": "8",
							"__text": "Editorul incorporat in DOS 5.0"
						},
						{
							"_nr": "9",
							"__text": "Fisiere de comenzi"
						},
						{
							"_nr": "10",
							"__text": "Lansarea sistemelor de operare"
						},
						{
							"_nr": "11",
							"__text": "Optimizarea sistemului"
						},
						{
							"_nr": "12",
							"__text": "Comenzi DOS 5.0"
						},
						{
							"_nr": "13",
							"__text": "Comenzile utilizabile la fisiere de comenzi"
						},
						{
							"_nr": "14",
							"__text": "Comenzile de configurare"
						},
						{
							"_nr": "15",
							"__text": "Drivere"
						},
						{
							"_nr": "16",
							"__text": "Rapidizarea discurilor"
						},
						{
							"_nr": "17",
							"__text": "Arhivarea fisierelor"
						},
						{
							"_nr": "18",
							"__text": "Virusii calculatoarelor"
						}
					]
				},
				"_id": "706",
				"_isbn": "973-9171-05-2"
			},
			{
				"titlu": "Calculul paralel: de la sisteme la programarea aplicatiilor",
				"autori": {
					"autor": {
						"_nume": "Dan Grigoras"
					}
				},
				"anAparitie": "2000",
				"editura": "Computer Libris Agora",
				"numarPagini": "376",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Calcul_Paralel_De_la_sisteme_la_programarea_aplicatiilor_Grigoras.jpg"
					},
					"__text": "Andrei Pasa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Arhitecturi paralele de calcul"
						},
						{
							"_nr": "I",
							"__text": "De la arhitecturi paralele la modele de calcul paralel"
						},
						{
							"_nr": "II",
							"__text": "Parametrii de performanta ai sistemelor paralele"
						},
						{
							"_nr": "III",
							"__text": "Procesorul"
						},
						{
							"_nr": "IV",
							"__text": "Retele de interconectare"
						},
						{
							"_nr": "V",
							"__text": "Sisteme paralele si distribuite"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Algoritmi si limbaje"
						},
						{
							"_nr": "VI",
							"__text": "Strategii pentru crearea programelor paralele"
						},
						{
							"_nr": "VII",
							"__text": "Algoritmi paraleli numerici"
						},
						{
							"_nr": "VIII",
							"__text": "Algoritmi paraleli nenumerici"
						},
						{
							"_nr": "IX",
							"__text": "Programarea paralela"
						},
						{
							"_nr": "X",
							"__text": "Planificarea proceselor paralele"
						}
					]
				},
				"_id": "707",
				"_isbn": "973-97534-6-9"
			},
			{
				"titlu": "Un atelier de programare",
				"autori": {
					"autor": {
						"_nume": "Cristian Giumale"
					}
				},
				"anAparitie": "2000",
				"editura": "Computer Libris Agora",
				"numarPagini": "382",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Un_atelier_de_programare_Giumale.jpg"
					},
					"__text": "Mircea Dragoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme de colorare"
						},
						{
							"_nr": "2",
							"__text": "Combinari, safari si acadele"
						},
						{
							"_nr": "3",
							"__text": "Drumetie cu cost minim"
						},
						{
							"_nr": "4",
							"__text": "Cu gandul la Jules Verne"
						},
						{
							"_nr": "5",
							"__text": "O societate cu constrangeri"
						},
						{
							"_nr": "6",
							"__text": "Probleme cu grafuri"
						},
						{
							"_nr": "7",
							"__text": "Generatori cu efort distribuit"
						},
						{
							"_nr": "8",
							"__text": "Autostrazi, tunele si descompunerea problemelor"
						},
						{
							"_nr": "9",
							"__text": "Numere multe"
						},
						{
							"_nr": "10",
							"__text": "Modele cu placute"
						},
						{
							"_nr": "Anexa A",
							"__text": "Notatii de complexitate"
						},
						{
							"_nr": "Anexa B",
							"__text": "Probleme de concurs"
						},
						{
							"_nr": "Anexa C",
							"__text": "Limbaje de programare utilizate in carte"
						}
					]
				},
				"_id": "708",
				"_isbn": "973-97534-2-6"
			},
			{
				"titlu": "Indrumator de limbaje de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Jitaru"
						},
						{
							"_nume": "Casimir Macarie"
						},
						{
							"_nume": "Stelian Niculescu"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Tehnica",
				"tiraj": "37500+65 exemplare legate",
				"numarPagini": "348",
				"pretOriginal": "10",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Creon"
				},
				"coperta": {
					"imagine": {
						"_path": "Indrumator_de_limbaje_de_programare_Niculescu.jpg"
					},
					"__text": "Alexandru Banu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Caracteristici tehnice ale limbajelor de programare"
						},
						{
							"_nr": "3",
							"__text": "Limbaje de asamblare"
						},
						{
							"_nr": "4",
							"__text": "Limbaje pentru rezolvarea problemelor tehnico-stiintifice"
						},
						{
							"_nr": "5",
							"__text": "Limbaje pentru probleme de gestiune"
						},
						{
							"_nr": "6",
							"__text": "Limbajul PL/I"
						},
						{
							"_nr": "7",
							"__text": "Limbaje de macrogenerare"
						},
						{
							"_nr": "8",
							"__text": "Limbaje conversationale"
						},
						{
							"_nr": "9",
							"__text": "Limbaje specializate"
						},
						{
							"_nr": "10",
							"__text": "Testarea combinata a limbajelor ASSIRIS, COBOL, FORTRAN"
						},
						{
							"_nr": "11",
							"__text": "Testarea si punerea la punct a programelor"
						}
					]
				},
				"_id": "709",
				"_isbn": ""
			},
			{
				"titlu": "Programe in limbajul FORTRAN",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Octavian Basca"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Albatros",
				"tiraj": "32300",
				"numarPagini": "330",
				"pretOriginal": "6",
				"colectia": {
					"_numarul": "184",
					"_nume": "Lyceum"
				},
				"tehnoredactori": {
					"tehnoredactor": "Cornel Cristescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programe_in_limbajul_FORTRAN_Georgescu.jpg"
					},
					"__text": "Marina Georgescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Cuvant inainte"
						},
						{
							"_nr": "II",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Ce este informatica"
						},
						{
							"_nr": "2",
							"__text": "Istoricul dezvoltarii mijloacelor de prelucrare a datelor"
						},
						{
							"_nr": "3",
							"__text": "Dezvoltarea informaticii in tara noastra"
						},
						{
							"_nr": "4",
							"__text": "generalitati despre calculatoarele electronice"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi"
						},
						{
							"_nr": "6",
							"__text": "Scheme logice"
						},
						{
							"_nr": "7",
							"__text": "Scurta prezentare a limbajului de programare FORTRAN IV"
						},
						{
							"_nr": "8",
							"__text": "Cum se prezinta problemele in aceasta carte"
						},
						{
							"_nr": "9",
							"__text": "Ce inseamna un program bun"
						},
						{
							"_nr": "III",
							"__text": "Programe"
						},
						{
							"_nr": "IV",
							"__text": "Rezultatele exercitiilor"
						}
					]
				},
				"_id": "710",
				"_isbn": ""
			},
			{
				"titlu": "C++. Tehnologia orientata spre obiecte. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Mircea Popovici"
						},
						{
							"_nume": "Ioan Mircea Popovici"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "488",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "298",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_Tehnologia_orientata_spre_obiecte_aplicatii_Popovici_2002.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Bazele in C/C++"
						},
						{
							"_nr": "1",
							"__text": "Introducere in C++"
						},
						{
							"_nr": "2",
							"__text": "Fundamentele limbajului C++.Partea I"
						},
						{
							"_nr": "3",
							"__text": "Fundamentele limbajului C++.Partea II"
						},
						{
							"_nr": "4",
							"__text": "Functii in C/C++"
						},
						{
							"_nr": "5",
							"__text": "Structuri si uniuni"
						},
						{
							"_nr": "II",
							"__text": "Programarea orientata spre obiecte"
						},
						{
							"_nr": "6",
							"__text": "Fundamentele orientarii spre obiecte"
						},
						{
							"_nr": "7",
							"__text": "Clase"
						},
						{
							"_nr": "8",
							"__text": "Crearea si initializarea obiectelor"
						},
						{
							"_nr": "9",
							"__text": "Despre elementele claselor"
						},
						{
							"_nr": "10",
							"__text": "Mostenirea si derivarea claselor"
						},
						{
							"_nr": "11",
							"__text": "Operatorii ca functii"
						},
						{
							"_nr": "12",
							"__text": "Solutia template"
						},
						{
							"_nr": "13",
							"__text": "Concepte avansate ale orientarii spre obiecte"
						},
						{
							"_nr": "14",
							"__text": "Transpunerea conceptelor OO in C++"
						},
						{
							"_nr": "15",
							"__text": "Implementarea tipurilor de date oriectate spre obiecte"
						},
						{
							"_nr": "III",
							"__text": "Sinteze si raspunsuri"
						},
						{
							"_nr": "13",
							"__text": "Sinteza prin exercitii"
						},
						{
							"_nr": "14",
							"__text": "Solutii si raspunsuri"
						},
						{
							"_nr": "IV",
							"__text": "Anexe"
						},
						{
							"_nr": "Anexa A",
							"__text": "Intrari si iesiri (standard)"
						},
						{
							"_nr": "Anexa B",
							"__text": "Intrari si iesiri (stream)"
						},
						{
							"_nr": "Anexa C",
							"__text": "Preprocesorul C++"
						},
						{
							"_nr": "Anexa D",
							"__text": "Functii video"
						},
						{
							"_nr": "Anexa E"
						}
					]
				},
				"_id": "711",
				"_isbn": "973-20-0320-0"
			},
			{
				"titlu": "Windows 3.1:Utilizare",
				"autori": {
					"autor": {
						"_nume": "Florin Vladimit Pilat"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "240",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "30",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_3_1_Utilizare_Pilat.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea interfetei grafice Windows 3.1, moduri de functionare"
						},
						{
							"_nr": "2",
							"__text": "Bazele functionarii interfetei grafice Windows 3.1"
						},
						{
							"_nr": "3",
							"__text": "Bazele functionarii programelor de aplicatie"
						},
						{
							"_nr": "4",
							"__text": "Gestiunea programelor cu aplicatia program MANAGER"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea fisierelor cu aplicatia FILE MANAGER"
						},
						{
							"_nr": "6",
							"__text": "Configurarea si personalizarea sistemului"
						},
						{
							"_nr": "7",
							"__text": "Gestiunea imprimarii cu PRINT MANAGER"
						},
						{
							"_nr": "8",
							"__text": "Aplicatia PIF Editor si optimizarea utilizarii aplicatiilor Windows"
						},
						{
							"_nr": "9",
							"__text": "Aplicatia PAINTBRUSH"
						},
						{
							"_nr": "10",
							"__text": "Aplicatia TERMINAL"
						},
						{
							"_nr": "11",
							"__text": "Aplicatia WRITE. Alte accesorii Windows"
						},
						{
							"_nr": "12",
							"__text": "Legarea si incapsularea obiectelor in Windows"
						},
						{
							"_nr": "13",
							"__text": "Optimizarea sistemului de calcul pentru lucrul cu Windows"
						}
					]
				},
				"_id": "712",
				"_isbn": "973-601-160-7"
			},
			{
				"titlu": "Windows 3.1",
				"autori": {
					"autor": {
						"_nume": "Florin Vladimit Pilat"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "192",
				"pretOriginal": "990",
				"colectia": {
					"_numarul": "14",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_3_1_Pilat.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea si modurile de functionare ale produsului Windows 3.0"
						},
						{
							"_nr": "2",
							"__text": "Bazele functionarii interfetei grafice Windows 3.0"
						},
						{
							"_nr": "3",
							"__text": "Lansarea in executie a programelor de aplicatie"
						},
						{
							"_nr": "4",
							"__text": "Crearea si folosirea grupurilor. Gestiunea programelor cu componenta program MANAGER"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea fisierelor cu FILE MANAGER. Gestiunea Task-urilor"
						},
						{
							"_nr": "6",
							"__text": "Configurarea cu modulul de instalare si personalizarea sistemului"
						},
						{
							"_nr": "7",
							"__text": "Instalarea unei imprimante. Gestiunea imprimarii cu PRINT MANAGER"
						},
						{
							"_nr": "8",
							"__text": "Aplicatia TERMINAL"
						},
						{
							"_nr": "9",
							"__text": "Aplicatiile CLIPBOARD si PAINTBRUSH"
						},
						{
							"_nr": "10",
							"__text": "Aplicatia WRITE. Alte accesorii Windows"
						},
						{
							"_nr": "11",
							"__text": "Optimizarea folosirii aplicatiilor sub Windows cu ajutorul fisierelor .PIF"
						},
						{
							"_nr": "12",
							"__text": "Optimizarea sistemului pentru lucrul cu Windows"
						}
					]
				},
				"_id": "713",
				"_isbn": "973-601-071-6"
			},
			{
				"titlu": "MS-DOS 6.2 (pentru incepatori)",
				"autori": {
					"autor": {
						"_nume": "Jennifer Fulton"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "176",
				"pretOriginal": "4490",
				"colectia": {
					"_numarul": "34",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 minute guid to MS-DOS 6.2",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "SUA"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_pentru_incepatori_Fulton.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Un prim contact cu sistemul de operare DOS"
						},
						{
							"_nr": "2",
							"__text": "Ce sunt discurile, cataloagele si fisierele"
						},
						{
							"_nr": "3",
							"__text": "Introducerea comezilor DOS"
						},
						{
							"_nr": "4",
							"__text": "Artificii pnetru introducerea comenzilor"
						},
						{
							"_nr": "5",
							"__text": "O mana de ajutor"
						},
						{
							"_nr": "6",
							"__text": "Listarea fisierelor"
						},
						{
							"_nr": "7",
							"__text": "Copierea si mutarea fisierelor"
						},
						{
							"_nr": "8",
							"__text": "Stergerea fisierelor si recuperarea lor"
						},
						{
							"_nr": "9",
							"__text": "Lucrul cu cataloage"
						},
						{
							"_nr": "10",
							"__text": "Formatarea dischetelor"
						},
						{
							"_nr": "11",
							"__text": "Alte operatii cu dischete"
						},
						{
							"_nr": "12",
							"__text": "Dublarea spatiului pe disc cu DoubleScace"
						},
						{
							"_nr": "13",
							"__text": "Alte operatii"
						},
						{
							"_nr": "14",
							"__text": "Defragmentarea unei unitati"
						},
						{
							"_nr": "15",
							"__text": "O privire generala asupra copiilor de siguranta"
						},
						{
							"_nr": "16",
							"__text": "Restaurarea discului"
						},
						{
							"_nr": "17",
							"__text": "Protejarea sistemului importriva virusilor"
						},
						{
							"_nr": "18",
							"__text": "Optimizarea memoriei"
						},
						{
							"_nr": "19",
							"__text": "Editara unui fisier cu editorul DOS"
						},
						{
							"_nr": "20",
							"__text": "Configurarea sistemului dumneavoastra"
						},
						{
							"_nr": "21",
							"__text": "Definirea configuratiilor multiple ale sistemului"
						}
					]
				},
				"_id": "714",
				"_isbn": "973-601-175-5"
			},
			{
				"titlu": "Hello, Basic. Aplicatii pe calcualtoare de tip SPECTRUM",
				"autori": {
					"autor": {
						"_nume": "Luminita State"
					}
				},
				"anAparitie": "1992",
				"editura": "Agni",
				"numarPagini": "192",
				"pretOriginal": "550",
				"redactori": {
					"redactor": "Anton Batatorescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Hello_Basic_State_1992.jpg"
					},
					"__text": "Ioan Hutu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul BASIC pentru calculatoare compatibile Spectrum"
						},
						{
							"_nr": "1",
							"__text": "Despre limbajul BASIC"
						},
						{
							"_nr": "2",
							"__text": "Expresii BASIC"
						},
						{
							"_nr": "3",
							"__text": "Functii BASIC"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni si comenzi BASIC"
						},
						{
							"_nr": "5",
							"__text": "Subprograme BASIC"
						},
						{
							"_nr": "6",
							"__text": "Grafica"
						},
						{
							"_nr": "7",
							"__text": "Cromatica"
						},
						{
							"_nr": "8",
							"__text": "Efecte speciale"
						},
						{
							"_nr": "II",
							"__text": "Aplicatii in BASIC"
						},
						{
							"_nr": "9",
							"__text": "Elemente de calcul matricial"
						},
						{
							"_nr": "10",
							"__text": "Rezolvarea sistemelor liniare"
						},
						{
							"_nr": "11",
							"__text": "Rezolvarea ecuatiilor neliniare"
						},
						{
							"_nr": "12",
							"__text": "Integrarea numerica"
						},
						{
							"_nr": "13",
							"__text": "Programare liniara"
						},
						{
							"_nr": "14",
							"__text": "Metode de sortare"
						},
						{
							"_nr": "15",
							"__text": "Jocuri"
						},
						{
							"_nr": "16",
							"__text": "Diverse"
						}
					]
				},
				"_id": "715",
				"_isbn": "973-95626-1-2"
			},
			{
				"titlu": "Principiile teoriei codurilor. Algoritmi si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Adraian-Traian Murgan"
						},
						{
							"_nume": "Radu Radescu"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "536",
				"pretOriginal": "0",
				"pretCumparare": "200000",
				"tehnoredactori": {
					"tehnoredactor": "Radu Radescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Principiile_teoriei_codurilor_Algoritmi_si_aplicatii_Murgan.jpg"
					},
					"__text": "Vlad Oancea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Coduri bloc. Coduri Hamming grup"
						},
						{
							"_nr": "3",
							"__text": "Corpuri finite. Campuri Galois"
						},
						{
							"_nr": "4",
							"__text": "Coduri ciclice. Coduri Hamming ciclice"
						},
						{
							"_nr": "5",
							"__text": "Decodarea Error-Trapping. Coduri Golay"
						},
						{
							"_nr": "6",
							"__text": "Coduri ciclice corectoare de pachete de erori. Coduri fire"
						},
						{
							"_nr": "7",
							"__text": "Coduri aritmetice"
						},
						{
							"_nr": "8",
							"__text": "Coduri BCH"
						},
						{
							"_nr": "9",
							"__text": "Coduri Reed-Solomon"
						},
						{
							"_nr": "10",
							"__text": "Coduri convolutionale. Codarea Viterbi"
						},
						{
							"_nr": "11",
							"__text": "Strategii de trasmisiune cu repetare automata la cerere - ARQ"
						},
						{
							"_nr": "12",
							"__text": "Aplicatii ale teoriei codurilor in ingineria informatiei"
						},
						{
							"_nr": "13",
							"__text": "Aplicatii ale teoriei codurilor in ingineria comunicatiilor"
						},
						{
							"_nr": "14",
							"__text": "Inteligenta computationala aplicata in teoria codurilor"
						}
					]
				},
				"_id": "716",
				"_isbn": "973-31-1545-2"
			},
			{
				"titlu": "Logica si teoria argumetarii",
				"autori": {
					"autor": {
						"_nume": "Dan Craciun"
					}
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "280",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Logica_si_teoria_argumentarii_Craciun.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Adevar si validitate"
						},
						{
							"_nr": "2",
							"__text": "Logica propozitiilor"
						},
						{
							"_nr": "3",
							"__text": "Logica traditionala a termenilor"
						},
						{
							"_nr": "4",
							"__text": "Logica moderna a predicatelor"
						},
						{
							"_nr": "5",
							"__text": "Logica interferentelor probabile"
						},
						{
							"_nr": "6",
							"__text": "Logica discursului"
						},
						{
							"_nr": "7",
							"__text": "Erori in demonstratie"
						}
					]
				},
				"_id": "717",
				"_isbn": "973-31-1518-5"
			},
			{
				"titlu": "Felix C-256. Structura si programarea calculatorului",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "Ion Carutasu"
						},
						{
							"_nume": "Petru Macarie"
						},
						{
							"_nume": "Corneliu Masek"
						},
						{
							"_nume": "Victor Meghesan"
						},
						{
							"_nume": "Maria Mocica"
						},
						{
							"_nume": "Lucia Popescu"
						},
						{
							"_nume": "Werner Schatz"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"tiraj": "7180",
				"numarPagini": "542",
				"pretOriginal": "30",
				"redactori": {
					"redactor": [
						"Smaranda Dimitriu",
						"Paul Zamfirescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Felix_c_256_Structura_si_programarea_calculatorului_Baltac.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organizarea generala a calculatorului FELIX C-256"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni generele si functionarea unitatii centrale"
						},
						{
							"_nr": "3",
							"__text": "Structura sistemului de intrare-iesire"
						},
						{
							"_nr": "4",
							"__text": "Echipamente periferice"
						},
						{
							"_nr": "5",
							"__text": "Intreruperi si derutari"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni priveilefiate si optionale"
						},
						{
							"_nr": "7",
							"__text": "Scheme logice, subrutine, fisiere, cartele de comanda"
						},
						{
							"_nr": "8",
							"__text": "Particlaritati ale limbajelor de programare"
						},
						{
							"_nr": "9",
							"__text": "Principii de organizare ale sistemului de operare"
						},
						{
							"_nr": "10",
							"__text": "Structura sistemului de operare SIRIS-2R"
						},
						{
							"_nr": "11",
							"__text": "Norme de programare"
						}
					]
				},
				"_id": "718",
				"_isbn": ""
			},
			{
				"titlu": "Logica predicatelor",
				"autori": {
					"autor": {
						"_nume": "Cornel Popa"
					}
				},
				"anAparitie": "1992",
				"editura": "Hyperion XXI",
				"numarPagini": "372",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Logica_predicatelor_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Obiectul logicii"
						},
						{
							"_nr": "II",
							"__text": "Logica, drept si informatica juridica"
						},
						{
							"_nr": "III",
							"__text": "Logica propozitiilor"
						},
						{
							"_nr": "IV",
							"__text": "Axiomatizarea logicii propozitiilor"
						},
						{
							"_nr": "V",
							"__text": "Logica predicatelor"
						}
					]
				},
				"_id": "719",
				"_isbn": "973-95575-4-6"
			},
			{
				"titlu": "Analiza sistemelor complexe",
				"autori": {
					"autor": [
						{
							"_nume": "Gh. Boldur-Latescu"
						},
						{
							"_nume": "Gh. Ciobanu"
						},
						{
							"_nume": "I. Bancila"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "384",
				"pretOriginal": "28",
				"redactori": {
					"redactor": "Dorina Badila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_sistemelor_complexe_Latescu.jpg"
					},
					"__text": "Constantin Gheorghiu-Enescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Analiza sistematica, obiect, metode si tendinte actuale"
						},
						{
							"_nr": "2",
							"__text": "Metodologii pentru analiza si proiectarea sistemelor informatice"
						},
						{
							"_nr": "3",
							"__text": "Metodologii de analiza si proiectare informational-decizionala"
						},
						{
							"_nr": "4",
							"__text": "Metodele cercetatii operationale in optimizarea deciziilor"
						},
						{
							"_nr": "5",
							"__text": "Dinamica industriala - instrument de analiza si proiectare a sistemelor"
						},
						{
							"_nr": "6",
							"__text": "Informatica si analiza de sistem"
						},
						{
							"_nr": "7",
							"__text": "Factorul uman in analiza de sistem"
						},
						{
							"_nr": "8",
							"__text": "Analiza sistemelor complexe - disciplina integratoare a preocuparilor din domeniul conducerii stiintifice"
						}
					]
				},
				"_id": "720",
				"_isbn": ""
			},
			{
				"titlu": "Programarea matematica : Programarea stochastica (Vol. II)",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Mihoc"
						},
						{
							"_nume": "Ileana Nadejde"
						}
					]
				},
				"anAparitie": "1968",
				"editura": "Stiintifica",
				"tiraj": "28000+140 ex.",
				"numarPagini": "408",
				"pretOriginal": "12.50",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Dorina Badila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Stanculescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_matematica_Vol_II_Mihoc.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Programe stochastice"
						},
						{
							"_nr": "II",
							"__text": "Transporturi stochastice"
						},
						{
							"_nr": "III",
							"__text": "Programare in lanturi Markov"
						}
					]
				},
				"_id": "721",
				"_isbn": ""
			},
			{
				"titlu": "Programare liniare cu mai multi indici",
				"autori": {
					"autor": {
						"_nume": "Cerchez Mihu"
					}
				},
				"anAparitie": "1974",
				"editura": "Academiei Republicii Socialiste Romania",
				"tiraj": "2000",
				"numarPagini": "176",
				"pretOriginal": "6.25",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Elena Henning"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bologan"
				},
				"coperta": {
					"imagine": {
						"_path": "Programe_liniare_cu_mai_multi_indici_Mihu.jpg"
					},
					"__text": "Popa Teodor"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme de transport triaxiale T-3A"
						},
						{
							"_nr": "2",
							"__text": "Probleme de transport triplanare T-3P"
						},
						{
							"_nr": "3",
							"__text": "Probleme de transport biaxiale T-2A"
						},
						{
							"_nr": "4",
							"__text": "Probleme de transport axial-planare T-AP"
						},
						{
							"_nr": "5",
							"__text": "Probleme de transport biplanare T-2P"
						},
						{
							"_nr": "6",
							"__text": "Probleme de transport generalizate"
						},
						{
							"_nr": "7",
							"__text": "Determinarea timpului partial minim in problemele de transport tridimensionale"
						},
						{
							"_nr": "8",
							"__text": "Probleme liniare cu n indici (n>=4)"
						},
						{
							"_nr": "Anexa",
							"__text": "Metoda difetentelor comparate"
						}
					]
				},
				"_id": "722",
				"_isbn": ""
			},
			{
				"titlu": "Statistica matematica si cercetari operationale (Vol II)",
				"autori": {
					"autor": [
						{
							"_nume": "George Ciucu"
						},
						{
							"_nume": "Virgil Craiu"
						},
						{
							"_nume": "Anton Stefanescu"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Didactica si pedagogica",
				"tiraj": "4695",
				"numarPagini": "308",
				"pretOriginal": "8.45",
				"redactori": {
					"redactor": "Mariana Craiu"
				},
				"tehnoredactori": {
					"tehnoredactor": "A. Daniel"
				},
				"coperta": {
					"imagine": {
						"_path": "Statistica_matematica_si_cercetari_operationale_Vol_II_Ciucu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme ale programarii matematice"
						},
						{
							"_nr": "2",
							"__text": "Elemente de teoria jocurilor"
						},
						{
							"_nr": "3",
							"__text": "Elemente in teoria asteptarii"
						},
						{
							"_nr": "4",
							"__text": "Elemente de teoria fiabilitatii"
						}
					]
				},
				"_id": "723",
				"_isbn": ""
			},
			{
				"titlu": "Statistica matematica si cerceteri operationale (Vol III)",
				"autori": {
					"autor": [
						{
							"_nume": "George Ciucu"
						},
						{
							"_nume": "Virgil Craiu"
						},
						{
							"_nume": "Anton Stefanescu"
						},
						{
							"_nume": "Maria Viorica Stefanescu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Didactica si pedagogica",
				"numarPagini": "368",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Valentin Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Petrica"
				},
				"coperta": {
					"imagine": {
						"_path": "Statistica_matematica_si_cercetari_operationale_Vol_III_Ciucu.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programarea stochastica"
						},
						{
							"_nr": "2",
							"__text": "Jocuri aleatoare"
						},
						{
							"_nr": "3",
							"__text": "Programarea multicriteriu"
						},
						{
							"_nr": "4",
							"__text": "Programarea dinamica"
						},
						{
							"_nr": "5",
							"__text": "Jocuri stochastice"
						},
						{
							"_nr": "6",
							"__text": "Elemente de analiza bayesiana"
						},
						{
							"_nr": "7",
							"__text": "Elemente de teoria deciziilor statistice"
						},
						{
							"_nr": "8",
							"__text": "Elemente de predictie statistica"
						},
						{
							"_nr": "9",
							"__text": "teste secventiale bayesiane"
						}
					]
				},
				"_id": "724",
				"_isbn": ""
			},
			{
				"titlu": "Macroprocesoare si limbaje extensibile",
				"autori": {
					"autor": [
						{
							"_nume": "Stelian Niculescu"
						},
						{
							"_nume": "Dan Petre Golesteanu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "304",
				"pretOriginal": "17.50",
				"redactori": {
					"redactor": "Ileana Candroveanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Macroprocesoare_si_limbaje_extensibile_Niculescu.jpg"
					},
					"__text": "Gheorghe Motora"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbaje de programare : Notiuni fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Extensibilitate in limbaje de programare"
						},
						{
							"_nr": "3",
							"__text": "Facilitati conversationale de extensibilitate"
						},
						{
							"_nr": "4",
							"__text": "Mecanisme de extensie pentru limbaje evoluate"
						},
						{
							"_nr": "5",
							"__text": "Initiere in tehnica macro-extensibilitatii: Macroprocesorul si limbajul LPS"
						}
					]
				},
				"_id": "725",
				"_isbn": ""
			},
			{
				"titlu": "Fortran : Manual de utilizare",
				"autori": {
					"autor": {
						"_nume": "Martin Gabriel"
					}
				},
				"anAparitie": "1972",
				"editura": "Institutul de cercetati si proiectari pentru tehnica de calcul",
				"numarPagini": "148",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Fortran_Manual_de_utilizare_Martin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare generala"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni"
						},
						{
							"_nr": "3",
							"__text": "Proceduri si subprograme"
						},
						{
							"_nr": "4",
							"__text": "Facilitati de punere la punct"
						},
						{
							"_nr": "5",
							"__text": "Caracteristici suplimentare"
						},
						{
							"_nr": "6",
							"__text": "Pregatirea compilarii"
						},
						{
							"_nr": "7",
							"__text": "Reprezentarea informatiilor"
						},
						{
							"_nr": "8",
							"__text": "Fisiere utilizabile in FORTRAN FELIX C-256"
						}
					]
				},
				"_id": "726",
				"_isbn": ""
			},
			{
				"titlu": "Algoritmi si metode de reprezentare",
				"autori": {
					"autor": [
						{
							"_nume": "Stelian Niculescu"
						},
						{
							"_nume": "Mihai Radu Dumitrescu"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Didactica si pedagogica",
				"tiraj": "2200+80 exemplare brosate",
				"numarPagini": "272",
				"pretOriginal": "5.85",
				"redactori": {
					"redactor": "Valentin Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": "T. Balaita"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_si_metode_de_reprezentare_Niculescu.jpg"
					},
					"__text": "N. Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea notiuni de algoritm"
						},
						{
							"_nr": "2",
							"__text": "Descrierea algoritmilor cu ajutorul schemelor logice"
						},
						{
							"_nr": "3",
							"__text": "Limbaj pentru descrierea algoritmilor"
						},
						{
							"_nr": "4",
							"__text": "Tabele de decizie"
						},
						{
							"_nr": "5",
							"__text": "Descrierea algoritmilor prin expresii booleene"
						}
					]
				},
				"_id": "727",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in limbajul COBOL",
				"autori": {
					"autor": [
						{
							"_nume": "Casimir Macarie"
						},
						{
							"_nume": "Anastase Pitis"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Institutul central pentru conducere si informatica",
				"numarPagini": "117",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_COBOL_Macarie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "XI",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "XII",
							"__text": "Elemente de baza ale limbajului COBOL"
						},
						{
							"_nr": "XIII",
							"__text": "Descrierea structurilor de control de baza in limbajul COBOL"
						},
						{
							"_nr": "XIV",
							"__text": "Elemente complementare de descriere si prelucrarea datelor"
						},
						{
							"_nr": "XV",
							"__text": "Fisiere pe cartele. Validarea datelor"
						},
						{
							"_nr": "XVI",
							"__text": "Intocmirea rapoartelor"
						},
						{
							"_nr": "XVII",
							"__text": "Prelucrarea tablourilor"
						},
						{
							"_nr": "XVIII",
							"__text": "Prelucrarea fisierelor"
						},
						{
							"_nr": "XIX",
							"__text": "Prelucrari speciale in COBOL"
						},
						{
							"_nr": "XX",
							"__text": "Programul de inventariere a fisierelor  si volumelor"
						},
						{
							"_nr": "XXI",
							"__text": "Generatorul de programe de sortare"
						},
						{
							"_nr": "XXII",
							"__text": "Programul de intretinere a fisierelor si volumelor"
						},
						{
							"_nr": "XXIII",
							"__text": "Utilizarea bibilotecilor"
						},
						{
							"_nr": "XXIV",
							"__text": "Utilizarea procedurilor catalogate"
						}
					]
				},
				"_id": "728",
				"_isbn": ""
			},
			{
				"titlu": "Utilizarea mijloacelor electronice de capacitate mica la prelucrarea datelor evidentei economice.Aplicatii practice (Vol I)",
				"autori": {
					"autor": [
						{
							"_nume": "Petru Pantea"
						},
						{
							"_nume": "Dan Racovitan"
						},
						{
							"_nume": "Nicolae Ghisoiu"
						},
						{
							"_nume": "Ionica Netea"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Dacia",
				"numarPagini": "140",
				"pretOriginal": "5.75",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "13",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Uilizarea_mijloacelor_electonice_de_capacitate_mica_la_prelucrarea_datelor_economice_I_Pantea.jpg"
					},
					"__text": "Adrian Butura"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea mijloacelor electronice de capacitate mica utilizate la prelucrarea datelor economice"
						},
						{
							"_nr": "2",
							"__text": "Aplicatii practice privind utilizare mijloacelor electronice de capacitate mica utilizate la prelucrarea datelor evidentei economice"
						}
					]
				},
				"_id": "729",
				"_isbn": ""
			},
			{
				"titlu": "Utilizarea mijloacelor electronice de capacitate mica la prelucrarea datelor evidentei economice.Aplicatii practice (Vol II)",
				"autori": {
					"autor": [
						{
							"_nume": "Petru Pantea"
						},
						{
							"_nume": "Dan Racovitan"
						},
						{
							"_nume": "Nicolae Ghisoiu"
						},
						{
							"_nume": "Ionica Netea"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Dacia",
				"numarPagini": "144",
				"pretOriginal": "5.75",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "14",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Uilizarea_mijloacelor_electonice_de_capacitate_mica_la_prelucrarea_datelor_economice_II_Pantea.jpg"
					},
					"__text": "Adrian Butura"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "2",
							"__text": "Aplicatii practice privind utilizare mijloacelor electronice de capacitate mica utilizate la prelucrarea datelor evidentei economice (continuare)"
						},
						{
							"_nr": "3",
							"__text": "Posibilitati de determinare a eficientei economice a utilizarii mijlocelor electronice de capacitate mica"
						}
					]
				},
				"_id": "730",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare si microcontrolere",
				"autori": {
					"autor": {
						"_nume": "Ioan Burda"
					}
				},
				"anAparitie": "2002",
				"editura": "Presa Universitara Clujeana",
				"numarPagini": "220",
				"pretOriginal": "60500",
				"coperta": {
					"imagine": {
						"_path": "Microprocesoare_si_microcontrolere_Burda.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Utilitarul Debug. Familia de Microprocesoare 80X86"
						},
						{
							"_nr": "2",
							"__text": "Programe Simple in Limbaj de Asamblare"
						},
						{
							"_nr": "3",
							"__text": "Circuite Spcializate Programabile"
						},
						{
							"_nr": "4",
							"__text": "Portul Paralel. Aplicatii Simple"
						},
						{
							"_nr": "5",
							"__text": "Programe Rezidente in Memorie"
						},
						{
							"_nr": "6",
							"__text": "Perioada si Frecventa unui Semnal Extern"
						},
						{
							"_nr": "7",
							"__text": "Microcontrolerul 68HC11. PcBug"
						},
						{
							"_nr": "8",
							"__text": "Voltmetru Digital cu Microcontrolerul 6HC11"
						},
						{
							"_nr": "9",
							"__text": "Microcontrolerul PIC 16F84"
						},
						{
							"_nr": "Anexa A",
							"__text": "Setul de instructiuni al Familiei de Microprocesoare 80X86"
						},
						{
							"_nr": "Anexa B",
							"__text": "Setul de instructiuni ale Microcontrolerului 68HC11"
						},
						{
							"_nr": "Anexa C",
							"__text": "Setul de instructiuni ale Microcontrolerului PIC 16F84"
						}
					]
				},
				"_id": "731",
				"_isbn": "973-610-046-4"
			},
			{
				"titlu": "Combinatorica cu aplicatii",
				"autori": {
					"autor": {
						"_nume": "Zoltan Kasa"
					}
				},
				"anAparitie": "2003",
				"editura": "Presa Universitara Clujeana",
				"numarPagini": "188",
				"pretOriginal": "66000",
				"coperta": {
					"imagine": {
						"_path": "Combinatorica_cu_aplicatii_Kasa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Permutari, aranjamente, combinari"
						},
						{
							"_nr": "2",
							"__text": "Generarea combinarilor, permutarilor si a unor multimi"
						},
						{
							"_nr": "3",
							"__text": "Partitiile unui numar natural"
						},
						{
							"_nr": "4",
							"__text": "Functii generatoare"
						},
						{
							"_nr": "5",
							"__text": "Automatizarea demonstrarii indentitatilor"
						},
						{
							"_nr": "6",
							"__text": "Principiul includerii si al excluderii"
						},
						{
							"_nr": "7",
							"__text": "Numere remarcabile"
						},
						{
							"_nr": "8",
							"__text": "Combinatorica cuvintelor"
						}
					]
				},
				"_id": "732",
				"_isbn": "973-610-152-5"
			},
			{
				"titlu": "Achizitia, prelucrarea si renunoasterea imaginilor",
				"autori": {
					"autor": {
						"_nume": "Dorian Cojocaru"
					}
				},
				"anAparitie": "2002",
				"editura": "Universitara Craiova",
				"numarPagini": "332",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Achizitia_prelucrarea_si_recunoasterea_imaginilor_Cojocaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Achizitia imaginii"
						},
						{
							"_nr": "3",
							"__text": "Formarea imaginii numerice"
						},
						{
							"_nr": "4",
							"__text": "Detectia contururilor"
						},
						{
							"_nr": "5",
							"__text": "Segmentarea imaginii"
						},
						{
							"_nr": "6",
							"__text": "Descrierea imaginilor"
						},
						{
							"_nr": "7",
							"__text": "Clasificarea formelor"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii bazate pe vedere artificiala"
						},
						{
							"_nr": "Anexa A",
							"__text": "Prelucrarea imaginilor in MATLAB"
						},
						{
							"_nr": "Anexa B",
							"__text": "Editarea neliniara a imaginilor"
						}
					]
				},
				"_id": "733",
				"_isbn": "973-8043-146-5"
			},
			{
				"titlu": "Word Sense Disambiguation. Algorithms and Applications",
				"autori": {
					"autor": [
						{
							"_nume": "Eneko Agirre"
						},
						{
							"_nume": "Philip Edmonds"
						},
						{
							"_nume": "Adam Kilgarriff"
						},
						{
							"_nume": "Nancy Ide"
						},
						{
							"_nume": "Yorick Wilks"
						},
						{
							"_nume": "Matha Palmer"
						},
						{
							"_nume": "Hwee Tou Ng"
						},
						{
							"_nume": "Hoa Tran Dang"
						},
						{
							"_nume": "Rada Mihalcea"
						},
						{
							"_nume": "Ted Pedersen"
						},
						{
							"_nume": "Lluis Marquez"
						},
						{
							"_nume": "Gerard Escudero"
						},
						{
							"_nume": "David Martinez"
						},
						{
							"_nume": "German Rigau"
						},
						{
							"_nume": "Mark Stevenson"
						},
						{
							"_nume": "Julio Gonzalo"
						},
						{
							"_nume": "Felisa Verdejo"
						},
						{
							"_nume": "Paul Buitelaar"
						},
						{
							"_nume": "Bernardo Magnini"
						},
						{
							"_nume": "Carlo Strapparava"
						},
						{
							"_nume": "Piek Vossen"
						},
						{
							"_nume": "Philip Resnik"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Springer",
				"numarPagini": "374",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Word_sense_disambiguation_Algorithms_and_Applications_Agirre.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introductions"
						},
						{
							"_nr": "2",
							"__text": "Word Senses"
						},
						{
							"_nr": "3",
							"__text": "Making Sense About Sense"
						},
						{
							"_nr": "4",
							"__text": "Evaluation of WSD Systems"
						},
						{
							"_nr": "5",
							"__text": "Knowledge-Bases Methods for WSD"
						},
						{
							"_nr": "6",
							"__text": "Unsupervised Corpus-Based Method for WSD"
						},
						{
							"_nr": "7",
							"__text": "Suppervised Corpus-Bases Method for WSD"
						},
						{
							"_nr": "8",
							"__text": "Knowledge Sources for WSD"
						},
						{
							"_nr": "9",
							"__text": "Automatic Acquisition of Lexical Information and Examples"
						},
						{
							"_nr": "10",
							"__text": "Domain-Specific WSD"
						},
						{
							"_nr": "11",
							"__text": "WSD in NLP Applications"
						},
						{
							"_nr": "A",
							"__text": "Resources for WSD"
						}
					]
				},
				"_id": "734",
				"_isbn": "978-1-4020-6870-6"
			},
			{
				"titlu": "C++",
				"autori": {
					"autor": [
						{
							"_nume": "Danny Kalev"
						},
						{
							"_nume": "Michael J. Tobler"
						},
						{
							"_nume": "Jan Walter"
						},
						{
							"_nume": "Paul Snaith"
						},
						{
							"_nume": "Andrei Kossoroukov"
						},
						{
							"_nume": "Scott Roberts"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "496",
				"pretOriginal": "450000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "371",
					"_nume": "Calculatorul personal"
				},
				"traducere": {
					"titluOriginal": "C++ How-To",
					"editura": "SAMS",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "1999",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cristina Mihai"
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_Kalev.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Elemente de limbaj"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Structuri de date"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Algoritmi"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Tratarea erorilor"
						},
						{
							"_nr": "Partea a cincea",
							"__text": "Gestinarea memoriei"
						},
						{
							"_nr": "Partea a sasea",
							"__text": "I/O"
						},
						{
							"_nr": "Partea a saptea",
							"__text": "Anexe"
						}
					]
				},
				"_id": "735",
				"_isbn": "973-20-0429-0"
			},
			{
				"titlu": "PL/SQL",
				"autori": {
					"autor": [
						{
							"_nume": "Tom Luers"
						},
						{
							"_nume": "Timothy Atwood"
						},
						{
							"_nume": "Jonathan Gennick"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "504",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "352",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Teach Yourself PL/SQL in 21 Days",
					"editura": "Sams",
					"traducatori": {
						"traducator": [
							"Sorina Dumitru",
							"Felicia Badescu",
							"Ovidiu Badescu"
						]
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "PL_SQL_Luers.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente fundamentale PL/SQL"
						},
						{
							"_nr": "2",
							"__text": "Selectarea unui instrument de dezvoltare"
						},
						{
							"_nr": "3",
							"__text": "Scrierea declaratiilor si a blocurilor"
						},
						{
							"_nr": "4",
							"__text": "Scrierea expresiilor PL/SQL"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea functilor, a instructiunilor IF si a buclelor"
						},
						{
							"_nr": "6",
							"__text": "Implementarea ciclurilor si a instructiunilor GOTO"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea functiilor Oracle incorporate"
						},
						{
							"_nr": "8",
							"__text": "Proceduri, pachete, erori si exceptii"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea SQL: INSERT, SELECT, declaratii avansate si tabele"
						},
						{
							"_nr": "10",
							"__text": "Manipularea datelor cu ajutorul cursoarelor, al instructiunilor DELETE si UPDATE"
						},
						{
							"_nr": "11",
							"__text": "Scrierea declansatoarelor pentru bazele de date"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea cursoarelor pentru proceduri complexe"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea obiectelor Oracle8 pentru programarea orientata pe obiecte"
						},
						{
							"_nr": "14",
							"__text": "Depanarea codului si prevenirea erorilor"
						},
						{
							"_nr": "15",
							"__text": "Exploarea subiectelor avansate"
						},
						{
							"_nr": "16",
							"__text": "Administarea tranzactiilor si blocurilor"
						},
						{
							"_nr": "17",
							"__text": "Generarea codului SQL dinamic"
						},
						{
							"_nr": "18",
							"__text": "Scrierea in fisiere si pe ecran"
						},
						{
							"_nr": "19",
							"__text": "Administrarea lucrarilor de baze de date"
						},
						{
							"_nr": "20",
							"__text": "Avertizarea altor proceduri:pachetul DBMS_ALERT"
						},
						{
							"_nr": "21",
							"__text": "Utilizarea J/SQL pentru aplicatii WEB"
						}
					]
				},
				"_id": "736",
				"_isbn": "973-20-0299-9"
			},
			{
				"titlu": "MCSE: 1001 intrebari si raspunsuri",
				"autori": {
					"autor": [
						{
							"_nume": "Shane Stigler"
						},
						{
							"_nume": "Mark A. Linsenbardt"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "990",
				"pretOriginal": "390000",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "345",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "1001 MCSE Tips",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": [
							"Ileana Cazan",
							"Florin Moraru",
							"Stafan Spanache"
						]
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cristina Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Mihaela Ghica",
						"Carolina Scumpu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "MCSE_1001_intrebari_si_raspunsuri_Stigler.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": {
						"_nr": "@",
						"__text": "1001 intrebari si raspunsuri"
					}
				},
				"_id": "737",
				"_isbn": "973-20-0091-0"
			},
			{
				"titlu": "Totul despre C si C++ : Manualul fundamental de programare in C si C++",
				"autori": {
					"autor": [
						{
							"_nume": "Kris Jamsa"
						},
						{
							"_nume": "Lars Klander"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "1328",
				"pretOriginal": "590000",
				"pretCumparare": "300000",
				"colectia": {
					"_numarul": "321",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Jamsa's C/C++ Programmer's Bible",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Eugen Dumitrescu"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_C_si_Cpp_Jamsa.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Primele notiuni de C"
						},
						{
							"_nr": "2",
							"__text": "Macroinstructiuni si constante"
						},
						{
							"_nr": "3",
							"__text": "Siruri"
						},
						{
							"_nr": "4",
							"__text": "Functii"
						},
						{
							"_nr": "5",
							"__text": "Operatii de la tastatura"
						},
						{
							"_nr": "6",
							"__text": "Functii matematice"
						},
						{
							"_nr": "7",
							"__text": "Fisiere, directoare si dicuri"
						},
						{
							"_nr": "8",
							"__text": "Matrice, pointeri, structuri"
						},
						{
							"_nr": "9",
							"__text": "Servicii DOS si BIOS"
						},
						{
							"_nr": "10",
							"__text": "Gestionarea memoriei"
						},
						{
							"_nr": "11",
							"__text": "Data si ora"
						},
						{
							"_nr": "12",
							"__text": "Redirectarea intrarilor si iesirilor si procesarea liniei de comanda"
						},
						{
							"_nr": "13",
							"__text": "Instrumente de programare"
						},
						{
							"_nr": "14",
							"__text": "Limbajul C avansat"
						},
						{
							"_nr": "15",
							"__text": "Introducere in C++"
						},
						{
							"_nr": "16",
							"__text": "Obiecte"
						},
						{
							"_nr": "17",
							"__text": "Functii de clasa uzuale"
						},
						{
							"_nr": "18",
							"__text": "I/O in C++"
						},
						{
							"_nr": "19",
							"__text": "Mostenire si polimorfism"
						},
						{
							"_nr": "20",
							"__text": "Functii generice si sabloane"
						},
						{
							"_nr": "21",
							"__text": "Tratarea exceptiilor si portabilitatea tipurilor"
						},
						{
							"_nr": "22",
							"__text": "Crearea unui exemplu de clasa reutilizabila"
						},
						{
							"_nr": "23",
							"__text": "Biblioteca standard de sabloane"
						},
						{
							"_nr": "24",
							"__text": "Introducere in programarea in Windows"
						},
						{
							"_nr": "25",
							"__text": "Mesaje si meniuri"
						},
						{
							"_nr": "26",
							"__text": "Casete de dialog"
						},
						{
							"_nr": "27",
							"__text": "Gestionarea memoriei in Windows"
						},
						{
							"_nr": "28",
							"__text": "Procese si fire de executie"
						},
						{
							"_nr": "29",
							"__text": "Interfata cu dispozitivele grafice"
						},
						{
							"_nr": "30",
							"__text": "Blocuri de bitmap, metafisiere si pictograme"
						},
						{
							"_nr": "31",
							"__text": "Intrari/iesiri in Windows"
						}
					]
				},
				"_id": "738",
				"_isbn": "973-601-911-X"
			},
			{
				"titlu": "Sisteme de operare moderne (editia II)",
				"autori": {
					"autor": {
						"_nume": "Andrew S. Tanenbaum"
					}
				},
				"anAparitie": "2004",
				"editura": "Byblos",
				"numarPagini": "884",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Modern operating systems second ed.",
					"editura": "Prentice Hall",
					"traducatori": {
						"traducator": [
							"Valentin Cristea",
							"Nicolae Tapus",
							"Andrei Agapi",
							"Cosmin Arad",
							"Catalin Barcau",
							"Catalin Carstoiu",
							"George Ciobanu",
							"Ozana-Silvia Dragomir",
							"Ruxandra Enaceanu",
							"Ionut-Andrei Frujina",
							"Cosmin Grigoras",
							"Bogdan Grosu",
							"Dan Mihailescu",
							"Dorel Nastasa",
							"Ana-Maria Oprescu",
							"Vlad Panait",
							"Bogdan Pintea",
							"Octavian Purdila",
							"Gabriel-Valentin Raduti",
							"Bodgan Rudisteanu",
							"Cosmin Sarbu",
							"Vlad Mihai Sima",
							"Mihaela Toarta",
							"Eduard-Alexandru Tudoras",
							"Septimiu Turcu",
							"Octavian Udrea",
							"Dan Ungureanu",
							"Ana Varbanescu",
							"Ramiro Voicu"
						]
					},
					"_an": "2001",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_moderne_Editia_II_Tanenbaum.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Procese si fire de executie"
						},
						{
							"_nr": "3",
							"__text": "Interblocari (deadlocks)"
						},
						{
							"_nr": "4",
							"__text": "Gestionarea memoriei"
						},
						{
							"_nr": "5",
							"__text": "Intrari/Iesiri"
						},
						{
							"_nr": "6",
							"__text": "Sisteme de fisiere"
						},
						{
							"_nr": "7",
							"__text": "Sisteme de operare multimedia"
						},
						{
							"_nr": "8",
							"__text": "Sisteme multiprocesor"
						},
						{
							"_nr": "9",
							"__text": "Securitate"
						},
						{
							"_nr": "10",
							"__text": "Studiu de caz 1: Unix si Linux"
						},
						{
							"_nr": "11",
							"__text": "Studiu de caz 2: Windows 2000"
						},
						{
							"_nr": "12",
							"__text": "Proiectarea sistemelor de operare"
						},
						{
							"_nr": "13",
							"__text": "Legaturi recomandate si bibliografie"
						}
					]
				},
				"_id": "739",
				"_isbn": "973-86699-2-8"
			},
			{
				"titlu": "Sinteza din text a semnalului vocal (Vol.I. Modelare acustica si fonologica)",
				"autori": {
					"autor": [
						{
							"_nume": "Mircea Giugiu"
						},
						{
							"_nume": "Luciana Peev"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Risoprint",
				"numarPagini": "242",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Sinteza_din_text_a_semnalului_vocal_Vol_I_Modelareacustica_si_fonologica_Giurgiu.jpg"
					},
					"__text": "Voichita-Maria Clinci"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sinteza din text a semnalului vocal"
						},
						{
							"_nr": "2",
							"__text": "Modele de producere a semnalului vocal"
						},
						{
							"_nr": "3",
							"__text": "Metode de analiza acustica a semnalului vocal"
						},
						{
							"_nr": "4",
							"__text": "Sistemul fonetic al limbii romane"
						},
						{
							"_nr": "5",
							"__text": "Modelul fonologic pentru limba romana"
						},
						{
							"_nr": "6",
							"__text": "Crearea bazei de date cu semnal vocal"
						},
						{
							"_nr": "7",
							"__text": "Analiza prozodica a unitatilor acustice"
						},
						{
							"_nr": "8",
							"__text": "Modalitati de analiza prozodica"
						},
						{
							"_nr": "9",
							"__text": "Modelul de preprocesare a limbajului natural"
						},
						{
							"_nr": "10",
							"__text": "Modulul de sinteza de semnal vocal"
						},
						{
							"_nr": "11",
							"__text": "Tehnologii actuale si aplicatii folosind sinteza din text a semnalului vocal"
						}
					]
				},
				"_id": "740",
				"_isbn": "973-751-296-0"
			},
			{
				"titlu": "Baze de date distribuite",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Radulescu"
						},
						{
							"_nume": "Adriana Kentsch"
						},
						{
							"_nume": "Roland Kentsch"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Risoprint",
				"numarPagini": "126",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Adrian Radulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_distribuite_Radulescu.jpg"
					},
					"__text": "Camelia Manasia"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Baze de date relationale"
						},
						{
							"_nr": "2",
							"__text": "Dependente functionale si norme normale"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura bazelor de date"
						},
						{
							"_nr": "4",
							"__text": "Proiectarea bazelor de date"
						},
						{
							"_nr": "5",
							"__text": "Baze de date paralele, baze de date distribuite"
						},
						{
							"_nr": "6",
							"__text": "Controlul concurentei"
						},
						{
							"_nr": "7",
							"__text": "Optimizarea bazelor de date distribuite"
						},
						{
							"_nr": "8",
							"__text": "Baze de date pe platforme etetogene"
						},
						{
							"_nr": "9",
							"__text": "Depozite de date"
						},
						{
							"_nr": "10",
							"__text": "Data Minint"
						},
						{
							"_nr": "11",
							"__text": "Studiu de caz"
						},
						{
							"_nr": "13",
							"__text": "Bibligrafie"
						}
					]
				},
				"_id": "741",
				"_isbn": "973-751-267-7"
			},
			{
				"titlu": "Introducere in calcul paralel si distribuit.Indrumator de laborator",
				"autori": {
					"autor": {
						"_nume": "Cosmina Ivan"
					}
				},
				"anAparitie": "2007",
				"editura": "Risoprint",
				"numarPagini": "168",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_calcul_paralel_si_distribuit_Indrumator_de_laborator_Ivan.jpg"
					},
					"__text": "Voichita-Maria Clinci"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de programare multithread. Biblioteca Pthreads"
						},
						{
							"_nr": "2",
							"__text": "Elemente de programare multithread. JavaMT"
						},
						{
							"_nr": "3",
							"__text": "Programare paralela bazata pe directive. OpenMP"
						},
						{
							"_nr": "4",
							"__text": "Programare paralela bazat pe transfer de mesaje. Mediul PVM"
						},
						{
							"_nr": "5",
							"__text": "Programare paralela bazat pe transferul de mesaje. Standardul si biblioteca MPI"
						},
						{
							"_nr": "6",
							"__text": "Elemente de programare distribuita prin invocare de metode la distanta. RMI"
						},
						{
							"_nr": "7",
							"__text": "Elemente de programare distribuita folosind brokeri de obiecte. Modelul CORBA"
						}
					]
				},
				"_id": "742",
				"_isbn": "978-973-751-450-9"
			},
			{
				"titlu": "Data Mining in Internet",
				"autori": {
					"autor": {
						"_nume": "Mihai Acornicului"
					}
				},
				"anAparitie": "2008",
				"editura": "Risoprint",
				"numarPagini": "176",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Data_mining_in_internet_Avornicului.jpg"
					},
					"__text": "Buzogany Laszlo"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiunea de data mining"
						},
						{
							"_nr": "2",
							"__text": "Aspecte data mining-ului"
						},
						{
							"_nr": "3",
							"__text": "Tehnici si metode de data mining"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de cautare si de descoperire a resurselor"
						},
						{
							"_nr": "5",
							"__text": "Data mining pentru hypertext"
						},
						{
							"_nr": "6",
							"__text": "Realizarea unui sistem de extragere de evenimente"
						},
						{
							"_nr": "7",
							"__text": "Concluzi finale"
						}
					]
				},
				"_id": "743",
				"_isbn": "978-973-751-894-1"
			},
			{
				"titlu": "Latex 2e",
				"autori": {
					"autor": [
						{
							"_nume": "Paul A. Blaga"
						},
						{
							"_nume": "Horia F. Pop"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Tehnica",
				"numarPagini": "364",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Virgil Spulber"
				},
				"tehnoredactori": {
					"tehnoredactor": "Diana Jilavu"
				},
				"coperta": {
					"imagine": {
						"_path": "Latex_2e_Blaga.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Latex 2e"
						},
						{
							"_nr": "II",
							"__text": "Pachete Latex"
						},
						{
							"_nr": "III",
							"__text": "Consideratii finale"
						}
					]
				},
				"_id": "744",
				"_isbn": "973-31-1390-5"
			},
			{
				"titlu": "Oracle 8",
				"autori": {
					"autor": {
						"_nume": "David Austin"
					}
				},
				"anAparitie": "1999",
				"editura": "Image",
				"numarPagini": "796",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "Using Oracle8",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Daniel Ilies"
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Georgiana Mihaita"
				},
				"tehnoredactori": {
					"tehnoredactor": "Diana Jilavu"
				},
				"coperta": {
					"imagine": {
						"_path": "Oracle_8_Austin.jpg"
					},
					"__text": "Ioana Cucu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Construirea bazei de date Oracle"
						},
						{
							"_nr": "II",
							"__text": "Personalizarea bazei de date Oracle"
						},
						{
							"_nr": "III",
							"__text": "Administrarea segmentelor de date si indecsi"
						},
						{
							"_nr": "IV",
							"__text": "Administrarea utilizatorilor si securitatea bazei de date"
						},
						{
							"_nr": "V",
							"__text": "Arhivarea bazei de date Oracle"
						},
						{
							"_nr": "VI",
							"__text": "Optimizarea aplicatiilor bazei de date"
						},
						{
							"_nr": "VII",
							"__text": "Optimizarea bazei de date"
						},
						{
							"_nr": "VIII",
							"__text": "Utilizarea uneltelor si optiunilor aditionale din Oracle"
						}
					]
				},
				"_id": "745",
				"_isbn": "973-98995-9-5"
			},
			{
				"titlu": "Utilizare Visual C++ 6",
				"autori": {
					"autor": [
						{
							"_nume": "Jon Bates"
						},
						{
							"_nume": "Tim Tompkins"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "760",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "335",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Using Visual C++ 6",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Lucian Limona"
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizare_Visual_Cpp_6_Bates.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Crearea aplicatiilor Visual C++"
						},
						{
							"_nr": "II",
							"__text": "Casete de dialog si controale"
						},
						{
							"_nr": "III",
							"__text": "Elmentele unei aplicatii"
						},
						{
							"_nr": "IV",
							"__text": "Desenarea elementelor grafice"
						},
						{
							"_nr": "V",
							"__text": "Tehnici avansate de lucru cu documente si vederi"
						},
						{
							"_nr": "VI",
							"__text": "Transferul datelor aplicatiei"
						},
						{
							"_nr": "VII",
							"__text": "Subiecte avansate"
						}
					]
				},
				"_id": "746",
				"_isbn": "973-20-0306-5"
			},
			{
				"titlu": "ORACLE de la 9i la 11g pentru dezvoltatorii de aplicatii. Dezvoltarea de aplicatii cu intrumentele lui ORACLEDS (Volumul 2, partea I-a)",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Maftei"
						},
						{
							"_nume": "Catalin Maftei"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "332",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "238",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Eugen Maftei",
						"Catalin Maftei"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Oracle_de_la_9i_la_11g_pentru_dezvoltatorii_de_aplicatii_Dezvoltarea_de_aplicatii_cu_instrumentele_lui_OracleDS_Vol_2_partea_I_Matei.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Dezvoltarea rapida de aplicatii in Oracle"
						},
						{
							"_nr": "2",
							"__text": "Forme in ORACLE"
						},
						{
							"_nr": "3",
							"__text": "Crearea si executia unor forme simple"
						},
						{
							"_nr": "4",
							"__text": "Triggere si subprograme utilizator in forme"
						},
						{
							"_nr": "5",
							"__text": "Liste, butoane radio, boxe de control, butoane de comanda si articole calculate in forme"
						},
						{
							"_nr": "6",
							"__text": "Obiecte de nivel inalt in forme si forme multibloc"
						}
					]
				},
				"_id": "747",
				"_isbn": "978-973-650-270-5"
			},
			{
				"titlu": "ORACLE de la 9i la 11g pentru dezvoltatorii de aplicatii. Dezvoltarea de aplicatii cu intrumentele lui ORACLEDS (Volumul 2, partea II-a)",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Maftei"
						},
						{
							"_nume": "Catalin Maftei"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "340",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "238",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Eugen Maftei",
						"Catalin Maftei"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Oracle_de_la_9i_la_11g_pentru_dezvoltatorii_de_aplicatii_Dezvoltarea_de_aplicatii_cu_instrumentele_lui_OracleDS_Vol_2_partea_II_Matei.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "7",
							"__text": "Facilitati avansate in forme"
						},
						{
							"_nr": "8",
							"__text": "Introducere in rapoarte ORACLE"
						},
						{
							"_nr": "9",
							"__text": "Facilitati Reports Builder"
						},
						{
							"_nr": "10",
							"__text": "Crearea si modificarea manuala a rapoartelor"
						},
						{
							"_nr": "11",
							"__text": "Parametrizarea, executia si distributia rapoartelor"
						},
						{
							"_nr": "12",
							"__text": "Construirea unei aplicatii ORACLE cu meniu utilizator"
						}
					]
				},
				"_id": "748",
				"_isbn": "978-973-650-272-9"
			},
			{
				"titlu": "Principiile inteligentei artificiale",
				"autori": {
					"autor": {
						"_nume": "D. Dumitrescu"
					}
				},
				"anAparitie": "2002",
				"editura": "Albastra",
				"numarPagini": "290",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "91",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Principiile_inteligentei_artificiale_Dumitrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Rezolvarea problemelor. Cautare"
						},
						{
							"_nr": "2",
							"__text": "Rezolvarea problemelor prin cautarea unui minim"
						},
						{
							"_nr": "3",
							"__text": "Cautarea unei solutii. Agenti inteligenti"
						},
						{
							"_nr": "4",
							"__text": "Elemente de logica clasica"
						},
						{
							"_nr": "5",
							"__text": "Demonstrarea automata a teoremelor"
						},
						{
							"_nr": "6",
							"__text": "Reprezentarea cunoasterii"
						},
						{
							"_nr": "7",
							"__text": "Multimi nunatate si rationament aproximativ"
						},
						{
							"_nr": "8",
							"__text": "Sisteme expert si control inteligent"
						},
						{
							"_nr": "9",
							"__text": "Invatarea in sistemele cu inteligenta artificala"
						},
						{
							"_nr": "10",
							"__text": "Retele neuronale. Principii generale"
						},
						{
							"_nr": "11",
							"__text": "Memorii asociate"
						},
						{
							"_nr": "12",
							"__text": "Modelul Hopfield de retea neuronala"
						}
					]
				},
				"_id": "749",
				"_isbn": "973-9443-06-0"
			},
			{
				"titlu": "Initiere in programarea logica. Aplicatii in PROLOG",
				"autori": {
					"autor": [
						{
							"_nume": "Mariana Neagu"
						},
						{
							"_nume": "Cristina Luca"
						},
						{
							"_nume": "Auro Podoreanu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "180",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "91",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_programarea_logica_Aplicatii_in_Prolog_Neagu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in programarea logica - limbajul Prolog"
						},
						{
							"_nr": "2",
							"__text": "Programare in Prolog"
						},
						{
							"_nr": "3",
							"__text": "Probleme rezolvate"
						}
					]
				},
				"_id": "750",
				"_isbn": "973-650-169-X"
			},
			{
				"titlu": "Sisteme inteligente. Instruire automata",
				"autori": {
					"autor": {
						"_nume": "Gabriela Czibula"
					}
				},
				"anAparitie": "2008",
				"editura": "Risoprint",
				"numarPagini": "220",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_inteligente_Instruire_automata_Czibula.jpg"
					},
					"__text": "Petru Dragan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme inteligente"
						},
						{
							"_nr": "2",
							"__text": "Cautare"
						},
						{
							"_nr": "3",
							"__text": "Planificare"
						},
						{
							"_nr": "4",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "5",
							"__text": "Agenti si agenti inteligenti"
						},
						{
							"_nr": "6",
							"__text": "Invatare"
						},
						{
							"_nr": "7",
							"__text": "Modele matematice pentru agenti inteligenti care invata"
						}
					]
				},
				"_id": "751",
				"_isbn": "978-973-751-899-6"
			},
			{
				"titlu": "Sisteme mutliagent in inteligenta artificala distribuita. Arhitecturi si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Gabriela Serban"
					}
				},
				"anAparitie": "2006",
				"editura": "Risoprint",
				"numarPagini": "300",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_multiagent_in_inteligenta_artificiala_distribuita_Arhitecturi_si_aplicatii_Serban.jpg"
					},
					"__text": "Voichita-Maria Clinci"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Agenti si agenti inteligenti"
						},
						{
							"_nr": "2",
							"__text": "Problematica agentilor software inteligenti"
						},
						{
							"_nr": "3",
							"__text": "Arhitecturi de agenti inteligenti"
						},
						{
							"_nr": "4",
							"__text": "Sisteme multiagent si societati de agenti"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi de cautare pentru agenti"
						},
						{
							"_nr": "6",
							"__text": "Planificare"
						},
						{
							"_nr": "7",
							"__text": "Invatare"
						},
						{
							"_nr": "8",
							"__text": "Metode formale in iad. Reprezentarea bazata pe logica si rationamentul in sistemele bazate pe agenti"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii ale agentilor inteligenti si ale sistemelor multiagent"
						},
						{
							"_nr": "10",
							"__text": "Implementarea unui sistem multiagent"
						}
					]
				},
				"_id": "752",
				"_isbn": "978-973-751-194-2"
			},
			{
				"titlu": "Programare in limbaj de asamblare X86. Lucrari practice",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Lupu"
						},
						{
							"_nume": "Annamaria Mesaros"
						},
						{
							"_nume": "Simina Moldovan"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Risoprint",
				"numarPagini": "122",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Programare_in_limbaj_de_asamblare_X86_Lucrati_practice_Lupu.jpg"
					},
					"__text": "Voichita-Maria Clinci"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in microprocesoare"
						},
						{
							"_nr": "2",
							"__text": "Simulator de microprocesor(I)"
						},
						{
							"_nr": "3",
							"__text": "Simulator de microprocesor(II)"
						},
						{
							"_nr": "4",
							"__text": "Microprocesorul 8086"
						},
						{
							"_nr": "5",
							"__text": "Setul de instructiuni 8086 (I)"
						},
						{
							"_nr": "6",
							"__text": "Setul de instructiuni 8086 (II)"
						},
						{
							"_nr": "7",
							"__text": "Dezvoltarea programelor in limbaj de asamblare(I)"
						},
						{
							"_nr": "8",
							"__text": "Dezvoltarea programelor in limbaj de asamblare(II)"
						},
						{
							"_nr": "9",
							"__text": "Subrutine, intreruperi si servicii"
						},
						{
							"_nr": "10",
							"__text": "Interfatarea aplicatiilor in limbaj de asamblare cu sistemul de operare"
						},
						{
							"_nr": "11",
							"__text": "Interfatarea limbajului de asamblare cu limbajul C"
						},
						{
							"_nr": "12",
							"__text": "Setul extins de instructiuni X86"
						}
					]
				},
				"_id": "753",
				"_isbn": "978-973-751-146-8"
			},
			{
				"titlu": "Sa invatam pas cu pas C++",
				"autori": {
					"autor": {
						"_nume": "Martin L. Rinehart"
					}
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "480",
				"pretOriginal": "35000",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Learn C++ Today!",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": [
							"Tudor Balanescu",
							"Silvia Candea",
							"Gheorghe Marian"
						]
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Sa_invatam_Cpp_pas_cu_pas_Rinehart.jpg"
					},
					"__text": "Margareta Chilim"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sa invatam C++"
						},
						{
							"_nr": "2",
							"__text": "Spune 'Hello World'"
						},
						{
							"_nr": "3",
							"__text": "Sa desenam casete"
						},
						{
							"_nr": "4",
							"__text": "Crearea unui obiect de tip box"
						},
						{
							"_nr": "5",
							"__text": "Casetele de dialog sunt mostenitoarele casetelor"
						},
						{
							"_nr": "6",
							"__text": "Mai multe casete de dialog: stilul de lucru cu fisiere proiect"
						},
						{
							"_nr": "7",
							"__text": "Un editor pentru o singura linie"
						},
						{
							"_nr": "8",
							"__text": "Alte functii ale editorului de linie"
						},
						{
							"_nr": "9",
							"__text": "Citirea fisierelor text"
						},
						{
							"_nr": "10",
							"__text": "Afisarea fisierelor text"
						},
						{
							"_nr": "11",
							"__text": "Redimensionarea, deplasarea si derularea ferestrelor"
						},
						{
							"_nr": "12",
							"__text": "Editarea cu Tiny Editor"
						},
						{
							"_nr": "13",
							"__text": "Manipularea grupurilor de linii"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea multi-fisierelor"
						},
						{
							"_nr": "15",
							"__text": "Concluzii"
						}
					]
				},
				"_id": "754",
				"_isbn": "973-31-1153-8"
			},
			{
				"titlu": "Microcalculatoarele FELIX M18, M18B, M118 (Vol I)",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Trandafir Moisa"
						},
						{
							"_nume": "Andrei Gayraud"
						},
						{
							"_nume": "Nicolae Tapus"
						},
						{
							"_nume": "Constantin Botez"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Tehnica",
				"numarPagini": "288",
				"pretOriginal": "23",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Stela Serbanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Mirocalculatoarele_FELIX_M18_M18B_M118_Vol_I_Petrescu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala a microcalculatoarelor FELIX M18, M18B, M118"
						},
						{
							"_nr": "2",
							"__text": "Structura"
						},
						{
							"_nr": "3",
							"__text": "Modul de operare"
						},
						{
							"_nr": "4",
							"__text": "Programul monitor - descriere si utilizare"
						},
						{
							"_nr": "5",
							"__text": "Subsistemul de I/E si echipamente periferice standard"
						}
					]
				},
				"_id": "755",
				"_isbn": ""
			},
			{
				"titlu": "Analiza si prelucrarea secventelor video: indexarea automata dupa continut",
				"autori": {
					"autor": {
						"_nume": "Bodgan Ionescu"
					}
				},
				"anAparitie": "2009",
				"editura": "Tehnica",
				"numarPagini": "268",
				"pretOriginal": "218000",
				"pretCumparare": "218000",
				"coperta": {
					"imagine": {
						"_path": "Analiza_si_prelucrarea_secventelor_video_Indexarea_automata_dupa_continut_Ionescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conceptul de indexare dupa continut"
						},
						{
							"_nr": "2",
							"__text": "Segmentarea temporala"
						},
						{
							"_nr": "3",
							"__text": "Analiza miscarii"
						},
						{
							"_nr": "4",
							"__text": "Analiza de culoare"
						},
						{
							"_nr": "5",
							"__text": "Rezumarea automata de continut"
						},
						{
							"_nr": "6",
							"__text": "Formalizarea fuzzy"
						},
						{
							"_nr": "7",
							"__text": "Clasificarea dupa continut a datelor"
						}
					]
				},
				"_id": "756",
				"_isbn": "978-973-31-2354-5"
			},
			{
				"titlu": "Bioinformatica (Vol.II)",
				"autori": {
					"autor": [
						{
							"_nume": "Manuela Elisabeta Sidoroff"
						},
						{
							"_nume": "Iulia Stanciu"
						},
						{
							"_nume": "Voicu Boscaiu"
						},
						{
							"_nume": "Alina Butu"
						},
						{
							"_nume": "Marian Butu"
						},
						{
							"_nume": "Andrei Paun"
						},
						{
							"_nume": "Andrei Bancila"
						},
						{
							"_nume": "Bianca Popa"
						},
						{
							"_nume": "Stefan Stefanescu"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Tehnica",
				"tiraj": "200",
				"numarPagini": "132",
				"pretOriginal": "87200",
				"pretCumparare": "87200",
				"coperta": {
					"imagine": {
						"_path": "Bioinformatica_II_Sidoroff.jpg"
					},
					"__text": "Andreea Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Matrialul genetic"
						},
						{
							"_nr": "II",
							"__text": "Introducere in verificarea ipotezelor statistice pentru microarrays"
						},
						{
							"_nr": "III",
							"__text": "Metode de analiza a proteinelor"
						},
						{
							"_nr": "IV",
							"__text": "Calculabilitatea pe baza de membrane si proteine"
						},
						{
							"_nr": "V",
							"__text": "Prognoza evolutiei dinamice a reactiilor chimice"
						},
						{
							"_nr": "VI",
							"__text": "Simularea stochastica a reactiilor chimice - aspecte de implementare"
						}
					]
				},
				"_id": "757",
				"_isbn": "978-973-31-2342-2"
			},
			{
				"titlu": "Modelare geometrica algoritmica",
				"autori": {
					"autor": {
						"_nume": "Emilia Petrisor"
					}
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "366",
				"pretOriginal": "163500",
				"pretCumparare": "163500",
				"coperta": {
					"imagine": {
						"_path": "Modelare_geometrica_algoritmica_Petrisor.jpg"
					},
					"__text": "Roxana Ioana Rosu,\n      Andreea Ioana Radut"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bazele matematice ale CAGD"
						},
						{
							"_nr": "2",
							"__text": "Curve Bezier"
						},
						{
							"_nr": "3",
							"__text": "Cubice Spline definite de cubice Bezier"
						},
						{
							"_nr": "4",
							"__text": "Curbe Bezier rationale"
						},
						{
							"_nr": "5",
							"__text": "Curbe B-Spline"
						},
						{
							"_nr": "6",
							"__text": "Polarizarea curbelor Bezier si B-Spline"
						},
						{
							"_nr": "7",
							"__text": "Suprafete Bezier"
						},
						{
							"_nr": "8",
							"__text": "Suprafete Hermite"
						},
						{
							"_nr": "9",
							"__text": "Suprafete B-Spline"
						},
						{
							"_nr": "10",
							"__text": "Suprafete Bezier si B-Spline rationale"
						},
						{
							"_nr": "11",
							"__text": "Suprafete Bezier triunghiulare"
						},
						{
							"_nr": "12",
							"__text": "Interpolarea unei retele de curbe"
						}
					]
				},
				"_id": "758",
				"_isbn": "973-31-2034-0"
			},
			{
				"titlu": "Secrete Adobe Photoshop 4",
				"autori": {
					"autor": [
						{
							"_nume": "Gary David Bouton"
						},
						{
							"_nume": "Barbara Bouton"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "872",
				"pretOriginal": "180000",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Inside Adobe Photoshop 4",
					"editura": "New Riders Press Corporation",
					"traducatori": {
						"traducator": [
							"Adrian viu Ludosan",
							"Sorin Vicol"
						]
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Secrete_Adobe_Photoshop_4_Bouton.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Explorarea vechilor si noilor facilitati"
						},
						{
							"_nr": "II",
							"__text": "Si acum, la treaba"
						},
						{
							"_nr": "III",
							"__text": "Retusarea imaginilor"
						},
						{
							"_nr": "IV",
							"__text": "Tehnici avansate de editare a imaginilor"
						},
						{
							"_nr": "V",
							"__text": "Efecte speciale in Photoshop"
						},
						{
							"_nr": "VI",
							"__text": "Productia de imagini, lucrul cu imagini in format www...si altele"
						}
					]
				},
				"_id": "759",
				"_isbn": "973-601-866-0"
			},
			{
				"titlu": "Analiza statistica folosind limbajul R",
				"autori": {
					"autor": [
						{
							"_nume": "Andrei Paun"
						},
						{
							"_nume": "Mihaela Paun"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "MatrixRom",
				"numarPagini": "196",
				"pretOriginal": "300000",
				"pretCumparare": "300000",
				"coperta": {
					"imagine": {
						"_path": "Analiza_statistica_folosind_limbajul_R_Paun.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in limbajul R"
						},
						{
							"_nr": "2",
							"__text": "Metode grafice de analiza datelor"
						},
						{
							"_nr": "3",
							"__text": "Masuri de tendinta centrala, dispersie si cuartile"
						},
						{
							"_nr": "4",
							"__text": "Inferenta statistica pentru un singur esantion"
						},
						{
							"_nr": "5",
							"__text": "Inferenta statistica pentru doua esantioane"
						},
						{
							"_nr": "6",
							"__text": "Inferenta statistica pentru 3 sau mai multe esantioane"
						},
						{
							"_nr": "7",
							"__text": "Regresie liniara si corelatii intre doua variabile"
						},
						{
							"_nr": "8",
							"__text": "Analiza supravietuirii (Survival analysis)"
						},
						{
							"_nr": "9",
							"__text": "Analiza seriilor temporale in R"
						},
						{
							"_nr": "10",
							"__text": "Indici de inegalitate economica"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Pachete R folosite"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Tabelele testelor statistice"
						}
					]
				},
				"_id": "760",
				"_isbn": "978-973-755-514-4"
			},
			{
				"titlu": "Notiuni aplicate de inginerie a sistemelor de programe",
				"autori": {
					"autor": [
						{
							"_nume": "Anca Daniela Ionita"
						},
						{
							"_nume": "Alexandra Cernian"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "MatrixRom",
				"numarPagini": "138",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Notiuni_aplicate_de_inginerie_a_sistemelor_de_programe_Ionita.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza pentru analiza sistemelor de programe"
						},
						{
							"_nr": "2",
							"__text": "Elemente de proiectare a diagramelor de clase"
						},
						{
							"_nr": "3",
							"__text": "Modalitati de realizare a persistentei datelor"
						},
						{
							"_nr": "4",
							"__text": "Implementarea in Java a modelelor UML"
						},
						{
							"_nr": "5",
							"__text": "Modalitati de implemetare a persistentei datelor"
						},
						{
							"_nr": "6",
							"__text": "Salvarea versiunilor de cod"
						},
						{
							"_nr": "7",
							"__text": "Testarea codului Java"
						}
					]
				},
				"_id": "761",
				"_isbn": "978-973-755-526-7"
			},
			{
				"titlu": "Algoritmi de triangularizare",
				"autori": {
					"autor": [
						{
							"_nume": "Marian Ioan Munteanu"
						},
						{
							"_nume": "Ana Irina Nistor"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Case Editoriala Demiug",
				"numarPagini": "174",
				"pretOriginal": "272500",
				"pretCumparare": "272500",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_de_triangularizare_Munteanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "O abordare generala asupra metodelor de triangularizare"
						},
						{
							"_nr": "3",
							"__text": "Triangulari optime"
						},
						{
							"_nr": "4",
							"__text": "Triagularizarea suprafetelor curbe"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii"
						},
						{
							"_nr": "6",
							"__text": "Programe MATLAB"
						}
					]
				},
				"_id": "762",
				"_isbn": "973-152-059-7"
			},
			{
				"titlu": "Sintaxa determinatorilor: Analiza ligvistica si aplicatie computationala",
				"autori": {
					"autor": {
						"_nume": "Ana-Maria Barbu"
					}
				},
				"anAparitie": "2004",
				"editura": "ALL Eductional",
				"numarPagini": "216",
				"pretOriginal": "299000",
				"pretCumparare": "299000",
				"redactori": {
					"redactor": "E. Tomescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sintaxa_determinantorilor_Analiza_lingvistica_si_aplicatie_computationala_Barbu.jpg"
					},
					"__text": "Stelian Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Teoria X-Bara"
						},
						{
							"_nr": "2",
							"__text": "Teoria ligvistica HPSG"
						},
						{
							"_nr": "3",
							"__text": "Sintaxa determinatorilor"
						},
						{
							"_nr": "4",
							"__text": "Descrierea formala in cadrul HPSG a sintaxei determinatorilor"
						},
						{
							"_nr": "5",
							"__text": "Concluzii"
						}
					]
				},
				"_id": "763",
				"_isbn": "973-684-619-9"
			},
			{
				"titlu": "Culegere de probleme C",
				"autori": {
					"autor": {
						"_nume": "Lucian Vasiu"
					}
				},
				"anAparitie": "1995",
				"editura": "GIL",
				"numarPagini": "96",
				"pretOriginal": "80000",
				"pretCumparare": "80000",
				"coperta": {
					"imagine": {
						"_path": "Culegere_de_probleme_C_Vasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "",
							"__text": "Prefata"
						},
						{
							"_nr": "",
							"__text": "Programe"
						}
					]
				},
				"_id": "764",
				"_isbn": "973-97135-7-2"
			},
			{
				"titlu": "Informatica:teste grila:varianta Pascal:pentru liceu si bacalaureat",
				"autori": {
					"autor": {
						"_nume": "Daniela Dinculescu"
					}
				},
				"anAparitie": "2005",
				"editura": "CARMINIS",
				"numarPagini": "88",
				"pretOriginal": "80000",
				"pretCumparare": "80000",
				"redactori": {
					"redactor": "Carmen Joldescu"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Dragos Dinculescu",
						"Marius Harzoiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_teste_grila_varianta_Pascal_pentru_liceu_si_bacalaureat_Dinculescu.jpg"
					},
					"__text": "Giorgian Gingut"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "II",
							"__text": "Structuri de control (pseudocod si limbaj Turbo Pascal)"
						},
						{
							"_nr": "III",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "IV",
							"__text": "Fisiere text"
						},
						{
							"_nr": "V",
							"__text": "Subprograme"
						},
						{
							"_nr": "VI",
							"__text": "Recursivitate"
						},
						{
							"_nr": "VII",
							"__text": "Tehnici de programare"
						},
						{
							"_nr": "VIII",
							"__text": "Structuri de date alocate dinamic"
						},
						{
							"_nr": "IX",
							"__text": "Grafuri"
						}
					]
				},
				"_id": "765",
				"_isbn": "973-7826-70-1"
			},
			{
				"titlu": "Proiectarea bazelor de date",
				"autori": {
					"autor": {
						"_nume": "Michael J. Hernandez"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "402",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "487",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Database Design for Mere Mortals: A Hand-on Guide to Relational Database Design",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": [
							"Radu Biris",
							"Mihai Manastireanu"
						]
					},
					"_an": "2003",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_bazelor_date_Hernandez.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Proiectarea bazelor de date relationale"
						},
						{
							"_nr": "1",
							"__text": "Baza de date relationala"
						},
						{
							"_nr": "2",
							"__text": "Obiective de proiectare"
						},
						{
							"_nr": "3",
							"__text": "Terminologie"
						},
						{
							"_nr": "Partea II",
							"__text": "Procesul de proiectare"
						},
						{
							"_nr": "4",
							"__text": "O vedere de ansamblu a conceptelor"
						},
						{
							"_nr": "5",
							"__text": "Initierea procesului"
						},
						{
							"_nr": "6",
							"__text": "Analiza bazei de date curente"
						},
						{
							"_nr": "7",
							"__text": "Stabilirea structurilor de tabel"
						},
						{
							"_nr": "8",
							"__text": "Chei"
						},
						{
							"_nr": "9",
							"__text": "Specificatii de camp"
						},
						{
							"_nr": "10",
							"__text": "Relatii intre tabele"
						},
						{
							"_nr": "11",
							"__text": "Reguli de desfasurare a activitatii"
						},
						{
							"_nr": "12",
							"__text": "Vederi"
						},
						{
							"_nr": "13",
							"__text": "Revizuirea integritatii datelor"
						},
						{
							"_nr": "Partea III",
							"__text": "Alte probleme legate de proiectarea unei baze de date"
						},
						{
							"_nr": "14",
							"__text": "Proiectare gresita - ce sa nu faceti"
						},
						{
							"_nr": "15",
							"__text": "Adaptarea sau incarcarea regulilor"
						},
						{
							"_nr": "Partea IV",
							"__text": "Anexe"
						},
						{
							"_nr": "Anexa A",
							"__text": "Raspunsuri la intrebarile recapitulative"
						},
						{
							"_nr": "Anexa B",
							"__text": "Diagrama procesului de proiectare a unei baza de date"
						},
						{
							"_nr": "Anexa C",
							"__text": "Indrumari de proiectare"
						},
						{
							"_nr": "Anexa D",
							"__text": "Formulare de documentatie"
						},
						{
							"_nr": "Anexa E",
							"__text": "Simboluri din diagrama de proiectare  a bazei de date"
						},
						{
							"_nr": "Anexa F",
							"__text": "Exemple de proiecte"
						},
						{
							"_nr": "Anexa G",
							"__text": "Lecturi recomandate"
						}
					]
				},
				"_id": "766",
				"_isbn": "973-20-0892-X"
			},
			{
				"titlu": "HTML prin exemple",
				"autori": {
					"autor": {
						"_nume": "Teodoru Gugoiu"
					}
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "176",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "353",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "HTML_prin_exemple_Gugoiu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prima pagina Web"
						},
						{
							"_nr": "2",
							"__text": "Culori, fonturi si margini"
						},
						{
							"_nr": "3",
							"__text": "Stiluri pentru blocuri de text"
						},
						{
							"_nr": "4",
							"__text": "Configurarea fonturilor"
						},
						{
							"_nr": "5",
							"__text": "Blocuri de text"
						},
						{
							"_nr": "6",
							"__text": "Liste"
						},
						{
							"_nr": "7",
							"__text": "Tabele"
						},
						{
							"_nr": "8",
							"__text": "Imagini"
						},
						{
							"_nr": "9",
							"__text": "Documentarea unei pagini Web"
						},
						{
							"_nr": "10",
							"__text": "Legaturi"
						},
						{
							"_nr": "11",
							"__text": "Sunete si videoclipuri"
						},
						{
							"_nr": "12",
							"__text": "Harti de imagini"
						},
						{
							"_nr": "13",
							"__text": "Formulare"
						},
						{
							"_nr": "14",
							"__text": "Cadre"
						},
						{
							"_nr": "15",
							"__text": "Stiluri"
						},
						{
							"_nr": "16",
							"__text": "Stiluri. Configurarea fonturilor"
						},
						{
							"_nr": "17",
							"__text": "Stiluri. Setarea textului"
						},
						{
							"_nr": "18",
							"__text": "Stiluri. Configurarea blocurilor de text"
						},
						{
							"_nr": "19",
							"__text": "Straturi. Sintaxa CSS"
						},
						{
							"_nr": "20",
							"__text": "Straturi. Sintaxa LAYER"
						},
						{
							"_nr": "A",
							"__text": "Crearea primei pagini Web"
						},
						{
							"_nr": "B",
							"__text": "Cautarea fisierelor imagine"
						},
						{
							"_nr": "C",
							"__text": "Harti de imagini"
						},
						{
							"_nr": "D",
							"__text": "URL"
						},
						{
							"_nr": "E",
							"__text": "Lista ordonata a etichetelor si atributelor"
						},
						{
							"_nr": "F",
							"__text": "Atribute pentru stiluri"
						},
						{
							"_nr": "G",
							"__text": "Nume de culori"
						}
					]
				},
				"_id": "767",
				"_isbn": "973-20-0455-X"
			},
			{
				"titlu": "Prelucrari de date: teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriel Gorghiu"
						},
						{
							"_nume": "Laura Monica Gorghiu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Bibliotheca",
				"tiraj": "300 exemplare",
				"numarPagini": "206",
				"pretOriginal": "189000",
				"pretCumparare": "189000",
				"tehnoredactori": {
					"tehnoredactor": "Ion Anghel"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrari_de_date_Teorie_si_Aplicatii_Gorchiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Metode experimentale. Notiuni introductive"
						},
						{
							"_nr": "II",
							"__text": "Variabile si relatii intre variabile"
						},
						{
							"_nr": "III",
							"__text": "Scale de masura. Reprezentari grafice"
						},
						{
							"_nr": "IV",
							"__text": "Marimi fizice. Masurarea marimilor fizice. Erori si incertitudini de masurare"
						},
						{
							"_nr": "V",
							"__text": "Ipoteze statistice si verificrea acestora. Metode statistice in cercetarea experimentala. Ierarhizarea factorilor semnificativi ai unui experiment"
						}
					]
				},
				"_id": "768",
				"_isbn": "973-712-181-3"
			},
			{
				"titlu": "Algoritmi: indrumar de laborator",
				"autori": {
					"autor": {
						"_nume": "Dumitru Fanache"
					}
				},
				"anAparitie": "2004",
				"editura": "Bibliotheca",
				"numarPagini": "200",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"tehnoredactori": {
					"tehnoredactor": "Andrei-Luchian Georgescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_Indrumar_de_laborator_Fanache.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Reprezentarea algoritmilor elementari"
						},
						{
							"_nr": "2",
							"__text": "Modalitati de reprezentare a algoritmilor"
						},
						{
							"_nr": "3",
							"__text": "Dezvoltati de programe prin algoritmi elementari"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii privind studiul complexitatii"
						},
						{
							"_nr": "5",
							"__text": "Dezvoltarea de programe folosind notiunea de subprogram"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii cu algoritmi recursivi"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii la metoda divide et impera"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii la tehnica alegerii local optimale"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii la programarea dinamica"
						},
						{
							"_nr": "10",
							"__text": "Aplicatii la tehnica cautarii cu revenire"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii la tehnica ramifica si margineste"
						},
						{
							"_nr": "12",
							"__text": "Aplicatii la algoritmii probabilisti si la algoritmi genetici"
						},
						{
							"_nr": "13",
							"__text": "Bibligrafie"
						}
					]
				},
				"_id": "769",
				"_isbn": "973-712-031-0"
			},
			{
				"titlu": "Metode numerice in electronica: aplicatii in limbajul C",
				"autori": {
					"autor": {
						"_nume": "Ioan Rusu"
					}
				},
				"anAparitie": "1997",
				"editura": "Tehnica",
				"numarPagini": "282",
				"pretOriginal": "0",
				"pretCumparare": "130800",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_in_electronica_Aplicatii_in_limbajul_c_Rusu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Erori"
						},
						{
							"_nr": "2",
							"__text": "Rezolvarea numerica a ecuatiilor algebrice"
						},
						{
							"_nr": "3",
							"__text": "Rezolvarea numerica a sistemelor de ecuatii"
						},
						{
							"_nr": "4",
							"__text": "Derivarea numerica"
						},
						{
							"_nr": "5",
							"__text": "Integrarea numerica"
						},
						{
							"_nr": "6",
							"__text": "Interpolarea"
						},
						{
							"_nr": "7",
							"__text": "Optimizari"
						},
						{
							"_nr": "8",
							"__text": "Rezolvarea ecuatiilor difentiale"
						},
						{
							"_nr": "9",
							"__text": "Rezolvarea numerica a ecuatiilor integrale"
						},
						{
							"_nr": "10",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "11",
							"__text": "Functii speciale"
						},
						{
							"_nr": "12",
							"__text": "Transformata Fourier discreta"
						},
						{
							"_nr": "13",
							"__text": "Sortari"
						}
					]
				},
				"_id": "770",
				"_isbn": "973-31-1146-5"
			},
			{
				"titlu": "Metode numerice",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Bratianu"
						},
						{
							"_nume": "Vasile Bostan"
						},
						{
							"_nume": "Lucian Cojocia"
						},
						{
							"_nume": "Gabriel-Paul Negreanu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "212",
				"pretOriginal": "0",
				"pretCumparare": "109000",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_Bratianu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Generarea si propagarea erorilor"
						},
						{
							"_nr": "3",
							"__text": "Aproximarea functiilor"
						},
						{
							"_nr": "4",
							"__text": "Interpolarea functiilor"
						},
						{
							"_nr": "5",
							"__text": "Integrarea numerica"
						},
						{
							"_nr": "6",
							"__text": "Derivarea numerica"
						},
						{
							"_nr": "7",
							"__text": "Rezolvarea ecuatiilor neliniare si transcendente"
						},
						{
							"_nr": "8",
							"__text": "Calcul matricial"
						},
						{
							"_nr": "9",
							"__text": "Rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "10",
							"__text": "Valori si vectori proprii"
						},
						{
							"_nr": "11",
							"__text": "Rezolvarea ecuatiilor diferentiale ordinare"
						}
					]
				},
				"_id": "771",
				"_isbn": "973-31-0819-7"
			},
			{
				"titlu": "Ghid complet pentru Delphi 2",
				"autori": {
					"autor": [
						{
							"_nume": "Peter Norton"
						},
						{
							"_nume": "John Mueller"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "808",
				"pretOriginal": "150000",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "255",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Peter Norton's Guide to Delphi 2",
					"editura": "Peter Norton",
					"traducatori": {
						"traducator": [
							"Adrian Liviu Ludosan",
							"Sorin Vicol"
						]
					},
					"_an": "1996",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_complet_pentru_Delphi_2_Norton.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Arhitectura limbajului Delphi"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Delphi si Windows"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Mediul Delphi si aplicatiile"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Imbunatatirea mediului Delphi"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "772",
				"_isbn": "973-601-628-5"
			},
			{
				"titlu": "Secrete PC",
				"autori": {
					"autor": {
						"_nume": "Peter Norton"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "640",
				"pretOriginal": "34000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "191",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Peter Norton's Inside the PC",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Dana Haifler"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Secrete_PC_Norton.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Interiorul unui PC"
						},
						{
							"_nr": "2",
							"__text": "Componentele hard ale unui PC"
						},
						{
							"_nr": "3",
							"__text": "Procesorul, creierul calculatorului"
						},
						{
							"_nr": "4",
							"__text": "Controlul traficului prin microprocesor"
						},
						{
							"_nr": "5",
							"__text": "O privire retrospectiva asupra evolutiei discurilor"
						},
						{
							"_nr": "6",
							"__text": "Discurile vazute prin perspectiva sistemului de operare DOS"
						},
						{
							"_nr": "7",
							"__text": "Detalii suplimentare despre discuri"
						},
						{
							"_nr": "8",
							"__text": "Utilitarele discurilor"
						},
						{
							"_nr": "9",
							"__text": "Stocarea datelor pe discuri amovibile"
						},
						{
							"_nr": "10",
							"__text": "Stocarea datelor pe discuri optice"
						},
						{
							"_nr": "11",
							"__text": "Afisarea imaginilor pe ecran"
						},
						{
							"_nr": "12",
							"__text": "Setul de caractere al PC-ului"
						},
						{
							"_nr": "13",
							"__text": "Tastatura"
						},
						{
							"_nr": "14",
							"__text": "Datele utilizate de calculator"
						},
						{
							"_nr": "15",
							"__text": "\"Bancurile de lucru\" ale memoriei"
						},
						{
							"_nr": "16",
							"__text": "Chestiuni fundamentale legate de componenta BIOS integrata"
						},
						{
							"_nr": "17",
							"__text": "Detalii despre BIOS"
						},
						{
							"_nr": "18",
							"__text": "Rolul sistemului de operare DOS"
						},
						{
							"_nr": "19",
							"__text": "Sistemul de operare DOS in serviciul utilizatorilor"
						},
						{
							"_nr": "20",
							"__text": "Sistemul de operare DOS in serviciul programatorilor"
						},
						{
							"_nr": "21",
							"__text": "Imprimate si alte mijloce de comunicatie"
						},
						{
							"_nr": "22",
							"__text": "Modul de elaborare a programelor"
						},
						{
							"_nr": "23",
							"__text": "Rolul si functiile mediului Microsoft Windows"
						},
						{
							"_nr": "24",
							"__text": "Standardul de interfata Plug and Play"
						},
						{
							"_nr": "25",
							"__text": "Multimedia"
						},
						{
							"_nr": "26",
							"__text": "Exploatarea resurselor si rezolvarea unor probleme"
						},
						{
							"_nr": "Anexa A",
							"__text": "Cum a construit IBM primul calculator personal"
						},
						{
							"_nr": "Anexa B",
							"__text": "Listingurile programelor in QBasic"
						}
					]
				},
				"_id": "773",
				"_isbn": "973-601-547-5"
			},
			{
				"titlu": "Tehnici fundamentale de Prelucrarea si Analiza Imaginilor",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Vertan"
						},
						{
							"_nume": "Mihai Ciuc"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "MatrixRom",
				"numarPagini": "222",
				"pretOriginal": "310000",
				"pretCumparare": "310000",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_fundamentale_de_Prelucrarea_si_Analiza_Imaginilor_Vertan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "I",
							"__text": "Prelucrarea imaginilor"
						},
						{
							"_nr": "2",
							"__text": "Imbunatatirea imaginilor"
						},
						{
							"_nr": "3",
							"__text": "Operatii de vecinatate"
						},
						{
							"_nr": "4",
							"__text": "Transformate unitare"
						},
						{
							"_nr": "II",
							"__text": "Analiza imaginilor"
						},
						{
							"_nr": "5",
							"__text": "Segmentarea orientata pe regiuni"
						},
						{
							"_nr": "6",
							"__text": "Segmentarea orientata pe contururi"
						},
						{
							"_nr": "7",
							"__text": "Caracterizarea texturilor"
						},
						{
							"_nr": "8",
							"__text": "Caracterizarea formelor si contururilor plane"
						},
						{
							"_nr": "III",
							"__text": "Reprezentarea si prelucrarea imaginilor color"
						},
						{
							"_nr": "9",
							"__text": "Descrierea culorilor si modele de prelucrare a imaginilor color"
						},
						{
							"_nr": "10",
							"__text": "Compresia imaginilor"
						}
					]
				},
				"_id": "774",
				"_isbn": "978-973-755-207-5"
			},
			{
				"titlu": "Bazele inteligentei artificiale (1. Logici propozitionale)",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Malita"
						},
						{
							"_nume": "Mircea Malita"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Tehnica",
				"numarPagini": "264",
				"pretOriginal": "22.50",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Tasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_inteligentei_artificiale_Logici_propozitionale_Malita.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Logica clasica a propozitiilor"
						},
						{
							"_nr": "2",
							"__text": "Sistemul deductiv al calculului propozitiilor"
						},
						{
							"_nr": "3",
							"__text": "Latice si algebre booleene"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi"
						},
						{
							"_nr": "5",
							"__text": "Circuite logice"
						},
						{
							"_nr": "6",
							"__text": "Introducere in logica intuitionists si logica  pozitiva"
						},
						{
							"_nr": "7",
							"__text": "Elemente de logica polivalenta"
						},
						{
							"_nr": "8",
							"__text": "Privire asupra logicilor modale"
						},
						{
							"_nr": "9",
							"__text": "Introducere in logica temporala"
						},
						{
							"_nr": "10",
							"__text": "Elemente de logica probabilista"
						}
					]
				},
				"_id": "775",
				"_isbn": ""
			},
			{
				"titlu": "Criptografie cu chei publice",
				"autori": {
					"autor": {
						"_nume": "Arto Salomaa"
					}
				},
				"anAparitie": "1993",
				"editura": "Militara",
				"numarPagini": "256",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Public-Key Cryptography",
					"editura": "Springer-Verlag Heidelberg",
					"traducatori": {
						"traducator": [
							"Lila Kari Santean",
							"Mircea Andrasiu"
						]
					},
					"_an": "1990",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Misu Raileanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Nicolae"
				},
				"coperta": {
					"imagine": {
						"_path": "Criptografie_cu_chei_publice_Salomaa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Criptografia clasica in dublu sens"
						},
						{
							"_nr": "2",
							"__text": "Ideea cheilor publice"
						},
						{
							"_nr": "3",
							"__text": "Sisteme rucsac"
						},
						{
							"_nr": "4",
							"__text": "R.S.A."
						},
						{
							"_nr": "5",
							"__text": "Alte baze pentru criptosisteme"
						},
						{
							"_nr": "6",
							"__text": "Protocoale criptografice. Perspective surprinzatoare in comunicatii"
						},
						{
							"_nr": "Anexa A",
							"__text": "Introducere in teoria complexitatii"
						},
						{
							"_nr": "Anexa B",
							"__text": "Introducere in teoria multimilor"
						}
					]
				},
				"_id": "776",
				"_isbn": "973-32-0-327-0"
			},
			{
				"titlu": "Recunoastera formelor: aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Romul Vancea"
						},
						{
							"_nume": "Stefan Holban"
						},
						{
							"_nume": "Dan Ciubotariu"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "336",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Eugen Marineanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Aurel Budnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Recunoasterea_formelelor_aplicatii_Vancea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Clasificatori decizional-teoretici"
						},
						{
							"_nr": "4",
							"__text": "Clasificatori statistici"
						},
						{
							"_nr": "5",
							"__text": "Selectarea caracteristicilor formelor"
						},
						{
							"_nr": "6",
							"__text": "Indicatii de proiectare pentru sistemele de recunoasterea formelor"
						},
						{
							"_nr": "7",
							"__text": "Tehnici de grupare"
						},
						{
							"_nr": "8",
							"__text": "Recunoastere sintactica (lingvistica) a formelor"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "777",
				"_isbn": "973-27-0015-17"
			},
			{
				"titlu": "Inteligenta artificiala si robotica",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Draganescu"
						},
						{
							"_nume": "Ion Coteanu"
						},
						{
							"_nume": "Constantin Arseni"
						},
						{
							"_nume": "C. Balaceanu-Stolnici"
						},
						{
							"_nume": "Cristian Calude"
						},
						{
							"_nume": "Gheorghe Paun"
						},
						{
							"_nume": "Dumitru Constantin"
						},
						{
							"_nume": "Alexandru Vilan"
						},
						{
							"_nume": "Solomon Marcus"
						},
						{
							"_nume": "Edmond Nicolau"
						},
						{
							"_nume": "Radu Michaescu"
						},
						{
							"_nume": "Ion Danaila"
						},
						{
							"_nume": "Lucretia Vasilescu"
						},
						{
							"_nume": "Radu Nicolescu"
						},
						{
							"_nume": "Ileana Streinu-Brinzoi"
						},
						{
							"_nume": "Dan Tufis"
						},
						{
							"_nume": "Ioan Georgescu"
						},
						{
							"_nume": "Dan D. Farcas"
						},
						{
							"_nume": "Serban A. Basarab"
						},
						{
							"_nume": "Rolanda Predescu"
						},
						{
							"_nume": "Mariana Belis"
						},
						{
							"_nume": "Gheorghe M. Stefan"
						},
						{
							"_nume": "Dolphi Drimer"
						},
						{
							"_nume": "Mihai Barbuceanu"
						},
						{
							"_nume": "Luca-Dan Serbanati"
						},
						{
							"_nume": "Viorel Darie"
						},
						{
							"_nume": "Valentin Cean"
						},
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Ion Crisan"
						},
						{
							"_nume": "Radu Magda"
						},
						{
							"_nume": "Adrian Moanga"
						},
						{
							"_nume": "Ion Dumitrache"
						},
						{
							"_nume": "Aurelian Mihai Stanescu"
						},
						{
							"_nume": "Adrian Davidoviu"
						},
						{
							"_nume": "Gheorghe Draganoiu"
						},
						{
							"_nume": "Iulia Tufis"
						},
						{
							"_nume": "Damian Popescu"
						},
						{
							"_nume": "Florin Teodorescu"
						},
						{
							"_nume": "Gheorghe Pentiuc"
						},
						{
							"_nume": "Dan Mindutianu"
						},
						{
							"_nume": "Gheorghe Tecuci"
						},
						{
							"_nume": "Serban Voinea"
						},
						{
							"_nume": "Nicolae Nicula"
						},
						{
							"_nume": "Horia Giuroiu"
						},
						{
							"_nume": "Valentin Ciuche"
						},
						{
							"_nume": "Damian Popescu"
						},
						{
							"_nume": "Ioan Motoc"
						},
						{
							"_nume": "Radu Hobincu"
						},
						{
							"_nume": "Corneliu Burileanu"
						},
						{
							"_nume": "Valentin Teodorescu"
						},
						{
							"_nume": "Grigore Stolojanu"
						},
						{
							"_nume": "Cezar Radu"
						},
						{
							"_nume": "Mircea Constantinescu"
						},
						{
							"_nume": "Dana Cristescu"
						},
						{
							"_nume": "Adrian Petrecu"
						},
						{
							"_nume": "Adrian Surpateanu"
						},
						{
							"_nume": "Valentin I. Vlad"
						},
						{
							"_nume": "Radu Zaciu"
						},
						{
							"_nume": "Sorin Ionescu"
						},
						{
							"_nume": "Ancuta Breaban"
						},
						{
							"_nume": "Virgil Barban"
						},
						{
							"_nume": "Ileana Rabega"
						},
						{
							"_nume": "Virgil Stancovici"
						},
						{
							"_nume": "Paul Caravia"
						},
						{
							"_nume": "Mihai Barbuceanu"
						},
						{
							"_nume": "Radu Bercaru"
						},
						{
							"_nume": "Gorun Manolescu"
						},
						{
							"_nume": "Adrian Rogoz"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "400",
				"pretOriginal": "43",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ionel Tudor"
				},
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_si_robotica_Draganescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Social Inteligence"
						},
						{
							"_nr": "2",
							"__text": "Structura limbii si inteligenta artifiala"
						},
						{
							"_nr": "3",
							"__text": "Competenta si performanta informationala in organizarea functionala a creierului uman"
						},
						{
							"_nr": "I",
							"__text": "Pshologia si neurologia in raport cu inteligenta artificiala"
						},
						{
							"_nr": "1",
							"__text": "Sisteme inteligente"
						},
						{
							"_nr": "2",
							"__text": "Despre adecvarea unui model gramatical al functionarii creierului"
						},
						{
							"_nr": "3",
							"__text": "Nicelurile inteligentei"
						},
						{
							"_nr": "II",
							"__text": "Limbajul natural si inteligenta artificiala"
						},
						{
							"_nr": "1",
							"__text": "Limbile artificiale ca mijloc de intelegere a celor naturale"
						},
						{
							"_nr": "2",
							"__text": "Natural and artificial languages"
						},
						{
							"_nr": "3",
							"__text": "Proiectul primei banci romanesti de date lingvistice. Aspecte functionale noi: evaluarea automata a datelor sementice"
						},
						{
							"_nr": "4",
							"__text": "Determinarea relatiilor sinonimice de analogie si de asociatie cu ajutorul calculatorului"
						},
						{
							"_nr": "5",
							"__text": "Inferenta gramaticala si invatarea"
						},
						{
							"_nr": "6",
							"__text": "Aspecte ale interectiunii om-masina prin intermediul limbajului natural"
						},
						{
							"_nr": "III",
							"__text": "Probleme fundamentale ale inteligentei artificiale"
						},
						{
							"_nr": "1",
							"__text": "O abordare non-von-Newman a structurii echipamenteleor si programelor in inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Automatul aleator cu utilitati - o metoda de abordare in robotica, neurologie, pshologie si stiinta conducerii"
						},
						{
							"_nr": "3",
							"__text": "Analogie, generalizare, model"
						},
						{
							"_nr": "4",
							"__text": "Echilibrul cognitiv"
						},
						{
							"_nr": "5",
							"__text": "Sisteme intruibile cu memoie asociativa si integrativa"
						},
						{
							"_nr": "6",
							"__text": "Structuri neechilibrate in sisteme de prelucrare a informatiei"
						},
						{
							"_nr": "7",
							"__text": "Informatie tehnologica"
						},
						{
							"_nr": "8",
							"__text": "Structura cognitiva si rezolutiva a sistemelor inteligente de tip expert"
						},
						{
							"_nr": "9",
							"__text": "Ptobleme de arhitectura si reprezentare a cunostiintelor in sistemele experte"
						},
						{
							"_nr": "10",
							"__text": "Proiectarea programelor si inteligenta artificiala"
						},
						{
							"_nr": "11",
							"__text": "Corelarea criteriilor de apreciere a variantelor si procese de autoinstruire in programele pentru jocul de sah"
						},
						{
							"_nr": "12",
							"__text": "Abstractizare si descompunere in proiectarea produselor informatice"
						},
						{
							"_nr": "IV",
							"__text": "Recunoastera formelor, vederea si vorbirea artificiala, robotica"
						},
						{
							"_nr": "1",
							"__text": "Robotii in industria romaneasca"
						},
						{
							"_nr": "2",
							"__text": "Programarea si comanda robotilor industriali romanesti"
						},
						{
							"_nr": "3",
							"__text": "Consideratii asupra alegerii optime a structurilor si algoritmilor de conducere pentru roboti"
						},
						{
							"_nr": "4",
							"__text": "Robotica si inteligenta artifiala"
						},
						{
							"_nr": "5",
							"__text": "Metoda de determinare a acceleratiilor generelizate in functie de paramentrii cinematici ai miscarii comandante"
						},
						{
							"_nr": "6",
							"__text": "Folosirea calculului simbolic in determinarea automata a modelului dinamic pentru structuri mecanice antropomorfice"
						},
						{
							"_nr": "7",
							"__text": "Concept privind cercetarea sistemelor multidimensionale prin metode de analiza si recunoasterea formelor"
						},
						{
							"_nr": "8",
							"__text": "Programarea textuala a robotilor"
						},
						{
							"_nr": "9",
							"__text": "Sistem de analiza si sinteza si recunoastere a formelor"
						},
						{
							"_nr": "10",
							"__text": "Sistem cu logica programata pentru recunoasterea cuvintelor izolate, independent de vorbitor"
						},
						{
							"_nr": "11",
							"__text": "Tehnici de determinare a frecventei fundamentele a semnalului vocal, implementatate in logica programata"
						},
						{
							"_nr": "12",
							"__text": "Prelucrarea si recunoasterea semnalelor vocale cu ajutorul micro- si minicalculatoarelor de productie romaneasca Felix M-18 si Coral 285"
						},
						{
							"_nr": "13",
							"__text": "Sisteme de vedere artificiala pentru roboti industriali"
						},
						{
							"_nr": "14",
							"__text": "Sistem pentru achizitie si prelucrare de imagini"
						},
						{
							"_nr": "15",
							"__text": "Object recognition using optoelectronic coding and decoding oriented toward robot vision"
						},
						{
							"_nr": "16",
							"__text": "Asupra unor transformari aplicate in sistemele de prelucrare si analiza a imaginilor"
						},
						{
							"_nr": "17",
							"__text": "Metode si dispozitive pentru recunoasterea formelor in identificarea dactiloscopica"
						},
						{
							"_nr": "18",
							"__text": "Aspecte ale comunicarii om-masina in sistemele de recunoastere automata a formelor vizuale"
						},
						{
							"_nr": "19",
							"__text": "Recunoasterea formelor in geofizica: prelucrarea prin migrare a sectiunilor seismice"
						},
						{
							"_nr": "20",
							"__text": "Un model de reprezentare a formelor geometice"
						},
						{
							"_nr": "V",
							"__text": "Filosofia informatiei"
						},
						{
							"_nr": "1",
							"__text": "Filosofia informatiei"
						},
						{
							"_nr": "2",
							"__text": "Informatia ca principiu organizational"
						},
						{
							"_nr": "3",
							"__text": "Despre statutul epistemologic al inteligentei artificiale"
						},
						{
							"_nr": "4",
							"__text": "Bazele teoretice ale sistemelor informatice - probleme actuale si de perspectiva"
						},
						{
							"_nr": "5",
							"__text": "Cele trei lumi ale lui Karl R. Popper si triada ontologica a materie"
						}
					]
				},
				"_id": "778",
				"_isbn": ""
			},
			{
				"titlu": "Mecanisme formale pentru specificarea limbajelor",
				"autori": {
					"autor": {
						"_nume": "Teodor Rus"
					}
				},
				"anAparitie": "1983",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "478",
				"pretOriginal": "48",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Melus Tureac"
				},
				"coperta": {
					"imagine": {
						"_path": "Mecanisme_formale_pentru_specificarea_limbajelor_Rus.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Conceptul de limbaj. Limbaj de programare"
						},
						{
							"_nr": "2",
							"__text": "Gramtica: mecanism de specificare a limbajelor de programare"
						},
						{
							"_nr": "3",
							"__text": "Politici si algoritmi de analiza pentru limbaje context liber"
						},
						{
							"_nr": "4",
							"__text": "Reprezentarea finita a limbajelor context-liber"
						},
						{
							"_nr": "5",
							"__text": "Dezambiguizarea limbajelor context-liber"
						},
						{
							"_nr": "6",
							"__text": "Specificarea limbajelor de programare cu ajutorul ierarhiei HAS"
						},
						{
							"_nr": "7",
							"__text": "Constructii intr-o ierarhie HAS"
						}
					]
				},
				"_id": "779",
				"_isbn": ""
			},
			{
				"titlu": "Programarea microcalculatoarelor in sistemul de operare CP/M",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Moraru"
						},
						{
							"_nume": "Mihai Atodiroaei"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "384",
				"pretOriginal": "33",
				"redactori": {
					"redactor": "Virgil Spulber"
				},
				"tehnoredactori": {
					"tehnoredactor": "Angela Ilovan"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_microcalculatoarelor_si_sistemul_de_operare_CPM_Moraru.jpg"
					},
					"__text": "Christian Nicolescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in sistemul de operare CP/M"
						},
						{
							"_nr": "2",
							"__text": "Organizarea interna a sistemului CP/M"
						},
						{
							"_nr": "3",
							"__text": "Programe utilitare in sistemul CP/M"
						},
						{
							"_nr": "4",
							"__text": "Editoare de texte utilizate in CP/M"
						},
						{
							"_nr": "5",
							"__text": "Programe pentru utilizarea limbajului masina"
						},
						{
							"_nr": "6",
							"__text": "Programare in limbajul masina 8080 si Z80"
						},
						{
							"_nr": "7",
							"__text": "Intrari-iesiri in limbaj masina in CP/M"
						},
						{
							"_nr": "8",
							"__text": "Programare in limbajul C sub CP/M"
						},
						{
							"_nr": "9",
							"__text": "Programare in limbajul Pascal sub CP/M"
						},
						{
							"_nr": "10",
							"__text": "Programare in limbajul Fortran sub CP/M"
						},
						{
							"_nr": "11",
							"__text": "Programare in limbajul Basic sub CP/M"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea programului de gestiune DBASE"
						}
					]
				},
				"_id": "780",
				"_isbn": "973-29-0024-5"
			},
			{
				"titlu": "Algoritmi de programare paralela si distribuita. Lucrari practice",
				"autori": {
					"autor": [
						{
							"_nume": "Petre Anghelescu"
						},
						{
							"_nume": "Ilie Popa"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "MatrixRom",
				"numarPagini": "202",
				"pretOriginal": "290000",
				"pretCumparare": "290000",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_de_programare_paralela_si_distribuita_Lucrari_practice_Anghelescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lucrarea 1",
							"__text": "Introducere in programare paralela si distribuita - standardul MPI"
						},
						{
							"_nr": "Lucrarea 2",
							"__text": "Programare paralela si distribuita in MPI"
						},
						{
							"_nr": "Lucrarea 3",
							"__text": "Algoritmi paraleli de inmultire a matricelor cu vectori"
						},
						{
							"_nr": "Lucrarea 4",
							"__text": "Algoritmi paralei de inmultire a matricelor"
						},
						{
							"_nr": "Lucrarea 5",
							"__text": "Algoritmi paralei de sortare"
						},
						{
							"_nr": "Lucrarea 6",
							"__text": "Algoritmi paralei pentru prelucrare grafuri"
						}
					]
				},
				"_id": "781",
				"_isbn": "978-973-755-435-2"
			},
			{
				"titlu": "40 de jocuri logice in Basic",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Gheorghe Paun"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Recoop",
				"numarPagini": "160",
				"pretOriginal": "150",
				"redactori": {
					"redactor": [
						"Mihaela Ionescu",
						"Emilia Teodoru"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "40_de_jocuri_logice_in_Basic_Diamandi.jpg"
					},
					"__text": "Paula Vladescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Jocuri logice competitive"
						},
						{
							"_nr": "2",
							"__text": "Jocuri logice solitare"
						},
						{
							"_nr": "3",
							"__text": "Jocuri distractive"
						},
						{
							"_nr": "4",
							"__text": "Jocuri didactice"
						},
						{
							"_nr": "5",
							"__text": "Jocuri de reflexe"
						},
						{
							"_nr": "6",
							"__text": "Jocuri de aventura"
						},
						{
							"_nr": "7",
							"__text": "Alte jocuri"
						},
						{
							"_nr": "8",
							"__text": "Tehnici si artificii pentru programarea jocurilor in limbajul BASIC"
						}
					]
				},
				"_id": "782",
				"_isbn": ""
			},
			{
				"titlu": "Utilitare Norton",
				"autori": {
					"autor": [
						{
							"_nume": "Sanda Popescu"
						},
						{
							"_nume": "Mihaela Nicoara"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Memorii SRL",
				"numarPagini": "32",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Utilitare_Norton_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Norton Commander"
						},
						{
							"_nr": "2",
							"__text": "Norton Utilities"
						}
					]
				},
				"_id": "783",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul de operare MIX. Fortran-77. Manual de operere",
				"autori": {
					"autor": {
						"_nume": "[ITC]"
					}
				},
				"anAparitie": "1986",
				"editura": "ITC",
				"numarPagini": "148",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_MIX_Fortran_77_Manual_de_operere_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala a modului de operare"
						},
						{
							"_nr": "2",
							"__text": "Operatii de intrare/iesire in FORTRAN-77"
						},
						{
							"_nr": "3",
							"__text": "Interfata cu sistemul de operare"
						},
						{
							"_nr": "4",
							"__text": "Particularitati de implementare a programelor FORTRAN-77"
						},
						{
							"_nr": "5",
							"__text": "Facilitati de programare in limbajul FORTRAN-77"
						},
						{
							"_nr": "Anexa A",
							"__text": "Prezentarea datelor in FORTRAN-77"
						},
						{
							"_nr": "Anaxa B",
							"__text": "Subprograme lae bibliotecii FORTRAN-77"
						},
						{
							"_nr": "Anexa C",
							"__text": "Functiile intrinseci si numele lor generice"
						},
						{
							"_nr": "Anexa E",
							"__text": "Implicatii ale utilizarii comutatorului /F77"
						}
					]
				},
				"_id": "784",
				"_isbn": ""
			},
			{
				"titlu": "LISP: limbajul de programare al inteligentei artificiale",
				"autori": {
					"autor": {
						"_nume": "Ileana Streinu"
					}
				},
				"anAparitie": "1986",
				"editura": "Stiintifica si encilopedica",
				"numarPagini": "264",
				"pretOriginal": "20.50",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Angela Ilovan"
				},
				"coperta": {
					"imagine": {
						"_path": "LISP_Limbajul_de_programare_al_inteligentei_artificiale_Streinu.jpg"
					},
					"__text": "Gheorghe Marinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Atomii, liste si functii primitive"
						},
						{
							"_nr": "3",
							"__text": "Definirea de noi functii, conditionala si evaluatorul"
						},
						{
							"_nr": "4",
							"__text": "Recursivitatea, principala tehnica de programare in LISP"
						},
						{
							"_nr": "5",
							"__text": "Aritmetica si algebra in LISP:functii numerice"
						},
						{
							"_nr": "6",
							"__text": "Liste de asociere si liste de proprietati"
						},
						{
							"_nr": "7",
							"__text": "Tipuri de functii"
						},
						{
							"_nr": "8",
							"__text": "Iteratie prin PROG si MAP"
						},
						{
							"_nr": "9",
							"__text": "Citire, scriere, prelucrari speciale de atomi"
						},
						{
							"_nr": "10",
							"__text": "Anatomia listelor"
						},
						{
							"_nr": "11",
							"__text": "Argumente si valori functionale"
						},
						{
							"_nr": "12",
							"__text": "Rationament si logica LISP"
						}
					]
				},
				"_id": "785",
				"_isbn": ""
			},
			{
				"titlu": "Ghidul Softplus pentru utilizatorii Paradox",
				"autori": {
					"autor": {
						"_nume": "[Softplus]"
					}
				},
				"anAparitie": "1992",
				"editura": "Softplus",
				"numarPagini": "144",
				"pretOriginal": "320",
				"coperta": {
					"imagine": {
						"_path": "Ghidul_Softplus_pentru_utilizatorii_Paradox_Softplus.jpg"
					},
					"__text": "Cristian Alexa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in Paradox"
						},
						{
							"_nr": "II",
							"__text": "Proiectarea unei aplicatii de gestiune a unei baze de date"
						},
						{
							"_nr": "III",
							"__text": "Crearea tabelelor si a videoinformatiilor specifice aplicatiei"
						},
						{
							"_nr": "IV",
							"__text": "Regasirea informatiilor prin metode Query by Example (QBE)"
						},
						{
							"_nr": "V",
							"__text": "Proiectarea rapoartelor si a graficelor de prezentare si analiza rezultatelor"
						},
						{
							"_nr": "VI",
							"__text": "Automatizarea etapelor de realizare a aplicatiilor folosind limbajul PAL (Paradox Application Language)"
						},
						{
							"_nr": "VII",
							"__text": "Interfata Paradox cu alte sisteme de gestiune de date"
						},
						{
							"_nr": "VIII",
							"__text": "Comenzi si functii PAL (Paradox Application Language)"
						}
					]
				},
				"_id": "786",
				"_isbn": "973-95801-5-7"
			},
			{
				"titlu": "Memento Paradox 3.5",
				"autori": {
					"autor": {
						"_nume": "[Softplus]"
					}
				},
				"anAparitie": "1992",
				"editura": "Softplus",
				"numarPagini": "100",
				"pretOriginal": "32",
				"coperta": {
					"imagine": {
						"_path": "Memento_Paradox_3_5_Softplus.jpg"
					},
					"__text": "Cristian Alexa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Memento Paradox 3.5"
						},
						{
							"_nr": "II",
							"__text": "Memento Comenzi PAL"
						},
						{
							"_nr": "III",
							"__text": "Memento functii PAL Principale"
						}
					]
				},
				"_id": "787",
				"_isbn": "973-95801-0-6"
			},
			{
				"titlu": "CP/M. Manual de utilizare",
				"autori": {
					"autor": {
						"_nume": "[ICE FELIX]"
					}
				},
				"anAparitie": "1991",
				"editura": "ICE FELIX",
				"numarPagini": "28",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "CPM_Manual_de_utilizare_ICE_FELIX.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Comenzi"
						},
						{
							"_nr": "3",
							"__text": "Caractere de control CP/M"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de fisiere CP/M"
						},
						{
							"_nr": "5",
							"__text": "Mesaje de eroare CP/M"
						}
					]
				},
				"_id": "788",
				"_isbn": ""
			},
			{
				"titlu": "Ce este si ce va ofera calculatorul IBM-PC (editia IV)",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Carariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Marius Chiorean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "128",
				"pretOriginal": "115",
				"colectia": {
					"_numarul": "1",
					"_nume": ""
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ce_este_si_va_ofera_calculatorul_IBM_PC_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurt istoric al calculatoarelor personale"
						},
						{
							"_nr": "2",
							"__text": "Microprocesoare Intel"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura calculatoarelor de tip IBM PC"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "5",
							"__text": "Limbaje de operare si utilitare"
						},
						{
							"_nr": "6",
							"__text": "Sotware integrat pentru calculatoare"
						},
						{
							"_nr": "7",
							"__text": "Comunicatii"
						},
						{
							"_nr": "8",
							"__text": "Sisteme de productie a informatiilor"
						},
						{
							"_nr": "9",
							"__text": "Instruirea personalului"
						},
						{
							"_nr": "10",
							"__text": "Lista de preturi pentru principalele produse software"
						},
						{
							"_nr": "11",
							"__text": "Ofertele firmei MicroInformatica"
						}
					]
				},
				"_id": "789",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in programarea vizuala in Visual Basic a aplicatiilor Windows",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Curelet-Balan"
					}
				},
				"anAparitie": "1994",
				"editura": "Done",
				"numarPagini": "206",
				"pretOriginal": "4490",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Curelet-Balan"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programarea_visuala_in_Visual_Basic_a_aplicatiilor_Windows_Balan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Forma si proprietatile ei"
						},
						{
							"_nr": "2",
							"__text": "Evenimentele"
						},
						{
							"_nr": "3",
							"__text": "Proiecte, metode, module"
						},
						{
							"_nr": "4",
							"__text": "Afisarea informatiei grafice si textuale"
						},
						{
							"_nr": "5",
							"__text": "Editarea textului in toata zona de lucru a formei"
						},
						{
							"_nr": "6",
							"__text": "Meniuri"
						},
						{
							"_nr": "7",
							"__text": "Optiuni de meniu care deschid cutii de dialog predefinite"
						},
						{
							"_nr": "8",
							"__text": "Controale din cutia de dialog Open"
						},
						{
							"_nr": "9",
							"__text": "Alte controale"
						},
						{
							"_nr": "10",
							"__text": "Calculator de buzunar"
						},
						{
							"_nr": "11",
							"__text": "Efecte vizuale speciale"
						},
						{
							"_nr": "12",
							"__text": "Drag and Drop"
						},
						{
							"_nr": "Anexa A",
							"__text": "Forma FileForm a aplicatiei CardFile si codul ei sursa"
						},
						{
							"_nr": "Anexa B",
							"__text": "Setul de iconae predefinite ale Visula Basic-ului"
						},
						{
							"_nr": "Anexa C",
							"__text": "Ghid utilizare tastatura"
						},
						{
							"_nr": "Anexa D",
							"__text": "Table uzuale"
						},
						{
							"_nr": "Anexa E",
							"__text": "Evenimente, merodele si proprietatile obiectelor Visual Basic"
						}
					]
				},
				"_id": "790",
				"_isbn": "973-96-793-0-7"
			},
			{
				"titlu": "Introducere in cibernetica",
				"autori": {
					"autor": {
						"_nume": "W. Ross Ashby"
					}
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "10000+120 exemplare brosate",
				"numarPagini": "344",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "An Introduction to Cybernetics",
					"editura": "Chapman and Hall Ltd and University Paperbacks",
					"traducatori": {
						"traducator": [
							"Al. Popovici",
							"Sergiu Marcus"
						]
					},
					"_an": "1971",
					"_editia": "III",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_cibernetica_Ashby.jpg"
					},
					"__text": "Marian Nedelcu, Cosma Iurov"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "In ce consta noutatea"
						},
						{
							"_nr": "Partea intai",
							"__text": "Mecanismul"
						},
						{
							"_nr": "2",
							"__text": "Schimbarea"
						},
						{
							"_nr": "3",
							"__text": "Masina determinista"
						},
						{
							"_nr": "4",
							"__text": "Masina cu intrare"
						},
						{
							"_nr": "5",
							"__text": "Stabilitatea"
						},
						{
							"_nr": "6",
							"__text": "Cutia negra"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Varietatea"
						},
						{
							"_nr": "7",
							"__text": "Cantitatea de varietate"
						},
						{
							"_nr": "8",
							"__text": "Transmiterea varietatii"
						},
						{
							"_nr": "9",
							"__text": "Transmiterea neintrerupta"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Reglarea si comanda"
						},
						{
							"_nr": "10",
							"__text": "Reglarea in sistemele biologice"
						},
						{
							"_nr": "11",
							"__text": "Varietatea necesara"
						},
						{
							"_nr": "12",
							"__text": "Regulatorul controlat prin eroare"
						},
						{
							"_nr": "13",
							"__text": "Reglarea sistemului foarte mare"
						},
						{
							"_nr": "14",
							"__text": "Amplificarea reglarii"
						},
						{
							"_nr": "Anexa",
							"__text": "Homeostatul (traducerea paragrafelor 8.1-8.4 din lucrarea 'Design for a brain' de W.R. Ashby)"
						}
					]
				},
				"_id": "791",
				"_isbn": ""
			},
			{
				"titlu": "Informatica (pentru anul II liceu, clase speciale de informatica)",
				"autori": {
					"autor": [
						{
							"_nume": "Petre Preoteasa"
						},
						{
							"_nume": "Maria Lovin"
						},
						{
							"_nume": "Constantin P. Popovici"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Didactica si pedagogica",
				"tiraj": "6100+90 ex. brosate",
				"numarPagini": "304",
				"pretOriginal": "7.55",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Valentin Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ionescu Victoria"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_II_Login.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Bazele programarii calculatoarelor. Limbajul FORTRAN"
						},
						{
							"_nr": "1",
							"__text": "Prelucrarea datelor"
						},
						{
							"_nr": "2",
							"__text": "Evolutia sistemelor de prelucrare a datelor"
						},
						{
							"_nr": "3",
							"__text": "Structura unui calculator"
						},
						{
							"_nr": "4",
							"__text": "Reprezentarea darlor in sistemele de calcul"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi"
						},
						{
							"_nr": "6",
							"__text": "Limbajul Fortran"
						},
						{
							"_nr": "7",
							"__text": "Expresii"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni de atribuire"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni de control"
						},
						{
							"_nr": "10",
							"__text": "Instructiuni de intare/iesire"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Logica matematica"
						},
						{
							"_nr": "1",
							"__text": "Algebra propozitiilor"
						},
						{
							"_nr": "2",
							"__text": "Expresii booleene"
						},
						{
							"_nr": "3",
							"__text": "Functii booleene"
						},
						{
							"_nr": "4",
							"__text": "Calculul propozitiilor"
						},
						{
							"_nr": "5",
							"__text": "Logica predicatelor"
						}
					]
				},
				"_id": "792",
				"_isbn": ""
			},
			{
				"titlu": "Jocuri si matematica (Vol.I)",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Paun"
					}
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "240",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Adina Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Staicu"
				},
				"coperta": {
					"imagine": {
						"_path": "Jocuri_si_matematica_I_Paun.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Extraordinarele probleme ale lui Lewis Carroll"
						},
						{
							"_nr": "2",
							"__text": "Din problemele lui Sam Loyd"
						},
						{
							"_nr": "3",
							"__text": "Catave jocuri de doua persoane"
						},
						{
							"_nr": "4",
							"__text": "Jocuri de o singura persoana"
						},
						{
							"_nr": "5",
							"__text": "Logica in joaca"
						},
						{
							"_nr": "6",
							"__text": "Incoparabilul GO"
						}
					]
				},
				"_id": "793",
				"_isbn": "973-31-1426-X"
			},
			{
				"titlu": "Arta programarii calculatoarelor (Vol.3: Sortare si cautare)",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "680",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "The Art of Computer Programming, Volume 3, Sorting and Searching, Second Edition",
					"editura": "Addison Wesley Logman",
					"traducatori": {
						"traducator": "Mihaela Tarpa"
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "2",
					"_an": "1988"
				},
				"coperta": {
					"imagine": {
						"_path": "Arta_programarii_calculatoarelor_Vol_3_Sortare_si_cautare_Knuth.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "5",
							"__text": "Sortare"
						},
						{
							"_nr": "5.1",
							"__text": "Proprietatile combinatorii ale permutarilor"
						},
						{
							"_nr": "5.2",
							"__text": "Sortare interna"
						},
						{
							"_nr": "5.3",
							"__text": "Sortare optima"
						},
						{
							"_nr": "5.4",
							"__text": "Sortare externa"
						},
						{
							"_nr": "5.5",
							"__text": "Sumar, istoric si bibliografie"
						},
						{
							"_nr": "6",
							"__text": "Cautare"
						},
						{
							"_nr": "6.1",
							"__text": "Cautare secventiala"
						},
						{
							"_nr": "6.2",
							"__text": "Cautare prin comparatie de chei"
						},
						{
							"_nr": "6.3",
							"__text": "Cautare digitala"
						},
						{
							"_nr": "6.4",
							"__text": "Cautare hashing"
						},
						{
							"_nr": "6.5",
							"__text": "Cautare dupa chei secundare"
						}
					]
				},
				"_id": "794"
			},
			{
				"titlu": "Aplicatii in C si C++",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "168",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "241",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_in_C_si_Cpp_2003_Patrut.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului C/C++"
						},
						{
							"_nr": "2",
							"__text": "Tablouri si pointeri"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Structuri si tipuri definite de programator"
						},
						{
							"_nr": "5",
							"__text": "Explorarea fisierelor"
						},
						{
							"_nr": "6",
							"__text": "Algorirmi de teoria grafurilor"
						},
						{
							"_nr": "7",
							"__text": "Grafica in C/C++"
						},
						{
							"_nr": "8",
							"__text": "Programare orientata pe obiecte in Borland C++"
						}
					]
				},
				"_id": "795",
				"_isbn": "973-601-760-5"
			},
			{
				"titlu": "Proiectarea siturilor Web: design si functionalitate",
				"autori": {
					"autor": {
						"_nume": "Sabin Buraga"
					}
				},
				"anAparitie": "2002",
				"editura": "Polirom",
				"numarPagini": "272",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_siturilor_Web_Design_si_functionalitate_Buraga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Spatiul World Wide Web"
						},
						{
							"_nr": "2",
							"__text": "Utilizari si aplicatii Web"
						},
						{
							"_nr": "3",
							"__text": "Afaceri pe Web"
						},
						{
							"_nr": "4",
							"__text": "Crearea paginilor Web"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea unui sit Web"
						}
					]
				},
				"_id": "796",
				"_isbn": "973-681-113-1"
			},
			{
				"titlu": "Cum sa realizam jocuri pe calculator",
				"autori": {
					"autor": {
						"_nume": "Ion Diamandi"
					}
				},
				"anAparitie": "1992",
				"editura": "Agni",
				"numarPagini": "160",
				"pretOriginal": "198",
				"redactori": {
					"redactor": "Mihaela Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Romanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Cum_sa_realizam_jocuri_pe_calculator_Diamandi.jpg"
					},
					"__text": "Andrei Teodorescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fundamente ale realizarii jocurilor pe calculator"
						},
						{
							"_nr": "II",
							"__text": "Incepem sa realizam jocuri"
						},
						{
							"_nr": "III",
							"__text": "Cum se pot realiza desene cu calculatorul"
						},
						{
							"_nr": "IV",
							"__text": "Cum se pot realiza efecte sonore si muzica cu calculatorul"
						},
						{
							"_nr": "V",
							"__text": "Raspunsuri la exercitiile si temele propuse"
						}
					]
				},
				"_id": "797",
				"_isbn": "973-95626-0-4"
			},
			{
				"titlu": "Dictionar explicativ de calculatoare: englez-roman si roman-englez",
				"autori": {
					"autor": [
						{
							"_nume": "Marcel-Teodor Dan"
						},
						{
							"_nume": "anlin-Tavi Mirestean"
						},
						{
							"_nume": "Manual Miclea"
						},
						{
							"_nume": "Cristian Miclea"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "152",
				"pretOriginal": "4500",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_explicativ_de_calculatoare_Ban.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Prima parte",
							"__text": "Englez - Roman"
						},
						{
							"_nr": "A doua parte",
							"__text": "Roman - Englez"
						}
					]
				},
				"_id": "798",
				"_isbn": "973-31-0694-1"
			},
			{
				"titlu": "Limbaje de programare pentru sisteme in timp real",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Davidoviciu"
						},
						{
							"_nume": "Boldur Barbat"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Tehnica",
				"numarPagini": "224",
				"pretOriginal": "23",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Eugenia Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_programare_pentru_sisteme_in_timp_real_Davidoviciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Sisteme de calcul in timp real"
						},
						{
							"_nr": "1",
							"__text": "Specificul aplicatiilor in timp real relectat in programare"
						},
						{
							"_nr": "2",
							"__text": "Caracteristicile limbajelor de programare in timp real"
						},
						{
							"_nr": "3",
							"__text": "Limbajul RTL/2"
						},
						{
							"_nr": "4",
							"__text": "Limbajul FORTRAN"
						},
						{
							"_nr": "5",
							"__text": "Limbajul PASCAL CONCURENT"
						},
						{
							"_nr": "6",
							"__text": "Limbajul EDISON"
						},
						{
							"_nr": "7",
							"__text": "Limbajul MODULA-2"
						},
						{
							"_nr": "8",
							"__text": "Limbajul ADA"
						},
						{
							"_nr": "9",
							"__text": "Extensii de timp real ale unor limbeje uzuale (FORTRAN-77, BASIC)"
						},
						{
							"_nr": "10",
							"__text": "Comparatie intre principalele limbaje in timp real. Compediu. Limbaje TR pe calculatoare din tara"
						},
						{
							"_nr": "11",
							"__text": "Ingineria programarii in timp real"
						}
					]
				},
				"_id": "799",
				"_isbn": ""
			},
			{
				"titlu": "Analiza dispersionala",
				"autori": {
					"autor": {
						"_nume": "Ion Vaduva"
					}
				},
				"anAparitie": "1970",
				"editura": "Tehnica",
				"numarPagini": "264",
				"pretOriginal": "11",
				"coperta": {
					"imagine": {
						"_path": "Analiza_dispersionala_Vaduva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Functii de repartitie uzuale"
						},
						{
							"_nr": "II",
							"__text": "Estimatia parametrilor in cazul modelelor liniare"
						},
						{
							"_nr": "III",
							"__text": "Analiza dispersionala multifactoriala cu factor nealeator"
						},
						{
							"_nr": "IV",
							"__text": "Analiza dispersionala cu factori nealeatori"
						},
						{
							"_nr": "V",
							"__text": "Planificarea experientelor"
						},
						{
							"_nr": "VI",
							"__text": "Discutii asupra ipotezelor fundamentale"
						},
						{
							"_nr": "VII",
							"__text": "Analiza dispersionala multidimensionala"
						}
					]
				},
				"_id": "800",
				"_isbn": ""
			},
			{
				"titlu": "Informatica pentru elevi",
				"autori": {
					"autor": [
						{
							"_nume": "Florian Boian"
						},
						{
							"_nume": "Ioana Chiroreanu"
						},
						{
							"_nume": "Gheorghe Coman"
						},
						{
							"_nume": "Militon Frentiu"
						},
						{
							"_nume": "Seyer Groze"
						},
						{
							"_nume": "Kaza Zoltan"
						},
						{
							"_nume": "Teodor Toadere"
						},
						{
							"_nume": "Leon Timbulea"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "212",
				"pretOriginal": "360",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_elevi_Boian.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistem de calcul"
						},
						{
							"_nr": "2",
							"__text": "Algoritm"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Basic"
						},
						{
							"_nr": "4",
							"__text": "Alte programe"
						},
						{
							"_nr": "5",
							"__text": "Produse program"
						},
						{
							"_nr": "6",
							"__text": "Programe pentru IBM-PC"
						}
					]
				},
				"_id": "801",
				"_isbn": "973-95718-4-0"
			},
			{
				"titlu": "Basic pentru calculatoarele ZX SPECTRUM, HC, TIM-S, COBRA, CIP, JET: Instructiuni, Exercitii, Probleme",
				"autori": {
					"autor": {
						"_nume": "Mircea-Mihail Popovici"
					}
				},
				"anAparitie": "1992",
				"editura": "APH",
				"numarPagini": "170",
				"pretOriginal": "396",
				"redactori": {
					"redactor": "A.Hristev"
				},
				"tehnoredactori": {
					"tehnoredactor": "A.Hristev"
				},
				"coperta": {
					"imagine": {
						"_path": "Basic_pentru_calculatoarele_ZX_SPECTRUM_HC_TIMS_COBRA_CIP_JET_Instructiuni_Exercitii_Probleme_Popovici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductice"
						},
						{
							"_nr": "2",
							"__text": "Limbajul Basic"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni/comenzi pentru executarea, editarea, stergerea, lansarea, operirea, continuarea, incarcarea, salvarea programelor si stergerea ecranului"
						},
						{
							"_nr": "4",
							"__text": "Instructinu pentru introducerea datelor si afisarea rezultatelor"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni de atribuire, salt si pentru rezervarea memoriei. Instructiuni logice si pentru testarea claviaturii"
						},
						{
							"_nr": "6",
							"__text": "Functii standard, dunctii utilizator si subrutine"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni pentru producerea sunetelor si folosirea culorilor"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni grafice"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni de lucru cu memoria"
						},
						{
							"_nr": "10",
							"__text": "Instructiuni pentru pentru porturi, cai, canale, imprimanta, microdrive si dischete"
						},
						{
							"_nr": "11",
							"__text": "Artifici pentru perfectionarea si protejarea programelor"
						},
						{
							"_nr": "12",
							"__text": "Alcatuirea si copierea programelor complexe"
						}
					]
				},
				"_id": "802",
				"_isbn": "973-95175-4-4"
			},
			{
				"titlu": "Limbaje formale si tehnici de compilare: curs pentru invatamant deschis la distanta",
				"autori": {
					"autor": {
						"_nume": "Horia Ciocarlie"
					}
				},
				"anAparitie": "2008",
				"editura": "Univeristatea Politehnica din Timisoara",
				"numarPagini": "102",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_si_tehnici_de_compilare_curs_pentru_invatamant_deschis_la_distanta_Ciocarlie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Definirea si reprezentarea formala a limbajelor de programare"
						},
						{
							"_nr": "3",
							"__text": "Analiza lexicala"
						},
						{
							"_nr": "4",
							"__text": "analiza sintactica"
						},
						{
							"_nr": "5",
							"__text": "Analiza sintactica ascendenta"
						}
					]
				},
				"_id": "803",
				"_isbn": ""
			},
			{
				"titlu": "Aplicatii ale inteligentei artificiale in domeniile economic si tehnic",
				"autori": {
					"autor": {
						"_nume": "Titus Slavici"
					}
				},
				"anAparitie": "2007",
				"editura": "Mirton",
				"numarPagini": "216",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": [
						"Veronica Bivolaru",
						"Titus Slavici"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_ale_inteligentei_artificiale_in_domeniile_economic_si_tehnic_Slavici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Privire generala asupra utilizarii calculatoarelor si inteligentei artificiale in domeniile tehnic si economic. teorii generale"
						},
						{
							"_nr": "II",
							"__text": "Informatie, reprezentarea cunoasterii, baze de cunostinte, cunoastere si decizie"
						},
						{
							"_nr": "III",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "IV",
							"__text": "Retele neuronale artificiale"
						},
						{
							"_nr": "V",
							"__text": "Sisteme bazate pe logica fuzzy"
						},
						{
							"_nr": "VI",
							"__text": "Algoritmi genetici si calcul evolutiv"
						},
						{
							"_nr": "VII",
							"__text": "Agenti inteligenti"
						},
						{
							"_nr": "VIII",
							"__text": "Sisteme hibride in inteligenta artificiala"
						}
					]
				},
				"_id": "804",
				"_isbn": "973-52-0211-8"
			},
			{
				"titlu": "Sisteme informatice cu functionare in timp real",
				"autori": {
					"autor": {
						"_nume": "Nicolae Popescu"
					}
				},
				"anAparitie": "1984",
				"editura": "Militara",
				"numarPagini": "288",
				"pretOriginal": "25",
				"redactori": {
					"redactor": "Misu Raileanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ch. Cucos"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_cu_functionare_in_timp_real_Popescu.jpg"
					},
					"__text": "Valeriu Giodic"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Informatica in lumea contemporana"
						},
						{
							"_nr": "2",
							"__text": "Mijloacele informaticii. Tendinte de dezvoltare"
						},
						{
							"_nr": "3",
							"__text": "Informatica - instrument in sprijinul conducerii stiintifice"
						},
						{
							"_nr": "4",
							"__text": "Clasificarrea si caracteristicile sistemelor informatice"
						},
						{
							"_nr": "5",
							"__text": "Configuratii si arhitecturi ale sistemelor de prelucrare automata a datelor (SPAD)"
						},
						{
							"_nr": "6",
							"__text": "Informatica distribuita"
						},
						{
							"_nr": "7",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "8",
							"__text": "Exemple de retele de prelucrare automata a datelor"
						},
						{
							"_nr": "9",
							"__text": "Banci de date"
						},
						{
							"_nr": "10",
							"__text": "Organizarea si caracteristicile sistemelor in timp real"
						},
						{
							"_nr": "11",
							"__text": "Sisteme de prelucrarea datelor"
						},
						{
							"_nr": "12",
							"__text": "Sisteme de comunicatie"
						},
						{
							"_nr": "13",
							"__text": "Sisteme de terminale"
						},
						{
							"_nr": "14",
							"__text": "Securitatea datelor si sisteme informatice"
						}
					]
				},
				"_id": "805",
				"_isbn": ""
			},
			{
				"titlu": "Microsoft Access pentru Windows 95 - Ghid de referinta",
				"autori": {
					"autor": {
						"_nume": "James E. Powell"
					}
				},
				"anAparitie": "1997",
				"editura": "All Education",
				"numarPagini": "326",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"traducere": {
					"titluOriginal": "Microsoft Access for Windows 95 - Instant reference",
					"editura": "Sybex",
					"traducatori": {
						"traducator": "Andreea Lutic"
					},
					"_an": "1996",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Viorica Fatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Catalin Mantu"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Access_pentru_Windows_95_Ghid_de_referinta_Powell.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "Partea I",
							"__text": "Concepte specifice unei baze de date"
						},
						{
							"_nr": "Partea II",
							"__text": "Utilizarea programului Access"
						},
						{
							"_nr": "Partea III",
							"__text": "Caracteristici si functii"
						}
					]
				},
				"_id": "806",
				"_isbn": "973-0229-34-4"
			},
			{
				"titlu": "AutoCAD 14 - Ghid de referinta",
				"autori": {
					"autor": [
						{
							"_nume": "George Omura"
						},
						{
							"_nume": "B. Robert Callori"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "All Education",
				"numarPagini": "364",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"traducere": {
					"titluOriginal": "AutoCAD - Instant reference",
					"editura": "Sybex",
					"traducatori": {
						"traducator": "Daniel Munteanu"
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireau"
				},
				"coperta": {
					"imagine": {
						"_path": "AutoCAD_Ghid_de_referinta_Omura.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "Ghid de referinta",
							"__text": "AutoCAD 14"
						},
						{
							"_nr": "Anexa",
							"__text": "Bare de instrumente suplimentare"
						}
					]
				},
				"_id": "807",
				"_isbn": "973-9431-59-3"
			},
			{
				"titlu": "Logica polivalenta",
				"autori": {
					"autor": {
						"_nume": "Anton Dumitru"
					}
				},
				"anAparitie": "1971",
				"editura": "Enciclopedica Romana",
				"numarPagini": "424",
				"pretOriginal": "11",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Ladislau Redlinger"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Logica_polivalenta_Dumitru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere. Propozitia si modalitatea ei"
						},
						{
							"_nr": "2",
							"__text": "Sisteme formale"
						},
						{
							"_nr": "3",
							"__text": "Logica bivalenta"
						},
						{
							"_nr": "4",
							"__text": "Logica implicatiei stricte"
						},
						{
							"_nr": "5",
							"__text": "Logicile lui Lukasiewicz"
						},
						{
							"_nr": "6",
							"__text": "Logica intuitionista"
						},
						{
							"_nr": "7",
							"__text": "Logica modala"
						},
						{
							"_nr": "8",
							"__text": "Logica probalitatilor"
						},
						{
							"_nr": "9",
							"__text": "Aplicatiile logicilor polivalente"
						},
						{
							"_nr": "10",
							"__text": "Concluzii finale"
						}
					]
				},
				"_id": "808",
				"_isbn": ""
			},
			{
				"titlu": "Tiplogii ale sistemelor deductive",
				"autori": {
					"autor": {
						"_nume": "Florentina Calmatuianu"
					}
				},
				"anAparitie": "2006",
				"editura": "Universitatii \"Alexandru Ioan Cuza\"",
				"numarPagini": "246",
				"pretOriginal": "280000",
				"pretCumparare": "280000",
				"redactori": {
					"redactor": "Dana Lungu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Florentina Crucerescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Tipologii_ale_sistemelor_deductive_Calmatuianu.jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Analiza hexadica a axiomatizari"
						},
						{
							"_nr": "3",
							"__text": "Tipologii ale sistemelor deductive la nivelul primitivelor"
						},
						{
							"_nr": "4",
							"__text": "O tipologie a sistemelor deductive la nivelul regulilor formale"
						},
						{
							"_nr": "5",
							"__text": "Tipologii ale sistemelor deductive la nivelul regulilor de transformare"
						},
						{
							"_nr": "6",
							"__text": "Tipologii ale sistemelor deductive la nivelul regulilor de desemnare"
						},
						{
							"_nr": "7",
							"__text": "O tipologie a sistemelor deductive la nivelul regulilor de adevar"
						},
						{
							"_nr": "8",
							"__text": "Tipologii ale sistemelor deductive la nivelul rezultatelor"
						},
						{
							"_nr": "9",
							"__text": "Forme verticale ale aximotizarii"
						}
					]
				},
				"_id": "809",
				"_isbn": "978-973-703-137-2"
			},
			{
				"titlu": "Analogie. Modelare. Simulare. Cibernetica",
				"autori": {
					"autor": {
						"_nume": "Edmond Nicolau"
					}
				},
				"anAparitie": "1977",
				"editura": "Stiintifica si enciclopedica",
				"tiraj": "7850",
				"numarPagini": "208",
				"pretOriginal": "6.50",
				"pretCumparare": "5000",
				"redactori": {
					"redactor": "Vasile Vacaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Analogie_Modelare_Simulare_Cibernetica_Nicolau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Metodolgia cercetarii stiintifice"
						},
						{
							"_nr": "Ii",
							"__text": "Analogia - notiuni introductive"
						},
						{
							"_nr": "III",
							"__text": "Analogia ca metoda de cunoastere stiintifica"
						},
						{
							"_nr": "IV",
							"__text": "Sonicitatea"
						},
						{
							"_nr": "V",
							"__text": "Metode - consideratii generale"
						},
						{
							"_nr": "VI",
							"__text": "Metode simple de modelare a activitatii logice"
						},
						{
							"_nr": "VII",
							"__text": "'Legile gandirii' - un model de logica"
						},
						{
							"_nr": "VIII",
							"__text": "Masini logice (masini pentru modelarea activiatii logice)"
						},
						{
							"_nr": "IX",
							"__text": "Modele de neuroni"
						},
						{
							"_nr": "X",
							"__text": "Logici polivalente si logica continua - teorie si modele"
						},
						{
							"_nr": "XI",
							"__text": "Medelul balantei legaturilor dintre ramuri"
						},
						{
							"_nr": "XII",
							"__text": "Modele dinamice utilizate in economie"
						},
						{
							"_nr": "XIII",
							"__text": "Introducere in tehnica modelarii"
						},
						{
							"_nr": "XIV",
							"__text": "Teoria similitudinii"
						},
						{
							"_nr": "XV",
							"__text": "Simularea"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Teoreme referitoare la calculul analogic"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Aducerea functiilor booleene la forme canonice"
						}
					]
				},
				"_id": "810",
				"_isbn": ""
			},
			{
				"titlu": "Codificare si coduri",
				"autori": {
					"autor": {
						"_nume": "Dan Ionescu"
					}
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "184",
				"pretOriginal": "5.50",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Ceru"
				},
				"coperta": {
					"imagine": {
						"_path": "Codificare_si_coduri_Ionescu.jpg"
					},
					"__text": "Marian Negru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Alte coduri detectoare si corectoare de erori"
						},
						{
							"_nr": "3",
							"__text": "Coduri ciclice"
						},
						{
							"_nr": "4",
							"__text": "Coduri liniare"
						},
						{
							"_nr": "5",
							"__text": "Notiuni generale privind coduri detectoare si corectoare de erori"
						},
						{
							"_nr": "6",
							"__text": "Coduri liniare"
						},
						{
							"_nr": "7",
							"__text": "Coduir ciclice"
						},
						{
							"_nr": "8",
							"__text": "Alte coduri detectoare si corectoare de erori"
						}
					]
				},
				"_id": "811",
				"_isbn": ""
			},
			{
				"titlu": "Programare structurata",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Vaduva"
						},
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "Vasile Florescu"
						},
						{
							"_nume": "Ion Floricica"
						}
					]
				},
				"anAparitie": "1978",
				"editura": "Tehnica",
				"tiraj": "7300+65 exemplare brosate",
				"numarPagini": "248",
				"pretOriginal": "10",
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_structurata_Vaduva.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Particularitati si limite ale programarii clasice"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea si realizarea modulara a sistemelor de programe"
						},
						{
							"_nr": "3",
							"__text": "Metode programarii structurate"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi de prelucrare a datelor organizate in structuri de fisier"
						},
						{
							"_nr": "5",
							"__text": "Programarea structurata utilizand limbajul COBOL"
						},
						{
							"_nr": "6",
							"__text": "Programarea structurata utilizand limbajul FORTRAN"
						},
						{
							"_nr": "7",
							"__text": "Organizarea activitatii de programare"
						}
					]
				},
				"_id": "812",
				"_isbn": ""
			},
			{
				"titlu": "Abc de calculatoare personale si... nu doar atat (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Nicolae Tapus"
						},
						{
							"_nume": "Trandafir Moisa"
						},
						{
							"_nume": "Gheorghe Rizescu"
						},
						{
							"_nume": "Viorica Harabor"
						},
						{
							"_nume": "Traian Mihu"
						}
					]
				},
				"anAparitie": "1990",
				"editura": "Tehnica",
				"numarPagini": "312",
				"pretOriginal": "44.5",
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Abc_de_calculatoare_personale_si_nu_doar_atat_Vol_I_Petrescu.jpg"
					},
					"__text": "Silvia Pintea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Calculatoare, microcalculatoare si calculatoare personale in tara noastra si pe plan mondial"
						},
						{
							"_nr": "Partea II",
							"__text": "Calculatoare numerice. Realizare fizica. Baze aritmetice si logice"
						},
						{
							"_nr": "Partea III",
							"__text": "Calculatorul personal HC-85. Structura, componente, operare, programare"
						},
						{
							"_nr": "Partea IV",
							"__text": "Programarea in limbajul BASIC pe calculatorul HC-85"
						},
						{
							"_nr": "Partea V",
							"__text": "Programarea in limbajul LOGO pe calculatorul HC-85"
						}
					]
				},
				"_id": "813",
				"_isbn": "973-31-0013-7"
			},
			{
				"titlu": "Abc de calculatoare personale si... nu doar atat (Vol.2)",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Gheorghe Rizescu"
						},
						{
							"_nume": "Nicolae Tapus"
						},
						{
							"_nume": "Trandafir Moisa"
						},
						{
							"_nume": "Paul Zamfirescu"
						},
						{
							"_nume": "Victor Cososchi"
						},
						{
							"_nume": "Mihai Marsanu"
						},
						{
							"_nume": "Eugen Dobrovie"
						},
						{
							"_nume": "Nicolae Badea"
						},
						{
							"_nume": "Constantin Harabor"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Tehnica",
				"numarPagini": "360",
				"pretOriginal": "123.5",
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Abc_de_calculatoare_personale_si_nu_doar_atat_Vol_II_Petrescu.jpg"
					},
					"__text": "Silvia Pintea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea VI",
							"__text": "Microcalculatorul HC-85 in procese industriale. Pachete de programe de aplicatii pentru HC-85"
						},
						{
							"_nr": "Partea VII",
							"__text": "Calculatorul personal in invatamant si educatie"
						},
						{
							"_nr": "Partea VIII",
							"__text": "Programe educationale in BASIC pe calculatorul HC-85"
						},
						{
							"_nr": "Partea IX",
							"__text": "Microbiblioteca de programe pe casete"
						},
						{
							"_nr": "Partea X",
							"__text": "Complemente matematice"
						},
						{
							"_nr": "Partea XI",
							"__text": "Complemente informatice"
						}
					]
				},
				"_id": "814",
				"_isbn": "973-31-0015-3"
			},
			{
				"titlu": "Cui i-e frica de calculatorul electronic?",
				"autori": {
					"autor": {
						"_nume": "Dan D. Farcas"
					}
				},
				"anAparitie": "1987",
				"editura": "Albatros",
				"numarPagini": "248",
				"pretOriginal": "13",
				"pretCumparare": "5000",
				"tehnoredactori": {
					"tehnoredactor": "Gabriela-Lucia Iliopolos"
				},
				"coperta": {
					"imagine": {
						"_path": "Cui_i_e_frica_de_calculatorul_electronic_Farcas.jpg"
					},
					"__text": "Mihai Pienesu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme care ne primesc"
						},
						{
							"_nr": "2",
							"__text": "Utilaje pentru industria informatiei si inteligentei"
						},
						{
							"_nr": "3",
							"__text": "Avalansa informatiilor"
						},
						{
							"_nr": "4",
							"__text": "Activitati si obiective"
						},
						{
							"_nr": "5",
							"__text": "Cu stim ce lucarari sa 'punem' pe calculator"
						},
						{
							"_nr": "6",
							"__text": "Proiectantul si programatorul"
						},
						{
							"_nr": "7",
							"__text": "Din miturile informaticii"
						},
						{
							"_nr": "8",
							"__text": "Masina de multiplicat inteligenta"
						},
						{
							"_nr": "9",
							"__text": "Inteligenta modelelor matematice"
						},
						{
							"_nr": "10",
							"__text": "Universul inteligentei artificiale"
						},
						{
							"_nr": "11",
							"__text": "Inteligenta microprocesoarelor"
						},
						{
							"_nr": "12",
							"__text": "Omul devine cea mai pretioasa resursa"
						}
					]
				},
				"_id": "815",
				"_isbn": ""
			},
			{
				"titlu": "De la Gutenberg la microprocesor",
				"autori": {
					"autor": {
						"_nume": "Alexandru Irod"
					}
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "224",
				"pretOriginal": "13",
				"redactori": {
					"redactor": "Vasule Vacar"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "De_la_Gutenberg_la_microprocesor_Irod.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme de prelucrare si fotoculegere a textelor"
						},
						{
							"_nr": "II",
							"__text": "Sisteme de prelucrare a imaginilor"
						},
						{
							"_nr": "III",
							"__text": "Prelucrarea integrala a informatiilor de text si de imagine"
						},
						{
							"_nr": "IV",
							"__text": "Incontro tipografia"
						}
					]
				},
				"_id": "816",
				"_isbn": ""
			},
			{
				"titlu": "Calculatoare si Internet: Dictionar explicativ",
				"autori": {
					"autor": {
						"_nume": "[Random House Webster's]"
					}
				},
				"anAparitie": "2003",
				"editura": "Corint",
				"numarPagini": "304",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Random House Webster's Pocket Computer and Intenet Dictionary",
					"editura": "Random House",
					"traducatori": {
						"traducator": "Mihai Danciu"
					},
					"_an": "2003",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Alice Raluca Vinereanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihaela Dumitru"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_si_internet_Dictionar_explicativ_Random_House.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Ghid al terminilor dupa categorii"
						},
						{
							"_nr": "-",
							"__text": "Dictionar"
						}
					]
				},
				"_id": "817",
				"_isbn": "973-653-424-3"
			},
			{
				"titlu": "Creier uman si creier artificial",
				"autori": {
					"autor": {
						"_nume": "Eli Katz"
					}
				},
				"anAparitie": "1977",
				"editura": "Stiintifica si enciclopedica",
				"tiraj": "12500",
				"numarPagini": "328",
				"pretOriginal": "10",
				"redactori": {
					"redactor": "Gheorghe Pal"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Creier_uman_si_creier_artificial_Katz.jpg"
					},
					"__text": "Gheorghe Motora"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Energie si entropie"
						},
						{
							"_nr": "III",
							"__text": "Interactiunea informationala"
						},
						{
							"_nr": "IV",
							"__text": "Reflectare si cunoastere"
						},
						{
							"_nr": "V",
							"__text": "Reactie invera si finalitate"
						},
						{
							"_nr": "VI",
							"__text": "Creierii artificiali"
						},
						{
							"_nr": "VII",
							"__text": "Creier uman si creier artificial"
						}
					]
				},
				"_id": "818",
				"_isbn": ""
			},
			{
				"titlu": "Analiza datelor si informatica - cu aplicatii la studii de piata  si sondaje de opinie",
				"autori": {
					"autor": [
						{
							"_nume": "Gilbert Saporta"
						},
						{
							"_nume": "Viorica Stefanescu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Economica",
				"numarPagini": "376",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Dinu Marin"
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_datelor_si_informatica_cu_aplicatii_la_studii_de_piata_si_sondaje_de_opinie_Saporta.jpg"
					},
					"__text": "Lucian Sandu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Descrierea unidimensional a datelor numerice"
						},
						{
							"_nr": "2",
							"__text": "Descrierea bidimensionala a datelor si masuri ale corelatiei intre variabile"
						},
						{
							"_nr": "3",
							"__text": "Descrierea bidimensionala a datelor numerice"
						},
						{
							"_nr": "4",
							"__text": "Analiza canonica si compararea a doua grupuri de variabile"
						},
						{
							"_nr": "5",
							"__text": "Analiza corespondentelor"
						},
						{
							"_nr": "6",
							"__text": "Analiza corespindentelor multiple"
						},
						{
							"_nr": "7",
							"__text": "Metode de clasificare"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii ale analizei datelor la studii de piata si sondaje de opinie"
						}
					]
				},
				"_id": "819",
				"_isbn": "973-9198-24-4"
			},
			{
				"titlu": "Proiectarea sistemelor expert in logica fuzzy si teoria prosibilitatilor",
				"autori": {
					"autor": {
						"_nume": "Vasile Georgescu"
					}
				},
				"anAparitie": "1995",
				"editura": "INTARF",
				"numarPagini": "216",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "3",
					"_nume": "Inteligenta artificiala"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_sistemelor_expert_in_logica_fuzzy_si_teoria_posibilitatilor_Georgescu.jpg"
					},
					"__text": "Adrian Sandita"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Reprezentarea cunostintelor imprecise / incerte / incomplete"
						},
						{
							"_nr": "2",
							"__text": "Filtrare fuzzy multicriteriala"
						},
						{
							"_nr": "3",
							"__text": "Inferente plauzibile. model de propagare a incertitudini"
						},
						{
							"_nr": "4",
							"__text": "Logica fuzzy"
						},
						{
							"_nr": "5",
							"__text": "Profuzzy - prototipul unui sistem expert in logica fuzzy. Specificatii de implementare"
						}
					]
				},
				"_id": "820",
				"_isbn": "973-95985-1-X"
			},
			{
				"titlu": "Sisteme de control fuzzy. Modelare si proiectare asistate de calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Emil Sofron"
						},
						{
							"_nume": "Nicu Bizon"
						},
						{
							"_nume": "Silviu Ionita"
						},
						{
							"_nume": "Radian Raducu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All Educational",
				"numarPagini": "280",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Marius Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Madalin Erighin"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_control_fuzzy_Modelare_si_proiectare_asistate_de_calculator_Sofron.jpg"
					},
					"__text": "Daniel Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modele inteligente pentru procese de control"
						},
						{
							"_nr": "3",
							"__text": "Sisteme fuzzz pentru control inteligent"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii ale modelarii fuzzy"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea sistemelor fuzzy cu matlab"
						}
					]
				},
				"_id": "821",
				"_isbn": "973-9431-32-1"
			},
			{
				"titlu": "Controverse in stiinta si inginerie",
				"autori": {
					"autor": {
						"_nume": "Solomon Marcus"
					}
				},
				"anAparitie": "1990",
				"editura": "Tehnica",
				"numarPagini": "248",
				"pretOriginal": "71",
				"redactori": {
					"redactor": [
						"Carmen Hotaranu",
						"Marcelina Joavina"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "B.E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Controverse_in_stiinta_si_inginerie_Marcus.jpg"
					},
					"__text": "Dan Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sub semnul controversei"
						},
						{
							"_nr": "II",
							"__text": "Provocarile timpului nostru"
						},
						{
							"_nr": "III",
							"__text": "Tipuri de intelegerea contemporana"
						},
						{
							"_nr": "IV",
							"__text": "Caracterul national in stiinta (Preliminarii)"
						},
						{
							"_nr": "V",
							"__text": "In lumea aproximatiei"
						},
						{
							"_nr": "VI",
							"__text": "Aproximarea haosului"
						},
						{
							"_nr": "VII",
							"__text": "Controverse ale stiintei si inginerie calculului"
						},
						{
							"_nr": "VIII",
							"__text": "Stiinta despre stiinta"
						},
						{
							"_nr": "IX",
							"__text": "Itinerar matematic"
						},
						{
							"_nr": "X",
							"__text": "Invatare, lectura, asimilare"
						},
						{
							"_nr": "XI",
							"__text": "Sa-ti dea prin gand ceva! sau talent prin antrenare"
						},
						{
							"_nr": "XII",
							"__text": "A gasi sau numai a verifica"
						},
						{
							"_nr": "XIII",
							"__text": "Arta teoremei"
						},
						{
							"_nr": "XIV",
							"__text": "Numerele, la a doua lectura"
						},
						{
							"_nr": "XV",
							"__text": "Radiografia greselii"
						},
						{
							"_nr": "XVI",
							"__text": "Ludic si cognitiv:limbaj,logica si calculatoare in jocul de sah"
						},
						{
							"_nr": "XVII",
							"__text": "Imagini distorsionate ale stiintei si tehnicii"
						},
						{
							"_nr": "XVIII",
							"__text": "Spre o tipologie a impreciziei in sisteme"
						}
					]
				},
				"_id": "822",
				"_isbn": "973-31-0258-X"
			},
			{
				"titlu": "Partenerul meu de joc calculatorul - ghid pentru utilizarea microcalculatoarelor - (editia a doua)",
				"autori": {
					"autor": {
						"_nume": "Ion Diamandi"
					}
				},
				"anAparitie": "1990",
				"editura": "Recoop",
				"numarPagini": "128",
				"pretOriginal": "43",
				"redactori": {
					"redactor": "Adriana Cruceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Romanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Partenerul_meu_de_joc_calculatorul_Diamandi.jpg"
					},
					"__text": "Elena Dragulelei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Activitatea 1",
							"__text": "Punerea in functiune si tastatura"
						},
						{
							"_nr": "Activitatea 2",
							"__text": "Cuvinte, litere, numere"
						},
						{
							"_nr": "Activitatea 3",
							"__text": "Programe"
						},
						{
							"_nr": "Activitatea 4",
							"__text": "Alte lucruri despre program"
						},
						{
							"_nr": "Activitatea 5",
							"__text": "Variabile"
						},
						{
							"_nr": "Activitatea 6",
							"__text": "Puncte pe ecran"
						},
						{
							"_nr": "Activitatea 7",
							"__text": "Desenarea liniilor prin puncte"
						},
						{
							"_nr": "Activitatea 8",
							"__text": "Patrate si dreptunghiuri"
						},
						{
							"_nr": "Activitatea 9",
							"__text": "Negru pe alb, alb pe negru"
						},
						{
							"_nr": "Activitatea 10",
							"__text": "Reguli pentru multipli"
						},
						{
							"_nr": "Activitatea 11",
							"__text": "Si alte reguli"
						},
						{
							"_nr": "Activitatea 12",
							"__text": "Calculul multiplilor prin adunare"
						},
						{
							"_nr": "Activitatea 13",
							"__text": "Calculatorul ia o hotarare"
						},
						{
							"_nr": "Activitatea 14",
							"__text": "Multipli comuni"
						},
						{
							"_nr": "Activitatea 15",
							"__text": "Fractii si numere zecimale"
						},
						{
							"_nr": "Activitatea 16",
							"__text": "Fractii echivalente"
						},
						{
							"_nr": "Activitatea 17",
							"__text": "Patratul numerelor"
						},
						{
							"_nr": "Activitatea 18",
							"__text": "Afalti numarul"
						},
						{
							"_nr": "Activitatea 19",
							"__text": "Folositi calculatorul direct - cu SQR"
						},
						{
							"_nr": "Activitatea 20",
							"__text": "Gasirea divizorilor"
						},
						{
							"_nr": "Activitatea 21",
							"__text": "Numere prime"
						},
						{
							"_nr": "Activitatea 22",
							"__text": "Gasirea divizorilor cu calculatorul"
						},
						{
							"_nr": "Activitatea 23",
							"__text": "Un program cu divizori, mai mult decat cele anterioare"
						},
						{
							"_nr": "Activitatea 24",
							"__text": "Aflarea numerelor prime cu ajutorul lui INT"
						},
						{
							"_nr": "Activitatea 25",
							"__text": "Probleme pentru exercitii"
						},
						{
							"_nr": "Activitatea 26",
							"__text": "Programarea pentru alte probleme"
						},
						{
							"_nr": "Activitatea 27",
							"__text": "Sume cu calculatorul"
						},
						{
							"_nr": "Activitatea 28",
							"__text": "Numere negative"
						},
						{
							"_nr": "Activitatea 29",
							"__text": "Media aritmetica"
						},
						{
							"_nr": "Activitatea 30",
							"__text": "Hazdul, intamplarea"
						},
						{
							"_nr": "Activitatea 31",
							"__text": "Programe mai bune"
						},
						{
							"_nr": "Activitatea 32",
							"__text": "Un sumar al cuvintelor (instructiunilor) si ideilor"
						},
						{
							"_nr": "Activitatea 33",
							"__text": "Modul de abordare de sus in jos, pentru scrierea programelor BASIC de rezolvare a problemelor matematice"
						},
						{
							"_nr": "Activitatea 34",
							"__text": "Cateva probleme de rezolvat"
						},
						{
							"_nr": "Anexa 0",
							"__text": "Raspunsuri la exercitiile si temele propuse"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Cateva sfaturi pentru utilizarea altor calculatoare in limbajul BASIC"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Indicatii privind modificarea programelor utilizate pentru a functiona si pe alte microcalculatoare"
						}
					]
				},
				"_id": "823",
				"_isbn": ""
			},
			{
				"titlu": "ASSIRIS, S.G.F. si implicatiile lor in FORTRAN si COBOL",
				"autori": {
					"autor": {
						"_nume": "Minerva Bocsa"
					}
				},
				"anAparitie": "1986",
				"editura": "Facla",
				"numarPagini": "336",
				"pretOriginal": "24",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan I. Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "ASSIRIS_SGF_si_implicatiile_lor_in_FORTRAN_si_COBOL_Bocsa.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Intelegerea unui text ASSIRIS"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Programarea in ASSIRIS"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Sistemul de gestiune a fisierelor SGF"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Alte directive si macroinstructiuni"
						},
						{
							"_nr": "Partea a cincea",
							"__text": "Informatiile din vidajul memoriei"
						}
					]
				},
				"_id": "824",
				"_isbn": ""
			},
			{
				"titlu": "Metode numerice in teoria jocurilor",
				"autori": {
					"autor": {
						"_nume": "Ernest Dani"
					}
				},
				"anAparitie": "1983",
				"editura": "Dacia",
				"numarPagini": "372",
				"pretOriginal": "22",
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_in_teoria_jocurilor_Dani.jpg"
					},
					"__text": "Calin Damian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Modele"
						},
						{
							"_nr": "I",
							"__text": "Jocuri matriciale"
						},
						{
							"_nr": "II",
							"__text": "Jocuri pozitionale"
						},
						{
							"_nr": "III",
							"__text": "Aplicatii in economie"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Programe"
						},
						{
							"_nr": "IV",
							"__text": "Programul PFP004"
						},
						{
							"_nr": "V",
							"__text": "Programul JOC001"
						},
						{
							"_nr": "VI",
							"__text": "Programul JOC002"
						}
					]
				},
				"_id": "825",
				"_isbn": ""
			},
			{
				"titlu": "Interactiunea conversationala in sistemele colaborative pe Web",
				"autori": {
					"autor": [
						{
							"_nume": "Stefan Trausan-Matu"
						},
						{
							"_nume": "Gerry Stahl"
						},
						{
							"_nume": "Timothy Koschmann"
						},
						{
							"_nume": "Dan Suthers"
						},
						{
							"_nume": "Nicolae Nistor"
						},
						{
							"_nume": "Ana Maria Marhan"
						},
						{
							"_nume": "Vlad Posea"
						},
						{
							"_nume": "Dan-Gheorghe Mihaila"
						},
						{
							"_nume": "Costin Chiru"
						},
						{
							"_nume": "Traian Rebedea"
						},
						{
							"_nume": "Alexandru Gartner"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "MatrixRom",
				"numarPagini": "178",
				"pretOriginal": "240000",
				"pretCumparare": "240000",
				"colectia": {
					"_numarul": "5",
					"_nume": "Seria Interactiune om-masina"
				},
				"traducere": {
					"editura": "Cambridge Univeristy Press",
					"traducatori": {
						"traducator": "Traian Rebedea"
					},
					"_an": "",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"coperta": {
					"imagine": {
						"_path": "Interactiunea_conversationala_in_sistemele_colaborative_pe_Web_Matu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Invatarea colaborativa sprijinita de calculator: o perspectiva istorica"
						},
						{
							"_nr": "2",
							"__text": "Initierea si sustinerea comunitatilor cognitive pe baza teoriei memoriei colective"
						},
						{
							"_nr": "3",
							"__text": "Polifonia colaborarii dialogale"
						},
						{
							"_nr": "4",
							"__text": "Modalitati de reprezentarea a emotiilor in comunicarea bazata pe text"
						},
						{
							"_nr": "5",
							"__text": "Prelucrari bazate pe cunostinte in aplicatiile colaborative pe webul semantic"
						},
						{
							"_nr": "6",
							"__text": "Analiza retelelor sociale"
						},
						{
							"_nr": "7",
							"__text": "Prelucrarea limbajului natural in interactiunile chat"
						},
						{
							"_nr": "8",
							"__text": "Detectarea firelor de discutie in conversatiile chat"
						},
						{
							"_nr": "9",
							"__text": "Polyphony, un sistem de chat bazat pe cunostinte"
						}
					]
				},
				"_id": "826",
				"_isbn": "978-973-755-393-5"
			},
			{
				"titlu": "AMC 48",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "O. Stanescu"
						},
						{
							"_nume": "D. Tibuleac"
						},
						{
							"_nume": "L. Vlad"
						},
						{
							"_nume": "N. Badea-Dinca"
						},
						{
							"_nume": "V. Marinescu"
						},
						{
							"_nume": "N. Costake"
						},
						{
							"_nume": "D. Roman"
						},
						{
							"_nume": "M. Guran"
						},
						{
							"_nume": "A. Davidoviciu"
						},
						{
							"_nume": "I. Zaborszky"
						},
						{
							"_nume": "P.V. Kokotovic"
						},
						{
							"_nume": "J. Hatvany"
						},
						{
							"_nume": "J. Somlo"
						},
						{
							"_nume": "G. Bengtsson, B. Egardt"
						},
						{
							"_nume": "Ion Iliescu"
						},
						{
							"_nume": "T. Geber"
						},
						{
							"_nume": "E. Miclescu"
						},
						{
							"_nume": "A. M. Borozan"
						},
						{
							"_nume": "O. Borozan"
						},
						{
							"_nume": "R. Ciocea"
						},
						{
							"_nume": "M. Constantinescu"
						},
						{
							"_nume": "M. Ivan"
						},
						{
							"_nume": "A. Lazar"
						},
						{
							"_nume": "St. Panait"
						},
						{
							"_nume": "D. Trifanescu"
						},
						{
							"_nume": "L. Dumitrascu"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "344",
				"pretOriginal": "27",
				"traducere": {
					"traducatori": {
						"traducator": [
							"Adrian Davidoviciu",
							"Vasile Sima",
							"Radu Magda",
							"Marcel Sarbu",
							"Aristide Predoi",
							"Sandu Lazar",
							"Dan Dobrescu",
							"Alexandra Tatu",
							"Matei Constantin",
							"Doina Tudor",
							"Nicolae Tudorancescu",
							"Ilie Paiusi",
							"Cornelia Basarab",
							"Dan Radulescu",
							"Virgil Micula",
							"Serban Sporea",
							"Radu Manolescu",
							"Nicolae Algiu",
							"Leonard Stoian Horobat",
							"Gabriel Spiridon",
							"Eust. Stanciu",
							"Serban Lupescu"
						]
					},
					"_an": "",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Traznea"
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_48_Tehnica.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cercetarea si proiectarea asistate de calculator (CPAC)- un imperativ al unei economiei avansate"
						},
						{
							"_nr": "2",
							"__text": "Pregatirea si perfectionarea personalului implicat in activitatea de cercetare si proiectare asistate de calculator"
						},
						{
							"_nr": "3",
							"__text": "Implementarea si generelalizarea aplicatiilor CPAC in cadrul unor puncte pilot"
						},
						{
							"_nr": "4",
							"__text": "Elaborarea de produse-program pentru activitarea de cercetare si proiectare asista de calculator (CPAC)"
						},
						{
							"_nr": "5",
							"__text": "Congresul al 9-lea mondoal al Federatiei Internationale de Automatizare (I.F.A.C.)"
						},
						{
							"_nr": "6",
							"__text": "Dezvoltarea stiintei sistemelor"
						},
						{
							"_nr": "7",
							"__text": "Teoria reglarii automate in anii '80"
						},
						{
							"_nr": "8",
							"__text": "Celule de fabricatie conduse de calculator"
						},
						{
							"_nr": "9",
							"__text": "Reglarea cu autoacodare in procese"
						},
						{
							"_nr": "10",
							"__text": "'Societatea informatica - societate postindustriala' de Y. Masura (Japonia)"
						},
						{
							"_nr": "11",
							"__text": "Minicalculatorele CORAL si INDEPENDENT (partea I)"
						},
						{
							"_nr": "12",
							"__text": "Memento de teleprelucrare (partea I)"
						},
						{
							"_nr": "13",
							"__text": "BASIC pentru incepatori cu calculatorul personal"
						}
					]
				},
				"_id": "827",
				"_isbn": ""
			},
			{
				"titlu": "AMC 54",
				"autori": {
					"autor": [
						{
							"_nume": "A. Davidoviciu"
						},
						{
							"_nume": "A. Predoi"
						},
						{
							"_nume": "M. Sirbu"
						},
						{
							"_nume": "I.S. Lazar"
						},
						{
							"_nume": "I. Felea"
						},
						{
							"_nume": "G. Purice"
						},
						{
							"_nume": "St. Frustok"
						},
						{
							"_nume": "M. Neamtu"
						},
						{
							"_nume": "N. Sterescu"
						},
						{
							"_nume": "N. Radu"
						},
						{
							"_nume": "D. Brasoveanu"
						},
						{
							"_nume": "D. Georgescu"
						},
						{
							"_nume": "M. Bruda"
						},
						{
							"_nume": "M. Mazilu"
						},
						{
							"_nume": "M. Vicol"
						},
						{
							"_nume": "V. Bundaru"
						},
						{
							"_nume": "I. Halasz"
						},
						{
							"_nume": "M. Avram"
						},
						{
							"_nume": "T. Samoil"
						},
						{
							"_nume": "T. Voica"
						},
						{
							"_nume": "B. Takacs"
						},
						{
							"_nume": "I. Motoasca"
						},
						{
							"_nume": "V. Neagu"
						},
						{
							"_nume": "T. Roth"
						},
						{
							"_nume": "R. Motas"
						},
						{
							"_nume": "C. Munteanu"
						},
						{
							"_nume": "R. Meza"
						},
						{
							"_nume": "V. PRedescu"
						},
						{
							"_nume": "A. Vasii"
						},
						{
							"_nume": "O. Dinescu"
						},
						{
							"_nume": "N. Busurca"
						},
						{
							"_nume": "N. Mihaita"
						},
						{
							"_nume": "M. Filipoiu"
						},
						{
							"_nume": "C. Gavrilescu"
						},
						{
							"_nume": "M. Ponepal"
						},
						{
							"_nume": "F. Marcoff"
						},
						{
							"_nume": "A. Balasa"
						},
						{
							"_nume": "G. Safta-Zecheria"
						},
						{
							"_nume": "C. Safta-Zecheria"
						},
						{
							"_nume": "D. Maftei"
						},
						{
							"_nume": "V. Tepelea"
						},
						{
							"_nume": "L. Iftode"
						},
						{
							"_nume": "N.D. Cirtu"
						},
						{
							"_nume": "Gh. Popescu"
						},
						{
							"_nume": "H. Ivanitki"
						},
						{
							"_nume": "P. Constantinescu"
						},
						{
							"_nume": "B. Droasca"
						},
						{
							"_nume": "D. Stanescu"
						},
						{
							"_nume": "E. Kalisz"
						},
						{
							"_nume": "I. Miu"
						},
						{
							"_nume": "D. Gruia"
						},
						{
							"_nume": "C. Manole"
						},
						{
							"_nume": "D. Enghel"
						},
						{
							"_nume": "S. Constantinescu"
						},
						{
							"_nume": "P. Ripea"
						},
						{
							"_nume": "C. Dumitrescu"
						},
						{
							"_nume": "M. Andrei"
						},
						{
							"_nume": "T. Geber"
						},
						{
							"_nume": "V. Savescu"
						},
						{
							"_nume": "I. Rusu"
						},
						{
							"_nume": "M. Draghici"
						},
						{
							"_nume": "G.B. Luchian"
						},
						{
							"_nume": "M. Florovici"
						},
						{
							"_nume": "M. Enache"
						},
						{
							"_nume": "G. Crisan"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Tehnica",
				"numarPagini": "488",
				"pretOriginal": "50",
				"traducere": {
					"traducatori": {
						"traducator": "Alecu Cramanescu"
					},
					"_an": "",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"redactori": {
					"redactor": [
						"Paul Zamfirescu",
						"Smaranda Dimitriu",
						"Victoria Popescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_54_Tehnica.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "INFOTEC '86/IPA '87"
						},
						{
							"_nr": "2",
							"__text": "MULTIPROM. Sistem tipizat de module penru echipamente umerice. Manual general (II)"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de operare U. Manual de prezentare (II)"
						},
						{
							"_nr": "4",
							"__text": "Roboti industriali in sisteme flexibile (III)"
						},
						{
							"_nr": "5",
							"__text": "Biocalculatorul - primii pasi"
						},
						{
							"_nr": "6",
							"__text": "Rolul informatiei in geneaza si dezvoltarea sistemelor (II)"
						},
						{
							"_nr": "7",
							"__text": "Sisteme bidimensionale - 2D - (III)"
						},
						{
							"_nr": "8",
							"__text": "Terminal interactiv DAF 2020. Manual de functionare. Manual de utilizare"
						},
						{
							"_nr": "9",
							"__text": "Cuplarea la calculatoarele Felix C256/512/1024 a terminalelor de teletrasmisie (I)"
						},
						{
							"_nr": "10",
							"__text": "Sistem inteligent de gestiunea bazelor de date - o notiune impusa de practica"
						},
						{
							"_nr": "11",
							"__text": "Ghid (vesel) al analistului (serios). Proiect de analiza de sistem la uzina 'U' (II)"
						},
						{
							"_nr": "12",
							"__text": "Calculatoare personale cu grafica interactiva"
						},
						{
							"_nr": "13",
							"__text": "Grafica pe calculator in arhitectura si design"
						}
					]
				},
				"_id": "828",
				"_isbn": ""
			},
			{
				"titlu": "AMC 21 A si B",
				"autori": {
					"autor": [
						{
							"_nume": "I. Romanu"
						},
						{
							"_nume": "C. Bilciu"
						},
						{
							"_nume": "V. Dudas"
						},
						{
							"_nume": "Cserveny"
						},
						{
							"_nume": "N. Mirea"
						},
						{
							"_nume": "R. Zimand"
						},
						{
							"_nume": "A. Gheorghe"
						},
						{
							"_nume": "M.C. Demetrescu"
						},
						{
							"_nume": "E. Diatcu"
						},
						{
							"_nume": "I. Tomescu"
						},
						{
							"_nume": "S.Florea"
						},
						{
							"_nume": "E. Nastasel"
						},
						{
							"_nume": "A. Carabulea"
						},
						{
							"_nume": "A. Davidacioiu"
						},
						{
							"_nume": "R. Magda"
						},
						{
							"_nume": "D. Voiuc"
						},
						{
							"_nume": "C. Pirvan"
						},
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "I. Stancioiu"
						},
						{
							"_nume": "D. Martinov"
						},
						{
							"_nume": "N. Dinu"
						},
						{
							"_nume": "C.L. Pogorschi"
						},
						{
							"_nume": "Victoria Pogorschi"
						},
						{
							"_nume": "N. Spranceana"
						},
						{
							"_nume": "A. Petrescu"
						},
						{
							"_nume": "N. Tapus"
						},
						{
							"_nume": "C. Zervos"
						},
						{
							"_nume": "Irina Arhanasiu"
						},
						{
							"_nume": "M. Ionescu"
						},
						{
							"_nume": "A. Marian"
						},
						{
							"_nume": "T. Canescu"
						},
						{
							"_nume": "V. Caisan"
						},
						{
							"_nume": "I. Svasta"
						},
						{
							"_nume": "E. Bolesch"
						},
						{
							"_nume": "Dr. Cupceanu"
						},
						{
							"_nume": "Acad. St. Milcu"
						},
						{
							"_nume": "Eug. Niculescu-Mizil"
						},
						{
							"_nume": "I. Tomescu"
						},
						{
							"_nume": "M. Tomescu"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"tiraj": "2400+70",
				"numarPagini": "456",
				"pretOriginal": "30",
				"colectia": {
					"_numarul": "21",
					"_nume": "AMC"
				},
				"redactori": {
					"redactor": [
						"Paul Zamfirescu",
						"Smaranda Dimitriu",
						"Mircea Grosu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_21_A_si_B_Tehnica.jpg"
					},
					"__text": "C. Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calendar. Retrospective. Perspective"
						},
						{
							"_nr": "2",
							"__text": "Planificarea intreprinderilor publice in tarile in curs de dezvoltare (Seminar international, Liubliana sept. oct. 1974)"
						},
						{
							"_nr": "3",
							"__text": "Metode de analiza a problemelor de conducere. Metoda Kepner-Tregoe"
						},
						{
							"_nr": "4",
							"__text": "Conceptul de joc matematic utilizat in conducerea sistemelor industriale"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de conducere cu calculator a proceselor tehnologice (SICCPTEH)"
						},
						{
							"_nr": "6",
							"__text": "Limbaje de programare specializate pentru aplicatii industriale"
						},
						{
							"_nr": "7",
							"__text": "Felix C-32, calculator electronic din generatia a treia, de conceptie romaneasca"
						},
						{
							"_nr": "8",
							"__text": "Sistem de gestiunea bazei de date pentru intreprinderi din constuctia de masini"
						},
						{
							"_nr": "9",
							"__text": "Problema agregarii informatiilor la realizarea sistemelor informatice cu mijloace de automatizare (SIMCA) in constructia de masini"
						},
						{
							"_nr": "10",
							"__text": "Conducerea cu calculatorul a masinilor-unelte"
						},
						{
							"_nr": "11",
							"__text": "Limbaj de simulare a circuitelor logice"
						},
						{
							"_nr": "12",
							"__text": "Program pentru analiza circuitelor electronice neliniare in curent coninuu"
						},
						{
							"_nr": "13",
							"__text": "Aspecte ele tehnologiilor si performantelor sistemelor de memorii MIS moderne"
						},
						{
							"_nr": "14",
							"__text": "Echipamente si sisteme de intrare iesire si prelucrarea datelor grafice"
						},
						{
							"_nr": "15",
							"__text": "Preocuparea colectivului intreprinderii ELECTROAPARATAJ in transformarea acesteia in unitate model"
						},
						{
							"_nr": "16",
							"__text": "Studiul muncii si aplicarea acestuia la ELECTROAPARATAJ"
						},
						{
							"_nr": "17",
							"__text": "Modelarea matematica a programarii activitatii de asimilare a noilor produse"
						},
						{
							"_nr": "18",
							"__text": "Model pentru elaborarea analitica a normelor de timp pe reper si a manoperei de produs cu ajutorul calculatorului"
						},
						{
							"_nr": "19",
							"__text": "Sinteza lucrarilor simpozionului de bioingineriemedicala aplicata 'Medicina si tehnica', Bucuresti, 1974"
						},
						{
							"_nr": "20",
							"__text": "Tehnologia medicala domeniu de cooperare multidisciplinara"
						},
						{
							"_nr": "21",
							"__text": "Cibernetica si teoria sistemelor in progresul bioingineriei"
						},
						{
							"_nr": "22",
							"__text": "Conducere colectiva. Principiu si metoda"
						},
						{
							"_nr": "23",
							"__text": "Terminologie bilingva in domeniul informaticii si calculatoare (A-B)"
						}
					]
				},
				"_id": "829",
				"_isbn": ""
			},
			{
				"titlu": "AMC 50",
				"autori": {
					"autor": [
						{
							"_nume": "N. Teianu"
						},
						{
							"_nume": "P. Bakonyi"
						},
						{
							"_nume": "A. Bekessy"
						},
						{
							"_nume": "J. Demetrovics"
						},
						{
							"_nume": "M. Ruda"
						},
						{
							"_nume": "I. Paiusi"
						},
						{
							"_nume": "A. Tatu"
						},
						{
							"_nume": "N. Tudorancescu"
						},
						{
							"_nume": "R. Manolescu"
						},
						{
							"_nume": "C. Matei"
						},
						{
							"_nume": "C. Basarab"
						},
						{
							"_nume": "S. Sporea"
						},
						{
							"_nume": "R. Radulescu"
						},
						{
							"_nume": "St. Lupulescu"
						},
						{
							"_nume": "T. Geber"
						},
						{
							"_nume": "Th. Popescu"
						},
						{
							"_nume": "M. Sirbu"
						},
						{
							"_nume": "A. Davidoviciu"
						},
						{
							"_nume": "M. Dumietru"
						},
						{
							"_nume": "R. Radescu"
						},
						{
							"_nume": "M. Algiu"
						},
						{
							"_nume": "A. Varga"
						},
						{
							"_nume": "V. Sima"
						},
						{
							"_nume": "B. Droasca"
						},
						{
							"_nume": "D. Stanescu"
						},
						{
							"_nume": "I. Budea"
						},
						{
							"_nume": "G. Gromov"
						}
					]
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "368",
				"pretOriginal": "25",
				"colectia": {
					"_numarul": "50",
					"_nume": "AMC"
				},
				"traducere": {
					"traducatori": {
						"traducator": [
							"Adrian Davidoviciu",
							"Vasile Sima",
							"Radu Magda",
							"Marcel Sarbu",
							"Aristide Predoi",
							"Sandu Lazar",
							"Dan Dobrescu",
							"Alexandra Tatu",
							"Matei Constantin",
							"Doina Tudor",
							"Nicolae Tudorancescu",
							"Ilie Paiusi",
							"Cornelia Basarab",
							"Dan Radulescu",
							"Virgil Micula",
							"Serban Sporea",
							"Radu Manolescu",
							"Nicolae Algiu",
							"Leonard Stoian Horobat",
							"Gabriel Spiridon",
							"Eust. Stanciu",
							"Serban Lupescu"
						]
					},
					"_an": "",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Traznea"
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_50_Tehnica.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatoare de buzunar programabile (CBP-uri) si microcalculatoare personale (PC-uri) in proiectare. Metodica si secvente simple"
						},
						{
							"_nr": "2",
							"__text": "Microcalculatoare pentru spitale"
						},
						{
							"_nr": "3",
							"__text": "Sisteme electroenergetice"
						},
						{
							"_nr": "4",
							"__text": "Modelari in agricultura si industrie"
						},
						{
							"_nr": "5",
							"__text": "Determinarea defectiunilor in instalatii automatizate"
						},
						{
							"_nr": "6",
							"__text": "Reglarea proceselor industriale"
						},
						{
							"_nr": "7",
							"__text": "Automatica si informatica in biologie si medicina"
						},
						{
							"_nr": "8",
							"__text": "Servoactionari si regulatoare cu uP"
						},
						{
							"_nr": "9",
							"__text": "Ingineria programarii"
						},
						{
							"_nr": "10",
							"__text": "Conducere in mod real cu calculatoare"
						},
						{
							"_nr": "11",
							"__text": "Reglare automata cu microprocesoare"
						},
						{
							"_nr": "12",
							"__text": "Aplicatii ale sistemelor mari in lume"
						},
						{
							"_nr": "13",
							"__text": "Modelare si identificare"
						},
						{
							"_nr": "14",
							"__text": "Sinteza sistemelor automate"
						},
						{
							"_nr": "15",
							"__text": "Analiza si sinteza sistemelor 2-D"
						},
						{
							"_nr": "16",
							"__text": "Conducerea adaptiva si stohastica"
						},
						{
							"_nr": "17",
							"__text": "Programe de test pentru CORAL si INDEPENDENT. Memento"
						},
						{
							"_nr": "18",
							"__text": "Fenomenul calculatoarelor personale"
						}
					]
				},
				"_id": "830",
				"_isbn": ""
			},
			{
				"titlu": "FORTH - concept informatic si limbaj de programare",
				"autori": {
					"autor": [
						{
							"_nume": "Radu Berindeanu"
						},
						{
							"_nume": "Agota Matekovits"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Facla",
				"numarPagini": "300",
				"pretOriginal": "85",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan I. Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "FORTH_concept_informatic_si_limbaj_de_programare_Berindeanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare generala"
						},
						{
							"_nr": "2",
							"__text": "Limbajul FORTH - concepte de baza"
						},
						{
							"_nr": "3",
							"__text": "Programarea in FORTH - notiuni fundamentale"
						},
						{
							"_nr": "4",
							"__text": "Sistemul de operare FORTH - structura si functionare"
						},
						{
							"_nr": "5",
							"__text": "Operatii de intrare/iesire cu memoria externa"
						},
						{
							"_nr": "6",
							"__text": "Editorul FORTH"
						},
						{
							"_nr": "7",
							"__text": "Aspecte particulare ale limbajului FORTH"
						},
						{
							"_nr": "8",
							"__text": "Proiectarea si implementarea aplicatiilor in FORTH"
						},
						{
							"_nr": "9",
							"__text": "Definitii utile"
						},
						{
							"_nr": "10",
							"__text": "Implementari FORTH pe microprocesoare"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii in FORTH"
						},
						{
							"_nr": "Anexa A",
							"__text": "Lista mesajelor de eroare in FORTH"
						},
						{
							"_nr": "Anexa B",
							"__text": "Lista cuvintelor limbajului FORTH"
						},
						{
							"_nr": "Anexa C",
							"__text": "Index cuvinte FORTH pe capitole"
						}
					]
				},
				"_id": "831",
				"_isbn": "973-36-0077-6"
			},
			{
				"titlu": "Teleinformatica",
				"autori": {
					"autor": [
						{
							"_nume": "Florin-Vladimir Pilat"
						},
						{
							"_nume": "Lucia Coculeascu"
						},
						{
							"_nume": "Valentin Cristea"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "1986",
				"pretOriginal": "43",
				"redactori": {
					"redactor": "Dinu-ionitiu Nechita"
				},
				"tehnoredactori": {
					"tehnoredactor": "Angela Ilovan"
				},
				"coperta": {
					"imagine": {
						"_path": "Teleinformatica_Pilat.jpg"
					},
					"__text": "Christian Nicolescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modele pentru sisteme teleinformatice"
						},
						{
							"_nr": "3",
							"__text": "Canele de teletransmisie a datelor"
						},
						{
							"_nr": "4",
							"__text": "Legatura de date"
						},
						{
							"_nr": "5",
							"__text": "Transportul datelor in sistemele teleinformatice"
						},
						{
							"_nr": "6",
							"__text": "Accesul utilizatorilor la resurselor sistemului teleinformatic"
						},
						{
							"_nr": "7",
							"__text": "Sisteme teleinformatice dupa natura aplicatiei"
						},
						{
							"_nr": "8",
							"__text": "Conceperea si realizarea sistemelor teleinformatice"
						},
						{
							"_nr": "9",
							"__text": "Tendinte si perspective in evolutia teleinformaticii in anii '80"
						}
					]
				},
				"_id": "832",
				"_isbn": ""
			},
			{
				"titlu": "Teste de concordanta cu programe in FORTRAN",
				"autori": {
					"autor": [
						{
							"_nume": "Virgil Craiu"
						},
						{
							"_nume": "Radu Enache"
						},
						{
							"_nume": "Octavian Basca"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "1986",
				"pretOriginal": "24",
				"redactori": {
					"redactor": "Ion Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marinela Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Teste_de_concordanta_cu_programe_in_FORTRAN_Craiu.jpg"
					},
					"__text": "Constantin Gheorghiu-Enescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Testul X2"
						},
						{
							"_nr": "2",
							"__text": "Teste de concordanta pentru repartitiile gama si exponentiala"
						},
						{
							"_nr": "3",
							"__text": "Test de ajuatare pentru repartitia binomiala"
						},
						{
							"_nr": "4",
							"__text": "Teste de ajustare pentru repartitia Poisson"
						},
						{
							"_nr": "5",
							"__text": "Teste de tip Kolmogorov-Smirnov"
						},
						{
							"_nr": "6",
							"__text": "Testul Cramer-von Mises. Testul Anderson-Darling"
						},
						{
							"_nr": "7",
							"__text": "Testul Watson"
						},
						{
							"_nr": "8",
							"__text": "Testul Kuiper"
						},
						{
							"_nr": "9",
							"__text": "Teste de ajustare pentru repartitia valoriii extreme"
						},
						{
							"_nr": "10",
							"__text": "Teste de ajustare pentru repartitia logistica"
						},
						{
							"_nr": "11",
							"__text": "Teste de ajustare pentru o ipoteza simpla bazate pe functia caracteristica"
						},
						{
							"_nr": "12",
							"__text": "Inlocuirea ipotezelor compuse cu ipoteze simple echivalente"
						},
						{
							"_nr": "13",
							"__text": "Observatii pe cerc"
						},
						{
							"_nr": "14",
							"__text": "Teste de tip Shapiro"
						},
						{
							"_nr": "15",
							"__text": "Testul D'Agostino"
						},
						{
							"_nr": "16",
							"__text": "Teste de independenta si verificarea normalitatii"
						},
						{
							"_nr": "17",
							"__text": "Coeficienti pearsonieni si verificarea normalitatii"
						},
						{
							"_nr": "18",
							"__text": "Teste bazate pe statistici de tip coeficient de corelatie"
						},
						{
							"_nr": "19",
							"__text": "U-statisticile in verificarea ipotezelor de concordanta"
						},
						{
							"_nr": "20",
							"__text": "Compararea testelor de normalitate"
						}
					]
				},
				"_id": "833",
				"_isbn": ""
			},
			{
				"titlu": "Programarea calculatoarelor in limbajul C++",
				"autori": {
					"autor": [
						{
							"_nume": "Luminita Duta"
						},
						{
							"_nume": "Nicolae Cecilian Istrate"
						},
						{
							"_nume": "Adriana Alexandru"
						},
						{
							"_nume": "Gabriel Gorghiu"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Cetatea de Scaun",
				"numarPagini": "128",
				"pretOriginal": "70000",
				"redactori": {
					"redactor": "Dan Margarit"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_in_limbajul_Cpp_Duta.jpg"
					},
					"__text": "Dan Margarit"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului C++"
						},
						{
							"_nr": "2",
							"__text": "Tipuri simple de date"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de control"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de date compuse (structurate)"
						},
						{
							"_nr": "5",
							"__text": "Functii in C++"
						},
						{
							"_nr": "6",
							"__text": "Fisiere si operatii de intrare/iesire"
						},
						{
							"_nr": "7",
							"__text": "Directive preprocesor"
						}
					]
				},
				"_id": "834",
				"_isbn": "973-85691-7-6"
			},
			{
				"titlu": "Manual Basic",
				"autori": {
					"autor": {
						"_nume": "[RET - Alpha Ltd.]"
					}
				},
				"anAparitie": "1991",
				"editura": "TM",
				"numarPagini": "70",
				"pretOriginal": "100",
				"coperta": {
					"imagine": {
						"_path": "Manual_Basic_RET_ALPHA_Ltd.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Tastatura; Ecranul TV"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Programe, linii de program, editarea programelor"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Decizii; Bucle de program (iteratii)"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Subrutine; READ, DATA, RESTORE"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Expresii; Alte lucruri despre stringuri"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Functii"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Functii matematice; Numere aleatoare (intamplatoare)"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Tablouri; Conditii"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Setul de caractere"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Mai multe despre PRINT si INPUT; Culori"
						},
						{
							"_nr": "Lectia 11",
							"__text": "PLOT, DRAW, CIRCLE, POINT"
						},
						{
							"_nr": "Lectia 12",
							"__text": "SAVE; VERIFY; LOAD; MERGE; Lucrul cu imprimanta; CLEAR; USR"
						}
					]
				},
				"_id": "835",
				"_isbn": ""
			},
			{
				"titlu": "Minunata lumea a HC-ului : programe demonstrative in limbajul de asamblare Z80",
				"autori": {
					"autor": {
						"_nume": "Vlad Atanasiu"
					}
				},
				"anAparitie": "1994",
				"editura": "Agni",
				"numarPagini": "164",
				"pretOriginal": "1950",
				"redactori": {
					"redactor": "Victor Cososchi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vlad Atanasiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Minunata_lume_a_HC_ului_Atanasiu.jpg"
					},
					"__text": "Adina Dumitriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Introducere in cod masina"
						},
						{
							"_nr": "4",
							"__text": "Efecte de acoperire a ecranului"
						},
						{
							"_nr": "5",
							"__text": "efecte de asteptare"
						},
						{
							"_nr": "6",
							"__text": "Lucrul cu imagini"
						},
						{
							"_nr": "7",
							"__text": "Miscare pe ecran"
						}
					]
				},
				"_id": "836",
				"_isbn": "973-95626-7-1"
			},
			{
				"titlu": "Cum sa realizam jocuri pe calculator (editia II)",
				"autori": {
					"autor": {
						"_nume": "Ion Diamandi"
					}
				},
				"anAparitie": "1994",
				"editura": "Agni",
				"numarPagini": "160",
				"pretOriginal": "2700",
				"redactori": {
					"redactor": "Mihaela Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Romanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Cum_sa_realizam_jocuri_pe_calculator_Editia_II_Diamandi.jpg"
					},
					"__text": "Iolanda Malamen"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fundamente ale realizarii jocurilor pe calculator"
						},
						{
							"_nr": "II",
							"__text": "Incepem sa realizam jocuri"
						},
						{
							"_nr": "III",
							"__text": "Cum se pot realiza desene cu calculatorul"
						},
						{
							"_nr": "IV",
							"__text": "Cum se pot realiza efecte sonore si muzica cu calculatorul"
						},
						{
							"_nr": "V",
							"__text": "Raspunsuri la exercitiile si temele propuse"
						}
					]
				},
				"_id": "837",
				"_isbn": "973-963-4-5"
			},
			{
				"titlu": "Clipper v5.0 - Ghid de utilizare",
				"autori": {
					"autor": [
						{
							"_nume": "Maria Lascutoiu"
						},
						{
							"_nume": "Mirela Dumitrescu"
						},
						{
							"_nume": "Sorin Butnaru"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "MATE",
				"numarPagini": "200",
				"pretOriginal": "1500",
				"coperta": {
					"imagine": {
						"_path": "Clipper_v5_0_Ghid_de_utilizare_Lascutoiu.jpg"
					},
					"__text": "L. Ionescu, S. Butnaru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Principii fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Compilatorul CLIPPER"
						},
						{
							"_nr": "3",
							"__text": "Editorul de lagaturi RTLINK"
						},
						{
							"_nr": "4",
							"__text": "Clase de obiecte"
						},
						{
							"_nr": "5",
							"__text": "Variabile si tipuri de date"
						},
						{
							"_nr": "6",
							"__text": "Crearea si exploatarea bazelor de date"
						},
						{
							"_nr": "7",
							"__text": "Crearea rapoartelor"
						},
						{
							"_nr": "8",
							"__text": "Crearea etichetelor"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea editorului"
						},
						{
							"_nr": "10",
							"__text": "Debbuger (depanatorul)"
						},
						{
							"_nr": "11",
							"__text": "NORTON GUIDE"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Exemplu"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Comenzi"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Functii"
						}
					]
				},
				"_id": "838",
				"_isbn": "973-95131-0-7"
			},
			{
				"titlu": "Secrete ale programarii cod obiecte. Joc de sah in cod sursa Turbo Pascal",
				"autori": {
					"autor": {
						"_nume": "Murgu Ion"
					}
				},
				"anAparitie": "1993",
				"editura": "Zamolxis",
				"numarPagini": "140",
				"pretOriginal": "1000",
				"pretCumparare": "500000",
				"coperta": {
					"imagine": {
						"_path": "Secrete_ale_programarii_cod_obiecte_Joc_de_sah_in_cod_sursa_Turbo_Pascal_Murgu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1.1",
							"__text": "Introducere"
						},
						{
							"_nr": "1.2",
							"__text": "Incapsulare"
						},
						{
							"_nr": "1.3",
							"__text": "Declararea obiectelor"
						},
						{
							"_nr": "1.4",
							"__text": "Implementarea obiectelor"
						},
						{
							"_nr": "2",
							"__text": "Mostenirea si redefinirea"
						},
						{
							"_nr": "3.1",
							"__text": "Variabile in Turbo-Pascal"
						},
						{
							"_nr": "3.2",
							"__text": "Variabile statice"
						},
						{
							"_nr": "3.3",
							"__text": "Tipul pointer"
						},
						{
							"_nr": "3.4",
							"__text": "Variabile dinamice"
						},
						{
							"_nr": "4.1",
							"__text": "Obiecte dinamice"
						},
						{
							"_nr": "4.2",
							"__text": "Instante de tip obiect"
						},
						{
							"_nr": "4.3",
							"__text": "Polimorfism"
						},
						{
							"_nr": "4.4",
							"__text": "Virtualizare"
						},
						{
							"_nr": "5.1",
							"__text": "Observatii"
						},
						{
							"_nr": "6.1",
							"__text": "Joc concurs"
						},
						{
							"_nr": "7.1",
							"__text": "Eventual concurs"
						},
						{
							"_nr": "8.1",
							"__text": "Folosirea intreruperilor"
						}
					]
				},
				"_id": "839",
				"_isbn": "973-96028-0-0"
			},
			{
				"titlu": "Prelucrarea numerica a semnalului vocal",
				"autori": {
					"autor": [
						{
							"_nume": "Grigore Stolojanu"
						},
						{
							"_nume": "Valentin Podaru"
						},
						{
							"_nume": "Florin Cetina"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Militara",
				"numarPagini": "264",
				"pretOriginal": "21.50",
				"redactori": {
					"redactor": "Dorin Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "V. Giodic"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea_numerica_a_semnalului_vocal_Stolojanu.jpg"
					},
					"__text": "D. Andrei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Semnale"
						},
						{
							"_nr": "2",
							"__text": "Transformari discrete ortogonale"
						},
						{
							"_nr": "3",
							"__text": "Caracterizarea sistemelor discrete cu tranformata Z"
						},
						{
							"_nr": "4",
							"__text": "Filtre numerice"
						},
						{
							"_nr": "5",
							"__text": "Prelucrarea numerica in timp real"
						},
						{
							"_nr": "6",
							"__text": "Metode de analiza a semnalului vocal"
						},
						{
							"_nr": "7",
							"__text": "Estimarea parametrilor fundamentali ai semnalui vocal"
						},
						{
							"_nr": "8",
							"__text": "Circuite si dispozitive utilizate in prelucrarea numerica a semnalului vocal"
						},
						{
							"_nr": "9",
							"__text": "Implementari de prelucrare a semnalului vocal"
						}
					]
				},
				"_id": "840",
				"_isbn": ""
			},
			{
				"titlu": "Secrete Windows NT Server 4",
				"autori": {
					"autor": {
						"_nume": "Drew Heywood"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "1000",
				"pretOriginal": "120000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "227",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Inside Windows NT Server 4",
					"editura": "New Riders Publishing",
					"traducatori": {
						"traducator": [
							"Daniel Merezeanu",
							"Rodica Bitoiu"
						]
					},
					"_an": "1996",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Secrete_Windows_NT_Server_NT_Heywood.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Notiuni fundamentali"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Instalarea si gestionarea clientilor"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Administrare"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "841",
				"_isbn": "973-601-562-9"
			},
			{
				"titlu": "PC - Depanare si modelare",
				"autori": {
					"autor": {
						"_nume": "Scott Mueller"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "1424",
				"pretOriginal": "0",
				"pretCumparare": "1000",
				"colectia": {
					"_numarul": "60",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Upgrading and Repairing PCs",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Camelia Diaconescu",
							"Dan Soloveanu",
							"Cristina Botez",
							"Bogdan Constantin",
							"Alin Ioachimescu",
							"Emilia Patilea",
							"Nicolae Pora",
							"Dana Soloveanu"
						]
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Manuela Matache",
						"Cristian Matache"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "PC_Depanare_si_modernizare_Mueller.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere si privire de ansamblu asupra hardului calculatoarelor personale"
						},
						{
							"_nr": "II",
							"__text": "Componente principale ale sistemului"
						},
						{
							"_nr": "III",
							"__text": "Dispozitive de intrare/iesire"
						},
						{
							"_nr": "IV",
							"__text": "Dispozitive de memorare de mare capacitate"
						},
						{
							"_nr": "V",
							"__text": "Asamblarea si intretinerea sistemelor"
						},
						{
							"_nr": "VI",
							"__text": "Depistarea defectelor hard si soft"
						},
						{
							"_nr": "VII",
							"__text": "Sitemele IBM. Date tehnice"
						},
						{
							"_nr": "VIII",
							"__text": "Anexe"
						}
					]
				},
				"_id": "842",
				"_isbn": "973-601-242-5"
			},
			{
				"titlu": "Metode noi in matematica aplicata: algoritmi si programe de baza si de algebra liniara",
				"autori": {
					"autor": {
						"_nume": "Vasile Sima"
					}
				},
				"anAparitie": "1992",
				"editura": "Stiintifica",
				"numarPagini": "416",
				"pretOriginal": "275",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Maria Boricean"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_noi_in_matematica_aplicata_algoritmi_si_programe_de_baza_si_de_algebra_liniara_Sima.jpg"
					},
					"__text": "Gheorghe Gh. Marinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calcule stiintifice, algoritmi si programe"
						},
						{
							"_nr": "2",
							"__text": "Calcule fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Calcule si operatii speciale"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de ecuatii algebrice liniare"
						},
						{
							"_nr": "5",
							"__text": "Valori si vectori proprii"
						}
					]
				},
				"_id": "843",
				"_isbn": "973-44-0088-6"
			},
			{
				"titlu": "Ghidul bobocului pentru calculatoare personale",
				"autori": {
					"autor": {
						"_nume": "Joe Kraynak"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "360",
				"pretOriginal": "19900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "93",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The complete idiot's guide to PC's",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Rodica Bitoiu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Liana Laca"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Vladulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_calculatoare_personale_Kraynak.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "Sa incepem"
						},
						{
							"_nr": "Partea a 2-a",
							"__text": "Lucrul cu sistemele de operare DOS si OS/2 si cu programul Windows (vechi si nou)"
						},
						{
							"_nr": "Partea a 3-a",
							"__text": "Sa trecem la treaba cu aplicatiile"
						},
						{
							"_nr": "Partea a 4-a",
							"__text": "Este randul dvs. sa creati"
						},
						{
							"_nr": "Partea a 5-a",
							"__text": "Gestionarea discurilor, a cataloagelor si a fisierelor"
						},
						{
							"_nr": "Partea a 6-a",
							"__text": "Intindeti mana si veti atinge alte calculatoare"
						},
						{
							"_nr": "Partea a 7-a",
							"__text": "cum sa deveniti un utilizator experimentat"
						}
					]
				},
				"_id": "844",
				"_isbn": "973-601-307-3"
			},
			{
				"titlu": "Ghidul bobocului pentru jocuri pe calculator",
				"autori": {
					"autor": {
						"_nume": "David Haskin"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "360",
				"pretOriginal": "25000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "94",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The complete idiot's guide to PC games",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Nicolae Ionescu Crutan"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Alina Popa"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Vladulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_jocuri_pe_calculator_Haskin.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Inainte de joaca"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Cum o fac jucatorii"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Sa trecem la lucruri concrete"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Sa inceapa jocurilor"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Aveti totul la dispozitie"
						}
					]
				},
				"_id": "845",
				"_isbn": "973-601-306-5"
			},
			{
				"titlu": "Ghidul bobocului pentru Microsoft Office",
				"autori": {
					"autor": {
						"_nume": "Sherry Kinkoph"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "368",
				"pretOriginal": "13900",
				"colectia": {
					"_numarul": "75",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The complete idiot's guide to Microsoft Office",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Mirela Siminiceanu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cora Radulian"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alin Dragomir"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_Microsoft_Office_Kinkoph.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "De fapt ce este Microsoft Office"
						},
						{
							"_nr": "Partea a 2-a",
							"__text": "Microsoft Office - Intrari si Iesiri"
						},
						{
							"_nr": "Partea a 3-a",
							"__text": "Cum sa le colectam pe toate"
						},
						{
							"_nr": "Partea a 4-a",
							"__text": "Sa facem ca programele Office sa lucreze pentru noi"
						}
					]
				},
				"_id": "846",
				"_isbn": "973-601-269-7"
			},
			{
				"titlu": "Word pentru Windows 95 (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Peter Aitken"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "184",
				"pretOriginal": "8000",
				"colectia": {
					"_numarul": "104",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 Minute Guide To Word for Windows 95",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Daniel Merezeanu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Word_Sub_Windows_95_Seria_10_minute_Aitken.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Lansarea in executie"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de asistenta sotware on-line"
						},
						{
							"_nr": "3",
							"__text": "Crearea unui document nou"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea sabloanelor"
						},
						{
							"_nr": "5",
							"__text": "Controlul modului de afisare pe ecran"
						},
						{
							"_nr": "6",
							"__text": "Salvarea documentelor"
						},
						{
							"_nr": "7",
							"__text": "Incarcarea documentelor"
						},
						{
							"_nr": "8",
							"__text": "Tiparirea documentelor"
						},
						{
							"_nr": "9",
							"__text": "Mutarea si copierea textului"
						},
						{
							"_nr": "10",
							"__text": "Formatarea documentelor"
						},
						{
							"_nr": "11",
							"__text": "Formatarea caracterlor"
						},
						{
							"_nr": "12",
							"__text": "Setarea marginilor si a spatierii liniilor"
						},
						{
							"_nr": "13",
							"__text": "Setarea pozitiilor fixe de scriere"
						},
						{
							"_nr": "14",
							"__text": "Alinierea textului"
						},
						{
							"_nr": "15",
							"__text": "Cautarea si inlocuirea unui text"
						},
						{
							"_nr": "16",
							"__text": "Folosirea sabloanelor"
						},
						{
							"_nr": "17",
							"__text": "Numerotarea paginilor, antete si subsoluri"
						},
						{
							"_nr": "18",
							"__text": "Note su subsol si note de final"
						},
						{
							"_nr": "19",
							"__text": "Utilizarea facilitatii AutoCorrect"
						},
						{
							"_nr": "20",
							"__text": "Caractere speciale si simboluri"
						},
						{
							"_nr": "21",
							"__text": "Corectarea documentului"
						},
						{
							"_nr": "22",
							"__text": "Liste numerotate sau marcate"
						},
						{
							"_nr": "23",
							"__text": "Aranjarea textului in coloane"
						},
						{
							"_nr": "24",
							"__text": "Folosirea stilurilor"
						},
						{
							"_nr": "25",
							"__text": "Crearea si modificarea stilurilor"
						},
						{
							"_nr": "26",
							"__text": "Formatare automata a textului"
						},
						{
							"_nr": "27",
							"__text": "Tabele"
						},
						{
							"_nr": "28",
							"__text": "Adaugarea de imagini grafice in documente"
						},
						{
							"_nr": "29",
							"__text": "Deschiderea mai multor documente simultan"
						},
						{
							"_nr": "30",
							"__text": "Lucrul cu documente multiple"
						},
						{
							"_nr": "31",
							"__text": "Economisiti timpul cu ajutorul macro-urilor"
						}
					]
				},
				"_id": "847",
				"_isbn": "973-601-336-7"
			},
			{
				"titlu": "Word pentru Windows 6 (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Peter Aitken"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "144",
				"pretOriginal": "3900",
				"colectia": {
					"_numarul": "40",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 Minute Guide To Word for Windows 6",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "[Colectiv]"
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Word_Sub_Windows_6_Seria_10_minute_Aitken.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Pornirea"
						},
						{
							"_nr": "2",
							"__text": "Sistemul Help (sistem de asistenta soft)"
						},
						{
							"_nr": "3",
							"__text": "Crearea unui document nou"
						},
						{
							"_nr": "4",
							"__text": "Controlul modului de afisare pe ecran"
						},
						{
							"_nr": "5",
							"__text": "Salvarea documentelor"
						},
						{
							"_nr": "6",
							"__text": "Incarcarea documentelor"
						},
						{
							"_nr": "7",
							"__text": "Tiparirea documentelor"
						},
						{
							"_nr": "8",
							"__text": "Mutarea si copierea textului"
						},
						{
							"_nr": "9",
							"__text": "Formatarea documentelor"
						},
						{
							"_nr": "10",
							"__text": "Formatarea caracterlor"
						},
						{
							"_nr": "11",
							"__text": "Setarea marginilor si a spatiilui dintre linii"
						},
						{
							"_nr": "12",
							"__text": "Setarea pozitiilor de scriere (taburi)"
						},
						{
							"_nr": "13",
							"__text": "Alinierea textului"
						},
						{
							"_nr": "14",
							"__text": "Cautarea si inlocuirea unui text"
						},
						{
							"_nr": "15",
							"__text": "Folosirea modelelor"
						},
						{
							"_nr": "16",
							"__text": "Numerotarea paginilor, antetul si subsolul paginii"
						},
						{
							"_nr": "17",
							"__text": "Corectare documentului"
						},
						{
							"_nr": "18",
							"__text": "Crearea listelor numerotate si a celor marcate"
						},
						{
							"_nr": "19",
							"__text": "Aranjarea textului in coloane"
						},
						{
							"_nr": "20",
							"__text": "Folosirea stilurilor"
						},
						{
							"_nr": "21",
							"__text": "Crearea propriilor stiluri"
						},
						{
							"_nr": "22",
							"__text": "Formatare formatarii automate"
						},
						{
							"_nr": "23",
							"__text": "Tabele"
						},
						{
							"_nr": "24",
							"__text": "Adaugarea de imagini grafice documentelor"
						},
						{
							"_nr": "25",
							"__text": "Folosrea Auto Text-ului"
						},
						{
							"_nr": "26",
							"__text": "Deschiderea documentelor multiple"
						},
						{
							"_nr": "27",
							"__text": "Lucrul cu documente multiple"
						},
						{
							"_nr": "28",
							"__text": "Economisirea timpul cu macro-uri"
						}
					]
				},
				"_id": "848",
				"_isbn": "973-601-336-7"
			},
			{
				"titlu": "Excel sub Windows 95 (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Trudi Reisner"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "176",
				"pretOriginal": "8000",
				"colectia": {
					"_numarul": "117",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 Minute Guide To Excel for Windows 95",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Rotica Bitoiu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Excel_sub_Windows_95_Seria_10_minute_Reisner.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Lansarea in executie a progeamului Excel si iesirea din el"
						},
						{
							"_nr": "2",
							"__text": "Sa examinam fereastra Excel"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea barelor cu instrumente de lucru ale programului Excel"
						},
						{
							"_nr": "4",
							"__text": "Introducerea diferitelor tipuri de date"
						},
						{
							"_nr": "5",
							"__text": "Prelucrarea datelor introduse"
						},
						{
							"_nr": "6",
							"__text": "Lucrul cu fisiere agenda de lucru"
						},
						{
							"_nr": "7",
							"__text": "Lucrul cu foile de calcul"
						},
						{
							"_nr": "8",
							"__text": "Tiparirea agendei de lucru"
						},
						{
							"_nr": "9",
							"__text": "Tiparirea foilor de calcul mari"
						},
						{
							"_nr": "10",
							"__text": "Lucrul cu serii de celule"
						},
						{
							"_nr": "11",
							"__text": "Copierea, mutarea si stergerea datelor"
						},
						{
							"_nr": "12",
							"__text": "Inserarea si stergerea celulelor, a randurilor si a colanelor"
						},
						{
							"_nr": "13",
							"__text": "Efectuarea calculelor cu ajutorul formulelor"
						},
						{
							"_nr": "14",
							"__text": "Copierea formulelor si recalcularea lor"
						},
						{
							"_nr": "15",
							"__text": "Efectuarea calculelor cu ajutorul functiilor"
						},
						{
							"_nr": "16",
							"__text": "Potrivirea formatului numerelor si a alinierii textului"
						},
						{
							"_nr": "17",
							"__text": "Dati textului dumneavoastra un alt aspect"
						},
						{
							"_nr": "18",
							"__text": "Adaugarea chenarelor si a umbrelor celuleo"
						},
						{
							"_nr": "19",
							"__text": "Modificarea latimii coloanei si a inaltimii randului"
						},
						{
							"_nr": "20",
							"__text": "Aranjarea cu ajutorul stilurilor"
						},
						{
							"_nr": "21",
							"__text": "Lucrul cu sabloane"
						},
						{
							"_nr": "22",
							"__text": "Crearea digramelor"
						},
						{
							"_nr": "23",
							"__text": "Sa invatam notiunile elementeare ale bazelor de date"
						},
						{
							"_nr": "24",
							"__text": "Gasirea si ordonarea datelor dintr-o baza de date"
						},
						{
							"_nr": "25",
							"__text": "Adaugarea in foile de calcul a elementelor grafice si a altor obiecte"
						}
					]
				},
				"_id": "849",
				"_isbn": "973-601-361-8"
			},
			{
				"titlu": "Alegerea si cumpararea calculatorului personal (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Shelley O'Hara"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "168",
				"pretOriginal": "5000",
				"colectia": {
					"_numarul": "67",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 Minute Guide To Buying a Computer",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Oana Marasescu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Alegerea_si_cumparea_calculatorului_personal_Seria_10_minute_Hara.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De ce un calculator?"
						},
						{
							"_nr": "2",
							"__text": "Ce doriti sa faceti cu un calculator?"
						},
						{
							"_nr": "3",
							"__text": "Cum sa alegeti programele necesare?"
						},
						{
							"_nr": "4",
							"__text": "Ce este de fapt un calculator?"
						},
						{
							"_nr": "5",
							"__text": "Tipuri de calculatoare"
						},
						{
							"_nr": "6",
							"__text": "Microprocesorul si memoria"
						},
						{
							"_nr": "7",
							"__text": "Discuri"
						},
						{
							"_nr": "8",
							"__text": "Alte consideratii privind unitatea de sistem"
						},
						{
							"_nr": "9",
							"__text": "Monitoare"
						},
						{
							"_nr": "10",
							"__text": "Tastatura si mouse"
						},
						{
							"_nr": "11",
							"__text": "Imprimante"
						},
						{
							"_nr": "12",
							"__text": "Comunicatii"
						},
						{
							"_nr": "13",
							"__text": "Calculatoare multimedia"
						},
						{
							"_nr": "14",
							"__text": "Alte echipamente"
						},
						{
							"_nr": "15",
							"__text": "Calculatoare Macintosh"
						},
						{
							"_nr": "16",
							"__text": "Calculatoare portabile"
						},
						{
							"_nr": "17",
							"__text": "Alcatuirea unei liste complete de echipamente, accesorii si programe"
						},
						{
							"_nr": "18",
							"__text": "Prospectarea pietei"
						},
						{
							"_nr": "19",
							"__text": "De unde cumparati calculatorul?"
						},
						{
							"_nr": "20",
							"__text": "Decizia finala de cumparare"
						},
						{
							"_nr": "21",
							"__text": "Dupa cumparare..."
						}
					]
				},
				"_id": "850",
				"_isbn": "973-601-228-X"
			},
			{
				"titlu": "PC - ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Silvia Curteanu"
					}
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "240",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ioana Gagea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": "PC_Ghid_de_utilizare_Curteanu.jpg"
					},
					"__text": "Manuela Oborogranu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Hardware si software. Elemene componete ale calculatorului personal"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "3",
							"__text": "Editarea si procesarea textelor"
						},
						{
							"_nr": "4",
							"__text": "Calcul tabelar si diagrame"
						},
						{
							"_nr": "5",
							"__text": "Grafica"
						},
						{
							"_nr": "6",
							"__text": "Internet"
						}
					]
				},
				"_id": "851",
				"_isbn": "973-683-662-2"
			},
			{
				"titlu": "Algoritmi de automatizare a proiectarii. Programe. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Roman"
						},
						{
							"_nume": "Adrian Lustig"
						},
						{
							"_nume": "Cristian Stanescu"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "Militara",
				"numarPagini": "240",
				"pretOriginal": "23.50",
				"redactori": {
					"redactor": "Misu Raileanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gh. Cucos"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_de_automatizare_a_proiectarii_Roman.jpg"
					},
					"__text": "Victor Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Dispozitive de desen de tipul plotter"
						},
						{
							"_nr": "2",
							"__text": "Dispozitive si tehnici de interactiune"
						},
						{
							"_nr": "3",
							"__text": "Arhitecturi de display-uri performante"
						},
						{
							"_nr": "4",
							"__text": "Elemente de geometrie computationala"
						},
						{
							"_nr": "5",
							"__text": "Grafica interactiva pentru automatizarea proiectarii"
						},
						{
							"_nr": "6",
							"__text": "Transferarea descrierii numerice a produselor intre sisteme CAD diferite"
						},
						{
							"_nr": "7",
							"__text": "Sisteme de proiectare asistata de calculator"
						}
					]
				},
				"_id": "852",
				"_isbn": ""
			},
			{
				"titlu": "Practica bazelor de date. Totul despre... SOCRATE si SOCRATE-MINI pe FELIX C, Coral, Independent (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Sabau"
						},
						{
							"_nume": "Vasile Avram"
						},
						{
							"_nume": "Aurelian Cojocaru"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Tehnica",
				"numarPagini": "496",
				"pretOriginal": "31",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Practica_bazelor_de_date_Totul_despre_SOCRATE_si_SOCRATE_MINI_pe_FELIX_C_CORAL_Independent_Vol_1_Sabau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Sectiunea I",
							"__text": "Baze de date si SGBD-ul SOCRATE"
						},
						{
							"_nr": "Sectiunea II",
							"__text": "SGBD SOCRATE  - Tratare generala"
						},
						{
							"_nr": "Sectiunea III",
							"__text": "Optimizari si dezvoltari de SGBD-uri"
						},
						{
							"_nr": "Sectiunea IV",
							"__text": "Aplicatie complexa cu SGBD SOCRATE pentru activitatea de personal"
						}
					]
				},
				"_id": "853",
				"_isbn": "973-31-0020-X"
			},
			{
				"titlu": "Practica bazelor de date. Totul despre... SOCRATE si SOCRATE-MINI pe FELIX C, Coral, Independent (Vol.2)",
				"autori": {
					"autor": [
						{
							"_nume": "Alexandru Sotir"
						},
						{
							"_nume": "Valeriu Orbeanu"
						},
						{
							"_nume": "Constantin Baronide"
						},
						{
							"_nume": "Razvan Nedelcu"
						},
						{
							"_nume": "Nicolae Fildan"
						},
						{
							"_nume": "Adrian Alexandrescu"
						},
						{
							"_nume": "andrian Dobritoiu"
						},
						{
							"_nume": "Dan Viorel Norea"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Tehnica",
				"numarPagini": "272",
				"pretOriginal": "31",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Trasnea"
				},
				"coperta": {
					"imagine": {
						"_path": "Practica_bazelor_de_date_Totul_despre_SOCRATE_si_SOCRATE_MINI_pe_FELIX_C_CORAL_Independent_Vol_2_Sabau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Sectiunea V",
							"__text": "Manual de lansare SOCRATE-MINI"
						},
						{
							"_nr": "Sectiunea VI",
							"__text": "Manual de utilizare SOCRATE-MINI"
						},
						{
							"_nr": "Sectiunea VII",
							"__text": "Manual de operare SOCRATE-MINI"
						},
						{
							"_nr": "Sectiunea VIII",
							"__text": "Aplicatie complexa cu SGBD SOCRATE+MINI pentru activitatea de exploatare a flotei maritime"
						}
					]
				},
				"_id": "854",
				"_isbn": "973-31-0022-6"
			},
			{
				"titlu": "Programarea in limbajul de asamblare LAF",
				"autori": {
					"autor": [
						{
							"_nume": "Dan N. Dobrescu"
						},
						{
							"_nume": "Leonard Hoboret-Stoian"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Albatros",
				"numarPagini": "448",
				"pretOriginal": "16",
				"tehnoredactori": {
					"tehnoredactor": "Cornel Cristescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_de_asamblare_LAF_Dobrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Asamblorul LAF"
						},
						{
							"_nr": "2",
							"__text": "Scurta prezentare a calculatorului Felix C-32"
						},
						{
							"_nr": "3",
							"__text": "Descrierea instructiunilor masina le limbajului de asamblare LAF"
						},
						{
							"_nr": "4",
							"__text": "Directivele limbajului de asamblare LAF"
						},
						{
							"_nr": "5",
							"__text": "Macroinstructiunile recunoscute de limbajul de asamblare LAF"
						},
						{
							"_nr": "6",
							"__text": "Biblioteca asamblorului LAF"
						},
						{
							"_nr": "7",
							"__text": "Indicatii de depnare si corectare a programelor"
						}
					]
				},
				"_id": "855",
				"_isbn": ""
			},
			{
				"titlu": "Limbajul C. Elemente fundamentale si aplicatii tehnice",
				"autori": {
					"autor": [
						{
							"_nume": "Titus Slavici"
						},
						{
							"_nume": "Serban Popa"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Eurostampa - Editura Fundatiei \"Ioan Slavici\"",
				"numarPagini": "220",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Serban Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C_Elemente_fundamentale_si_aplicatii_tehnice_Slavici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elementele fundamentale ale limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Bibliotecile limbajului C"
						},
						{
							"_nr": "3",
							"__text": "Mediul de dezvoltare al limbajului C"
						},
						{
							"_nr": "4",
							"__text": "Mediul de dezvoltare al limbajului C sub Windows"
						},
						{
							"_nr": "5",
							"__text": "Introducere in Microsoft Visual C++"
						},
						{
							"_nr": "6",
							"__text": "Produse industriale specifice conducerii sistemelor cu ajutorul calculatorului. Interfata de proces ADA111"
						}
					]
				},
				"_id": "856",
				"_isbn": "973-687-159-2"
			},
			{
				"titlu": "Utilizarea si programarea calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Ciocarlie"
						},
						{
							"_nume": "Rodica Ciocarlie"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Orizonturi Universitare",
				"numarPagini": "234",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "44",
					"_nume": "calculatoare-informatica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Rodica Ciocarlie"
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizarea_si_programarea_calculatoarelor_Ciocarlie.jpg"
					},
					"__text": "Dan Nitu, Adina Filca"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in utilizarea si programarea calculatoarelor"
						},
						{
							"_nr": "2",
							"__text": "Functii ale sistemului de operare in sprijinul activitatii de programare"
						},
						{
							"_nr": "3",
							"__text": "Mediul de programare Turbo Pascal"
						},
						{
							"_nr": "4",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "5",
							"__text": "Notiunea de tip. Tipur de date standard"
						},
						{
							"_nr": "6",
							"__text": "Constante, variabile si expresii. Procedurii standard pentru citire/scriere"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni"
						},
						{
							"_nr": "8",
							"__text": "Tipuri definte in program. Tipuri de date simple"
						},
						{
							"_nr": "9",
							"__text": "Subprograme"
						},
						{
							"_nr": "10",
							"__text": "Metode pentru structurarea datelor. Tipuri de date structurate"
						}
					]
				},
				"_id": "857",
				"_isbn": "973-638-143-9"
			},
			{
				"titlu": "Arta programarii calculatoarelor.MMIX. Un calculator RISC pentru noul mileniu",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "2005",
				"editura": "Teora",
				"numarPagini": "150",
				"pretOriginal": "250000",
				"traducere": {
					"titluOriginal": "The Art of Computer Programming, Volume 1, Fascicule 1:MMIX-A RISC Computer For The New Millennium",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Ioan Bledea"
					},
					"_an": "2005",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Arta_programarii_calculatoarelor_MMIX_Un_calculator_RISC_pentru_noul_mileniu_Knuth.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "1.3",
							"__text": "MMIX"
						},
						{
							"_nr": "1.3.1",
							"__text": "Descrierea calculatorului MMIX"
						},
						{
							"_nr": "1.3.2",
							"__text": "Limbajul de asamblare MMIX"
						},
						{
							"_nr": "1.3.3",
							"__text": "Aplicatii ale permutarilor"
						},
						{
							"_nr": "1.4",
							"__text": "Cateva tehnici fundamentale de programare"
						},
						{
							"_nr": "1.4.1",
							"__text": "Subrutine"
						},
						{
							"_nr": "1.4.2",
							"__text": "Corutine"
						},
						{
							"_nr": "1.4.3",
							"__text": "Rutine interpretative"
						}
					]
				},
				"_id": "858",
				"_isbn": "1-59496-099-2"
			},
			{
				"titlu": "Arta programarii calculatoarelor. Generarea tuturor tuplurilor si permutarilor",
				"autori": {
					"autor": {
						"_nume": "Donald E. Knuth"
					}
				},
				"anAparitie": "2005",
				"editura": "Teora",
				"numarPagini": "150",
				"pretOriginal": "250000",
				"traducere": {
					"titluOriginal": "The Art of Computer Programming, Volume 4, Fascicule 2: Generating All Tuples and Permutations",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Cora Radulian"
					},
					"_an": "2005",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Arta_programarii_calculatoarelor_Generararea_tuturor_tuplelor_si_permutarilot_Knuth.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "7",
							"__text": "Analiza combintoriala"
						},
						{
							"_nr": "7.2",
							"__text": "Generarea tuturor posibilitatilor"
						},
						{
							"_nr": "7.2.1",
							"__text": "Generarea modelelor combinatoriale elementare"
						},
						{
							"_nr": "7.2.1.1",
							"__text": "Generarea tuturor n-tuplurilor"
						},
						{
							"_nr": "7.2.1.2",
							"__text": "Generarea tuturor permutarilor"
						}
					]
				},
				"_id": "859",
				"_isbn": "1-59496-098-4"
			},
			{
				"titlu": "Data mining. Metode si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Denis Enachescu"
					}
				},
				"anAparitie": "2009",
				"editura": "Academiei Romane",
				"numarPagini": "278",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Dan-Florin Dumitrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Luminita Stafi",
						"Laurentiu Vasile"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Data_mining_Metode_si_aplicatii_Enachescu.jpg"
					},
					"__text": "Nicoleta Zorzon"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode exploratorii multidimensionale"
						},
						{
							"_nr": "2",
							"__text": "Metode de clasificare"
						},
						{
							"_nr": "3",
							"__text": "Metode explicative uzuale"
						},
						{
							"_nr": "4",
							"__text": "Metode liniare"
						}
					]
				},
				"_id": "860",
				"_isbn": "978-973-27-1798-1"
			},
			{
				"titlu": "Windows 95 pe scurt",
				"autori": {
					"autor": {
						"_nume": "Kat Yarborough Nelson"
					}
				},
				"anAparitie": "1995",
				"editura": "All Education",
				"numarPagini": "132",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "The Little Windows 95 Book",
					"editura": "Peachpit Press",
					"traducatori": {
						"traducator": "Mario Felan"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Viorica Fatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Roxana Cornelia Poncea"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_95_pe_scurt_Nelson.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Un tur cu insotitor"
						},
						{
							"_nr": "2",
							"__text": "Pornirea rapida a sistemului Windows 95"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu fisiere"
						},
						{
							"_nr": "4",
							"__text": "Explorarea fisierelor"
						},
						{
							"_nr": "5",
							"__text": "Personalizarea sistemului Windows"
						},
						{
							"_nr": "6",
							"__text": "Lucrul cu programe"
						},
						{
							"_nr": "7",
							"__text": "Tiparirea"
						},
						{
							"_nr": "8",
							"__text": "Nu sunteti de vina voi; Este..."
						}
					]
				},
				"_id": "861",
				"_isbn": "973-9229-20-4"
			},
			{
				"titlu": "Microsoft Windows 3.1 : aplicatii, tehnici, practica",
				"autori": {
					"autor": [
						{
							"_nume": "Marius Iurian"
						},
						{
							"_nume": "Simona Iurian"
						},
						{
							"_nume": "Cristian Mihoc"
						},
						{
							"_nume": "Dragos Pop"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Promedia",
				"numarPagini": "184",
				"pretOriginal": "1295",
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Windows_3_1_aplicatii_tehnici_practica_Iurian.jpg"
					},
					"__text": "Cristina Gatina"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea Windows"
						},
						{
							"_nr": "2",
							"__text": "Notiunile fundamentale ale sistemului Windows"
						},
						{
							"_nr": "3",
							"__text": "Gestiunea programelor si a grupurilor de programe. Program Manager"
						},
						{
							"_nr": "4",
							"__text": "Gestionarul de fisiere Fele Manager"
						},
						{
							"_nr": "5",
							"__text": "Configurarea sistemului. Aplicatia Control Panel"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea imprimantelor si fonturilor"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii"
						},
						{
							"_nr": "8",
							"__text": "Editorul de texte Write"
						},
						{
							"_nr": "9",
							"__text": "Aplicatia Paintbrush"
						},
						{
							"_nr": "10",
							"__text": "Aplicatia Terminal si alte accesorii"
						},
						{
							"_nr": "11",
							"__text": "Integrarea aplicatiilor Windows"
						}
					]
				},
				"_id": "862",
				"_isbn": "973-96114-0-0"
			},
			{
				"titlu": "Word 6.0",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Aldica"
						},
						{
							"_nume": "Lucian Vasiu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Tehnica",
				"numarPagini": "188",
				"pretOriginal": "6000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Word_6_0_Aldica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea si rularea"
						},
						{
							"_nr": "2",
							"__text": "Word 6.0. Prezentare generala"
						},
						{
							"_nr": "3",
							"__text": "Adaptarea si optimizarea Word"
						},
						{
							"_nr": "4",
							"__text": "Introducerea si editarea unui text"
						},
						{
							"_nr": "5",
							"__text": "Formatarea unui text"
						},
						{
							"_nr": "6",
							"__text": "Formatarea paragrafelor"
						},
						{
							"_nr": "7",
							"__text": "Formatarea si sortarea listelor"
						},
						{
							"_nr": "8",
							"__text": "Formatarea automata si stiluri de paragrafe"
						},
						{
							"_nr": "9",
							"__text": "Stabilirea caracteristicilor paginii de lucru"
						},
						{
							"_nr": "10",
							"__text": "Impartirea paginilor in coloane"
						},
						{
							"_nr": "11",
							"__text": "Lucrul cu tabele"
						},
						{
							"_nr": "12",
							"__text": "Elemente grafice, ecuatii, diagrame"
						},
						{
							"_nr": "13",
							"__text": "Gestiunea fisierelor"
						},
						{
							"_nr": "14",
							"__text": "Lucrul cu documente mari"
						},
						{
							"_nr": "15",
							"__text": "Posta electronica"
						}
					]
				},
				"_id": "863",
				"_isbn": "973-31-0696-8"
			},
			{
				"titlu": "Initiere in utilizarea calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Matei Sorin"
						},
						{
							"_nume": "Florian Gabriel"
						},
						{
							"_nume": "Popescu Eugen"
						},
						{
							"_nume": "Matei M. Daniel"
						},
						{
							"_nume": "Florian Daniela Aurelia"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Colegial",
				"numarPagini": "202",
				"pretOriginal": "50000",
				"colectia": {
					"_numarul": "1",
					"_nume": "Initiere in calculatoare"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_utilizarea_calculatoarelor_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "2",
							"__text": "Aplicatia Norton Commander"
						},
						{
							"_nr": "3",
							"__text": "Windos 9X (95,98)"
						},
						{
							"_nr": "4",
							"__text": "Virusi - Antivirusi"
						},
						{
							"_nr": "5",
							"__text": "Arhivare - Dezarhivare"
						},
						{
							"_nr": "6",
							"__text": "Retele de calculatoare si Internet"
						}
					]
				},
				"_id": "864",
				"_isbn": "973-99203-7-3"
			},
			{
				"titlu": "Calculatorul, prietenul nostru: primii pasi in informatica",
				"autori": {
					"autor": {
						"_nume": "Vasile Poenaru"
					}
				},
				"anAparitie": "2005",
				"editura": "Coresi",
				"numarPagini": "176",
				"pretOriginal": "110000",
				"coperta": {
					"imagine": {
						"_path": "Calculatorul_prietenul_nostru_primii_pasi_in_informatica_Poenaru.jpg"
					},
					"__text": "Alexandra Poenaru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cateva precizari"
						},
						{
							"_nr": "2",
							"__text": "Simbolurile Alfabetului Fonetic International folosite in cartea de fata"
						},
						{
							"_nr": "3",
							"__text": "Ti-e frica de calculator?"
						},
						{
							"_nr": "4",
							"__text": "Calculatorul - privire de asamblu"
						},
						{
							"_nr": "5",
							"__text": "Componentele software"
						},
						{
							"_nr": "6",
							"__text": "Microsoft Office"
						},
						{
							"_nr": "7",
							"__text": "Internetul si pota electronica"
						}
					]
				},
				"_id": "865",
				"_isbn": "973-608-759-X"
			},
			{
				"titlu": "Initiere in CorelDRAW",
				"autori": {
					"autor": [
						{
							"_nume": "Daniel Codres"
						},
						{
							"_nume": "Mihaela Codres"
						},
						{
							"_nume": "Mary Petrescu"
						},
						{
							"_nume": "Eugen Popescu"
						},
						{
							"_nume": "Ecaterian Boarna"
						},
						{
							"_nume": "Daniela Ciobanu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Else",
				"numarPagini": "146",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_CorelDraw_Codres.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Operatii cu documente"
						},
						{
							"_nr": "3",
							"__text": "Stabilirea atributelor e culoare"
						},
						{
							"_nr": "4",
							"__text": "Operatii de baza cu obiecte"
						},
						{
							"_nr": "5",
							"__text": "Formatarea textelor"
						},
						{
							"_nr": "6",
							"__text": "Operatii speciale cu obiecte"
						},
						{
							"_nr": "7",
							"__text": "Efecte speciale"
						},
						{
							"_nr": "8",
							"__text": "Tiparirea unui document la imprimanta"
						},
						{
							"_nr": "9",
							"__text": "Prezentarea meniurilor"
						},
						{
							"_nr": "10",
							"__text": "Facilitati CorelDRAW 8"
						},
						{
							"_nr": "11",
							"__text": "Ferificati-va cunostintelor"
						}
					]
				},
				"_id": "866",
				"_isbn": "973-86180-0-2"
			},
			{
				"titlu": "Compediu de programare a mini/micro calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "Ion Odagescu"
						},
						{
							"_nume": "Pavel Nastase"
						},
						{
							"_nume": "Cristina Copos"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Enciclopedica",
				"numarPagini": "416",
				"pretOriginal": "800",
				"redactori": {
					"redactor": [
						"Iulian Mircea",
						"Constantin Ramanciuc"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Compediu_de_programare_a_mini_macro_calculatoarelor_Dodescu.jpg"
					},
					"__text": "Ghoerghe G. Marinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea generala a mini/microcalculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi si descrierea lor"
						},
						{
							"_nr": "4",
							"__text": "Programarea in limbajul MACRO-11"
						},
						{
							"_nr": "5",
							"__text": "Programarea in limbaj BASIC"
						},
						{
							"_nr": "6",
							"__text": "Programarea in limbaj FORTRAN 77"
						},
						{
							"_nr": "7",
							"__text": "Programarea in limbaj COBOL"
						},
						{
							"_nr": "8",
							"__text": "Programarea in limbaj dBASE"
						},
						{
							"_nr": "9",
							"__text": "Programarea in limbaj C"
						},
						{
							"_nr": "10",
							"__text": "Programarea in limbaj PROLOG"
						},
						{
							"_nr": "11",
							"__text": "Sistemul de gestiune a bazelor de date ORACLE"
						},
						{
							"_nr": "12",
							"__text": "Metode de proiectare a programelor"
						},
						{
							"_nr": "13",
							"__text": "Codificarea programelor"
						},
						{
							"_nr": "14",
							"__text": "Probleme propuse spre rezolvare"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Produse-program existente in Biblioeca nationala de programe (BNP)"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Lista principalelor comenzi dBASE"
						}
					]
				},
				"_id": "867",
				"_isbn": "973-45-1003-7"
			},
			{
				"titlu": "Windows 95 - Ghid de intalare rapida",
				"autori": {
					"autor": {
						"_nume": "Andrew Reese"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "110",
				"pretOriginal": "5000",
				"colectia": {
					"_numarul": "99",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Windows 95 Easy Installation Guide",
					"editura": "Prima Publishing",
					"traducatori": {
						"traducator": "Mihai Dan Pavelescu"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_95_Ghid_de_instalare_rapida_Reese.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Inainte de a incepe"
						},
						{
							"_nr": "2",
							"__text": "Instalarea pas-cu-pas"
						},
						{
							"_nr": "3",
							"__text": "Depanarea eventualelor probleme"
						}
					]
				},
				"_id": "868",
				"_isbn": "973-601-286-7"
			},
			{
				"titlu": "Word pentru Windows 95: usor si rapid",
				"autori": {
					"autor": {
						"_nume": "Christian Crumlish"
					}
				},
				"anAparitie": "1996",
				"editura": "All Education",
				"numarPagini": "116",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Word for Windows 95 Quick and Easy",
					"editura": "Sybex Inc.",
					"traducatori": {
						"traducator": "Andreea Lutic"
					},
					"_an": "1996",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Viorica Fatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Luminita Para"
				},
				"coperta": {
					"imagine": {
						"_path": "Word_pentru_Windows_95_Usor_si_Rapid_Crumlish.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Ce fac mai intai?"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Sa dam lucrurilor o forma"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Revizuiti-va munca"
						}
					]
				},
				"_id": "869",
				"_isbn": "973-9229-08-5"
			},
			{
				"titlu": "PC nu este o masina de scris",
				"autori": {
					"autor": {
						"_nume": "Robin Williams"
					}
				},
				"anAparitie": "1996",
				"editura": "All Education",
				"numarPagini": "80",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "The PC is not a typewritter",
					"editura": "Peachpit Press",
					"traducatori": {
						"traducator": [
							"Andrian Pascu",
							"Corina Pascu"
						]
					},
					"_an": "1992",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Viorica Fatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Roxana Cornelia Poncea"
				},
				"coperta": {
					"imagine": {
						"_path": "Pc_nu _este_o_masina_de_scris_Williams.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Spatierea propozitiilor"
						},
						{
							"_nr": "2",
							"__text": "Ghilimelele"
						},
						{
							"_nr": "3",
							"__text": "Apostroful"
						},
						{
							"_nr": "4",
							"__text": "Liniile de dialog"
						},
						{
							"_nr": "5",
							"__text": "Caractere speciale"
						},
						{
							"_nr": "6",
							"__text": "Accentele"
						},
						{
							"_nr": "7",
							"__text": "Sublinierea"
						},
						{
							"_nr": "8",
							"__text": "Majusculele"
						},
						{
							"_nr": "9",
							"__text": "Tehnica kerning"
						},
						{
							"_nr": "10",
							"__text": "Tabulari si indentari"
						},
						{
							"_nr": "11",
							"__text": "Efecte de tip Vaduve si Orfani"
						},
						{
							"_nr": "12",
							"__text": "Cratima si separarea liniilor"
						},
						{
							"_nr": "13",
							"__text": "Spatierea liniilor"
						},
						{
							"_nr": "14",
							"__text": "Alinierea textului"
						},
						{
							"_nr": "15",
							"__text": "Aranjarea semnelor de punctuatie"
						},
						{
							"_nr": "16",
							"__text": "Fontruei serif si sens serif"
						},
						{
							"_nr": "17",
							"__text": "Combinarea fonturilor"
						},
						{
							"_nr": "19",
							"__text": "Diverse"
						},
						{
							"_nr": "19",
							"__text": "Test"
						}
					]
				},
				"_id": "870",
				"_isbn": "973-9229-21-2"
			},
			{
				"titlu": "Ghidul dumneavoastra pentru Windows 95",
				"autori": {
					"autor": [
						{
							"_nume": "Alan Simspson"
						},
						{
							"_nume": "Elizabeth Olson"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "All Education",
				"numarPagini": "264",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Alan Simpson's Easy Guide to Windows 95",
					"editura": "Sybex Inc.",
					"traducatori": {
						"traducator": "Silviu Timus"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Marius Somodi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Virgiliu Radulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_dumneavoastra_pentru_Windows_95_Simpson.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Windows 95"
						},
						{
							"_nr": "2",
							"__text": "Sa trecem la treaba"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza in Windows"
						},
						{
							"_nr": "4",
							"__text": "Lucrul cu fisiere, directoare si obiecte"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea programelor"
						},
						{
							"_nr": "6",
							"__text": "Scrierea si desenarea"
						},
						{
							"_nr": "7",
							"__text": "Partajarea informatiilor intre programe"
						},
						{
							"_nr": "8",
							"__text": "O multime de accesorii"
						},
						{
							"_nr": "9",
							"__text": "Personalizarea sistemului Windows"
						},
						{
							"_nr": "10",
							"__text": "Intretinerea calculatorului"
						},
						{
							"_nr": "11",
							"__text": "Internet, Compuserve si celelalte"
						},
						{
							"_nr": "12",
							"__text": "Partajarea resurselor intr-o retea locala"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea programului Microsoft Exchange"
						},
						{
							"_nr": "14",
							"__text": "La drum cu Briefcase"
						},
						{
							"_nr": "Anexa",
							"__text": "Instalarea sistemului Windows 95"
						}
					]
				},
				"_id": "871",
				"_isbn": "973-9229-05-0"
			},
			{
				"titlu": "1001 sugesti pentru programatori Visual Basic",
				"autori": {
					"autor": [
						{
							"_nume": "Kris Jamsa"
						},
						{
							"_nume": "Lars Klander"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All Educational",
				"numarPagini": "812",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "1001 Visual Basic Programmer's Tips",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Radu Biris"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Andreea Rosemarie Lutic"
				},
				"coperta": {
					"imagine": {
						"_path": "1001_Sugestii_pentru_programatorii_Visual_Basic_Jamsa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Visual Basic"
						},
						{
							"_nr": "2",
							"__text": "Variabile"
						},
						{
							"_nr": "3",
							"__text": "Constructii iterative si conditionale"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea sirurilor in Visual Basic"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea functiilor si subrutinelor"
						},
						{
							"_nr": "6",
							"__text": "Introducerea operatiilor de intrare/iesire"
						},
						{
							"_nr": "7",
							"__text": "Efectuarea operatiilor matematice"
						},
						{
							"_nr": "8",
							"__text": "Lucrul cu vectori si structuri"
						},
						{
							"_nr": "9",
							"__text": "Lucrul cu fisiere, directoare si unitati de disc"
						},
						{
							"_nr": "10",
							"__text": "Lucrul cu date calendaristice"
						},
						{
							"_nr": "11",
							"__text": "Forme, meniuri si bare de intrumente"
						},
						{
							"_nr": "12",
							"__text": "Introducerea conceptelor avansate din Visual Basic"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea claselor, obiectelor si controalelor"
						},
						{
							"_nr": "14",
							"__text": "Gestionarea bazelor de date"
						},
						{
							"_nr": "15",
							"__text": "Comunicare cu Visual Basic"
						},
						{
							"_nr": "16",
							"__text": "Utilizarea Visual Basic pentru distribuirea obiectelor ActiveX"
						},
						{
							"_nr": "17",
							"__text": "Programare avansata utilizand controale specifice si Win API"
						}
					]
				},
				"_id": "872",
				"_isbn": "973-9392-48-2"
			},
			{
				"titlu": "Intranet - Mica enciclopedie",
				"autori": {
					"autor": [
						{
							"_nume": "Lynn M. Bremner"
						},
						{
							"_nume": "Anthony F. Iasi"
						},
						{
							"_nume": "Al Servati"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All Educational",
				"numarPagini": "466",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "Intranet Bible",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Andreea Rosemarie Lutic"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Intranet_Mica_enciclopedie_Bremner.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sa intelegem o retea de calculatoare"
						},
						{
							"_nr": "2",
							"__text": "Intraneturile: Inceputul unei revolutii"
						},
						{
							"_nr": "3",
							"__text": "Intraneturile in actiune"
						},
						{
							"_nr": "4",
							"__text": "Intraneturile pentru clientii dvs."
						},
						{
							"_nr": "5",
							"__text": "Componentele unui intranet"
						},
						{
							"_nr": "6",
							"__text": "Planificarea si gestionarea unui intranet"
						},
						{
							"_nr": "7",
							"__text": "Ghidul incepatorului pentru HTML"
						},
						{
							"_nr": "8",
							"__text": "Tebele HTML si formatare speciala"
						},
						{
							"_nr": "9",
							"__text": "Imagini si HTML"
						},
						{
							"_nr": "10",
							"__text": "Crearea si gestionarea continutului"
						},
						{
							"_nr": "11",
							"__text": "Formulare si programarea CGI"
						},
						{
							"_nr": "12",
							"__text": "Probleme pe termen scurt si lung privind dezvoltarea unui intranet"
						},
						{
							"_nr": "13",
							"__text": "Instrumente intranet gata pentru utilizare"
						},
						{
							"_nr": "14",
							"__text": "Publcarea in Web folosind FrontPage 97"
						},
						{
							"_nr": "15",
							"__text": "Profitul de investitii pentru intraneturi"
						},
						{
							"_nr": "16",
							"__text": "Intraneturi comparativ cu Groupware"
						},
						{
							"_nr": "17",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "18",
							"__text": "Examinarea TCP/IP"
						},
						{
							"_nr": "19",
							"__text": "Probleme privind securitatea intraneturilor"
						},
						{
							"_nr": "20",
							"__text": "Java si intraneturile"
						},
						{
							"_nr": "21",
							"__text": "Un ghid al principalelor referinte despre intraneturi"
						}
					]
				},
				"_id": "873",
				"_isbn": "973-9392-12-1"
			},
			{
				"titlu": "Internet pentru incepatori",
				"autori": {
					"autor": [
						{
							"_nume": "Philippa Wingate"
						},
						{
							"_nume": "Andy Griffin"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Aquila 93",
				"numarPagini": "48",
				"pretOriginal": "0",
				"traducere": {
					"titluOriginal": "The Internet for Beginners",
					"editura": "Osborne Publishing",
					"traducatori": {
						"traducator": "Titus Pop"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "Anglia"
				},
				"coperta": {
					"imagine": {
						"_path": "Internet_pentru_incepatori_Wingate.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre aceasta carte"
						},
						{
							"_nr": "2",
							"__text": "Ce este internetul ?"
						},
						{
							"_nr": "3",
							"__text": "Ce gasesti pe net ?"
						},
						{
							"_nr": "4",
							"__text": "Cum functioneaza netul ?"
						},
						{
							"_nr": "5",
							"__text": "Istoria netului"
						},
						{
							"_nr": "6",
							"__text": "Echipamentul esential"
						},
						{
							"_nr": "7",
							"__text": "Obtinerea accesului"
						},
						{
							"_nr": "8",
							"__text": "Conectarea si deconectarea"
						},
						{
							"_nr": "9",
							"__text": "Word Wide Web"
						},
						{
							"_nr": "10",
							"__text": "Paginile web"
						},
						{
							"_nr": "11",
							"__text": "Explorarea webului"
						},
						{
							"_nr": "12",
							"__text": "Expert surfing"
						},
						{
							"_nr": "13",
							"__text": "Grupuri de stiri"
						},
						{
							"_nr": "14",
							"__text": "Corespondenta cu grupurile de stiri"
						},
						{
							"_nr": "15",
							"__text": "Netiquette"
						},
						{
							"_nr": "16",
							"__text": "E-mail"
						},
						{
							"_nr": "17",
							"__text": "Trimiterea e-mail"
						},
						{
							"_nr": "18",
							"__text": "Receptionarea"
						},
						{
							"_nr": "19",
							"__text": "Liste de corespondenta"
						},
						{
							"_nr": "20",
							"__text": "Fisiere prin net"
						},
						{
							"_nr": "21",
							"__text": "Cyberchat-ul"
						},
						{
							"_nr": "22",
							"__text": "Jocuri on-line"
						},
						{
							"_nr": "23",
							"__text": "Probleme si rezolvari"
						},
						{
							"_nr": "24",
							"__text": "Masuri de siguranta"
						},
						{
							"_nr": "25",
							"__text": "Funrnizori de servicii"
						},
						{
							"_nr": "26",
							"__text": "Site-uri si resurse"
						},
						{
							"_nr": "27",
							"__text": "Dictionar internet"
						},
						{
							"_nr": "28",
							"__text": "Slang de net"
						},
						{
							"_nr": "29",
							"__text": "Index"
						},
						{
							"_nr": "30",
							"__text": "Multimiri"
						}
					]
				},
				"_id": "874",
				"_isbn": "973-9319-49-1"
			},
			{
				"titlu": "Limbaje de programare - Cobol [XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Casimir Macarie"
						},
						{
							"_nume": "Anastase Pitis"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Didactica si pedagogica",
				"tiraj": "1600",
				"numarPagini": "222",
				"pretOriginal": "11.70",
				"redactori": {
					"redactor": "Lucia Barna"
				},
				"tehnoredactori": {
					"tehnoredactor": "Viorica Condopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_programare_COBOL_XI_Macarie.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Prezentarea limbajului COBOL"
						},
						{
							"_nr": "II",
							"__text": "Structura programelor COBOL"
						},
						{
							"_nr": "III",
							"__text": "Elemente de descriere a datelor"
						},
						{
							"_nr": "IV",
							"__text": "Diviziunea de prelucrare"
						},
						{
							"_nr": "V",
							"__text": "Elemente complmentare de descriere a datelor"
						},
						{
							"_nr": "VI",
							"__text": "Prelucrarea fisierelor"
						},
						{
							"_nr": "VII",
							"__text": "Crearea fisierelor de incercare"
						},
						{
							"_nr": "VIII",
							"__text": "Invetarierea fisierelor si volumelor"
						},
						{
							"_nr": "IX",
							"__text": "Sortarea fisierelor"
						},
						{
							"_nr": "X",
							"__text": "Utilizarea subprogramelor"
						},
						{
							"_nr": "XI",
							"__text": "Segmentarea programelor COBOL"
						},
						{
							"_nr": "XII",
							"__text": "Utilizarea bibliotecilor"
						}
					]
				},
				"_id": "875",
				"_isbn": ""
			},
			{
				"titlu": "Microsoft Word 97 pap cu pas",
				"autori": {
					"autor": {
						"_nume": "[Microsoft Press]"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "328",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "221",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Microsof Word 97 Step by Step",
					"editura": "Microsoft Press",
					"traducatori": {
						"traducator": "Calin Suciu"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Word_97_pas_cu_pas_Microsot_Press.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "Tehnici elementare"
						},
						{
							"_nr": "Partea a 2-a",
							"__text": "Simplificarea operatiilor uzuale"
						},
						{
							"_nr": "Partea a 3-a",
							"__text": "Aranjarea textului si a imaginilor grafice"
						}
					]
				},
				"_id": "876",
				"_isbn": "973-601-380-4"
			},
			{
				"titlu": "Windows 95 pentru toti",
				"autori": {
					"autor": {
						"_nume": "Andy Rathbone"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "392",
				"pretOriginal": "19000",
				"colectia": {
					"_numarul": "59",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Windows 95 For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Nicolae Ionescu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cora Radulian"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Liliana Potop",
						"Bogdan Potop"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_95_pentru_toti_Rathbone.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Informatii generale despre Windows 95 (Incepeti de aici)"
						},
						{
							"_nr": "II",
							"__text": "Punerea la treaba a programului Windows 95"
						},
						{
							"_nr": "III",
							"__text": "Utilizarea aplicatiilor din Windows 95 (si rularea sistemului de operare DOS, daca vi se nazare)"
						},
						{
							"_nr": "IV",
							"__text": "Am venit, am vazut, am invins: scurte indicatii pentru tranzitia la Windows 95"
						},
						{
							"_nr": "V",
							"__text": "Ajutor"
						},
						{
							"_nr": "VI",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "877",
				"_isbn": "973-601-240-9"
			},
			{
				"titlu": "Windows 3.1: lectii pentru toti",
				"autori": {
					"autor": {
						"_nume": "Andy Rathbone"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "290",
				"pretOriginal": "25000",
				"pretCumparare": "5000",
				"colectia": {
					"_numarul": "171",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Dummies 101: Windows 3.1",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Daniel Aizic"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_3_1_Lectii_pentru_toti_Rathbone.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Notiuni elementare"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Program Manager si File Manager"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Utilizarea mediului Windows"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Programe gratuite"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Solutionarea problemelor"
						}
					]
				},
				"_id": "878",
				"_isbn": "973-601-240-9"
			},
			{
				"titlu": "WordPerfect 6.1 sub windows pentru toti",
				"autori": {
					"autor": [
						{
							"_nume": "Margaret Levine Young"
						},
						{
							"_nume": "David C. Kay"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "448",
				"pretOriginal": "19600",
				"colectia": {
					"_numarul": "112",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "WordPerfect 6.1 For Windows For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Dana Irina Paradovschi"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Dragos Chitu"
				},
				"coperta": {
					"imagine": {
						"_path": "WordPerfect_6_1_sub_Windows_pentru_toti_Young.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Introducere in WordPerfect sub Windows"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Infrumusetarea textului"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Ce puteti face cu documentele"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Ajuta-ma, Doamne!"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "879",
				"_isbn": "973-601-301-4"
			},
			{
				"titlu": "MS-DOS pentru toti",
				"autori": {
					"autor": {
						"_nume": "Dan Gookin"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "408",
				"pretOriginal": "11900",
				"colectia": {
					"_numarul": "58",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "DOS For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Mihai Manastireanu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Alin Dragomir"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_pentru_toti_Gookin.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Strictul necesar"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Ghidul neoficial intr-ale echipamentelor"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Ghidul soft al neofitului"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Ajutor, cine ma salveza de aici?\""
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Partea decaloagelor"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Ghid DOS pentru oameni normali"
						}
					]
				},
				"_id": "880",
				"_isbn": "973-601-239-5"
			},
			{
				"titlu": "Programarea bazelor de date in Visual Basic 6 pentru ... amici",
				"autori": {
					"autor": {
						"_nume": "Richard Mansfield"
					}
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "376",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Visual Basic 6 Database Programming for Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Walter Radu Fotescu"
					},
					"_an": "1999",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Nita"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_bazelor_de_date_in_Visual_Basic_6_pentru_amici_Mansfield.jpg"
					},
					"__text": "Andreea Staicu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Fundamentele bazelor de date"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Realizarea unei conexiuni"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Contactarea utilizatorului"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Construirea unei baze de date"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Conexiunea Internet"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Programarea efectiva"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Lucrand cu interogari"
						},
						{
							"_nr": "Partea a VIII-a",
							"__text": "Partea celor zece"
						}
					]
				},
				"_id": "881",
				"_isbn": "973-31-1586-X"
			},
			{
				"titlu": "Word 6 sub Windows. Pas cu pas",
				"autori": {
					"autor": {
						"_nume": "Terry Mah"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "160",
				"pretOriginal": "7500",
				"colectia": {
					"_numarul": "105",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Microsft Word for Windows version 6.0 Step by Step",
					"editura": "Norby Publishing Limited",
					"traducatori": {
						"traducator": "Vladimier Visan"
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Word_6_sub_Windows_Pas_cu_pas_Mah.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bazele programului Word"
						},
						{
							"_nr": "2",
							"__text": "Principalele tehbici de prelucrare a textului"
						},
						{
							"_nr": "3",
							"__text": "Scrisori, etichete si plicuri"
						},
						{
							"_nr": "4",
							"__text": "Rapoarte si documente de dimensiuni mari"
						},
						{
							"_nr": "5",
							"__text": "Curriculum vite, table si scrisori de prezentare"
						},
						{
							"_nr": "6",
							"__text": "Formulare de coordonare a lucrului pentru cursanti"
						}
					]
				},
				"_id": "882",
				"_isbn": "973-601-248-4"
			},
			{
				"titlu": "dBase",
				"autori": {
					"autor": {
						"_nume": "Miorita Ilie"
					}
				},
				"anAparitie": "1993",
				"editura": "Teora",
				"numarPagini": "216",
				"pretOriginal": "2990",
				"coperta": {
					"imagine": {
						"_path": "dBase_IV_Ilie.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Ce este dBASE IV"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Cunostinte de baza despre sistemul de meniuri din dBASE IV"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Proiectarea unei structuri de fisier de date"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Organizarea fisierelor bazei de date"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Afisarea, adaugarea si modificarea datelor"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Organizarea datelor"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Fisier de tup query. Crearea unui view"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Functia de filtrare a datelor folosind fisiere query"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Folosirea query-urilor de tip update (cerere de tip actualizare)"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Proiectarea si folosirea form-urilor (formularelor)"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Crearea rapoartelor"
						},
						{
							"_nr": "Lectia 12",
							"__text": "Folisrea facilitatii mailmerge (interclasare)"
						},
						{
							"_nr": "Lectia 13",
							"__text": "Crearea etichetelor"
						},
						{
							"_nr": "Lectia 14",
							"__text": "Listarea"
						},
						{
							"_nr": "Lectia 15",
							"__text": "Utilizarea meniului \"Tools\""
						},
						{
							"_nr": "Lectia 16",
							"__text": "Utilizarea editorului de programe"
						}
					]
				},
				"_id": "883",
				"_isbn": "973-601-114-3"
			},
			{
				"titlu": "Teoria decizei. Studii de caz",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Dobre"
						},
						{
							"_nume": "Adrian Badescu"
						},
						{
							"_nume": "Corina Irimiea"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Scipta",
				"numarPagini": "184",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Rodica Daniela Isaila"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mariana Radu"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_deciziei_Studii_de_caz_Dobre.jpg"
					},
					"__text": "Mariana Radu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Procesul decizional economic"
						},
						{
							"_nr": "2",
							"__text": "Probleme de decizie in conditie de certitudine"
						},
						{
							"_nr": "3",
							"__text": "Probleme de decizii in conditii de incertitudine si de risc"
						},
						{
							"_nr": "4",
							"__text": "Probleme decizionale din domeniul financiar"
						},
						{
							"_nr": "5",
							"__text": "Decizii de grup"
						},
						{
							"_nr": "6",
							"__text": "Probleme diverse"
						}
					]
				},
				"_id": "884",
				"_isbn": "973-9161-97-9"
			},
			{
				"titlu": "Despre Macintosh si sistemul de operare MAX OS X: raspunsuri la intrebarile utilizatorilor",
				"autori": {
					"autor": [
						{
							"_nume": "Sorin Paliga"
						},
						{
							"_nume": "Sergiu Partenie"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Meteor Press",
				"numarPagini": "192",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Emanuela Soimaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Rodica Boaca"
				},
				"coperta": {
					"imagine": {
						"_path": "Despre_Macintosh_si_sistemul_de_operare_MAC_OS_X_Raspunsuri_la_intrebarile_utilizatorilor_Paliga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Primul pas: ce Mac sa-mi cumpar"
						},
						{
							"_nr": "2",
							"__text": "Instalarea"
						},
						{
							"_nr": "3",
							"__text": "Sunt prima oara in sistem. Ce fac mai departe?"
						},
						{
							"_nr": "4",
							"__text": "Alte aplicatii"
						},
						{
							"_nr": "5",
							"__text": "Mac-ul meu are probleme. Ce fac?"
						},
						{
							"_nr": "6",
							"__text": "MAC OS Special"
						}
					]
				},
				"_id": "885",
				"_isbn": "978-973-728-292-7"
			},
			{
				"titlu": "Elemente de programare liniara",
				"autori": {
					"autor": {
						"_nume": "Ernest Dani"
					}
				},
				"anAparitie": "1971",
				"editura": "Didactica si Pedagogica",
				"numarPagini": "136",
				"pretOriginal": "2.90",
				"pretCumparare": "5000",
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_programare_liniara_Dani.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Calcul matricial"
						},
						{
							"_nr": "II",
							"__text": "Metoda simplex"
						},
						{
							"_nr": "III",
							"__text": "Metode distributiva"
						},
						{
							"_nr": "IV",
							"__text": "Aplicatii in economie"
						}
					]
				},
				"_id": "886",
				"_isbn": ""
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Tamas"
						},
						{
							"_nume": "Eden Ali"
						},
						{
							"_nume": "Pavel Nastase"
						},
						{
							"_nume": "Bogdan Ionescu"
						},
						{
							"_nume": "Florentina Berbec"
						},
						{
							"_nume": "Luana Cosacescu"
						},
						{
							"_nume": "Iuliana Ionescu"
						},
						{
							"_nume": "Mirela Oancea"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Sofitech",
				"numarPagini": "320",
				"pretOriginal": "70000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Tamas.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Comunicarea om-masina"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura microcalculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Echipamente periferice pentru microcalculatoare"
						},
						{
							"_nr": "4",
							"__text": "Medii de stocare in masa a informatiilor"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de programe"
						},
						{
							"_nr": "6",
							"__text": "Retele locale de calculatoare"
						},
						{
							"_nr": "7",
							"__text": "Internet"
						}
					]
				},
				"_id": "887",
				"_isbn": "973-98230-4-1"
			},
			{
				"titlu": "Metode noi in proiectare. Elemente de grafica tridimensionala",
				"autori": {
					"autor": {
						"_nume": "Dorian Dogaru"
					}
				},
				"anAparitie": "1988",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "354",
				"pretOriginal": "43",
				"redactori": {
					"redactor": "Ion Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_noi_in_proiectare_Elemete_de_grafica_3D_Dogaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Introducere in grafica 3D"
						},
						{
							"_nr": "I.1",
							"__text": "Concepte si notiuni generale"
						},
						{
							"_nr": "I.2",
							"__text": "Transformari spatiale si plane"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Reprezentari simple"
						},
						{
							"_nr": "II.1",
							"__text": "Construirea imaginii"
						},
						{
							"_nr": "II.2",
							"__text": "Modelarea corpurilor 3D"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Prelucrarea reprezentarilor simple"
						},
						{
							"_nr": "III.1",
							"__text": "Linii si suprafete mascate"
						},
						{
							"_nr": "III.2",
							"__text": "Iluminare, texturi, sectiuni"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Programe ilustrative"
						},
						{
							"_nr": "IV.1",
							"__text": "Programe de modelare si reprezentare"
						},
						{
							"_nr": "IV.2",
							"__text": "Pachet de rutine grafice BASIC pentru ZX Spectrum"
						}
					]
				},
				"_id": "888",
				"_isbn": ""
			},
			{
				"titlu": "Bazele informaticii [IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Serban Radu Cancer"
						},
						{
							"_nume": "Horia Adrian Ionescu"
						},
						{
							"_nume": "Ana Elena Costin"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Didactica si pedagogica",
				"numarPagini": "112",
				"pretOriginal": "6500",
				"redactori": {
					"redactor": "Marius Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Opriseanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Cancer.jpg"
					},
					"__text": "Elena Dragulelei-Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Bazele aritmetice ale operatiilor desfasurate in calculatoarele electronice numerice"
						},
						{
							"_nr": "3",
							"__text": "Coduri. Operatii aritmetice"
						},
						{
							"_nr": "4",
							"__text": "Bazele logice ale structurii calculatoarelor electronice numerice"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi. Metode de reprezentare"
						},
						{
							"_nr": "6",
							"__text": "Reprezentarea algoritmilor in limbaje de programare. Limbajul Pascal"
						}
					]
				},
				"_id": "889",
				"_isbn": "973-30-5238-8"
			},
			{
				"titlu": "Teste grila de programare in limbajul Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Pavel Florin Moraru"
						},
						{
							"_nume": "George Daniel Mateescu"
						},
						{
							"_nume": "Marius Conca"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Petrion",
				"numarPagini": "256",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": [
						"Marius Conca",
						"Razvan N. Popescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Teste_grila_de_programare_in_limbajul_Pascal_Moraru.jpg"
					},
					"__text": "John Daniel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Limbajul Pascal in opt capitole"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Teste grila de sinteza"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Bacalaureat 2000"
						}
					]
				},
				"_id": "890",
				"_isbn": "973-9470-18-1"
			},
			{
				"titlu": "Programare dinamica aplicata",
				"autori": {
					"autor": [
						{
							"_nume": "Richard E. Bellman"
						},
						{
							"_nume": "Stuart E. Dreyfus"
						}
					]
				},
				"anAparitie": "1967",
				"editura": "Tehnica",
				"tiraj": "2500+110",
				"numarPagini": "442",
				"pretOriginal": "23",
				"traducere": {
					"titluOriginal": "Applied dynamic programming",
					"editura": "Princeton Univerity Press",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1964",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Dan Lutic"
				},
				"tehnoredactori": {
					"tehnoredactor": "Grigore Leca"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_dinamica_aplicata_Bellman.jpg"
					},
					"__text": "St. Pandele"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Procese de alocatie unidimensionale"
						},
						{
							"_nr": "II",
							"__text": "Procese de repartitie multidimensionale"
						},
						{
							"_nr": "III",
							"__text": "Procese unidimensionale de nivelare si de planificare"
						},
						{
							"_nr": "IV",
							"__text": "Metode de cercetare optimala"
						},
						{
							"_nr": "V",
							"__text": "Programarea dinamica si calculul variatiilor"
						},
						{
							"_nr": "VI",
							"__text": "Traiectorii optime"
						},
						{
							"_nr": "VII",
							"__text": "Procese de productie in mai multi pasi utilizand complexe industriale"
						},
						{
							"_nr": "VIII",
							"__text": "Procese de comanda cu legatura inversa"
						},
						{
							"_nr": "IX",
							"__text": "Rezulate de calcul pentru procese de comanda cu legatura inversa"
						},
						{
							"_nr": "X",
							"__text": "Ecualti liniare si functii criteriu patratice"
						},
						{
							"_nr": "XI",
							"__text": "Procese de decizie de tip Markov"
						},
						{
							"_nr": "XII",
							"__text": "Analiza numerica"
						},
						{
							"_nr": "Anexa I",
							"__text": "Asupra unei curbe trancendente"
						},
						{
							"_nr": "Anexa II",
							"__text": "Un nou mod de tratare a teoriei dualitatii in programarea dinamica"
						},
						{
							"_nr": "Anexa III",
							"__text": "O metoda de calcul bazata pe aproxamatii succesive in spatiul strategiilor"
						},
						{
							"_nr": "Anexa IV",
							"__text": "Despre o noua transformare functionala in analiza: transformarea maximum"
						},
						{
							"_nr": "Anaxa V",
							"__text": "Calculatorul Rand Jonniac"
						}
					]
				},
				"_id": "891",
				"_isbn": ""
			},
			{
				"titlu": "Calcule numerice la mecanisme plane",
				"autori": {
					"autor": {
						"_nume": "Maros Dezideriu"
					}
				},
				"anAparitie": "1987",
				"editura": "Dacia",
				"numarPagini": "212",
				"pretOriginal": "25",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Calcule_numerice_la_mecanismele_plane_Dezideriu.jpg"
					},
					"__text": "Adriana Andrei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calcule numerice referitoare la analiza cinematica a mecanismelor cu bare articulate"
						},
						{
							"_nr": "2",
							"__text": "Calcule numerice referitoare la analiza si sinteze cinematica a mecanismelor elementare cu cuple superioare"
						},
						{
							"_nr": "3",
							"__text": "Calcule numerice referitoare la cinetostatica si dinamica mecanismelor"
						}
					]
				},
				"_id": "892",
				"_isbn": ""
			},
			{
				"titlu": "Microcontrolerul 80C32 - Manual de utilizare",
				"autori": {
					"autor": [
						{
							"_nume": "Dragos Marinescu"
						},
						{
							"_nume": "Serban Iancu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Tehnica",
				"numarPagini": "88",
				"pretOriginal": "16000",
				"pretCumparare": "32700",
				"redactori": {
					"redactor": "Mihaela Marian"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mariana Gheorghita"
				},
				"coperta": {
					"imagine": {
						"_path": "Microcontrolerul_80C32_Manual_de_utilizare_Marinescu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Descrierea hardware a microcontrolerului 80C32"
						},
						{
							"_nr": "2",
							"__text": "Descrierea software a microcontrolerului 80C32"
						},
						{
							"_nr": "3",
							"__text": "Sistem de dezvoltare minimal cu 80C32"
						},
						{
							"_nr": "4",
							"__text": "Anexa : Exemple de programe shareware in limbajul asamblor de la Industry Net"
						}
					]
				},
				"_id": "893",
				"_isbn": "973-31-1178-1"
			},
			{
				"titlu": "Informatica - Manual [XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Marcel Andrei Homorodean"
						},
						{
							"_nume": "Simona Petrescu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Niculescu",
				"numarPagini": "280",
				"pretOriginal": "150000",
				"pretCumparare": "5000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_XII_Homorodean.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "2",
							"__text": "Mediul de programare Visual FoxPro"
						},
						{
							"_nr": "3",
							"__text": "Tabele simple"
						},
						{
							"_nr": "4",
							"__text": "Programe si functii Visual FoxPro"
						},
						{
							"_nr": "5",
							"__text": "Baze de date"
						},
						{
							"_nr": "6",
							"__text": "Relatii intre tabele"
						},
						{
							"_nr": "7",
							"__text": "Interogari"
						},
						{
							"_nr": "8",
							"__text": "Vederi locale si vederi la distanta"
						},
						{
							"_nr": "9",
							"__text": "Aplicatia Form Designer"
						},
						{
							"_nr": "10",
							"__text": "Utilizarea controalelor simple"
						},
						{
							"_nr": "11",
							"__text": "Realizarea unui cadru de dezvoltarea a aplicatiilor"
						},
						{
							"_nr": "12",
							"__text": "Controale complexe pentru contruirea formularelor"
						},
						{
							"_nr": "13",
							"__text": "Rapoarte si etichete"
						},
						{
							"_nr": "14",
							"__text": "Meniuri"
						}
					]
				},
				"_id": "894",
				"_isbn": "973-568-776-3"
			},
			{
				"titlu": "Editie speciala Utilizare Access 95",
				"autori": {
					"autor": {
						"_nume": "Roger Jennings"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "1280",
				"pretOriginal": "199000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "230",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Special Edition Using Access 95",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Dana Saloveanu",
							"Cristina Botez",
							"Rodica Bitoiu",
							"Nicolae Dorel Pora"
						]
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Manuela Matache",
						"Cristian Matache"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Editie_speciala_Utilizare_Access_95_Jennings.jpg"
					},
					"__text": "Mihai Nasta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sa invatam principiile de baza ale programului Access"
						},
						{
							"_nr": "2",
							"__text": "Interogand in cautarea unor anumite informatii"
						},
						{
							"_nr": "3",
							"__text": "Crearea formularelor si rapoartelor"
						},
						{
							"_nr": "4",
							"__text": "Comenzile macro Access"
						},
						{
							"_nr": "5",
							"__text": "Integrarea programului Access in aplicatiile Office 95"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea tehnicilor avansate ale programului Access"
						},
						{
							"_nr": "7",
							"__text": "Programarea folosind Visual Basic for Applications"
						},
						{
							"_nr": "8",
							"__text": "Finalizarea unei aplicatii Access"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Glosar"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Conventii pentru numele obiectelor si variabilelor din Access"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Dictionar de date pentru tabela Personnel Actions"
						}
					]
				},
				"_id": "895",
				"_isbn": "973-601-623-4"
			},
			{
				"titlu": "Totul despre Internet: Ghidul utilizatorului and Catalog (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Ed Krol"
					}
				},
				"anAparitie": "1995",
				"editura": "Romsym Data",
				"numarPagini": "690",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "The Whole Internet Uset Guide and Catalog",
					"editura": "O'Reilly and Associates, Inc.",
					"traducatori": {
						"traducator": "Liviu Dudau"
					},
					"_an": "1992",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_Internet_ghidul_Utilizatorului_si_Catalog_Krol.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Obiectul acestei carti"
						},
						{
							"_nr": "2",
							"__text": "Ce este Internetul?"
						},
						{
							"_nr": "3",
							"__text": "Cum functioneaza Internetul?"
						},
						{
							"_nr": "4",
							"__text": "Ce este permis pe Internet?"
						},
						{
							"_nr": "5",
							"__text": "Conectarea la distanta"
						},
						{
							"_nr": "6",
							"__text": "transferul fisierelor : FTP"
						},
						{
							"_nr": "7",
							"__text": "Posta electronica"
						},
						{
							"_nr": "8",
							"__text": "Stiri in retea"
						},
						{
							"_nr": "9",
							"__text": "Gasirea programelor"
						},
						{
							"_nr": "10",
							"__text": "Gasirea unor persoane"
						},
						{
							"_nr": "11",
							"__text": "Sapand prin Internet : Gopher"
						},
						{
							"_nr": "12",
							"__text": "Cautarea in baze de date indexate : WAIS"
						},
						{
							"_nr": "13",
							"__text": "World Wide Web"
						},
						{
							"_nr": "14",
							"__text": "Alte aplicatii"
						},
						{
							"_nr": "15",
							"__text": "Tratarea problemelor"
						},
						{
							"_nr": "16",
							"__text": "Resurse pe internet"
						},
						{
							"_nr": "Apendicele A",
							"__text": "Conectarea la Internet"
						},
						{
							"_nr": "Apendicele B",
							"__text": "Conectivitatea retelei internationale"
						},
						{
							"_nr": "Apendicele C",
							"__text": "Utilizari acceptabile"
						},
						{
							"_nr": "Apendicele D",
							"__text": "Introducere in Unix"
						}
					]
				},
				"_id": "896",
				"_isbn": "973-97046-2-X"
			},
			{
				"titlu": "Inteligenta artificiala si sisteme expert",
				"autori": {
					"autor": [
						{
							"_nume": "Cornelia Novac"
						},
						{
							"_nume": "Vasile Palade"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "280",
				"pretOriginal": "0",
				"pretCumparare": "207100",
				"tehnoredactori": {
					"tehnoredactor": [
						"Cornelia Novac",
						"Vasile Palade"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Inteligenta_artificiala_si_sisteme_expert_Novac.jpg"
					},
					"__text": "Andreea Staicu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Bazele teoretice ale inteligentei artificiale"
						},
						{
							"_nr": "3",
							"__text": "Metode de inferenta"
						},
						{
							"_nr": "4",
							"__text": "Reprezentarea cunoasterii"
						},
						{
							"_nr": "5",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "6",
							"__text": "Incertitudinea in sistemele expert"
						},
						{
							"_nr": "7",
							"__text": "Proiectarea sistemelor expert"
						},
						{
							"_nr": "8",
							"__text": "Evaluarea sistemelor expert"
						}
					]
				},
				"_id": "897",
				"_isbn": "973-31-1584"
			},
			{
				"titlu": "Aplicatii Windows in Visual C# 2008 Express Edition: aplicatii cu baze de date SQL Server 2008",
				"autori": {
					"autor": [
						{
							"_nume": "Ana Intuneric"
						},
						{
							"_nume": "Cristina Sichim"
						},
						{
							"_nume": "Daniela Tarasa"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Polirom",
				"numarPagini": "2008",
				"pretOriginal": "229600",
				"pretCumparare": "229600",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_Windows_in_Visual_C#_2008_Express_Edition_Aplicatii_cu_baze_de_date_SQL_server_2008_Intuneric.jpg"
					},
					"__text": "Radu Raileanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Aplicatii Windows cu C#"
						},
						{
							"_nr": "1",
							"__text": "Formulare, casete de text, butoane, etichete, imagini"
						},
						{
							"_nr": "2",
							"__text": "Timp, contor de timp si animatie"
						},
						{
							"_nr": "3",
							"__text": "Text, font, animatie, fisiere text"
						},
						{
							"_nr": "4",
							"__text": "Mouse-ul, prietenul nostru"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Interactiunea cu baze de date"
						},
						{
							"_nr": "1",
							"__text": "Proiectarea bazelor de date"
						},
						{
							"_nr": "2",
							"__text": "Comenzi DML de manipulare a datelor"
						},
						{
							"_nr": "3",
							"__text": "Instantierea unei baze de date folosind mediul vizual"
						},
						{
							"_nr": "4",
							"__text": "Filtrarea datelor"
						},
						{
							"_nr": "5",
							"__text": "Afisare cu sumar"
						},
						{
							"_nr": "6",
							"__text": "DataGridView - Excel"
						},
						{
							"_nr": "7",
							"__text": "Evidentierea celulelor"
						},
						{
							"_nr": "8",
							"__text": "Selectarea valorilor introduse in tabela"
						},
						{
							"_nr": "9",
							"__text": "Afisarea imaginilor cu ajutorul DateGridView"
						},
						{
							"_nr": "10",
							"__text": "Trimiterea datelor prin e-mail"
						}
					]
				},
				"_id": "898",
				"_isbn": ""
			},
			{
				"titlu": "Metodinca predarii informaticii",
				"autori": {
					"autor": [
						{
							"_nume": "C. Masalagiu"
						},
						{
							"_nume": "I. Asiminoaei"
						},
						{
							"_nume": "I. Maxim"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "MatrixRom",
				"numarPagini": "200",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Metodica_predarii_informaticii_Masalagiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni fundamentale in informatica"
						},
						{
							"_nr": "2",
							"__text": "Reforma curiculara"
						},
						{
							"_nr": "3",
							"__text": "Principii didactice"
						},
						{
							"_nr": "4",
							"__text": "Obiective didactice"
						},
						{
							"_nr": "5",
							"__text": "Metode, tehnici, procedee didactice"
						},
						{
							"_nr": "6",
							"__text": "Liste, stive, cozi, grafuri, arbori, sortare/cautare"
						}
					]
				},
				"_id": "899",
				"_isbn": "973-685-218-0"
			},
			{
				"titlu": "Cartea jocurilor",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Feteanu"
						},
						{
							"_nume": "Gheorghe Paun"
						},
						{
							"_nume": "Radu Baciu"
						},
						{
							"_nume": "Doinea Ciurea"
						},
						{
							"_nume": "Izabela Dorobantu"
						},
						{
							"_nume": "Victor Dumitrescu"
						},
						{
							"_nume": "Mircea Dumitru"
						},
						{
							"_nume": "Anca Filomon"
						},
						{
							"_nume": "Flavius Florea"
						},
						{
							"_nume": "Dana Cosman"
						},
						{
							"_nume": "Zhang Haitao"
						},
						{
							"_nume": "Livia Iacob"
						},
						{
							"_nume": "Adina Ionescu"
						},
						{
							"_nume": "Catalin Mamali"
						},
						{
							"_nume": "Solomon Marcus"
						},
						{
							"_nume": "Dragos Marinescu"
						},
						{
							"_nume": "Anisoara Paun"
						},
						{
							"_nume": "Mihaela Puia"
						},
						{
							"_nume": "Valentin Radulescu"
						},
						{
							"_nume": "Parik Stefanov"
						},
						{
							"_nume": "Gheorghe Stihi"
						},
						{
							"_nume": "Dan Stefanescu"
						},
						{
							"_nume": "Emilia Teodoru"
						},
						{
							"_nume": "Ladislau Varga"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "Recoop",
				"numarPagini": "224",
				"pretOriginal": "28",
				"redactori": {
					"redactor": "Adrian Cruceru"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gheorghe Dociu",
						"Dan Romanescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Cartea_jocurilor_Paun.jpg"
					},
					"__text": "Jack Cohn"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "In loc de prefata la o carte asteptata de toti amatorii de jocuri"
						},
						{
							"_nr": "2",
							"__text": "Pentamino"
						},
						{
							"_nr": "3",
							"__text": "GO - o partida de GO de la primul turneu national"
						},
						{
							"_nr": "4",
							"__text": "Cubul cu buline"
						},
						{
							"_nr": "5",
							"__text": "Initiere in Scrabble"
						},
						{
							"_nr": "6",
							"__text": "Patratul magic"
						},
						{
							"_nr": "7",
							"__text": "FLEX - 50 de jocuri intr-un singur"
						},
						{
							"_nr": "8",
							"__text": "Jocurile si oamenii (traducere dupa \"Science et Vie\")"
						},
						{
							"_nr": "9",
							"__text": "Stiati ca...?"
						},
						{
							"_nr": "10",
							"__text": "Caroiaj logic"
						},
						{
							"_nr": "11",
							"__text": "Jocurile si informatica. Opt jocuri pentru invatarea informaticii"
						},
						{
							"_nr": "12",
							"__text": "Fastieco"
						},
						{
							"_nr": "13",
							"__text": "Scoate inelul"
						},
						{
							"_nr": "14",
							"__text": "Sah - sase variante neobisnuite ale jocului de sah"
						},
						{
							"_nr": "15",
							"__text": "Interesct"
						},
						{
							"_nr": "16",
							"__text": "Mozaic - Sfaturi pentru un tanar creator de jocuri logice"
						},
						{
							"_nr": "17",
							"__text": "Jocul-joaca si jocul-corvoada"
						},
						{
							"_nr": "18",
							"__text": "Scrabble - o partida libera comentata"
						},
						{
							"_nr": "19",
							"__text": "Jocul cuvintelor"
						},
						{
							"_nr": "20",
							"__text": "Discul 12"
						},
						{
							"_nr": "21",
							"__text": "Mozaic - De la \"Shaturanga\" la sah"
						},
						{
							"_nr": "22",
							"__text": "Mozaic - Cum poate fi inventat un joc"
						},
						{
							"_nr": "23",
							"__text": "Umor"
						},
						{
							"_nr": "24",
							"__text": "Domilit"
						},
						{
							"_nr": "25",
							"__text": "Telefonul magic"
						},
						{
							"_nr": "26",
							"__text": "Muta patratul"
						},
						{
							"_nr": "27",
							"__text": "O scurta \"lectie\" despre permutari"
						},
						{
							"_nr": "28",
							"__text": "Breloc cu bile"
						},
						{
							"_nr": "29",
							"__text": "Total 10"
						},
						{
							"_nr": "30",
							"__text": "Sah - Sahul si cibernetica"
						},
						{
							"_nr": "31",
							"__text": "GO - Alte doua partide disputate in turneul Cluj-Napoca"
						},
						{
							"_nr": "32",
							"__text": "Caleidoscop de perspicacitate"
						},
						{
							"_nr": "33",
							"__text": "Caleidoscop de perspicacitate (raspunsuri)"
						},
						{
							"_nr": "34",
							"__text": "GO - Un test prea dificil"
						},
						{
							"_nr": "35",
							"__text": "Scrabble prin corespondenta"
						},
						{
							"_nr": "36",
							"__text": "Ganduri despre joc"
						},
						{
							"_nr": "37",
							"__text": "Program BASIC pentru jocurile IMPAS-FLEX"
						},
						{
							"_nr": "38",
							"__text": "Mozaic - Jocurile si istoria"
						},
						{
							"_nr": "39",
							"__text": "Piramida"
						},
						{
							"_nr": "40",
							"__text": "Mozaic - Jocul, o modalitate de concetrare?"
						},
						{
							"_nr": "41",
							"__text": "AS 31"
						},
						{
							"_nr": "42",
							"__text": "Jocul celor 8 coincidente"
						},
						{
							"_nr": "43",
							"__text": "Combicub"
						},
						{
							"_nr": "44",
							"__text": "Jocul perechilor"
						},
						{
							"_nr": "45",
							"__text": "GO - Stitul de joc si grupa sanguina"
						},
						{
							"_nr": "46",
							"__text": "Umor - O veste imbucuratoare"
						},
						{
							"_nr": "47",
							"__text": "Marele cub de buline"
						},
						{
							"_nr": "48",
							"__text": "Mozaic - Doua jocuri chimice"
						},
						{
							"_nr": "49",
							"__text": "Program BASIC pentru jocul drumului albastru"
						},
						{
							"_nr": "50",
							"__text": "Turnul din Hanoi"
						},
						{
							"_nr": "51",
							"__text": "Impas"
						},
						{
							"_nr": "52",
							"__text": "Mozaic - Vistiernicul ingenios"
						},
						{
							"_nr": "53",
							"__text": "Mozaic - Proprietatile magice ale hartiei de calc"
						},
						{
							"_nr": "54",
							"__text": "Scrabble - Duplicat"
						},
						{
							"_nr": "55",
							"__text": "Mozaic - Printesa sau tigrul"
						},
						{
							"_nr": "56",
							"__text": "Jocuri pentru invatarea informatici - BINAR"
						},
						{
							"_nr": "57",
							"__text": "Sah - Cinci probleme non-conformiste de sah"
						},
						{
							"_nr": "58",
							"__text": "Mozaic - 3+1 jocuri rebusiste noi"
						},
						{
							"_nr": "59",
							"__text": "O multiproblema cu dominouri"
						},
						{
							"_nr": "60",
							"__text": "Mozaic - Cinci probleme de logica"
						},
						{
							"_nr": "61",
							"__text": "Go - O intrebare dificila: Cum se joaca la mijlocul tablei?"
						},
						{
							"_nr": "62",
							"__text": "Sah - Sase variante exotice ale sahului"
						},
						{
							"_nr": "63",
							"__text": "O problema de perspicacitate"
						},
						{
							"_nr": "64",
							"__text": "Program BASIC pentru jocul Traversare"
						},
						{
							"_nr": "65",
							"__text": "Meijin - maetrul de GO"
						},
						{
							"_nr": "60",
							"__text": "JECO va ofera"
						}
					]
				},
				"_id": "900",
				"_isbn": ""
			},
			{
				"titlu": "Desfasurarea suprafetelor asistata de calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan D. Marinescu"
						},
						{
							"_nume": "Aurelian Tanasescu"
						},
						{
							"_nume": "Radu Constantinescu"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Tehnica",
				"numarPagini": "226",
				"pretOriginal": "24",
				"redactori": {
					"redactor": "Vasile Buzaty"
				},
				"tehnoredactori": {
					"tehnoredactor": "V. E. Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Desfasurarea_suprafetelor_asistata_de_calculator_Marinescu.jpg"
					},
					"__text": "Acginagel-Costea Ileana"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Transformari geometrice"
						},
						{
							"_nr": "2",
							"__text": "Constructii geometrice plane necesare in desfasurarea suprafetelor, relatii grafice si analitice"
						},
						{
							"_nr": "3",
							"__text": "Proceduri apelate in programele principale ale desfasuratelor de suprafete"
						},
						{
							"_nr": "4",
							"__text": "Constructii geometrice spatiale de poliedre necesare in desfasurarea suprafetelor. Relatii grafice si analitice"
						},
						{
							"_nr": "5",
							"__text": "Suprafete. Curbura suprafetelor. Suprafete desfasurate"
						},
						{
							"_nr": "6",
							"__text": "Desfasurarea automata a suprafetelor cilindrice"
						},
						{
							"_nr": "7",
							"__text": "Desfasurarea automata a suprafetelor conice"
						},
						{
							"_nr": "8",
							"__text": "Desfasurarea automata a intersectiei dintre doi cilindri avand axele necoplanare sau concurente"
						},
						{
							"_nr": "9",
							"__text": "Desfasurarea automata a intersectiei dintre doua conuri avand axele necoplanare sau concurente"
						},
						{
							"_nr": "10",
							"__text": "Desfasurarea automata a intersectiei dintre un con si un cilindru avand axele necoplanare sau concurente"
						},
						{
							"_nr": "11",
							"__text": "relatii analitice generale pentru determinarea desfasuratelor dintre suprafetele conice si ccilindrice de rotatie"
						},
						{
							"_nr": "12",
							"__text": "Desfasurarea aproximativa a suprafetelor nedesfasurate"
						},
						{
							"_nr": "13",
							"__text": "Anexe"
						}
					]
				},
				"_id": "901",
				"_isbn": ""
			},
			{
				"titlu": "Practica dezvoltarii software orientata pe structuri de date",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Ivan"
						},
						{
							"_nume": "Critian Ionita"
						},
						{
							"_nume": "Catalin Boja"
						},
						{
							"_nume": "Marius Popa"
						},
						{
							"_nume": "Adrian Pocovnicu"
						},
						{
							"_nume": "Daniel Milodin"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "ASE",
				"numarPagini": "224",
				"pretOriginal": "88000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Livia Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Carmen Stefania Creanga"
				},
				"coperta": {
					"imagine": {
						"_path": "Practica_dezvoltarii_software_orintata_pe_structuri_de_date_Ivan.jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Lucrul cu matrice"
						},
						{
							"_nr": "2",
							"__text": "Matrice rare"
						},
						{
							"_nr": "3",
							"__text": "Structuri de tip articol"
						},
						{
							"_nr": "4",
							"__text": "Lista simpla"
						},
						{
							"_nr": "5",
							"__text": "Stiva"
						},
						{
							"_nr": "6",
							"__text": "Lista dubla"
						},
						{
							"_nr": "7",
							"__text": "Arbori binari"
						},
						{
							"_nr": "8",
							"__text": "Alte tipuri de arbori"
						},
						{
							"_nr": "9",
							"__text": "Arbori B"
						},
						{
							"_nr": "10",
							"__text": "Grafuri"
						},
						{
							"_nr": "11",
							"__text": "Agregarea de structuri de date"
						},
						{
							"_nr": "12",
							"__text": "Subiecte propuse pentru examen"
						}
					]
				},
				"_id": "902",
				"_isbn": "973-594-630-0"
			},
			{
				"titlu": "Algoritmi in programare",
				"autori": {
					"autor": [
						{
							"_nume": "Bogdan Ghilic-Micu"
						},
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Constantin Apostol"
						},
						{
							"_nume": "Marian Stoica"
						},
						{
							"_nume": "Catalin Lucia Cocianu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "318",
				"pretOriginal": "89000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_in_programare_Micu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Structura si functionarea calculatorului"
						},
						{
							"_nr": "2",
							"__text": "Organizarea interna a datelor"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi. Scheme logice structurate"
						},
						{
							"_nr": "4",
							"__text": "Etapele rezolvarii problemelor cu calculatorul"
						},
						{
							"_nr": "5",
							"__text": "Sisteme informationale si informatice"
						},
						{
							"_nr": "6",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "7",
							"__text": "Tipuri de date si expresii"
						},
						{
							"_nr": "8",
							"__text": "Realizarea structurilor fundamentale de control"
						},
						{
							"_nr": "9",
							"__text": "Subrograme"
						},
						{
							"_nr": "10",
							"__text": "Tehnici de introducere primara a datelor in procesul prelucrarii"
						},
						{
							"_nr": "11",
							"__text": "Validarea datelor"
						},
						{
							"_nr": "12",
							"__text": "Organizarea externa a datelor in fisiere"
						},
						{
							"_nr": "13",
							"__text": "tipul de data articol"
						},
						{
							"_nr": "14",
							"__text": "Prelucrarea fisierelor in Pascal"
						},
						{
							"_nr": "15",
							"__text": "Algoritmi de prelucrare a fisierelor binare"
						},
						{
							"_nr": "16",
							"__text": "Mediul de dezvoltare Turbo Pascal"
						}
					]
				},
				"_id": "903",
				"_isbn": "973-594-168-6"
			},
			{
				"titlu": "Computer Driving Licence. Modulul 7 - Internet",
				"autori": {
					"autor": [
						{
							"_nume": "Bernhard Eder"
						},
						{
							"_nume": "Willibald Kodym"
						},
						{
							"_nume": "Franz Lechner"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "BIC ALL",
				"numarPagini": "144",
				"pretOriginal": "59000",
				"traducere": {
					"titluOriginal": "Durchst@rten zum Computer Fuhrerschein, Internet",
					"editura": "VERITAS-VERLAG Linz",
					"traducatori": {
						"traducator": "Constatin Predan"
					},
					"_an": "2000",
					"_editia": "I",
					"_limba": "germana",
					"_tara": "Germania"
				},
				"redactori": {
					"redactor": "Madalina Aimee Erighin"
				},
				"coperta": {
					"imagine": {
						"_path": "Computer_Drivind_Licence_Modulul_7_Internet_Eder.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este internetul?"
						},
						{
							"_nr": "2",
							"__text": "\"Planul de constuctie\" al Internetului"
						},
						{
							"_nr": "3",
							"__text": "Servicii in Internet"
						},
						{
							"_nr": "4",
							"__text": "Cum ajung in internet"
						},
						{
							"_nr": "5",
							"__text": "Provider"
						},
						{
							"_nr": "6",
							"__text": "Adresare in Internet"
						},
						{
							"_nr": "7",
							"__text": "Programe pentru navigare in Internet"
						},
						{
							"_nr": "8",
							"__text": "Direct in WWW"
						},
						{
							"_nr": "9",
							"__text": "Bookmarks (Semne de citire) si Favorites"
						},
						{
							"_nr": "10",
							"__text": "Bara de instrumente personale"
						},
						{
							"_nr": "11",
							"__text": "A cauta si a gasi"
						},
						{
							"_nr": "12",
							"__text": "Cum expediem E-mail-urile"
						},
						{
							"_nr": "13",
							"__text": "Receptionarea E-mail-urilor"
						},
						{
							"_nr": "14",
							"__text": "Stabilirea contactelor cu ajutorul agendei de adrese"
						},
						{
							"_nr": "15",
							"__text": "Administrarea E-mail-urilor"
						},
						{
							"_nr": "16",
							"__text": "E-mail-uri in Internet"
						},
						{
							"_nr": "17",
							"__text": "Siguranta in Internet"
						},
						{
							"_nr": "18",
							"__text": "A conversa in Chat"
						}
					]
				},
				"_id": "904",
				"_isbn": "973-571-345-4"
			},
			{
				"titlu": "Teoria deciziei si cercetare operationala",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Alexandra Dumitrescu"
						},
						{
							"_nume": "Cristian Niculescu"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Niculescu",
				"numarPagini": "206",
				"pretOriginal": "0",
				"pretCumparare": "60000",
				"coperta": {
					"imagine": {
						"_path": "Teoria_deciziei_si_cercetare_operationala_Dumitrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Procesul decizional"
						},
						{
							"_nr": "3",
							"__text": "Procesul informational"
						},
						{
							"_nr": "4",
							"__text": "Sistemul decizional"
						},
						{
							"_nr": "5",
							"__text": "Unitati decizionale"
						},
						{
							"_nr": "6",
							"__text": "Decizii de grup"
						},
						{
							"_nr": "7",
							"__text": "Optimizarea deciziilor in conditii de certitudine"
						},
						{
							"_nr": "8",
							"__text": "Optimizarea deciziilor in conditii de risc"
						},
						{
							"_nr": "9",
							"__text": "Decizii in conditii de incertitudine si optimizarea lor. Decizii fuzzy"
						},
						{
							"_nr": "10",
							"__text": "Optimizarea deciziilor prin programarea liniara"
						},
						{
							"_nr": "11",
							"__text": "Optimizarea deciziilor prin programarea dinamica"
						},
						{
							"_nr": "12",
							"__text": "Programarea neliniara folosita pentru optimizarea deciziilor"
						},
						{
							"_nr": "13",
							"__text": "Modelele planificarii in retea"
						},
						{
							"_nr": "14",
							"__text": "Fundamentarea deciziilor folosind teoria fenomenelor de asteptare si teoria stocurilor"
						},
						{
							"_nr": "15",
							"__text": "Analiza Markov"
						},
						{
							"_nr": "16",
							"__text": "Optimizarea deciziilor pe baza teoriei reinnoirii"
						}
					]
				},
				"_id": "905",
				"_isbn": "973-568-578-7"
			},
			{
				"titlu": "Probleme de statistica rezolvate pe calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Ion D. Resa"
						},
						{
							"_nume": "St. Petrescu"
						},
						{
							"_nume": "M. Precupas"
						},
						{
							"_nume": "Al. Care"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "facla",
				"numarPagini": "212",
				"pretOriginal": "20.50",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan I. Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_statistica_rezolvate_pe_calculator_Resa.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Repartitii empirice"
						},
						{
							"_nr": "2",
							"__text": "Selectii de volum mare"
						},
						{
							"_nr": "3",
							"__text": "Selectii de volum redus"
						},
						{
							"_nr": "4",
							"__text": "Corelatie si regresie"
						}
					]
				},
				"_id": "906",
				"_isbn": ""
			},
			{
				"titlu": "Matrice rare si aplicatiile lor",
				"autori": {
					"autor": [
						{
							"_nume": "Neculai Andrei"
						},
						{
							"_nume": "Constantin Rasurnoiu"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Tehnica",
				"numarPagini": "280",
				"pretOriginal": "15.50",
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Matrice_rare_si_aplicatiile_lor_Andrei.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Sisteme fizice si matrice rare"
						},
						{
							"_nr": "3",
							"__text": "Metode de programare cu matrice rare"
						},
						{
							"_nr": "4",
							"__text": "Metode de ordonare"
						},
						{
							"_nr": "5",
							"__text": "Factorizarea matricelor rare"
						},
						{
							"_nr": "6",
							"__text": "Rezolvarea sistemelor de ecuatii algebrice liniare cu matricea coeficientilor rara"
						},
						{
							"_nr": "7",
							"__text": "Programarea liniara utilizand metode de calcul cu matrice rare"
						},
						{
							"_nr": "8",
							"__text": "Optimizarea sistemelor utilizand metode de calcul cu matrice rare"
						}
					]
				},
				"_id": "907",
				"_isbn": ""
			},
			{
				"titlu": "Algebra binara a lui Boole si aplicatiile ei in informatica",
				"autori": {
					"autor": {
						"_nume": "Raoul de Palma"
					}
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"tiraj": "6000+50 exemplare brosate",
				"numarPagini": "144",
				"pretOriginal": "4",
				"traducere": {
					"titluOriginal": "L'Algebre binaire de Boole et ses applications a l'informatique",
					"editura": "Dunod",
					"traducatori": {
						"traducator": "Ion Sacuiu"
					},
					"_an": "1971",
					"_editia": "I",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Valentina Cretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Algebra_binara_a_lui_Boole_si_aplicatiile_ei_in_informatica_Palma.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de matematici moderne"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de numeratie"
						},
						{
							"_nr": "3",
							"__text": "Cum sa codificam informatiile"
						},
						{
							"_nr": "4",
							"__text": "Algebra lui Boole"
						},
						{
							"_nr": "5",
							"__text": "Aplicatiile algebrei booleene la functionarea unui calculator si a unui automat"
						},
						{
							"_nr": "6",
							"__text": "Cateva aplicatii in limbajele de programare"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea algebrei lui Boole in metodele de analiza"
						}
					]
				},
				"_id": "908",
				"_isbn": ""
			},
			{
				"titlu": "Automate aleatoare cu utilitati",
				"autori": {
					"autor": {
						"_nume": "Dan D. Farcas"
					}
				},
				"anAparitie": "1987",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "234",
				"pretOriginal": "16.50",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Automate_aleatoare_cu_utilitati_Farcas.jpg"
					},
					"__text": "Gh. G. Marinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Activitatea si modelul activitatii"
						},
						{
							"_nr": "2",
							"__text": "Automate aleatoare"
						},
						{
							"_nr": "3",
							"__text": "Modelul matematic pentru automate aleatoare"
						},
						{
							"_nr": "4",
							"__text": "Motivatie si utilitate"
						},
						{
							"_nr": "5",
							"__text": "Modeul matematic pentru automate cu utilitati"
						},
						{
							"_nr": "6",
							"__text": "Automate adaptive"
						},
						{
							"_nr": "7",
							"__text": "Modelul matematic pentru automate adaptive"
						},
						{
							"_nr": "8",
							"__text": "Automate neuronale, modele intuitive"
						},
						{
							"_nr": "9",
							"__text": "Modele verbale, modele logice"
						},
						{
							"_nr": "10",
							"__text": "Modelul matematic pentur un automat logic"
						},
						{
							"_nr": "11",
							"__text": "Sisteme de automate, multiautomate, metaautomate"
						},
						{
							"_nr": "12",
							"__text": "Modelul matematic pentu sisteme de automate"
						},
						{
							"_nr": "13",
							"__text": "Aplicatii ale automatelor aleatoare cu utilitati in optimizarea unor procese industriale"
						},
						{
							"_nr": "14",
							"__text": "Abordari posibile in stiinte umane si sociale"
						}
					]
				},
				"_id": "909",
				"_isbn": ""
			},
			{
				"titlu": "Aspecte noi ale teoriei informatiei",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Petrica"
						},
						{
							"_nume": "Vasile Stefanescu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Academiei Republicii Socialiste Romane",
				"numarPagini": "152",
				"pretOriginal": "6.50",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Aspecte_noi_ale_teoriei_informatiei_Petrica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Teoria lui Shannon a informatiei"
						},
						{
							"_nr": "2",
							"__text": "Energia si corelatia informationala Onicescu"
						},
						{
							"_nr": "3",
							"__text": "Caracterizari functionale ale energiei si corelatiei informationale. Teoremele lui Onicescu"
						},
						{
							"_nr": "4",
							"__text": "Transmiterea informatiei. Codificare"
						},
						{
							"_nr": "5",
							"__text": "Estimatii prin metoda energiei informationale maxime"
						},
						{
							"_nr": "6",
							"__text": "Esimarea prin metoda energiei informationale maxime in cazul lantului Markov"
						},
						{
							"_nr": "7",
							"__text": "Estimatii prin metoda energiei informationale maxime in cazul variabilelor complet inlantuite"
						},
						{
							"_nr": "8",
							"__text": "Estimatii prin metoda ebergiei informationale maxime in experimente secventiale"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii ale energiei si corelatiei Onicescu"
						}
					]
				},
				"_id": "910",
				"_isbn": ""
			},
			{
				"titlu": "Limbajul logicii matematice",
				"autori": {
					"autor": {
						"_nume": "Hans Freudenthal"
					}
				},
				"anAparitie": "1973",
				"editura": "Tehnica",
				"tiraj": "10970+30+140 exemplare brosate",
				"numarPagini": "152",
				"pretOriginal": "5",
				"traducere": {
					"titluOriginal": "The language of logic",
					"editura": "Elsevier Publishing Company",
					"traducatori": {
						"traducator": "Valentina Neicov"
					},
					"_an": "1966",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicolae Serbanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_logicii_matematice_Freudenthal.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Multimi si aplicatii"
						},
						{
							"_nr": "2",
							"__text": "Logica propozitiilor"
						},
						{
							"_nr": "3",
							"__text": "Logica predicatelor"
						},
						{
							"_nr": "4",
							"__text": "Logica formala"
						},
						{
							"_nr": "5",
							"__text": "Limbaj si metalimbaj"
						}
					]
				},
				"_id": "911",
				"_isbn": ""
			},
			{
				"titlu": "Elemente de teoria laticelor si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Agneta Vescan"
					}
				},
				"anAparitie": "1984",
				"editura": "Dacia",
				"numarPagini": "144",
				"pretOriginal": "10",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_teoria_laticelor_si_aplicatii_Vescan.jpg"
					},
					"__text": "Calin Damian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Structuri partial ordonate"
						},
						{
							"_nr": "II",
							"__text": "Latice"
						},
						{
							"_nr": "III",
							"__text": "Latice distributice si modulare"
						},
						{
							"_nr": "IV",
							"__text": "Latice complementare si algebre Boole"
						},
						{
							"_nr": "V",
							"__text": "Teoreme de izomorfism pentru latice"
						},
						{
							"_nr": "Anexa I",
							"__text": "Universul lui Grotheendieck"
						},
						{
							"_nr": "Anexa II",
							"__text": "Unele aplicatii ale teoriei laticelor in teoria automatelor"
						}
					]
				},
				"_id": "912",
				"_isbn": ""
			},
			{
				"titlu": "Modele matematice si pachete de programe cu aplicatii in economie",
				"autori": {
					"autor": [
						{
							"_nume": "Virgil Chichernea"
						},
						{
							"_nume": "Liviu Dumitrascu"
						},
						{
							"_nume": "Teodor Lorent"
						},
						{
							"_nume": "Gabriel Mihoc"
						},
						{
							"_nume": "Petre Norarescu"
						},
						{
							"_nume": "Stefan Patrut"
						}
					]
				},
				"anAparitie": "1979",
				"editura": "Academia \"Stefan Gheorghiu\"",
				"numarPagini": "412",
				"pretOriginal": "0",
				"pretCumparare": "5000",
				"coperta": {
					"imagine": {
						"_path": "Modele_matematice_si_pachete_de_programe_cu_aplicatii_in_economie_Chichernea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Modele matematice si pachete de programe"
						},
						{
							"_nr": "I",
							"__text": "Prelucrarea statistica a datelor"
						},
						{
							"_nr": "2",
							"__text": "Analize statistico-matematice efectualte cu ajutorul calculatorului electronic"
						},
						{
							"_nr": "3",
							"__text": "Analize de regresie"
						},
						{
							"_nr": "4",
							"__text": "Analiza de varianta"
						},
						{
							"_nr": "II",
							"__text": "Modele de optimizare"
						},
						{
							"_nr": "5",
							"__text": "Modele de programare liniara"
						},
						{
							"_nr": "6",
							"__text": "Pachetul de programe OPALINE"
						},
						{
							"_nr": "7",
							"__text": "Sistemul de programe PROGEN"
						},
						{
							"_nr": "8",
							"__text": "Modele operationale de programare lineara"
						},
						{
							"_nr": "9",
							"__text": "Nodele de transport si repartitie"
						},
						{
							"_nr": "Partea III",
							"__text": "Metode de planificare si control in retea"
						},
						{
							"_nr": "10",
							"__text": "Analiza drumului critic"
						},
						{
							"_nr": "11",
							"__text": "Pachetul de programe PERT-CORAIL-II"
						}
					]
				},
				"_id": "913",
				"_isbn": ""
			},
			{
				"titlu": "Lucrari practice. Studii de caz rezolvate cu produsul informatic Quantitative Analysis For Management (II)",
				"autori": {
					"autor": [
						{
							"_nume": "Camelia Ratiu-Suciu"
						},
						{
							"_nume": "Florica Luban"
						},
						{
							"_nume": "Daniela Hincu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "ASE",
				"numarPagini": "285",
				"pretOriginal": "2900",
				"pretCumparare": "5000",
				"coperta": {
					"imagine": {
						"_path": "Lucrari_practice_studii_de_caz_rezolvate_cu_produsul_informatic_Quntitative_analysis_gor_Management_Suciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "5",
							"__text": "Modelarea unor procese stochastice de tip Markov"
						},
						{
							"_nr": "6",
							"__text": "Modelarea unor procese econimice cu programarea dinamica"
						},
						{
							"_nr": "7",
							"__text": "Modelarea economica a unor procese de previziune"
						},
						{
							"_nr": "8",
							"__text": "Modelarea unor probleme de stocare"
						},
						{
							"_nr": "9",
							"__text": "Modelarea unor probleme de asteptare"
						},
						{
							"_nr": "10",
							"__text": "Simularea unor procese economice cu tehnica Monte Carlo"
						},
						{
							"_nr": "11",
							"__text": "Rezolvarea studiilor de caz propuse cu produsul informatic QM"
						},
						{
							"_nr": "Anexa",
							"__text": "Pachetul de programe QM. Prezentare generala si ghid de utilizare"
						}
					]
				},
				"_id": "914",
				"_isbn": "973-9175-39-2"
			},
			{
				"titlu": "Mintea noastra...cea de toate zilele. Despre gandire, fizica si calculatoare",
				"autori": {
					"autor": {
						"_nume": "Roger Penrose"
					}
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "504",
				"pretOriginal": "15500",
				"pretCumparare": "140000",
				"traducere": {
					"titluOriginal": "The emperor's new mind. Concerning Computers, Minds, and the Laws of Physic",
					"editura": "Oxford University Press",
					"traducatori": {
						"traducator": [
							"Cornelia C. Rusu",
							"Mircea V. Rusu"
						]
					},
					"_an": "1989",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "Anglia"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicoleta Negoita"
				},
				"coperta": {
					"imagine": {
						"_path": "Mintea_noastra_cea_de_toate_zilele_despre_gandire_fizica_si_calculatoare_Penrose.jpg"
					},
					"__text": "Mircea V. Rusu, Razvan Gheorghiu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Poate un calculator sa gandeasca?"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi si masini Turing"
						},
						{
							"_nr": "3",
							"__text": "Matematica si realitate"
						},
						{
							"_nr": "4",
							"__text": "Adevar, demonstratie si intelegere"
						},
						{
							"_nr": "5",
							"__text": "Lumea clasica"
						},
						{
							"_nr": "6",
							"__text": "Magie cunatica si mister cuantica"
						},
						{
							"_nr": "7",
							"__text": "Cosmologie si sageata timpului"
						},
						{
							"_nr": "8",
							"__text": "In cautarea gravitatii cuntice"
						},
						{
							"_nr": "9",
							"__text": "Creierul si modele ale lui"
						},
						{
							"_nr": "10",
							"__text": "unde se afla azi fizica mintii"
						}
					]
				},
				"_id": "915",
				"_isbn": "973-31-0969-X"
			},
			{
				"titlu": "Logica matematica",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Cazacu"
						},
						{
							"_nume": "Valeria Slabu"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Stefan Lupascu",
				"numarPagini": "328",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Logica_matematica_Cazacu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Functii booleene"
						},
						{
							"_nr": "2",
							"__text": "Formule si intrepretari"
						},
						{
							"_nr": "3",
							"__text": "Sisteme deductive"
						},
						{
							"_nr": "4",
							"__text": "Teorii formale de ordinul I"
						},
						{
							"_nr": "5",
							"__text": "Deductii automate"
						}
					]
				},
				"_id": "916",
				"_isbn": "973-99044-0-8"
			},
			{
				"titlu": "Introducere in teoria multimilor",
				"autori": {
					"autor": {
						"_nume": "Ferucio Laurentiu Tiplea"
					}
				},
				"anAparitie": "1998",
				"editura": "Universitatii \"Alexandru Ioan Cuza\"",
				"numarPagini": "320",
				"pretOriginal": "31000",
				"redactori": {
					"redactor": "Mihaela Popovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ferucio Laurentiu Tiplea"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_teoria_multimilor_Tiplea.jpg"
					},
					"__text": "Bogdan Popa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Multimi"
						},
						{
							"_nr": "2",
							"__text": "Relatii si functii"
						},
						{
							"_nr": "3",
							"__text": "Numere naturale"
						},
						{
							"_nr": "4",
							"__text": "Echipotenta, finititudine, numarabilitate"
						},
						{
							"_nr": "5",
							"__text": "Numere reale"
						},
						{
							"_nr": "6",
							"__text": "Ordini bune si ordinali"
						},
						{
							"_nr": "7",
							"__text": "Propozitii echivalente Axiomei alegerii"
						},
						{
							"_nr": "8",
							"__text": "Cardinali"
						},
						{
							"_nr": "9",
							"__text": "Inchideri"
						},
						{
							"_nr": "10",
							"__text": "Ordini partiale bune"
						}
					]
				},
				"_id": "917",
				"_isbn": "973-9312-29-2"
			},
			{
				"titlu": "Verificarea ipotezelor statistice",
				"autori": {
					"autor": {
						"_nume": "Virgil Craiu"
					}
				},
				"anAparitie": "1972",
				"editura": "Didactica si pedagogica",
				"numarPagini": "230",
				"pretOriginal": "10.30",
				"redactori": {
					"redactor": "Avram Pop"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ilinca Prosan"
				},
				"coperta": {
					"imagine": {
						"_path": "Verificarea_ipotezelor_statistice_Craiu.jpg"
					},
					"__text": "Nicolae Sirbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Testul raportului de verosimilitate si verificarea ipotezelor statistice privind parametrii repartitiei normale"
						},
						{
							"_nr": "2",
							"__text": "Verificari de ipoteze privind parametrii repartitiei binomiala, hipergeometrica si Poisson"
						},
						{
							"_nr": "3",
							"__text": "Testul X^2"
						},
						{
							"_nr": "4",
							"__text": "Teste pentru egalitatea dispersiilor si mediilor"
						},
						{
							"_nr": "5",
							"__text": "Teste neparametrice"
						},
						{
							"_nr": "6",
							"__text": "Analiza dispersionala"
						},
						{
							"_nr": "7",
							"__text": "Regresie lineara"
						},
						{
							"_nr": "8",
							"__text": "Repartitia normala bidimensionala si coefiecientul de corelatie"
						},
						{
							"_nr": "9",
							"__text": "Regrese multipla si analiza dispersionala"
						},
						{
							"_nr": "10",
							"__text": "Testul secvential"
						}
					]
				},
				"_id": "918",
				"_isbn": ""
			},
			{
				"titlu": "Modele de analiza statistica",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Mihoc"
						},
						{
							"_nume": "Veniamin Urseanu"
						},
						{
							"_nume": "Emiliana Ursianu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "518",
				"pretOriginal": "27",
				"redactori": {
					"redactor": "Maria Blaga"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Modele_de_analiza_statistica_Mihoc.jpg"
					},
					"__text": "Constantin Geheorghiu-Enescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode de analiza statisitca a caracteristicilor calitative"
						},
						{
							"_nr": "2",
							"__text": "Metode de analiza a dependetei stochastice"
						},
						{
							"_nr": "3",
							"__text": "Metode de analiza a factorilor. Planificarea experientelor"
						},
						{
							"_nr": "4",
							"__text": "Statistica informationala. Energia si corelatia informationala"
						},
						{
							"_nr": "5",
							"__text": "Modele markoviene"
						},
						{
							"_nr": "6",
							"__text": "Inferenta statistica pentru variabile markoviene"
						}
					]
				},
				"_id": "919",
				"_isbn": ""
			},
			{
				"titlu": "Analiza asistata de calculator a circuitelor electrice si electronice neliniare complexe de mari dimensiuni",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Iordache"
						},
						{
							"_nume": "Mircea Perpelea"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Didactica si pedagogica",
				"numarPagini": "224",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Murariu Marius"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Tilea Ion",
						"Munteanu Corneliu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_asistata_de_calculator_a_circuitelor_electrice_si_electronice_neliniare_complexe_de_mari_dimensiuni_Iordache.jpg"
					},
					"__text": "Dumitru Smalenic"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Stadiul actual al analizei circuitelor electrice si electronice de dimensiuni mari"
						},
						{
							"_nr": "2",
							"__text": "Utilizarea grafurilor de curent si de tensiune in analiza circuitelor electrice si electronice de dimensiuni mari"
						},
						{
							"_nr": "3",
							"__text": "Aplicarea metodei nodale modificate in analiza circuitelor electrice si electronice de dimensiuni mari"
						},
						{
							"_nr": "4",
							"__text": "Metode de tip tablou pentru analiza circuitelor electrice si electronice de dimensiuni mari in regim tranzitoriu"
						},
						{
							"_nr": "5",
							"__text": "Sectionarea circuitelor electrice si electronice de dimensiuni mari"
						},
						{
							"_nr": "6",
							"__text": "Analiza diakoptica a circuitelor electrice si electronice complexe neliniare de dimensiuni mari"
						},
						{
							"_nr": "7",
							"__text": "O metoda de analiya hibrida generalizata a circuitelor electrice si electronice neliniare de dimensiuni mari"
						},
						{
							"_nr": "Anexa A1",
							"__text": "Listingul programului ANMCER - Analiza Nodala Modificata a Circuitelor Electrice si Electronice Rezistive"
						},
						{
							"_nr": "Anexa A2",
							"__text": "Datele de intrare pentru exemplul din figura 3.7"
						},
						{
							"_nr": "Anexa A3",
							"__text": "Datele de iesire pentru exemplul din figura 3.7"
						},
						{
							"_nr": "Anexa A4",
							"__text": "Datele de intrare pentru modelarea matematica a sistemului de ventilatie a rotoarelor turbogeneratoare de medie putere (12MW)"
						},
						{
							"_nr": "Anexa A5",
							"__text": "Datele de iesire pentru sistemul de ventilatie a rotoarelor turbogeneratoare de medie putere (12MW)"
						},
						{
							"_nr": "Anexa A6",
							"__text": "Listingul programului GSCE - Generarea Subcircuitelor intr-un Circuit Electric sau Electronic"
						}
					]
				},
				"_id": "920",
				"_isbn": "973-30-3175-5"
			},
			{
				"titlu": "Cercetari operationale",
				"autori": {
					"autor": [
						{
							"_nume": "A. Stefanescu"
						},
						{
							"_nume": "C. Zidaroiu"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Didactica si pedagogica",
				"numarPagini": "250",
				"pretOriginal": "10.80",
				"redactori": {
					"redactor": "Gabriela Iliescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Petrica"
				},
				"coperta": {
					"imagine": {
						"_path": "Cercetari_operationale_Stefanescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programare liniara"
						},
						{
							"_nr": "2",
							"__text": "Programare neliniara"
						},
						{
							"_nr": "3",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "4",
							"__text": "Elemente de teoria jocurilor"
						},
						{
							"_nr": "5",
							"__text": "Elemene de teoira asteptarii"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Multimi si functii convexe"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Lnaturi Markov"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Functii Bessel modificate, de speta I."
						}
					]
				},
				"_id": "921",
				"_isbn": ""
			},
			{
				"titlu": "Proiectarea cu circuite logice MSI si LSI standard",
				"autori": {
					"autor": {
						"_nume": "Thomas R. Blakeslee"
					}
				},
				"anAparitie": "1988",
				"editura": "Tehnica",
				"numarPagini": "380",
				"pretOriginal": "39",
				"traducere": {
					"titluOriginal": "Digital Design with Standard MSI ans LSI. Design tehniques for the microcomputer age (Second edition)",
					"editura": "Logisticon Inc.",
					"traducatori": {
						"traducator": [
							"M. Bodea",
							"Gh. Stefan"
						]
					},
					"_an": "1979",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Smarada Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiada Nistor"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_cu_circuite_logice_MSI_si_LSI_standard_Blakeslee.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ideea: sa adaptam ceea ce avem de facut la componentele ieftine"
						},
						{
							"_nr": "2",
							"__text": "Obiectvele proiectarii unui sistem digital"
						},
						{
							"_nr": "3",
							"__text": "Logica combinationala I : Proiectarea logica traditionala"
						},
						{
							"_nr": "4",
							"__text": "Logica combinationala II : proiectarea cu circuite MSI si LSI"
						},
						{
							"_nr": "5",
							"__text": "Logica secventiala : Proiectare"
						},
						{
							"_nr": "6",
							"__text": "Realitati neplacute I : Probleme de propagare si blocare"
						},
						{
							"_nr": "7",
							"__text": "Logica programata I : Microcalculatoare"
						},
						{
							"_nr": "8",
							"__text": "Logica programata II : Programarea asistata de calculator"
						},
						{
							"_nr": "9",
							"__text": "Logica programata III : Sisteme de dezvoltare"
						},
						{
							"_nr": "10",
							"__text": "Logica programata IV : Proiectarea hardware-ului a microcalculatoarelor"
						},
						{
							"_nr": "11",
							"__text": "Dimensiunea timp"
						},
						{
							"_nr": "12",
							"__text": "Realitati neplacute II : Zgomotul si relexiile"
						},
						{
							"_nr": "13",
							"__text": "Dispozitive de intrare-iesire"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea statisticii la proictarea digitala"
						},
						{
							"_nr": "15",
							"__text": "Consecintele sociale ale ingineriei"
						}
					]
				},
				"_id": "922",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de ecuatii liniare si forme patratice",
				"autori": {
					"autor": {
						"_nume": "Cerchez Mihu"
					}
				},
				"anAparitie": "1985",
				"editura": "Tehnica",
				"numarPagini": "232",
				"pretOriginal": "12.50",
				"redactori": {
					"redactor": "Lina Ticos"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_ecuatii_de_ecuatii_liniare_si_forme_patratice_Mihu.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode de rezolvarea a sistemelor de ecuatii liniare"
						},
						{
							"_nr": "2",
							"__text": "Discutia sistemelor de ecuatii liniar omogene si neomogene"
						},
						{
							"_nr": "3",
							"__text": "Forme patratice. Expresia canonoca a unei forme patratice"
						},
						{
							"_nr": "4",
							"__text": "Determinarea numarului de operatii elementare necesare pentru un sistem de n ecuatii liniare cu n necunoscute"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Calculul erorilor cu care au fost obtinute solutiile sistemelor"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Scheme logice si programe pe calculator, pentru metoda Onicescu si metoda vectoriaal (W. E. Purcell)"
						}
					]
				},
				"_id": "923",
				"_isbn": ""
			},
			{
				"titlu": "Metode robuste de estimare mutlidimensionala",
				"autori": {
					"autor": {
						"_nume": "Aida Toma"
					}
				},
				"anAparitie": "2009",
				"editura": "MatrixRom",
				"numarPagini": "154",
				"pretOriginal": "0",
				"pretCumparare": "210000",
				"coperta": {
					"imagine": {
						"_path": "Metode_robuste_de_estimare_multidimensionala_Toma.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte fundamentale in statisitca robusta"
						},
						{
							"_nr": "2",
							"__text": "Estimarea robusta a parametrilor repartitiilor eliptice"
						},
						{
							"_nr": "3",
							"__text": "Estimarea robusta a parametrilor repartitiilor multidimensionale din sistemul de translatii a lui Johnson"
						},
						{
							"_nr": "4",
							"__text": "Metode de distanta Helliger minima in estimarea parametrica multidimensionala"
						}
					]
				},
				"_id": "924",
				"_isbn": "978-973-755-469-7"
			},
			{
				"titlu": "Analiza statistica multidimensionala. Aplicatii in cadrul studiului produselor si serviciilor",
				"autori": {
					"autor": {
						"_nume": "Cristina Boboc"
					}
				},
				"anAparitie": "2007",
				"editura": "Meteor Press",
				"numarPagini": "192",
				"pretOriginal": "0",
				"pretCumparare": "154000",
				"coperta": {
					"imagine": {
						"_path": "Analiza_statistica_multidimensionala_Aplicatii_in_cadrul_studiului_produselor_si_serviciilor_Boboc.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Controverse si tendinte actuale ale conceptului de calitate"
						},
						{
							"_nr": "2",
							"__text": "Metode statistice de analiza a calitatii obligatorii"
						},
						{
							"_nr": "3",
							"__text": "Metode statistice de analiza a calitatii necesare"
						},
						{
							"_nr": "4",
							"__text": "Metode statistice de analiza a calitatii exceptionale"
						},
						{
							"_nr": "5",
							"__text": "Metode econometrice utilizate in studiul \"calitatii optime\""
						}
					]
				},
				"_id": "925",
				"_isbn": "978-973-728-241-5"
			},
			{
				"titlu": "Metode numerice pentru ecuatii diferentiale cu aplicatii",
				"autori": {
					"autor": {
						"_nume": "Liviu Gr. Ixaru"
					}
				},
				"anAparitie": "1979",
				"editura": "Academiei Republicii Socialiste Romania",
				"tiraj": "4600",
				"numarPagini": "206",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Mocanu Petre"
				},
				"tehnoredactori": {
					"tehnoredactor": "Tudor Ionel"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_pentru_ecuatii_diferentiale_cu_aplicatii_Ixaru.jpg"
					},
					"__text": "Damian Petrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metode multipas"
						},
						{
							"_nr": "2",
							"__text": "Metode unipas"
						},
						{
							"_nr": "3",
							"__text": "Metode perturbative"
						},
						{
							"_nr": "4",
							"__text": "Probleme cu conditii la limite"
						},
						{
							"_nr": "5",
							"__text": "Rezolvarea ecuatiilor cu derivate partiale"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "926",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme logice si forme normale in calculul propoztional bivalent",
				"autori": {
					"autor": {
						"_nume": "Eugen Mihailescu"
					}
				},
				"anAparitie": "1966",
				"editura": "Academiei Republicii Socialiste Romania",
				"tiraj": "2380",
				"numarPagini": "380",
				"pretOriginal": "16",
				"redactori": {
					"redactor": "Roza Adler"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Triculescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_logice_si_forme_formale_in_calculul_propozitional_bivalent_Mihailescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme complete avand ca functor deductiv echivalenta logica"
						},
						{
							"_nr": "al II-lea",
							"__text": "Sisteme logice necomplete avand echivalenta ca functor deductiv"
						},
						{
							"_nr": "al III-lea",
							"__text": "Sisteme complete avand ca functor deductiv implicatia"
						}
					]
				},
				"_id": "927",
				"_isbn": ""
			},
			{
				"titlu": "Inferenta statistica",
				"autori": {
					"autor": [
						{
							"_nume": "George Ciucu"
						},
						{
							"_nume": "Virgil Craiu"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Didactica si pedagogica",
				"tiraj": "1780",
				"numarPagini": "488",
				"pretOriginal": "16",
				"redactori": {
					"redactor": "A. Pop"
				},
				"tehnoredactori": {
					"tehnoredactor": "Otto Necsoiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Inferenta_statistica_Ciucu.jpg"
					},
					"__text": "N. Sirbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Teoria selectiei"
						},
						{
							"_nr": "II",
							"__text": "Statistica ordinii"
						},
						{
							"_nr": "III",
							"__text": "Estimarea parametrilor"
						},
						{
							"_nr": "IV",
							"__text": "Metode verosimilitarii maxime. Metode minimului X^2. Metoda momentelor"
						},
						{
							"_nr": "V",
							"__text": "Metode celor mai mici patrate"
						},
						{
							"_nr": "VI",
							"__text": "Interval de estimare"
						},
						{
							"_nr": "VII",
							"__text": "Verificarea ipotezelor statistice"
						},
						{
							"_nr": "VIII",
							"__text": "Analiza dispersionala"
						},
						{
							"_nr": "IX",
							"__text": "Regresie"
						},
						{
							"_nr": "X",
							"__text": "Testul raportului verosimilitatii"
						},
						{
							"_nr": "XI",
							"__text": "Testul X^2"
						},
						{
							"_nr": "XII",
							"__text": "Teste pentru verificarea ipotezelor cu privire la egalitatea mediilor sau dispersiilor unei repartitii normale"
						},
						{
							"_nr": "XIII",
							"__text": "Trste Wald-Wolfowitz. Wilcoxon-Mann-Whinei si X"
						},
						{
							"_nr": "XIV",
							"__text": "Teste de tip Kolmogorov si Sminov"
						},
						{
							"_nr": "XV",
							"__text": "Analiza secventiala"
						}
					]
				},
				"_id": "928",
				"_isbn": ""
			},
			{
				"titlu": "Calculator programabil pentru invatamant MPI-16 (manual de utilizare)",
				"autori": {
					"autor": {
						"_nume": "A. Buburuzan"
					}
				},
				"anAparitie": "1975",
				"editura": "Univeristate \"Al. I. Cuza\" - Centrul de multiplicare",
				"numarPagini": "34",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Calculator_programabil_pentru_invatamant_MPI_16_Manual_de_utilizare_Buburuzan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Domeniul de utilizare"
						},
						{
							"_nr": "2",
							"__text": "Partile componente ale calculatorului si rolul lor"
						},
						{
							"_nr": "3",
							"__text": "Descrierea instructiunilor"
						},
						{
							"_nr": "4",
							"__text": "Programarea calculatorului"
						},
						{
							"_nr": "5",
							"__text": "Exercitii de programare"
						}
					]
				},
				"_id": "929",
				"_isbn": ""
			},
			{
				"titlu": "Statistica",
				"autori": {
					"autor": {
						"_nume": "Simona Ioana Ghita"
					}
				},
				"anAparitie": "2006",
				"editura": "Meteor Press",
				"numarPagini": "376",
				"pretOriginal": "0",
				"pretCumparare": "285000",
				"redactori": {
					"redactor": "Anca Lepadatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Rodica Boaca"
				},
				"coperta": {
					"imagine": {
						"_path": "Statistica_Ghita.jpg"
					},
					"__text": "Mihai Chiriacescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in studiul statisticii"
						},
						{
							"_nr": "2",
							"__text": "Culegerea si prelucrarea primara a datelor statistice"
						},
						{
							"_nr": "3",
							"__text": "Analiza descriptiva a seriilor statistice univariate"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea sondajului statistic in studiul fenomenelor econimico-sociale"
						},
						{
							"_nr": "5",
							"__text": "Analiza statistica a seriilor de date multivariate"
						},
						{
							"_nr": "6",
							"__text": "Analiza evolutie in timp a fenomenelor economico-sociale"
						},
						{
							"_nr": "7",
							"__text": "Indicii statistici"
						}
					]
				},
				"_id": "930",
				"_isbn": "978-973-728-152-4"
			},
			{
				"titlu": "Automate programabile",
				"autori": {
					"autor": [
						{
							"_nume": "Theodor Borangiu"
						},
						{
							"_nume": "Radu Dobrescu"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "342",
				"pretOriginal": "37",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Mateescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Automate_programabile_Borangiu.jpg"
					},
					"__text": "Theodor Popa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in teoria structurala a automatelor finite programabile"
						},
						{
							"_nr": "2",
							"__text": "Structura sistemelor de conducere a proceselor cu automate programabile orientate pe prelucrare de bit"
						},
						{
							"_nr": "3",
							"__text": "Seturi de instructiuni pentru automate programabile cu prelucrare de bit"
						},
						{
							"_nr": "4",
							"__text": "Structuri de automate programabile cu prelucrare de bit"
						},
						{
							"_nr": "5",
							"__text": "Principii de constructie a programelor AP cu prelucrare de bit"
						},
						{
							"_nr": "6",
							"__text": "Automate programabile algoritmice (APA)"
						},
						{
							"_nr": "7",
							"__text": "Automate programabile cu prelucrare de cuvint (APC)"
						},
						{
							"_nr": "8",
							"__text": "Siguranta in functionare a automatelor programbile"
						}
					]
				},
				"_id": "931",
				"_isbn": ""
			},
			{
				"titlu": "MIND - Microcalculator modular cu destinatie speciala si industriala - Manual de utilizare",
				"autori": {
					"autor": {
						"_nume": "[Microelectonica]"
					}
				},
				"anAparitie": "1900",
				"editura": "Microelectronica",
				"numarPagini": "96",
				"pretOriginal": "0",
				"pretCumparare": "5000",
				"coperta": {
					"imagine": {
						"_path": "MIND_Calculator_modular_cu_destinatie_speciale_si_industriale_Manual_de_utilizare_Microelectronica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "A",
							"__text": "Descriere generala"
						},
						{
							"_nr": "B",
							"__text": "Caracteristici tehnice comune modulelor MIND"
						},
						{
							"_nr": "C",
							"__text": "Magistrala STD Z80 EX CMOS"
						},
						{
							"_nr": "D",
							"__text": "Caracteristici tehnice ale modulelor MIND"
						}
					]
				},
				"_id": "932",
				"_isbn": ""
			},
			{
				"titlu": "Programarea la masinile electronice de calcul",
				"autori": {
					"autor": {
						"_nume": "C. Belea"
					}
				},
				"anAparitie": "1969",
				"editura": "Militara",
				"numarPagini": "344",
				"pretOriginal": "21",
				"redactori": {
					"redactor": "D. Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_la_masinile_electronice_de_calcul_Belea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Programarea la masinile electronice de calcul numerice si analogice"
						},
						{
							"_nr": "I",
							"__text": "Bazele aritmetice, organizarea si functionarea masinilor electronice numerice"
						},
						{
							"_nr": "II",
							"__text": "Programarea la calculatoarele electronice numerice"
						},
						{
							"_nr": "III",
							"__text": "Programarea automata"
						},
						{
							"_nr": "IV",
							"__text": "Programarea la calculatoarele elecronice analogice"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Elaborarea modelelor matematice si algoritmilor de rezolvare a problemelor la calculatoarele electronice"
						},
						{
							"_nr": "V",
							"__text": "Modelarea matematica"
						},
						{
							"_nr": "VI",
							"__text": "Metode de rezolvare a unor probleme de algebra liniara"
						},
						{
							"_nr": "VII",
							"__text": "Reprezentarea analitica, interpolarea si aproximarea functiilor"
						},
						{
							"_nr": "VIII",
							"__text": "Cateva metode si algoritmi uzuali de rezolvare a ecuatiilor neliniare. Integrarea numerica a ecuatiilor diferentiale"
						},
						{
							"_nr": "Anexe",
							"__text": "Caracteristicile generale si sisteme de intructiuni ale principalelor calculatoare construite in tara"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Caracteristicile generale si sistemul de instructiuni ale calculatorului CIFA-3"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Caracteristicile generale si sistemul de instructiuni ale calculatorului CIDA-102"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Caracteristicile generale si sistemul de instructiuni ale calculatorului MECIPT-1"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Caracteristicile generale si sistemul de instructiuni ale calculatorului MECIPT-2"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Caracteristicile generale si sistemul de instructiuni ale calculatorului DACICC-1"
						},
						{
							"_nr": "Anexa 6",
							"__text": "Caracteristicile generale si sistemul de instructiuni ale calculatorului CET-500"
						}
					]
				},
				"_id": "933",
				"_isbn": ""
			},
			{
				"titlu": "Interferente in lumea calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Gorodcov"
						},
						{
							"_nume": "Mihail Oncescu"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Stiintifica si encilopedica",
				"numarPagini": "136",
				"pretOriginal": "5.25",
				"redactori": {
					"redactor": "Gheorghe Vlasceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Interferente_in_lumea_calculatoarelor_Gorodcov.jpg"
					},
					"__text": "George Malusel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Informatia - numitorul comun al oricarei activitati umane"
						},
						{
							"_nr": "2",
							"__text": "Microprocesorul a transformat evolutia in revolutie informatica"
						},
						{
							"_nr": "3",
							"__text": "\"Remember\" sau a memora in cod-masina"
						},
						{
							"_nr": "4",
							"__text": "Robotul intre articulatii si comenzi"
						},
						{
							"_nr": "5",
							"__text": "In cautarea unei...muze"
						},
						{
							"_nr": "6",
							"__text": "Care vor fi masinile mileniului III?"
						}
					]
				},
				"_id": "934",
				"_isbn": "973-29-0016-4"
			},
			{
				"titlu": "Mai repede ca gandul",
				"autori": {
					"autor": {
						"_nume": "Edmond Nicolau"
					}
				},
				"anAparitie": "1964",
				"editura": "Tineretului",
				"tiraj": "14140",
				"numarPagini": "208",
				"pretOriginal": "4.50",
				"redactori": {
					"redactor": "A. Baltaretu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gh. Chiru"
				},
				"coperta": {
					"imagine": {
						"_path": "Mai_repede_ca_gandul_Nicolau.jpg"
					},
					"__text": "Catrici Octav"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "In loc de introducere"
						},
						{
							"_nr": "2",
							"__text": "Mai repede ca gandul"
						},
						{
							"_nr": "3",
							"__text": "Marile familii ale calculatoarelor electronice"
						},
						{
							"_nr": "4",
							"__text": "1+1=10"
						},
						{
							"_nr": "5",
							"__text": "Cum putem reprezenta semnalele in calculatoarele digitale"
						},
						{
							"_nr": "6",
							"__text": "Numere cibernetice"
						},
						{
							"_nr": "7",
							"__text": "O raza in intuneric"
						},
						{
							"_nr": "8",
							"__text": "Cea mai simpla matematica"
						},
						{
							"_nr": "9",
							"__text": "\"Legile gandirii\""
						},
						{
							"_nr": "10",
							"__text": "O idee pierduta si apoi regasita"
						},
						{
							"_nr": "11",
							"__text": "De la logica la calculatoare"
						},
						{
							"_nr": "12",
							"__text": "Scoala de la Bucuresti"
						},
						{
							"_nr": "13",
							"__text": "Anatomia unui calculator digital"
						},
						{
							"_nr": "14",
							"__text": "Memoria electronica"
						},
						{
							"_nr": "15",
							"__text": "Sa facem un program"
						},
						{
							"_nr": "16",
							"__text": "Modele"
						},
						{
							"_nr": "17",
							"__text": "Spre stele"
						},
						{
							"_nr": "18",
							"__text": "Trenul fara mecanic"
						},
						{
							"_nr": "19",
							"__text": "Sectorul energetic"
						},
						{
							"_nr": "20",
							"__text": "Uzina viitorului"
						},
						{
							"_nr": "21",
							"__text": "Noul inginer-sef al uzinei"
						},
						{
							"_nr": "22",
							"__text": "Cea mai buna planificare"
						},
						{
							"_nr": "23",
							"__text": "MECIPT traduce"
						},
						{
							"_nr": "24",
							"__text": "Q.E.D."
						},
						{
							"_nr": "25",
							"__text": "Modelul unui creier"
						},
						{
							"_nr": "26",
							"__text": "Neuronul nu este un releu"
						},
						{
							"_nr": "27",
							"__text": "Joc si strategie"
						},
						{
							"_nr": "28",
							"__text": "Intamplare si necesitate"
						},
						{
							"_nr": "29",
							"__text": "Ce este si ce nu este \"inteligenta artificiala\""
						},
						{
							"_nr": "30",
							"__text": "Cibernetica si societatea"
						}
					]
				},
				"_id": "935",
				"_isbn": ""
			},
			{
				"titlu": "Elemente de teoria probabilitatilor si statistica matematica",
				"autori": {
					"autor": {
						"_nume": "G. Ciucu"
					}
				},
				"anAparitie": "1963",
				"editura": "Didactica si pedagogica",
				"tiraj": "12000+120",
				"numarPagini": "362",
				"pretOriginal": "0",
				"pretCumparare": "150000",
				"tehnoredactori": {
					"tehnoredactor": "Prusan Tamara"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_teoria_probabilitatilor_si_statistica_matematica_Ciucu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Camp de probabilitate"
						},
						{
							"_nr": "II",
							"__text": "Legea numerelor mari. Functie cartacteristica. Legi limita"
						},
						{
							"_nr": "III",
							"__text": "Functii aletaore"
						},
						{
							"_nr": "IV",
							"__text": "Procese Markov"
						},
						{
							"_nr": "V",
							"__text": "Lanturi cu legaturi complete"
						},
						{
							"_nr": "VI",
							"__text": "Elemente de teoria asteptarii"
						},
						{
							"_nr": "VII",
							"__text": "Elemente de statistica matematica"
						}
					]
				},
				"_id": "936",
				"_isbn": ""
			},
			{
				"titlu": "Teoria transmisiunii informatiei",
				"autori": {
					"autor": {
						"_nume": "Alexandru Spataru"
					}
				},
				"anAparitie": "1983",
				"editura": "Didactica si pedagogica",
				"numarPagini": "466",
				"pretOriginal": "43",
				"redactori": {
					"redactor": "Maria Beluri"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vergilia Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_transmisiunii_informatiei_Spataru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Masura informatiei in semnalele discrete"
						},
						{
							"_nr": "3",
							"__text": "Masura informatiei in smenalele continue"
						},
						{
							"_nr": "4",
							"__text": "Codarea surselor pentru canale fara perturbatii"
						},
						{
							"_nr": "5",
							"__text": "Codarea pentru canale cu perturbatii"
						},
						{
							"_nr": "6",
							"__text": "Spatiul semnalelor"
						},
						{
							"_nr": "7",
							"__text": "Semnale aleatoare"
						},
						{
							"_nr": "8",
							"__text": "Semnale aleatoare esantionate"
						},
						{
							"_nr": "9",
							"__text": "Detectia semnalelor"
						},
						{
							"_nr": "10",
							"__text": "Estimarea paramentrilor"
						},
						{
							"_nr": "11",
							"__text": "Estimarea formei semnalului"
						},
						{
							"_nr": "12",
							"__text": "Transmisiuni in banda de baza"
						},
						{
							"_nr": "13",
							"__text": "Transmisiuni cu semnal purtator sinusoidal"
						},
						{
							"_nr": "14",
							"__text": "Compresie de date"
						},
						{
							"_nr": "15",
							"__text": "Recunoasterea formelor"
						}
					]
				},
				"_id": "937",
				"_isbn": ""
			},
			{
				"titlu": "Probleme de teoria multimilor si logica matematica",
				"autori": {
					"autor": [
						{
							"_nume": "I.A. Lavrov"
						},
						{
							"_nume": "L.L. Maksimova"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"tiraj": "17150+110 exemplare brosate",
				"numarPagini": "108",
				"pretOriginal": "3.25",
				"traducere": {
					"titluOriginal": "Zadaci po logike ngu",
					"editura": "Novosibirsk",
					"traducatori": {
						"traducator": [
							"Eugenia Metoni",
							"Constantin P. Popovici"
						]
					},
					"_an": "1970",
					"_editia": "I",
					"_limba": "rusa",
					"_tara": "U.R.S.S."
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_teoria_multimilor_si_logica_matematica_Lavrov.jpg"
					},
					"__text": "Alexandru Banu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Teoria multimilor"
						},
						{
							"_nr": "2",
							"__text": "Logica matematica"
						}
					]
				},
				"_id": "938",
				"_isbn": ""
			},
			{
				"titlu": "Calcul numeric",
				"autori": {
					"autor": [
						{
							"_nume": "Virgil Brisca"
						},
						{
							"_nume": "Bucur Ionescu"
						},
						{
							"_nume": "Gheorghe Tudor"
						}
					]
				},
				"anAparitie": "1972",
				"editura": "Didactica si pedagogica",
				"numarPagini": "100",
				"pretOriginal": "2.35",
				"redactori": {
					"redactor": "Emilian Stanescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Viorica Rogai"
				},
				"coperta": {
					"imagine": {
						"_path": "Calcul_numeric_Brisca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni de tehnici de calcul"
						},
						{
							"_nr": "II",
							"__text": "Metode aproximative pentru rezolvarea ecuatiilor"
						},
						{
							"_nr": "III",
							"__text": "Erori inevitabile in calculul valorilor functiilor de o singura variabila"
						},
						{
							"_nr": "IV",
							"__text": "Interpolarea"
						}
					]
				},
				"_id": "939",
				"_isbn": ""
			},
			{
				"titlu": "Analiza numerica",
				"autori": {
					"autor": {
						"_nume": "I. Cuculescu"
					}
				},
				"anAparitie": "1967",
				"editura": "Tehnica",
				"tiraj": "3200+140",
				"numarPagini": "320",
				"pretOriginal": "11",
				"redactori": {
					"redactor": "Lina Ticos"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Analiza_numerica_Cuculescu.jpg"
					},
					"__text": "V. Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Rezolvarea ecuatiilor cu o necunoascuta"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Sisteme de ecuatii liniare"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Aproximarea functiilor"
						}
					]
				},
				"_id": "940",
				"_isbn": ""
			},
			{
				"titlu": "Optimizarea sistemelor",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Ionescu"
						},
						{
							"_nume": "Corneliu Popeea"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Didactica si pedagogica",
				"numarPagini": "288",
				"pretOriginal": "8.30",
				"redactori": {
					"redactor": "Maria Beluri"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vergelia Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Optimizarea_sistemelor_Ionescu.jpg"
					},
					"__text": "V. Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Proces. Sistem. Optimizare"
						},
						{
							"_nr": "2",
							"__text": "Optimizarea cu criteriu patratic a sistemelor liniare"
						},
						{
							"_nr": "3",
							"__text": "Proceduri de rezolvare numerica a problemelor de programare matemeatica"
						},
						{
							"_nr": "4",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "5",
							"__text": "Principiul minimului"
						},
						{
							"_nr": "6",
							"__text": "Probleme tipice de conducere optimala"
						},
						{
							"_nr": "7",
							"__text": "Proceduri de rezolvare numerica a problemelor de conducere optimala"
						}
					]
				},
				"_id": "941",
				"_isbn": ""
			},
			{
				"titlu": "Din spectacolul matematicii",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Paun"
					}
				},
				"anAparitie": "1983",
				"editura": "Albatros",
				"numarPagini": "240",
				"pretOriginal": "6",
				"tehnoredactori": {
					"tehnoredactor": "Gabriela Iliopolos"
				},
				"coperta": {
					"imagine": {
						"_path": "Din_spectacolul_matematicii_Paun.jpg"
					},
					"__text": "Dan Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cuvant inainte"
						},
						{
							"_nr": "2",
							"__text": "Ce este matematica?"
						},
						{
							"_nr": "3",
							"__text": "Despre limitele matematicii"
						},
						{
							"_nr": "4",
							"__text": "De la paradoxul marchizului de la Condorcet la teorema lui Arrow"
						},
						{
							"_nr": "5",
							"__text": "Preliminarii la o paralela necesara: matematica si literatura SF"
						},
						{
							"_nr": "6",
							"__text": "O teorema cu demonstratia in limba romana: teorema de imposibilitate a agregarii indicatorilor"
						},
						{
							"_nr": "7",
							"__text": "Formalizarea aritmeticii este inevitabil incompleta: Teoremele lui Godel"
						},
						{
							"_nr": "8",
							"__text": "O problema celebra cu o rezolvare neobisnuita: problema celor patru culori"
						},
						{
							"_nr": "9",
							"__text": "Ce pot si ce nu pot algoritmii"
						},
						{
							"_nr": "10",
							"__text": "Despre adevarul \"adevarurilor evidente\""
						},
						{
							"_nr": "11",
							"__text": "Sfaturi pentru un tanar rezolvator de probleme"
						},
						{
							"_nr": "12",
							"__text": "De la gramatica limbii la gramatica proceselor economice"
						},
						{
							"_nr": "13",
							"__text": "Teoria catastrofelor intre entuziasm si contestare"
						},
						{
							"_nr": "14",
							"__text": "Cate puncte are un patrat"
						},
						{
							"_nr": "15",
							"__text": "Problemele lui Hilbert si ecoul lor in matematica"
						},
						{
							"_nr": "16",
							"__text": "Completitudinea matematicianului"
						},
						{
							"_nr": "17",
							"__text": "Ce se poate face cu un polinom?"
						},
						{
							"_nr": "18",
							"__text": "Gramatica creierului"
						},
						{
							"_nr": "19",
							"__text": "Lantul mincinosilor sau despre logica - model al gandirii"
						},
						{
							"_nr": "20",
							"__text": "Anticamera matematicii - problemele de la logica duminicala"
						},
						{
							"_nr": "21",
							"__text": "Probleme \"ciudate\", teoreme \"ciudate\""
						},
						{
							"_nr": "22",
							"__text": "Misterioasele numere prime"
						},
						{
							"_nr": "23",
							"__text": "Corpuri geometice care \"nu pot exista\": banda lui Moebius si sticla lui Klein"
						},
						{
							"_nr": "24",
							"__text": "Matematica si criptologia"
						},
						{
							"_nr": "25",
							"__text": "Ce este...?"
						},
						{
							"_nr": "25.1",
							"__text": "...teoria multimilor fuzzy"
						},
						{
							"_nr": "25.2",
							"__text": "...teoria grafurilor"
						},
						{
							"_nr": "25.3",
							"__text": "...analiza non-standard"
						},
						{
							"_nr": "25.4",
							"__text": "...topologia"
						},
						{
							"_nr": "26",
							"__text": "Matemetica-ecuson si capcanele ei"
						},
						{
							"_nr": "27",
							"__text": "Calculatoare sau creiere electronice"
						},
						{
							"_nr": "28",
							"__text": "Matematicienii si nematematicienii despre matematica"
						},
						{
							"_nr": "29",
							"__text": "incheiere"
						},
						{
							"_nr": "30",
							"__text": "Bibiliografie"
						}
					]
				},
				"_id": "942",
				"_isbn": ""
			},
			{
				"titlu": "Elemente de teoria multimilor si algebra moderna (Vol.I)",
				"autori": {
					"autor": [
						{
							"_nume": "A. Kaumann"
						},
						{
							"_nume": "M. Precigout"
						}
					]
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "11400+110 exemplare brosate",
				"numarPagini": "222",
				"pretOriginal": "10.50",
				"traducere": {
					"titluOriginal": "Cours de matematiques nouvelles pour le recyclage des ingenieurs et cadres",
					"editura": "Dunod",
					"traducatori": {
						"traducator": "Gheorghe Gussi"
					},
					"_an": "1966",
					"_editia": "I",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_teoria_multimilor_si_algebra_moderna_Vol_I_Kaufmann.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiunea de multime"
						},
						{
							"_nr": "2",
							"__text": "Operatii booleene pe multimea partilor unei multimi"
						},
						{
							"_nr": "3",
							"__text": "Relatii dintre multimi"
						},
						{
							"_nr": "4",
							"__text": "Corespondente"
						},
						{
							"_nr": "5",
							"__text": "Relatii de echivalenta intr-o multime"
						},
						{
							"_nr": "5",
							"__text": "Structurile algebrice. Grupuri"
						},
						{
							"_nr": "7",
							"__text": "Inele si grupuri"
						}
					]
				},
				"_id": "943",
				"_isbn": ""
			},
			{
				"titlu": "Elemente de teoria multimilor si algebra moderna (Vol.II)",
				"autori": {
					"autor": [
						{
							"_nume": "A. Kaumann"
						},
						{
							"_nume": "M. Precigout"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Tehnica",
				"tiraj": "11400+110 exemplare brosate",
				"numarPagini": "240",
				"pretOriginal": "10",
				"traducere": {
					"titluOriginal": "Cours de matematiques nouvelles pour le recyclage des ingenieurs et cadres",
					"editura": "Dunod",
					"traducatori": {
						"traducator": "Gheorghe Gussi"
					},
					"_an": "1966",
					"_editia": "I",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_teoria_multimilor_si_algebra_moderna_Vol_II_Kaufmann.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de logica matematica"
						},
						{
							"_nr": "2",
							"__text": "Relatii de ordine intr-o multime"
						},
						{
							"_nr": "3",
							"__text": "Multimi bine ordonate"
						},
						{
							"_nr": "4",
							"__text": "Multimi infinite"
						},
						{
							"_nr": "5",
							"__text": "Latice"
						},
						{
							"_nr": "6",
							"__text": "Algebre booleene"
						}
					]
				},
				"_id": "944",
				"_isbn": ""
			},
			{
				"titlu": "Masini matematice moderne",
				"autori": {
					"autor": {
						"_nume": "A. D. Smirnov"
					}
				},
				"anAparitie": "1960",
				"editura": "Tehnica",
				"numarPagini": "116",
				"pretOriginal": "2.80",
				"traducere": {
					"titluOriginal": "Sovremennaie matematiceskie masinai",
					"editura": "Moskva",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1959",
					"_editia": "I",
					"_limba": "rusa",
					"_tara": "U.R.S.S."
				},
				"redactori": {
					"redactor": "M. Varia"
				},
				"tehnoredactori": {
					"tehnoredactor": "I. Logan"
				},
				"coperta": {
					"imagine": {
						"_path": "Masini_matematice_moderne_Smirnov.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Masinile analogice"
						},
						{
							"_nr": "II",
							"__text": "Masini electronice cifrice"
						},
						{
							"_nr": "III",
							"__text": "Utilizarea masinilor electronice"
						}
					]
				},
				"_id": "945",
				"_isbn": ""
			},
			{
				"titlu": "Aplicatiile emasinilor electronice de calcul",
				"autori": {
					"autor": [
						{
							"_nume": "Samson Schacheter"
						},
						{
							"_nume": "Florin Munteanu"
						}
					]
				},
				"anAparitie": "1960",
				"editura": "Tehnica",
				"numarPagini": "116",
				"pretOriginal": "3.35",
				"redactori": {
					"redactor": "M. Varia"
				},
				"tehnoredactori": {
					"tehnoredactor": "E. Gorun"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatiile_masinilor_electronice_de_calcul_Schachter.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Principiile functional-constructive ale masinilor electronice de calcul"
						},
						{
							"_nr": "II",
							"__text": "Principii de utilizare a masinilor electronice de calcul"
						},
						{
							"_nr": "III",
							"__text": "Aplicatii industriale ale masinilor electronice de calcul"
						},
						{
							"_nr": "IV",
							"__text": "Perspectivele de dezvoltare si de utilizare a masinilor electronice de calcul"
						}
					]
				},
				"_id": "946",
				"_isbn": ""
			},
			{
				"titlu": "Programare orientata pe obiecte in limbajul Java",
				"autori": {
					"autor": [
						{
							"_nume": "Cristina Marinescu"
						},
						{
							"_nume": "Petru Florin Mihancea"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Politehnica",
				"numarPagini": "200",
				"pretOriginal": "0",
				"pretCumparare": "220000",
				"redactori": {
					"redactor": "Claudia Mihali"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_orientata_pe_obiecte_in_limbajul_Java_Marinescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in programarea oriectata pe obiecte"
						},
						{
							"_nr": "2",
							"__text": "Clase si Obiecte"
						},
						{
							"_nr": "3",
							"__text": "Transmiterea mesajelor"
						},
						{
							"_nr": "4",
							"__text": "Cateva clase Java predefinite"
						},
						{
							"_nr": "5",
							"__text": "Relatia de mostenire"
						},
						{
							"_nr": "6",
							"__text": "Polimorfismul"
						},
						{
							"_nr": "7",
							"__text": "Interfete"
						},
						{
							"_nr": "8",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "9",
							"__text": "Pachete"
						},
						{
							"_nr": "10",
							"__text": "Colectii de obiecte"
						},
						{
							"_nr": "11",
							"__text": "Elemente de programare concurenta"
						},
						{
							"_nr": "12",
							"__text": "Probleme frecvente in cod. Javadoc. Jar"
						}
					]
				},
				"_id": "947",
				"_isbn": "978-973-625-306-5"
			},
			{
				"titlu": "Introducere in programarea oriectata-obiect: Concepte din perspectiva ingineriei software",
				"autori": {
					"autor": [
						{
							"_nume": "Mircea Cezar Preda"
						},
						{
							"_nume": "Ana-Maria Mirea"
						},
						{
							"_nume": "Doina Lavinia Preda"
						},
						{
							"_nume": "Constantin Teodorescu-Mihai"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Polirom",
				"numarPagini": "280",
				"pretOriginal": "279500",
				"pretCumparare": "279500",
				"redactori": {
					"redactor": "Catalina Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programnarea_orientata_obiect_Concepte_fundamentale_din_perspectiva_ingineriei_software_Preda.jpg"
					},
					"__text": "Laurentiu Midvichi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Obiectural vs procedural"
						},
						{
							"_nr": "2",
							"__text": "Clase si obiecte"
						},
						{
							"_nr": "3",
							"__text": "Initializarea si distrigerea obiectelor"
						},
						{
							"_nr": "4",
							"__text": "Reutilizarea codului-sursa"
						},
						{
							"_nr": "5",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "6",
							"__text": "Sabloane de proiectare"
						},
						{
							"_nr": "7",
							"__text": "Dezvoltarea orientata-obiect a aplicatiilor"
						},
						{
							"_nr": "Anexa",
							"__text": "Dezvoltarea aplicatiilor C++ utilizand mediul de dezvoltare Microsoft Visual C++"
						}
					]
				},
				"_id": "948",
				"_isbn": "978-973-46-1629-9"
			},
			{
				"titlu": "Introducere in modelarea fuzzy",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Vaduva"
						},
						{
							"_nume": "Grigore Albeanu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "160",
				"pretOriginal": "142000",
				"pretCumparare": "142000",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_modelarea_fuzzy_Vaduva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "(Sub)multimi fuzzy"
						},
						{
							"_nr": "2",
							"__text": "Operatii cu (sub)multimi fuzzy"
						},
						{
							"_nr": "3",
							"__text": "Relatii fuzzy"
						},
						{
							"_nr": "4",
							"__text": "Elemente de logica fuzzy"
						},
						{
							"_nr": "5",
							"__text": "Reguli fuzzy"
						},
						{
							"_nr": "6",
							"__text": "Sisteme fuzzy"
						},
						{
							"_nr": "7",
							"__text": "Clasificare fuzzy"
						},
						{
							"_nr": "8",
							"__text": "Decizii fuzzy"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "949",
				"_isbn": "973-575-833-4"
			},
			{
				"titlu": "Introducere in procesarea limbajului natural",
				"autori": {
					"autor": {
						"_nume": "Florentina Hristea"
					}
				},
				"anAparitie": "2010",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "336",
				"pretOriginal": "410000",
				"pretCumparare": "410000",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_procesarea_limbajului_natural_Hristea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Consideratii preliminare"
						},
						{
							"_nr": "2",
							"__text": "Tehnici cu stari finite"
						},
						{
							"_nr": "3",
							"__text": "Gramatici"
						},
						{
							"_nr": "4",
							"__text": "Analiza sintactica bazata pe constituenti"
						},
						{
							"_nr": "5",
							"__text": "Caracteristici si gramatici augmentate. Gramatici de unificare"
						},
						{
							"_nr": "6",
							"__text": "Elemente de semantica computationala"
						},
						{
							"_nr": "7",
							"__text": "Reteaua semantica WordNet. O aplicatie in dezambiguizarea automata a sensului cuvintelor"
						},
						{
							"_nr": "8",
							"__text": "Dezambiguizarea automata a sensului cuvintelor la granita dintre tehicile nesupervizate si cele bazate pe cunostinte"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Limbajul de baza al formei logice"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Programe"
						}
					]
				},
				"_id": "950",
				"_isbn": "978-973-737-719-7"
			},
			{
				"titlu": "Matematici asistate de calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Pavel Naslau"
						},
						{
							"_nume": "Romeo Negrea"
						},
						{
							"_nume": "Liviu Cadariu"
						},
						{
							"_nume": "Bogdan Caruntu"
						},
						{
							"_nume": "Dan Popescu"
						},
						{
							"_nume": "Monica Balmez"
						},
						{
							"_nume": "Constantin Dumitrascu"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Politehnica",
				"tiraj": "200",
				"numarPagini": "754",
				"pretOriginal": "350000",
				"pretCumparare": "545500",
				"colectia": {
					"_numarul": "0",
					"_nume": "Student"
				},
				"redactori": {
					"redactor": "Claudia Mihali"
				},
				"coperta": {
					"imagine": {
						"_path": "Matematici_asistate_de_calculator_Naslau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Softuri matematice"
						},
						{
							"_nr": "2",
							"__text": "Calcul simbolic si calcul numeric"
						},
						{
							"_nr": "3",
							"__text": "Rezolvarea sistemelor liniare"
						},
						{
							"_nr": "4",
							"__text": "Determinarea valorilor proprii si a vectorilor proprii"
						},
						{
							"_nr": "5",
							"__text": "Rezolvarea ecuatiilor si sistemelor de ecuatii neliniare"
						},
						{
							"_nr": "6",
							"__text": "Interpolare sau aproximare polinomiala"
						},
						{
							"_nr": "7",
							"__text": "Functii spline"
						},
						{
							"_nr": "8",
							"__text": "Ecuatii diferentiale"
						},
						{
							"_nr": "9",
							"__text": "Transformari integrale"
						},
						{
							"_nr": "10",
							"__text": "Ecuatii cu derivate partiale de ordinul al doilea"
						},
						{
							"_nr": "11",
							"__text": "Elemente de teoria probabilitatilor si statistica matematica"
						},
						{
							"_nr": "12",
							"__text": "Curbe si suprafete Bezier"
						}
					]
				},
				"_id": "951",
				"_isbn": "973-625-234-5"
			},
			{
				"titlu": "Tehnici de inteligenta artificiala in conducerea sistemelor elecroenergetice",
				"autori": {
					"autor": [
						{
							"_nume": "Mircea Eremia"
						},
						{
							"_nume": "Geheorghe Cartina"
						},
						{
							"_nume": "Dragos Petricica"
						},
						{
							"_nume": "Adrian-Ion Bulac"
						},
						{
							"_nume": "Constantin Bulac"
						},
						{
							"_nume": "Ion Tristiu"
						},
						{
							"_nume": "Gheroghe Grigoras"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Agir",
				"numarPagini": "356",
				"pretOriginal": "156000",
				"pretCumparare": "156000",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_inteligenta_artificiala_in_condurerea_sistemelor_electroenergetice_Eremia.jpg"
					},
					"__text": "Ion Marin"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "3",
							"__text": "Arbori de decizie"
						},
						{
							"_nr": "4",
							"__text": "Retele neuronale artificiale"
						},
						{
							"_nr": "5",
							"__text": "Logica fuzzy"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi genetici"
						},
						{
							"_nr": "7",
							"__text": "Agenti inteligenti"
						},
						{
							"_nr": "8",
							"__text": "Invatare prin stimulare"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii ale tehnologiilor hibride"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Logica formala"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Mediul de programare CLIPS"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Metode de invatare prin stimulare"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Datele retelei Test IEEE 30"
						}
					]
				},
				"_id": "952",
				"_isbn": "973-720-106-X"
			},
			{
				"titlu": "Modele probabiliste si statistice in stiinta si ingineria calculatoarelor",
				"autori": {
					"autor": {
						"_nume": "Emilia Petrisor"
					}
				},
				"anAparitie": "2008",
				"editura": "Politehnica",
				"numarPagini": "390",
				"pretOriginal": "467600",
				"pretCumparare": "467600",
				"colectia": {
					"_numarul": "0",
					"_nume": "Student"
				},
				"redactori": {
					"redactor": "Claudia Mihali"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_probabilistice_si_statistice_in_stiinta_calculatoarelor_Petrisor.jpg"
					},
					"__text": "Mariana Gavrilescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere. Evenimente si probabilitati"
						},
						{
							"_nr": "2",
							"__text": "Variabile aleatoare discrete"
						},
						{
							"_nr": "3",
							"__text": "Variabile aleatoare continue"
						},
						{
							"_nr": "4",
							"__text": "Valori aleatori continui"
						},
						{
							"_nr": "5",
							"__text": "Simularea variabilelor aleatoare"
						},
						{
							"_nr": "6",
							"__text": "Modele probabiliste bazate pe procese stochastice"
						},
						{
							"_nr": "7",
							"__text": "Elemente de statistica si masini instruibile"
						}
					]
				},
				"_id": "953",
				"_isbn": "978-973-625-791-9"
			},
			{
				"titlu": "Simulare Monte Carlo",
				"autori": {
					"autor": {
						"_nume": "Emilia Petrisor"
					}
				},
				"anAparitie": "2006",
				"editura": "Politehnica",
				"numarPagini": "170",
				"pretOriginal": "265000",
				"pretCumparare": "265000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Student"
				},
				"redactori": {
					"redactor": "Claudia Mihali"
				},
				"coperta": {
					"imagine": {
						"_path": "Simulare_Monte_Carlo_Petrisor.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de teoria probabilitatilor"
						},
						{
							"_nr": "2",
							"__text": "Simularea variabilelor alatoare uniform distribuite"
						},
						{
							"_nr": "3",
							"__text": "Metode de simulare a variabilelor aleatoare ne-uniform distribuite"
						},
						{
							"_nr": "4",
							"__text": "Simularea proceselor stochastice"
						},
						{
							"_nr": "5",
							"__text": "Metode Monte Carlo"
						},
						{
							"_nr": "6",
							"__text": "Lanturi Markov in timp discret"
						},
						{
							"_nr": "7",
							"__text": "Metode Monte Carlo bazate pe lanturi Markov"
						}
					]
				},
				"_id": "954",
				"_isbn": "978-973-625-374-4"
			},
			{
				"titlu": "Crearea paginilor Web",
				"autori": {
					"autor": {
						"_nume": "Ned Snell"
					}
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "363",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Teacg Yourself to Create Web Pages in 24 Hours",
					"editura": "SAMS Publishing",
					"traducatori": {
						"traducator": "Marius Moldovan"
					},
					"_an": "1999",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Marius Stuparu"
				},
				"coperta": {
					"imagine": {
						"_path": "Crearea_paginilor_Web_Snell.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Primii pasi, prima pagina Web"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Titluri, text si tabele"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Referirea resurselor"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Insufletirea paginilor cu elemente multimedia"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Finisarea paginilor"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Plasarea online"
						}
					]
				},
				"_id": "955",
				"_isbn": "973-20-0512-2"
			},
			{
				"titlu": "Tehnologii pe programare a aplicatiilor internet cu baze de date",
				"autori": {
					"autor": {
						"_nume": "Ioan Filip"
					}
				},
				"anAparitie": "2003",
				"editura": "Orizonturi universitare",
				"numarPagini": "188",
				"pretOriginal": "0",
				"pretCumparare": "119900",
				"tehnoredactori": {
					"tehnoredactor": "Ioan Filip"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_de_programare_a_aplicatiilor_internet_cu_baze_de_date_Filip.jpg"
					},
					"__text": "Ioana Banciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Dezvoltarea paginilor Web utilizand HTML"
						},
						{
							"_nr": "2",
							"__text": "Baze de date relationale - InterBase"
						},
						{
							"_nr": "3",
							"__text": "Baze de date relationale - MySQL"
						},
						{
							"_nr": "4",
							"__text": "Limbajul PHP"
						},
						{
							"_nr": "5",
							"__text": "Lucrul cu baze de date utilizand tehnologia Internet Database Connector"
						}
					]
				},
				"_id": "956",
				"_isbn": "973-638-016-5"
			},
			{
				"titlu": "Baze de date relationale si orientate pe obiecte",
				"autori": {
					"autor": {
						"_nume": "Dan A. Pescaru"
					}
				},
				"anAparitie": "2001",
				"editura": "Politehnica",
				"numarPagini": "154",
				"pretOriginal": "132500",
				"pretCumparare": "132500",
				"colectia": {
					"_numarul": "0",
					"_nume": "Informatica"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_relationale_si_orientate_pe_obiecte_Percaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Interfata sistemului Visual dBase. Fisiere de date"
						},
						{
							"_nr": "2",
							"__text": "Limbajul dBase. Expresii. Operatori. Functii"
						},
						{
							"_nr": "3",
							"__text": "Consulatarea Bazelor de date. Instructiuni de cilare"
						},
						{
							"_nr": "4",
							"__text": "Sortarea si indexarea tabelelor. Crearea Interogarilor"
						},
						{
							"_nr": "5",
							"__text": "Elemente de programare orientata pe obiecte in Visual dBase"
						},
						{
							"_nr": "6",
							"__text": "Generararea de ferestre tip formular"
						},
						{
							"_nr": "7",
							"__text": "Crearea unor forumare complexe"
						},
						{
							"_nr": "8",
							"__text": "Crearea controalor personalizate si a meniurilor"
						},
						{
							"_nr": "9",
							"__text": "Generarea Rapoartelor si Etichetelor"
						},
						{
							"_nr": "10",
							"__text": "Comenzi speciale. Depanarea programelor dBase"
						},
						{
							"_nr": "11",
							"__text": "Implementarea structurilor ierarhice"
						},
						{
							"_nr": "12",
							"__text": "Implementarea structurilor de tip retea"
						},
						{
							"_nr": "13",
							"__text": "Modelul Relational. Limbajul SQL. Visual dBase Local SQL"
						},
						{
							"_nr": "14",
							"__text": "Limbajuk PL-SQL"
						},
						{
							"_nr": "15",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "957",
				"_isbn": "973-8247-53-5"
			},
			{
				"titlu": "Introducere in proiectarea algoritmilor paraleli",
				"autori": {
					"autor": {
						"_nume": "Cornelius Croitoru"
					}
				},
				"anAparitie": "2002",
				"editura": "MatrixRom",
				"numarPagini": "222",
				"pretOriginal": "0",
				"pretCumparare": "171000",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_proiectarea_algoritmilor_paraleli_Croitoru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Modele ale calculului paralel"
						},
						{
							"_nr": "2",
							"__text": "Tehnici de baza in proiectarea algoritmilor PRAM"
						},
						{
							"_nr": "3",
							"__text": "Procesari paralele pe grafuri"
						},
						{
							"_nr": "4",
							"__text": "Elemente de complexitate paralela"
						}
					]
				},
				"_id": "958",
				"_isbn": "973-685-412-4"
			},
			{
				"titlu": "Programare concurenta. Mecanisme suport orientate timp real",
				"autori": {
					"autor": {
						"_nume": "Nicolae Robu"
					}
				},
				"anAparitie": "2009",
				"editura": "Politehnica",
				"tiraj": "100",
				"numarPagini": "256",
				"pretOriginal": "200000",
				"pretCumparare": "312000",
				"redactori": {
					"redactor": "Claudia Mihali"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicolae Robu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_concurenta_Mecanisme_suport_orientate_timp_real_Robu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Considratii introductive"
						},
						{
							"_nr": "2",
							"__text": "Starile takurilor si evolutia in spatiul starilor"
						},
						{
							"_nr": "3",
							"__text": "Comutarea taskurilor"
						},
						{
							"_nr": "4",
							"__text": "Dispecerizarea taskurilor"
						},
						{
							"_nr": "5",
							"__text": "Stabilirea perioadei de comutare a taskurilor"
						},
						{
							"_nr": "6",
							"__text": "Conflicte potentiale in sitemele multitasking si solutii de depasire a lor. Excluderea mutuala"
						},
						{
							"_nr": "7",
							"__text": "Sincronizarea taskurilor"
						},
						{
							"_nr": "8",
							"__text": "Comunicarea intre taskuri"
						}
					]
				},
				"_id": "959",
				"_isbn": "973-625-047-4"
			},
			{
				"titlu": "Aplicatii distribuite",
				"autori": {
					"autor": [
						{
							"_nume": "Radu Dobrescu"
						},
						{
							"_nume": "Matei Dobrescu"
						},
						{
							"_nume": "Henri-George Coanda"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Bibliotheca",
				"numarPagini": "164",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Henri-George Coanda"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_distribuite_Dobrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in domeniul sistemelor distribuite"
						},
						{
							"_nr": "2",
							"__text": "Aplicatii distribuite la nivel proces"
						},
						{
							"_nr": "3",
							"__text": "Interconectarea unitatilor de procesare in sisteme distribuite"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii distribuite in siteme integrate industriale"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii distribuite in mediul INTERNET"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii multimedia distribuite"
						},
						{
							"_nr": "7",
							"__text": "Principii de proiectare a structurilor de procesare paralela"
						}
					]
				},
				"_id": "960",
				"_isbn": "973-8413-07-9"
			},
			{
				"titlu": "Elemente de inteligenta artificiala si Prolog : teorie si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Petre Anghelescu"
					}
				},
				"anAparitie": "2010",
				"editura": "MatrixRom",
				"numarPagini": "136",
				"pretOriginal": "200000",
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_inteligenta_artificiala_si_Prolog_Teorie_si_Aplicatii_Anghelescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programarea aplicatiilor bazate pe cunostinte utilizand limbajul SWI-Prolog"
						},
						{
							"_nr": "2",
							"__text": "Module si predicate predefinite pentru prelucrarea obiectelor SWI-Prolog"
						},
						{
							"_nr": "3",
							"__text": "Reprezentarea si prelucrarea listelor si arborilor in SWI-Prolog"
						},
						{
							"_nr": "4",
							"__text": "Logica cu predicate de ordinul I si SWI-Prolog"
						},
						{
							"_nr": "5",
							"__text": "Reprezentarea sistemelor cu IA bazate pe cunostinte in SWI-Prolog"
						},
						{
							"_nr": "6",
							"__text": "Implementarea de sisteme cu IA bazate pe cunostinte in SWI-Prolog"
						}
					]
				},
				"_id": "961",
				"_isbn": "978-973-755-561-8"
			},
			{
				"titlu": "Informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "C. Apostol"
						},
						{
							"_nume": "C. Baron"
						},
						{
							"_nume": "I. Bucur"
						},
						{
							"_nume": "St. Dan"
						},
						{
							"_nume": "A. Danilov"
						},
						{
							"_nume": "I. Ivan"
						},
						{
							"_nume": "Rodica Mihalea"
						},
						{
							"_nume": "P. Nastase"
						},
						{
							"_nume": "I. Odagescu"
						},
						{
							"_nume": "Fl. Pilat"
						},
						{
							"_nume": "I. Rosea"
						},
						{
							"_nume": "V. Rosea"
						},
						{
							"_nume": "Gh. Sabau"
						},
						{
							"_nume": "Stefania Scheianu"
						},
						{
							"_nume": "Gh. Sofronie"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "604",
				"pretOriginal": "68",
				"redactori": {
					"redactor": "Gheorghe Vlasceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Dodescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme informationale. Sisteme informatice"
						},
						{
							"_nr": "2",
							"__text": "Modele. Modelare. Algoritmi numerice"
						},
						{
							"_nr": "3",
							"__text": "Structuri de date"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi de prelucrare"
						},
						{
							"_nr": "5",
							"__text": "Trasmisia si codificarea informatiei"
						},
						{
							"_nr": "6",
							"__text": "Bazele aritmetice si logice ale sistemelor de calcul"
						},
						{
							"_nr": "7",
							"__text": "Calculatoare, minicalculatoare, microprocesoare, microcalculatoare si microcalculatoare personale"
						},
						{
							"_nr": "8",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "9",
							"__text": "Modele si algoritmi privind gestiunea resurselor unui sistem de calcul"
						},
						{
							"_nr": "10",
							"__text": "Tehnici de exploatare a sistemelor de calcul"
						},
						{
							"_nr": "11",
							"__text": "Limbaje de programare - Elemente de definire formala si analiza comparata"
						},
						{
							"_nr": "12",
							"__text": "Tehnica programarii"
						},
						{
							"_nr": "13",
							"__text": "Programe utilitare si programe aplicative"
						},
						{
							"_nr": "14",
							"__text": "Metode si tehnice de simulare a proceselor"
						},
						{
							"_nr": "15",
							"__text": "Baze si banci de date"
						},
						{
							"_nr": "16",
							"__text": "Teleprelucrarea informatiei"
						},
						{
							"_nr": "17",
							"__text": "Sisteme expert"
						}
					]
				},
				"_id": "962",
				"_isbn": ""
			},
			{
				"titlu": "HC 91 - Manual de prezentare",
				"autori": {
					"autor": {
						"_nume": "[ICE FELIX]"
					}
				},
				"anAparitie": "1991",
				"editura": "ICE FELIX",
				"numarPagini": "50",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "HC91_Manual_de_utilizare_ICE_FELIX.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Elemente de programare si editare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Basic"
						}
					]
				},
				"_id": "963",
				"_isbn": ""
			},
			{
				"titlu": "Calculator pentru instruire 03 - Manual de utilizare",
				"autori": {
					"autor": {
						"_nume": "[ELECTRONICA]"
					}
				},
				"anAparitie": "1988",
				"editura": "ELECTRONICA",
				"numarPagini": "58",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Calculator_pentru_instruire_03_Manual_de_utilizare_Electronica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "0",
							"__text": "Instalare si punere in functiune"
						},
						{
							"_nr": "1",
							"__text": "Structura CIP-ului"
						},
						{
							"_nr": "2",
							"__text": "Aplicatie practica AP1 / Literee mari/Litere mici / Videonormal/Videoinvers"
						},
						{
							"_nr": "3",
							"__text": "Cum comunicam cu CIP-ul/ Programe in limbah BASCIC/Aplicatie practica AP3"
						},
						{
							"_nr": "4",
							"__text": "Operatii, operatori, constante, variabile, expresii/Aplicatie practica AP4/Operatori/Constante numerice/Constante sir de caractere/Variabile sir de caractere/Notatia exponentiala a numerelor/Operatori relationali/Operatori logici/Alipirea sirurilor"
						},
						{
							"_nr": "5",
							"__text": "Intrari, iesiri, aplicatii simple/LET/INPUT/READ/DATA/RESTORE/PRINT/PRINT AT/Macheta ecranului/PRINT TAB/REM/Salvarea programelor pe caseta"
						},
						{
							"_nr": "6",
							"__text": "Conditii sau alternative - IF ... THEN; GO TO/GO TO/IF ... THEN"
						},
						{
							"_nr": "7",
							"__text": "Cum se pot repeta parti de program / Aplicatie practica AP7/FOR ... NEXT"
						},
						{
							"_nr": "8",
							"__text": "Colectii de date/DIM/GO SUB/RETURN/Aplicatie practica AP8/LISTA/MATRICE/DIM/Subrutine/GO SUB/RETURN"
						},
						{
							"_nr": "9",
							"__text": "Sa desenam cu CIP-ul/Aplicatie practica AP9/INK, PAPER/BORDER/PLOT/DRAW/CIRCLE/RND/RAND/Caractere grafice standard/Caractere grafice speciale/Animatia pe ecran"
						},
						{
							"_nr": "10",
							"__text": "Sa introducem sunete/Aplicatie practica AP10/Codificarea notelor/Schimbarea octavei/Schimbarea duratei/Schimbarea ritmului"
						},
						{
							"_nr": "11",
							"__text": "Functii/Aplicatie practica AP8/SIN, COS,TAN,ASN,SCS,ATN/PI/ABS/SGN/INT/SQR/EXP/LN/BIN xxxxxxx/POINT/LEN/STR$/VAL/VAL$/CHR$/CEDE/INKEY$/SCREEN$/DEF FN/FN"
						},
						{
							"_nr": "12",
							"__text": "Memeria CIP-ului/Aplicatie practica AP12/RAM VIDEO/Variabile de sistem/CLEAR/NEW/LINIE BASIC/Cod masina/RANDOMISE USR/SAVE CODE/LOAD CODE/OUT/IN"
						}
					]
				},
				"_id": "964",
				"_isbn": ""
			},
			{
				"titlu": "Din spectacolul informaticii - calculatorul personal",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Ion Odagescu"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Militara",
				"numarPagini": "208",
				"pretOriginal": "130",
				"redactori": {
					"redactor": "Al. Mihalcea"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Bodea"
				},
				"coperta": {
					"imagine": {
						"_path": "Din_spectacolul_informaticii_calculatorului_personal_Diamandi.jpg"
					},
					"__text": "Victor Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Actualitati si perspective"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi de soertare. Cautare si memorare"
						},
						{
							"_nr": "3",
							"__text": "Programe bazate pe recurenta si pe metoda celor mai mici patrate"
						},
						{
							"_nr": "4",
							"__text": "Instruire asistata de calculator"
						},
						{
							"_nr": "5",
							"__text": "Programe utilitare si grafica"
						},
						{
							"_nr": "6",
							"__text": "Programe in stil LOGO"
						},
						{
							"_nr": "7",
							"__text": "Produse program specifice microcalculatoarelor"
						},
						{
							"_nr": "8",
							"__text": "Programe posibile"
						},
						{
							"_nr": "9",
							"__text": "Tendinte"
						}
					]
				},
				"_id": "965",
				"_isbn": "973-32-0178-2"
			},
			{
				"titlu": "Un PC pentru fiecare",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Carstea"
						},
						{
							"_nume": "Ion Diamandi"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Getic",
				"numarPagini": "128",
				"pretOriginal": "596",
				"coperta": {
					"imagine": {
						"_path": "Un_Pc_pentru_fiecare_Diamandi.jpg"
					},
					"__text": "Iolanda Malamen"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Hardware"
						},
						{
							"_nr": "2",
							"__text": "Software"
						}
					]
				},
				"_id": "966",
				"_isbn": "973-95247-3-7"
			},
			{
				"titlu": "Fortran (Vol.I)",
				"autori": {
					"autor": [
						{
							"_nume": "Nicolae Costake"
						},
						{
							"_nume": "Radu Eftimie"
						},
						{
							"_nume": "Radu Zamfirescu"
						},
						{
							"_nume": "Augustin Chirila"
						}
					]
				},
				"anAparitie": "1971",
				"editura": "Tehnica",
				"numarPagini": "160",
				"pretOriginal": "4.75",
				"colectia": {
					"_numarul": "27",
					"_nume": "Automatica - Calculatoare moderne"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Fortran_Vol_I_Costake.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni generale de programare"
						},
						{
							"_nr": "2",
							"__text": "Sintaxa si semantica limbajului Fortran"
						},
						{
							"_nr": "3",
							"__text": "Practica programarii FORTAN"
						},
						{
							"_nr": "4",
							"__text": "Descrierea metalingvistica a limbajului FORTRAN in conventia BACKUS"
						}
					]
				},
				"_id": "967",
				"_isbn": ""
			},
			{
				"titlu": "Fortran (Vol.II)",
				"autori": {
					"autor": [
						{
							"_nume": "Nicolae Costake"
						},
						{
							"_nume": "Radu Eftimie"
						},
						{
							"_nume": "Radu Zamfirescu"
						},
						{
							"_nume": "Augustin Chirila"
						}
					]
				},
				"anAparitie": "1971",
				"editura": "Tehnica",
				"numarPagini": "104",
				"pretOriginal": "4.75",
				"colectia": {
					"_numarul": "27",
					"_nume": "Automatica - Calculatoare moderne"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Fortran_Vol_II_Costake.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "5",
							"__text": "Exemple de programe FORTRAN"
						},
						{
							"_nr": "6",
							"__text": "Ghide de programe FORTRAN"
						}
					]
				},
				"_id": "968",
				"_isbn": ""
			},
			{
				"titlu": "Memento DOS (Editia III)",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "30",
				"colectia": {
					"_numarul": "1",
					"_nume": "Memento"
				},
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_DOS_Editia_III_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Configurarea sistemului (CONFIG.SYS)"
						},
						{
							"_nr": "2",
							"__text": "Comenzi DOS"
						},
						{
							"_nr": "3",
							"__text": "Fisiere de comenzi (lot de lucrari)"
						}
					]
				},
				"_id": "969",
				"_isbn": ""
			},
			{
				"titlu": "Memento dBase III Plus (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Marius Chiorean"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "30",
				"colectia": {
					"_numarul": "2",
					"_nume": "Memento"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_dBase_III_Plus_Editia_II_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notatii si conventi"
						},
						{
							"_nr": "2",
							"__text": "Specificatii tehbice dBase III Plus"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de fisiere specifice dBase III Plus"
						},
						{
							"_nr": "4",
							"__text": "Sintaxa comenzilor"
						}
					]
				},
				"_id": "970",
				"_isbn": ""
			},
			{
				"titlu": "Memento WordStar (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Lia Chiorean"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "30",
				"colectia": {
					"_numarul": "3",
					"_nume": "Memento"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_WordStar_Editia_II_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Observatii generale"
						},
						{
							"_nr": "2",
							"__text": "Tastatura"
						},
						{
							"_nr": "3",
							"__text": "Instalarea editorului de texte WordStar"
						},
						{
							"_nr": "4",
							"__text": "Comenzi de la probpter-ul sistemului"
						},
						{
							"_nr": "5",
							"__text": "Meniul Opening Menu"
						},
						{
							"_nr": "6",
							"__text": "Meniul Edit Menu"
						},
						{
							"_nr": "7",
							"__text": "Comenzi cu punct"
						},
						{
							"_nr": "8",
							"__text": "Mesaje de eroare WordStar"
						}
					]
				},
				"_id": "971",
				"_isbn": ""
			},
			{
				"titlu": "Memento Turbo C 2.0 (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Vlad Caprariu"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "30",
				"colectia": {
					"_numarul": "4",
					"_nume": "Memento"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_Turbo_C_2_0_Editia_II_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instructiuni Turbo C"
						},
						{
							"_nr": "2",
							"__text": "Variabile Turbo C"
						},
						{
							"_nr": "3",
							"__text": "Liniile de control ale compilatorului"
						},
						{
							"_nr": "4",
							"__text": "Operatori"
						},
						{
							"_nr": "5",
							"__text": "Mediul de programare Turbo C V 2.0"
						},
						{
							"_nr": "6",
							"__text": "Rutine de intrare/iesire"
						}
					]
				},
				"_id": "972",
				"_isbn": ""
			},
			{
				"titlu": "Memento Turbo Pascal 5.5 (Editia II)",
				"autori": {
					"autor": {
						"_nume": "Kovacs Sandor"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "30",
				"colectia": {
					"_numarul": "5",
					"_nume": "Memento"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_Turbo_Pascal_5_5_Editia_II_Sandor.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului Turbo Pascal"
						},
						{
							"_nr": "2",
							"__text": "Programe si Unit-uri"
						},
						{
							"_nr": "3",
							"__text": "Definitii de constante"
						},
						{
							"_nr": "4",
							"__text": "Definitii de tipuri"
						},
						{
							"_nr": "5",
							"__text": "Declaratii de variabile"
						},
						{
							"_nr": "6",
							"__text": "Declaratii de PROCEDURE si FUNCTION"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni"
						},
						{
							"_nr": "8",
							"__text": "Expresii"
						},
						{
							"_nr": "9",
							"__text": "Proceduri si functii Turbo Pascal - Unit System"
						},
						{
							"_nr": "10",
							"__text": "Mediul de programare Turbo Pascal"
						}
					]
				},
				"_id": "973",
				"_isbn": ""
			},
			{
				"titlu": "Memento FoxBASE+",
				"autori": {
					"autor": {
						"_nume": "Marius Chiorean"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "30",
				"pretOriginal": "25",
				"colectia": {
					"_numarul": "6",
					"_nume": "Memento"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_FoxBASE_Plus_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Specifice tehbice FoxBASE+"
						},
						{
							"_nr": "2",
							"__text": "Notiuni si conventii"
						},
						{
							"_nr": "3",
							"__text": "Domeniul de valabilitate al clauzelor conditionate"
						},
						{
							"_nr": "4",
							"__text": "Operatori"
						},
						{
							"_nr": "5",
							"__text": "Compatibilitatea cu dBase III Plus"
						},
						{
							"_nr": "6",
							"__text": "Sintaxa comenzilor"
						}
					]
				},
				"_id": "974",
				"_isbn": ""
			},
			{
				"titlu": "Memento Norton Utilities (Editia II)",
				"autori": {
					"autor": [
						{
							"_nume": "Vlas Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Horea Pop"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "25",
				"colectia": {
					"_numarul": "7",
					"_nume": "Memento"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_Norton_Utilities_Editia_II_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Despre Utilitare"
						},
						{
							"_nr": "2",
							"__text": "Comenzile Norton Integrator"
						}
					]
				},
				"_id": "975",
				"_isbn": ""
			},
			{
				"titlu": "WordStar - Ghid de utilizare (Editia IV)",
				"autori": {
					"autor": {
						"_nume": "Lia Chiorean"
					}
				},
				"anAparitie": "1993",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "144",
				"pretOriginal": "1250",
				"colectia": {
					"_numarul": "7",
					"_nume": "Biblioteca PC"
				},
				"coperta": {
					"imagine": {
						"_path": "WordStar_Ghid_de_utilizare_Editia_IV_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea editorului de texte WordStar"
						},
						{
							"_nr": "2",
							"__text": "OPENING MENU (meniul principal)"
						},
						{
							"_nr": "3",
							"__text": "Comenzi cu punct"
						},
						{
							"_nr": "4",
							"__text": "Instalarea si configurarea editorului de texte"
						}
					]
				},
				"_id": "976",
				"_isbn": "973-96274-0-4"
			},
			{
				"titlu": "IBM PC pentru utilizatori",
				"autori": {
					"autor": {
						"_nume": "[MicroInformatica]"
					}
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "220",
				"pretOriginal": "300",
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Calin Chiorean"
				},
				"coperta": {
					"imagine": {
						"_path": "IBM_PC_pentru_utilizatori_MicroInformatica.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni generale despre calculatoare IBM-PC"
						},
						{
							"_nr": "2",
							"__text": "Notiuni elementare despre sistemul de operare MS-DOS"
						},
						{
							"_nr": "3",
							"__text": "Organizarea fisierelor si a directoarelor"
						},
						{
							"_nr": "4",
							"__text": "Principalele comenzi MS-DOS"
						},
						{
							"_nr": "5",
							"__text": "Unele comezni si programe utilitare"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea mediului de operare Norton Commander"
						},
						{
							"_nr": "7",
							"__text": "Editarea textelor"
						},
						{
							"_nr": "8",
							"__text": "IBM-PC pentru utilziatori experimentati"
						},
						{
							"_nr": "9",
							"__text": "Arhivarea fisierelor"
						},
						{
							"_nr": "10",
							"__text": "Protectia contra virusilor"
						},
						{
							"_nr": "11",
							"__text": "Configurarea sistemului de operare"
						},
						{
							"_nr": "12",
							"__text": "Deservirea discului Winchester"
						},
						{
							"_nr": "13",
							"__text": "Memento MS-DOS"
						}
					]
				},
				"_id": "977",
				"_isbn": "973-96274-0-4"
			},
			{
				"titlu": "Sistemul de operare DOS. Comenzi (Editia II)",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Marius Chiorean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"numarPagini": "224",
				"pretOriginal": "115",
				"colectia": {
					"_numarul": "2",
					"_nume": "Seria PC"
				},
				"redactori": {
					"redactor": "Smaranda Dervesteanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Calin Chiorean"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_DOS_Comenzi_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Lansarea sistemului de operare DOS"
						},
						{
							"_nr": "3",
							"__text": "Organizarea si specificarea fisierelor DOS"
						},
						{
							"_nr": "4",
							"__text": "Editarea liniei de comanda"
						},
						{
							"_nr": "5",
							"__text": "Comenzi DOS"
						},
						{
							"_nr": "6",
							"__text": "Fisiere de comenzi (lot de lucrari)"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea discului Winchester (discului fix)"
						},
						{
							"_nr": "8",
							"__text": "IBM-PC pentru utilziatori experimentati"
						},
						{
							"_nr": "9",
							"__text": "Arhivarea fisierelor"
						},
						{
							"_nr": "10",
							"__text": "Protectia contra virusilor"
						},
						{
							"_nr": "11",
							"__text": "Configurarea sistemului de operare"
						},
						{
							"_nr": "12",
							"__text": "Deservirea discului Winchester"
						},
						{
							"_nr": "13",
							"__text": "Memento MS-DOS"
						}
					]
				},
				"_id": "978",
				"_isbn": ""
			},
			{
				"titlu": "Utilitare, comenzi, interfete MS-DOS 6 : Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Calin Chiorean"
					}
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "456",
				"pretOriginal": "5500",
				"colectia": {
					"_numarul": "2",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Utilitare_Comenzi_Interfete_MS_DOS_6_Ghid_de_utilizare_Chiorean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "3",
							"__text": "Configurarea sistemului"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea discului fix"
						},
						{
							"_nr": "5",
							"__text": "Configurarea si optimizarea utilizarii memoriei dinamice"
						},
						{
							"_nr": "6",
							"__text": "Utilizare MS-DOS 6"
						},
						{
							"_nr": "7",
							"__text": "Comenzi MS-DOS 6"
						},
						{
							"_nr": "8",
							"__text": "Interferente software MS-DOS 6"
						}
					]
				},
				"_id": "979",
				"_isbn": "973-96274-6-3"
			},
			{
				"titlu": "Memento:WordStar,Ventura",
				"autori": {
					"autor": {
						"_nume": "[RomSoft]"
					}
				},
				"anAparitie": "1992",
				"editura": "RomSoft",
				"numarPagini": "62",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "5",
					"_nume": ""
				},
				"coperta": {
					"imagine": {
						"_path": "WordStar_Ventura_Memento_RomSoft.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "WordStar"
						},
						{
							"_nr": "II",
							"__text": "Ventura"
						}
					]
				},
				"_id": "980",
				"_isbn": ""
			},
			{
				"titlu": "DOS-5.0 - Ghid de referinta al comenzilor",
				"autori": {
					"autor": {
						"_nume": "[RomSoft]"
					}
				},
				"anAparitie": "1992",
				"editura": "RomSoft",
				"numarPagini": "44",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "2",
					"_nume": ""
				},
				"redactori": {
					"redactor": "Florea Eugenia"
				},
				"coperta": {
					"imagine": {
						"_path": "DOS_5_0_Ghid_de_referinta_al_comenzilor_RomSoft.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Gestionarea fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Gestionarea discurilor"
						},
						{
							"_nr": "4",
							"__text": "Lucrul cu DOS"
						},
						{
							"_nr": "5",
							"__text": "Prevenirea dezastrelor"
						},
						{
							"_nr": "6",
							"__text": "Comenzi batch"
						},
						{
							"_nr": "7",
							"__text": "Lucrul cu text"
						},
						{
							"_nr": "8",
							"__text": "Configurarea discurilor"
						},
						{
							"_nr": "9",
							"__text": "Configurarea memoriei"
						},
						{
							"_nr": "10",
							"__text": "Initiere hardware-ului"
						},
						{
							"_nr": "11",
							"__text": "Programare"
						},
						{
							"_nr": "12",
							"__text": "Index alfabetic al comenzilor"
						}
					]
				},
				"_id": "981",
				"_isbn": ""
			},
			{
				"titlu": "Abecedarul DOS",
				"autori": {
					"autor": [
						{
							"_nume": "Mihaela Nicoara"
						},
						{
							"_nume": "Sanda Popescu"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "Memorii SRL",
				"numarPagini": "32",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria de initiere in calculatoare"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Dan Paulescu",
						"Konrad Pfaff",
						"Silviu Lazar"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Abecedarul_DOS_Nicoara.jpg"
					},
					"__text": "Liviu Curtuzan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Descrierea sistemelor IBM - PC"
						},
						{
							"_nr": "3",
							"__text": "Concepte DOS de baza"
						},
						{
							"_nr": "4",
							"__text": "Comenzile DOS de baza"
						},
						{
							"_nr": "5",
							"__text": "Comenzi DOS avansate"
						},
						{
							"_nr": "6",
							"__text": "Exemple de utilizare a comenzior DOS"
						},
						{
							"_nr": "7",
							"__text": "Alte facilitati DOS"
						},
						{
							"_nr": "8",
							"__text": "Glosar de termeni"
						}
					]
				},
				"_id": "982",
				"_isbn": ""
			},
			{
				"titlu": "Programarea in limbajul Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Ion Fatu"
						}
					]
				},
				"anAparitie": "1900",
				"editura": "Institutul Politehnic Bucuresti",
				"tiraj": "500",
				"numarPagini": "270",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_Pascal_Iorga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul Pascal in ierarhia limbajuelor de programare"
						},
						{
							"_nr": "II",
							"__text": "Forme de reprezentare a sintaxei"
						},
						{
							"_nr": "III",
							"__text": "Unitati lexicale ale limbajului Pascal"
						},
						{
							"_nr": "IV",
							"__text": "Structura limbajului Pascal"
						},
						{
							"_nr": "V",
							"__text": "Tipuri de date de baza"
						},
						{
							"_nr": "VI",
							"__text": "Partea declarativa a unui program Pascal"
						},
						{
							"_nr": "VII",
							"__text": "Expresii si instructiuni simple"
						},
						{
							"_nr": "VIII",
							"__text": "Instructiuni structurate"
						},
						{
							"_nr": "IX",
							"__text": "Tipuri de date structurate (tipul tablou)"
						},
						{
							"_nr": "X",
							"__text": "Proceduri si functii"
						},
						{
							"_nr": "XI",
							"__text": "Tipul inregistrare"
						},
						{
							"_nr": "XII",
							"__text": "Tipul multime"
						},
						{
							"_nr": "XIII",
							"__text": "Algoritme recursive"
						},
						{
							"_nr": "XIV",
							"__text": "Fisiere"
						},
						{
							"_nr": "XV",
							"__text": "Variabile si structuri de date dinamice"
						},
						{
							"_nr": "XVI",
							"__text": "Extensii posibile ale limbajului Pascal"
						},
						{
							"_nr": "XVII",
							"__text": "Definitia aximatica a limbajului PAscal si demonstrarea corectitudinii programelor"
						},
						{
							"_nr": "XVIII",
							"__text": "Compilatorul Pascal Felix C-256"
						},
						{
							"_nr": "XIX",
							"__text": "Compilatorul Pascal pentru minicalculatoare CORAL 4011 si INDEPENDET 100"
						},
						{
							"_nr": "Anexa I",
							"__text": "Sintaxa limbajului Pascal in notatie Backus-Naur extinsa"
						},
						{
							"_nr": "Anexa II",
							"__text": "Diagrame de sintaxa Pascal"
						}
					]
				},
				"_id": "983",
				"_isbn": ""
			},
			{
				"titlu": "Memo PC - Comenzi curente pentru utilizarea computerelor personale",
				"autori": {
					"autor": {
						"_nume": "Victor Popescu"
					}
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "200",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Memo_PC_Comenzi_curente_pentru_utilizarea_computerelor_personale_Popescu.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare MS-DOS"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de opearea MS Windows 98"
						},
						{
							"_nr": "4",
							"__text": "Lucrati pe INTERNET"
						},
						{
							"_nr": "5",
							"__text": "Suita de programe Microsoft Office 2000"
						},
						{
							"_nr": "6",
							"__text": "Prelucrare texte cu programul MS WORD 2000 in limba romana"
						},
						{
							"_nr": "7",
							"__text": "Prelucrare tabelara cu programul MS EXCEL 2000 in limba romana"
						}
					]
				},
				"_id": "984",
				"_isbn": "973-31-1562-2"
			},
			{
				"titlu": "Microprocesorul 8080 in aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Tiberiu Muresan"
						},
						{
							"_nume": "Radu Stoinescu"
						},
						{
							"_nume": "Crisan Strungaru"
						},
						{
							"_nume": "Emil Petriu"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Facla",
				"numarPagini": "264",
				"pretOriginal": "18.50",
				"redactori": {
					"redactor": "Gheorghe Mandici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioan I. Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesorul_8080_in_aplicatii_Muresan.jpg"
					},
					"__text": "Nicolae Sarbu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aspecte de interes general asupra operatiilor de intrare-iesire"
						},
						{
							"_nr": "2",
							"__text": "Realizarea unui sistem cu microprocesorul 8080. Varianta 1"
						},
						{
							"_nr": "3",
							"__text": "Realizarea unui microcalculatorr : Varianta 2"
						},
						{
							"_nr": "4",
							"__text": "Interfete cu alte periferice numerice"
						},
						{
							"_nr": "5",
							"__text": "Conectarea unor periferice analogice"
						},
						{
							"_nr": "6",
							"__text": "Implementarea unor functiuni aritmetice de baza"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii in robotica"
						}
					]
				},
				"_id": "985",
				"_isbn": ""
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": [
						{
							"_nume": "Leon Livovschi"
						},
						{
							"_nume": "Horia Georgescu"
						},
						{
							"_nume": "Constantin P. Popovici"
						},
						{
							"_nume": "Nicolae Tandareanu"
						}
					]
				},
				"anAparitie": "1981",
				"editura": "Didactica si pedagogica",
				"numarPagini": "256",
				"pretOriginal": "7.70",
				"redactori": {
					"redactor": "Gabriela - Chira Iliescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Petrica"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Popovici.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Partea I"
						},
						{
							"_nr": "I",
							"__text": "Limbaje formale si automate"
						},
						{
							"_nr": "II",
							"__text": "Limbaje regulate"
						},
						{
							"_nr": "III",
							"__text": "Limbaje independente de context"
						},
						{
							"_nr": "IV",
							"__text": "Elemente ale teoriei compilarii"
						},
						{
							"_nr": "Partea II",
							"__text": "Partea II"
						},
						{
							"_nr": "I",
							"__text": "Vetificarea programelor"
						},
						{
							"_nr": "II",
							"__text": "Programe structurate"
						},
						{
							"_nr": "III",
							"__text": "Complexitatea programelor"
						},
						{
							"_nr": "IV",
							"__text": "Scheme de programe"
						},
						{
							"_nr": "Partea III",
							"__text": "Partea III"
						},
						{
							"_nr": "I",
							"__text": "Grafuri"
						},
						{
							"_nr": "II",
							"__text": "Structuri de date"
						},
						{
							"_nr": "III",
							"__text": "Probleme ale prelucrarii datelor"
						},
						{
							"_nr": "Partea IV",
							"__text": "Partea IV"
						},
						{
							"_nr": "I",
							"__text": "Sistem de calcul si sistem de operare"
						},
						{
							"_nr": "II",
							"__text": "Facilitati de rulare a programelor"
						},
						{
							"_nr": "III",
							"__text": "Crearea si exploatarea fisierelor"
						},
						{
							"_nr": "IV",
							"__text": "Crearea si exploatarea bibliotecilor utilizator"
						}
					]
				},
				"_id": "986",
				"_isbn": ""
			},
			{
				"titlu": "Microcalculatoarele FELIX M18, M18B, M118 (Vol II)",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Petrescu"
						},
						{
							"_nume": "Trandafir Moisa"
						},
						{
							"_nume": "Nicolae Tapus"
						},
						{
							"_nume": "Dan Gheorghiu"
						},
						{
							"_nume": "Mircea Brozici"
						},
						{
							"_nume": "Constantin Berbece"
						},
						{
							"_nume": "Damian Popescu"
						},
						{
							"_nume": "Constantin Petrescu"
						},
						{
							"_nume": "Theodor Balan"
						},
						{
							"_nume": "Gheorghe Petrescu"
						},
						{
							"_nume": "Vasile Lungu"
						},
						{
							"_nume": "Marius Hanganut"
						},
						{
							"_nume": "Aristide Predoi"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Tehnica",
				"numarPagini": "256",
				"pretOriginal": "23",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Stela Serbanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Mirocalculatoarele_FELIX_M18_M18B_M118_Vol_II_Petrescu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "6",
							"__text": "Comenzile sistemului de operare SFDX-18"
						},
						{
							"_nr": "7",
							"__text": "Editarea textelor"
						},
						{
							"_nr": "8",
							"__text": "Utilizarea limbajului de asamblare"
						},
						{
							"_nr": "9",
							"__text": "Aplicatii ale microcalculatoarelor din familia FELIX M18-M118"
						}
					]
				},
				"_id": "987",
				"_isbn": ""
			},
			{
				"titlu": "HC 85- Manual tehnic",
				"autori": {
					"autor": {
						"_nume": "[ICE FELIX]"
					}
				},
				"anAparitie": "1986",
				"editura": "ICE FELIX",
				"numarPagini": "96",
				"pretOriginal": "0",
				"pretCumparare": "70000",
				"coperta": {
					"imagine": {
						"_path": "HC_85_Manual_tehnic_ICE_FELIX.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala si instalare"
						},
						{
							"_nr": "2",
							"__text": "Elemente de programe si editare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Basic"
						},
						{
							"_nr": "4",
							"__text": "Functionarea calculatoarului HC 85"
						},
						{
							"_nr": "Anexa A",
							"__text": "Lista de componente"
						},
						{
							"_nr": "Anexa B",
							"__text": "Scheme logice"
						}
					]
				},
				"_id": "988",
				"_isbn": ""
			},
			{
				"titlu": "HC 85- Manual tehnic",
				"autori": {
					"autor": {
						"_nume": "Nicolae Dragulanescu"
					}
				},
				"anAparitie": "1987",
				"editura": "ICE FELIX",
				"numarPagini": "98",
				"pretOriginal": "0",
				"pretCumparare": "70000",
				"coperta": {
					"imagine": {
						"_path": "HC_85_Manual_tehnic_Dragulanescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala si instalare"
						},
						{
							"_nr": "2",
							"__text": "Elemente de programe si editare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul Basic"
						},
						{
							"_nr": "4",
							"__text": "Functionarea calculatoarului HC 85"
						},
						{
							"_nr": "Anexa A",
							"__text": "Lista de componente"
						},
						{
							"_nr": "Anexa B",
							"__text": "Scheme logice"
						}
					]
				},
				"_id": "989",
				"_isbn": ""
			},
			{
				"titlu": "Proiectarea sistemelor cu microprocesor Z80",
				"autori": {
					"autor": [
						{
							"_nume": "Marius Cornea Hasegan"
						},
						{
							"_nume": "Dorina Cornea Hasegan"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "Dacia",
				"numarPagini": "216",
				"pretOriginal": "23",
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_sistemelor_cu_microprocesor_Z80_Hasegan.jpg"
					},
					"__text": "Calin Damian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Unitatea centrala de prelucrare a sistemului Z80-Z80 CPU"
						},
						{
							"_nr": "3",
							"__text": "Programarea microprocesorului Z80"
						},
						{
							"_nr": "4",
							"__text": "Circuitul numarator-temporizator Z80 CTC"
						},
						{
							"_nr": "5",
							"__text": "Circuitul de control pentru intrare-iesire seriala, Z80 SIO"
						},
						{
							"_nr": "6",
							"__text": "Circuitul de intrare-iesire paralela, Z80 PIO"
						},
						{
							"_nr": "7",
							"__text": "Circuitul de control al accesului direct la memorie, Z80 DMA"
						},
						{
							"_nr": "8",
							"__text": "Realizarea unui sistem Z80. Aplicatii"
						},
						{
							"_nr": "9",
							"__text": "Sistem de dezvoltare cu microprocesor Z80"
						}
					]
				},
				"_id": "990",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare : circuite, proiectare",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Lupu"
						},
						{
							"_nume": "Stefan Stancescu"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Militara",
				"numarPagini": "288",
				"pretOriginal": "23",
				"redactori": {
					"redactor": "Dumitru Nicolescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gh. Cucos"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesoare_circuite_proiectare_Lupu.jpg"
					},
					"__text": "V. Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Familia de circuite Z80"
						},
						{
							"_nr": "2",
							"__text": "Conectarea memoriilor externe cu discuri flexibile la sisteme cu microprocesoare"
						},
						{
							"_nr": "3",
							"__text": "Un sistem modular cu microprocesor"
						}
					]
				},
				"_id": "991",
				"_isbn": ""
			},
			{
				"titlu": "Securitatea si protectia informatiei in sistemele electronice de calcul",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Angheloiu"
						},
						{
							"_nume": "Eugen Gyorfi"
						},
						{
							"_nume": "Victor Patriciu"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Militara",
				"numarPagini": "308",
				"pretOriginal": "24",
				"redactori": {
					"redactor": "A. Mihalcea"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Securitatea_si_protectia_informatiei_in_sistemele_electronice_de_calcul_Angeloiu.jpg"
					},
					"__text": "C. Grigoriu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme generale ale protectiei si securitatii informatiei"
						},
						{
							"_nr": "2",
							"__text": "Structura si evolutia sistemelor criptografice"
						},
						{
							"_nr": "3",
							"__text": "Bazele teoretice ale sistemelor secrete"
						},
						{
							"_nr": "4",
							"__text": "Metode criptografice"
						},
						{
							"_nr": "5",
							"__text": "Securitatea datelor la calculatoarele electronice"
						},
						{
							"_nr": "6",
							"__text": "Securitatea datelor in retelele teleinformatice"
						},
						{
							"_nr": "7",
							"__text": "Elemente de protanaliz"
						}
					]
				},
				"_id": "992",
				"_isbn": ""
			},
			{
				"titlu": "Internet: informare si instruire. Pasi in lumea comunicatiilor",
				"autori": {
					"autor": {
						"_nume": "Mihai Jalobeanu"
					}
				},
				"anAparitie": "1995",
				"editura": "Promedia Plus",
				"numarPagini": "424",
				"pretOriginal": "11500",
				"tehnoredactori": {
					"tehnoredactor": "Mihai Jalobeanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Internet_informare_si_instruire_pasi_in_lumea_comunicatiiior_Jalobeanu.jpg"
					},
					"__text": "Octavian Bour"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1-a",
							"__text": "Transimisa informatiei si comunicatia intre calculatoare"
						},
						{
							"_nr": "Partea 2-a",
							"__text": "Utilitare si comenzi pentru posta electronica"
						},
						{
							"_nr": "Partea 3-a",
							"__text": "Instrumente si tehnici de lucru"
						},
						{
							"_nr": "Partea 4-a",
							"__text": "Accestul la Internet perin posta electronica"
						},
						{
							"_nr": "Partea 5-a",
							"__text": "Informarea si instruirea in Internet"
						},
						{
							"_nr": "Partea 6-a",
							"__text": "Grupuri de dezbatere in retea"
						},
						{
							"_nr": "Partea 7-a",
							"__text": "Publicatii electronice si accesul in bibliotecile publice"
						},
						{
							"_nr": "Partea 8-a",
							"__text": "Resurse romanesti privind accesul la Internet"
						}
					]
				},
				"_id": "993",
				"_isbn": "973-96862-8-1"
			},
			{
				"titlu": "Bazele programarii pe Personal Computer",
				"autori": {
					"autor": {
						"_nume": "Florin Bica"
					}
				},
				"anAparitie": "1991",
				"editura": "Universitatii din Craiova",
				"numarPagini": "240",
				"pretOriginal": "175",
				"redactori": {
					"redactor": "Octavian Lohon"
				},
				"tehnoredactori": {
					"tehnoredactor": "Florin Bica"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_programarii_pe_Personal_Computer_Bica.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Personal Computer. Elemente generale de hard si soft de baza"
						},
						{
							"_nr": "2",
							"__text": "Construirea algoritmilor"
						},
						{
							"_nr": "3",
							"__text": "Reprezentarea algoritmilor cu ajutorul schemelor logice"
						},
						{
							"_nr": "4",
							"__text": "Elementele limbajului GW-BASIC"
						},
						{
							"_nr": "5",
							"__text": "Comenzi GW-BASIC"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni si functii pentru prelucrarea datelor numerice si a sirurilor de caractere neorganizate in fisiere"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni si functii pentru prelucrarea fisierelor"
						},
						{
							"_nr": "8",
							"__text": "Instructiuni si functii pentru prelucrarea  grafica si sonora"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni si functii diverse"
						},
						{
							"_nr": "Anexa nr.1",
							"__text": "Coduri de eroare si mesaje aferente"
						},
						{
							"_nr": "Anexa nr.2",
							"__text": "Coduri caracterelor ASCII"
						},
						{
							"_nr": "Anexa nr.3",
							"__text": "Coduri scan asociate tastaturii"
						},
						{
							"_nr": "-",
							"__text": "Programe in SW-BASIC, concepte si testate pe IBM PC (286/386/486) JUNIOR-86 sub MS-DOS 4.1"
						}
					]
				},
				"_id": "994",
				"_isbn": ""
			},
			{
				"titlu": "Descoperiti sistemul Linux",
				"autori": {
					"autor": {
						"_nume": "Steve Oualline"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "456",
				"pretOriginal": "90000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "260",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Discover Linux",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Rodica Bitoiu"
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Descoperiti_sistemul_Linux_Oualline.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Instalarea si rularea sistemului Linux"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Distractie si jocuri"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Sistemul Linux in birou"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Multimedia si instrumente de programare"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Moduri de configurare avansata"
						}
					]
				},
				"_id": "995",
				"_isbn": "973-601-868-7"
			},
			{
				"titlu": "Securitatea in Internet",
				"autori": {
					"autor": {
						"_nume": "[Anonim]"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "768",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "319",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Maximum Security",
					"editura": "Sams Publishing Copyright",
					"traducatori": {
						"traducator": "Silviu Petrescu"
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Securitatea_in_Internet_Anonim.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Pregatirea scenei"
						},
						{
							"_nr": "II",
							"__text": "Cunoasterea terenului"
						},
						{
							"_nr": "III",
							"__text": "Instrumente"
						},
						{
							"_nr": "IV",
							"__text": "Platforme si securitate"
						},
						{
							"_nr": "V",
							"__text": "Sa incepem de la nivelul zero"
						},
						{
							"_nr": "VI",
							"__text": "Atacul de la distanta"
						},
						{
							"_nr": "VII",
							"__text": "Legea"
						},
						{
							"_nr": "VIII",
							"__text": "Anexe"
						}
					]
				},
				"_id": "996",
				"_isbn": "973-601-930-6"
			},
			{
				"titlu": "Initiere in programarea calculatoarelor numerice",
				"autori": {
					"autor": {
						"_nume": "John S. Murphy"
					}
				},
				"anAparitie": "1969",
				"editura": "Tehnica",
				"numarPagini": "132",
				"pretOriginal": "9.50",
				"pretCumparare": "5000",
				"traducere": {
					"titluOriginal": "Basics of digital computer programming",
					"editura": "John F. Rider Publisher",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1969",
					"_editia": "5",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Dimitriu Smaranda"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_programarea_calculatoarelor_numerice_Murphy.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prelucrarea datelor"
						},
						{
							"_nr": "2",
							"__text": "Introducere in programare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul de programare"
						},
						{
							"_nr": "4",
							"__text": "Exemplu de program"
						},
						{
							"_nr": "5",
							"__text": "Unele probleme ale fisierelor"
						},
						{
							"_nr": "6",
							"__text": "Prelucrarea fisierelor"
						},
						{
							"_nr": "7",
							"__text": "Intocmirea programului"
						},
						{
							"_nr": "8",
							"__text": "Un alt exemplu de program"
						},
						{
							"_nr": "9",
							"__text": "Program combinat"
						},
						{
							"_nr": "10",
							"__text": "Analiza programului"
						},
						{
							"_nr": "11",
							"__text": "Mentinerea fisierelor"
						},
						{
							"_nr": "12",
							"__text": "Memoria"
						},
						{
							"_nr": "13",
							"__text": "Adresarea"
						},
						{
							"_nr": "14",
							"__text": "Zonele din memorie"
						},
						{
							"_nr": "15",
							"__text": "Operarea programului"
						},
						{
							"_nr": "16",
							"__text": "Ramificarea"
						},
						{
							"_nr": "17",
							"__text": "Cicluri"
						},
						{
							"_nr": "18",
							"__text": "Unele probleme ale benzii magnetice"
						},
						{
							"_nr": "19",
							"__text": "Modificarea adresei"
						},
						{
							"_nr": "20",
							"__text": "Descrierea datelor"
						},
						{
							"_nr": "21",
							"__text": "Compilatorul"
						},
						{
							"_nr": "22",
							"__text": "Principalele etape ale programarii"
						}
					]
				},
				"_id": "997",
				"_isbn": ""
			},
			{
				"titlu": "Retele de interconectare calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Donald W. Davies"
						},
						{
							"_nume": "Dereck L.A. Barber"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Tehnica",
				"tiraj": "5000+70 legate",
				"numarPagini": "646",
				"pretOriginal": "42",
				"pretCumparare": "5000",
				"traducere": {
					"titluOriginal": "Computer Networks",
					"editura": "John Wiley  and Sons Ltd",
					"traducatori": {
						"traducator": [
							"Traian Ionescu",
							"Ana Miahela Ionescu"
						]
					},
					"_an": "1975",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_de_interconectare_calculatoarelor_Davies.jpg"
					},
					"__text": "Simone Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatoare si comunicatii"
						},
						{
							"_nr": "2",
							"__text": "Comunicatiile pentru date si reteaua telefonica"
						},
						{
							"_nr": "3",
							"__text": "Interactiunea calculatoarelor"
						},
						{
							"_nr": "4",
							"__text": "Retele particulare pentru date"
						},
						{
							"_nr": "5",
							"__text": "Transmiterea datelor"
						},
						{
							"_nr": "6",
							"__text": "Comanda, memorarea si codificarea fluxului informational"
						},
						{
							"_nr": "7",
							"__text": "Multiplexarea numerica"
						},
						{
							"_nr": "8",
							"__text": "Sisteme de coumtare a mesajelor"
						},
						{
							"_nr": "9",
							"__text": "Principii pentru comutarea datelro"
						},
						{
							"_nr": "10",
							"__text": "Structura retelei pentru comutarea pachetelor"
						},
						{
							"_nr": "11",
							"__text": "Protocoale, terminale si supraveghearea retelei"
						},
						{
							"_nr": "12",
							"__text": "Geografia retelei, fiabilitate si dirijare"
						},
						{
							"_nr": "13",
							"__text": "Software pentru sisteme de comutarea pachetelor"
						},
						{
							"_nr": "14",
							"__text": "Trecerea in revista a principiilor de proiectare a retelelor pentru date"
						},
						{
							"_nr": "Anexa",
							"__text": "Specificatia interfetei i-e pentru SCD (standard britanic 4421)"
						}
					]
				},
				"_id": "998",
				"_isbn": ""
			},
			{
				"titlu": "Teleprelucrarea datelor",
				"autori": {
					"autor": [
						{
							"_nume": "Lucia Coculescu"
						},
						{
							"_nume": "Constantin Poinaru"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Militara",
				"numarPagini": "304",
				"pretOriginal": "17",
				"redactori": {
					"redactor": "D. Nicolescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Teleprelucrarea_datelor_Coculescu.jpg"
					},
					"__text": "M. Panduru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De la teleprelucrarea datelor catre teleinformatica"
						},
						{
							"_nr": "2",
							"__text": "Transmiterea datelor pe un circuit de date"
						},
						{
							"_nr": "3",
							"__text": "Transportul informatiei pe o legatura de date"
						},
						{
							"_nr": "4",
							"__text": "Sisteme teleinformatice"
						},
						{
							"_nr": "5",
							"__text": "Protectia informatiilor in sistemele teleinformatice"
						}
					]
				},
				"_id": "999",
				"_isbn": ""
			},
			{
				"titlu": "Probleme de analiza numerica rezolvate cu calculatorul",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Marinescu"
						},
						{
							"_nume": "Irina Rizzoli"
						},
						{
							"_nume": "Ileana Popescu"
						},
						{
							"_nume": "Cristina Stefan"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "264",
				"pretOriginal": "19.50",
				"redactori": {
					"redactor": "Petre Moraru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_analiza_numerica_rezolvate_cu_calculatorul_Rizzoli.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Metode numerice pentru rezolvarea unor probleme de analiza matematica"
						},
						{
							"_nr": "1",
							"__text": "Numere reale"
						},
						{
							"_nr": "2",
							"__text": "Aproximarea functiilor de una sau mai multe variabile reale"
						},
						{
							"_nr": "3",
							"__text": "Poligoane ortogonale"
						},
						{
							"_nr": "4",
							"__text": "Calculul numeric al integralelor"
						},
						{
							"_nr": "5",
							"__text": "Rezolvarea ecuatiilor neliniare"
						},
						{
							"_nr": "II",
							"__text": "Metode numerice pentru rezolvarea unor probleme de algebra"
						},
						{
							"_nr": "1",
							"__text": "Rezolvarea sistemelor liniare"
						},
						{
							"_nr": "2",
							"__text": "Metode de inversare de matrici"
						},
						{
							"_nr": "3",
							"__text": "Valori si vectori proprii"
						},
						{
							"_nr": "III",
							"__text": "Metode numerice rezolvate a ecuatiilor diferentiale si cu derivate partiale"
						},
						{
							"_nr": "1",
							"__text": "Ecuatii diferentiale"
						},
						{
							"_nr": "2",
							"__text": "Ecuatii cu derivate partiale"
						}
					]
				},
				"_id": "1000",
				"_isbn": ""
			},
			{
				"titlu": "Calculatorul, nimic mai simplu!",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Albescu"
						},
						{
							"_nume": "Alexandru Vilan"
						},
						{
							"_nume": "Cristian Craciunoiu"
						},
						{
							"_nume": "Titi Tudorancea"
						},
						{
							"_nume": "Liviu Ionescu"
						},
						{
							"_nume": "Horia Dumitrascu"
						},
						{
							"_nume": "Stefan Niculescu-Maier"
						},
						{
							"_nume": "Mihaela Gorodcov"
						},
						{
							"_nume": "Stelian Niculescu"
						},
						{
							"_nume": "Viorel Darie"
						},
						{
							"_nume": "Mihai Mirsanu"
						},
						{
							"_nume": "Mihai Jitaru"
						},
						{
							"_nume": "Ana Maria Preda"
						},
						{
							"_nume": "Claudia Dumitrescu"
						},
						{
							"_nume": "Gorun Manolescu"
						},
						{
							"_nume": "Mariana Damian"
						},
						{
							"_nume": "Nicolae Costake"
						},
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Ion Ionascu"
						},
						{
							"_nume": "Cryseea Calinescu"
						},
						{
							"_nume": "Cristian Chiritescu"
						},
						{
							"_nume": "Vasile Petrovic"
						},
						{
							"_nume": "Aurel Istrate"
						},
						{
							"_nume": "Nicolae Arcusi"
						},
						{
							"_nume": "Adrian Taciulescu"
						},
						{
							"_nume": "Dragos Falie"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Comitetul Central al U.T.C.",
				"numarPagini": "78",
				"pretOriginal": "6",
				"redactori": {
					"redactor": "Titi Tudorancea"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Mihai Cetina",
						"Cornel Daneliuc"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatorul_nimic_mai_simplu_Albescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De ce calculatoare>"
						},
						{
							"_nr": "2",
							"__text": "Argumente pentru informatica"
						},
						{
							"_nr": "3",
							"__text": "Fara presentimente"
						},
						{
							"_nr": "4",
							"__text": "Calatorie in lumea calculatoarelor personale"
						},
						{
							"_nr": "5",
							"__text": "Arhitectura calculatorului"
						},
						{
							"_nr": "6",
							"__text": "Microprocesorul"
						},
						{
							"_nr": "7",
							"__text": "Microprocesorul - intre stiinta si arta"
						},
						{
							"_nr": "8",
							"__text": "Limbaje de programare"
						},
						{
							"_nr": "9",
							"__text": "Introducere in programrea calculatoarelor personale"
						},
						{
							"_nr": "10",
							"__text": "Grafica pe calculatoarele personale si profesionale"
						},
						{
							"_nr": "11",
							"__text": "Calculatorul in biroul dumneavoastra"
						},
						{
							"_nr": "12",
							"__text": "Calculatorul in medicina"
						},
						{
							"_nr": "13",
							"__text": "Cum alegem?"
						},
						{
							"_nr": "14",
							"__text": "Calculatoare personale fabricate in R.S. Romania"
						},
						{
							"_nr": "15",
							"__text": "Calculatorul...un simplu joc?"
						},
						{
							"_nr": "16",
							"__text": "Voineasa '86"
						},
						{
							"_nr": "17",
							"__text": "'Familia' BASIC"
						},
						{
							"_nr": "18",
							"__text": "MICRODOC - sistem de regasire documentara"
						},
						{
							"_nr": "19",
							"__text": "Program - pentru analiza circuitelor electrice active si pasive"
						},
						{
							"_nr": "20",
							"__text": "Calculul derivatei functiilor polinomiale de grad mai mic sau egal cu 10"
						},
						{
							"_nr": "21",
							"__text": "Aventura microinformaticii"
						},
						{
							"_nr": "22",
							"__text": "Calculatoare personale din tarile socialiste"
						},
						{
							"_nr": "23",
							"__text": "Regatul micilor masini"
						},
						{
							"_nr": "24",
							"__text": "Regulamentul concursului 'Calculatorul, nimic mai simplu!'"
						},
						{
							"_nr": "25",
							"__text": "Varietati"
						}
					]
				},
				"_id": "1001",
				"_isbn": ""
			},
			{
				"titlu": "Minicalculatoare.Aplicatii (Vol.I)",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "Dan Ionescu"
						},
						{
							"_nume": "Cristian Popescu"
						},
						{
							"_nume": "Iulian Popa"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"tiraj": "15600+75",
				"numarPagini": "280",
				"pretOriginal": "12",
				"colectia": {
					"_numarul": "45",
					"_nume": "Automatica.Informatica"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Minicalculatoare_Aplicatii_I_Dodescu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in domeniul minicalculatoarelor"
						},
						{
							"_nr": "2",
							"__text": "Bazele aritmetice, logice si constructive ale minicalculatoarelelor"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura si functionarea unitatii centrale la minicalculatoare"
						},
						{
							"_nr": "4",
							"__text": "Sistemul de I/E, hardware-software"
						},
						{
							"_nr": "5",
							"__text": "Programe de sistem si limbaje de programare"
						},
						{
							"_nr": "6",
							"__text": "Sisteme de operare"
						}
					]
				},
				"_id": "1002",
				"_isbn": ""
			},
			{
				"titlu": "Minicalculatoare.Aplicatii (Vol.II)",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "Dan Ionescu"
						},
						{
							"_nume": "Cristian Popescu"
						},
						{
							"_nume": "Iulian Popa"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"tiraj": "15600+75",
				"numarPagini": "280",
				"pretOriginal": "12",
				"colectia": {
					"_numarul": "46",
					"_nume": "Automatica.Informatica"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Minicalculatoare_Aplicatii_II_Dodescu.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Familii de minicalculatoare"
						},
						{
							"_nr": "2",
							"__text": "Minicalculatoarele in regim de teleprelucrare"
						},
						{
							"_nr": "3",
							"__text": "Configuratii specifice ale minicalculatoarelor de proces"
						},
						{
							"_nr": "4",
							"__text": "Utilizarea intereactiva a minicalculatoarelor in regim de multiacces"
						},
						{
							"_nr": "5",
							"__text": "Retele de minicalculatoare"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii ale minicalculatoarelor in alte domenii"
						},
						{
							"_nr": "7",
							"__text": "Criterii de alegere a minicalculatoarelor"
						}
					]
				},
				"_id": "1003",
				"_isbn": ""
			},
			{
				"titlu": "Sa invatam BASIC",
				"autori": {
					"autor": {
						"_nume": "Horia Dumitrascu"
					}
				},
				"anAparitie": "1987",
				"editura": "Albatros",
				"numarPagini": "224",
				"pretOriginal": "12.50",
				"tehnoredactori": {
					"tehnoredactor": "Cornel Cristescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sa_invatam_BASIC_Dumitrascu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajuk BASIC si contextul sau informatic"
						},
						{
							"_nr": "2",
							"__text": "Modul de lucru imediat"
						},
						{
							"_nr": "3",
							"__text": "Comenzile interpretorului BASIC"
						},
						{
							"_nr": "4",
							"__text": "Modul program"
						},
						{
							"_nr": "5",
							"__text": "Programe scrise in limbajul BASIC"
						},
						{
							"_nr": "6",
							"__text": "In concluzie, practicati"
						}
					]
				},
				"_id": "1004",
				"_isbn": ""
			},
			{
				"titlu": "Metode avansate de utilizare a tehicii de calcul",
				"autori": {
					"autor": [
						{
							"_nume": "Marcel Popescu"
						},
						{
							"_nume": "Ion Floricica"
						},
						{
							"_nume": "Mihai Jitaru"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "Militara",
				"numarPagini": "256",
				"pretOriginal": "14.50",
				"redactori": {
					"redactor": "Alexandru Mihalcea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dionisie Bodea"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_avanate_de_utilizare_a_tehnicii_de_calcul_Popescu.jpg"
					},
					"__text": "Victor Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Programare in limbaj C"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Programare in Modula-2"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Programare in Prolog"
						}
					]
				},
				"_id": "1005",
				"_isbn": "973-32-0061-1"
			},
			{
				"titlu": "Ghid de utilizare a calculatoarelor portabile in agricultura",
				"autori": {
					"autor": {
						"_nume": "Cristian Ionescu"
					}
				},
				"anAparitie": "1981",
				"editura": "Ceres",
				"numarPagini": "152",
				"pretOriginal": "5.75",
				"redactori": {
					"redactor": "Doina Danet"
				},
				"tehnoredactori": {
					"tehnoredactor": "Eugenia Cernea"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_utilizare_a_calculatoarelor_portabile_in_agricultura_Ionescu.jpg"
					},
					"__text": "Vasile Roman"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura calculatoarelor portabile"
						},
						{
							"_nr": "3",
							"__text": "Bazele aritmetice ale folosirii calculatoarelor"
						},
						{
							"_nr": "4",
							"__text": "Functii atasate calculatoarelor portabile"
						},
						{
							"_nr": "5",
							"__text": "Metode numerice de calcul ale functiilor complexe"
						},
						{
							"_nr": "6",
							"__text": "Optimizarea calculelor efectuate la calculatoare"
						},
						{
							"_nr": "7",
							"__text": "Calculatoare portabile de larga circulatie"
						}
					]
				},
				"_id": "1006",
				"_isbn": ""
			},
			{
				"titlu": "Calculatorul electronic de biroul programabil - FELIX CE 130 P",
				"autori": {
					"autor": {
						"_nume": "[ICE FELIX]"
					}
				},
				"anAparitie": "1900",
				"editura": "Intreprinderea de calculatoare electronice Bucuresti",
				"numarPagini": "116",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Calculatorul_electronic_de_birou_programabil_FELIX_CE_130_P_ICE_FELIX.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Descrierea tastaturii"
						},
						{
							"_nr": "3",
							"__text": "Operare in modul de lucru automat"
						},
						{
							"_nr": "4",
							"__text": "Operare in mod de lucru programabil"
						},
						{
							"_nr": "5",
							"__text": "Exemple de calcul"
						},
						{
							"_nr": "6",
							"__text": "Programul de test pentru Felix CE 130 P"
						}
					]
				},
				"_id": "1007",
				"_isbn": ""
			},
			{
				"titlu": "Metode si tehnici utilizate in realizarea sistemelor informatice",
				"autori": {
					"autor": {
						"_nume": "Dumitru Oprean"
					}
				},
				"anAparitie": "1980",
				"editura": "Dacia",
				"numarPagini": "188",
				"pretOriginal": "7",
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_si_tehnici_utilizate_in_realizarea_sistemelor_informatice_Oprean.jpg"
					},
					"__text": "M. Baciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme informationale si sisteme informatice. Realizarea sistemelor informatice"
						},
						{
							"_nr": "2",
							"__text": "Metode si tehnici de planificare si coordonare a activitatilor informatice"
						},
						{
							"_nr": "3",
							"__text": "Elaborarea temei de ralizare a sistemului informatic"
						},
						{
							"_nr": "4",
							"__text": "Elaborarea conceptiei sistemului informatic"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea tehnica a componentelor sistemului informatic"
						},
						{
							"_nr": "6",
							"__text": "Elaborarea programelor"
						},
						{
							"_nr": "7",
							"__text": "Implementarea sistemului informatic"
						},
						{
							"_nr": "8",
							"__text": "Tendinte in realizarea sistemelor informatice"
						}
					]
				},
				"_id": "1008",
				"_isbn": ""
			},
			{
				"titlu": "Foxpro 2.5: compediu de comenzi si functii",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Ion Teodor"
						},
						{
							"_nume": "Manole Velicanu"
						},
						{
							"_nume": "Marcel Patrulescu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Cison",
				"numarPagini": "96",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Constanta Bodea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marcel Patrulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Foxpro_2_5_compediu_de_comenzi_si_functii_Lungu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul Foxpro - comparatie intre versiunile 2.5 si 2.0"
						},
						{
							"_nr": "2",
							"__text": "Ghid pentru programatori in limbajul Foxpro 2.5"
						}
					]
				},
				"_id": "1009",
				"_isbn": "973-96370-1-9"
			},
			{
				"titlu": "Programe de instruire",
				"autori": {
					"autor": {
						"_nume": "Adrian I. Vlad"
					}
				},
				"anAparitie": "1989",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "136",
				"pretOriginal": "11.50",
				"redactori": {
					"redactor": "Ion Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marinela Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Programe_de_instruire_Vlad.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sesiunea de lucru BASIC"
						},
						{
							"_nr": "2",
							"__text": "Moduri de operare"
						},
						{
							"_nr": "3",
							"__text": "Informatii generale despre programarea in BASIC"
						},
						{
							"_nr": "4",
							"__text": "Intrari si iesiri"
						},
						{
							"_nr": "5",
							"__text": "Comenzi, instructiuni si functii BASIC"
						}
					]
				},
				"_id": "1010",
				"_isbn": "973-29-0108-X"
			},
			{
				"titlu": "Tehnici de programare si structuri de date (Vol.III)",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1994",
				"editura": "L and S InfoMat",
				"numarPagini": "96",
				"pretOriginal": "900",
				"redactori": {
					"redactor": "Sebastian Proksch"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_si_structuri_de_date_III_Sorin.jpg"
					},
					"__text": "Sebastian Proksch"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "6",
							"__text": "Tehnica BRANCH AND BOUND"
						},
						{
							"_nr": "7",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "8",
							"__text": "Tehnica Greedy"
						},
						{
							"_nr": "9",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "1011",
				"_isbn": "973-96382-2-8"
			},
			{
				"titlu": "Informatica Aplicata [XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Radu Marsanu"
						},
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Florin Hartescu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Didactica si pedagogica",
				"numarPagini": "116",
				"pretOriginal": "695",
				"redactori": {
					"redactor": "Alexandra Moraru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mircea Lata"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_aplicata_XII_Marsanu.jpg"
					},
					"__text": "Elena Dragulelei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Informatica distribuita"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Birotica"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Informatica industriala"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Grafica interactiva"
						}
					]
				},
				"_id": "1012",
				"_isbn": "973-30-3198-4"
			},
			{
				"titlu": "Initiere in FOXPRO",
				"autori": {
					"autor": [
						{
							"_nume": "Elvira Nicoleta Birzoaca"
						},
						{
							"_nume": "Nicu George Birzoaca"
						},
						{
							"_nume": "Stoica Gradinaru"
						},
						{
							"_nume": "Alina Ionisor"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Arves",
				"numarPagini": "216",
				"pretOriginal": "69000",
				"colectia": {
					"_numarul": "7",
					"_nume": "Initiere in calculatoare"
				},
				"coperta": {
					"imagine": {
						"_path": "Initirere_FOX_PRO_Birzoaca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aplicatia FoxPro - Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de gestiune a bazelor de date - notiuni fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Operatii elemetare asupra bazelor de date"
						},
						{
							"_nr": "4",
							"__text": "Prelucrari statistice asupra bazelor de date"
						},
						{
							"_nr": "5",
							"__text": "Operatii de intrare/iesire"
						},
						{
							"_nr": "6",
							"__text": "Limbajul de programare FOXPRO"
						},
						{
							"_nr": "7",
							"__text": "Generatoare"
						}
					]
				},
				"_id": "1013",
				"_isbn": "973-85773-7-3"
			},
			{
				"titlu": "Editie speciala Utilizare Visual FoxPro 3 pentru Window",
				"autori": {
					"autor": [
						{
							"_nume": "Michael D. Antonovich"
						},
						{
							"_nume": "Christopher R. Green"
						},
						{
							"_nume": "Val Matison"
						},
						{
							"_nume": "Steven Miller"
						},
						{
							"_nume": "Monte Mitzefelt"
						},
						{
							"_nume": "Rod Paddock"
						},
						{
							"_nume": "Patrice-Ann Rutledge"
						},
						{
							"_nume": "Jay van Santen"
						},
						{
							"_nume": "Rich Strahl"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "1056",
				"pretOriginal": "129000",
				"colectia": {
					"_numarul": "223",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Special Editon Using Visual FoxPro 3.0 for Windows",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Calin Suciu"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Nicolae Dorel Pora"
				},
				"coperta": {
					"imagine": {
						"_path": "Editie_speciala_Utilizare_Visual_FoxPro_3_pentru_Windows_Antonovich.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in Visual FoxPro 3.0"
						},
						{
							"_nr": "II",
							"__text": "Transformarea datelor in informatii"
						},
						{
							"_nr": "III",
							"__text": "Construirea aplicatiilor pe calea utilizarii obiectelor"
						},
						{
							"_nr": "VI",
							"__text": "Din secretele profesionistilor"
						},
						{
							"_nr": "V",
							"__text": "Aprofundarea programului Visual FoxPro"
						},
						{
							"_nr": "VI",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1014",
				"_isbn": "973-601-554-8"
			},
			{
				"titlu": "Dictionar de informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Pierre Morvan"
						},
						{
							"_nume": "Norma Detollenaere"
						},
						{
							"_nume": "Michel Lucas"
						},
						{
							"_nume": "Jean-Pierre Meinadier"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Niculescu",
				"numarPagini": "432",
				"pretOriginal": "0",
				"pretCumparare": "49900",
				"traducere": {
					"titluOriginal": "Dictionnaire del'informatique",
					"editura": "Larousse Bordas",
					"traducatori": {
						"traducator": [
							"Cristina Chiculescu",
							"Lucian Nicolae",
							"Elena Bitu"
						]
					},
					"_an": "1998",
					"_editia": "II",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_de_informatica_Morvan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prefata"
						},
						{
							"_nr": "2",
							"__text": "Multumiri"
						},
						{
							"_nr": "3",
							"__text": "Dictionar de informatica"
						},
						{
							"_nr": "4",
							"__text": "Lexic englez-francez-roman"
						},
						{
							"_nr": "5",
							"__text": "Principalele organisme franceze avand legatura cu informatica"
						},
						{
							"_nr": "6",
							"__text": "Elemente bibligrafice"
						}
					]
				},
				"_id": "1015",
				"_isbn": "973-568-312-1"
			},
			{
				"titlu": "Dialog cu viitorul",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Lucia Cryseea Calinescu"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "144",
				"pretOriginal": "5.25",
				"colectia": {
					"_numarul": "290",
					"_nume": "Stiinta pentru toti"
				},
				"redactori": {
					"redactor": "Dinu-Ioanitiu Nechita"
				},
				"tehnoredactori": {
					"tehnoredactor": "Stefania Mihai"
				},
				"coperta": {
					"imagine": {
						"_path": "Dialog_cu_viitorul_Diamandi.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Informatica la indemina tuturor"
						},
						{
							"_nr": "2",
							"__text": "Arta conversatiei cu calculatorul"
						},
						{
							"_nr": "3",
							"__text": "De ce LOGO?"
						},
						{
							"_nr": "4",
							"__text": "Cel mai nou...cel mai ieftin...si performant"
						},
						{
							"_nr": "5",
							"__text": "Mica istorie: de la experiment...la un program national"
						}
					]
				},
				"_id": "1016",
				"_isbn": ""
			},
			{
				"titlu": "Stii sa utilizezi calculatorul? 700 de teste pentru obtinerea Permisului European de Conducere a Computerului",
				"autori": {
					"autor": {
						"_nume": "Ana Dulu"
					}
				},
				"anAparitie": "2003",
				"editura": "Andreco",
				"numarPagini": "406",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Stii_sa_utilizezi_calculatorul_700_de_teste_pentru_obtinerea_Permisului_European_de_Conducere_a_Computerului_Dulu.jpg"
					},
					"__text": "George Mirita"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza ale tehnologiei informatiei"
						},
						{
							"_nr": "2",
							"__text": "Utilizarea computerului si organizarea fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Procese de text"
						},
						{
							"_nr": "4",
							"__text": "Calcul tabelar"
						},
						{
							"_nr": "5",
							"__text": "baze de date"
						},
						{
							"_nr": "6",
							"__text": "Prezentari"
						},
						{
							"_nr": "7",
							"__text": "Informatie si comunicare"
						}
					]
				},
				"_id": "1017",
				"_isbn": "973-99140-4-7"
			},
			{
				"titlu": "Primii pasi in retele fara fir",
				"autori": {
					"autor": {
						"_nume": "Jim Geier"
					}
				},
				"anAparitie": "2005",
				"editura": "Corint",
				"numarPagini": "237",
				"pretOriginal": "300000",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "Wireless Networks first-step",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Cristian Mihai"
					},
					"_an": "2005",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alexandru Mitrea"
				},
				"coperta": {
					"imagine": {
						"_path": "Primii_pasi_in_retele_fara_fir_Geier.jpg"
					},
					"__text": "Alexandru Mitrea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Lumea retelelor fara fir: o introducere in concepte"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura sistemului fara fir: cum functioneaza retelele fara fir"
						},
						{
							"_nr": "3",
							"__text": "Fundamentele semnalelor de radiofrecventa si ale semnalelor luminoase: mediul invizibil"
						},
						{
							"_nr": "4",
							"__text": "PAN-uri fara fir: retele pentru spatii restranse"
						},
						{
							"_nr": "5",
							"__text": "LAN-urile fara fir: retele pentru cladiri si complexe de cladiri"
						},
						{
							"_nr": "6",
							"__text": "MAN-urile fara fir: retele pentru conextarea cladirilor si a locatiilor aflate la distanta"
						},
						{
							"_nr": "7",
							"__text": "WAN-uri fara fir: retele pentru conexiuni pe tot globul"
						},
						{
							"_nr": "7",
							"__text": "WAN-uri fara fir: retele pentru conexiuni pe tot globul"
						},
						{
							"_nr": "8",
							"__text": "Securitatea retelor fara fir: protejarea resurselor de informatii"
						}
					]
				},
				"_id": "1018",
				"_isbn": "973-653-709-9"
			},
			{
				"titlu": "Primii pasi in retele retele locale bazate pe comutatoare",
				"autori": {
					"autor": {
						"_nume": "Matthew J. Castellli"
					}
				},
				"anAparitie": "2005",
				"editura": "Corint",
				"numarPagini": "384",
				"pretOriginal": "300000",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "LAN Switching first-step",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Cristian Mihai"
					},
					"_an": "2005",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Calin Tuta"
				},
				"coperta": {
					"imagine": {
						"_path": "Primii_pasi_in_retele_locale_bazate_pe_comutatoare_Castelli.jpg"
					},
					"__text": "Mihaela Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bazele retelor de calculatoare"
						},
						{
							"_nr": "2",
							"__text": "Modele si standarde de retea"
						},
						{
							"_nr": "3",
							"__text": "Introducere in documeniul retelelor locale"
						},
						{
							"_nr": "4",
							"__text": "Arhitectura traditionala a retelelor LAN"
						},
						{
							"_nr": "5",
							"__text": "Retele LAN Ethernet"
						},
						{
							"_nr": "6",
							"__text": "Cum functioneaza un comutator"
						},
						{
							"_nr": "7",
							"__text": "Protocolul arborelui acoperitor (STP)"
						},
						{
							"_nr": "8",
							"__text": "Retele locale virtuale (retle VLAN)"
						},
						{
							"_nr": "9",
							"__text": "Securitatea retelelor bazate pe comutatoare"
						},
						{
							"_nr": "10",
							"__text": "Proiectarea retelelor bazate pe comutatoare"
						},
						{
							"_nr": "11",
							"__text": "Administrarea retelelor bazate pe comutatoare"
						},
						{
							"_nr": "12",
							"__text": "Studii de caz pentru retelele bazate pe comutatoare"
						}
					]
				},
				"_id": "1019",
				"_isbn": "973-653-740-4"
			},
			{
				"titlu": "Visual Basic.NET pentru Windows:ghid de invatare rapida prin imagini",
				"autori": {
					"autor": {
						"_nume": "Harold Davis"
					}
				},
				"anAparitie": "2004",
				"editura": "Corint",
				"numarPagini": "584",
				"pretOriginal": "400000",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "Visual Basic.NET for Windows. Visual QuickStart Guide",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": [
							"Oana Paun",
							"Dan-Andrei Dimitriu"
						]
					},
					"_an": "2005",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Visual_Basic_NET_pentru_Windows_Ghid_de_invatare_rapida_prin_imagini_Davis.jpg"
					},
					"__text": "Andreea Apostol, Gabriel Iancu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea mediului Visual Studio.NET"
						},
						{
							"_nr": "2",
							"__text": "Crearea unui serviciu Web"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu clasele"
						},
						{
							"_nr": "4",
							"__text": "Interfete pentru clase"
						},
						{
							"_nr": "5",
							"__text": "Form-uri Windows"
						},
						{
							"_nr": "6",
							"__text": "Consumarea unui serviciu Web"
						},
						{
							"_nr": "7",
							"__text": "Casetele de mesaj si instructiunile if"
						},
						{
							"_nr": "8",
							"__text": "Lucrul cu form-urile MDI"
						},
						{
							"_nr": "9",
							"__text": "Exceptii si depanarea"
						},
						{
							"_nr": "10",
							"__text": "Controale care accepta date introduse de utilizator"
						},
						{
							"_nr": "11",
							"__text": "Componenta Timer - control de timp"
						},
						{
							"_nr": "12",
							"__text": "Meniurile"
						},
						{
							"_nr": "13",
							"__text": "Controalele caseta de dialog standard"
						},
						{
							"_nr": "14",
							"__text": "Inventarul de obiecte"
						},
						{
							"_nr": "15",
							"__text": "XML, date si ADO.NET"
						},
						{
							"_nr": "16",
							"__text": "Aplicatii web ASP.NET"
						}
					]
				},
				"_id": "1020",
				"_isbn": "973-653-638-6"
			},
			{
				"titlu": "Scheme logice si programe FORTRAN - Culegere de probleme",
				"autori": {
					"autor": {
						"_nume": "Grigor Moldovan"
					}
				},
				"anAparitie": "1978",
				"editura": "Didactica si pedagogica",
				"tiraj": "25000",
				"numarPagini": "188",
				"pretOriginal": "5.85",
				"redactori": {
					"redactor": "Valentin Radu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Petre Naziru"
				},
				"coperta": {
					"imagine": {
						"_path": "Scheme_logice_si_programe_Fortran_Culegere_de_probleme_Moldovan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Algoritmi si descrierea lor"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Indicatii la problemele propuse"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Solutii la problemele propuse"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Elemente ale limbajului FORTRN si programe FORTRAN pentru unele probleme propuse"
						}
					]
				},
				"_id": "1021"
			},
			{
				"titlu": "Informatica pentru grupele de performanta (gimnaziu)",
				"autori": {
					"autor": [
						{
							"_nume": "Ligia Garlea"
						},
						{
							"_nume": "Adrian Negreanu Maior"
						},
						{
							"_nume": "Adrian Pintea"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Dacia educational",
				"numarPagini": "156",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Clara Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_grupele_de_performanta_gimnaziu_Ionescu.jpg"
					},
					"__text": "Sorin Luca"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in programarea calculatoarelor"
						},
						{
							"_nr": "2",
							"__text": "Cum descrierem algoritmii? Cyum scriem programe?"
						},
						{
							"_nr": "3",
							"__text": "Structura generala a unui program Pascal"
						},
						{
							"_nr": "4",
							"__text": "Structura repetitiva"
						},
						{
							"_nr": "5",
							"__text": "Maxim si minim"
						},
						{
							"_nr": "6",
							"__text": "Operatii cu numere intregi"
						},
						{
							"_nr": "7",
							"__text": "Divizibilitate si numere prime"
						},
						{
							"_nr": "8",
							"__text": "Tipul tablou"
						},
						{
							"_nr": "9",
							"__text": "Tablouri bidimensionale"
						},
						{
							"_nr": "10",
							"__text": "Numere mari"
						},
						{
							"_nr": "11",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "12",
							"__text": "Tipul inregistrare"
						},
						{
							"_nr": "13",
							"__text": "Tipul multime"
						},
						{
							"_nr": "14",
							"__text": "Probleme recapitulare"
						}
					]
				},
				"_id": "1022",
				"_isbn": "973-35-1759-3"
			},
			{
				"titlu": "Programarea sistematica in limbajele Pascal si FORTRAN",
				"autori": {
					"autor": [
						{
							"_nume": "Luca-Dan Serbanati"
						},
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Valeriu Iorga"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Tehnica",
				"numarPagini": "404",
				"pretOriginal": "28",
				"redactori": {
					"redactor": "Lili Ticos"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_sistematica_in_limbele_Pascal_si_Fortran_Serbanati.jpg"
					},
					"__text": "Mariana Zielinski"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Scurt istoric"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Echipamente ale functionarii si programarii calculatoarelor"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Programarea in PASCAL"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Programarea in FORTRAN"
						},
						{
							"_nr": "Partea a cincea",
							"__text": "Depanarea programelor si programe complexe"
						},
						{
							"_nr": "Anexe",
							"__text": "Implementarea limbajelor PASCAL si FORTRAN la calculatoarele romanesti. Extensii ale limbajului FORTRAN"
						}
					]
				},
				"_id": "1023",
				"_isbn": ""
			},
			{
				"titlu": "Initiere in Word XP",
				"autori": {
					"autor": [
						{
							"_nume": "Eugen Popescu"
						},
						{
							"_nume": "Florentin Iovan"
						},
						{
							"_nume": "Mary Petrescu"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Else",
				"numarPagini": "110",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "8",
					"_nume": "Informatica fara meditator"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_Word_XP_Popescu.jpg"
					},
					"__text": "Florentin Iovan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Operatii cu blocuri de text"
						},
						{
							"_nr": "3",
							"__text": "Formatarea documentelor"
						},
						{
							"_nr": "4",
							"__text": "Imagini"
						},
						{
							"_nr": "5",
							"__text": "Crearea desenelor in Word"
						},
						{
							"_nr": "6",
							"__text": "Crearea efectelor speciale cu ajutorul aplicatiei WordArt (texte artistice)"
						},
						{
							"_nr": "7",
							"__text": "Tabele"
						},
						{
							"_nr": "8",
							"__text": "Editorul de ecuatii"
						},
						{
							"_nr": "9",
							"__text": "Diagrame (grafice)"
						},
						{
							"_nr": "10",
							"__text": "Crearea cuprinsului si a indexului"
						},
						{
							"_nr": "11",
							"__text": "Tiparirea unui document la imprimanta"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea barelor cu instrumente"
						}
					]
				},
				"_id": "1024",
				"_isbn": "978-973-7708-38-0"
			},
			{
				"titlu": "Creierul electronic",
				"autori": {
					"autor": {
						"_nume": "Edmond Nicolau"
					}
				},
				"anAparitie": "1979",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "112",
				"pretOriginal": "2.60",
				"colectia": {
					"_numarul": "64",
					"_nume": "Stiinta pentru toti"
				},
				"redactori": {
					"redactor": "Sanda Stoian"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mircea Nasta"
				},
				"coperta": {
					"imagine": {
						"_path": "Creierul_electronic_Nicolau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "'Creierul electronic'"
						},
						{
							"_nr": "II",
							"__text": "Informatie, teoria informatiei, informatica si explozia informationala"
						},
						{
							"_nr": "III",
							"__text": "Calcualtare electronice"
						},
						{
							"_nr": "IV",
							"__text": "Calculatoare digitale"
						},
						{
							"_nr": "V",
							"__text": "Alfabete si reprezentarea literelor"
						},
						{
							"_nr": "VI",
							"__text": "Suportul informatiei"
						},
						{
							"_nr": "VII",
							"__text": "Sisteme de numerare"
						},
						{
							"_nr": "VIII",
							"__text": "Numere binare"
						},
						{
							"_nr": "IX",
							"__text": "Calcule binare"
						},
						{
							"_nr": "X",
							"__text": "Cum putem reprezenta semnalele in calculatoarele digitale?"
						},
						{
							"_nr": "XI",
							"__text": "Programe si algoritmi"
						},
						{
							"_nr": "XII",
							"__text": "Cum decide calculatorul?"
						},
						{
							"_nr": "XIII",
							"__text": "Teoreme functionale"
						},
						{
							"_nr": "XIV",
							"__text": "Blocuri functionale"
						},
						{
							"_nr": "XV",
							"__text": "Automate, intrari, iesiri si stari"
						},
						{
							"_nr": "XVI",
							"__text": "Dispozitive de memorie"
						},
						{
							"_nr": "XVII",
							"__text": "Unitatea centrala"
						},
						{
							"_nr": "XVIII",
							"__text": "Dispozitivele de iesire"
						},
						{
							"_nr": "XIX",
							"__text": "Tendinte contemporane"
						},
						{
							"_nr": "XX",
							"__text": "Calculatoarele deceniului noua"
						},
						{
							"_nr": "XXI",
							"__text": "Calculatorul electronic si gadirea umana"
						}
					]
				},
				"_id": "1025"
			},
			{
				"titlu": "Proiectarea aplicatiilor de tip HELP sub WINDOWS",
				"autori": {
					"autor": {
						"_nume": "Rodica Baciu"
					}
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "128",
				"pretOriginal": "8500",
				"colectia": {
					"_numarul": "59",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_aplicatiilor_de_tip_HELP_sub_Windows_Baciu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Etapele preliminare pentru crearea unei aplicatii de tip HELP"
						},
						{
							"_nr": "2",
							"__text": "Crearea fisierelor TOPIC"
						},
						{
							"_nr": "3",
							"__text": "Crearea fisierului PROIECT"
						},
						{
							"_nr": "4",
							"__text": "Compilarea fisierelor HELP"
						},
						{
							"_nr": "5",
							"__text": "Adaugarea imaginilor grafice"
						},
						{
							"_nr": "6",
							"__text": "Folosirea macro-urilor"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea meniurilor"
						},
						{
							"_nr": "8",
							"__text": "Definirea bookmark-urilor"
						},
						{
							"_nr": "9",
							"__text": "Crearea HELP-ului ce poate fi accesat de o aplicatie"
						},
						{
							"_nr": "10",
							"__text": "Un exemplu de o aplicatie HELP"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Mesajele de eroare care se dau la compilarea cu compilatorul HC.31"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Topicele fisierului EXEMPLU.RTF. Topicele fisierului DEFINIT.FTF. Fisierul proiect EXENPLU.HPJ. Fisierul BACK.BMP"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Tabel pentru pastarea evidentei codurilor de control"
						}
					]
				},
				"_id": "1026",
				"_isbn": "973-9215-31-9"
			},
			{
				"titlu": "Editorul de ecuatii sub Windows",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "1997",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "64",
				"pretOriginal": "13000",
				"colectia": {
					"_numarul": "71",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Editorul_de_ecuatii_sub_Windows_Kovacs.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza despre editorul de ecuatii"
						},
						{
							"_nr": "2",
							"__text": "Stiluri si fonturi"
						},
						{
							"_nr": "3",
							"__text": "Dimensiunea corespunzatoare diferitelor tipuri"
						},
						{
							"_nr": "4",
							"__text": "Spatiere si aliniere"
						},
						{
							"_nr": "5",
							"__text": "Paleta cu simboluri"
						},
						{
							"_nr": "6",
							"__text": "Paleta cu modele"
						},
						{
							"_nr": "7",
							"__text": "Meniurile si comenzile editorului de ecuatii"
						},
						{
							"_nr": "8",
							"__text": "Taste si combinatii de taste utilizate de editorul de ecuatii"
						}
					]
				},
				"_id": "1027",
				"_isbn": "973-9215-55-6"
			},
			{
				"titlu": "De la Pascal la Delphi",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1995",
				"editura": "L and S InfoMat",
				"numarPagini": "320",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "De_la_Pascal_la_Delphi_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Turbo Pascal"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Borland Delphi"
						}
					]
				},
				"_id": "1028",
				"_isbn": "973-99376-0-8"
			},
			{
				"titlu": "Informatica - Caiet de lucru [VI]",
				"autori": {
					"autor": {
						"_nume": "Liliana Arici"
					}
				},
				"anAparitie": "1995",
				"editura": "Polirom",
				"numarPagini": "96",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Madalina Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Caiet_de_lucru_VI_Arici.jpg"
					},
					"__text": "Angela Rotaru-Serbenco"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de programare in limbajul Pascal"
						},
						{
							"_nr": "II",
							"__text": "Pagini Web cu Microsoft Word 2003"
						}
					]
				},
				"_id": "1029",
				"_isbn": "973-99376-0-8"
			},
			{
				"titlu": "Proiectarea structurilor asistata de calculator",
				"autori": {
					"autor": {
						"_nume": "Gabriel Adriana Serb"
					}
				},
				"anAparitie": "1989",
				"editura": "Militara",
				"numarPagini": "208",
				"pretOriginal": "23.50",
				"redactori": {
					"redactor": "Al. Mihalcea"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_structurilor_asitata_de_calculator_Serb.jpg"
					},
					"__text": "A. Ionescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Preocupari actuale in proiectarea si cercetarea asistate de calculator"
						},
						{
							"_nr": "2",
							"__text": "Alocarea dinamica de memorie in limbaj FORTRAN"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea simularii numerice prin programul PMSD in analiza dinamica si seismica a structurilor"
						},
						{
							"_nr": "4",
							"__text": "Teleprelucrarea conversationala in limbaj FORTRAN"
						},
						{
							"_nr": "5",
							"__text": "Programele din biblioteca PROGLIN"
						}
					]
				},
				"_id": "1030",
				"_isbn": "973-32-0009-3"
			},
			{
				"titlu": "Coduri detectoare si corectoare de erori",
				"autori": {
					"autor": {
						"_nume": "G. Cullmann"
					}
				},
				"anAparitie": "1972",
				"editura": "Tehnica",
				"tiraj": "4300+110 exemplare brosate",
				"numarPagini": "292",
				"pretOriginal": "11",
				"traducere": {
					"titluOriginal": "Codes detecteurs et correcteurs d' erreurs",
					"editura": "Dunod",
					"traducatori": {
						"traducator": "Valentina Neicov"
					},
					"_an": "1967",
					"_editia": "I",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Valentina Bucur"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Coduri_detectoare_si_corectoare_de_erori_Cullmann.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Recapitularea catorva proprietati importante ale numerelor intregi"
						},
						{
							"_nr": "2",
							"__text": "Algebra moderna"
						},
						{
							"_nr": "3",
							"__text": "Algebra liniara"
						},
						{
							"_nr": "4",
							"__text": "Coduri detectoare si corectoare de erori"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Cateva exemple de coduri"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Polinoame ireductibile si primitive pe C.G. (2)"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Puteri ale lui 2"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Logaritmii in baza 2 ai numerelor de la 1 la 100"
						}
					]
				},
				"_id": "1031",
				"_isbn": ""
			},
			{
				"titlu": "Adobe GoLive 5.0",
				"autori": {
					"autor": {
						"_nume": "[Adobe Press]"
					}
				},
				"anAparitie": "2002",
				"editura": "Teora",
				"numarPagini": "318",
				"pretOriginal": "290000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "413",
					"_nume": "Calculatoare Personale"
				},
				"traducere": {
					"titluOriginal": "Adobe GoLive 5.0 Classroom in a Book",
					"editura": "Adobe Press",
					"traducatori": {
						"traducator": "Radu Biris"
					},
					"_an": "2001",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Adobe_GoLive_5_0_Adobe_Press.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Prezentarea suprafetei de lucru"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Lucrul cu text si tabele"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Machetarea paginilor Web"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Utilizarea elementelor Smart Objects"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Crearea legaturilor"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Utilizarea cadrelor"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Crearea efectelor Rollover"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Crearea animatiilor"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Utilizarea actiunilor si limbajul JavaScript"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Crearea formularelor"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Utilizarea foilor de stil in cascada"
						},
						{
							"_nr": "Lectia 12",
							"__text": "Combinarea animatiilor Adobe LiveMotion cu filmele QuickTime"
						},
						{
							"_nr": "Lectia 13",
							"__text": "Gestionarea site-urilor Web"
						}
					]
				},
				"_id": "1032",
				"_isbn": "973-20-0252-9"
			},
			{
				"titlu": "Matematici speciale. rezolvarea problemelor asistata de calculator cu exemplificari in Derive, MathCAD, Maple, Mathematica",
				"autori": {
					"autor": [
						{
							"_nume": "Ernest Scheiber"
						},
						{
							"_nume": "Mircea Lupu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Tehnica",
				"numarPagini": "172",
				"pretOriginal": "22000",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Margareta Chilim"
				},
				"tehnoredactori": {
					"tehnoredactor": "Diana Jilavu"
				},
				"coperta": {
					"imagine": {
						"_path": "Matematici_speciale_Rezolvarea_problemelor_asistata_de_calculator_cu_exemplificari_in_Derive_MathCAD_Maple_Mathematica.jpg"
					},
					"__text": "Margareta Chilim"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aplicatii elementare de calcul simbolic"
						},
						{
							"_nr": "2",
							"__text": "Ecuatii diferentiale ordinare"
						},
						{
							"_nr": "3",
							"__text": "Serii Fourier"
						},
						{
							"_nr": "4",
							"__text": "Elemente de teoria campului"
						},
						{
							"_nr": "5",
							"__text": "Functii complexe"
						},
						{
							"_nr": "6",
							"__text": "Transformari integrale"
						},
						{
							"_nr": "7",
							"__text": "Ecuatii integrale"
						},
						{
							"_nr": "8",
							"__text": "Ecuatii cu derivate partiale"
						}
					]
				},
				"_id": "1033",
				"_isbn": "973-31-1193-7"
			},
			{
				"titlu": "Introducere in WAP, WML si WMLScript. Notiuni WAP, pagini WAP/WML, utilizare WML, utilizare WMLScript, realizare Site WAP",
				"autori": {
					"autor": {
						"_nume": "Calin Ioan Acu"
					}
				},
				"anAparitie": "2004",
				"editura": "Albastra",
				"numarPagini": "380",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "187",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_WAP_WML_si_WMLScript_notiuni_WAP_pagini_WAP_WML_utilizare_WML_utilizare_WMLScript_realizare_Site_WAp_Acu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurta introducere"
						},
						{
							"_nr": "2",
							"__text": "WAP si navigatoarele WAP"
						},
						{
							"_nr": "3",
							"__text": "Fundamente WAP si WML"
						},
						{
							"_nr": "4",
							"__text": "Marcaje WML"
						},
						{
							"_nr": "5",
							"__text": "Limbajul WMLScript"
						},
						{
							"_nr": "6",
							"__text": "Realizarea unui site WAP"
						}
					]
				},
				"_id": "1034",
				"_isbn": "973-650-137-X"
			},
			{
				"titlu": "Dezvoltarea aplicatiilor in C/C++ sub sistemul de operare UNIX (reeditare)",
				"autori": {
					"autor": {
						"_nume": "Andrei Baranga"
					}
				},
				"anAparitie": "2009",
				"editura": "Albastra",
				"numarPagini": "126",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "179",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_aplicatiilor_in_C_Cpp_sub_sistemul_de_operare_UNIX_Baranga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Utilitare pentru dezvolarea de aplicatii scrise in limbajul C"
						},
						{
							"_nr": "2",
							"__text": "Interfata aplicatiilor cu sistemul de operare"
						},
						{
							"_nr": "3",
							"__text": "Gestionarea intrarilor si iesirilor"
						},
						{
							"_nr": "4",
							"__text": "Gestionarea terminalelor"
						},
						{
							"_nr": "5",
							"__text": "Gestionarea proceselor"
						},
						{
							"_nr": "6",
							"__text": "Gestionarea semnalelor"
						},
						{
							"_nr": "7",
							"__text": "Comunicarea intre procese cu ajutorul fisierelor speciale de tip tub (PIPE)"
						},
						{
							"_nr": "8",
							"__text": "Comunicarea intre procese cu ajutorul IPC SYSTEM V"
						},
						{
							"_nr": "9",
							"__text": "Alte apeluri utile"
						}
					]
				},
				"_id": "1035",
				"_isbn": "978-973-650-264-4"
			},
			{
				"titlu": "Introducere in sistemul de operare UNIX",
				"autori": {
					"autor": {
						"_nume": "Andrei Baranga"
					}
				},
				"anAparitie": "2007",
				"editura": "Albastra",
				"numarPagini": "90",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "212",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_sistemul_de_operare_UNIX_Baranga.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Vedere generala"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de gestiune a fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de gestiune a proceselor"
						},
						{
							"_nr": "4",
							"__text": "Comenzile principlae ale sistemului"
						},
						{
							"_nr": "5",
							"__text": "Limbajele de comenzi shell"
						},
						{
							"_nr": "6",
							"__text": "Editorul de texte vi"
						},
						{
							"_nr": "7",
							"__text": "Lucrul in retea"
						}
					]
				},
				"_id": "1036",
				"_isbn": "978-973-650-206-4"
			},
			{
				"titlu": "Programare in inteligenta artificiala: LISP si PROLOG (reeditare)",
				"autori": {
					"autor": [
						{
							"_nume": "Horia Pop"
						},
						{
							"_nume": "Gabriela Serban"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Albastra",
				"numarPagini": "186",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "166",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programare_in_inteligenta_artificiala_LISP_si_PROLOG_Pop.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Programare functionala. Limbajul Lisp"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Programare logica. Limbajul Prolog"
						}
					]
				},
				"_id": "1037",
				"_isbn": "973-650-104-3"
			},
			{
				"titlu": "Evolutie si inteligenta artificiala: paradigme moderne si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Ruxandra Stoean"
						},
						{
							"_nume": "Catalin Stoean"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Albastra",
				"numarPagini": "168",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "242",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Evolutie_si_inteligenta_artificiala_Paradigme_moderne_si_aplicatii_Stoean.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Descierea problemelor"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi evolutivi"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi culturali"
						},
						{
							"_nr": "4",
							"__text": "Coevolutie"
						},
						{
							"_nr": "5",
							"__text": "Algoritmi multinationali"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi bazati pe credinte"
						},
						{
							"_nr": "7",
							"__text": "Conservarea speciilor"
						},
						{
							"_nr": "8",
							"__text": "Stoluri de particule"
						},
						{
							"_nr": "9",
							"__text": "Optimizarea prin colonii de furnici"
						},
						{
							"_nr": "10",
							"__text": "Sisteme artificiale imune"
						}
					]
				},
				"_id": "1038",
				"_isbn": "978-973-650-277-4"
			},
			{
				"titlu": "Proictarea asistata de calculator a sistemelor discrete",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Cosma"
						},
						{
							"_nume": "Francisc Momeo"
						},
						{
							"_nume": "Petrica Dumitru"
						},
						{
							"_nume": "Lucian Nica"
						},
						{
							"_nume": "Dan Marinescu"
						},
						{
							"_nume": "Stefan Diaconescu"
						},
						{
							"_nume": "Monica Grigorescu"
						},
						{
							"_nume": "Marius Balan"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "204",
				"pretOriginal": "20",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_asistata_de_calculator_a_sistemelor_discrete_Cosma.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "O metoda de analiza si specificare completa a functionarii unui sistem discret (automatul finit functional si criteriul separarii in spatiu sau timp)"
						},
						{
							"_nr": "3",
							"__text": "Limbaje de specificare a sistemelor numerice"
						},
						{
							"_nr": "4",
							"__text": "Automatizarea proiectarii microcalculatoarelor"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea dispozitivelor bazate pe microprocesoare (DBM)"
						},
						{
							"_nr": "6",
							"__text": "Asamblarea"
						},
						{
							"_nr": "7",
							"__text": "Interconectarea"
						},
						{
							"_nr": "8",
							"__text": "Generarea automata a secventelor de text"
						}
					]
				},
				"_id": "1039",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare, microcalculatoare, minicalculatoare",
				"autori": {
					"autor": {
						"_nume": "Granino A. Korn"
					}
				},
				"anAparitie": "1981",
				"editura": "Tehnica",
				"numarPagini": "440",
				"pretOriginal": "43",
				"traducere": {
					"titluOriginal": "Microprocessors and small digital computer systems for engincers and scientists",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": [
							"Adrian Davidoviciu",
							"Rodica Boconcios",
							"Radu Magda",
							"Andrei Romulus"
						]
					},
					"_an": "1977",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Micoprocesoare_microcalculatoare_minicalculatoare_Korn.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de mini/microcalculatoare"
						},
						{
							"_nr": "2",
							"__text": "Unitati centrale si seturi de instructiuni"
						},
						{
							"_nr": "3",
							"__text": "Programarea cu asambloare si macroasambloare"
						},
						{
							"_nr": "4",
							"__text": "Realizarea de interfete pentru interconectarea microprocesoarelor si minicalculatoarelor cu diverse dispozitive si echipamente"
						},
						{
							"_nr": "5",
							"__text": "Operarea calculatorului, programele si elaborarea programelor"
						},
						{
							"_nr": "6",
							"__text": "Sisteme de microcalculatore si decizii de proiectare"
						},
						{
							"_nr": "7",
							"__text": "Familii de mini/microcalculatoare"
						},
						{
							"_nr": "Anexa A",
							"__text": "Material de referinta pentru limbaje de nivel inalt"
						},
						{
							"_nr": "Anexa B",
							"__text": "Table de referinta"
						},
						{
							"_nr": "Anexa C",
							"__text": "Microcalculatoare si minicalculatore romanesti"
						}
					]
				},
				"_id": "1040",
				"_isbn": ""
			},
			{
				"titlu": "QuarkXPress 4 si 5 Shortcurs pentru PC si MAC",
				"autori": {
					"autor": {
						"_nume": "David Blatner"
					}
				},
				"anAparitie": "2003",
				"editura": "Corint",
				"numarPagini": "160",
				"pretOriginal": "131000",
				"pretCumparare": "90000",
				"traducere": {
					"titluOriginal": "QuarkXPress Power Shortcuts. Productivity Shortcuts for QuarkxPress 4 and 5",
					"editura": "Peachpit Press",
					"traducatori": {
						"traducator": "Mihaela Mazilu"
					},
					"_an": "2003",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "QuarkXPress_4_si_5_Shortcuts_pentru_PC_si_MAC_Blatner.jpg"
					},
					"__text": "Andreea Apostol, Walter Riess"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Palete"
						},
						{
							"_nr": "3",
							"__text": "Interfata"
						},
						{
							"_nr": "4",
							"__text": "Meniuri"
						},
						{
							"_nr": "5",
							"__text": "Mutarea"
						},
						{
							"_nr": "6",
							"__text": "Textul"
						},
						{
							"_nr": "7",
							"__text": "Imagini si linii"
						},
						{
							"_nr": "8",
							"__text": "Surprize"
						},
						{
							"_nr": "9",
							"__text": "Taste functionale"
						},
						{
							"_nr": "10",
							"__text": "Meniuri contextuale"
						},
						{
							"_nr": "11",
							"__text": "Caractere speciale"
						},
						{
							"_nr": "12",
							"__text": "setul de caractere ANSI"
						},
						{
							"_nr": "13",
							"__text": "zece secrete bine pastrate"
						},
						{
							"_nr": "14",
							"__text": "Index"
						}
					]
				},
				"_id": "1041",
				"_isbn": "973-653-425-1"
			},
			{
				"titlu": "Realizarea filmelor cu Windows XP: ghid rapid de proiecte prin imagini",
				"autori": {
					"autor": {
						"_nume": "Jan Ozer"
					}
				},
				"anAparitie": "2004",
				"editura": "Corint",
				"numarPagini": "142",
				"pretOriginal": "100000",
				"pretCumparare": "90000",
				"traducere": {
					"titluOriginal": "Making a movie with Windows XP: Visual QuickProject Guide",
					"editura": "Peachpit Press",
					"traducatori": {
						"traducator": "Mihai Danciu"
					},
					"_an": "2004",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": [
						"Cristina Mihai",
						"Alice Raluca Petrescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicolae Bucur"
				},
				"coperta": {
					"imagine": {
						"_path": "Realizarea_filmelor_cu_Windows_XP_Ghid_rapid_de_proiecte_prin_imagini_Ozer.jpg"
					},
					"__text": "Nicolae Bucur"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programul Movie Maker"
						},
						{
							"_nr": "2",
							"__text": "Colectarea articolelor proiectului"
						},
						{
							"_nr": "3",
							"__text": "Pregatirea clipurilor"
						},
						{
							"_nr": "4",
							"__text": "Asamblarea clipurilor"
						},
						{
							"_nr": "5",
							"__text": "Inserara tranzitiilor"
						},
						{
							"_nr": "6",
							"__text": "Aplicarea efectelor speciale"
						},
						{
							"_nr": "7",
							"__text": "Crearea titlurilor"
						},
						{
							"_nr": "8",
							"__text": "Utilizarea meterialelor audio"
						},
						{
							"_nr": "9",
							"__text": "Crearea autofilmelor"
						},
						{
							"_nr": "10",
							"__text": "Operatiile finale"
						}
					]
				},
				"_id": "1042",
				"_isbn": "973-653-710-2"
			},
			{
				"titlu": "QuarkXPress 6 pentru Windows si Macintoch: ghid de invatare rapida prin imagini",
				"autori": {
					"autor": [
						{
							"_nume": "Elaine Weinmann"
						},
						{
							"_nume": "Peter Lourekas"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Corint",
				"numarPagini": "540",
				"pretOriginal": "390000",
				"pretCumparare": "90000",
				"traducere": {
					"titluOriginal": "QuickXPress 6 for Macintoch and Windows: Visual QuickStart Guide",
					"editura": "Peachpit Press",
					"traducatori": {
						"traducator": "Liliana Dabulescu"
					},
					"_an": "2004",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Alice Raluca Petrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "QuarkXPress_6_pentru_Windows_si_Macintosh_Ghid_de_invatare_rapida_prin_imagini_Weinmann.jpg"
					},
					"__text": "Andreea Apostol,Gabriel Iancu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Primii pasi"
						},
						{
							"_nr": "3",
							"__text": "Navigare/Anulare"
						},
						{
							"_nr": "4",
							"__text": "Introducerea textului"
						},
						{
							"_nr": "5",
							"__text": "Desfasurara textului"
						},
						{
							"_nr": "6",
							"__text": "Formate"
						},
						{
							"_nr": "7",
							"__text": "Atribute tipografice"
						},
						{
							"_nr": "8",
							"__text": "Tabele si tabulari"
						},
						{
							"_nr": "9",
							"__text": "Imagini"
						},
						{
							"_nr": "10",
							"__text": "Lucrul cu mai multe elemente"
						},
						{
							"_nr": "11",
							"__text": "Imagini si text"
						},
						{
							"_nr": "12",
							"__text": "Linii"
						},
						{
							"_nr": "13",
							"__text": "Foi de stil"
						},
						{
							"_nr": "14",
							"__text": "Pagini Master"
						},
						{
							"_nr": "15",
							"__text": "Culori"
						},
						{
							"_nr": "16",
							"__text": "Straturi"
						},
						{
							"_nr": "17",
							"__text": "Cautarea si inlocuirea"
						},
						{
							"_nr": "18",
							"__text": "Elemente Bezier"
						},
						{
							"_nr": "19",
							"__text": "Machete pentru Web"
						},
						{
							"_nr": "20",
							"__text": "Biblioteci"
						},
						{
							"_nr": "21",
							"__text": "Sicronizarea"
						},
						{
							"_nr": "22",
							"__text": "Carti"
						},
						{
							"_nr": "23",
							"__text": "Preferinte"
						},
						{
							"_nr": "24",
							"__text": "Imprimarea"
						},
						{
							"_nr": "25",
							"__text": "XML"
						},
						{
							"_nr": "Anexa A",
							"__text": "Caractere speciale"
						},
						{
							"_nr": "Anexa B",
							"__text": "Scurtaturi de la tastatura"
						}
					]
				},
				"_id": "1043",
				"_isbn": "973-653-624-6"
			},
			{
				"titlu": "Invata singur programarea jocurilor in 24 de lectii",
				"autori": {
					"autor": {
						"_nume": "Mihael Morrison"
					}
				},
				"anAparitie": "2005",
				"editura": "Corint",
				"numarPagini": "488",
				"pretOriginal": "450000",
				"pretCumparare": "90000",
				"traducere": {
					"titluOriginal": "Sams Teach Yourself Game Programming in 24 hours",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Andrei Conea"
					},
					"_an": "2003",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Alice Raluca Petrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicolae Bucur"
				},
				"coperta": {
					"imagine": {
						"_path": "Invata_singur_programrea_jocurilor_in_24_de_lectii_Morrison.jpg"
					},
					"__text": "Nicolae Bucur"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Primul pas"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Interactiunea cu jucatorii"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Animarea jocurilor cu ajutorul spite-urilor"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Crearea zgomotelor cu sunet si muzica"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Programarea animatiei la nivel avansat"
						},
						{
							"_nr": "PArtea a VI-a",
							"__text": "Inteligenta jocurilor"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Imbunatatirea jocurilor"
						},
						{
							"_nr": "Partea a VIII-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1044",
				"_isbn": "973-653-652-1"
			},
			{
				"titlu": "Primii pasi in retele de calculatoare",
				"autori": {
					"autor": {
						"_nume": "Wendell Odom"
					}
				},
				"anAparitie": "2004",
				"editura": "Corint",
				"numarPagini": "510",
				"pretOriginal": "350000",
				"pretCumparare": "90000",
				"traducere": {
					"titluOriginal": "Computer Networking first-step",
					"editura": "Cisco Press",
					"traducatori": {
						"traducator": "Cristian Mihai"
					},
					"_an": "2004",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": [
						"Cristina Mihai",
						"Alice Raluca Petrescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Alexandru Mitrea"
				},
				"coperta": {
					"imagine": {
						"_path": "Primii_pasi_in_retele_de_calculatoare_Odom.jpg"
					},
					"__text": "Alexandru Mitrea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Bazele retelelor de calculatoare"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Functionarea retelelor locale (Functionarea departamentului de transport local)"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Transport si logistica: cum se face comert folosind reteua"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Calatorind pe sosele in cautarea adreselor  corecte"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Construirea unui sistem de autostrazi interstatele (inter-LAN)"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Protejarea retelei"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1045",
				"_isbn": "973-653-665-3"
			},
			{
				"titlu": "Primii pasi in securitatea retelor",
				"autori": {
					"autor": {
						"_nume": "Tom Thomas"
					}
				},
				"anAparitie": "2005",
				"editura": "Corint",
				"numarPagini": "454",
				"pretOriginal": "350000",
				"pretCumparare": "90000",
				"traducere": {
					"titluOriginal": "Network Security first-step",
					"editura": "Cisco Press",
					"traducatori": {
						"traducator": "Liliana Dabuleanu"
					},
					"_an": "2004",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alexandru Mitrea"
				},
				"coperta": {
					"imagine": {
						"_path": "Primii_pasi_in_securitatea_retelelor_Thomas.jpg"
					},
					"__text": "Alexandru Mitrea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Pe aici trebuie sa fie hackeri"
						},
						{
							"_nr": "2",
							"__text": "Politici si solutii de securitate"
						},
						{
							"_nr": "3",
							"__text": "O privire de ansamblu asupra tehnicilor de securitate"
						},
						{
							"_nr": "4",
							"__text": "Protocoale de securitate"
						},
						{
							"_nr": "5",
							"__text": "Sisteme firewall"
						},
						{
							"_nr": "6",
							"__text": "Securitatea routerelor"
						},
						{
							"_nr": "7",
							"__text": "Retele private virtuale IPSec (VPN)"
						},
						{
							"_nr": "8",
							"__text": "Securitatea retelelor fara fir"
						},
						{
							"_nr": "9",
							"__text": "Detectarea intruziunilor si sistemele Honeypot"
						},
						{
							"_nr": "10",
							"__text": "Instrumente de lucru"
						},
						{
							"_nr": "Anexa A",
							"__text": "Raspunsuri la intrebarile recapitulative"
						}
					]
				},
				"_id": "1046",
				"_isbn": "973-653-666-1"
			},
			{
				"titlu": "Probleme de informatica date la concursurile internationale",
				"autori": {
					"autor": [
						{
							"_nume": "Radu Berinde"
						},
						{
							"_nume": "Horia Andrei Ciochina"
						},
						{
							"_nume": "Dan Chinea"
						},
						{
							"_nume": "Cornel Margine"
						},
						{
							"_nume": "Adrian Atansiu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Fundatiei PRO",
				"numarPagini": "268",
				"pretOriginal": "0",
				"pretCumparare": "240000",
				"redactori": {
					"redactor": "Lucia Craciun"
				},
				"tehnoredactori": {
					"tehnoredactor": "Carmen Arbanas"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_informatica_date_la_concursurile_internationale_Berinde.jpg"
					},
					"__text": "Cristian Crisbasan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "A",
							"__text": "Olimpiada internationala de informatica"
						},
						{
							"_nr": "B",
							"__text": "Concursul de informatica al Europei Centrale"
						},
						{
							"_nr": "C",
							"__text": "Balcaniada de informatica"
						}
					]
				},
				"_id": "1047",
				"_isbn": "973-8434-12-2"
			},
			{
				"titlu": "Rank distance and apllications",
				"autori": {
					"autor": {
						"_nume": "Liviu P. Dinu"
					}
				},
				"anAparitie": "2010",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "224",
				"pretOriginal": "270000",
				"pretCumparare": "270000",
				"coperta": {
					"imagine": {
						"_path": "Rank_distance_and_applications_Dinu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introduction"
						},
						{
							"_nr": "2",
							"__text": "Rank Distance on Rankings"
						},
						{
							"_nr": "3",
							"__text": "The Mathematics of Rank Distance"
						},
						{
							"_nr": "4",
							"__text": "Rnak distance on strings"
						},
						{
							"_nr": "5",
							"__text": "Rank agrregation problem"
						},
						{
							"_nr": "6",
							"__text": "Total ans Scaled Rank Distance"
						},
						{
							"_nr": "7",
							"__text": "Rank aggregation combining scheme"
						},
						{
							"_nr": "8",
							"__text": "Rank distance in computational linguistics"
						},
						{
							"_nr": "9",
							"__text": "Rank distance in Computational Biology"
						},
						{
							"_nr": "10",
							"__text": "Rank distance and socio-cultural sciences"
						}
					]
				},
				"_id": "1048",
				"_isbn": "978-973-737-800-2"
			},
			{
				"titlu": "Componente compozabile : solutii actuale in ingineria software",
				"autori": {
					"autor": {
						"_nume": "Ioana Sora"
					}
				},
				"anAparitie": "2004",
				"editura": "Orizonturi Universitare",
				"numarPagini": "170",
				"pretOriginal": "0",
				"pretCumparare": "150000",
				"colectia": {
					"_numarul": "39",
					"_nume": "Calculatoare-Informatica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ioana Sora"
				},
				"coperta": {
					"imagine": {
						"_path": "Componente_compozabile_Solutii_actuale_in_ingineria_software_Sora.jpg"
					},
					"__text": "Ioan Sora,Dan Nitu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni fundamentale ale ingineriei software bazate pe componente"
						},
						{
							"_nr": "3",
							"__text": "Arhitecturi dinamice bazate pe componente"
						},
						{
							"_nr": "4",
							"__text": "Componente compozabile in arhitecturi multi-flux"
						},
						{
							"_nr": "5",
							"__text": "Strategii de compozitie pentru arhitecturi multi-flux"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii practice"
						}
					]
				},
				"_id": "1049",
				"_isbn": "973-638-110-2"
			},
			{
				"titlu": "Introducere in HTML si XML",
				"autori": {
					"autor": [
						{
							"_nume": "Dorin Carstoiu"
						},
						{
							"_nume": "Virginia Ecaterina Oltean"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Printech",
				"numarPagini": "98",
				"pretOriginal": "0",
				"pretCumparare": "90000",
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_HTML_si_XML_Carstoiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "World Wide Web, hipertexte, limbaje de adnotare"
						},
						{
							"_nr": "2",
							"__text": "Introducere in HTML"
						},
						{
							"_nr": "3",
							"__text": "Introducere in XML"
						}
					]
				},
				"_id": "1050",
				"_isbn": "973-652-954-1"
			},
			{
				"titlu": "Informatica in 27 de lectii - Proiect de manual pentru clasele V-VI",
				"autori": {
					"autor": {
						"_nume": "Bebe Diamandi"
					}
				},
				"anAparitie": "1996",
				"editura": "Petrion",
				"numarPagini": "144",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_in_27_de_lectii_Proiect_de_manual_pentru_clasele_V_VI_Diamandi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Fundamente"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Aplicatii in matemetica"
						},
						{
							"_nr": "PArtea a III-a",
							"__text": "Aplicatii in geometrie. Mediul LOGO"
						}
					]
				},
				"_id": "1051",
				"_isbn": "973-9116-08-6"
			},
			{
				"titlu": "Hiponimie, sinonimie, antonimie: abordare din perspectiva lingvisticii teoretice si a lingvisticii computationale",
				"autori": {
					"autor": {
						"_nume": "Verginica Barbu Mititelu"
					}
				},
				"anAparitie": "2010",
				"editura": "MatrixRom",
				"numarPagini": "234",
				"pretOriginal": "230000",
				"pretCumparare": "230000",
				"coperta": {
					"imagine": {
						"_path": "Hiponimie_sinonimie_antonimie_Abordare_din_perspectiva_lingvisticii_teoretice_si_a_lingvisticii_computationale_Mititelu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Hiponimia"
						},
						{
							"_nr": "2",
							"__text": "Sinonimia"
						},
						{
							"_nr": "3",
							"__text": "Antonimia"
						}
					]
				},
				"_id": "1052",
				"_isbn": "978-973-775-590-8"
			},
			{
				"titlu": "Aplicatii mobile pe platforma .NET",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Dospinescu"
						},
						{
							"_nume": "Virel Iftode"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Universitatii \"Alexandru Ion Cuza\"",
				"numarPagini": "334",
				"pretOriginal": "220000",
				"pretCumparare": "220000",
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_mobile_pe_platforma_NET_Dospinescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea si configurarea platformelor/componentlelor necesare dezvoltarii aplicatiilor mobile"
						},
						{
							"_nr": "2",
							"__text": "Sistemul I/O pentru aplicatii mobile"
						},
						{
							"_nr": "3",
							"__text": "Validari la nivel de formular - Regex"
						},
						{
							"_nr": "4",
							"__text": "Introducere in SQL Server Compact Edition"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii cu baze de date SQL Server 2005 - Compact Edition"
						},
						{
							"_nr": "6",
							"__text": "Replicarea bazelor de date in aplicatii mobile"
						},
						{
							"_nr": "7",
							"__text": "Model de informare globala cu infrastructura mobila"
						},
						{
							"_nr": "8",
							"__text": "Impachetarea si distribuirea aplicatiilor dispozitivelor smart"
						}
					]
				},
				"_id": "1053",
				"_isbn": "973-978-703-254-6"
			},
			{
				"titlu": "Programarea orientata pe obiect in Turbo Visio (Volumul I)",
				"autori": {
					"autor": {
						"_nume": "Liviu Jianu"
					}
				},
				"anAparitie": "1993",
				"editura": "INTARF",
				"numarPagini": "146",
				"pretOriginal": "690",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Informatia - Documentatii"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_Turbo_Vision_Vol_I_Jianu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Invatand Turbo Visio"
						},
						{
							"_nr": "2",
							"__text": "Scriind aplicatii in Turbo Vision"
						},
						{
							"_nr": "3",
							"__text": "Ierarhia obiectelor"
						},
						{
							"_nr": "4",
							"__text": "Vederi"
						},
						{
							"_nr": "5",
							"__text": "Programarea gestionata prin evenimente"
						},
						{
							"_nr": "6",
							"__text": "A scrie programe sigure"
						},
						{
							"_nr": "7",
							"__text": "Colectii"
						},
						{
							"_nr": "8",
							"__text": "Fluxuri"
						},
						{
							"_nr": "9",
							"__text": "Resurse"
						},
						{
							"_nr": "10",
							"__text": "Indicatii si secrete"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "1054",
				"_isbn": "973-95984-6-3"
			},
			{
				"titlu": "Turbo Pascal 6.0 - Ghid de utilizare",
				"autori": {
					"autor": {
						"_nume": "Sandor Kovacs"
					}
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"tiraj": "15000",
				"numarPagini": "352",
				"pretOriginal": "455",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "13",
					"_nume": "Biblioteca PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Chiorean Calin"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_6_0_Ghid_de_utilizare_Sandor.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Limbajul Turbo Pascal"
						},
						{
							"_nr": "3",
							"__text": "Unit-ul System"
						},
						{
							"_nr": "4",
							"__text": "Unit-ul Strings"
						},
						{
							"_nr": "5",
							"__text": "Unit-ul Dos"
						},
						{
							"_nr": "6",
							"__text": "Unit-ul Printer"
						},
						{
							"_nr": "7",
							"__text": "Unit-ul Graph"
						},
						{
							"_nr": "8",
							"__text": "Unit-ul Crt"
						},
						{
							"_nr": "9",
							"__text": "Unit-ul Overlay"
						},
						{
							"_nr": "10",
							"__text": "Mediul de programare Turbo Pascal 6.0"
						}
					]
				},
				"_id": "1055",
				"_isbn": ""
			},
			{
				"titlu": "Memento Unit-uri Turbo Pascal 5.5",
				"autori": {
					"autor": {
						"_nume": "Kovacs Sandor"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "30",
				"pretOriginal": "30",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "9",
					"_nume": "Memento"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Memento_Unit_uri_Turbo_Pascal_5_5_Sandor.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Unit-ul DOS"
						},
						{
							"_nr": "2",
							"__text": "Unit-ul GRAPH"
						},
						{
							"_nr": "3",
							"__text": "Unit-ul CRT"
						},
						{
							"_nr": "4",
							"__text": "Unit-ul OVERLAY"
						},
						{
							"_nr": "5",
							"__text": "Unit-ul PRINTER"
						},
						{
							"_nr": "6",
							"__text": "Lista alfabetica a functiilor Turbo Pascal"
						},
						{
							"_nr": "7",
							"__text": "Lista alfabetica a procedurilor Turbo Pascal"
						}
					]
				},
				"_id": "1056",
				"_isbn": ""
			},
			{
				"titlu": "Virusii calculatoarelor",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Marius Chiorean"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "104",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "6"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Virusii_calculatoarelor_Caprariu.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Probleme generale privind protectia informatiilor pe calculatoarele electronice"
						},
						{
							"_nr": "2",
							"__text": "Virusii calculatoarelor compatibile IBM-PC"
						},
						{
							"_nr": "3",
							"__text": "Structura programelor de tip virus"
						},
						{
							"_nr": "4",
							"__text": "Metode de prevenire a contaminarii cu virusi"
						},
						{
							"_nr": "5",
							"__text": "Principalii virusi ai calculatoarelor compatible IBM-PC"
						}
					]
				},
				"_id": "1057",
				"_isbn": ""
			},
			{
				"titlu": "Ghid de initiere Novell-NetWare",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Paiu"
						},
						{
							"_nume": "Vladimir Dumitrescu"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "15000",
				"numarPagini": "90",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Ghid de initiere",
					"_numarul": "2"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghid_de_initiere_NOVELL_NETWARE_Paiu.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Componetele unei retele locale Novell-NetWare"
						},
						{
							"_nr": "3",
							"__text": "Metode de control a retelei si componentelor implicate"
						},
						{
							"_nr": "4",
							"__text": "Tipuri de sisteme de operare NetWare"
						},
						{
							"_nr": "5",
							"__text": "Pregatirea unui WS si lansarea unei sesiuni de lucru in retea"
						},
						{
							"_nr": "6",
							"__text": "Definirea si crearea structurilor de directori"
						},
						{
							"_nr": "7",
							"__text": "Incarcarea in retea a unor aplicatii si date"
						},
						{
							"_nr": "8",
							"__text": "Adaugarea unor utilizatori si grupuri de lucru"
						},
						{
							"_nr": "9",
							"__text": "Definirea si scrierea scenariilor de login"
						},
						{
							"_nr": "10",
							"__text": "Definirea si introducerea elementelor sistemului de protectie a retelei"
						},
						{
							"_nr": "11",
							"__text": "Definirea si introducerea optiunilor de imprimare in mediul de retea locala"
						},
						{
							"_nr": "12",
							"__text": "Definirea si crearea de menu-uri de utilizator"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea practica a facilitatilor de imprimare in retea"
						},
						{
							"_nr": "14",
							"__text": "Folosirea practica a altor utilitare si comenzi de retea"
						}
					]
				},
				"_id": "1058",
				"_isbn": ""
			},
			{
				"titlu": "Retele de calculatoare",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Ionescu"
						},
						{
							"_nume": "Mitica Caraiani"
						},
						{
							"_nume": "Konya Matilda"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"numarPagini": "116",
				"pretOriginal": "145",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "9"
				},
				"redactori": {
					"redactor": "Vlad Caprariu"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_de_calcutlatoare_Ionescu.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "II",
							"__text": "Aspecte privind retelele de calculatoare"
						},
						{
							"_nr": "Anexa A",
							"__text": "Dictionar de termeni"
						}
					]
				},
				"_id": "1059",
				"_isbn": ""
			},
			{
				"titlu": "Unix - Gestiune fisierelor",
				"autori": {
					"autor": [
						{
							"_nume": "Ignat Iosif"
						},
						{
							"_nume": "Muntean Emil"
						},
						{
							"_nume": "Pusztai Kalaman"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "140",
				"pretOriginal": "295",
				"pretCumparare": "10000",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "9"
				},
				"redactori": {
					"redactor": "Poenaru Codruta"
				},
				"tehnoredactori": {
					"tehnoredactor": "Poenaru Codruta"
				},
				"coperta": {
					"imagine": {
						"_path": "Unix_Gestiunea_fisierelor_Ignat.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Functiile de nivel inferior ale sistemului de gestionare a fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Functiile de nivel superior (apelurile sistem) ale sistemului de gestionare a fisierelor"
						},
						{
							"_nr": "4",
							"__text": "Comenzile interpretorului SHELL referitoare la fisiere"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Functiile de sistem (apelurile sistem) referitoare la fisiere"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Coduri de eroare"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Comenzi SHELL referitare la fisiere"
						}
					]
				},
				"_id": "1060",
				"_isbn": "973-95718-3-2"
			},
			{
				"titlu": "Sistemul de operare DOS. Comenzi (Editia III)",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Caprariu"
						},
						{
							"_nume": "Andrei Enyedi"
						},
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Marius Chiorean"
						}
					]
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"numarPagini": "224",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"colectia": {
					"_numarul": "2",
					"_nume": "Biblioteca PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_Comenzi_Editia_III_Caprariu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Lansarea sistemului de operare DOS"
						},
						{
							"_nr": "3",
							"__text": "Organizarea si specificarea fisierelor DOS"
						},
						{
							"_nr": "4",
							"__text": "Editarea liniei de comanda"
						},
						{
							"_nr": "5",
							"__text": "Comenzi DOS"
						},
						{
							"_nr": "6",
							"__text": "Fisiere de comenzi (lot de lucrari)"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea discului Winchester (discului fix)"
						},
						{
							"_nr": "Anexa A",
							"__text": "Facilitati introduse de versiunile sistemului DOS"
						},
						{
							"_nr": "Anexa B",
							"__text": "Functii extinse ale ecranului si claviaturi"
						},
						{
							"_nr": "Anexa C",
							"__text": "Informatii specifice de tara"
						}
					]
				},
				"_id": "1061",
				"_isbn": ""
			},
			{
				"titlu": "Elemente de grafica pe calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Florica Moldoveanu"
						},
						{
							"_nume": "Marius Zaharia"
						},
						{
							"_nume": "Zoea Racovita"
						},
						{
							"_nume": "Gabriel Hera"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Universitatea Politehnica Bucuresti",
				"numarPagini": "152",
				"pretOriginal": "1150",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_grafica_pe_calculator_Indrumator_de_laborator_Moldoveanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Partea I-a"
						},
						{
							"_nr": "1",
							"__text": "Utilizarea calculatoarelor tip IBM-PC pentru dezvoltarea aplicatiilor grafice"
						},
						{
							"_nr": "2",
							"__text": "Deriver-e si moduri grafice"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de coordonate dispozitiv. Poarta de vizualizare"
						},
						{
							"_nr": "4",
							"__text": "Primitive grafice"
						},
						{
							"_nr": "5",
							"__text": "Atribute de afisarea a primitivelor grafice"
						},
						{
							"_nr": "6",
							"__text": "Textul in mod grafice"
						},
						{
							"_nr": "7",
							"__text": "Operatii cu imagini in memorie"
						},
						{
							"_nr": "8",
							"__text": "Scrierea valorilor numerice in mod grafic"
						},
						{
							"_nr": "9",
							"__text": "Citirea de la consola in mod grafic"
						},
						{
							"_nr": "10",
							"__text": "Salvarea si restaurarea imaginilor in/din fisiere"
						},
						{
							"_nr": "11",
							"__text": "Operarea cu mai multe pagini video"
						},
						{
							"_nr": "II",
							"__text": "Partea II-a"
						},
						{
							"_nr": "Tema 1",
							"__text": "Aplicatii grafice simple"
						},
						{
							"_nr": "Tema 2",
							"__text": "Fisiere .CHR"
						},
						{
							"_nr": "Tema 3",
							"__text": "Cursoare grafice"
						},
						{
							"_nr": "Tema 4",
							"__text": "Meniuri"
						},
						{
							"_nr": "Tema 5",
							"__text": "Interfata cu mouse-ul"
						},
						{
							"_nr": "Tema 6",
							"__text": "Transformari grafice 2D"
						},
						{
							"_nr": "Tema 7",
							"__text": "Transformarea de vizualizare 2D"
						},
						{
							"_nr": "Tema 8",
							"__text": "Proiectii si transformarii tridimensionale"
						},
						{
							"_nr": "Tema 9",
							"__text": "Identificarea primitivelor afisate pe ecran"
						},
						{
							"_nr": "Tema 10",
							"__text": "Curbe de aproximare"
						},
						{
							"_nr": "Tema 11",
							"__text": "Determinarea fetelor autoobturate ale poliedrelor convexe"
						}
					]
				},
				"_id": "1062",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de operare",
				"autori": {
					"autor": [
						{
							"_nume": "Marian Dobre"
						},
						{
							"_nume": "Aldrin Teganeanu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Universitatea Politehnica Bucuresti",
				"numarPagini": "260",
				"pretOriginal": "1900",
				"pretCumparare": "10000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_Dobre.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Modele ale sistemelor de operare"
						},
						{
							"_nr": "3",
							"__text": "Interfata cu sistemul de operare"
						},
						{
							"_nr": "4",
							"__text": "Administrarea informatiilor (sistemul de fisiere)"
						},
						{
							"_nr": "5",
							"__text": "Administrarea echipamentelor periferice"
						},
						{
							"_nr": "6",
							"__text": "Administrarea prceselor si a procesoarelor"
						},
						{
							"_nr": "7",
							"__text": "Administrarea memoriei"
						},
						{
							"_nr": "8",
							"__text": "Interdependenta modulelor de administare a resurselor"
						}
					]
				},
				"_id": "1063",
				"_isbn": ""
			},
			{
				"titlu": "Borland C++ 4 : ghidul programatorului",
				"autori": {
					"autor": {
						"_nume": "Nabajyoti Barkakati"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "896",
				"pretOriginal": "49000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "211",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Borland C++ 4 - Developer's Guide",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": [
							"Aurelia Merezeanu",
							"Dan Merezeanu"
						]
					},
					"_an": "1994",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Camelia Diaconescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dan Merezeanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Borland_Cpp_4_Ghidul_programatorului_Barkakati.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Initiere in Borland C++"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Programarea orientata pe obiecte"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Limbajul C++"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Aplicarea tehnicilor POO in C++"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Programarea in mediul Windows"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Aplicatii grafice"
						}
					]
				},
				"_id": "1064",
				"_isbn": "973-601-502-5"
			},
			{
				"titlu": "Utilizare Windows 95, editie speciala",
				"autori": {
					"autor": [
						{
							"_nume": "Ron Person"
						},
						{
							"_nume": "R. Michael O'Mara"
						},
						{
							"_nume": "Gerarld 'Jerry' Paul Honeycutt Jr."
						},
						{
							"_nume": "Roger Jennings"
						},
						{
							"_nume": "Rob Tidrow"
						},
						{
							"_nume": "Ian Stockell"
						},
						{
							"_nume": "Dick Cravens"
						},
						{
							"_nume": "William S. Holderby"
						},
						{
							"_nume": "Michael Marchuk"
						},
						{
							"_nume": "Gordon Meltzer"
						},
						{
							"_nume": "Glenn Fincher"
						},
						{
							"_nume": "Francis Moss"
						},
						{
							"_nume": "Paul Robichaux"
						},
						{
							"_nume": "Doug Kilarsky"
						},
						{
							"_nume": "Jim Boyce"
						},
						{
							"_nume": "Sue Plumbey"
						},
						{
							"_nume": "Alex Leavens"
						},
						{
							"_nume": "Lisa Bucki"
						},
						{
							"_nume": "Dave Plotkin"
						},
						{
							"_nume": "Peter Kent"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "1276",
				"pretOriginal": "235000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "145",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Special Edition, Using Windows 95",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Rodica Bitoiu",
							"Bogdan Constantin",
							"Nicolae Ionescu-Crutan",
							"Dan Soloveanu"
						]
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Camelia Diaconescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Nasta"
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizare_Windows_95_Editie_speciala_Person.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in Windows 95"
						},
						{
							"_nr": "2",
							"__text": "Lucrul cu Windows 95"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu aplicatiile"
						},
						{
							"_nr": "4",
							"__text": "Lucrul cu discurile si cu fisierele"
						},
						{
							"_nr": "5",
							"__text": "Folosirea mediului Windows 95 in retele de calculatoare"
						},
						{
							"_nr": "6",
							"__text": "Comunicatii prin modem realizate cu Windows 95"
						},
						{
							"_nr": "7",
							"__text": "Multimedia pentru Windows 95"
						},
						{
							"_nr": "Anexe",
							"__text": "Anexe"
						},
						{
							"_nr": "Index",
							"__text": "Indexuri"
						}
					]
				},
				"_id": "1065",
				"_isbn": "973-601-330-8"
			},
			{
				"titlu": "3D Studio pentru incepatori",
				"autori": {
					"autor": [
						{
							"_nume": "Jim Lammers"
						},
						{
							"_nume": "Michael Todd Peterson"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "512",
				"pretOriginal": "40000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "170",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "3D Studio 4 beginners",
					"editura": "New Riders Publishing",
					"traducatori": {
						"traducator": [
							"Minerva Cernea",
							"Ariana Popescu",
							"Gheorghe Musca"
						]
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "3D_Studio_pentru_incepatori_Lammers.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Grafica tridimensionala pe calculator"
						},
						{
							"_nr": "2",
							"__text": "Sa facem cunostiinta cu 3D Studio"
						},
						{
							"_nr": "3",
							"__text": "Introducere in modelarea 3D"
						},
						{
							"_nr": "4",
							"__text": "Lumini, aparat fotografic, scenografie"
						},
						{
							"_nr": "5",
							"__text": "Regia spectacolului: Motor!"
						},
						{
							"_nr": "6",
							"__text": "3D Studio pe un plan superior"
						},
						{
							"_nr": "7",
							"__text": "Tehnici avansate de modelare"
						},
						{
							"_nr": "8",
							"__text": "Tehnici avansate de iluminare si de proictare a meterialelor"
						},
						{
							"_nr": "9",
							"__text": "Tehnici avansate de animatie"
						},
						{
							"_nr": "10",
							"__text": "Tehnici avansate de proectare a cadrelor cheie"
						},
						{
							"_nr": "11",
							"__text": "Video Post"
						},
						{
							"_nr": "12",
							"__text": "Elemente de iesire"
						},
						{
							"_nr": "A",
							"__text": "Comenzile barei cu meniuri"
						}
					]
				},
				"_id": "1066",
				"_isbn": "973-601-409-7"
			},
			{
				"titlu": "Ghidul bobocului pentru multimedia",
				"autori": {
					"autor": {
						"_nume": "David Haskin"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "360",
				"pretOriginal": "25000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "76",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The complete idiot's guide to multimedia",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Nicolae Ionescu Crutan"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Rodica German"
				},
				"tehnoredactori": {
					"tehnoredactor": "Carmen Dumitrescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_bobocului_pentru_multimedia_Haskin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Sa incepem cu notiunile fundamentele despre multimedia"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Pregatirea pentru multimedia"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Partea dureroasa: Instalarea componentelor multimedia"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Castigul: utilizarea aplicatiilor multimedia"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "De unde vin aplicatiile multimedia: conceperea aplicatiilor multimedia"
						}
					]
				},
				"_id": "1067",
				"_isbn": "973-601-270-0"
			},
			{
				"titlu": "MCSE: Windows 95: Ghid de studiu",
				"autori": {
					"autor": [
						{
							"_nume": "Lance Mortensen"
						},
						{
							"_nume": "Rick Sawtell"
						},
						{
							"_nume": "Duane Benson"
						},
						{
							"_nume": "Alex Conger"
						},
						{
							"_nume": "Blake Ferrin"
						},
						{
							"_nume": "Max Holloway"
						},
						{
							"_nume": "Tracy Johnson"
						},
						{
							"_nume": "Mike Spinola"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All",
				"numarPagini": "614",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"traducere": {
					"titluOriginal": "MCSE:Windows 95 Study Guide",
					"editura": "Sybex Inc.",
					"traducatori": {
						"traducator": "Radu Valentin Ceausu"
					},
					"_an": "1998",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "MCSE_Windows_95_Ghid_de_studiu_Mortensen.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Familia Windows"
						},
						{
							"_nr": "2",
							"__text": "Organizarea si instalarea sistemului de operare Windows 95"
						},
						{
							"_nr": "3",
							"__text": "Procesul de incarcare"
						},
						{
							"_nr": "4",
							"__text": "Windows 95 - Arhitectura si memorie"
						},
						{
							"_nr": "5",
							"__text": "Personalizarea si configurarea Windows 95"
						},
						{
							"_nr": "6",
							"__text": "Registry"
						},
						{
							"_nr": "7",
							"__text": "Rularea aplicatiilor"
						},
						{
							"_nr": "8",
							"__text": "Administrarea resurselor si a utilitarelor de disc"
						},
						{
							"_nr": "9",
							"__text": "Componentele de retea in Windows 95"
						},
						{
							"_nr": "10",
							"__text": "Protocolul TCP/IP in Windows 95"
						},
						{
							"_nr": "11",
							"__text": "Retele cu servere Microsoft"
						},
						{
							"_nr": "12",
							"__text": "Retele cu servere NetWare"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea imprimantelor"
						},
						{
							"_nr": "14",
							"__text": "Profiluri de utilizator"
						},
						{
							"_nr": "15",
							"__text": "Politici de sistem"
						},
						{
							"_nr": "16",
							"__text": "Administrarea de la distanta"
						},
						{
							"_nr": "17",
							"__text": "Comunicatii"
						},
						{
							"_nr": "18",
							"__text": "Folosirea calculatorului de la o locatie mobila"
						},
						{
							"_nr": "19",
							"__text": "Clientul Microsoft Exchange"
						},
						{
							"_nr": "20",
							"__text": "Plug and Play"
						},
						{
							"_nr": "21",
							"__text": "Afisajul"
						},
						{
							"_nr": "22",
							"__text": "Multimedia"
						},
						{
							"_nr": "23",
							"__text": "Depanarea in Windows 95"
						},
						{
							"_nr": "Anexa A",
							"__text": "Raspunsuri la intrebari"
						},
						{
							"_nr": "Anexa B",
							"__text": "Introducerea si eliminarea unor componente in Windows 95"
						},
						{
							"_nr": "Anexa C",
							"__text": "Windows 95 si serviciul Internet"
						},
						{
							"_nr": "Anexa D",
							"__text": "Glosar"
						}
					]
				},
				"_id": "1068",
				"_isbn": "973-9392-72-5"
			},
			{
				"titlu": "Aplicatii ingineresti ale calculatoarelor : Optimizari",
				"autori": {
					"autor": [
						{
							"_nume": "Ion I. Dumitrescu"
						},
						{
							"_nume": "Mihai Florovici"
						},
						{
							"_nume": "Ion Macri"
						},
						{
							"_nume": "Vasile Marinoiu"
						},
						{
							"_nume": "Ion Locovei"
						}
					]
				},
				"anAparitie": "1976",
				"editura": "Didactica si pedagogica",
				"tiraj": "3350",
				"numarPagini": "290",
				"pretOriginal": "19",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Alexandra Suliteanu Moraru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mirea Ioan"
				},
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_ingineresti_ale_calculatoarelor_Optimizari_Dumitrescu.jpg"
					},
					"__text": "Wageman Victor"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prelucrarea automata a datelor"
						},
						{
							"_nr": "2",
							"__text": "Bazele programarii in FORTRAN"
						},
						{
							"_nr": "3",
							"__text": "Initiere in PL/I"
						},
						{
							"_nr": "4",
							"__text": "Metode numerice"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "1069",
				"_isbn": ""
			},
			{
				"titlu": "Algoritmi si programare Java : teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Eleonor Ciurea"
						},
						{
							"_nume": "Cristina Luca"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "216",
				"pretOriginal": "250000",
				"pretCumparare": "200000",
				"colectia": {
					"_numarul": "199",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_si_programare_Java_teorie_si_aplicatii_Ciurea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Prima parte"
						},
						{
							"_nr": "1",
							"__text": "Notiuni de teoria grafurilor"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi"
						},
						{
							"_nr": "3",
							"__text": "Date elementare si structuri de date"
						},
						{
							"_nr": "4",
							"__text": "Metode de elaborare a algoritmilor"
						},
						{
							"_nr": "II",
							"__text": "Partea a doua"
						},
						{
							"_nr": "5",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "6",
							"__text": "Unitati lexicale ale limbajului Java"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni, tablouri, siruri"
						},
						{
							"_nr": "8",
							"__text": "Programarea obiectuala in Java"
						},
						{
							"_nr": "9",
							"__text": "Exceptii"
						},
						{
							"_nr": "10",
							"__text": "Programare Input/Output"
						}
					]
				},
				"_id": "1070",
				"_isbn": "973-650-136-1"
			},
			{
				"titlu": "Introducere in Prolog - un limabj al inteligentei artificiale",
				"autori": {
					"autor": {
						"_nume": "Ryurick Marius Hristev"
					}
				},
				"anAparitie": "1992",
				"editura": "APH",
				"numarPagini": "96",
				"pretOriginal": "156",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Ryurick Marius Hristev"
				},
				"tehnoredactori": {
					"tehnoredactor": "Ryurick Marius Hristev"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_Prolog_un_limbaj_al_inteligentei_artificiale_Hristev.jpg"
					},
					"__text": "Ryurick Marius Hristev"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calcul propozitional"
						},
						{
							"_nr": "2",
							"__text": "Baze de date"
						},
						{
							"_nr": "3",
							"__text": "Reguli"
						},
						{
							"_nr": "4",
							"__text": "Liste"
						},
						{
							"_nr": "5",
							"__text": "Reguli complexe"
						},
						{
							"_nr": "6",
							"__text": "Manipularea listelor"
						},
						{
							"_nr": "7",
							"__text": "Analiza gramaticala"
						},
						{
							"_nr": "8",
							"__text": "Tehnici de programare"
						},
						{
							"_nr": "9",
							"__text": "Metalogica"
						},
						{
							"_nr": "10",
							"__text": "Sintaxa Prolog standard"
						},
						{
							"_nr": "11",
							"__text": "Derivarea formala a functiilor"
						}
					]
				},
				"_id": "1071",
				"_isbn": "973-95175-5-2"
			},
			{
				"titlu": "Limbajul masina al calculatoarelor ZX Spectrum, HC, TIM-S, CoBra, CIP, JET",
				"autori": {
					"autor": {
						"_nume": "Mircea Mihail Popovici"
					}
				},
				"anAparitie": "1993",
				"editura": "APH",
				"numarPagini": "344",
				"pretOriginal": "1290",
				"pretCumparare": "100000",
				"tehnoredactori": {
					"tehnoredactor": "Ryurick Marius Hristev"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_masina_al_calculatoarelor_ZX_Spectrum_HC_TIMS_COBRA_CIP_JET_Popovici.jpg"
					},
					"__text": "Ryurick Marius Hristev"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Setul de instructiuni"
						},
						{
							"_nr": "3",
							"__text": "Folosirea instructiunilor in operatii de baza"
						},
						{
							"_nr": "4",
							"__text": "Folosirea instructiunilor pentru cicluri, testari, roatatii si deplasari"
						},
						{
							"_nr": "5",
							"__text": "Folosirea instructiunilor pentru culori, sunete si scrierea textelor"
						},
						{
							"_nr": "6",
							"__text": "Tastatura si afisajul"
						},
						{
							"_nr": "7",
							"__text": "Notiuini despre animatie si intreruperi"
						},
						{
							"_nr": "8",
							"__text": "50 rutine pentru perfectionarea programelor proprii"
						},
						{
							"_nr": "9",
							"__text": "Dezasamblorul MONS3M21"
						}
					]
				},
				"_id": "1072",
				"_isbn": "973-95175-8-7"
			},
			{
				"titlu": "Gazeta de informatica (Anul II, nr.12/1992)",
				"autori": {
					"autor": [
						{
							"_nume": "Sergiu Rudeanu"
						},
						{
							"_nume": "Ion Diamandi"
						},
						{
							"_nume": "Clara Ionescu"
						},
						{
							"_nume": "Catalin Sipos"
						},
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Tudor Leu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Libris",
				"tiraj": "5000",
				"numarPagini": "34",
				"pretOriginal": "100",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": [
						"Adrian Atanasiu",
						"Horia Georgescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gabriela Georgescu",
						"Vlad Atansiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Gazeta_de_informatica_II_12_1992_Rudeanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de logiva matematica (III)"
						},
						{
							"_nr": "2",
							"__text": "Introducere in LOGO (VII)"
						},
						{
							"_nr": "3",
							"__text": "Teste pentru licee"
						},
						{
							"_nr": "4",
							"__text": "Programarea mouse-ului"
						},
						{
							"_nr": "5",
							"__text": "Probleme rezolvate"
						},
						{
							"_nr": "6",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "7",
							"__text": "Probleme de concurs"
						}
					]
				},
				"_id": "1073"
			},
			{
				"titlu": "Gazeta de informatica (Anul III, nr.1/1993)",
				"autori": {
					"autor": [
						{
							"_nume": "Sergiu Rudeanu"
						},
						{
							"_nume": "Adrian Atanasiu"
						},
						{
							"_nume": "Marin Popa"
						},
						{
							"_nume": "Clara Ionescu"
						},
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Cristina Manole"
						},
						{
							"_nume": "Laurentiu Popescu"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Libris",
				"tiraj": "6000",
				"numarPagini": "34",
				"pretOriginal": "100",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": [
						"Adrian Atanasiu",
						"Horia Georgescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gabriela Georgescu",
						"Vlad Atansiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Gazeta_de_informatica_IV_1_1993_Rudeanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de logiva matematica (IV)"
						},
						{
							"_nr": "2",
							"__text": "Ordinul de complexitate al algoritmilor"
						},
						{
							"_nr": "3",
							"__text": "Tabara de pregatire a lotului olimpic"
						},
						{
							"_nr": "4",
							"__text": "Teste pentru licee"
						},
						{
							"_nr": "5",
							"__text": "anagrama lui Gauss"
						},
						{
							"_nr": "6",
							"__text": "O conjectura asupra numarului 1729"
						},
						{
							"_nr": "7",
							"__text": "Descompunerea unui intreg in factori primi"
						},
						{
							"_nr": "8",
							"__text": "Probleme rezolvate"
						},
						{
							"_nr": "9",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "10",
							"__text": "Probleme de concurs"
						}
					]
				},
				"_id": "1074"
			},
			{
				"titlu": "Gazeta de informatica (Anul III, nr.2/1993)",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Ion Fatu"
						},
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Petru Pau"
						},
						{
							"_nume": "Clara Ionescu"
						},
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Ciprian Jichici"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Libris",
				"tiraj": "6000",
				"numarPagini": "34",
				"pretOriginal": "100",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": [
						"Adrian Atanasiu",
						"Horia Georgescu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gabriela Georgescu",
						"Vlad Atansiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Gazeta_de_informatica_IV_2_1993_Balanescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Asupra partitiilor unui numar natural"
						},
						{
							"_nr": "2",
							"__text": "Calculul distantei intre cuvinte"
						},
						{
							"_nr": "3",
							"__text": "Metode backtracking (I)"
						},
						{
							"_nr": "4",
							"__text": "Teste pentru licee"
						},
						{
							"_nr": "5",
							"__text": "Probleme rezolvate"
						},
						{
							"_nr": "6",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "7",
							"__text": "Probleme de concurs"
						}
					]
				},
				"_id": "1075"
			},
			{
				"titlu": "Gazeta de informatica (Anul IV, nr.2/1994)",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Fatu"
						},
						{
							"_nume": "Valeriu Iorga"
						},
						{
							"_nume": "Stelian Ciurea"
						},
						{
							"_nume": "Carmen Mateescu"
						},
						{
							"_nume": "Daniel Mateescu"
						},
						{
							"_nume": "Tudor Sorin"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Libris",
				"numarPagini": "34",
				"pretOriginal": "400",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": [
						"Adrian Atanasiu",
						"Horia Georgescu",
						"Ion Mandoiu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gabriela Georgescu",
						"Vlad Atansiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Gazeta_de_informatica_IV_2_1994_Fatu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "O metoda de evitare a solutiilor backtraking"
						},
						{
							"_nr": "2",
							"__text": "Metode numerice(II)"
						},
						{
							"_nr": "3",
							"__text": "Operatii cu numere mari"
						},
						{
							"_nr": "4",
							"__text": "Rezolvarea unor probleme de loc geometric cu ajutorul calculatorului"
						},
						{
							"_nr": "5",
							"__text": "O problema de concurs rezolvata"
						}
					]
				},
				"_id": "1076"
			},
			{
				"titlu": "Gazeta de informatica (Anul IV, nr.3/1994)",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Fatu"
						},
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Carmen Mateescu"
						},
						{
							"_nume": "Daniel Mateescu"
						},
						{
							"_nume": "Ion Maxim"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Libris",
				"numarPagini": "34",
				"pretOriginal": "400",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": [
						"Adrian Atanasiu",
						"Horia Georgescu",
						"Ion Mandoiu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Gabriela Georgescu",
						"Vlad Atansiu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Gazeta_de_informatica_IV_3_1994_Balanescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Strategii de castig in jocurile matematice"
						},
						{
							"_nr": "2",
							"__text": "Metodinca predarii informaticii (VIII)"
						},
						{
							"_nr": "3",
							"__text": "Corectitudinea instructiunilor repetitive"
						},
						{
							"_nr": "4",
							"__text": "Metode numerice (III)"
						},
						{
							"_nr": "5",
							"__text": "Propunere de programa pentru scoala postliceala de informatica"
						},
						{
							"_nr": "6",
							"__text": "Probleme propuse"
						},
						{
							"_nr": "7",
							"__text": "Probleme de concurs"
						}
					]
				},
				"_id": "1077"
			},
			{
				"titlu": "Algoritmi fundamentali in Java: aplicatii",
				"autori": {
					"autor": {
						"_nume": "Doina Logofatu"
					}
				},
				"anAparitie": "2007",
				"editura": "Polirom",
				"numarPagini": "372",
				"pretOriginal": "289500",
				"pretCumparare": "150000",
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_fundamentali_in_Java_Aplicatii_Logofatu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi - fundamente"
						},
						{
							"_nr": "2",
							"__text": "Introducere in POO"
						},
						{
							"_nr": "3",
							"__text": "Java - fundamente"
						},
						{
							"_nr": "4",
							"__text": "Cutii in cutii"
						},
						{
							"_nr": "5",
							"__text": "Sume de puteri"
						},
						{
							"_nr": "6",
							"__text": "Greedy"
						},
						{
							"_nr": "7",
							"__text": "Problema ordonarii datelor"
						},
						{
							"_nr": "8",
							"__text": "Recursivitatea"
						},
						{
							"_nr": "9",
							"__text": "Divide et impera"
						},
						{
							"_nr": "10",
							"__text": "Backtracking"
						},
						{
							"_nr": "11",
							"__text": "Programare dinamica"
						}
					]
				},
				"_id": "1078",
				"_isbn": "978-973-46-0815-7"
			},
			{
				"titlu": "Bazele programarii pe obiecte in limbajul Turbo Pascal (Partea I)",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1993",
				"editura": "L and S InfoMat",
				"numarPagini": "80",
				"pretOriginal": "900",
				"redactori": {
					"redactor": "Sebastian Proksch"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_programarii_pe_obiecte_in_limbajul_Turbo_Pascal_I_Tudor.jpg"
					},
					"__text": "Sebastian Proksch"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Premizele programarii pe obiecte"
						},
						{
							"_nr": "2",
							"__text": "Programarea pe obiecte"
						},
						{
							"_nr": "3",
							"__text": "Concluzii finale"
						}
					]
				},
				"_id": "1079",
				"_isbn": "973-96382-1-X"
			},
			{
				"titlu": "Culegere de programare de matematica in limbajul BASIC pentru gimnaziu",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan N. Radu"
						},
						{
							"_nume": "Daniela I. Marinescu"
						},
						{
							"_nume": "Nicolae G. Cristea"
						},
						{
							"_nume": "George I. Radu"
						}
					]
				},
				"anAparitie": "1900",
				"editura": "Casa personalului didactic Dimbovita",
				"numarPagini": "144",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Culegere_de_probleme_de_matematica_in_limbajul_BASIC_pentru_gimanziu_Radu.jpg"
					},
					"__text": "Silviu Bartis"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Generalitati"
						},
						{
							"_nr": "II",
							"__text": "Teoria"
						},
						{
							"_nr": "III",
							"__text": "Aritmetica"
						},
						{
							"_nr": "IV",
							"__text": "Algebra"
						},
						{
							"_nr": "V",
							"__text": "Geometrie plana"
						},
						{
							"_nr": "VI",
							"__text": "Geometrie in spatiu"
						},
						{
							"_nr": "VII",
							"__text": "Trigonometrie"
						},
						{
							"_nr": "VIII",
							"__text": "Diverse"
						},
						{
							"_nr": "IX",
							"__text": "Raspunsuri"
						}
					]
				},
				"_id": "1080",
				"_isbn": ""
			},
			{
				"titlu": "Proiectarea cu microcalculatoare integrate",
				"autori": {
					"autor": {
						"_nume": "Octavian Capatina"
					}
				},
				"anAparitie": "1992",
				"editura": "Dacia",
				"numarPagini": "172",
				"pretOriginal": "426",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "0",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Monica Cremene"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Rusu"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_cu_microcalculatoare_integrate_Capatina.jpg"
					},
					"__text": "M. Baciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Structura microcalculatorului integrat Z8"
						},
						{
							"_nr": "3",
							"__text": "Module hard si soft de dezvoltare"
						},
						{
							"_nr": "4",
							"__text": "Microcalculatoare integrate specializate. Transputere"
						}
					]
				},
				"_id": "1081",
				"_isbn": "973-35-0309-6"
			},
			{
				"titlu": "Interconectarea calculatoarelor: INTERNET-ul pe intelesul tuturor",
				"autori": {
					"autor": {
						"_nume": "Ovidiu Sandor"
					}
				},
				"anAparitie": "1995",
				"editura": "Carpe Diem",
				"numarPagini": "128",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Interconectarea_calculatoarelor_INTERNET_ul_pe_intelesul_tuturor_Sandor.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "In loc de introducere"
						},
						{
							"_nr": "2",
							"__text": "Un mic istoric"
						},
						{
							"_nr": "3",
							"__text": "Principiile sistemelor deschise"
						},
						{
							"_nr": "4",
							"__text": "Retele locale"
						},
						{
							"_nr": "5",
							"__text": "Internet. Structura si serviciile"
						},
						{
							"_nr": "6",
							"__text": "Internet. Protocoale"
						}
					]
				},
				"_id": "1082",
				"_isbn": "973-9201-07-5"
			},
			{
				"titlu": "Tehnici orientate pe obiecte",
				"autori": {
					"autor": {
						"_nume": "Ioan Salomie"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "364",
				"pretOriginal": "9900",
				"colectia": {
					"_numarul": "41",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnici_orientate_pe_obiecte_Salomie.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tipuri de date abastrate (ADT). ADT-uri colectie"
						},
						{
							"_nr": "2",
							"__text": "Modelul calculational orientat obiectual. Conceptie, paradigme, specificare"
						},
						{
							"_nr": "3",
							"__text": "Terminologie si suport C++ pentru programarea OO"
						},
						{
							"_nr": "4",
							"__text": "Tehnici de programare cu clase si obiecte"
						},
						{
							"_nr": "5",
							"__text": "Tehnici de programare orientate pe obiecte"
						}
					]
				},
				"_id": "1083",
				"_isbn": "973-9215-05-X"
			},
			{
				"titlu": "Probleme de calcul numeric si statistic MathCAD",
				"autori": {
					"autor": {
						"_nume": "Ciresica Jalobeanu"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "248",
				"pretOriginal": "6900",
				"colectia": {
					"_numarul": "39",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Probleme_de_calcul_numeric_si_statistic_MathCad_Jalobeanu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Prezentare utiltarului MathCad"
						},
						{
							"_nr": "1",
							"__text": "Posibilitati de calcul in MathCad"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea posibilitatilor grafice"
						},
						{
							"_nr": "3",
							"__text": "Editarea documentelor in MathCad"
						},
						{
							"_nr": "4",
							"__text": "Vectori si matrice"
						},
						{
							"_nr": "5",
							"__text": "Functii si operatori in MathCad"
						},
						{
							"_nr": "6",
							"__text": "Fisierele de date in MathCad"
						},
						{
							"_nr": "7",
							"__text": "Tiparirea documentelor"
						},
						{
							"_nr": "8",
							"__text": "MathCad sub Windows"
						},
						{
							"_nr": "Partea II-a"
						},
						{
							"_nr": "9",
							"__text": "Rezolvarea aproximativa a ecuatiilor"
						},
						{
							"_nr": "10",
							"__text": "Metode numerice in algebra liniara"
						},
						{
							"_nr": "11",
							"__text": "Rezolvarea numerica a ecuatiilor diferentiale"
						},
						{
							"_nr": "12",
							"__text": "Aproximare si interpolare"
						},
						{
							"_nr": "13",
							"__text": "Calculul aproximativ al integralelor si derivatelor"
						},
						{
							"_nr": "14",
							"__text": "Calcul statistic"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Solutii MathCad ale problemelor propuse"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Tabelul comenzilor MathCad"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Calculul simbolic in versiunea 3.0"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Simboluri MathCad"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Variabile predefinite"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Mesajele de eroare"
						}
					]
				},
				"_id": "1084",
				"_isbn": "973-97000-3-9"
			},
			{
				"titlu": "Programarea aplicatiilor C sub Windows",
				"autori": {
					"autor": {
						"_nume": "Mihaela Ordean"
					}
				},
				"anAparitie": "1996",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "388",
				"pretOriginal": "12090",
				"colectia": {
					"_numarul": "52",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_aplicatiilor_C_sub_Windows.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Vocabular si concepte in programarea sub Windows"
						},
						{
							"_nr": "3",
							"__text": "Exemplul salut"
						},
						{
							"_nr": "4",
							"__text": "Introducere in GDI"
						},
						{
							"_nr": "5",
							"__text": "Interfata utilizatorului cu dispozitivele de intrare"
						},
						{
							"_nr": "6",
							"__text": "Ferestre Windows"
						},
						{
							"_nr": "7",
							"__text": "Gestionarea memoriei"
						},
						{
							"_nr": "8",
							"__text": "Crearea fisierelor HELP"
						}
					]
				},
				"_id": "1085",
				"_isbn": "973-9215-19-X"
			},
			{
				"titlu": "Microsoft Office 95",
				"autori": {
					"autor": [
						{
							"_nume": "Prodan Augustin"
						},
						{
							"_nume": "Prodan Mihai"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Promedia Plus",
				"numarPagini": "254",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "4",
					"_nume": "Seria Microsoft, Windows 95"
				},
				"redactori": {
					"redactor": "Serban Dronca"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Office_95_Prodan.jpg"
					},
					"__text": "Serban Dronca"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Cum gestionati documente Word"
						},
						{
							"_nr": "2",
							"__text": "Cum operati asupra unui text"
						},
						{
							"_nr": "3",
							"__text": "Cum alegeti un format pentru caractere"
						},
						{
							"_nr": "4",
							"__text": "Alinierea, identartea si spatierea textului"
						},
						{
							"_nr": "5",
							"__text": "Cum tipariti documente ?"
						},
						{
							"_nr": "6",
							"__text": "Cum lucrati cu tabele ?"
						},
						{
							"_nr": "7",
							"__text": "Cum va orientati in documente Excel 7.0 ?"
						},
						{
							"_nr": "8",
							"__text": "Cum operatii asupra foilor de calcul din documente Excel"
						},
						{
							"_nr": "9",
							"__text": "Cum folositi formulele intr-o foaie de calcul ?"
						},
						{
							"_nr": "10",
							"__text": "Cum aliniati datele si cum alegeti un format ?"
						},
						{
							"_nr": "11",
							"__text": "Cum construiti grafice ?"
						},
						{
							"_nr": "12",
							"__text": "Cum lucrati cu baze de date ?"
						},
						{
							"_nr": "13",
							"__text": "Cum tipariti informatiile cuprinse in documente Excel ?"
						},
						{
							"_nr": "14",
							"__text": "Crearea si vizualizarea unei prezentari cu PowerPoint"
						},
						{
							"_nr": "15",
							"__text": "Cum operati asupra unei prezentari"
						}
					]
				},
				"_id": "1086",
				"_isbn": "973-9275-05-2"
			},
			{
				"titlu": "Microsoft Works pentru Windows 95 versiunea 4.0: solutia pentru birou modern",
				"autori": {
					"autor": {
						"_nume": "Serban Dronca"
					}
				},
				"anAparitie": "1996",
				"editura": "Promedia Plus",
				"numarPagini": "272",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "2",
					"_nume": "Seria Microsoft, Windows 95"
				},
				"redactori": {
					"redactor": "Serban Dronca"
				},
				"tehnoredactori": {
					"tehnoredactor": "Serban Dronca"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Works_pentru_Windows_95_versiunea_4_0_Solutia_pentru_un_birou_modern_Dronca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Sistemul de operare Windows 95"
						},
						{
							"_nr": "III",
							"__text": "Procesorul de texte"
						},
						{
							"_nr": "VI",
							"__text": "Calcul tabelar"
						},
						{
							"_nr": "V",
							"__text": "Baze de date"
						},
						{
							"_nr": "VI",
							"__text": "Comunicatii"
						}
					]
				},
				"_id": "1087",
				"_isbn": "973-9275-00-1"
			},
			{
				"titlu": "Microsoft Windows 3.11 for Workgroups: Sistemul de operare, gestiunea retelelor",
				"autori": {
					"autor": [
						{
							"_nume": "Marius Iurian"
						},
						{
							"_nume": "Simona Iurian"
						},
						{
							"_nume": "Cristian Mihoc"
						},
						{
							"_nume": "Dragos Pop"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Promedia Plus",
				"numarPagini": "272",
				"pretOriginal": "9980",
				"colectia": {
					"_numarul": "2",
					"_nume": "Seria PC, Software"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Marius Iurian",
						"Simona Iurian",
						"Cristian Mihoc",
						"Dragos Pop"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Windows_3_11_for_Workgroups_Sistemul_de_operare_gestiunea_retelelor_Iurian.jpg"
					},
					"__text": "Octavian Bour"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Instalarea sistemului Windows for Workgroups"
						},
						{
							"_nr": "II",
							"__text": "Notiunile fundamentale ale sistemului Windows for Workgroup"
						},
						{
							"_nr": "III",
							"__text": "Gestiunea aplicatiilor"
						},
						{
							"_nr": "VI",
							"__text": "Grupul de aplicatii de retea Network"
						},
						{
							"_nr": "V",
							"__text": "Gestionarul de fisiere File Manager"
						},
						{
							"_nr": "VI",
							"__text": "Configurarea sistemului. Aplicatia Control Panel"
						},
						{
							"_nr": "VII",
							"__text": "Utilizarea imprimantelor si a fonturilor"
						},
						{
							"_nr": "VIII",
							"__text": "Configurarea si utilizarea modemurilor si a faxmodemurilor"
						},
						{
							"_nr": "IX",
							"__text": "Editorul de texte Write"
						},
						{
							"_nr": "X",
							"__text": "Aplicatia Paintbrush"
						},
						{
							"_nr": "XI",
							"__text": "Integrarea aplicatiilor Windows"
						}
					]
				},
				"_id": "1088",
				"_isbn": "973-96862-6-5"
			},
			{
				"titlu": "Microsoft Word 6.0",
				"autori": {
					"autor": {
						"_nume": "Calin Chiorean"
					}
				},
				"anAparitie": "1996",
				"editura": "Promedia Plus",
				"numarPagini": "300",
				"pretOriginal": "14900",
				"colectia": {
					"_numarul": "6",
					"_nume": "Seria PC, Software"
				},
				"redactori": {
					"redactor": "Serban Droncea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Calin Chiorean"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_Word_6_0_Chiorean.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instalarea editorului de texte Word 6.0"
						},
						{
							"_nr": "2",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "3",
							"__text": "Operatiuni de baza"
						},
						{
							"_nr": "4",
							"__text": "Editarea textului"
						},
						{
							"_nr": "5",
							"__text": "Crearea documentelor"
						}
					]
				},
				"_id": "1089",
				"_isbn": "973-97377-4-9"
			},
			{
				"titlu": "MS-DOS 6.2 - comenzi, metode, exemple",
				"autori": {
					"autor": [
						{
							"_nume": "Marius Muntean"
						},
						{
							"_nume": "Marius Joldos"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Promedia Plus",
				"numarPagini": "326",
				"pretOriginal": "11500",
				"colectia": {
					"_numarul": "1",
					"_nume": "Seria PC, Software"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_6_2_Comenzi_metode_exemple_Muntean_1996.jpg"
					},
					"__text": "Octavian Bour"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere si conventii. Instalarea MS-DOS"
						},
						{
							"_nr": "2",
							"__text": "Bazele utilizarii MS-DOS"
						},
						{
							"_nr": "3",
							"__text": "Comenzile sistemului de operare MS-DOS 6.XX"
						},
						{
							"_nr": "4",
							"__text": "Administrarea sistemului"
						},
						{
							"_nr": "5",
							"__text": "Configurartea sistemului"
						}
					]
				},
				"_id": "1090",
				"_isbn": "973-96862-9-X"
			},
			{
				"titlu": "Calculatoare cu program si teoria programarii",
				"autori": {
					"autor": {
						"_nume": "Constantin P. Popovici"
					}
				},
				"anAparitie": "1972",
				"editura": "Editura stiintifica",
				"numarPagini": "336",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Adrian Moraru"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_cu_program_si_teoria_programarii_Popovici.jpg"
					},
					"__text": "Monica Moisescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Elemente de logica matematica"
						},
						{
							"_nr": "3",
							"__text": "Elaborarea schemelor-bloc (schemelor logice) ale programelor"
						},
						{
							"_nr": "4",
							"__text": "Scheme de numeratie"
						},
						{
							"_nr": "5",
							"__text": "Codificarea numerelor intregi si a numerelor reale in vederea reprezentarii lor in calculator"
						},
						{
							"_nr": "6",
							"__text": "Principalele schme electronoce dintr-un calcualtor cu program"
						},
						{
							"_nr": "7",
							"__text": "Limbajul de programare Fortran"
						},
						{
							"_nr": "8",
							"__text": "Coduri detectoare si corectoare de erori"
						},
						{
							"_nr": "9",
							"__text": "Date si consideratii asupra calculatoarelor"
						}
					]
				},
				"_id": "1091",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare 2/4/8 biti",
				"autori": {
					"autor": {
						"_nume": "Cristian Lupu"
					}
				},
				"anAparitie": "1995",
				"editura": "Militara",
				"numarPagini": "192",
				"pretOriginal": "6000",
				"redactori": {
					"redactor": "Dumitru Nicolescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesoare_2_4_8_biti_Lupu.jpg"
					},
					"__text": "Victot Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Microprogramare si microprocesoare BIT-SLICE"
						},
						{
							"_nr": "2",
							"__text": "Familia microprocesolui Z80"
						}
					]
				},
				"_id": "1092",
				"_isbn": "973-32-0409-9"
			},
			{
				"titlu": "Z80 in microcalculatoare compatibile Spectrum",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Pleter"
						},
						{
							"_nume": "Cristian Constantinescu"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Militara",
				"numarPagini": "192",
				"pretOriginal": "6200",
				"redactori": {
					"redactor": "Dumitru Nicolescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Z80_in_microcalculatoare_compatibile_Spectrum_Pleter.jpg"
					},
					"__text": "Victot Ilie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentare generala a arhitecturii si a organizarii interne a calculatoarelor compatibile Spectrum"
						},
						{
							"_nr": "2",
							"__text": "Hardware"
						},
						{
							"_nr": "3",
							"__text": "Programarea in limba de asamblare pe compatibilele Spectrum"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii ale compatibilelor Spectrum"
						}
					]
				},
				"_id": "1093",
				"_isbn": "973-32-0431-5"
			},
			{
				"titlu": "Grafica pe calculator: algoritmi fundamentali",
				"autori": {
					"autor": {
						"_nume": "Grigore Albeanu"
					}
				},
				"anAparitie": "2001",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "164",
				"pretOriginal": "57300",
				"pretCumparare": "100000",
				"coperta": {
					"imagine": {
						"_path": "Grafica_pe_calculator_Algoritmi_fundamentali_Albeanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Transformari"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi de decupare"
						},
						{
							"_nr": "3",
							"__text": "Elemente de modelare geometrica"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi pentru sinteza primitivelor grafice"
						},
						{
							"_nr": "5",
							"__text": "Determinarea vizibilitatii obiectelor"
						}
					]
				},
				"_id": "1094",
				"_isbn": "973-575-949-0"
			},
			{
				"titlu": "Invata singur utilizarea calculatorului",
				"autori": {
					"autor": {
						"_nume": "Mariana Milosescu"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "132",
				"pretOriginal": "120000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "482",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Invata_singur_utilizarea_calculatorului_Milosescu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatorul"
						},
						{
							"_nr": "2",
							"__text": "Hardware"
						},
						{
							"_nr": "3",
							"__text": "Software"
						},
						{
							"_nr": "4",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "5",
							"__text": "Tehnologia informatiei si societatea"
						},
						{
							"_nr": "6",
							"__text": "Securitate, drepturi de autor si legislatie"
						}
					]
				},
				"_id": "1095",
				"_isbn": "973-20-0684-6"
			},
			{
				"titlu": "Invata singur Internet",
				"autori": {
					"autor": {
						"_nume": "Mariana Milosescu"
					}
				},
				"anAparitie": "2003",
				"editura": "Teora",
				"numarPagini": "158",
				"pretOriginal": "180000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "488",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Invata_singur_Internet_Milosescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Functiile de comunicare, conectivitate si documentare"
						},
						{
							"_nr": "2",
							"__text": "Reteaua Internet"
						},
						{
							"_nr": "3",
							"__text": "Functii de documentare a retelei Internet"
						},
						{
							"_nr": "4",
							"__text": "Functia de comunicare a retelei Internet"
						},
						{
							"_nr": "5",
							"__text": "Functia de conectivitate a retelei Internet"
						},
						{
							"_nr": "6",
							"__text": "Functia comerciala a retelei Internet"
						}
					]
				},
				"_id": "1096",
				"_isbn": "973-20-00939-X"
			},
			{
				"titlu": "Curs de bazele informaticii. Circuite, automate, limbaje formale",
				"autori": {
					"autor": [
						{
							"_nume": "L. Livovschi"
						},
						{
							"_nume": "C.P. Popovici"
						},
						{
							"_nume": "N. Tandareanu"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Tipografia Universitatii Bucuresti",
				"tiraj": "780",
				"numarPagini": "312",
				"pretOriginal": "20.55",
				"pretCumparare": "25000",
				"coperta": {
					"imagine": {
						"_path": "Curs_de_bazele_informaticii_Circuite_automate_limbaje_formale_Livovschi.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Functii booleene, circuite combinationale, automate finite"
						},
						{
							"_nr": "1",
							"__text": "Functii booleene"
						},
						{
							"_nr": "2",
							"__text": "Ecuatii booleene"
						},
						{
							"_nr": "3",
							"__text": "Circuite combinationale"
						},
						{
							"_nr": "4",
							"__text": "Sinteza schemelor logice (sau combinationale)"
						},
						{
							"_nr": "5",
							"__text": "Elemente asupra automatelor finite si descrierea unor circuite ale calculatoarelor"
						},
						{
							"_nr": "Partea II",
							"__text": "Limbaje formale cu aplicatii la limbajele de programare"
						},
						{
							"_nr": "1",
							"__text": "Limbaje formale si automate"
						},
						{
							"_nr": "2",
							"__text": "Limbaje regulate"
						},
						{
							"_nr": "3",
							"__text": "Limbaje independente de context"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii ale limbajelor formale la limbajele de programare"
						}
					]
				},
				"_id": "1097",
				"_isbn": ""
			},
			{
				"titlu": "Limbaje formale: culegere de probleme",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Atanasiu"
						},
						{
							"_nume": "Alexandru Mateescu"
						}
					]
				},
				"anAparitie": "1990",
				"editura": "Tipografia Universitatii Bucuresti",
				"tiraj": "578",
				"numarPagini": "308",
				"pretOriginal": "37.45",
				"pretCumparare": "25000",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_formale_Culegere_de_probleme_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Gramatici regulate, automate finite"
						},
						{
							"_nr": "2",
							"__text": "Gramatici si limbaje independente de context (Proprietati generale)"
						},
						{
							"_nr": "3",
							"__text": "Alte proprietati ale limbajelor independente de context, automate stiva"
						},
						{
							"_nr": "4",
							"__text": "Scheme de translatoare, translatori"
						},
						{
							"_nr": "5",
							"__text": "Gramatici de precedenta"
						},
						{
							"_nr": "6",
							"__text": "Masini secventiale generalizate (GSM-uri), familii abstracte de limbaje LAFL-uri si limbaje iterative"
						},
						{
							"_nr": "7",
							"__text": "Gramatici matriciale, contextuale Marcus, van Wijngaarden, complet initiale, sisteme Lindenmayer"
						}
					]
				},
				"_id": "1098",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme si echipamente electronice pentru prelucrarea datelor",
				"autori": {
					"autor": {
						"_nume": "N. Ciubotaru"
					}
				},
				"anAparitie": "1974",
				"editura": "Institutul de cercetare si proiectare pentru industria lemnului",
				"tiraj": "1620",
				"numarPagini": "172",
				"pretOriginal": "37.45",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "E. Scripcaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Lefter"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_si_echipamente_electronice_pentru_prelucrarea_datelor_Ciubotaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Masini de calcul electronice de birou"
						},
						{
							"_nr": "II",
							"__text": "Masini electronice de facturat-contabilizat"
						},
						{
							"_nr": "III",
							"__text": "Sisteme de prelucrare automata a datelor"
						}
					]
				},
				"_id": "1099",
				"_isbn": ""
			},
			{
				"titlu": "Informatica in medicina",
				"autori": {
					"autor": [
						{
							"_nume": "Tiberiu Spircu"
						},
						{
							"_nume": "Stefan Tigan"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "192",
				"pretOriginal": "4900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "2",
					"_nume": "Medicina"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_in_medicina_Spircu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Informatica medicala si stiintele informatiei"
						},
						{
							"_nr": "II",
							"__text": "Calculatoare personale"
						},
						{
							"_nr": "III",
							"__text": "Sistemul de operare"
						},
						{
							"_nr": "IV",
							"__text": "Utilitare si interfete cu utilizatorul"
						},
						{
							"_nr": "V",
							"__text": "Editarea"
						},
						{
							"_nr": "VI",
							"__text": "Programarea"
						},
						{
							"_nr": "VII",
							"__text": "Baze de date"
						},
						{
							"_nr": "VIII",
							"__text": "Documentarea"
						},
						{
							"_nr": "IX",
							"__text": "Calculele si analiza datelor"
						},
						{
							"_nr": "X",
							"__text": "Semanle si imagini"
						},
						{
							"_nr": "Anexa A",
							"__text": "Elemente de logica"
						},
						{
							"_nr": "Anexa B",
							"__text": "Scrierea binara si cea hexazecimala a numerelor"
						},
						{
							"_nr": "Anexa C",
							"__text": "Evaluarea si evolutia calculatoarelor personale"
						},
						{
							"_nr": "Anexa D",
							"__text": "Elemente de teorie a probabilitatilor"
						},
						{
							"_nr": "Anexa E",
							"__text": "Software cu specific medical"
						}
					]
				},
				"_id": "1100",
				"_isbn": "973-601-188-7"
			},
			{
				"titlu": "Microsoft SQL Server",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Popa"
						},
						{
							"_nume": "Mioara Udrica"
						},
						{
							"_nume": "Alexandru Manole"
						},
						{
							"_nume": "Bogdan Gabriel Vasilciuc"
						},
						{
							"_nume": "Miahi Garba"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Economica",
				"numarPagini": "312",
				"pretOriginal": "0",
				"pretCumparare": "150000",
				"redactori": {
					"redactor": "Carmen Taranu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paulina Mirea"
				},
				"coperta": {
					"imagine": {
						"_path": "Microsoft_SQL_Server_Popa.jpg"
					},
					"__text": "Alexandru Ion"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul de gestiune a bazelor de date SQL Server"
						},
						{
							"_nr": "2",
							"__text": "Organizarea si prelucrarea datelor pentru analiza multidimensionala"
						},
						{
							"_nr": "3",
							"__text": "Limbajul MDX (MultiDimensional eXpression) pentru procesarea datelor multidimensiune"
						},
						{
							"_nr": "4",
							"__text": "Accesarea si prezentarea informatiilor din depozitele de date"
						},
						{
							"_nr": "5",
							"__text": "Gestionarea informatiilor dintr-o baza de date SQL Server prin aplicatii web"
						},
						{
							"_nr": "6",
							"__text": "Microsoft SQL Server 2005: deosebiri, noutati"
						}
					]
				},
				"_id": "1101",
				"_isbn": "978-973-709-277-9"
			},
			{
				"titlu": "Sisteme de operare",
				"autori": {
					"autor": {
						"_nume": "Ioan Jurca"
					}
				},
				"anAparitie": "2001",
				"editura": "Editura de vest",
				"numarPagini": "272",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_Jurca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Structura sistemelor de operare"
						},
						{
							"_nr": "3",
							"__text": "Unix: prezentare generala"
						},
						{
							"_nr": "4",
							"__text": "Comenzi Unix"
						},
						{
							"_nr": "5",
							"__text": "Interpretorul de comenzi (shell)"
						},
						{
							"_nr": "6",
							"__text": "Apeluri de sistem pt. operatii cu fisierele"
						},
						{
							"_nr": "7",
							"__text": "Apeluri sistem pentru cataloge"
						},
						{
							"_nr": "8",
							"__text": "Functii din biblioteca standard de I/E"
						},
						{
							"_nr": "9",
							"__text": "Apeluri pentru gestionarea proceselor"
						},
						{
							"_nr": "10",
							"__text": "Relatii intre procese. Semnale"
						},
						{
							"_nr": "11",
							"__text": "Comunicarea interprocese (IPC)"
						},
						{
							"_nr": "12",
							"__text": "Fire de executie"
						}
					]
				},
				"_id": "1102",
				"_isbn": "973-36-0339-2"
			},
			{
				"titlu": "Programarea calculatoarelor[X-XII]",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Munteanu"
						},
						{
							"_nume": "Traian Ionescu"
						},
						{
							"_nume": "Gheorghe Musca"
						},
						{
							"_nume": "Daniela Tataru"
						},
						{
							"_nume": "Sergiu Mihai Dascalu"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Didactica si pedagogica",
				"numarPagini": "408",
				"pretOriginal": "1318",
				"redactori": {
					"redactor": [
						"Maria Beluri",
						"Marius Murariu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Elana Stan"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_X_XII_Munteanu.jpg"
					},
					"__text": "Elena Dragulelei"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Limbajul de programare PASCAL"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Limbajul de programare C"
						}
					]
				},
				"_id": "1103",
				"_isbn": "973-30-3162-3"
			},
			{
				"titlu": "Informatica economica[XI]",
				"autori": {
					"autor": {
						"_nume": "Daniela Oprescu"
					}
				},
				"anAparitie": "1994",
				"editura": "Didactica si pedagogica",
				"numarPagini": "144",
				"pretOriginal": "437",
				"redactori": {
					"redactor": "Alexandru Moraru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paraschiv Otto Necsoiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_economica_XI_Oprescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organismul economico+social ca sistem dinamic"
						},
						{
							"_nr": "2",
							"__text": "Clase de probleme din cadru sistemului de gestiune"
						},
						{
							"_nr": "3",
							"__text": "Organizarea si prelucrarea informatiilor de gestiune"
						}
					]
				},
				"_id": "1104",
				"_isbn": "973-30-3186-0"
			},
			{
				"titlu": "Microprocesoarele x86 - o abordare software",
				"autori": {
					"autor": [
						{
							"_nume": "Corneliu Burileanu"
						},
						{
							"_nume": "Mihaela Ionita"
						},
						{
							"_nume": "Mircea Ionita"
						},
						{
							"_nume": "Mircea Filotti"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Albastra",
				"tiraj": "5000",
				"numarPagini": "348",
				"pretOriginal": "85000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "85",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesoarele_x86_o_abordare_software_Burileanu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instructiuni de transfer al datelor"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni de prelucrare a datelor"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de control al programului"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni de control al microcalculatorului"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni specifice prelucrarii in virgula mobila"
						},
						{
							"_nr": "6",
							"__text": "Tehnici de programare specifice limbajelor de inalt implementate in libaj de asamblare"
						},
						{
							"_nr": "7",
							"__text": "Microprocesoarele x86 in modul virtual (protejat)"
						}
					]
				},
				"_id": "1105",
				"_isbn": "973-9125-95-5"
			},
			{
				"titlu": "Initiere operare PC: curs intensiv",
				"autori": {
					"autor": {
						"_nume": "[Electrabiz]"
					}
				},
				"anAparitie": "1900",
				"editura": "[Elecrabiz]",
				"numarPagini": "38",
				"pretOriginal": "0",
				"pretCumparare": "2500",
				"coperta": {
					"imagine": {
						"_path": "Initiere_operare_PC_Curs_intensiv_Electrabiz.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Notiuni generale despre calculatoare. Organizarea fizica si logica a P.C.-urilor"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Principalele elemente de manipulare a ferestrelor in modul Windows"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Principalele comenzi ale programului Windows Explorer"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Recuperarea fisierelor sterse prin aplicatia Recycle Bin. Cautarea si redenumirea fisierelor. Prezentarea programelor Wordpad, Notepad, Paint"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Recapitularea notiunilor si exercitiilor practice invatate pe parcursul lectiilor 1,2,3,4. Verificarea cunostiintelor acumulate si prin intermediul testul intermediar - teoretic si practic"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Prezentarea principalelor comenzi comune intalnite in diferite programe"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Prezentarea programului Microsoft Word. Organizarea ecranului si a paginii de lucru, meniuri, lucrul cu documente"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Prezentarea programului Microsoft Works Spreedsheet/Excel. Lucrul cu foi de calcul, celule de lucru, formule"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Prezentarea browser-elor Internet. Posta electonica. Navigare prn web, motoare de cautare"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Recapitularea notiunilor si exercitiilor practice invatate pe parcursul lectiilor 6,7,8,9. Verificarea cunostiintelor acumulate si dobandite prin intermediul testul intermediar - teoretic si practic"
						}
					]
				},
				"_id": "1106",
				"_isbn": ""
			},
			{
				"titlu": "Programarea la calculatoarele elecronice (Limbajul Cobol) -manual pentru licee de specialitate, anii III si IV",
				"autori": {
					"autor": [
						{
							"_nume": "Stelian Niculescu"
						},
						{
							"_nume": "Ileana Trandafir"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Didactica si pedagogica",
				"tiraj": "10000+120 brosate",
				"numarPagini": "324",
				"pretOriginal": "11.20",
				"redactori": {
					"redactor": "Monica Sarbu"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Antoniu"
				},
				"coperta": {
					"imagine": {
						"_path": ""
					},
					"__text": "Wegeman Victor"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de numeratie"
						},
						{
							"_nr": "3",
							"__text": "Suporturi de informatii"
						},
						{
							"_nr": "4",
							"__text": "Unitatile componente ale unui calculator electronic si modul lor de functionare"
						},
						{
							"_nr": "5",
							"__text": "Reprezentarea interna a informatiilor"
						},
						{
							"_nr": "6",
							"__text": "Organizarea datelor (fisiere)"
						},
						{
							"_nr": "7",
							"__text": "Algoritmi si scheme logice"
						},
						{
							"_nr": "8",
							"__text": "Introducere in limbajul COBOL"
						},
						{
							"_nr": "9",
							"__text": "Diviziunile IDENTIFICATION si ENVIRONMENT"
						},
						{
							"_nr": "10",
							"__text": "Diviziunea DATA"
						},
						{
							"_nr": "11",
							"__text": "Diviziunea PROCEDURE"
						},
						{
							"_nr": "12",
							"__text": "Prelucrarea fisierelor"
						}
					]
				},
				"_id": "1107",
				"_isbn": ""
			},
			{
				"titlu": "Programarea liniara a sistemelor mari: concepte, metode, experienta de calcul (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "George B. Dantzig"
						},
						{
							"_nume": "P. Huard"
						},
						{
							"_nume": "Andree F. Perold"
						},
						{
							"_nume": "Richard D. McBride"
						},
						{
							"_nume": "Eugeniusz Toczylowski"
						},
						{
							"_nume": "Tatsu Aonuma"
						},
						{
							"_nume": "Michael Bastian"
						},
						{
							"_nume": "Robert Fourier"
						},
						{
							"_nume": "Philippe Gille"
						},
						{
							"_nume": "Etienne Loute"
						},
						{
							"_nume": "Peter Kall"
						},
						{
							"_nume": "A. Propoi"
						},
						{
							"_nume": "V. Krivonozhko"
						},
						{
							"_nume": "Philip Abrahamson"
						},
						{
							"_nume": "D. Ament"
						},
						{
							"_nume": "M. Remmelswaal"
						},
						{
							"_nume": "J. Klaus Beer"
						},
						{
							"_nume": "James K. Ho"
						},
						{
							"_nume": "J. Stahl"
						},
						{
							"_nume": "Marchal L. Fisher"
						},
						{
							"_nume": "Margaret H. Wright"
						},
						{
							"_nume": "Michael A. Saunders"
						},
						{
							"_nume": "Walter Murray"
						},
						{
							"_nume": "Philip E. Gill"
						},
						{
							"_nume": "A. Schrijver"
						},
						{
							"_nume": "L. Lovasz"
						},
						{
							"_nume": "M. Grotschel"
						},
						{
							"_nume": "Stanislaw Walukiewicz"
						}
					]
				},
				"anAparitie": "1990",
				"editura": "Tehnica",
				"numarPagini": "338",
				"pretOriginal": "29",
				"traducere": {
					"titluOriginal": "Large scale liniar programming",
					"editura": "International Institute for Applied Systemes Analysis (IIASA)",
					"traducatori": {
						"traducator": [
							"Adrian Gheorghe",
							"Anca Babes",
							"Anton Popescu",
							"Vasile Sima"
						]
					},
					"_an": "1980",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mirce Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "V.E.Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_liniara_a_sistemelor_mari_1_Dantzig.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Metode etapizate in programare liniara"
						},
						{
							"_nr": "Partea II",
							"__text": "Metoda simplex pentru programe liniare nestructurate"
						},
						{
							"_nr": "Partea III",
							"__text": "Metoda simplex pentru programe liniare dinamice si bloc-unghiulare"
						},
						{
							"_nr": "Partea IV",
							"__text": "Algoritmi de decompozitie"
						},
						{
							"_nr": "Partea V",
							"__text": "Algoritmi elipsoidali"
						}
					]
				},
				"_id": "1108",
				"_isbn": "973-31-0137"
			},
			{
				"titlu": "AMC 55",
				"autori": {
					"autor": [
						{
							"_nume": "M. Sirbu"
						},
						{
							"_nume": "V. Peteanu"
						},
						{
							"_nume": "C. Mustanta"
						},
						{
							"_nume": "P. Zamfirescu"
						},
						{
							"_nume": "P. Eles"
						},
						{
							"_nume": "H. Ciocarlie"
						},
						{
							"_nume": "N.D. Cirtu"
						},
						{
							"_nume": "Gh. Popescu"
						},
						{
							"_nume": "G. Nelepcu"
						},
						{
							"_nume": "N. Perciun"
						},
						{
							"_nume": "Th. Borangiu"
						},
						{
							"_nume": "A. Hosu"
						},
						{
							"_nume": "R. Dobrescu"
						},
						{
							"_nume": "G. Ionescu"
						},
						{
							"_nume": "V. Sgirciu"
						},
						{
							"_nume": "S. Molin"
						},
						{
							"_nume": "P. Constantinescu"
						},
						{
							"_nume": "B. Droasca"
						},
						{
							"_nume": "D. Stanescu"
						},
						{
							"_nume": "E. Kalisz"
						},
						{
							"_nume": "I. Miu"
						},
						{
							"_nume": "A. Pastila"
						},
						{
							"_nume": "M. Popovici"
						},
						{
							"_nume": "I. Agirbiceanu"
						},
						{
							"_nume": "V. Stoian"
						},
						{
							"_nume": "E. Enache"
						},
						{
							"_nume": "M. Andrei"
						},
						{
							"_nume": "C. Zaharia"
						},
						{
							"_nume": "T. Geber"
						},
						{
							"_nume": "V. Savescu"
						},
						{
							"_nume": "I. Rusu"
						},
						{
							"_nume": "G.B. Luchian"
						},
						{
							"_nume": "M. Enache"
						},
						{
							"_nume": "M. Florovici"
						},
						{
							"_nume": "A. A. Samarschi"
						},
						{
							"_nume": "G. S. Pospelov"
						},
						{
							"_nume": "N. N. Moiseev"
						},
						{
							"_nume": "K. A. Valiev"
						},
						{
							"_nume": "D. S. Kurdiumov"
						},
						{
							"_nume": "A. A. Petrov"
						}
					]
				},
				"anAparitie": "1987",
				"editura": "Tehnica",
				"numarPagini": "488",
				"pretOriginal": "52",
				"traducere": {
					"traducatori": {
						"traducator": "Alecu Cramanescu"
					},
					"_an": "",
					"_editia": "",
					"_limba": "",
					"_tara": ""
				},
				"redactori": {
					"redactor": [
						"Paul Zamfirescu",
						"Smaranda Dimitriu",
						"Victoria Popescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_55_Tehnica.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "IFAC '87, CONDINF '87"
						},
						{
							"_nr": "2",
							"__text": "Programarea sistemelor distribuite in limbaje de nivel inalt"
						},
						{
							"_nr": "4",
							"__text": "Roboti industriali in sisteme flexibile (IV)"
						},
						{
							"_nr": "5",
							"__text": "Sistem cu microprocesor pentru gestiunea operativa a puterii si nergiei electrice la consumatori"
						},
						{
							"_nr": "6",
							"__text": "Sistemul ECASUD-01 de supravechere cu microcalculator a proceselor de sudura"
						},
						{
							"_nr": "7",
							"__text": "Rolul informatiei in geneaza si dezvoltarea sistemelor (III)"
						},
						{
							"_nr": "8",
							"__text": "Sisteme bidimensionale - 2D - (VI)"
						},
						{
							"_nr": "9",
							"__text": "Terminal interactiv DAF 2010 R. Manual de utilizare si functionare"
						},
						{
							"_nr": "10",
							"__text": "Cuplarea la calculatoarele Felix C256/512/1024 a terminalelor de teletrasmisie (II)"
						},
						{
							"_nr": "11",
							"__text": "Viitorul 'imediat' al calculatoarelor"
						},
						{
							"_nr": "12",
							"__text": "40 exercitii si probleme de programare grafica in BASIC pe calculatoare personale romanesti si straine"
						},
						{
							"_nr": "13",
							"__text": "Grafica interactiva pe calculatoare personale si personal-profesionale (I)"
						},
						{
							"_nr": "14",
							"__text": "Ghid (vesel) al analistului (serios). Proiect de analiza de sistem la uzina 'U' (III)"
						}
					]
				},
				"_id": "1109",
				"_isbn": ""
			},
			{
				"titlu": "Colectie de probleme pentru calculatorul electronic CET-500",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriela Alamoreanu"
						},
						{
							"_nume": "Romeo Amaftiesei"
						},
						{
							"_nume": "Doina Badescu"
						},
						{
							"_nume": "Nicolae Besleaga"
						},
						{
							"_nume": "Barbu Castoiu"
						},
						{
							"_nume": "Ion Carutasu"
						},
						{
							"_nume": "Mircea Cazacu"
						},
						{
							"_nume": "Florica Ceausu"
						},
						{
							"_nume": "Eugen Ciupitu"
						},
						{
							"_nume": "Irina Curelaru"
						},
						{
							"_nume": "Ion Dragos"
						},
						{
							"_nume": "Maria Duma"
						},
						{
							"_nume": "Dumitru Dumitrescu"
						},
						{
							"_nume": "Mariana Gavrilas"
						},
						{
							"_nume": "Victor Giurgiu"
						},
						{
							"_nume": "Alexandru Glodeanu"
						},
						{
							"_nume": "Florin Ionescu"
						},
						{
							"_nume": "Victor Ionescu-Vlasceanu"
						},
						{
							"_nume": "Petre Macarie"
						},
						{
							"_nume": "Diana Martin"
						},
						{
							"_nume": "Gabriel Martin"
						},
						{
							"_nume": "Gherasim Marton"
						},
						{
							"_nume": "Maria Mocica"
						},
						{
							"_nume": "Nicolae Moldovan"
						},
						{
							"_nume": "Cornel Nistor"
						},
						{
							"_nume": "Mihai Oprica"
						},
						{
							"_nume": "Titus Ponta"
						},
						{
							"_nume": "Alexandru Popescu"
						},
						{
							"_nume": "Dan Popescu"
						},
						{
							"_nume": "Corneliu Protop"
						},
						{
							"_nume": "Werner Schatz"
						},
						{
							"_nume": "Mihai Stancu"
						},
						{
							"_nume": "Rodica Stoian"
						},
						{
							"_nume": "Petre Tanasescu"
						},
						{
							"_nume": "Tamara Tinu"
						},
						{
							"_nume": "Victor Toma"
						},
						{
							"_nume": "Nicolae Vilcov"
						},
						{
							"_nume": "Ion Vita"
						},
						{
							"_nume": "Gheorghita Vladuca"
						},
						{
							"_nume": "Ion Zaharia"
						},
						{
							"_nume": "Ion Zamfirescu"
						}
					]
				},
				"anAparitie": "1967",
				"editura": "Academiei Republicii Socialiste Romania",
				"tiraj": "2070",
				"numarPagini": "778",
				"pretOriginal": "32",
				"redactori": {
					"redactor": "Ionescu Victor"
				},
				"tehnoredactori": {
					"tehnoredactor": "Cora Johnson"
				},
				"coperta": {
					"imagine": {
						"_path": "Colectie_de_probleme_pentru_calculatorul_electronic_CET_500_Toma.jpg"
					},
					"__text": "Rusu Iordache"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "PARTEA I"
						},
						{
							"_nr": "1",
							"__text": "Calculatorul electroni tranzistorizat CET-500"
						},
						{
							"_nr": "Partea II",
							"__text": "PARTEA II"
						},
						{
							"_nr": "1",
							"__text": "Subrutine pentru calculul functiilor elementare si speciale"
						},
						{
							"_nr": "2",
							"__text": "Rutine interpretative"
						},
						{
							"_nr": "3",
							"__text": "Analiza armonica"
						},
						{
							"_nr": "4",
							"__text": "Calculul integralelor definite"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de ecuatii algebrice liniare"
						},
						{
							"_nr": "6",
							"__text": "Ecuatii neliniare"
						},
						{
							"_nr": "7",
							"__text": "Calcul matricial"
						},
						{
							"_nr": "8",
							"__text": "Determinarea valorilor si vectorilor proprii ai unei matrice simetrice prin metoda rotatiilor"
						},
						{
							"_nr": "9",
							"__text": "Generarea numerelor pseudoaleatoare"
						},
						{
							"_nr": "10",
							"__text": "Corelatia liniara de doua variabile"
						},
						{
							"_nr": "11",
							"__text": "Programare liniara"
						},
						{
							"_nr": "12",
							"__text": "Programe nearitmetice"
						},
						{
							"_nr": "Partea III",
							"__text": "PARTEA III"
						},
						{
							"_nr": "1",
							"__text": "Mecanica fluidelor"
						},
						{
							"_nr": "2",
							"__text": "Meteorologie"
						},
						{
							"_nr": "3",
							"__text": "Fizica nucleului"
						},
						{
							"_nr": "4",
							"__text": "Fizica particulelor elementare"
						},
						{
							"_nr": "5",
							"__text": "Fizia semiconductorilor"
						},
						{
							"_nr": "6",
							"__text": "Optica"
						},
						{
							"_nr": "7",
							"__text": "Electronica"
						},
						{
							"_nr": "8",
							"__text": "Astronomie"
						},
						{
							"_nr": "9",
							"__text": "Geodezie"
						},
						{
							"_nr": "10",
							"__text": "Fotogrametrie"
						},
						{
							"_nr": "11",
							"__text": "Geologie"
						},
						{
							"_nr": "12",
							"__text": "Hidrotehnica"
						},
						{
							"_nr": "13",
							"__text": "Sisteme energetice"
						},
						{
							"_nr": "14",
							"__text": "Economie forestiera"
						},
						{
							"_nr": "15",
							"__text": "Contabilitate"
						}
					]
				},
				"_id": "1110"
			},
			{
				"titlu": "Internet pentru incepatori",
				"autori": {
					"autor": {
						"_nume": "Michael B. Karbo"
					}
				},
				"anAparitie": "2002",
				"editura": "Egmont",
				"numarPagini": "80",
				"pretOriginal": "39000",
				"pretCumparare": "2500",
				"traducere": {
					"titluOriginal": "Internet - laer det selv",
					"editura": "IDG Forlag A/S",
					"traducatori": {
						"traducator": "Andreea Tolea"
					},
					"_an": "2001",
					"_editia": "II",
					"_limba": "daneza",
					"_tara": "Danemarca"
				},
				"coperta": {
					"imagine": {
						"_path": "Internet_pentru_incepatori_Karbo.jpg"
					},
					"__text": "Aurelia Ulici"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "World Wide Web"
						},
						{
							"_nr": "Partea a doua",
							"__text": "E-mail si Usenet"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Cum functioneaza retaua"
						},
						{
							"_nr": "Anexa",
							"__text": "Instalare"
						}
					]
				},
				"_id": "1111",
				"_isbn": "973-583-375-1"
			},
			{
				"titlu": "Windows pentru incepatori",
				"autori": {
					"autor": {
						"_nume": "Britt Malka"
					}
				},
				"anAparitie": "2003",
				"editura": "Egmont",
				"numarPagini": "80",
				"pretOriginal": "39000",
				"pretCumparare": "2500",
				"traducere": {
					"titluOriginal": "Windows for Absolute Beginners",
					"editura": "IDG Danmark A/S",
					"traducatori": {
						"traducator": ""
					},
					"_an": "2002",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "Danemarca"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_pentru_incepatori_Malka.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Unelte"
						},
						{
							"_nr": "III",
							"__text": "Munca pe computer"
						},
						{
							"_nr": "Anexa",
							"__text": "Descarcarea de pe Internet"
						},
						{
							"_nr": "Index",
							"__text": "Index"
						}
					]
				},
				"_id": "1112",
				"_isbn": "973-583-326-3"
			},
			{
				"titlu": "Caiet de laborator pentru clasa a IX-a : Aplicatii C/C++ (Vol.I)",
				"autori": {
					"autor": [
						{
							"_nume": "Manuela Carpem"
						},
						{
							"_nume": "Liliana Chira"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Axa",
				"numarPagini": "124",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Caiet_de_laborator_pentru_clasa_a_IX_a_Aplicatii_C_Cpp_I_Carpen.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Functii de citire/scriere"
						},
						{
							"_nr": "2",
							"__text": "Operatorii limbajului C"
						},
						{
							"_nr": "3",
							"__text": "Structuri de control"
						},
						{
							"_nr": "4",
							"__text": "Tablouri"
						}
					]
				},
				"_id": "1113",
				"_isbn": "973-8053-91-9"
			},
			{
				"titlu": "Informatica pentru clasa a X-a : Fise de lucru pentru elevi",
				"autori": {
					"autor": [
						{
							"_nume": "Manuela Carpem"
						},
						{
							"_nume": "Liliana Chira"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Axa",
				"numarPagini": "142",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_clasa_a_X_a_Fise_de_lucru_pentru_elevi_Carpen.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Subprograme"
						},
						{
							"_nr": "2",
							"__text": "Structuri de date"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Metode Divide et Impera"
						},
						{
							"_nr": "5",
							"__text": "Metode Backtracking"
						}
					]
				},
				"_id": "1114",
				"_isbn": "973-660-041-6"
			},
			{
				"titlu": "Limbajul C/C++ pentru incepatori (anul I de studii, editia a II-a, revazuta si completata)",
				"autori": {
					"autor": [
						{
							"_nume": "Manuela Carpem"
						},
						{
							"_nume": "Liliana Chira"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Axa",
				"numarPagini": "178",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C_Cpp_pentru_incepatori_Anul_I_de_studii_Editia_a_II_a_Revazuta_si_completata_Carpen.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Tipuri fundamentale de date"
						},
						{
							"_nr": "3",
							"__text": "Functii de intrare/iesire standard"
						},
						{
							"_nr": "4",
							"__text": "Operatorii limbajului C"
						},
						{
							"_nr": "5",
							"__text": "Structuri de control in limbajul C"
						},
						{
							"_nr": "6",
							"__text": "Tablouri"
						},
						{
							"_nr": "7",
							"__text": "Pointeri"
						},
						{
							"_nr": "8",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "9",
							"__text": "Structuri"
						},
						{
							"_nr": "10",
							"__text": "Exploatarea fisierelor"
						}
					]
				},
				"_id": "1115",
				"_isbn": "973-8053-83-8"
			},
			{
				"titlu": "Limbajul C/C++ pentru incepatori (anul I de studii)",
				"autori": {
					"autor": {
						"_nume": "Manuela Carpem"
					}
				},
				"anAparitie": "2001",
				"editura": "Axa",
				"numarPagini": "150",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C_Cpp_pentru_incepatori_Anul_I_de_studii_Carpen.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza ale limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Tipuri fundamentale de date"
						},
						{
							"_nr": "3",
							"__text": "Functii de intrare/iesire standard"
						},
						{
							"_nr": "4",
							"__text": "Operatorii limbajului C"
						},
						{
							"_nr": "5",
							"__text": "Insructiunile limbajului C"
						},
						{
							"_nr": "6",
							"__text": "Tablouri"
						},
						{
							"_nr": "7",
							"__text": "Pointeri"
						},
						{
							"_nr": "8",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "9",
							"__text": "Structuri"
						},
						{
							"_nr": "10",
							"__text": "Exploatarea fisierelor"
						}
					]
				},
				"_id": "1116",
				"_isbn": "973-8053-21-8"
			},
			{
				"titlu": "Elemente de inteligenta artificiala pentru conducerea operativa a productiei",
				"autori": {
					"autor": [
						{
							"_nume": "Stelian Gutu"
						},
						{
							"_nume": "Liviu Dumitrascu"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "220",
				"pretOriginal": "18.50",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_inteligenta_artificiala_pentru_conducerea_operativa_a_productiei_Dumitrascu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente definitorii ale inteligentei umane in cadrul activitatilor economico-productive"
						},
						{
							"_nr": "2",
							"__text": "Modele ale procesului de decizie"
						},
						{
							"_nr": "3",
							"__text": "Modelul relational 'INTELEC' de reprezentare si manipulare a cunostintelor. Defintie, structura, realizare informatica si mod de utilizare"
						},
						{
							"_nr": "4",
							"__text": "Planificarea comportamentului in cadrul modelului INTELEC"
						},
						{
							"_nr": "5",
							"__text": "Conceptul de model inteligent in conducerea operativa a productiei"
						},
						{
							"_nr": "6",
							"__text": "Sistemul SOFTPLAN - element de baza in realizarea unui model inteligent pentru conducerea operativa a productiei in ramura constructiilor de masini"
						},
						{
							"_nr": "7",
							"__text": "Conducerea operativa a productiei cu ajutorul modelului 'INTELEC'"
						}
					]
				},
				"_id": "1117",
				"_isbn": ""
			},
			{
				"titlu": "Proiectarea sistemelor teleinformatice",
				"autori": {
					"autor": [
						{
							"_nume": "Lucia Coculescu"
						},
						{
							"_nume": "Valentin Cristea"
						},
						{
							"_nume": "Ioan Finta"
						},
						{
							"_nume": "Victor Patriciu"
						},
						{
							"_nume": "Florin Pilat"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "Militara",
				"numarPagini": "820",
				"pretOriginal": "26",
				"redactori": {
					"redactor": "Misu Raileanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Cucos"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_sistemelor_teleinformatice_Coculescu.jpg"
					},
					"__text": "Ion Epurean"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura sistemelor teleinformatice"
						},
						{
							"_nr": "2",
							"__text": "Utilizarea modelelor de calcul distribuit in proiectarea sistemelor teleinformatice"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea modelelor cu siruri de asteptare in proiectarea sistemelor teleinformatice"
						},
						{
							"_nr": "4",
							"__text": "Tehnologia realizarii unui sistem teleinformatic"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea sistemelor de protectie si autentificare in STI"
						},
						{
							"_nr": "6",
							"__text": "Sisteme de programe pentru realizarea aplicatiilor"
						},
						{
							"_nr": "7",
							"__text": "Evaluarea si optimizarea performantelor STI"
						}
					]
				},
				"_id": "1118",
				"_isbn": ""
			},
			{
				"titlu": "Grafica interactiva - initiere",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Dumitrescu"
						},
						{
							"_nume": "Ion Lambrescu"
						},
						{
							"_nume": "Liviu Dumitrascu"
						},
						{
							"_nume": "Andrei Dumitrescu"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Universitatii Ploiesti",
				"numarPagini": "220",
				"pretOriginal": "2000",
				"tehnoredactori": {
					"tehnoredactor": "Mihaela Arama"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_interactiva_Initiere_Dumitrescu.jpg"
					},
					"__text": "I. Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere. Concepte de baza"
						},
						{
							"_nr": "2",
							"__text": "Elemente pentru grafica"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi pentru grafica interactiva"
						},
						{
							"_nr": "4",
							"__text": "Sistemul grafic AutoCAD"
						},
						{
							"_nr": "5",
							"__text": "Pachete de programe moderne"
						},
						{
							"_nr": "6",
							"__text": "Un exemplu de aplicatie"
						}
					]
				},
				"_id": "1119",
				"_isbn": "973-96146-1-3"
			},
			{
				"titlu": "Turbo Pascal 6.0 - Programe",
				"autori": {
					"autor": [
						{
							"_nume": "Lucian Vasiu"
						},
						{
							"_nume": "Radu Grama"
						},
						{
							"_nume": "Alexandra Aldica"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "MicroInformatica",
				"tiraj": "5000",
				"numarPagini": "400",
				"pretOriginal": "3835",
				"colectia": {
					"_numarul": "25",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_6_0_Programe_Vasiu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Enciclipedia Turbo Pascal 6.0"
						},
						{
							"_nr": "2",
							"__text": "Programae care folosesc unit-urile standard"
						},
						{
							"_nr": "3",
							"__text": "Unit-uri proprii - Programe care folosesc unit-uri proprii"
						},
						{
							"_nr": "4",
							"__text": "Programare orientata pe obiecte si Turbo Vision"
						},
						{
							"_nr": "5",
							"__text": "Probleme propuse"
						}
					]
				},
				"_id": "1120",
				"_isbn": "973-96274-2-0"
			},
			{
				"titlu": "Tehnologia informatiei si comunicatiilor [Manual X]",
				"autori": {
					"autor": [
						{
							"_nume": "Mioara Gheorghe"
						},
						{
							"_nume": "Monica Tataram"
						},
						{
							"_nume": "Manuela Florea"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Corint",
				"numarPagini": "160",
				"pretOriginal": "32000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Sorin Petrescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihaela Dumitru"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnologia_informatiei_si_a_comunicatiilor_X_Tataram.jpg"
					},
					"__text": "Walter Riess"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aplicatia Power Point pentru realizarea unei prezentari computerizate"
						},
						{
							"_nr": "2",
							"__text": "Aplicatia de calcul tabelar Microsoft Excel"
						},
						{
							"_nr": "3",
							"__text": "Aplicatia de gestiune a bazelor de date Microsoft Access"
						}
					]
				},
				"_id": "1121",
				"_isbn": "973-653-739-0"
			},
			{
				"titlu": "Limbaje de programare si baze de date",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Tamas"
						},
						{
							"_nume": "Berbec Florentina"
						},
						{
							"_nume": "Ivancenco Veronica"
						},
						{
							"_nume": "Glavan Nicolae"
						},
						{
							"_nume": "Popa Gheorghe"
						},
						{
							"_nume": "Vrincianu Marinela"
						},
						{
							"_nume": "Anica-Popa Ionut"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "InfoMega",
				"numarPagini": "384",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Limbaje_de_programare_si_baze_de_date_Ilie.jpg"
					},
					"__text": "Andrei Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Logica programarii calculatoarelor"
						},
						{
							"_nr": "II",
							"__text": "Baze de date"
						},
						{
							"_nr": "III",
							"__text": "Sistemul de gestiune a bazelor de date relationale FoxPro"
						},
						{
							"_nr": "IV",
							"__text": "Introducere in programarea orientata obiect"
						},
						{
							"_nr": "V",
							"__text": "Aplicatii practica privind gestiunea produselor finite la o societate comerciala"
						}
					]
				},
				"_id": "1122",
				"_isbn": "973-99970-7-4"
			},
			{
				"titlu": "Birotica",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Somnea"
						},
						{
							"_nume": "Mihai Calciu"
						},
						{
							"_nume": "Emil Dumitrescu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Tehnica",
				"numarPagini": "276",
				"pretOriginal": "42000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Margareta Chilim"
				},
				"coperta": {
					"imagine": {
						"_path": "Birotica_Somnea.jpg"
					},
					"__text": "Margareta Chilim"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Consideratii generale"
						},
						{
							"_nr": "II",
							"__text": "Ghid pentru alegerea echipamentelor"
						},
						{
							"_nr": "III",
							"__text": "Software, principii de alegere si folosire Internet. Ghiduri de servicii"
						},
						{
							"_nr": "IV",
							"__text": "Culegere de exemple implementate"
						}
					]
				},
				"_id": "1123",
				"_isbn": "973-31-1258-5"
			},
			{
				"titlu": "Auditul sistemelor informatice",
				"autori": {
					"autor": [
						{
							"_nume": "Ali Eden"
						},
						{
							"_nume": "Victoria Stanciu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Dual Tech",
				"numarPagini": "272",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Auditul_sistemelor_informatice_Eden.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in audit - De ce auditul sistemelor informatice?"
						},
						{
							"_nr": "II",
							"__text": "Securitatea sistemelor informatice"
						},
						{
							"_nr": "III",
							"__text": "Managementul securitatii sistemelor informatice"
						},
						{
							"_nr": "IV",
							"__text": "Vulnerabilitatea si protectia sistemelor informatice"
						},
						{
							"_nr": "V",
							"__text": "Auditul aplicatiilor"
						},
						{
							"_nr": "VI",
							"__text": "Studii de caz"
						}
					]
				},
				"_id": "1124",
				"_isbn": "973-85525-7-5"
			},
			{
				"titlu": "Intrumar de laborator pentru metode numerice",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Rusu"
						},
						{
							"_nume": "Ovidiu Tol"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Universitatea Politehnica Bucuresti",
				"numarPagini": "98",
				"pretOriginal": "0",
				"pretCumparare": "2500",
				"coperta": {
					"imagine": {
						"_path": "Intrumar_de_laborator_pentru_metode_numerice_Rusu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lucrarea 1",
							"__text": "Erori"
						},
						{
							"_nr": "Lucrarea 2",
							"__text": "Rezolvarea numerica a ecuatiilor algebrice"
						},
						{
							"_nr": "Lucrarea 3",
							"__text": "rezolvarea numerica a sistemelor de ecuatii"
						},
						{
							"_nr": "Lucrarea 4",
							"__text": "Derivarea numerica"
						},
						{
							"_nr": "Lucrarea 5",
							"__text": "Integrarea numerica"
						},
						{
							"_nr": "Lucrarea 6",
							"__text": "Interpolarea"
						},
						{
							"_nr": "Lucrarea 7",
							"__text": "Metode de optimizare"
						},
						{
							"_nr": "Lucrarea 8",
							"__text": "Rezolvarea numerica a ecuatiilor diferentiale"
						},
						{
							"_nr": "Lucrarea 9",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "Lucrarea 10",
							"__text": "Transformata Fourier Rapida"
						},
						{
							"_nr": "Anexa",
							"__text": "Anexa"
						}
					]
				},
				"_id": "1125",
				"_isbn": "973-85525-7-5"
			},
			{
				"titlu": "Limbajele C si C++ pentru incepatori. Limbajul C. Volumul I",
				"autori": {
					"autor": {
						"_nume": "Liviu Negrescu"
					}
				},
				"anAparitie": "1994",
				"editura": "Albastra",
				"tiraj": "5000",
				"numarPagini": "784",
				"pretOriginal": "7500",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "27",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajele_C_si_Cpp_pentru_incepatori_Limbajul_C_Volumul_I_Editia_I_Negrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Intrari/Iesiri standard"
						},
						{
							"_nr": "3",
							"__text": "Expresii"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni"
						},
						{
							"_nr": "5",
							"__text": "Clase de memorie"
						},
						{
							"_nr": "6",
							"__text": "Initializare"
						},
						{
							"_nr": "7",
							"__text": "Programare modulara"
						},
						{
							"_nr": "8",
							"__text": "Pointeri"
						},
						{
							"_nr": "9",
							"__text": "Recursivitate"
						},
						{
							"_nr": "10",
							"__text": "Structuri si tipuri definite de utlizator"
						},
						{
							"_nr": "11",
							"__text": "Liste"
						},
						{
							"_nr": "12",
							"__text": "Arbori"
						},
						{
							"_nr": "13",
							"__text": "Tabele"
						},
						{
							"_nr": "14",
							"__text": "Sortare"
						},
						{
							"_nr": "15",
							"__text": "Din nou despre preprocesare in C"
						},
						{
							"_nr": "16",
							"__text": "Intrari/Iesiri"
						},
						{
							"_nr": "17",
							"__text": "Functii standard"
						},
						{
							"_nr": "18",
							"__text": "Gestiunea ecranului in mod text"
						},
						{
							"_nr": "19",
							"__text": "Gestiunea ecranului in mod grafic"
						}
					]
				},
				"_id": "1126",
				"_isbn": "973-96274-4-7"
			},
			{
				"titlu": "Lexiconul Hackerilor",
				"autori": {
					"autor": {
						"_nume": "L. Ivaner"
					}
				},
				"anAparitie": "1997",
				"editura": "Promedia Plus",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Serban Dronca"
				},
				"coperta": {
					"imagine": {
						"_path": "Lexiconul_Hackerilor_Ivaner.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cuvant inainte"
						},
						{
							"_nr": "2",
							"__text": "Introducere"
						},
						{
							"_nr": "3",
							"__text": "Indreptar pentru folosirea lexiconului"
						},
						{
							"_nr": "4",
							"__text": "Bibliografie"
						},
						{
							"_nr": "5",
							"__text": "A-Z"
						},
						{
							"_nr": "Anexa A",
							"__text": "Din folclorul hackerilor"
						},
						{
							"_nr": "Anexa B",
							"__text": "Un portret al lui J. Random Hacker"
						},
						{
							"_nr": "Anexa C",
							"__text": "Bibilografie"
						}
					]
				},
				"_id": "1127",
				"_isbn": "973-9275-02-8"
			},
			{
				"titlu": "Excel pentru toti",
				"autori": {
					"autor": {
						"_nume": "Greg Harvey"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "416",
				"pretOriginal": "13900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "61",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Excel For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Calin Suciu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Iancu"
				},
				"coperta": {
					"imagine": {
						"_path": "Excel_pentru_toti_Harvey.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Notiuni elementare"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Pe masura ce lucrurile se schimba"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Regasirea informatiilor"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Lucruri uimitoare pe cae le puteti face cu programul Excel"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Excel pe gustul fiecaruia"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Functii Excel (pentru oameni obisnuiti)"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Partea decaloagelor"
						},
						{
							"_nr": "Anexa",
							"__text": "Cum sa instalati pe calculatorul dvs. programul Excel fara sa va iasa peri albi"
						}
					]
				},
				"_id": "1128",
				"_isbn": "973-601-243-3"
			},
			{
				"titlu": "Corel Draw! 5 pentru toti",
				"autori": {
					"autor": {
						"_nume": "Deke McClelland"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "466",
				"pretOriginal": "13900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "56",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Corel Draw! 5 For Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Cora Radulian"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Alin Dragomir"
				},
				"coperta": {
					"imagine": {
						"_path": "Corel_Draw_5_pentru_toti_McClelland.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "CorelDRAW! 5 pentru toti"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Sa incepa grafica"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Mesajul trebuie sa 'treaca rampa' (Oricare ar fi)"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Celelalte programe uimitoare ale produsului Corel"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Partea decaloagelor"
						},
						{
							"_nr": "Anexa",
							"__text": "Instalarea pachetului CorelDraw 4"
						}
					]
				},
				"_id": "1129",
				"_isbn": "973-601-237-9"
			},
			{
				"titlu": "Proiectarea sistemelor informatice",
				"autori": {
					"autor": [
						{
							"_nume": "Victoria Stanciu"
						},
						{
							"_nume": "Alexandru Gavrila"
						},
						{
							"_nume": "Dragos Mangiuc"
						},
						{
							"_nume": "Bogdan Gheorghe Sahlean"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Dual Tech",
				"numarPagini": "246",
				"pretOriginal": "150000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_sistemelor_informatice_Stanciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme informationale si sisteme informatice"
						},
						{
							"_nr": "II",
							"__text": "Modelarea conceptuala a datelor"
						},
						{
							"_nr": "III",
							"__text": "Modelarea conceptuala a prelucrarilor"
						},
						{
							"_nr": "IV",
							"__text": "Modelarea fizica si logica a datelor"
						},
						{
							"_nr": "V",
							"__text": "Modelul organizatioal si modelul operational al prelucrarilor"
						},
						{
							"_nr": "VI",
							"__text": "Elemente de modelare obiectuala"
						},
						{
							"_nr": "VII",
							"__text": "Coduri"
						},
						{
							"_nr": "VIII",
							"__text": "Sisteme informatice financiar-bancare"
						}
					]
				},
				"_id": "1130",
				"_isbn": "973-85525-6-7"
			},
			{
				"titlu": "Ghidul utilizatorului de PC",
				"autori": {
					"autor": {
						"_nume": "Lawrence J. Magid"
					}
				},
				"anAparitie": "1996",
				"editura": "All",
				"numarPagini": "376",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"traducere": {
					"titluOriginal": "The little PC book",
					"editura": "Peachpit Press",
					"traducatori": {
						"traducator": "Silviu Timus"
					},
					"_an": "1993",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Andreea Lutic"
				},
				"tehnoredactori": {
					"tehnoredactor": "Carmen Biris"
				},
				"coperta": {
					"imagine": {
						"_path": "Ghidul_utilizatorului_de_PC_Magid.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea 1",
							"__text": "Orientarea"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Asamblarea unui sistem"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Regulile drumului"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Aprovizionarea cu programe"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Retele pentru DOS"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Retele pentru Windows"
						}
					]
				},
				"_id": "1131",
				"_isbn": "973-9229-24-7"
			},
			{
				"titlu": "CorelDraw! 5 in 389 imagini",
				"autori": {
					"autor": {
						"_nume": "Tracy Lehman Cramer"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "168",
				"pretOriginal": "7000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "84",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "CorelDRAW! VisiRef",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Alexandru Nanu"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "CorelDraw_4_in_389_imagini_Cramer.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Notiuni de baza: Dimensionarea paginii, adaugarea si stergerea paginii, casete derulante"
						},
						{
							"_nr": "2",
							"__text": "Desenare: Curbe, linii, cercuri, patrate"
						},
						{
							"_nr": "3",
							"__text": "Editare: Decupare, copiere, lipire, crearea unei clone, anularea operatiei"
						},
						{
							"_nr": "4",
							"__text": "Gestionarea fisierelor: Deschidere, salvare, importare, exportare"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de asistenta (Help): Cautare, manuale, ecranele sistemului Help"
						},
						{
							"_nr": "6",
							"__text": "Straturi: Afisare, adaugare, blocare, ordonare, strat principal"
						},
						{
							"_nr": "7",
							"__text": "Noduri: Adaugare, stergere, modificarea formei, prelucrare"
						},
						{
							"_nr": "8",
							"__text": "Obiecte: Aliniere, grupare, combinare"
						},
						{
							"_nr": "9",
							"__text": "Obiecte: Deplasare, rediminesionare, oglindire, rotire"
						},
						{
							"_nr": "10",
							"__text": "Linii de contur si tiputi de umplere: Culori, grosimi, modele"
						},
						{
							"_nr": "11",
							"__text": "Programul PHOTO-PAINT: Pensule, instrumente, efecte speciale"
						},
						{
							"_nr": "12",
							"__text": "Tiparirea: Documente, pagini, obiecte, separatii de culoare"
						},
						{
							"_nr": "13",
							"__text": "Corectarea documentelor: Verificarea ortografiei, dictionarul de sinonime, abrevieri"
						},
						{
							"_nr": "14",
							"__text": "Efecte speciale: Mixare, anvelopa, reliefare, perspectiva"
						},
						{
							"_nr": "15",
							"__text": "Simbouri si imagini grafice: Inserare si umplere repetata"
						},
						{
							"_nr": "16",
							"__text": "Notiuni de baza despre text: Introducere, fonturi, dimensiuni, tabulatori, coloane"
						},
						{
							"_nr": "17",
							"__text": "Manevrarea textului: Mutarea pe o curba si convertirea si curbe"
						},
						{
							"_nr": "18",
							"__text": "Vizualizarea documentelor: Marirea, micsorarea si afisarea"
						}
					]
				},
				"_id": "1132",
				"_isbn": "973-601-311-1"
			},
			{
				"titlu": "PC - manualul incepatorului (editia II)",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Marinescu"
						},
						{
							"_nume": "George Dimitriu"
						},
						{
							"_nume": "Mihai Trandafirescu"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "149000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "299",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "PC_Manualul_incepatorului_Editia_II_Marinescu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ghidul cumparatorului"
						},
						{
							"_nr": "II",
							"__text": "Ghidul utilizatorului"
						},
						{
							"_nr": "III",
							"__text": "Ghidul programatorului"
						}
					]
				},
				"_id": "1133",
				"_isbn": "973-20-0257-3"
			},
			{
				"titlu": "Principiile matematice ale teoriei clasificarii",
				"autori": {
					"autor": {
						"_nume": "D. Dumitrescu"
					}
				},
				"anAparitie": "1999",
				"editura": "Academiei Romane",
				"numarPagini": "428",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Petre Mocanu"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Liana Haidar",
						"Silvia Deocletian"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Principiile_matematice_ale_teoriei_clasificarii_Dumitrescu.jpg"
					},
					"__text": "Gigi Gabrila"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Preliminarii matematice"
						},
						{
							"_nr": "2",
							"__text": "Clasificarea su analiza datelor"
						},
						{
							"_nr": "3",
							"__text": "Metode probabilistice de clasificare"
						},
						{
							"_nr": "4",
							"__text": "Clasificatori instruibili"
						},
						{
							"_nr": "5",
							"__text": "Metode de clasificare bazate pe optimizarea unui functii criteriu"
						},
						{
							"_nr": "6",
							"__text": "Selectarea caracteristicilor"
						},
						{
							"_nr": "7",
							"__text": "Clasificarea ierarhica. Metode de clasificare bazate pe teoria grafelor"
						},
						{
							"_nr": "8",
							"__text": "Multimi nuantate si partitii nuantate"
						},
						{
							"_nr": "9",
							"__text": "Relatii nuantate"
						},
						{
							"_nr": "10",
							"__text": "Graful de ambiguitate si neambiguitate. Masuri nuantate. Entropia unei partitii nuantate."
						},
						{
							"_nr": "11",
							"__text": "Clasificare cu relatii nuantate"
						},
						{
							"_nr": "12",
							"__text": "Clasificare cu partitii nuantate"
						},
						{
							"_nr": "13",
							"__text": "Clasificare ierarhica divizata cu partii nuantate"
						},
						{
							"_nr": "14",
							"__text": "Clasificare nuantata divizata. Extinderi si rafinari"
						},
						{
							"_nr": "15",
							"__text": "Clasificare structurala"
						}
					]
				},
				"_id": "1134",
				"_isbn": "973-27-0589-2"
			},
			{
				"titlu": "Totul despre hardware",
				"autori": {
					"autor": {
						"_nume": "Winn L. Rosch"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "1288",
				"pretOriginal": "180000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "269",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Hardware Bible",
					"editura": "Sams Corporation",
					"traducatori": {
						"traducator": [
							"Cristian Mocanu",
							"Florin Moraru"
						]
					},
					"_an": "1997",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Totul_despre_hardware_Rosch.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de baza"
						},
						{
							"_nr": "2",
							"__text": "Placa de baza"
						},
						{
							"_nr": "3",
							"__text": "Microprocesoare"
						},
						{
							"_nr": "4",
							"__text": "Memoria"
						},
						{
							"_nr": "5",
							"__text": "Sistemul BIOS"
						},
						{
							"_nr": "6",
							"__text": "Seturile de cipuri si circuitele de suport"
						},
						{
							"_nr": "7",
							"__text": "Magistrala de extensie"
						},
						{
							"_nr": "8",
							"__text": "Tehnologii de stocare masiva"
						},
						{
							"_nr": "9",
							"__text": "Interfetele de stocare"
						},
						{
							"_nr": "10",
							"__text": "Hard-discurile"
						},
						{
							"_nr": "11",
							"__text": "Dischetele"
						},
						{
							"_nr": "12",
							"__text": "Compact-discurile"
						},
						{
							"_nr": "13",
							"__text": "Benzile magnetice"
						},
						{
							"_nr": "14",
							"__text": "Dispozitivele de intrare"
						},
						{
							"_nr": "15",
							"__text": "Sistemul de afisare"
						},
						{
							"_nr": "16",
							"__text": "Adaptorele de afisare"
						},
						{
							"_nr": "17",
							"__text": "Displayurile"
						},
						{
							"_nr": "18",
							"__text": "Sistemul audio"
						},
						{
							"_nr": "19",
							"__text": "Porturile paralele"
						},
						{
							"_nr": "20",
							"__text": "Imprimante si plotterele"
						},
						{
							"_nr": "21",
							"__text": "Porturi seriale"
						},
						{
							"_nr": "22",
							"__text": "Telecomunicatiile"
						},
						{
							"_nr": "23",
							"__text": "Lucrul in retea"
						},
						{
							"_nr": "24",
							"__text": "Alimentarea"
						},
						{
							"_nr": "25",
							"__text": "Carcasele"
						}
					]
				},
				"_id": "1135",
				"_isbn": "973-601-856-3"
			},
			{
				"titlu": "Programarea in limbajul C/C++ de la teorie la practica",
				"autori": {
					"autor": [
						{
							"_nume": "Mihail Buricea"
						},
						{
							"_nume": "Bogdan Mihail Buricea"
						},
						{
							"_nume": "Eugen Dumitru Balan"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Sitech",
				"numarPagini": "276",
				"pretOriginal": "0",
				"pretCumparare": "160000",
				"coperta": {
					"imagine": {
						"_path": "Programarea_in_limbajul_C_Cpp_de_la_teorie_la_practica_Buricea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Tema nr.1",
							"__text": "Mediul de programare Borland C. Principale comenzi ale mediului de programare Borland C"
						},
						{
							"_nr": "Tema nr.2",
							"__text": "Limbajul C. Structura unui program. Elementele de baza ale limbajului C. Identificatori, cuvinte cheie sau rezervate, cuvinte utilizator, separatori. Tipuri de date si operatii asupra acestor tipuri de date"
						},
						{
							"_nr": "Tema nr.3",
							"__text": "Operatii de intrare/iesire pe suporturile standard in C/C++"
						},
						{
							"_nr": "Tema nr.4",
							"__text": "Instructiuni de control al programului: instructiuni de atribuire, instructiuni expresie, instructiuni bloc, instructiuni de selectie(if, ?, switch), implementarea unor algoritmi cu structuri secventiale si structuri alternative de program, instructiuni de ciclare sau iterative (while, do-while, for), implementarea unor algoritmi de recurenta utilizand instructiuni de ciclare, instructiuni de salt (goto, return, break, functia exit(), continue)"
						},
						{
							"_nr": "Tema nr.5",
							"__text": "Declararea si utilizarea tablourilor de date si sirurilor de caractere in Limbajul C/C++"
						},
						{
							"_nr": "Tema nr.6",
							"__text": "Definirea si utilizarea pointerilor in Limbajul C"
						},
						{
							"_nr": "Tema nr.7",
							"__text": "Definirea, declararea si apelul functiilor in Limbajul C/C++"
						},
						{
							"_nr": "Tema nr.8",
							"__text": "Descrierea si utilizarea datelor structurate: structura, structura su campuri de biti, uniune, enumerare si definit de utilizator C/C++"
						},
						{
							"_nr": "Tema nr.9",
							"__text": "Declareare si utilizarea fisierelor in Limbajul C/C++"
						},
						{
							"_nr": "Tema nr.10",
							"__text": "Declararea si utilizarea listelor, stivelor si cozilor de date in Limbajul C/C++"
						}
					]
				},
				"_id": "1136",
				"_isbn": "973-657-300-1"
			},
			{
				"titlu": "Programarea logica - o abordare pragmatica",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Kevorchian"
						},
						{
							"_nume": "Nicoale Constantinescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Sitech",
				"numarPagini": "268",
				"pretOriginal": "0",
				"pretCumparare": "160000",
				"coperta": {
					"imagine": {
						"_path": "Programare_logica_o_abordare_pragmatica_Kevorchian.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programe logice si DATALOG"
						},
						{
							"_nr": "2",
							"__text": "Mediul Prolog"
						},
						{
							"_nr": "3",
							"__text": "Probleme de la concursurile de programare"
						},
						{
							"_nr": "4",
							"__text": "Solutiile programelor propuse"
						},
						{
							"_nr": "5",
							"__text": "Anexa Predicate"
						}
					]
				},
				"_id": "1137",
				"_isbn": "973-657-928-X"
			},
			{
				"titlu": "Ingineria sistemelor de programe de la teorie la practica",
				"autori": {
					"autor": {
						"_nume": "Mihail Buricea"
					}
				},
				"anAparitie": "2010",
				"editura": "Sitech",
				"numarPagini": "184",
				"pretOriginal": "0",
				"pretCumparare": "218000",
				"coperta": {
					"imagine": {
						"_path": "Ingineria_sistemelor_de_programe_de_la_teorie_la_practica_Buricea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Fisiere in ingineria programarii in C/C++"
						},
						{
							"_nr": "II",
							"__text": "Structuri complexe de date in ingineria programarii"
						},
						{
							"_nr": "III",
							"__text": "Algoritmi si recursivitate in ingineria programarii"
						},
						{
							"_nr": "IV",
							"__text": "Sortare si algoritmi de sortare"
						},
						{
							"_nr": "V",
							"__text": "Cautare si selectie"
						},
						{
							"_nr": "VI",
							"__text": "Tehnica Backtraking"
						},
						{
							"_nr": "VII",
							"__text": "Programarea Dinamica"
						},
						{
							"_nr": "VIII",
							"__text": "Tehnica Greedy"
						}
					]
				},
				"_id": "1138",
				"_isbn": "978-606-11-0539-7"
			},
			{
				"titlu": "Sisteme de baze de date - fundamente teoretice",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Lupsoiu"
						},
						{
							"_nume": "Dorel Savulea"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Sitech",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "218000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_baze_de_date_fundamente_teoretice_Lupsoru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "II",
							"__text": "Concepte ale modelului relational"
						},
						{
							"_nr": "III",
							"__text": "Operatori relationali"
						},
						{
							"_nr": "IV",
							"__text": "Dependente functionale"
						},
						{
							"_nr": "V",
							"__text": "Restrictii redundante in baze de date"
						},
						{
							"_nr": "VI",
							"__text": "Dependente multivoce"
						},
						{
							"_nr": "VII",
							"__text": "Baze de date si forme normale"
						},
						{
							"_nr": "VIII",
							"__text": "Transformari conservative"
						},
						{
							"_nr": "IX",
							"__text": "Teoria reprezentarii"
						},
						{
							"_nr": "X",
							"__text": "Sisteme de interogare"
						},
						{
							"_nr": "XI",
							"__text": "Optimizarea interograrilor"
						},
						{
							"_nr": "XII",
							"__text": "Baze de date distribuite si paralele"
						},
						{
							"_nr": "XIII",
							"__text": "Procesarea paralela a interogarilor"
						},
						{
							"_nr": "XIV",
							"__text": "Baze de date orientate pe obiecte"
						},
						{
							"_nr": "XV",
							"__text": "Baze de date deductive"
						}
					]
				},
				"_id": "1139",
				"_isbn": "978-606-11-0567-0"
			},
			{
				"titlu": "Structuri de date si algoritmi. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Dumintru Dan Burdescu"
						},
						{
							"_nume": "Marian Cristian Mihaescu"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Sitech",
				"numarPagini": "360",
				"pretOriginal": "0",
				"pretCumparare": "327000",
				"coperta": {
					"imagine": {
						"_path": "Stucturi_de_date_si_algorimi_2010_Burdescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Structuri arborescente"
						},
						{
							"_nr": "1.1",
							"__text": "Arbori binari (2-3)"
						},
						{
							"_nr": "1.2",
							"__text": "Arbori oarecare (a-b)"
						},
						{
							"_nr": "1.3",
							"__text": "Arbori de cautare"
						},
						{
							"_nr": "1.4",
							"__text": "Arbori echilibrati"
						},
						{
							"_nr": "1.5",
							"__text": "Arbori optimali"
						},
						{
							"_nr": "1.6",
							"__text": "Arbori Splay si arbori de cautare aletori"
						},
						{
							"_nr": "II",
							"__text": "Structuri arborescente multicai"
						},
						{
							"_nr": "2.1",
							"__text": "Arbori multicai (2-3)"
						},
						{
							"_nr": "2.2",
							"__text": "Arbori multicai (a-b)"
						},
						{
							"_nr": "2.3",
							"__text": "Arbori B"
						},
						{
							"_nr": "2.4",
							"__text": "Arbori Trie"
						},
						{
							"_nr": "2.5",
							"__text": "Moviel Fibonacii si movile binome"
						},
						{
							"_nr": "III",
							"__text": "Structuri arborecene spatiale"
						},
						{
							"_nr": "3.1",
							"__text": "Arbori M"
						},
						{
							"_nr": "3.2",
							"__text": "Arbori M+"
						},
						{
							"_nr": "3.3",
							"__text": "Arbori R"
						},
						{
							"_nr": "3.4",
							"__text": "Arbori R+"
						},
						{
							"_nr": "3.5",
							"__text": "Arbori R*"
						}
					]
				},
				"_id": "1140",
				"_isbn": "978-973-746-646-4"
			},
			{
				"titlu": "Verificarea si validarea sistemlor soft",
				"autori": {
					"autor": {
						"_nume": "Militon Frentiu"
					}
				},
				"anAparitie": "2010",
				"editura": "Presa Universitatii Clujeana",
				"numarPagini": "234",
				"pretOriginal": "0",
				"pretCumparare": "186900",
				"coperta": {
					"imagine": {
						"_path": "Verificarea_si_validarea_sistemelor_soft_Frentiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Corectitudinea programelor"
						},
						{
							"_nr": "1",
							"__text": "Corectitudinea algoritmilor"
						},
						{
							"_nr": "2",
							"__text": "Dezvoltarea programelor din specificatii"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Verificarea si validarea programelor"
						},
						{
							"_nr": "3",
							"__text": "Testarea programelor"
						},
						{
							"_nr": "4",
							"__text": "Inspectarea programelor"
						},
						{
							"_nr": "5",
							"__text": "Executia simbolica"
						},
						{
							"_nr": "6",
							"__text": "Verificarea modelelor"
						},
						{
							"_nr": "7",
							"__text": "Verificare, validare, certificare"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Consecinte asupra metodologiei programarii"
						},
						{
							"_nr": "8",
							"__text": "Cleanroom"
						},
						{
							"_nr": "9",
							"__text": "Asigurarea calitatii softului"
						},
						{
							"_nr": "10",
							"__text": "Consecinte asupra metodologiei programarii"
						}
					]
				},
				"_id": "1141",
				"_isbn": "978-973-610-979-9"
			},
			{
				"titlu": "FoxPro",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriel Dima"
						},
						{
							"_nume": "Mihai Dima"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "Teora",
				"numarPagini": "456",
				"pretOriginal": "6950",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "23",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "FoxPro_Dima.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere"
						},
						{
							"_nr": "II",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "III",
							"__text": "Variabile si masive"
						},
						{
							"_nr": "IV",
							"__text": "Baze de date"
						},
						{
							"_nr": "V",
							"__text": "Intrare/Iesire"
						},
						{
							"_nr": "VI",
							"__text": "Programe"
						},
						{
							"_nr": "VII",
							"__text": "Interfata sistem a FoxPro"
						},
						{
							"_nr": "VIII",
							"__text": "Generatoare"
						},
						{
							"_nr": "IX",
							"__text": "Lucrul in retea"
						},
						{
							"_nr": "X",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1142",
				"_isbn": "973-601-117-8"
			},
			{
				"titlu": "Sisteme de informare in management(Note de curs)",
				"autori": {
					"autor": {
						"_nume": "Stefan Brad"
					}
				},
				"anAparitie": "2005",
				"editura": "Academia de Studii Economice",
				"numarPagini": "456",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_informare_in_management_Note_de_curs_Brad.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme - definitii, concepte"
						},
						{
							"_nr": "II",
							"__text": "Fundamentele managementului informatiei"
						},
						{
							"_nr": "III",
							"__text": "Introducere in sisteme de informare"
						},
						{
							"_nr": "IV",
							"__text": "Sisteme de asistare a deciziei si sisteme expert"
						},
						{
							"_nr": "V",
							"__text": "Planificarea si dezvoltarea sistemelor de informare"
						},
						{
							"_nr": "VI",
							"__text": "Structura si design-ului sistemelor"
						},
						{
							"_nr": "VII",
							"__text": "Dezvoltarea sistemelor de informare pentru utilizatori finali"
						},
						{
							"_nr": "VIII",
							"__text": "Implementarea sistemelor de informare"
						},
						{
							"_nr": "IX",
							"__text": "Impactul sistemelor informatice asupra strategiei organizatiei"
						},
						{
							"_nr": "X",
							"__text": "Firma digitala o oportunitate?"
						}
					]
				},
				"_id": "1143",
				"_isbn": ""
			},
			{
				"titlu": "Informatica - Manual (varianta Pascal)[XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Daniela Oprescu"
						},
						{
							"_nume": "Liana Bejan Ienulescu"
						},
						{
							"_nume": "Viorica Patrascu"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Niculescu",
				"numarPagini": "184",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_XI_Varianta_Pascal_Oprescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de teoira grafurilor"
						},
						{
							"_nr": "II",
							"__text": "Alocarea dinamica a memoriei interne"
						},
						{
							"_nr": "III",
							"__text": "Elemente de programare orientata pe obiecte"
						},
						{
							"_nr": "IV",
							"__text": "Aplicatii practice"
						}
					]
				},
				"_id": "1144",
				"_isbn": "973-568-545-0"
			},
			{
				"titlu": "PC - manualul incepatorului",
				"autori": {
					"autor": [
						{
							"_nume": "Dan Marinescu"
						},
						{
							"_nume": "Mihai Trandafirescu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "224",
				"pretOriginal": "9000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "10",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "PC_Manualul_incepatorului_Editia_I_Marinescu.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ghidul cumparatorului"
						},
						{
							"_nr": "II",
							"__text": "Ghidul utilizatorului"
						},
						{
							"_nr": "III",
							"__text": "Ghidul programatorului"
						}
					]
				},
				"_id": "1145",
				"_isbn": "973-601-088-0"
			},
			{
				"titlu": "Informatica - probleme propuse si rezolvate",
				"autori": {
					"autor": [
						{
							"_nume": "Rodica Cherciu"
						},
						{
							"_nume": "Dan Grigoriu"
						},
						{
							"_nume": "Irina Iosupescu"
						},
						{
							"_nume": "Cecilia Balanescu"
						},
						{
							"_nume": "Simona Petrescu"
						},
						{
							"_nume": "Marcel Homorodean"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "Niculescu",
				"numarPagini": "296",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_Probleme_propuse_si_rezolvate_Cherciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "2",
							"__text": "Tablouri unidimensionale"
						},
						{
							"_nr": "3",
							"__text": "Tablouri bidimensionale"
						},
						{
							"_nr": "4",
							"__text": "Fisiere"
						},
						{
							"_nr": "5",
							"__text": "Subprograme"
						},
						{
							"_nr": "6",
							"__text": "Recursivitate"
						},
						{
							"_nr": "7",
							"__text": "Backtracking"
						},
						{
							"_nr": "8",
							"__text": "Variabile dimanice. Liste"
						},
						{
							"_nr": "9",
							"__text": "Algoritmul de sortare rapida (QuickSort)"
						},
						{
							"_nr": "10",
							"__text": "Teste"
						}
					]
				},
				"_id": "1146",
				"_isbn": "973-568-736-4"
			},
			{
				"titlu": "AMC 11 (Automatica - Metrologie - Calculatoare)",
				"autori": {
					"autor": [
						{
							"_nume": "C. Simbotin"
						},
						{
							"_nume": "N. Ghita"
						},
						{
							"_nume": "P. P. Popescu"
						},
						{
							"_nume": "V. Petrescu"
						},
						{
							"_nume": "A. Millea"
						},
						{
							"_nume": "M. Solomon"
						},
						{
							"_nume": "L. Trufinescu"
						},
						{
							"_nume": "C. Tapu"
						},
						{
							"_nume": "C. Penescu"
						},
						{
							"_nume": "G. Ionescu"
						},
						{
							"_nume": "Fl. Tanasescu"
						},
						{
							"_nume": "S. Lehaci"
						},
						{
							"_nume": "I. Papadache"
						},
						{
							"_nume": "G. Weinrich"
						},
						{
							"_nume": "Grosu Mircea"
						},
						{
							"_nume": "Chiric Toma"
						},
						{
							"_nume": "Dimitriu Smaranda"
						},
						{
							"_nume": "Zgavirdici Carmen"
						}
					]
				},
				"anAparitie": "1968",
				"editura": "Tehnica",
				"tiraj": "1300+140",
				"numarPagini": "116",
				"pretOriginal": "12",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Zamfirescu Paul"
				},
				"tehnoredactori": {
					"tehnoredactor": "Nicolae Serbanescu"
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_11.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Manifestari internationale importante in domeniul automaticii, calculatoarelor, metrologiei, in perioada martie 1968 - februarie 1969"
						},
						{
							"_nr": "2",
							"__text": "Congresul MESUCORA - Paris 1967"
						},
						{
							"_nr": "3",
							"__text": "Realizari recente de mijloce de masurare in domeniul marimilor mecanice si acustice (sinteza din lucrarile congresului MESUCORA, 1967)"
						},
						{
							"_nr": "4",
							"__text": "Masurari specifice sistemelor electroenergetice, masurari electrice si electronice, siguranta in functionare a echipamentelor si componentelor(sinteza din lucrarile congresului MESUCORA, 1967)"
						},
						{
							"_nr": "5",
							"__text": "Analizoare, metode automate de analiza si automatizari cu reactie analitica (sinteza din lucrarile congresului MESUCORA, 1967)"
						},
						{
							"_nr": "6",
							"__text": "Analiza dinamica a sistemelor a sistemelor, conducerea automatica a proceselor, inregistrarea si prelucrarea masurarilor (sinteza din lucrarile congresului MESUCORA, 1967)"
						},
						{
							"_nr": "7",
							"__text": "Congresul IMEKO-Varsovia, 1967"
						},
						{
							"_nr": "8",
							"__text": "Comunicariile congresului IMEKO 1967"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea unui calculator analogic pentru verificarea transmisiei unui semnal printr-un circuit de masurare"
						},
						{
							"_nr": "10",
							"__text": "Sectiunea de comunicari tehnico-stiintifice in automatica"
						},
						{
							"_nr": "11",
							"__text": "Stadiul actual in perspectivele actionarilor electrice reglabile"
						}
					]
				},
				"_id": "1147",
				"_isbn": ""
			},
			{
				"titlu": "AMC 25 (Automatica - Management - Calculatoare)",
				"autori": {
					"autor": [
						{
							"_nume": "E. Niculescu-Mizil"
						},
						{
							"_nume": "I. Sandulescu"
						},
						{
							"_nume": "C. Belea"
						},
						{
							"_nume": "O. Cernian"
						},
						{
							"_nume": "Gh. Marian"
						},
						{
							"_nume": "A. Ionescu"
						},
						{
							"_nume": "J. M. Bogdan"
						},
						{
							"_nume": "Lucia Coculescu"
						},
						{
							"_nume": "L. D. Serbanati"
						},
						{
							"_nume": "D. Teodorescu"
						},
						{
							"_nume": "D. F. Lazaroiu"
						},
						{
							"_nume": "J. Weisman"
						},
						{
							"_nume": "T. Colosi"
						},
						{
							"_nume": "Maria Ionescu-Balcesti"
						},
						{
							"_nume": "A. Cutcutache"
						},
						{
							"_nume": "M. I. Belcea"
						},
						{
							"_nume": "M. Bratan"
						},
						{
							"_nume": "V. Pascu"
						},
						{
							"_nume": "M. Berinde"
						},
						{
							"_nume": "M. Dumitrescu"
						},
						{
							"_nume": "E. Bruder"
						},
						{
							"_nume": "Al. Bruder"
						},
						{
							"_nume": "M. Stoica"
						},
						{
							"_nume": "P. Isac"
						},
						{
							"_nume": "J. Basoc"
						},
						{
							"_nume": "I. Faur"
						}
					]
				},
				"anAparitie": "1977",
				"editura": "Tehnica",
				"tiraj": "2150+65 exemplare brosate",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": [
						"Paul Zamfirescu",
						"Smaranda Dimitriu",
						"Mircea Grosu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "AMC_25.jpg"
					},
					"__text": "Constantin Guluta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calendar. Retrospective. Perspective"
						},
						{
							"_nr": "2",
							"__text": "Starile entropice din sistemul mondial si influenta lor asupra modelarii dezvoltarii unui sistem social-economic national"
						},
						{
							"_nr": "3",
							"__text": "Necesitatrea perfectionarii metodelor de realizare a sistemelor informatice"
						},
						{
							"_nr": "4",
							"__text": "Implementarea unui sistem de minicalculatoare WANG 2200 la Univeristatea din Craiova"
						},
						{
							"_nr": "5",
							"__text": "Concepte actuale in implementarea sistemelor informatice. Un nou sistem de gestiune a prelucrarilor"
						},
						{
							"_nr": "6",
							"__text": "Tendinte acuale in informatica repartizata"
						},
						{
							"_nr": "7",
							"__text": "Metode si tehnici de asigurare a portabilitatii programelor"
						},
						{
							"_nr": "8",
							"__text": "Metode noi de sinteza a sistemelor automate liniare prin vectori specifici"
						},
						{
							"_nr": "9",
							"__text": "Fiabilitatea echipamentelor tehnice din punctul de vedere al solicitarilor climatice si mecanice si standardizarea incercarilor"
						},
						{
							"_nr": "10",
							"__text": "Algebra de aproximare a sistemelor cu elemente liniare si neliniare"
						},
						{
							"_nr": "11",
							"__text": "Sisteme cibernetice multilocale"
						},
						{
							"_nr": "12",
							"__text": "Utilizarea simularii proceselor tehnice in regim stationar la analiza si proictarea instalatiilor chimice"
						},
						{
							"_nr": "13",
							"__text": "Dimanica starilor intr-un sitem electronic de calcul"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea cercetarilor de piata interna si externa la functionarea strategiei dezvoltarii unitatiilor econimice socialiste"
						},
						{
							"_nr": "15",
							"__text": "Indicatori de evaluare a conducerii si organizarii pentru un nou obiectiv economic"
						},
						{
							"_nr": "16",
							"__text": "Contributii in interpretarea cibernetica a muncii intelectuale"
						},
						{
							"_nr": "17",
							"__text": "Interconexiunea matematica-cibernetica"
						},
						{
							"_nr": "18",
							"__text": "Capacitatea de productie"
						},
						{
							"_nr": "19",
							"__text": "Intreprinderea si conceptia sistemica. Ciclul de instruire participativa pentru analistii de sistem (IV)"
						}
					]
				},
				"_id": "1148",
				"_isbn": ""
			},
			{
				"titlu": "Prelucrarea electronica a informatiei economice",
				"autori": {
					"autor": [
						{
							"_nume": "Lascu Ricu"
						},
						{
							"_nume": "Ion Stefanescu"
						},
						{
							"_nume": "Constantin Staicu"
						},
						{
							"_nume": "Florin Bica"
						}
					]
				},
				"anAparitie": "1983",
				"editura": "Scrisul romanesc",
				"numarPagini": "372",
				"pretOriginal": "29",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Vasile Predescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Lunulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea_electronica_a_informatiei_economice_Ricu.jpg"
					},
					"__text": "Eustatiu Gregorian"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bazele teoretice ale prelucrarii datelor"
						},
						{
							"_nr": "2",
							"__text": "Echipamente electronice de medie mecanizare"
						},
						{
							"_nr": "3",
							"__text": "Suporturi de intregistrare a datelor in vederea prelucrarii automate. Organizarea datelor pe suporturi"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de prelucrare automata a datelor"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "6",
							"__text": "Programarea calculatoarelor electronice"
						},
						{
							"_nr": "7",
							"__text": "Prezentarea limbajului de programare COBOL ANS-2"
						},
						{
							"_nr": "8",
							"__text": "Liumbajul de programare COBOL ANS-2 pentru prelucrarea datelor organizate in fisire"
						},
						{
							"_nr": "9",
							"__text": "Exploatarea programelor elaborate in limbajul COBOL ANS-2"
						},
						{
							"_nr": "10",
							"__text": "Tendinte in constructia si utilizarea tehnicii electonice de calcul"
						}
					]
				},
				"_id": "1149",
				"_isbn": ""
			},
			{
				"titlu": "Programarea si utilizarea echipamentelor electronice FELIX FC in prelucrarea datelor economice cu aplicatii in agricultura",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Negescu"
						},
						{
							"_nume": "Vasile Florescu"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Ceres",
				"numarPagini": "176",
				"pretOriginal": "12",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Olga Alexei"
				},
				"tehnoredactori": {
					"tehnoredactor": "Stelianu Parizianu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_si_utilizarea_echipamentelor_electronice_FELIX_FC_in_prelucrarea_datelor_economice_cu_aplicatii_in_agriculatura_Negescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive privind folosirea echipamentelor electronice FELIX FC in prelucrarea datelor economice"
						},
						{
							"_nr": "2",
							"__text": "Caracteristicile tehnico-functionale ale masinilor electronice de facturat - contabilizat FELIX FC si posiblitati de programare"
						},
						{
							"_nr": "3",
							"__text": "Posiblitati de folosire a echipamentelor electronice FELIX FC in unitatile agricole"
						},
						{
							"_nr": "4",
							"__text": "Aplicatii practice de folosre a masinilor electronice FELIX FC in prelucrarea datelor economice"
						}
					]
				},
				"_id": "1150",
				"_isbn": ""
			},
			{
				"titlu": "Programarea calculatoarelor electonice",
				"autori": {
					"autor": {
						"_nume": "Dragos Vaida"
					}
				},
				"anAparitie": "1967",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "386",
				"pretOriginal": "18.50",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Ionescu Victor"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_calculatoarelor_electronice_Vaida.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Principii generale"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Programarea in cod cu aplicatie la calculatorul ECONOMIST CIFA 102"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Limbajul algoritmic ALGOL"
						}
					]
				},
				"_id": "1151",
				"_isbn": ""
			},
			{
				"titlu": "Microarhitecturi de procesare a informatiei",
				"autori": {
					"autor": [
						{
							"_nume": "Lucian N. Vintan"
						},
						{
							"_nume": "Adrian Florea"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Tehnica",
				"numarPagini": "312",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Microarhitecturi_de_procesare_a_informatiei_Vintan.jpg"
					},
					"__text": "Andreea Staiuc"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura microprocesorelor"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura microprocesorelor acutale"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura sistemului ierarhizat pe memorie"
						},
						{
							"_nr": "4",
							"__text": "O memorie moderna reprezentativa : HSA"
						},
						{
							"_nr": "5",
							"__text": "Procesorul IA-64: intre evolutie si revolutie"
						},
						{
							"_nr": "6",
							"__text": "Arhitectura calculatorelor, incotro"
						},
						{
							"_nr": "7",
							"__text": "Simularea unei microarhitecturi avansate"
						},
						{
							"_nr": "8",
							"__text": "Arhitectura sistemelor multiprocesor"
						},
						{
							"_nr": "9",
							"__text": "Probleme propuse spre rezolvare"
						},
						{
							"_nr": "10",
							"__text": "Indicatii de solutionare"
						},
						{
							"_nr": "11",
							"__text": "Ce gasiti pe CD?"
						}
					]
				},
				"_id": "1152",
				"_isbn": "973-31-1551-7"
			},
			{
				"titlu": "Calculatoare automate si programare",
				"autori": {
					"autor": [
						{
							"_nume": "C. Strugaru"
						},
						{
							"_nume": "I. Hoffman"
						}
					]
				},
				"anAparitie": "1967",
				"editura": "Institutul Politehnic Timisoara - Facultatea electrotehnica",
				"numarPagini": "310",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_automate_si_programare_Strugaru.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Principiul de functionare al calculatoarelor electronice numerice"
						},
						{
							"_nr": "II",
							"__text": "Bazele aritmetice"
						},
						{
							"_nr": "III",
							"__text": "Algebra booleana"
						},
						{
							"_nr": "IV",
							"__text": "Minimizarea functiilor logice"
						},
						{
							"_nr": "V",
							"__text": "Circuite logice"
						},
						{
							"_nr": "VI",
							"__text": "Dispozitivele calculatorului numeric"
						},
						{
							"_nr": "VII",
							"__text": "Programarea calculatoarelor electronice numerice"
						},
						{
							"_nr": "VIII",
							"__text": "Dispozitive pentru introducere si extragere"
						},
						{
							"_nr": "IX",
							"__text": "Calculatoare analogice electronice"
						}
					]
				},
				"_id": "1153",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in programarea automata",
				"autori": {
					"autor": {
						"_nume": "Paul Constantinescu"
					}
				},
				"anAparitie": "1967",
				"editura": "Tehnica",
				"tiraj": "3000+140 ex brosate",
				"numarPagini": "224",
				"pretOriginal": "10.50",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Mihai Dan Lutic"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programarea_automata_Constantinescu.jpg"
					},
					"__text": "V. Visan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ce este programarea automata"
						},
						{
							"_nr": "II",
							"__text": "Identificatori si expresii"
						},
						{
							"_nr": "III",
							"__text": "Instructiunile in ALGOL"
						},
						{
							"_nr": "IV",
							"__text": "Declaratii si proceduri in ALGOL"
						},
						{
							"_nr": "V",
							"__text": "Principii de alcatuire a programului translator"
						},
						{
							"_nr": "VI",
							"__text": "Diferite programe in ALGOL"
						}
					]
				},
				"_id": "1154",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in cibernetica sistemelor discrete",
				"autori": {
					"autor": [
						{
							"_nume": "Edmond Nicolau"
						},
						{
							"_nume": "Alex. Popovici"
						}
					]
				},
				"anAparitie": "1966",
				"editura": "Tehnica",
				"tiraj": "2800+140 ex brosate",
				"numarPagini": "272",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Avram Marcovici"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Geru"
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_cibernetica_sistemelor_discrete_Nicolau.jpg"
					},
					"__text": "M. Stelea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Elemente de algebra si grafe"
						},
						{
							"_nr": "II",
							"__text": "Elemente de logica matematica"
						},
						{
							"_nr": "III",
							"__text": "Modelarea"
						},
						{
							"_nr": "IV",
							"__text": "Sinteza circuitelor"
						},
						{
							"_nr": "V",
							"__text": "Circuite de comutatie"
						},
						{
							"_nr": "VI",
							"__text": "Automate cu un numar finit de stari"
						},
						{
							"_nr": "VII",
							"__text": "Calculatoare digitale"
						},
						{
							"_nr": "VIII",
							"__text": "Programarea calculatoarelor digitale"
						},
						{
							"_nr": "IX",
							"__text": "Teoria probabilitatilor"
						},
						{
							"_nr": "X",
							"__text": "Informatica"
						},
						{
							"_nr": "XI",
							"__text": "Automate aleatoare"
						},
						{
							"_nr": "XII",
							"__text": "Elemente de programare matematica"
						},
						{
							"_nr": "XIII",
							"__text": "Recunoasterea formelor"
						}
					]
				},
				"_id": "1155",
				"_isbn": ""
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": {
						"_nume": "Gabriela Stroe"
					}
				},
				"anAparitie": "1992",
				"editura": "Universitatea Romano-Americana",
				"numarPagini": "106",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_Stroe.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Informatie. Reprezentarea informatiilor"
						},
						{
							"_nr": "2",
							"__text": "Sistem de calcul"
						},
						{
							"_nr": "3",
							"__text": "Perifericele sistemului de calcul"
						},
						{
							"_nr": "4",
							"__text": "Sistemul de operare CP/M"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de operare Unix"
						},
						{
							"_nr": "6",
							"__text": "Sistemul de operare MS-DOS"
						},
						{
							"_nr": "7",
							"__text": "Norton Commander"
						},
						{
							"_nr": "8",
							"__text": "Editorul de texte WordStar"
						}
					]
				},
				"_id": "1156",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de calcul cu acces multiplu",
				"autori": {
					"autor": {
						"_nume": "M.V. Wilkes"
					}
				},
				"anAparitie": "1974",
				"editura": "Tehnica",
				"tiraj": "5000+125 ex. brosate",
				"numarPagini": "192",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"colectia": {
					"_numarul": "43",
					"_nume": "AI"
				},
				"traducere": {
					"titluOriginal": "Time-sharing computer systems",
					"editura": "Macdonald - London and American Elsevier Inc. - New-Work",
					"traducatori": {
						"traducator": "Vasile Baltac"
					},
					"_an": "1972",
					"_editia": "II",
					"_limba": "engleza",
					"_tara": "Marea Britanie"
				},
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dumitru Nicolescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_calcul_cu_acces_multiplu_Wilkes.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Accesul multiplu din punctul de vedere al utilizatorului"
						},
						{
							"_nr": "3",
							"__text": "Primele sisteme si principii generale"
						},
						{
							"_nr": "4",
							"__text": "Conducerea proceselor"
						},
						{
							"_nr": "5",
							"__text": "Administrarea spatiului de memorie"
						},
						{
							"_nr": "6",
							"__text": "Planificarea"
						},
						{
							"_nr": "7",
							"__text": "Calculatoare satelit si reprezentari grafice"
						},
						{
							"_nr": "8",
							"__text": "Sisteme de fisiere"
						},
						{
							"_nr": "9",
							"__text": "Aspecte privind conducerea si operarea sistemelor cu acces multiplu"
						}
					]
				},
				"_id": "1157",
				"_isbn": ""
			},
			{
				"titlu": "Indrumator pentru elaborarea proiectelor de an la disciplina 'Limbaje de programare a calculatoarelor electronice'",
				"autori": {
					"autor": [
						{
							"_nume": "V. Rosca"
						},
						{
							"_nume": "C. Apostol"
						},
						{
							"_nume": "I. Ivan"
						},
						{
							"_nume": "I. Gh. Rosca"
						},
						{
							"_nume": "P. Nastase"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Academia de Studii Economice",
				"numarPagini": "140",
				"pretOriginal": "9.25",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Indrumar_pentru_elaborarea_proiectelor_de_an_la_disciplina_Limbaje_de_programare_a_calculatoarelor_electronice_Studii_de_caz_Rosca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metodologia de realizare a proiectului"
						},
						{
							"_nr": "2",
							"__text": "Studii de caz nr.1 : Optimizarea stocurilor de materiale intr-o intreprindere industriala"
						},
						{
							"_nr": "3",
							"__text": "Studii de caz nr.2 : Urmarirea indeplinirii planului de desfacere la un magazin universal"
						},
						{
							"_nr": "4",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1158",
				"_isbn": ""
			},
			{
				"titlu": "Matematici aplicate in economie: Programari si grafuri (Teorie si aplicatii)",
				"autori": {
					"autor": [
						{
							"_nume": "Cr. Dinescu"
						},
						{
							"_nume": "V. Burlacu"
						},
						{
							"_nume": "V. Butescu"
						},
						{
							"_nume": "B. Savulescu"
						},
						{
							"_nume": "D. Vasiliu"
						}
					]
				},
				"anAparitie": "1984",
				"editura": "Academia de Studii Economice",
				"numarPagini": "426",
				"pretOriginal": "26.50",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Matematici_speciale_aplicate_in_economie_Programri_si_grafuri_Teorie_si_aplicatii_Dinescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Extensii in programare liniara"
						},
						{
							"_nr": "1",
							"__text": "Extensii in programare liniara"
						},
						{
							"_nr": "2",
							"__text": "Programare liniara discreta"
						},
						{
							"_nr": "3",
							"__text": "Programare matematica neliniara"
						},
						{
							"_nr": "4",
							"__text": "Programarea dinamica"
						},
						{
							"_nr": "II",
							"__text": "Combinatorica si teoria grafurilor"
						},
						{
							"_nr": "5",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "6",
							"__text": "Arbori si arborescente"
						},
						{
							"_nr": "7",
							"__text": "Drumuri optime"
						},
						{
							"_nr": "8",
							"__text": "Fluxuri in retele de transport"
						},
						{
							"_nr": "9",
							"__text": "Alte probleme de interes practic rezolvate cu ajutorul teoriei grafurilor"
						}
					]
				},
				"_id": "1159",
				"_isbn": ""
			},
			{
				"titlu": "Prelucrarea datelor experimentale cu calculatoare numerice: aplicatii din constructia de masini",
				"autori": {
					"autor": [
						{
							"_nume": "Ioan Constantinescu"
						},
						{
							"_nume": "Dan Golumbovici"
						},
						{
							"_nume": "Constantin Militaru"
						}
					]
				},
				"anAparitie": "1980",
				"editura": "Tehnica",
				"numarPagini": "260",
				"pretOriginal": "22",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Vasile Buzatu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea_datelor_experimentale_cu_calculatoare_numerice_Constantinescu.jpg"
					},
					"__text": "Valerian Agalopol"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Prelucrarea sirurilor simple de date"
						},
						{
							"_nr": "3",
							"__text": "Prelucrarea sirurilor de date obtinute in functie de o variabila independenta"
						},
						{
							"_nr": "4",
							"__text": "Prelucrarea sirurilor multimensionale"
						},
						{
							"_nr": "5",
							"__text": "Prelucrarea rezultatelor masurarilor efectuate cu traductoare tenometrice rezistive"
						},
						{
							"_nr": "6",
							"__text": "Limbajul specializat PREDEX"
						},
						{
							"_nr": "7",
							"__text": "Exemple de prelucrare a datelor experimentale in constructia de masini"
						}
					]
				},
				"_id": "1160",
				"_isbn": ""
			},
			{
				"titlu": "Cibernetica",
				"autori": {
					"autor": [
						{
							"_nume": "Edmond Nicolau"
						},
						{
							"_nume": "Constantin Balaceanu"
						}
					]
				},
				"anAparitie": "1961",
				"editura": "Stiintifica",
				"tiraj": "6000+145",
				"numarPagini": "492",
				"pretOriginal": "15.20",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Sanda Stoian"
				},
				"tehnoredactori": {
					"tehnoredactor": "Gheorghe Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Cibernetica_Nicolau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "O pagina din istoria stiintei contemporane"
						},
						{
							"_nr": "2",
							"__text": "Principii metodice"
						},
						{
							"_nr": "3",
							"__text": "De la tam-tam la fata invizibila a Lunii"
						},
						{
							"_nr": "4",
							"__text": "Energetica si teoria informatiei"
						},
						{
							"_nr": "5",
							"__text": "Putina fonetica"
						},
						{
							"_nr": "6",
							"__text": "Canale de comuncatie"
						},
						{
							"_nr": "7",
							"__text": "Rezonanta-filtre"
						},
						{
							"_nr": "8",
							"__text": "Modulatia"
						},
						{
							"_nr": "9",
							"__text": "Esantionarea semnalelor"
						},
						{
							"_nr": "10",
							"__text": "Modulatia in impulsuri"
						},
						{
							"_nr": "11",
							"__text": "Analog-digital"
						},
						{
							"_nr": "12",
							"__text": "Stabilitatea la perturbatii"
						},
						{
							"_nr": "13",
							"__text": "O lege fiziologica"
						},
						{
							"_nr": "14",
							"__text": "Volumul mesajului"
						},
						{
							"_nr": "15",
							"__text": "Semnalul nervos"
						},
						{
							"_nr": "16",
							"__text": "Neuronul"
						},
						{
							"_nr": "17",
							"__text": "Sinapsa. Hormoni"
						},
						{
							"_nr": "18",
							"__text": "Cantitatea de informatie"
						},
						{
							"_nr": "19",
							"__text": "Probabilitati"
						},
						{
							"_nr": "20",
							"__text": "Entropia termodinamica"
						},
						{
							"_nr": "21",
							"__text": "Entropia informationala"
						},
						{
							"_nr": "22",
							"__text": "Entropia termodinamica si informatia"
						},
						{
							"_nr": "23",
							"__text": "Lanturi"
						},
						{
							"_nr": "24",
							"__text": "Redondanta"
						},
						{
							"_nr": "25",
							"__text": "Coduri"
						},
						{
							"_nr": "26",
							"__text": "Capacitatea unui canal"
						},
						{
							"_nr": "27",
							"__text": "Teorema adaptarii"
						},
						{
							"_nr": "28",
							"__text": "Traductorii"
						},
						{
							"_nr": "29",
							"__text": "Excitatie si inhibatie"
						},
						{
							"_nr": "30",
							"__text": "Receptorii"
						},
						{
							"_nr": "31",
							"__text": "Organe de executie"
						},
						{
							"_nr": "32",
							"__text": "Dispozitive electronice"
						},
						{
							"_nr": "33",
							"__text": "Efectorii"
						},
						{
							"_nr": "34",
							"__text": "Automate"
						},
						{
							"_nr": "35",
							"__text": "Cum putem scrie un program?"
						},
						{
							"_nr": "36",
							"__text": "Sisteme PID"
						},
						{
							"_nr": "37",
							"__text": "Stabilitate"
						},
						{
							"_nr": "38",
							"__text": "Sisteme automate perfectionate"
						},
						{
							"_nr": "39",
							"__text": "Conexiunea inversa in fiziologie"
						},
						{
							"_nr": "40",
							"__text": "Stabilitatea sistemelor fiziologice"
						},
						{
							"_nr": "41",
							"__text": "Sisteme de urmarire in fiziologie"
						},
						{
							"_nr": "42",
							"__text": "Oscilatii in fiziologie"
						},
						{
							"_nr": "43",
							"__text": "Probleme fiziologice"
						},
						{
							"_nr": "44",
							"__text": "Fauna electronica"
						},
						{
							"_nr": "45",
							"__text": "Homeostatul"
						},
						{
							"_nr": "46",
							"__text": "Automate complexe"
						},
						{
							"_nr": "47",
							"__text": "Masini matematice"
						},
						{
							"_nr": "48",
							"__text": "Un scurt istoric al masinilor matematice"
						},
						{
							"_nr": "49",
							"__text": "Un mod special de scriere a numerelor"
						},
						{
							"_nr": "50",
							"__text": "Masina Turing"
						},
						{
							"_nr": "51",
							"__text": "Algoritmii"
						},
						{
							"_nr": "52",
							"__text": "Masini digitale"
						},
						{
							"_nr": "53",
							"__text": "Dispozitive de intrare si de iesire"
						},
						{
							"_nr": "54",
							"__text": "Elemente bistabile si utilizarea lor in calculatoare"
						},
						{
							"_nr": "55",
							"__text": "Limbajul si logica calculatoarelor digitale"
						},
						{
							"_nr": "56",
							"__text": "'Memoria' masinii"
						},
						{
							"_nr": "57",
							"__text": "Modele electronice"
						},
						{
							"_nr": "58",
							"__text": "Automatizarea complexa"
						},
						{
							"_nr": "59",
							"__text": "Omul si masina"
						},
						{
							"_nr": "60",
							"__text": "Masini care efectueaza munci intelectuale"
						},
						{
							"_nr": "61",
							"__text": "Perceptronul"
						},
						{
							"_nr": "62",
							"__text": "Traducerea mecanica"
						},
						{
							"_nr": "63",
							"__text": "Problema lui Tezeu"
						},
						{
							"_nr": "64",
							"__text": "Cum iesim din labirint"
						},
						{
							"_nr": "65",
							"__text": "Masini care vorbesc si inteleg sunete"
						},
						{
							"_nr": "66",
							"__text": "Retele de neuroni"
						},
						{
							"_nr": "67",
							"__text": "Functia de memorizarea a sistemului nervos"
						},
						{
							"_nr": "68",
							"__text": "Legatura temporara si clasificarea statistica a mesajelor in creieri"
						},
						{
							"_nr": "69",
							"__text": "Notiune, recunoastere, abstractizare si gandire"
						},
						{
							"_nr": "70",
							"__text": "Limbajul"
						},
						{
							"_nr": "71",
							"__text": "Teoria jocurilor"
						},
						{
							"_nr": "72",
							"__text": "Exemple de jocuri finite"
						},
						{
							"_nr": "73",
							"__text": "Un rezultat surprinzator"
						},
						{
							"_nr": "74",
							"__text": "O problema psihologica"
						},
						{
							"_nr": "75",
							"__text": "Veghe, somn, atentie, afectivitate"
						},
						{
							"_nr": "76",
							"__text": "Anticiparea"
						},
						{
							"_nr": "77",
							"__text": "Cibernetica ridica probleme"
						},
						{
							"_nr": "78",
							"__text": "Cateva perspective"
						},
						{
							"_nr": "79",
							"__text": "Bibligrafie"
						}
					]
				},
				"_id": "1161",
				"_isbn": ""
			},
			{
				"titlu": "C# : modele de aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Octavian Dospinescu"
						},
						{
							"_nume": "Paul Brodner"
						},
						{
							"_nume": "Alexandra Cassiopeea Ursu"
						},
						{
							"_nume": "Maricela Georgiana Avram"
						},
						{
							"_nume": "Oana Mihaela Liteanu"
						},
						{
							"_nume": "Radu Andrei Cioboranu"
						},
						{
							"_nume": "Alexandru Huiban"
						},
						{
							"_nume": "Maximilian Robu"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Universitatii \"Alexandru Ion Cuza\"",
				"numarPagini": "404",
				"pretOriginal": "380000",
				"pretCumparare": "380000",
				"redactori": {
					"redactor": "Emil Juverdeanu"
				},
				"coperta": {
					"imagine": {
						"_path": "C#_Modele_de_aplicatii_Dospinescu.jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurta initiere in Visual Studio .NET"
						},
						{
							"_nr": "2",
							"__text": "Gestiunea bazelor de date in C#"
						},
						{
							"_nr": "3",
							"__text": "Rapoarte in C#"
						},
						{
							"_nr": "4",
							"__text": "Sistemul de intrare/iesire in C#"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea firelor de executie"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii Web simple in C#"
						},
						{
							"_nr": "7",
							"__text": "Aplicatii Web complexe"
						},
						{
							"_nr": "8",
							"__text": "Servicii Web"
						},
						{
							"_nr": "9",
							"__text": "ASP.NET 2.0 \"Atlas\" si Ajax"
						},
						{
							"_nr": "10",
							"__text": "Depanarea aplicatiilor in C#"
						},
						{
							"_nr": "11",
							"__text": "Pachetul de instalare"
						}
					]
				},
				"_id": "1162",
				"_isbn": "978-973-703-420-5"
			},
			{
				"titlu": "Tehnologii Java pentru dezvoltarea aplicatiilor",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriel Stoian"
						},
						{
							"_nume": "Claudiu Ionut Popirlan"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Universitaria",
				"numarPagini": "216",
				"pretOriginal": "130000",
				"pretCumparare": "130000",
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_Java_pentru_dezvolarea_aplicatiilor_Stoian.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente introductive"
						},
						{
							"_nr": "2",
							"__text": "Clase si obiecte in Java"
						},
						{
							"_nr": "3",
							"__text": "Exceptii si manipularea acestora"
						},
						{
							"_nr": "4",
							"__text": "Mini-aplicatii (Java Applets)"
						},
						{
							"_nr": "5",
							"__text": "Interfete grafice in Java"
						},
						{
							"_nr": "6",
							"__text": "Fire de executie (Thread) in Java"
						},
						{
							"_nr": "7",
							"__text": "Java Database Connectivity - JDBC"
						},
						{
							"_nr": "8",
							"__text": "Servleturi Java"
						},
						{
							"_nr": "9",
							"__text": "Java 2 Micro Edition (J2ME)"
						}
					]
				},
				"_id": "1163",
				"_isbn": "978-606-510-724-3"
			},
			{
				"titlu": "Programarea in limbaje de asamblare: ghid practic (Vol.I)",
				"autori": {
					"autor": [
						{
							"_nume": "Marian Gheorghe"
						},
						{
							"_nume": "Marian Marius Adrian"
						},
						{
							"_nume": "Dumitrascu Eugen"
						},
						{
							"_nume": "Enescu Nicolae Iulian"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Universitaria",
				"numarPagini": "256",
				"pretOriginal": "100000",
				"pretCumparare": "100000",
				"coperta": {
					"imagine": {
						"_path": "Programare_in_limbaje_de_asamblare_Ghid_practic_I_Gheorghe.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Elemente de arhitectura"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului"
						},
						{
							"_nr": "4",
							"__text": "Setul de instructiuni"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni pe siruri"
						},
						{
							"_nr": "6",
							"__text": "Proceduri"
						},
						{
							"_nr": "7",
							"__text": "Macroinstructiuni si directive de asamblare"
						},
						{
							"_nr": "8",
							"__text": "Structuri si inregistrai pe biti"
						},
						{
							"_nr": "9",
							"__text": "Programare mixta"
						}
					]
				},
				"_id": "1164",
				"_isbn": "978-973-742-302-3"
			},
			{
				"titlu": "Programarea in limbaje de asamblare: ghid practic (Vol.II)",
				"autori": {
					"autor": [
						{
							"_nume": "Marian Gheorghe"
						},
						{
							"_nume": "Marian Marius Adrian"
						},
						{
							"_nume": "Dumitrascu Eugen"
						},
						{
							"_nume": "Enescu Nicolae Iulian"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Universitaria",
				"numarPagini": "150",
				"pretOriginal": "100000",
				"pretCumparare": "100000",
				"coperta": {
					"imagine": {
						"_path": "Programare_in_limbaje_de_asamblare_Ghid_practic_II_Gheorghe.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Anexa 1",
							"__text": "Lucrari de laborator"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Codul ASCII standard"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Reprezentarea informatiei"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Setul de instruciuni Pentium"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Modele grila"
						},
						{
							"_nr": "Anexa 6",
							"__text": "Modele de probleme pentru examen"
						},
						{
							"_nr": "Anexa 7",
							"__text": "Modele de probleme pentru testul de laborator"
						},
						{
							"_nr": "Anexa 8",
							"__text": "Turbo assembler si dezvoltarea de programe"
						}
					]
				},
				"_id": "1165",
				"_isbn": "978-973-742-303-8"
			},
			{
				"titlu": "FoxPro 2.6 sub Windows",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriel Dima"
						},
						{
							"_nume": "Mihai Dima"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "512",
				"pretOriginal": "60000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "43",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "FoxPro_2_6_sub_Windows_Dima.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Lucrul cu bazele de date prin intermediul interfetei mediului FoxPro sub Windows"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Baze de date - meniuri"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Limbajul FoxPro sub Windows"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "4",
							"__text": "Variabile si masive"
						},
						{
							"_nr": "5",
							"__text": "Baze de date - Limbaj de programare"
						},
						{
							"_nr": "6",
							"__text": "Intrare/Iesire"
						},
						{
							"_nr": "7",
							"__text": "Ferestre si meniuri"
						},
						{
							"_nr": "8",
							"__text": "Programe"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Alte elemente ale mediului FoxPro sub Windows"
						},
						{
							"_nr": "9",
							"__text": "Generatoare"
						},
						{
							"_nr": "10",
							"__text": "Alte elemente ale mediului FoxProW"
						},
						{
							"_nr": "11",
							"__text": "Facilitati ale versiunii 2.6 a FoxPro-ului sub Windows"
						}
					]
				},
				"_id": "1166",
				"_isbn": "973-601-193-3"
			},
			{
				"titlu": "Clasic si modern in teoria si practica bazelor de date realtionale",
				"autori": {
					"autor": {
						"_nume": "Mirela Danubianu"
					}
				},
				"anAparitie": "2009",
				"editura": "InfoData",
				"numarPagini": "308",
				"pretOriginal": "500000",
				"pretCumparare": "500000",
				"tehnoredactori": {
					"tehnoredactor": "Sorina Nicoleta Stanica"
				},
				"coperta": {
					"imagine": {
						"_path": "Clasic_si_modern_in_teoria_si_practica_bazelor_de_date_relationale_Danubianu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Baze de date"
						},
						{
							"_nr": "2",
							"__text": "Modelarea conceptuala si modele de date"
						},
						{
							"_nr": "3",
							"__text": "Limbaje relationale"
						},
						{
							"_nr": "4",
							"__text": "Limbajul SQL"
						},
						{
							"_nr": "5",
							"__text": "Constrangeri si declansatoare in SQL"
						},
						{
							"_nr": "6",
							"__text": "SQL si mediile de programare"
						},
						{
							"_nr": "7",
							"__text": "Dependente functionale.Procesul de normalizare"
						},
						{
							"_nr": "8",
							"__text": "Administrarea tranzactiilor"
						},
						{
							"_nr": "9",
							"__text": "Prelucrarea si optimizarea interogarilor"
						},
						{
							"_nr": "10",
							"__text": "Securitatea bazelor de date"
						},
						{
							"_nr": "11",
							"__text": "Baze de date distribuite"
						}
					]
				},
				"_id": "1167",
				"_isbn": "978-973-1803-40-1"
			},
			{
				"titlu": "Bazele informaticii pentru ingineri - curs si aplicatii. Curs practic de OpenOffice.org 2.2",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Nicolae Stan"
						},
						{
							"_nume": "Eduard Minciuc"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Politehnica Press",
				"numarPagini": "108",
				"pretOriginal": "80000",
				"pretCumparare": "80000",
				"coperta": {
					"imagine": {
						"_path": "Bazele_informaticii_pentru_ingineri_Curs_si_aplicatii_Curs_practic_de_OpenOffice_org_2_2_Stan.jpg"
					},
					"__text": "Adriana Butmalai"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Prima parte",
							"__text": "Initiere in Writer"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Initiere in Math"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Initiere in Draw"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Initiere in Calc"
						},
						{
							"_nr": "Partea a cincea",
							"__text": "Initiere in Impress"
						}
					]
				},
				"_id": "1168",
				"_isbn": "978-606-515-002-7"
			},
			{
				"titlu": "Culegere de probleme pentru atestat la informatica",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Giorgie Daniel"
						},
						{
							"_nume": "Belinschi Mihaela Corina"
						},
						{
							"_nume": "Mocanu Corina"
						},
						{
							"_nume": "Freitag Gebrila"
						},
						{
							"_nume": "Tompea Viorel"
						},
						{
							"_nume": "Coaja Gabriela"
						},
						{
							"_nume": "Lazarescu Antonela"
						},
						{
							"_nume": "David Liliana"
						},
						{
							"_nume": "Aga Petronela Marinela"
						},
						{
							"_nume": "Stefanescu Narcisa Daniela"
						},
						{
							"_nume": "Dumistrascu Gabriala"
						},
						{
							"_nume": "Erhan Mihail"
						},
						{
							"_nume": "Sestac Suzana"
						},
						{
							"_nume": "Udulesc Marius"
						},
						{
							"_nume": "Cristina Zotic"
						},
						{
							"_nume": "Vatamanescu Nicolae"
						},
						{
							"_nume": "Popa Carmen"
						},
						{
							"_nume": "Marcu Ovidiu"
						},
						{
							"_nume": "Marcu Daniela"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "InfoData",
				"numarPagini": "194",
				"pretOriginal": "300000",
				"pretCumparare": "300000",
				"redactori": {
					"redactor": "Tiberiu Socaciu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihaela Belinschi"
				},
				"coperta": {
					"imagine": {
						"_path": "Culegere_de_probleme_pentru_atestat_la_informatica_Vlad.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Programare. C++"
						},
						{
							"_nr": "II",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "III",
							"__text": "Elemente de tehnoredactare. Microsoft. Word"
						}
					]
				},
				"_id": "1169",
				"_isbn": "978-973-88224-7-4"
			},
			{
				"titlu": "Aplicatii intenet de tip \"Magazin virtual - comertul viitorului\" utilizand tehnologii web de programare PHP si Mysql si asp.net",
				"autori": {
					"autor": [
						{
							"_nume": "Valeriu Lupu"
						},
						{
							"_nume": "Elena Lupu"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "InfoData",
				"numarPagini": "240",
				"pretOriginal": "300000",
				"pretCumparare": "300000",
				"coperta": {
					"imagine": {
						"_path": "Aplicatii_internet_de_tip_Magazin_virtual_comertul_viitorului_utilizand_tehnologii_web_de_programare_PHP_Mysql_si_asp_net_Lupu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in baze de date"
						},
						{
							"_nr": "2",
							"__text": "Modele si arhitecturi de date"
						},
						{
							"_nr": "3",
							"__text": "Modelul relational de date"
						},
						{
							"_nr": "4",
							"__text": "Limbajul SQL al bazelor de date"
						},
						{
							"_nr": "*",
							"__text": "PHP - Notiuni de baza"
						},
						{
							"_nr": "*",
							"__text": "ASP.NET"
						},
						{
							"_nr": "*",
							"__text": "Studiu de caz: Realizarea unei aplicatii client-server pentru gestionarea unui magazin virtual"
						},
						{
							"_nr": "Anexa",
							"__text": "Limbajul HTML"
						}
					]
				},
				"_id": "1170",
				"_isbn": "978-973-88224-7-4"
			},
			{
				"titlu": "Teoria retelelor neuronale artificiale (Vol.1)",
				"autori": {
					"autor": [
						{
							"_nume": "Dumitru Popescu"
						},
						{
							"_nume": "Maria Luiza Flonta"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "265",
				"pretOriginal": "350000",
				"pretCumparare": "350000",
				"tehnoredactori": {
					"tehnoredactor": "Elena Popovici"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_retelelor_neuronale_artificiale_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Neuronul natural"
						},
						{
							"_nr": "2",
							"__text": "Neuronul artificial"
						},
						{
							"_nr": "3",
							"__text": "Perceptronul"
						},
						{
							"_nr": "4",
							"__text": "Retele neronale cu mai multe straturi"
						},
						{
							"_nr": "5",
							"__text": "Instruirea retelelor neuronale"
						},
						{
							"_nr": "6",
							"__text": "Algoritmul de retropropagare a erorii"
						},
						{
							"_nr": "7",
							"__text": "Memorii asociative"
						},
						{
							"_nr": "8",
							"__text": "Reteaua neuronala Hopfield"
						},
						{
							"_nr": "9",
							"__text": "Memorie asociativa bidirectionala"
						},
						{
							"_nr": "10",
							"__text": "Neuronul statistic"
						},
						{
							"_nr": "11",
							"__text": "Metode statistice de instruire"
						},
						{
							"_nr": "12",
							"__text": "Masina Boltzmann"
						},
						{
							"_nr": "Anexa A",
							"__text": "Tabelul A1. Comparatie detaliata intre paradigama logico-simbolica si paradigama conexionista realizata prin contrapunerea tututor elementelor structural-functionale"
						},
						{
							"_nr": "Anexa B",
							"__text": "Functii de activare"
						},
						{
							"_nr": "Anexa C",
							"__text": "Elemente de calcul vectorial"
						},
						{
							"_nr": "Anexa d",
							"__text": "Elemente de calcul matricial"
						},
						{
							"_nr": "Anexa E",
							"__text": "Elemente de logica"
						},
						{
							"_nr": "Anexa F",
							"__text": "Deducerea regulii de calcul a modificarilor ponderilor sinapitce in algoritmul de retropropragare a erorii"
						},
						{
							"_nr": "Anexa G",
							"__text": "Lanturi Markov"
						},
						{
							"_nr": "Anexa H",
							"__text": "Caracterizarea celor mai importante tipuri de retele neuronale artificiale"
						},
						{
							"_nr": "Anexa I",
							"__text": "Programe pentru retele neuronale"
						}
					]
				},
				"_id": "1171",
				"_isbn": "978-973-737-682-4"
			},
			{
				"titlu": "Proiectarea sistemelor informatice : metode, modela, tehnici si intrumente utilizate",
				"autori": {
					"autor": [
						{
							"_nume": "Nicolae Morariu"
						},
						{
							"_nume": "Sorin Vlad"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "InfoData",
				"numarPagini": "240",
				"pretOriginal": "300000",
				"pretCumparare": "300000",
				"redactori": {
					"redactor": "Tiberiu Socaciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Proiectarea_sistemelor_informatice_Modele_modele_tehnici_si_instrumente_utilizalizate_Morariu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme informatice"
						},
						{
							"_nr": "2",
							"__text": "Initierea si planifiecarea realizarii unui sistem informatic"
						},
						{
							"_nr": "3",
							"__text": "Analiza sistemului existent si definirea cerintelor noului sistem"
						},
						{
							"_nr": "4",
							"__text": "Proiectarea logica a sistemelor informatice"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea fizica a sistemelor informatice"
						},
						{
							"_nr": "6",
							"__text": "Instrumente CASE"
						},
						{
							"_nr": "7",
							"__text": "Tendinte actuale si de perspectiva in evolutia sistemelor informatice"
						},
						{
							"_nr": "8",
							"__text": "Studii de caz - Arhitecuturi de sisteme informatice"
						}
					]
				},
				"_id": "1172",
				"_isbn": "978-973-88224-7-4"
			},
			{
				"titlu": "Invatati Backtracking:  exemple in Pascal si C",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Socaciu"
					}
				},
				"anAparitie": "2006",
				"editura": "InfoData",
				"numarPagini": "164",
				"pretOriginal": "30000",
				"pretCumparare": "300000",
				"redactori": {
					"redactor": "Bogdan Patrut"
				},
				"tehnoredactori": {
					"tehnoredactor": "Tiberiu Socaciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Invatati_backtracking_Exemple_in_Pascal_si_C_Socaciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metoda backtraking"
						},
						{
							"_nr": "2",
							"__text": "Backtracking pe matrice"
						},
						{
							"_nr": "3",
							"__text": "Backtracking cu solutii de lungime variabila"
						},
						{
							"_nr": "4",
							"__text": "Backtracking cu miscari"
						},
						{
							"_nr": "5",
							"__text": "Backtracking in grafuri"
						},
						{
							"_nr": "6",
							"__text": "Backtracking in combinatorica"
						},
						{
							"_nr": "7",
							"__text": "Backtracking pe tabla de sah"
						}
					]
				},
				"_id": "1173",
				"_isbn": "973-87774-1-0"
			},
			{
				"titlu": "Informatica pentru economisti si functionari publici",
				"autori": {
					"autor": [
						{
							"_nume": "Doru E. Tiliute"
						},
						{
							"_nume": "Sorin Vlad"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "InfoData",
				"numarPagini": "294",
				"pretOriginal": "30000",
				"pretCumparare": "300000",
				"redactori": {
					"redactor": "Tiberiu Socaciu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Tiberiu Socaciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_pentru_economisti_si_functionari_publici_Tiliute.jpg"
					},
					"__text": "Doru E. Tiliute"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in informatica"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare Windows XP"
						},
						{
							"_nr": "3",
							"__text": "Microsoft Word"
						},
						{
							"_nr": "4",
							"__text": "Microsoft Excel"
						},
						{
							"_nr": "5",
							"__text": "Powerpoint"
						},
						{
							"_nr": "6",
							"__text": "Programe de arhivare"
						}
					]
				},
				"_id": "1174",
				"_isbn": "978-973-1803-47-0"
			},
			{
				"titlu": "Instrumente si resurse Web pentru profesori",
				"autori": {
					"autor": {
						"_nume": "Traian Anghel"
					}
				},
				"anAparitie": "2009",
				"editura": "All",
				"numarPagini": "289",
				"pretOriginal": "100000",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Elena Cofoas"
				},
				"coperta": {
					"imagine": {
						"_path": "Instrumente_si_resurse_Web_pentru_profesori_Anghel.jpg"
					},
					"__text": "Alexandru Novac"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Serviciul World Wide Web"
						},
						{
							"_nr": "2",
							"__text": "Introducere in e-Learning"
						},
						{
							"_nr": "3",
							"__text": "Instrumente Web 2.0 utilizate in educatie"
						},
						{
							"_nr": "4",
							"__text": "Resurse educationale deschise"
						},
						{
							"_nr": "5",
							"__text": "Medii virtuale de invatare"
						},
						{
							"_nr": "6",
							"__text": "Instrumente software pentru crearea materialelor de invatare"
						},
						{
							"_nr": "7",
							"__text": "Instrumente online pentru crearea si publicarea materialelor de invatare"
						},
						{
							"_nr": "8",
							"__text": "Cautarea informatiilor pe Web"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Declaratia de la Cape Town"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Declaratia de la Berlin"
						}
					]
				},
				"_id": "1175",
				"_isbn": "978-973-571-938-8"
			},
			{
				"titlu": "Tehnici de programare in C pentru Windows 2000",
				"autori": {
					"autor": {
						"_nume": "Cristian George Savu"
					}
				},
				"anAparitie": "2000",
				"editura": "All Educational",
				"numarPagini": "496",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Viorica Georgeta Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Radu Dobrici"
				},
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_in_C_pentru_Windows_2000_Savu.jpg"
					},
					"__text": "Dorin Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "2",
							"__text": "Introducere in limbajul C"
						},
						{
							"_nr": "3",
							"__text": "Structuri de date, liste, arbori"
						},
						{
							"_nr": "4",
							"__text": "Ferestre, mesaje"
						},
						{
							"_nr": "5",
							"__text": "Programarea pentru Windows"
						},
						{
							"_nr": "6",
							"__text": "Resurse"
						},
						{
							"_nr": "7",
							"__text": "Crearea casetelor de dialog (Dialog Box)"
						},
						{
							"_nr": "8",
							"__text": "Ferestre control"
						},
						{
							"_nr": "9",
							"__text": "Controale owner-draw"
						},
						{
							"_nr": "10",
							"__text": "Controale comune in Windows 2000"
						},
						{
							"_nr": "11",
							"__text": "Font si text"
						},
						{
							"_nr": "12",
							"__text": "Grafica in Windows"
						},
						{
							"_nr": "13",
							"__text": "Fisiere"
						},
						{
							"_nr": "14",
							"__text": "Intrari de la tastatura si mouse"
						},
						{
							"_nr": "15",
							"__text": "Gestiunea memoriei in Windows 2000"
						},
						{
							"_nr": "16",
							"__text": "Imagini bitmap"
						},
						{
							"_nr": "17",
							"__text": "Imagini metafisiere"
						},
						{
							"_nr": "18",
							"__text": "Multimedia in Windows 2000"
						},
						{
							"_nr": "19",
							"__text": "Interfata multidocument MDI"
						},
						{
							"_nr": "20",
							"__text": "Baze de date cu ODBC"
						},
						{
							"_nr": "21",
							"__text": "Baze de date ADO"
						},
						{
							"_nr": "22",
							"__text": "Comunicatia prin TCP/IP"
						}
					]
				},
				"_id": "1176",
				"_isbn": "973-684-249-5"
			},
			{
				"titlu": "Securitatea comertului electronic",
				"autori": {
					"autor": [
						{
							"_nume": "Victor Valeriu-Patriciu"
						},
						{
							"_nume": "Monica Ene-Pietrosanu"
						},
						{
							"_nume": "Ion Bica"
						},
						{
							"_nume": "Calin Vaduva"
						},
						{
							"_nume": "Nicolae Voicu"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "All Educational",
				"numarPagini": "424",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Viorica Georgeta Murariu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Narcis Razvan Popescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Securitatea_comertului_electronic_Patriciu.jpg"
					},
					"__text": "Dorin Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in comertul electronic"
						},
						{
							"_nr": "2",
							"__text": "Securitatea tranzactiilor in comertul electronic"
						},
						{
							"_nr": "3",
							"__text": "Semnatura electronica in e-commerce"
						},
						{
							"_nr": "4",
							"__text": "Sisteme electronice de plati in e-commerce"
						},
						{
							"_nr": "5",
							"__text": "Securitatea Web si Java"
						}
					]
				},
				"_id": "1177",
				"_isbn": "973-571-325-X"
			},
			{
				"titlu": "Baze de date. Limbajul PL/SQL",
				"autori": {
					"autor": [
						{
							"_nume": "Adela Bara"
						},
						{
							"_nume": "Iuliana Botha"
						},
						{
							"_nume": "Vlad Diaconita"
						},
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Anda Velicanu"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "ASE",
				"numarPagini": "246",
				"pretOriginal": "208000",
				"pretCumparare": "208000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Nica"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_Limbajul_PL_SQL_Bara.jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in PL/SQL (Procedural Language Extension to SQL)"
						},
						{
							"_nr": "II",
							"__text": "Variabile PL/SQL"
						},
						{
							"_nr": "III",
							"__text": "Interactiunea cu serverul Oracle prin comenzi SQL"
						},
						{
							"_nr": "VI",
							"__text": "Structuri fundamentale de programare"
						},
						{
							"_nr": "V",
							"__text": "Cursori si tabele index BY"
						},
						{
							"_nr": "VI",
							"__text": "Tratarea exceptiilor"
						},
						{
							"_nr": "VII",
							"__text": "Subprograme PL/SQL"
						},
						{
							"_nr": "VIII",
							"__text": "Pachete de subprograme"
						},
						{
							"_nr": "IX",
							"__text": "Declansatori"
						},
						{
							"_nr": "X",
							"__text": "Realizarea de videoformate si rapoarte"
						},
						{
							"_nr": "XI",
							"__text": "Execitii recapitulative"
						}
					]
				},
				"_id": "1178",
				"_isbn": "978-606-505-263-5"
			},
			{
				"titlu": "Modelarea orientata obiect",
				"autori": {
					"autor": [
						{
							"_nume": "Rodica Mihalca"
						},
						{
							"_nume": "Csaba Fabian"
						},
						{
							"_nume": "Adina Uta"
						},
						{
							"_nume": "Iulian Intorsureanu"
						},
						{
							"_nume": "Anca Andreescu"
						},
						{
							"_nume": "Dan Jisa"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "ASE",
				"numarPagini": "216",
				"pretOriginal": "221000",
				"pretCumparare": "221000",
				"redactori": {
					"redactor": "Luiza Constantinecu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Emilia Velcu"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelarea_orientata_obiect_Mihalca.jpg"
					},
					"__text": "Livia Radu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Metodologii orientate obiect precursoare limbajului unificat de modelare UML"
						},
						{
							"_nr": "II",
							"__text": "Limbajul unificat de modelare UML (Unified Modeling Language)"
						},
						{
							"_nr": "III",
							"__text": "Procesul unificat de dezvoltare software (Unified Software Development Process - USDP)"
						},
						{
							"_nr": "VI",
							"__text": "Produse de tip CASE"
						}
					]
				},
				"_id": "1179",
				"_isbn": "978-606-505-263-5"
			},
			{
				"titlu": "Dezvoltarea aplicatiilor RIA",
				"autori": {
					"autor": [
						{
							"_nume": "Andrei Toma"
						},
						{
							"_nume": "Radu Constantinescu"
						},
						{
							"_nume": "Mihai Pricope"
						},
						{
							"_nume": "Florea Nastase"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "ASE",
				"numarPagini": "216",
				"pretOriginal": "166000",
				"pretCumparare": "166000",
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_aplicatiilor_RIA_Toma.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tehnologii necesare"
						},
						{
							"_nr": "2",
							"__text": "Introducere in Flex"
						},
						{
							"_nr": "3",
							"__text": "Interfete Flex"
						},
						{
							"_nr": "4",
							"__text": "Introducere in Action Script si MXML"
						},
						{
							"_nr": "5",
							"__text": "Servicii web in C#"
						},
						{
							"_nr": "6",
							"__text": "Servicii de date in C#"
						},
						{
							"_nr": "7",
							"__text": "Servicii web si surse de date in Flex. Evenimente. Data Binding"
						},
						{
							"_nr": "8",
							"__text": "Servicii web si surse de date in Flex. Upload de imagini"
						},
						{
							"_nr": "9",
							"__text": "Data validation. Item editors. Item renders. Drag and drop"
						},
						{
							"_nr": "10",
							"__text": "Sesiuni. Skin-uri. Efecte si tranzitii. Custom components"
						},
						{
							"_nr": "11",
							"__text": "API-uri externe. Mash-up"
						},
						{
							"_nr": "12",
							"__text": "Arhitecturi.MVC.Securitate"
						}
					]
				},
				"_id": "1180",
				"_isbn": "978-606-505-290-1"
			},
			{
				"titlu": "Sisteme de operare (I): arhitecturi, procese, memorie, dispozitive",
				"autori": {
					"autor": {
						"_nume": "Ileana-Diana Nicolae"
					}
				},
				"anAparitie": "2007",
				"editura": "Universitaria Craiova",
				"numarPagini": "324",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_I_Arhitecturi_procese_memorie_dispozitive_Nicolae.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni primare"
						},
						{
							"_nr": "2",
							"__text": "Clasificarea sistemelor de operare. Tipuri de sisteme de operare"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura sistemelor de operare"
						},
						{
							"_nr": "4",
							"__text": "Notiuni despre gestiunea proceselor"
						},
						{
							"_nr": "5",
							"__text": "Managementul memoriei"
						},
						{
							"_nr": "6",
							"__text": "Managementul dispozitivelor de intrare/iesire"
						},
						{
							"_nr": "7",
							"__text": "Servicii web si surse de date in Flex. Evenimente. Data Binding"
						},
						{
							"_nr": "8",
							"__text": "servicii web si surse de date in Flex. Upload de imagini"
						},
						{
							"_nr": "9",
							"__text": "Data validation. Item editors. Item renders. Drag and drop"
						},
						{
							"_nr": "10",
							"__text": "Sesiuni. Skin-uri. Efecte si tranzitii. Custom components"
						},
						{
							"_nr": "11",
							"__text": "API-uri externe. Mash-up"
						},
						{
							"_nr": "12",
							"__text": "Arhitecturi.MVC.Securitate"
						}
					]
				},
				"_id": "1181",
				"_isbn": "978-973-742-711-3"
			},
			{
				"titlu": "Prelucrarea imaginilor si recunoasterea formelor: teorie si aplicatii",
				"autori": {
					"autor": {
						"_nume": "Mihai Bulea"
					}
				},
				"anAparitie": "2003",
				"editura": "Academiei Romane",
				"numarPagini": "460",
				"pretOriginal": "0",
				"pretCumparare": "327000",
				"redactori": {
					"redactor": "Dan-Florin Dumitrescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Prelucrarea_imaginilor_si_recunoasterea_formelor_Teorie_si_aplicatii_Mihai_Bulea.jpg"
					},
					"__text": "Gigi Gavrila"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "I",
							"__text": "Partea I"
						},
						{
							"_nr": "2",
							"__text": "Tehnici de imbunatatire a imaginilor"
						},
						{
							"_nr": "3",
							"__text": "Restaurarea imaginilor"
						},
						{
							"_nr": "4",
							"__text": "Detectia muchiilor, liniilor si spoturilor"
						},
						{
							"_nr": "5",
							"__text": "Prelucrari morfologice ale imaginilor"
						},
						{
							"_nr": "6",
							"__text": "Segmentarea imaginilor"
						},
						{
							"_nr": "7",
							"__text": "Transformarea Hough"
						},
						{
							"_nr": "8",
							"__text": "'Potrivirea' imaginilor (image matching)"
						},
						{
							"_nr": "9",
							"__text": "Extragerea trasaturilor imagistice"
						},
						{
							"_nr": "II",
							"__text": "Partea a II-a"
						},
						{
							"_nr": "10",
							"__text": "Clasificatori liniari"
						},
						{
							"_nr": "11",
							"__text": "Tehnici statistice de recunoastere"
						},
						{
							"_nr": "12",
							"__text": "Metoda functiilor de potential"
						},
						{
							"_nr": "13",
							"__text": "Algoritmi de grupare (clustering)"
						},
						{
							"_nr": "14",
							"__text": "Retele neurale folosite in recunoasterea de forme"
						},
						{
							"_nr": "15",
							"__text": "Selectia trasaturilor"
						},
						{
							"_nr": "16",
							"__text": "Algoritmi de gestiune a seturilor de forme de antrenament"
						},
						{
							"_nr": "III",
							"__text": "Partea a III-a"
						},
						{
							"_nr": "17",
							"__text": "Recunoasterea obiectelor imagistice cu invariante specifice"
						},
						{
							"_nr": "18",
							"__text": "Masurarea automata a sitelor textile"
						},
						{
							"_nr": "19",
							"__text": "Recunoasterea simbolurilor muzicale tiparite"
						},
						{
							"_nr": "20",
							"__text": "Recunoasterea automata a textelor tiparite"
						},
						{
							"_nr": "21",
							"__text": "PROImage - Aplicatie didactica generala de prelucrarea a imaginilor"
						},
						{
							"_nr": "ANEXE",
							"__text": "Anexe"
						},
						{
							"_nr": "A.1",
							"__text": "Continutul CD-ului atasat, instalare programe"
						},
						{
							"_nr": "A.2",
							"__text": "SitaApp - Aplicatie de evaluare a sitelor textile"
						},
						{
							"_nr": "A.3",
							"__text": "GoblenApp - Aplicatie de generare modele goblen"
						},
						{
							"_nr": "A.4",
							"__text": "ProImage - Aplicatie didactica de prelucrarea de imagini"
						},
						{
							"_nr": "A.5",
							"__text": "DigitRecogn - Recunoasterea automata a numerelor seriale de pe ceasuri"
						},
						{
							"_nr": "A.6",
							"__text": "PROApp - Aplicatie de recunoastere caractere tiparite (OCR)"
						},
						{
							"_nr": "A.7",
							"__text": "ImgCompare, SmartRename - Programe utilitare"
						}
					]
				},
				"_id": "1182",
				"_isbn": "973-27-1000-4"
			},
			{
				"titlu": "Sisteme de gestiune a bazelor de date",
				"autori": {
					"autor": [
						{
							"_nume": "Manole Velicanu"
						},
						{
							"_nume": "Constanta Bodea"
						},
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Cristina Ionita"
						},
						{
							"_nume": "Georgeta Badescu"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Petrion",
				"numarPagini": "208",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Camelia Dana Cristea"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_gestiune_a_bazelor_de_date_Velicanu.jpg"
					},
					"__text": "Cornel Olaru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Elemente de teoria bazelor de date"
						},
						{
							"_nr": "1",
							"__text": "Organizarea datelor in baze de date"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "1",
							"__text": "Sisteme de gestiune a bazelor de date (SGBD) - Aspecte fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de gestiune a bazelor de date relationale (SGBDR)"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de gestiune a bazelor de date orientate pe obiecte (SGBDOO)"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de gestiune a bazelor de date arborescente"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de gestiune a bazelor de date retea"
						},
						{
							"_nr": "6",
							"__text": "Sisteme de gestiune a bazelor de date distribuite (SGBDD)"
						}
					]
				},
				"_id": "1183",
				"_isbn": "973-9116-59-0"
			},
			{
				"titlu": "Baze de date: fundamente teoretice si practice",
				"autori": {
					"autor": [
						{
							"_nume": "Florescu Vasile"
						},
						{
							"_nume": "Berbec Florentina"
						},
						{
							"_nume": "Nastase Pavel"
						},
						{
							"_nume": "Stanciu Victoria"
						},
						{
							"_nume": "Cosacescu Luana"
						},
						{
							"_nume": "Ionescu Bogdan"
						},
						{
							"_nume": "Ivancenco Veronica"
						},
						{
							"_nume": "Anica Popa Liana"
						},
						{
							"_nume": "Cozgarea Adrian"
						},
						{
							"_nume": "Cozgarea Gabriel"
						},
						{
							"_nume": "Gheorghe Mirela"
						},
						{
							"_nume": "Mihai Florin"
						},
						{
							"_nume": "Vrincianu Marinela"
						},
						{
							"_nume": "Stanciu Andrei"
						},
						{
							"_nume": "Sahlean Gheorghe Bogdan"
						},
						{
							"_nume": "Anica Popa Ionut"
						},
						{
							"_nume": "Pechi Alexandru"
						}
					]
				},
				"anAparitie": "2002",
				"editura": "InfoMega",
				"numarPagini": "548",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Marinela Vrincianu"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_fundamente_teoretice_si_practice_Florescu.jpg"
					},
					"__text": "Andrei Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "2",
							"__text": "Modele de reprezentare a bazelor de date"
						},
						{
							"_nr": "3",
							"__text": "Proiectarea bazelor de date"
						},
						{
							"_nr": "4",
							"__text": "SGBD Access: Initiere"
						},
						{
							"_nr": "5",
							"__text": "SGBD Access: Aprofundare"
						},
						{
							"_nr": "6",
							"__text": "Limbajul SQL"
						},
						{
							"_nr": "7",
							"__text": "Securitatea si integritatea bazelor de date"
						},
						{
							"_nr": "8",
							"__text": "Sisteme client-server (C/S)"
						},
						{
							"_nr": "9",
							"__text": "Baze de date distribuite"
						},
						{
							"_nr": "10",
							"__text": "Baze de date on-line"
						},
						{
							"_nr": "11",
							"__text": "Baze de date avansate"
						},
						{
							"_nr": "12",
							"__text": "Studii de caz: Proiectarea BD si interogari in SQL"
						},
						{
							"_nr": "13",
							"__text": "Aplicatii Access si SQL Server"
						}
					]
				},
				"_id": "1184",
				"_isbn": "973-99970-3-1"
			},
			{
				"titlu": "Elaborarea si introducerea sistemelor informatice",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Pisau"
						},
						{
							"_nume": "Constantin Toma"
						},
						{
							"_nume": "Ion Mihaiescu"
						}
					]
				},
				"anAparitie": "1975",
				"editura": "Tehnica",
				"tiraj": "4700+70+20",
				"numarPagini": "268",
				"pretOriginal": "15",
				"redactori": {
					"redactor": "Paul Zamfirescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Elaborarea_si_introducerea_sistemelor_informatice_Pisau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cunostinte sintetice despre sisteme informationale si informatice"
						},
						{
							"_nr": "2",
							"__text": "Prezentarea generala a unei metodologii de realizare a sistemelor informatice"
						},
						{
							"_nr": "3",
							"__text": "Studiul de oportunitate"
						},
						{
							"_nr": "4",
							"__text": "Proiectul cadru"
						},
						{
							"_nr": "5",
							"__text": "Proiectul de detaliu"
						},
						{
							"_nr": "6",
							"__text": "Organizarea tratatii informatiilor"
						},
						{
							"_nr": "7",
							"__text": "Elaborarea instructiunilor pentru programare"
						},
						{
							"_nr": "8",
							"__text": "Programarea"
						},
						{
							"_nr": "9",
							"__text": "Punerea in functiune a unui subistem informatic"
						},
						{
							"_nr": "10",
							"__text": "Exploatarea unui sistem informatic"
						},
						{
							"_nr": "11",
							"__text": "Intretinerea, perfectionarea si dezvoltarea unui sistem informatic"
						},
						{
							"_nr": "12",
							"__text": "Perfectionarea structurii serviciilor"
						},
						{
							"_nr": "13",
							"__text": "Instructiuni pentru utilizatori"
						},
						{
							"_nr": "14",
							"__text": "Pregatirea lansarii subsistemului informatic si formarea personalului"
						},
						{
							"_nr": "15",
							"__text": "Alegerea furnizorului de echipamente"
						},
						{
							"_nr": "16",
							"__text": "Asigurarea infrastructurii sistemului informatic"
						},
						{
							"_nr": "17",
							"__text": "Punerea in functiune a infrastructurii sistemului informatic"
						},
						{
							"_nr": "18",
							"__text": "Exploararea infrastructurii sistemului informatic"
						},
						{
							"_nr": "19",
							"__text": "Intretinerea, perfectionarea si dezvoltarea infrastructurii sistemului informatic"
						}
					]
				},
				"_id": "1185"
			},
			{
				"titlu": "Intruire in programare: construirea programelor si exploatarea datelor",
				"autori": {
					"autor": [
						{
							"_nume": "Honeywell Bull"
						},
						{
							"_nume": "Jean-Dominique Warnier"
						},
						{
							"_nume": "Brendan M. Flanagan"
						}
					]
				},
				"anAparitie": "1973",
				"editura": "Tehnica",
				"tiraj": "6000+140",
				"numarPagini": "450",
				"pretOriginal": "26",
				"traducere": {
					"titluOriginal": "Entrainement a la programmation",
					"editura": "Les Editions d'Organisation",
					"traducatori": {
						"traducator": [
							"Gonda Gavril",
							"Roman Constantin"
						]
					},
					"_an": "1972",
					"_editia": "II,I",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": [
						"Smaranda Dimitriu",
						"Zamfirescu Paul"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Instruire_in_programare_Construirea_programelor_si_exploarea_datelor_Honeywell.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Construirea programelor"
						},
						{
							"_nr": "Partea intii",
							"__text": "Introducere matematica"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Organizarea unui program in secvente"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Organizarea interna a secventelor programului"
						},
						{
							"_nr": "II",
							"__text": "Explorarea datelor"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Datele si construirea programelor"
						},
						{
							"_nr": "Partea a cincea",
							"__text": "Datele de iesire si fazele prelucrarii"
						},
						{
							"_nr": "Partea a sasea",
							"__text": "Sinteza"
						}
					]
				},
				"_id": "1186"
			},
			{
				"titlu": "Conducerea cu calculatoare a proceselor industriale",
				"autori": {
					"autor": [
						{
							"_nume": "Emanuel S. Savas"
						},
						{
							"_nume": "Harold Davidson"
						},
						{
							"_nume": "Martin Y. Silberberg"
						},
						{
							"_nume": "Fred C. Schneider"
						},
						{
							"_nume": "Max. T. Night"
						},
						{
							"_nume": "Joseph F. Hornor"
						},
						{
							"_nume": "Terrence K. McMahon"
						}
					]
				},
				"anAparitie": "1969",
				"editura": "Tehnica",
				"numarPagini": "340",
				"pretOriginal": "32",
				"traducere": {
					"titluOriginal": "Computer Control of Industrial Processes",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": ""
					},
					"_an": "1965",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"coperta": {
					"imagine": {
						"_path": "Conduerea_cu_calculatoare_a _proceselor_industriale_Savas.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intii",
							"__text": "Principiile conducerii cu calculatoare"
						},
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte privitoare la conducerea cu calculatoare"
						},
						{
							"_nr": "3",
							"__text": "Metode matematice"
						},
						{
							"_nr": "4",
							"__text": "Metode statistice pentru elaborarea modelelor"
						},
						{
							"_nr": "5",
							"__text": "Organizarea si conducerea stationara"
						},
						{
							"_nr": "6",
							"__text": "Organizarea si conducerea dinamica"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Aplicatiile conducerii cu calculatoare"
						},
						{
							"_nr": "7",
							"__text": "Planificarea unui proiect de sistem de conducere cu calculator"
						},
						{
							"_nr": "8",
							"__text": "Aspectele economice ale conducerii cu calculatoare"
						},
						{
							"_nr": "9",
							"__text": "Calculatoare de proces"
						},
						{
							"_nr": "10",
							"__text": "Aparatajul de masurat si de reglare pentru condurerea cu calculatoare"
						},
						{
							"_nr": "11",
							"__text": "Conducerea numerica directa"
						},
						{
							"_nr": "12",
							"__text": "Programarea pentru conducerea cu calculatoare"
						},
						{
							"_nr": "13",
							"__text": "Conducerea cu calculatoare in industrie"
						}
					]
				},
				"_id": "1187"
			},
			{
				"titlu": "Bazele proiectarii calculatoarelor numerice",
				"autori": {
					"autor": {
						"_nume": "Yaohan Chu"
					}
				},
				"anAparitie": "1968",
				"editura": "Tehnica",
				"tiraj": "2500+110",
				"numarPagini": "660",
				"pretOriginal": "43",
				"traducere": {
					"titluOriginal": "Digital computer design fundamentals",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": [
							"Sandu Lazar",
							"Anca Lazar"
						]
					},
					"_an": "1962",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Maria Beluri"
				},
				"tehnoredactori": {
					"tehnoredactor": "Bety Negreanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_proiectarii_calculatoarelor_numerice_Chu.jpg"
					},
					"__text": "Nicolae Nicolaev"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Operatii aritmetice in calculatoarele numerice - I"
						},
						{
							"_nr": "2",
							"__text": "Operatii aritmetice in calculatoarele numerice - II"
						},
						{
							"_nr": "3",
							"__text": "Algebra booleana"
						},
						{
							"_nr": "4",
							"__text": "Miniminzarea functiilor booleene"
						},
						{
							"_nr": "5",
							"__text": "Circuite logice - I"
						},
						{
							"_nr": "6",
							"__text": "Circuite logice - II"
						},
						{
							"_nr": "7",
							"__text": "Circuite logice cu miezuri magnetice"
						},
						{
							"_nr": "8",
							"__text": "Circuite logice cu miezuri magnetice cu mai multe deschizaturi"
						},
						{
							"_nr": "9",
							"__text": "Matrice de comutatie"
						},
						{
							"_nr": "10",
							"__text": "Elementele calculatorului numeric"
						},
						{
							"_nr": "11",
							"__text": "Proiectarea logica a unui calculator numeric simplu"
						},
						{
							"_nr": "12",
							"__text": "Blocul aritmetic si blocul de comanda"
						}
					]
				},
				"_id": "1188"
			},
			{
				"titlu": "Calculatoare analogice si numerice: aplicatii in automatizari",
				"autori": {
					"autor": {
						"_nume": "M. Pelegrin"
					}
				},
				"anAparitie": "1966",
				"editura": "Tehnica",
				"tiraj": "3200+140+20",
				"numarPagini": "640",
				"pretOriginal": "37",
				"traducere": {
					"titluOriginal": "Machines a calculer electronique. Aplications aux automatismes",
					"editura": "Dumond",
					"traducatori": {
						"traducator": "-"
					},
					"_an": "1964",
					"_editia": "II",
					"_limba": "franceza",
					"_tara": "Franta"
				},
				"redactori": {
					"redactor": "Smaranda Dimitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_analogice_si_numerice_Aplicatii_in_automatizari_Pelegrin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere si definitii"
						},
						{
							"_nr": "Partea I-a",
							"__text": "Calculatoare analogice"
						},
						{
							"_nr": "2",
							"__text": "Calculatoare analogice mecno-electrice"
						},
						{
							"_nr": "3",
							"__text": "Calculatoare analogice cu curenti purtatori"
						},
						{
							"_nr": "4",
							"__text": "Calculatoare analogice electronice"
						},
						{
							"_nr": "5",
							"__text": "Cuve electrolitice. Retele cu rezistente"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Calculatoare numerice"
						},
						{
							"_nr": "6",
							"__text": "Principiul calculatoarelor numerice"
						},
						{
							"_nr": "7",
							"__text": "Reprezentarea marimilor in vederea prelucrarii lor numerice"
						},
						{
							"_nr": "8",
							"__text": "Programarea"
						},
						{
							"_nr": "9",
							"__text": "Circuite functionale fundamentale"
						},
						{
							"_nr": "10",
							"__text": "Elementul de calcul. Principiu si realizarea sa"
						},
						{
							"_nr": "11",
							"__text": "Performatele si exploatarea calculatoarelor"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Aplicatii in automatizari"
						},
						{
							"_nr": "12",
							"__text": "Probleme particulare datorita functionarii in lant"
						},
						{
							"_nr": "13",
							"__text": "Elemente de cuplare si convertire"
						},
						{
							"_nr": "14",
							"__text": "Exemple de calcul al unor sisteme"
						},
						{
							"_nr": "15",
							"__text": "Automate finite si calculatoare specializate"
						}
					]
				},
				"_id": "1189"
			},
			{
				"titlu": "Analele Univeristatii Bucuresti - Informatica (Anul LIX - 2010)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu P. Dinu"
						},
						{
							"_nume": "Ionut Dinca"
						},
						{
							"_nume": "Tudor Balanescu"
						},
						{
							"_nume": "Daniel Claudian Voinescu"
						},
						{
							"_nume": "Anca Dinu"
						},
						{
							"_nume": "Iulian Daniel Buzdugan"
						},
						{
							"_nume": "Anca Cioates"
						},
						{
							"_nume": "Cornelia Enachescu"
						},
						{
							"_nume": "Denis Enachescu"
						},
						{
							"_nume": "Dalia Miron"
						},
						{
							"_nume": "Flavian Radulescu"
						},
						{
							"_nume": "Bogdan Aman"
						},
						{
							"_nume": "Gabriel Ciobanu"
						},
						{
							"_nume": "Adrian Atanasiu"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "112",
				"pretOriginal": "185000",
				"redactori": {
					"redactor": "Irina Hritcu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Emeline-Daniela Avram"
				},
				"coperta": {
					"imagine": {
						"_path": "Analele_Universitatii_Bucuresti_Informatica_2010_Dinu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Contextual Grammars and Linguistic Segmentation"
						},
						{
							"_nr": "2",
							"__text": "From P Systems to Software Systems by Design Patterns"
						},
						{
							"_nr": "3",
							"__text": "Concise Representation o Regular Languages"
						},
						{
							"_nr": "4",
							"__text": "Projectivity Property in NLP"
						},
						{
							"_nr": "5",
							"__text": "On the Usage of the Naive Bayes Model in Automatic Word Sense Disambiguation"
						},
						{
							"_nr": "6",
							"__text": "Cluster Analysis Techniques for Testing \"Similarity\" of Drug Dissolution Profiles"
						},
						{
							"_nr": "7",
							"__text": "Formalizing the Low-Density Lipoprotein Degradation"
						},
						{
							"_nr": "8",
							"__text": "Morphisms on Amiable Words"
						}
					]
				},
				"_id": "1190"
			},
			{
				"titlu": "Limbajul de programare Visual Basic. Ghid de realizare a plicatiilor de gestiune",
				"autori": {
					"autor": [
						{
							"_nume": "Anica-Popa Liana"
						},
						{
							"_nume": "Anica-Popa Ionut"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "InfoMega",
				"numarPagini": "374",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Limbajul_de_programare_Visual_Basic_Ghid_de_realizare_a_aplicatiilor_de_gestiune_Anica_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in limbajul Visual Basic"
						},
						{
							"_nr": "2",
							"__text": "Visual Basic - Notiuni fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Visual Basic - Notiuni avansate"
						},
						{
							"_nr": "4",
							"__text": "Teste de evaluare"
						}
					]
				},
				"_id": "1191",
				"_isbn": "973-86657-4-4"
			},
			{
				"titlu": "JavaScript fara mistere",
				"autori": {
					"autor": {
						"_nume": "Jim Keogh"
					}
				},
				"anAparitie": "2006",
				"editura": "Rosetti Educational",
				"numarPagini": "378",
				"pretOriginal": "0",
				"pretCumparare": "200000",
				"traducere": {
					"titluOriginal": "JavaScript Demystified",
					"editura": "McGraw-Hill",
					"traducatori": {
						"traducator": "Cora Radulian"
					},
					"_an": "2005",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "JavaScript_fara_mistere_Keogh.jpg"
					},
					"__text": "Daniel Munteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "O privire atenta asupra limbajului JavaScript"
						},
						{
							"_nr": "2",
							"__text": "Variabile, operatori si expresii"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni conditionale"
						},
						{
							"_nr": "4",
							"__text": "Tablouri"
						},
						{
							"_nr": "5",
							"__text": "Functii"
						},
						{
							"_nr": "6",
							"__text": "Siruri"
						},
						{
							"_nr": "7",
							"__text": "Formulare si tratarea erorilor"
						},
						{
							"_nr": "8",
							"__text": "Blocuri cookie"
						},
						{
							"_nr": "9",
							"__text": "Ferestre de browser"
						},
						{
							"_nr": "10",
							"__text": "Expresii regulate"
						},
						{
							"_nr": "11",
							"__text": "JavaScript si cadrele"
						},
						{
							"_nr": "12",
							"__text": "Elemente rollover"
						},
						{
							"_nr": "13",
							"__text": "Trasmiteti-va mesajul: bara de stare, anunturile si prezentarile de diapozitive"
						},
						{
							"_nr": "14",
							"__text": "Protejarea paginii dumneavostra web"
						},
						{
							"_nr": "15",
							"__text": "Meniuri"
						},
						{
							"_nr": "16",
							"__text": "DHTML"
						}
					]
				},
				"_id": "1192",
				"_isbn": "973-7881-10-9"
			},
			{
				"titlu": "Programarea aplicatiilor Internet",
				"autori": {
					"autor": [
						{
							"_nume": "Kris Jamsa"
						},
						{
							"_nume": "Ken Cope"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "All",
				"numarPagini": "602",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"traducere": {
					"titluOriginal": "Internet Programming",
					"editura": "Jamsa Press",
					"traducatori": {
						"traducator": "Mircea Patrascu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_aplicatiilor_Internet_Jamsa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in retelele de calculatoare"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura de retea"
						},
						{
							"_nr": "3",
							"__text": "Introducere in TCP/IP"
						},
						{
							"_nr": "4",
							"__text": "IP (Internet Protocol)"
						},
						{
							"_nr": "5",
							"__text": "Intelegerea protocoalelor de transport"
						},
						{
							"_nr": "6",
							"__text": "Comparatie intre SLIP si PPP"
						},
						{
							"_nr": "7",
							"__text": "Interfata Socket"
						},
						{
							"_nr": "8",
							"__text": "Windows Socket API"
						},
						{
							"_nr": "9",
							"__text": "Intelegerea sistemului numelor de domeniu (Domain Name System - DNS)"
						},
						{
							"_nr": "10",
							"__text": "Protocolul Finger pentru informatii utilizator"
						},
						{
							"_nr": "11",
							"__text": "Socket-uri Windows asincrone"
						},
						{
							"_nr": "12",
							"__text": "Folosirea handle-urilor pentru task-uri Winsock"
						},
						{
							"_nr": "13",
							"__text": "Serviciul Time si ordinea octetilor in retea"
						},
						{
							"_nr": "14",
							"__text": "Socket-uri brute"
						},
						{
							"_nr": "15",
							"__text": "Posta electronica in Internet"
						},
						{
							"_nr": "16",
							"__text": "Intelegerea protocoalelor de transfer al fisierelor"
						},
						{
							"_nr": "17",
							"__text": "Programarea aplicatiilor Internet folosind DLL-uri"
						},
						{
							"_nr": "18",
							"__text": "Programarea vizuala in Internet"
						},
						{
							"_nr": "19",
							"__text": "Paienjeni pe panza"
						},
						{
							"_nr": "Anexa A",
							"__text": "Firewall-uri si securitatea in Internet"
						},
						{
							"_nr": "Anexa B",
							"__text": "Intelegerea exemplelor de programe"
						}
					]
				},
				"_id": "1193",
				"_isbn": "973-9337-64-3"
			},
			{
				"titlu": "Primii pasi cu C#: Programare Windows cu C#",
				"autori": {
					"autor": {
						"_nume": "Obancea Dragos Iulian"
					}
				},
				"anAparitie": "2009",
				"editura": "Noua",
				"numarPagini": "154",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Primii_pasi_cu_C#_Programare_Windows_cu_C#_Obancea.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "I",
							"__text": "Sa ne pregatim"
						},
						{
							"_nr": "II",
							"__text": "Prima aplicatie"
						},
						{
							"_nr": "III",
							"__text": "Toolbox"
						},
						{
							"_nr": "IV",
							"__text": "Elemente de proiect"
						},
						{
							"_nr": "V",
							"__text": "Linii, forme geometrice si alte elemente de grafica"
						},
						{
							"_nr": "-",
							"__text": "Sfaturi si trucuri"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Net Speed"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Cpu Meter"
						}
					]
				},
				"_id": "1194",
				"_isbn": "978-606-8082-21-9"
			},
			{
				"titlu": "Sisteme informatice",
				"autori": {
					"autor": {
						"_nume": "Iosif Sandulescu"
					}
				},
				"anAparitie": "1981",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "112",
				"pretOriginal": "2.6",
				"pretCumparare": "40000",
				"colectia": {
					"_numarul": "137",
					"_nume": "Stiinta pentru toti"
				},
				"redactori": {
					"redactor": "Florica Plopeanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marilena Damaschinopol"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_Sandulescu.jpg"
					},
					"__text": "Eugen Kerry"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Sisteme informatice"
						},
						{
							"_nr": "II",
							"__text": "Activitati specifice in domeniul informaticii"
						},
						{
							"_nr": "III",
							"__text": "Biblioteca nationala de programe"
						},
						{
							"_nr": "IV",
							"__text": "Metode si tehnici moderne in realizarea sistemelor informatice"
						},
						{
							"_nr": "V",
							"__text": "Eficienta economica a sistemelor informatice"
						},
						{
							"_nr": "VI",
							"__text": "Perspectivele informaticii romanesti"
						}
					]
				},
				"_id": "1195",
				"_isbn": ""
			},
			{
				"titlu": "Modelul matematic - instrument si punct de vedere",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Calude"
						},
						{
							"_nume": "Gheorghe Paun"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "136",
				"pretOriginal": "4.25",
				"pretCumparare": "140000",
				"colectia": {
					"_numarul": "193",
					"_nume": "Stiinta pentru toti"
				},
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Modelul_matematic_instrument_si_punct_de_vedere_Calude.jpg"
					},
					"__text": "Eugen Kerry"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aducem problema spre model, sau modelul spre problema?"
						},
						{
							"_nr": "2",
							"__text": "Patru problema - un singur model"
						},
						{
							"_nr": "3",
							"__text": "Modelul (matemetic) - punct de vedere"
						},
						{
							"_nr": "4",
							"__text": "Alte exemple de modele"
						},
						{
							"_nr": "5",
							"__text": "Problema - model - solutie"
						}
					]
				},
				"_id": "1196",
				"_isbn": ""
			},
			{
				"titlu": "Ce este teoria grafurilor?",
				"autori": {
					"autor": {
						"_nume": "Ioan Tomescu"
					}
				},
				"anAparitie": "1982",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "148",
				"pretOriginal": "4.25",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "182",
					"_nume": "Stiinta pentru toti"
				},
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Iordache"
				},
				"coperta": {
					"imagine": {
						"_path": "Ce_este_teoria_grafurilor_Tomescu.jpg"
					},
					"__text": "Eugen Kerry"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Scurt istoric al teoriei grafurilor"
						},
						{
							"_nr": "2",
							"__text": "Grafuri si subgrafuri"
						},
						{
							"_nr": "3",
							"__text": "Grafuri si matrici"
						},
						{
							"_nr": "4",
							"__text": "Probleme hamiltoniene"
						},
						{
							"_nr": "5",
							"__text": "Grafuri planare si rezolvarea problemei celor patru culori"
						},
						{
							"_nr": "6",
							"__text": "Grafuri si grupuri"
						},
						{
							"_nr": "7",
							"__text": "Numerele lui Remsey"
						},
						{
							"_nr": "8",
							"__text": "Numararea grafurilor"
						}
					]
				},
				"_id": "1197",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul de operare DOS. Functii sistem (editia III)",
				"autori": {
					"autor": {
						"_nume": "Vlad Caprariu"
					}
				},
				"anAparitie": "1991",
				"editura": "MicroInformatica",
				"tiraj": "10000",
				"numarPagini": "160",
				"pretOriginal": "150",
				"colectia": {
					"_nume": "Biblioteca PC",
					"_numarul": "3"
				},
				"tehnoredactori": {
					"tehnoredactor": "Smaranda Dervesteanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_DOS_Functii_sistem_Caprariu_1991_ed_III.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Apeluri de functii sistem"
						},
						{
							"_nr": "2",
							"__text": "Descrierea detaliata a apelurilor sistem"
						},
						{
							"_nr": "3",
							"__text": "Exemple de utilizare a functiilor sistem"
						}
					]
				},
				"_id": "1198",
				"_isbn": ""
			},
			{
				"titlu": "Sistemul de operare MIX. Fortran-77. Manual de programare",
				"autori": {
					"autor": {
						"_nume": "[ITC]"
					}
				},
				"anAparitie": "1984",
				"editura": "ITC",
				"numarPagini": "174",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_MIX_Fortran_77_Manual_de_programare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati despre limbajul FORTRAN-77"
						},
						{
							"_nr": "2",
							"__text": "Elemente componente ale unei instructiuni FORTRAN"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de atribuire"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni de control"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni de intrare/iesire"
						},
						{
							"_nr": "6",
							"__text": "Specificatia de format"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni de specificare"
						},
						{
							"_nr": "8",
							"__text": "Proceduri"
						},
						{
							"_nr": "Anexa A",
							"__text": "Setul de caractere"
						},
						{
							"_nr": "Anexa B",
							"__text": "Functii intriseci si numele lor generice"
						}
					]
				},
				"_id": "1199",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoarele 8086, 286, 386 : Programarea in limbaj de asamblare",
				"autori": {
					"autor": [
						{
							"_nume": "Irina Athanasiu"
						},
						{
							"_nume": "Alexandru Panoiu"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Teora",
				"numarPagini": "144",
				"pretOriginal": "350",
				"colectia": {
					"_numarul": "4",
					"_nume": "Calculatoare personale"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mihai Trandafirescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Microprocesoare_8086_286_286_Athanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajul de asamblare pentru microprocesorul 8086/8088"
						},
						{
							"_nr": "2",
							"__text": "Repertoriul de instructiuni al microprocesorului 8086/8088"
						},
						{
							"_nr": "3",
							"__text": "Elemente de utilizarea a macroinstructiunilor in limbaj de asamblare"
						},
						{
							"_nr": "4",
							"__text": "Conventii de transfer de parametri pentru subprograme in limbajele de programare Turbo Pascal si C"
						},
						{
							"_nr": "5",
							"__text": "Elemente privind sistemul de operare MS-DOS"
						},
						{
							"_nr": "6",
							"__text": "Elemente privind utilizarea unor echipamente din configuratia standard"
						}
					]
				},
				"_id": "1200",
				"_isbn": "973-601-023-6"
			},
			{
				"titlu": "Limbajul C: programare",
				"autori": {
					"autor": {
						"_nume": "[ITC]"
					}
				},
				"anAparitie": "1988",
				"editura": "ITC",
				"numarPagini": "116",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "7",
					"_nume": "Software"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C_Programare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati asupra limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Unitati lexicale ale limbajului C"
						},
						{
							"_nr": "3",
							"__text": "Variabile"
						},
						{
							"_nr": "4",
							"__text": "Operatori si exepresii"
						},
						{
							"_nr": "5",
							"__text": "Declaratii"
						},
						{
							"_nr": "6",
							"__text": "Instructiuni"
						},
						{
							"_nr": "7",
							"__text": "Functiile si structrura unui program"
						},
						{
							"_nr": "8",
							"__text": "Liniile de control ale compilatorului"
						},
						{
							"_nr": "9",
							"__text": "Pointeri si masive"
						},
						{
							"_nr": "10",
							"__text": "Structuri si reuniuni"
						},
						{
							"_nr": "11",
							"__text": "Intrari/iesiri"
						}
					]
				},
				"_id": "1201",
				"_isbn": ""
			},
			{
				"titlu": "Cobol-81: programare si operare",
				"autori": {
					"autor": [
						{
							"_nume": "Prodan Augustin"
						},
						{
							"_nume": "Dan Carmen"
						},
						{
							"_nume": "Handrea Mihai"
						},
						{
							"_nume": "Mecea Lucia"
						},
						{
							"_nume": "Prichici Paul"
						},
						{
							"_nume": "Topliceanu Sorin"
						}
					]
				},
				"anAparitie": "1988",
				"editura": "ITC",
				"numarPagini": "216",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "11",
					"_nume": "Software"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Cobol_81_programare_si_operare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati"
						},
						{
							"_nr": "2",
							"__text": "Structura de baza"
						},
						{
							"_nr": "3",
							"__text": "Prelucrarea fisierelor"
						},
						{
							"_nr": "4",
							"__text": "Segmentarea in Cobol-81"
						},
						{
							"_nr": "5",
							"__text": "Biblioteci sursa Cobol-81"
						},
						{
							"_nr": "6",
							"__text": "Sortare si interclasare"
						},
						{
							"_nr": "7",
							"__text": "Ghid de operare"
						},
						{
							"_nr": "Anexa A",
							"__text": "Cuvinte rezervate COBOL"
						},
						{
							"_nr": "Anexa B",
							"__text": "Registrul de stare pentru intrari/iesiri"
						},
						{
							"_nr": "Anexa C",
							"__text": "Formatul COBOL"
						}
					]
				},
				"_id": "1202",
				"_isbn": ""
			},
			{
				"titlu": "Fortran-77: programare",
				"autori": {
					"autor": {
						"_nume": "[ITC]"
					}
				},
				"anAparitie": "1988",
				"editura": "ITC",
				"numarPagini": "216",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "1",
					"_nume": "Software"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Fortran_77_Programare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Generalitati despre limbajul FORTRAN-77"
						},
						{
							"_nr": "2",
							"__text": "Elemente componente ale unei instructiuni FORTRAN"
						},
						{
							"_nr": "3",
							"__text": "Instructiuni de atribuire"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni de control"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni de intrare/iesire"
						},
						{
							"_nr": "6",
							"__text": "Specificatia de format"
						},
						{
							"_nr": "7",
							"__text": "Instructiuni de specificare"
						},
						{
							"_nr": "8",
							"__text": "Proceduri"
						},
						{
							"_nr": "Anexa A",
							"__text": "Setul de caractere"
						},
						{
							"_nr": "Anexa B",
							"__text": "Functii intriseci si numele lor generice"
						}
					]
				},
				"_id": "1203",
				"_isbn": ""
			},
			{
				"titlu": "Fortran-77: operare",
				"autori": {
					"autor": {
						"_nume": "[ITC]"
					}
				},
				"anAparitie": "1988",
				"editura": "ITC",
				"numarPagini": "148",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "2",
					"_nume": "Software"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Fortran_77_Operare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala a modului de operare"
						},
						{
							"_nr": "2",
							"__text": "Operatii de intrare/iesire in FORTRAN-77"
						},
						{
							"_nr": "3",
							"__text": "Interfata cu sistemul de operare"
						},
						{
							"_nr": "4",
							"__text": "Particularitati de implementare a programelor FORTRAN-77"
						},
						{
							"_nr": "5",
							"__text": "Facilitati de programare in limbajul FORTRAN-77"
						},
						{
							"_nr": "Anexa A",
							"__text": "Prezentarea datelor in FORTRAN-77"
						},
						{
							"_nr": "Anaxa B",
							"__text": "Subprograme lae bibliotecii FORTRAN-77"
						},
						{
							"_nr": "Anexa C",
							"__text": "Functiile intrinseci si numele lor generice"
						},
						{
							"_nr": "Anexa E",
							"__text": "Implicatii ale utilizarii comutatorului /F77"
						}
					]
				},
				"_id": "1204",
				"_isbn": ""
			},
			{
				"titlu": "DOS-PC GW-BASIC: programare si operare",
				"autori": {
					"autor": [
						{
							"_nume": "Bosilca Gheorghe"
						},
						{
							"_nume": "Barbu Monica"
						},
						{
							"_nume": "Galfi Suzana"
						},
						{
							"_nume": "Kerekes Stefan"
						},
						{
							"_nume": "Oprea Carmen"
						},
						{
							"_nume": "Szasz Detre"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "ITC",
				"numarPagini": "208",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "2",
					"_nume": "DOS-PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "DOS_PC_GW_BASIC_Programare_si_operare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Interpretorul GW-BASIC"
						},
						{
							"_nr": "2",
							"__text": "Limbjul GW-BASIC"
						},
						{
							"_nr": "3",
							"__text": "Introducerea programelor GW_BASIC"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni si functii de interes general"
						},
						{
							"_nr": "5",
							"__text": "Tratarea fisierelor"
						},
						{
							"_nr": "6",
							"__text": "Incarcarea si salvarea programelor"
						},
						{
							"_nr": "7",
							"__text": "Tratarea evenimentelor"
						},
						{
							"_nr": "8",
							"__text": "Tratarea erorilor"
						},
						{
							"_nr": "9",
							"__text": "Instructiuni si functii grafice"
						},
						{
							"_nr": "10",
							"__text": "Subprograme si apelarea lor"
						},
						{
							"_nr": "11",
							"__text": "Inlantuirea programelor"
						},
						{
							"_nr": "12",
							"__text": "Functii speciale"
						},
						{
							"_nr": "13",
							"__text": "Instructiuni si comenzi speciale"
						},
						{
							"_nr": "Anexa A",
							"__text": "Coduri si mesaje de eroare GW-BASIC"
						},
						{
							"_nr": "Anexa B",
							"__text": "Cuvinte rezervate GW-BASIC"
						},
						{
							"_nr": "Anexa C",
							"__text": "Index"
						}
					]
				},
				"_id": "1205",
				"_isbn": ""
			},
			{
				"titlu": "EPT : Editor Profesional de texte",
				"autori": {
					"autor": [
						{
							"_nume": "Popescu Mircea"
						},
						{
							"_nume": "Magda Marcella"
						},
						{
							"_nume": "Patrulea Mirela"
						},
						{
							"_nume": "Popa Greta"
						},
						{
							"_nume": "Topliceanu Sorin"
						},
						{
							"_nume": "Zeicu Mircea"
						}
					]
				},
				"anAparitie": "1990",
				"editura": "ITC",
				"numarPagini": "256",
				"pretOriginal": "0",
				"colectia": {
					"_numarul": "3",
					"_nume": "DOS-PC"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Dervesteanu Smaranda",
						"Sanda Moldovan"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "EPT_Editor_profesional_de_texte_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte si functii ale programului EPT"
						},
						{
							"_nr": "3",
							"__text": "Modul de utilizare a programului EPT"
						},
						{
							"_nr": "4",
							"__text": "Ghid de refereinte"
						},
						{
							"_nr": "Anexa A",
							"__text": "Mesaje de eroare"
						},
						{
							"_nr": "Anexa B",
							"__text": "Exemple EPT si observatii"
						},
						{
							"_nr": "Anexa C",
							"__text": "Adaptarea EPT-ului"
						},
						{
							"_nr": "Anexa D",
							"__text": "Fisierel de pe discurile EPT"
						},
						{
							"_nr": "Anexa E",
							"__text": "Buna utilizare a directoarelor si memorie"
						},
						{
							"_nr": "Anexa F",
							"__text": "Codurile ASCII ale caracterelor si setul de caractere extins"
						},
						{
							"_nr": "Anexa G",
							"__text": "Sumarul comenzilor"
						}
					]
				},
				"_id": "1206",
				"_isbn": ""
			},
			{
				"titlu": "BASIC PLUS 2 : Manual de programare si operare",
				"autori": {
					"autor": {
						"_nume": "[ITC]"
					}
				},
				"anAparitie": "1987",
				"editura": "ITC",
				"numarPagini": "192",
				"pretOriginal": "0",
				"tehnoredactori": {
					"tehnoredactor": [
						"Dervesteanu Smaranda",
						"Sanda Moldovan"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "BASIC_PLUS_2_Manual_de_programare_si_operare_ITC.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajul de programare BASIC-PLUS-2"
						},
						{
							"_nr": "2",
							"__text": "Instructiuni BASIC-PLUS-2"
						},
						{
							"_nr": "3",
							"__text": "Fisiere BASIC-PLUS-2"
						},
						{
							"_nr": "4",
							"__text": "Subprograme in BASIC-PLUS-2"
						},
						{
							"_nr": "5",
							"__text": "Compilatorul BASIC-PLUS-2"
						},
						{
							"_nr": "6",
							"__text": "Editia de legaturi a programelor BASIC-PLUS-2"
						},
						{
							"_nr": "7",
							"__text": "Executia taskurilor program BP2"
						},
						{
							"_nr": "8",
							"__text": "Apelul procedurilor si transmiterea parametrilor"
						},
						{
							"_nr": "9",
							"__text": "Tratarea informatiilor externe"
						},
						{
							"_nr": "10",
							"__text": "Inlantuirea programelor BASIC-PLUS-2"
						},
						{
							"_nr": "11",
							"__text": "Depanatorul simbolic interactiv BASIC-PLUS-2"
						},
						{
							"_nr": "Anexa A",
							"__text": "Lista optiunilor de compilare BASIC-PLUS-2"
						},
						{
							"_nr": "Anexa B",
							"__text": "Erori detectate in analiza liniei de comanda"
						},
						{
							"_nr": "Anexa C",
							"__text": "Lista erorilor sintactice depistate de compilator la analiza liniilor program"
						},
						{
							"_nr": "Anexa D",
							"__text": "Lista erorilor semnatice si de generare"
						},
						{
							"_nr": "Anexa E",
							"__text": "Lista erorilor de executie"
						},
						{
							"_nr": "Anexa F",
							"__text": "Comenzile depanatorului simbolic interactiv"
						},
						{
							"_nr": "Anexa G",
							"__text": "Cuvinte rezervate BASIC-PLUS-2"
						}
					]
				},
				"_id": "1207",
				"_isbn": ""
			},
			{
				"titlu": "COBOL: programe de instruire",
				"autori": {
					"autor": {
						"_nume": "Nicolae Ghisoiu"
					}
				},
				"anAparitie": "1992",
				"editura": "Genesis",
				"numarPagini": "132",
				"pretOriginal": "300",
				"coperta": {
					"imagine": {
						"_path": "Cobol_programe_de_instruire_Ghisoiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elaborarea modular-structurata a programelor Cobol"
						},
						{
							"_nr": "2",
							"__text": "Programe modular-structurate in Cobol"
						},
						{
							"_nr": "3",
							"__text": "Operarea programelor Cobol"
						}
					]
				},
				"_id": "1208",
				"_isbn": "973-95224-0-8"
			},
			{
				"titlu": "Teoria jocurilor strategice",
				"autori": {
					"autor": {
						"_nume": "Alexandru Al. Rosu"
					}
				},
				"anAparitie": "1967",
				"editura": "Militara",
				"numarPagini": "442",
				"pretOriginal": "24",
				"redactori": {
					"redactor": "Dorin Dumitriu"
				},
				"tehnoredactori": {
					"tehnoredactor": "D. Andrei"
				},
				"coperta": {
					"imagine": {
						"_path": "Teoria_jocurilor_strategice_Rosu.jpg"
					},
					"__text": "O. Oprescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in teoria jocurilor strategice"
						},
						{
							"_nr": "II",
							"__text": "Jocuri matriceale"
						},
						{
							"_nr": "III",
							"__text": "Jocuri fara punct sa"
						},
						{
							"_nr": "IV",
							"__text": "Proprietatile matematice ale jocurilor matriceale"
						},
						{
							"_nr": "V",
							"__text": "Rezolvarea jocurilor matriceale"
						},
						{
							"_nr": "VI",
							"__text": "Rezolvarea jocurilor particulare"
						},
						{
							"_nr": "VII",
							"__text": "Jocuri cu toate strategiile active"
						},
						{
							"_nr": "VIII",
							"__text": "Legatura dintre teoria jocurilor si programarea liniara"
						},
						{
							"_nr": "IX",
							"__text": "Alte legaturi ale teoriei jocurilor"
						},
						{
							"_nr": "X",
							"__text": "Aplicatii ale teoriei jocurilor matriceale"
						},
						{
							"_nr": "XI",
							"__text": "Jocuri infinite"
						},
						{
							"_nr": "XII",
							"__text": "Aplicatii ale jocurilor continue"
						},
						{
							"_nr": "XIII",
							"__text": "Teoria deciziilor"
						},
						{
							"_nr": "XIV",
							"__text": "Jocuri cu suma nula si n persoane"
						},
						{
							"_nr": "XV",
							"__text": "Jocuri fara suma nula"
						}
					]
				},
				"_id": "1209",
				"_isbn": ""
			},
			{
				"titlu": "Compresia datelor",
				"autori": {
					"autor": {
						"_nume": "Dan Stefanoiu"
					}
				},
				"anAparitie": "2003",
				"editura": "Printech",
				"numarPagini": "454",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Compresia_datelor_Stefanoiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Compresia prin minimizarea redundantei"
						},
						{
							"_nr": "3",
							"__text": "Compresia adaptiva de tip HUFFMAN"
						},
						{
							"_nr": "4",
							"__text": "Compresia prin codaj aritmetic"
						},
						{
							"_nr": "5",
							"__text": "Compresia utilizand modele statistice de ordin superior"
						},
						{
							"_nr": "6",
							"__text": "Compresia cu fereastra culisanta, pe baza de dictionar"
						},
						{
							"_nr": "7",
							"__text": "Arhivarea si compresia fisierelor"
						},
						{
							"_nr": "8",
							"__text": "Compresia si codajul vocii"
						},
						{
							"_nr": "9",
							"__text": "Compresia imaginilor"
						}
					]
				},
				"_id": "1210",
				"_isbn": "973-652-817-2"
			},
			{
				"titlu": "Sun Certified Programmer for Java 6 Study Guide: Exam 310-065",
				"autori": {
					"autor": [
						{
							"_nume": "Kathy Sierra"
						},
						{
							"_nume": "Bert Bates"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "McGraw-Hill",
				"numarPagini": "852",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": "Sun_Certified_Programmer_for_Java_6_Study_Guide_Exam_310_065_Sierra.jpg"
					},
					"__text": "Jeff Weeks"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Declarations and Access Control"
						},
						{
							"_nr": "2",
							"__text": "Object Orientation"
						},
						{
							"_nr": "3",
							"__text": "Assignments"
						},
						{
							"_nr": "4",
							"__text": "Operators"
						},
						{
							"_nr": "5",
							"__text": "Flow Control, Exceptions, and Assertions"
						},
						{
							"_nr": "6",
							"__text": "Strings, I/O, Formatting, and Parsing"
						},
						{
							"_nr": "7",
							"__text": "Generics and Collections"
						},
						{
							"_nr": "8",
							"__text": "Inner Classes"
						},
						{
							"_nr": "9",
							"__text": "Development"
						}
					]
				},
				"_id": "1211",
				"_isbn": "978-0-07-159108-9"
			},
			{
				"titlu": "Sisteme informatice: elemente fundamentale [ed. III]",
				"autori": {
					"autor": {
						"_nume": "Bogdan Onete"
					}
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "156",
				"pretOriginal": "39000",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Violeta Rogojan"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_Elemente_fundamentale_Onete.jpg"
					},
					"__text": "Claudia-Marinela Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme informatice in activitatea economica"
						},
						{
							"_nr": "2",
							"__text": "Concepte ale sistemelor informatice"
						},
						{
							"_nr": "3",
							"__text": "Tipuri si categorii de sisteme informatice"
						},
						{
							"_nr": "4",
							"__text": "Proiectarea si implementarea de sisteme informatice"
						},
						{
							"_nr": "5",
							"__text": "Modelarea in sistemele informatice"
						},
						{
							"_nr": "6",
							"__text": "Inteligenta artificiala si sistemele expert"
						},
						{
							"_nr": "7",
							"__text": "Tehnologii inteligente de varf"
						},
						{
							"_nr": "8",
							"__text": "Hardware pentru sisteme informatice"
						},
						{
							"_nr": "9",
							"__text": "Software pentru sisteme informatice"
						},
						{
							"_nr": "10",
							"__text": "Sisteme informatice de marketing"
						},
						{
							"_nr": "11",
							"__text": "Sisteme informatice pentru turism"
						}
					]
				},
				"_id": "1212",
				"_isbn": "973-594-273-9"
			},
			{
				"titlu": "Bacalaureat 2010: Evaluarea competentelor digitale",
				"autori": {
					"autor": [
						{
							"_nume": "Silviu Iulian Dumitru"
						},
						{
							"_nume": "Oana Camelia Condur"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Corint",
				"numarPagini": "152",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Marieva Ionescu"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Corneliu Frumosu",
						"Crina Aprodu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Bacalaureat_2010_Evaluarea_competentelor_digitale_Dumitru.jpg"
					},
					"__text": "Valeria Moldovan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Informatii privind sustinerea examenului de evaluare a competentelor digitale"
						},
						{
							"_nr": "1",
							"__text": "Concepte generale ale tehnologiei informatiei"
						},
						{
							"_nr": "2",
							"__text": "Utilizarea computerului si organizarea fisierelor"
						},
						{
							"_nr": "3",
							"__text": "Editorul de texte"
						},
						{
							"_nr": "4",
							"__text": "Calcul tabelar"
						},
						{
							"_nr": "5",
							"__text": "Baze de date"
						},
						{
							"_nr": "6",
							"__text": "Prezentari"
						},
						{
							"_nr": "7",
							"__text": "Informatie si comunicare"
						}
					]
				},
				"_id": "1213",
				"_isbn": "978-973-135-542-9"
			},
			{
				"titlu": "Calculatoare personale",
				"autori": {
					"autor": {
						"_nume": "Joe Kraynak"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "272",
				"pretOriginal": "6990",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "48",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The First Book of Personal Computing",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Ioana Magdalena Manea"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Maria Vladulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_personale_Kraynak.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce poate face pentru dvs. un calculator personal"
						},
						{
							"_nr": "2",
							"__text": "Partile componente ale calculatorului"
						},
						{
							"_nr": "3",
							"__text": "Punerea in functiune a calculatorului"
						},
						{
							"_nr": "4",
							"__text": "Folosirea tastaturii si a mousului"
						},
						{
							"_nr": "5",
							"__text": "Cum sa lucrati cu dischete, cataloage si fisiere"
						},
						{
							"_nr": "6",
							"__text": "Folosirea calculatorului IBM sau a celui compatibil cu cel IBM"
						},
						{
							"_nr": "7",
							"__text": "Folosirea calculatorului Macintosh"
						},
						{
							"_nr": "8",
							"__text": "Invatati sa lucrati cu aplicatii soft"
						},
						{
							"_nr": "9",
							"__text": "Redactarea unui text cu un program 'word processor'"
						},
						{
							"_nr": "10",
							"__text": "Prelucrarea datelor cu ajutorul foilor de calcul"
						},
						{
							"_nr": "11",
							"__text": "Controlarea informatiei cu ajutorul unei baze de date"
						},
						{
							"_nr": "12",
							"__text": "Folosirea calculatorului in scopuri artistice: grafica"
						},
						{
							"_nr": "13",
							"__text": "Editarea computerizata a publicatiilor"
						},
						{
							"_nr": "14",
							"__text": "Cum sa va economisiti timpul si banii"
						},
						{
							"_nr": "15",
							"__text": "Jocurile si educatia cu ajutorul calculatorului"
						},
						{
							"_nr": "16",
							"__text": "Comunicarea intre calculatoare"
						},
						{
							"_nr": "17",
							"__text": "Gasiti-va calculatorul dorit"
						},
						{
							"_nr": "18",
							"__text": "Achizitionarea de soft"
						},
						{
							"_nr": "19",
							"__text": "Intretinerea si protejarea calculatorului"
						}
					]
				},
				"_id": "1214",
				"_isbn": "973-601-211-5"
			},
			{
				"titlu": "Limbajele C si C++ pentru incepatori. Limbajul C. Volumul I - partea I-a(editia IV)",
				"autori": {
					"autor": {
						"_nume": "Liviu Negrescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "310",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "27",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajele_C_si_Cpp_pentru_incepatori_Limbajul_C_Vol_I_partea_I_ed_IV_Negrescu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Intrari/Iesiri standard"
						},
						{
							"_nr": "3",
							"__text": "Expresii"
						},
						{
							"_nr": "4",
							"__text": "Instructiuni"
						},
						{
							"_nr": "5",
							"__text": "Clase de memorie"
						},
						{
							"_nr": "6",
							"__text": "Initializare"
						},
						{
							"_nr": "7",
							"__text": "Programare modulara"
						},
						{
							"_nr": "8",
							"__text": "Pointeri"
						},
						{
							"_nr": "9",
							"__text": "Recursivitate"
						}
					]
				},
				"_id": "1215",
				"_isbn": "973-9215-83-1"
			},
			{
				"titlu": "C pentru toti (Volumul I)",
				"autori": {
					"autor": {
						"_nume": "Dan Gookin"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "624",
				"pretOriginal": "28000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "158",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "C For Dummies, Volume 1",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Corneliu Catrina"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": [
						"Cristian Mihai",
						"Dan Mateescu",
						"Vasile Nedelcu"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Cristian Dumitrescu",
						"Dan Mateescu",
						"Vasile Nedelcu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "C_pentru_toti_Vol_I_Gookin.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Chestiile de inceput (chiar nedureroase uneori)"
						},
						{
							"_nr": "2",
							"__text": "Construirea (si amestecarea) blocurilor de baza in limbajul C"
						},
						{
							"_nr": "3",
							"__text": "Lacrimi amare datorita variabilelor si (hm!) matematicii"
						},
						{
							"_nr": "4",
							"__text": "Luarea deciziilor (sau 'Voi avea si eu ceea ce are ea')"
						},
						{
							"_nr": "5",
							"__text": "Propriile dumneavostra functii"
						},
						{
							"_nr": "6",
							"__text": "Perfectionarea artei de a programa in limbajul C"
						},
						{
							"_nr": "7",
							"__text": "Sa ne invartim in cicluri"
						},
						{
							"_nr": "A",
							"__text": "Raspunsuri la exercitii"
						},
						{
							"_nr": "B",
							"__text": "Tabela ASCII"
						},
						{
							"_nr": "C",
							"__text": "Luptandu-ne cu Borland C++ 4.0"
						},
						{
							"_nr": "D",
							"__text": "Programe potential distractive"
						}
					]
				},
				"_id": "1216",
				"_isbn": "973-601-401-0"
			},
			{
				"titlu": "Ingineria programarii (Vol.2)",
				"autori": {
					"autor": [
						{
							"_nume": "Ilie Vaduva"
						},
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "Vasile Florescu"
						},
						{
							"_nume": "Ion Floricica"
						},
						{
							"_nume": "Mihai Jitaru"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "280",
				"pretOriginal": "28",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Aurel Budnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Ingineria programarii_Vol_2_Vaduva.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "7",
							"__text": "Corectitudinea si fiablitatea programelor"
						},
						{
							"_nr": "8",
							"__text": "Testarea si depanarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Documentarea programelor"
						},
						{
							"_nr": "10",
							"__text": "Implementarea programelor"
						},
						{
							"_nr": "11",
							"__text": "Intretinerea programelor"
						},
						{
							"_nr": "12",
							"__text": "Calitatea produselor-program"
						},
						{
							"_nr": "13",
							"__text": "Portabilitatea si adaptabilitatea programelor"
						},
						{
							"_nr": "14",
							"__text": "Evaluarea si masurarea performantelor"
						},
						{
							"_nr": "15",
							"__text": "Conducerea activitatii de elaborarea a produselor-program"
						},
						{
							"_nr": "16",
							"__text": "Programarea proceselor concurente"
						},
						{
							"_nr": "17",
							"__text": "Instrumente software si medii de programare"
						},
						{
							"_nr": "18",
							"__text": "Programarea folosind limbaje de nivel inalt"
						}
					]
				},
				"_id": "1217",
				"_isbn": ""
			},
			{
				"titlu": "Calculatoare electronice si sisteme de operare",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "Vladimir Dumitrescu"
						},
						{
							"_nume": "Dan Ionescu"
						},
						{
							"_nume": "Cristian Popescu"
						},
						{
							"_nume": "Cornel Tudor"
						}
					]
				},
				"anAparitie": "1974",
				"editura": "Didactica si pedagogica",
				"numarPagini": "744",
				"pretOriginal": "27.50",
				"redactori": {
					"redactor": "V. Marinoiu"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Antoniu"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_electronice_si_sisteme_de_operare_Dodescu.jpg"
					},
					"__text": "D. Negrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Notiuni privind teoria informatiei. Masura, transmisie si prelucrare"
						},
						{
							"_nr": "3",
							"__text": "Bazele logice ale calculatoarelor numerice"
						},
						{
							"_nr": "4",
							"__text": "Bazele numerice ale calculatoarelor electronice"
						},
						{
							"_nr": "5",
							"__text": "Circuite logice"
						},
						{
							"_nr": "6",
							"__text": "Blocuri functionale"
						},
						{
							"_nr": "7",
							"__text": "Sisteme de memorie"
						},
						{
							"_nr": "8",
							"__text": "Unitati periferice de intrare"
						},
						{
							"_nr": "9",
							"__text": "Unitati periferice de iesire"
						},
						{
							"_nr": "10",
							"__text": "Unitati periferice de intrare-iesire"
						},
						{
							"_nr": "11",
							"__text": "Unitatea centrala"
						},
						{
							"_nr": "12",
							"__text": "Sistemul de intrare-iesire"
						},
						{
							"_nr": "13",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "14",
							"__text": "Metode de exloatare a sistemului de calcul"
						}
					]
				},
				"_id": "1218",
				"_isbn": ""
			},
			{
				"titlu": "Introducere in programare: teorie si practica Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Constantin Apostol"
						},
						{
							"_nume": "Bogdan Ghilic-Micu"
						},
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Valer Rosca"
						}
					]
				},
				"anAparitie": "1993",
				"editura": "Viata Romaneasca",
				"numarPagini": "315",
				"pretOriginal": "1500",
				"tehnoredactori": {
					"tehnoredactor": [
						"Dana Colibaba",
						"Stefan Preda",
						"Valera Abaluta",
						"Adriana Cucu",
						"Gabriela Dobrinescu",
						"Doina Moraru",
						"Doina Onel",
						"Mariana Oprescu"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Introducere_in_programare_Teorie_si_practica_Pascal_Apostol.jpg"
					},
					"__text": "A. Ionescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Partea I"
						},
						{
							"_nr": "1",
							"__text": "Structura si functionarea calculatorului"
						},
						{
							"_nr": "2",
							"__text": "Organizarea interna a datelor"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi. Scheme logice structurate"
						},
						{
							"_nr": "4",
							"__text": "Etapele rezolvarii problemelor cu calculatorul"
						},
						{
							"_nr": "II",
							"__text": "Partea II"
						},
						{
							"_nr": "5",
							"__text": "Pascal - Limbaj de programare sistematica a calculatoarelor"
						},
						{
							"_nr": "6",
							"__text": "Tipuri statice de date in Pascal"
						},
						{
							"_nr": "7",
							"__text": "Expresii in Pascal"
						},
						{
							"_nr": "8",
							"__text": "Realizarea structurilor de control in Pascal"
						},
						{
							"_nr": "9",
							"__text": "Operatii de intrare/iesire efectuate cu tastatura/monitorul"
						},
						{
							"_nr": "10",
							"__text": "Subprograme Pascal"
						},
						{
							"_nr": "11",
							"__text": "Functii si proceduri aritmetice si asupra sirurilor de caractere"
						},
						{
							"_nr": "12",
							"__text": "Introducere in mediul de dezvoltare Turbo Pascal"
						},
						{
							"_nr": "III",
							"__text": "Partea III"
						},
						{
							"_nr": "13",
							"__text": "Exercitii rezolvate"
						},
						{
							"_nr": "Anexe",
							"__text": "Anexe"
						},
						{
							"_nr": "1",
							"__text": "Notiuni privind algebra boole"
						},
						{
							"_nr": "2",
							"__text": "Codificarea datelor"
						},
						{
							"_nr": "3",
							"__text": "Sisteme de numeratie"
						},
						{
							"_nr": "4",
							"__text": "Notiuni privind teoria grafurilor"
						},
						{
							"_nr": "5",
							"__text": "Taste 'firbinti' uzuale"
						},
						{
							"_nr": "6",
							"__text": "Editarea programelor sursa Pascal"
						}
					]
				},
				"_id": "1219",
				"_isbn": "973-564-115-5"
			},
			{
				"titlu": "Metode numerice in tehnica. Aplicatii in FORTRAN",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Siminonescu"
						},
						{
							"_nume": "Mihai Dranga"
						},
						{
							"_nume": "Victor Moise"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Tehnica",
				"numarPagini": "192",
				"pretOriginal": "6000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Daniela Raduly"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_in_tehnica_Aplicatii_in_FORTRAN_Simionescu.jpg"
					},
					"__text": "Daniela Raduly"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Erori"
						},
						{
							"_nr": "2",
							"__text": "Rezolvarea ecuatiilor neliniare"
						},
						{
							"_nr": "3",
							"__text": "Rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "4",
							"__text": "Vectori si valori proprii"
						},
						{
							"_nr": "5",
							"__text": "Rezolvarea sistemelor de ecuatii neliniare"
						},
						{
							"_nr": "6",
							"__text": "Diferente finite"
						},
						{
							"_nr": "7",
							"__text": "Aproximarea si interpolarea functiilor"
						},
						{
							"_nr": "8",
							"__text": "Derivarea si integrarea numerica"
						},
						{
							"_nr": "9",
							"__text": "Rezolvarea numerica a ecuatiilor diferentiale ordinare"
						},
						{
							"_nr": "10",
							"__text": "Rezolvarea numerica a ecuatiilor diferentiale cu derivate partiale"
						}
					]
				},
				"_id": "1220",
				"_isbn": "973-31-0896-0"
			},
			{
				"titlu": "Sa invatam sa programam BASIC",
				"autori": {
					"autor": [
						{
							"_nume": "Camelia Zaharia"
						},
						{
							"_nume": "Marian Zaharia"
						}
					]
				},
				"anAparitie": "1992",
				"editura": "Tehnica",
				"numarPagini": "184",
				"pretOriginal": "210",
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Sa_invatam_sa_programam_Basic_Zaharia.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni generale"
						},
						{
							"_nr": "2",
							"__text": "Algoritmi. Metode de reprezentare"
						},
						{
							"_nr": "3",
							"__text": "Elemente ale limbajului BASIC"
						},
						{
							"_nr": "4",
							"__text": "Spre o programare modulara si structurata"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii"
						}
					]
				},
				"_id": "1221",
				"_isbn": "973-31-0433-7"
			},
			{
				"titlu": "Cum functioneaza un calculator? (Vol I)",
				"autori": {
					"autor": {
						"_nume": "Rulf Neigenfind"
					}
				},
				"anAparitie": "1991",
				"editura": "Tehnica",
				"numarPagini": "64",
				"pretOriginal": "60",
				"traducere": {
					"titluOriginal": "Wie erklären Sie jemand, der Sie fragt, wie ein Computer funktioniert, wie ein Computer funktioniert?",
					"editura": "",
					"traducatori": {
						"traducator": "Silvia Candea"
					},
					"_an": "1988",
					"_editia": "I",
					"_limba": "germana",
					"_tara": "Germania"
				},
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Cum_functioneaza_un_calculator_I_Neigenfind.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Incepeti cu o comparatie corecta om-calculator. Un capotol despre notiunile principale"
						},
						{
							"_nr": "2",
							"__text": "Se constata ca omenirea, odata cu faurirea istoriei sale, face si matematica. Un capitol despre istoria calculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Explicati de ce calculatorul cel istet nu poate numara nici macar pana la doi. Un capitol despre sistemul numeric dual"
						},
						{
							"_nr": "4",
							"__text": "Motivati de ce fara program calculatorul este complet inutil. Un capitol despre programare"
						},
						{
							"_nr": "5",
							"__text": "Sa parcurgem un echipament de calcul. Un captol despre hardware"
						},
						{
							"_nr": "6",
							"__text": "Sa descriem cum si cu ce se alimenteaza un calculator. Un capitol despre introducerea datelor"
						},
						{
							"_nr": "7",
							"__text": "Sa descriem unde se iau deciziile intr-un calculator. Un capitol despre unitate centrala"
						},
						{
							"_nr": "8",
							"__text": "Sa descriem  cum isi manifesta in final calculatorul intelepciunea. Un capitol despre iesirea datelor"
						},
						{
							"_nr": "9",
							"__text": "Cateva exemple referitoare la mai buna exploatare a unui calculator. Un capitol despre optimizarea calculatoarelor"
						},
						{
							"_nr": "10",
							"__text": "Cateva speculatii privitoare la modul cum se va lucra in viitorul apropriat cu calculatorul. Un capitol despre viitorul calculatoarelor"
						},
						{
							"_nr": "11",
							"__text": "Organizati un mic curs de limba: limbajul calculatorului / limba romana. Un dictionar al notiunilor de calculatoare"
						}
					]
				},
				"_id": "1222",
				"_isbn": "973-31-400-0"
			},
			{
				"titlu": "Cum functioneaza un calculator? (Vol II)",
				"autori": {
					"autor": {
						"_nume": "Rulf Neigenfind"
					}
				},
				"anAparitie": "1991",
				"editura": "Tehnica",
				"numarPagini": "40",
				"pretOriginal": "60",
				"traducere": {
					"titluOriginal": "Wie erklären Sie jemand, der Sie fragt, wie ein Computer funktioniert, wie ein Computer funktioniert?",
					"editura": "",
					"traducatori": {
						"traducator": "Silvia Candea"
					},
					"_an": "1988",
					"_editia": "I",
					"_limba": "germana",
					"_tara": "Germania"
				},
				"redactori": {
					"redactor": "Silvia Candea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Silvia Candea"
				},
				"coperta": {
					"imagine": {
						"_path": "Cum_functioneaza_un_calculator_II_Neigenfind.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cititi si uitati. Un capitol despre rostul si menirea calculatoarelor. Sau: in ce scop sa cititi aceasta brosura"
						},
						{
							"_nr": "2",
							"__text": "Un capitol adresat multor cititori. Despre aplicatii ale calculatorului. Sau: ce legatura exista intre calculator si concediul dvs."
						},
						{
							"_nr": "3",
							"__text": "Programarea, primul pas. Despre analiza problemei. Sau: cum arata problema ce trebuie rezolvata"
						},
						{
							"_nr": "4",
							"__text": "Programarea, pasul al 2-lea. Despre schema logica. Sau: cum se ajunge la solutia problemei"
						},
						{
							"_nr": "5",
							"__text": "Programarea, pasul al 2-lea. Despre evolutia programului. Sau: cum arata programul care solutioneaza problema dvs."
						},
						{
							"_nr": "6",
							"__text": "Programarea, pasul al 4-lea. Despre scrierea codului. Sau: cum arata limbajul prin care se poate comanda calculatorului"
						},
						{
							"_nr": "7",
							"__text": "Cum se lucreaza cu calculatorul? Despre comunicarea om-calculator. Sau: ce intelege un calculator"
						},
						{
							"_nr": "8",
							"__text": "La urma a fost cuvantul. Despre simplificarea limbajului de programare. Sau: poate sa se autoprogrameze un calculator"
						},
						{
							"_nr": "9",
							"__text": "Scoala inalta a programarii. Despre specializarea in programare. Sau: ce trebuie sa cunoasca un programator contemporan"
						},
						{
							"_nr": "10",
							"__text": "Cu calulatorul de la egal la egal. Despre viitorul programarii. Si: care va fi rolul omului in acest context?"
						}
					]
				},
				"_id": "1223",
				"_isbn": "973-31-402-7"
			},
			{
				"titlu": "Adevarat, dar nedemonstrabil",
				"autori": {
					"autor": {
						"_nume": "Cristian Calude"
					}
				},
				"anAparitie": "1988",
				"editura": "Stiintifica si enciclopedica",
				"numarPagini": "112",
				"pretOriginal": "4",
				"colectia": {
					"_numarul": "294",
					"_nume": "Stiinta pentru toti"
				},
				"redactori": {
					"redactor": "Maria Boriceanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiu Popa"
				},
				"coperta": {
					"imagine": {
						"_path": "Adevarat_dar_nedemonstrabil_Calude.jpg"
					},
					"__text": "Ileana Nacu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Lucruri imposibile"
						},
						{
							"_nr": "3",
							"__text": "Cateva probleme fara solutii"
						},
						{
							"_nr": "4",
							"__text": "Orice afirmatie poate fi demonstrata?"
						},
						{
							"_nr": "5",
							"__text": "Gandire axiomatica, gandire intuitiva"
						},
						{
							"_nr": "6",
							"__text": "Jocul axiomelor"
						},
						{
							"_nr": "7",
							"__text": "Deductii formale"
						},
						{
							"_nr": "8",
							"__text": "Completitudine"
						},
						{
							"_nr": "9",
							"__text": "Calculul propozitiilor"
						},
						{
							"_nr": "10",
							"__text": "Calculul intuitionist al propozitiilor"
						},
						{
							"_nr": "11",
							"__text": "Calculul predicatelor"
						},
						{
							"_nr": "12",
							"__text": "Necalculabilitatea calculabilitatii"
						},
						{
							"_nr": "13",
							"__text": "Aritmetica standard si artitmetica non-standard"
						},
						{
							"_nr": "14",
							"__text": "scurta incursiune in istoria moderna a logicii"
						},
						{
							"_nr": "15",
							"__text": "Prima teorema de incompletitudine a lui Godel"
						},
						{
							"_nr": "16",
							"__text": "A doua teorema de incompletitudine a lui Godel"
						},
						{
							"_nr": "17",
							"__text": "Numere mari si foarte mari"
						},
						{
							"_nr": "18",
							"__text": "Incompletitudinea Godel in versiune informationala"
						},
						{
							"_nr": "19",
							"__text": "Incompletitudinea Godel in versiune diofantiana"
						},
						{
							"_nr": "20",
							"__text": "Incompletitudinea Godel in versiune axiomatica"
						},
						{
							"_nr": "21",
							"__text": "Lungimea demonstratiilor"
						},
						{
							"_nr": "22",
							"__text": "Problema continutului"
						},
						{
							"_nr": "23",
							"__text": "Probleme independente privind complexitatea calculului"
						},
						{
							"_nr": "24",
							"__text": "Matematici constructive si independenta"
						},
						{
							"_nr": "25",
							"__text": "In loc de concluzii"
						}
					]
				},
				"_id": "1224",
				"_isbn": ""
			},
			{
				"titlu": "Metode numerice in pseudocod. Aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Marilena Popa"
						},
						{
							"_nume": "Romulus Militaru"
						}
					]
				},
				"anAparitie": "2011",
				"editura": "Sitech",
				"numarPagini": "162",
				"pretOriginal": "163500",
				"pretCumparare": "163500",
				"coperta": {
					"imagine": {
						"_path": "Metode_numerice_in_pseudocod_Aplicatii_Popa.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Metoda Gauss, cu pivotare partiala la fiecare etapa, pentru rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "2",
							"__text": "Metoda Gauss, cu pivotare totala la fiecare etapa, pentru rezolvarea sistemelor de ecuatii liniare (cu evaluarea determinantului matricei date initial)"
						},
						{
							"_nr": "3",
							"__text": "Metoda Gauss, cu pivotare partiala la fiecare etapa, pentru inversarea matricilor"
						},
						{
							"_nr": "4",
							"__text": "Condensarea pivotata pentru calculul determinantilor"
						},
						{
							"_nr": "5",
							"__text": "Factorizarea LR a matricelor aplicata la rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "6",
							"__text": "Factorizarea LR a matricelor tridiagonale cu aplicare la rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "7",
							"__text": "Factorizarea LR a matricelor tridiagonale cu varfuri cu aplicare la rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "8",
							"__text": "Factorizarea LR a matricelor pentadiagonale cu aplicare la rezolvarea sistemelor de ecuatii liniare"
						},
						{
							"_nr": "9",
							"__text": "Metoda Jacobi pentru rezolvarea iterativa a sistemelor de ecuatii liniare"
						},
						{
							"_nr": "10",
							"__text": "Metoda Seidel-Gauss pentru rezolvarea iterativa a sistemelor de ecuatii liniare"
						},
						{
							"_nr": "11",
							"__text": "Metoda Seidel-Gauss pentru rezolvarea iterativa a sistemelor liniare cu matice slab populate"
						},
						{
							"_nr": "12",
							"__text": "Metoda Newton pentru rezolvarea numarica iterativa a ecuztiilor liniare"
						},
						{
							"_nr": "13",
							"__text": "Metoda aproximatiilor succesive pentru rezolvarea numerica iterativa a ecuatiilor si sistemelor de ecuatii neliniare"
						},
						{
							"_nr": "14",
							"__text": "Metoda Bairstow pentru rezolvarea ecuatiilor algebrice"
						},
						{
							"_nr": "15",
							"__text": "Metoda Leverrier pentru determinarea coeficientilor poliromului caracteristic"
						},
						{
							"_nr": "16",
							"__text": "Metoda Krylov pentru determinarea coeficientilor poliromului caracteristic"
						},
						{
							"_nr": "17",
							"__text": "Metoda Fadeev pentru determinarea coeficientilor poliromului caracteristic"
						},
						{
							"_nr": "18",
							"__text": "Metoda Danilevski pentru determinarea coeficientilor poliromului caracteristic prin aducerea unei matrice la forma normal Frobenius"
						},
						{
							"_nr": "19",
							"__text": "Metoda Danilevski pentru determinarea unui vector propriu corespunzator unei valori proprii"
						},
						{
							"_nr": "20",
							"__text": "Factorizarea LR pentru obtinerea valorilor proprii ale unei matrice"
						},
						{
							"_nr": "21",
							"__text": "Metoda iterativa de tip Newton pentru estimarea numerica a valorilor proprii extreme ale unei matrie reale simetrice"
						},
						{
							"_nr": "22",
							"__text": "Aproximarea functiilor prin interolare Lagrange"
						},
						{
							"_nr": "23",
							"__text": "Diferente divizate pe noduri simple"
						},
						{
							"_nr": "24",
							"__text": "Aproximarea functiilor prin interolare Newton"
						},
						{
							"_nr": "25",
							"__text": "Diferente divizate pe noduri multiple"
						},
						{
							"_nr": "26",
							"__text": "Polirom de interpolare Hermite"
						},
						{
							"_nr": "27",
							"__text": "Aproximarea functiilor prin sline cubic cu derivata a doua nula la extremitatile intervalului de aproximare"
						},
						{
							"_nr": "28",
							"__text": "Aproximarea functiilor prin sline cubic cu prima derivata egala cu prima derivata a functiei la extremitatile intervalului de aproximare"
						},
						{
							"_nr": "29",
							"__text": "Metoda celor mai mici patrate pentru aproximarea functiilor - cazul discret"
						},
						{
							"_nr": "30",
							"__text": "Metoda trapezului pentru evaluarea integralelor"
						},
						{
							"_nr": "31",
							"__text": "Metoda Simpson pentru evaluarea integralelor"
						},
						{
							"_nr": "32",
							"__text": "Metoda Newton pentru evaluarea integralelor"
						},
						{
							"_nr": "33",
							"__text": "Evaluarea numerica a integralelor duble pe domenii convexe de frontiera poligonala"
						},
						{
							"_nr": "34",
							"__text": "Metoda Euler pentru rezolvarea unei probleme Cauchy asociata unei ecuatii diferentiale ordinare"
						},
						{
							"_nr": "35",
							"__text": "Metoda Runge-Kutta de ordinul doi pentru rezolvarea unei probleme Cauchy asociata unei ecuatii diferentiale ordinare"
						},
						{
							"_nr": "36",
							"__text": "Metoda Euler pentru rezolvarea unei probleme Cauchy asociata unui sistem de doua ecuatii diferentiale ordinare"
						},
						{
							"_nr": "37",
							"__text": "Metoda Runge-Kutta de ordinul doi pentru rezolvarea unei probleme Cauchy asociata unui sistem de doua ecuatii diferentiale ordinare"
						}
					]
				},
				"_id": "1225",
				"_isbn": "978-606-11-1073-5"
			},
			{
				"titlu": "Initiere in Windows 98",
				"autori": {
					"autor": [
						{
							"_nume": "Gabriela Panait"
						},
						{
							"_nume": "Sorin Matei"
						},
						{
							"_nume": "Cristina Popescu"
						},
						{
							"_nume": "Daniela Staicu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Arves",
				"numarPagini": "148",
				"pretOriginal": "60000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "10",
					"_nume": "Initiere in calculatoare"
				},
				"redactori": {
					"redactor": "Mihaela Panait"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Windows_98_Panait.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Introducere in Windows"
						},
						{
							"_nr": "II",
							"__text": "Meniuri"
						},
						{
							"_nr": "III",
							"__text": "Structura unei ferestre de dialog"
						},
						{
							"_nr": "IV",
							"__text": "Obiecte in Windows. Gasire si selectarea lor"
						},
						{
							"_nr": "V",
							"__text": "Copierea si mutarea obiectelor"
						},
						{
							"_nr": "VI",
							"__text": "Personalizarea sistemului de operare Windows"
						},
						{
							"_nr": "VII",
							"__text": "Instalarea aplicatiilor sub Windows 98"
						},
						{
							"_nr": "VIII",
							"__text": "Accesorii Windows"
						},
						{
							"_nr": "IX",
							"__text": "Internet"
						},
						{
							"_nr": "X",
							"__text": "Internet Explorer"
						},
						{
							"_nr": "XI",
							"__text": "Standardul Plug and Play"
						},
						{
							"_nr": "XII",
							"__text": "Multimedia"
						},
						{
							"_nr": "XIII",
							"__text": "Retele"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Combinatii de taste specifice Windows 98"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Mic dictionar de termeni stiintifici"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Bibliografie"
						}
					]
				},
				"_id": "1226",
				"_isbn": "973-86204-9-X"
			},
			{
				"titlu": "Informatica industriala: noi paradigme si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Gheorghe Filip"
						},
						{
							"_nume": "Boldur Barbat"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Tehnica",
				"numarPagini": "440",
				"pretOriginal": "65400",
				"pretCumparare": "65400",
				"redactori": {
					"redactor": "Mihaela Chirosca"
				},
				"tehnoredactori": {
					"tehnoredactor": "Andreea Staicu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_industriala_Noi_paradigme_si_aplicatii_Filip.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Factori de influenta"
						},
						{
							"_nr": "2",
							"__text": "Orientare spre obiect"
						},
						{
							"_nr": "3",
							"__text": "Orientare spre agent"
						},
						{
							"_nr": "4",
							"__text": "Sinergie: intuitie, teorie, aplicatii"
						},
						{
							"_nr": "5",
							"__text": "Integrarea intreprinderii industriale"
						},
						{
							"_nr": "6",
							"__text": "Infrastructuri informatice pentru integrarea intreprinderii"
						},
						{
							"_nr": "7",
							"__text": "Sisteme suport pentru decizii in coordonarea proceselor industriale"
						},
						{
							"_nr": "8",
							"__text": "Interfete centrate pe om"
						}
					]
				},
				"_id": "1227",
				"_isbn": "973-31-1324-7"
			},
			{
				"titlu": "Initiere in JavaScript si tehnologiile Netscape",
				"autori": {
					"autor": {
						"_nume": "Dan Somnea"
					}
				},
				"anAparitie": "1998",
				"editura": "Tehnica",
				"numarPagini": "344",
				"pretOriginal": "60000",
				"pretCumparare": "87200",
				"redactori": {
					"redactor": "Margareta Chilim"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_JavaScript_si_tehnologiile_Netscape_Somnea.jpg"
					},
					"__text": "Margareta Chilim"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Universul Internet"
						},
						{
							"_nr": "2",
							"__text": "Limbajul pentru hipertexte. Elemente preliminare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul JavaScript. Primele elemente"
						},
						{
							"_nr": "4",
							"__text": "Obiectele limbajului JavaScript"
						},
						{
							"_nr": "5",
							"__text": "Obiectele specifice Netscape (client)"
						},
						{
							"_nr": "6",
							"__text": "Initiere in LiveConnect"
						},
						{
							"_nr": "7",
							"__text": "Initiere in LiveWire, LiveWire Pro si LivePayment"
						},
						{
							"_nr": "8",
							"__text": "Cateva exemple ilustrative"
						}
					]
				},
				"_id": "1228",
				"_isbn": "973-31-1229-1"
			},
			{
				"titlu": "Limbajul C# pentru incepatori: Mediul de programare Visual C# 2008. Depanarea programelor si tratarea erorilor; proprietati, tablouri si clasa Array (Vol. VI)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Negrescu"
						},
						{
							"_nume": "Lavinia Negrescu"
						}
					]
				},
				"anAparitie": "2010",
				"editura": "Albastra",
				"numarPagini": "200",
				"pretOriginal": "300000",
				"pretCumparare": "240000",
				"colectia": {
					"_numarul": "239",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C#_pentru_incepatori_Mediul_de_programare_Visual_C#_2008_Depanarea_programelor_si_tratarea_erorilor_proprietati_tablouri_si_clasa_Array_VI_Negrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Mediul de programare Visual Studio C# 2008"
						},
						{
							"_nr": "2",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "3",
							"__text": "Depanarea programelor"
						},
						{
							"_nr": "4",
							"__text": "Exceptiiole si tratarea erorilor"
						},
						{
							"_nr": "5",
							"__text": "Date constante"
						},
						{
							"_nr": "6",
							"__text": "Conversia datelor folosind metoda parse"
						},
						{
							"_nr": "7",
							"__text": "Proprietati"
						},
						{
							"_nr": "8",
							"__text": "Operatorii is, as si typeof"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea spatiilor de nume"
						},
						{
							"_nr": "10",
							"__text": "Tablouri"
						}
					]
				},
				"_id": "1229",
				"_isbn": "978-973-650-273-6"
			},
			{
				"titlu": "Limbajul C# pentru incepatori: Instructiunile limbajului C# (Vol. IV)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Negrescu"
						},
						{
							"_nume": "Lavinia Negrescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "168",
				"pretOriginal": "230000",
				"pretCumparare": "170000",
				"colectia": {
					"_numarul": "193",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C#_pentru_incepatori_Instructiunile_limbajului_C#_IV_Negrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": {
						"_nr": "1",
						"__text": "Instructiuni"
					}
				},
				"_id": "1230",
				"_isbn": "973-650-155-8"
			},
			{
				"titlu": "Limbajul C# pentru incepatori: Expresii (Vol. III)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Negrescu"
						},
						{
							"_nume": "Lavinia Negrescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "168",
				"pretOriginal": "230000",
				"pretCumparare": "170000",
				"colectia": {
					"_numarul": "192",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C#_pentru_incepatori_Expresii_III_Negrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": {
						"_nr": "1",
						"__text": "Expresii"
					}
				},
				"_id": "1231",
				"_isbn": "973-650-154-x"
			},
			{
				"titlu": "Limbajul C# pentru incepatori: Notiuni de baza (Vol. II)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Negrescu"
						},
						{
							"_nume": "Lavinia Negrescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "216",
				"pretOriginal": "270000",
				"pretCumparare": "210000",
				"colectia": {
					"_numarul": "191",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C#_pentru_incepatori_Notiuni_de_baza_II_Negrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Constructii de baza ale limbajului C#"
						},
						{
							"_nr": "2",
							"__text": "Realizarea programelor executabile si lansarea lor in executie"
						},
						{
							"_nr": "3",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "4",
							"__text": "Crearea si lansarea in executie a programelor C# su mediul de programarea Microsoft Visual Studio.NET"
						},
						{
							"_nr": "5",
							"__text": "Intrari-iesiri standard"
						}
					]
				},
				"_id": "1232",
				"_isbn": "973-650-153-1"
			},
			{
				"titlu": "Limbajul C# pentru incepatori: Notiuni introductive (Vol. I)",
				"autori": {
					"autor": [
						{
							"_nume": "Liviu Negrescu"
						},
						{
							"_nume": "Lavinia Negrescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Albastra",
				"numarPagini": "144",
				"pretOriginal": "200000",
				"pretCumparare": "150000",
				"colectia": {
					"_numarul": "190",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Limbajul_C#_pentru_incepatori_Notiuni_introductive_I_Negrescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "2",
							"__text": "Scheme logice"
						},
						{
							"_nr": "3",
							"__text": "Pseudo-cod"
						},
						{
							"_nr": "4",
							"__text": "Programare structurata"
						},
						{
							"_nr": "5",
							"__text": "Functii"
						},
						{
							"_nr": "6",
							"__text": "Organizarea datelor"
						}
					]
				},
				"_id": "1233",
				"_isbn": "973-650-152-3"
			},
			{
				"titlu": "Sisteme de operare: instalare, programare, utilizare Linux",
				"autori": {
					"autor": [
						{
							"_nume": "Costica Morosanu"
						},
						{
							"_nume": "Silviu Paval"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "274",
				"pretOriginal": "212600",
				"redactori": {
					"redactor": "Dana Lungu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Costica Morosanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_Instalare_programare_utilizare_Linux_Morosanu.jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Elemente de baza ale SO Linux"
						},
						{
							"_nr": "3",
							"__text": "Sistemele de fisiere in SO Linux"
						},
						{
							"_nr": "4",
							"__text": "Prelucrarea fisierelor text"
						},
						{
							"_nr": "5",
							"__text": "Procese si gestiunea memoriei"
						},
						{
							"_nr": "6",
							"__text": "Programare su shell-ul bash"
						},
						{
							"_nr": "7",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "8",
							"__text": "Pachete de programare"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Instalarea SO Mandrake Linux"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Sistemul de management al fisierelor"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Resurse Linux pe Internet"
						}
					]
				},
				"_id": "1234",
				"_isbn": "978-973-703-143-1"
			},
			{
				"titlu": "Dezvoltarea aplicatiilor orientate obiect pe platforma Java",
				"autori": {
					"autor": {
						"_nume": "Catalin Strimbei"
					}
				},
				"anAparitie": "2010",
				"editura": "Universitatii \"Alexandru Ioan Cuza\" Iasi",
				"numarPagini": "322",
				"pretOriginal": "290000",
				"redactori": {
					"redactor": "Elena Busca"
				},
				"coperta": {
					"imagine": {
						"_path": "Dezvoltarea_aplicatiilor_orientate_obiect_pe_platforma_Java_Strimbei.jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in universul obiectelor"
						},
						{
							"_nr": "2",
							"__text": "Proiectarea claselor"
						},
						{
							"_nr": "3",
							"__text": "Polimorfism si genericitate"
						},
						{
							"_nr": "4",
							"__text": "Model Driven Design:  constuirea unui model reutilizabil orientat obiect al afacerii"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea platformei Java pentru dezvoltarea aplicatiilor cu interfata grafica"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Modelul contabil. Clasele cu persistenta JPA"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Aplicatie JSF. Cod sursa formulare Web"
						}
					]
				},
				"_id": "1235",
				"_isbn": "978-973-703-143-1"
			},
			{
				"titlu": "C++ Builder in aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Mihai Oltean"
						},
						{
							"_nume": "Mircea Cocan"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "416",
				"pretOriginal": "62000",
				"pretCumparare": "100000",
				"colectia": {
					"_numarul": "79",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Cpp_Builder_in_aplicatii_Oltean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "IDE (Mediul de dezvoltare integrat)"
						},
						{
							"_nr": "3",
							"__text": "Elementele limbajului C++ Builder"
						},
						{
							"_nr": "4",
							"__text": "Object Pascal"
						},
						{
							"_nr": "5",
							"__text": "Legatura dintre C++ Builder si Delphi"
						},
						{
							"_nr": "6",
							"__text": "VCL (Visual Compoment Library)"
						},
						{
							"_nr": "7",
							"__text": "Ghidul dezvoltatorului de componente"
						}
					]
				},
				"_id": "1236",
				"_isbn": "973-9215-92-0"
			},
			{
				"titlu": "Netware 3.11, 2.12 - Utilitare si administrare (ed. II)",
				"autori": {
					"autor": {
						"_nume": "Razvan Ilie Orbu"
					}
				},
				"anAparitie": "1995",
				"editura": "Albastra",
				"tiraj": "2000",
				"numarPagini": "208",
				"pretOriginal": "5680",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "30",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Netware_3_11__2_12_Utilitare_si_administrare_ed_II_Orbu.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala a componiei Novell"
						},
						{
							"_nr": "2",
							"__text": "Filiere autorizate pentru promovarea serviciilor Novell"
						},
						{
							"_nr": "3",
							"__text": "Prezentarea generala a sistemelor Netware 2.2, 3.11, 4.0"
						},
						{
							"_nr": "4",
							"__text": "Tehnologii de interconectare"
						},
						{
							"_nr": "5",
							"__text": "Netware 3.11"
						},
						{
							"_nr": "6",
							"__text": "Sistemul de securizare a informatiei"
						},
						{
							"_nr": "7",
							"__text": "Conectarea la un File Server"
						},
						{
							"_nr": "8",
							"__text": "Comenzi si procese general accesibile"
						},
						{
							"_nr": "9",
							"__text": "Utilitarul Session"
						},
						{
							"_nr": "10",
							"__text": "Utilitarul Filer"
						},
						{
							"_nr": "11",
							"__text": "Utilitarul Syscon"
						},
						{
							"_nr": "12",
							"__text": "Utilitarul FConsole"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea imprimantelor in mediul Netware"
						},
						{
							"_nr": "14",
							"__text": "Arhivarea informatiei sub sistemul Netware"
						},
						{
							"_nr": "15",
							"__text": "Comenzi supervizor"
						},
						{
							"_nr": "16",
							"__text": "Instalarea unei statii de lucru"
						},
						{
							"_nr": "17",
							"__text": "Instarea server-ului Netware 3.11"
						},
						{
							"_nr": "18",
							"__text": "Monitorizarea functionarii server-ului"
						},
						{
							"_nr": "19",
							"__text": "Comenzi consola"
						},
						{
							"_nr": "20",
							"__text": "Administrarea server-ului de la distanta"
						},
						{
							"_nr": "21",
							"__text": "Descrierea conceptului de Name Services"
						},
						{
							"_nr": "22",
							"__text": "Produse de interconectare Netware"
						},
						{
							"_nr": "23",
							"__text": "Conceptul Open Data-Link Interface"
						},
						{
							"_nr": "24",
							"__text": "Solutii pentru integrarea mediilor Unix in retele Novell"
						},
						{
							"_nr": "25",
							"__text": "Produse pentru administrarea sistemelor Netware"
						},
						{
							"_nr": "26",
							"__text": "Evenimente care se pot manifesta in functionarea unei retele"
						}
					]
				},
				"_id": "1237",
				"_isbn": "973-9215-92-0"
			},
			{
				"titlu": "Initiere in programarea vizuala - Varianta Borland Delphi",
				"autori": {
					"autor": [
						{
							"_nume": "Tudor Sorin"
						},
						{
							"_nume": "Radu Boriga"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "L and S InfoMat",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "60000",
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_programarea_vizuala_Varianta_Borland_Delphi_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea mediului Delphi"
						},
						{
							"_nr": "2",
							"__text": "Butoane"
						},
						{
							"_nr": "3",
							"__text": "Editarea textelor"
						},
						{
							"_nr": "4",
							"__text": "Liste de selectie"
						},
						{
							"_nr": "5",
							"__text": "Meniuri"
						},
						{
							"_nr": "6",
							"__text": "Cutii de dialog"
						},
						{
							"_nr": "7",
							"__text": "Elemente de grafica"
						},
						{
							"_nr": "8",
							"__text": "Gruparea componentelor"
						},
						{
							"_nr": "9",
							"__text": "Evenimente"
						},
						{
							"_nr": "10",
							"__text": "Programarea multimedia"
						},
						{
							"_nr": "11",
							"__text": "Programarea aplicatiilor Internet"
						}
					]
				},
				"_id": "1238",
				"_isbn": "973-99377-7-2"
			},
			{
				"titlu": "Sistemul de operare Unix:utilizare si programare Shell",
				"autori": {
					"autor": {
						"_nume": "Razvan Daniel Zota"
					}
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "98",
				"pretOriginal": "37000",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Sistemul_de_operare_Unix_utilizare_si_programare_Shell_Zota.jpg"
					},
					"__text": "Claudia-Marinela Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "UNIX - caracteristici generale"
						},
						{
							"_nr": "2",
							"__text": "Shell-uri UNIX"
						}
					]
				},
				"_id": "1239",
				"_isbn": "973-594-275-5"
			},
			{
				"titlu": "Ghid practic pentru calculatoare personale",
				"autori": {
					"autor": {
						"_nume": "Victor Popescu"
					}
				},
				"anAparitie": "1997",
				"editura": "Recif",
				"numarPagini": "160",
				"pretOriginal": "12000",
				"pretCumparare": "40000",
				"coperta": {
					"imagine": {
						"_path": "Ghid_practic_pentru_calculatoare_personale_Popescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "2",
							"__text": "Sistemul de operare MS-DOS"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de operare MS Windows"
						},
						{
							"_nr": "4",
							"__text": "Prelucrare texte cu programul MS Word"
						},
						{
							"_nr": "5",
							"__text": "Prelucrare tabele cu programul MS Excel"
						},
						{
							"_nr": "6",
							"__text": "Sistemul de operare Windows 95"
						}
					]
				},
				"_id": "1240",
				"_isbn": "973-9179-43-6"
			},
			{
				"titlu": "Baze de date",
				"autori": {
					"autor": {
						"_nume": "Constantin Baron"
					}
				},
				"anAparitie": "2007",
				"editura": "Cartea Universitara",
				"numarPagini": "70",
				"pretOriginal": "0",
				"pretCumparare": "30000",
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_Baron.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Modulul I",
							"__text": "Concepte si notiuni fundamentale privind organizarea structurala a datelor si sisteme de gestiune a bazelor de date"
						},
						{
							"_nr": "Modulul II",
							"__text": "Gestiuena bazelor de date relationale in mediul de dezvoltare Microsoft - Access"
						},
						{
							"_nr": "Modulul III",
							"__text": "Gestiunea bazelor de date relationale cu obiecte macros si comenzi SQL"
						}
					]
				},
				"_id": "1241",
				"_isbn": "973-731-260-0"
			},
			{
				"titlu": "Metode de optimizare: algoritmi-programe",
				"autori": {
					"autor": {
						"_nume": "Ioan Dancea"
					}
				},
				"anAparitie": "1976",
				"editura": "Dacia",
				"tiraj": "8650+90+20",
				"numarPagini": "204",
				"pretOriginal": "10",
				"pretCumparare": "80000",
				"colectia": {
					"_numarul": "5",
					"_nume": "Informatica"
				},
				"redactori": {
					"redactor": "Mircea Stefan"
				},
				"tehnoredactori": {
					"tehnoredactor": "L. Hlavathy"
				},
				"coperta": {
					"imagine": {
						"_path": "Metode_de_optimizare_Dancea.jpg"
					},
					"__text": "Vasile Pop-Silaghi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Concepte fundamentale ale optimizarii"
						},
						{
							"_nr": "2",
							"__text": "Optimizarea neliniara fara restrictii"
						},
						{
							"_nr": "3",
							"__text": "Optimizarea neliniara cu restrictii"
						},
						{
							"_nr": "4",
							"__text": "Programarea liniara si probleme inrudite"
						},
						{
							"_nr": "5",
							"__text": "Subprograme pentru optimizare aflate in biblioteca matematica a calculatorului Felix C-256"
						}
					]
				},
				"_id": "1242",
				"_isbn": ""
			},
			{
				"titlu": "Ghidul programatorulyi Fortran IV pentru sistemul de operare pe disc IBM\\360",
				"autori": {
					"autor": {
						"_nume": "V.I. Rosca"
					}
				},
				"anAparitie": "1971",
				"editura": "ASE",
				"numarPagini": "194",
				"pretOriginal": "10.50",
				"pretCumparare": "60000",
				"coperta": {
					"imagine": {
						"_path": "Ghidul_programatorului_Fortran_IV_pentru_sistemul_de_operare_pe_disc_IBM_360_Rosca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Comenzi de control"
						},
						{
							"_nr": "3",
							"__text": "Construirea programelor Fortran"
						},
						{
							"_nr": "4",
							"__text": "Biblioteca Fortran"
						},
						{
							"_nr": "5",
							"__text": "Prelucrarea programelor Fortran"
						},
						{
							"_nr": "6",
							"__text": "Tipuri de joburi"
						},
						{
							"_nr": "7",
							"__text": "Diagnostice ajutatoare"
						},
						{
							"_nr": "8",
							"__text": "Linkage editorul"
						},
						{
							"_nr": "9",
							"__text": "Intrari iesiri"
						},
						{
							"_nr": "10",
							"__text": "Prelucrarea etichetelor"
						},
						{
							"_nr": "11",
							"__text": "Sugestii de programare"
						},
						{
							"_nr": "12",
							"__text": "Iesirea sistemului"
						},
						{
							"_nr": "Anexa A",
							"__text": "Mesaje de diagnosticare"
						},
						{
							"_nr": "Anexa B",
							"__text": "Eticheta standard de fisier pe DISC - Format 1"
						},
						{
							"_nr": "Anexa C",
							"__text": "Eticheta standard de fisier pe bada magnetica"
						},
						{
							"_nr": "Anexa E",
							"__text": "Suprograme Assembler"
						},
						{
							"_nr": "Anexa E",
							"__text": "Multiprogramarea DOS"
						},
						{
							"_nr": "Anexa F",
							"__text": "Suprograme de biblioteca"
						}
					]
				},
				"_id": "1243",
				"_isbn": ""
			},
			{
				"titlu": "Gestionarea memoriei pentru toti",
				"autori": {
					"autor": {
						"_nume": "Doug Lowe"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "248",
				"pretOriginal": "12000",
				"pretCumparare": "50000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "136"
				},
				"traducere": {
					"titluOriginal": "Memory Management For Dummies",
					"editura": "IDGBooks Worldwide",
					"traducatori": {
						"traducator": "Mihaela Stefan"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Cristian Mihai"
				},
				"tehnoredactori": {
					"tehnoredactor": "Alin Dragomir"
				},
				"coperta": {
					"imagine": {
						"_path": "Gestionarea_memoriei_pentru_toti_Lowe.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Gestionarea memoriei"
						},
						{
							"_nr": "2",
							"__text": "Obiective generale in gestionarea memoriei"
						},
						{
							"_nr": "3",
							"__text": "Modele de fisierre CONFIG.SYS si AUTOEXEC.BAT"
						},
						{
							"_nr": "4",
							"__text": "Ghidul comenzilor pentru optimizarea memoriei"
						},
						{
							"_nr": "5",
							"__text": "Rezolvarea defectiunilor"
						},
						{
							"_nr": "6",
							"__text": "Memorie suplimentara"
						},
						{
							"_nr": "7",
							"__text": "Dictionar de termini specifici"
						}
					]
				},
				"_id": "1244",
				"_isbn": "973-601-371-5"
			},
			{
				"titlu": "Netware (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Galen Grimes"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "136",
				"pretOriginal": "7000",
				"colectia": {
					"_numarul": "80",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 minute guide to Netware",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": "Adrian Popescu"
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Netware_in_lectii_de_10_minute_Grimes.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Conectarea si deconectarea de la reteua NetWare"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Notiuni elementare despre sistemul NetWare"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Automatizarea pricedurii de conectare"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Crearea unui fisier cu comenzi pentru conectare"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Utilizarea imprimantelor de retea - parte I"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Informatii suplimentare despre utilizarea imprimantelor de retea"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Conectarea la imprimantele de retea prin intermediul fisierului cu comenzi pentru conectare"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Trimiterea mesajelor in retea"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Controlul primirii mesajelor"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Folosirea mai multor servere"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Utilizarea programului utilitara SESSION"
						},
						{
							"_nr": "Lectia 12",
							"__text": "Utilizarea programului SESSION pentru atasarea la discurile de retea"
						},
						{
							"_nr": "Lectia 13",
							"__text": "Utilizarea programului SESSION pentru controlul sarcinilor individuale si colective"
						},
						{
							"_nr": "Lectia 14",
							"__text": "Intelegerea drepturilor de acces"
						},
						{
							"_nr": "Lectia 15",
							"__text": "Lucrul cu cataloage"
						},
						{
							"_nr": "Lectia 16",
							"__text": "Cateva comenzi pentru utilizatori"
						},
						{
							"_nr": "Lectia 17",
							"__text": "Imbunatirea si tiparirea unui graf"
						},
						{
							"_nr": "Lectia 18",
							"__text": "Folosirea utilitarului FILER - partea I"
						},
						{
							"_nr": "Lectia 19",
							"__text": "Folosirea utilitarului FILER - partea II"
						},
						{
							"_nr": "Lectia 20",
							"__text": "Utilizarea facilitatilor sistemului explicativ NetWare"
						},
						{
							"_nr": "Lectia 21",
							"__text": "Imbunatatirea performatelor statiei de lucru"
						},
						{
							"_nr": "Anexa A",
							"__text": "Initiere in retele de calculatoare"
						},
						{
							"_nr": "Anexa B",
							"__text": "Initiere in sistemul de operare DOS"
						}
					]
				},
				"_id": "1245",
				"_isbn": "973-601-293-X"
			},
			{
				"titlu": "Curs rapid Microsoft FronPage 2000",
				"autori": {
					"autor": {
						"_nume": "[Microsoft Corporation]"
					}
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "136",
				"pretOriginal": "95000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "350",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Quick Course in Microsoft FrontPage 2000",
					"editura": "Microsoft Corporation",
					"traducatori": {
						"traducator": "Nicolae Ionescu-Crutan"
					},
					"_an": "1999",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Curs_rapid_Microsoft_FronPage_2000_Microsoft_Corporation.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Notiuni de baza"
						},
						{
							"_nr": "1",
							"__text": "Crearea unui sit de web simplu"
						},
						{
							"_nr": "2",
							"__text": "Imbunatatirea aspectului Web-ului"
						},
						{
							"_nr": "3",
							"__text": "Publicarea sitului in Web"
						},
						{
							"_nr": "Partea II",
							"__text": "Dezvoltarea cunostintelor"
						},
						{
							"_nr": "4",
							"__text": "Crearea unui web mai complex"
						},
						{
							"_nr": "5",
							"__text": "Introducerea de efecte speciale"
						},
						{
							"_nr": "6",
							"__text": "Intretinerea si actualizarea unui web"
						}
					]
				},
				"_id": "1246",
				"_isbn": "973-20-0307-3"
			},
			{
				"titlu": "Curs rapid Microsoft Internet Explorer 4",
				"autori": {
					"autor": {
						"_nume": "[Microsoft Corporation]"
					}
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "216",
				"pretOriginal": "35000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "296",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Quick Course in Microsoft Internet Explorer 4",
					"editura": "Microsoft Corporation",
					"traducatori": {
						"traducator": "Beatrice Balasa"
					},
					"_an": "1997",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Curs_rapid_Microsoft_Internet_Explorer_4_Microsoft_Corporation.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Studierea elementelor fundamentale"
						},
						{
							"_nr": "1",
							"__text": "Introducere in Internet Explorer 4"
						},
						{
							"_nr": "2",
							"__text": "Gasirea informatiilor in Web"
						},
						{
							"_nr": "3",
							"__text": "Comunicarea cu alti utilizatori"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Obtinerea performantelor"
						},
						{
							"_nr": "4",
							"__text": "Personalizarea programului Internet Explorer"
						},
						{
							"_nr": "5",
							"__text": "Alte cai de comunicare"
						},
						{
							"_nr": "6",
							"__text": "Particiaparea la grupurile de informare"
						}
					]
				},
				"_id": "1247",
				"_isbn": "973-20-0307-3"
			},
			{
				"titlu": "Practica optimizarii aplicatiilor informatice",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Ivan"
						},
						{
							"_nume": "Catalin Boja"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "ASE",
				"numarPagini": "484",
				"pretOriginal": "173000",
				"pretCumparare": "50000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Nica"
				},
				"coperta": {
					"imagine": {
						"_path": "Practica_optimizarii_aplicatiilor_informatice_Ivan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Concepte de baza"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de aplicatii informatice"
						},
						{
							"_nr": "4",
							"__text": "Criterii de optim"
						},
						{
							"_nr": "5",
							"__text": "Optimizare pe text sursa"
						},
						{
							"_nr": "6",
							"__text": "Optimizarea in procesul de compilare"
						},
						{
							"_nr": "7",
							"__text": "Optimizare prin simulare"
						},
						{
							"_nr": "8",
							"__text": "Utilizare algoritmilor genetici si a retelelor neuronale"
						},
						{
							"_nr": "9",
							"__text": "Rezultate experimentale"
						},
						{
							"_nr": "10",
							"__text": "Optimizarea calitatii"
						},
						{
							"_nr": "11",
							"__text": "Optimizarea stocarii in domeniul datelor"
						},
						{
							"_nr": "12",
							"__text": "Optimizarea regasirii informatiei"
						},
						{
							"_nr": "13",
							"__text": "Managementul optimizarii proiectelor TIC"
						},
						{
							"_nr": "14",
							"__text": "Optimizarea aplicatiilor web"
						},
						{
							"_nr": "15",
							"__text": "Concluzii"
						}
					]
				},
				"_id": "1248",
				"_isbn": "978-973-594-932-7"
			},
			{
				"titlu": "Sisteme informatice executive",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Adela Bara"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "ASE",
				"numarPagini": "288",
				"pretOriginal": "243000",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Stefania Creanga"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_executive_Lungu.jpg"
					},
					"__text": "Luiza Constantinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Managementul strategic al organizatiei"
						},
						{
							"_nr": "2",
							"__text": "Solutii de sisteme informatice pentru managementul strategic"
						},
						{
							"_nr": "3",
							"__text": "Solutii de organizare a datelor in depozite de date (Datawarehouses)"
						},
						{
							"_nr": "4",
							"__text": "Solutii de anazliza a datelor - Tehnologia OLAP (On-line Analytical Processing)"
						},
						{
							"_nr": "5",
							"__text": "Solutii de extragere a cunostintelor din date (Data Mining)"
						},
						{
							"_nr": "6",
							"__text": "Solutii de extragere si optimizare a cererilor de regasire din depozitele de date"
						},
						{
							"_nr": "7",
							"__text": "Solutii de dezvoltare a sistemelor informatice executive"
						},
						{
							"_nr": "8",
							"__text": "Metodologii de realizare a sistemelor informatice executive"
						},
						{
							"_nr": "9",
							"__text": "Solutii de tehnologii si instrumente disponibile care pot fi utilizate in realizarea sistemelor informatice executive"
						},
						{
							"_nr": "10",
							"__text": "Propunearea si realizarea unei solutii de sitem informatic executiv in cadrul unei companii nationale"
						}
					]
				},
				"_id": "1249",
				"_isbn": "978-973-594-690-6"
			},
			{
				"titlu": "Sisteme informatice pentru instruire si cercetare in domeniul juridic economic",
				"autori": {
					"autor": {
						"_nume": "Cristian Uscatu"
					}
				},
				"anAparitie": "2007",
				"editura": "ASE",
				"numarPagini": "220",
				"pretOriginal": "368500",
				"pretCumparare": "20000",
				"tehnoredactori": {
					"tehnoredactor": "Violeta Rogojan"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_pentru_instruire_si_cercetare_in_domeniul_juridic_economic_Uscatu.jpg"
					},
					"__text": "Violeta Rogojan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Procese de instuire"
						},
						{
							"_nr": "2",
							"__text": "Tehnologia informatiei si comunicatiilor in educatie"
						},
						{
							"_nr": "3",
							"__text": "Instruire asistata de calculator"
						},
						{
							"_nr": "4",
							"__text": "Baze de date juridice pentru instuire si cercetare"
						},
						{
							"_nr": "5",
							"__text": "Proiect de ansamblu pentru un sistem de instruire si cercetare in domeniul juridic-economic"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Scheme conceptuale a bazei de date juridice"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Lista abrevierilor folosite si acronimelor"
						}
					]
				},
				"_id": "1250",
				"_isbn": "978-606-505-000-6"
			},
			{
				"titlu": "Excel pentru Windows 95 in 503 imagini",
				"autori": {
					"autor": {
						"_nume": "Catht Kenny"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "224",
				"pretOriginal": "14000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "132",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Excel for Windows 95 Visual Quick Reference",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Lian Claudiu Sebe"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Excel_pentru_Windows_95_in_503_imagini_Kenny.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Operatii de baza: introducerea datelor, anularea comenzilor, selectare, pozitionare"
						},
						{
							"_nr": "2",
							"__text": "Grafice: creare, modificare, formatare, adaptare la cerinte"
						},
						{
							"_nr": "3",
							"__text": "Randuri si coloane: inserare, stergere, dimensionare, ascundere"
						},
						{
							"_nr": "4",
							"__text": "Modificarea datelor: copiere, lipire, verificarea ortografiei, protejare"
						},
						{
							"_nr": "5",
							"__text": "Administrarea fisierelor: salvare, deschidere, sabloane, cautare"
						},
						{
							"_nr": "6",
							"__text": "Formantarea: numere, fonturi, margini, tipare, stiluri"
						},
						{
							"_nr": "7",
							"__text": "Formule si functii: instroducere, copiere"
						},
						{
							"_nr": "8",
							"__text": "Imagini grafice: desenarea obiectelor, utilizarea ilustratiilor, harti"
						},
						{
							"_nr": "9",
							"__text": "Sistemul Help: utilizarea sistemului Help on-line, utilizarea facilitatilor TipWizard"
						},
						{
							"_nr": "10",
							"__text": "Interfata: bara cu instrumente, afisarea foii de calcul"
						},
						{
							"_nr": "11",
							"__text": "Liste: creare, sortare, interogare"
						},
						{
							"_nr": "12",
							"__text": "Macro-uri: intregitrare, rulare, modificare"
						},
						{
							"_nr": "13",
							"__text": "Tiparirea: vizualizarea prealabila, tiparirea, stabilirea parametrilor paginii"
						},
						{
							"_nr": "14",
							"__text": "Grupuri de celule: generarea valorilor, denumire, transpunere"
						},
						{
							"_nr": "15",
							"__text": "Foi de calcul: inserare, stergere, copiere, mutare"
						}
					]
				},
				"_id": "1251",
				"_isbn": "973-601-457-6"
			},
			{
				"titlu": "Baze de date prin exemple",
				"autori": {
					"autor": {
						"_nume": "Manole Velicanu"
					}
				},
				"anAparitie": "2007",
				"editura": "ASE",
				"numarPagini": "484",
				"pretOriginal": "247000",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Luiza Constantinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Emilia Velcu"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_prin_exemple_Velicanu.jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sisteme de baza de date(SBD). Aspecte fundamentale"
						},
						{
							"_nr": "2",
							"__text": "Introducere in bazs de date(BD)"
						},
						{
							"_nr": "3",
							"__text": "Realizarea bazelode de date. Aspecte metodologice"
						},
						{
							"_nr": "4",
							"__text": "Baze de date relationale (BDR)"
						},
						{
							"_nr": "5",
							"__text": "Baze de date orientate obiect - BDOO"
						},
						{
							"_nr": "6",
							"__text": "Baze de date distribuite - BDD"
						},
						{
							"_nr": "7",
							"__text": "Visual FOXPRO - VFP"
						}
					]
				},
				"_id": "1252",
				"_isbn": "978-973-594-918-1"
			},
			{
				"titlu": "Integrarea sistemelor informatice",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Lungu"
						},
						{
							"_nume": "Ana-Ramona Bologa"
						},
						{
							"_nume": "Vlad Diaconita"
						},
						{
							"_nume": "Adela Bara"
						},
						{
							"_nume": "Iuliana Botha"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "ASE",
				"numarPagini": "302",
				"pretOriginal": "344000",
				"pretCumparare": "50000",
				"tehnoredactori": {
					"tehnoredactor": "Carmen Stefania Creanga"
				},
				"coperta": {
					"imagine": {
						"_path": "Integrarea_sistemelor_informatice_Lungu.jpg"
					},
					"__text": "Luiza Constantinescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Aspecte teoretice despre integrarea aplicatiilor informatice"
						},
						{
							"_nr": "2",
							"__text": "Studiul tehnologiilor informatice de integrare a datelor"
						},
						{
							"_nr": "3",
							"__text": "Analiza comparativa a diferitelor instrumente utilizate pentru integrarea datelor"
						},
						{
							"_nr": "4",
							"__text": "Studiul tehnologiilor informatice de integrare a aplicatiilor"
						},
						{
							"_nr": "5",
							"__text": "Analiza comparativa a diferitelor instrumente utilizate pentru integrarea aplicatiilor"
						},
						{
							"_nr": "6",
							"__text": "Managementul proiectelor de implementare a solutiilor integrate de tip ERP"
						},
						{
							"_nr": "7",
							"__text": "Visual FOXPRO - VFP"
						}
					]
				},
				"_id": "1253",
				"_isbn": "978-973-594-975-4"
			},
			{
				"titlu": "Utilizare Microsoft Office pentru Windows 95, editie speciala",
				"autori": {
					"autor": [
						{
							"_nume": "Rick Winter"
						},
						{
							"_nume": "Patty Winter"
						},
						{
							"_nume": "Jeff Bankston"
						},
						{
							"_nume": "Robert Garrison"
						},
						{
							"_nume": "Carmen Minarik"
						},
						{
							"_nume": "Donna Minarik"
						},
						{
							"_nume": "Patrice-Anne Rutledge"
						},
						{
							"_nume": "Diane Tinney"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "896",
				"pretOriginal": "50000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "206",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Special Edition Using Microsoft Office for Windows 95",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Nicolae Ionescu-Crutan",
							"Bogdan Constantin",
							"Rodica Bitoiu"
						]
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "1",
					"_an": "1995"
				},
				"redactori": {
					"redactor": "Mihaela Tarpa"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Cristian Matache",
						"Manuela Matache"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizare_Microsoft_Office_pentru_Windows_95_Winter.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Descoperirea caracteristicilor de baza"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Utilizarea programului Word"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Utilizarea programului Excel"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Utilizarea elementelor multimedia in Microsoft Office"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Utilizarea aplicatiei PowerPoint"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Lucrul cu aplicatiile Microsoft"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Personalizarea pachetului Microsoft Office"
						},
						{
							"_nr": "Partea a VIII-a",
							"__text": "Utilizarea pachetului Microsoft Office intr-un grup"
						},
						{
							"_nr": "Partea a IX-a",
							"__text": "Tehnicile profesionistilor"
						}
					]
				},
				"_id": "1254",
				"_isbn": "973-601-498-3"
			},
			{
				"titlu": "FoxPro 2.6 pentru Windows - Ghidul programatorului",
				"autori": {
					"autor": {
						"_nume": "Jeb Long"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "1152",
				"pretOriginal": "60000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "144",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "FoxPro 2.6 for Windows Developer's Guide",
					"editura": "SAMS Publishing",
					"traducatori": {
						"traducator": [
							"Cristina Botez",
							"Bogdan Constantin",
							"Dana Soloveanu",
							"Mihaela Tarpa",
							"Vladimir Visan"
						]
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "1",
					"_an": "1994"
				},
				"redactori": {
					"redactor": "Mihaela Tarpa"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Cristian Matache",
						"Manuela Matache"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "FoxPro_2_6_pentru_Windows_Ghidul_programatorului_Long.jpg"
					},
					"__text": "Mihai Nasta"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Prezentare si concepte generale"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Limbajul FoxPro"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Instrumente de lucru pentru cresterea productivitatii"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1255",
				"_isbn": "973-601-368-5"
			},
			{
				"titlu": "Manual complet pentru PC",
				"autori": {
					"autor": [
						{
							"_nume": "Joe Kraynak"
						},
						{
							"_nume": "Lisa Bucki"
						},
						{
							"_nume": "Gordon McComb"
						},
						{
							"_nume": "Paul McFedries"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "512",
				"pretOriginal": "40000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "151",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The Big Basics Book of PCs",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Lucia Elena Popescu"
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "1",
					"_an": "1995"
				},
				"coperta": {
					"imagine": {
						"_path": "Manual_complet_pentru_PC_Kraynak.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Sectiunea 1",
							"__text": "Cum sa"
						},
						{
							"_nr": "1",
							"__text": "Cumparati si sa configurati un calculator"
						},
						{
							"_nr": "2",
							"__text": "Lucrati cu calculatorul dumneavoastra"
						},
						{
							"_nr": "3",
							"__text": "Intelegeti modul in care calculatorul stocheaza fisierele"
						},
						{
							"_nr": "4",
							"__text": "Ne descurcam in programele mediului Windows 3.11"
						},
						{
							"_nr": "5",
							"__text": "Organizati fisierele si cataloagele cu ajutorul mediului Windows 3.11"
						},
						{
							"_nr": "6",
							"__text": "Gasiti si sa afisati fisierele si multe altele in mediul Windows 3.11"
						},
						{
							"_nr": "7",
							"__text": "Lucrati in mediul Winsows 95"
						},
						{
							"_nr": "8",
							"__text": "Lucrul in sistemul DOS (Disk Operating System)"
						},
						{
							"_nr": "9",
							"__text": "Va conectati cu lumea exterioara utilizand retelele BBS si Internet"
						},
						{
							"_nr": "Sectiunea 2",
							"__text": "Pastrati-va calculatorul curat"
						},
						{
							"_nr": "10",
							"__text": "Instalarea programelor noi"
						},
						{
							"_nr": "11",
							"__text": "Faceti calculatorul dvs. sa lucreze repede"
						},
						{
							"_nr": "12",
							"__text": "Instalarea elemetelor noi de hard"
						},
						{
							"_nr": "13",
							"__text": "Pastrati-va datele in siguranta"
						},
						{
							"_nr": "Sectiunea 3",
							"__text": "101 solutii rapide"
						},
						{
							"_nr": "14",
							"__text": "Tabelul cautarului grabit"
						},
						{
							"_nr": "15",
							"__text": "Ghidul cumparatorului de soft"
						}
					]
				},
				"_id": "1256",
				"_isbn": "973-601-545-9"
			},
			{
				"titlu": "Mastering Oracle Power Object",
				"autori": {
					"autor": [
						{
							"_nume": "Rick Greenwald"
						},
						{
							"_nume": "Robert Hoskin"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "O Reilly and Associates",
				"numarPagini": "494",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Mastering_Oracle_Power_Objects_Greenwald.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Overview"
						},
						{
							"_nr": "1",
							"__text": "Introducing Oracle Power Objects"
						},
						{
							"_nr": "II",
							"__text": "OPO and Data"
						},
						{
							"_nr": "2",
							"__text": "Client-Side Data Issues"
						},
						{
							"_nr": "3",
							"__text": "Server-Side Data Issues"
						},
						{
							"_nr": "4",
							"__text": "Moving Data"
						},
						{
							"_nr": "III",
							"__text": "Global Issues"
						},
						{
							"_nr": "5",
							"__text": "Built-In Methods"
						},
						{
							"_nr": "6",
							"__text": "Global Variables, Functions, and Menus"
						},
						{
							"_nr": "7",
							"__text": "Object Classes and Libraries"
						},
						{
							"_nr": "IV",
							"__text": "Creating Real Applications"
						},
						{
							"_nr": "8",
							"__text": "Lists"
						},
						{
							"_nr": "9",
							"__text": "Reports"
						},
						{
							"_nr": "10",
							"__text": "Implementing Drag and Drop"
						},
						{
							"_nr": "11",
							"__text": "Compiling and Debugging Applications"
						},
						{
							"_nr": "V",
							"__text": "OPO and Beyond"
						},
						{
							"_nr": "12",
							"__text": "PL/SQL"
						},
						{
							"_nr": "13",
							"__text": "Integrating the Web"
						},
						{
							"_nr": "Appendix",
							"__text": "OPO Questions and Answers"
						}
					]
				},
				"_id": "1257",
				"_isbn": "1-56592-239-5"
			},
			{
				"titlu": "Firewalls: Protejarea sistemelor Linux",
				"autori": {
					"autor": {
						"_nume": "Robert L. Ziegler"
					}
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "440",
				"pretOriginal": "390000",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "408",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Linux Firewalls",
					"editura": "New Rider Press",
					"traducatori": {
						"traducator": [
							"Cristian Mocanu",
							"Florin Mocanu"
						]
					},
					"_limba": "engleza",
					"_tara": "S.U.A.",
					"_editia": "1",
					"_an": "1999"
				},
				"coperta": {
					"imagine": {
						"_path": "Firewalls_Protejarea_sistemelor_Linux_Ziegler.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Consideratii preliminare"
						},
						{
							"_nr": "1",
							"__text": "Concepte preliminare ce stau la baza firewall-urilor cu filtrarea pachetelor"
						},
						{
							"_nr": "II",
							"__text": "Filtrarea pachetelor si masuri de securitate elementare"
						},
						{
							"_nr": "2",
							"__text": "Concepte referitoare la filtrarea pachetelor"
						},
						{
							"_nr": "3",
							"__text": "Construirea si instalarea unui sistem firewall"
						},
						{
							"_nr": "4",
							"__text": "aspecte privind retele locale, firewall-uri multiple si retele de perimetru"
						},
						{
							"_nr": "5",
							"__text": "Depanarea regulilor pentru firewall"
						},
						{
							"_nr": "III",
							"__text": "Securitatea si monitorizare la nivel de sistem"
						},
						{
							"_nr": "6",
							"__text": "Cum verificati daca sistemul ruleaza asa cum doriti"
						},
						{
							"_nr": "7",
							"__text": "Probleme la nivelul administrarii de sisteme UNIX"
						},
						{
							"_nr": "8",
							"__text": "Detectarea intruziunilor si raportarea incidentelor"
						},
						{
							"_nr": "IV",
							"__text": "Anexe"
						},
						{
							"_nr": "A",
							"__text": "Exemple de securitate"
						},
						{
							"_nr": "B",
							"__text": "Exemple de firewall-uri si scripturi de suport"
						},
						{
							"_nr": "C",
							"__text": "Glosar"
						}
					]
				},
				"_id": "1258",
				"_isbn": "973-20-0605-6"
			},
			{
				"titlu": "Norton Commander 5.0",
				"autori": {
					"autor": {
						"_nume": "Mariana Milosescu"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "296",
				"pretOriginal": "23000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "210",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Norton_Commander_5_0_Milosescu.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Prezentarea mediului de lucru Norton Commander"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Lucrul cu mouse-ul"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Configurarea interfetei mediului de lucru Norton Commander"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Tehnica autodocumentarii"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Istoricul comenzilor MS-DOS"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Operatii cu discul (1)"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Operatii cu directoare (1)"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Operatii cu directoare (2)"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Operatii cu directoare (3)"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Operatii de informare"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Operatii cu fisiere (1)"
						},
						{
							"_nr": "Lectia 12",
							"__text": "Operatii cu fisiere (2)"
						},
						{
							"_nr": "Lectia 13",
							"__text": "Operatii cu fisiere (3)"
						},
						{
							"_nr": "Lectia 14",
							"__text": "Operatii cu fisiere (4)"
						},
						{
							"_nr": "Lectia 15",
							"__text": "Operatii cu discul (2)"
						},
						{
							"_nr": "Lectia 16",
							"__text": "Asocierea fisierelor la aplicatii si crearea meniurilor"
						}
					]
				},
				"_id": "1259",
				"_isbn": "973-601-550-5"
			},
			{
				"titlu": "Retele Windows. Servere si clienti. Exemple practice",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Munteanu"
						},
						{
							"_nume": "Valerica Greavu Serban"
						},
						{
							"_nume": "Gabriel Cristescu"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Polirom",
				"numarPagini": "344",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"redactori": {
					"redactor": "Daniel Chitoi"
				},
				"coperta": {
					"imagine": {
						"_path": "Retele_Windows_Servere_si_clienti_Exemple_practice_Munteanu.jpg"
					},
					"__text": "Ionut Brostianu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Arhitectura Windows XP/Server 2003"
						},
						{
							"_nr": "2",
							"__text": "Computer Management"
						},
						{
							"_nr": "3",
							"__text": "Securizarea clientilor"
						},
						{
							"_nr": "4",
							"__text": "Winsows Server 2003 DNS"
						},
						{
							"_nr": "5",
							"__text": "Active Directory"
						},
						{
							"_nr": "6",
							"__text": "Internet Security and Acceleration Server 2004"
						},
						{
							"_nr": "7",
							"__text": "Internet Informantion Server"
						},
						{
							"_nr": "8",
							"__text": "SQL Server"
						},
						{
							"_nr": "9",
							"__text": "Terminal Server"
						},
						{
							"_nr": "10",
							"__text": "Exchange 2003 Enterprise"
						},
						{
							"_nr": "11",
							"__text": "DHCP"
						},
						{
							"_nr": "12",
							"__text": "Office Live Communication Server 2003"
						},
						{
							"_nr": "13",
							"__text": "SharePoint"
						},
						{
							"_nr": "14",
							"__text": "Evaluarea securitatii retelei"
						}
					]
				},
				"_id": "1260",
				"_isbn": "973-681-457-2"
			},
			{
				"titlu": "Dictionar P.C.",
				"autori": {
					"autor": {
						"_nume": "Philip E. Margolis"
					}
				},
				"anAparitie": "1997",
				"editura": "Nemira",
				"numarPagini": "368",
				"pretOriginal": "40000",
				"pretCumparare": "50000",
				"traducere": {
					"titluOriginal": "Personal Computer Dictionary",
					"editura": "Philip E. Margolis",
					"traducatori": {
						"traducator": "Gabriel Gheorghiu"
					},
					"_an": "1996",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": ""
				},
				"tehnoredactori": {
					"tehnoredactor": "Gabriel Gheorghiu"
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionar_PC_Margolis.jpg"
					},
					"__text": "Dan Alexandru Ionescu"
				},
				"cuprins": {
					"capitol": {
						"_nr": "-"
					}
				},
				"_id": "1261",
				"_isbn": "973-9301-61-8"
			},
			{
				"titlu": "Bazele Access 95",
				"autori": {
					"autor": [
						{
							"_nume": "Allen Browne"
						},
						{
							"_nume": "Alison Balter"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "648",
				"pretOriginal": "32000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "154",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Essential ACCESS 95",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": [
							"Vladimir Visan",
							"Cristian Mocanu"
						]
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"coperta": {
					"imagine": {
						"_path": "Bazele_Access_95_Browne.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Crearea unei baze de date"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Extragerea datelor"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Modelul orientat pe evenimente"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Programarea in VBA (Visual Basic pentru aplicatii)"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Tehnici avansate"
						}
					]
				},
				"_id": "1262",
				"_isbn": "973-601-549-1"
			},
			{
				"titlu": "Windows 95 in 373 imagini",
				"autori": {
					"autor": {
						"_nume": "Michael Watson"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "208",
				"pretOriginal": "15000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "169",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Windows 95 Visual Quick Reference",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Anca Petrescu",
							"Silviu Petrescu"
						]
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_95_in_373_imagini_Watson.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere: Cum se foloseste aceasta carte"
						},
						{
							"_nr": "2",
							"__text": "Accesorii: Aplicatiilor Calculator, Notepad, Paint si WordPad"
						},
						{
							"_nr": "3",
							"__text": "Notiuni de baza: Pornire/iesire, elementele ecranului, mouse"
						},
						{
							"_nr": "4",
							"__text": "Comunicatii: Aplicatii HyperTerminal, Phone Dialer si Mail"
						},
						{
							"_nr": "5",
							"__text": "Organizare proprie: Ecrab, fonturi, parole, date"
						},
						{
							"_nr": "6",
							"__text": "Administrarea discului: Discuri, dosare, formatare"
						},
						{
							"_nr": "7",
							"__text": "Programe DOS: Comenzi si proprietati"
						},
						{
							"_nr": "8",
							"__text": "Echipament: Instalare si configurare"
						},
						{
							"_nr": "9",
							"__text": "Fisiere si dosare: Lucrul cu programul Explorer"
						},
						{
							"_nr": "10",
							"__text": "Sistemul Help: Obtinerea informatiilor privind functiile sistemului Windows"
						},
						{
							"_nr": "11",
							"__text": "Aplicatia Microsoft Exchange: Folosirea cutiei postale Inbox"
						},
						{
							"_nr": "12",
							"__text": "Microsoft Network: Reteaua MSN, sisteme de informatii BBS"
						},
						{
							"_nr": "13",
							"__text": "Multimedia: CD Audio, Sunet, Midi, Video"
						},
						{
							"_nr": "14",
							"__text": "Tiparirea: Tiparirea fisierelor si controlul imprimantelor"
						},
						{
							"_nr": "15",
							"__text": "Intretinerea sistemului: Utilitarele Backup, DriveSpace si Scandisk"
						}
					]
				},
				"_id": "1263",
				"_isbn": "973-601-678-1"
			},
			{
				"titlu": "Delphi 4",
				"autori": {
					"autor": {
						"_nume": "Tom Swan"
					}
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "768",
				"pretOriginal": "100000",
				"pretCumparare": "650000",
				"colectia": {
					"_numarul": "370",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Delphi 4 Bible",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": [
							"Florin Moraru",
							"Cristian Mocanu"
						]
					},
					"_an": "1998",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Delphi_4_Swan.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Introducere"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Interfata cu utilizatorul"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Aplicatiile"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Tehnici avansate"
						}
					]
				},
				"_id": "1264",
				"_isbn": "973-20-0290-5"
			},
			{
				"titlu": "Internet si pagini Web : manual pentru incepatori si initiati",
				"autori": {
					"autor": [
						{
							"_nume": "Marcel Andrei Homorodean"
						},
						{
							"_nume": "Irina Iosupescu"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Niculescu",
				"numarPagini": "192",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Internet_si_pagini_Web_manual_pentru_incepatori_si_initiati_Homorodean.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Navigarea pe Internet"
						},
						{
							"_nr": "3",
							"__text": "Posta electronica"
						},
						{
							"_nr": "4",
							"__text": "Alte servicii oferite de retaua Internet"
						},
						{
							"_nr": "5",
							"__text": "Realizarea paginilor web"
						},
						{
							"_nr": "6",
							"__text": "Imbunatatiri ale paginilor Web"
						},
						{
							"_nr": "7",
							"__text": "Exemplu de construire a unui site Web"
						},
						{
							"_nr": "8",
							"__text": "Dictionar de termini Internet"
						}
					]
				},
				"_id": "1265",
				"_isbn": "973-568-914-6"
			},
			{
				"titlu": "Initiere in Web Design",
				"autori": {
					"autor": [
						{
							"_nume": "Claudia Nicoleta Stefan"
						},
						{
							"_nume": "Razvan Dragoi"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "Arves",
				"numarPagini": "124",
				"pretOriginal": "70000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "13",
					"_nume": "Initiere in calculatoare"
				},
				"coperta": {
					"imagine": {
						"_path": "Initiere_in_Web_Design_Stefan.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Limbajul de marcare Hipertext - HTML"
						},
						{
							"_nr": "2",
							"__text": "Editarea si vizualizarea unui document HTML"
						},
						{
							"_nr": "3",
							"__text": "HTML de baza"
						},
						{
							"_nr": "4",
							"__text": "Imbunatatirea paginii wev cu imagini, liste si tabele"
						},
						{
							"_nr": "5",
							"__text": "Referinte in pagini Web"
						},
						{
							"_nr": "6",
							"__text": "Cadre"
						},
						{
							"_nr": "7",
							"__text": "Formulare"
						},
						{
							"_nr": "8",
							"__text": "Transferul fisierelor prin FTP"
						},
						{
							"_nr": "9",
							"__text": "Proiectarea site-urilor Web"
						},
						{
							"_nr": "10",
							"__text": "Introducere in JavaScript"
						}
					]
				},
				"_id": "1266",
				"_isbn": "973-7958-10-1"
			},
			{
				"titlu": "Internet World Wide Web : JavaScript, HTML, Java",
				"autori": {
					"autor": [
						{
							"_nume": "Pavel Nastase"
						},
						{
							"_nume": "Floarea Nastase"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Economica",
				"numarPagini": "336",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Dan Marinescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Internet_World_Wide_Web_JavaScript_HTML_Java_Nastase.jpg"
					},
					"__text": "Adrian Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea generala a retelei INTERNET"
						},
						{
							"_nr": "2",
							"__text": "Functionarea retelei INTERNET"
						},
						{
							"_nr": "3",
							"__text": "Servicii INTERNET"
						},
						{
							"_nr": "4",
							"__text": "Serviciul World Wide Web (WWW)"
						},
						{
							"_nr": "5",
							"__text": "Netscape"
						},
						{
							"_nr": "6",
							"__text": "Limbajul HTML"
						},
						{
							"_nr": "7",
							"__text": "Limbajul JavaScript"
						},
						{
							"_nr": "8",
							"__text": "Limbajul Java"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea pachetelor Java"
						},
						{
							"_nr": "10",
							"__text": "Comunicarea intre paginile HTML si Java"
						},
						{
							"_nr": "11",
							"__text": "Conectarea paginilor Web la baze de date"
						},
						{
							"_nr": "12",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1267",
				"_isbn": "973-590-132-3"
			},
			{
				"titlu": "Arhitectura retelelor de calculatoare",
				"autori": {
					"autor": {
						"_nume": "Floarea Nastase"
					}
				},
				"anAparitie": "1999",
				"editura": "Economica",
				"numarPagini": "272",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Dan Marinescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Arhitectura_retelelor_de_calculatoare_Nastase.jpg"
					},
					"__text": "Adrian Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Arhitecura comunicatiei"
						},
						{
							"_nr": "3",
							"__text": "Nivelul fizic"
						},
						{
							"_nr": "4",
							"__text": "Arhitecturi ale retelelor locale"
						},
						{
							"_nr": "5",
							"__text": "Arhitecturi ale retelor de debit inalt"
						},
						{
							"_nr": "6",
							"__text": "Nivelul legatura de date"
						},
						{
							"_nr": "7",
							"__text": "Nivelul retea"
						},
						{
							"_nr": "8",
							"__text": "Nivelul transport"
						},
						{
							"_nr": "9",
							"__text": "Nivelul aplicatie"
						},
						{
							"_nr": "10",
							"__text": "Comunicarea intre paginile HTML si Java"
						},
						{
							"_nr": "11",
							"__text": "Conectarea paginilor Web la baze de date"
						},
						{
							"_nr": "12",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1268",
				"_isbn": "973-590-253-2"
			},
			{
				"titlu": "Red Hat Linux pentru ... amici",
				"autori": {
					"autor": [
						{
							"_nume": "Jon Maddog Hall"
						},
						{
							"_nume": "Paul G. Sery"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "366",
				"pretOriginal": "0",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "Red Hat Linux for Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Vlad Constantin Oancea"
					},
					"_an": "1999",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"tehnoredactori": {
					"tehnoredactor": "Constantin Nita"
				},
				"coperta": {
					"imagine": {
						"_path": "Red_Hat_Linux_pentru_amici_Hall.jpg"
					},
					"__text": "Andreea Staicu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Introducere in Linux"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Instalarea Linux-ului"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Cateva notiuni de baza"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Utilizarea Red Hat Linux"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Conectarea"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Partea celor zece"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1269",
				"_isbn": "973-31-1565-7"
			},
			{
				"titlu": "Norton Utilities 8 pentru Toti",
				"autori": {
					"autor": {
						"_nume": "Beth Slick"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "20000",
				"pretCumparare": "28000",
				"colectia": {
					"_numarul": "160",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Norton Utilities 8 for Dummies",
					"editura": "IDGBooks Worldwide",
					"traducatori": {
						"traducator": "Nicolae Pora"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marcel Stanciu"
				},
				"coperta": {
					"imagine": {
						"_path": "Norton_Utilities_8_pentru_toti_Slick.jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Cum gandeste Norton"
						},
						{
							"_nr": "II",
							"__text": "Norton tonic"
						},
						{
							"_nr": "III",
							"__text": "Un dram de precautie"
						},
						{
							"_nr": "IV",
							"__text": "Salvati-ma!"
						},
						{
							"_nr": "V",
							"__text": "Devaraua Norton"
						},
						{
							"_nr": "VI",
							"__text": "Partea decaloagelor"
						}
					]
				},
				"_id": "1270",
				"_isbn": "973-601-382-0"
			},
			{
				"titlu": "MS-DOS si Windows puternici impreuna",
				"autori": {
					"autor": [
						{
							"_nume": "Martin Matthews"
						},
						{
							"_nume": "Bruce Dobson"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "344",
				"pretOriginal": "20000",
				"pretCumparare": "27000",
				"colectia": {
					"_numarul": "138",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The Power of Windows and DOS Together",
					"editura": "Prima Communications",
					"traducatori": {
						"traducator": "Daniel Aizic"
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Serban Enescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marius Dascalu"
				},
				"coperta": {
					"imagine": {
						"_path": "MS_DOS_si_Windows_puternici_impreuna_Matthews.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Medii integrate Windows si DOS"
						},
						{
							"_nr": "2",
							"__text": "Program Manager si DOS Shell"
						},
						{
							"_nr": "3",
							"__text": "Gestiunea fisierelor in Windows si DOS"
						},
						{
							"_nr": "4",
							"__text": "Instalarea si executarea aplicatiilro in mediile Windows si DOS"
						},
						{
							"_nr": "5",
							"__text": "Editarea textului si a comenzilor in Windows si DOS"
						},
						{
							"_nr": "6",
							"__text": "Configurarea mediului DOS"
						},
						{
							"_nr": "7",
							"__text": "Configurarea mediului Windows"
						},
						{
							"_nr": "8",
							"__text": "Optimizarea mediilor DOS si Windows"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea retelor de calculatoare si a mediilor Windows si DOS in retea"
						},
						{
							"_nr": "Anexa B",
							"__text": "Pregatirea instalarii si instalarea sistemului de operare 6"
						},
						{
							"_nr": "Anexa B",
							"__text": "Pregatirea instalarii si instalarea interfetei grafice Windows 3.1"
						},
						{
							"_nr": "Anexa C",
							"__text": "Comenzile DOS 6"
						}
					]
				},
				"_id": "1271",
				"_isbn": "973-601-469-X"
			},
			{
				"titlu": "Utilizarea sistemului de operare DOS-PC",
				"autori": {
					"autor": [
						{
							"_nume": "Dervesteanu Smaranda"
						},
						{
							"_nume": "Enyedi Andrei"
						},
						{
							"_nume": "Muntean Marius"
						}
					]
				},
				"anAparitie": "1989",
				"editura": "ITC",
				"numarPagini": "200",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "1",
					"_nume": "DOS-PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dervesteanu Smaranda"
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizarea_sistemului_de_operare_DOS_PC_ITCI.jpg"
					},
					"__text": "Dervesteanu Liviu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Lansarea sistemului de operare DOS-PC"
						},
						{
							"_nr": "3",
							"__text": "Organizarea si specificarea fisierelor DOS-PC"
						},
						{
							"_nr": "4",
							"__text": "Editarea liniei de comanda"
						},
						{
							"_nr": "5",
							"__text": "Comenzi DOS-PC"
						},
						{
							"_nr": "6",
							"__text": "Fisierele de comenzi (lot de lucrari)"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea discului Winchester (discului fix)"
						},
						{
							"_nr": "Anexa A",
							"__text": "Mesaje"
						},
						{
							"_nr": "Anexa B",
							"__text": "Facilitati introduse de versiunile sistemului DOS-PC"
						},
						{
							"_nr": "Anexa C",
							"__text": "Functiile extinse ale ecranului si claviaturii"
						}
					]
				},
				"_id": "1272",
				"_isbn": ""
			},
			{
				"titlu": "Informatica aplicata",
				"autori": {
					"autor": [
						{
							"_nume": "Anca Gheorghiu"
						},
						{
							"_nume": "Corina Maria Bichis"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Victor",
				"numarPagini": "350",
				"pretOriginal": "0",
				"pretCumparare": "70000",
				"tehnoredactori": {
					"tehnoredactor": "Florica Budnic"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_aplicata_Gheorghiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in universul calculatoarelor electronice"
						},
						{
							"_nr": "2",
							"__text": "Consideratii generale privind structura si functionarea calculatoarelor electronice"
						},
						{
							"_nr": "3",
							"__text": "Componente hardware ale calculatorului electronic numeric"
						},
						{
							"_nr": "4",
							"__text": "Sisteme de operare. Sistemul Windows"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de operare Linux"
						},
						{
							"_nr": "6",
							"__text": "Microsoft Word"
						},
						{
							"_nr": "7",
							"__text": "Microsoft Excel"
						},
						{
							"_nr": "8",
							"__text": "Microsoft Power Point"
						},
						{
							"_nr": "9",
							"__text": "Crearea paginilor Web"
						},
						{
							"_nr": "10",
							"__text": "Sisteme informatice in societatea moderna"
						},
						{
							"_nr": "11",
							"__text": "Configurarea calculatoarului personal. Instalarea softului si intretinerea sistemului"
						}
					]
				},
				"_id": "1273",
				"_isbn": "973-8128-56-0"
			},
			{
				"titlu": "Calculatoare personale: elemente arhitecturale",
				"autori": {
					"autor": {
						"_nume": "Radu Marsanu"
					}
				},
				"anAparitie": "2001",
				"editura": "All",
				"numarPagini": "304",
				"pretOriginal": "0",
				"pretCumparare": "40000",
				"redactori": {
					"redactor": "Mihaela Marian"
				},
				"coperta": {
					"imagine": {
						"_path": "Calculatoare_personale_Elemente_arhitecturale_Marsanu.jpg"
					},
					"__text": "Daniel Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Calculatoare personale"
						},
						{
							"_nr": "2",
							"__text": "Microprocesore"
						},
						{
							"_nr": "3",
							"__text": "Memoria sistemelor de calcul. Memoria interna la calculatoarele personale"
						},
						{
							"_nr": "4",
							"__text": "Memoria externa"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de intrare/iesire"
						}
					]
				},
				"_id": "1274",
				"_isbn": "973-571-337-3"
			},
			{
				"titlu": "Tehnologii informatice in administratia publica",
				"autori": {
					"autor": {
						"_nume": "Sofia Elena Colesca"
					}
				},
				"anAparitie": "2004",
				"editura": "Eficon Press",
				"numarPagini": "96",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"coperta": {
					"imagine": {
						"_path": "Tehnologii_informatice_in_administratia_publica_Colesca.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Servicii administrative electronice"
						},
						{
							"_nr": "2",
							"__text": "Organizarea internet-ului"
						},
						{
							"_nr": "3",
							"__text": "Proiectarea site-urilor de Web"
						},
						{
							"_nr": "4",
							"__text": "Limbajul HTML"
						}
					]
				},
				"_id": "1275",
				"_isbn": "973-86365-7-4"
			},
			{
				"titlu": "Sisteme informatice cu baze de date Access: aplicatii practice in turism",
				"autori": {
					"autor": [
						{
							"_nume": "Botezatu Cornelia"
						},
						{
							"_nume": "Mihalcescu Cezar"
						},
						{
							"_nume": "Iacob Ionel"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Universitara",
				"numarPagini": "342",
				"pretOriginal": "0",
				"pretCumparare": "60000",
				"coperta": {
					"imagine": {
						"_path": "Sisteme_informatice_cu_baze_de_date_Access_Aplicatii_practice_in_turism_Botezatu.jpg"
					},
					"__text": "Daniel Tutunel"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul informatic - instrument al conducerii unitatiilor economico-sociale"
						},
						{
							"_nr": "2",
							"__text": "Concepte utilizate in organizarea datelor"
						},
						{
							"_nr": "3",
							"__text": "Metodologii de realizare a sistemelor informatice"
						},
						{
							"_nr": "4",
							"__text": "Sistemul de gestiune a bazelor de date Microsoft Access 2002"
						},
						{
							"_nr": "5",
							"__text": "Aplicatii practice in turism"
						}
					]
				},
				"_id": "1276",
				"_isbn": "973-7787-34-X"
			},
			{
				"titlu": "GoLive 5 pentru ... amici",
				"autori": {
					"autor": {
						"_nume": "Willian B. Sanders"
					}
				},
				"anAparitie": "2001",
				"editura": "Tehnica",
				"numarPagini": "374",
				"pretOriginal": "190000",
				"pretCumparare": "40000",
				"traducere": {
					"titluOriginal": "GoLivve 5 for Dummies",
					"editura": "IDG Books Worldwide",
					"traducatori": {
						"traducator": "Maria Diaconu"
					},
					"_an": "1999",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Alice Raluca Vinereanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marieta Stanciu"
				},
				"coperta": {
					"imagine": {
						"_path": "GoLive_5_pentru_amici_Sanders.jpg"
					},
					"__text": "Andreea Staicu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Pe locuri, fiti gata, GoLive 5!"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Arata bine - design-ul care place"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Pentru bucuria ochilor: ingrijirea, hranirea si organizarea site-urilor Web"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Cum sa facem paginile Web sa prinda viata cu GoLive 5"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Partea celor zece"
						}
					]
				},
				"_id": "1277",
				"_isbn": "973-31-2042-1"
			},
			{
				"titlu": "Programarea avansata in Delphi",
				"autori": {
					"autor": {
						"_nume": "Mihai Oltean"
					}
				},
				"anAparitie": "1999",
				"editura": "Albastra",
				"tiraj": "500",
				"numarPagini": "326",
				"pretOriginal": "87000",
				"pretCumparare": "70000",
				"colectia": {
					"_numarul": "97",
					"_nume": "Seria PC"
				},
				"tehnoredactori": {
					"tehnoredactor": "Codruta Poenaru"
				},
				"coperta": {
					"imagine": {
						"_path": "Programarea_avansata_in_Delphi_Oltean.jpg"
					},
					"__text": "Liviu Dervesteanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Instructiuni asamblare in Delphi"
						},
						{
							"_nr": "2",
							"__text": "Clipboard-ul"
						},
						{
							"_nr": "3",
							"__text": "Tiparirea la imprimanta"
						},
						{
							"_nr": "4",
							"__text": "Dynamic Linking Library (DLL)"
						},
						{
							"_nr": "5",
							"__text": "Programarea exceptiilor in Delphi"
						},
						{
							"_nr": "6",
							"__text": "Prelucrarea imagnilor JPEG"
						},
						{
							"_nr": "7",
							"__text": "Delphi si Windows API"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii cu mai multe fire de executie"
						},
						{
							"_nr": "9",
							"__text": "COM, DCOM, OLE Automation, ActiveX"
						},
						{
							"_nr": "10",
							"__text": "Internet Solution Pack"
						},
						{
							"_nr": "11",
							"__text": "Dezvoltarea de componente"
						},
						{
							"_nr": "12",
							"__text": "Experti Delphi"
						},
						{
							"_nr": "13",
							"__text": "Registrii Windows"
						},
						{
							"_nr": "14",
							"__text": "DirectX"
						},
						{
							"_nr": "15",
							"__text": "Probleme rezolvate"
						}
					]
				},
				"_id": "1278",
				"_isbn": "973-9443-19-2"
			},
			{
				"titlu": "Prelucrarea fisierelor in Pascal",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Bogdan Ghilic-Micu"
						},
						{
							"_nume": "Marian Stoica"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "ASE",
				"numarPagini": "164",
				"pretOriginal": "23000",
				"pretCumparare": "60000",
				"redactori": {
					"redactor": "Marian Stoica"
				},
				"coperta": {
					"imagine": {
						"_path": "Algoritmi_si_programe_de_prelucrare_a_fisierelor_Rosca.jpg"
					},
					"__text": "Marian Stoica"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Caracteristici generale ale algoritmilor de prelucrare a fisierelor"
						},
						{
							"_nr": "2",
							"__text": "Prelucrarea masivelor memorate in fisiere binare"
						},
						{
							"_nr": "3",
							"__text": "Validarea datelor"
						},
						{
							"_nr": "4",
							"__text": "Algoritmi de prelucrare a fisierelor secventiale"
						},
						{
							"_nr": "5",
							"__text": "Sortarea si interclasarea fisierelor binare memorate dens"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi de prelucrare a fisierelor organizate relativ"
						},
						{
							"_nr": "7",
							"__text": "Algoritmi de prelucrare a fisierelor organizate indexat"
						}
					]
				},
				"_id": "1279",
				"_isbn": "973-9462-36-7"
			},
			{
				"titlu": "Grafica in OOP... si nu numai...",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "1995",
				"editura": "Adias",
				"numarPagini": "110",
				"pretOriginal": "5980",
				"pretCumparare": "30000",
				"redactori": {
					"redactor": "Bogdan Patrut"
				},
				"tehnoredactori": {
					"tehnoredactor": "Octavian Aspru"
				},
				"coperta": {
					"imagine": {
						"_path": "Grafica_in_OOP_si_nu_numai_Patrut.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Lumea minunata a OOP-ului"
						},
						{
							"_nr": "II",
							"__text": "Lucrul cu mouse-ul in modul grafic"
						},
						{
							"_nr": "III",
							"__text": "Clasa generala FEREASTRA"
						},
						{
							"_nr": "IV",
							"__text": "Structura generala a unui program cu ferestre"
						},
						{
							"_nr": "V",
							"__text": "Clasa RECEPTOR si componente"
						},
						{
							"_nr": "VI",
							"__text": "Lucrul cu imagini. Formatul BMP"
						},
						{
							"_nr": "VII",
							"__text": "Programul AGENDA"
						},
						{
							"_nr": "VIII",
							"__text": "Verificarea corectitudinii sintactice a unei functii si evaluarea sa intr-un anumit punct"
						},
						{
							"_nr": "IX",
							"__text": "Reprezentarea grafica a unei functii"
						},
						{
							"_nr": "X",
							"__text": "Programul FUNCTII"
						},
						{
							"_nr": "XI",
							"__text": "Dericare formala"
						},
						{
							"_nr": "XII",
							"__text": "Editor de tabele... inteligent"
						},
						{
							"_nr": "XIII",
							"__text": "Obiecte grafice complexe"
						},
						{
							"_nr": "XIV",
							"__text": "Grafica in 256 de culori"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Listingul programului functii"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Listingul unit-ului UTABEL"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Listingul programului HARTA"
						}
					]
				},
				"_id": "1280",
				"_isbn": "973-96265-4-8"
			},
			{
				"titlu": "Dictionarul explicativ al computerului : Standardul comprehensiv pentru utilizarea computerului la birou, scoala, acasa",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Baternai"
					}
				},
				"anAparitie": "1995",
				"editura": "ARC",
				"numarPagini": "352",
				"pretOriginal": "0",
				"pretCumparare": "60000",
				"tehnoredactori": {
					"tehnoredactor": [
						"T. Baternai",
						"Enache Cristian"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Dictionarul_explicativ_al_computerului_Standardul_comprehensiv_pentru_utilizarea_computerului_la_birou_scoala_acasa_Baternai.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Dictionar de termini"
						},
						{
							"_nr": "Apendix A",
							"__text": "Setul de caractere ASCII"
						},
						{
							"_nr": "Apendix B",
							"__text": "Echivalenti numerici"
						}
					]
				},
				"_id": "1281",
				"_isbn": "973-97342-0-0"
			},
			{
				"titlu": "Informatica: manual pentru ciclul superioar al liceului [XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Hutanu"
						},
						{
							"_nume": "Tudor Sorin"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "L and S InfoMat",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Informatica_manual_pentru_ciclul_superior_al_liceului_XI_Hutanu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tablouri"
						},
						{
							"_nr": "2",
							"__text": "Subprograme"
						},
						{
							"_nr": "3",
							"__text": "Siruri de caractere"
						},
						{
							"_nr": "4",
							"__text": "Structuri de date neomogene"
						},
						{
							"_nr": "5",
							"__text": "Structuri de date"
						},
						{
							"_nr": "6",
							"__text": "Introducere in recursivitate"
						},
						{
							"_nr": "7",
							"__text": "Metoda Divide et Impera"
						},
						{
							"_nr": "8",
							"__text": "Metoda Backtracking"
						},
						{
							"_nr": "9",
							"__text": "Grafuri"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Memento"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Aplicatii practice ale grafurilor"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Codul ASCII"
						}
					]
				},
				"_id": "1282",
				"_isbn": "973-88037-1-3"
			},
			{
				"titlu": "Informatica manageriala in activitatea de educatie fizica si sport",
				"autori": {
					"autor": [
						{
							"_nume": "Zenovic Gherasim"
						},
						{
							"_nume": "Maria Andronie"
						},
						{
							"_nume": "Nicolae Popescu-Doborin"
						}
					]
				},
				"anAparitie": "2004",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "256",
				"pretOriginal": "247430",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Roxana Ene"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Maria Andronie",
						"Marian Bolintis"
					]
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_manageriala_in_activitatea_de_educatie_fizica_si_sport_Gherasim.jpg"
					},
					"__text": "Maria Andronie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Organizatia sportiva. Concepte de societate informationala - societate a cunoasterii. Fundamentele organizationale ale sistemelor informationale/informatice"
						},
						{
							"_nr": "2",
							"__text": "Importanta informaticii manageriale in fundamentarea procesului de luare a deciziilor in managementul educatiei fizice si sportului. Managerul, realizarea si utilizarea sistemelor informatice dedicate"
						},
						{
							"_nr": "3",
							"__text": "Organizarea datelor in baze de date. Sisteme de gestiune a bazelor de date. Depozite de date si sisteme informatice de asistarea a deciziei (DSS)"
						},
						{
							"_nr": "4",
							"__text": "Produsul Microsoft Access. Tabele si formulare. Interogari. Rapoarte. Aplicatie pentru gestionarea bazei materiale a unei asociatii sportive"
						},
						{
							"_nr": "5",
							"__text": "Proiectarea sistemelor informatice de gestiune"
						},
						{
							"_nr": "6",
							"__text": "Organizarea si prelucrarea distribuita a datelor. Baze de date pe Internet. Folosirea HTML pentru crearea site-urilor Web. Aplicatie pentru crearea unui site de prezentare a unui club sportiv"
						},
						{
							"_nr": "7",
							"__text": "Utilizarea tehnicilor de inteligenta artificiala in fundamentarea deciziilor unui manager. Sisteme expert destinate managementului sporturilor"
						},
						{
							"_nr": "8",
							"__text": "Managementul activitatilor sportive sub forma de proiecte. Produsul informatic Microsoft Project. Aplicatie pentru managementul organizatiei unei competitii sportive internationale."
						},
						{
							"_nr": "9",
							"__text": "Utilizarea calculului tabelar in luarea deciziei manageriale in activitatea de educatie fizica si sport"
						}
					]
				},
				"_id": "1283",
				"_isbn": "973-725-032-X"
			},
			{
				"titlu": "Utilizare MS-DOS",
				"autori": {
					"autor": {
						"_nume": "Gerry Routledge"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "464",
				"pretOriginal": "189000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "128",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Using DOS",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Lian Claudiu Sebe"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizare_MS_DOS_Routledge.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "0",
							"__text": "Introducere"
						},
						{
							"_nr": "I",
							"__text": "Sa incepem cu DOS"
						},
						{
							"_nr": "II",
							"__text": "Cum lucram cu fisiere si cataloage DOS"
						},
						{
							"_nr": "III",
							"__text": "Intretinerea preventiva a calculatorului"
						},
						{
							"_nr": "IV",
							"__text": "Folosirea altor programe sub DOS"
						},
						{
							"_nr": "V",
							"__text": "Ce este Windows"
						},
						{
							"_nr": "VI",
							"__text": "Cum sa determinam calculatorul sa lucreze mai rapid si mai eficient"
						},
						{
							"_nr": "VII",
							"__text": "Tolba cu referinte"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Glosar"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Index"
						}
					]
				},
				"_id": "1284",
				"_isbn": "973-601-309-X"
			},
			{
				"titlu": "Informatica - Manual pentru clasa a IX-a",
				"autori": {
					"autor": [
						{
							"_nume": "Bogdan Patrut"
						},
						{
							"_nume": "Mariana Milosescu"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Teora",
				"numarPagini": "296",
				"pretOriginal": "89000",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Liliana Dabulescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Informatica_Manual_pentru_clasa_IX_Patrut.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rolul si functiile sistemelor de calcul"
						},
						{
							"_nr": "2",
							"__text": "Sisteme de operare si programe utilitare"
						},
						{
							"_nr": "3",
							"__text": "Algoritmi"
						},
						{
							"_nr": "4",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "5",
							"__text": "Elemente de baza ale limbajului de programare"
						},
						{
							"_nr": "6",
							"__text": "Structuri de control"
						},
						{
							"_nr": "7",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "8",
							"__text": "Fisiere"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Reprezentarea interna a datelor"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Cerinte in realizarea programelor"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Mediul de programare Turbo Pascal"
						}
					]
				},
				"_id": "1285",
				"_isbn": "973-20-0388-X"
			},
			{
				"titlu": "Windows 98",
				"autori": {
					"autor": [
						{
							"_nume": "Jane Calabria"
						},
						{
							"_nume": "Dorothy Burke"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Teora",
				"numarPagini": "464",
				"pretOriginal": "220000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "367",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Windows 98 6 in 1",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Dumitru Rentea"
					},
					"_an": "1998",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A"
				},
				"redactori": {
					"redactor": "Cristina Mihai"
				},
				"coperta": {
					"imagine": {
						"_path": "Windows_98_Calabria.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Navigarea in Windows 98"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Lucrul cu fisiere, dosare si unitati de disc"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Personalizarea si intretinerea sistemului de operare Windows 98"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Lucrul cu aplicatii Windows"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Windows 98 si Internetul"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Lucrul in retea si pe calculatoare portabile cu Windows 98"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Anexe"
						}
					]
				},
				"_id": "1286",
				"_isbn": "973-20-0542-4"
			},
			{
				"titlu": "Baze de date relationale: organizare si interogare",
				"autori": {
					"autor": {
						"_nume": "Marin Fotache"
					}
				},
				"anAparitie": "1996",
				"editura": "Junimea",
				"numarPagini": "276",
				"pretOriginal": "14900",
				"pretCumparare": "200000",
				"colectia": {
					"_numarul": "1",
					"_nume": "CASH"
				},
				"redactori": {
					"redactor": "Marius Chelaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marin Fotache"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_relationale_Organizare_interograre_Fotache.jpg"
					},
					"__text": "S. Prutianu, C. Munteanu, M. Fotache"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Fisiere, baze, banci si depozite de date"
						},
						{
							"_nr": "2",
							"__text": "Modelul Entitati-asociatii"
						},
						{
							"_nr": "3",
							"__text": "Modelul relational"
						},
						{
							"_nr": "4",
							"__text": "Algebra relationala"
						},
						{
							"_nr": "5",
							"__text": "SQL"
						},
						{
							"_nr": "6",
							"__text": "Nucleul SQL in FoxPro"
						},
						{
							"_nr": "7",
							"__text": "Limbajele QBE si QUEL"
						}
					]
				},
				"_id": "1287",
				"_isbn": "973-37-0248-X"
			},
			{
				"titlu": "Baze de date relationale: organizare, interogare si normalizare (ediata a II-a, adaugita)",
				"autori": {
					"autor": {
						"_nume": "Marin Fotache"
					}
				},
				"anAparitie": "1997",
				"editura": "Junimea",
				"numarPagini": "386",
				"pretOriginal": "0",
				"pretCumparare": "250000",
				"colectia": {
					"_numarul": "11",
					"_nume": "CASH"
				},
				"redactori": {
					"redactor": "Marius Chelaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marin Fotache"
				},
				"coperta": {
					"imagine": {
						"_path": "Baze_de_date_relationale_Organizare_interograre_si_normalizare_Editia_II_Fotache.jpg"
					},
					"__text": "Sergiu Corunga"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Organizarea bazelor de date"
						},
						{
							"_nr": "1",
							"__text": "Fisiere, baze, banci si depozite de date"
						},
						{
							"_nr": "2",
							"__text": "Modelul Entitati-asociatii"
						},
						{
							"_nr": "3",
							"__text": "Modelul relational"
						},
						{
							"_nr": "II",
							"__text": "Interogarea bezelor de date relationale"
						},
						{
							"_nr": "4",
							"__text": "Algebra relationala"
						},
						{
							"_nr": "5",
							"__text": "SQL"
						},
						{
							"_nr": "6",
							"__text": "Nucleul SQL in FoxPro"
						},
						{
							"_nr": "7",
							"__text": "Limbajele QBE si QUEL"
						},
						{
							"_nr": "III",
							"__text": "Normalizarea bazelor de date relationale"
						},
						{
							"_nr": "8",
							"__text": "Necesitatea normalizarii bazelor de date"
						},
						{
							"_nr": "9",
							"__text": "Dependente intre atribute"
						},
						{
							"_nr": "10",
							"__text": "Formele normale ale unei baze de date relationale"
						}
					]
				},
				"_id": "1288",
				"_isbn": "973-37-0305-2"
			},
			{
				"titlu": "Utilizare Word 6 pentru Windows",
				"autori": {
					"autor": {
						"_nume": "Nancy Stevenson"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "384",
				"pretOriginal": "26000",
				"pretCumparare": "20000",
				"colectia": {
					"_nume": "Calculatoare personale",
					"_numarul": "141"
				},
				"traducere": {
					"titluOriginal": "Using Word 6 for Windows",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Silviu Petrescu"
					},
					"_an": "1995",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizare_Word_6_pentru_Windows_Stevenson.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Numai notiuni de baza"
						},
						{
							"_nr": "II",
							"__text": "Sa facem ceva din nimic"
						},
						{
							"_nr": "III",
							"__text": "Sa \"impodobim\" documentele"
						},
						{
							"_nr": "IV",
							"__text": "Sa ne organizam"
						},
						{
							"_nr": "V",
							"__text": "Tehnoredactarea pe intelesul tuturor"
						},
						{
							"_nr": "VI",
							"__text": "Administrarea datelor si a documentelor"
						},
						{
							"_nr": "VII",
							"__text": "Lucruri deosebite ce se pot realiza in Word"
						}
					]
				},
				"_id": "1289",
				"_isbn": "973-601-387-1"
			},
			{
				"titlu": "Schemelor logice: semnificatie, elaborare, verificare, testare",
				"autori": {
					"autor": {
						"_nume": "Leon Livovschi"
					}
				},
				"anAparitie": "1980",
				"editura": "Tehnica",
				"numarPagini": "278",
				"pretOriginal": "10.50",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Valeriu Morarescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Scheme_logice_Seminicatie_elaborare_verificare_testare_Livovschi.jpg"
					},
					"__text": "Simona Niculescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Evolutia unui calculator si schemele logice"
						},
						{
							"_nr": "2",
							"__text": "Calculabilitatea si recursivitatea"
						},
						{
							"_nr": "3",
							"__text": "Tabele de decizie"
						},
						{
							"_nr": "4",
							"__text": "Transformarea, structura si elaborarea schemelor logice"
						},
						{
							"_nr": "5",
							"__text": "Verificarea corectitudinii programalor"
						},
						{
							"_nr": "6",
							"__text": "Testarea programelor"
						}
					]
				},
				"_id": "1290",
				"_isbn": ""
			},
			{
				"titlu": "Elemente de inteligenta artificiala",
				"autori": {
					"autor": {
						"_nume": "Ioan Georgescu"
					}
				},
				"anAparitie": "1985",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "352",
				"pretOriginal": "37",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Felicia Bolocan"
				},
				"coperta": {
					"imagine": {
						"_path": "Elemente_de_inteligenta_artificiala_Georgescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Introducere"
						},
						{
							"_nr": "1",
							"__text": "Despre inteligenta artificiala"
						},
						{
							"_nr": "2",
							"__text": "Notiuni introductive de logica matematica"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Sisteme cognitive"
						},
						{
							"_nr": "1",
							"__text": "Cunoasterea"
						},
						{
							"_nr": "2",
							"__text": "Metode de reprezentare a cunoasterii"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Sisteme rezolutive"
						},
						{
							"_nr": "1",
							"__text": "Problematica rezolvarii problemelor"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea problemelor"
						},
						{
							"_nr": "3",
							"__text": "Strategii de control in spariul starilor"
						},
						{
							"_nr": "4",
							"__text": "Strategii de rezolvare pentru probleme predicative"
						},
						{
							"_nr": "5",
							"__text": "Rezolvarea problemelor prin metode non-rezolutive"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Aplicatii"
						},
						{
							"_nr": "1",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "2",
							"__text": "Comunicarea in limbaj natural"
						},
						{
							"_nr": "3",
							"__text": "Planificareaactiunilor si invatarea automata"
						},
						{
							"_nr": "4",
							"__text": "Sisteme independenta de domeniul pentru achizitia cunoasterii"
						}
					]
				},
				"_id": "1291",
				"_isbn": ""
			},
			{
				"titlu": "Practica optimizarii asistate de calculator",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Sima"
						},
						{
							"_nume": "Andras Varga"
						}
					]
				},
				"anAparitie": "1986",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "352",
				"pretOriginal": "37",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Mircea Grosu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Olimpiada Nistor"
				},
				"coperta": {
					"imagine": {
						"_path": "Practica_optimizarii_asistate_de_calculator_Sima.jpg"
					},
					"__text": "Simona Dumitrescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Rezolvarea asistata de calculator a problemelor de optimizare. Aspecte teoretice si practice"
						},
						{
							"_nr": "2",
							"__text": "Rezolvarea asistata de calculator a problemelor de optimizare fara restrictii"
						},
						{
							"_nr": "3",
							"__text": "Rezolvarea asistata de calculator a problemelor de optimizare cu restrictii liniare"
						},
						{
							"_nr": "4",
							"__text": "Rezolvarea asistata de calculator a problemelor de optimizare cu restrictii neliniare"
						},
						{
							"_nr": "5",
							"__text": "Probleme de calcul pentru rezolvarea problemelor de optimizare"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii de optimizare asistata de calculator"
						},
						{
							"_nr": "Anexa A",
							"__text": "Elemente de albebra liniara numeria in rezolvarea problemelor de optimizare"
						},
						{
							"_nr": "Anexa B",
							"__text": "Probleme de optimizare"
						}
					]
				},
				"_id": "1292",
				"_isbn": ""
			},
			{
				"titlu": "Procese si sisteme informationale: probleme de automatizare (Vol.12)",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Cartianu"
						},
						{
							"_nume": "Roduca Strungaru"
						},
						{
							"_nume": "Edmond Nicolau"
						},
						{
							"_nume": "Mihai Draganescu"
						},
						{
							"_nume": "Alexandru Spataru"
						},
						{
							"_nume": "Solomon Marcus"
						},
						{
							"_nume": "Adelaida Mateescu"
						},
						{
							"_nume": "George Rulea"
						},
						{
							"_nume": "Mariana Belis"
						},
						{
							"_nume": "Mugur Savescu"
						},
						{
							"_nume": "Gheorghe I. Mitrofan"
						},
						{
							"_nume": "Vasile Baltac"
						},
						{
							"_nume": "Dumitru Stanomir"
						},
						{
							"_nume": "Octavian Stanasila"
						},
						{
							"_nume": "Valentin I. Vlad"
						},
						{
							"_nume": "Adriana Vlad"
						},
						{
							"_nume": "Dan Dascalu"
						},
						{
							"_nume": "Margareta Draghici"
						},
						{
							"_nume": "Ioan Georgescu"
						},
						{
							"_nume": "Gorun Manolescu"
						},
						{
							"_nume": "Florin Stanciulescu"
						},
						{
							"_nume": "Cornelia Zaciu"
						},
						{
							"_nume": "Dragos Zaharia"
						},
						{
							"_nume": "Ion Marghescu"
						},
						{
							"_nume": "Silviu Ciochina"
						}
					]
				},
				"anAparitie": "1982",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "252",
				"pretOriginal": "15.50",
				"redactori": {
					"redactor": "Eugen Marinescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Doina Nord"
				},
				"coperta": {
					"imagine": {
						"_path": "Procese_si_sisteme_informationale_Probleme_de_automatizare_Vol_12_Nicolau.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Psychic processes informational prcesses"
						},
						{
							"_nr": "2",
							"__text": "Information processing in the nervous system"
						},
						{
							"_nr": "3",
							"__text": "Arhitectura si structura in sisteme deschise si introdeschise"
						},
						{
							"_nr": "4",
							"__text": "Probleme informationale in compresia de date"
						},
						{
							"_nr": "5",
							"__text": "Aspete algoritmice ale comunicarii umane"
						},
						{
							"_nr": "6",
							"__text": "Determinarea preciziei de estimare a parametrilor semnalului detectat in sistemele informationale"
						},
						{
							"_nr": "7",
							"__text": "Semnale complexe"
						},
						{
							"_nr": "8",
							"__text": "Transmiterea informatiei in sistemele biologice"
						},
						{
							"_nr": "9",
							"__text": "Analiza aproximativa, cu ajutorul functiilor Walsh, a circuitelor electrice liniare"
						},
						{
							"_nr": "10",
							"__text": "Aplicatii ale tehnicii digitale in televiziune"
						},
						{
							"_nr": "11",
							"__text": "Informatica distribuita si sistemele de programe (software) ale calculatoarelor electronice"
						},
						{
							"_nr": "12",
							"__text": "Semnale si sisteme. Punctul de vedere operational"
						},
						{
							"_nr": "13",
							"__text": "Codarea holografica a informatiei"
						},
						{
							"_nr": "14",
							"__text": "Noi dispozitive electronice in sisteme informationale"
						},
						{
							"_nr": "15",
							"__text": "Structurarea datelor - prezent si viitor"
						},
						{
							"_nr": "16",
							"__text": "Probleme ale reprezentarii cunoasterii in inteligenta artificiala"
						},
						{
							"_nr": "17",
							"__text": "Consideratii asupra modelarii traductorului ultrasonic magnetostrictiv prin circuite cu parametrii distribuiti"
						},
						{
							"_nr": "18",
							"__text": "Ingineria conceptiei: metoda deciziilor descendent structurate"
						},
						{
							"_nr": "19",
							"__text": "Sisteme mari: teorie si aplicatii"
						},
						{
							"_nr": "20",
							"__text": "Transmiterea informatiei prin fibrele nervoase; zgomotul si memoria fibrei nervoase mielinizate"
						},
						{
							"_nr": "21",
							"__text": "Operatori liniari si functii z-ortogonale in sinteza caracteristicilor de directivitate"
						},
						{
							"_nr": "22",
							"__text": "Valori limita ale performantelor sistemelor numerice de transmitere a informatiei"
						}
					]
				},
				"_id": "1293",
				"_isbn": ""
			},
			{
				"titlu": "Utilizare produselor software: Word, Excel, PMT, WinQSB, Systat",
				"autori": {
					"autor": [
						{
							"_nume": "Rodica Mihalca"
						},
						{
							"_nume": "Csaba Fabian"
						},
						{
							"_nume": "Adina Uta"
						},
						{
							"_nume": "Iulian Intorsureanu"
						},
						{
							"_nume": "Oana Muntean"
						},
						{
							"_nume": "Anca Andronescu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "192",
				"pretOriginal": "49000",
				"pretCumparare": "0",
				"tehnoredactori": {
					"tehnoredactor": "Daniela Panait"
				},
				"coperta": {
					"imagine": {
						"_path": "Utilizarea_produselor_software_Word_Excel_PMT_WinQSB_Systat_Mihalca.jpg"
					},
					"__text": "Claudia-Marinela Dumitru"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Limbajul Visual Basic for Applications (VBA) pentru Microsoft Word"
						},
						{
							"_nr": "II",
							"__text": "Facilitati ale produsului Microsoft Excel"
						},
						{
							"_nr": "III",
							"__text": "PMT"
						},
						{
							"_nr": "IV",
							"__text": "WinQSB"
						},
						{
							"_nr": "V",
							"__text": "Produsul software SYSTAT pentru prelucrari statistice"
						}
					]
				},
				"_id": "1294",
				"_isbn": "973-594-320-4"
			},
			{
				"titlu": "Tratat de proiectare a sistemelor informatice prin metoda Merise: fundamente, specificatii globale, conceptuale si logice (Vol.1)",
				"autori": {
					"autor": {
						"_nume": "Niculae Davidescu"
					}
				},
				"anAparitie": "2006",
				"editura": "Ziua",
				"numarPagini": "432",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Neaga Thotu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Laura Zaharia"
				},
				"coperta": {
					"imagine": {
						"_path": "Tratat_de_proiectare_a_sistemelor_informatice_prin_metoda_Merise_Vol_1_Fundamente_specificatii_globale_conceptuale_si_logice_Davidescu.jpg"
					},
					"__text": "Mihaela Schiopu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Interactiunile dintre gestiunea financiar-contabila si sistemele informatice de gestiune"
						},
						{
							"_nr": "2",
							"__text": "Structura sistemelor informatice de gestiune (SIG)"
						},
						{
							"_nr": "4",
							"__text": "Elemente privind conexiunea dintre funtionarea sistemului Internet si sistemele informatice de gestiune"
						},
						{
							"_nr": "4",
							"__text": "Elemente generale privind metodele si conceptele fundamentale de realizare a sistemelor informatice de gestiune"
						},
						{
							"_nr": "5",
							"__text": "Elemente definitorii ale metodei MERISE"
						},
						{
							"_nr": "6",
							"__text": "Modelarea globala"
						},
						{
							"_nr": "7",
							"__text": "Modelarea conceptuala"
						},
						{
							"_nr": "8",
							"__text": "Modelarea logica"
						}
					]
				},
				"_id": "1295",
				"_isbn": "973-7712-33-1"
			},
			{
				"titlu": "Tratat de proiectare a sistemelor informatice prin metoda Merise: specificatii fizice si implementarea sistemului (Vol.2)",
				"autori": {
					"autor": {
						"_nume": "Niculae Davidescu"
					}
				},
				"anAparitie": "2006",
				"editura": "Ziua",
				"numarPagini": "432",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"redactori": {
					"redactor": "Neaga Thotu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Laura Zaharia"
				},
				"coperta": {
					"imagine": {
						"_path": "Tratat_de_proiectare_a_sistemelor_informatice_prin_metoda_Merise_Vol_2_Specificatii_fizice_si_implementarea_sistemului_Davidescu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "9",
							"__text": "Modelarea fizica prin SGBDR ACCES"
						},
						{
							"_nr": "10",
							"__text": "AMC*Designer: software dedicat generarii automate a modelelor specifice metodei MERISE"
						},
						{
							"_nr": "11",
							"__text": "Descrierea MCD prin AMC*Designer"
						},
						{
							"_nr": "12",
							"__text": "Meniurile utilizae pentru generarea Modelului Conceptual de Date si Medelului Conceptual de Prelucrare"
						},
						{
							"_nr": "13",
							"__text": "Implementarea SIG"
						},
						{
							"_nr": "14",
							"__text": "Reglmentarile nationale si internationale privind dreptul de autor in sfera utilizarii SIG"
						},
						{
							"_nr": "Anexa",
							"__text": "Tehnologii IDD"
						}
					]
				},
				"_id": "1296",
				"_isbn": "973-7712-35-8"
			},
			{
				"titlu": "Bioinformatica (Vol.I - Calcul molecular si metode de simulare)",
				"autori": {
					"autor": [
						{
							"_nume": "Manuela Elisabeta Sidoroff"
						},
						{
							"_nume": "Alina Butu"
						},
						{
							"_nume": "Marian Butu"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "Tehnica",
				"tiraj": "200",
				"numarPagini": "150",
				"pretOriginal": "130800",
				"pretCumparare": "130800",
				"coperta": {
					"imagine": {
						"_path": "Bioinformatica_I_Sidoroff.jpg"
					},
					"__text": "Andrei Manescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Calcul molecular"
						},
						{
							"_nr": "II",
							"__text": "Metode de simulare Monte Carlo"
						},
						{
							"_nr": "III",
							"__text": "Simularea dinamicii moleculare"
						},
						{
							"_nr": "IV",
							"__text": "Banca de date pentru biotehnologie"
						}
					]
				},
				"_id": "1297",
				"_isbn": "978-973-31-2299-9"
			},
			{
				"titlu": "Protejati-va PC-ul",
				"autori": {
					"autor": {
						"_nume": "Torben B. Serensen"
					}
				},
				"anAparitie": "2004",
				"editura": "Egmont",
				"numarPagini": "78",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"traducere": {
					"titluOriginal": "Beskyt din PC",
					"editura": "Fortaget Libris",
					"traducatori": {
						"traducator": "Claudiu Gedo"
					},
					"_an": "2004",
					"_editia": "I",
					"_limba": "daneza",
					"_tara": "Danemarca"
				},
				"coperta": {
					"imagine": {
						"_path": "Protejati_va_PC_ul_Serensen.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Cum sa va faceti PC-ul mai sigur"
						},
						{
							"_nr": "2",
							"__text": "Backup"
						},
						{
							"_nr": "3",
							"__text": "Cum functioneaza Internetul"
						},
						{
							"_nr": "4",
							"__text": "Vulnerabilitatile permit accesul la PC-ul dumneavoastra"
						},
						{
							"_nr": "5",
							"__text": "Virusii si viermii constituie amenintarea cea mai periculoasa"
						},
						{
							"_nr": "6",
							"__text": "Securizarea e-mail-ului"
						},
						{
							"_nr": "7",
							"__text": "Reclama nedorita este spam"
						},
						{
							"_nr": "8",
							"__text": "Inselatorii din Nigeria si alte colturi ale lumii"
						},
						{
							"_nr": "9",
							"__text": "Spionarea si incalcarea intimitatii"
						},
						{
							"_nr": "10",
							"__text": "Firewall inseamna dublarea securitatii"
						},
						{
							"_nr": "11",
							"__text": "Hackerii vor publicitate si spatiu pe HDD"
						},
						{
							"_nr": "12",
							"__text": "Cand sunteti atacat"
						},
						{
							"_nr": "13",
							"__text": "Securitatea in retelele casnice"
						},
						{
							"_nr": "14",
							"__text": "Cel mai bun firewall sunteti dumneavoastra"
						}
					]
				},
				"_id": "1298",
				"_isbn": "973-583-455-3"
			},
			{
				"titlu": "Sisteme de operare: manual pentru licee cu profil de informatica [X]",
				"autori": {
					"autor": {
						"_nume": "Radu Marsanu"
					}
				},
				"anAparitie": "1998",
				"editura": "All",
				"numarPagini": "316",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Mihai Manastireanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_operare_manual_pentru_licee_cu_profil_de_informatica_X_Marsanu.jpg"
					},
					"__text": "Dominic Cernea"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Functiile si componentele unui sistem de operare"
						},
						{
							"_nr": "2",
							"__text": "Tehnici de gestiune a lucrarilor la sistemele de calcul"
						},
						{
							"_nr": "3",
							"__text": "Gestiunea resurselor unui sistem de calcul, functie a sistemelor de operare"
						},
						{
							"_nr": "4",
							"__text": "Componentele sistemelor de operare MS-DOS si Unix"
						},
						{
							"_nr": "5",
							"__text": "Gestiunea memoriei interne si gestiunea discurilor la MS-DOS"
						},
						{
							"_nr": "6",
							"__text": "Componenta ROM-BIOS si intreruperi"
						},
						{
							"_nr": "7",
							"__text": "Dezvoltarea programelor aplicatie sub MS-DOS si Unix"
						},
						{
							"_nr": "8",
							"__text": "Configurarea sistemului de operare. CONFIG.SYS si AUTOEXEC.BAT"
						},
						{
							"_nr": "9",
							"__text": "Comunicatii prin Internet"
						}
					]
				},
				"_id": "1299",
				"_isbn": "973-9431-02-x"
			},
			{
				"titlu": "Microsoft MS-DOS 6.22",
				"autori": {
					"autor": {
						"_nume": "[Microsoft Corporation]"
					}
				},
				"anAparitie": "1994",
				"editura": "Microsoft Corporation",
				"numarPagini": "274",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Microsoft_MS_DOS_6_22_Microsoft Corporation.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Getting Started"
						},
						{
							"_nr": "2",
							"__text": "MS-DOS Basics"
						},
						{
							"_nr": "3",
							"__text": "Managing Your System"
						},
						{
							"_nr": "4",
							"__text": "Configuring Your System"
						},
						{
							"_nr": "5",
							"__text": "Freeing Disk Space"
						},
						{
							"_nr": "6",
							"__text": "Making More Memory Available"
						},
						{
							"_nr": "7",
							"__text": "Features to Your Laptop Computer"
						},
						{
							"_nr": "8",
							"__text": "Diagnosing and Solving Problems"
						},
						{
							"_nr": "9",
							"__text": "Customizing for International Use"
						},
						{
							"_nr": "Appendix A",
							"__text": "Summary of MS-DOS Commands"
						},
						{
							"_nr": "Appendix B",
							"__text": "Accessibility to MS-DOS for Individuals with Disabilities"
						},
						{
							"_nr": "Appendix C",
							"__text": "Keybord Layouts and Character Sets"
						},
						{
							"_nr": "Appendix D",
							"__text": "Obtaining New Virus Signatures"
						},
						{
							"_nr": "Appendix E",
							"__text": "Freeing Disk Space by Using DriveSpace"
						}
					]
				},
				"_id": "1300",
				"_isbn": ""
			},
			{
				"titlu": "Gramatici contextuale",
				"autori": {
					"autor": {
						"_nume": "Gheorghe Paun"
					}
				},
				"anAparitie": "1982",
				"editura": "Academiei Republicii Socialiste Romania",
				"numarPagini": "156",
				"pretOriginal": "13.50",
				"pretCumparare": "50000",
				"redactori": {
					"redactor": "Luminita Zorilescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Elena Mattescu"
				},
				"coperta": {
					"imagine": {
						"_path": "Gramatici_contextuale_Paun.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni si rezultate din teoria limbajelor formale"
						},
						{
							"_nr": "2",
							"__text": "Gramatici contextuale Marcus"
						},
						{
							"_nr": "3",
							"__text": "Puterea generativa a gramaticilor contextuale Marcus"
						},
						{
							"_nr": "4",
							"__text": "Proprietati de inchidere la operatii cu limbaje"
						},
						{
							"_nr": "5",
							"__text": "Probleme de decizie"
						},
						{
							"_nr": "6",
							"__text": "Complexitatea sintactica a limbajelor contextuale"
						},
						{
							"_nr": "7",
							"__text": "Gramatici contextuale cu restrictii in derivare"
						},
						{
							"_nr": "8",
							"__text": "Variante ale gramaticilor contextuale"
						},
						{
							"_nr": "9",
							"__text": "Gramatici n-contextuale"
						},
						{
							"_nr": "10",
							"__text": "Alte probleme privind gramaticile si limbajele contextuale"
						}
					]
				},
				"_id": "1301",
				"_isbn": ""
			},
			{
				"titlu": "Curs de lingvistica matematica",
				"autori": {
					"autor": {
						"_nume": "Adrian Atanasiu"
					}
				},
				"anAparitie": "1998",
				"editura": "Universitatii din Bucuresti",
				"numarPagini": "204",
				"pretOriginal": "17100",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Curs_de_lingvistica_matematica_Atanasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Studiul limbajului"
						},
						{
							"_nr": "2",
							"__text": "Elemente de lingvistica. Sintaxa limbii"
						},
						{
							"_nr": "3",
							"__text": "Notiuni de morfologie"
						},
						{
							"_nr": "4",
							"__text": "Gramatici si analizatori sintactici"
						},
						{
							"_nr": "5",
							"__text": "Alte modele gramaticale"
						},
						{
							"_nr": "6",
							"__text": "Gramatici pe arbori"
						},
						{
							"_nr": "7",
							"__text": "Expresii ale cfg-urilor"
						},
						{
							"_nr": "8",
							"__text": "Utilizarea caracteristicilor in analiza"
						},
						{
							"_nr": "9",
							"__text": "Semantici si logica formala"
						},
						{
							"_nr": "10",
							"__text": "Modele si structuri semantice"
						},
						{
							"_nr": "11",
							"__text": "Semantica si teoria modelelor"
						},
						{
							"_nr": "12",
							"__text": "Reprezentarea universului cunoasterii"
						},
						{
							"_nr": "13",
							"__text": "Rationament si cunoastere"
						},
						{
							"_nr": "14",
							"__text": "Contextul local al discursului"
						},
						{
							"_nr": "15",
							"__text": "Propozitii eliptice si anafora"
						},
						{
							"_nr": "16",
							"__text": "Agentul conversational"
						},
						{
							"_nr": "Anaxa 1",
							"__text": "Calculul cu predicate de ordinul I"
						}
					]
				},
				"_id": "1302",
				"_isbn": "973-575-270-0"
			},
			{
				"titlu": "Programare C/C++",
				"autori": {
					"autor": [
						{
							"_nume": "Vasile Lungu"
						},
						{
							"_nume": "Gheorghe Petrescu"
						}
					]
				},
				"anAparitie": "2009",
				"editura": "Printech",
				"numarPagini": "208",
				"pretOriginal": "0",
				"pretCumparare": "0",
				"coperta": {
					"imagine": {
						"_path": "Programare_C_si_Cpp_Lungu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Structura unui program C"
						},
						{
							"_nr": "3",
							"__text": "Variabile si tipuri de date"
						},
						{
							"_nr": "4",
							"__text": "Operatorii limbajului C"
						},
						{
							"_nr": "5",
							"__text": "Instructiuni"
						},
						{
							"_nr": "6",
							"__text": "Tipuri de date structurate"
						},
						{
							"_nr": "7",
							"__text": "Functii"
						},
						{
							"_nr": "8",
							"__text": "Introducere in programarea orientata pe obiecte"
						},
						{
							"_nr": "9",
							"__text": "Structura programelor in C++"
						},
						{
							"_nr": "10",
							"__text": "Trecerea de la C la C++"
						},
						{
							"_nr": "11",
							"__text": "Caracteristicile limbajului C++"
						},
						{
							"_nr": "12",
							"__text": "Supraincarcarea operatorilor"
						},
						{
							"_nr": "13",
							"__text": "Derivarea claselor. Polimorfism"
						}
					]
				},
				"_id": "1303",
				"_isbn": "978-973-521-397-5"
			},
			{
				"titlu": "Sisteme de prelucrare grafica",
				"autori": {
					"autor": [
						{
							"_nume": "Rodica Baciu"
						},
						{
							"_nume": "Daniel Volovici"
						}
					]
				},
				"anAparitie": "1999",
				"editura": "Albastra",
				"numarPagini": "524",
				"pretOriginal": "150000",
				"pretCumparare": "0",
				"colectia": {
					"_numarul": "88",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": "Sisteme_de_prelucrare_grafica_Baciu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Grafica pe calculator"
						},
						{
							"_nr": "2",
							"__text": "Privire generala asupra sistemelor grafice"
						},
						{
							"_nr": "3",
							"__text": "Primitive de iesire"
						},
						{
							"_nr": "4",
							"__text": "Atribute ale primitivelor de iesire"
						},
						{
							"_nr": "5",
							"__text": "Transformari in doua dimensiuni"
						},
						{
							"_nr": "6",
							"__text": "Transformarea de vizualizare si algoritmi de decupare"
						},
						{
							"_nr": "7",
							"__text": "Segmente"
						},
						{
							"_nr": "8",
							"__text": "Interfata cu utilizatorul"
						},
						{
							"_nr": "9",
							"__text": "Concepte de grafica tridimensionala"
						},
						{
							"_nr": "10",
							"__text": "Transformari de vizualizare 3D"
						},
						{
							"_nr": "11",
							"__text": "Transformari geometrice tridimensionale"
						},
						{
							"_nr": "12",
							"__text": "Reprezentarea curbelor si a suprafetelor"
						},
						{
							"_nr": "13",
							"__text": "Modelarea corpurilor solide"
						},
						{
							"_nr": "14",
							"__text": "Stergerea suprafetelor si a liniilor ascunse"
						},
						{
							"_nr": "15",
							"__text": "Modele de culoare"
						},
						{
							"_nr": "16",
							"__text": "Iluminarea si umbrirea suprafetelor"
						},
						{
							"_nr": "Anexa 1",
							"__text": "Fractali Mandelbrot"
						},
						{
							"_nr": "Anexa 2",
							"__text": "Fractali autopatratici"
						},
						{
							"_nr": "Anexa 3",
							"__text": "Inversul fractalului autopatratic"
						},
						{
							"_nr": "Anexa 4",
							"__text": "Inversul fractalului autopatratic x 2"
						},
						{
							"_nr": "Anexa 5",
							"__text": "Nivele de intensitate"
						},
						{
							"_nr": "Anexa 6",
							"__text": "Puncte de halfton"
						},
						{
							"_nr": "Anexa 7",
							"__text": "Separatie de culoare"
						},
						{
							"_nr": "Anexa 8",
							"__text": "Algoritmi de iluminare globala"
						},
						{
							"_nr": "Anexa 9",
							"__text": "Aplicatie de grafica 3D"
						}
					]
				},
				"_id": "1304",
				"_isbn": "973-9215-99-8"
			},
			{
				"titlu": "AutoCAD 2008 si AutoCAD LT 2008",
				"autori": {
					"autor": [
						{
							"_nume": "David Frey"
						},
						{
							"_nume": "Jon McFarland"
						}
					]
				},
				"anAparitie": "2008",
				"editura": "Teora",
				"numarPagini": "714",
				"pretOriginal": "0",
				"pretCumparare": "10000",
				"traducere": {
					"titluOriginal": "AutoCAD 2008 and AutoCAD LT 2008",
					"editura": "Wiley Publishing",
					"traducatori": {
						"traducator": "Simona Preda"
					},
					"_an": "2008",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": "AutoCAD_2008_si_AutoCAD_LT_2008_Frey.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Prezentarea programului AutoCAD"
						},
						{
							"_nr": "2",
							"__text": "Cunoasterea comenzilor principale"
						},
						{
							"_nr": "3",
							"__text": "Configurarea desenului"
						},
						{
							"_nr": "4",
							"__text": "Strategii de desenare : partea I"
						},
						{
							"_nr": "5",
							"__text": "Strategii de desenare : partea a II-a"
						},
						{
							"_nr": "6",
							"__text": "Utilizarea straturilor in vederea organizarii desenului"
						},
						{
							"_nr": "7",
							"__text": "Gruparea obiectelor in blocuri"
						},
						{
							"_nr": "8",
							"__text": "Controlarea textului de pe desen"
						},
						{
							"_nr": "9",
							"__text": "Utilizarea blocurilor dinamice si a tabelelor"
						},
						{
							"_nr": "10",
							"__text": "Generarea elevatiilor"
						},
						{
							"_nr": "11",
							"__text": "Utilizarea hasurilor si a degradeurilor"
						},
						{
							"_nr": "12",
							"__text": "Introducerea cotelor pe desen"
						},
						{
							"_nr": "13",
							"__text": "Utilizarea referintelor externe"
						},
						{
							"_nr": "14",
							"__text": "Utilizarea machetelor pentru configurarea tiparii"
						},
						{
							"_nr": "15",
							"__text": "Tiparirea desenului 3D"
						},
						{
							"_nr": "16",
							"__text": "Crearea geometriei 3D"
						},
						{
							"_nr": "17",
							"__text": "Materiale si randare"
						}
					]
				},
				"_id": "1305",
				"_isbn": "978-973-20-1110-2"
			},
			{
				"titlu": "Manual de informatica pentru clasa a IX-a. Algoritmi si limbaje de programare",
				"autori": {
					"autor": {
						"_nume": "Bogdan Patrut"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "264",
				"pretOriginal": "30000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "243",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": "Manual_de_informatica_pentru_clasa_a_IX_a_Algoritmi_si_limbaje_de_programare_Patrut.jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Algoritmi. Programare structurata"
						},
						{
							"_nr": "2",
							"__text": "Elementele de baza ale limbajului Pascal"
						},
						{
							"_nr": "3",
							"__text": "Structuri de control"
						},
						{
							"_nr": "4",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "5",
							"__text": "Subprograme"
						},
						{
							"_nr": "6",
							"__text": "Unitati de program"
						},
						{
							"_nr": "7",
							"__text": "Fisiere"
						},
						{
							"_nr": "8",
							"__text": "Grafica"
						}
					]
				},
				"_id": "1306",
				"_isbn": "973-601-714-1"
			},
			{
				"titlu": "Visual Basic 3.0",
				"autori": {
					"autor": {
						"_nume": "Lucian Vasiu"
					}
				},
				"anAparitie": "1996",
				"editura": "Tehnica",
				"numarPagini": "256",
				"pretOriginal": "7000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "0",
					"_nume": "Seria Microsoft"
				},
				"tehnoredactori": {
					"tehnoredactor": "Victoria Ungureanu"
				},
				"coperta": {
					"imagine": {
						"_path": "Visual_Basic_3_0_Vasiu.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea intai",
							"__text": "Studiu introductiv"
						},
						{
							"_nr": "Partea a doua",
							"__text": "Crearea interfetei"
						},
						{
							"_nr": "Partea a treia",
							"__text": "Programarea in Visual Basic"
						},
						{
							"_nr": "Partea a patra",
							"__text": "Aplicatii in Visual Basic 3.0"
						}
					]
				},
				"_id": "1307",
				"_isbn": "973-31-0747-6"
			},
			{
				"titlu": "Turbo Pascal  - Algoritmi si limbaje de programare [IX]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1996",
				"editura": "L and S InfoMat",
				"numarPagini": "330",
				"pretOriginal": "12000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Turbo_Pascal_Algoritmi_si_limbaje_de_programare_IX_Sorin_1996.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni de algoritm, caracteristici"
						},
						{
							"_nr": "2",
							"__text": "Principiile programarii structurate"
						},
						{
							"_nr": "3",
							"__text": "Elemente de baza ale limbajului Pascal"
						},
						{
							"_nr": "4",
							"__text": "Instructiunile limbajului Turbo Pascal"
						},
						{
							"_nr": "5",
							"__text": "Tipuri structurate de date"
						},
						{
							"_nr": "6",
							"__text": "Subprograme"
						},
						{
							"_nr": "7",
							"__text": "Unitatea de program CRT"
						},
						{
							"_nr": "8",
							"__text": "Fisiere Pascal"
						},
						{
							"_nr": "9",
							"__text": "Unitatea de program DOS"
						},
						{
							"_nr": "10",
							"__text": "Grafica pe calculator"
						}
					]
				},
				"_id": "1308",
				"_isbn": "973-96382-4-4"
			},
			{
				"titlu": "Sisteme de calcul si operare",
				"autori": {
					"autor": [
						{
							"_nume": "Gheorghe Dodescu"
						},
						{
							"_nume": "Stefan Dan"
						},
						{
							"_nume": "Floarea Nastase"
						},
						{
							"_nume": "Cristina Papastere"
						}
					]
				},
				"anAparitie": "1995",
				"editura": "Aldo",
				"numarPagini": "324",
				"pretOriginal": "5457",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": [
						"Marcel Voica",
						"Stefan Preda"
					]
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Marcel Voica",
						"Stefan Preda",
						"Diana Olaru",
						"Mariana Oprescu",
						"Valeria Abaluta",
						"Doina Onel"
					]
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Elemente de teoria transmisiei informatiei"
						},
						{
							"_nr": "2",
							"__text": "Bazele numerice ale calculatoarelor"
						},
						{
							"_nr": "3",
							"__text": "Bazele logice ale calculatoarelor"
						},
						{
							"_nr": "4",
							"__text": "Microprocesoare"
						},
						{
							"_nr": "5",
							"__text": "Sisteme de operare"
						},
						{
							"_nr": "6",
							"__text": "Editorul Edlin"
						},
						{
							"_nr": "7",
							"__text": "Sistemul de fisiere sub UNIX"
						},
						{
							"_nr": "8",
							"__text": "Profilul lucrarilor si metodelor de exploatarea sistemelor de calcul"
						},
						{
							"_nr": "9",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "10",
							"__text": "Multiprocesare si procesare distribuita"
						},
						{
							"_nr": "11",
							"__text": "Procesare paralela"
						}
					]
				},
				"_id": "1309",
				"_isbn": "973-95353-0-5"
			},
			{
				"titlu": "Baze de date in Microsoft Access 2000",
				"autori": {
					"autor": [
						{
							"_nume": "Pavel Nastase"
						},
						{
							"_nume": "Liana Elena Covrig"
						},
						{
							"_nume": "Bogdan Florian Barbulescu"
						},
						{
							"_nume": "Luana Virginia Cosacescu"
						},
						{
							"_nume": "Florin Mihai"
						},
						{
							"_nume": "Andrei Stanciu"
						},
						{
							"_nume": "Robert Aurelian Sova"
						}
					]
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "336",
				"pretOriginal": "59000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Gheorge Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "Niveluri si modele de reprezentare a datelor in bazele de date"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de gestiune a bazelor de date Access 2000"
						},
						{
							"_nr": "4",
							"__text": "Limbajul SQL"
						},
						{
							"_nr": "5",
							"__text": "Limbajul Visual Basic pentru Access 2000"
						},
						{
							"_nr": "6",
							"__text": "Aplicatii practice"
						}
					]
				},
				"_id": "1310",
				"_isbn": "973-20-0437-1"
			},
			{
				"titlu": "Bazele programarii in C++ [XI]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1999",
				"editura": "L and S InfoMat",
				"numarPagini": "304",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "De la Turbo Pascal la C++"
						},
						{
							"_nr": "2",
							"__text": "Intrari-iesiri standard"
						},
						{
							"_nr": "3",
							"__text": "Instructiunile limbajului C++"
						},
						{
							"_nr": "4",
							"__text": "Pointeri"
						},
						{
							"_nr": "5",
							"__text": "Masive"
						},
						{
							"_nr": "6",
							"__text": "Functii"
						},
						{
							"_nr": "7",
							"__text": "Programarea orientata spre obiecte"
						},
						{
							"_nr": "8",
							"__text": "Stream-uri"
						},
						{
							"_nr": "9",
							"__text": "Preprocesorul"
						}
					]
				},
				"_id": "1311",
				"_isbn": "973-96382-7-9"
			},
			{
				"titlu": "Fundamentele programarii. Culegere de probleme pentru clasa a X-a",
				"autori": {
					"autor": [
						{
							"_nume": "Dana Lica"
						},
						{
							"_nume": "Mircea Pasoi"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "L and S InfoMat",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tipuri de date structurate"
						},
						{
							"_nr": "2",
							"__text": "Subprograme definite de utilizator"
						}
					]
				},
				"_id": "1312",
				"_isbn": "973-7658-02-7"
			},
			{
				"titlu": "Informatica intensiv [XI]",
				"autori": {
					"autor": [
						{
							"_nume": "Vlad Hutanu"
						},
						{
							"_nume": "Tudor Sorin"
						}
					]
				},
				"anAparitie": "2006",
				"editura": "L and S Soft",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Alocarea dinamica a memoriei"
						},
						{
							"_nr": "2",
							"__text": "Liste liniare"
						},
						{
							"_nr": "3",
							"__text": "Metoda Divide et Impera"
						},
						{
							"_nr": "4",
							"__text": "Metoda Backtracking"
						},
						{
							"_nr": "5",
							"__text": "Metoda Greedy"
						},
						{
							"_nr": "6",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "7",
							"__text": "Grafuri neorientate"
						},
						{
							"_nr": "8",
							"__text": "Grafuri orientate"
						},
						{
							"_nr": "9",
							"__text": "Arbori"
						}
					]
				},
				"_id": "1313",
				"_isbn": "973-7658-06-X"
			},
			{
				"titlu": "Tehnici de programare si structuri de date[X]",
				"autori": {
					"autor": {
						"_nume": "Tudor Sorin"
					}
				},
				"anAparitie": "1995",
				"editura": "L and S InfoMat",
				"numarPagini": "252",
				"pretOriginal": "7500",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": "Tehnici_de_programare_Manual_X_Sorin.jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Stiva"
						},
						{
							"_nr": "2",
							"__text": "Metoda backtracking"
						},
						{
							"_nr": "3",
							"__text": "Recursivitate"
						},
						{
							"_nr": "4",
							"__text": "Tehnica Divide et Impera"
						},
						{
							"_nr": "5",
							"__text": "Structuri de date"
						},
						{
							"_nr": "6",
							"__text": "Tehnica Branch and Bound"
						},
						{
							"_nr": "7",
							"__text": "Programare dinamica"
						},
						{
							"_nr": "8",
							"__text": "Tehnica Greedy"
						},
						{
							"_nr": "9",
							"__text": "Analiza timpului de calcul necesar algoritmilor"
						},
						{
							"_nr": "10",
							"__text": "Consideratii asupra olimpiadelor de informatica"
						},
						{
							"_nr": "11",
							"__text": "Aplicatii ale recursivitatii in grafica"
						}
					]
				},
				"_id": "1314",
				"_isbn": "973-96382-3-6"
			},
			{
				"titlu": "Word 6 pentru Windows",
				"autori": {
					"autor": {
						"_nume": "Susanne Weixel"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "400",
				"pretOriginal": "15900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "58",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Word 6 for Windows Quickstart",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Cora Radulian"
					},
					"_an": "1994",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Crearea si modificarea documentelor elementare"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Aranjarea in pagina pentru a obtine un aspect profesional"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Adaptarea la cerintele utilizatorului si adaugarea in documente a altor elemente in afara de text"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Caracteristici evolutate si integrare"
						}
					]
				},
				"_id": "1315",
				"_isbn": "973-601-341-3"
			},
			{
				"titlu": "Utilizare Windows 95",
				"autori": {
					"autor": {
						"_nume": "Ed Bott"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "512",
				"pretOriginal": "19900",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "98",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Using Windows 95",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Vladimir Visan"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Cum sa incepeti lucrul cu sistemul de operare Windows"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Cum sa controlati sistemul Windows"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Lucrul cu programe de aplicatii"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Cum sa procedati pentru ca Windows sa lucreze asa cum va place dumneavoastra"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "De pe ecranul calculatorului, pe pagina: tiparirea si seturile de caractere"
						},
						{
							"_nr": "Partea a VI-a",
							"__text": "Aprindeti reflectoarele, porniti aparatul de filmat, incepe actiunea: multimedia si multe alte distractii"
						},
						{
							"_nr": "Partea a VII-a",
							"__text": "Comunicati cu restul lumii"
						},
						{
							"_nr": "Partea a VIII-a",
							"__text": "Depanarea sistemului Windows 95"
						}
					]
				},
				"_id": "1316",
				"_isbn": "973-601-292-1"
			},
			{
				"titlu": "Programe antivirus",
				"autori": {
					"autor": {
						"_nume": "George Dimitriu"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "248",
				"pretOriginal": "27000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "216",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Generalitati"
						},
						{
							"_nr": "II",
							"__text": "Functionarea unui virus"
						},
						{
							"_nr": "III",
							"__text": "Clasificarea programelor antivirus"
						},
						{
							"_nr": "IV",
							"__text": "Exemple de virusi MS-DOS"
						},
						{
							"_nr": "V",
							"__text": "Programe antivirus MS-DOS"
						},
						{
							"_nr": "VI",
							"__text": "Exemple de virusi Windows 95"
						},
						{
							"_nr": "VII",
							"__text": "Programe antivirus Windows 95"
						},
						{
							"_nr": "VIII",
							"__text": "Metoda optima de prevenire a infectatii cu virusi"
						},
						{
							"_nr": "IX",
							"__text": "Metode de devirusare"
						},
						{
							"_nr": "X",
							"__text": "Bibligrafie"
						}
					]
				},
				"_id": "1317",
				"_isbn": "973-20-0437-1"
			},
			{
				"titlu": "Oracle PowerObjects fara profesor in 21 de zile",
				"autori": {
					"autor": {
						"_nume": "Tom Grant"
					}
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "808",
				"pretOriginal": "120000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "233",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Teach Yourself Orecle Power Objects in 21 Days",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": [
							"Cristina Botez",
							"Florin Popa",
							"Cristian Mocanu"
						]
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Primul dumneavoastra formular"
						},
						{
							"_nr": "2",
							"__text": "Crearea unei tabele"
						},
						{
							"_nr": "3",
							"__text": "Crearea unei vederi"
						},
						{
							"_nr": "4",
							"__text": "Conectarea unui formular la o sursa de inregistrari"
						},
						{
							"_nr": "5",
							"__text": "Relatii master-detail"
						},
						{
							"_nr": "6",
							"__text": "Mai multe despre seturile de inregistrari"
						},
						{
							"_nr": "7",
							"__text": "Rapoarte"
						},
						{
							"_nr": "8",
							"__text": "Valori derivate"
						},
						{
							"_nr": "9",
							"__text": "Meniuri si bare cu instrumente"
						},
						{
							"_nr": "10",
							"__text": "Biblioteci legate dinamic (DLL)"
						},
						{
							"_nr": "11",
							"__text": "Imagini, dar nu numai atat"
						},
						{
							"_nr": "12",
							"__text": "Obiecte avansate de baze de date"
						},
						{
							"_nr": "13",
							"__text": "Impunerea regulilor de lucru"
						},
						{
							"_nr": "14",
							"__text": "Proprietati si metode definite de utilizator"
						},
						{
							"_nr": "15",
							"__text": "Adaugarea uneor elemente de securitate la aplicatia dumneavoastra"
						},
						{
							"_nr": "16",
							"__text": "Clase definite de utilizator"
						},
						{
							"_nr": "17",
							"__text": "Modul de lucru cu obiectele OLE"
						},
						{
							"_nr": "18",
							"__text": "Scrierea codului SQL"
						},
						{
							"_nr": "19",
							"__text": "Imbunatatiri ale mediului de lucru"
						},
						{
							"_nr": "20",
							"__text": "Cresterea performantelor sistemului"
						},
						{
							"_nr": "21",
							"__text": "Finisarea aplicatiei dumneavoastra"
						},
						{
							"_nr": "Anexa A",
							"__text": "Foile de lucru pentru proprietatile si metodele definite de utilizator"
						},
						{
							"_nr": "Anexa B",
							"__text": "De la Visual Basic la Oracle Power Objects"
						},
						{
							"_nr": "Anexa C",
							"__text": "Standarde GUI pentru aplicatii Orecle Power Objects"
						}
					]
				},
				"_id": "1318",
				"_isbn": "973-601-711-7"
			},
			{
				"titlu": "Word 7 sub Windows 95",
				"autori": {
					"autor": {
						"_nume": "Guy Hart-Davis"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "408",
				"pretOriginal": "19000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "175",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The ABC of Word for Windows 95",
					"editura": "Sybex",
					"traducatori": {
						"traducator": "Florin Moraru"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Alina Popa"
				},
				"tehnoredactori": {
					"tehnoredactor": "Dragos Chitu"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sa incepem lucrul cu programul Word"
						},
						{
							"_nr": "2",
							"__text": "Formatarea unui document"
						},
						{
							"_nr": "3",
							"__text": "Tiparirea unui document"
						},
						{
							"_nr": "4",
							"__text": "Componente pentru imbunatatirea textului"
						},
						{
							"_nr": "5",
							"__text": "Lucrul cu antete si subsoluri"
						},
						{
							"_nr": "6",
							"__text": "Folosirea comenzilor Find si Replace"
						},
						{
							"_nr": "7",
							"__text": "Coloane si tabele"
						},
						{
							"_nr": "8",
							"__text": "Sortarea informatiilor"
						},
						{
							"_nr": "9",
							"__text": "Combinarea corespondentei"
						},
						{
							"_nr": "10",
							"__text": "Folosirea componentelor Word de automatizare a procesului de creare a documentelor"
						},
						{
							"_nr": "11",
							"__text": "Planuri"
						},
						{
							"_nr": "12",
							"__text": "Tabele de cuprins si indexuri"
						},
						{
							"_nr": "13",
							"__text": "Documente principale"
						},
						{
							"_nr": "14",
							"__text": "Campuri"
						},
						{
							"_nr": "15",
							"__text": "Macrocomenzi"
						},
						{
							"_nr": "16",
							"__text": "Formulare"
						},
						{
							"_nr": "17",
							"__text": "Adaptarea programului Word"
						},
						{
							"_nr": "18",
							"__text": "Posibilitati de lucru in grup"
						},
						{
							"_nr": "19",
							"__text": "OLE si dosarele Office"
						},
						{
							"_nr": "20",
							"__text": "Internet Assistant"
						},
						{
							"_nr": "Anexa",
							"__text": "Instalarea programului Word sub Windows 95"
						}
					]
				},
				"_id": "1319",
				"_isbn": "973-601-492-4"
			},
			{
				"titlu": "Windows 95",
				"autori": {
					"autor": {
						"_nume": "Sharon Crawford"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "376",
				"pretOriginal": "18000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "166",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The ABC of Windows 95",
					"editura": "Sybex",
					"traducatori": {
						"traducator": "Mihaela Tarpa"
					},
					"_an": "1996",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Dan Petac"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Valentin Tanase"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este nou in Windows 95"
						},
						{
							"_nr": "2",
							"__text": "Sa incepem cu inceputul (sau sa continuam, in cazul nostru)"
						},
						{
							"_nr": "3",
							"__text": "Sa vedem ce mai este nou prin jur"
						},
						{
							"_nr": "4",
							"__text": "Sa o luam pe scurtatura"
						},
						{
							"_nr": "5",
							"__text": "Noile puteri ale mouse-ului"
						},
						{
							"_nr": "6",
							"__text": "Pornim in exploatare"
						},
						{
							"_nr": "7",
							"__text": "Fisiere si dosoare"
						},
						{
							"_nr": "8",
							"__text": "Lansarea programelor"
						},
						{
							"_nr": "9",
							"__text": "Recycle Bin"
						},
						{
							"_nr": "10",
							"__text": "Folosirea sistemului de operare MS-DOS"
						},
						{
							"_nr": "11",
							"__text": "Echipamentul hard mai pe intelesul nostru"
						},
						{
							"_nr": "12",
							"__text": "Privelisti si sunete"
						},
						{
							"_nr": "13",
							"__text": "Pe penoul de control"
						},
						{
							"_nr": "14",
							"__text": "Creati-va proria retea"
						},
						{
							"_nr": "15",
							"__text": "Conectati-va la lumea exterioara"
						},
						{
							"_nr": "16",
							"__text": "Un manunchi de programele"
						},
						{
							"_nr": "17",
							"__text": "Instrumente care va mentin sistemul in forma"
						}
					]
				},
				"_id": "1320",
				"_isbn": "973-601-493-2"
			},
			{
				"titlu": "Utilizare Internet (editia II)",
				"autori": {
					"autor": {
						"_nume": "Jerry Honeycutt"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "336",
				"pretOriginal": "50000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "257",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Using the Internet",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": [
							"Cristian Fotache",
							"Mihaela Carasca"
						]
					},
					"_an": "1997",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Ce este Internetul? Imi foloseste la ceva?"
						},
						{
							"_nr": "II",
							"__text": "Conectarea la Internet"
						},
						{
							"_nr": "III",
							"__text": "Folosirea conexiunii Internet"
						},
						{
							"_nr": "IV",
							"__text": "Experimentati ceea ce ati invatat"
						},
						{
							"_nr": "V",
							"__text": "Siguranta in Internet"
						}
					]
				},
				"_id": "1321",
				"_isbn": "973-601-629-3"
			},
			{
				"titlu": "Programare si baze de date",
				"autori": {
					"autor": {
						"_nume": "Zenovic Gherasim"
					}
				},
				"anAparitie": "2005",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "236",
				"pretOriginal": "132980",
				"redactori": {
					"redactor": "Constantin Florea"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Vasilichia Ionescu",
						"Georgina Gheorghe"
					]
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Maria Andronie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Data, informaie si cunostinta. Organizarea datelor in vederea prelucrarii automate cu ajutorul calculatoarelor electronice"
						},
						{
							"_nr": "2",
							"__text": "Rezolvarea problemelor economice cu ajutorul programelor pe calculator. Programare"
						},
						{
							"_nr": "3",
							"__text": "Limbajul de programare Visual Basic"
						},
						{
							"_nr": "4",
							"__text": "Teoria bazelor de date si a sistemelor de gestiune a bazelor de date"
						},
						{
							"_nr": "5",
							"__text": "Sistemul de gestiune a bazelor de date Microsoft Access"
						},
						{
							"_nr": "6",
							"__text": "Limbajul structurat de interograre, SQL"
						},
						{
							"_nr": "7",
							"__text": "Elemente fundamentale ale serverelor de baze de date"
						},
						{
							"_nr": "8",
							"__text": "Baze de date distribuite"
						},
						{
							"_nr": "9",
							"__text": "Baze de date avansate"
						}
					]
				},
				"_id": "1322",
				"_isbn": "973-725-096-6"
			},
			{
				"titlu": "Aplicatii economice in Visual Basic si Access",
				"autori": {
					"autor": [
						{
							"_nume": "Doina Fusaru"
						},
						{
							"_nume": "Zenovic Gherasim"
						},
						{
							"_nume": "Adela Bara"
						},
						{
							"_nume": "Maria Andronie"
						},
						{
							"_nume": "Petrisor Stroe"
						}
					]
				},
				"anAparitie": "2005",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "236",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Maria Cernea"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Maria Andronie",
						"Marcela Olaru"
					]
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Maria Andronie"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Visual Basic"
						},
						{
							"_nr": "2",
							"__text": "Access"
						},
						{
							"_nr": "3",
							"__text": "Aplicatii economice complexe"
						},
						{
							"_nr": "4",
							"__text": "Exemple de teste grila pentru examenul de licenta"
						}
					]
				},
				"_id": "1323",
				"_isbn": "973-725-295-0"
			},
			{
				"titlu": "Analiza datelor",
				"autori": {
					"autor": [
						{
							"_nume": "Cristian Mihai Pomohaci"
						},
						{
							"_nume": "Daniela Parlea"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Fundatiei Romania de Maine",
				"numarPagini": "180",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Cosmin Comarnescu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Marcela Olaru"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Marilena Balan"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Notiuni preliminare"
						},
						{
							"_nr": "2",
							"__text": "Reprezentarea datelor ca mod de examinare preliminara a fenomenului studiat"
						},
						{
							"_nr": "3",
							"__text": "Statistica in cercetarea sociala"
						},
						{
							"_nr": "4",
							"__text": "Notiuni introductive. Despre grafuri si baze de date"
						},
						{
							"_nr": "5",
							"__text": "Utilizarea EXCEL si SPSS in statistica"
						}
					]
				},
				"_id": "1324",
				"_isbn": "978-973-725-753-6"
			},
			{
				"titlu": "Manual de informatica pentru clasa a XII-a",
				"autori": {
					"autor": {
						"_nume": "Carmen Popescu"
					}
				},
				"anAparitie": "2007",
				"editura": "L and S InfoMat",
				"numarPagini": "320",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Proiectarea bazelor de date"
						},
						{
							"_nr": "I.1",
							"__text": "Proiectarea bazelor de date. Notiuni introductive"
						},
						{
							"_nr": "I.2",
							"__text": "Normalizarea datelor"
						},
						{
							"_nr": "I.3",
							"__text": "Implementarea modelului conceptual"
						},
						{
							"_nr": "I.4",
							"__text": "Elemente avansate de proiectare a bazelor de date"
						},
						{
							"_nr": "I.5",
							"__text": "Dezvoltarea profesionala in domeniul IT"
						},
						{
							"_nr": "I.6",
							"__text": "Managementul de proiect"
						},
						{
							"_nr": "Partea II",
							"__text": "Programarea bazelor de date"
						},
						{
							"_nr": "I.1",
							"__text": "Interogari simple. Sortarea datelor"
						},
						{
							"_nr": "I.2",
							"__text": "Functii singulare"
						},
						{
							"_nr": "I.3",
							"__text": "Interogari multiple"
						},
						{
							"_nr": "I.4",
							"__text": "Gruparea datelor"
						},
						{
							"_nr": "I.5",
							"__text": "Subinterogari"
						},
						{
							"_nr": "I.6",
							"__text": "Crearea si modificarea structurii tabelelor. Constrangeri"
						},
						{
							"_nr": "I.7",
							"__text": "Introducerea si actualizarea datelor din tabele"
						},
						{
							"_nr": "I.8",
							"__text": "Vederi (views)"
						},
						{
							"_nr": "I.9",
							"__text": "Secvente. Indecsi. Sinonime"
						},
						{
							"_nr": "I.10",
							"__text": "Alocarea si revolarea drepturilor"
						},
						{
							"_nr": "I.11",
							"__text": "Realizarea proiectelor"
						},
						{
							"_nr": "I.12",
							"__text": "Aplicatii recapitulative"
						}
					]
				},
				"_id": "1325",
				"_isbn": "978-973-7658-11-1"
			},
			{
				"titlu": "Birotica",
				"autori": {
					"autor": [
						{
							"_nume": "Adrian Pana"
						},
						{
							"_nume": "Bogdan Ionescu"
						},
						{
							"_nume": "Valerica Mares"
						}
					]
				},
				"anAparitie": "1994",
				"editura": "All",
				"numarPagini": "302",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Catalin Fratila"
				},
				"tehnoredactori": {
					"tehnoredactor": [
						"Marcel Fagar",
						"Monica Fagar",
						"Catalin Fratila"
					]
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Carmen Fundi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Locul biroticii in societatea moderna"
						},
						{
							"_nr": "2",
							"__text": "Functiile si structura sistemelor informatice de birou"
						},
						{
							"_nr": "3",
							"__text": "Sistemul grafic de operare Windows v.3.1"
						},
						{
							"_nr": "4",
							"__text": "Procesorul de texte Word sub Windows"
						},
						{
							"_nr": "5",
							"__text": "Procesorul de tabele Lotus 1-2-3/3.1"
						},
						{
							"_nr": "6",
							"__text": "Procesoare integrate Works"
						}
					]
				},
				"_id": "1326",
				"_isbn": "973-9156-74-6"
			},
			{
				"titlu": "Tehnologia informatiei si a comunicatiei [X]",
				"autori": {
					"autor": [
						{
							"_nume": "Daniela Oprescu"
						},
						{
							"_nume": "Cristina-Eugenia Damacus"
						}
					]
				},
				"anAparitie": "2011",
				"editura": "Niculescu",
				"numarPagini": "128",
				"pretOriginal": "37500",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Utilizarea aplicatiilor software specializate pentru realizarea unei prezentari - PowerPoint"
						},
						{
							"_nr": "2",
							"__text": "Utilizarea aplicatiilor software specializate pentru calcul tabelar - Excel"
						},
						{
							"_nr": "3",
							"__text": "Utilizarea aplicatiilor software specializate pentru baze de date - Access"
						}
					]
				},
				"_id": "1327",
				"_isbn": "973-87247-4-8"
			},
			{
				"titlu": "Tehnologia informatiei si a comunicatiei [IX]",
				"autori": {
					"autor": [
						{
							"_nume": "Daniela Oprescu"
						},
						{
							"_nume": "Cristina-Eugenia Damacus"
						}
					]
				},
				"anAparitie": "2011",
				"editura": "Niculescu",
				"numarPagini": "119",
				"pretOriginal": "28500",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Notiuni introductive"
						},
						{
							"_nr": "II",
							"__text": "Structura calculatorului personal"
						},
						{
							"_nr": "III",
							"__text": "Utilizarea mouse-ului si a tastaturii"
						},
						{
							"_nr": "IV",
							"__text": "Sistemul de operare Windows"
						},
						{
							"_nr": "V",
							"__text": "Editorul de texte Microsoft Word"
						},
						{
							"_nr": "VI",
							"__text": "Mijloace moderne de comunicare - Internetul"
						},
						{
							"_nr": "VII",
							"__text": "Mijloace pentru crearea unui pagini web"
						}
					]
				},
				"_id": "1328",
				"_isbn": "973-568-882-4"
			},
			{
				"titlu": "Windows 95 (Seria 10 minute)",
				"autori": {
					"autor": {
						"_nume": "Trudi Reisner"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "256",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "66",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "10 Minute Guide To Windows 95",
					"editura": "Que Corporation",
					"traducatori": {
						"traducator": "Silviu Petrescu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Suprafata de lucru a sistemului de operare Windows 95"
						},
						{
							"_nr": "2",
							"__text": "Lucrul cu ferestrele"
						},
						{
							"_nr": "3",
							"__text": "Folosirea meniurilor"
						},
						{
							"_nr": "4",
							"__text": "Folosirea casetelor de dialog"
						},
						{
							"_nr": "5",
							"__text": "Folosirea meniului de asisteneta Help in Windows 95"
						},
						{
							"_nr": "6",
							"__text": "Oprirea sistemului de operare Windows 95"
						},
						{
							"_nr": "7",
							"__text": "Lansarea si terminarea aplicatiilor"
						},
						{
							"_nr": "8",
							"__text": "Lucrul cu mai multe ferestre"
						},
						{
							"_nr": "9",
							"__text": "Copierea si mutarea informatiilor (datelor) intre ferestre"
						},
						{
							"_nr": "10",
							"__text": "Vizualizarea unitatilor de disc, a cataloagelor si a fisierelor prin fereastra My Computer"
						},
						{
							"_nr": "11",
							"__text": "Vizualizarea unitatilor de disc, a cataloagelor si a fisierelor cu ajutorul programului Windows Explorer"
						},
						{
							"_nr": "12",
							"__text": "Crearea si stergerea fisierelor si a cataloagelor"
						},
						{
							"_nr": "13",
							"__text": "Mutarea si copierea fisierelor si a cataloagelor"
						},
						{
							"_nr": "14",
							"__text": "Redenumirea si cautarea fisierelor si a cataloagelor"
						},
						{
							"_nr": "15",
							"__text": "Cum se folosesc fonturile Windows"
						},
						{
							"_nr": "16",
							"__text": "Pregatirea pentru tiparire"
						},
						{
							"_nr": "17",
							"__text": "Tiparirea cu ajutorul catalogului Printers"
						},
						{
							"_nr": "18",
							"__text": "Controlul infatisarii mediului Windows 95"
						},
						{
							"_nr": "19",
							"__text": "Configurarea dispozitivelor hardware"
						},
						{
							"_nr": "20",
							"__text": "Organizarea suprafetei de lucru"
						},
						{
							"_nr": "21",
							"__text": "Rularea aplicatiilor DOS"
						},
						{
							"_nr": "22",
							"__text": "Crearea unui document in WordPad"
						},
						{
							"_nr": "23",
							"__text": "Adaugarea imaginilor grafice cu aplicatia Paint"
						},
						{
							"_nr": "24",
							"__text": "Folosirea celorlalte accesorii"
						},
						{
							"_nr": "25",
							"__text": "Lucrul cu dispozitive multimedia"
						},
						{
							"_nr": "26",
							"__text": "Inretinearea sistemului Windows 95 cu ajutorul programelor utilitare"
						},
						{
							"_nr": "Anexa A",
							"__text": "Ajutor pentru utilizatorul care trece de la Windows 3.1 la Windows 95"
						},
						{
							"_nr": "Anexa B",
							"__text": "Despre retele"
						}
					]
				},
				"_id": "1329",
				"_isbn": "973-601-253-0"
			},
			{
				"titlu": "Imprimante",
				"autori": {
					"autor": {
						"_nume": "Luis Columbus"
					}
				},
				"anAparitie": "1995",
				"editura": "Teora",
				"numarPagini": "320",
				"pretOriginal": "12000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "90",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "The Simple Printer Book",
					"editura": "Alpha Books",
					"traducatori": {
						"traducator": [
							"Adina Pivniceru",
							"Camelia Diaconescu"
						]
					},
					"_an": "1993",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sa facem cunostinta cu imprimantele"
						},
						{
							"_nr": "2",
							"__text": "Sa intelegem cum functioneaza imprimantele cu roata margareta"
						},
						{
							"_nr": "3",
							"__text": "Sa facem cunostinta cu imprimantele matriciale (dot-matrix)"
						},
						{
							"_nr": "4",
							"__text": "Sa intelegem imprimantele cu jet de cerneala"
						},
						{
							"_nr": "5",
							"__text": "Sa intelegem imprimantele laser"
						},
						{
							"_nr": "6",
							"__text": "Sa intelegem imprimantele color"
						},
						{
							"_nr": "7",
							"__text": "Configurarea imprimantei"
						},
						{
							"_nr": "8",
							"__text": "Alegerea corpuri de litera"
						},
						{
							"_nr": "9",
							"__text": "Sugestii pentru tiparirea din programele Windows"
						},
						{
							"_nr": "10",
							"__text": "Rezolvarea unor probleme uzuale care apar la imprimante"
						},
						{
							"_nr": "11",
							"__text": "Intretinerea imprimantei"
						},
						{
							"_nr": "12",
							"__text": "Modernizarea imprimantei"
						},
						{
							"_nr": "13",
							"__text": "Cateva utilizari mai deosebite ale imprimantei"
						},
						{
							"_nr": "Anexa A",
							"__text": "Glosarul termenilor folositi in domeniul imprimantelor"
						},
						{
							"_nr": "Anexa B",
							"__text": "Firme care comercializeaza imprimante"
						}
					]
				},
				"_id": "1330",
				"_isbn": "973-601-253-0"
			},
			{
				"titlu": "SQL fara profesor, in 14 zile",
				"autori": {
					"autor": [
						{
							"_nume": "Jeff Perkins"
						},
						{
							"_nume": "Bryan Morgan"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Teora",
				"numarPagini": "400",
				"pretOriginal": "50000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "229",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Teach Yourself SQL in 14 Days",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Cristian Mocanu"
					},
					"_an": "1995",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere in SQL"
						},
						{
							"_nr": "2",
							"__text": "Introducere in interogare"
						},
						{
							"_nr": "3",
							"__text": "Expresii, conditii si operatori"
						},
						{
							"_nr": "4",
							"__text": "Functii"
						},
						{
							"_nr": "5",
							"__text": "Clauze"
						},
						{
							"_nr": "6",
							"__text": "Jonctiuni"
						},
						{
							"_nr": "7",
							"__text": "Subinterogari"
						},
						{
							"_nr": "8",
							"__text": "Manipularea datelor"
						},
						{
							"_nr": "9",
							"__text": "Crearea si intretinerea tabelelor"
						},
						{
							"_nr": "10",
							"__text": "Crearea vederilor si a indecsilor"
						},
						{
							"_nr": "11",
							"__text": "Controlul tranzactiilor"
						},
						{
							"_nr": "12",
							"__text": "Securitatea bazelor de date"
						},
						{
							"_nr": "13",
							"__text": "SQL incapsulat"
						},
						{
							"_nr": "14",
							"__text": "Tehnici SQL avansate"
						},
						{
							"_nr": "15",
							"__text": "Metode de folosire dinamica a SQL"
						}
					]
				},
				"_id": "1331",
				"_isbn": "973-601-742-9"
			},
			{
				"titlu": "Microsoft Visual FoxPro 5.0 - o carte pentru toti",
				"autori": {
					"autor": {
						"_nume": "Paul Petrus"
					}
				},
				"anAparitie": "1998",
				"editura": "Promedia Plus",
				"numarPagini": "528",
				"pretOriginal": "0",
				"pretCumparare": "50000",
				"colectia": {
					"_numarul": "5",
					"_nume": "-"
				},
				"redactori": {
					"redactor": "Serban Dronca"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Primi pasi"
						},
						{
							"_nr": "2",
							"__text": "Privire de ansamblu"
						},
						{
							"_nr": "3",
							"__text": "Obiecte in Visual FoxPro"
						},
						{
							"_nr": "4",
							"__text": "Organizarea informatiei"
						},
						{
							"_nr": "5",
							"__text": "Accesarea informatiei"
						},
						{
							"_nr": "6",
							"__text": "Citirea si afisarea informatiei"
						},
						{
							"_nr": "7",
							"__text": "Alte facilitati ale sistemului Visual FoxPro"
						},
						{
							"_nr": "8",
							"__text": "Aplicatii cu Visual FoxPro. Application Wizard"
						}
					]
				},
				"_id": "1332",
				"_isbn": "973-9275-16-8"
			},
			{
				"titlu": "Excel pentru Windows 95: usor si rapid",
				"autori": {
					"autor": {
						"_nume": "Gerald E. Jones"
					}
				},
				"anAparitie": "1996",
				"editura": "All Education",
				"numarPagini": "196",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"traducere": {
					"titluOriginal": "Excel for Windows 95 Quick and Easy",
					"editura": "Sybex Inc.",
					"traducatori": {
						"traducator": "Marius Somodi"
					},
					"_an": "1996",
					"_editia": "I",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Andreea Lutic"
				},
				"tehnoredactori": {
					"tehnoredactor": "Luminita Para"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lectia 1",
							"__text": "Faceti cunostinta cu foile de lucru"
						},
						{
							"_nr": "Lectia 2",
							"__text": "Prezentarea unei foi de lucru"
						},
						{
							"_nr": "Lectia 3",
							"__text": "Calculul soldului contului bancar"
						},
						{
							"_nr": "Lectia 4",
							"__text": "Proiectarea unui raport de cheltuieli"
						},
						{
							"_nr": "Lectia 5",
							"__text": "Formatarea raportului de cheltuieli"
						},
						{
							"_nr": "Lectia 6",
							"__text": "Previzualizarea si imprimarea raportului de cheltuili"
						},
						{
							"_nr": "Lectia 7",
							"__text": "Sortarea cheltuielilor dupa conturi"
						},
						{
							"_nr": "Lectia 8",
							"__text": "Crearea unui rezumat al registrului"
						},
						{
							"_nr": "Lectia 9",
							"__text": "Legarea foilor de lucru intr-un registru"
						},
						{
							"_nr": "Lectia 10",
							"__text": "Crearea de diagrame si harti"
						},
						{
							"_nr": "Lectia 11",
							"__text": "Lucrul cu baze de date"
						}
					]
				},
				"_id": "1333",
				"_isbn": "973-9229-07-7"
			},
			{
				"titlu": "Programarea calculatoarelor. Stiinta invatarii unui limbaj de programare. Teorie si aplicatii",
				"autori": {
					"autor": [
						{
							"_nume": "Ion Gh. Rosca"
						},
						{
							"_nume": "Bogdan Ghilic-Micu"
						},
						{
							"_nume": "Catalina - Lucia Cocianu"
						},
						{
							"_nume": "Marian Stoica"
						},
						{
							"_nume": "Cristian Uscatu"
						}
					]
				},
				"anAparitie": "2003",
				"editura": "ASE",
				"numarPagini": "224",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Simona Busoi"
				},
				"tehnoredactori": {
					"tehnoredactor": "Doroty-Elisabeta Ionescu"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Simona Busoi"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Teorie"
						},
						{
							"_nr": "1",
							"__text": "Aparitia si evolutia limbajului C"
						},
						{
							"_nr": "2",
							"__text": "Constructorii de baza ale limbajului"
						},
						{
							"_nr": "3",
							"__text": "Tipuri de date"
						},
						{
							"_nr": "4",
							"__text": "Operatori si expresii"
						},
						{
							"_nr": "5",
							"__text": "Operatii de intrarea/iesire cu tastatura/monitorul"
						},
						{
							"_nr": "6",
							"__text": "Realizarea structurilor fundamentale de control"
						},
						{
							"_nr": "7",
							"__text": "Pointeri"
						},
						{
							"_nr": "8",
							"__text": "Subprograme"
						},
						{
							"_nr": "9",
							"__text": "Fisiere de date"
						},
						{
							"_nr": "Partea II",
							"__text": "Aplicatii"
						},
						{
							"_nr": "1",
							"__text": "Functii"
						},
						{
							"_nr": "2",
							"__text": "Operatii cu masive si pointeri"
						},
						{
							"_nr": "3",
							"__text": "Lucrul cu siruri de caractere"
						},
						{
							"_nr": "4",
							"__text": "Structuri dinamice de date"
						},
						{
							"_nr": "5",
							"__text": "Lucrul cu fisiere"
						}
					]
				},
				"_id": "1334",
				"_isbn": "973-594-243-7"
			},
			{
				"titlu": "World Wide Web - Mosaic si Netscape",
				"autori": {
					"autor": [
						{
							"_nume": "Florin Pilat"
						},
						{
							"_nume": "Madalina Raceanu"
						},
						{
							"_nume": "Carmen Stanciu"
						}
					]
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "272",
				"pretOriginal": "15000",
				"pretCumparare": "20000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Mihai Ciupa"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Ce este Internet?"
						},
						{
							"_nr": "2",
							"__text": "Aplicatia World Wide Web (WWW)"
						},
						{
							"_nr": "3",
							"__text": "Obtinerea, instalarea si configurarea clientului Mosaic pentru MS-Windows"
						},
						{
							"_nr": "4",
							"__text": "Bazele utilizarii interfetei Mosaic"
						},
						{
							"_nr": "5",
							"__text": "Navigatia in Internet cu instrumentul Mosaic"
						},
						{
							"_nr": "6",
							"__text": "Accesul la servicii Internet cu Mosaic"
						},
						{
							"_nr": "7",
							"__text": "Alti clienti pentru accesul la WWW"
						},
						{
							"_nr": "8",
							"__text": "Crearea documentelor HTML"
						}
					]
				},
				"_id": "1335",
				"_isbn": "973-601-446-0"
			},
			{
				"titlu": "Visual Basic: primii pasi... si urmatori",
				"autori": {
					"autor": [
						{
							"_nume": "Luminita Finaru"
						},
						{
							"_nume": "Ioan Brava"
						}
					]
				},
				"anAparitie": "2001",
				"editura": "Polirom",
				"numarPagini": "320",
				"pretOriginal": "212900",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Ioana Gagea"
				},
				"tehnoredactori": {
					"tehnoredactor": "Paul Paduraru"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Manuela Oboroceanu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Visual Basic - prezentare generala"
						},
						{
							"_nr": "2",
							"__text": "Intelegerea programelor Visual Basic"
						},
						{
							"_nr": "3",
							"__text": "Controale si proprietati"
						},
						{
							"_nr": "4",
							"__text": "Mai multe despre controale"
						},
						{
							"_nr": "5",
							"__text": "Scrierea codului"
						},
						{
							"_nr": "6",
							"__text": "Casete de mesaj si casete de introducere a datelor"
						},
						{
							"_nr": "7",
							"__text": "Structuri de control"
						},
						{
							"_nr": "8",
							"__text": "Realizarea unei aplicatii functionale"
						},
						{
							"_nr": "9",
							"__text": "Mai multe controale"
						},
						{
							"_nr": "10",
							"__text": "Casete de dialog"
						},
						{
							"_nr": "11",
							"__text": "Programarea modulara in Visual Basic"
						},
						{
							"_nr": "12",
							"__text": "Functii predefinite"
						},
						{
							"_nr": "13",
							"__text": "Elemente de grafica"
						},
						{
							"_nr": "14",
							"__text": "Crearea de meniuri in aplicatiile Visual Basic"
						},
						{
							"_nr": "15",
							"__text": "Linii de instrumente si alte elemente grafice"
						},
						{
							"_nr": "16",
							"__text": "Utilizarea fisierelor"
						},
						{
							"_nr": "17",
							"__text": "Facilitati pentru lucrul cu baze de date"
						},
						{
							"_nr": "18",
							"__text": "Verba volant, scripta manet"
						},
						{
							"_nr": "19",
							"__text": "Rapoarte"
						},
						{
							"_nr": "20",
							"__text": "Depanarea aplicatiilor Visual Basic"
						},
						{
							"_nr": "21",
							"__text": "Programarea orientata pe obiecte"
						},
						{
							"_nr": "22",
							"__text": "Distribuirea aplicatiilor"
						},
						{
							"_nr": "Anexa",
							"__text": "O aplicatie complexa"
						}
					]
				},
				"_id": "1336",
				"_isbn": "973-683-813-7"
			},
			{
				"titlu": "Informatica medicala (Partea I)",
				"autori": {
					"autor": {
						"_nume": "Tiberiu Spircu"
					}
				},
				"anAparitie": "1997",
				"editura": "Editura Universitara 'Carol Davila'",
				"numarPagini": "128",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Elena Peoenaru"
				},
				"tehnoredactori": {
					"tehnoredactor": "Tiberiu Spircu"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Razvan Cretu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Calculatoare personale - componente hard"
						},
						{
							"_nr": "II",
							"__text": "Calculatoare personale - sistemul de operare MS-DOS"
						},
						{
							"_nr": "III",
							"__text": "Teoria informatiei si aplicatiile sale"
						},
						{
							"_nr": "IV",
							"__text": "Logica, inteligenta artificiala si sisteme expert in medicina"
						}
					]
				},
				"_id": "1337",
				"_isbn": "973-97350-8-8"
			},
			{
				"titlu": "Secrete C++",
				"autori": {
					"autor": {
						"_nume": "Constantin Galatan"
					}
				},
				"anAparitie": "2006",
				"editura": "Albastra",
				"numarPagini": "436",
				"pretOriginal": "450000",
				"pretCumparare": "150000",
				"colectia": {
					"_numarul": "210",
					"_nume": "Seria PC"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Bazele limbajului C++"
						},
						{
							"_nr": "2",
							"__text": "Concepte fundamentale"
						},
						{
							"_nr": "3",
							"__text": "Clase"
						},
						{
							"_nr": "4",
							"__text": "Supraincarcarea operatorilor"
						},
						{
							"_nr": "5",
							"__text": "Mostenire si polimorfism"
						},
						{
							"_nr": "6",
							"__text": "Sabloane. Tratarea exceptiilor"
						}
					]
				},
				"_id": "1338",
				"_isbn": "973-650-186-8"
			},
			{
				"titlu": "Fractali: puneti fractalii sa lucreze pentru dumneavoastra",
				"autori": {
					"autor": {
						"_nume": "Duck Oliver"
					}
				},
				"anAparitie": "1996",
				"editura": "Teora",
				"numarPagini": "464",
				"pretOriginal": "80000",
				"pretCumparare": "20000",
				"colectia": {
					"_numarul": "114",
					"_nume": "Calculatoare personale"
				},
				"traducere": {
					"titluOriginal": "Fractalvision: Put Fractals to Work",
					"editura": "Sams Publishing",
					"traducatori": {
						"traducator": "Mihai Enache"
					},
					"_an": "1992",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Introducere"
						},
						{
							"_nr": "2",
							"__text": "O istorie a fractalilor si haosului"
						},
						{
							"_nr": "3",
							"__text": "Fractalii din lumea reala"
						},
						{
							"_nr": "4",
							"__text": "Filozofia fractalilor"
						},
						{
							"_nr": "5",
							"__text": "Arta modelarii fractale"
						},
						{
							"_nr": "6",
							"__text": "Matemetica din spatele magiei"
						},
						{
							"_nr": "7",
							"__text": "Epilog"
						},
						{
							"_nr": "8",
							"__text": "Softul FractalVision. Scurt indrumar"
						},
						{
							"_nr": "9",
							"__text": "Ghidul softului FractalVision"
						},
						{
							"_nr": "10",
							"__text": "Sinteze matematice, formule, algoritmi"
						},
						{
							"_nr": "11",
							"__text": "Bibliografie comentata"
						},
						{
							"_nr": "12",
							"__text": "Suportul bibliotecii grafice"
						}
					]
				},
				"_id": "1339",
				"_isbn": "973-601-364-2"
			},
			{
				"titlu": "Manual de informatica: informatica aplicata [XII]",
				"autori": {
					"autor": {
						"_nume": "Silviu Petrescu"
					}
				},
				"anAparitie": "1998",
				"editura": "Teora",
				"numarPagini": "296",
				"pretOriginal": "35000",
				"pretCumparare": "30000",
				"colectia": {
					"_numarul": "253",
					"_nume": "Calculatoare personale"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "CorelDRAW"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Retele NetWare"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Sistemul de operare UNIX"
						}
					]
				},
				"_id": "1340",
				"_isbn": "973-601-854-7"
			},
			{
				"titlu": "Tehnologia informatiei; Informatica - Tehnologii asistate de calculator [X]",
				"autori": {
					"autor": {
						"_nume": "Mariana Milosescu"
					}
				},
				"anAparitie": "2000",
				"editura": "Teora",
				"numarPagini": "176",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Liliana Dabuleanu"
				},
				"tehnoredactori": {
					"tehnoredactor": "Mariana Milosescu"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Gheorghe Popescu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Tipuri de date si operatii specifice acestora"
						},
						{
							"_nr": "2",
							"__text": "Folosirea aplicatiilor software pentru rezolvarea unor probleme specifice"
						},
						{
							"_nr": "3",
							"__text": "Folosirea tehnicii de calcul in scopul modelarii si analizarii evenimentelor"
						}
					]
				},
				"_id": "1341",
				"_isbn": "973-20-0301-4"
			},
			{
				"titlu": "Bazele informaticii",
				"autori": {
					"autor": [
						{
							"_nume": "Emil Stan"
						},
						{
							"_nume": "Georgiana Bozomala"
						}
					]
				},
				"anAparitie": "1997",
				"editura": "Editura Ministerului de Interne",
				"numarPagini": "240",
				"pretOriginal": "0",
				"pretCumparare": "20000",
				"redactori": {
					"redactor": "Vasi Dimitrica"
				},
				"tehnoredactori": {
					"tehnoredactor": "Vasi Dimitrica"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Sistemul informational din Ministerul de Interne"
						},
						{
							"_nr": "2",
							"__text": "Arhitectura sistemului de calcul"
						},
						{
							"_nr": "3",
							"__text": "Sistemul de operare MS-DOS"
						},
						{
							"_nr": "4",
							"__text": "Pornirea calculatoarelor (lucrare practica)"
						},
						{
							"_nr": "5",
							"__text": "Retele de calculatoare"
						},
						{
							"_nr": "6",
							"__text": "Algoritmi si modele de reprezentare"
						},
						{
							"_nr": "7",
							"__text": "Procesoare de texte. Write sub Windows"
						},
						{
							"_nr": "8",
							"__text": "Organziarea si structurarea datelor"
						},
						{
							"_nr": "9",
							"__text": "FoxPro2 (lucrare practica)"
						},
						{
							"_nr": "10",
							"__text": "Sistemul informatic - Componenta a sistemului informational"
						},
						{
							"_nr": "11",
							"__text": "Sisteme informatice in cadruk MI"
						},
						{
							"_nr": "12",
							"__text": "Securitatea si integritatea informatiilor"
						},
						{
							"_nr": "13",
							"__text": "Infractiuni cu calculatorul"
						},
						{
							"_nr": "14",
							"__text": "Decizia asitata de calculator"
						},
						{
							"_nr": "15",
							"__text": "Sisteme expert"
						},
						{
							"_nr": "16",
							"__text": "Comunicatii in retelele de calculatoare"
						},
						{
							"_nr": "17",
							"__text": "Intretinerea sistemelor de calcul"
						},
						{
							"_nr": "18",
							"__text": "Bibligrafie"
						}
					]
				},
				"_id": "1342",
				"_isbn": "973-9265-07-3"
			},
			{
				"titlu": "Programarea in C#",
				"autori": {
					"autor": {
						"_nume": "David Conger"
					}
				},
				"anAparitie": "2003",
				"editura": "All",
				"numarPagini": "344",
				"pretOriginal": "217000",
				"pretCumparare": "100000",
				"traducere": {
					"titluOriginal": "The Complet Idiot's Guide to C# Programming",
					"editura": "Pearson Education",
					"traducatori": {
						"traducator": "Maruab Belinschi"
					},
					"_an": "2002",
					"_editia": "1",
					"_limba": "engleza",
					"_tara": "S.U.A."
				},
				"redactori": {
					"redactor": "Marcel Oaida"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					},
					"__text": "Stelian Stanciu"
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Partea I",
							"__text": "Inceputul"
						},
						{
							"_nr": "Partea a II-a",
							"__text": "Bazele programarii"
						},
						{
							"_nr": "Partea a III-a",
							"__text": "Dincolo de elementele de baza"
						},
						{
							"_nr": "Partea a IV-a",
							"__text": "Programarea orientata pe obiecte"
						},
						{
							"_nr": "Partea a V-a",
							"__text": "Programarea in lumea reala"
						}
					]
				},
				"_id": "1343",
				"_isbn": "973-571-429-9"
			},
			{
				"titlu": "Atestat la informatica: subiecte rezolvate",
				"autori": {
					"autor": [
						{
							"_nume": "Brandusa Bogdan"
						},
						{
							"_nume": "Gilda Gratiela Gebaila"
						},
						{
							"_nume": "Doina Druta"
						},
						{
							"_nume": "Simona Mihaela Popa"
						}
					]
				},
				"anAparitie": "2007",
				"editura": "Niculescu",
				"numarPagini": "230",
				"pretOriginal": "100000",
				"pretCumparare": "100000",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Programare, clasele de matematica-informatica (intensiv informatica)"
						},
						{
							"_nr": "2",
							"__text": "Programare, clasele de matematica-informatica (neintensiv)"
						},
						{
							"_nr": "3",
							"__text": "Baze de date"
						},
						{
							"_nr": "4",
							"__text": "Word si Windows"
						}
					]
				},
				"_id": "1344",
				"_isbn": "978-973-748-121-4"
			},
			{
				"titlu": "Arhitectura calculatoarelor. Indrumar de laborator (editia a 2-a)",
				"autori": {
					"autor": [
						{
							"_nume": "Corneliu Burileanu"
						},
						{
							"_nume": "Mihaela Ionita"
						},
						{
							"_nume": "Mircea Ionita"
						},
						{
							"_nume": "Luigi Bojan"
						},
						{
							"_nume": "Miahi Puchiu"
						},
						{
							"_nume": "Sorin Marinescu"
						}
					]
				},
				"anAparitie": "1998",
				"editura": "Univeritatea 'Politehnica' Bucuresti",
				"numarPagini": "116",
				"pretOriginal": "0",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "Lucrarea nr.1",
							"__text": "Asamblorul integrat TASMB"
						},
						{
							"_nr": "Lucrarea nr.2",
							"__text": "Dezasamblorul-depanatorul AFD"
						},
						{
							"_nr": "Lucrarea nr.3",
							"__text": "Intructiuni de transfer al datelor pentru microprocesoarele Intel"
						},
						{
							"_nr": "Lucrarea nr.4",
							"__text": "Instructiuni de prelucrare a datelor si instructiuni de control al programului (salturi propriu-zise) pentru microprocesoarele Intel"
						},
						{
							"_nr": "Lucrarea nr.5",
							"__text": "Instructiuni de constol al programului (cu exceptia salturilor propriu-zice) pentru microprocesoarele Intel"
						},
						{
							"_nr": "Lucrarea nr.6",
							"__text": "Tehnica intreruperilor pentru microprocesoarele Intel"
						},
						{
							"_nr": "Lucrarea nr.7",
							"__text": "Analiza semnaelor logice intr-un sistem cu microprocesor"
						},
						{
							"_nr": "Anexa",
							"__text": "Setul de instructiuni Intel 8086"
						}
					]
				},
				"_id": "1345",
				"_isbn": ""
			},
			{
				"titlu": "Microprocesoare si microcontrolere: indrumar de laborator",
				"autori": {
					"autor": {
						"_nume": "Catalin Mucichescu"
					}
				},
				"anAparitie": "1999",
				"editura": "Univeritatea 'Politehnica' Bucuresti",
				"numarPagini": "208",
				"pretOriginal": "10100",
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "I",
							"__text": "Microprocesoare"
						},
						{
							"_nr": "II",
							"__text": "Microcontrolere"
						}
					]
				},
				"_id": "1346",
				"_isbn": ""
			},
			{
				"titlu": "Sisteme de prelucrare numerica cu microcontrolere, microprocesoare, procesoare numerice de semnal",
				"autori": {
					"autor": {
						"_nume": "Liviu Toma"
					}
				},
				"anAparitie": "2002",
				"editura": "Editura de Vest",
				"numarPagini": "192",
				"pretOriginal": "0",
				"redactori": {
					"redactor": "Ion Ilin"
				},
				"coperta": {
					"imagine": {
						"_path": ".jpg"
					}
				},
				"cuprins": {
					"capitol": [
						{
							"_nr": "1",
							"__text": "Principii generale privind structura si functionarea unui sistem de prelucrare numerica"
						},
						{
							"_nr": "2",
							"__text": "Microcontrolerul 8051"
						},
						{
							"_nr": "3",
							"__text": "Microcontrolerul 80C552"
						},
						{
							"_nr": "4",
							"__text": "Microprocesorul 8086"
						},
						{
							"_nr": "5",
							"__text": "Procesorul numeric de semnal TMS320C50"
						},
						{
							"_nr": "6",
							"__text": "Implementarea filtrelor numerice cu procesorul numeric de semnal TMS320C50"
						}
					]
				},
				"_id": "1347",
				"_isbn": "973-36-0358-9"
			}
		]
	}
}