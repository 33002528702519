import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class SpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <hr/>
                    <b>1. ChatModel</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'public interface OpenAIService {\n' +
                            '\n' +
                            '    String getAnswer(String question);\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'import org.springframework.ai.chat.model.ChatModel;\n' +
                            'import org.springframework.ai.chat.model.ChatResponse;\n' +
                            'import org.springframework.ai.chat.prompt.Prompt;\n' +
                            'import org.springframework.ai.chat.prompt.PromptTemplate;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class OpenAIServiceImpl implements OpenAIService{\n' +
                            '\n' +
                            '    private final ChatModel chatModel;\n' +
                            '\n' +
                            '    public OpenAIServiceImpl(@Qualifier("openAiChatModel") ChatModel chatModel) {\n' +
                            '        this.chatModel = chatModel;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getAnswer(String question) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate(question);\n' +
                            '\n' +
                            '        Prompt prompt = promptTemplate.create();\n' +
                            '\n' +
                            '        ChatResponse chatResponse = chatModel.call(prompt);\n' +
                            '\n' +
                            '        return chatResponse.getResult().getOutput().getContent();\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'import org.junit.jupiter.api.Test;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.boot.test.context.SpringBootTest;\n' +
                            '\n' +
                            'import static org.junit.jupiter.api.Assertions.*;\n' +
                            '\n' +
                            '@SpringBootTest\n' +
                            'class OpenAIServiceImplTest {\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    OpenAIService openAIService;\n' +
                            '\n' +
                            '    @Test\n' +
                            '    void getAnswer() {\n' +
                            '\n' +
                            '        String answer = openAIService.getAnswer("Zi-mi o gluma");\n' +
                            '        System.out.println("gluma:");\n' +
                            '        System.out.println(answer);\n' +
                            '\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca cheia nu este valida se va obtine o eroare:
                    <SyntaxHighlighter>
                        {'org.springframework.ai.retry.NonTransientAiException: 401 - {\n' +
                            '    "error": {\n' +
                            '        "message": "Incorrect API key provided: ${OPENAI*****KEY}. You can find your API key at https://platform.openai.com/account/api-keys.",\n' +
                            '        "type": "invalid_request_error",\n' +
                            '        "param": null,\n' +
                            '        "code": "invalid_api_key"\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <b>Controller</b>
                    <br/>
                    <br/>
                    Fie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.controller;\n' +
                            '\n' +
                            'import org.springframework.web.bind.annotation.PostMapping;\n' +
                            'import org.springframework.web.bind.annotation.RestController;\n' +
                            'import ro.agnes.spring.ia.model.Answer;\n' +
                            'import ro.agnes.spring.ia.model.Question;\n' +
                            'import ro.agnes.spring.ia.service.OpenAIService;\n' +
                            '\n' +
                            '@RestController\n' +
                            'public class QuestionController {\n' +
                            '\n' +
                            '    private final OpenAIService openAIService;\n' +
                            '\n' +
                            '    public QuestionController(OpenAIService openAIService){\n' +
                            '        this.openAIService = openAIService;\n' +
                            '    }\n' +
                            '\n' +
                            '    @PostMapping("/ask")\n' +
                            '    public Answer askQuestion(@RequestBody Question question){\n' +
                            '        return openAIService.getAnswer(question);\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'public record Question(String question) {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'public record Question(String question) {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'import ro.agnes.spring.ia.model.Answer;\n' +
                            'import ro.agnes.spring.ia.model.Question;\n' +
                            '\n' +
                            'public interface OpenAIService {\n' +
                            '\n' +
                            '    String getAnswer(String question);\n' +
                            '\n' +
                            '    Answer getAnswer(Question question);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'import org.springframework.ai.chat.model.ChatModel;\n' +
                            'import org.springframework.ai.chat.model.ChatResponse;\n' +
                            'import org.springframework.ai.chat.prompt.Prompt;\n' +
                            'import org.springframework.ai.chat.prompt.PromptTemplate;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import ro.agnes.spring.ia.model.Answer;\n' +
                            'import ro.agnes.spring.ia.model.Question;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class OpenAIServiceImpl implements OpenAIService{\n' +
                            '\n' +
                            '    private final ChatModel chatModel;\n' +
                            '\n' +
                            '    public OpenAIServiceImpl(@Qualifier("openAiChatModel") ChatModel chatModel) {\n' +
                            '        this.chatModel = chatModel;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getAnswer(String question) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate(question);\n' +
                            '\n' +
                            '        Prompt prompt = promptTemplate.create();\n' +
                            '\n' +
                            '        ChatResponse chatResponse = chatModel.call(prompt);\n' +
                            '\n' +
                            '        return chatResponse.getResult().getOutput().getContent();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Answer getAnswer(Question question) {\n' +
                            '        return new Answer(getAnswer(question.question()));\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <b>Testare Postman</b>:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_11.png'}/>
                    </div>


                    <hr/>
                    <b>2. ChatClient</b>
                    <br/>
                    <br/>
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '    private final ChatClient chatClient;\n\n' +

                            '    public OpenAIServiceImpl(ChatClient.Builder builder) {\n' +
                            '        this.chatClient = builder.build();\n' +
                            '    }\n\n' +

                            '    public ChatResponse getAnswer(String question) {\n' +
                            '        ChatResponse chatResponse = chatClient.prompt(question).call().chatResponse();\n' +
                            '        return chatResponse;\n' +
                            '    }\n'
                        }
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpringIALlmContent;