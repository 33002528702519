import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function AdapterMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-util-adapter";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>1. Adaptor SCART 21 pini tata si SCART 21 pini mama, 3 x RCA mama, SVHS mama, cu comutator, Goobay</b>
                <br/>
                <br/>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_23.png'}/>
                </div>

                <hr/>
                <b>2. Mini convertor AV(RCA) la HDMI, 1080p</b>
                <br/>
                <br/>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_24.png'}/>
                </div>

                <hr/>
                <b>3. Adaptor conectare aparatura audio video, SCART la 3xRCA, S-VHS, cu comutator In-Out</b>
                <br/>
                <br/>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_25.png'}/>
                </div>

                <hr/>
                <b>4. Adaptor Scart la HDMI, Active, Full HD</b>
                <br/>
                <br/>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_26.png'}/>
                </div>

                <hr/>
                <b>5. Convertor Rf la HDMI</b>
                <br/>
                <br/>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_27.png'}/>
                </div>

                <hr/>
                <b>6. Alimentator universal 230V/3-12VDC 1500mA, Goobay 53997</b>
                <br/>
                <br/>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_31.png'}/>
                </div>

                Alimentator universal 230V/3-12V
                <ul>
                 <li>
                     iesire (3V-12V DC): 3 / 4.5 / <b>5</b> / 6 / 7.5 / 9 / 12V cu <b>1.5A</b>
                 </li>
                    <li>
                        curent iesire : 1500mA max
                    </li>
                    <li>
                        intrare: AC 100-240V ~ 60/50Hz
                    </li>
                </ul>

                Adaptoare incluse:
                <ul>
                    <li>2.5 mm plug</li>
                    <li><b>3.5 mm plug</b></li>
                    <li>3.5 x 1.35 mm DC plug</li>
                    <li>5.0 x 2.1 mm DC plug</li>

                    <li>5.5 x 1.5 mm DC plug</li>
                    <li>5.5 x 2.5 mm DC plug</li>
                    <li>5.5 x 2.1 mm DC plug</li>
                    <li>4.0 x 1.7 mm DC plug</li>

                    <li>adapter la USB-A mama</li>
                </ul>

                Nu are nimic dacă folosești un alt alimentator cu 5V și 1.5A. Este perfect sigur și nu va afecta calculatorul tău, deoarece:
                <ul>
                    <li>
                        Tensiunea (5V) este aceeași, ceea ce este esențial pentru a evita daunele la dispozitiv.
                    </li>
                    <li>
                        Amperajul (1.5A) reprezintă capacitatea maximă a alimentatorului de a furniza curent. Dispozitivul tău va consuma doar cât are nevoie (1.3A), deci alimentatorul de 1.5A este mai mult decât suficient.
                    </li>
                </ul>
                Important este ca tensiunea să fie aceeași (5V) și mufa să fie compatibilă, atât ca dimensiune, cât și ca polaritate (+ și -). Dacă aceste condiții sunt îndeplinite, poți folosi alimentatorul fără probleme.

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default AdapterMuseumContent;