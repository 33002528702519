import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../grafana/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class DataSourcesGrafanaContent extends BaseContentPage  {

    constructor(props) {
        super(props, "grafana-data-sources", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana - Surse de date</b>
                    <br/>
                    <br/>

                    <b>1. PostgreSQL</b>
                    <br/>
                    <br/>

                    Home ~ Connections ~ Data sources

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img.png'}/>
                    </div>

                    Se apasă pe butonul: <b>Add data source</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_1.png'}/>
                    </div>

                    Se cauta si se alege <b>PostgreSQL</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_2.png'}/>
                    </div>

                    Se completeaza credentiale de conectare la baza de date:
                    <ul>
                        <li>
                            Host: localhost:5432
                        </li>
                        <li>
                            Database: lportal
                        </li>
                        <li>
                            User: lportal
                        </li>
                        <li>
                            Password: -
                        </li>
                        <li>
                            TLS/SSL Mode: disable (<i>deocamdata</i>)
                        </li>
                    </ul>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_3.png'}/>
                    </div>

                    Si se apasa pe butonul <b>Save & Test</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_4.png'}/>
                    </div>

                    Daca se poate conecta cu succes va apare urmatorul mesaj:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_5.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DataSourcesGrafanaContent;