import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OllamaSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-ollama-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    

                    Daca se doreste folosirea OpenAI si Ollamaa in acelasi proiect si constructia ChatClient folosind <b>ChatClient.Builder</b> este nevoie de:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia;\n' +
                            '\n' +
                            'import org.springframework.ai.autoconfigure.chat.client.ChatClientBuilderConfigurer;\n' +
                            'import org.springframework.ai.chat.client.ChatClient;\n' +
                            'import org.springframework.ai.chat.model.ChatModel;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Scope;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            'public class SpringIaApplication {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        SpringApplication.run(SpringIaApplication.class, args);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Scope("prototype")\n' +
                            '    public ChatClient.Builder openAiChatClientBuilder(ChatClientBuilderConfigurer chatClientBuilderConfigurer,\n' +
                            '                                               @Qualifier("openAiChatModel") ChatModel chatModel) {\n' +
                            '        ChatClient.Builder builder = ChatClient.builder(chatModel);\n' +
                            '        return chatClientBuilderConfigurer.configure(builder);\n' +
                            '    }\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Scope("prototype")\n' +
                            '    public ChatClient.Builder ollamaChatClientBuilder(ChatClientBuilderConfigurer chatClientBuilderConfigurer,\n' +
                            '                                               @Qualifier("ollamaChatModel") ChatModel chatModel) {\n' +
                            '        ChatClient.Builder builder = ChatClient.builder(chatModel);\n' +
                            '        return chatClientBuilderConfigurer.configure(builder);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    ChatClient:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'import org.springframework.ai.chat.client.ChatClient;\n' +
                            'import org.springframework.ai.chat.client.advisor.MessageChatMemoryAdvisor;\n' +
                            'import org.springframework.ai.chat.memory.InMemoryChatMemory;\n' +
                            'import org.springframework.ai.chat.model.ChatResponse;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            '@Service\n' +
                            'public class OllamaServiceImpl implements OllamaService {\n' +
                            '\n' +
                            '    private final ChatClient chatClient;\n' +
                            '\n' +
                            '    public OllamaServiceImpl(@Qualifier("ollamaChatClientBuilder") ChatClient.Builder builder) {\n' +
                            '\n' +
                            '        this.chatClient = builder\n' +
                            '                .defaultAdvisors(new MessageChatMemoryAdvisor(new InMemoryChatMemory()))\n' +
                            '                .build();\n' +
                            '    }\n' +
                            '\n' +
                            '    public String getAnswer(String question) {\n' +
                            '        ChatResponse chatResponse = chatClient.prompt(question).call().chatResponse();\n' +
                            '        return chatResponse.getResult().getOutput().getContent();\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Modificare in application.properties:
                    <SyntaxHighlighter>
                        {'spring.ai.ollama.chat.model=gemma:2b'}
                    </SyntaxHighlighter>

                    Daca se va folosi mistral, de exemplu, se va modifica:
                    <SyntaxHighlighter>
                        {'spring.ai.ollama.chat.model=mistral'}
                    </SyntaxHighlighter>

                    Daca Ollama este instalata local (setare implicita):
                    <SyntaxHighlighter>
                        {'spring.ai.ollama.base-url=http://localhost:11434'}
                    </SyntaxHighlighter>



                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OllamaSpringIALlmContent;