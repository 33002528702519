import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function BancnoteMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-bancnote-ro";

    let height = 200;
    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>Bancnote romanesti</b>
                <br/>
                <br/>

                <b>500 lei - 1942 - aprilie</b>
                <div className={"row center"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_9.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_8.png'}/>
                    </div>
                </div>


                <hr/>
                <b>5 lei - 1966</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_11.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_10.png'}/>
                    </div>
                </div>
                <hr/>
                <b>10 lei - 1966</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_1.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img.png'}/>
                    </div>
                </div>

                <hr/>
                <b>25 lei - 1966</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_13.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_12.png'}/>
                    </div>
                </div>
                <hr/>
                <b>50 lei - 1966</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_7.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_6.png'}/>
                    </div>
                </div>

                <hr/>
                <b>100 lei - 1966</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_5.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_4.png'}/>
                    </div>
                </div>

                <hr/>
                <b>500 lei - 1991 - aprilie</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_26.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_27.png'}/>
                    </div>
                </div>

                <hr/>
                <b>1.000 lei - 1991 - septembrie</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_21.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_20.png'}/>
                    </div>
                </div>

                <hr/>
                <b>200 lei - 1992 - decembrie</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_30.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_31.png'}/>
                    </div>
                </div>

                <hr/>
                <b>500 lei - 1992 - decembrie</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_29.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_28.png'}/>
                    </div>
                </div>

                <hr/>
                <b>5.000 lei - 1993 - mai</b>

                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_17.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_16.png'}/>
                    </div>
                </div>

                <hr/>
                <b>100.00 lei - 1994 - februarie</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_15.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_14.png'}/>
                    </div>
                </div>

                <hr/>
                <b>1.000 lei - 1998</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_25.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_24.png'}/>
                    </div>
                </div>

                <hr/>
                <b>5.000 lei - 1998</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_23.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_22.png'}/>
                    </div>
                </div>

                <hr/>
                <b>2.000 lei - 1999</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_2.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_3.png'}/>
                    </div>
                </div>
                <hr/>
                <b>100.000 lei - 2001</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_19.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_18.png'}/>
                    </div>
                </div>

                <hr/>
                <b>1 leu - 2005 - iulie</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_35.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_34.png'}/>
                    </div>
                </div>

                <hr/>
                <b>100 lei - 2005 - ianuarie</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_37.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_36.png'}/>
                    </div>
                </div>


                <hr/>
                <b>1 leu - 2018 - ianuarie</b>
                <div className={"row"}>
                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_32.png'}/>
                    </div>

                    <div style={{padding: 0}}>
                        <img alt={""} style={{height: height}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/numimastica/img_33.png'}/>
                    </div>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default BancnoteMuseumContent;