import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class HideCommerceMenuLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-hide-commerce", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Ascundere sectiune/tab Commerce din Mega Menu [Control Panel]</b>
                    <br/>
                    <br/>

                    1. Din UI, configurarea se face din:
                    <SyntaxHighlighter>
                        {'Control Panel -> System Settings -> Module Container -> Bundle Blacklist'}
                    </SyntaxHighlighter>

                    2. Urmatoarele module se adauga separat:
                    <ul>
                        <li>com.liferay.commerce.currency.service</li>
                        <li>com.liferay.commerce.health.status.web</li>
                        <li>com.liferay.commerce.inventory.service</li>
                        <li>com.liferay.commerce.order.web</li>
                        <li>com.liferay.commerce.pricing.service</li>
                        <li>com.liferay.commerce.product.service</li>
                    </ul>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/c_10.png'}/>
                    </div>

                    3. Se apasa butonul <b>Save</b>
                    <br/>
                    <br/>
                    4. Se verifica in log-uri ca s-au oprit modulele de mai sus:
                    <SyntaxHighlighter>
                        {'2023-11-02 22:04:53.381 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleBlacklist:180] Stopping blacklisted bundle com.liferay.commerce.currency.service_4.0.43 [141]\n' +
                            '2023-11-02 22:04:55.560 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleStartStopLogger:71] STOPPED com.liferay.commerce.currency.service_4.0.43 [141]\n' +
                            '2023-11-02 22:04:55.569 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleBlacklist:180] Stopping blacklisted bundle com.liferay.commerce.health.status.web_4.0.34 [152]\n' +
                            '2023-11-02 22:04:55.579 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleStartStopLogger:71] STOPPED com.liferay.commerce.health.status.web_4.0.34 [152]\n' +
                            '2023-11-02 22:04:55.591 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleBlacklist:180] Stopping blacklisted bundle com.liferay.commerce.inventory.service_4.0.62 [154]\n' +
                            '2023-11-02 22:04:55.716 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleStartStopLogger:71] STOPPED com.liferay.commerce.inventory.service_4.0.62 [154]\n' +
                            '2023-11-02 22:04:55.731 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleBlacklist:180] Stopping blacklisted bundle com.liferay.commerce.pricing.service_4.0.56 [171]\n' +
                            '2023-11-02 22:04:56.045 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleStartStopLogger:71] STOPPED com.liferay.commerce.pricing.service_4.0.56 [171]\n' +
                            '2023-11-02 22:04:56.053 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleBlacklist:180] Stopping blacklisted bundle com.liferay.commerce.product.service_6.0.120 [183]\n' +
                            '2023-11-02 22:04:57.166 INFO  [CM Event Dispatcher (Fire ConfigurationEvent: pid=com.liferay.portal.bundle.blacklist.internal.configuration.BundleBlacklistConfiguration)][BundleStartStopLogger:71] STOPPED com.liferay.commerce.product.service_6.0.120 [183]'}
                    </SyntaxHighlighter>

                    5. Se reincarca pagina si de deschide mega menu/Control Panel: sectiunea →Commerce nu se mai afiseaza!

                    <hr/>
                    <b>Observatie</b>
                    <ul>
                        <li>
                            <b>Dezactivarea Commerce in Blacklist poate provoca inconsecvențe cu alte module Liferay DXP!</b>
                            <a target={"_blank"} href={"https://learn.liferay.com/en/w/commerce/installation-and-upgrades/activating-liferay-commerce-enterprise"}>
                                Activating Liferay Commerce Enterprise
                            </a>
                        </li>
                    </ul>




                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360018174711-Terms-of-Use"}>*/}
                        {/*           Terms of use*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HideCommerceMenuLiferayContent;