import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class SoundSenzorArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-sound-sensor", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1.  Senzor de sunet (microfon) </b>
                    <br/>
                    <br/>

                    Exista senzori de sunet:
                    <ul>
                        <li>
                            analog

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_44.png'}/>
                            </div>

                            (dacă e conectat un senzor de sunet analogic la Arduino, pentru a schimba nivelul de sunet care declanșează senzorul, este nevoie de senzor)

                        </li>
                        <li>
                            digital
                            <br/>
                            (dacă e conectat un senzor de sunet analogic la Arduino, pentru a schimba nivelul de sunet care declanșează senzorul, este nevoie de program)
                        </li>
                    </ul>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_45.png'}/>
                    </div>

                    Cod:
                    <SyntaxHighlighter>
                        {'#define sensorPin A2\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  Serial.begin(9600);  // initialize serial communications at 9600 bps:\n' +
                            '  pinMode(sensorPin, INPUT);\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  int sensorValue = analogRead(sensorPin);\n' +
                            '  Serial.println(sensorValue);\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        {/*<li>*/}
                        {/*    <a target={"_blank"} href={"https://www.circuitgeeks.com/arduino-buzzer-tutorial/"}>*/}
                        {/*        Arduino Buzzer Tutorial*/}
                        {/*    </a>*/}
                        {/*</li>*/}


                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SoundSenzorArduinoContent;