import React from "react";

// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";


class SleuthZipkinSpringContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-spring-sleuth-zipkin", IndexContent);
    }

    render() {

        return (
            <div className="home index article">

                {this.title()}
                {this.navigator()}
                <br/>

                <div className={"text-justify important"}>

                    <b>1. Sleuth</b>
                    <br/>
                    <br/>

                    In <i>api-gateway</i>, in <i>build.gradle</i> (nu e nevoie sa fie adaugata in <i>eureka-server</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'implementation \'org.springframework.cloud:spring-cloud-starter-sleuth\''}
                    </SyntaxHighlighter>
                    avut in vedere:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'org.springframework.boot\' version \'2.7.4\'\n' +
                            '    id \'io.spring.dependency-management\' version \'1.0.14.RELEASE\'\n' +
                            '    id \'java\'\n' +
                            '}'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencyManagement {\n' +
                            '    imports {\n' +
                            '        mavenBom "org.springframework.cloud:spring-cloud-dependencies:${springCloudVersion}"\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    In <i>address-service</i>, <i>student-service</i> in <i>build.gradle</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'implementation group: \'org.springframework.cloud\', name: \'spring-cloud-starter-sleuth\', version: "${spring_cloud_version}"'}
                    </SyntaxHighlighter>

                    Apoi, in <i>api-gateway</i>, in <i>application.properties</i> (nu e nevoie sa fie adaugata in <i>eureka-server</i> sau microservicii: <i>address-service</i>, <i>student-service</i>):
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'spring.sleuth.reactor.instrumentation-type = decorate-on-each'}
                    </SyntaxHighlighter>

                    In log-urile de la <i>api-gateway</i>:
                    <SyntaxHighlighter>
                        {'2022-09-30 23:20:14.599  INFO [api-gateway,,] 2320 --- [trap-executor-0] c.n.d.s.r.aws.ConfigClusterResolver      : Resolving eureka endpoints via configuration\n' +
                            '2022-09-30 23:20:22.763  INFO [api-gateway,be7bdcbbeb708716,be7bdcbbeb708716] 2320 --- [ctor-http-nio-2] r.i.l.m.spring.app.CustomFilter          : AuthKJ = 1234\n' +
                            '2022-09-30 23:20:23.553  INFO [api-gateway,be7bdcbbeb708716,c3a47e157afe36c4] 2320 --- [ctor-http-nio-3] r.i.l.m.spring.app.CustomFilter          : AuthKJ = null\n' +
                            '2022-09-30 23:20:23.719  INFO [api-gateway,be7bdcbbeb708716,c3a47e157afe36c4] 2320 --- [ctor-http-nio-3] r.i.l.m.spring.app.CustomFilter          : Post Filter = 200 OK\n' +
                            '2022-09-30 23:20:23.760  INFO [api-gateway,be7bdcbbeb708716,be7bdcbbeb708716] 2320 --- [ctor-http-nio-2] r.i.l.m.spring.app.CustomFilter          : Post Filter = 200 OK'}
                    </SyntaxHighlighter>
                    partea interesanta e formata din
                    <SyntaxHighlighter>
                        {'[api-gateway,be7bdcbbeb708716,be7bdcbbeb708716]'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li>api-gateway - este numele serviciului</li>
                        <li>be7bdcbbeb708716 - este <i>trace</i> id</li>
                        <li>be7bdcbbeb708716 - este <i>span</i> id</li>
                    </ul>

                    In log-urile de la <i>student-service</i>:
                    <SyntaxHighlighter>
                        {'2022-09-30 23:20:23.297  INFO [student-service,be7bdcbbeb708716,684f601185f2fdf9] 36508 --- [nio-9092-exec-1] o.a.c.c.C.[Tomcat].[localhost].[/]       : Initializing Spring DispatcherServlet \'dispatcherServlet\'\n' +
                            '2022-09-30 23:20:23.297  INFO [student-service,be7bdcbbeb708716,684f601185f2fdf9] 36508 --- [nio-9092-exec-1] o.s.web.servlet.DispatcherServlet        : Initializing Servlet \'dispatcherServlet\'\n' +
                            '2022-09-30 23:20:23.298  INFO [student-service,be7bdcbbeb708716,684f601185f2fdf9] 36508 --- [nio-9092-exec-1] o.s.web.servlet.DispatcherServlet        : Completed initialization in 1 ms\n' +
                            '2022-09-30 23:20:23.333  INFO [student-service,be7bdcbbeb708716,684f601185f2fdf9] 36508 --- [nio-9092-exec-1] r.i.l.m.spring.service.StudentService    : Inside Student getById\n' +
                            '2022-09-30 23:20:23.414  INFO [student-service,be7bdcbbeb708716,684f601185f2fdf9] 36508 --- [nio-9092-exec-1] r.i.l.m.spring.service.CommonService     : count = 1\n' +
                            '2022-09-30 23:20:45.675  INFO [student-service,,] 36508 --- [trap-executor-0] c.n.d.s.r.aws.ConfigClusterResolver      : Resolving eureka endpoints via configuration'}
                    </SyntaxHighlighter>
                    observam acelasi <i>trace</i> id : <i>be7bdcbbeb708716</i>

                    <br/>
                    In log-urile de la <i>address-service</i>:
                    <SyntaxHighlighter>
                        {'2022-09-30 23:20:23.612  INFO [address-service,be7bdcbbeb708716,1d6222cb48caefa8] 33340 --- [nio-9094-exec-1] o.a.c.c.C.[Tomcat].[localhost].[/]       : Initializing Spring DispatcherServlet \'dispatcherServlet\'\n' +
                            '2022-09-30 23:20:23.612  INFO [address-service,be7bdcbbeb708716,1d6222cb48caefa8] 33340 --- [nio-9094-exec-1] o.s.web.servlet.DispatcherServlet        : Initializing Servlet \'dispatcherServlet\'\n' +
                            '2022-09-30 23:20:23.613  INFO [address-service,be7bdcbbeb708716,1d6222cb48caefa8] 33340 --- [nio-9094-exec-1] o.s.web.servlet.DispatcherServlet        : Completed initialization in 1 ms\n' +
                            '2022-09-30 23:20:23.639  INFO [address-service,be7bdcbbeb708716,1d6222cb48caefa8] 33340 --- [nio-9094-exec-1] r.i.l.m.spring.service.AddressService    : Inside getById 1\n' +
                            '2022-09-30 23:24:54.518  INFO [address-service,,] 33340 --- [trap-executor-0] c.n.d.s.r.aws.ConfigClusterResolver      : Resolving eureka endpoints via configuration\n'}
                    </SyntaxHighlighter>
                    observam acelasi <i>trace</i> id : <i>be7bdcbbeb708716</i>

                    <hr/>
                    <b>2. Zipkin</b>
                    <br/>
                    <br/>

                    Instalare Zipkin: <a target={"_blank"} href={"https://zipkin.io/pages/quickstart.html"}>aici</a>!
                    <br/>
                    Se downloadeaza (ex: zipkin-server-2.23.18-exec.jar) si apoi se porneste:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'java -jar .\\zipkin-server-2.23.18-exec.jar'}
                    </SyntaxHighlighter>
                    se va afisa:
                    <SyntaxHighlighter>
                        {'\n' +
                            '                  oo\n' +
                            '                 oooo\n' +
                            '                oooooo\n' +
                            '               oooooooo\n' +
                            '              oooooooooo\n' +
                            '             oooooooooooo\n' +
                            '           ooooooo  ooooooo\n' +
                            '          oooooo     ooooooo\n' +
                            '         oooooo       ooooooo\n' +
                            '        oooooo   o  o   oooooo\n' +
                            '       oooooo   oo  oo   oooooo\n' +
                            '     ooooooo  oooo  oooo  ooooooo\n' +
                            '    oooooo   ooooo  ooooo  ooooooo\n' +
                            '   oooooo   oooooo  oooooo  ooooooo\n' +
                            '  oooooooo      oo  oo      oooooooo\n' +
                            '  ooooooooooooo oo  oo ooooooooooooo\n' +
                            '      oooooooooooo  oooooooooooo\n' +
                            '          oooooooo  oooooooo\n' +
                            '              oooo  oooo\n' +
                            '\n' +
                            '     ________ ____  _  _____ _   _\n' +
                            '    |__  /_ _|  _ \\| |/ /_ _| \\ | |\n' +
                            '      / / | || |_) | \' / | ||  \\| |\n' +
                            '     / /_ | ||  __/| . \\ | || |\\  |\n' +
                            '    |____|___|_|   |_|\\_\\___|_| \\_|\n' +
                            '\n' +
                            ':: version 2.23.18 :: commit 4b71677 ::\n' +
                            '\n' +
                            '2022-09-30 23:37:07.938  INFO [/] 3068 --- [oss-http-*:9411] c.l.a.s.Server                           : Serving HTTP at /0:0:0:0:0:0:0:0:9411 - http://127.0.0.1:9411/\n'}
                    </SyntaxHighlighter>

                    daca vom scrie intr-un brower: <i>http://127.0.0.1:9411/</i>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/spring/img.png'}/>
                    </div>

                    configurare microservicii si <i>api-gateway</i>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'implementation \'org.springframework.cloud:spring-cloud-sleuth-zipkin\''}
                    </SyntaxHighlighter>

                    apoi in <i>api-gateway</i>, <i>student-service</i>, <i>address-service</i> in <i>application.properties</i>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'spring.zipkin.base-url = http://127.0.0.1:9411'}
                    </SyntaxHighlighter>

                    daca repornim serviciile si <i>api-gateway</i> cu noile configurari. si apoi din browser apasam pe butonul <i>Run Query</i>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/spring/img_1.png'}/>
                    </div>

                    daca se apasa pe butonul <i>show</i>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/spring/img_2.png'}/>
                    </div>

                    se poate filtra si dupa <i>trace</i> id:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/spring/img_4.png'}/>
                    </div>

                </div>

                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a href={" https://www.baeldung.com/spring-conditionalonproperty"}>*/}
                    {/*                The Spring @ConditionalOnProperty Annotation*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>

        );
    }
}

export default SleuthZipkinSpringContent;