import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OverrideJspLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-override-jsp", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Suprascriere / Inlocuire / Overdide JSP</b>
                    <br/>
                    <br/>
                    Pentru a inlocui o pagina JSP (de exemplu pagina de login) este nevoie de:
                    <ul>
                        <li>
                            Declararea fragmentului gazdă (<b>unde se află JSP-ul original</b>)
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'Fragment-Host: com.liferay.login.web;bundle-version="1.0.0"'}
                            </SyntaxHighlighter>

                            <hr/>
                            Pentru a declara corect gazda fragmentului (<b>Fragment-Host</b>) în fisierul <i>bnd.bnd</i>, trebuie specificat:
                            <ul>
                                <li>
                                    numele simbolic (<b>Bundle-SymbolicName</b>) al pachetului al modulului gazdă, unde se află JSP-ul original (de exemplu: com.liferay.login.web)</li>
                                <li>
                                    versiunea exactă (<b>Bundle-Version</b>) a modulului gazdă căreia îi aparține fragmentul (exemplu: <i>bundle-version="1.0.0</i>)
                                </li>
                            </ul>

                            <br/>
                            Exemplu:
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'Bundle-Name: Ibrid Login JSP Override\n' +
                                    'Bundle-SymbolicName: ro.ibrid.login.jsp.override\n' +
                                    'Bundle-Version: 1.0.0\n' +
                                    'Fragment-Host: com.liferay.login.web;bundle-version="[1.1.0,7.0.0)"\n' +
                                    '-sources: true'}
                            </SyntaxHighlighter>

                            <hr/>
                            Pentru a obtine <b>Bundle-SymbolicName</b> si <b>Bundle-Version</b>:
                            <ul>
                                <li>cauta in sursele Liferay

                                    Exemplu: <a target={"_blank"} href={"https://github.com/liferay/liferay-portal/blob/master/modules/apps/login/login-web/bnd.bnd"}>bnd.bnd</a> al modulului <i>Liferay Login Web</i>
                                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                        {'Bundle-Name: Liferay Login Web\n' +
                                            'Bundle-SymbolicName: com.liferay.login.web\n' +
                                            'Bundle-Version: 6.0.47\n' +
                                            'Import-Package:\\\n' +
                                            '\tjavax.net.ssl,\\\n' +
                                            '\t\\\n' +
                                            '\t*\n' +
                                            'Web-ContextPath: /login-web'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>
                                    folosi <b>Gogo Shell</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/o_1.png'}/>
                                    </div>
                                    Comanda <b>lb</b>:
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/o_2.png'}/>
                                    </div>
                                    Comanda <b>b</b>, cu ID-ul 565:
                                    <SyntaxHighlighter>
                                        {'  565|Active     |   15|Liferay Login Web (6.0.41)|6.0.41'}
                                    </SyntaxHighlighter>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/o_3.png'}/>
                                    </div>
                                </li>
                            </ul>

                        </li>
                        <li>
                            <b>fisierul JSP care îl va înlocui pe cel original</b>
                            <br/>
                            de exemplu, fisierul original se poate gasi aici: <a target={"_blank"} href={"https://github.com/liferay/liferay-portal/blob/master/modules/apps/login/login-web/src/main/resources/META-INF/resources/login.jsp"}>/resources/META-INF/resources/login.jsp</a>
                            <br/>
                            Trebuie imitata structura de foldere a modulului gazdă.
                            De exemplu, daca JSP-ul original se află în folderul <i>/META-INF/resources/login.jsp</i>, noul fișier JSP se pune în <i>src/main/resources/META-INF/resources/login.jsp</i>.
                            <br/>
                            Daca este necesar, ne putem referi si la fisierul original JSP,  urmând una dintre cele două convenții de denumire posibile:
                            <ul>
                                <li>portal</li>
                                <li>original</li>
                            </ul>
                           <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                               {'<liferay-util:include\n' +
                                   '    page="/login.original.jsp"\n' +
                                   '    servletContext="<%= application %>"\n' +
                                   '/>'}
                           </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'<liferay-util:include\n' +
                                    '    page="/login.portal.jsp"\n' +
                                    '    servletContext="<%= application %>"\n' +
                                    '/>'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    Fisierul <b>build.gradle</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                            '\n' +
                            '  compileOnly group: "com.liferay.portal", name: "release.portal.api"\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a target={"_blank"} href={"https://help.liferay.com/hc/en-us/articles/360017891932-Module-JSP-Override-"}>
                                    Module JSP Override
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/overriding-module-jsp-in-liferay-7-using-liferay-ide"}>
                                    Overriding Module JSP in Liferay 7 using Liferay IDE
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a target={"_blank"} href={"https://lifedev-solutions.blogspot.com/2023/03/liferay-module-jsp-override.html"}>
                                    Liferay Module JSP Override
                                </a>
                            </div>
                        </li>



                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OverrideJspLiferayContent;