import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function SpectrumMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-spectrum";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>ZX Spectrum</b>
                <br/>
                <br/>

                ZX Spectrum a fost un computer comercializat de Sinclair Research.
                Este considerat unul dintre cele mai influente computere realizate vreodată si este unul dintre cele mai bine vândute computere britanice, cu peste cinci milioane
                de unități vândute.
                A fost lansat în Regatul Unit pe 23 aprilie 1982.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_48.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_49.png'}/>
                </div>

                <hr/>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_55.png'}/>
                </div>

                <hr/>

                Carti:

                <div className={"row"}>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/museum-2/img_58.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/museum-2/img_59.png'}/>
                    </div>
                </div>

                <div className={"row"}>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/museum-2/img_57.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/museum-2/img_56.png'}/>
                    </div>

                </div>


                Alimentator:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_60.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_61.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_62.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_63.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_64.png'}/>
                </div>

                {/*<div style={{padding: 10}}>*/}
                {/*    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}*/}
                {/*         src={process.env.PUBLIC_URL + '/img/museum-2/img_65.png'}/>*/}
                {/*</div>*/}

                <hr/>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_66.png'}/>
                </div>

                <hr/>
                <b>1. ZX Spectrum (D03-163597)</b>
                <br/>
                <br/>

                Observatii:
                <ul>
                    <li>Nu merge tastatura</li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_69.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_67.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_68.png'}/>
                </div>

                <hr/>
                <b>2. ZX Spectrum</b>
                <br/>
                <br/>

                Nu merg tastele:
                <ul>
                    <li>
                        Nu merg unele taste: 4, 7,r,u,f,j,c,n
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_76.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_75.png'}/>
                </div>


                <div className={"text-justify"}>

                </div>

            </div>


            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default SpectrumMuseumContent;