import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class VisionSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-vision-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Avand urmatoare imagine:

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_17.png'}/>
                    </div>


                    Cod:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '   public String getImageDescription() {\n' +
                            '\n' +
                            '        OpenAiChatOptions chatOptions = OpenAiChatOptions.builder()\n' +
                            '                .model(OpenAiApi.ChatModel.GPT_4_O.getValue())\n' +
                            '                .build();\n' +
                            '\n' +
                            '        Media media = new Media(MimeTypeUtils.IMAGE_JPEG, image);\n' +
                            '        UserMessage userMessage = new UserMessage("Explica ce este in aceasta imagine?", media);\n' +
                            '\n' +
                            '        ChatResponse response = chatModel.call(new Prompt(userMessage, chatOptions));\n' +
                            '\n' +
                            '        return  response.getResult().getOutput().getContent();\n' +
                            '    }'
                        }
                    </SyntaxHighlighter>

                    Raspunsul:
                    <SyntaxHighlighter>
                        {'Imaginea arată o pisică și un iepure. Pisica pare să stea cu capul pe capul iepurelui. Este o scenă drăguță și amuzantă, dat fiind că ambele animale par să fie prietenoase și relaxate unul cu celălalt.'}
                    </SyntaxHighlighter>

                    (poza a fost generata folosind ca prompt: "O pisica care mananca un iepure")

                    <hr/>
                    Se pot compara si 2 imagini. De exemplu, se pot trimite 2 poze la 2 produse si sa primesti o recomandare pentru dieta.

                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'  public String getCompareImages(String prompt, String pathImage1, String pathImage2) {\n' +
                            '\n' +
                            '        OpenAiChatOptions chatOptions = OpenAiChatOptions.builder()\n' +
                            '                .model(OpenAiApi.ChatModel.GPT_4_O.getValue())\n' +
                            '                .build();\n' +
                            '\n' +
                            '        Media media1 = new Media(MimeTypeUtils.IMAGE_JPEG, new FileSystemResource(pathImage1));\n' +
                            '        Media media2 = new Media(MimeTypeUtils.IMAGE_JPEG, new FileSystemResource(pathImage2));\n' +
                            '        UserMessage userMessage = new UserMessage(prompt, List.of(media1, media2));\n' +
                            '\n' +
                            '        ChatResponse response1 = chatModel.call(new Prompt(userMessage, chatOptions));\n' +
                            '\n' +
                            '        ChatResponse response2 = chatClient\n' +
                            '                .prompt().user(u -> u.text(prompt)\n' +
                            '                        .media(MimeTypeUtils.IMAGE_JPEG, new FileSystemResource(pathImage1))\n' +
                            '                        .media(MimeTypeUtils.IMAGE_JPEG, new FileSystemResource(pathImage2)))\n' +
                            '                .options(chatOptions)\n' +
                            '                .call()\n' +
                            '                .chatResponse();\n' +
                            '\n' +
                            '        return response2.getResult().getOutput().getContent();\n' +
                            '    }'}
                    </SyntaxHighlighter>
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VisionSpringIALlmContent;