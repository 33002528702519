import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function FloppyMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-floppy";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>


                <b>Floppy ICE FELIX HC-90</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_85.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_84.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_82.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_83.png'}/>
                </div>

                <hr/>
                <b>Floppy 5.25</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_86.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_87.png'}/>
                </div>

                <hr/>
                <b>Commodore 1541 (floppy 5.25)</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_95.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_96.png'}/>
                </div>

                <hr/>
                <b>Iomega Tage 700</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_97.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_98.png'}/>
                </div>

                <hr/>
                <b>Floppy 5.25 - Negru</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_99.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_100.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_101.png'}/>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default FloppyMuseumContent;