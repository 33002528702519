export const albumsDb = [
    {
        id:1,
        title:"Use Your Illusion I",
        publisher:"Commercial Marketing",
        year:2013,
        authors:["Guns N' Roses"],
        discs:2,
        language: "engleza",
        ISBN: "0720642441510",
        img:"145284316-0.jpeg",

        /*
            1. Right Next Door To Hell (Album Version (Explicit)) [Explicit] 3:02
            2. Dust N' Bones (Album Version (Explicit)) [Explicit] 4:58
            3. Live And Let Die 3:04
            4. Don't Cry (Original) 4:44
            5. Perfect Crime (Album Version (Explicit)) [Explicit] 2:23
            6. You Ain't The First (Album Version) 2:36
            7. Bad Obsession (Album Version (Explicit)) [Explicit] 5:28
            8. Back Off Bitch (Album Version (Explicit)) [Explicit] 5:03
            9. Double Talkin' Jive (Album Version (Explicit)) [Explicit] 3:23
            10. November Rain 8:57
            11. The Garden (Album Version) 5:22
            12. Garden Of Eden (Album Version (Explicit)) [Explicit] 2:41
            13. Don't Damn Me (Album Version) [Explicit] 5:18
            14. Bad Apples (Album Version (Explicit)) [Explicit] 4:28
            15. Dead Horse (Album Version) 4:17
            16. Coma (Album Version (Explicit)) [Explicit] 10:16
         */
    },

    {
        id: 2,
        title: "Jeff Wayne's Musical Version Of The War Of The Worlds ",
        publisher: "Sony Music Cmg",
        year: 2018,
        authors: ["Jeff Wayne"],
        discs: 2,
        language: "engleza",
        ISBN: "0889854494315",
        img: "93366390-0-240.jpeg",
        /*
            Vinyl 1:

            Side A

            1. The Eve of the War - Jeff Wayne / Richard Burton / Justin Hayward
            2. Horsell Common and the Heat Ray - Jeff Wayne / Richard Burton
            Side B

            1. The Artilleryman and the Fighting Machine - Jeff Wayne
            2. Forever Autumn - Jeff Wayne / Richard Burton / Justin Hayward
            3. Thunder Child - Jeff Wayne / Richard Burton / Chris Thompson
            Vinyl 2:

            Side A

            1. The Red Weed (Part 1) - Jeff Wayne / Richard Burton
            2. The Spirit of Man - Jeff Wayne / Richard Burton / Phil Lynott
            3. The Red Weed (Part 2) - Jeff Wayne / Richard Burton
            4. The Artilleryman Returns - Jeff Wayne
            Side B

            1. Brave New World - Jeff Wayne / Richard Burton / David Essex
            2. Dead London - Jeff Wayne / Richard Burton
            3. Dead London - Jeff Wayne / Richard Burton
            4. Epilogue (Part 1) - Richard Burton / Jeff Wayne
            5. Epilogue (Part 2) (NASA) - Jeff Wayne

         */
    },

    {
        id: 3,
        title: "Mesdames",
        publisher: "-",
        year: 2020,
        authors: ["Grand Corps Malade"],
        discs: 1,
        language: "franceza",
        ISBN: "-",
        img: "Mesdames-Vinyle-Blanc.jpg",
    },

    {
        id: 4,
        title: "Cheap Smell",
        publisher: "-",
        year: 2018,
        authors: ["Kovacs"],
        discs: 2,
        language: "engleza",
        ISBN: "-",
        img: "Cheap-Smell-Double-Vinyle.jpg",
    },

    {
        id: 5,
        title: "The New Abnormal",
        publisher: "RCA Records",
        year: 2020,
        authors: ["The Strokes"],
        discs: 1,
        language: "engleza",
        ISBN: "0194397058819",
        img: "764884164-0-240.jpeg",
    },

    {
        id: 6,
        title: "Welcome Love Back",
        publisher: "Maru Records",
        year: 2020,
        authors: ["Maru"],
        discs: 1,
        language: "engleza, romana",
        ISBN: "2000001007792",
        img: "1711379633-0-240.png",
    },

    {
        id: 7,
        title: "Joie de vivre",
        publisher: "-",
        year: 2021,
        authors: ["Louane"],
        discs: 2,
        language: "franceza",
        ISBN: "-",
        img: "Joie-de-vivre.jpg",
    },

    {
        id: 8,
        title: "En cas de de tempête, ce jardin sera fermé",
        publisher: "-",
        year: 2018,
        authors: ["Coeur De Pirate"],
        discs: 1,
        language: "franceza",
        ISBN: "-",
        img: "En-cas-de-de-tempete-ce-jardin-sera-ferme.jpg",
    },

    {
        id: 9,
        title: "Roses",
        publisher: "-",
        year: 2015,
        authors: ["Coeur De Pirate"],
        discs: 1,
        language: "franceza",
        ISBN: "-",
        img: "Roses.jpg",
    },

    {
        id: 10,
        title: "Blonde",
        publisher: "-",
        year: 2011,
        authors: ["Coeur De Pirate"],
        discs: 1,
        language: "franceza",
        ISBN: "-",
        img: "Blonde.jpg",
    },

    {
        id: 11,
        title: "Les failles cachées",
        publisher: "-",
        year: 2020,
        authors: ["Pomme"],
        discs: 1,
        language: "franceza",
        ISBN: "-",
        img: "Les-Failles-Cachees.jpg",
    },

    {
        id: 12,
        title: "A peu près",
        publisher: "-",
        year: 2017,
        authors: ["Pomme"],
        discs: 2,
        language: "franceza",
        ISBN: "-",
        img: "A-peu-pres.jpg",
    },

    {
        id: 13,
        title: "XX",
        publisher: "-",
        year: 2012,
        authors: ["HIM"],
        discs: 2,
        language: "engleza",
        ISBN: "-",
        img: "R-4214667-1358750745-2700.jpg",
    },

    {
        id: 14,
        title: "Bohemian Rhapsody",
        publisher: "-",
        year: 2019,
        authors: ["Queen"],
        discs: 2,
        language: "engleza",
        ISBN: "-",
        img: "393588502-0.jpeg",
    },

    {
        id: 15,
        title: "The Visitors",
        publisher: "Polydor",
        year: 2013,
        authors: ["Abba"],
        discs: 1,
        language: "engleza",
        ISBN: "0602527346540",
        img: "0602527346540-2572706-240.jpg",
    },

    {
        id: 16,
        title: "Toi toi II",
        publisher: "-",
        year: 2022,
        authors: ["Suzane"],
        discs: 2,
        language: "franceza",
        ISBN: "-",
        img: "Toi-Toi-II.jpg",
    },

    {
        id: 17,
        title: "Alina Manole Live Session 2020",
        publisher: "-",
        year: 2020,
        authors: ["Alina Manole"],
        discs: 2,
        language: "romana",
        ISBN: "-",
        img: "alina-manole.png",
    },

    {
        id: 18,
        title: "Orizont",
        publisher: "Universal Music Romania",
        year: 2016,
        authors: ["Coma"],
        discs: 1,
        language: "romana",
        ISBN: "0602557306293",
        img: "1047933-0.jpeg",
    },

    {
        id: 19,
        title: "Oui",
        publisher: "-",
        year: 2018,
        authors: ["Camille"],
        discs:2,
        language: "franceza",
        ISBN: "-",
        img: "Oui-Inclus-CD.jpg",
    },

    {
        id: 20,
        title: "Premiers émois",
        publisher: "-",
        year: 2019,
        authors: ["Vendredi Sur Mer"],
        discs:2,
        language: "franceza",
        ISBN: "-",
        img: "Premiers-emois-Double-Vinyle-blanc-Gatefold.jpg",
    },

    {
        id: 21,
        title: "Louane",
        publisher: "-",
        year: 2019,
        authors: ["Louane"],
        discs:2,
        language: "franceza",
        ISBN: "-",
        img: "Louane-Double-Vinyle-Gatefold.jpg",
    },

    {
        id: 22,
        title: "Phoenix",
        publisher: "-",
        year: 2021,
        authors: ["Charlotte Cardin"],
        discs:1,
        language: "franceza",
        ISBN: "-",
        img: "Phoenix.jpg",
    },

    {
        id: 23,
        title: "Petite Amie",
        publisher: "-",
        year: 2017,
        authors: ["Juliette Armanet"],
        discs:1,
        language: "franceza",
        ISBN: "-",
        img: "Petite-Amie.jpg",
    },

    {
        id: 24,
        title: "A ta merci",
        publisher: "-",
        year: 2017,
        authors: ["Fishbach"],
        discs:1,
        language: "franceza",
        ISBN: "-",
        img: "A-ta-merci.jpg",
    },


    {
        id: 25,
        title: "Il suffit d’y croire",
        publisher: "-",
        year: 2019,
        authors: ["Hoshi"],
        discs:2,
        language: "franceza",
        ISBN: "-",
        img: "Il-suffit-d-y-croire-Edition-Collector.jpg",
    },

    {
        id: 26,
        title: "Selah Sue",
        publisher: "-",
        year: 2011,
        authors: ["Selah Sue"],
        discs:2,
        language: "engleza",
        ISBN: "-",
        img: "Selah-Sue.jpg",
    },

    {
        id: 27,
        title: "Ilo Veyou",
        publisher: "-",
        year: 2017,
        authors: ["Camille"],
        discs:2,
        language: "franceza",
        ISBN: "-",
        img: "Ilo-Veyou.jpg",
    },

    {
        id: 28,
        title: "Shades of black",
        publisher: "-",
        year: 2015,
        authors: ["Kovacs"],
        discs: 2,
        language: "engleza",
        ISBN: "-",
        img: "R-6854614-1453715793-6828.jpg",
    },


    {
        id: 29,
        title: "Souldier",
        publisher: "-",
        year: 2018,
        authors: ["Jain"],
        discs: 2,
        language: "engleza",
        ISBN: "-",
        img: "Souldier.jpg",
    },

    {
        id: 30,
        title: "Zanaka",
        publisher: "-",
        year: 2016,
        authors: ["Jain"],
        discs: 2,
        language: "engleza",
        ISBN: "-",
        img: "Zanaka.jpg",
    },

    {
        id: 31,
        title: "Back To Black",
        publisher: "Universal Records",
        year: 2018,
        authors: ["Amy Winehouse"],
        discs: 1,
        language: "engleza",
        ISBN: "0602567913016",
        img: "262169523-0.jpeg",
    },

    {
        id: 32,
        title: "Frank",
        publisher: "Island Records",
        year: 2020,
        authors: ["Amy Winehouse"],
        discs: 2,
        language: "engleza",
        ISBN: "0602508973383",
        img: "1067160141-0.jpeg",
    },

    {
        id: 33,
        title: "Lioness: Hidden Treasures",
        publisher: "Universal Music",
        year: 2011,
        authors: ["Amy Winehouse"],
        discs: 2,
        language: "engleza",
        ISBN: "0602527906034",
        img: "0602527906034-1883799.jpg",
    },

    {
        id: 34,
        title: "Spirit Of French Songs",
        publisher: "Wagram",
        year: 2017,
        authors: ["Multi-Artistes"],
        discs: 1,
        language: "franceza",
        ISBN: "3596973487865",
        img: "553288275-0.jpeg",
    },

    {
        id: 35,
        title: "Mes Jeunes Annees",
        publisher: "Wagram Music",
        year: 2018,
        authors: ["Charles Aznavour"],
        discs: 1,
        language: "franceza",
        ISBN: "3596973557865",
        img: "553288239-0.jpeg",
    },

    {
        id: 36,
        title: "It Wasn't Hard To Love You",
        publisher: "Asphalt Tango Records",
        year: 2021,
        authors: ["Fanfare Ciocărlia"],
        discs: 1,
        language: "romana, engleza",
        ISBN: "4015698273892",
        img: "R-20066308-1630432467-6239.jpg",
    },

    {
        id: 37,
        title: "Onwards To Mars!",
        publisher: "Asphalt Tango Records",
        year: 2016,
        authors: ["Fanfare Ciocărlia"],
        discs: 1,
        language: "romana, engleza",
        ISBN: "4015698003925",
        img: "443716-0.jpg",
    },

    {
        id: 38,
        title: "Prin Lume",
        publisher: "Universal Music Romania",
        year: 2022,
        authors: ["Paulina"],
        discs: 1,
        language: "romana",
        ISBN: "0602445885343",
        img: "1746871229-1.png",
    },

    {
        id: 39,
        title: "Live At Glastonbury 2007",
        publisher: "Island Records",
        year: 2022,
        authors: ["Amy Winehouse"],
        discs: 1,
        language: "engleza",
        ISBN: "602445556847",
        img: "1744771425-0.jpeg",
    },

    {
        id: 40,
        title: "Tara te vrea prost",
        publisher: "Trei Betivi",
        year: 2021,
        authors: ["Sarmalele Reci"],
        discs: 1,
        language: "romana",
        ISBN: "2000000991108",
        img: "1399596093-0.png",
    },

    {
        id: 41,
        title: "Killing The Classics",
        publisher: "20 CM Records",
        year: 2021,
        authors: ["Cheloo,Lazar"],
        discs: 1,
        language: "romana",
        ISBN: "5948223100068",
        img: "1566990137-0.jpeg",
    },

    {
        id: 42,
        title: "Nord",
        publisher: "Universal Music Romania",
        year: 2018,
        authors: ["Luna Amara"],
        discs: 1,
        language: "romana",
        ISBN: "0602577309267",
        img: "240064296-0.jpeg",
    },

    {
        id: 43,
        title: "Julia Set",
        publisher: "Soft Records",
        year: 2019,
        authors: ["N.O.R."],
        discs: 1,
        language: "romana",
        ISBN: "4075303104911",
        img: "518272143-0.jpeg",
    },

    {
        id: 44,
        title: "Ispita",
        publisher: "Trei Betivi",
        year: 2022,
        authors: ["Celelalte Cuvinte"],
        discs: 1,
        language: "romana",
        ISBN: "2000001033142",
        img: "2038148400-0.png",
    },

    {
        id: 45,
        title: "Amy Winehouse: At The BBC",
        publisher: "UMC",
        year: 2021,
        authors: ["Amy Winehouse"],
        discs: 3,
        language: "engleza",
        ISBN: "0602435415604",
        img: "1324539085-0.jpeg",
    },
    {
        id: 46,
        title: "Acordul parintilor",
        publisher: "Universal Music Romania",
        year: 2023,
        authors: ["Coma"],
        discs: 1,
        language: "romana",
        ISBN: "0602455494948",
        img: "2109339249-0.jpeg",
    },
    {
        id: 47,
        title: "Fericirea e un lucru marunt",
        publisher: "Soft Records",
        year: 2021,
        authors: ["Țapinarii"],
        discs: 1,
        language: "romana",
        ISBN: "4075303195216",
        img: "2068250002-0-240.jpeg",
        tags: ["tapinarii"]
    },
    {
        id: 48,
        title: "Vernisaj",
        publisher: "MediaPro Music",
        year: 2022,
        authors: ["Vunk"],
        discs: 1,
        language: "romana",
        ISBN: "0602448687043",
        img: "2062202938-0.jpeg",
    },

    {
        id: 49,
        title: "Acasa",
        publisher: "Global Records",
        year: 2022,
        authors: ["Irina Rimes"],
        discs: 1,
        language: "romana",
        ISBN: "5948223520187",
        img: "2007694693-0.jpeg",
    },

    {
        id: 50,
        title: "Echilibru",
        publisher: "",
        year: 2022,
        authors: ["Om la luna"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "om-la-luna-echilibru.jpg",
    },

    {
        id: 51,
        title: "Dans",
        publisher: "",
        year: 2022,
        authors: ["Om la luna"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "om-la-luna-dans.jpg",
    },
    {
        id: 52,
        title: "În caz de om la lună",
        publisher: "",
        year: 2022,
        authors: ["Om la luna"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "om-la-luna-in-caz-de-om-la-luna.jpg",
    },
    {
        id:53,
        title: "Aimée",
        publisher: "",
        year: 2020,
        authors: ["Julien Doré"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "Aimée_(album_de_Julien_Doré).png",
    },

    {
        id:54,
        title: "On n'enferme pas les oiseaux",
        publisher: "",
        year: 2021,
        authors: ["Barbara Pravi"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "Barbara_Pravi_-_On_N’enferme_Pas_Les_Oiseaux.png",
    },

    {
        id:55,
        title: "Les Prières - Racines",
        publisher: "",
        year: 2021,
        authors: ["Barbara Pravi"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "Les-Prieres-Racines.jpg",
    },

    {
        id:56,
        title: "Déjà Venise",
        publisher: "",
        year: 2019,
        authors: ["Clio"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "Deja-Venise.jpg",
    },

    {
        id:57,
        title: "Tête brulée",
        publisher: "",
        year: 2022,
        authors: ["Iliona"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "Tete-brulee.jpg",
    },


    {
        id:58,
        title: "Rayons Gamma",
        publisher: "",
        year: 2021,
        authors: ["P.R2B"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "Rayons-Gamma.jpg",
    },

    {
        id:59,
        title: "Pietre În Alb",
        publisher: "",
        year: 2022,
        authors: ["Luna Amară"],
        discs: 2,
        language: "romana",
        ISBN: "",
        img: "R-23948174-1658436531-3368.jpg",
    },

    {
        id:60,
        title: "Stasis",
        publisher: "",
        year: 2019,
        authors: ["IGLU"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "391969218-0.jpeg",
    },

    {
        id:61,
        title: "Antimaterie",
        publisher: "",
        year: 2021,
        authors: ["Oigăn"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "Oigăn+-+Antimaterie+Cover.jpg",
    },


    {
        id:62,
        title: "Flori de Mucegai",
        publisher: "Supersömnic Records",
        year: 2023,
        authors: ["Pinholes"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "pinholes-flori-de-mucegai-580153.jpg",
    },

    {
        id:63,
        title: "Aproape",
        publisher: "Universal Music România",
        year: 2023,
        authors: ["Luna Amară"],
        discs: 1,
        language: "romana",
        ISBN: "",
        img: "luna-amara-nord-black-copie-673110.jpg",
    },

    {
        id:64,
        title: "Saraca fata bogata",
        publisher: "Universal Music Romania",
        year: 2023,
        authors: ["Paulina"],
        discs: 1,
        language: "romana",
        ISBN: "0602455254092",
        img: "2151764566-2.jpeg",
    },

    {
        id:65,
        title: "Descantec",
        publisher: "Universal Music Romania",
        year: 2023,
        authors: ["Dora Gaitanovici"],
        discs: 1,
        language: "romana",
        ISBN: "0602455402875",
        img: "2150060679-0.jpeg",
    },
    {
        id:66,
        title: "Altorchestra",
        publisher: "Overground Music",
        year: 2023,
        authors: ["Byron"],
        discs: 2,
        language: "romana",
        ISBN: "5948223500134",
        img: "img.png",
    },
    {
        id:67,
        title: "Vladivostok",
        publisher: "Universal Music Romania",
        year: 2019,
        authors: ["Robin and the Backstabbers"],
        discs: 1,
        language: "romana",
        ISBN: "5948223500110",
        img: "2122327888-1.jpeg",
    },

    {
        id:68,
        title: "Inelele lui Saturn",
        publisher: "Art",
        year: 2018,
        authors: ["W.G. SEBALD"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105933",
        img: "223352406-3.jpeg",
    },

    {
        id:69,
        title: "Cartea verii",
        publisher: "Art",
        year: 2018,
        authors: ["TOVE JANSSON"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105957",
        img: "223352244-3.jpeg",
    },

    {
        id:70,
        title: "Vremurile bune de altadata",
        publisher: "Art",
        year: 2018,
        authors: ["BOHUMIL HRABAL"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105964",
        img: "223352436-3.jpeg",
    },

    {
        id:71,
        title: "Gottland",
        publisher: "Art",
        year: 2018,
        authors: ["MARIUSZ SZCZYGIEŁ"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105926",
        img: "223352403-3.jpeg",
    },

    {
        id:72,
        title: "Muzica pentru cameleoni",
        publisher: "Art",
        year: 2018,
        authors: ["TRUMAN CAPOTE"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105940",
        img: "223352433-3.jpeg",
    },

    {
        id:73,
        title: "Dominatia Masculina",
        publisher: "Art",
        year: 2018,
        authors: ["PIERRE BOURDIEU"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105971",
        img: "223352283-0.png",
    },

    {
        id:74,
        title: "Ce-am pierdut in foc",
        publisher: "Art",
        year: 2018,
        authors: ["MARIANA ENRIQUEZ"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105919",
        img: "223352223-0.jpeg",
    },

    {
        id:75,
        title: "Mai este oare acesta un om?",
        publisher: "Art",
        year: 2018,
        authors: ["PRIMO LEVI"],
        discs: 1,
        language: "romana",
        ISBN: "9786067105902",
        img: "223352226-0.jpeg",
    },

    {
        id:76,
        title: "Fata Morgana",
        publisher: "Universal Music Romania",
        year: 2023,
        authors: ["DL GOE"],
        discs: 1,
        language: "romana",
        ISBN: "0602458208962",
        img: "2382171714-0.jpeg",
    },

    {
        id:77,
        title: "Days are Gone",
        publisher: "Universal Music Romania",
        year: 2013,
        authors: ["Haim"],
        discs: 2,
        language: "engleza",
        ISBN: "0602537524440",
        img: "0602537524440-2559820.jpg",
    },
    {
        id:78,
        title: "Le Pop 10",
        publisher: "",
        year: 2022,
        authors: ["Emma Peters, Clou, Malik Djoudi, KCIDY, Ussar, Edwige, Palatine, Iliona,  Carla De Coignac, Voyou, P.R2B, Camélia Jordana, Laura Cahen, Suzanne Lindon, Ariane Roy,  Elisa Erka"],
        discs: 2,
        language: "franceza",
        ISBN: "",
        img: "LPM054LP_CU.jpg",
    },

    {
        id:79,
        title: "Le Pop 9 Au Debut",
        publisher: "",
        year: 2018,
        authors: ["Hollydays, Angèle, Eddy Crampes, Pomme, Paradis, Laurie Darmon, Ezechiel Pailhès, Ali Danel, La Femme, Alexia Gredy, Adrien Soleiman, Flavien Berger, Achille,  Gérald Kurdian, Chaton"],
        discs: 2,
        language: "franceza",
        ISBN: "",
        img: "1000x1000_v-a-le-pop-9-au-debut-vinyl.jpg",
    },
    {
        id:80,
        title: "Sentiments",
        publisher: "",
        year: 2023,
        authors: ["Louane"],
        discs: 1,
        language: "franceza",
        ISBN: "B0BXYLKJ5C",
        img: "ab67616d0000b2736ddad5ffc62722ceecacd18a.jpg",
    },

    {
        id:81,
        title: "Nonante-cinq La Suite",
        publisher: "",
        year: 2021,
        authors: ["Angele"],
        discs: 1,
        language: "franceza",
        ISBN: "",
        img: "ab67616d0000b27397011e40525bc67e15e6ba88.jpg",
    },

    {
        id:82,
        title: "Fazele lunii",
        publisher: "",
        year: 2023,
        authors: ["Om la lună"],
        discs: 2,
        language: "romana",
        ISBN: "",
        img: "om-la-luna.png",
    },
    {
        id:83,
        title: "La Symphonie Des Eclairs (Le Dernier Des Voyages) ",
        publisher: "Disparate",
        year: 2024,
        authors: ["Zaho De Sagazan"],
        discs: 2,
        language: "franceza",
        ISBN: "",
        img: "zaho.jpeg",
    },

    {
        id:84,
        title: "Vunk",
        publisher: "Universal Music Romania",
        year: 2022,
        authors: ["Vunk"],
        discs: 2,
        language: "romana",
        ISBN: "",
        img: "3959333795-0.jpeg",
    },

]