import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function CommodoreMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-commodore";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>Commodore Plus/4</b>
                <br/>
                <br/>
                Commodore Plus/4 este un computer de acasă lansat de Commodore International în 1984.
                Numele „Plus/4” se referă la suita de birou cu patru aplicații rezidente ROM ( procesor de text ,foaie de calcul ,bază de date și grafică ).
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_44.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_45.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_46.png'}/>
                </div>

                <hr/>
                <b>Commodore A-500 / Amiga 500</b>
                <br/>
                <br/>

                A lansat in 1987:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_47.png'}/>
                </div>

                <hr/>
                <b>Commodore C64</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_58.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_59.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_65.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_66.png'}/>
                </div>

                <hr/>
                <b>Commodore C64 (C64 Ser.-No 502616)</b>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_24.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_25.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_26.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_27.png'}/>
                </div>


                <hr/>
                <b>Commodore C64C / C64-II</b>
                <br/>
                <br/>

                A fost lansat in 1986:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_60.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_61.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_67.png'}/>
                </div>

                <hr/>
                <b>Commodore C64 (Ser.-No HB4 525018)</b>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_28.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_29.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_30.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_31.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_32.png'}/>
                </div>

                <hr/>
                <b>Commodore 64</b>
                <br/>
                <br/>

                Commodore 64, cunoscut și sub numele de C64, este un computer pe 8 biți, lasant în ianuarie 1982 de Commodore International:
                <br/>
                A fost listat în Guinness World Records ca fiind cel mai vândut model de computer unic din toate timpurile, cu estimări independente plasând numărul vândute
                între 12,5 și 17 milioane de unități:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_62.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_63.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_64.png'}/>
                </div>

                Porturi spate:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_22.png'}/>
                </div>

                <ul>
                    <li>1: Port de expansiune (I/O), ( port de memorie ).</li>
                    <li>2: Mufă RF (O) pentru conectarea directă la intrarea RF a unui televizor.</li>
                    <li> 3: Mufă A/V (I/O) pentru conectarea unui monitor de computer și/sau a unui amplificator audio.</li>
                    <li> 4: Port serial (I/O) pentru conectarea unei unități de dischetă sau a unei imprimante .</li>
                    <li> 5: Port casetă (I/O) pentru conectarea unui set de date .</li>
                    <li> 6: Portul de utilizator (I/O) este programabil liber și poate fi folosit pentru a conecta cartuse sau carduri de interfață.</li>
                </ul>

                Porturi laterale:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_23.png'}/>
                </div>

                <ul>
                    <li>
                        1 și 2: Cele două porturi de control (I) 1 și 2 sunt utilizate în principal ca intrări de control pentru jocuri.
                    </li>

                    <li>
                        3. Comutatorul pornit/oprit.
                    </li>
                    <li>
                        4. Mufă de alimentare (I) pentru sursa de alimentare.
                    </li>
                </ul>

                Status:
                <ul>
                    <li>se aprinde becul rosu</li>
                    <li>nu pare ca functioneaza</li>
                    <li>tasta F1 este rupta</li>
                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default CommodoreMuseumContent;