import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ConfigIdentityProviderKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-config-identity-provider", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                            <b>Configurare Identity Provider</b>
                            <br/>
                            <ul>

                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Identity Providers</b> (meniul din stanga):
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-20.png'}/>
                                    </div>
                                </li>
                                <li>se selecteaza <b>Keycloak OpenID Connect</b> la <b>Add provider...</b></li>
                                <li>se completeaza formularul <b>Add identity provider</b>
                                    <ul>
                                        <li><b>Alias</b>:vanilla-keycloak-oidc</li>
                                        <li><b>Display Name</b>:Keycloak OpenID Connect Identity Provider
                                            <div style={{padding:10}}>
                                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-21.png'}/>
                                            </div>
                                        </li>
                                        <li>
                                           se continua completarea formularului si pentru sectiunea <b>OpenID Connect Config</b> cu informatii obtinute:
                                            <ul>
                                                <li>pe baza serviciului <b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration </li>
                                            </ul>
                                           astfel:
                                           <ul>
                                               <li>
                                                   <b>Authorization URL</b>: <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth</i>
                                               </li>
                                               <li>
                                                   <b>Token URL</b>: <i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token</i>
                                               </li>
                                               <li>
                                                   <b>Logout URL</b>:<i>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/logout</i>
                                               </li>
                                               <li>
                                                   <b>Client Authentication</b>:<i>Client secret sent as post</i>
                                               </li>
                                               <li>
                                                   <b>Client ID</b>:<i>liferay-portal-client</i>
                                               </li>
                                               <li>
                                                   <b>Client Secret</b>:<i>0c778028-f03e-4bdd-b835-8ecb33653ea0</i>

                                                   <div style={{padding:10}}>
                                                       <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                                            src={process.env.PUBLIC_URL + '/img/liferay/keycloak-22.png'}/>
                                                   </div>
                                               </li>
                                               <li>se apasa butonul <b>Save</b></li>
                                           </ul>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ConfigIdentityProviderKeycloakContent;