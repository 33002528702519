import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HistoryMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-history";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <b>History</b>
                <br/>
                <br/>

                România a fost cea de a 8-a țară din lume care a reușit proiectarea și realizarea unui calculator electronic.
                <ul>
                    <li>
                        1967 - s-a înființat ITC (Institutul pentru Tehnică de Calcul)
                    </li>
                    <li>
                        1968 - este cumpărată de la compania franceză Compagnie internationale pour l'informatique (CII) licența pentru producția de calculatoare Iris 50, adaptate
                        în România sub numele de Felix C-256
                    </li>
                    <li>
                        1970 - este înființat Institutul Central de Informatică (ICI)
                    </li>
                    <li>
                        1971 - este înființată Fabrica de Memorii Timișoara
                    </li>
                    <li>
                        1974 - se înființează Rom Control Data, societate mixtă româno-americană
                    </li>
                </ul>
                <hr/>
                Cronologie calculatoare (1957-1977):
                <ul>
                    <li>
                        1957 - <b>CIFA-1</b> (Calculatorul Institutului de Fizică Aplicată 1)
                        <ul>
                            <li>primul calculator electronic românesc cu tuburi electronice</li>
                            <li>realizat la: Institutul de Fizică Atomică</li>
                            <li>sub indrumarea: Victor Toma</li>
                        </ul>
                    </li>
                    <li>
                        1959 - <b>CIFA-2</b>
                        <ul>
                            <li>cu 800 de tuburi electronice</li>
                        </ul>
                    </li>
                    <li>
                        1959 - <b>MARICA</b> (Mașina Automată cu Relee a Institutului de Calcul al Academiei), conceput și realizat experimental la Institutul de Calcul din Cluj
                        <ul>
                            <li>
                                În prezent se află la Muzeul Național Tehnic Dimitrie Leonida.
                            </li>
                        </ul>

                    </li>
                    <li>
                        1959 - <b>DACICC-1</b> (Dispozitiv Automat de Calcul al Institutului de Calcul Cluj)
                        <ul>
                            <li>sub indrumarea: Tiberiu Popoviciu</li>
                            <li>
                                În prezent se află la Muzeul Național Tehnic Dimitrie Leonida.
                            </li>
                        </ul>
                    </li>
                    <li>
                        1961 - <b>MECIPT-1</b> (Mașina Electronică de Calcul a Institutului Politehnic Timișoara)
                        <ul>
                            <li>
                                a fost un calculator de generația întâi
                            </li>
                            <li>
                                proiectarea acestuia a început încă din 1956 de către un colectiv condus de Iosif Kaufmann, Wilhelm Löwenfeld și Vasile Baltac
                            </li>
                        </ul>
                    </li>
                    <li>
                        1961 - <b>CIFA-3</b>
                    </li>
                    <li>
                        1962 - <b>CIFA-4</b>
                    </li>
                    <li>
                        1962 - <b>CENA</b>
                        <ul>
                            <li>calculator militar românesc folosit de MApN, după modelul calculatorului MECIPT-1</li>
                        </ul>
                    </li>
                    <li>
                        1962 - <b>CIFA-101</b>
                    </li>
                    <li>
                        1963 - <b>MECIPT-4</b> (Mașina Electronică de Calcul a Institutului Politehnic Timișoara)
                        <ul>
                            <li>
                                a fost un calculator de generația a doua, cu tranzistoare și memorie cu ferite
                            </li>
                        </ul>
                    </li>
                    <li>
                        1964 - <b>CET 500</b>
                        <ul>
                            <li>
                                realizat la Institutul de Fizică Atomică din București
                            </li>
                        </ul>
                    </li>
                    <li>
                        1964 - <b>CIFA-102</b>
                    </li>
                    <li>
                        1965 - <b>MECIPT-3</b> (Mașina Electronică de Calcul a Institutului Politehnic Timișoara)
                        <ul>
                            <li>
                                generația a treia, complet tranzistorizat
                            </li>
                        </ul>
                    </li>
                    <li>
                        1966 - <b>CET 501</b>
                    </li>
                    <li>
                        1968 - <b>DACICC-200</b> (Dispozitiv Automat de Calcul al Institutului de Calcul Cluj)
                    </li>
                    <li>
                        1970 - <b>CESAR</b>
                        <ul>
                            <li>calculator militar românesc realizat la ITCI Cluj, pentru artileria regimentală din Armata Română</li>
                        </ul>
                    </li>
                    <li>
                        1970 - <b>CND1</b>
                    </li>
                    <li>
                        1971 - <b>MC1</b>
                        <ul>
                            <li>
                                produs Institutul Politehnic din București, realizat în mare parte folosind tehnologia Felix C-256
                            </li>
                        </ul>
                    </li>
                    <li>
                        1972 - <b>CETA</b>:
                        <ul>
                            <li>calculator românesc din generația a II-a, cu tranzistori</li>
                        </ul>
                    </li>
                    <li>
                        1973 - <b>Felix C-256</b>
                        <ul>
                            <li>
                                primul calculator românesc sub licență franceză IRIS 50
                            </li>
                        </ul>
                    </li>
                    <li>
                        1974 - <b>FELIX MC-8</b>
                    </li>
                    <li>
                        1974 - <b>Felix C 512</b>
                    </li>
                </ul>
                <hr/>
                Cronologie calculatoare (1977-1982):
                <ul>
                    <li>
                        1977 - <b>Independent I-100</b>
                        <ul>
                            <li>este realizat primul minicalculator românesc</li>
                            <li>realizat la: ITC</li>
                        </ul>
                    </li>
                    <li>
                        1979 - <b>Independent I-102F</b>
                    </li>
                    <li>
                        1979 - <b>CORAL</b>
                    </li>
                    <li>
                        1980 - <b>L/B881</b>
                        <ul>
                            <li>microcalculator românesc bazat pe procesorul 8080</li>
                            <li>dezvoltat de radioamatorii din grupul Lixco, sub îndrumarea lui Nicoară Paulian</li>
                        </ul>
                    </li>
                    <li>
                        1980 - <b>CUB</b> si <b>CUB-Z</b>
                        <ul>
                            <li>
                                CUB și CUB-Z (Calculator Universal de Birou) au fost două mărci de calculatoare produse la I.C.E. Felix București. Unul dintre cele mai reușite
                                modele, CUB-Z, a fost accesibil pentru utilizare largă în perioada 1987-1989, după care a fost înlocuit de modelul HC-88
                            </li>
                        </ul>
                    </li>
                </ul>
                <hr/>
                Cronologie calculatoare (1982-1994):
                <ul>
                    <li>
                        1982 - <b>aMIC</b>
                        <ul>
                            <li>
                                microcalculator personal românesc
                            </li>
                            <li>
                                produs ca model de laborator la Catedra de Calculatoare din Institutul Politehnic București de catre prof. Adrian Petrescu si Francisc Iacob,
                            </li>
                            <li>
                                scos din fabricatie: 1984
                            </li>
                        </ul>
                    </li>
                    <li>
                        1983 - <b>TIM-S</b>
                        <ul>
                            <li>
                                este produs la Timișoara, pana in 1986, de Institutul pentru Tehnică de Calcul și Informatică (ITCI) și Fabrica de Memorii electronice și Componente pentru Tehnică de Calcul și Informatică (FMECTC)
                            </li>
                        </ul>
                    </li>
                    <li>
                        1983 - <b>Prae</b>, la Institutul de Tehnică de Calcul (ITC) - in limba latinã, pra (citit pre) inseamna inceput
                        <ul>
                            <li>
                                primul microcalculator personal de conceptie romaneasca, destinat in primul rand elevilor
                            </li>
                            <li>
                                a fost proiectat de catre o echipa a Institutului de Tehnica de Calcul din Cluj-Napoca sub conducerea inginerului Miklos Patrubany
                            </li>
                            <li>
                                a fost asimilat in productia de serie in cursul anului 1985 la Fabrica de Memorii si Componente Electronice pentru Tehnica de Calcul din Timisoara (FMCETC), insa dupa numai un an productia a fost sistata;
                                <br/>
                                Centrala Industriala de Electronica si Tehnica de Calcul a decis intre timp continuarea fabricarii in exclusivitate a modelelor compatibile Spectrum (intre care HC 85, TIM-S) si IBM PC (Felix PC), iar PRAE nu se incadra in niciuna dintre aceste categorii.
                            </li>
                            <li>
                                variante
                                <ul>
                                    <li>PRAE-1000:
                                        <br/>
                                        era construit in jurul microprocesorului Z80 (cu viteza de 2,5 MHz) si avea 16 kB memorie RAM si 16 kB memorie EPROM. Dispozitivul de afisare era televizorul alb-negru, care putea fi conectat prin intermediul mufei de antena. PRAE-1000 putea lucra atat in mod text (32×30 caractere), cat si in mod grafic (la o rezolutie de 256×256 pixeli), insa doar monocrom, fara nuante intermediare intre alb si negru. Tastatura calculatorului era ultraplata, cu 40 de taste circulare care functionau la simpla atingere. Fiecare tasta era garantata pentru un milion de apasari. Stocarea datelor se facea pe caseta, la o densitate a inregistrarii de 1200 baud. Sursa de alimentare si puntea redresoare erau interne; tot in interior exista si un mic difuzor pentru semnalarea sonora a erorilor.
                                    </li>
                                    <li>
                                        PRAE-T
                                    </li>
                                    <li>
                                        PRAE-L
                                    </li>
                                    <li>
                                        PRAE-M
                                    </li>
                                    <li>
                                        PRAE-MAX - iunie 1988 (nu au intrat niciodata in productia de serie)
                                    </li>
                                    <li>
                                        PRAE-PHOENIX - iunie 1988 (nu au intrat niciodata in productia de serie)
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </li>
                    <li>
                        1983 - <b>Independent I-106</b>
                    </li>

                    <li>
                        1984 - <b>CM4</b>
                    </li>
                    <li>
                        1984 - <b>DIALISP</b>, realizat la Universitatea Politehnica din București
                    </li>
                    <li>
                        1985 - <b>HC 85</b>
                    </li>
                    <li>
                        1986 - <b>CoBra</b> (singurul calculator românesc produs la Brașov)
                    </li>
                    <li>
                        1988 - <b>CIP 01</b>
                    </li>
                    <li>
                        1989 - <b>JET</b>
                    </li>
                    <li>
                        1989 - <b>CIP 02</b>
                    </li>
                    <li>
                        1989 - <b>HC 88</b>
                    </li>
                    <li>
                        1991 - <b>HC 91</b>
                    </li>
                    <li>
                        1992 - <b>HC 2000</b>; scos din fabricatie 1994
                    </li>
                </ul>


            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HistoryMuseumContent;