import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class RouterNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-router", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Gasirea adresei rooter-ului</b>
                    <br/><br/>
                    <ul>
                        <li>
                            Din linie de comanda se ruleaza: <b>ipconfig</b>

                            <SyntaxHighlighter showLineNumbers={true} language="cmd" style={androidstudio}>
                                {'Wireless LAN adapter WiFi:\n' +
                                    '\n' +
                                    '   Connection-specific DNS Suffix  . : local\n' +
                                    '   IPv6 Address. . . . . . . . . . . : **************************************\n' +
                                    '   Temporary IPv6 Address. . . . . . : **************************************\n' +
                                    '   Link-local IPv6 Address . . . . . : xxxx::xxxx:xxxx:xxxx:xxxx%21\n' +
                                    '   IPv4 Address. . . . . . . . . . . : 192.168.1.4\n' +
                                    '   Subnet Mask . . . . . . . . . . . : 255.255.255.0\n' +
                                    '   Default Gateway . . . . . . . . . : xxxx::xxxx%21\n' +
                                    '                                       192.168.1.1\n'}
                            </SyntaxHighlighter>

                            Adresa rooter-ului este <b>192.168.1.1</b>!

                        </li>
                        <li>
                            pe spatele router-ului:
                            <ul>
                                <li>Default terminal WEB IP: 192.168.1.1</li>
                                <li>Default terminal WEB user name: user</li>
                                <li>Default terminal WEB password: digi</li>
                            </ul>
                        </li>
                    </ul>

                    Daca scriem in bara de adrese a browser-ului: <b>http://192.168.1.1/login.html</b>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/nas-2/img.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   <li>*/}
                    {/*       <a target={"_blank"} href={"https://mariushosting.com/how-to-reset-synology-network-settings/"}>https://mariushosting.com/how-to-reset-synology-network-settings/</a>*/}
                    {/*   </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RouterNasContent;