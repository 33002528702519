import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers5LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-5", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    Contiunuam sa lucram la modul (de exemplu: <i>ibrid-site-initializer</i>) cu urmatoarea structura:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'ibrid-site-initializer\n' +
                            '├──src\n' +
                            '│  └──main\n' +
                            '│     └──resources\n' +
                            '│        ├── META-INF\n' +
                            '│        │   └── resources\n' +
                            '│        │       └── thumbnail.png\n' +
                            '│        └── site-initializer\n' +
                            '│            ├── ddm-templates   \n' +
                            '│            ├── documents   \n' +
                            '│            ├── fragments   \n' +
                            '│            ├── layout-page-templates     \n' +
                            '│            ├── layout-set     \n' +
                            '│            ├── layouts     \n' +
                            '│            ├── style-books     \n' +
                            '│            ├── expando-columns.json     \n' +
                            '│            └── roles.json  \n' +
                            '├── bnd.bnd\n' +
                            '└── build.gradle'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>1. Definire Layout Set</b>
                    <br/>
                    <br/>

                    Se pot defini opțiunile implicite pentru Layout Set folosind următoarea structură de fișiere:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'site-initializer\n' +
                            '└── layout-set\n' +
                            '    └── public\n' +
                            '        ├── css.css\n' +
                            '        ├── js.js\n' +
                            '        ├── logo.png\n' +
                            '        └── metadata.json'}
                    </SyntaxHighlighter>

                    Mai sus, s-a definit configurația pentru paginile publice.
                    <br/>
                    Dacă trebuie să definita configurația pentru paginile private, atunci se creaza dun director numit <b>private</b> cu aceeași structură ca <b>public</b>.

                    <br/>
                    <br/>
                    <b>Observatie:</b>
                    <ul>
                        <li>
                            dacă este nevoie atât de pagini publice, cât și de pagini private - trebuie activata funcționalitatea <b>Private Pages</b> din <b>Release Feature Flags</b>.
                        </li>
                    </ul>

                    <hr/>


                    In fisierul <b>logo.png</b> se poate defini un logo personalitat:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_22.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_23.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_24.png'}/>
                    </div>

                    În fisierul <b>css.css</b> se poate defini cod CSS personalizat pentru <b>Layout Set</b> (va fi vizibil în setările <b>Look & Feel</b> de pe site-ul creat).

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'.ibrid-wrapper .ibrid-footer {\n' +
                            '    position: fixed;\n' +
                            '    bottom: 0;\n' +
                            '    left: 0;\n' +
                            '    right: 0;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_25.png'}/>
                    </div>

                    In fisierul <b>js.js</b> se poate pune cod Javascript personalizat ( va apare in configuratia <b>Advanced ~ Javascript</b> pentru <b>Layout Set</b>).

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'console.log(\'ibrid liferay-site-initializer...\');'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/si_26.png'}/>
                    </div>

                    In fisierul <b>metadata.json</b> se poate defini setările necesare temei:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "settings": {\n' +
                            '    "lfr-theme:regular:show-footer": false,\n' +
                            '    "lfr-theme:regular:show-header": false,\n' +
                            '    "lfr-theme:regular:show-header-search": false,\n' +
                            '    "lfr-theme:regular:show-maximize-minimize-application-links": false,\n' +
                            '    "lfr-theme:regular:wrap-widget-page-content": false\n' +
                            '  },\n' +
                            '  "themeName": "Classic"\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>
                                Introducing Site Initializers
                            </a>
                        </li>
                        <li>
                            <a href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>
                                Site Initializers: Update Support
                            </a>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers5LiferayContent;