import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class OpenaiApiKeySpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-openai-api-key-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    
                    <b>Generare cheie pentru OPENAI_API_KEY</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_9.png'}/>
                    </div>

                    <ul>
                        <li>
                            API key name: AgnesTestKey
                        </li>
                        <li>
                            Project name: SpringAiTest
                        </li>
                    </ul>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_10.png'}/>
                    </div>

                    <SyntaxHighlighter>
                        {'curl https://api.openai.com/v1/chat/completions \\\n' +
                            '  -H "Content-Type: application/json" \\\n' +
                            '  -H "Authorization: Bearer XXXX" \\\n' +
                            '  -d \'{\n' +
                            '    "model": "gpt-4o-mini",\n' +
                            '    "store": true,\n' +
                            '    "messages": [\n' +
                            '      {"role": "user", "content": "write a haiku about ai"}\n' +
                            '    ]\n' +
                            '  }\'\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Adaugare OPENAI_API_KEY in EV</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_6.png'}/>
                    </div>

                    Modify options:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_7.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_8.png'}/>
                    </div>


                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default OpenaiApiKeySpringIALlmContent;