import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class BladeLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-blade", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Instalare Blade CLI pe Windows</b>
                    <br/>

                    Se urmeaza urmatorii pasi:
                    <ul>
                        <li>se descarca JPM (Java package manager), adica fisierul <b>biz.aQute.jpm.run.jar</b> file folosind urmatorul link:
                            <br/>
                            <a href={"https://raw.githubusercontent.com/jpm4j/jpm4j.installers/master/dist/biz.aQute.jpm.run.jar"}>
                                https://raw.githubusercontent.com/jpm4j/jpm4j.installers/master/dist/biz.aQute.jpm.run.jar
                            </a>
                            <br/>
                            sa presupunem ca il punem in locatia: <i>C:\tools\jpm</i>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'cd C:\\tools\\jpm'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            initializare JPM (Java package manager):
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'java -jar biz.aQute.jpm.run.jar init'}
                            </SyntaxHighlighter>
                            in consola se va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'post : C:\\JPM4J\\bin\\jpm _postinstall\n' +
                                'In post install\n' +
                                'Home dir      C:\\JPM4J\n' +
                                'Bin  dir      C:\\JPM4J\\bin'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            adaugare in ENV (System Environment Variables) in <b>Path</b> calea catre JPM:
                            <ul>
                                <li>se creeaza JPM_HOME:C:\tools\jpm</li>
                                <li>se adauga la Path: %JPM_HOME%\bin</li>
                            </ul>
                            testare, folosind comanda: <b>jpm install</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'jpm version\n' +
                                '3.2.0.201605172009'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            instalare Blade, folosind comanda: <b>jpm install</b>
                            <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                                {'jpm install -f https://releases.liferay.com/tools/blade-cli/latest/blade.jar'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            update/actualizare Blade: <b>blade update</b>
                            <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                                {'blade update\n' +
                                'Current blade version 3.3.0.201811151753\n' +
                                'Updating from: https://repository-cdn.liferay.com/nexus/content/repositories/liferay-public-releases/com/liferay/blade/com.liferay.blade.cli//4.0.12/com.liferay.blade.cli-4.0.12.jar\n' +
                                'blade update cannot execute successfully because of Windows file locking.  Please use the following command:\n' +
                                '        jpm install -f https://repository-cdn.liferay.com/nexus/content/repositories/liferay-public-releases/com/liferay/blade/com.liferay.blade.cli//4.0.12/com.liferay.blade.cli-4.0.12.jar\n' +
                                '\n' +
                                'blade version 3.3.0.201811151753\n' +
                                '\n' +
                                'Run \'blade update\' to update to  version 4.0.12'}
                            </SyntaxHighlighter>
                        </li>

                    </ul>

                    <hr/>

                    <b>1. Creare Liferay Workspace</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'blade init -v 7.4 liferay-vanilla-blade'}
                    </SyntaxHighlighter>

                    Dupa executia comenzii se va creea un director numit <i>liferay-vanilla-blade</i>, avand urmatoarea structura:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/blade-1.png'}/>
                    </div>

                    <hr/>
                    <b>2. Creare portlet</b>

                    Mergem in locatia Lifery Workspace (creata mai sus)
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'cd liferay-vanilla-blade'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'blade create -t mvc-portlet -p com.dopamina.test.portlet -c TestPortlet test-portlet'}
                    </SyntaxHighlighter>
                    unde:
                    <ul>
                        <li><b>t</b> project-template (ex: mvc-portlet)</li>
                        <li><b>p</b> nume pachet (ex: com.dopamina.portlet)</li>
                        <li><b>c</b> clasa (ex: MyPortlet)</li>
                        <li><i>nume proiect</i> (numele modului/portletului); se va creea in mod implicit in directorul <b>modules</b> din <i>Liferay Workspace</i></li>
                    </ul>

                    In directorul <b>modules</b> se va creea modul/directorul <i>test-portlet</i>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/blade-2.png'}/>
                    </div>

                    <hr/>
                    <b>3. Alte comenzi</b>
                    <ul>
                        <li>blade gw - executa comenzi Gradle folosind Gradle Wrapper (ex: blade gw tasks)</li>
                        <li>blade sh - executa comenzi Gogo Shell (ex: blade sh lb)</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        <li>
                            <div>
                                <a href={"https://lifedev-solutions.blogspot.com/2020/01/installing-blade-cli-from-command-line.html"}>
                                    Installing Blade CLI from Command Line
                                </a>
                            </div>
                        </li>
                        <li>
                            <div>
                                <a href={"http://www.liferaysavvy.com/2016/11/java-package-manager-jpm.html"}>
                                    Java Package Manager (JPM)
                                </a>
                            </div>
                        </li>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BladeLiferayContent;