import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class PotentiometerArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-potentiometer", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Potentiometru</b>
                    <br/>
                    <br/>

                    Un potentiometru (eng: potentiometer/ potmeter) poate arata cam asa:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/potentiometru.png'}/>
                    </div>

                    Un potentiometru poate fi vazut ca un rezistor cu rezistenta variabil (un rezistor are rezistenta fixa).

                    <br/>

                    Un potentiometru are 3 terminale:
                    <ul>
                        <li>
                            2 fixe (power+ground)
                        </li>
                        <li>
                            1 variabil (semnal)
                        </li>
                    </ul>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 150}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/p_2.png'}/>
                    </div>

                    {/*Circuitul curentului ar fi cam asa:*/}
                    {/*<div style={{padding: 10}}>*/}
                    {/*    <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}*/}
                    {/*         src={process.env.PUBLIC_URL + '/img/arduino/p_3.png'}/>*/}
                    {/*</div>*/}

                    {/*Un potentiometul se poate masura cu un multimetru:*/}
                    {/*<div style={{padding: 10}}>*/}
                    {/*    <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}*/}
                    {/*         src={process.env.PUBLIC_URL + '/img/arduino/p3.jpg'}/>*/}
                    {/*</div>*/}

                    Se va folosi pini de tip <b>analog</b>, notati pe arduino cu: <b>A0, A1, A2, A3, A4, A5 </b> ca <b>input</b>!

                    <br/>
                    Cod (citire valoare; poate lua valori de la 0 la 1023):
                    <SyntaxHighlighter>
                        {'// C++ code\n' +
                            '//\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  Serial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '  \n' +
                            '  Serial.println(analogRead(A0));\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>
                    sau (citire valoare, scriere valoare sau folosire valoare ca input pentru alta componenta pe pinul 11):
                    <SyntaxHighlighter>
                        {'// C++ code\n' +
                            '//\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  pinMode(11, OUTPUT);\n' +
                            '  Serial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '  \n' +
                            '  int valoareSenzor = analogRead(A0);\n' +
                            '  // float voltaj = (valoareSenzor * 5 / 1024.0)\n' +
                            '  int luminozitate = valoareSenzor / 4;\n' +
                            '  analogWrite(11, luminozitate);\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>
                    Reamintim:
                    <ul>
                        <li>pentru analogRead(), valorile posibile sunt intre: 0..1023 (pe pinii A0, A1, A2, A3, A4, A5);</li>
                        <li>pentru analogWrite(), valorile posibile sunt intre: 0..255 - (pe pini capabil de PWN)</li>
                        <li>se poate creea o conversie/mapare intre 0..1023 si 0..25, folosind metoda <b>map</b>:
                            <SyntaxHighlighter>
                                {'map(variabilaDeConvertit, 0, 1023, 0, 255)'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            pinii analogi A0, A1, A2, A3, A4, A5 se pot folosi pentru citirea datelor de intrare analogice.
                            <br/>
                            dar, se pot folosi si ca pinii digitali de intrare/ieșire!

                            <br/>
                            deci, un pin digital analog se poate comporta ca un pin analog
                            <SyntaxHighlighter>
                                {'pinMode(A5, OUTPUT)'}
                            </SyntaxHighlighter>

                        </li>
                        <li>
                            citire pin digital: digitalRead(2);
                            <br/>
                            cirire pin angalog: analogRead(A0);
                        </li>
                    </ul>
                    Observatie:
                    <ul>
                        <li>
                            LED-ul trebuie conectat la un pic capabil PWM.
                            <br/>
                            (pinii capabili de PWM sunt marcati pa Arduinc cu ~ sau - sau / si sunt: 3, 5, 6, 9, 10, 11.)
                        </li>
                    </ul>

                    Schema ar fi (cu obiectia ca acel cablu rosu pentru LED trebuie sa ajunga la pinu 11 sau un pin capabil PWM):
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_2.png'}/>
                    </div>



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.youtube.com/watch?v=Wa8CjGsOFzY&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=7&ab_channel=ScienceBuddies"}>
                                How to Use a Potentiometer with Arduino analogRead (Lesson #7)
                            </a>
                        </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PotentiometerArduinoContent;