import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers13LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-13", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Exemplu: Configurare structuri</b>
                    <br/>
                    <br/>

                    Meniu stânga -> Conținut -> Conținut Web:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc1.png'}/>
                    </div>

                    <hr/>
                    <b>
                        1. Creare <b>director/folder</b> cu numele <i>Servicii Online</i>
                    </b>

                    <ul>
                        <li>
                            Se apasa pe butonul +
                        </li>
                        <li>
                            Se alege Fisier
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc2.png'}/>
                    </div>

                    La nume se completeaza: Servicii Online
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc3.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc4.png'}/>
                    </div>

                    <hr/>
                    <b>2. Creare structura de tip SERVICII_ONLINE_CARD_STRUCTURE</b>
                    <br/>
                    <br/>
                    Pasi:
                    <ul>
                        <li>intram in directorul Servicii Online</li>
                        <li>se apasa pe butonul +</li>
                        <li>se apasa pe SERVICII_ONLINE_CARD_STRUCTURE</li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc5.png'}/>
                    </div>
                    Se completeaza detalii:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc6.png'}/>
                    </div>
                    Se apasa pe butonul Selecteaza (marcat mai jos) pentru a selecta pagina pe care se va duce la click pe link-ul “completeaza”:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc7.png'}/>
                    </div>
                    Se apasa pe butonul Salveaza ca Sablon / Publica.

                    <hr/>
                    <b>3. Creare sectiune pentru afisarea structurilor construite mai sus</b>
                    <br/>
                    <br/>

                    Pe pagina pe care vom vrea sa afisam structurile construite mai sus e apasa pe iconita creion:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc8.png'}/>
                    </div>
                    In mod editare pagina, alegem Distribuitor de active si il tragem in zona din pagina pe care o dorim:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc9.png'}/>
                    </div>
                    Configurare Distribuitor de active:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc10.png'}/>
                    </div>

                    <b>3.1. TAB “Selectare Bunuri”</b>
                    <br/>
                    <br/>
                    Selectam tab-ul “Selectare Bunuri”.
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc11.png'}/>
                    </div>
                    Din acest tab, se alege:
                    <ul>
                        <li>
                            Selectare bunuri: <b>Dinamic</b>
                        </li>
                    </ul>

                    Apoi din sectiunea <b>Sursa</b>:
                    <ul>
                        <li>
                            selectam la “Asigneaza Tip” – <b>Continut web</b>
                        </li>
                        <li>
                            selectam la “Conținut web Structuri” – SERVICII_ONLINE_CARD_STRUCTURE
                        </li>
                    </ul>


                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc12.png'}/>
                    </div>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc13.png'}/>
                    </div>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc14.png'}/>
                    </div>

                    <b>3.2. TAB “Setari Afisare”</b>
                    <br/>
                    <br/>
                    Selectam tab-ul “Setari Afisare”:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc15.png'}/>
                    </div>

                    Selectam:
                    <ul>
                        <li>
                            Editeaza Sablon XSL: Servicii Online Page Widget Template
                        </li>
                        <li>
                            Comportamentul legaturii de active: Arata Continutul intreg
                        </li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc16.png'}/>
                    </div>
                    Se apasa Salvare
                    <hr/>
                    Se va afisa:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc17.png'}/>
                    </div>

                    <hr/>
                    <b>Site Initializer</b>
                    <br/>
                    <br/>
                    Vom avea in:
                    <ul>
                        <li>
                            ddm-structures
                            <ul>
                                <li>servicii_online_card-structure.xml</li>
                            </ul>
                        </li>
                        <li>
                            ddm-templates
                            <br/>
                            2 template (pentru card + pagina de carduri):
                            <ul>
                                <li>
                                    servicii-online-card
                                    <ul>
                                        <li>
                                            ddm-template.ftl
                                        </li>
                                        <li>
                                            ddm-template.json
                                        </li>
                                    </ul>
                                </li>
                                <li>servicii-online-page-widget
                                    <ul>
                                        <li>
                                            ddm-template.ftl
                                        </li>
                                        <li>
                                            ddm-template.json
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        Fie <b>servicii_online_card-structure.xml</b>:
                        <br/>
                        Defineste o structura de tipul:
                        <ul>
                            <li>servicii_card_icon: image</li>
                            <li>servicii_card_title: text</li>
                            <li>servicii_card_text: text</li>
                            <li>servicii_card_link1: link_to_layout</li>
                            <li>servicii_card_link1_text: text</li>
                            <li>servicii_card_link2: link_to_layout</li>
                            <li>servicii_card_link2_text: text</li>
                        </ul>
                        <SyntaxHighlighter>
                            {'<?xml version="1.0"?>\n' +
                                '\n' +
                                '<root>\n' +
                                '    <structure>\n' +
                                '        <name>SERVICII_ONLINE_CARD_STRUCTURE</name>\n' +
                                '        <description>\n' +
                                '            Structure for Servicii Online Card\n' +
                                '        </description>\n' +
                                '        <definition>\n' +
                                '            <![CDATA[\n' +
                                '                    {\n' +
                                '                      "definitionSchemaVersion": "2.0",\n' +
                                '                      "availableLanguageIds": [\n' +
                                '                        "en_US",\n' +
                                '                        "ro_RO"\n' +
                                '                      ],\n' +
                                '                      "successPage": {\n' +
                                '                        "body": {},\n' +
                                '                        "title": {},\n' +
                                '                        "enabled": false\n' +
                                '                      },\n' +
                                '                      "defaultLanguageId": "en_US",\n' +
                                '                      "fields": [\n' +
                                '                        {\n' +
                                '                          "dataType": "image",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Header Icon",\n' +
                                '                            "en_US": "Servicii Card Header Icon"\n' +
                                '                          },\n' +
                                '                          "type": "image",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": false,\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "text",\n' +
                                '                          "repeatable": false,\n' +
                                '                          "requiredDescription": true,\n' +
                                '                          "name": "Image78392820",\n' +
                                '                          "localizable": true,\n' +
                                '                          "fieldReference": "servicii_card_icon",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          }\n' +
                                '                        },\n' +
                                '                        {\n' +
                                '                          "labelAtStructureLevel": true,\n' +
                                '                          "ddmDataProviderInstanceId": [],\n' +
                                '                          "tooltip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "requireConfirmation": false,\n' +
                                '                          "type": "text",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": true,\n' +
                                '                          "displayStyle": "singleline",\n' +
                                '                          "ddmDataProviderInstanceOutput": [],\n' +
                                '                          "repeatable": false,\n' +
                                '                          "nativeField": false,\n' +
                                '                          "confirmationLabel": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "fieldReference": "servicii_card_title",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "placeholder": {\n' +
                                '                            "ro_RO": "Emitere Certificat de Urbanism",\n' +
                                '                            "en_US": "Emitere Certificat de Urbanism"\n' +
                                '                          },\n' +
                                '                          "direction": [\n' +
                                '                            "vertical"\n' +
                                '                          ],\n' +
                                '                          "confirmationErrorMessage": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "hideField": false,\n' +
                                '                          "autocomplete": false,\n' +
                                '                          "dataType": "string",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Title",\n' +
                                '                            "en_US": "Servicii Card Title"\n' +
                                '                          },\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "keyword",\n' +
                                '                          "name": "Text30116499",\n' +
                                '                          "localizable": true,\n' +
                                '                          "dataSourceType": ""\n' +
                                '                        },\n' +
                                '                        {\n' +
                                '                          "labelAtStructureLevel": true,\n' +
                                '                          "ddmDataProviderInstanceId": [],\n' +
                                '                          "tooltip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "requireConfirmation": false,\n' +
                                '                          "type": "text",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": true,\n' +
                                '                          "displayStyle": "singleline",\n' +
                                '                          "ddmDataProviderInstanceOutput": [],\n' +
                                '                          "repeatable": false,\n' +
                                '                          "nativeField": false,\n' +
                                '                          "confirmationLabel": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "fieldReference": "servicii_card_text",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "placeholder": {\n' +
                                '                            "ro_RO": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s",\n' +
                                '                            "en_US": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard dummy text ever since the 1500s"\n' +
                                '                          },\n' +
                                '                          "direction": [\n' +
                                '                            "vertical"\n' +
                                '                          ],\n' +
                                '                          "confirmationErrorMessage": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "hideField": false,\n' +
                                '                          "autocomplete": false,\n' +
                                '                          "dataType": "string",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Text",\n' +
                                '                            "en_US": "Servicii Card Text"\n' +
                                '                          },\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "keyword",\n' +
                                '                          "name": "Text75730167",\n' +
                                '                          "localizable": true,\n' +
                                '                          "dataSourceType": ""\n' +
                                '                        },\n' +
                                '                        {\n' +
                                '                          "dataType": "link-to-page",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Link 1 to Page",\n' +
                                '                            "en_US": "Servicii Card Link 1 to Page"\n' +
                                '                          },\n' +
                                '                          "type": "link_to_layout",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": true,\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "keyword",\n' +
                                '                          "repeatable": false,\n' +
                                '                          "name": "LinkToLayout68544865",\n' +
                                '                          "localizable": true,\n' +
                                '                          "fieldReference": "servicii_card_link1",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          }\n' +
                                '                        },\n' +
                                '                        {\n' +
                                '                          "labelAtStructureLevel": true,\n' +
                                '                          "ddmDataProviderInstanceId": [],\n' +
                                '                          "tooltip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "requireConfirmation": false,\n' +
                                '                          "type": "text",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": true,\n' +
                                '                          "displayStyle": "singleline",\n' +
                                '                          "ddmDataProviderInstanceOutput": [],\n' +
                                '                          "repeatable": false,\n' +
                                '                          "nativeField": false,\n' +
                                '                          "confirmationLabel": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "fieldReference": "servicii_card_link1_text",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "Text to be displayed as link",\n' +
                                '                            "en_US": "Text to be displayed as link"\n' +
                                '                          },\n' +
                                '                          "placeholder": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "direction": [\n' +
                                '                            "vertical"\n' +
                                '                          ],\n' +
                                '                          "confirmationErrorMessage": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "hideField": false,\n' +
                                '                          "autocomplete": false,\n' +
                                '                          "dataType": "string",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Link 1 Text",\n' +
                                '                            "en_US": "Servicii Card Link 1 Text"\n' +
                                '                          },\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "keyword",\n' +
                                '                          "name": "Text55456993",\n' +
                                '                          "localizable": true,\n' +
                                '                          "dataSourceType": ""\n' +
                                '                        },\n' +
                                '                        {\n' +
                                '                          "dataType": "link-to-page",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Link 2 to Page",\n' +
                                '                            "en_US": "Servicii Card Link 2 to Page"\n' +
                                '                          },\n' +
                                '                          "type": "link_to_layout",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": false,\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "keyword",\n' +
                                '                          "repeatable": false,\n' +
                                '                          "name": "LinkToLayout79532993",\n' +
                                '                          "localizable": true,\n' +
                                '                          "fieldReference": "servicii_card_link2",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          }\n' +
                                '                        },\n' +
                                '                        {\n' +
                                '                          "labelAtStructureLevel": true,\n' +
                                '                          "ddmDataProviderInstanceId": [],\n' +
                                '                          "tooltip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "requireConfirmation": false,\n' +
                                '                          "type": "text",\n' +
                                '                          "showLabel": true,\n' +
                                '                          "required": false,\n' +
                                '                          "displayStyle": "singleline",\n' +
                                '                          "ddmDataProviderInstanceOutput": [],\n' +
                                '                          "repeatable": false,\n' +
                                '                          "nativeField": false,\n' +
                                '                          "confirmationLabel": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "fieldReference": "servicii_card_link2_text",\n' +
                                '                          "tip": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "placeholder": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "direction": [\n' +
                                '                            "vertical"\n' +
                                '                          ],\n' +
                                '                          "confirmationErrorMessage": {\n' +
                                '                            "ro_RO": "",\n' +
                                '                            "en_US": ""\n' +
                                '                          },\n' +
                                '                          "hideField": false,\n' +
                                '                          "autocomplete": false,\n' +
                                '                          "dataType": "string",\n' +
                                '                          "readOnly": false,\n' +
                                '                          "label": {\n' +
                                '                            "ro_RO": "Servicii Card Link 2 Text",\n' +
                                '                            "en_US": "Servicii Card Link 2 Text"\n' +
                                '                          },\n' +
                                '                          "fieldNamespace": "",\n' +
                                '                          "visibilityExpression": "",\n' +
                                '                          "indexType": "keyword",\n' +
                                '                          "name": "Text81437565",\n' +
                                '                          "localizable": true,\n' +
                                '                          "dataSourceType": ""\n' +
                                '                        }\n' +
                                '                      ]\n' +
                                '                    }\n' +
                                '            ]]>\n' +
                                '        </definition>\n' +
                                '    </structure>\n' +
                                '</root>'}
                        </SyntaxHighlighter>

                        <hr/>

                        Fie template <b>servicii-online-card</b>:
                        <br/>
                        <b>ddm-template.json</b>:
                        <SyntaxHighlighter>
                            {'{\n' +
                                '  "ddmTemplateKey": "SERVICII_ONLINE_CARD",\n' +
                                '  "ddmStructureKey": "SERVICII_ONLINE_CARD_STRUCTURE",\n' +
                                '  "name": "Servicii Online Card Template"\n' +
                                '}'}
                        </SyntaxHighlighter>

                        <b>ddm-template.ftl</b>:
                        <SyntaxHighlighter>
                            {'<div class="card servicii__card">\n' +
                                '    <div class="card-header servicii__card-header">\n' +
                                '        <div class="servicii__card-img-frame">\n' +
                                '            <#if (servicii_card_icon.getData())?? && servicii_card_icon.getData() != "">\n' +
                                '                <img alt="${servicii_card_icon.getAttribute("alt")}"\n' +
                                '                     class="servicii__card-img "\n' +
                                '                     data-fileentryid="${servicii_card_icon.getAttribute("fileEntryId")}"\n' +
                                '                     src="${servicii_card_icon.getData()}" />\n' +
                                '            </#if>\n' +
                                '        </div>\n' +
                                '        <span>\n' +
                                '            <#if (servicii_card_title.getData())??>\n' +
                                '                ${servicii_card_title.getData()}\n' +
                                '            </#if>\n' +
                                '        </span>\n' +
                                '    </div>\n' +
                                '    <div class="card-body servicii__card-body">\n' +
                                '        <p>\n' +
                                '            <#if (servicii_card_text.getData())??>\n' +
                                '                ${servicii_card_text.getData()}\n' +
                                '            </#if>\n' +
                                '        </p>\n' +
                                '    </div>\n' +
                                '    <div class="servicii__card-footer">\n' +
                                '        <hr class="mx-4 my-0 servicii__card-footer-devider">\n' +
                                '        <div class="py-2">\n' +
                                '            <a data-senna-off="true" href="${servicii_card_link1.getFriendlyUrl()}">\n' +
                                '                <#if (servicii_card_link1_text.getData())??>\n' +
                                '                    ${servicii_card_link1_text.getData()}\n' +
                                '                </#if>\n' +
                                '            </a>\n' +
                                '            <#if servicii_card_link2?? && servicii_card_link2.getFriendlyUrl()?? && servicii_card_link2.getFriendlyUrl() != "">\n' +
                                '                |\n' +
                                '                <a data-senna-off="true" href="${servicii_card_link2.getFriendlyUrl()}">\n' +
                                '                    <#if (servicii_card_link2_text.getData())??>\n' +
                                '                        ${servicii_card_link2_text.getData()}\n' +
                                '                    </#if>\n' +
                                '                </a>\n' +
                                '            </#if>\n' +
                                '        </div>\n' +
                                '    </div>\n' +
                                '</div>'}
                        </SyntaxHighlighter>
                        <hr/>
                        Fie template <b>servicii-online-page-widget</b>:
                        <br/>
                        <b>ddm-template.json</b>:
                        <SyntaxHighlighter>
                            {'{\n' +
                                '  "className": "com.liferay.asset.kernel.model.AssetEntry",\n' +
                                '  "ddmTemplateKey": "SERVICII_ONLINE_PAGE_WT",\n' +
                                '  "name": Servicii Online Page Widget Template",\n' +
                                '  "resourceClassName": "com.liferay.portlet.display.template.PortletDisplayTemplate"\n' +
                                '}'}
                        </SyntaxHighlighter>

                        <b>ddm-template.ftl</b>:
                        <SyntaxHighlighter>
                            {'<div class="row">\n' +
                                '    <div class="col-10 offset-1">\n' +
                                '        <div class="row">\n' +
                                '            <div class="col-4 text-left py-4">\n' +
                                '                <svg class="lifecase-page__icon-home" id="ic_home" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">\n' +
                                '                    <path id="Path_372" data-name="Path 372" d="M0,0H24V24H0Z" fill="none"/>\n' +
                                '                    <path id="Path_373" data-name="Path 373" d="M5,12H3l9-9,9,9H19" fill="none" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
                                '                    <path id="Path_374" data-name="Path 374" d="M5,12v7a2,2,0,0,0,2,2H17a2,2,0,0,0,2-2V12" fill="none" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
                                '                    <path id="Path_375" data-name="Path 375" d="M9,21V15a2,2,0,0,1,2-2h2a2,2,0,0,1,2,2v6" fill="none" stroke="#92929d" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>\n' +
                                '                </svg>\n' +
                                '                <strong>></strong>\n' +
                                '                <strong>Servicii online</strong>\n' +
                                '            </div>\n' +
                                '        </div>\n' +
                                '        <#if entries?has_content>\n' +
                                '            <div class="row servicii__cards-container">\n' +
                                '                <#list entries as curEntry>\n' +
                                '                    <div class="servicii__card-entries col-sm-6 col-md-4 col-lg-3 m-0 p-2" >\n' +
                                '                        <#assign\n' +
                                '                        assetRenderer = curEntry.getAssetRenderer()\n' +
                                '                        journalArticle = assetRenderer.getAssetObject()\n' +
                                '                        />\n' +
                                '                        <@liferay_journal["journal-article"]\n' +
                                '                        articleId=journalArticle.getArticleId()\n' +
                                '                        ddmTemplateKey=journalArticle.getDDMTemplateKey()\n' +
                                '                        groupId=journalArticle.getGroupId()\n' +
                                '                        />\n' +
                                '                    </div>\n' +
                                '                </#list>\n' +
                                '            </div>\n' +
                                '        </#if>\n' +
                                '    </div>\n' +
                                '</div>'}
                        </SyntaxHighlighter>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>*/}
                    {/*            Introducing Site Initializers*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"}href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>*/}
                    {/*            Site Initializers: Update Support*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/embedding-portlets-and-setting-preferences-in-a-liferay-7-2-theme/maximized"}>*/}
                    {/*            Embedding portlets and setting preferences in a Liferay 7.2 theme*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers13LiferayContent;