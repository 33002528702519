import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class EchipamentVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-echipament", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Echipamente:

                    <hr/>
                    <b>Transmitator Wireless pentru blitz-uri</b>
                    <br/>
                    <i>Exemplu:</i> Godox TTL XPRO-N Transmitator Wireless pentru Nikon
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-19.png'}/>
                    </div>

                    <hr/>
                    <b>Blitz tip patina</b>
                    <br/>
                    <i>Exemplu:</i> Godox Ving V860IIN pentru Nikon
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-20.png'}/>
                    </div>

                    <hr/>
                    <b>Difuzor pentru blitz</b>
                    <br/>
                    <i>Exemplu:</i> White-Bounce-Diffuser-Dome-pentru-Canon-580EX-580EX-II-Godox-V860-II
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-25.png'}/>
                    </div>

                    <hr/>
                    <b>Lampa LED circulara RGB</b>
                    <br/>
                    <i>Exemplu:</i> Hakutatz VL-480RGBW Lampa LED Circulara RGB
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-26.png'}/>
                    </div>

                    <hr/>
                    <b>Blit de studio</b>
                    <br/>
                    <i>Exemplu:</i> Godox AD400 Pro TTL Witstro
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-34.png'}/>
                    </div>

                    <hr/>
                    <b>Snoot</b>
                    <br/>
                    <i>Exemplu: </i> Godox Flash Snoot SN-04 pentru AD400pro
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-89.png'}/>
                    </div>

                    <hr/>
                    <b>Filte Gel</b>
                    <br/>
                    <i>Exemplu: </i> Godox SA-11C Set Filtre Gel
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-90.png'}/>
                    </div>

                    <hr/>

                    <b>Lampa LED cu lentila de focalizare</b> este o lampa care foloseste tehnologia LED, fiind cu lumina continua si avand capacitatea de controla lumina

                    <br/>

                    <i>Exemplu</i>: Godox S60
                    <div>
                        <div style={{padding:10}}>
                            <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/videography/videografie-4.png'}/>
                        </div>
                    </div>

                    <hr/>
                    <b>Barndors / Voleti</b> este un sistem de directionare a luminii care se prinde pe un reflextor.

                    <br/>

                    <i>Exemplu</i>: Godox-SA-08
                    <div className="row justify-content-md-center">
                        <div style={{padding:10}}>
                            <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/videography/videografie-2.png'}/>
                        </div>

                        <div style={{padding:10}}>
                            <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/videography/videografie-3.png'}/>
                        </div>

                    </div>

                    <hr/>
                    <b>Atasament de proiectie pentru lampa/reflector</b> folosit pentru a controla fasciculul de lumină proiectat cu obiective detasabile
                    <br/>
                    <i>Exemplu</i>: Godox SA-P1 Projection Attachment

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-5.png'}/>
                    </div>

                    <hr/>
                    <b>Obiectiv/Lentila pentru atasamentul de proiectie</b>
                    <br/>
                    <i>Exemplu</i>: Godox SA-01 85mm

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-6.png'}/>
                    </div>

                    <hr/>
                    <b>Sistem de prindere pentru filtre Gobo</b>
                    <br/>
                    <i>Exemplu</i>: Godox SA-10
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-7.png'}/>
                    </div>

                    <hr/>
                    <b>Gobo</b> este un obiect plasat în interiorul sau în fața unei surse de lumină pentru a controla forma luminii emise și umbra acesteia.
                    <br/>

                    <i>Exemplu 1</i>: Godox SA-09-001 Kit
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-86.png'}/>
                    </div>

                    <i>Exemplu 2</i>: Godox SA-09-002 Kit
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-8.png'}/>
                    </div>

                    <i>Exemplu 3</i>: Godox SA-09-003 Kit
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-9.png'}/>
                    </div>

                    <hr/>
                    <b>Modificatori de intensitate a luminii pentru Projection Attachment</b>
                    <br/>
                    <i>Exemplu: </i> Godox-SA-05-Scrim
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-39.png'}/>
                    </div>

                    <hr/>
                    <b>Modificatori de obturare a luminii pentru Projection Attachment</b>
                    <br/>
                    <i>Exemplu: </i>Godox SA-07 Framing Shutter
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-40.png'}/>
                    </div>


                    <hr/>
                    <b>Lavaliera</b>
                    <br/>
                    <i>Exemplu: </i> Rode Lavalier GO Microfon Lavaliera
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-10.png'}/>
                    </div>

                    <hr/>
                    <b>Sistem de microfon lavaliera (cu transmitatoare si receiver)</b>
                    <br/>
                    <i>Exemplu</i>: Rode Wireless GO II Sistem Microfon Wireless Dual Digital Kit cu 2 Transmitatoare
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-11.png'}/>
                    </div>

                    <hr/>
                    <b>Microfon pentru camera cu Patina</b>
                    <br/>
                    <i>Exemplu:</i> Audio-Technica AT8024 Microfon Stereo cu Jack 3.5mm
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-13.png'}/>
                    </div>

                    <hr/>
                    <b>Sistem de Stabilizare pentru Smartphone</b>
                    <br/>
                    <i>Exemplu:</i> DJI-OM4
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-12.png'}/>
                    </div>

                    <hr/>
                    <b>Stabilizator Gimbal</b>
                    <br/>
                    <i>Exemplu:</i> DJI RS 2
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-14.png'}/>
                    </div>

                    <hr/>
                    <b>Sistem calibrare monitoare</b>
                    <br/>
                    <i>Exemplu:</i> X-Rite i1 Display Pro Plus
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-15.png'}/>
                    </div>

                    <hr/>
                    <b>Clema</b>
                    <br/>
                    <i>Exemplu 1:</i> Gorila Grip Clips A281
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-16.png'}/>
                    </div>

                    <i>Exemplu 2:</i> Clema fundal studio foto A281 110x80x80mm H6807
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-55.png'}/>
                    </div>

                    <hr/>
                    <b>Suport pt sustinere blenda / fundal</b>
                    <br/>
                    <i>Exemplu:</i> Suport pt sustinere blenda / fundal
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-54.png'}/>
                    </div>

                    <hr/>
                    <b>Clema fundal studio foto Falcon Eyes CL-Clip cu spigot pentru accesorii</b>
                    <br/>
                    <i>Exemplu:</i> Clema fundal studio foto Falcon Eyes CL-Clip cu spigot pentru accesorii
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-56.png'}/>
                    </div>

                    <hr/>
                    <b>Clema fundal studio foto Weifeng A-283 cu cap tip bila pentru accesorii</b>
                    <br/>
                    <i>Exemplu:</i> Clema fundal studio foto Weifeng A-283 cu cap tip bila pentru accesorii
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-57.png'}/>
                    </div>

                    <hr/>
                    <b>Sistem prindere fundal</b>
                    <br/>
                    <i>Exemplu 1:</i> Fancier FT901
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-17.png'}/>
                    </div>

                    <i>Exemplu 2:</i> Kit pentru suport fundal studio 190cm (noname) cu bara fundal telescopica 3m pentru stative cu spigot standard
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-53.png'}/>
                    </div>

                    <hr/>
                    <b>Sistem prindere T-stand</b>
                    <br/>
                    <i>Exemplu 1:</i> Kit pentru suport fundal studio T-stand cu stativ 210cm si bara 150cm
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-85.png'}/>
                    </div>

                    <i>Exemplu 2:</i> Kit pentru suport fundal studio T-stand cu stativ 210cm si bara 100cm
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-87.png'}/>
                    </div>

                    <hr/>
                    <b>Obiective</b>
                    <br/>
                    <i>Exemplu 1:</i> Samyang 14mm Obiectiv Foto Mirrorless F2.8 MF Montura Nikon Z
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-18.png'}/>
                    </div>

                    <i>Exemplu 2:</i> Lensbaby Obscura 16
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-58.png'}/>
                    </div>

                    <i>Exemplu 3:</i> Lensbaby Velvet 85
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-64.png'}/>
                    </div>

                    <i>Exemplu 4:</i> Lensbaby Composer Pro II + Sweet 80
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-67.png'}/>
                    </div>

                    <hr/>
                    <b>Optica Obiective</b>
                    <br/>
                    <i>Exemplu 1:</i> Lensbaby Obscura Optic
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-59.png'}/>
                    </div>

                    <i>Exemplu 2:</i> Lensbaby Twist 60 Optic
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-63.png'}/>
                    </div>

                    <hr/>
                    <b>Corp Obiective</b>
                    <br/>
                    <i>Exemplu 1:</i> Lensbaby Spark 2.0
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-60.png'}/>
                    </div>

                    <hr/>
                    <b>Husa Obiective</b>
                    <br/>
                    <i>Exemplu 1:</i> Lensbaby Custom Lens Case + Tall
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-61.png'}/>
                    </div>

                    <hr/>
                    <b>Protectie corp obiective</b>
                    <br/>
                    <i>Exemplu 1:</i> Lensbaby Optic Swap Tool / Container
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-62.png'}/>
                    </div>

                    <hr/>
                    <b>Accesori obiectiv</b>
                    <br/>
                    <i>Exemplu 1:</i> Lensbaby OMNI Creative Filter System
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-65.png'}/>
                    </div>

                    <hr/>
                    <b>Husa obiective</b>
                    <br/>
                    <i>Exemplu 1:</i> Lensbaby Optic Swap Collection Case - Large
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-66.png'}/>
                    </div>


                    <hr/>
                    <b>Cap trepied cu bila</b>
                    <br/>
                    <i>Exemplu:</i>  Manfrotto 494 Cap de Trepied cu Bila
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-21.png'}/>
                    </div>

                    <hr/>
                    <b>Stativ pentru lumini si blitz-uri</b>
                    <br/>
                    <i>Exemplu 1:</i> Fancier W806 Stativ pentru Lumini si Blituri 2.6m
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-24.png'}/>
                    </div>

                    <i>Exemplu 2:</i> Tolifo Stativ lumini 200cm
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-33.png'}/>
                    </div>

                    <hr/>
                    <b>Aparat foto</b>
                    <br/>

                    <i>Exemplu 1:</i>  Nikon D3400
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-29.png'}/>
                    </div>

                    <i>Exemplu 2 :</i>  Nikon D5600
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-23.png'}/>
                    </div>

                    <i>Exemplu 3:</i>  Nikon D810
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-30.png'}/>
                    </div>

                    <i>Exemplu 4:</i>  Nikon Z6
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-28.png'}/>
                    </div>

                    <hr/>
                    <b>Capac protectie blit - montura Bowens</b>
                    <br/>
                    <i>Exemplu:</i>   Capac protectie blit - montura Bowens
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-27.png'}/>
                    </div>

                    <hr/>
                    <b>Monitor</b>
                    <br/>
                    <i>Exemplu:</i> Benq SW271
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-31.png'}/>
                    </div>

                    <hr/>
                    <b>Cititor carduri</b>
                    <br/>
                    <i>Exemplu:</i> Sandisk Cititor de Carduri SD USB 3.0 170MB/s
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-32.png'}/>
                    </div>

                    <hr/>
                    <b>Octobox</b>
                    <br/>
                    <i>Exemplu:</i> Octobox 95cm
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-35.png'}/>
                    </div>

                    <hr/>
                    <b>Monitor si recorder de camera</b>
                    <br/>
                    <i>Exemplu:</i> Atomos Ninja V 5" monitor recorder 4K HDR 10bit HDMI
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-37.png'}/>
                    </div>

                    <hr/>
                    <b>Cablu HDMI Micro-Mare 40cm Spiralat 61cm Extins</b>
                    <br/>
                    <i>Exemplu: </i>Atomos Cablu HDMI Micro-Mare 40cm Spiralat 61cm Extins
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-41.png'}/>
                    </div>

                    <hr/>
                    <b>2 X Baterii NP-F750 5200mAh</b>
                    <br/>
                    <i>Exemplu: </i>Atomos AtomX Power Kit 2
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-52.png'}/>
                    </div>

                    <hr/>
                    <b>Monitor Mount</b>
                    <br/>
                    <i>Exemplu: </i>Atomos AtomX 5'' / 7'' Monitor Mount
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-68.png'}/>
                    </div>

                    <hr/>
                    <b>ADAPTOR USB LA SATA 22 PINI PENTRU HDD/SSD 2.5" </b>
                    <br/>
                    <i>Exemplu 1: </i>Atomos <b>USB-C 3.1</b> Stație de andocare
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-69.png'}/>
                    </div>

                    <i>Exemplu 2: </i>ADAPTOR <b>USB 3.0</b> LA SATA 22 PINI PENTRU HDD/SSD 2.5" GOFLEX, GEMBIRD AUS3-02
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-70.png'}/>
                    </div>

                    <hr/>
                    <b>ADAPTOR SATA 22 PINI LA SLIM SATA 13 PINI T-M</b>
                    <br/>
                    <i>Exemplu 1: </i>ADAPTOR SATA 22 PINI LA SLIM SATA 13 PINI T-M, DELOCK
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-79.png'}/>
                    </div>

                    <hr/>
                    <b>ADAPTOR USB 3.0-A LA USB TYPE C T-M</b>
                    <br/>
                    <i>Exemplu 1: </i>ADAPTOR USB 3.0-A LA USB-C T-M
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-80.png'}/>
                    </div>

                    <i>Exemplu 2: </i>ADAPTOR USB 3.1-A LA USB-C T-M (20cm)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-82.png'}/>
                    </div>

                    <hr/>
                    <b>ADAPTOR USB 3.1-A LA USB TYPE C M-T</b>
                    <br/>
                    <i>Exemplu 1: </i>ADAPTOR USB 3.1-A LA USB TYPE C M-T
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-81.png'}/>
                    </div>


                    <hr/>
                    <b>Fundal Croma-green</b>
                    <br/>
                    <i>Exemplu: </i>Godox Fundal Croma-green 2 x 3m
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-71.png'}/>
                    </div>

                    <hr/>
                    <b>Adaptor stativ 1/4 la 3/8</b>
                    <br/>
                    <i>Exemplu: </i>Manfrotto Adaptor stativ 1/4 la 3/8
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-72.png'}/>
                    </div>

                    <hr/>
                    <b>Surub adaptor 3/8 la 3/8</b>
                    <br/>
                    <i>Exemplu: </i>Manfrotto Surub adaptor 3/8 la 3/8
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-73.png'}/>
                    </div>

                    <hr/>
                    <b>Contragreutate fundal</b>
                    <br/>
                    <i>Exemplu: </i>Manfrotto 062-3 contragreutate fundal
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-74.png'}/>
                    </div>

                    <hr/>
                    <b>Fundal</b>
                    <br/>
                    <i>Exemplu 1: </i>Fundal foto Brown Dark (110 x 90 cm)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-75.png'}/>
                    </div>

                    <i>Exemplu 2: </i>Fundal foto Wall Texture 5 (110 x 90 cm)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-76.png'}/>
                    </div>

                    <i>Exemplu 3: </i>Fundal foto Grunge 1 (110 x 90 cm)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-77.png'}/>
                    </div>

                    <i>Exemplu 4: </i>Fundal foto Wall Texture 5 (200 x 160 cm)
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-78.png'}/>
                    </div>


                    <hr/>
                    <b>Minitrepied pentru smartphone</b>
                    <br/>
                    <i>Exemplu:</i> Joby GorillaPod Mobile Mini Minitrepied flexibil blue
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-36.png'}/>
                    </div>

                    <hr/>
                    <b>Panou pliabil pentru reglarea balansului de alb.</b>
                    <br/>
                    <i>Exemplu:</i> Lastolite Balans de alb/gri 18% 30cm
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-38.png'}/>
                    </div>

                    <hr/>
                    <b>Slider motorizat</b>
                    <br/>
                    <i>Exemplu: </i>GVM GR-80QD Slider Profesionist Motorizat din Fibra de Carbon
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-42.png'}/>
                    </div>


                    <hr/>
                    <b>Quick Release</b>
                    <br/>
                    <i>Exemplu 1: </i>SmallRig Quick Release Clamp & Plate Arca-Type
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-83.png'}/>
                    </div>
                    <i>Exemplu 2: </i>Placuta+Baza quick-release Ulanzi Falcam F38
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-84.png'}/>
                    </div>


                    <hr/>
                    <b>Trepied</b>
                    <br/>
                    <i>Exemplu: </i>Manfrotto MVK502AM
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-43.png'}/>
                    </div>

                    <hr/>
                    <b>Cap fluid video ball</b>
                    <br/>
                    <i>Exemplu: </i>Manfrotto MVH502A
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-44.png'}/>
                    </div>

                    Ergonomie scazuta:
                    <ul>
                        <li>bula nivel (spirit level) este pozitionata sub placa de sus (top-plate: R1021,04) pe care se pune talpa (504plong plate), iar vizibilitatea este redusa cand aceasta este in pozitie orizontala; deci trebuie adusa intr-o pozitie cat mai verticala

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/videography/videografie-45.jpg'}/>
                            </div>
                        </li>
                        <li>
                            buton de frână si blocare pan (Pan Brake Knob, R1021,30) pentru controlul miscari pe orizonatala - incommod pozitionat sub placa de sus
                        </li>
                        <li>
                            butonul de blocare a plăcii (Plate Locking Knob, R1021,27) ingruneaza plasarea unui Mirorless / DSLR pe talpa (aparatul trebuie pus astfel incat sa nu loveasca aparatul)
                        </li>
                    </ul>
                    Parti bune:
                    <ul>
                        <li>
                            control blocare/deblocare tild (miscare pe verticala) e pozitionat ok
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                               <a href={"https://www.youtube.com/watch?v=83GBhIwUgHk&ab_channel=ArtVideoProductions"}>Godox S60 LED Focusing Light | Everything You Want To Know</a><br/>
                           </div>
                       </li>


                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default EchipamentVideografieContent;