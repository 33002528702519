import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaUpgradeStepsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-upgrade-steps", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Upgrade steps</b>
                    <br/>
                    <br/>

                    Cu bifa pusa de <b>Developer Mode</b>, modificarile din <b>service.xml</b> se vor reflacta si in baza de date.
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/u1.png'}/>
                    </div>
                    In plus, in fisierul <b>portal-developer.properties</b>, trebuie sa existe si:
                    <SyntaxHighlighter>
                        {'schema.module.build.auto.upgrade=true'}
                    </SyntaxHighlighter>

                    Doar ca acest mod, nu este recomandat pentru mediul de productie.
                    <hr/>
                    Exista:
                    <ul>
                        <li>
                            Service Builder Upgrade Steps (exemplu: adaugare coloana)
                        </li>
                        <li>
                            Application Upgrade Steps (similar cu Service Builder Upgrade Steps, doar intentia difera - aici se poate face de exemplu un import de carti dintr-un
                            fisier json)
                        </li>
                    </ul>

                    <hr/>
                    <b>2. Service Builder Upgrade Steps</b>
                    <br/>
                    <br/>


                    In modulul <b>agnes-training\agnes-service-builder\agnes-service-builder-service</b> se creaza pachetul
                    <b>ro.agnes.service.builder.upgrade</b>

                    <br/>
                    <br/>

                    Apoi implmentam un <b>UpgradeStepRegistrator</b>
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.service.builder.upgrade;\n' +
                            '\n' +
                            'import com.liferay.portal.upgrade.registry.UpgradeStepRegistrator;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        service = UpgradeStepRegistrator.class\n' +
                            ')\n' +
                            'public class UpgradeProcessRegistry implements UpgradeStepRegistrator {\n' +
                            '    \n' +
                            '    @Override\n' +
                            '    public void register(Registry registry) {\n' +
                            '\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Aici se inregistreaza obiecte de tip<i>UpgradeProcess</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.service.builder.upgrade;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.upgrade.DummyUpgradeProcess;\n' +
                            'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import com.liferay.portal.upgrade.registry.UpgradeStepRegistrator;\n' +
                            '\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        service = UpgradeStepRegistrator.class\n' +
                            ')\n' +
                            'public class UpgradeProcessRegistry implements UpgradeStepRegistrator {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void register(Registry registry) {\n' +
                            '\n' +
                            '        registry.register("1.0.0", "1.0.1", new UpgradeProcess() {\n' +
                            '            @Override\n' +
                            '            protected void doUpgrade() throws Exception {\n' +
                            '                \n' +
                            '            }\n' +
                            '        });\n' +
                            '\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2.1. Adaugare coloana noua: alterTableAddColumn</b>
                    <br/>
                    <br/>

                    Exercitiu. Adaugam in service.xml, pentru tabela Book:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<column name="isbn" type="String" />'}
                    </SyntaxHighlighter>
                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.service.builder.upgrade.v1_0_1;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.upgrade.UpgradeProcess;\n' +
                            'import ro.agnes.service.builder.model.BookTable;\n' +
                            '\n' +
                            'public class IsbnUpgradeProcess extends UpgradeProcess {\n' +
                            '    @Override\n' +
                            '    protected void doUpgrade() throws Exception {\n' +
                            '        alterTableAddColumn(\n' +
                            '                BookTable.INSTANCE.getTableName(),\n' +
                            '                BookTable.INSTANCE.isbn.getName(),\n' +
                            '                "VARCHAR(20)");\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Apoi, in UpgradeProcessRegistry, se adauga:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'registry.register("1.0.1", "1.0.2", new IsbnUpgradeProcess());'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'package ro.agnes.service.builder.upgrade;\n' +
                            '\n' +
                            'import com.liferay.portal.kernel.upgrade.DummyUpgradeProcess;\n' +
                            'import com.liferay.portal.upgrade.registry.UpgradeStepRegistrator;\n' +
                            'import org.osgi.service.component.annotations.Component;\n' +
                            'import ro.agnes.service.builder.upgrade.v1_0_1.IsbnUpgradeProcess;\n' +
                            '\n' +
                            '@Component(\n' +
                            '        immediate = true,\n' +
                            '        service = UpgradeStepRegistrator.class\n' +
                            ')\n' +
                            'public class UpgradeProcessRegistry implements UpgradeStepRegistrator {\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public void register(Registry registry) {\n' +
                            '\n' +
                            '        registry.register("1.0.0", "1.0.1", new IsbnUpgradeProcess());\n' +
                            '\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Trebuie actualizata si linia urmatoarea din <b>bnd.bnd</b>:
                    <SyntaxHighlighter>
                        {
                            'Liferay-Require-SchemaVersion: 1.0.1'}
                    </SyntaxHighlighter>
                    Apoi, se face build.

                    <hr/>
                    <b>3. Application Upgrade Steps</b>
                    <br/>
                    <br/>
                    Se creeaza un nou modul <b>agnes-upgrade-step</b>.
                    <br/>
                    In rest e similar ca mai sus.
                    <br/>
                    O diferenta este data de faptul ca primul pas este:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'registry.register("0.0.0", "1.0.0", new DummyUpgradeProcess());'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>Observatie 1</b>
                    <ul>
                        <li>
                            Detalii legate de pasii de upgrade se gasesc in tabela <b>release_</b>

                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'SELECT * FROM public.release_\n' +
                                    'where servletcontextname like \'%agnes%\'\n' +
                                    'ORDER BY releaseid ASC '}
                            </SyntaxHighlighter>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/u12.png'}/>
                            </div>
                        </li>
                    </ul>

                    <hr/>
                    <b>Observatie 2</b>
                    <ul>
                        <li>
                            Daca se face o greseala intr-un UpgradeProcess, atunci se poate:
                            <ul>
                                <li>
                                    modifica manual <b>schemaversion</b> in tabela <b>release_</b>
                                </li>
                                <li>
                                    clear cache database din modul de administrare Liferay
                                </li>
                                <li>
                                    re-deploy
                                </li>
                            </ul>
                        </li>
                    </ul>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaUpgradeStepsLiferayContent;