import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateUserKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-user", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                            <b>Creare utilizator nou</b>
                            <br/>
                            Inițial, nu există utilizatori într-un realm nou, așa că haideți să creăm unul:
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se face click pe <b>Users</b> (meniul din stanga):
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-9.png'}/>
                                    </div>
                                </li>
                                <li>se face click pe butonul <b>Add user</b> (colțul din dreapta sus al tabelului) </li>
                                <li>se completeaza formularul:
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-10.png'}/>
                                    </div>
                                    Se apasa butonul <b>Save</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-11.png'}/>
                                    </div>
                                </li>

                                <li>
                                    Utilizatorul va avea nevoie de o parolă inițială setată pentru a se putea autentifica. Pentru a face acest lucru:
                                    <ul>
                                        <li>se apasa click pe <b>Credentials</b> (în partea de sus a paginii)</li>
                                        <li>se completeaza formulatul <b>Set Password</b> cu o parola
                                            <div style={{padding:10}}>
                                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-12.png'}/>
                                            </div>
                                        </li>
                                        <li>se seteaza pe <b>OFF</b> <b>Temporary</b> pentru a preveni actualizarea parolei la prima conectare</li>
                                        <li>se apasa <b>Set password</b>:
                                            <div style={{padding:10}}>
                                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                                     src={process.env.PUBLIC_URL + '/img/liferay/keycloak-13.png'}/>
                                            </div>
                                        </li>

                                    </ul>

                                </li>
                            </ul>

                    <hr/>

                    <b>Verificare: </b> Logare cu utilizatorul creat mai sus:
                    <br/>
                    Logare:
                    <a href={"http://localhost:8180/auth/realms/vanilla-realm/account/#/"}>
                        <b>http://localhost:8180/auth/realms/vanilla-realm/account/#/</b>
                    </a>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-14.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateUserKeycloakContent;