import React from "react";
import IndexContentHelper from "./IndexContentHelper";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft} from "@fortawesome/free-solid-svg-icons/faArrowLeft";
import {faArrowRight} from "@fortawesome/free-solid-svg-icons/faArrowRight";

const NavigatorFragment = ({pageId, toc}) => {

    let prevPage = IndexContentHelper.prev(pageId, toc);

    let nextPage = IndexContentHelper.next(pageId, toc);

    let chapterPrev = IndexContentHelper.chapterByItem(prevPage, toc);
    let chapterNext = IndexContentHelper.chapterByItem(nextPage, toc);

    let indexUrl = IndexContentHelper.defaultUrl(toc);

    return (
        <div className="navivator">
            <div className={"row"}>
                <div className={"col-sm text-left"}>
                    {prevPage ?
                        <Link to={prevPage.url}><FontAwesomeIcon icon={faArrowLeft}/> {chapterPrev}. {prevPage.title}</Link>
                        : <span/>
                    }
                </div>
                <div className={"col-sm"}>
                    <Link to={indexUrl}>[ cuprins ]</Link>
                </div>
                <div className={"col-sm text-right"}>
                    {nextPage ?
                        <Link to={nextPage.url}>{chapterNext}. {nextPage.title} <FontAwesomeIcon icon={faArrowRight}/></Link>
                        : <span/>
                    }
                </div>
            </div>
        </div>
    )
}

export default NavigatorFragment;