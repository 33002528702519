import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../grafana/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class VariablesGrafanaContent extends BaseContentPage  {

    constructor(props) {
        super(props, "grafana-variables", IndexContent);
    }


    render() {

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana - Variabile</b>
                    <br/>
                    <br/>

                    <b>1. Creare Dashboard</b>
                    <br/>
                    <br/>

                    Dintr-un dashboard, in starea de vizualizare:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_12.png'}/>
                    </div>

                    Apoi:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_13.png'}/>
                    </div>

                    Se adauga o noua variabila; se apasa pe butonul <b>Add variable</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_14.png'}/>
                    </div>

                    Se defineste variabila (variabila de tip <b>Query</b>):
                    <ul>
                        <li>Select variable type: Query</li>
                        <li>Name: TIP_CERERE</li>
                        <li>Label: Tip cerere</li>
                        <li>Query: <b>select formid __value, name __text from ibrid_form</b></li>
                    </ul>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_15.png'}/>
                    </div>

                    Se selecteaza:
                    <ul>
                        <li><b>Multi-value</b></li>
                        <li>
                            <b>Include All options</b>
                        </li>
                    </ul>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_16.png'}/>
                    </div>

                    Se apasa pe butonul <b>Apply</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_17.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_18.png'}/>
                    </div>

                    Apoi, in dashboard va apare o sectiune de unde se pot selecta parametrii ce pot fi folositi in afisarea graficelor, pe baza variabilelor definite:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_19.png'}/>
                    </div>

                    <hr/>
                    <b>2. Tipuri de variabile</b>
                    <br/>
                    <br/>

                    Tipuri de variabile:
                    <ul>
                        <li>
                            <b>Query</b>: pentru a obtine valorile cu ajutorul unui query (date dintr-un nomenclator)
                            <br/>
                            de exemplu:
                            <SyntaxHighlighter>
                                {'select formid __value, name __text from ibrid_form'}
                            </SyntaxHighlighter>
                            Se pot folosi aliasurile predefinite:
                            <ul>
                                <li><b>__value</b></li>
                                <li><b>__text</b></li>
                            </ul>
                        </li>
                        <li>
                            <b>Custom</b>: se pot defini constante (date care nu sunt neaparat pastrate intr-un nomenclator;
                                sau reprezinta doar o selectie particulara)
                            <br/>
                            de poate defini:
                            <SyntaxHighlighter>
                                {'valoare1, valoare2'}
                            </SyntaxHighlighter>
                            sau
                            <SyntaxHighlighter>
                                {'text1 : valoare1, text2 : valoare2'}
                            </SyntaxHighlighter>
                            Observatie:
                            <ul>
                                <li>
                                    intre <i>text</i> si <i>valoare</i> trebuie sa fie: SPATIU, DOUA PUNCTE, SPATIU!
                                </li>
                            </ul>
                            <br/>
                            de exemplu:
                            <SyntaxHighlighter>
                                {'Portal : PORTAL, Info Chiosc : INFO_CHISOC '}
                            </SyntaxHighlighter>
                            alt exemplu:
                            <SyntaxHighlighter>
                                {'alb, rosu, verde'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <b>Text box</b>
                        </li>
                        <li>
                            <b>Constant</b>
                        </li>
                        <li>
                            <b>Interval</b>
                        </li>
                        <li>
                            <b>Data sources4</b>
                        </li>
                        <li>
                            <b>Ad hoc filters</b> (PostgreSQL nu suporta acest tip de variabila)
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Interpolare</b>
                    <br/>
                    <br/>

                    Daca avem o variabila definita cu numele VARIABILA, aceasta se poate folosi in query-riuri: <b>$VARIABILA</b> (acest lucru se numeste <b>interpolare</b>)
                    <br/>
                    De exemmplu: daca avem variabila TIP_CERERE, atunci o putem referi in query-riuri cu: $TIP_CERERE.

                    <br/>
                    Interpolarea se poate folosi, de exemplu si la numele unui Panel:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_22.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_23.png'}/>
                    </div>


                    <hr/>
                    <b>4. Variabile predefinite</b>
                    <br/>
                    <br/>

                    Variabile predefinite:
                    <ul>
                        <li><b>$timeFilter</b>: folosit pentru a selexta doar rezultatele dintr-o anumita perioada de timp</li>
                    </ul>

                    Intervale predefinite:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_21.png'}/>
                    </div>

                    <hr/>
                    <b>5. Rata de actualizare</b>
                    <br/>
                    <br/>

                    Rata de actualizare:
                    <ul>
                        <li>Off</li>
                        <li>10s</li>
                        <li>30s</li>
                        <li>1m</li>
                        <li>5m</li>
                        <li>15m</li>
                        <li>30m</li>
                        <li>1h</li>
                        <li>2h</li>
                        <li>1d</li>
                    </ul>

                    Rata de actualizare se poate personalizam din <b>Settings</b> ~ <b>Auto refresh</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_20.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://sbcode.net/grafana/dashboard-variables/"}>Dashboard Variables</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default VariablesGrafanaContent;