import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MemorySpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-memory-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '@Bean\n' +
                            'public ChatMemory chatMemory(){\n' +
                            '\treturn new InMemoryChatMemory()\n' +
                            '}'
                        }
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MemorySpringIALlmContent;