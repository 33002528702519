import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class TextToSpeechSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-tts-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <SyntaxHighlighter>
                        {'    @Autowired\n' +
                            '    private OpenAiAudioSpeechModel openAiAudioSpeechModel;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            '@Override\n' +
                            '    public void textToSpeech(String text) {\n' +
                            '        byte[] content = openAiAudioSpeechModel.call(text);\n' +
                            '        save(content);\n' +
                            '    }'
                        }
                    </SyntaxHighlighter>

                    sau cu optiuni:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'  @Override\n' +
                            '    public void textToSpeech(String text) {\n' +
                            '\n' +
                            '        OpenAiAudioSpeechOptions options = OpenAiAudioSpeechOptions.builder()\n' +
                            '                .withVoice(OpenAiAudioApi.SpeechRequest.Voice.ALLOY)\n' +
                            '                .withSpeed(1.0f)\n' +
                            '                .withResponseFormat(OpenAiAudioApi.SpeechRequest.AudioResponseFormat.MP3)\n' +
                            '                .withModel(OpenAiAudioApi.TtsModel.TTS_1.value)\n' +
                            '                .build();\n' +
                            '\n' +
                            '        SpeechPrompt speechPrompt = new SpeechPrompt(text, options);\n' +
                            '\n' +
                            '        SpeechResponse speechResponse = openAiAudioSpeechModel.call(speechPrompt);\n' +
                            '\n' +
                            '        byte [] content = speechResponse.getResult().getOutput();\n' +
                            '        \n' +
                            '        save(content);\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    unde:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' private void save(byte[] content) {\n' +
                            '\n' +
                            '        String filePath = "D:\\\\work\\\\spring-ia\\\\src\\\\main\\\\resources\\\\text.mp3";\n' +
                            '\n' +
                            '        try (FileOutputStream fos = new FileOutputStream(filePath)) {\n' +
                            '            fos.write(content);\n' +
                            '            System.out.println("Datele au fost salvate în " + filePath);\n' +
                            '        } catch (IOException e) {\n' +
                            '            System.err.println("Eroare la salvarea fișierului: " + e.getMessage());\n' +
                            '        }\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Optiunile se pot adauga si in <i>application.properties</i>:
                    <SyntaxHighlighter>
                        {'spring.ai.openai.audio.speech.options.speed=1.0\n' +
                            'spring.ai.openai.audio.speech.options.model=tts\n' +
                            'spring.ai.openai.audio.speech.options.voice=alloy\n' +
                            'spring.ai.openai.audio.speech.options.response-format=mp3'}
                    </SyntaxHighlighter>

                    <hr/>
                    Folosind flux-uri (textul e spart in chunk-uri):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    public Flux<byte[]> textToSpeech3() throws IOException {\n' +
                            '\n' +
                            '        String content = new String(Files.readAllBytes(Paths.get("tts")));\n' +
                            '\n' +
                            '        // instantiere manuala\n' +
                            '        var openAIAudioApi = new OpenAiAudioApi("API_KEY");\n' +
                            '        var openAiAudioSpeechModel = new OpenAiAudioSpeechModel(openAIAudioApi);\n' +
                            '        \n' +
                            '        OpenAiAudioSpeechOptions options = OpenAiAudioSpeechOptions.builder()\n' +
                            '                .voice(OpenAiAudioApi.SpeechRequest.Voice.ALLOY)\n' +
                            '                .speed(1.0f)\n' +
                            '                .responseFormat(OpenAiAudioApi.SpeechRequest.AudioResponseFormat.MP3)\n' +
                            '                .model(OpenAiAudioApi.TtsModel.TTS_1.value)\n' +
                            '                .build();\n' +
                            '\n' +
                            '        SpeechPrompt speechPrompt = new SpeechPrompt(content, options);\n' +
                            '\n' +
                            '        Flux<SpeechResponse> responseFlux = openAiAudioSpeechModel.stream(speechPrompt);\n' +
                            '\n' +
                            '        return responseFlux.flatMap(speechResponse -> Flux.just(speechResponse.getResult().getOutput()));\n' +
                            '    }'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TextToSpeechSpringIALlmContent;