import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../react/IndexContent";

class HillaReactContent extends BaseContentPage  {

    constructor(props) {
        super(props, "react-hilla", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Hilla </b>
                    <br/>
                    <br/>

                    Setup proiect:
                    <SyntaxHighlighter>
                        {'npx @hilla/cli init hilla-spring-vanilla'}
                    </SyntaxHighlighter>

                    poate afisa:
                    <SyntaxHighlighter>
                        {'npx: installed 106 in 14.519s\n' +
                            '\n' +
                            '@hilla/cli is deprecated. Use \'npm init vaadin\' to create new projects\n' +
                            '\n' +
                            'Creating application \'hilla-spring-vanilla\'\n' +
                            'Project \'hilla-spring-vanilla\' created'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>Module hilla-spring-vanilla SDK 17 is not compatible with the source version 21.</li>
                    </ul>

                    <SyntaxHighlighter>
                        {'git init'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'git remote add origin https://letyournailsgrow@bitbucket.org/letyournailsgrow/hilla-spring-vanilla.git'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'git push -u origin main'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <a target={"_blank"} href={"https://vaadin.com/hilla"}>Hilla</a>
                       </li>
                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default HillaReactContent;