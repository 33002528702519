import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class GoogleVertexGeminiSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-google-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Google Vertex AI este platforma de inteligență artificială oferită de Google Cloud,
                    care permite dezvoltarea, implementarea și gestionarea modelelor de învățare automată la scară largă.
                    <br/>
                    În cadrul acestei platforme, modelele Gemini reprezintă o familie avansată de modele de limbaj de mari dimensiuni (LLM) dezvoltate de Google DeepMind, succesoare ale modelelor LaMDA și PaLM 2.

                    <br/>

                    Evoluția modelelor Gemini:
                    <ul>
                        <li>
                            Gemini 1.0: Lansat în decembrie 2023, a introdus capacități multimodale și a fost integrat în diverse produse Google,
                            inclusiv în chatbot-ul Gemini (anterior cunoscut sub numele de Bard)
                        </li>
                        <li>
                            Gemini 1.5: Lansat în februarie 2024, a adus îmbunătățiri semnificative în performanță și capacități,
                            inclusiv o fereastră de context extinsă la un milion de tokeni, permițând procesarea unor volume mai mari de date
                        </li>
                        <li>
                            Gemini 2.0 Flash Experimental: Anunțat în decembrie 2024, această versiune experimentală oferă viteze și performanțe îmbunătățite,
                            introducând API-ul Multimodal Live pentru interacțiuni în timp real și capacități avansate de generare de imagini și text-to-speech
                        </li>
                    </ul>

                    Pentru a începe să utilizați modelele Gemini în Vertex AI, este necesar să aveți un cont Google Cloud și să configurați serviciile corespunzătoare.
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GoogleVertexGeminiSpringIALlmContent;