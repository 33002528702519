import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class SpatiuDeCuloareVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-spatiu-culoare", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Model de culoare</b>
                    <br/>
                    Un model de culoare este un model matematic abstract care permite reprezentarea culorilor sub formă numerică.
                    <br/>
                    Exemple de modele de culoare: RGB, CMYK.

                    <hr/>
                    <b>2. Spatiu de culoare</b>
                    <br/>

                    Un spațiu de culoare este combinația dintre un <i>model de culoare</i> și o <i>funcție de cartografiere</i> adecvată modelului de culoare.
                    <br/>
                    Modelul de culoare folosește <i>functia de cartografiere</i> pentru a asocia unui element dintr-un spațiu de culoare un vector numeric.

                    <br/>
                    <br/>
                    Altfel spus, un spațiu de culoare este tipul și numărul de culori care provin din combinațiile <i>componentelor de culoare</i> ale unui model de culoare.
                    Prin componentele sale de culoare, un model de culoare defineste un sistem de coordonate (de obicei tridimensional, dar poate fi si bidimensional).
                    Și in spatiul sau, un punct corespunde unui culori.
                    <br/>
                    <br/>
                    Avand doar proprietățile unui model de culoare, putem determina doar o anumită culoare relativ la alte culori ale spațiului de culoare.
                    Asta înseamnă că nu putem presupune cum este percepută de fapt o anumită culoare.
                    Ceea ce avem este doar un set de valori ale <i>componentelor de culoare</i> care poate reprezenta diferite culori în diferite contexte.
                    <br/>
                    <br/>

                    Pentru a determina o anumită culoare, este necesară în plus o referire la spațiul de culoare standardizat al vederii umane.
                    Referința este obținută prin cuantificare, rezultând o <i>formulă de conversie</i> specifică contextului.
                    Cu această formulă de conversie, toate culorile unui spațiu de culoare relativ pot fi exprimate în termeni de culori ale spațiului de culoare viziunii umane,
                    iar un set de valori ale <i>componentelor de culoare</i> reprezintă <i>percepția reală (absolută) a culorii</i> specifică contextului.

                    <br/>
                    <br/>
                    Culorile unui spațiu de culoare sunt determinate de:
                    <ul>
                        <li>valorile componente ale unui model de culoare în termeni relativi</li>
                        <li>referire la spațiul de culoare al vederii umane în termeni absoluti</li>
                    </ul>

                    Un spațiu de culoare <i>adevărat/absolut</i> necesită o specificare a <b>punctului alb</b> al spațiului de culoare.
                    <br/>
                    Un spațiu de culoare ale ecranului necesită o specificare a <i>valorii gamma</i> presupuse.

                    <br/>
                    <br/>
                    Spațiul de culoare definește culorile care sunt disponibile într-un anumit subset al unui model de culoare.
                    <br/>
                    Exemple de spatii de culoare: sRGB/Rec.709, DPI-P3, Adobe RGB, Rec.2020

                    <hr/>
                    <b>3. Gamut / Gama</b>
                    <br/>

                    Un subset de culori care poate fi reprezentat de un anumit model este un spatiu de culoare mai limitat.
                    Acest subset este numit gama / gamut și depinde de funcția utilizată pentru modelul de culoare.
                    (de exemplu, spațiile de culoare Adobe RGB și sRGB sunt diferite, deși ambele se bazează pe modelul de culoare RGB)

                    <br/>
                    <br/>
                    Gamut / Gama este o portiune sau zona din spatiul de culoare, reproductibila de către un anumit dispozitiv.
                    Deci, <b>gamut reprezinta toate culorile pe care le poate produce un anumit dispozitiv</b>.

                    <br/>

                    De exemplu, pentru un monitor gama/gamutul poate fi:
                    <ul>
                        <li>100% (tot) spatiul de culoare sRGB</li>
                        <li>90% din spatiul de culoare DPI-P3</li>
                    </ul>


                    <hr/>

                    O camera poate folosi un spatiu de culoare AdobeRGB, dar asta nu inseamna ca senzorul poate inregistra toate culorile din spatiul de culoare AdobeRGB.
                    <hr/>
                    Un monitor poate afisa AdobeRGB, dar sa nu fie capabil sa afiseze toate culorile din spatiu de culoare AdobeRGB.
                    <br/>
                    <br/>
                    Spatiu de culoare:
                    <ul>
                        <li>nu inseamna culori, reprezinta doar definitia culorilor</li>
                        <li>nu defineste ce culori este capabil sa afiseze un dispozitiv, ci doar ce culori sunt in acest spatiu</li>
                    </ul>

                    Deci, gamut este reprezinta descrierea culorilor pe care un dispozitiv poate sa le afiseze, printeze sau reproduca.

                    <hr/>
                    Rezumand:
                    <ul>
                        <li><b>spațiul de culoare</b> definește <b>culorile care sunt disponibile într-un anumit subset al unui model de culoare</b></li>
                        <li><b>gamut (gama de culori)</b> reprezintă <b>toate culorile pe care le poate produce un anumit dispozitiv</b></li>
                    </ul>

                    <hr/>
                    <b>4. Model aditiv</b>
                    <br/>

                    În 1861, fizicianul englez James Clark Maxwell a venit cu o propunere de a folosi o metodă de obținere a unei imagini color, cunoscută sub numele de <i>fuziune aditivă a culorilor</i>.
                    Culorile din acest model sunt adăugate la culoarea neagră.

                    <br/>
                    <br/>
                    <b>Spatiul RGB</b> este un model aditiv de culoare. In acest model, pornind de la negru (fara culoare), culorile <i>albastru, roșu și verde</i> sunt amestecate în diferite moduri pentru a produce o gamă largă de culori.

                    <br/>
                    In RGB avem:
                    <ul>
                        <li>Culori principale: rosu, verde, albastru</li>
                        <li>Culori complementare: cyan (G+B), magenta (B+R), galben (R+G)</li>
                        <li>Suma tuturor celor trei culori primare în acțiuni egale dă alb</li>
                    </ul>

                    Dezavantaje RGB:
                    <ul>
                        <li>gama de culori insuficientă</li>
                        <li>nu poate reproduce multe culori percepibile ochiului (de exemplu, cian și portocaliu spectral pur)</li>
                        <li>imposibilitatea reproducerii uniforme a culorilor pe diferite dispozitive (dependență hardware) datorită faptului că culorile de bază ale acestui model depind de parametrii tehnici ai dispozitivelor de ieșire a imaginii</li>
                    </ul>


                    <b>Spațiul de culoare Rec.709</b>
                    <br/>
                    Rec. 709 utilizează culori specifice de roșu, verde și albastru și iluminant D65 (6500k) pentru punctul alb
                    pentru a construi un spațiu de culoare standard și pentru a reproduce gama de culori și tonuri de gri exacte.
                    <br/>
                    <br/>
                    <b>Spațiul de culoare DCI-P3</b>
                    <br/>
                    DCI-P3 este un spațiu comun de culoare RGB pentru proiecția cinematografică digitală din industria cinematografică americană.
                    Adesea denumită „gamă largă”, gama DCI-P3 este cu 26% mai mare decât sRGB/Rec.709.
                    DCI-P3 este de așteptat să fie adoptat în sistemele de televiziune și Home Cinema ca un pas spre implementarea Rec. 2020.
                    <br/>
                    <br/>
                    <b>D65: Iluminantul standard de zi</b>
                    <br/>
                    Utilizat de Rec. 709 ca punct alb, iluminantul D65 este un standard utilizat în mod obișnuit de către CIE (Comisia Internațională pentru Iluminat)
                    pentru a reprezenta lumina medie a zilei cu o temperatură de culoare corelată de aproximativ 6500 K.
                    <br/>
                    <br/>

                    Capacitatea de a afișa cel puțin 16,7 (256 x 256 x 256 = 16.777.216 culori) milioane de nuanțe este un tip de imagine color complet denumit uneori <b>True Color</b>.

                    <hr/>

                    <b>5. Spatiu de culoare video</b>
                    <br/>
                    <br/>
                    Un spatiu de culoare video este un standard care defineste:
                    <ul>
                        <li><i>gama de culori</i></li>
                        <li><i>punctul alb</i></li>
                        <li><i>gama</i> sau <i>functia de transfer a componentelor de culoare</i> pentru codificarea culorilor intr-un sistem video</li>
                    </ul>

                    CIE (Comisia Internațională de Iluminat) definește un spațiu de culoare ca fiind:
                    <ul>
                        <li>reprezentare geometrică a culorii în spațiu, de obicei de 3 dimensiuni</li>
                    </ul>
                    Cele 3 dimensiuni sunt:
                    <ul>
                        <li>Rosu (R)</li>
                        <li>Verde (G)</li>
                        <li>Albastru (B)</li>
                    </ul>
                    Deci, pentru definirea unui spatiu de culoare, avem nevoie de:
                    <ul>
                        <li>gama de culori = un set de 3 culori primare individuale cu cromaticitati definite</li>
                        <li>punctul alb = cromaticitate unde R=G=B</li>
                        <li>gama = functia de transfer a componentelor de culoare care leaga valoare codificata cu valoare liniara a luminii din lumea reala</li>
                    </ul>
                    RGB este un model de codificare a culorilor, nu un spatiu de codare a culorilor:
                    <ul>
                        <li>Un model de codare a culorilor este pur și simplu o metodă de codificare a informațiilor de culoare</li>
                        <li>Un spațiu de codificare a culorilor definește valorile reale si leaga aceste valori cu o anumita tehnologie de captare sau afisare</li>
                    </ul>
                    Un model matematic de codificare a culorilor (de condificare a informatiilor de coloare a celor 3 dimensiuni) este <i>inutil</i> fara un mijloc de a lega <i>valorile codificate</i> de <i>culorile reale</i> pe care le vedem (perceptia noastra ascupra culorilor).

                    <br/>
                    <br/>
                    Relatia dintre perceptia noastra asupra culorii si fizica a fost stabilita de CIE in 1931.
                    <br/>
                    Diagrama de cromaticitate CIE 1931 xy (hartă principală la care se referă orice alt spațiu de culoare;
                    leagă valorile codificate și toate calculele de lungimi de undă vizibile absolute ale luminii):
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-46.png'}/>
                    </div>

                    Gama Rec. 709 spațiu de culoare video, definit de cromaticitatea celor trei culori primare pentru a forma o amprentă triunghiulară:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-47.png'}/>
                    </div>

                    Gama Rec. Spațiul de culoare video 2020, definit de cromaticitatea celor trei culori primare pentru a forma o amprentă triunghiulară:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-48.png'}/>
                    </div>

                    Gama unui spațiu de culoare este volumul de culoare care poate fi definit în limitele acelui spațiu de culoare.
                    <br/>
                    Gama oricărui spațiu de culoare este redat ca un triunghi suprapus pe diagrama de cromaticitate CIE 1931.
                    <br/>
                    Cele trei puncte ale triunghiului reprezintă primarele RGB, iar spațiul din interiorul triunghiului este gama.
                    <br/>
                    Orice valori de culoare din interiorul triunghiului sunt considerate în gamut pentru acel spațiu de culoare.
                    <br/>
                    Orice valori din afara triunghiului sunt considerate în afara gamei și nu pot fi codificate folosind acel spațiu de culoare.

                    <br/>
                    <br/>
                    <b>Punctul alb</b> pentru un spațiu de culoare declarat ca valoare CCT (temperatura corelată a culorii) în grade Kelvin:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/videography/videografie-49.png'}/>
                    </div>

                    Punctul alb de 6500k este D65.

                    <br/>
                    <br/>

                    O <b>funcție de transfer</b> este o relație matematică care definește modul în care intensitatea codificată sau valorile de luminanță se raportează la energia luminoasă reală la intrarea sau la ieșirea unui dispozitiv de captare sau afișare.
                    <br/>
                    Este cel mai des, dar incorect, aceasta functie de transfer este denumita <b>gamma</b>.


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=NU0P1w5tfHQ&ab_channel=LightDarkAcademy"}>Color Management, Color spaces and Gamut</a>
                            </div>
                        </li>
                       <li>
                           <div>
                               <a href={"https://www.richardlackey.com/what-is-a-video-color-space/"}>What Is A Video Color Space?</a><br/>
                           </div>
                       </li>
                        <li>
                            <div>
                                <a href={"http://www.workwithcolor.com/color-space-and-gamut-8531.htm"}>Color Space and Color Gamut</a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default SpatiuDeCuloareVideografieContent;