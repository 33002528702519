import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../nas/IndexContent";

class PortForwardNasContent extends BaseContentPage  {

    constructor(props) {
        super(props, "nas-port-forwarding", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Configurare router: Application » Port Forwarding » Port Forwarding</b>

                    <br/>
                    <br/>

                    Sa presupunem:
                    <ul>
                        <li>ca avem DDNS: dristor.go.ro</li>
                        <li>pe NAS avem un client de email
                            <br/>
                            IP: 192.168.1.7 / Port:80
                        </li>
                        <li>pe NAS avem intr-o masina virtual un server nginx
                            <br/>
                            IP: 192.168.1.9 / Port:80
                        </li>
                    </ul>

                    Atunci pentru a putea fi vazute in afara retelei se pot face urmatoarele setari din:
                    <br/>
                    <b>Application » Port Forwarding » Port Forwarding</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/nas-4/img_2.png'}/>
                    </div>

                    Dupa setarile facute, vom putea accesa si din afara retelei cele 2 aplicatii:
                    <ul>
                        <li>Clientul de email: http://dristor.go.ro:8001/mail</li>
                        <li>Serverul nginx: http://dristor.go.ro:8000</li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <a target={"_blank"} href={"https://www.media-max.ro/separare-sau-izolare-retele-wireless-ethernet-digi/"}>Separare sau izolare retele wireless-ethernet Digi</a>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PortForwardNasContent;