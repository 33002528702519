import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ReindexSearchLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-reindex", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Reindexare</b>

                    <ul>
                        <li>
                            logare cu administrator
                        </li>
                        <li>
                            Control Panel → Search → (tab) Index Actions
                        </li>
                        <li>
                            Se apasa click pe butonul <b>Execute</b> pentru:
                            <ul>
                                <li>
                                    Reindex search indexes.
                                </li>
                                <li>
                                    Reindex spell-check dictionaries.
                                </li>
                            </ul>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/c_13.png'}/>
                            </div>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://liferayiseasy.blogspot.com/2022/06/how-to-enable-jquery-in-liferay-dxp-73.html"}>*/}
                        {/*            How to enable jQuery in Liferay DXP (7.3)*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ReindexSearchLiferayContent;