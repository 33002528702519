import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class BluetoothHC06ArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-bluetooth-hc06", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Modul Bluetooth - HC 06</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_21.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_22.png'}/>
                    </div>

                    Pini:
                    <ul>
                        <li>
                            RXD (pin 1/RX pe Arduino) - RX - Receptionare date
                        </li>
                        <li>
                            TXD (pin 0/TX pe Arduino) - TX - Transmitere date
                        </li>
                        <li>
                            GND
                        </li>
                        <li>
                            VCC (5V)
                        </li>
                    </ul>

                    Nume dispozitiv: HC-06
                    <br/>
                    Parola (PIN-ul): 1234
                    <br/>
                    Observatie:
                    <ul>
                        <li>
                            pentru a se schimba parola se scrie comanda:
                            <SyntaxHighlighter>
                                {'AT+PSWD=noua_parola'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                    <hr/>


                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_23.png'}/>
                    </div>

                    <b>Important, pentru unele module HC-06</b>
                    <ul>
                        <li>
                            Pinul RX de la Arduino functioneaza atat cu tensiune logica de 5V cat si cu 3.3V</li>
                        <li>
                            Pinul RX de la modul HC-05 NU tolereaza tensiunea logica de 5V. Trebuie rezistori de 1k si 2k.
                        </li>
                    </ul>

                    In acest caz, este nevoie de:
                    <ul>
                        <li>
                            rezistor 1k Ohm între pinul 3 (daca se RX se conecteaza la pinul 3 de pe Arduino) și Rx
                        </li>
                        <li>
                            rezistor 2k Ohm între RX și GND
                        </li>
                    </ul>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_24.png'}/>
                    </div>


                    <hr/>
                    Exista o problema daca se conecteaza mai sus - conflic la urcarea programului pe Arduino.
                    <br/>
                    In acest sens, se poate evita pinii TX, RX si sa se foloseasca alti pini, de exemplu 10 si 11, impreuna cu o librarie:
                    <SyntaxHighlighter>
                        {'#include <SoftwareSerial.h>'}
                    </SyntaxHighlighter>
                    <SyntaxHighlighter>
                        {'SoftwareSerial mySerial(10,11); // RX,TX'}
                    </SyntaxHighlighter>

                    Exemplu 1 (afisare pe Serial Monitor ce se transmite de pe telefon):
                    <SyntaxHighlighter>
                        {'#include <SoftwareSerial.h>\n' +
                            '\n' +
                            'SoftwareSerial BtSerial(3,2);\n' +
                            '\n' +
                            'String messageReceive; \n' +
                            '\n' +
                            'void setup(){\n' +
                            '  Serial.begin(9600);\n' +
                            '  BtSerial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop(){\n' +
                            '  String messageReceive = ""; \n' +
                            '  while (BtSerial.available()){\n' +
                            '    char c =  BtSerial.read();\n' +
                            '    messageReceive += c;\n' +
                            '  }\n' +
                            '\n' +
                            '  if (messageReceive.length()>0){\n' +
                            '    Serial.print(messageReceive);\n' +
                            '  }\n' +
                            ' \n' +
                            '}'}
                    </SyntaxHighlighter>

                    Exemplu 2 (afisare pe telefon: TEST 1 sau ce se scrie pe Serial):
                    <SyntaxHighlighter>
                        {'#include <SoftwareSerial.h>\n' +
                            '\n' +
                            'SoftwareSerial BtSerial(3,2);// RX,TX\n' +
                            '\n' +
                            'String messageReceive; \n' +
                            '\n' +
                            'void setup(){\n' +
                            '  Serial.begin(9600);\n' +
                            '  BtSerial.begin(9600);\n' +
                            '}\n' +
                            '\n' +
                            'void loop(){\n' +
                            '  String messageReceive = ""; \n' +
                            '  while (BtSerial.available()){\n' +
                            '    char c =  BtSerial.read();\n' +
                            '    messageReceive += c;\n' +
                            '  }\n' +
                            '\n' +
                            '  if (messageReceive.length()>0){\n' +
                            '    Serial.print(messageReceive);\n' +
                            '  }\n' +
                            ' \n' +
                            '  if (Serial.available()){\n' +
                            '    char c =  Serial.read();\n' +
                            '    BtSerial.println(c);\n' +
                            '    delay(10);\n' +
                            '  }\n' +
                            '\n' +
                            '  BtSerial.write("TEST 1\\n");\n' +
                            '  Serial.println("TEST 2");\n' +
                            '  delay(2000);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Pe un telefon Android se poate instala:
                    <ul>
                        <li>
                            <b>Serial Bluetooth Terminal</b>
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/arduino/img_25.png'}/>
                            </div>

                            <li>
                                Setari Device:
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/arduino/img_27.png'}/>
                                </div>
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/arduino/img_26.png'}/>
                                </div>
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/arduino/img_28.png'}/>
                                </div>
                                <div style={{padding: 10}}>
                                    <img alt={""} style={{width: 250}} className={"rounded mx-auto d-block responsive-img"}
                                         src={process.env.PUBLIC_URL + '/img/arduino/img_29.png'}/>
                                </div>
                            </li>
                        </li>

                        <li>
                            <b>Bluetooth Terminal</b>
                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>

                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BluetoothHC06ArduinoContent;