import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FragmentsDesignLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-design-fragments", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <b>1. Fragmente: UI</b>
                    <br/>
                    <br/>

                    Un fragment în Liferay este o bucată de cod HTML, CSS și JavaScript care poate fi reutilizată pe mai multe pagini web.
                    <br/>
                    <br/>

                    Un fragment poate fi un element simplu, cum ar fi un buton sau un formular, sau poate fi un element mai complex, cum ar fi un flux de lucru sau o pagină întreagă.
                    Fragmentele pot fi configurate pentru a accepta date din exterior, cum ar fi numele unui utilizator sau conținutul unui câmp de formular.

                    <br/>
                    <br/>

                    Se poate accesa din: <b>Design ~ Fragments</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_1.png'}/>
                    </div>

                    In definirea unui fragment se poate scrie cod:
                    <ul>
                        <li>HTML</li>
                        <li>CSS</li>
                        <li>Javascript</li>
                    </ul>
                    Exemplu cod HTML:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<div id="header" class="d-flex justify-content-between py-2 mx-2 mx-2">\n' +
                            '    <div class="d-flex justify-content-start">\n' +
                            '        <!-- Logo and Header-->\n' +
                            '        <a href="/web/ibrid">\n' +
                            '            <img alt="LC Logo" class="h-75"\n' +
                            '                 src="[resources:logo (15).svg]"\n' +
                            '                 data-lfr-editable-type="image"\n' +
                            '                 data-lfr-editable-id="ibrid-logo"\n' +
                            '            />\n' +
                            '            <h2 data-lfr-editable-type="rich-text" class="px-2"\n' +
                            '                data-lfr-editable-id="ibrid-header">\n' +
                            '                Learning Center\n' +
                            '            </h2>\n' +
                            '        </a>\n' +
                            '    </div>\n' +
                            '    <div class="d-flex justify-content-end">\n' +
                            '        <div class="px-2">\n' +
                            '            <!-- Navigation and Search -->\n' +
                            '            <lfr-drop-zone></lfr-drop-zone>\n' +
                            '        </div>\n' +
                            '        <div class="px-2">\n' +
                            '            <!-- Language Switch -->\n' +
                            '            [#if configuration.showLanguage]\n' +
                            '            [@liferay_portlet["runtime"]\n' +
                            '            portletName="com_liferay_site_navigation_language_web_portlet_SiteNavigationLanguagePortlet"/]\n' +
                            '            [/#if]\n' +
                            '        </div>\n' +
                            '        <div class="px-2">\n' +
                            '            <!-- User Account -->\n' +
                            '            [#if configuration.showPersonalBar]\n' +
                            '            [@liferay_portlet["runtime"]\n' +
                            '            portletName="com_liferay_product_navigation_user_personal_bar_web_portlet_ProductNavigationUserPersonalBarPortlet"/]\n' +
                            '            [/#if]\n' +
                            '        </div>\n' +
                            '    </div>\n' +
                            '</div>'}
                    </SyntaxHighlighter>

                    Exemplu cod CSS:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'#header .page-editor__no-fragments-state {\n' +
                            '    padding: 1vh !important;\n' +
                            '}\n' +
                            '\n' +
                            '#header a {\n' +
                            '    color: inherit;\n' +
                            '    text-decoration: none;\n' +
                            '    display: flex;\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_2.png'}/>
                    </div>

                    Configurare fragment:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_3.png'}/>
                    </div>

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'{\n' +
                            '  "fieldSets": [\n' +
                            '    {\n' +
                            '      "fields": [\n' +
                            '        {\n' +
                            '          "name": "showLanguage",\n' +
                            '          "label": "Show Language",\n' +
                            '          "description": "Show Language Widget",\n' +
                            '          "type": "checkbox",\n' +
                            '          "defaultValue": true\n' +
                            '        },\n' +
                            '        {\n' +
                            '          "name": "showPersonalBar",\n' +
                            '          "label": "Show Personal Bar",\n' +
                            '          "description": "Show Personal Bar Widget",\n' +
                            '          "type": "checkbox",\n' +
                            '          "defaultValue": true\n' +
                            '        }\n' +
                            '      ]\n' +
                            '    }\n' +
                            '  ]\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Resurse:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/d_4.png'}/>
                    </div>

                    <b>2. Fragmente: Creare folosind Fragments Toolkit</b>
                    <br/>
                    <br/>

                    <b>Fragments Toolkit</b> este un set de instrumente pentru dezvoltarea fragmentelor pentru Liferay.
                    <ul>
                        <li>
                            Generarea de fragmente de la zero.
                        </li>
                        <li>
                            Exportarea acestora dintr-o instanță Liferay.
                        </li>
                        <li>
                            Importul acestora într-o instanță Liferay.
                        </li>
                    </ul>
                    Link: <a target={"_blank"} href={"https://www.npmjs.com/package/generator-liferay-fragments"}>Liferay Fragments Toolkit</a>

                    <hr/>
                    Instalare:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'npm i generator-liferay-fragments'}
                    </SyntaxHighlighter>
                    (mai bine, versiunea 1.9.1):
                    <SyntaxHighlighter>
                        {'npm i generator-liferay-fragments@1.9.1'}
                    </SyntaxHighlighter>

                    Creare fragment:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'yo liferay-fragments'}
                    </SyntaxHighlighter>

                    Se va introduce numele fragmentului:
                    <SyntaxHighlighter>
                        {'? Project name (Sample Liferay Fragments)'}
                    </SyntaxHighlighter>

                    Apoi, suntem intrebati daca vrem un exemplu de cod (raspundem cu Y):
                    <SyntaxHighlighter>
                        {'? Add sample content? Yes'}
                    </SyntaxHighlighter>

                    Exemplu complet:
                    <SyntaxHighlighter>
                        {'yo liferay-fragments\n' +
                            '\n' +
                            '    __    ____________________  _____  __\n' +
                            '   / /   /  _/ ____/ ____/ __ \\/   \\ \\/ /\n' +
                            '  / /    / // /_  / __/ / /_/ / /| |\\  /\n' +
                            ' / /____/ // __/ / /___/ _, _/ ___ |/ /\n' +
                            '/_____/___/_/   /_____/_/ |_/_/  |_/_/\n' +
                            '\n' +
                            '? Project name frag-1\n' +
                            '? Add sample content? Yes\n' +
                            '\n' +
                            'Creating directory\n' +
                            '    force .yo-rc.json\n' +
                            '   create src\\.gitkeep\n' +
                            '   create src\\sample-collection\\sample-fragment\\index.html\n' +
                            '   create src\\sample-collection\\sample-fragment\\main.js\n' +
                            '   create src\\sample-collection\\sample-fragment\\styles.css\n' +
                            '   create src\\sample-collection\\sample-fragment\\fragment.json\n' +
                            '   create src\\sample-collection\\sample-fragment\\configuration.json\n' +
                            '   create src\\sample-collection\\sample-fragment-with-new-editables\\index.html\n' +
                            '   create src\\sample-collection\\sample-fragment-with-new-editables\\main.js\n' +
                            '   create src\\sample-collection\\sample-fragment-with-new-editables\\styles.css\n' +
                            '   create src\\sample-collection\\sample-fragment-with-new-editables\\fragment.json\n' +
                            '   create src\\sample-collection\\sample-fragment-with-new-editables\\configuration.json\n' +
                            '   create src\\sample-collection\\sample-fragment-with-react\\index.html\n' +
                            '   create src\\sample-collection\\sample-fragment-with-react\\main.js\n' +
                            '   create src\\sample-collection\\sample-fragment-with-react\\styles.css\n' +
                            '   create src\\sample-collection\\sample-fragment-with-react\\fragment.json\n' +
                            '   create src\\sample-collection\\sample-fragment-with-react\\configuration.json\n'}
                    </SyntaxHighlighter>

                    Apoi:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'cd frag-1'}
                    </SyntaxHighlighter>
                    Import:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'yarn run import'}
                    </SyntaxHighlighter>
                    (n-a functionat!)

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <div>
                            <a href={"https://learn.liferay.com/w/dxp/site-building/developer-guide/developing-page-fragments/using-the-fragments-toolkit#setting-up-the-toolkit"}>
                                Using the Fragments Toolkit
                            </a>
                        </div>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default FragmentsDesignLiferayContent;