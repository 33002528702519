import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AudioRecordJavaContent extends BaseContentPage {

    constructor(props) {
        super(props, "java-audio-record", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>

                <div className={"text-justify important"}>


                    Inregistrare sunet de la microfon si salvare pe disk:
                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.agnes.spring.ia.audio;\n' +
                            '\n' +
                            'import javax.sound.sampled.*;\n' +
                            'import java.io.ByteArrayInputStream;\n' +
                            'import java.io.ByteArrayOutputStream;\n' +
                            'import java.io.File;\n' +
                            'import java.io.IOException;\n' +
                            'import java.util.Scanner;\n' +
                            '\n' +
                            'public class AudioRecorder {\n' +
                            '\n' +
                            '\n' +
                            '    public static void main(String args[]){\n' +
                            '        // Specificăm formatul audio\n' +
                            '//        AudioFormat format = new AudioFormat(\n' +
                            '//                AudioFormat.Encoding.PCM_SIGNED, // Format PCM semnat\n' +
                            '//                44100, // Rată de eșantionare (Hz)\n' +
                            '//                16,    // Număr de biți per eșantion\n' +
                            '//                2,     // Canale (stereo)\n' +
                            '//                4,     // Dimensiunea unui cadru (2 canale * 16 biți = 4 octeți)\n' +
                            '//                44100, // Rată de cadre pe secundă\n' +
                            '//                true   // Big-endian (ordinea octeților)\n' +
                            '//        );\n' +
                            '\n' +
                            '        AudioFormat format = new AudioFormat(44100, 16, 1, true, true); // Mono, PCM semnat\n' +
                            '\n' +
                            '        // Linie de captură audio (microfon)\n' +
                            '        DataLine.Info info = new DataLine.Info(TargetDataLine.class, format);\n' +
                            '        if (!AudioSystem.isLineSupported(info)) {\n' +
                            '            System.out.println("Linia specificată nu este suportată.");\n' +
                            '            return;\n' +
                            '        }\n' +
                            '\n' +
                            '        try (TargetDataLine microphone = (TargetDataLine) AudioSystem.getLine(info)) {\n' +
                            '            // Deschidem microfonul\n' +
                            '            microphone.open(format);\n' +
                            '            System.out.println("Înregistrare începe... Apasă Enter pentru a opri.");\n' +
                            '\n' +
                            '            // Pornim microfonul\n' +
                            '            microphone.start();\n' +
                            '\n' +
                            '            // Buffer pentru datele audio\n' +
                            '            byte[] buffer = new byte[4096];\n' +
                            '            ByteArrayOutputStream out = new ByteArrayOutputStream();\n' +
                            '\n' +
                            '            // Thread pentru captarea datelor audio\n' +
                            '            Thread recordingThread = new Thread(() -> {\n' +
                            '                while (!Thread.currentThread().isInterrupted()) {\n' +
                            '                    int bytesRead = microphone.read(buffer, 0, buffer.length);\n' +
                            '                    out.write(buffer, 0, bytesRead);\n' +
                            '                }\n' +
                            '            });\n' +
                            '\n' +
                            '            recordingThread.start();\n' +
                            '            System.in.read(); // Așteptăm input pentru a opri înregistrarea; cand se apasa ENTER se trece mai departe\n' +
                            '\n' +
                            '            // daca se doreste orice input pentru oprirea înregistrării; cand se apasa ORICE tasta se trece mai departe\n' +
                            '            //Scanner scanner = new Scanner(System.in);\n' +
                            '            //scanner.nextLine(); // Citește o linie de la utilizator\n' +
                            '\n' +
                            '            recordingThread.interrupt();\n' +
                            '\n' +
                            '            // Oprirea microfonului\n' +
                            '            microphone.stop();\n' +
                            '            microphone.close();\n' +
                            '            System.out.println("Înregistrare oprită.");\n' +
                            '\n' +
                            '            // Salvăm datele înregistrate într-un fișier WAV\n' +
                            '            saveAudio(out.toByteArray(), format, "D:\\\\work\\\\spring-ia\\\\src\\\\main\\\\resources\\\\"+"recording.wav");\n' +
                            '\n' +
                            '        } catch (LineUnavailableException | IOException e) {\n' +
                            '            e.printStackTrace();\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '    // Metodă pentru salvarea datelor audio într-un fișier WAV\n' +
                            '    private static void saveAudio(byte[] audioData, AudioFormat format, String fileName) {\n' +
                            '        try (ByteArrayInputStream bais = new ByteArrayInputStream(audioData);\n' +
                            '             AudioInputStream audioStream = new AudioInputStream(bais, format, audioData.length / format.getFrameSize())) {\n' +
                            '            File wavFile = new File(fileName);\n' +
                            '            AudioSystem.write(audioStream, AudioFileFormat.Type.WAVE, wavFile);\n' +
                            '            System.out.println("Fișier audio salvat: " + wavFile.getAbsolutePath());\n' +
                            '        } catch (IOException e) {\n' +
                            '            e.printStackTrace();\n' +
                            '        }\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>




                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            Jeanne Boyarsky, Scott Selikoff, 2020 -  OCP - Oracle Certified Professional Java SE 11 Developer - Complete Guide*/}
                    {/*        </div>*/}
                    {/*    </li>*/}
                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AudioRecordJavaContent;