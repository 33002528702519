import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FunctionsSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-functions-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    OpenAI are abilitatea de a invoca functii.
                    <ul>
                        <li>un model cunoaste doar ce ce era la momentul antrenarii (poate fi in urma cu luni de zile sau chiar ani)</li>
                        <li>nu stie, de exemplu, care este ziua curenta sau cum este vremea curenta</li>
                        <li>cu ajutorul functiilor, un model va fi capabil sa obtina informatii legate de ziua curenta sau de vreme</li>
                    </ul>

                    Creare functie:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {
                            'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'import java.util.function.Function;\n' +
                            '\n' +
                            'public class StockRetrievalService implements Function<StockRetrievalService.Request, StockRetrievalService.Response> {\n' +
                            '\n' +
                            '    public record Request(String simbol){}\n' +
                            '\n' +
                            '    public record Response(Double price){}\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Response apply(Request request) {\n' +
                            '        return new Response(777D);\n' +
                            '    }\n' +
                            '}\n'
                        }
                    </SyntaxHighlighter>

                    Creare @Bean:
                    <SyntaxHighlighter  showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.config;\n' +
                            '\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.context.annotation.Description;\n' +
                            'import ro.agnes.spring.ia.model.StockRetrievalService;\n' +
                            '\n' +
                            'import java.util.function.Function;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class Functions {\n' +
                            '\n' +
                            '    @Bean\n' +
                            '    @Description("Returns the stock price given a symbol")\n' +
                            '    Function<StockRetrievalService.Request, StockRetrievalService.Response> stockRetrievalService(){\n' +
                            '        return new StockRetrievalService();\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Service:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    public String getStockPrice(String company){\n' +
                            '        OpenAiChatOptions chatOptions = OpenAiChatOptions.builder()\n' +
                            '                .function("stockRetrievalService") // nume @Bean\n' +
                            '                .build();\n' +
                            '\n' +
                            '        Prompt prompt = new Prompt("Get stock symbol and stock price for "+company, chatOptions);\n' +
                            '\n' +
                            '        return chatModel.call(prompt).getResult().getOutput().getContent();\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    sau:

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' public String getStockPrice2(String company){\n' +
                            '        OpenAiChatOptions chatOptions = OpenAiChatOptions.builder()\n' +
                            '                .functionCallbacks(List.of(\n' +
                            '                        FunctionCallback.builder()\n' +
                            '                                .function("CurrentStock", new StockRetrievalService())\n' +
                            '                                .description("Returns the stock price given a symbol").build()))\n' +
                            '                .build();\n' +
                            '\n' +
                            '        Prompt prompt = new Prompt("Get stock symbol and stock price for "+company, chatOptions);\n' +
                            '\n' +
                            '        return chatModel.call(prompt).getResult().getOutput().getContent();\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Convertire raspuns, imbagatind modelul cu schema:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    public String getStockPrice2(String company){\n' +
                            '        OpenAiChatOptions chatOptions = OpenAiChatOptions.builder()\n' +
                            '                .functionCallbacks(List.of(\n' +
                            '                        FunctionCallback.builder()\n' +
                            '                                .function("CurrentStock", new StockRetrievalService())\n' +
                            '                                .description("Returns the stock price given a symbol")\n' +
                            '                                .responseConverter(response->{\n' +
                            '                                    String schema = ModelOptionsUtils.getJsonSchema(StockRetrievalService.Response.class, false);\n' +
                            '                                    String json = ModelOptionsUtils.toJsonString(response);\n' +
                            '                                    return schema +"\\n"+ json;\n' +
                            '                                })\n' +
                            '                                .build()))\n' +
                            '                .build();\n' +
                            '\n' +
                            '        Prompt prompt = new Prompt("Get stock symbol and stock price for "+company, chatOptions);\n' +
                            '\n' +
                            '        return chatModel.call(prompt).getResult().getOutput().getContent();\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Convertire raspuns, imbagatind modelul cu schema si context:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    public String getStockPrice2(String company){\n' +
                            '        OpenAiChatOptions chatOptions = OpenAiChatOptions.builder()\n' +
                            '                .functionCallbacks(List.of(\n' +
                            '                        FunctionCallback.builder()\n' +
                            '                                .function("CurrentStock", new StockRetrievalService())\n' +
                            '                                .description("Returns the stock price given a symbol")\n' +
                            '                                .responseConverter(response->{\n' +
                            '                                    String schema = ModelOptionsUtils.getJsonSchema(StockRetrievalService.Response.class, false);\n' +
                            '                                    String json = ModelOptionsUtils.toJsonString(response);\n' +
                            '                                    return schema +"\\n"+ json;\n' +
                            '                                })\n' +
                            '                                .build()))\n' +
                            '                .build();\n' +
                            '\n' +
                            '        Message userMessage = new PromptTemplate("Get stock symbol and stock price for "+company).createMessage();\n' +
                            '        Message systemMessage = new SystemPromptTemplate("You are a stock service").createMessage();// context\n' +
                            '        \n' +
                            '        var response = openAiChatModel.call(new Prompt(List.of(userMessage, systemMessage), chatOptions)).getResult();\n' +
                            '        \n' +
                            '        return response.getOutput().getContent();\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    Daca in controller avem:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @PostMapping("/fn")\n' +
                            '    public String fn() {\n' +
                            '        return openAIService.getStockPrice("X");\n' +
                            '    }'}
                    </SyntaxHighlighter>
                    Rezultatul apelarii, poate fi:
                    <SyntaxHighlighter>
                        {'The stock symbol "X" represents United States Steel Corporation, and its current stock price is $777.00.'}
                    </SyntaxHighlighter>

                    <hr/>
                    Alt exemplu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.functions;\n' +
                            '\n' +
                            'import org.springframework.web.client.RestClient;\n' +
                            '\n' +
                            'import java.util.function.Function;\n' +
                            '\n' +
                            'public class WeatherServiceFunction implements Function<WeatherServiceFunction.WeatherRequest, WeatherServiceFunction.WeatherResponse> {\n' +
                            '\n' +
                            '    public static final String WEATHER_URL = "..";\n' +
                            '\n' +
                            '    record WeatherRequest(String location) {\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    record WeatherResponse() {\n' +
                            '    }\n' +
                            '\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public WeatherResponse apply(WeatherRequest weatherRequest) {\n' +
                            '        RestClient restClient = RestClient.builder()\n' +
                            '                .baseUrl(WEATHER_URL)\n' +
                            '                .defaultHeaders(httpHeaders -> {\n' +
                            '                    httpHeaders.set("X-API-Key", "..");\n' +
                            '                    httpHeaders.set("Accept", "application/json");\n' +
                            '                    httpHeaders.set("Content-Type", "application/json");\n' +
                            '                })\n' +
                            '                .build();\n' +
                            '\n' +
                            '        return restClient.get()\n' +
                            '                .uri(uriBuilder -> {\n' +
                            '                    uriBuilder.queryParam("city", weatherRequest.location());\n' +
                            '                    return uriBuilder.build();\n' +
                            '                })\n' +
                            '                .retrieve()\n' +
                            '                .body(WeatherResponse.class);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FunctionsSpringIALlmContent;