import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class RedisSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-redis-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Redis Vector Db</b>
                    <br/>
                    <br/>


                    Se adauga dependinta:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'implementation \'org.springframework.ai:spring-ai-redis-store-spring-boot-starter\''}
                    </SyntaxHighlighter>

                    Se adauga configurarile:
                    <SyntaxHighlighter>
                        {'spring.ai.vectorstore.redis.uri=localhost\n' +
                            'spring.ai.vectorstore.redis.index=documents'}
                    </SyntaxHighlighter>

                    Configurare Bean:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'import org.springframework.ai.embedding.EmbeddingModel;\n' +
                            '\n' +
                            'import org.springframework.ai.vectorstore.RedisVectorStore;\n' +
                            'import org.springframework.context.annotation.Bean;\n' +
                            'import org.springframework.context.annotation.Configuration;\n' +
                            'import org.springframework.data.redis.connection.jedis.JedisConnectionFactory;\n' +
                            'import redis.clients.jedis.JedisPooled;\n' +
                            '\n' +
                            '@Configuration\n' +
                            'public class AppConfig {\n' +
                            '    @Bean\n' +
                            '    public RedisVectorStore vectorStore(EmbeddingModel embeddingModel,\n' +
                            '                                        JedisConnectionFactory jedisConnectionFactory) {\n' +
                            '        return new RedisVectorStore(\n' +
                            '                RedisVectorStore.RedisVectorStoreConfig.builder()\n' +
                            '                        .withMetadataFields( RedisVectorStore.MetadataField.tag("meta2")\n' +
                            '                        )\n' +
                            '                        .build(),\n' +
                            '                embeddingModel,\n' +
                            '                new JedisPooled(jedisConnectionFactory.getHostName(), jedisConnectionFactory.getPort()), true);\n' +
                            '    }\n' +
                            '\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Incarcare si cautare:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' @Value("classpath:input.txt")\n' +
                            '    Resource resource;\n' +
                            '    private static final double SIMILARITY_THRESHOLD = 0.5;\n' +
                            '    VectorStore vectorStore;\n' +
                            '\n' +
                            '    public AIController(VectorStore vectorStore) {\n' +
                            '        this.vectorStore = vectorStore;\n' +
                            '    }\n' +
                            '\n' +
                            '    @GetMapping("/load")\n' +
                            '    public String load() throws IOException, InterruptedException {\n' +
                            '        List<Document> documents = Files.lines(resource.getFile().toPath())\n' +
                            '                .map(line -> new Document(line, Map.of("meta1", "meta2")))\n' +
                            '                .toList();\n' +
                            '\n' +
                            '        TextSplitter textSplitter = new TokenTextSplitter();\n' +
                            '\n' +
                            '        for (Document document : documents) {\n' +
                            '            List<Document> splitteddocs = textSplitter.split(document);\n' +
                            '            System.out.println("before adding document: " + document.getContent());\n' +
                            '            vectorStore.add(splitteddocs);\n' +
                            '            System.out.println("Added document: " + document.getContent());\n' +
                            '            Thread.sleep(20000);\n' +
                            '        }\n' +
                            '        return "Loaded " + resource.getFilename();\n' +
                            '    }\n' +
                            '\n' +
                            '    @GetMapping("/search")\n' +
                            '    public String search() throws IOException, InterruptedException {\n' +
                            '\n' +
                            '        List<Document> results = vectorStore.similaritySearch(SearchRequest.\n' +
                            '                query("Bike for small kids").withTopK(2)\n' +
                            '                .withSimilarityThreshold(SIMILARITY_THRESHOLD));\n' +
                            '        return results.toString();\n' +
                            '    }'}
                    </SyntaxHighlighter>

                </div>
                <br/>
                <div className={"text-justify"}>
                {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default RedisSpringIALlmContent;