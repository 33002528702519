import React from "react";

class IndexContentHelper {

    static chapterByItem(item, content) {
        if (item == null) {
            return ""
        }
        return IndexContentHelper.chapter(item.id, content)
    }

    // 2 nivele
    static chapter(id, content) {
        let currentItem = IndexContentHelper.current(id, content);
        let parent = currentItem.parent;
        let index = IndexContentHelper.indexByParent(id, content, parent);

        let parentIndex = IndexContentHelper.indexByParent(parent, content);

        // alert(id+" "+index+" / "+parent + " "+ parentIndex)

        return (parentIndex + 1) + "." + (index + 1);
    }

    static indexByParent(id, content, parent) {
        let k = -1;
        for (let i = 0; i < content.length; i++) {
            let item = content[i];
            if (item.parent === parent) {
                k++;
                if (item.id === id) {
                    return k;
                }
            }
        }
        return null;
    }

    static getArticle(content) {
        let count = 0;
        for (let i = 0; i < content.length; i++) {
            if (content[i].url !== "#") {
                count++;
            }
        }
        return count;
    }

    static index(id, content) {
        for (let i = 0; i < content.length; i++) {
            let item = content[i];
            if (item.id === id) {
                return i;
            }
        }
        return null;
    }

    static current(id, content) {
        for (let i = 0; i < content.length; i++) {
            let item = content[i];
            if (item.id === id) {
                return item;
            }
        }
        return null;
    }

    static next(id, content) {
        for (let i = 0; i < content.length; i++) {
            let item = content[i];
            if (item.id === id) {

                while (i < content.length - 1) {

                    if (content[i + 1].url !== "#") {
                        return content[i + 1];
                    } else {
                        i++;
                    }
                }
                return null;

            }
        }
        return null;
    }

    static defaultUrl(content) {
        for (let i = 0; i < content.length; i++) {
            let item = content[i];
            if (item.defaultUrl) {
                return content[i].defaultUrl;
            }
        }
        return null;
    }

    static prev(id, content) {

        for (let i = 0; i < content.length; i++) {
            let item = content[i];
            if (item.id === id) {

                while (i > 0) {

                    if (content[i - 1].url !== "#") {
                        return content[i - 1];
                    } else {
                        i--;
                    }
                }
                return null;
            }
        }
        return null;
    }


}

export default IndexContentHelper;
