import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class FormatSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-format-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    <hr/>
                    <b>1. Parametrizare Prompt</b>
                    <br/>
                    <br/>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'public record CapitalRequest(String tara) {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    capital.prompt (fisierul de template prompt - in src/main/resources/templates):
                    <SyntaxHighlighter>
                        {'Care este capitala {tara}?'}
                    </SyntaxHighlighter>

                    se adauga in controller:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'   @PostMapping("/ask-capital")\n' +
                            '    public Answer askQuestion(@RequestBody CapitalRequest capitalRequest){\n' +
                            '        return openAIService.getAnswer(capitalRequest);\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    iar in implementare (dupa ce se completeaza metoda si in service):
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Value("classpath:templates/capital.prompt")\n' +
                            '    private Resource capitalPrompt;'}
                    </SyntaxHighlighter>
                    si
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Override\n' +
                            '    public Answer getAnswer(CapitalRequest capitalRequest) {\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate(capitalPrompt);\n' +
                            '\n' +
                            '        Prompt prompt = promptTemplate.create(Map.of("tara", capitalRequest.tara()));\n' +
                            '\n' +
                            '        ChatResponse chatResponse = chatModel.call(prompt);\n' +
                            '\n' +
                            '        return new Answer(chatResponse.getResult().getOutput().getContent());\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>2. Formatare raspuns (template/sablon)</b>
                    <br/>
                    <br/>

                    Se poate folosi: <b>Respond with the following format:</b> sau <b>Raspunde folosind urmatorul sablon:</b>
                    <br/>

                    Fisierul template de prompt (in src/main/resources/templates):
                    <SyntaxHighlighter>
                        {'Care este capitala {tara}?\n' +
                            'Respond with the following format:\n' +
                            '   Capitala pentru ```tara``` este ```capital```.\n' +
                            '   Numar populatie ```population```.\n' +
                            '   Regiune: ```region```.\n' +
                            '   Limba: ```language```.\n' +
                            '   Moneda: ```currency```.'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter>
                        {'{\n' +
                            '    "answer": "Capitala pentru ```România``` este ```București```.  \\nNumar populatie ```aproximativ 1.8 milioane``` (variază în funcție de surse și ani).  \\nRegiune: ```Europa de Est```.  \\nLimba: ```Română```.  \\nMoneda: ```Leu (RON)```."\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>3. Formatare raspuns (format json)</b>
                    <br/>
                    <br/>

                    Fisierul template de prompt (in src/main/resources/templates):
                    <SyntaxHighlighter>
                        {'Care este capitala {tara}?\n' +
                            'Raspunde in format JSON punand capitala intr-o propritate numita `capitala`.'}
                    </SyntaxHighlighter>

                    Raspunsul va fi:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '    "answer": "```json\\n{\\n  \\"capitala\\": \\"București\\"\\n}\\n```"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Dar se poate parsa raspunsul astfel incat raspunsul sa devina:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '    "answer": "București"\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.service;\n' +
                            '\n' +
                            'import com.fasterxml.jackson.core.JsonProcessingException;\n' +
                            'import com.fasterxml.jackson.databind.JsonNode;\n' +
                            'import com.fasterxml.jackson.databind.ObjectMapper;\n' +
                            'import org.springframework.ai.chat.model.ChatModel;\n' +
                            'import org.springframework.ai.chat.model.ChatResponse;\n' +
                            'import org.springframework.ai.chat.prompt.Prompt;\n' +
                            'import org.springframework.ai.chat.prompt.PromptTemplate;\n' +
                            'import org.springframework.beans.factory.annotation.Autowired;\n' +
                            'import org.springframework.beans.factory.annotation.Qualifier;\n' +
                            'import org.springframework.beans.factory.annotation.Value;\n' +
                            'import org.springframework.core.io.Resource;\n' +
                            'import org.springframework.stereotype.Service;\n' +
                            'import org.springframework.web.bind.annotation.RequestBody;\n' +
                            'import ro.agnes.spring.ia.model.Answer;\n' +
                            'import ro.agnes.spring.ia.model.CapitalRequest;\n' +
                            'import ro.agnes.spring.ia.model.Question;\n' +
                            '\n' +
                            'import java.util.Map;\n' +
                            '\n' +
                            '@Service\n' +
                            'public class OpenAIServiceImpl implements OpenAIService {\n' +
                            '\n' +
                            '    private final ChatModel chatModel;\n' +
                            '\n' +
                            '    public OpenAIServiceImpl(@Qualifier("openAiChatModel") ChatModel chatModel) {\n' +
                            '        this.chatModel = chatModel;\n' +
                            '    }\n' +
                            '\n' +
                            '    @Value("classpath:templates/capital.prompt")\n' +
                            '    private Resource capitalPrompt;\n' +
                            '\n' +
                            '    @Value("classpath:templates/capital-info.prompt")\n' +
                            '    private Resource capitalInfoPrompt;\n' +
                            '\n' +
                            '    @Value("classpath:templates/capital-json.prompt")\n' +
                            '    private Resource capitalJsonPrompt;\n' +
                            '\n' +
                            '    @Autowired\n' +
                            '    private ObjectMapper objectMapper;\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public String getAnswer(String question) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate(question);\n' +
                            '\n' +
                            '        Prompt prompt = promptTemplate.create();\n' +
                            '\n' +
                            '        ChatResponse chatResponse = chatModel.call(prompt);\n' +
                            '\n' +
                            '        return chatResponse.getResult().getOutput().getContent();\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Answer getAnswer(Question question) {\n' +
                            '        return new Answer(getAnswer(question.question()));\n' +
                            '    }\n' +
                            '\n' +
                            '    @Override\n' +
                            '    public Answer getAnswer(CapitalRequest capitalRequest) {\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate(capitalJsonPrompt); // capitalPrompt, capitalInfoPrompt\n' +
                            '\n' +
                            '        Prompt prompt = promptTemplate.create(Map.of("tara", capitalRequest.tara()));\n' +
                            '\n' +
                            '        ChatResponse chatResponse = chatModel.call(prompt);\n' +
                            '        String response = chatResponse.getResult().getOutput().getContent();\n' +
                            '\n' +
                            '        response = getJson(response);\n' +
                            '\n' +
                            '        return new Answer(response);\n' +
                            '    }\n' +
                            '\n' +
                            '    private String getJson(String response) {\n' +
                            '        String responseString;\n' +
                            '        try {\n' +
                            '            JsonNode jsonNode = objectMapper.readTree(response);\n' +
                            '            responseString = jsonNode.get("capitala").asText();\n' +
                            '\n' +
                            '        } catch (JsonProcessingException e) {\n' +
                            '            throw new RuntimeException(e);\n' +
                            '        }\n' +
                            '        return responseString;\n' +
                            '    }\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>4. Formatare JSON - varianta 2 cu BeanOutputConverter</b>
                    <br/>
                    <br/>

                    Fie <i>CapitalResponse</i>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'import com.fasterxml.jackson.annotation.JsonPropertyDescription;\n' +
                            'public record CapitalResponse(@JsonPropertyDescription("This is the city name") String answer) {\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Adaugam in controller:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @PostMapping("/capital")\n' +
                            '    public CapitalResponse getCapital(@RequestBody CapitalRequest getCapitalRequest) {\n' +
                            '        return this.openAIService.getCapital(getCapitalRequest);\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Adaugam in service:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' CapitalResponse getCapital(CapitalRequest getCapitalRequest);'}
                    </SyntaxHighlighter>

                    Adaugam implementarea:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'  public CapitalResponse getCapital(CapitalRequest capitalRequest) {\n' +
                            '        BeanOutputConverter<CapitalResponse> converter = new BeanOutputConverter<>(CapitalResponse.class);\n' +
                            '        String format = converter.getFormat();\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate(capitalJson2Prompt);\n' +
                            '        Prompt prompt = promptTemplate.create(Map.of(\n' +
                            '                "tara", capitalRequest.tara(),\n' +
                            '                "format", format)\n' +
                            '        );\n' +
                            '\n' +
                            '        ChatResponse response = chatModel.call(prompt);\n' +
                            '\n' +
                            '        return converter.convert(response.getResult().getOutput().getContent());\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Variabila <i>format</i> va contine:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'Your response should be in JSON format.\n' +
                            'Do not include any explanations, only provide a RFC8259 compliant JSON response following this format without deviation.\n' +
                            'Do not include markdown code blocks in your response.\n' +
                            'Remove the ```json markdown from the output.\n' +
                            'Here is the JSON Schema instance your output must adhere to:\n' +
                            '```{\n' +
                            '  "$schema" : "https://json-schema.org/draft/2020-12/schema",\n' +
                            '  "type" : "object",\n' +
                            '  "properties" : {\n' +
                            '    "answer" : {\n' +
                            '      "type" : "string",\n' +
                            '      "description" : "This is the city name"\n' +
                            '    }\n' +
                            '  },\n' +
                            '  "additionalProperties" : false\n' +
                            '}```\n'}
                    </SyntaxHighlighter>


                    <hr/>
                    <b>Returnare date structurate</b>
                    <br/>
                    <br/>

                    Returnare lista de String-uri:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {' public List<String> getCityList(){\n' +
                            '        String message = "Zi-mi 5 orase din Romania";\n' +
                            '\n' +
                            '        return chatClient.prompt()\n' +
                            '                .user(message)\n' +
                            '                .call()\n' +
                            '                .entity(new ListOutputConverter(new DefaultConversionService()));\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    Returnare obiect
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Override\n' +
                            '    public FoodResponse getFoods(String country, String numFoods) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate("Esti un expert in mancare traditionala.\\n" +\n' +
                            '                "Raspunde la urmatoarea intrabare: Care este mancarea traditionala in {country}?\\n" +\n' +
                            '                "Returneza o lista de {numFoods}.\\n");\n' +
                            '        \n' +
                            '        Prompt prompt = promptTemplate.create(Map.of("country", country, "numFoods", numFoods));\n' +
                            '\n' +
                            '        return chatClient.prompt(prompt).call().entity(FoodResponse.class);\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>

                    cu:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia.model;\n' +
                            '\n' +
                            'import java.util.List;\n' +
                            '\n' +
                            'public record FoodResponse(String country, List<String> numFoods) {\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    Halucinatie:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_12.png'}/>
                    </div>

                    Pentru a evita halucinatiile se pot adauga restictii:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Override\n' +
                            '    public FoodResponse getFoods(String country, String numFoods) {\n' +
                            '\n' +
                            '        PromptTemplate promptTemplate = new PromptTemplate("Esti un expert in mancare traditionala.\\n" +\n' +
                            '                "Raspunde la urmatoarea intrabare: Care este mancarea traditionala in {country}?\\n" +\n' +
                            '                "Returneza o lista de {numFoods}. Evita tarile care nu exista. \\n"+\n' +
                            '                "Oferiți informații despre un anumit fel de mâncare dintr-o anumită țară.\\n"+\n' +
                            '                "Evitați să oferiți informații despre locuri fictive.\\n" +\n' +
                            '                "Dacă țara este fictivă sau inexistentă, întoarceți țara fără mancare.");\n' +
                            '        \n' +
                            '        Prompt prompt = promptTemplate.create(Map.of("country", country, "numFoods", numFoods));\n' +
                            '\n' +
                            '        return chatClient.prompt(prompt).call().entity(FoodResponse.class);\n' +
                            '\n' +
                            '    }'}
                    </SyntaxHighlighter>
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FormatSpringIALlmContent;