import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class ModerationsSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-moderations-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                    OpenAiModerationModel este un serviciu oferit de OpenAI pentru analiza și moderarea conținutului generat de utilizatori,
                    având ca scop detectarea conținutului care poate fi inadecvat, periculos sau contrar regulilor de utilizare.
                    Acest model poate fi utilizat pentru a monitoriza și filtra textul în aplicații, forumuri, chat-uri și alte platforme care necesită un control al conținutului.

                    <br/>
                    <br/>
                    Functionalitati oferite:
                    <ul>
                        <li>
                            Detectarea conținutului sensibil
                            <ul>
                                <li> Discurs instigator la ură (hate speech).</li>
                                <li> Conținut violent sau amenințător.</li>
                                <li> Materiale sexuale explicite sau sugestive.</li>
                                <li> Autovătămare sau promovarea acesteia.</li>
                                <li> Conținut ilegal.</li>
                            </ul>
                        </li>
                        <li>
                            Clasificarea textului:
                            <ul>
                                <li>
                                    <li>Modelele pot evalua textul în funcție de categorii predefinite (e.g., violență, nuditate, limbaj ofensator).</li>
                                    <li>Ajută la identificarea rapidă a fragmentelor care ar trebui analizate suplimentar sau blocate.</li>
                                </li>
                            </ul>
                        </li>
                    </ul>
                    <hr/>

                    Se foloseste <b>OpenAiModerationModel</b>:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    @Autowired\n' +
                            '    private OpenAiModerationModel openAiModerationModel;'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'    public ModerationResult moderate(String text){\n' +
                            '        Moderation moderation = openAiModerationModel.call(new ModerationPrompt(text)).getResult().getOutput();\n' +
                            '        return moderation.getResults().get(0);\n' +
                            '    }\n' +
                            '}'}
                    </SyntaxHighlighter>

                    <hr/>

                    Pentru textul "akuuzu want to kill some one " avem rezultatul:
                    <SyntaxHighlighter>
                        {'{\n' +
                            '   "flagged=true",\n' +
                            '   "categories=Categories"{\n' +
                            '      "sexual=false",\n' +
                            '      "hate=false",\n' +
                            '      "harassment=false",\n' +
                            '      "selfHarm=false",\n' +
                            '      "sexualMinors=false",\n' +
                            '      "hateThreatening=false",\n' +
                            '      "violenceGraphic=false",\n' +
                            '      "selfHarmIntent=false",\n' +
                            '      "selfHarmInstructions=false",\n' +
                            '      "harassmentThreatening=false",\n' +
                            '      "violence=true"\n' +
                            '   },\n' +
                            '   "categoryScores=CategoryScores"{\n' +
                            '      sexual=1.4182731320033781E-5,\n' +
                            '      hate=6.275362829910591E-5,\n' +
                            '      harassment=9.194292942993343E-4,\n' +
                            '      selfHarm=2.3648483329452574E-4,\n' +
                            '      sexualMinors=1.2984852446606965E-6,\n' +
                            '      hateThreatening=4.031578555441229E-6,\n' +
                            '      violenceGraphic=5.069174221716821E-6,\n' +
                            '      selfHarmIntent=3.4807788324542344E-5,\n' +
                            '      selfHarmInstructions=2.198330548708327E-6,\n' +
                            '      harassmentThreatening=8.509405888617039E-4,\n' +
                            '      violence=0.6513784527778625\n' +
                            '   }\n' +
                            '}'}
                    </SyntaxHighlighter>
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ModerationsSpringIALlmContent;