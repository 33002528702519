import React from "react";
import {MathComponent} from "mathjax-react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class McCullochPittsContent extends BaseContentPage {

    constructor(props) {
        super(props, "mcculloch-pitts", IndexContent);
    }

    render() {
        return (
            <div className="home ann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>
                    Neuronul McCulloch-Pitts (1943) este probabil cel vechi neuron artificial. Caracteristiciile acestuia:
                    <ul>
                        <li>
                            valorile intrărilor sunt binare (1 sau 0)
                        </li>
                        <li>
                            activarea neuronului (iesirea neuronului sau valoarea care o transmite mai departe neuronul) este binară (1 sau 0)
                        </li>
                        <li>
                            conexiunile sunt de 2 tipuri:
                            <ul>
                                <li>
                                    excitatoare (dacă ponderea asociată conexiunii este pozitivă)
                                </li>
                                <li>
                                    inhibatoare (dacă ponderea asociată conexiunii este negativă)
                                </li>
                            </ul>
                        </li>
                        <li>
                            toate ponderiile asociate cu conexiuni excitatoare care intră în neuron sunt egale si pozitive; dacă avem <b>n</b> intrări în neuron atunci:
                                <MathComponent tex={String.raw`w=w_1=w_2=...=w_n`}/>
                        </li>
                        <li>
                            toate ponderiile asociate cu conexiuni inhibatoare care intră în neuron sunt egale si negative; dacă avem <b>m</b> intrări în neuron atunci:
                            <MathComponent tex={String.raw`p=p_{n+1}=p_{p+2}=...=p_{n+m}`}/>
                        </li>
                        <li>
                            ponderiile sunt <b>fixe</b> (nu se ajustează, nu se modifică)
                        </li>
                        <li>
                            <b>pragul</b> (threshold) este fix; dacă <b>intrarea netă</b> (sumarizarea) este mai mare decât pragul setat, atunci neuronul se <i>aprinde</i>
                                <MathComponent tex={String.raw`sum=\sum_{i}^{n} x_i w`}/>
                            <MathComponent
                                tex={String.raw`y = f(x) = \Bigg\{\begin{eqnarray} 1, sum \ge \theta  \\ 0, sum \lt \theta  \end{eqnarray}`}/>
                            putem considera ca f(x) este <b>funcția de activare (funcția pas de activare)</b>
                        </li>
                        <li>
                            pragul este setat astfel încât <b>inhibarea să fie absolută</b>, adică orice intrare inhibitoare non-zero să împiedice <i>aprinderea</i> neuronului;
                        </li>
                    </ul>

                    <i>Observație 1:</i> Intr-o retea cu neuronii McCulloch-Pitts, acestia sunt conectati feedforward (sunt doar conexiuni de la neuronii din layerul curent catre următorul layer)
                    <br/>

                </div>

                <br/>

                <div className={"text-justify"}>

                        <div className={""}>
                            <b>Exemplu:</b><br/>
                            Să presupunem că avem neuronul Y:<br/>
                        </div>

                        <div style={{padding:50}}>
                            <img alt={""} style={{width:250}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/ann_4.png'}/>
                        </div>

                        <div className={""}>
                            Acest neuron are:
                            <ul>
                                <li>
                                    2 conexiuni excitatoare (conexiunea de la X<sub>1</sub> la X și conexiunea de X<sub>2</sub> la X );
                                    ponderile pe aceste conexiuni sunt fixe și egale cu w=2
                                </li>
                                <li>
                                    1 conexiune inhibatoare (conexiunea de la X<sub>3</sub> la X)
                                    ponderea pe această conexiune este egală cu p=-1
                                </li>
                                <li>
                                    pragul pentru neuronul Y egal cu 4 (această valoare este singura care permite ca neuronul Y să se <i>aprindă</i> uneori și să
                                    și prevină această <i>aprindere</i> dacă neuronul primește un semnal diferit de 0 prin intermediul conexiunii inhibatoare)
                                    <br/>
                                    {/*}
                                    neuronul se va <i>aprinde</i> sau activa numai dacă numărul de conexiuni excitatoare (n) pe care primește semnalul 1 este mai mare
                                    decât numărul de conexiuni inhibatoare (p) pe care primește 1, cu un număr care este mai mare decât valoare pragului.
                                    */}
                                </li>
                            </ul>
                        </div>
                </div>
                <br/>

                <div className={"text-justify important"}>
                    <b>Algoritm McCulloch-Pitts</b>
                    <div className={"padding50"}>
                        <i>date de intrare:</i>
                        <br/>
                        <ul>
                            <MathComponent tex={String.raw`X={\{x_1,x_2,...,x_n \},x_i \in \{0,1\} : excitatoare}`}/>
                            <MathComponent tex={String.raw`Z={\{z_1,z_2,...,z_m\}},z_i \in \{0,1\} : inhibatoare`}/>
                            <MathComponent tex={String.raw`w : pondere`}/>
                            <MathComponent tex={String.raw`\theta : prag`}/>
                        </ul>

                        <i>algoritm:</i>
                        <ul>
                            <MathComponent tex={String.raw`sumZ=\sum_{i}^{m} z_i`}/>
                            <i>
                                if (sumZ {">"} 0) return y=0<br/>
                            </i>
                            <MathComponent tex={String.raw`sum=\sum_{i}^{n} x_i w`}/>
                            <MathComponent
                                tex={String.raw`return\space y = \Bigg\{\begin{eqnarray} 1, sum \ge \theta  \\ 0, sum \lt \theta  \end{eqnarray}`}/>
                        </ul>
                        <br/>

                        <i>date de iesire:</i>
                        <ul>
                            <MathComponent tex={String.raw`y`}/>
                            <ul>
                                <li>dacă y = 1 atunci înseamnă ca neuronul s-a activat</li>
                                <li>dacă y = 0 atunci înseamnă ca neuronul nu s-a activat</li>
                            </ul>
                        </ul>
                        <br/>

                    </div>
                </div>

                <br/>

                <div className={"text-justify important"}>
                    Rezumând, caracteristicile parametrilor pentru neuronul McCulloch-Pitts:
                    <table>
                        <tr>
                            <th>
                                Parametru
                            </th>
                            <th>
                                Variabila
                            </th>
                            <th>
                                Tip
                            </th>
                        </tr>
                        <tr>
                            <td>
                                Date de intrare
                            </td>
                            <td>
                                <MathComponent tex={String.raw`x_i`}/>
                                <MathComponent tex={String.raw`w`}/>
                                <MathComponent tex={String.raw`\theta`}/>
                            </td>
                            <td>
                                Binar (pentru datele de intrare x<sub>i</sub> în neuron) <br/>
                                Real (pentru ponderea w)<br/>
                                Real (pentru pragul &theta;)<br/>
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Iesire
                            </td>
                            <td>
                                <MathComponent tex={String.raw`y`}/>
                            </td>
                            <td>
                                Binar
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Functie de activare
                            </td>
                            <td>
                                <MathComponent tex={String.raw`f()`}/>
                            </td>
                            <td>
                                Functia pas
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Tip antrenare
                            </td>
                            <td>
                                <MathComponent tex={String.raw`-`}/>
                            </td>
                            <td>
                                -
                            </td>
                        </tr>

                        <tr>
                            <td>
                                Rugula de învătare
                            </td>
                            <td>
                                <MathComponent tex={String.raw`-`}/>
                            </td>
                            <td>
                                -
                            </td>
                        </tr>

                    </table>

                </div>

                <br/>
                <br/>

                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ul>
                        <li>
                            Laurene Fausett. 1993. Fundamentals of Neural Networks:Architectures, Algorithms And Applications, Pearson
                        </li>
                        <li>
                            <div>
                                Ivan Nunes da Silva, Danilo Hernane Spatti , Rogerio Andrade Flauzino, Luisa Helena Bartocci Liboni, Silas Franco dos Reis Alves. 2017. Artificial Neural Networks - A Practical Course
                            </div>
                        </li>
                        <li>
                            <a href={"https://www.tutorialspoint.com/artificial_neural_network/index.htm"} target={"_blank"}  rel="noreferrer" >
                                https://www.tutorialspoint.com/artificial_neural_network/index.htm
                            </a>
                        </li>
                    </ul>
                </div>

                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default McCullochPittsContent;