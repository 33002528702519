import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class ChangePortsLiferayContent  extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-change-ports", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a rula mai multe instanțe Liferay pe o singură mașină, trebuie să schimbați porturile pentru:
                    <ul>
                        <li>Tomcat</li>
                        <li>Felix Gogo Shell</li>
                    </ul>

                    <hr/>

                    <b>1. Schimbare porturi Tomcat</b>
                    <br/>
                    <br/>
                    Porturile Tomcat se schimba din fisierul <b>server.xml</b>:
                    <br/>
                    [PROJECT]/bundles/tomcat-9.0.17/conf/server.xml

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:950}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/liferay-change-ports.png'}/>
                    </div>

                    <hr/>

                    <b>2. Schimbare porturi Felix Gogo Shell</b>
                    <br/>
                    <br/>

                    Porturile Tomcat se schimba din fisierul <b>portal-setup-wizard.properties</b>:
                    <br/>
                    [PROIECT]/bundles/portal-setup-wizard.properties
                    <br/>
                    <br/>
                    Trebuie adaugat/modificat urmatorul rand:
                    <br/>
                    <b>module.framework.properties.osgi.console=11312</b>
                    <br/>
                    (portul s-a schimbat de la cel implicit 11311 {"->"} 11312).

                    <br/>
                    <br/>
                    După ce se reporneste Liferay, se poate conecta la <b>Felix Gogo Shell</b> in felul urmator:
                    <br/>
                    <br/>
                    <pre>
                        telnet localhost 11312<br/>
                        Trying 127.0.0.1...<br/>
                        Connected to localhost.<br/>
                        Escape character is '^]'.<br/>
                        ____________________________<br/>
                        Welcome to Apache Felix Gogo<br/>
                        <br/>
                        g! <br/>
                    </pre>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                        <li>
                            <div>
                                <a href={"https://lifedev-solutions.blogspot.com/2019/09/running-multiple-liferay-instances-on.html"}>
                                    Running multiple Liferay instances on single machine
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default ChangePortsLiferayContent;