import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class IRRemoteArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-lcd", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. IR Remote </b>
                    <br/>
                    <br/>

                    Import librarie:
                    <SyntaxHighlighter>
                        {'#include <IRremote.h>'}
                    </SyntaxHighlighter>
                    Aceasta librarie trebuie importata! (din Tools -> Manage Libraries - de la shirriff, z3t0, ArminJo - versiunea 2.6.1)

                    <SyntaxHighlighter>
                        {'#define IR_RECEIVE_PIN 5' +
                            '\n' +
                            'IRrecv irrecv(IR_RECIVE_PIN);\n' +
                            'decode_results results;'}
                    </SyntaxHighlighter>

                    In setup():
                    <SyntaxHighlighter>
                        {'irrecv.enableIRIn()'}
                    </SyntaxHighlighter>

                    In loop():
                    <SyntaxHighlighter>
                        {'if (irrecv.decode(&results)){\n' +
                            '  irrecv.resume();\n' +
                            '  Serial.println(results.value);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Daca se foloseste versiune 3.0.3/4.3.0 a librarie de mai sus, trebuie facute urmatoarele modificari:
                    <br/>
                    In setup():
                    <SyntaxHighlighter>
                        {'IrReceiver.begin(IR_RECEIVE_PIN);'}
                    </SyntaxHighlighter>

                    In loop():
                    <SyntaxHighlighter>
                        {'if (IrReceiver.decode()){\n' +
                            '  IrReceiver.resume();\n' +
                            '  Serial.println(IrReceiver.decodedIRData.decodedRawData, HEX);\n' +
                            '  Serial.println(IrReceiver.decodedIRData.command);\n' +
                            '}'}
                    </SyntaxHighlighter>

                    Modul receptor IR:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/modul-receptor-ir.jpg'}/>
                    </div>

                    Acesta are 3 pini:
                    <ul>
                        <li>cel mai aproape de litera (S) (signal)</li>
                        <li>cel din mijloc (+) e alimentare cu 5V</li>
                        <li>cel mai aproape de semnul (-) e GND</li>
                    </ul>

                    <SyntaxHighlighter>
                        {'#include <IRremote.h>\n' +
                            '\n' +
                            '#include <Wire.h>\n' +
                            '#include <LiquidCrystal_I2C.h>\n' +
                            '\n' +
                            '#define IR_RECEIVE_PIN 8\n' +
                            '\n' +
                            'LiquidCrystal_I2C lcd(0x27, 16, 2);  // set the LCD address to 0x27 for a 16 chars and 2 line display\n' +
                            '\n' +
                            'void setup() {\n' +
                            '  Serial.begin(9600);\n' +
                            '  IrReceiver.begin(IR_RECEIVE_PIN);\n' +
                            '  \n' +
                            '  lcd.init();  // initialize the lcd\n' +
                            '  \n' +
                            '  lcd.backlight();\n' +
                            '  lcd.setCursor(0, 0);\n' +
                            '  lcd.print("START");\n' +
                            '}\n' +
                            '\n' +
                            'void loop() {\n' +
                            '  \n' +
                            '  if (IrReceiver.decode()){\n' +
                            '     IrReceiver.resume();\n' +
                            '     int command = IrReceiver.decodedIRData.command;\n' +
                            '     Serial.print(command);\n' +
                            '     if (command==12){\n' +
                            '        lcd.clear();\n' +
                            '        lcd.setCursor(0, 0);\n' +
                            '        lcd.print("KJ");\n' +
                            '     }\n' +
                            '     if (command==24){\n' +
                            '        lcd.clear();\n' +
                            '        lcd.setCursor(0, 0);\n' +
                            '        lcd.print("AKA");\n' +
                            '     }\n' +
                            '     if (command==94){\n' +
                            '        lcd.clear();\n' +
                            '        lcd.setCursor(0, 0);\n' +
                            '        lcd.print("ANDREEA+ASE=LOVE");\n' +
                            '     }\n' +
                            '      if (command==22){\n' +
                            '        lcd.clear();\n' +
                            '        lcd.setCursor(0, 0);\n' +
                            '        lcd.print("START");\n' +
                            '     }\n' +
                            '  }\n' +
                            '  \n' +
                            '}'}
                    </SyntaxHighlighter>

                    Schema:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_17.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=zACmjwvbils&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=8&ab_channel=ScienceBuddies"}>*/}
                    {/*            PING Ultrasonic Distance Sensor and Arduino (Lesson #8)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=n-gJ00GTsNg&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=9&ab_channel=ScienceBuddies"}>*/}
                    {/*            HC-SR04 Ultrasonic Distance Sensor and Arduino (Lesson #9)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IRRemoteArduinoContent;