import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../docker/IndexContent";

class TravisDockerContent extends BaseContentPage  {

    constructor(props) {
        super(props, "docker-travis", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Setup Tracis CI </b>
                    <br/>
                    <br/>

                    Travis CI este un serviciu de integrare continuă (CI) folosit pentru a construi și testa proiecte software găzduite pe GitHub și Bitbucket.

                    <br/>
                    <br/>
                    Pagina web: <a target={"_blank"} href={"https://www.travis-ci.com/"}>https://www.travis-ci.com/</a>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:650}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_6.png'}/>
                    </div>

                    Apasand pe butonul <b>Start Today</b>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_7.png'}/>
                    </div>

                    Alegem GitHub:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_8.png'}/>
                    </div>
                    Se apasa pe butonul <b>Authorize Travis CI</b>

                    <br/>
                    <br/>
                    Urmatorii pasi:
                    <ul>
                        <li>
                            se primeste un email de confirmare {"=>"} trebuie apasat pe butonul <b>Confirm Your Account</b> din mesajul email-ului:

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_9.png'}/>
                            </div>

                        </li>

                        <li>
                            activare repository GitHub:

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_10.png'}/>
                            </div>
                            Se apasa pe butonul <b>Activate All Repositories Using GitHub Apps</b>

                            <br/>
                            Apoi se pot selecta toate respository-urile sau doar unele dintre ele (voi selecta doar <i>docker-react</i>).
                            <br/>
                            Apoi, se apasa pe butonul <b>Aprove & Install</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_14.png'}/>
                            </div>
                            dupa vom fi redirectati catre:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_15.png'}/>
                            </div>

                        </li>

                        <li>
                            se selecteaza planul:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_11.png'}/>
                            </div>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_16.png'}/>
                            </div>

                            selectam <b>Trial Plan / Free</b>.
                            <br/>
                            se vor completa detalii de contact si detalii de CREDIT CARD.

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_17.png'}/>
                            </div>

                        </li>

                        <li>

                            se adauga un fisier numit <b>.travis.yml</b> in proiectul de pe GitHub:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_12.png'}/>
                            </div>
                        </li>

                        <li>
                            se testeaza, facand build la proiect.
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/docker/img_13.png'}/>
                            </div>
                        </li>

                    </ul>

                    Dashbord-ul de la Travis:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_18.png'}/>
                    </div>

                    Pagina pentru repository-ul <i>docker-react</i>:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_19.png'}/>
                    </div>

                    <hr/>
                    <b>Fisierul .travis.yml</b>
                    <br/>
                    <br/>

                    Conectare la masina virtuala pe care este proiectul:
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'ssh kj@192.168.1.8 -p 22'}
                    </SyntaxHighlighter>
                    Schimbam directorul curent sa ajungem la directorul proiectului:
                    <SyntaxHighlighter>
                        {'~/learn/frontend$ ls\n' +
                            'docker-compose.yml  Dockerfile  Dockerfile.dev  node_modules  package.json  package-lock.json  public  README.md  src'}
                    </SyntaxHighlighter>

                    Creare fisier  <b>.travis.yml</b> cu urmatorul continut (<b>nano .travis.yml</b>):
                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'sudo: required\n' +
                            'language: generic\n' +
                            '\n' +
                            'services:\n' +
                            '  - docker\n' +
                            '\n' +
                            'before_install:\n' +
                            '  - docker build -t iulianbuzdugan/docker-react -f Dockerfile.dev .\n' +
                            '\n' +
                            'script:\n' +
                            '  - docker run -e CI=true iulianbuzdugan/docker-react npm run test\n'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git add .'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git commit -m "added travis file"'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'[master 51b359e] added travis file\n' +
                            ' 1 file changed, 12 insertions(+)\n' +
                            ' create mode 100644 .travis.yml'}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={false} language="cmd" style={androidstudio}>
                        {'git push origin master'}
                    </SyntaxHighlighter>
                    se poate afisa:
                    <SyntaxHighlighter>
                        {'Enumerating objects: 4, done.\n' +
                            'Counting objects: 100% (4/4), done.\n' +
                            'Delta compression using up to 2 threads\n' +
                            'Compressing objects: 100% (3/3), done.\n' +
                            'Writing objects: 100% (3/3), 391 bytes | 391.00 KiB/s, done.\n' +
                            'Total 3 (delta 1), reused 0 (delta 0), pack-reused 0\n' +
                            'remote: Resolving deltas: 100% (1/1), completed with 1 local object.\n' +
                            'To github.com:letyournailsgrownow/docker-react.git\n' +
                            '   abdd4af..51b359e  master -> master'}
                    </SyntaxHighlighter>

                    <hr/>

                    Dupa <b>git push</b>, pagina pentru repository-ul <i>docker-react</i> poate arata asa (in timpul rularii):
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_20.png'}/>
                    </div>
                    Si asa dupa ce s-a finalizat procesul:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_21.png'}/>
                    </div>

                    <hr/>

                    Dupa acest proces, s-au pierdut 20 de credite:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/docker/img_22.png'}/>
                    </div>
                    Practic la fiecare push se mai scad 20 de credite.
                </div>



                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <div>*/}
                    {/*            <a target={"_blank"} href={"https://www.digitalocean.com/community/questions/how-to-fix-docker-got-permission-denied-while-trying-to-connect-to-the-docker-daemon-socket"}>*/}
                    {/*                How to fix docker: Got permission denied while trying to connect to the Docker daemon socket*/}
                    {/*            </a>*/}
                    {/*        </div>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}


                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default TravisDockerContent;