import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class MistralAISpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-mistral-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Mistral AI este o companie franceză specializată în dezvoltarea de modele lingvistice de mari dimensiuni (LLM).
                    În 2024, au lansat două modele inovatoare: Mistral Large 2 și Mistral NeMo.

                    <br/>
                    Mistral AI a adoptat o abordare open-source, facilitând accesul cercetătorilor și dezvoltatorilor la modelele lor.
                    Această strategie, alături de angajamentul față de principiile open-source, a consolidat poziția companiei în industria AI.

                    <br/>
                    Dezvoltat în colaborare cu NVIDIA, Mistral NeMo servește ca înlocuitor pentru sistemele care utilizează Mistral 7B,
                    oferind performanțe îmbunătățite și compatibilitate sporită.
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default MistralAISpringIALlmContent;