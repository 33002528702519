import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function HC91_912MuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-hc91-912";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>


                <b>HC 91 (912)</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_50.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-5/img_51.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_52.png'}/>
                </div>

                Status:
                <ul>
                    <li>nu are RF</li>
                    <li>nu merge tasta 1</li>
                </ul>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default HC91_912MuseumContent;