import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class FormsValidationContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-forms-validation", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    Formularele fac posibilă colectarea de tot felul de informații de la utilizatori în diverse scopuri:
                    <ul>
                        <li>informații de contact</li>
                        <li>feedback de la utilizatori</li>
                        <li>etc</li>
                    </ul>
                    O aplicație poate avea forme simple până la foarte complicate.
                    <b>Liferay Forms</b> face crearea și gestionarea acestor formulare mult mai ușoară decât construirea lor de la zero.

                    <hr/>
                    In cele ce urmeaza, vom arata cum putem adăuga în mod programatic validare personalizată la câmpurile Formular Liferay.

                    <hr/>

                    <b>1. Crearea unui Liferay Form</b>
                    <br/>
                    <br/>
                    Din <b>Content & Data</b> {"->"} <b>Forms</b>:

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/liferay-1.png'}/>
                    </div>

                    Se creaza o forma:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/liferay-2.png'}/>
                    </div>
                    Vom avea 3 capuri:
                    <ul>
                        <li>Nume (eticheta: Nume; nume camp: nume; tip: text)

                            <br/>
                            Setarea etichetei:
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/liferay-3.png'}/>
                            </div>

                            <br/>
                            Setarea nume camp:

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/liferay-4.png'}/>
                            </div>

                        </li>
                        <li>Prenume (eticheta: Prenume; nume camp: prenume; tip: text)</li>
                        <li>Varsta (eticheta: Varsta; nume camp: varsta; tip: numeric)</li>
                    </ul>

                    Apoi se apasa pe butonul de <b>Save</b> si butonul de <b>Publish</b>


                    <hr/>

                    <b>2. Interceptare metodei de randare a unui Liferay Form</b>
                    <br/>
                    <br/>

                    Clasa <b>FormWrapperPortlet</b>:

                    <SyntaxHighlighter showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.dopamina.liferay.vanilla.apps.formwrapper.portlet;\n' +
                        '\n' +
                        'import com.liferay.dynamic.data.mapping.model.DDMStructure;\n' +
                        'import com.liferay.dynamic.data.mapping.model.DDMFormInstance;\n' +
                        'import com.liferay.dynamic.data.mapping.service.DDMFormInstanceLocalService;\n' +
                        'import com.liferay.dynamic.data.mapping.service.DDMStructureLocalService;\n' +
                        'import com.liferay.portal.kernel.dao.orm.DynamicQuery;\n' +
                        'import com.liferay.portal.kernel.dao.orm.PropertyFactoryUtil;\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCPortlet;\n' +
                        'import com.liferay.portal.kernel.util.LocaleUtil;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        'import ro.dopamina.liferay.vanilla.apps.formwrapper.constants.FormWrapperPortletKeys;\n' +
                        '\n' +
                        'import javax.portlet.Portlet;\n' +
                        'import javax.portlet.PortletException;\n' +
                        'import javax.portlet.RenderRequest;\n' +
                        'import javax.portlet.RenderResponse;\n' +
                        'import java.io.IOException;\n' +
                        'import java.util.List;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '\timmediate = true,\n' +
                        '\tproperty = {\n' +
                        '\t\t"com.liferay.portlet.display-category=category.sample",\n' +
                        '\t\t"com.liferay.portlet.header-portlet-css=/css/main.css",\n' +
                        '\t\t"com.liferay.portlet.instanceable=true",\n' +
                        '\t\t"javax.portlet.display-name=FormWrapper",\n' +
                        '\t\t"javax.portlet.init-param.template-path=/",\n' +
                        '\t\t"javax.portlet.init-param.view-template=/view.jsp",\n' +
                        '\t\t"javax.portlet.init-param.add-process-action-success-action=false",\n' +
                        '\t\t"javax.portlet.name=" + FormWrapperPortletKeys.FORM_WRAPPER,\n' +
                        '\t\t"javax.portlet.resource-bundle=content.Language",\n' +
                        '\t\t"javax.portlet.security-role-ref=power-user,user"\n' +
                        '\t},\n' +
                        '\tservice = Portlet.class\n' +
                        ')\n' +
                        'public class FormWrapperPortlet extends MVCPortlet {\n' +
                        '\n' +
                        '\t@Reference\n' +
                        '\tprivate DDMStructureLocalService ddmStructureLocalService;\n' +
                        '\n' +
                        '\t@Reference\n' +
                        '\tprivate DDMFormInstanceLocalService ddmFormInstanceLocalService; // sau DDMFormInstanceLocalServiceUtil\n' +
                        '\n' +
                        '\t@Override\n' +
                        '\tpublic void doView(RenderRequest renderRequest, RenderResponse renderResponse) throws IOException, PortletException {\n' +
                        '\n' +
                        '\t\tString formName = "Prima forma";//\n' +
                        '\n' +
                        '\n' +
                        '\t\t// ia structura (DDMStructure) dupa nume (formName = "Prima forma")\n' +
                        '\t\tDynamicQuery dynamicQuery = ddmStructureLocalService.dynamicQuery();\n' +
                        '\t\tString find="%<Name language-id=\\"" + LocaleUtil.getDefault() + "\\">" + formName + "</Name>%";\n' +
                        '\t\tdynamicQuery.add(PropertyFactoryUtil.forName("name").like(find));\n' +
                        '\t\tList<DDMStructure> ddmStructureList = ddmStructureLocalService.dynamicQuery(dynamicQuery);\n' +
                        '\n' +
                        '\t\tDDMStructure ddmStructure = ddmStructureList.get(0);\n' +
                        '\n' +
                        '\t\t// se obtine DDMFormInstance\n' +
                        '\t\tdynamicQuery = ddmFormInstanceLocalService.dynamicQuery();\n' +
                        '\t\tdynamicQuery.add(PropertyFactoryUtil.forName("structureId").eq(ddmStructure.getStructureId()));\n' +
                        '\t\tList<DDMFormInstance> formInstances = ddmFormInstanceLocalService.dynamicQuery(dynamicQuery);\n' +
                        '\n' +
                        '\t\tDDMFormInstance ddmFormInstance = formInstances.get(0);\n' +
                        '\n' +
                        '\t\trenderRequest.setAttribute("formInstanceId", ddmFormInstance.getFormInstanceId());\n' +
                        '\n' +
                        '\t\t//DDMFormInstanceLocalServiceUtil.fetchDDMFormInstance(ddmFormInstance.getFormInstanceId());\n' +
                        '\n' +
                        '\t\tsuper.doView(renderRequest, renderResponse);\n' +
                        '\n' +
                        '\t}\n' +
                        '\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Clasa <b>FormWrapperPortletKeys</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.dopamina.liferay.vanilla.apps.formwrapper.constants;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianb\n' +
                        ' */\n' +
                        'public class FormWrapperPortletKeys {\n' +
                        '\n' +
                        '\tpublic static final String FORM_WRAPPER =\n' +
                        '\t\t"ro_dopamina_liferay_vanilla_apps_formwrapper_FormWrapperPortlet";\n' +
                        '\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>bnd.bnd</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'Bundle-Name: form-wrapper\n' +
                        'Bundle-SymbolicName: ro.dopamina.liferay.vanilla.apps.formwrapper\n' +
                        'Bundle-Version: 1.0.0\n' +
                        'Export-Package: ro.dopamina.liferay.vanilla.apps.formwrapper.constants\n' +
                        '-liferay-aggregate-resource-bundles: ro.letyournailsgrow.liferay.vanilla.common.i18n\n' +
                        '-noee: true'}
                    </SyntaxHighlighter>

                    Fisierul <b>gradle.build</b>:
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'dependencies {\n' +
                        '\tcompileOnly group: "com.liferay.portal", name: "release.dxp.api"\n' +
                        '\tcssBuilder group: "com.liferay", name: "com.liferay.css.builder", version: "3.0.2"\n' +
                        '\n' +
                        '\tcompileOnly group: "com.liferay", name: "com.liferay.dynamic.data.mapping.form.field.type"\n' +
                        '\t\n' +
                        '}'}
                    </SyntaxHighlighter>

                    Fisierul <b>init.jsp</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ taglib uri="http://java.sun.com/jsp/jstl/core" prefix="c" %>\n' +
                        '\n' +
                        '<%@ taglib uri="http://java.sun.com/portlet_2_0" prefix="portlet" %>\n' +
                        '\n' +
                        '<%@ taglib uri="http://liferay.com/tld/aui" prefix="aui" %>\n' +
                        '<%@ taglib uri="http://liferay.com/tld/portlet" prefix="liferay-portlet" %>\n' +
                        '<%@ taglib uri="http://liferay.com/tld/theme" prefix="liferay-theme" %>\n' +
                        '<%@ taglib uri="http://liferay.com/tld/ui" prefix="liferay-ui" %>\n' +
                        '<%@ taglib uri="http://liferay.com/tld/form" prefix="liferay-form" %>\n' +
                        '\n' +
                        '<%@ page import="com.liferay.portal.kernel.servlet.SessionErrors" %>\n' +
                        '\n' +
                        '<liferay-theme:defineObjects />\n' +
                        '\n' +
                        '<portlet:defineObjects />'}
                    </SyntaxHighlighter>

                    Fisierul <b>view.jsp</b>
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'<%@ include file="/META-INF/resources/init.jsp" %>\n' +
                        '\n' +
                        '<portlet:actionURL name = "/save/form-wrapper" var="saveFormWrapperURL"/>\n' +
                        '\n' +
                        '<aui:form action="${saveFormWrapperURL}" method="post" name="fm">\n' +
                        '\n' +
                        '    <aui:input type="hidden" name="recordId" value="${recordId}" />\n' +
                        '\n' +
                        '    <liferay-form:ddm-form-renderer\n' +
                        '            ddmFormInstanceId="${formInstanceId}"\n' +
                        '            ddmFormInstanceRecordId="${recordId}"\n' +
                        '            showFormBasicInfo="${true}"\n' +
                        '            showSubmitButton="${false}"\n' +
                        '    />\n' +
                        '\n' +
                        '    <aui:button-row>\n' +
                        '        <aui:button type="submit" cssClass="btn btn-primary" id="submit-button" value="Adauga" />\n' +
                        '    </aui:button-row>\n' +
                        '\n' +
                        '</aui:form>'}
                    </SyntaxHighlighter>

                    <hr/>

                    <b>3. Actiunea de salvare a unui Liferay Form</b>
                    <br/>
                    <br/>

                    <b>Observatie</b>
                    <br/>
                    Proprietatea <b>showSubmitButton</b> a tag-ului <b>liferay-form:ddm-form-renderer</b> specifica daca se va afisa butonul de summit implicit sau nu.
                    <br/>
                    Oricum ar fi, se va apela actiunea cu <b>actionURL</b> = <i>/save/form-wrapper</i>.
                    <br/>
                    <br/>
                    Pentru a raspunde la actiunea butonul <i>Adauga</i>, se implementeaza <b>MVC Action Command</b> (<i>SaveFormWrapperActionCommand</i>):
                    <SyntaxHighlighter  showLineNumbers={true} language="java" style={androidstudio}>
                        {'package ro.dopamina.liferay.vanilla.apps.formwrapper.portlet;\n' +
                        '\n' +
                        'import com.liferay.dynamic.data.lists.model.DDLRecord;\n' +
                        'import com.liferay.dynamic.data.mapping.form.values.factory.DDMFormValuesFactory;\n' +
                        'import com.liferay.dynamic.data.mapping.model.DDMForm;\n' +
                        'import com.liferay.dynamic.data.mapping.model.DDMFormInstance;\n' +
                        'import com.liferay.dynamic.data.mapping.model.DDMFormInstanceRecord;\n' +
                        'import com.liferay.dynamic.data.mapping.model.DDMStructure;\n' +
                        'import com.liferay.dynamic.data.mapping.service.DDMFormInstanceLocalService;\n' +
                        'import com.liferay.dynamic.data.mapping.service.DDMFormInstanceRecordService;\n' +
                        'import com.liferay.dynamic.data.mapping.service.DDMStructureLocalService;\n' +
                        'import com.liferay.dynamic.data.mapping.storage.DDMFormValues;\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.BaseMVCActionCommand;\n' +
                        'import com.liferay.portal.kernel.portlet.bridges.mvc.MVCActionCommand;\n' +
                        'import com.liferay.portal.kernel.service.ServiceContext;\n' +
                        'import com.liferay.portal.kernel.service.ServiceContextFactory;\n' +
                        'import com.liferay.portal.kernel.util.ParamUtil;\n' +
                        'import org.osgi.service.component.annotations.Component;\n' +
                        'import org.osgi.service.component.annotations.Reference;\n' +
                        'import ro.dopamina.liferay.vanilla.apps.formwrapper.constants.FormWrapperPortletKeys;\n' +
                        '\n' +
                        'import javax.portlet.ActionRequest;\n' +
                        'import javax.portlet.ActionResponse;\n' +
                        '\n' +
                        '/**\n' +
                        ' * @author iulianbu\n' +
                        ' */\n' +
                        '@Component(\n' +
                        '        immediate = true,\n' +
                        '        property = {\n' +
                        '                "javax.portlet.name=" + FormWrapperPortletKeys.FORM_WRAPPER,\n' +
                        '                "mvc.command.name=/save/form-wrapper"\n' +
                        '        },\n' +
                        '        service = MVCActionCommand.class\n' +
                        ')\n' +
                        'public class SaveFormWrapperActionCommand extends BaseMVCActionCommand {\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private DDMStructureLocalService ddmStructureLocalService;\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private DDMFormValuesFactory ddmFormValuesFactory;\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private DDMFormInstanceLocalService ddmFormInstanceLocalService; // sau DDMFormInstanceLocalServiceUtil\n' +
                        '\n' +
                        '    @Reference\n' +
                        '    private DDMFormInstanceRecordService ddmFormInstanceRecordService;\n' +
                        '\n' +
                        '    @Override\n' +
                        '    protected void doProcessAction(ActionRequest actionRequest, ActionResponse actionResponse) throws Exception {\n' +
                        '\n' +
                        '//        String nume = ParamUtil.get(actionRequest, "nume", "");\n' +
                        '//        String prenume = ParamUtil.get(actionRequest, "prenume", "");\n' +
                        '//        String varsta = ParamUtil.get(actionRequest, "varsta", "");\n' +
                        '\n' +
                        '        long groupId = ParamUtil.getLong(actionRequest, "groupId");\n' +
                        '        long formInstanceId = ParamUtil.getLong(actionRequest, "formInstanceId");\n' +
                        '        long recordId = ParamUtil.getLong(actionRequest, "recordId");\n' +
                        '\n' +
                        '        DDMFormInstance ddmFormInstance = ddmFormInstanceLocalService.getFormInstance(formInstanceId);\n' +
                        '\n' +
                        '        DDMStructure ddmStructure = ddmFormInstance.getStructure();\n' +
                        '\n' +
                        '        DDMForm ddmForm = ddmStructure.getDDMForm();\n' +
                        '\n' +
                        '        DDMFormValues ddmFormValues = ddmFormValuesFactory.create(actionRequest, ddmForm);\n' +
                        '        ServiceContext serviceContext = ServiceContextFactory.getInstance(DDLRecord.class.getName(), actionRequest);\n' +
                        '\n' +
                        '        DDMFormInstanceRecord ddmFormInstanceRecord = null;\n' +
                        '\n' +
                        '        if(recordId != 0){\n' +
                        '            ddmFormInstanceRecord = ddmFormInstanceRecordService.updateFormInstanceRecord(recordId, true, ddmFormValues, serviceContext);\n' +
                        '        } else {\n' +
                        '            ddmFormInstanceRecord = ddmFormInstanceRecordService.addFormInstanceRecord( groupId, ddmFormInstance.getFormInstanceId(), ddmFormValues, serviceContext);\n' +
                        '        }\n' +
                        '\n' +
                        '    }\n' +
                        '\n' +
                        '\n' +
                        '}\n'}
                    </SyntaxHighlighter>
                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.xtivia.com/blog/liferay-forms-field-custom-validation/"}>Liferay Form Field Custom Validation</a>
                            </div>
                            <div>
                                <a href={"https://www.xtivia.com/blog/top-10-features-liferay-forms/"}>Top 10 Features of Liferay Forms</a>
                            </div>
                        </li>

                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://help.liferay.com/hc/en-us/articles/360018159471-MVC-Render-Command"}>*/}
                        {/*            MVC Render Command*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FormsValidationContent;