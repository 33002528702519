import React from "react";
import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class CreateRealmKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-create-realm", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>


                            <b>Creare realm (domeniu)</b>
                            <br/>
                            Un realm/domeniu în Keycloak permite crearea de grupuri izolate de aplicații și utilizatori.
                            <br/>
                            În mod implicit, există un singur domeniu în Keycloak numit <b>master</b>.
                            Acesta este dedicat gestionării Keycloak și <b>nu</b> ar trebui să fie folosit pentru propriile aplicații.
                            <br/>
                            Pentru crearea unui nou realm:
                            <ul>
                                <li>se deschide consola de administrare Keycloak: <b>http://localhost:8180/ro/auth/admin</b></li>
                                <li>se trece cu mouse-ul peste meniul drop-down din colțul din stânga sus, unde scrie <b>Master</b>, apoi faceți clic pe <b>Add realm</b>:
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:450}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-6.png'}/>
                                    </div>
                                </li>
                                <li>se completeaza formularul:
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-7.png'}/>
                                    </div>
                                    si se apasa butonul <b>Create</b>:

                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/keycloak-8.png'}/>
                                    </div>
                                </li>
                            </ul>

                            Pentru Keycloak instalat pe <b>http://localhost:8180</b> si realm-ul cu numele <b>vanilla-realm</b> vom avea urmatorul URL configurare:
                            <br/> <br/>
                            <b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration
                            <br/> <br/>
                            Serviciul de mai sus returneaza urmatorul raspuns in format JSON:
                            <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                {'{\n' +
                                '   "issuer":"http://localhost:8180/auth/realms/vanilla-realm",\n' +
                                '   "authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth",\n' +
                                '   "token_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token",\n' +
                                '   "introspection_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token/introspect",\n' +
                                '   "userinfo_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/userinfo",\n' +
                                '   "end_session_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/logout",\n' +
                                '   "jwks_uri":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/certs",\n' +
                                '   "check_session_iframe":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/login-status-iframe.html",\n' +
                                '   "grant_types_supported":[\n' +
                                '      "authorization_code",\n' +
                                '      "implicit",\n' +
                                '      "refresh_token",\n' +
                                '      "password",\n' +
                                '      "client_credentials",\n' +
                                '      "urn:ietf:params:oauth:grant-type:device_code",\n' +
                                '      "urn:openid:params:grant-type:ciba"\n' +
                                '   ],\n' +
                                '   "response_types_supported":[\n' +
                                '      "code",\n' +
                                '      "none",\n' +
                                '      "id_token",\n' +
                                '      "token",\n' +
                                '      "id_token token",\n' +
                                '      "code id_token",\n' +
                                '      "code token",\n' +
                                '      "code id_token token"\n' +
                                '   ],\n' +
                                '   "subject_types_supported":[\n' +
                                '      "public",\n' +
                                '      "pairwise"\n' +
                                '   ],\n' +
                                '   "id_token_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "id_token_encryption_alg_values_supported":[\n' +
                                '      "RSA-OAEP",\n' +
                                '      "RSA-OAEP-256",\n' +
                                '      "RSA1_5"\n' +
                                '   ],\n' +
                                '   "id_token_encryption_enc_values_supported":[\n' +
                                '      "A256GCM",\n' +
                                '      "A192GCM",\n' +
                                '      "A128GCM",\n' +
                                '      "A128CBC-HS256",\n' +
                                '      "A192CBC-HS384",\n' +
                                '      "A256CBC-HS512"\n' +
                                '   ],\n' +
                                '   "userinfo_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512",\n' +
                                '      "none"\n' +
                                '   ],\n' +
                                '   "request_object_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512",\n' +
                                '      "none"\n' +
                                '   ],\n' +
                                '   "request_object_encryption_alg_values_supported":[\n' +
                                '      "RSA-OAEP",\n' +
                                '      "RSA-OAEP-256",\n' +
                                '      "RSA1_5"\n' +
                                '   ],\n' +
                                '   "request_object_encryption_enc_values_supported":[\n' +
                                '      "A256GCM",\n' +
                                '      "A192GCM",\n' +
                                '      "A128GCM",\n' +
                                '      "A128CBC-HS256",\n' +
                                '      "A192CBC-HS384",\n' +
                                '      "A256CBC-HS512"\n' +
                                '   ],\n' +
                                '   "response_modes_supported":[\n' +
                                '      "query",\n' +
                                '      "fragment",\n' +
                                '      "form_post",\n' +
                                '      "query.jwt",\n' +
                                '      "fragment.jwt",\n' +
                                '      "form_post.jwt",\n' +
                                '      "jwt"\n' +
                                '   ],\n' +
                                '   "registration_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/clients-registrations/openid-connect",\n' +
                                '   "token_endpoint_auth_methods_supported":[\n' +
                                '      "private_key_jwt",\n' +
                                '      "client_secret_basic",\n' +
                                '      "client_secret_post",\n' +
                                '      "tls_client_auth",\n' +
                                '      "client_secret_jwt"\n' +
                                '   ],\n' +
                                '   "token_endpoint_auth_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "introspection_endpoint_auth_methods_supported":[\n' +
                                '      "private_key_jwt",\n' +
                                '      "client_secret_basic",\n' +
                                '      "client_secret_post",\n' +
                                '      "tls_client_auth",\n' +
                                '      "client_secret_jwt"\n' +
                                '   ],\n' +
                                '   "introspection_endpoint_auth_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "authorization_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "authorization_encryption_alg_values_supported":[\n' +
                                '      "RSA-OAEP",\n' +
                                '      "RSA-OAEP-256",\n' +
                                '      "RSA1_5"\n' +
                                '   ],\n' +
                                '   "authorization_encryption_enc_values_supported":[\n' +
                                '      "A256GCM",\n' +
                                '      "A192GCM",\n' +
                                '      "A128GCM",\n' +
                                '      "A128CBC-HS256",\n' +
                                '      "A192CBC-HS384",\n' +
                                '      "A256CBC-HS512"\n' +
                                '   ],\n' +
                                '   "claims_supported":[\n' +
                                '      "aud",\n' +
                                '      "sub",\n' +
                                '      "iss",\n' +
                                '      "auth_time",\n' +
                                '      "name",\n' +
                                '      "given_name",\n' +
                                '      "family_name",\n' +
                                '      "preferred_username",\n' +
                                '      "email",\n' +
                                '      "acr"\n' +
                                '   ],\n' +
                                '   "claim_types_supported":[\n' +
                                '      "normal"\n' +
                                '   ],\n' +
                                '   "claims_parameter_supported":true,\n' +
                                '   "scopes_supported":[\n' +
                                '      "openid",\n' +
                                '      "phone",\n' +
                                '      "profile",\n' +
                                '      "web-origins",\n' +
                                '      "offline_access",\n' +
                                '      "email",\n' +
                                '      "roles",\n' +
                                '      "microprofile-jwt",\n' +
                                '      "address"\n' +
                                '   ],\n' +
                                '   "request_parameter_supported":true,\n' +
                                '   "request_uri_parameter_supported":true,\n' +
                                '   "require_request_uri_registration":true,\n' +
                                '   "code_challenge_methods_supported":[\n' +
                                '      "plain",\n' +
                                '      "S256"\n' +
                                '   ],\n' +
                                '   "tls_client_certificate_bound_access_tokens":true,\n' +
                                '   "revocation_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/revoke",\n' +
                                '   "revocation_endpoint_auth_methods_supported":[\n' +
                                '      "private_key_jwt",\n' +
                                '      "client_secret_basic",\n' +
                                '      "client_secret_post",\n' +
                                '      "tls_client_auth",\n' +
                                '      "client_secret_jwt"\n' +
                                '   ],\n' +
                                '   "revocation_endpoint_auth_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "HS256",\n' +
                                '      "HS512",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "HS384",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "backchannel_logout_supported":true,\n' +
                                '   "backchannel_logout_session_supported":true,\n' +
                                '   "device_authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth/device",\n' +
                                '   "backchannel_token_delivery_modes_supported":[\n' +
                                '      "poll",\n' +
                                '      "ping"\n' +
                                '   ],\n' +
                                '   "backchannel_authentication_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/ciba/auth",\n' +
                                '   "backchannel_authentication_request_signing_alg_values_supported":[\n' +
                                '      "PS384",\n' +
                                '      "ES384",\n' +
                                '      "RS384",\n' +
                                '      "ES256",\n' +
                                '      "RS256",\n' +
                                '      "ES512",\n' +
                                '      "PS256",\n' +
                                '      "PS512",\n' +
                                '      "RS512"\n' +
                                '   ],\n' +
                                '   "require_pushed_authorization_requests":false,\n' +
                                '   "pushed_authorization_request_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/par/request",\n' +
                                '   "mtls_endpoint_aliases":{\n' +
                                '      "token_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token",\n' +
                                '      "revocation_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/revoke",\n' +
                                '      "introspection_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token/introspect",\n' +
                                '      "device_authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth/device",\n' +
                                '      "registration_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/clients-registrations/openid-connect",\n' +
                                '      "userinfo_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/userinfo",\n' +
                                '      "pushed_authorization_request_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/par/request",\n' +
                                '      "backchannel_authentication_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/ext/ciba/auth"\n' +
                                '   }\n' +
                                '}'}
                            </SyntaxHighlighter>

                            Serviciul de mai sus contine toate metadatele de configurare referitoare la protocol, cum ar fi:
                            <ul>
                                <li>emitent (<b>issuer</b>: http://localhost:8180/auth/realms/vanilla-realm)</li>
                                <li>URL token (<b>token_endpoint</b>: http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token)</li>
                                <li>URL de autorizare (<b>authorization_endpoint</b>: http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth)</li>
                            </ul>

                            Aceste informații vor fi utile pentru activitățile de configurare ulterioare, atât pe Keycloak, cât și pe Liferay.
                            <br/><br/>
                            <b>Observatie:</b>
                            <br/>
                            Adresa URL pe care Liferay o va folosi pentru a obține metadatele configurației (<b>http://localhost:8180/auth/</b>realms/<b>vanilla-realm</b>/.well-known/openid-configuration)
                            &nbsp; este important să fie <b>accesibilă</b> și ca <b>configurația certificatului SSL/TLS să fie corectă</b>.



                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>


            </div>
        );
    }
}

export default CreateRealmKeycloakContent;