import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../grafana/IndexContent";

class DashboardsGrafanaContent extends BaseContentPage  {

    constructor(props) {
        super(props, "grafana-dashboards", IndexContent);
    }


    render() {

        // let path = ' D:\\tools\\GrafanaLabs\\grafana\\public\\locales\\en-US\\grafana.json'; // PATH OF YOUR JSON FILE (includes file name)
        //
        // translator.translateFile(
        //     path,
        //     translator.languages.English,
        //     translator.languages.Romanian
        // );

        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Grafana - Dashboards</b>
                    <br/>
                    <br/>

                    <b>1. Creare Dashboard</b>
                    <br/>
                    <br/>

                    Creare dashboard, din ecranul principal:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_6.png'}/>
                    </div>

                    Sau: Home ~ Dashboards:
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_7.png'}/>
                    </div>

                    Se apasa pe New ~ New Dashboard
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_8.png'}/>
                    </div>

                    <hr/>
                    <b>2. Configurare</b>
                    <br/>
                    <br/>

                    Apoi, se apasa pe butonul <b>+ Add visualization</b>
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_9.png'}/>
                    </div>

                    Intai se selecteaza data source: PostgreSQL!
                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_10.png'}/>
                    </div>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/grafana/img_11.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default DashboardsGrafanaContent;