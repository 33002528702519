import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function CobraMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-cobra";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                CoBra este primul şi, până acum, singurul calculator românesc produs la Braşov. Prototipul calculatorului a fost gata după un an de cercetări, în 1986.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_109.png'}/>
                </div>
                (sursa: <a href={"https://ro.wikipedia.org/wiki/CoBra"} target={"_blank"}>https://ro.wikipedia.org/wiki/CoBra</a> )
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        Producator: I.T.C.I. Braşov
                    </li>
                    <li>
                        An lansare: 1986
                    </li>
                    <li>
                        Scos din fabricaţie: 1990
                    </li>
                    <li>
                        Procesor: Z80
                    </li>
                    <li>
                        Variante:
                        <ul>
                            <li>CoBra cu carcasă neagră</li>
                        </ul>
                        <li>
                            CoBra cu carcasă albă
                        </li>
                    </li>
                </ul>

                <hr/>
                <b>Fabricate clandestin</b>
                <br/>
                <br/>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_36.png'}/>
                </div>

            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default CobraMuseumContent;