import {useRoutes} from "react-router-dom";
import IndexContent from "./IndexContent";
import React from "react";
import PrivateRoute from "../../security/PrivateRoute";
import UtilLlmContent from "./UtilLlmContent";
import OllamaRi5LlmContent from "./OllamaRi5LlmContent";
import IntroLlmContent from "./IntroLlmContent";
import WordEmbeddingLlmContent from "./WordEmbeddingLlmContent";
import OllamaWindowsLlmContent from "./OllamaWindowsLlmContent";
import SpringIALlmContent from "./SpringIALlmContent";
import AdvisorsSpringIALlmContent from "./AdvisorsSpringIALlmContent";
import ChatOptionsSpringIALlmContent from "./ChatOptionsSpringIALlmContent";
import CreateProjectSpringIALlmContent from "./CreateProjectSpringIALlmContent";
import OpenaiApiKeySpringIALlmContent from "./OpenaiApiKeySpringIALlmContent";
import FormatSpringIALlmContent from "./FormatSpringIALlmContent";
import OllamaSpringIALlmContent from "./OllamaSpringIALlmContent";
import MessageSpringIALlmContent from "./MessageSpringIALlmContent";
import StructuredOutputSpringIALlmContent from "./StructuredOutputSpringIALlmContent";
import RAGSpringIALlmContent from "./RAGSpringIALlmContent";
import SpeechToTextSpringIALlmContent from "./SpeechToTextSpringIALlmContent";
import TextToSpeechSpringIALlmContent from "./TextToSpeechSpringIALlmContent";
import ImageGenerateSpringIALlmContent from "./ImageGenerateSpringIALlmContent";
import VisionSpringIALlmContent from "./VisionSpringIALlmContent";
import FunctionsSpringIALlmContent from "./FunctionsSpringIALlmContent";
import ModerationsSpringIALlmContent from "./ModerationsSpringIALlmContent";
import PromptEngineeringSpringIALlmContent from "./PromptEngineeringSpringIALlmContent";
import VectorStoreSpringIALlmContent from "./VectorStoreSpringIALlmContent";
import MemorySpringIALlmContent from "./MemorySpringIALlmContent";
import MistralAISpringIALlmContent from "./MistralAISpringIALlmContent";
import AmazonBedrockISpringIALlmContent from "./AmazonBedrockISpringIALlmContent";
import AzureOpenIASpringIALlmContent from "./AzureOpenIASpringIALlmContent";
import GoogleVertexGeminiSpringIALlmContent from "./GoogleVertexGeminiSpringIALlmContent";
import AssemblyAISpringIALlmContent from "./AssemblyAISpringIALlmContent";
import ChromaDBSpringIALlmContent from "./ChromaDBSpringIALlmContent";
import MilvusSpringIALlmContent from "./MilvusSpringIALlmContent";
import RedisSpringIALlmContent from "./RedisSpringIALlmContent";
import MongoSpringIALlmContent from "./MongoSpringIALlmContent";


export default function Router() {
    let element = useRoutes([
        {path: "/llm/index", element: <IndexContent/>},
        {path: "/llm/intro", element: <PrivateRoute element={<IntroLlmContent/>}/>},
        {path: "/llm/ollama-pi5", element: <PrivateRoute element={<OllamaRi5LlmContent/>}/>},
        {path: "/llm/ollama-windows", element: <PrivateRoute element={<OllamaWindowsLlmContent/>}/>},
        {path: "/llm/create-project-spring-ia", element: <PrivateRoute element={<CreateProjectSpringIALlmContent/>}/>},
        {path: "/llm/spring-ia", element: <PrivateRoute element={<SpringIALlmContent/>}/>},
        {path: "/llm/advisors-spring-ia", element: <PrivateRoute element={<AdvisorsSpringIALlmContent/>}/>},
        {path: "/llm/chat-options-spring-ia", element: <PrivateRoute element={<ChatOptionsSpringIALlmContent/>}/>},
        {path: "/llm/util", element: <PrivateRoute element={<UtilLlmContent/>}/>},
        {path: "/llm/word-embedding", element: <PrivateRoute element={<WordEmbeddingLlmContent/>}/>},

        {path: "/llm/openai-api-key-spring-ia", element: <PrivateRoute element={<OpenaiApiKeySpringIALlmContent/>}/>},
        {path: "/llm/format-spring-ia", element: <PrivateRoute element={<FormatSpringIALlmContent/>}/>},
        {path: "/llm/ollama-spring-ia", element: <PrivateRoute element={<OllamaSpringIALlmContent/>}/>},
        {path: "/llm/message-spring-ia", element: <PrivateRoute element={<MessageSpringIALlmContent/>}/>},
        {path: "/llm/structured-output-spring-ia", element: <PrivateRoute element={<StructuredOutputSpringIALlmContent/>}/>},
        {path: "/llm/rag-spring-ia", element: <PrivateRoute element={<RAGSpringIALlmContent/>}/>},
        {path: "/llm/stt-spring-ia", element: <PrivateRoute element={<SpeechToTextSpringIALlmContent/>}/>},
        {path: "/llm/tts-spring-ia", element: <PrivateRoute element={<TextToSpeechSpringIALlmContent/>}/>},
        {path: "/llm/image-spring-ia", element: <PrivateRoute element={<ImageGenerateSpringIALlmContent/>}/>},
        {path: "/llm/vision-spring-ia", element: <PrivateRoute element={<VisionSpringIALlmContent/>}/>},
        {path: "/llm/functions-spring-ia", element: <PrivateRoute element={<FunctionsSpringIALlmContent/>}/>},
        {path: "/llm/moderations-spring-ia", element: <PrivateRoute element={<ModerationsSpringIALlmContent/>}/>},

        {path: "/llm/prompt-engineer-spring-ia", element: <PrivateRoute element={<PromptEngineeringSpringIALlmContent/>}/>},
        {path: "/llm/vector-store-spring-ia", element: <PrivateRoute element={<VectorStoreSpringIALlmContent/>}/>},

        {path: "/llm/memory-spring-ia", element: <PrivateRoute element={<MemorySpringIALlmContent/>}/>},
        {path: "/llm/mistral-spring-ia", element: <PrivateRoute element={<MistralAISpringIALlmContent/>}/>},
        {path: "/llm/amazon-spring-ia", element: <PrivateRoute element={<AmazonBedrockISpringIALlmContent/>}/>},
        {path: "/llm/azure-spring-ia", element: <PrivateRoute element={<AzureOpenIASpringIALlmContent/>}/>},
        {path: "/llm/google-spring-ia", element: <PrivateRoute element={<GoogleVertexGeminiSpringIALlmContent/>}/>},
        {path: "/llm/assemblyai-spring-ia", element: <PrivateRoute element={<AssemblyAISpringIALlmContent/>}/>},

        {path: "/llm/chroma-db-spring-ia", element: <PrivateRoute element={<ChromaDBSpringIALlmContent/>}/>},
        {path: "/llm/milvus-spring-ia", element: <PrivateRoute element={<MilvusSpringIALlmContent/>}/>},
        {path: "/llm/redis-spring-ia", element: <PrivateRoute element={<RedisSpringIALlmContent/>}/>},
        {path: "/llm/mongo-spring-ia", element: <PrivateRoute element={<MongoSpringIALlmContent/>}/>},

    ]);

    return element;
}