import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaCustomStructuresSettingsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-structures", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare structura</b>
                    <br/>
                    <br/>

                    <b>Content & Data</b> ~ <b>Web Content</b> ~ tab <b>Structures</b> ~ New

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_1.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_2.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_3.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_3_1.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_4.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_5.png'}/>
                    </div>

                    <hr/>
                    <b>2. Creare templata</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_6.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 1050}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_7.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 1050}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_8.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_9.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_10.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_11.png'}/>
                    </div>


                    <hr/>
                    <b>3. Creare Display Page Templates</b>
                    <br/>
                    <br/>

                    Design ~ Page Templates ~ Display Page Templates

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_12.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_13.png'}/>
                    </div>

                    La Subtype, se alege structura creata mai sus (carte):
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_14.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_15.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_16.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_17a.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_17.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_18.png'}/>
                    </div>

                    Dupa ce termina editarea, se apasa pe butonul Publish:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_19.png'}/>
                    </div>

                    Pentru pot exista mai multe Display Page Templates pentru Web content Article - carte trebuie ales unul implicit:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_20.png'}/>
                    </div>

                    Web content Article - carte (carte display page content)
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_21.png'}/>
                    </div>

                    <hr/>
                    <b>4. Creare continut</b>
                    <br/>
                    <br/>

                    Se creaza un folder:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_22.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_23.png'}/>
                    </div>

                    Se intra in folderul Biblioteca
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_24.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_25.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_26.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_28.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_29.png'}/>
                    </div>


                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_27.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_30.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_31.png'}/>
                    </div>


                    <hr/>
                    <b>5. Creare colectie</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_32.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_33.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_34.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_35.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_36.png'}/>
                    </div>

                    <hr/>
                    <b>6. Creare pagina</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_37.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_38a.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_39.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_40.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_41.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_42.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_43.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_44.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_45.png'}/>
                    </div>

                    Adaugand mai multe carti:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_46.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_47.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_48.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_49.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_50.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_51.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_52.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_53.png'}/>
                    </div>

                    Pe click pe link:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_54.png'}/>
                    </div>

                    Se poate adauga cautare:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_55.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_56.png'}/>
                    </div>


                    <hr/>
                    <b>7. Asset Publisher</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_57.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_58.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_59.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_60.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_61.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_62.png'}/>
                    </div>

                    Se da Save
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_63.png'}/>
                    </div>

                    {/*<div style={{padding: 10}}>*/}
                    {/*    <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}*/}
                    {/*         src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_64.png'}/>*/}
                    {/*</div>*/}

                    {/*<div style={{padding: 10}}>*/}
                    {/*    <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}*/}
                    {/*         src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_65.png'}/>*/}
                    {/*</div>*/}

                    {/*<div style={{padding: 10}}>*/}
                    {/*    <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}*/}
                    {/*         src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_66.png'}/>*/}
                    {/*</div>*/}

                    Creare templata:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_67.png'}/>
                    </div>


                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_68.png'}/>
                    </div>

                    Aplicare templata:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_69.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_70.png'}/>
                    </div>

                    <hr/>
                    <b>8. Page template</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_71.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_72.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_73.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_74.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_75.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_76.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_77.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_78.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_79.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_80.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_81.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_82.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 850}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/str_83.png'}/>
                    </div>

                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaCustomStructuresSettingsLiferayContent;