import React, { useState } from "react";

const ImageDialog = ({imagePath}) => {
    const [isOpen, setIsOpen] = useState(false);
    const [imageUrl, setImageUrl] = useState("");

    const handleButtonClick = (url) => {
        setImageUrl(url);
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
        setImageUrl("");
    };

    return (
        <div>
            <button className={"btn btn-link"} data-url={process.env.PUBLIC_URL + '/img/CARTE/'+imagePath} onClick={(e) => handleButtonClick(e.target.getAttribute("data-url"))}>
                vezi coperta
            </button>

            {isOpen && (
                <div
                    style={{
                        position: "fixed",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        backgroundColor: "rgba(0, 0, 0, 0.5)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        zIndex: 1000,
                    }}
                >
                    <div
                        style={{
                            backgroundColor: "white",
                            padding: "20px",
                            borderRadius: "8px",
                            textAlign: "center",
                            position: "relative",
                        }}
                    >
                        <img
                            src={imageUrl}
                            alt="Preview"
                            style={{ maxWidth: "100%", maxHeight: "80vh" }}
                        />
                        <button
                            onClick={handleClose}
                            style={{
                                position: "absolute",
                                top: "10px",
                                right: "10px",
                                background: "red",
                                color: "white",
                                border: "none",
                                borderRadius: "50%",
                                width: "30px",
                                height: "30px",
                                cursor: "pointer",
                            }}
                        >
                            X
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ImageDialog;