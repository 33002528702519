import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AddingCustomPasswordReminderQuestionsLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-adding-custom-password-reminder-questions", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                <div className={"text-justify important"}>

                    <b>Adaugare intrebari personalizate pentru recuperare parola</b>
                    <SyntaxHighlighter>
                        {'   #\n' +
                            '    # Set this to true to enable reminder queries that are used to help reset a\n' +
                            '    # user\'s password.\n' +
                            '    #\n' +
                            '    users.reminder.queries.enabled=true\n' +
                            '    users.reminder.queries.custom.question.enabled=true\n' +
                            '\n' +
                            '    #\n' +
                            '    # Set this to false to enable users without a reminder query to reset their\n' +
                            '    # password.\n' +
                            '    #\n' +
                            '    users.reminder.queries.required=false\n' +
                            '\n' +
                            '    #\n' +
                            '    # Input a list of questions used for reminder queries.\n' +
                            '    #\n' +
                            '    users.reminder.queries.questions=what-is-your-primary-frequent-flyer-number,what-is-your-library-card-number,what-was-your-first-phone-number,what-was-your-first-teacher\'s-name,what-is-your-father\'s-middle-name\n'}
                    </SyntaxHighlighter>

                    Puteți adăuga întrebări de securitate personalizate, astfel:
                    <ul>
                        <li>În timpul primei conectări
                            <ul>
                                <li>
                                    Conectare cu acreditările utilizatorului
                                </li>
                                <li>
                                    În secțiunea Întrebare a paginii Memento de parolă, selectați Scrieți propria întrebare
                                    Sub sectiunea <b>Question</b> a paginii <b>Password Reminder</b>, se selecteaza <b>Write my own question</b>
                                </li>
                                <li>
                                    Se introduce intrebarea personalizata de securitate
                                </li>
                                <li>
                                    Salvare
                                </li>
                            </ul>
                        </li>
                        <li>
                            În timpul conectărilor ulterioare:
                            <ul>
                                <li>
                                   Din meniul utilizatorului: <b>Account Settings</b> -> <b>Password</b>
                                </li>
                                <li>
                                    Sub <b>REMINDER</b> -> <b>Question</b> -> se selecteaza <b>Write my own question</b>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/c_11.png'}/>
                                    </div>
                                </li>
                                <li>
                                    Se introduce intrebarea personalizata de securitate
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/liferay/c_12.png'}/>
                                    </div>
                                </li>
                                <li>
                                    Salvare
                                </li>
                            </ul>



                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>


                        {/*<li>*/}
                        {/*    <div>*/}
                        {/*        <a href={"https://liferayiseasy.blogspot.com/2022/06/how-to-enable-jquery-in-liferay-dxp-73.html"}>*/}
                        {/*            How to enable jQuery in Liferay DXP (7.3)*/}
                        {/*        </a>*/}
                        {/*    </div>*/}
                        {/*</li>*/}

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AddingCustomPasswordReminderQuestionsLiferayContent;