import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class GenerateTokenKeycloakContent extends BaseContentPage {

    constructor(props) {
        super(props, "keycloak-generate-token", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Pentru a genera un token:
                    <ul>
                        <li>
                            se deschide consola de administrare Keycloak:
                            <a href={"http://localhost:8180/auth/admin"}>http://localhost:8180/auth/admin</a>
                        </li>
                        <li>
                            se apasa pe <b>Realm Settings</b> pentru <b>vanilla-realm</b>, apoi din tabul <b>General</b> apasam pe link-ul <b>OpenID Endpoint Configuration</b> din dreptul campului <b>Endpoints</b>
                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l1.png'}/>
                            </div>
                            acest lucru va apela serviciul:
                            <br/>
                            <a href={"http://localhost:8180/auth/realms/vanilla-realm/.well-known/openid-configuration"}>
                                <b>http://localhost:8180/auth/realms/vanilla-realm/.well-known/openid-configuration</b>
                            </a>
                            <br/>
                            care va returna sub forma de JSON <b>informatii de configurare</b>:
                            <br/>
                            un fragment din raspunsul JSON:
                            <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                {'{\n' +
                                '   "issuer":"http://localhost:8180/auth/realms/vanilla-realm",\n' +
                                '   "authorization_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/auth",\n' +
                                '   "token_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token",\n' +
                                '   "introspection_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token/introspect",\n' +
                                '   "userinfo_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/userinfo",\n' +
                                '   "end_session_endpoint":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/logout",\n' +
                                '   "jwks_uri":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/certs",\n' +
                                '   "check_session_iframe":"http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/login-status-iframe.html",\n' +
                                '   .....\n' +
                                '}'}
                            </SyntaxHighlighter>
                            partea care ne intereseaza acum este <b>token_endpoint</b>:
                            <br/>
                            <b>http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token</b>
                            <br/>
                            <hr/>
                            Din <b>Postman</b> cream o cerere noua <b>Add request</b>. Setam:
                            <ul>
                                <li>metoda: POST</li>
                                <li>URL: http://localhost:8180/auth/realms/vanilla-realm/protocol/openid-connect/token</li>
                                <li>la <b>Body</b> selectam <b>x-www-form-urlencoded</b>  si punem urmatorii parametrii:
                                    <ul>
                                        <li>grant_type:password</li>
                                        <li>client_id:liferay-portal-client</li>
                                        <li>client_secret:0c778028-f03e-4bdd-b835-8ecb33653ea0 (se gaseste in Keycloak, meniu <b>Clients</b>, tab <b>Credentials</b>, camp <b>Secret</b>)</li>
                                        <li>username:test@vanilla.ro</li>
                                        <li>password:test</li>
                                    </ul>
                                    <div style={{padding:10}}>
                                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                             src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l2.png'}/>
                                    </div>

                                    Raspunsul:
                                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                        {'{\n' +
                                        '    "access_token": "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICJJbHJ5ZFN6clRmWFlYV2c4TVZfS2FiWlVtN3FKUVU0bHJNeE9FdWdvRm9JIn0.eyJleHAiOjE2MzY0MDc0MTgsImlhdCI6MTYzNjQwNzExOCwianRpIjoiYTAxZjdkZTYtODgxOC00MGQ0LTgxYjgtMDhjMjFiOTE0NTdmIiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MTgwL2F1dGgvcmVhbG1zL3ZhbmlsbGEtcmVhbG0iLCJhdWQiOiJhY2NvdW50Iiwic3ViIjoiM2UxZmJjMzUtNGM0MC00MTJlLThmZTUtMzI3MzdmMmZhOWFmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoibGlmZXJheS1wb3J0YWwtY2xpZW50Iiwic2Vzc2lvbl9zdGF0ZSI6IjMxZmNlYTA5LTg3ZTUtNDgwMS04YmViLWVmMDE5ZTI4NDgyZiIsImFjciI6IjEiLCJyZWFsbV9hY2Nlc3MiOnsicm9sZXMiOlsib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiIsImRlZmF1bHQtcm9sZXMtdmFuaWxsYS1yZWFsbSJdfSwicmVzb3VyY2VfYWNjZXNzIjp7ImxpZmVyYXktcG9ydGFsLWNsaWVudCI6eyJyb2xlcyI6WyJhZG1pbmlzdHJhdG9yIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiIzMWZjZWEwOS04N2U1LTQ4MDEtOGJlYi1lZjAxOWUyODQ4MmYiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwibmFtZSI6InZhbmlsbGEgdmFuaWxsYSIsInByZWZlcnJlZF91c2VybmFtZSI6InZhbmlsbGEiLCJnaXZlbl9uYW1lIjoidmFuaWxsYSIsImZhbWlseV9uYW1lIjoidmFuaWxsYSIsImVtYWlsIjoidGVzdEB2YW5pbGxhLnJvIn0.SV7OzZbdE80d6ATgwi9O_Wy7gqBQ51NRI06OPjkR8EhLHd7iwaCiks08oWmud160lF4UtsDFrKhUNairwmj7hJiv-TnwKpeTyHPLyT6h-ToJLsQuT9XRjmY7j53aO2yTo94g_E5DCy_78M8L6E6I_RPzxoI9QVNka8GdyrpkUsQaEpd9v7aSnGko-kK_-D9b1yq9KCFwBIRdgoqVPlh14nAJL-svW44-_SSP1POT9igCYdHKU2PoJqol8jMT7bPHvDyX_iWMbfS7RvjO-KZN1fneLZsnUBIJ7AWyzJfr8ZevdCa6gGqUjqi15RXQwoA5555lUjzDVSBlp8JGsp6jiw",\n' +
                                        '    "expires_in": 300,\n' +
                                        '    "refresh_expires_in": 1800,\n' +
                                        '    "refresh_token": "eyJhbGciOiJIUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI0MmMwNzgxMC0xN2VjLTRjN2MtYjhlYi0zZjhlNTIwZDIzZmEifQ.eyJleHAiOjE2MzY0MDg5MTgsImlhdCI6MTYzNjQwNzExOCwianRpIjoiODZkNzhlMzAtZjg5Zi00MDM3LWFkZTUtMDhjNzkwNTAyNTk3IiwiaXNzIjoiaHR0cDovL2xvY2FsaG9zdDo4MTgwL2F1dGgvcmVhbG1zL3ZhbmlsbGEtcmVhbG0iLCJhdWQiOiJodHRwOi8vbG9jYWxob3N0OjgxODAvYXV0aC9yZWFsbXMvdmFuaWxsYS1yZWFsbSIsInN1YiI6IjNlMWZiYzM1LTRjNDAtNDEyZS04ZmU1LTMyNzM3ZjJmYTlhZiIsInR5cCI6IlJlZnJlc2giLCJhenAiOiJsaWZlcmF5LXBvcnRhbC1jbGllbnQiLCJzZXNzaW9uX3N0YXRlIjoiMzFmY2VhMDktODdlNS00ODAxLThiZWItZWYwMTllMjg0ODJmIiwic2NvcGUiOiJwcm9maWxlIGVtYWlsIiwic2lkIjoiMzFmY2VhMDktODdlNS00ODAxLThiZWItZWYwMTllMjg0ODJmIn0.WphDT6TgOWSohG_fnuQhdhY3p6HXgi-vLLLuggdPA1U",\n' +
                                        '    "token_type": "Bearer",\n' +
                                        '    "not-before-policy": 1636212387,\n' +
                                        '    "session_state": "31fcea09-87e5-4801-8beb-ef019e28482f",\n' +
                                        '    "scope": "profile email"\n' +
                                        '}'}
                                    </SyntaxHighlighter>
                                </li>

                            </ul>

                            <hr/>
                            Daca avem, curiozitatea, putem decoda access_token-ul JWT obtinut mai sus, folosind site-ul:
                            <br/>
                            <a href={"https://jwt.io"}>https://jwt.io</a>
                            <br/>

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l3.png'}/>
                            </div>

                            Deci, <b>access_token</b> include detalii de permisiune:
                            <ul>
                                <li>roluri de realm: <b>realm_access.roles
                                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                        {'"realm_access": {\n' +
                                        '    "roles": [\n' +
                                        '      "offline_access",\n' +
                                        '      "uma_authorization",\n' +
                                        '      "default-roles-vanilla-realm"\n' +
                                        '    ]\n' +
                                        '  },'}
                                    </SyntaxHighlighter>
                                </b>
                                </li>
                                <li>roluri de client: <b>resource_access.liferay-portal-client.roles</b>
                                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                        {'"resource_access": {\n' +
                                        '    "liferay-portal-client": {\n' +
                                        '      "roles": [\n' +
                                        '        "administrator"\n' +
                                        '      ]\n' +
                                        '    }'}
                                    </SyntaxHighlighter>
                                </li>
                                <li>username:

                                    <SyntaxHighlighter showLineNumbers={true} language="json" style={androidstudio}>
                                        {'"preferred_username": "vanilla",'}
                                    </SyntaxHighlighter>

                                </li>

                                <li>
                                    <b>iat</b> : ora de generare a token-ului
                                </li>

                                <li>
                                    <b>exp</b> : ora cand expira token-ul
                                </li>

                            </ul>

                            <br/>
                            <b>Observatie:</b>
                            <br/>
                            Perioadele de expirare a jetoanelor de acces (access token) pot fi personalizabile în:
                            <b>Realm Settings</b> {"=>"} tab <b>Tokens</b>
                            <br/>
                            În mod implicit, <b> Access Token Lifespan</b> durata de viață a jetonului de acces este de 5 minute, dar poate fi personalizată în funcție de cerințele dvs. de securitate.

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/keycloak/keycloak-l4.png'}/>
                            </div>

                        </li>
                    </ul>

                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                        <li>
                            <div>
                                <a href={"https://www.keycloak.org/getting-started/getting-started-zip"}>
                                    Keycloak on OpenJDK
                                </a>
                            </div>
                        </li>

                        <li>
                            <div>
                                <a href={"https://medium.com/devops-dudes/securing-spring-boot-rest-apis-with-keycloak-1d760b2004e"}>
                                    Securing Spring Boot REST APIs with Keycloak
                                </a>
                            </div>
                        </li>
                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default GenerateTokenKeycloakContent;