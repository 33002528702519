import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class IntroductionSiteInitializers14LiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-intro-site-initializers-13", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Configurare instanta (URL acasa, URL delogare, Pagina implicita)</b>
                    <br/>
                    <br/>

                    <div style={{padding:10}}>
                        <img alt={""} style={{width:750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/cc22_si.png'}/>
                    </div>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/introducing-site-initializers"}>*/}
                    {/*            Introducing Site Initializers*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"}href={"https://liferay.dev/de/blogs/-/blogs/site-initializers-update-support"}>*/}
                    {/*            Site Initializers: Update Support*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://liferay.dev/de/blogs/-/blogs/embedding-portlets-and-setting-preferences-in-a-liferay-7-2-theme/maximized"}>*/}
                    {/*            Embedding portlets and setting preferences in a Liferay 7.2 theme*/}
                    {/*        </a>*/}
                    {/*    </li>*/}

                    {/*</ol>*/}
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default IntroductionSiteInitializers14LiferayContent;