import React from "react";

import SyntaxHighlighter from 'react-syntax-highlighter';
import {androidstudio} from 'react-syntax-highlighter/dist/esm/styles/hljs';
import BaseContentPage from "../BaseContentPage";
import IndexContent from "./IndexContent";

class AnexaCreateProjectLiferayContent extends BaseContentPage {

    constructor(props) {
        super(props, "liferay-anexa-create-project", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>1. Creare proiect Liferay</b>
                    <br/>
                    <br/>

                    <b>File ~ New ~ Project</b>:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/1.png'}/>
                    </div>

                    Se selecteaza: <b>Liferay ~ Liferay Gradle Workspace</b>
                    <br/>
                    Se apasa <b>Next</b>:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 550}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/2.png'}/>
                    </div>

                    Se apasa pe butonul <b>Create</b>.

                    <br/>
                    <br/>
                    Se va genera urmatoarea structura de proiect:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/3.png'}/>
                    </div>

                    <hr/>
                    <b>2. Modificare gradle.properties</b>
                    <br/>
                    <br/>

                    In <b>gradle.properties</b> se adauga liniile:
                    <ul>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'org.gradle.jvmargs=-Xmx2g -Dfile.encoding=UTF-8'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'liferay.workspace.environment = local'}
                            </SyntaxHighlighter>
                            Linia de mai sus, face ca fisierul <b>portal-ext.properties</b> din <b>\configs\local\portal-ext.properties</b> sa fie copiat
                            in <b>bundles\portal-ext.properties</b>

                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/4.png'}/>
                            </div>
                        </li>
                        <li>
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'liferay.workspace.bundle.url=https://releases-cdn.liferay.com/portal/7.4.3.120-ga120/liferay-portal-tomcat-7.4.3.120-ga120-1718225443.7z'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>

                    <hr/>
                    <b>3. Initializare workspace (init bundle)</b>
                    <ul>
                        <li>
                            cu Liferay Plugin:
                            <div style={{padding: 10}}>
                                <img alt={""} style={{width: 450}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/liferay/agnes/5.png'}/>
                            </div>
                        </li>
                        <li>
                            cu Gradle:
                            <ul>
                                <li>Gradle ~ liferay-agnes ~ Tasks ~ <b>bundle</b> ~ <b>initBundle</b></li>
                            </ul>

                            va afisa:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'19:42:14: Executing \'initBundle\'...\n' +
                                    '\n' +
                                    'The liferay.workspace.bundle.url property is currently overriding the default value managed by the liferay.workspace.product setting.\n' +
                                    '> Task :verifyProduct\n' +
                                    '\n' +
                                    '> Task :downloadBundle\n' +
                                    'Download https://releases-cdn.liferay.com/portal/7.4.3.120-ga120/liferay-portal-tomcat-7.4.3.120-ga120-1718225443.7z\n' +
                                    '\n' +
                                    '> Task :verifyBundle SKIPPED\n' +
                                    '> Task :initBundle\n' +
                                    '\n' +
                                    'Deprecated Gradle features were used in this build, making it incompatible with Gradle 9.0.\n' +
                                    '\n' +
                                    'You can use \'--warning-mode all\' to show the individual deprecation warnings and determine if they come from your own scripts or plugins.\n' +
                                    '\n' +
                                    'For more on this, please refer to https://docs.gradle.org/8.5/userguide/command_line_interface.html#sec:command_line_warnings in the Gradle documentation.\n' +
                                    '\n' +
                                    'BUILD SUCCESSFUL in 3m 42s\n' +
                                    '3 actionable tasks: 3 executed\n' +
                                    '19:45:58: Execution finished \'initBundle\'.\n'}
                            </SyntaxHighlighter>

                            Initial se va descarca fisierul <b>liferay-portal-tomcat-7.4.3.120-ga120-1718225443.7z</b> in <b>C:\Users\[USER]\.liferay\bundles</b>
                        </li>
                    </ul>

                    <hr/>
                    <b>4. Modificare portal-ext.properties</b>
                    <br/>
                    <br/>

                    Fisierul se modifica astfel incat sa aiba urmatorul continut:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'\n' +
                            '\n' +
                            'admin.email.from.address=admin@agnes.ro\n' +
                            'admin.email.from.name=agnes agnes\n' +
                            'company.default.locale=en_US\n' +
                            'locales.enabled=en_US\n' +
                            'company.default.name=Agnes\n' +
                            'company.default.time.zone=UTC+2\n' +
                            'company.default.web.id=agnes.ro\n' +
                            'default.admin.password=test\n' +
                            'default.admin.email.address.prefix=admin\n' +
                            'default.admin.first.name=admin\n' +
                            'default.admin.last.name=admin\n' +
                            'liferay.home=D:/work/liferay-agnes/bundles\n' +
                            '\n' +
                            'jdbc.default.driverClassName=org.postgresql.Driver\n' +
                            'jdbc.default.url=jdbc:postgresql://localhost:5432/lportal-agnes\n' +
                            'jdbc.default.password=lportal\n' +
                            'jdbc.default.username=lportal\n' +
                            'jdbc.default.maximumPoolSize=100\n' +
                            'jdbc.default.idleTimeout=600000\n' +
                            'jdbc.default.maxLifetime=0\n' +
                            'jdbc.default.minimumIdle=10\n' +
                            'jdbc.default.registerMbeans=true\n' +
                            'include-and-override=portal-developer.properties\n'}
                    </SyntaxHighlighter>

                    Ne asiguram ca avem creata baza de date <b>lportal-agnes</b> cu owner-ul <b>lportal</b>.
                    <br/>
                    Crearea bazei de date in SQL:
                    <SyntaxHighlighter>
                        {'CREATE DATABASE "lportal-agnes"\n' +
                            '    WITH \n' +
                            '    OWNER = lportal\n' +
                            '    ENCODING = \'UTF8\'\n' +
                            '    CONNECTION LIMIT = -1;'}
                    </SyntaxHighlighter>

                    <hr/>
                    <b>5. Pornire server</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/6.png'}/>
                    </div>

                    Server va fi pornit cand se va afisa un mesaj similar cu:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'29-Aug-2024 17:01:06.732 INFO [main] org.apache.catalina.startup.Catalina.start Server startup in [73177] milliseconds'}
                    </SyntaxHighlighter>

                    Observatie:
                    <ul>
                        <li>
                            Daca portul 8080 este folosit, se poate porni serverul pe alt port, de exemplu 8087.
                            <br/>
                            S-ar putea sa fie nevoie de modificarea si a portului de oprire, de exemplu 8017.
                            <br/>
                            Acesta modificare se face in <b>\bundles\tomcat\conf\server.xml</b>
                        </li>
                    </ul>

                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {' <Connector maxThreads="75" port="8087" protocol="HTTP/1.1"\n' +
                            '               connectionTimeout="20000"\n' +
                            '               redirectPort="8443"\n' +
                            '               maxParameterCount="1000"\n' +
                            '               />'}
                    </SyntaxHighlighter>


                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'<Server port="8017" shutdown="SHUTDOWN">'}
                    </SyntaxHighlighter>

                    Testare:
                    <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                        {'http://localhost:8087/'}
                    </SyntaxHighlighter>
                    Logare:
                    <ul>
                        <li>admin@agnes.ro</li>
                        <li>admin</li>
                    </ul>

                    Din browser, se va afisa (la prima logare se cere modificarea parolei):
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/8.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/9.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/liferay/agnes/10.png'}/>
                    </div>


                    Observatii:
                    <ul>
                        <li>
                            Implicit porneste cu <b>portal-ext.properties</b>, avand urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'include-and-override=portal-developer.properties'}
                            </SyntaxHighlighter>
                        </li>

                        <li>
                            Implicit porneste cu <b>portal-setup-wizard.properties</b>, avand urmatorul continut:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'setup.wizard.enabled=false'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Implicit porneste cu baza de date Hypersonic (se poate vedea acest lucru din logurile server-ului):
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'Liferay is configured to use Hypersonic as its database. Do NOT use Hypersonic in production. Hypersonic is an embedded database useful for development and demonstration purposes. The database settings can be changed in portal-ext.properties.\n' +
                                    '2024-08-29 17:00:18.439 INFO  [SystemExecutorServiceUtil-1][DialectDetector:144] Using dialect com.liferay.portal.dao.orm.hibernate.HSQLDialect for HSQL Database Engine 2.7'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Implicit porneste cu Sidecar Elasticsearch (se poate vedea acest lucru din logurile server-ului):
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'Liferay automatically starts a child process of Elasticsearch named sidecar for convenient development and demonstration purposes. Do NOT use sidecar in production. Refer to the documentation for details on the limitations of sidecar and instructions on configuring a remote Elasticsearch connection in the Control Panel.'}
                            </SyntaxHighlighter>
                            si
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'2024-08-29 17:00:43.278 INFO  [SystemExecutorServiceUtil-10][Sidecar:103] Sidecar Elasticsearch liferay started at 127.0.0.1:9201'}
                            </SyntaxHighlighter>
                        </li>
                        <li>
                            Daca se foloseste PostgreSQL, in loguri se va afisa o informatie simulara cu:
                            <SyntaxHighlighter showLineNumbers={true} language="gradle" style={androidstudio}>
                                {'Using dialect org.hibernate.dialect.PostgreSQL10Dialect for PostgreSQL 12.3'}
                            </SyntaxHighlighter>
                        </li>
                    </ul>
                </div>
                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>

                    </ol>
                </div>
                <br/>
                {this.navigator()}
                <br/>
            </div>
        );
    }
}

export default AnexaCreateProjectLiferayContent;