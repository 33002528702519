import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class CreateProjectSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-create-project-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Creare proiect Intellij:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_4.png'}/>
                    </div>


                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/llm/img_5.png'}/>
                    </div>

                    <hr/>
                    <b>Schelet proiect</b>
                    <br/>
                    <br/>

                    build.gradle
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'plugins {\n' +
                            '    id \'java\'\n' +
                            '    id \'org.springframework.boot\' version \'3.4.1\'\n' +
                            '    id \'io.spring.dependency-management\' version \'1.1.7\'\n' +
                            '}\n' +
                            '\n' +
                            'group = \'ro.agnes\'\n' +
                            'version = \'0.0.1-SNAPSHOT\'\n' +
                            '\n' +
                            'java {\n' +
                            '    toolchain {\n' +
                            '        languageVersion = JavaLanguageVersion.of(17)\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'repositories {\n' +
                            '    mavenCentral()\n' +
                            '    maven { url \'https://repo.spring.io/milestone\' }\n' +
                            '}\n' +
                            '\n' +
                            'ext {\n' +
                            '    set(\'springAiVersion\', "1.0.0-M4")\n' +
                            '}\n' +
                            '\n' +
                            'dependencies {\n' +
                            '    implementation \'org.springframework.boot:spring-boot-starter-web\'\n' +
                            '    implementation \'org.springframework.ai:spring-ai-ollama-spring-boot-starter\'\n' +
                            '    implementation \'org.springframework.ai:spring-ai-openai-spring-boot-starter\'\n' +
                            '    testImplementation \'org.springframework.boot:spring-boot-starter-test\'\n' +
                            '    testRuntimeOnly \'org.junit.platform:junit-platform-launcher\'\n' +
                            '}\n' +
                            '\n' +
                            'dependencyManagement {\n' +
                            '    imports {\n' +
                            '        mavenBom "org.springframework.ai:spring-ai-bom:${springAiVersion}"\n' +
                            '    }\n' +
                            '}\n' +
                            '\n' +
                            'tasks.named(\'test\') {\n' +
                            '    useJUnitPlatform()\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    settings.gradle
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'rootProject.name = \'spring-ia\''}
                    </SyntaxHighlighter>

                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'package ro.agnes.spring.ia;\n' +
                            '\n' +
                            'import org.springframework.boot.SpringApplication;\n' +
                            'import org.springframework.boot.autoconfigure.SpringBootApplication;\n' +
                            '\n' +
                            '@SpringBootApplication\n' +
                            'public class SpringIaApplication {\n' +
                            '\n' +
                            '    public static void main(String[] args) {\n' +
                            '        SpringApplication.run(SpringIaApplication.class, args);\n' +
                            '    }\n' +
                            '\n' +
                            '}\n'}
                    </SyntaxHighlighter>

                    src/main/resources/application.properties:
                    <SyntaxHighlighter showLineNumbers={true} language="python" style={androidstudio}>
                        {'spring.application.name=spring-ia\n'}
                    </SyntaxHighlighter>

                    <hr/>

                    Adaugare in <i>src/main/resources/application.properties:</i>
                    <SyntaxHighlighter>
                        {'# OPENAI_API_KEY se pune in Environment variables\n' +
                            'spring.ai.openai.api-key=${OPENAI_API_KEY}'}
                    </SyntaxHighlighter>



                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default CreateProjectSpringIALlmContent;