import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function JETMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-jet";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                JET (Jocuri Electronice pe Televizor, prezentat ca „JET-EM Aparat pentru jocuri pe ecran TV”) este un microcalculator personal românesc. A fost proiectat și produs
                la Întreprinderea „Electromagnetica” din București în 1989/90, având interpretorul incorporat și un preț de cca. 11.500 lei.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_35.png'}/>
                </div>

                Detalii:
                <ul>
                    <li>
                        Producator: Electromagnetica
                    </li>
                    <li>
                        An lansare: 1989
                    </li>
                    <li>
                        Scos din fabricaţie: 1992
                    </li>
                    <li>
                        Procesor: Z80
                    </li>
                    <li>
                        Variante:
                        <ul>
                            <li>
                                JET cu carcasă albastră
                            </li>
                            <li>
                                JET cu carcasă albă
                            </li>
                            <li>
                                JET cu carcasă galbenă
                            </li>
                        </ul>
                    </li>
                    <li>
                        Tastele nu erau  gravate cu laser. Legendele sunt scoase la imprimantă pe hârtie obișnuită si decupate manual, fiind acoperite cu un capac de plastic transparent.
                    </li>
                </ul>
                Porturi:
                <ul>
                    <li>
                        RF
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_1.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_2.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_3.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_4.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_5.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_6.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 300}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_7.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_8.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_9.png'}/>
                </div>

                Testare:
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_10.png'}/>
                </div>

                Status:
                <ul>
                    <li>majoritatea tastelor nu merg</li>
                </ul>


            </div>

            <div className={"text-justify"}>

                Referinte:
                <ul>
                    <li>
                        <a target={"_blank"} href={"https://www.sinclaircollection.site/?page_id=506"}>sinclaircollection</a>
                    </li>
                    <li>
                        <a target={"_blank"} href={"https://retroit.ro/product/jet/"}>retroit</a>
                    </li>
                    <li>
                        <a  target={"_blank"} href={"https://ro.wikipedia.org/wiki/JET"}>wiki</a>
                    </li>
                </ul>

            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default JETMuseumContent;