import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function AlimentatorMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-alimentator";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                <hr/>
                <b>1. ICE Felix (HC 91)</b>
                <br/>
                <br/>

                <div className={"row"}>
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/museum/img_92.png'}/>
                    </div>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/museum-4/img_14.png'}/>
                    </div>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_93.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_94.png'}/>
                </div>

                Observatii:
                <ul>
                    <li>
                        Inainte de HC 91, mufa era rotunda (era proasta si putea face scurt usor) si de aceea incepand cu HC 91 s-a trecut la mufa lata.
                    </li>
                    <li>
                        HC-uri mergeau cu alimentator de 9V, dar inauntru au stabilizator de 5V (Cu +12V, -12V si +5V se alimenteaza HC2000)
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_48.png'}/>
                </div>
                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_49.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_50.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_51.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_52.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_53.png'}/>
                </div>

                <hr/>
                <b>3. ZX Spectrum Sinclair</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_60.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_61.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_62.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_63.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_64.png'}/>
                </div>

                <hr/>
                <b>4. aMic</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_74.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_70.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_71.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_72.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_73.png'}/>
                </div>

                <hr/>
                <b>5. CIP 01/O3</b>
                <br/>
                <br/>

                Caracteristici:
                <ul>
                    <li>
                        220V - 50Hz - 30VA
                        <ul>
                            <li>
                                220V: Tensiunea nominală de alimentare (220 volți).
                            </li>
                            <li>
                                50Hz: Frecvența curentului alternativ (50 hertzi), standard pentru rețelele electrice din Europa.
                            </li>
                            <li>
                                30VA: Puterea aparentă maximă a dispozitivului, exprimată în volți-amperi. Aceasta indică cerințele de alimentare ale aparatului
                                <br/>
                                30VA la 220V este echivalent cu aproximativ 136 mA
                            </li>
                        </ul>
                    </li>
                    <li>
                        5Vcc 1.3A
                        <ul>
                            <li>
                                5Vcc: Tensiunea de ieșire în curent continuu (5 volți).
                            </li>
                            <li>
                                1.3A: Curentul maxim de ieșire (1.3 amperi).
                            </li>
                        </ul>
                        <li>
                            IP 30
                            <br/>
                            Indică nivelul de protecție al dispozitivului împotriva pătrunderii corpurilor străine și apei, conform standardului internațional IP (Ingress
                            Protection).
                            <ul>
                                <li>
                                    3: Protecție împotriva corpurilor solide mai mari de 2.5 mm.
                                </li>
                                <li>
                                    0: Fără protecție împotriva apei.
                                </li>
                            </ul>
                        </li>
                        <li>
                            CONF: STAS 6048/7-80
                            <br/>
                            Face referire la un standard românesc de fabricație (STAS), indicând conformitatea dispozitivului cu normele tehnice specifice acestui standard.
                        </li>
                        <li>
                            conectorul tip jack (3.5mm)
                        </li>
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_11.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_12.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_13.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_14.png'}/>
                </div>

                Alimentator include atât un transformator (pentru scăderea tensiunii de la 220V), cât și circuite de redresare și stabilizare pentru a furniza tensiuni continuu
                stabilizate.

                <hr/>
                <b>6. Commodore</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_15.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_16.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_17.png'}/>
                </div>

                <hr/>
                <b>7. ICE Felix (HC 90)</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_45.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_43.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 500}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-3/img_44.png'}/>
                </div>

                <hr/>
                <b>8. Alimentator rusesc pentru probabil pentru Byte/Delta</b>
                <br/>
                <br/>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_19.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_18.png'}/>
                </div>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_17.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_16.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 400}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-4/img_15.png'}/>
                </div>

                Observatii:
                <ul>
                    <li>
                        СЕТЬ = rețea electrică
                    </li>
                    <li>
                        ВКЛ = pornit
                    </li>
                </ul>


            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default AlimentatorMuseumContent;