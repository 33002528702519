import React from "react";

import TitleFragment from "../TitleFragment";
import MuseumToc from "./MuseumToc";
import NavigatorFragment from "../NavigatorFragment";

function CIPMuseumContent(props) {

    let toc = new MuseumToc();

    let pageId = "museum-cip";

    return (
        <div className="home boltzmann">

            <TitleFragment pageId={pageId} toc={toc.getContent()}/>
            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

            <div className={"text-justify important"}>

                Calculatoare CIP (<b>C</b>alculator pentru <b>I</b>nstruiere <b>P</b>rogramabil)
                <ul>
                    <li>CIP 01</li>
                    <li>CIP 02</li>
                    <li>CIP 03</li>
                    <li>CIP 04</li>
                </ul>

                <hr/>
                <b>CIP 01</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        An producție: 1988
                    </li>
                    <li>
                        Producător: Electronica București
                    </li>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: Z80A (MMN 80 CPU - Clonă Z80 fabricată de Microelectronica București)
                    </li>
                    <li>
                        Memoria EPROM: 2 KB
                    </li>

                    <li>
                        Memoria RAM: 64 KB (memorii 4164)
                    </li>

                    <li>
                        Sistem de operare:
                        <br/>
                        cu încărcare de pe casetă:
                        <ul>
                            <li>
                                BASIC-S (Spectrum Basic interpreter)
                            </li>
                        </ul>

                    </li>
                    <li>
                        Rezoluție:
                        <ul>
                            <li>
                                256 X 192 pixeli in regim grafic
                            </li>
                            <li>
                                24 x 32 in regim alfanumeric
                            </li>
                        </ul>
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Afisare:
                        <ul>
                            <li>
                                televizor alb-negru sau color PAL (canalul 8 OIRT)
                            </li>
                            <li>
                                monitor alb-negru sau color
                            </li>
                        </ul>


                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Tastatura: QWERTY cu 40 de taste
                    </li>
                    <li>
                        Dimensiuni: 33 (lățime) X 28 (lungime) X 60 (înălțime) cm
                    </li>
                    <li>
                        Greutate: 3,5kg cu sursa de alimentare
                    </li>
                    <li>
                        Alimentare: 220 Vca/5 Vcc
                    </li>
                    <li>
                        Puterea consumata: max 20 VA
                    </li>


                </ul>

                E nevoie de:
                <ul>
                    <li>mufa DIN tata cu 6 contacte</li>
                    <li>cablu de conectare la TV</li>
                </ul>

                Observatie:
                <ul>
                    <li>
                        OIRT provine de la <i>Organisation Internationale de Radiodiffusion et de Télévision</i>
                    </li>

                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum-2/img_26.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_11.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_37.png'}/>
                </div>

                <hr/>
                <b>CIP 02</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        An producție: 1989
                    </li>
                    <li>
                        Producător: Electronica București
                    </li>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: MMN80CPU (Clonă Z80 fabricată de Microelectronica București)
                    </li>
                    <li>
                        Memoria EPROM: 2 KB + 16 KB
                    </li>

                    <li>
                        Memoria RAM: 64 KB
                    </li>

                    <li>
                        Sistem de operare: CIP-BASIC 48K
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 33 (lățime) X 28 (lungime) X 6.5 (înălțime) cm
                    </li>

                </ul>

                Fiind un model de tranziție comercializat pentru o scură perioadă, EPROM-ul de 2 KB este același utilizat pe calculatorul CIP-01 și EPROM-ul de 16 KB este același
                utilizat pe calculatorul CIP-03.

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_30.png'}/>
                </div>

                <hr/>
                <b>CIP 03</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        An producție: 1990
                    </li>
                    <li>
                        Producător: Electronica București
                    </li>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: MMN80CPU (Clonă Z80 fabricată de Microelectronica București)
                    </li>
                    <li>
                        Memoria EPROM: 16 KB
                    </li>

                    <li>
                        Memoria RAM: 64 KB
                    </li>

                    <li>
                        Sistem de operare: CIP-BASIC 48K
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Sunet: difuzor încorporat care poate emite sunete în intervalul de 10 octave
                    </li>
                    <li>
                        Dimensiuni: 33 (lățime) X 28 (lungime) X 6.5 (înălțime) cm
                    </li>

                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_5.png'}/>
                </div>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_6.png'}/>
                </div>

                Porturi:
                <ul>
                    <li>
                        alimentare:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_7.png'}/>
                        </div>
                    </li>
                    <li>
                        extensie:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_8.png'}/>
                        </div>
                    </li>
                    <li>
                        CAS (mufa intrare-iesire casetofon), AV (mufa video monitor), RF (mufa iesire semnal TV - RF = Radio Frequency):
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_9.png'}/>
                        </div>
                    </li>
                </ul>

                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_10.png'}/>
                </div>

                A veni in variantele:
                <ul>
                    <li>
                        pe albastru:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_39.png'}/>
                        </div>
                    </li>
                    <li>
                        pe rosu:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_38.png'}/>
                        </div>
                    </li>

                    <li>
                        pe rosu semitransparent:
                        <div style={{padding: 10}}>
                            <img alt={""} style={{width: 700}} className={"rounded mx-auto d-block responsive-img"}
                                 src={process.env.PUBLIC_URL + '/img/museum/img_40.png'}/>
                        </div>
                    </li>
                </ul>

                <hr/>
                <b>CIP 04</b>
                <br/>
                <br/>

                Detalii:
                <ul>
                    <li>
                        An producție: 1992
                    </li>
                    <li>
                        Producător: Electronica București
                    </li>
                    <li>
                        Model standard: 40 de taste
                    </li>
                    <li>
                        Microprocesor: MMN80 - 3.5 MHz Mhz
                    </li>
                    <li>
                        Memoria EPROM: 32 KB
                    </li>

                    <li>
                        Memoria RAM: 256 KB
                    </li>

                    <li>
                        Sistem de operare: Clone ZX-Spectrum +3
                    </li>
                    <li>
                        Rezoluție: 256 X 192 pixeli, 256 X 176 în mod BASIC
                    </li>
                    <li>
                        Mod text: 32 coloane X 24 linii; liniile 23 și 24 utilizate pentru comenzi în mod BASIC, având posibilitatea de extindere
                    </li>
                    <li>
                        Număr de culori disponibile: 16 culori
                    </li>
                    <li>
                        Unitate floppy: 3.5" Floppy disk drive
                    </li>

                </ul>


                <div style={{padding: 10}}>
                    <img alt={""} style={{width: 900, borderColor: "yellowgreen", borderWidth: 2, borderStyle: "solid"}} className={"rounded mx-auto d-block responsive-img"}
                         src={process.env.PUBLIC_URL + '/img/museum/img_27.png'}/>
                </div>

                <hr/>
                <b>OIRT</b>
                <br/>
                <br/>
                Standardul adoptat de OIRT pentru transmisiile de semnal analogic, alb/negru impune următoarele caracteristici ale semnalului de televiziune:
                <ul>
                    <li>
                        Numărul de linii: 625
                    </li>
                    <li>
                        Frecvența semicadrelor: 50 Hz
                    </li>
                    <li>
                        Frecvența cadrelor: 25Hz
                    </li>
                    <li>
                        Frecvența liniilor: 15625Hz
                    </li>
                    <li>
                        Formatul de cadru: 4/3
                    </li>
                    <li>
                        Frecvența video maximă: 7.5MHz
                    </li>
                </ul>
            </div>

            <div className={"text-justify"}>


            </div>

            <NavigatorFragment pageId={pageId} toc={toc.getContent()}/>

        </div>
    );
}

export default CIPMuseumContent;