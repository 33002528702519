import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../util/IndexContent";

class BackArticleContent extends BaseContentPage {

    constructor(props) {
        super(props, "article-back", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Reintoarceri</b>
                    <br/>
                    <br/>

                    Exista o tendinta spre reintoarceri:
                    <ul>
                        <li>
                            reintoarceri la aparate foto pe film
                        </li>
                        <li>
                            revenirea vinilurilor
                        </li>
                        <ul>
                            readucere in prim plan a unor concepte din programare vechi:
                            <ul>
                                <li>
                                    programarea functionala (anii 1950, limbajul de programare <b>LISP</b>)
                                </li>
                                <li>
                                    programare procedurala (anii 1950, limbajul de programare <b>Fortran</b>)
                                </li>
                                <li>
                                    inferarea tipului (anii 1970, limbajul de programare <b>ML</b>)
                                </li>
                            </ul>

                        </ul>
                    </ul>

                    <b># Cu cine seamana? Cu mama sau cu tata?</b>
                    <br/>
                    <br/>
                    Primul limbaj OOP a fost <b>Simula</b> in anii 1960.
                    Daca initial s-a pornit cu mostenire multipla (Simula, 1960, <b>C++</b>, 1985), aceasta s-a dovedit in scurt timp o problema
                    si noile limbaje OOP care au urmat (<b>Java</b>, 1985) au eliminat mostenirea multipla!
                    Dupa care <b>Rust</b>, anii 2000, a eliminat cu totul mostenirea clasica.
                    <br/>
                    <br/>
                    Și, hai să fim serioși, câți dintre noi mai știm cum se numea străbunica? Este aproape nenatural și inuman acest exercițiu.
                    Acest lanț / arbore genealogic poate iluziona doar în mistificări pseudospirituale.
                    În realitate, o funcționalitate moștenită de la un strămoș dintr-o generatie mai mare de 2-3 este irelevantă
                    (prima generatie suntem noi, a doua parintii, a treia bunicii, etc).

                    <br/>
                    <br/>

                    Pasi de retragere din OOP:
                     <ul>
                        <li>
                            <b>compozitie in locul extenderii</b>:
                            <ul>
                                <li>
                                    <b>Rust</b> nu permite extindere, are in schimb <b>traits</b> (interfete cu metode care au implementare)
                                </li>
                                <li>
                                    <b>Java</b> adopta <b>traits</b> in sintaxa existenta (interfete cu metode care au implementare)
                                    <br/>
                                    <b>Este recomandabil a se folosi o interfață cu metode implicite!</b>
                                </li>
                            </ul>
                        </li>
                        <li>
                            <b>React</b> s-a intors la functii
                        </li>
                        <li>
                            <b>Go</b> nu este OOP (in schimb se pot crea tipuri noi si atasa acestor tipuri functionalitati prin intermediul functiilor)
                        </li>
                    </ul>

                    <b># Zi-mi cum arati sa iti spun cine esti</b>
                    <br/>
                    <br/>

                    Primul limbaj de programare care a introdus conceptul de inferență a tipului a fost "ML" (Meta-Language), dezvoltat în anii 1970.
                    Aceasta idee a prins in ultimul timp din ce in ce mai mult, fiind fie adoptata fie gandita implicit in constructia noilor limbaje:
                    <ul>
                        <li>
                            <b>Java</b> (adoptare idee) a preluat aceasta idee, prin intermediul lui <b>var</b> intr-o forma limita
                        </li>
                        <li>
                            <b>Rust</b>
                        </li>
                        <li>
                            <b>Go</b>
                        </li>
                        <li>
                            <b>Python</b> (adoptare idee)
                        </li>
                        <li>
                            <b>Haskell</b> (adoptare idee)
                        </li>
                    </ul>

                    <b># De ce programatorii funcționali nu merg niciodată la petreceri? Pentru că nu se simt în apele lor când trebuie să schimbe starea lucrurilor!</b>

                    <br/>
                    <br/>
                    Programarea functionala s-a infiltrat in ultimii ani in limbaje care n-au fost gandite in acest mod, de exemplu in limbajul de programare <b>Java</b>.
                    <br/>
                    Și au aparut noi limbaje nativ functionale (<b>Scala</b>, <b>Kotlin</b>).
                    <br/>
                    <br/>

                    <b># La terapie: monolitul vrea să împartă totul, iar microserviciile preferau să-și păstreze spațiul personal!</b>
                    <br/>
                    <br/>

                    In ultimul timp au aparut intrebari daca toti abordarea pe microservicii este directia buna si corecta sau e timpul sa ne intoarcem la aplicatii monolite?
                    Sau un mix?

                    <br/>
                    <br/>

                    Reintorcerile vin mai degraba din uitare, decat din aduceri aminte.

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*   */}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default BackArticleContent;