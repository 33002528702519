import {Helmet} from "react-helmet";
import React from "react";
import IndexContentHelper from "./IndexContentHelper";

const TitleFragment = ({pageId, toc}) => {

    let chapter = IndexContentHelper.chapter(pageId, toc);

    let currentPage = IndexContentHelper.current(pageId, toc);

    return (
        <span>
                <Helmet>
                    <title>{chapter}. {currentPage.title}</title>
                </Helmet>

                <div className={"title"}>
                    {chapter}. {currentPage.title}
                </div>
            </span>
    )
}

export default TitleFragment;