import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../arduino/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";

class FotorezistorArduinoContent extends BaseContentPage {

    constructor(props) {
        super(props, "arduino-photoresistor", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    <b>Fotorezistor - masurarea luminozitatii</b>
                    <br/>
                    <br/>

                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 350}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_18.png'}/>
                    </div>

                    Este nevoie de:
                    <ul>
                        <li>
                            rezistor de 10 KOhm
                        </li>
                    </ul>

                    Lumina mai puternica valori mai mari:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_19.png'}/>
                    </div>

                    Lumina mai puternica valori mai mici:
                    <div style={{padding: 10}}>
                        <img alt={""} style={{width: 750}} className={"rounded mx-auto d-block responsive-img"}
                             src={process.env.PUBLIC_URL + '/img/arduino/img_20.png'}/>
                    </div>

                    <SyntaxHighlighter>
                        {'#define PHOTORESISTOR_PIN A5\n' +
                            '\n' +
                            '\n' +
                            'void setup()\n' +
                            '{\n' +
                            '  Serial.begin(9600);\n' +
                            '  pinMode(PHOTORESISTOR_PIN, INPUT);\n' +
                            '}\n' +
                            '\n' +
                            'void loop()\n' +
                            '{\n' +
                            '  Serial.println(analogRead(PHOTORESISTOR_PIN));\n' +
                            '  delay(1000); // Wait for 1000 millisecond(s)\n' +
                            '}'}
                    </SyntaxHighlighter>

                </div>

                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.youtube.com/watch?v=yBgMJssXqHY&list=PLlBVuTSjOrclb0iCMSRpS_H1lSrlSVeEm&index=5&ab_channel=ScienceBuddies"}>*/}
                    {/*            How to Use a Button with an Arduino (Lesson #5)*/}
                    {/*        </a>*/}
                    {/*    </li>*/}
                    {/*    <li>*/}
                    {/*        <a target={"_blank"} href={"https://www.projecthub.in/how-to-use-a-push-button-with-arduino/"}>How To Use A Push Button With Arduino</a>*/}
                    {/*    </li>*/}
                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default FotorezistorArduinoContent;