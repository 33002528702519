import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../videografie/IndexContent";

class IluminareVideografieContent extends BaseContentPage  {

    constructor(props) {
        super(props, "videografie-iluminare", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <br/>
                
                <div className={"text-justify important"}>

                    Iluminare in 3 puncte:
                    <ul>
                        <li>
                            lumina principala: <b>Key light</b>, folosita pentru a lumina subiectul
                            <ul>
                                <li>putin deasupra subiectului</li>
                                <li>45 de grade fata de aparat</li>
                                <li>catchlight: lumina din ochiul subiectului</li>
                            </ul>
                            creeaza umbre pe fata subiectului, care dau impresia de tridimensionalitate, profunzime a imaginii
                            <br/>
                            atentie la:
                            <ul>
                                <li>daca lumina este prea sus {"=>"} ochi de raton</li>
                                <li>daca lumina este prea jos {"=>"} senzatie horror</li>
                            </ul>

                            Paternuri de pozitionare a luminii:
                            <ul>
                                <li><b>flat lighting</b>: direct in fata subiectului
                                    <br/>
                                    nu exista umbre, deci imaginea nu pare trimimensionala
                                </li>
                                <li><b>paramount lighting</b> / <b>butterfly lighting</b>: in fata subiectului, dar mai sus (nu foarte sus, sa nu dam peste raton)
                                    <br/>
                                    apare o umbra sub nas
                                    <ul>
                                        <li>iluminare mai feminina</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>loop lighting</b>: lumina e mai sus si din lateral
                                    <br/>
                                    apare o umbra in lateral-jos a nasului
                                </li>
                                <li>
                                    <b>rembrandt lighting</b>: lumina e mai sus si din lateral la 45 de grade
                                    <br/>
                                    apare o umbra in lateral-jos a nasului care se prelungeste si se uneste cu umbra obrazului, iar in partea de sus pare de obicei un triunghi mai luminat
                                    <br/>
                                    in functie de pozitia camerei:
                                    <ul>
                                        <li><b>broad light</b>: daca camera e pe partea luminata a subiectului</li>
                                        <li><b>dark light / short light</b>: daca camera e pe partea intunecata a subiectului</li>
                                    </ul>
                                </li>
                                <li>
                                    <b>split lighting</b>: lumina vine din lateral, perpedicular cu subiectul
                                    <br/>
                                    jumatate din fata apare luminata si jumatate intunecata
                                    <ul>
                                        <li>iluminare mai masculina</li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li>a doua lumina
                            <ul>
                                <li><b>back light</b>: daca e iluminat spatele</li>
                                <li><b>hair light</b>: daca e iluminat parul</li>
                                <li><b>rim light</b>: daca iluminarea se face din lateral</li>
                            </ul>
                            pozitionata mai departe si in spatele subiectului
                        </li>
                        <li>a treia lumina:
                            <b>fill light</b>: e partea opusa a luminii principale (key light) pentru a face umbrele mai putin dure
                            <br/>
                            uneori se poate inlocui cu un relector (o blenda)
                        </li>
                    </ul>

                    <b>Checkerboard</b> (tabla de sah) = pe partea unde subiectul este mai iluminat, lumina din spate e mai slaba si pe partea unde subiectul este putin iluminat, lumina, lumina este mai puternica;
                    se alterneaza puterea luminii: dark, bright, dark, bright (intunecat, luminat, intunecat, lumina);
                    <br/>
                    ajuta subiectul sa iasa din fundal;

                    <hr/>
                    Pasi de urmat pentru o lumina cinematica:

                    <ul>
                        <li>
                            Se filmeaza de obicei din partea opusa partii luminate.

                            <div style={{padding:10}}>
                                <img alt={""} style={{width:550}} className={"rounded mx-auto d-block responsive-img"}
                                     src={process.env.PUBLIC_URL + '/img/videography/videografie-1.png'}/>
                            </div>
                        </li>
                    </ul>


                </div>

                <br/>
                <div className={"text-justify"}>
                    <b>Referinte:</b><br/>
                    <ol>
                       <li>
                           <div>
                              <a href={"https://www.youtube.com/watch?v=eZ5hpcn6tIM&ab_channel=ParkerWalbeck"}>Cinematic Lighting Techniques | Part 1</a>
                           </div>
                       </li>
                        <li>
                            <div>
                                <a href={"https://www.youtube.com/watch?v=0suVZjz3_Uw&ab_channel=TomorrowsFilmmakers"}>8 Steps to Cinematic Lighting | Tomorrow's Filmmakers</a>
                            </div>
                        </li>

                    </ol>
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default IluminareVideografieContent;