import {useRoutes} from "react-router-dom";
import React from "react";
import ReactHomeContent from "./IndexContent";
import PrivateRoute from "../../security/PrivateRoute";
import CreateReactAppContent from "./CreateReactAppContent";
import DockerReactAppContent from "./DockerReactAppContent";
import ReactBabelAppContent from "./ReactBabelAppContent";
import RoutingReactContent from "./RoutingReactContent";
import ExportImportJsContent from "./ExportImportJsContent";
import React18Content from "./React18Content";
import CssReactContent from "./CssReactContent";
import ComponentReactContent from "./ComponentReactContent";
import StateReactContent from "./StateReactContent";
import UpdateAppReactContent from "./UpdateAppReactContent";
import ComponentDidCatchReactContent from "./ComponentDidCatchReactContent";
import UseStateReactContent from "./UseStateReactContent";
import UseEffectReactContent from "./UseEffectReactContent";
import SpreadOperatorReactContent from "./SpreadOperatorReactContent";
import RestParameterReactContent from "./RestParameterReactContent";
import DestructuringReactContent from "./DestructuringReactContent";
import ClassReactContent from "./ClassReactContent";
import DefaultPropsReactContent from "./DefaultPropsReactContent";
import UseHistoryReactContent from "./UseHistoryReactContent";
import FormReactContent from "./FormReactContent";
import ContextReactContent from "./ContextReactContent";
import UseReducerReactContent from "./UseReducerReactContent";
import CustomHooksReactContent from "./CustomHooksReactContent";
import ReduxContent from "./ReduxContent";
import ReduxToolkitContent from "./ReduxToolkitContent";
import UpdateChildReactContent from "./UpdateChildReactContent";
import DataBindingReactContent from "./DataBindingReactContent";
import GenerateReactCLIReactContent from "./GenerateReactCLIReactContent";
import LifecycleReactContent from "./LifecycleReactContent";
import ServicesByContentReactContent from "./ServicesByContentReactContent";
import ServicesBySingletonReactContent from "./ServicesBySingletonReactContent";
import MemoReactContent from "./MemoReactContent";
import UseRefReactContent from "./UseRefReactContent";
import UseCallbackReactContent from "./UseCallbackReactContent";
import UseMemoReactContent from "./UseMemoReactContent";
import UsingStateCorrectlyContent from "./UsingStateCorrectlyContent";
import PropsReactContent from "./PropsReactContent";
import Routing6ReactContent from "./Routing6ReactContent";
import WebpackReactContent from "./WebpackReactContent";
import RxJsReactContent from "./RxJsReactContent";
import RxJsOperatorsReactContent from "./RxJsOperatorsReactContent";
import RxJsLastReactContent from "./RxJsLastReactContent";
import RxJsFirstReactContent from "./RxJsFirstReactContent";
import JestReactContent from "./JestReactContent";
import CypressReactContent from "./CypressReactContent";
import InfiniteScrollReactContent from "./InfiniteScrollReactContent";
import DynamicComponentsReactContent from "./DynamicComponentsReactContent";
import SpreadAttributeReactContent from "./SpreadAttributeReactContent";
import SignalsReactContent from "./SignalsReactContent";
import TapRxJsReactContent from "./TapRxJsReactContent";
import OfRxJsReactContent from "./OfRxJsReactContent";
import ViteReactContent from "./ViteReactContent";
import SwrReactContent from "./SwrReactContent";
import DependencyInjectionReactContent from "./DependencyInjectionReactContent";
import HillaReactContent from "./HillaReactContent";
/*
<Route path="/react/index" element={<ReactHomeContent/>} exact/>
<Route path="/react/create-react-app" element={<CreateReactAppContent/>} exact/>
<Route path="/react/docker-react-app" element={<DockerReactAppContent/>} exact/>
<Route path="/react/babel-react-app" element={<ReactBabelAppContent/>} exact/>
<Route path="/react/routing" element={<RoutingReactContent/>} exact/>
<Route path="/react/export-import-js" element={<ExportImportJsContent/>} exact/>
<Route path="/react/18" element={<React18Content/>} exact/>
<Route path="/react/css" element={<CssReactContent/>} exact/>
<Route path="/react/component" element={<ComponentReactContent/>} exact/>
<Route path="/react/state" element={<StateReactContent/>} exact/>
<Route path="/react/update-app" element={<UpdateAppReactContent/>} exact/>

<Route element={<PrivateRoutes/>}>
    <Route path="/react/componentDidCatch" element={<ComponentDidCatchReactContent/>} exact/>
    <Route path="/react/usestate" element={<UseStateReactContent/>} exact/>
    <Route path="/react/useeffect" element={<UseEffectReactContent/>} exact/>
    <Route path="/react/spread-operator" element={<SpreadOperatorReactContent/>} exact/>
    <Route path="/react/rest-parameter" element={<RestParameterReactContent/>} exact/>
    <Route path="/react/destructuring" element={<DestructuringReactContent/>} exact/>
    <Route path="/react/class" element={<ClassReactContent/>} exact/>
    <Route path="/react/default-props" element={<DefaultPropsReactContent/>} exact/>
    <Route path="/react/usehistory" element={<UseHistoryReactContent/>} exact/>
    <Route path="/react/form" element={<FormReactContent/>} exact/>
    <Route path="/react/context" element={<ContextReactContent/>} exact/>
    <Route path="/react/useReducer" element={<UseReducerReactContent/>} exact/>
    <Route path="/react/custom-hooks" element={<CustomHooksReactContent/>} exact/>
    <Route path="/react/redux" element={<ReduxContent/>} exact/>
    <Route path="/react/redux-toolkit" element={<ReduxToolkitContent/>} exact/>
    <Route path="/react/update-child" element={<UpdateChildReactContent/>} exact/>
    <Route path="/react/data-binding" element={<DataBindingReactContent/>} exact/>
    <Route path="/react/generate-react-cli" element={<GenerateReactCLIReactContent/>} exact/>
    <Route path="/react/lifecycle" element={<LifecycleReactContent/>} exact/>
    <Route path="/react/service-by-content" element={<ServicesByContentReactContent/>} exact/>
    <Route path="/react/service-by-singleton" element={<ServicesBySingletonReactContent/>} exact/>
    <Route path="/react/memo" element={<MemoReactContent/>} exact/>
    <Route path="/react/useref" element={<UseRefReactContent/>} exact/>
    <Route path="/react/usecallback" element={<UseCallbackReactContent/>} exact/>
    <Route path="/react/usememo" element={<UseMemoReactContent/>} exact/>
    <Route path="/react/using-state-correctly" element={<UsingStateCorrectlyContent/>} exact/>
    <Route path="/react/props" element={<PropsReactContent/>} exact/>
    <Route path="/react/routing-6" element={<Routing6ReactContent/>} exact/>
</Route>
*/

export default function Router() {
    let element = useRoutes([
        {path: "/react/index", element: <ReactHomeContent/>},
        {path: "/react/create-react-app", element: <CreateReactAppContent/>},
        {path: "/react/docker-react-app", element: <DockerReactAppContent/>},
        {path: "/react/babel-react-app", element: <ReactBabelAppContent/>},
        {path: "/react/routing", element: <RoutingReactContent/>},
        {path: "/react/export-import-js", element: <ExportImportJsContent/>},
        {path: "/react/18", element: <React18Content/>},
        {path: "/react/css", element: <CssReactContent/>},
        {path: "/react/component", element: <ComponentReactContent/>},
        {path: "/react/state", element: <StateReactContent/>},
        {path: "/react/update-app", element: <UpdateAppReactContent/>},
        {path: "/react/componentDidCatch", element: <PrivateRoute element={<ComponentDidCatchReactContent/>}/>},
        {path: "/react/usestate", element: <PrivateRoute element={<UseStateReactContent/>}/>},
        {path: "/react/useeffect", element: <PrivateRoute element={<UseEffectReactContent/>}/>},
        {path: "/react/spread-operator", element: <PrivateRoute element={<SpreadOperatorReactContent/>}/>},
        {path: "/react/rest-parameter", element: <PrivateRoute element={<RestParameterReactContent/>}/>},
        {path: "/react/destructuring", element: <PrivateRoute element={<DestructuringReactContent/>}/>},
        {path: "/react/class", element: <PrivateRoute element={<ClassReactContent/>}/>},
        {path: "/react/default-props", element: <PrivateRoute element={<DefaultPropsReactContent/>}/>},
        {path: "/react/usehistory", element: <PrivateRoute element={<UseHistoryReactContent/>}/>},
        {path: "/react/form", element: <PrivateRoute element={<FormReactContent/>}/>},
        {path: "/react/context", element: <PrivateRoute element={<ContextReactContent/>}/>},
        {path: "/react/useReducer", element: <PrivateRoute element={<UseReducerReactContent/>}/>},
        {path: "/react/custom-hooks", element: <PrivateRoute element={<CustomHooksReactContent/>}/>},
        {path: "/react/redux", element: <PrivateRoute element={<ReduxContent/>}/>},
        {path: "/react/redux-toolkit", element: <PrivateRoute element={<ReduxToolkitContent/>}/>},
        {path: "/react/update-child", element: <PrivateRoute element={<UpdateChildReactContent/>}/>},
        {path: "/react/data-binding", element: <PrivateRoute element={<DataBindingReactContent/>}/>},
        {path: "/react/generate-react-cli", element: <PrivateRoute element={<GenerateReactCLIReactContent/>}/>},
        {path: "/react/lifecycle", element: <PrivateRoute element={<LifecycleReactContent/>}/>},
        {path: "/react/service-by-content", element: <PrivateRoute element={<ServicesByContentReactContent/>}/>},
        {path: "/react/service-by-singleton", element: <PrivateRoute element={<ServicesBySingletonReactContent/>}/>},
        {path: "/react/memo", element: <PrivateRoute element={<MemoReactContent/>}/>},
        {path: "/react/useref", element: <PrivateRoute element={<UseRefReactContent/>}/>},
        {path: "/react/usecallback", element: <PrivateRoute element={<UseCallbackReactContent/>}/>},
        {path: "/react/usememo", element: <PrivateRoute element={<UseMemoReactContent/>}/>},
        {path: "/react/using-state-correctly", element: <PrivateRoute element={<UsingStateCorrectlyContent/>}/>},
        {path: "/react/props", element: <PrivateRoute element={<PropsReactContent/>}/>},
        {path: "/react/routing-6", element: <PrivateRoute element={<Routing6ReactContent/>}/>},
        {path: "/react/webpack", element: <PrivateRoute element={<WebpackReactContent/>}/>},
        {path: "/react/rxjs", element: <PrivateRoute element={<RxJsReactContent/>}/>},
        {path: "/react/rxjs-operators", element: <PrivateRoute element={<RxJsOperatorsReactContent/>}/>},
        {path: "/react/rxjs-last", element: <PrivateRoute element={<RxJsLastReactContent/>}/>},
        {path: "/react/rxjs-first", element: <PrivateRoute element={<RxJsFirstReactContent/>}/>},
        {path: "/react/jest", element: <PrivateRoute element={<JestReactContent/>}/>},
        {path: "/react/cypress", element: <PrivateRoute element={<CypressReactContent/>}/>},
        {path: "/react/infinite-scroll", element: <PrivateRoute element={<InfiniteScrollReactContent/>}/>},
        {path: "/react/dynamic-components", element: <PrivateRoute element={<DynamicComponentsReactContent/>}/>},
        {path: "/react/spread-attribute", element: <PrivateRoute element={<SpreadAttributeReactContent/>}/>},
        {path: "/react/signals", element: <PrivateRoute element={<SignalsReactContent/>}/>},
        {path: "/react/rxjs-tap", element: <PrivateRoute element={<TapRxJsReactContent/>}/>},
        {path: "/react/rxjs-of", element: <PrivateRoute element={<OfRxJsReactContent/>}/>},
        {path: "/react/vite", element: <PrivateRoute element={<ViteReactContent/>}/>},
        {path: "/react/swr", element: <PrivateRoute element={<SwrReactContent/>}/>},
        {path: "/react/dependency-injection",element: <PrivateRoute element={<DependencyInjectionReactContent/>}/>},

        {path: "/react/hilla",element: <PrivateRoute element={<HillaReactContent/>}/>}

    ]);

    return element;
}