import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class AzureOpenIASpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-azure-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                    Azure OpenAI este un serviciu oferit de Microsoft Azure care permite accesul la modelele avansate de inteligență artificială
                    dezvoltate de OpenAI, cum ar fi GPT-4, GPT-3.5-Turbo, Codex și DALL-E.
                    <br/>
                    Acest serviciu facilitează integrarea capacităților de generare de text, cod și imagini în aplicațiile dumneavoastră,
                    beneficiind de infrastructura securizată și scalabilă a Azure
                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default AzureOpenIASpringIALlmContent;