import React from "react";
import TocHelper from "../TocHelper";

class IndexContent extends React.Component {

    static content = [

        {id:"react-js-root", url:"#", title:"Recapitulare JavaScript", subtitle:""},
        {id:"react-spread-operator", url:"/react/spread-operator", title:"Operatorul spread (...)", subtitle:"", parent:"react-js-root"},
        {id:"react-rest-parameter", url:"/react/rest-parameter", title:"Parametrul rest(...)", subtitle:"", parent:"react-js-root"},
        {id:"react-destructuring", url:"/react/destructuring", title:"Destructurare", subtitle:"", parent:"react-js-root"},
        {id:"react-export-import-js", url:"/react/export-import-js", title:"Export / import", subtitle:"", parent:"react-js-root"},
        {id:"react-class", url:"/react/class", title:"Clase", subtitle:"", parent:"react-js-root"},

        {id:"react-root", url:"#", title:"Introducere in React", subtitle:""},
        {id:"react-create-react-app", url:"/react/create-react-app", title:"Generare aplicatie React", subtitle:"", parent:"react-root"},
        {id:"react-babel-react-app", url:"/react/babel-react-app", title:"JSX. Babel", subtitle:"", parent:"react-root"},

        {id:"react-css", url:"/react/css", title:"CSS: import, inline, module", subtitle:"", parent:"react-root"},
        {id:"react-component", url:"/react/component", title:"Componente", subtitle:"", parent:"react-root"},
        {id:"react-state", url:"/react/state", title:"State", subtitle:"", parent:"react-root"},
        {id:"react-using-state-correctly", url:"/react/using-state-correctly", title:"Folosire corecta State/Stare", subtitle:"", parent:"react-root"},
        {id:"react-data-binding", url:"/react/data-binding", title:"Data binding / Legarea datelor", subtitle:"", parent:"react-root"},
        {id:"react-default-props", url:"/react/default-props", title:"Valori implicite pentru props-uri", subtitle:"", parent:"react-root"},
        {id:"react-props", url:"/react/props", title:"Props: (fara valoare = true)", subtitle:"", parent:"react-root"},
        {id:"react-routing", url:"/react/routing", title:"Routing: React Router (versiunea 5)", subtitle:"", parent:"react-root"},
        {id:"react-routing-6", url:"/react/routing-6", title:"Routing: React Router (versiunea 6)", subtitle:"", parent:"react-root"},
        {id:"react-componentDidCatch", url:"/react/componentDidCatch", title:"Metoda componentDidCatch", subtitle:"", parent:"react-root"},
        {id:"react-context", url:"/react/context", title:"Context", subtitle:"", parent:"react-root"},
        {id:"react-update-child", url:"/react/update-child", title:"Actualizare copii/Fara actualizare copii/Apelare children cu props", subtitle:"", parent:"react-root"},
        {id:"react-memo", url:"/react/memo", title:"Actualizare copii doar daca s-a actualizat props: momo", subtitle:"", parent:"react-root"},

        {id:"react-hooks-root", url:"#", title:"Introducere in Hooks", subtitle:""},
        {id:"react-usestate", url:"/react/usestate", title:"useState", subtitle:"", parent:"react-hooks-root"},
        {id:"react-useeffect", url:"/react/useeffect", title:"useEffect", subtitle:"", parent:"react-hooks-root"},
        {id:"react-usehistory", url:"/react/usehistory", title:"useHistory", subtitle:"", parent:"react-hooks-root"},
        {id:"react-usereducer", url:"/react/usereducer", title:"useReducer", subtitle:"", parent:"react-hooks-root"},
        {id:"react-useref", url:"/react/useref", title:"useRef", subtitle:"", parent:"react-hooks-root"},
        {id:"react-usecallback", url:"/react/usecallback", title:"useCallback", subtitle:"", parent:"react-hooks-root"},
        {id:"react-usememo", url:"/react/usememo", title:"useMemo", subtitle:"", parent:"react-hooks-root"},
        {id:"react-custom-hooks", url:"/react/custom-hooks", title:"Creare Hook-uri personalizate", subtitle:"", parent:"react-hooks-root"},
        {id:"react-lifecycle", url:"/react/lifecycle", title:"Lifecycle", subtitle:"", parent:"react-hooks-root"},

        {id:"react-util-root", url:"#", title:"Librarii utile", subtitle:""},
        {id:"react-form", url:"/react/form", title:"Formulare: React / React Hook Form + Yup", subtitle:"", parent:"react-util-root"},
        {id:"react-redux", url:"/react/redux", title:"Introducere in Redux", subtitle:"", parent:"react-util-root"},
        {id:"react-redux-toolkit", url:"/react/redux-toolkit", title:"Introducere in Redux Toolkit", subtitle:"", parent:"react-util-root"},
        {id:"react-generate-react-cli", url:"/react/generate-react-cli", title:"Generate React CLI", subtitle:"", parent:"react-util-root"},
        {id:"react-separation-of-concern", url:"/react/update-app", title:"Actualizare/audit aplicatie React", subtitle:"", parent:"react-util-root"},
        {id:"react-service-by-content", url:"/react/service-by-content", title:"Servicii folosind Context", subtitle:"", parent:"react-util-root"},
        {id:"react-service-by-singleton", url:"/react/service-by-singleton", title:"Servicii folosind Singleton", subtitle:"", parent:"react-util-root"},
        {id:"react-webpack", url:"/react/webpack", title:"Introducere in Webpack", subtitle:"", parent:"react-util-root"},

        {id:"react-rxjs-root", url:"#", title:"RxJs", subtitle:""},
        {id:"react-rxjs", url:"/react/rxjs", title:"Introducere in RxJs", subtitle:"", parent:"react-rxjs-root"},
        {id:"react-rxjs-tap", url:"/react/rxjs-tap", title:"Operator: tap()", subtitle:"", parent:"react-rxjs-root"},
        {id:"react-rxjs-of", url:"/react/rxjs-of", title:"Operator: of()", subtitle:"", parent:"react-rxjs-root"},
        {id:"react-rxjs-last", url:"/react/rxjs-last", title:"Operator: last()", subtitle:"", parent:"react-rxjs-root"},
        {id:"react-rxjs-first", url:"/react/rxjs-first", title:"Operator: first()", subtitle:"", parent:"react-rxjs-root"},
        {id:"react-rxjs-operators", url:"/react/rxjs-operators", title:"Introducere in Operatori RxJs (forkJoin, zip, combineLatest, withLatestFrom)", subtitle:"", parent:"react-rxjs-root"},

        {id:"react-advanced-root", url:"#", title:"Mentinere aplicatie", subtitle:""},
        {id:"react-18", url:"/react/18", title:"Update to React 18", subtitle:"", parent:"react-advanced-root"},
        {id:"react-update-app", url:"/react/update-app", title:"Actualizare/audit aplicatie React", subtitle:"", parent:"react-advanced-root"},
        {id:"react-docker-react-app", url:"/react/docker-react-app", title:"Dockerizare aplicatie React", subtitle:"", parent:"react-advanced-root"},
        {id:"react-jest", url:"/react/jest", title:"Testare cu Jest", subtitle:"", parent:"react-advanced-root"},
        {id:"react-cypress", url:"/react/cypress", title:"Testare E2E cu Cypress", subtitle:"", parent:"react-advanced-root"},
        {id:"react-infinite-scroll", url:"/react/infinite-scroll", title:"Infinite Scroll", subtitle:"", parent:"react-advanced-root"},
        {id:"react-dynamic-components", url:"/react/dynamic-components", title:"Componente dinamice", subtitle:"", parent:"react-advanced-root"},
        {id:"react-spread-attribute", url:"/react/spread-attribute", title:"Spread attribute", subtitle:"", parent:"react-advanced-root"},
        {id:"react-signals", url:"/react/signals", title:"Signals", subtitle:"", parent:"react-advanced-root"},
        {id:"react-vite", url:"/react/vite", title:"Migrare de la CRA la Vite", subtitle:"", parent:"react-advanced-root"},
        {id:"react-swr", url:"/react/swr", title:"SWR", subtitle:"", parent:"react-advanced-root"},
        {id:"react-dependency-injection", url:"/react/dependency-injection", title:"Dependinta injectiva", subtitle:"", parent:"react-advanced-root"},

        {id:"react-spring-root", url:"#", title:"React+Spring", subtitle:""},
        {id:"react-hilla", url:"/react/hilla", title:"Hilla", subtitle:"", parent:"react-spring-root"},
    ]

    static indexUrl = "/react/index";

    render() {

        return (
            <div className="home index index-page">

                <div className={"title"}>
                    Introducere in React
                </div>

                {TocHelper.display(IndexContent.content)}

                <br/>
            </div>
        );
    }
}

export default IndexContent;