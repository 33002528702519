import React from "react";
import BaseContentPage from "../BaseContentPage";
import IndexContent from "../llm/IndexContent";
import SyntaxHighlighter from "react-syntax-highlighter";
import {androidstudio} from "react-syntax-highlighter/dist/cjs/styles/hljs";

class PromptEngineeringSpringIALlmContent extends BaseContentPage {

    constructor(props) {
        super(props, "llm-prompt-engineering-spring-ia", IndexContent);
    }

    render() {
        return (
            <div className="home boltzmann">

                {this.title()}
                {this.navigator()}

                <div className={"text-justify important"}>

                   Sfaturi pentru creare prompt (Prompt Engineering):
                    <ul>
                        <li>
                            trebuie date instructiuni clare si precise
                        </li>
                        <li>
                            se poate cere ca rezultatul sa fie intr-un anumit format (xml, json, xml)
                        </li>
                        <li>
                            se poate cere ca rezultatul sa respecte un anumit sablon/template
                        </li>
                        <li>
                            se poate cere sa fac un rezumat/sumarizare
                        </li>
                        <li>
                            se pot adaga mesaje system (pentru a crea un context)
                        </li>
                        <li>
                            se poate cere ce sentiment este indus de un anumit text (pozitiv sau negativ)
                        </li>
                        <li>
                            se poate cere ce emotie este indus de un anumit text (pozitiv, negativ, frustrare, dezaprobare)
                        </li>

                        <li>
                            se poate care din o lista de review-uri exprima furie
                        </li>

                        <li>
                            se poate care ce subiecte sunt intr-un text
                        </li>

                    </ul>

                </div>
                <br/>
                <div className={"text-justify"}>
                    {/*<b>Referinte:</b><br/>*/}
                    {/*<ol>*/}

                    {/*</ol>*/}
                </div>

                <br/>
                {this.navigator()}
                <br/>

            </div>
        );
    }
}

export default PromptEngineeringSpringIALlmContent;